<div class="container">
    <div class="box-item">
        <h3>Filters </h3>

        
        <!-- Uitlezen en klikbaar maken van de opties. -->
        <ul *ngFor="let filter of Filters.Filters">
            <li>
                {{filter.Name}}
            </li>

            <ul *ngFor="let option of filter.Options">
                <li>
                    <a (click)="selectOption(option.Id, option.Name)" class="{{SelectedOption ===  option.Id ? 'filteroption-selected':''}}">
                        {{ option.Name }}
                    </a>
                </li>
            </ul>
        </ul>
    </div>


    <div class="box-item">
        <h1>
            Product Varianten
            ({{ Results }})
        </h1>

        <!-- table weergeven van alle product.  -->

        <button mat-raised-button color="primary" (click)="searchVariants()">Zoek</button>
        <table>
            <tr>
                <td>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Variant Naam</mat-label>
                        <input matInput name="search" [(ngModel)]="searchVariant.Name" ngModel required #type="ngModel">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Variant Kenmerk</mat-label>
                        <input matInput name="Kenmerk" [(ngModel)]="searchVariant.Kenmerk" ngModel required #type="ngModel">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Variant DO1Naam</mat-label>
                        <input matInput name="search" [(ngModel)]="searchVariant.DO1Naam" ngModel required #type="ngModel">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Variant DO2Naam</mat-label>
                        <input matInput name="do2" [(ngModel)]="searchVariant.DO2Naam" ngModel required #type="ngModel">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Variant DO3Naam</mat-label>
                        <input matInput name="do3" [(ngModel)]="searchVariant.DO3Naam" ngModel required #type="ngModel">
                    </mat-form-field>
                </td>
                <td>
                    <button mat-raised-button color="primary" (click)="searchVariants()">Zoek</button>
                </td>
            </tr>
        </table>
        <p>
            <button mat-raised-button color="primary" (click)="searchVariants()">Zoek</button>
        </p>
        <p>
            <button mat-raised-button color="warn" (click)="searchOpposite()">Zoek tegenovergestelde</button>
        </p>
        <!--- Place list here. -->

        <input type="checkbox" [checked]="addRelation" (change)="addRelation=!addRelation">


        <button mat-raised-button color="primary" (click)="addFilterOptionRelation()">[{{deleteOpposite}}]Relatie leggen: {{addRelation}} met {{SelectedOptionName}} ({{SelectedOption}})</button>

        <table>
            <tr>
                <td>
                    Select
                </td>
                <td>
                    Name
                </td>
                <td>
                    Kenmerk
                </td>
                <td>
                    DO1
                </td>
                <td>
                    DO2
                </td>
                <td>
                    DO3
                </td>
            </tr>
            <tr *ngFor="let variant of ProductVariants.ProductVariant">
                <td>
                </td>
                <td>
                    {{variant.Name}}
                </td>
                <td>
                    {{variant.Kenmerk}}
                </td>
                <td>
                    {{variant.DO1Naam}}
                </td>
                <td>
                    {{variant.DO2Naam}}
                </td>
                <td>
                    {{variant.DO3Naam}}
                </td>
            </tr>
        </table>

    </div>  
</div>