/**
 * @ref WoonConnectViewModel/ContractorViewModel.cs
 */
	/**
	* Originele namespace: WoonConnectViewModel.LegalForm
	*/
	export enum LegalForm {
		Onbekend = 0,
		
		BeslotenVennootschap = 10
	}

