import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ProjectService } from '../services/ProjectService';
import { Woning } from '.././Models/Woning';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
	export interface IPersoonService {
		GeefAllePersonen(): ng.IPromise<PersoonInfo[]>;
		GeefAlleInlogs(): ng.IPromise<PersoonInfo[]>;
		GeefAllePersonenVanWoning(woningid): ng.IPromise<PersoonInfo[]>;
		GeefPersoon(persoonid): ng.IPromise<PersoonInfo>;
		OpslaanPersoon(persoon: PersoonInfo): ng.IPromise<boolean>;
	}

	export class PersoonInfo {

		public Inlognaam: string;
		public Geslacht: string;
		public Voornaam: string;
		public Tussenvoegsel: string;
		public Achternaam: string;
		public Telefoonnummer: string;
		public GeboorteDatum: string;
		public PersoonId: number;
		public Email: string;

		public Woningen: Woning[];

		// interface props
		public Tonen: boolean;
		
		public BezigMetLaden: boolean;

		public LaadData(data) {
			this.Inlognaam = data.Inlognaam;

			if (data.Geslacht == "1")
			{
				this.Geslacht = "Vrouw";
			}
			else
			{
				this.Geslacht = "Man";
			}
			
			this.Voornaam = data.Voornaam;
			this.Tussenvoegsel = data.Tussenvoegsel;
			this.Achternaam = data.Achternaam;
			this.Telefoonnummer = data.Telefoonnummer;
			this.GeboorteDatum = data.GeboorteDatum;
			this.PersoonId = data.PersoonId;
			this.Tonen = false;
			this.BezigMetLaden = false;
		}

		public VulDefault()
		{
			this.Inlognaam = "Basis";
			this.Geslacht = "";
			this.Voornaam = "Basis";
			this.Tussenvoegsel = "";
			this.Achternaam = "";
			this.Telefoonnummer = "";
			this.GeboorteDatum = "";
			this.PersoonId = -5;
			this.Email = "";
		}

		public GeefVolledigeNaam() {

			if (this.Voornaam == null) {
				this.Voornaam = "";
			}

			if (this.Tussenvoegsel == null) {
				this.Tussenvoegsel = "";
			}

			if (this.Achternaam == null) {
				this.Achternaam = "";
			}

			var naam = "";

			if (this.Tussenvoegsel.trim().length > 0) {
				naam = this.Voornaam.trim() + " " + this.Tussenvoegsel.trim() + " " + this.Achternaam;
			}
			else {
				naam = this.Voornaam.trim() + " " + this.Achternaam;
			}

			naam = naam.trim();

			if (naam == "")
			{
				naam = this.Inlognaam;
			}

			return naam;
		}
	}

	@Injectable({
		providedIn: 'root'
	})
	export class PersoonService implements IPersoonService {
		public ProjectId: number;

		constructor( private $http: HttpClient,
			private projectservice: ProjectService
			) {
			'ngInject';
			this.ProjectId = this.projectservice.GeefProjectId();
		}

		GeefAllePersonen(): ng.IPromise<PersoonInfo[]> {
			let request = this.$http.get('/Configurator/GeefPersonen').pipe(
				map(response => {
					var tPersonen = $.map(response, function (item) {
						var persoon = new PersoonInfo();
						persoon.LaadData(item);
						return persoon;
					});
					return tPersonen;
				})
			);
			return request.toPromise();
      }

      	GeefAlleInlogs(): ng.IPromise<PersoonInfo[]> {
			let request = this.$http.get('/Configurator/GeefPersonen?pAlleenBewoner=false').pipe(
				map(response => {
					var tPersonen = $.map(response, function (item) {
						const persoon = new PersoonInfo();
						persoon.LaadData(item);
						return persoon;
					});
					return tPersonen;
				})
			);
			return request.toPromise(); 
      }

		GeefPersoon(persoonid): ng.IPromise<PersoonInfo> {
			
			let obs = new Observable<PersoonInfo>(observable => {
				if (persoonid > 0) {
					this.$http.get('/Configurator/GeefPersoon?pPersoonId=' + persoonid).toPromise().then(response => {
						var persoon = new PersoonInfo();
						persoon.LaadData(response);
						return persoon;
					});
					observable.next(persoon)
					observable.complete();
				}
				else
				{
					var persoon = new PersoonInfo();
					persoon.VulDefault();
					observable.next(persoon)
					observable.complete();
				}	
			});
			
			return obs.toPromise();
		}
		

		GeefAllePersonenVanWoning(woningid): ng.IPromise<PersoonInfo[]> {
			let request = this.$http.get('/Configurator/GeefPersonenVanWoning?pWoningId=').pipe(
				map(response => {
					var tPersonen = $.map(response, function (item) {
						var persoon = new PersoonInfo();
						persoon.LaadData(item);
						return persoon;
					});
					return tPersonen;
				})
			);
			return request.toPromise(); 
		}

		OpslaanPersoon(persoon: PersoonInfo): ng.IPromise<boolean> {
			let obs = new Observable<boolean>((observer) => {
				let inputdata: any = {};
				inputdata.PersoonId = persoon.PersoonId;
				inputdata.Geslacht = persoon.Geslacht;
				inputdata.Voornaam = persoon.Voornaam;
				inputdata.Tussenvoegsel = persoon.Tussenvoegsel;
				inputdata.Achternaam = persoon.Achternaam;
				inputdata.Geboortedatum = persoon.GeboorteDatum;
				inputdata.Telefoonnummer = persoon.Telefoonnummer;
				inputdata.Emailadres = persoon.Email;
				this.$http.post('/Configurator/OpslaanPersoon', inputdata).toPromise().then(response => {
						var d: any = response;
						observer.next(d);
						observer.complete();
					});
				});
			return obs.toPromise();
		}
	}
