<nav *ngIf="tree">
  <ng-container *ngFor="let treenode of tree; trackBy: trackByIndex; let i = index">
    <wct-stui-tree-node [treenode]="treenode" class="level-one" [class.node_active]="treenode.active" (click)="setActive(treenode)"></wct-stui-tree-node>
    <div class="two" [ngClass]="{active: treenode.active}">
      <ng-container *ngFor="let treenode of treenode.children; trackBy: trackByIndex; let i = index">
        <wct-stui-tree-node [treenode]="treenode" class="level-two" [class.node_active]="treenode.active" (click)="setActive(treenode)"></wct-stui-tree-node>
        <div class="three" [ngClass]="{active: treenode.active}">
          <ng-container *ngFor="let treenode of treenode.children; trackBy: trackByIndex; let i = index">
            <wct-stui-tree-node [treenode]="treenode" class="level-three" [class.node_active]="treenode.active" (click)="setActive(treenode)"></wct-stui-tree-node>
            <div class="four" [ngClass]="{active: treenode.active}">
              <ng-container *ngFor="let treenode of treenode.children; trackBy: trackByIndex; let i = index">
                <wct-stui-tree-node [treenode]="treenode" class="level-four" [class.node_active]="treenode.active" (click)="setActive(treenode)"></wct-stui-tree-node>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</nav>
