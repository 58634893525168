import * as _ from 'lodash';
import * as $ from 'jquery';

import * as ng from 'angular';
import * as HelpersMasterTypescript from '.././Helpers/MasterTypescript';
import * as ServicesWebBerichtService from '.././services/WebBerichtService';
import * as HelpersServerTracker from '.././Helpers/ServerTracker';
import * as ServicesSceneTypescript from '.././services/SceneTypescript';
import * as ComponentsfooterWctFooter from '.././components/footer/WctFooter';
import * as ServicesOpslagService from '.././services/OpslagService';
import * as ServicesFatalErrorService from '.././services/FatalErrorService';
import * as ServicesSceneService from '../viewers/SceneService';
import * as ServicesProjectService from '.././services/ProjectService';
import * as ServicesWebGLViewerService from '.././services/WebGLViewerService';
import * as HelpersBackwards from '.././Helpers/Backwards';
import * as ServicesPerimeterService from '.././services/PerimeterService';
import { IDrawSceneConsumer } from '@consumerinterfaces/DrawSceneController';
import { DrawSceneRequestModel } from '@models/DrawSceneModel';
import { CalculateFragmentRequestModel } from '@models/CalculateFragmentModel';
import { CreateSceneRequestModel } from '@models/CreateSceneModel';
import { IDrawSceneResponseModel, IFragmentAction } from '@datacontracts/DrawSceneModel';
import { ICalculateFragmentResponseModel } from '@datacontracts/CalculateFragmentModel';
import { ICreateSceneResponseModel } from '@datacontracts/CreateSceneModel';
import { CalculateFragmentResult } from "@enums/ISceneService";
import { IWoningEventService } from './WoningEventService';
import * as Viewer from '../modules/webgl/WebGLViewer';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../infrastructure/app.configservice';
import {  DrawSceneOptions  } from '@models/DrawSceneModel';
// Module

var globalScene: any = new Object();

var mSceneOudeManierWachten = true;

////Perimeters

var perimeterLijst: ServicesPerimeterService.ViewPerimeter[];

var perimeterTriggers: ServicesPerimeterService.PerimeterTrigger[];

var perimeterEvents: ServicesPerimeterService.PerimeterEvent[];
////Einde perimeters
//globalScene.Master = true;

globalScene.AnimatieObjecten = {};

var mijnTestAnimatieTotaalTijd;
var mijnTestAnimatieTotaalAantal;
var mijnTestAnimatieViewer = null;

function SceneSessionLog(prefix, formatted) {
	if (configService.Software.BuildMode == 'DEBUG') {
		console.log(prefix + " " + formatted);
	}
}

export class OndersteundeFeatures {
	constructor() {
		this.Schaduw = true;
		this.Nabewerkingen = true;
	}

	AllesUit() {
		this.Schaduw = false;
		this.Nabewerkingen = false;
	}


	Schaduw: boolean;
	Nabewerkingen: boolean;

	Bepaal() {
		if (typeof HelpersBackwards.detect !== 'undefined') {
			var ua = HelpersBackwards.detect.parse(navigator.userAgent);
			var browser: string = "browserfamily=" + ua.browser.family + ", browsername=" + ua.browser.name + ", browserversion=" + ua.browser.version;

			if (ua.os.family == "Mac OS X" && ua.browser.family == "Firefox") {
				this.Schaduw = false;
				this.Nabewerkingen = false;
			} else if (ua.browser.family == 'Chrome' && ua.os.family == 'Mac OS X') {
				this.AllesUit();
			} else if (ua.browser.family == "Edge") {
				// alle features toegestaan
			} else if (ua.os.family == "Android") {
				this.Nabewerkingen = false;
			} else if (ua.browser.family == "IE" || ua.os.family == "iOS") {
				this.AllesUit();
			}
		}

		this.Schaduw = this.ZetVlag("WEBGL_SCHADUW", this.Schaduw);
		this.Nabewerkingen = this.ZetVlag("WEBGL_NABEWERKINGEN", this.Nabewerkingen);
	}

	ZetVlag(naam: string, orig: boolean): boolean {
		var vlag = _.find(configService.Software.FrontendVlaggen, (v) => v.Name == naam);
		if (vlag == null) {
			return orig;
		}

		var waarde = vlag.Value;
		if (waarde == "TRUE") {
			return true;
		}

		if (waarde == "FALSE") {
			return false;
		}

		return orig;
	}


}

export class WidthHeight {
	Width: number;
	Height: number;

	constructor(width: number, height: number) {
		'ngInject';
		this.Width = width;
		this.Height = height;
	}
}


export class FragmentModelData {
	FragmentId: string;
	FragmentUrl: string;
	FragmentUrlId: string;
	FragmentConfigKey: string;
	AktieNodig: boolean;
	TransformatieURL: string;
	TransformatieText: string;
	IsMeubilair: string;
}

export class FragmentAction {
	/* Server properties */
	action: string;
	modeldata: Array<FragmentModelData>;
	actionExtraData: any;
	DisplayMode: string;
	CameraNaam: string;
	CameraZoomSchaal: number;

	CameraRichting: number;
	CameraHelling: number;
	CameraCentrum: Array<number>;

	CameraRichting3D: Array<number>;
	CameraPositie: Array<number>;

	Zoom: number[][];
	CameraDomeAfbeelding: string;
	CameraRichtingIs3D: boolean;
	Lichtval: Array<number>;
	LichtSchittering: number;
	LichtbronSterkte: number;
	IsCameraLicht: boolean;
	OmgevinglichtSterkte: number;
	SlagschaduwVerzadiging: number;
	ToonSlagschaduw: boolean;
	AktieNodig: boolean;
	DownloadBezig: boolean;

	/* Eigen properties */
	JSONData: any = null;

	constructor(data: IFragmentAction) {
		'ngInject';
		if (data != null) {
			for (var k in data) {
				this[k] = data[k];
			}
		}
	}

	IsDownloadAktie(): boolean {
		return (this.action == "add" || this.action == "replace");
	}

	KanDownloaden(): boolean {
		return (!this.AktieNodig && this.JSONData == null && this.IsDownloadAktie() && !this.DownloadBezig);
	}

	IsViewerAktie(): boolean {
		return (this.action == "add" || this.action == "replace" || this.action == "removeMultiple" || this.action == "replaceTrans");
	}
	IsNieuwMeubilair(): boolean {
		return false;
		//return (this.action == "add") && (this.modeldata[0].IsMeubilair != null);
	}

	KanNaarViewer(): boolean {
		if (this.AktieNodig) {
			return false;
		}

		if (this.IsDownloadAktie && this.JSONData != null) {
			return true;
		}

		if (this.action == "removeMultiple" || this.action == "replaceTrans") {
			return true;
		}

		return false;
	}
}

export class SceneDisplayMode {

	sceneSession: SceneSession = null;
	Naam: string;
	DomeAfbeelding: string = null;
	IsAfgebeeld: boolean = false;
	CameraIsTerrein: boolean = false;
	CameraZoomSchaal: number = null;

	CameraRichting: number = 90.0;
	CameraHelling: number = 15.0;
	CameraRichting3D: Array<number> = [0, 0, 0];
	CameraRichtingIs3D = false;
	CameraNaam: string = "[initialcamera]";

	FirmamentCameraCentrum: Array<number> = null;
	FirmamentCameraCentrumHellingRichting: Array<number> = null;

	TerreinCameraPositie: Array<number> = [-10000, 2000, -5000];

	constructor(naam: string, sceneSession: SceneSession, private webglviewerservice: ServicesWebGLViewerService.IWebGLViewerService) {
		'ngInject';
		this.sceneSession = sceneSession;
		this.Naam = naam;
	}

	BackupCamera(viewer) {
		this.CameraZoomSchaal = this.webglviewerservice.GeefZoomSchaal(viewer);

		if (this.CameraIsTerrein) {
			this.TerreinCameraPositie = this.webglviewerservice.GeefPositie(viewer);
		}
		else {
			this.FirmamentCameraCentrum = this.webglviewerservice.GeefCentrum(viewer);
			var kijkhoeken = this.webglviewerservice.GeefKijkhoeken(viewer);
			this.FirmamentCameraCentrumHellingRichting = [kijkhoeken[0], kijkhoeken[1]];
			SceneSessionLog("Backup firmament centrum:", this.FirmamentCameraCentrum);
		}

		if (this.CameraRichtingIs3D) {
			this.CameraRichting3D = this.webglviewerservice.GeefKijkrichting(viewer);
		} else {
			var richting = this.webglviewerservice.GeefKijkhoeken(viewer);
			this.CameraRichting = richting[0];
			this.CameraHelling = richting[1];
		}
	}

	RestoreCamera(viewer) {
		if (viewer == null) {
			return;
		}

		SceneSessionLog("Restore zoomschaal", this.CameraZoomSchaal + " (=" + this.webglviewerservice.GeefZoomSchaal(viewer) + ")");

		var gewensteCamera = !this.CameraIsTerrein;
		if (gewensteCamera != this.webglviewerservice.IsTerreinCamera(viewer)) {
			SceneSessionLog("VIEWERCMD", "setCamera " + gewensteCamera);
			this.webglviewerservice.ZetTerreinCamera(viewer, gewensteCamera);
		}

		let zoomschaalGezet: boolean = false;

		if (this.CameraZoomSchaal != null) {
			SceneSessionLog("VIEWERCMD", "zetZoomSchaal " + this.CameraZoomSchaal);
			this.webglviewerservice.ZetZoomSchaal(viewer, this.CameraZoomSchaal);
			zoomschaalGezet = true;
		}

		if (this.CameraRichtingIs3D) {
			SceneSessionLog("VIEWERCMD", "setKijkrichting " + this.CameraRichting3D);
			this.webglviewerservice.ZetKijkrichting(viewer, this.CameraRichting3D);
		} else {
			SceneSessionLog("VIEWERCMD", "zetKijkrichting " + [this.CameraRichting, this.CameraHelling]);
			this.webglviewerservice.ZetKijkhoeken(viewer, [this.CameraRichting, this.CameraHelling]);
			SceneSessionLog("Restore kijkrichting", [this.CameraRichting, this.CameraHelling] + " (=" + this.webglviewerservice.GeefKijkhoeken(viewer) + ")");
		}

		if (!zoomschaalGezet) {
			SceneSessionLog("VIEWERCMD", "zetZoomGeheel");
			this.webglviewerservice.ZetZoomGeheel(viewer);
		}

		if (this.CameraIsTerrein) {
			SceneSessionLog("VIEWERCMD", "setPositie " + this.TerreinCameraPositie);
			this.webglviewerservice.ZetPositie(viewer, this.TerreinCameraPositie);
			//viewer.setOrthografischeProjectie(true);
		}
		else {
			if (this.FirmamentCameraCentrum != undefined) {
				if (this.FirmamentCameraCentrumHellingRichting != undefined) {
					// tijdelijke kijkrichting zetten, zodat centrum goed komt
					SceneSessionLog("VIEWERCMD", "zetKijkrichting " + this.FirmamentCameraCentrumHellingRichting);
					this.webglviewerservice.ZetKijkhoeken(viewer, this.FirmamentCameraCentrumHellingRichting);
				}
				SceneSessionLog("Restore firmament centrum", this.FirmamentCameraCentrum + "(=" + this.webglviewerservice.GeefCentrum(viewer) + ")");
				SceneSessionLog("VIEWERCMD", "setCentrum " + this.FirmamentCameraCentrum);
				this.webglviewerservice.ZetCentrum(viewer, this.FirmamentCameraCentrum);

				if (this.FirmamentCameraCentrumHellingRichting != undefined) {
					// en weer onze nieuwe zetten
					SceneSessionLog("VIEWERCMD", "zetKijkrichting " + [this.CameraRichting, this.CameraHelling]);

					this.webglviewerservice.ZetKijkhoeken(viewer, [this.CameraRichting, this.CameraHelling]);
				}
			}
		}
	}

	PreToonModel = function (viewer) {
		var myobject = this;
		if (!this.IsAfgebeeld) {
			if (!this.CameraIsTerrein) {
				SceneSessionLog("CameraZoomSchaal PreToonModel", this.CameraZoomSchaal);
				if (this.CameraZoomSchaal == 0) {
					//viewer.zetZoomGeheel();
				}
				if (this.FirmamentCameraCentrum) {
					//SceneSessionLog("VIEWERCMD", "setCentrum " + this.FirmamentCameraCentrum);
					//viewer.setCentrum(this.FirmamentCameraCentrum);
				}
			}
		}
	}

	PostToonModel(viewer) {
		var myobject = this;
		if (this.CameraZoomSchaal != null) {
			//SceneSessionLog("VIEWERCMD", "setZoomSchaal " + this.CameraZoomSchaal);
			//viewer.setZoomSchaal(this.CameraZoomSchaal);
			//SceneSessionLog("PostToonModel", "zetZoomSchaal: " + this.CameraZoomSchaal);
		}
		else {
			//SceneSessionLog("PostToonModel", "zetZoomGeheel");
			//viewer.zetZoomGeheel();
		}
		if (this.FirmamentCameraCentrum) {
			//SceneSessionLog("VIEWERCMD", "setZoomSchaal " + this.CameraZoomSchaal);
			//viewer.setZoomSchaal(this.CameraZoomSchaal);
			//SceneSessionLog("VIEWERCMD", "setCentrum " + this.FirmamentCameraCentrum);
			//viewer.setCentrum(this.FirmamentCameraCentrum);
		}


		SceneSessionLog("VIEWERCMD", "toonModel");
		//viewer.toonModel();
		this.webglviewerservice.ToonModel(viewer);

		this.IsAfgebeeld = true;
	}
}

export interface ISceneVoortgang {
	VoortgangStappenNu: number;
	VoortgangStappenTotaal: number;
	VoortgangPercentage: number;
	LadenBezig: boolean;
}

enum SceneSessionFase {
	Initieel,
	ZonderMeubels,
	MetMeubels,
	Klaar
}

export class SceneLoader {
	constructor(private projectservice: ServicesProjectService.IProjectService, private sceneservice: ServicesSceneService.ISceneService, private NieuweFragmenten: Array<FragmentAction>, private $http: HttpClient, private scenesession: SceneSession, private rapporteerVoortgang: boolean, private webglviewerservice: ServicesWebGLViewerService.IWebGLViewerService, private fatalerrorservice: ServicesFatalErrorService.IFatalErrorService, private drawsceneconsumer: IDrawSceneConsumer) {
		'ngInject';

	}


	DownloadCtx: ComponentsfooterWctFooter.BezigContext = this.sceneservice.Download.MaakContext();
	RekenCtx: ComponentsfooterWctFooter.BezigContext = this.sceneservice.Reken.MaakContext();
	ViewerCtx: ComponentsfooterWctFooter.BezigContext = this.sceneservice.Viewer.MaakContext();

	m_DownloadGestart = false;
	m_RekenGestart = false;
	m_ViewerGestart = false;

	m_DownloadAktiesBezig: number = 0;
	MaxDownloads: number = 7;


	/* Eigen properties */
	DownloadGestart(b: boolean = null): boolean {
		if (b != null) {
			this.m_DownloadGestart = b;
			this.DownloadCtx.Waarde(b);
		}
		return this.m_DownloadGestart;
		//if (this.DownloadCtx == null) {
		//    var i = this.GeefInstance();
		//    if (i != null) {
		//        this.DownloadCtx = i.Download.MaakContext();
		//    }
		//}


		//return this.DownloadCtx.Waarde(b);
	}

	RekenGestart(b: boolean = null): boolean {
		if (b != null) {
			this.m_RekenGestart = b;
			this.RekenCtx.Waarde(b);
		}
		return this.m_RekenGestart;
		//return this.RekenCtx.Waarde(b);
	}

	ViewerGestart(b: boolean = null): boolean {
		if (b != null) {
			this.m_ViewerGestart = b;
			this.ViewerCtx.Waarde(b);
		}
		return this.m_ViewerGestart;
		//return this.ViewerCtx.Waarde(b);
	}

	ViewerFragmentTransformatiesVerwerkt(fragment: FragmentAction, runUitContext: number) {
		var index = this.NieuweFragmenten.indexOf(fragment);
		if (index > -1) {
			this.NieuweFragmenten.splice(index, 1);
		}

		if (fragment.action == "add") {
			this.scenesession.HuidigeFragmenten.push(fragment);
		} else if (fragment.action == "replace" || fragment.action == "replaceTrans") {
			var moetgewijzigdworden = _.find(this.scenesession.HuidigeFragmenten, (m) => m.modeldata[0].FragmentId == fragment.modeldata[0].FragmentId);
			moetgewijzigdworden.modeldata[0] = fragment.modeldata[0];
		} else if (fragment.action == "removeMultiple") {
			var aantalVoor = this.scenesession.HuidigeFragmenten.length;
			this.scenesession.HuidigeFragmenten = _.filter(this.scenesession.HuidigeFragmenten, (f) => {
				if (f.IsViewerAktie()) {
					var moetverwijderdworden = _.find(fragment.modeldata, (m) => m.FragmentId == f.modeldata[0].FragmentId);
					return !moetverwijderdworden;
				} else {
					return true;
				}
			});
			var aantalNa = this.scenesession.HuidigeFragmenten.length;
			this.scenesession.Log("removeMultiple " + aantalVoor + " => " + aantalNa);
		} else if (fragment.action == "behouden") {
			/* skip */
		} else {
			this.scenesession.Log("** Onbekend type te verwerken fragment " + fragment.action);
		}

		this.ViewerGestart(false);
		this.VerwerkNieuweFragmenten(runUitContext);
	}

	VerwerkNieuweFragmenten(run: number, options : DrawSceneOptions = null) {

		if (this.scenesession.pendingUpdate) {
			this.scenesession.Log("Pending update, exit");

			if (!this.RekenGestart() && !this.DownloadGestart() && !this.ViewerGestart()) {
				// omdat het licht alleen de eerste keer wordt verstuurd, en deze wellicht wordt afgebroken, willen we de licht aktie altijd nog door laten gaan.
				var overigeAkties = _.filter(this.NieuweFragmenten, (f) => !f.IsViewerAktie());
				_.each(overigeAkties, (a) => {
					this.scenesession.VerwerkOverigFragmentAktie(a);
				});

				/* Alle overige akties zijn ook verwerkt, dus we kunnen opnieuw gaan tekenen */
				this.scenesession.pendingUpdate = false;
				this.scenesession.TekenActief = false;
				this.scenesession.DrawScene(options);
			}

			return;
		}

		if (this.scenesession.mDrawSceneRun != run) {
			this.scenesession.Log("Drawscene run " + this.scenesession.mDrawSceneRun + ", opgegeven run " + run + ", exit");
			return;
		}

		var viewer: any = this.scenesession.GeefViewer();
		if (viewer == null) {
			/* De viewer context is kwijtgeraakt; voorlopig gewoon stoppen met verder handelen */
			this.scenesession.DrawSceneEindeDoorContextFout();
			return;
		}

		this.scenesession.Log("Drawscene run " + this.scenesession.mDrawSceneRun + ", opgegeven run " + run);

		if (this.scenesession.m_Disposed) {
			this.scenesession.Log("VerwerkNieuweFragmenten, we zijn disposed, exit");
			return;
		}
		if (this.scenesession.mVerzoekPauzeer) {
			this.scenesession.mDrawSceneGepauzeerd = true;
			return;
		}

		var aantal = _.filter(this.NieuweFragmenten, (a) => a.IsViewerAktie()).length;

		if (this.rapporteerVoortgang) {
			this.scenesession.ZetVoortgang(75, this.scenesession.TotaalFragmentenNaarViewer - aantal, this.scenesession.TotaalFragmentenNaarViewer);
		}

		var aktieLoos: boolean = true;

		if (!this.RekenGestart()) {
			var rekenAktie = _.find(this.NieuweFragmenten, (f) => f.AktieNodig);
			if (rekenAktie != null) {
				this.scenesession.Debug("Start rekenaktie " + rekenAktie.modeldata[0].FragmentUrl);
				aktieLoos = false;

				let pid;
				let patt = /PID(\d+)_/;
				let matches = patt.exec(rekenAktie.modeldata[0].FragmentConfigKey);
				if (matches != null) {
					pid = matches[1];
				}
				else {
					pid = this.projectservice.GeefProjectId().toString();;
				}

				let req = new CalculateFragmentRequestModel();
				req.SceneSessionID = this.scenesession.sceneID;
				req.fragmentid = rekenAktie.modeldata[0].FragmentId;
				req.fragmentwaarde = rekenAktie.modeldata[0].FragmentUrlId;
				req.snapshot = this.scenesession.Snapshot;
				req.projectid = this.projectservice.GeefProjectId();
				req.fragmentprojectid = pid;

				this.RekenGestart(true);
				//var uri = '/Scene/BerekenFragment?SceneSessionID=' + this.scenesession.sceneID + '&fragmentid=' +  + '&fragmentwaarde=' + rekenAktie.modeldata[0].FragmentUrlId + '&snapshot=' + this.scenesession.Snapshot + '&projectid=' + this.projectservice.GeefProjectId() + '&fragmentprojectid=' + pid;
				this.drawsceneconsumer.CalculateFragment(req).then(d => {
					//this.$http.post(uri, null).then((d: any) => {
					let resultaat = d.Resultaat;

					rekenAktie.AktieNodig = false;
					if (resultaat == CalculateFragmentResult.SessionLost) {
						if (this.scenesession.m_Destroyed) {
							this.scenesession.DrawSceneEindeDoorSessieKwijt();
						}
						else {
							this.fatalerrorservice.ReloadPage(null, "Scene geen sessie 1");
						}
					}

					if (resultaat == CalculateFragmentResult.Empty) {
						/* Uit het rooster halen */
						var index = this.NieuweFragmenten.indexOf(rekenAktie);
						if (index > -1) {
							this.NieuweFragmenten.splice(index, 1);
						}
					}

					this.RekenGestart(false);
					this.VerwerkNieuweFragmenten(run);
				}).catch((err: any) => {
					rekenAktie.AktieNodig = false;

					// uit het rooster halen
					var index = this.NieuweFragmenten.indexOf(rekenAktie);
					if (index > -1) {
						this.NieuweFragmenten.splice(index, 1);
					}
					this.RekenGestart(false);
					this.VerwerkNieuweFragmenten(run);
				});
			}
		}
		else {
			aktieLoos = false;
		}

		if (this.m_DownloadAktiesBezig < this.MaxDownloads) {
			var downloadAkties = _.filter(this.NieuweFragmenten, (f) => f.KanDownloaden());
			if (downloadAkties != null) {
				_.each(downloadAkties, (downloadAktie) => {
					if (this.m_DownloadAktiesBezig < this.MaxDownloads) {
						aktieLoos = false;
						this.m_DownloadAktiesBezig++;
						this.scenesession.Debug("Start downloadaktie " + this.m_DownloadAktiesBezig + " " + downloadAktie.modeldata[0].FragmentUrl);

						this.DownloadGestart(true);
						var uri = downloadAktie.modeldata[0].FragmentUrl;

						//this.opslagservice.Geef(uri).then(data => {
						//	if (data == null) {
						//		this.$http({ method: 'GET', url: uri, transformResponse: [] }).then((d: any) => {
						//			var data = d.data;

						//			if (data.length > (1024 * 1024 * 50)) {
						//				this.opslagservice.SlaOp(uri, d.data);
						//			}
						//			//$.get(uri).done((data) => {
						//			downloadAktie.AktieNodig = false;
						//			downloadAktie.JSONData = data;
						//			this.DownloadGestart(false);
						//			this.VerwerkNieuweFragmenten();
						//		});
						//	}
						//	else {

						downloadAktie.DownloadBezig = true;

						this.$http.get<string>(uri, { responseType: 'text' as 'json' }).subscribe(d => {
							var data = d;

							downloadAktie.AktieNodig = false;
							downloadAktie.JSONData = data;

							this.m_DownloadAktiesBezig--;
							if (this.m_DownloadAktiesBezig == 0) {
								this.DownloadGestart(false);
							}
							this.VerwerkNieuweFragmenten(run);
						});
					}
				});

			}
		}
		else {
			aktieLoos = false;
		}

		if (!this.ViewerGestart()) {
			var viewerAktie = _.find(this.NieuweFragmenten, (f) => f.KanNaarViewer());
			if (viewerAktie != null) {
				this.scenesession.Debug("Start viewaktie " + viewerAktie.action + " " + viewerAktie.modeldata[0].FragmentId + ", key=" + viewerAktie.modeldata[0].FragmentConfigKey);

				aktieLoos = false;
				this.ViewerGestart(true);

				if (viewerAktie.action == "replace") {
					viewer.replace(viewerAktie.JSONData, viewerAktie.modeldata[0].FragmentId, (ID) => {
						if (ID != viewerAktie.modeldata[0].FragmentId) {
							this.scenesession.Log("** ONGELDIGE ID GEVONDEN (replace) " + ID + " != " + viewerAktie.modeldata[0].FragmentId);
						}
						this.scenesession.Log(" Viewer add " + viewerAktie.modeldata[0].FragmentId + "(" + ID + ")");
						this.ViewerFragmentVerwerkt(viewerAktie, run, viewer);
					});
				} else if (viewerAktie.action == "add") {

					viewer.add(viewerAktie.JSONData, (ID) => {
						if (ID != viewerAktie.modeldata[0].FragmentId) {
							this.scenesession.Log("** ONGELDIGE ID GEVONDEN (add) ID=" + ID + " != modeldata.fragmentid=" + viewerAktie.modeldata[0].FragmentId + "(" + viewerAktie.modeldata[0].FragmentConfigKey + ")");
						}
						this.scenesession.Log(" Viewer add " + viewerAktie.modeldata[0].FragmentId + "(" + ID + ")");
						this.ViewerFragmentVerwerkt(viewerAktie, run, viewer);
					});

				} else if (viewerAktie.action == "removeMultiple") {
					var fragmentIDs = [];
					_.each(viewerAktie.modeldata, (value) => {
						this.scenesession.Log(" Viewer removeMultiple " + value.FragmentId);

						fragmentIDs.push(value.FragmentId);
					});
					viewer.remove(fragmentIDs);

					this.ViewerFragmentTransformatiesVerwerkt(viewerAktie, run);
				} else if (viewerAktie.action == "replaceTrans") {
					this.ViewerFragmentVerwerkt(viewerAktie, run, viewer);
				}
			}
		}
		else {
			aktieLoos = false;
		}

		if (aktieLoos) {
			if (this.rapporteerVoortgang) {
				this.scenesession.ZetVoortgangBasis(90);
			}

			_.each(this.NieuweFragmenten, (i) => {
				this.scenesession.VerwerkOverigFragmentAktie(i);
			});
			this.scenesession.DrawSceneEinde();
		}

		//this.scenesession.NotifyAngular();
	}

	ViewerFragmentVerwerkt(fragment: FragmentAction, run: number, viewer: any) {
		if (viewer == null) {
			this.scenesession.Debug("Lege viewer aangetroffen in ViewerFragmentVerwerkt");
			return;
		}
		if (fragment.modeldata[0].TransformatieURL != null) {
			viewer.replaceTransFromFile(fragment.modeldata[0].TransformatieURL, fragment.modeldata[0].FragmentId, () => {
				this.ViewerFragmentTransformatiesVerwerkt(fragment, run);
			});

			/*
			this.$http.get(fragment.modeldata[0].TransformatieURL).then(r => {
				var text = JSON.stringify(r.data);
				viewer.replaceTrans(text, fragment.modeldata[0].FragmentId);

				// bewaren voor manipulatie
				fragment.modeldata[0].TransformatieText = text;
				this.ViewerFragmentTransformatiesVerwerkt(fragment);
			});
			*/
		} else {
			if (fragment.modeldata[0].TransformatieText != null) {
				viewer.replaceTrans(fragment.modeldata[0].TransformatieText, fragment.modeldata[0].FragmentId);
			}
			this.ViewerFragmentTransformatiesVerwerkt(fragment, run);
		}
	}
}

let configService: AppConfigService = null;

export class SceneSession implements ISceneVoortgang, ServicesSceneTypescript.SceneSession {
	mPendingDrawOptions: DrawSceneOptions;
	mOptions: DrawSceneOptions;
	SubscribeDrawScene(arg0: () => void) {
		this.m_DrawSceneFinished = arg0;
	}

	m_DrawSceneFinished: () => void = null;
	Fase: SceneSessionFase = SceneSessionFase.Initieel;
	canvas: any = null;
	scenetype: string = null;
	sceneID: string = "Onbekend";
	ooitGetoond: boolean = false;
	pendingUpdate: boolean = false;
	TekenActief: boolean = false;
	TekenAfgebrokenDoorContextFout: boolean = false;
	NieuweFragmenten: Array<FragmentAction> = [];
	MeubilairFragmenten: Array<FragmentAction> = [];
	Snapshot: string = null;
	HuidigeFragmenten: Array<FragmentAction> = [];
	NieuweFragmentenOnberekend: number = -1;
	TotaalFragmentenInitieel: number = 0;
	TotaalFragmentenNaarViewer: number = 0;
	ModelGetekendCallback: any = null;
	m_ActiveerSelectie: boolean = false;
	ZetRichtingInitieel: boolean = true;
	Gepauzeerd: boolean = false;
	VerversUitgesteldDoorPauze: boolean = false;
	DrawGewenstNaSetup: boolean = false;
	SceneZonneData: any = null;
	SceneZonneSnelheid: number = 200;
	SceneZonneEinde: () => void;
	LaatsteBelichtingAktie: any = null;
	displayModes: Array<SceneDisplayMode> = [];
	display: SceneDisplayMode = null;
	belichtingBackup: any = null;
	m_IdlePeriod: number = -1;
	m_BumpmappingHint: boolean = true;
	m_Geselecteerd: any = null;
	m_ViewerGeinitialiseerd: boolean = false;
	m_WebGLError: boolean = false;
	m_Disposed: boolean = false;
	m_Random: string = Math.random().toString();
	m_ServerTracker: HelpersServerTracker.ServerTracker = new HelpersServerTracker.ServerTracker();

	LoaderZonderNieuweMeubels: SceneLoader = null;
	LoaderNieuweMeubels: SceneLoader = null;

	displayFirmament: SceneDisplayMode = null;
	displayTerrein: SceneDisplayMode = null;

	volgCameraType: boolean = false;

	mVerzoekPauzeer: boolean = false;
	mDrawSceneRun: number = 1;
	mDrawSceneGepauzeerd: boolean = false;
	mDrawSceneOoitAangevraagd: boolean = false;

	ZonneTijd: string = "";

	// voor de camera beweging
	public TerreinAnimatieDirect: boolean = this.config.Software.TerreinCameraDirect;
	sceneAnimatieData: any = null;
	sceneAanHetTekenen: boolean = false;
	sceneCameraAnimatieNodig: boolean = false;
	laatsteCameraDisplay: string = null;
	zoom: any = undefined;
	LichtrichtingOverride: any = {};
	metLampen: boolean = false;
	m_Destroyed: boolean = false;

	mForceerCameraReset: boolean = false;

	ForceerCameraReset() {
		this.mForceerCameraReset = true;
	}

	webGLSupport(): boolean {
		return this.sceneservice.GebruikWebGL();
	}
	Image: any = null;

	private m_KleurTerrein: number[] = [255, 255, 255];
	private m_KleurFirmament: number[] = [255, 255, 255];

	LadenBezig: boolean = (false);
	VoortgangStappenNu: number = (0);
	VoortgangStappenTotaal: number = (0);
	VoortgangPercentage: number = (0);
	mAssen: boolean = false;
	mAssenIsAan: boolean = false;
	m_Filter: string = '';

	DisplayCameraIsTerrein(): boolean {
		return this.display.CameraIsTerrein;
	}

	ZetAssen(b: boolean) {
		this.mAssen = b;
	}

	Dispose() {
		this.Log('Dispose sessie');
		var viewer = null;
		if (this.m_ViewerGeinitialiseerd) {
			viewer = this.GeefViewer();
		}

		this.m_Disposed = true;

		if (viewer != null) {
			this.webglviewerservice.dispose(this.canvas);
			this.m_ViewerGeinitialiseerd = false;
		}
	}

	DisposeEnRedraw() {
		var viewer = this.GeefViewer();
		if (viewer != null) {
			this.webglviewerservice.dispose(this.canvas);
		}

		this.HuidigeFragmenten = [];
		this.ooitGetoond = false;
		this.DrawScene();
	}

	HeeftWebGLError() {
		return this.m_WebGLError;
	}

	public Destroyed() {
		// geeft aan dat de sessie (vermoedelijk) destroyed is vanuit Angular perspectief. Eventuele fouten hoeven niet aan de gebruiker te worden teruggemeld.
		this.m_Destroyed = true;
	}



	constructor(private config: AppConfigService, private projectservice: ServicesProjectService.IProjectService,
		private $http: HttpClient, private sceneservice: ServicesSceneService.ISceneService,
		private webglviewerservice: ServicesWebGLViewerService.IWebGLViewerService, canvas, scenetype, startload = false, metlampen = false, metSelectie_Dummy = false,
		selectieAntialiased_Dummy = false, tekencallback: any = null, kleurfirmament: number[] = null, kleurterrein: number[] = null,
		private fatalerrorservice: ServicesFatalErrorService.IFatalErrorService,
		private suppressRedraw: boolean,
		private drawsceneconsumer: IDrawSceneConsumer,
		private woningeventservice: IWoningEventService) {
		'ngInject';
		this.canvas = canvas;
		this.scenetype = scenetype;
		this.ModelGetekendCallback = tekencallback;
		this.m_ActiveerSelectie = metSelectie_Dummy;

		configService = config;

		this.ZetNabewerkingsTimeout();

		if (kleurfirmament != null) {
			this.m_KleurFirmament = kleurfirmament;
		}

		if (kleurterrein != null) {
			this.m_KleurTerrein = kleurterrein;
		}

		this.displayFirmament = new SceneDisplayMode('firmament', this, this.webglviewerservice);
		this.displayTerrein = new SceneDisplayMode('terrein', this, this.webglviewerservice);
		this.displayFirmament.CameraIsTerrein = false;

		this.displayTerrein.CameraIsTerrein = true;
		this.display = this.displayFirmament;
		this.displayModes.push(this.displayFirmament);
		this.displayModes.push(this.displayTerrein);


		//
		// Lampen staan standaard uit vanwege snelheid. Beinvloed de prestatie zeer nadeling (zie "Implementatiegids Online 3D-Viewer *.doc").
		//
		this.metLampen = metlampen;

		var self = this;

		this.Setup(startload);
	}

	OverrideBelichtingRichting(camera, richting) {
		this.LichtrichtingOverride[camera] = richting;
	}

	VolgCameraType(b) {
		this.volgCameraType = b;
	}

	GeefDisplay(s): SceneDisplayMode {
		for (var index = 0; index < this.displayModes.length; ++index) {
			var indexMode = this.displayModes[index];
			if (indexMode.Naam == s) {
				return indexMode;
			}
		}
	}

	BepaalWebGLOndersteuning(): OndersteundeFeatures {
		var features = new OndersteundeFeatures();
		features.Bepaal();
		features.Nabewerkingen = false;
		return features;
	}



	ZetNabewerkingsTimeout() {
		if (typeof HelpersBackwards.detect !== 'undefined') {
			var ua = HelpersBackwards.detect.parse(navigator.userAgent);

			if (ua.browser.family == "IE" || ua.browser.family == "Edge") {
				this.m_IdlePeriod = 3;
			} else {
				this.m_IdlePeriod = 0.02;
			}
		}
		else {
			this.m_IdlePeriod = 0.02;
		}
	}

	PasBelichtingToeVanItem(belichting: ServicesWebGLViewerService.WebGLBelichting, item: any) {
		this.webglviewerservice.ZetBelichtingLichtval(belichting, item.Lichtval);
		this.webglviewerservice.ZetBelichtingLichtbronSterkte(belichting, item.LichtbronSterkte);
		this.webglviewerservice.ZetBelichtingIsCameraLicht(belichting, item.IsCameraLicht);
		this.webglviewerservice.ZetBelichtingLichtSchittering(belichting, item.LichtSchittering);
		this.webglviewerservice.ZetBelichtingOmgevinglichtSterkte(belichting, item.OmgevinglichtSterkte);
		this.webglviewerservice.ZetBelichtingSlagschaduwVerzadiging(belichting, item.SlagschaduwVerzadiging); // 0 - 1 (1 = donker)
		this.webglviewerservice.ZetBelichtingSlagschaduwHint(belichting, 1);


		if (!this.BepaalWebGLOndersteuning().Schaduw) {
			this.webglviewerservice.ZetBelichtingSlagschaduw(belichting, false);
		} else {
			this.webglviewerservice.ZetBelichtingSlagschaduw(belichting, item.ToonSlagschaduw);
		}

		var overrideLichtval = (this.LichtrichtingOverride[this.display.CameraNaam]);
		if (typeof (overrideLichtval) == 'undefined') {
			overrideLichtval = (this.LichtrichtingOverride["Default"]);
		}
		if (typeof (overrideLichtval) != 'undefined') {
			this.webglviewerservice.ZetBelichtingLichtval(belichting, overrideLichtval);
		}
	}

	PasBelichtingToe() {
		var item = this.LaatsteBelichtingAktie;
		if (item != null) {
			var belichting = this.webglviewerservice.MaakBelichting();
			this.PasBelichtingToeVanItem(belichting, item);
			//belichting.toonSlagschaduwVerfijnd = true;
			this.webglviewerservice.ZetBelichting(this.GeefViewer(), belichting);
			//this.GeefViewer().belichting = belichting;
		}
	}

	ZetWeerType(type) {

		var item = this.LaatsteBelichtingAktie;
		var belichting: any = this.webglviewerservice.MaakBelichting();

		if (item != null) {
			this.PasBelichtingToeVanItem(belichting, item);
		}
		else {
			belichting = this.webglviewerservice.GeefBelichting(this.GeefViewer());
			this.webglviewerservice.ZetBelichtingOmgevinglichtSterkte(belichting, 0.4);
			this.webglviewerservice.ZetBelichtingLichtbronSterkte(belichting, 0.5);
			this.webglviewerservice.ZetBelichtingLichtSchittering(belichting, 0);
			this.webglviewerservice.ZetBelichtingSlagschaduwVerzadiging(belichting, 1);
			this.webglviewerservice.ZetBelichtingSlagschaduw(belichting, true);
		}

		var belichtingBackupClone = this.webglviewerservice.KopieerBelichting(belichting);

		this.belichtingBackup = belichtingBackupClone;

		var tOmgevinglichtSterkte = this.webglviewerservice.GeefBelichtingOmgevinglichtSterkte(belichting);
		var tLichtbronSterkte = this.webglviewerservice.GeefBelichtingLichtbronSterkte(belichting);
		var tLichtSchittering = this.webglviewerservice.GeefBelichtingLichtschittering(belichting);
		var tSlagschaduwVerzadiging = this.webglviewerservice.GeefBelichtingSlagschaduwVerzadiging(belichting);

		switch (type) {
			case "Zonnig":
				this.webglviewerservice.ZetBelichtingSlagschaduw(belichting, true);
				break;
			case "LichtBewolkt":
				tOmgevinglichtSterkte = tOmgevinglichtSterkte * 0.9;
				tLichtbronSterkte = tLichtbronSterkte * 0.9;
				tLichtSchittering = tLichtSchittering * 0.9;
				tSlagschaduwVerzadiging = tSlagschaduwVerzadiging * 0.5;
				this.webglviewerservice.ZetBelichtingSlagschaduw(belichting, true);
				break;
			case "Bewolkt":
				tOmgevinglichtSterkte = tOmgevinglichtSterkte * 0.8;
				tLichtbronSterkte = tLichtbronSterkte * 0.8;
				tLichtSchittering = tLichtSchittering * 0.8;
				tSlagschaduwVerzadiging = tSlagschaduwVerzadiging * 0;
				this.webglviewerservice.ZetBelichtingSlagschaduw(belichting, false);
				break;
			default:
				break;
		}
		this.webglviewerservice.ZetBelichtingOmgevinglichtSterkte(belichting, tOmgevinglichtSterkte);
		this.webglviewerservice.ZetBelichtingLichtbronSterkte(belichting, tLichtbronSterkte);
		this.webglviewerservice.ZetBelichtingLichtSchittering(belichting, tLichtSchittering);
		this.webglviewerservice.ZetBelichtingSlagschaduwVerzadiging(belichting, tSlagschaduwVerzadiging);

		this.webglviewerservice.ZetBelichting(this.GeefViewer(), belichting);
	}

	ZetDisplay(s) {
		var indexMode = this.GeefDisplay(s);
		var oudeDisplay = this.display;
		if (oudeDisplay != indexMode) {
			this.display = indexMode;
			var viewer = this.GeefViewer();
			if (this.ooitGetoond) {
				// als het model nog nooit getoond is, dan hoeven we de oude instellingen ook niet te bewaren
				oudeDisplay.BackupCamera(viewer);

				// en worden de nieuwe vanzelf gezet
				this.display.RestoreCamera(viewer);

				this.PasBelichtingToe();
			}
		}
	}

	OnResize(drawscene: boolean) {
		var viewer = this.GeefViewer();
		if (viewer != null) {
			if (drawscene && !this.mDrawSceneOoitAangevraagd) {
				this.DrawScene();
			}
			else {
				viewer.onResize();
			}
		}
	}



	Pan(x: number, y: number) {
		var viewer = this.GeefViewer();

		//if (w.CameraIndex == 100) {
		//    sessie.Pan(-1000, 0);
		//}
		//else if (w.CameraIndex == 101) {
		//    sessie.Pan(1000, 0);
		//}
		//else if (w.CameraIndex == 102) {
		//    sessie.Pan(0, -1000);
		//}

		//else if (w.CameraIndex == 103) {
		//    sessie.Pan(0, 1000);
		//}


		var huidigCentrum = this.webglviewerservice.GeefCentrum(viewer);
		var kijkRichting = this.webglviewerservice.GeefKijkhoeken(viewer);
		var richting = kijkRichting[0] / 180 * Math.PI;

		var nieuwCentrum = [huidigCentrum[0] + Math.sin(richting) * x, huidigCentrum[1] - Math.cos(richting) * x, huidigCentrum[2] + y];

		this.webglviewerservice.ZetCentrum(viewer, nieuwCentrum);

		this.webglviewerservice.ToonModel(viewer);
	}

	Log(s) {
		//var formatted = "[" + this.NieuweFragmenten.length + "," + this.TekenActief + "," + this.pendingUpdate + "," + this.scenetype + "," + this.sceneID + "," + this.canvas + "]" + s;
		//var formatted = "[" + this.NieuweFragmenten.length + "," + this.TekenActief + "," + this.pendingUpdate + "," + this.scenetype + "," + this.sceneID + "," + this.canvas + "]" + s;

		var prefix = this.LogPrefix();
		SceneSessionLog(prefix, s);
	}

	LogPrefix() {
		//var formatted = "[" + this.NieuweFragmenten.length + "," + this.TekenActief + "," + this.pendingUpdate + "," + this.scenetype + "," + this.sceneID + "," + this.canvas + "]" + s;

		var infix = this.m_Disposed ? "DISPOSED, " : "";

		if (this.m_ViewerGeinitialiseerd) {
			var viewer = this.GeefViewer();
			if (viewer == null) {
				infix += "#ViewerNULL, ";
			}
			else {
				var lst = viewer.list();
				infix += "#ViewerFragmenten=" + lst.length + ", ";
			}
		}
		else {
			infix += "#Viewer!Initialised, ";
		}

		var prefix = "[" + infix + this.NieuweFragmenten.length + "," + this.scenetype + "," + this.canvas + ",r=" + this.m_Random + "]";

		return prefix;
	}

	SceneSessionDump(viewer, prefix) {
		SceneSessionLog(prefix, "centrum " + this.webglviewerservice.GeefCentrum(viewer));
		SceneSessionLog(prefix, "zoomschaal " + this.webglviewerservice.GeefZoomSchaal(viewer));
		SceneSessionLog(prefix, "kijkhoeken " + this.webglviewerservice.GeefKijkhoeken(viewer));
		SceneSessionLog(prefix, "kijkrichting " + this.webglviewerservice.GeefKijkrichting(viewer));
		SceneSessionLog(prefix, "Positie " + this.webglviewerservice.GeefPositie(viewer));
		SceneSessionLog(prefix, "Navigeerbaar" + this.webglviewerservice.IsNavigeerbaar(viewer));
	}

	Pauzeer(activeer) {
		this.Gepauzeerd = activeer;
		if (!this.Gepauzeerd && this.VerversUitgesteldDoorPauze) {
			this.DrawScene();
		}
	}

	Dump() {
		var viewer = this.GeefViewer();
		var prefix = this.LogPrefix();
		if (this.ooitGetoond) {
			this.SceneSessionDump(viewer, prefix);
		}
		else {
			this.Log("Model nog niet getoond");
		}
	}

	DumpViewer() {
		console.log("test");

		_.each(this.HuidigeFragmenten, (e) => {
			console.log(e.modeldata[0].FragmentId + ' => ' + e.modeldata[0].FragmentConfigKey);
		});
	}

	ActiveerSelectie(actief_Dummy: boolean) {
		var viewer = this.GeefViewer();
		viewer.selectieToestaan = true;
	}

	SelectFragmentsById(fragmentkeys: string[]) {
		var viewer = this.GeefViewer();

		var format = _.map(fragmentkeys, (f) => {
			var obj: any = {};
			obj.fragmentID = f;
			return obj;
		});
		viewer.selectie = format;
		this.webglviewerservice.ToonModel(viewer);
	}

	GeefFragmentConfigKeys(sourcekeys: any[]): string[] {
		let relist = _.map(sourcekeys, (s) => {
			let ret: string = null;
			_.each(this.HuidigeFragmenten, (e) => {
				if (e.modeldata != null && e.modeldata[0].FragmentId == s.fragmentID) {
					ret = e.modeldata[0].FragmentConfigKey;
				}
			});
			return ret;
		});

		return relist;
	}



	GeefConfigKeyFragment(s): string {
		var ret: string = null;
		_.each(this.HuidigeFragmenten, (e) => {
			if (e.modeldata[0].FragmentConfigKey == s) {
				ret = e.modeldata[0].FragmentId;
			}
		});

		return ret;
	}

	Debug(s) {
		if (configService.Software.BuildMode == 'DEBUG') {
			this.Log(s);
		}
	}

	getCanvas() {
		return this.canvas;
	}

	GeefCanvasPlaatjeWidthHeight(): WidthHeight {
		var element = $("#" + this.canvas);
		var w = element.width();
		var h = element.height();

		var w_int = parseInt(w.toString(), 10);
		var h_int = parseInt(h.toString(), 10);

		var obj: WidthHeight = new WidthHeight(w_int, h_int);
		return obj;
	}

	InternZetAchtergrondKleur(viewer: any) {
		if (this.display.CameraIsTerrein) {
			this.webglviewerservice.ZetAchtergrondkleur(viewer, this.m_KleurTerrein);
		}
		else {
			this.webglviewerservice.ZetAchtergrondkleur(viewer, this.m_KleurFirmament);
		}
	}


	private ZetKleur(kleur: string, terrein: boolean) {
		if (this.m_ViewerGeinitialiseerd) {
			var viewer = this.GeefViewer();
			if (viewer != null) {
				if (this.display.CameraIsTerrein == terrein) {
					this.webglviewerservice.ZetAchtergrondkleur(viewer, kleur);
				}
			}
		}
	}

	ZetAchtergrondKleur(terreinkleur: number[], firmamentkleur: number[]) {
		this.m_KleurTerrein = terreinkleur;
		this.m_KleurFirmament = firmamentkleur;
		if (this.m_ViewerGeinitialiseerd) {
			var viewer = this.GeefViewer();
			this.InternZetAchtergrondKleur(viewer);
		}
	}

	GeefSelectie(): any {
		return this.m_Geselecteerd;
	}

	UpdateTransformatie(fragmentid: string, index: number, deltaX: number) {
		var viewer = this.GeefViewer();

		_.forEach(this.HuidigeFragmenten, (f) => {

			if (f.modeldata != null && f.modeldata[0].FragmentId == fragmentid) {
				var transformatie = JSON.parse(f.modeldata[0].TransformatieText);

				var transmat = transformatie.transLijst.transMats[index];

				transmat[12] += deltaX;

				var transformatieText = JSON.stringify(transformatie);
				f.modeldata[0].TransformatieText = transformatieText;
				viewer.replaceTrans(transformatieText, fragmentid);
			}
		});
	}

	//NieuweFragmenten: Array<FragmentAction> = [];

	onSelectieVeranderend(nieuweSelectie, isVeroorzaaktDoorGebruiker) {
		if (!isVeroorzaaktDoorGebruiker) {
			// De selectieverandering is veroorzaakt door een aanpassing via de
			// API; ingrijpen is (in veel gevallen) niet nodig:
			return true;
		}
		for (var i = 0; i < nieuweSelectie.length; i++) {
			var selectieID = nieuweSelectie[i];
			var fragmentID = selectieID.fragmentID;
			var transLijstIndices = selectieID.transLijstIndices;
			var isGeheelGeselecteerd = (transLijstIndices == null);
			var isGedeeltelijkGeselecteerd = (transLijstIndices != null);
			// TODO
		}

		this.m_Geselecteerd = nieuweSelectie;

		return true; // return false om de nieuwe selectie te annuleren
	}


	GeefViewer(): any {
		if (this.m_Disposed) {
			return null;
		}
		if (this.webGLSupport() == false) {
			return null;
		}

		var nabewerkingen = this.BepaalWebGLOndersteuning().Nabewerkingen;

		var opties = {
			metLampen: this.metLampen,
			metNabewerkingen: nabewerkingen
		};

		var viewer = this.webglviewerservice.MaakViewer(this.canvas, opties);

		if (viewer != null && !this.m_ViewerGeinitialiseerd) {
			console.log('viewer aangemaakt');

			if (this.BepaalWebGLOndersteuning().Nabewerkingen) {
				this.webglviewerservice.ZetTekenHints(viewer, this.m_BumpmappingHint);
				this.webglviewerservice.ZetNabewerkingenDefaults(viewer, this.m_IdlePeriod);
			}

			this.webglviewerservice.ZetOrthografischeProjectie(viewer, false);

			this.m_ViewerGeinitialiseerd = true;

			if (this.m_ActiveerSelectie) {
				this.ActiveerSelectie(true);
			}

			this.webglviewerservice.ZetSelectieKleur(viewer, [50, 100, 255, 128]);

			this.webglviewerservice.ZetSelectieVeranderd(viewer, (a, b) => this.onSelectieVeranderend(a, b), () => { });

			this.webglviewerservice.ZetBerichtCallback(viewer, (canvas, type, data) => {
				console.log('BerichtCallback: ' + canvas + type + data);
				this.sceneservice.Error('BerichtCallback: ' + canvas + type + data);

				this.m_WebGLError = true;
				//this.sceneservice.WebGLError = true;
			});

			this.InternZetAchtergrondKleur(viewer);
		}

		return viewer;
	}

	Setup(startload) {
		var viewer = this.GeefViewer();

		if (viewer == null) {
			this.Log("CreateScene called, but viewer is not ready yet");
		}

		if (configService.Software.BuildMode == 'DEBUG') {
			if (viewer != null) {
				this.webglviewerservice.ZetLogEnable(viewer, true);
			}
		}

		var myobject = this;

		//var wh = this.GeefCanvasPlaatjeWidthHeight();

		let req = new CreateSceneRequestModel();
		req.projectid = this.projectservice.GeefProjectId();
		req.sceneType = this.scenetype;
		req.webgl = true;

		//var uri = '/Scene/CreateScene?sceneType=' +  + '&ProjectId=' + ;

		if (this.webGLSupport() == false) {
			req.webgl = false;
		}

		if (this.scenetype.indexOf('local_') == 0) {
			myobject.sceneID = "local";
		} else {
			var myobject = this;
			$(document).ready(() => {
				var id = 'js' + _.sampleSize('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 10).join('');;
				myobject.Log("Setup: Documentready, call CreateScene, request id " + id);
				var token = this.m_ServerTracker.MaakToken('CreateScene');

				this.drawsceneconsumer.CreateScene(req).then(r => {

					//this.$http.post(uri, null).then(d => {
					//var data: any = d.data;
					myobject.Debug("CreateScene WebService callback: " + r.SessionKey + "startload=" + startload + ", DrawGewenstNaSetup=" + myobject.DrawGewenstNaSetup + ", jsid=" + id);

					this.m_ServerTracker.VerwijderToken(token);

					if (this.m_Disposed) {
						myobject.Debug("Viewer is al disposed, exit");
						return;
					}

					myobject.sceneID = r.SessionKey;
					if (startload || myobject.DrawGewenstNaSetup) {
						myobject.Debug("Preloading DrawScene");
						var pendingOptions = this.mPendingDrawOptions;
						this.mPendingDrawOptions = null;
						myobject.DrawScene(pendingOptions);
					}
				});
			});
		}
	}

	DrawSceneMagTekenen(): boolean {
		if (this.pendingUpdate) {
			this.Log("DrawScene: Pending update, quit");
			/* Er is al een pending update */
			return false;
		}
		if (this.TekenActief) {
			this.Log("DrawScene: Teken actief, quit");
			this.pendingUpdate = true;
			this.mDrawSceneRun++;
			return false;
		}
		if (this.Gepauzeerd) {
			this.Log("DrawScene: Gepauzeerd, quit");
			this.VerversUitgesteldDoorPauze = true;
			return false;
		}
		return true;
	}

	DrawSceneDirect() {
		if (!this.DrawSceneMagTekenen()) {
			return;
		}

		if (!this.ooitGetoond) {
			this.DrawScene();
		}
		else {
			if (this.mDrawSceneGepauzeerd) {
				this.HervatDrawScene();
			}
			else {
				this.DrawSceneEinde();
			}
		}
	}

	BezigMetLadenOfInitialisatie(): boolean {
		return this.LadenBezig || this.m_ServerTracker.HeeftTokens();
	}

	HeeftContextFout(): boolean {
		return this.TekenAfgebrokenDoorContextFout;
	}

	DrawScene(options : DrawSceneOptions = null): boolean {
		if (!this.DrawSceneMagTekenen()) {
			if (options != null)
			{
				this.mPendingDrawOptions = options;
			}
			/* Mag niet tekenen, maar is wel al in een tekenproces bezig en zal dus binnenkort verwerkt worden */
			return true;
		}

		this.Log("Drawscene start");
		var viewer = this.GeefViewer();
		if (viewer == null && this.webGLSupport()) {
			this.Log("DrawScene: viewer not ready yet");
			return false;
		}

		if (this.sceneID == "Onbekend") {
			this.Log("DrawScene: scene setup is not ready yet");
			this.DrawGewenstNaSetup = true;
			if (options != null)
			{
				this.mPendingDrawOptions = options;
			}
			return true;
		}

		if (options != null)
		{
			this.mOptions = options;
		}

		this.mDrawSceneOoitAangevraagd = true;
		this.mDrawSceneGepauzeerd = false;
		this.mVerzoekPauzeer = false;
		this.mDrawSceneRun += 1;

		this.TekenActief = true;

		this.TekenAfgebrokenDoorContextFout = false;

		this.StartLaden();

		// var commandoString = '';
		// if (commando != undefined) {
		// 	commandoString = '&commando=' + commando;
		// }

		var wh: WidthHeight = this.GeefCanvasPlaatjeWidthHeight();

		this.Debug("* DrawScene, call json");
		var myobject = this;

		//HelpersBackwards.JL('SceneTypescript').info('DrawScene aangeroepen');

		if (this.sceneID == 'local')
		{
			this.DrawSceneEinde();
			return;
		}

		var token = this.m_ServerTracker.MaakToken('TekenScene');

		if (this.mOptions == null)
		{
			this.mOptions = new DrawSceneOptions();
		}

		let req = new DrawSceneRequestModel();
		req.Options = this.mOptions;
		req.Height = wh.Height;
		req.Width = wh.Width;
		req.SceneSessionID = this.sceneID;
		req.WoningSelectie = this.woningeventservice.GeefScopeSelectieWoning().Model;

		this.drawsceneconsumer.DrawScene(req).then(r => {
			//this.webberichtservice.TekenScene(this.sceneID, wh.Width, wh.Height, commandoString, (ruwedata: any) => {

			this.m_ServerTracker.VerwijderToken(token);

			if (r == null) {
				/* Fout bij aanroep */

				this.DrawSceneEindeDoorContextFout();
				return;

			}

			this.Debug("* DrawScene, json callback");
			let akties: FragmentAction[] = _.map(r.Fragmenten, (r) => new FragmentAction(r));
			//$.getJSON(uri).done((data: FragmentAction[]) => {
			myobject.Log("Received " + akties.length + " fragments");
			//Profile("JSON ontvangen");

			// var akties = _.map(data, (d) => {
			// 	return new FragmentAction(d);
			// });

			myobject.Snapshot = r.Snapshot;

			myobject.ZetVoortgangBasis(15);

			myobject.NieuweFragmenten = akties;
			myobject.TotaalFragmentenInitieel = akties.length;

			myobject.BepaalViewerAkties();

			myobject.TotaalFragmentenNaarViewer = _.filter(myobject.NieuweFragmenten, (a) => a.IsViewerAktie()).length;

			myobject.NieuweFragmentenOnberekend = -1;

			myobject.LoaderZonderNieuweMeubels = new SceneLoader(this.projectservice, myobject.sceneservice, myobject.NieuweFragmenten, myobject.$http, this, true, this.webglviewerservice, this.fatalerrorservice, this.drawsceneconsumer);
			myobject.LoaderNieuweMeubels = new SceneLoader(this.projectservice, myobject.sceneservice, myobject.MeubilairFragmenten, myobject.$http, this, false, this.webglviewerservice, this.fatalerrorservice, this.drawsceneconsumer);

			myobject.Fase = SceneSessionFase.ZonderMeubels;
			myobject.LoaderZonderNieuweMeubels.VerwerkNieuweFragmenten(this.mDrawSceneRun, this.mOptions);
		});
		//}).fail(function () {
		//		myobject.StopLaden();
		//		$("#Error3D").show();
		//	});

		this.Debug("* DrawScene end");

		return true;
	}

	StartLaden() {
		if (!this.Gepauzeerd) {
			this.ZetVoortgangBasis(10);
			this.LadenBezig = this.sceneID != 'local';
		}
		//$("#Loading3D").show();
		// HelpersBackwards.BeginWachten("");
	}

	StopLaden() {
		this.LadenBezig = (false);
		$("#Loading3D").hide();

		//HelpersMasterTypescript.MasterControllerInstance.AngularScopeApplyAlsKan();
	}



	//GeefJSON(item, callback) {
	//	this.$http.get(item.modeldata[0].FragmentUrl, (jsondata) => {
	//		var data: any = jsondata.data;
	//		callback(data);
	//	});
	//}

	m_HuidigBasisPercentage: number = 0;

	ZetVoortgang(reeks: number, huidig: number, einde: number) {
		var percentage = ((reeks) * huidig) / einde;
		var percentagetotaal: number = this.m_HuidigBasisPercentage + percentage;
		//console.log('reeks ' + reeks + ', huidig=' + huidig + ', einde=' + einde + " => " + percentage + " / " + percentagetotaal);;
		this.VoortgangPercentage = (percentagetotaal);

		//angular.apply
		//MasterViewModelInstance.AngularBroadcast("VoortgangAangepast", percentagetotaal, false);
	}

	ZetVoortgangBasis(reeks: number) {
		this.m_HuidigBasisPercentage = reeks;
		//console.log('reeks ' + reeks + ' toegevoegd => ' + this.m_HuidigBasisPercentage);
		this.VoortgangPercentage = (this.m_HuidigBasisPercentage);

		//HelpersMasterTypescript.MasterControllerInstance.AngularScopeApplyAlsKan();


	}

	PauzeerDrawScene(b: boolean) {
		this.mVerzoekPauzeer = b;
	}

	HervatDrawScene() {
		if (this.mDrawSceneGepauzeerd) {
			this.mDrawSceneGepauzeerd = false;

			//this.ZetVoortgangBasis(10);

			if (this.LoaderZonderNieuweMeubels != null) {
				this.LoaderZonderNieuweMeubels.VerwerkNieuweFragmenten(this.mDrawSceneRun);
			} else if (this.LoaderNieuweMeubels) {
				this.LoaderNieuweMeubels.VerwerkNieuweFragmenten(this.mDrawSceneRun);
			}
		}

		//this.StopLaden();
	}

	IsDrawSceneGepauzeerd() {
		return this.mDrawSceneGepauzeerd;
	}

	BepaalViewerAkties() {
		// verwijderen gaan we zelf bepalen
		this.NieuweFragmenten = _.filter(this.NieuweFragmenten, (f) => f.action != "removeMultiple");
		if (this.m_Filter.length > 0) {
			this.NieuweFragmenten = _.filter(this.NieuweFragmenten, (f) => f.modeldata == null || f.modeldata[0].FragmentConfigKey.indexOf(this.m_Filter) >= 0);
		}

		var behandeldeFragmenten: Array<FragmentAction> = [];
		_.each(this.NieuweFragmenten, (f) => {
			if (f.IsViewerAktie() || f.action == "behouden") {
				var huidige = _.find(this.HuidigeFragmenten, (h) => h.IsViewerAktie() && h.modeldata[0].FragmentId == f.modeldata[0].FragmentId);
				if (huidige == null) {
					/* toevoegen */
					f.action = "add";
				} else {
					behandeldeFragmenten.push(huidige);
					if (huidige.modeldata[0].FragmentUrlId != f.modeldata[0].FragmentUrlId) {
						f.action = "replace";
					} else if (huidige.modeldata[0].TransformatieText != f.modeldata[0].TransformatieText || huidige.modeldata[0].TransformatieURL != f.modeldata[0].TransformatieURL) {
						f.action = "replaceTrans";
					} else {
						// niks doen, behouden
						f.action = "behouden";
					}
				}
			}
		});

		var verwijderaktie: FragmentAction = new FragmentAction(null);
		verwijderaktie.action = "removeMultiple";
		verwijderaktie.modeldata = [];

		_.each(this.HuidigeFragmenten, (h) => {
			var bestaat = _.includes(behandeldeFragmenten, h);
			if (!bestaat) {
				var modeldata: FragmentModelData = new FragmentModelData();
				modeldata.FragmentId = h.modeldata[0].FragmentId;
				verwijderaktie.modeldata.push(modeldata);
			}
		});

		if (verwijderaktie.modeldata.length > 0) {
			this.NieuweFragmenten.push(verwijderaktie);
		}

		/* Meubilair dat toegevoegd moet worden zetten we in een 2e lijst */
		this.MeubilairFragmenten = _.filter(this.NieuweFragmenten, (n) => n.IsNieuwMeubilair());
		this.NieuweFragmenten = _.filter(this.NieuweFragmenten, (n) => !n.IsNieuwMeubilair());
	}



	// NotifyAngular() {
	// 	HelpersMasterTypescript.MasterControllerInstance.AngularScopeApply();
	// }

	VerwerkOverigFragmentAktie(item: FragmentAction) {
		if (this.Fase == SceneSessionFase.MetMeubels) {
			/* We zijn klaar met meubels laden -- geen camera acties meer uitvoeren*/
			return;
		}

		if (item.action == "image") {
			this.Log(" Viewer image " + item.modeldata[0].FragmentUrl);
			this.Image = item.modeldata[0].FragmentUrl;

		} else if (item.action == "licht") {
			this.LaatsteBelichtingAktie = item;
			this.PasBelichtingToe();
			this.Log(" Viewer belichting");
		} else if (item.action == "camera") {
			var disp = this.GeefDisplay(item.DisplayMode);

			disp.CameraRichtingIs3D = item.CameraRichtingIs3D;
			if (item.CameraRichtingIs3D) {
				disp.CameraRichting3D = item.CameraRichting3D;
			}
			else {
				disp.CameraRichting = item.CameraRichting;
				disp.CameraHelling = item.CameraHelling;
			}
			disp.TerreinCameraPositie = item.CameraPositie;

			this.Log("Camera Positie=" + item.CameraPositie + ", " + item.DisplayMode + ", " + item.CameraNaam);

			disp.DomeAfbeelding = item.CameraDomeAfbeelding;

			if (item.CameraZoomSchaal != 0) {
				if (disp.CameraIsTerrein) {
					disp.CameraZoomSchaal = item.CameraZoomSchaal;
				}
			}

			if (item.CameraCentrum != undefined) {
				disp.FirmamentCameraCentrum = item.CameraCentrum;
				disp.FirmamentCameraCentrumHellingRichting = undefined;
			}

			//this.display.CameraRichting = item.CameraRichting;
			//this.display.CameraHelling = item.CameraHelling;
			this.Log("CAMERA richting=" + item.CameraRichting + ", helling=" + item.CameraHelling);

			if (disp == this.display && disp.CameraNaam == item.CameraNaam) {
				this.Log("CameraStandpunt is al actief: wijzig huidige camera instellingen niet");
				// forceer geen animatie
			}
			else {
				this.Log("Animatie nodig (oude camera=" + this.display.CameraNaam + ", nieuwe=" + item.CameraNaam + ")");
				this.sceneCameraAnimatieNodig = true;
				this.laatsteCameraDisplay = item.DisplayMode;
			}
			disp.CameraNaam = item.CameraNaam;


		} else if (item.action == "centrum") {
			this.display.CameraRichting = item.CameraRichting;
			this.display.CameraHelling = item.CameraHelling;
			this.display.FirmamentCameraCentrum = item.CameraCentrum;
			this.display.CameraZoomSchaal = item.CameraZoomSchaal;
			this.Log("CENTRUM richting=" + item.CameraRichting + ", helling=" + item.CameraHelling + ", centrum=" + item.CameraCentrum);
			this.sceneCameraAnimatieNodig = true;
		} else if (item.action == "zoom") {
			this.zoom = item.Zoom;
		} else if (item.action == "behouden") {
			/* skip */
		} else if (item.action == 'geensession') {
			if (!this.m_Destroyed) {
				this.fatalerrorservice.ReloadPage(null, "Scene geen sessie 2");
			}
		} else {
			this.Debug(" ** Onbekend type aktie " + item.action);
		}
	}

	DrawSceneEindeDoorContextFout() {
		this.mDrawSceneRun++; /* we hogen direct het run nummer op */
		this.Debug("DrawSceneEindeDoorContextFout");
		this.TekenActief = false;
		this.TekenAfgebrokenDoorContextFout = true;
		this.pendingUpdate = false;
	}

	DrawSceneEindeDoorSessieKwijt() {
		this.mDrawSceneRun++; /* we hogen direct het run nummer op */
		this.Debug("DrawSceneEindeDoorSessieKwijt");
		this.TekenActief = false;
		this.TekenAfgebrokenDoorContextFout = true;
		this.pendingUpdate = false;
	}

	DrawSceneEinde() {
		this.Debug("DrawSceneEindeFinish");

		if (this.Fase == SceneSessionFase.ZonderMeubels) {
			/* we hebben casco verwerkt -- nu de camera updaten */

			this.TekenActief = false;
			var viewer = this.GeefViewer();

			this.ZetVoortgangBasis(90);

			//Profile("Toon model");
			if (this.mForceerCameraReset) {
				this.ooitGetoond = false;
				this.Debug('Forceer camera reset');
			}

			console.log('Current display ' + this.display.CameraNaam);

			// op dit moment moet er wel een model aanwezig zijn
			if (!this.ooitGetoond) {
				if (this.mForceerCameraReset) {

				}

				this.display.RestoreCamera(viewer);
				this.PasBelichtingToe();
			}
			this.mForceerCameraReset = false;

			this.Debug("Toon model");
		}

		var casco = this.Fase == SceneSessionFase.ZonderMeubels;
		this.ToonModelInclusiefDome(casco, () => { if (casco) this.DrawSceneEindePostToonModel() });

		//if (this.LoaderZonderNieuweMeubels != null)
		//{
		//	this.LoaderZonderNieuweMeubels = null;
		//	this.Fase = SceneSessionFase.MetMeubels;

		//	this.LoaderNieuweMeubels.VerwerkNieuweFragmenten();
		//}
	}

	DrawSceneEindePostToonModel() {
		var viewer = this.GeefViewer();
		this.Log("Ooit getoond: " + this.ooitGetoond);

		if (this.mAssen != this.mAssenIsAan) {
			viewer.toonAssen = this.mAssen;
			this.mAssenIsAan = this.mAssen;
		}

		if (this.ooitGetoond == false) {
			this.sceneCameraAnimatieNodig = false;
		}



		if (this.sceneCameraAnimatieNodig) {
			if (this.volgCameraType && this.display.Naam != this.laatsteCameraDisplay) {
				this.Log("Volg camera type, switch naar " + this.laatsteCameraDisplay);
				this.ZetDisplay(this.laatsteCameraDisplay);
				this.ToonModelInclusiefDome(true, null);
				this.sceneCameraAnimatieNodig = false;
			}
			else {
				this.Log("Start animatie (sceneCameraAnimatieNodig)");
				globalScene.AnimatieObjecten[this.canvas] = this;
				if (viewer != null) {
					if (this.display.CameraIsTerrein) {
						viewer.animatieCallback = this.sceneViewerAnimatieTerreinFunctie;
					}
					else {
						viewer.animatieCallback = this.sceneViewerAnimatieFunctie;
					}
					viewer.animatie = true;
				}
				this.sceneCameraAnimatieNodig = false;
			}
		}

		this.ooitGetoond = true;

		if (this.pendingUpdate) {
			this.Debug("DrawSceneEindeFinish: Pending update");
			this.pendingUpdate = false;
			this.mPendingDrawOptions = null;
			this.DrawScene(this.mPendingDrawOptions);
		}

		this.mVerzoekPauzeer = false;
		this.mDrawSceneGepauzeerd = false;

		if (this.ModelGetekendCallback != null) {
			this.ModelGetekendCallback();
		}

		if (this.m_DrawSceneFinished != null) {
			this.m_DrawSceneFinished();
		}

		this.StopLaden();
	}


	ToonModelInclusiefDome(rapportage: boolean, callback: () => void) {
		this.Debug("ToonModelInclusiefDome");

		var viewer = this.GeefViewer();

		if (viewer == null && !this.webGLSupport()) {
			this.ToonModel(rapportage);
			if (callback != null) {
				callback();
			}
			return;
		}

		this.InternZetAchtergrondKleur(viewer);

		var dome = false;
		if (this.display.CameraIsTerrein) {
			this.Log('camera is terrein');
			var afbeelding = this.display.DomeAfbeelding;
			if (afbeelding != undefined) {
				this.Log('wel dome');
				dome = true;
			}
			else {
				this.Log('geen dome');
			}
		}

		if (dome) {
			//if (typeof BekijkPaginaInstance != "undefined") {
			//	viewer.achtergrondKleur = BekijkPaginaInstance.BekijkAchtergrondkleurArrayDome;
			//}
			viewer.zetHemel(this.display.DomeAfbeelding, 91, null, () => {
				if (rapportage) {
					this.ZetVoortgangBasis(100);
				}
				this.ToonModel(rapportage);
				if (callback != null) {
					callback();
				}
			});
		}
		else {
			//if (typeof BekijkPaginaInstance != "undefined") {
			//	viewer.achtergrondKleur = BekijkPaginaInstance.BekijkAchtergrondkleurArray;
			//}
			if (rapportage) {
				this.ZetVoortgangBasis(100);
			}
			this.ToonModel(rapportage);
			if (callback != null) {
				callback();
			}
		}
	}

	ToonModel(rapportage: boolean) {
		if (!this.webGLSupport()) {
			var image = new Image();
			var myobject = this;
			image.onload = function () {
				var canvasElement: any = document.getElementById(myobject.canvas);
				var context = canvasElement.getContext("2d");
				context.drawImage(image, 0, 0)
			}
			image.src = this.Image;
			return;
		}
		var viewer = this.GeefViewer();

		this.display.PreToonModel(viewer);

		if (rapportage) {
			this.ZetVoortgangBasis(100);
		}

		var uri = '../Content/GeneratedImages/PERIMETER.txt';

		var perimeter = null;

		//if (Perimeterviewer.PerimeterBestaat(uri) != false) {

		//   console.log("Perimeter bestaat wel");

		//   Perimeterviewer.GeefPerimeterJSON(uri, function (text) {
		//      //var parsedJSON = $.parseJSON(text);
		//      var parsedJSON = JSON.parse(text);
		//      var stringified = JSON.stringify(parsedJSON);

		//      perimeter = new ModuleViewer3D.Perimeter(stringified);
		//      viewer.wandelRestrictie = perimeter;

		//      //
		//      // Parsing per sub item wordt niet meer gedaan. Perimeter bestaat uit ��n geheel.
		//      //
		//      //for (var key in parsedJSON) {
		//      //   if (parsedJSON.hasOwnProperty(key)) {
		//      //      var val = parsedJSON[key];
		//      //      console.log(val);

		//      //      for (var subItem in parsedJSON[key]) {
		//      //         console.log(parsedJSON[key][subItem].key);
		//      //         console.log(parsedJSON[key][subItem].data);

		//      //         perimeter = new ModuleViewer3D.Perimeter(parsedJSON[key][subItem].data);
		//      //         viewer.wandelRestrictie = perimeter;
		//      //      }
		//      //   }
		//      //}
		//   });
		//}
		//else {
		//   console.log("Perimeter bestaat niet");
		//};

		this.webglviewerservice.ToonModel(viewer);

		var divElementViewer = $("#" + this.canvas);
		$(divElementViewer).show();

		this.display.PostToonModel(viewer);
	}

	mijnTestAnimatie(canvas, deltaTijd, totaleTijd) {
		var animatieViewer = mijnTestAnimatieViewer;
		if (totaleTijd == 0) {
			if (animatieViewer.camera != true) {
				animatieViewer.camera = true;
			}
			animatieViewer.navigeerbaar = false;
			mijnTestAnimatieTotaalTijd = 0;
			mijnTestAnimatieTotaalAantal = 0;
		}
		else {
			mijnTestAnimatieTotaalTijd = mijnTestAnimatieTotaalTijd + deltaTijd;
			mijnTestAnimatieTotaalAantal = mijnTestAnimatieTotaalAantal + 1;

			var kijkrichting = animatieViewer.kijkhoeken;
			kijkrichting = [kijkrichting[0] + 10, kijkrichting[1]]
			animatieViewer.kijkhoeken = kijkrichting;
		}

		//console.log("Animeer " + deltaTijd + "ms, gemiddeld=" + mijnTestAnimatieTotaalTijd / mijnTestAnimatieTotaalAantal + " kijkrichting=" + kijkrichting[0]);
	}

	ZetFilter(s: string) {
		this.m_Filter = s;
	}

	Animeer(deltaTijd, totaleTijd) {
		//this.Log("Animeer " + deltaTijd + "/" + totaleTijd);
		var animatieViewer = this.GeefViewer();
		if (totaleTijd == 0) {
			if (animatieViewer.camera != true) {
				// De huidige camera is geen firmamentcamera:
				animatieViewer.animatie = false;
				return;
			}

			// Initieer de animatie:
			this.sceneAnimatieData = {};
			this.sceneAnimatieData.wasNavigeerbaar = animatieViewer.navigeerbaar;

			animatieViewer.navigeerbaar = false;

			this.sceneAnimatieData.snelheidConstant = false; // input: optie voor constante of kwadratische snelheid
			this.sceneAnimatieData.duur = 2000; // input: animatie duurt 2 s
			this.sceneAnimatieData.duur3 = (this.sceneAnimatieData.duur * this.sceneAnimatieData.duur * this.sceneAnimatieData.duur);

			// Beginsituatie:
			this.sceneAnimatieData.beginKijkrichting = animatieViewer.kijkhoeken;
			var beginRichting = this.sceneAnimatieData.beginKijkrichting[0];
			var beginHelling = this.sceneAnimatieData.beginKijkrichting[1];
			this.sceneAnimatieData.beginZoomSchaal = animatieViewer.zoomSchaal;
			this.sceneAnimatieData.beginCentrum = animatieViewer.centrum;

			// Eindsituatie:
			this.sceneAnimatieData.eindKijkrichting = [this.display.CameraRichting, this.display.CameraHelling];
			animatieViewer.kijkhoeken = this.sceneAnimatieData.eindKijkrichting;
			// animatieViewer.zetZoomGeheel(); // aanname: de uiteindelijke zoom wordt door zetZoomGeheel bepaald

			this.sceneAnimatieData.eindZoomSchaal = animatieViewer.zoomSchaal;
			this.sceneAnimatieData.eindCentrum = animatieViewer.centrum;
			if (this.display.FirmamentCameraCentrum) {
				this.sceneAnimatieData.eindCentrum = this.display.FirmamentCameraCentrum;
			}

			// Verschil tussen begin- en eindsituaties:
			var verschilRichting = this.display.CameraRichting - beginRichting;
			if (beginRichting < this.display.CameraRichting) {
				if (verschilRichting > 180.0) {
					verschilRichting = verschilRichting - 360.0;
				}
			}
			else {
				if (verschilRichting < -180.0) {
					verschilRichting = verschilRichting + 360.0;
				}
			}
			var verschilHelling = this.display.CameraHelling - beginHelling;
			if (beginHelling < this.display.CameraHelling) {
				if (verschilHelling > 180.0) {
					verschilHelling = verschilHelling - 360.0;
				}
			}
			else {
				if (verschilHelling < -180.0) {
					verschilHelling = verschilHelling + 360.0;
				}
			}
			this.sceneAnimatieData.verschilKijkrichting = [verschilRichting, verschilHelling];
			this.sceneAnimatieData.verschilZoomSchaal = this.sceneAnimatieData.eindZoomSchaal - this.sceneAnimatieData.beginZoomSchaal;
			this.sceneAnimatieData.verschilCentrum = [this.sceneAnimatieData.eindCentrum[0] - this.sceneAnimatieData.beginCentrum[0], this.sceneAnimatieData.eindCentrum[1] - this.sceneAnimatieData.beginCentrum[1], this.sceneAnimatieData.eindCentrum[2] - this.sceneAnimatieData.beginCentrum[2]];

		}

		if (this.sceneAnimatieData != undefined && this.sceneAnimatieData != null && totaleTijd < this.sceneAnimatieData.duur) {
			var voortgang;
			if (this.sceneAnimatieData.snelheidConstant === true) {
				// Constante snelheid:
				voortgang = totaleTijd / this.sceneAnimatieData.duur;
			}
			else {
				// Kwadratische snelheid:
				voortgang = totaleTijd * totaleTijd * (3.0 * this.sceneAnimatieData.duur - 2.0 * totaleTijd) / this.sceneAnimatieData.duur3;
			}

			var voortgangKijkrichting = [this.sceneAnimatieData.beginKijkrichting[0] + this.sceneAnimatieData.verschilKijkrichting[0] * voortgang,
			this.sceneAnimatieData.beginKijkrichting[1] + this.sceneAnimatieData.verschilKijkrichting[1] * voortgang];
			var voortgangZoomSchaal = this.sceneAnimatieData.beginZoomSchaal + this.sceneAnimatieData.verschilZoomSchaal * voortgang;
			var voortgangCentrum = [this.sceneAnimatieData.beginCentrum[0] + this.sceneAnimatieData.verschilCentrum[0] * voortgang,
			this.sceneAnimatieData.beginCentrum[1] + this.sceneAnimatieData.verschilCentrum[1] * voortgang,
			this.sceneAnimatieData.beginCentrum[2] + this.sceneAnimatieData.verschilCentrum[2] * voortgang];

			animatieViewer.kijkhoeken = voortgangKijkrichting;
			animatieViewer.zoomSchaal = voortgangZoomSchaal;

			//this.Log("Bezig met animatie. Zoomschaal: " + voortgangZoomSchaal);

			SceneSessionLog("VIEWERCMD", "set centrum " + voortgangCentrum);
			animatieViewer.centrum = voortgangCentrum;
		}
		else {
			this.Log("Beeindig de animatie");

			if (animatieViewer != null) {
				// Beeindig de animatie:
				animatieViewer.kijkhoeken = this.sceneAnimatieData.eindKijkrichting;
				animatieViewer.zoomSchaal = this.sceneAnimatieData.eindZoomSchaal;

				this.Log("Einde animatie. Zoomschaal: " + this.sceneAnimatieData.eindZoomSchaal);

				animatieViewer.centrum = this.sceneAnimatieData.eindCentrum;

				animatieViewer.animatie = false;
				animatieViewer.navigeerbaar = true;
			}
			this.sceneAnimatieData = undefined;
		}

		if (this.TekenActief) {
			animatieViewer.animatie = false;
			animatieViewer.navigeerbaar = true;
			this.sceneAnimatieData = undefined;
		}
	}




	AnimeerTerrein(deltaTijd, totaleTijd) {
		var animatieViewer = this.GeefViewer();
		if (totaleTijd == 0) {
			if (animatieViewer.camera != false) {
				// De huidige camera is geen terrein :
				animatieViewer.animatie = false;
				return;
			}

			this.setupAnimatie(animatieViewer);
		}

		if (!this.TerreinAnimatieDirect && totaleTijd < this.sceneAnimatieData.duur) {
			const t = totaleTijd / this.sceneAnimatieData.duur;

			animatieViewer.positie = this.sceneAnimatieData.positieFunctie(t);
			animatieViewer.kijkhoeken = this.sceneAnimatieData.kijkrichtingFunctie(t);
			animatieViewer.zoomSchaal = BezierCurve.Blend(t, this.sceneAnimatieData.eindZoomSchaal, this.sceneAnimatieData.beginZoomSchaal);
		}
		else {
			animatieViewer.positie = this.sceneAnimatieData.positieFunctie(1);
			animatieViewer.kijkhoeken = this.sceneAnimatieData.kijkrichtingFunctie(1);
			animatieViewer.zoomSchaal = BezierCurve.Blend(1, this.sceneAnimatieData.eindZoomSchaal, this.sceneAnimatieData.beginZoomSchaal);

			//animatieViewer.kijkrichting = this.sceneAnimatieData.eindKijkrichting3D;
			//animatieViewer.zoomSchaal = this.sceneAnimatieData.eindZoomSchaal;
			//animatieViewer.positie = this.sceneAnimatieData.eindPositie;

			animatieViewer.animatie = false;
			animatieViewer.navigeerbaar = true;
			this.sceneAnimatieData = undefined;
		}
	}

	private setupAnimatie(animatieViewer: any): void {
		this.sceneAnimatieData = new Object();
		this.sceneAnimatieData.wasNavigeerbaar = animatieViewer.navigeerbaar;
		animatieViewer.navigeerbaar = false;

		this.setupBeginSitutatie(animatieViewer);
		this.setupEindSitutatie(animatieViewer);
		this.setupPositieEnKijkrichtingAnimatie();
	}

	private setupBeginSitutatie(animatieViewer: any): void {
		const startRichting3D = animatieViewer.kijkrichting;
		const startHoek = Math.atan2(startRichting3D[1], startRichting3D[0]) * 180 / Math.PI;
		const startHelling = -Math.atan2(startRichting3D[2], Math.sqrt(Math.pow(startRichting3D[0], 2) + Math.pow(startRichting3D[1], 2))) * 180 / Math.PI;
		this.sceneAnimatieData.beginPositie = animatieViewer.positie;
		this.sceneAnimatieData.beginKijkrichting = [startHoek, startHelling];
		this.sceneAnimatieData.beginZoomSchaal = animatieViewer.zoomSchaal;
	}

	private setupEindSitutatie(animatieViewer: any): void {
		const eindRichting3D = this.display.CameraRichting3D;
		const eindHoek = Math.atan2(eindRichting3D[1], eindRichting3D[0]) * 180 / Math.PI;
		const eindHelling = -Math.atan2(eindRichting3D[2], Math.sqrt(Math.pow(eindRichting3D[0], 2) + Math.pow(eindRichting3D[1], 2))) * 180 / Math.PI;
		this.sceneAnimatieData.eindPositie = this.display.TerreinCameraPositie;
		this.sceneAnimatieData.eindKijkrichting = [eindHoek, eindHelling];
		this.sceneAnimatieData.eindZoomSchaal = animatieViewer.zoomSchaal;
	}

	private setupPositieEnKijkrichtingAnimatie(): void {
		this.sceneAnimatieData.duur = 2000;
		const midPoint = 0.5;
		const midpointSlowdown = 0.8;
		const easeFunction = this.bicubicEasingWithSlowdownAtHalfwayPoint(midPoint, midpointSlowdown);
		const verticalMidPointOffset = 5000;
		const halfwayPointHandleOffset = 0.8;

		this.setupPositieAnimatie(this.sceneAnimatieData.beginPositie, this.sceneAnimatieData.eindPositie, verticalMidPointOffset, midPoint, halfwayPointHandleOffset, easeFunction);
		this.setupKijkrichtingAnimatie(this.sceneAnimatieData.beginKijkrichting, this.sceneAnimatieData.eindKijkrichting, midPoint, easeFunction);
	}

	private bicubicEasingWithSlowdownAtHalfwayPoint(midPoint: number, midpointSlowdown: number): (number) => number {
		return (t: number) => {
			const cubic = BezierCurve.EaseCubic(t);
			const doubleCubic = t < midPoint
				? BezierCurve.EaseCubic(t / midPoint) * midPoint
				: BezierCurve.EaseCubic((t - midPoint) / (1 - midPoint)) * (1 - midPoint) + midPoint;
			return BezierCurve.Blend(1 - midpointSlowdown, cubic, doubleCubic);
		};
	}

	private setupPositieAnimatie(startPoint: number[], endPoint: number[], verticalMidPointOffset: number, midPoint: number, halfwayPointHandleOffset: number, easeFunction: (number) => number): void {
		const halfwayPoint = BezierCurve.Sum([BezierCurve.Multiply(startPoint, 0.5), BezierCurve.Multiply(endPoint, 0.5), [0, 0, verticalMidPointOffset]]);
		const upHandles = [
			startPoint,
			BezierCurve.Add(startPoint, [0, 0, verticalMidPointOffset / 2]),
			BezierCurve.Add(halfwayPoint, [(startPoint[0] - halfwayPoint[0]) * halfwayPointHandleOffset, (startPoint[1] - halfwayPoint[1]) * halfwayPointHandleOffset, 0]),
			halfwayPoint
		];
		const downHandles = [
			halfwayPoint,
			BezierCurve.Add(halfwayPoint, [(endPoint[0] - halfwayPoint[0]) * halfwayPointHandleOffset, (endPoint[1] - halfwayPoint[1]) * halfwayPointHandleOffset, 0]),
			BezierCurve.Add(endPoint, [0, 0, verticalMidPointOffset / 2]),
			endPoint
		];

		this.sceneAnimatieData.positieFunctie = this.animatieFunctie(upHandles, downHandles, midPoint, easeFunction);
	}

	private setupKijkrichtingAnimatie(startPoint: number[], endPoint: number[], midPoint: number, easeFunction: (number) => number): void {

		//TODO: Zorgen dat de draaiing altijd de kortste route pakt (in principe door te zorgen dat het verschil tussen de hoeken nooit meer is dan 180 graden)
		const halfwayPoint = [BezierCurve.Blend(0.5, startPoint[0], endPoint[0]), 89];

		const upHandles = [
			startPoint,
			BezierCurve.BlendPoints(0.8, halfwayPoint, startPoint),
			BezierCurve.BlendPoints(0.9, halfwayPoint, startPoint),
			halfwayPoint
		];
		const downHandles = [
			halfwayPoint,
			BezierCurve.BlendPoints(0.9, halfwayPoint, endPoint),
			BezierCurve.BlendPoints(0.8, halfwayPoint, endPoint),
			endPoint
		];

		this.sceneAnimatieData.kijkrichtingFunctie = this.animatieFunctie(upHandles, downHandles, midPoint, easeFunction);
	}

	private animatieFunctie(upHandles: number[][], downHandles: number[][], midPoint: number, easeFunction: (number) => number) {
		return (t: number) => {
			return t < midPoint
				? BezierCurve.Bezier(upHandles[0], upHandles[1], upHandles[2], upHandles[3], easeFunction(t) / easeFunction(midPoint))
				: BezierCurve.Bezier(downHandles[0], downHandles[1], downHandles[2], downHandles[3], (easeFunction(t) - easeFunction(midPoint)) / (1 - easeFunction(midPoint)));
		}
	}

	ZetLichtval(d) {
		var belichting = this.GeefViewer().belichting;
		belichting.lichtval = d;
		this.webglviewerservice.ToonModel(this.GeefViewer());
	}


	scenenumto2digit(d) {
		return ('0' + d).slice(-2);
	}


	StopDagVerloop() {
		var viewer = this.GeefViewer();
		if (viewer != null) {
			viewer.animatie = false;
			viewer.navigeerbaar = true;
			this.sceneAnimatieData = undefined;

			$("#zonnetijd-span").html('');
		}
	}



	AnimeerDagVerloop(deltaTijd, totaleTijd) {
		this.Log("AnimeerDagVerloop " + deltaTijd + "/" + totaleTijd);

		var animatieViewer = this.GeefViewer();
		if (totaleTijd == 0) {
			// Initieer de animatie:
			this.sceneAnimatieData = new Object();
			this.sceneAnimatieData.wasNavigeerbaar = animatieViewer.navigeerbaar;

			animatieViewer.navigeerbaar = false;
		}

		var stap = Math.floor((totaleTijd / this.SceneZonneSnelheid));

		var stap1 = stap + 1;
		var substap = (totaleTijd % this.SceneZonneSnelheid);

		var percandere = (substap * 1.0) / (1.0 * this.SceneZonneSnelheid);
		var perc = (1.0 - percandere);
		//this.Log("Stap " + stap + ", substap=" + substap + ", perc=" + perc + ", percandere=" + percandere);
		var maxStappen = this.SceneZonneData.ZonneRichtingen.length;
		if (stap1 < maxStappen) {
			var huidigeStap = this.SceneZonneData.ZonneRichtingen[stap].richting;
			var volgendeStap = this.SceneZonneData.ZonneRichtingen[stap1].richting;
			var lichtval = [((perc * huidigeStap[0]) + (percandere * volgendeStap[0])), ((perc * huidigeStap[1]) + (percandere * volgendeStap[1])), ((perc * huidigeStap[2]) + (percandere * volgendeStap[2]))];
			//this.Log("huidigestap=" + huidigeStap + ", volgendestap=" + volgendeStap + ", lichtval=" + lichtval);
			var belichting = animatieViewer.belichting;
			belichting.lichtval = lichtval;

			this.ZonneTijd = this.scenenumto2digit(this.SceneZonneData.ZonneRichtingen[stap].Uur) + ":" + this.scenenumto2digit(this.SceneZonneData.ZonneRichtingen[stap].Minuut);

			//HelpersMasterTypescript.MasterControllerInstance.AngularScopeApply();

			$(".zonnetijd-span").html(this.scenenumto2digit(this.SceneZonneData.ZonneRichtingen[stap].Uur) + ":" + this.scenenumto2digit(this.SceneZonneData.ZonneRichtingen[stap].Minuut) + " uur");
			$("#TijdDisplayDiv").show();
			$("#TijdDisplay").html(this.scenenumto2digit(this.SceneZonneData.ZonneRichtingen[stap].Uur) + ":" + this.scenenumto2digit(this.SceneZonneData.ZonneRichtingen[stap].Minuut) + " uur");
		}
		else {
			this.Log("Beeindig de animatie");
			// Beeindig de animatie:
			animatieViewer.animatie = false;
			animatieViewer.navigeerbaar = true;
			this.sceneAnimatieData = undefined;

			this.ZonneTijd = "";

			$("#zonnetijd-span").html('');
			$("#TijdDisplay").html("");
			$("#TijdDisplayDiv").hide();

			//HelpersMasterTypescript.MasterControllerInstance.AngularScopeApply();

			this.ZonneTijd = "";

			if (this.SceneZonneEinde != null) {
				this.SceneZonneEinde();
				this.SceneZonneEinde = null;
			}

			//BekijkViewModelInstance.ZonDialoog().StopDagverloop();

			animatieViewer.belichting = this.belichtingBackup;
			this.webglviewerservice.ToonModel(animatieViewer);
		}
	}

	// Animatie-callback voor een geanimeerde positionering van een firmamentcamera binnen een bepaalde tijd.
	sceneViewerAnimatieFunctie(animatieCanvas, deltaTijd, totaleTijd) {
		// we hebben niet de juiste 'this', dus die moeten we nog opzoeken.
		var myobject = globalScene.AnimatieObjecten[animatieCanvas.id];
		myobject.Animeer(deltaTijd, totaleTijd);
	}

	// Animatie-callback voor een geanimeerde positionering van een firmamentcamera binnen een bepaalde tijd.
	sceneViewerAnimatieTerreinFunctie(animatieCanvas, deltaTijd, totaleTijd) {
		// we hebben niet de juiste 'this', dus die moeten we nog opzoeken.
		var myobject = globalScene.AnimatieObjecten[animatieCanvas.id];
		myobject.AnimeerTerrein(deltaTijd, totaleTijd);
	}

	// Animatie-callback voor een geanimeerde positionering van een firmamentcamera binnen een bepaalde tijd.
	sceneViewerAnimatieDagVerloopFunctie(animatieCanvas, deltaTijd, totaleTijd) {
		// we hebben niet de juiste 'this', dus die moeten we nog opzoeken.
		var myobject = globalScene.AnimatieObjecten[animatieCanvas.id];
		myobject.AnimeerDagVerloop(deltaTijd, totaleTijd);
	}

	StartDagVerloop(data, snelheid, einde: () => void) {
		globalScene.AnimatieObjecten[this.canvas] = this;
		this.SceneZonneData = data;
		this.SceneZonneSnelheid = snelheid;
		this.SceneZonneEinde = einde;

		var viewer = this.GeefViewer();
		if (viewer != null) {
			viewer.animatieCallback = this.sceneViewerAnimatieDagVerloopFunctie;
			viewer.animatie = true;
		}
	}

	ZetZichtStandaardEnRedraw() {
		var mijnViewer = this.GeefViewer();
		if (mijnViewer != null) {
			SceneSessionLog("VIEWERCMD", "zetZichtStandaard");
			this.webglviewerservice.ZetZichtStandaard(mijnViewer);
			SceneSessionLog("VIEWERCMD", "toonModel");
			this.webglviewerservice.ToonModel(mijnViewer);
		}

		//this.UpdateWidthHeightVoorStatischPlaatje();
	}

	ZetZoomGeheelZichtStandaardEnRedraw() {
		var mijnViewer = this.GeefViewer();
		if (mijnViewer != null) {
			SceneSessionLog("VIEWERCMD", "zetZichtStandaard");
			//mijnViewer.zetZichtStandaard();
			this.webglviewerservice.ZetZichtStandaard(mijnViewer);
			SceneSessionLog("VIEWERCMD", "zetZoomGeheel");
			this.webglviewerservice.ZetZoomGeheel(mijnViewer);
			//mijnViewer.zetZoomGeheel();
			SceneSessionLog("VIEWERCMD", "toonModel");
			this.webglviewerservice.ToonModel(mijnViewer);
			//mijnViewer.toonModel();
		}

		//this.UpdateWidthHeightVoorStatischPlaatje();
	}

	SetCamera(firmament) {
		var viewer = this.GeefViewer();
		if (viewer == null) {
			this.Log("SetCamera: viewer not ready yet");
			return;
		}

		SceneSessionLog("VIEWERCMD", "set camera " + firmament);
		viewer.camera = firmament;
	}


	TestAnimatie() {
		mijnTestAnimatieViewer = this.GeefViewer();
		if (mijnTestAnimatieViewer != null) {
			mijnTestAnimatieViewer.animatieCallback = this.mijnTestAnimatie;
			mijnTestAnimatieViewer.animatie = true;
		}
	}
}

export class BezierCurve {
	public static Bezier(p1: number[], p2: number[], p3: number[], p4: number[], t: number): number[] {
		var p = BezierCurve.Sum([BezierCurve.Multiply(p1, Math.pow(1 - t, 3)),
		BezierCurve.Multiply(p2, 3 * (t - 2 * Math.pow(t, 2) + Math.pow(t, 3))),
		BezierCurve.Multiply(p3, 3 * (Math.pow(t, 2) - Math.pow(t, 3))),
		BezierCurve.Multiply(p4, Math.pow(t, 3))
		]);
		return p;
	}

	public static Multiply(point: number[], amount: number): number[] {
		return point.map(coord => coord * amount);
	}

	public static Add(point1: number[], point2: number[]): number[] {
		return point1.map((coord, i) => coord + point2[i]);
	}

	public static Sum(points: number[][]): number[] {
		if (points.length == 0) {
			return undefined;
		}

		return points.slice(1).reduce((p1, p2) => BezierCurve.Add(p1, p2), points[0]);
	}

	public static EaseCubic(t: number): number {
		//Gekopieerd van https://github.com/d3/d3-ease/blob/master/src/cubic.js
		return ((t *= 2) <= 1 ? t * t * t : (t -= 2) * t * t + 2) / 2;
	}

	public static Blend(amount: number, influence: number, base: number): number {
		return amount * influence + (1 - amount) * base;
	}

	public static BlendPoints(amount: number, influence: number[], base: number[]): number[] {
		return BezierCurve.Add(BezierCurve.Multiply(influence, amount), BezierCurve.Multiply(base, 1 - amount));
	}
}


