import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { WoningEventService } from '@services/WoningEventService';
import { WoningService } from '@services/WoningService';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Woning } from 'src/app/Models/Woning';

@Component({
  selector: 'wct-dwelling-selector',
  templateUrl: './dwelling-selector.component.html',
  styleUrls: ['./dwelling-selector.component.scss']
})
export class DwellingSelectorComponent implements OnInit {
  currentDwellingId: any;
  dwellings: Woning[];
  Subsciption: Subscription;
  
  constructor(private woningservice: WoningService,
		private woningEventService: WoningEventService) { }

  ngOnInit(): void {
    this.currentDwellingId = this.woningEventService.GeefHuidigeWoningID();

		this.woningservice.GeefWoningen(false).then(r => {
			let values = _.map(r, (v) => v);
			values = values.sort((a,b) =>
			{
				if (a.AdresStraat != b.AdresStraat)
				{
					if (a.AdresStraat > b.AdresStraat)
					{
						return 1;
					}
					return -1;
				}

				if (parseInt(a.AdresNummer, 10) == parseInt(b.AdresNummer, 10)) {
					return a.AdresNummer.localeCompare(b.AdresNummer);
				}

				return parseInt(a.AdresNummer, 10) - parseInt(b.AdresNummer, 10);
			});
			this.dwellings = values;
		});

		this.Subsciption = this.woningEventService.DwellingId$.subscribe(x =>
		{
			this.currentDwellingId = x;
		});
  }

  ngOnDestroy()
	{
		this.Subsciption.unsubscribe();
	}

	dwellingChanged($event: MatSelectChange) {
		this.woningEventService.WisselWoning(this.currentDwellingId);
	}

}
