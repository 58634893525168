// [conv] import "(./FabAdminAddProductVariant).less";
import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductsConsumer } from '@consumers/ProductsController';
import { ProductVariantModel } from '@models/ProductsViewModel';

@Component({
   selector: 'add-product-variant',
  templateUrl: './FabAdminAddProductVariant.html',
  styleUrls: ['./FabAdminAddProductVariant.scss']
})
export class FabAdminAddProductVariantComponent {
    @Input() ProductId;
    fVariant: ProductVariantModel = new ProductVariantModel();
    @Output() public Saved = new EventEmitter<any>();
    @Output() showMe: boolean =true;

   
   constructor(private route: ActivatedRoute, private prod: ProductsConsumer, private router:Router) {

   }
    addVariant(){
        
        this.fVariant.ProductId = this.ProductId;
        this.prod.SaveVariant_Observable(this.fVariant).subscribe((data) => {
            console.log(data);
            this.Saved.emit();
            this.showMe = false;
        });
           
    }
    
}
