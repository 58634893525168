/**
 * @ref WoonConnectViewModel/DwellingViewModel.cs
 */

 import * as DataContracts from "@datacontracts/DwellingViewModel";

 
import * as AdresViewModel from "./AdresViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.DwellingRequestAllModel
	 */
	 

	export interface IDwellingRequestAllModel {
		TenderId: number;
		ToJsonContract(): DataContracts.IDwellingRequestAllModel;
	}

	export class DwellingRequestAllModel {
		// property-Properties
		public get TenderId(): number {
			return this._TenderId;
		}
		public set TenderId(newTenderId: number) {
			this._TenderId = newTenderId;
		}

		// field-Properties

		// fields
		protected _TenderId: number = 0;

		constructor(data?: DataContracts.IDwellingRequestAllModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDwellingRequestAllModel): void {
			if(data) {
				// vul properties
				this._TenderId = data.TenderId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDwellingRequestAllModel {
			return {
				TenderId: this.TenderId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.DwellingViewModel
	 */
	 

	export interface IDwellingViewModel {
		Id: number;
		Adres: AdresViewModel.IAdresViewModel;
		IsSelected: boolean;
		ToJsonContract(): DataContracts.IDwellingViewModel;
	}

	export class DwellingViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Adres(): AdresViewModel.IAdresViewModel {
			return this._Adres;
		}
		public set Adres(newAdres: AdresViewModel.IAdresViewModel) {
			this._Adres = newAdres;
		}

		public get IsSelected(): boolean {
			return this._IsSelected;
		}
		public set IsSelected(newIsSelected: boolean) {
			this._IsSelected = newIsSelected;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Adres: AdresViewModel.IAdresViewModel = new AdresViewModel.AdresViewModel(undefined);
		protected _IsSelected: boolean = false;

		constructor(data?: DataContracts.IDwellingViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDwellingViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Adres = data.Adres ? new AdresViewModel.AdresViewModel(data.Adres): undefined;
				this._IsSelected = data.IsSelected;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDwellingViewModel {
			return {
				Id: this.Id,
				Adres: (this.Adres ? new AdresViewModel.AdresViewModel(this.Adres).ToJsonContract(): this.Adres),
				IsSelected: this.IsSelected
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.DwellingsViewModel
	 */
	 

	export interface IDwellingsViewModel {
		Dwellings: DwellingViewModel[];
		ToJsonContract(): DataContracts.IDwellingsViewModel;
	}

	export class DwellingsViewModel {
		// property-Properties
		public get Dwellings(): DwellingViewModel[] {
			return this._Dwellings;
		}
		public set Dwellings(newDwellings: DwellingViewModel[]) {
			this._Dwellings = newDwellings;
		}

		// field-Properties

		// fields
		protected _Dwellings: DwellingViewModel[] = [];

		constructor(data?: DataContracts.IDwellingsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDwellingsViewModel): void {
			if(data) {
				// vul properties
				this._Dwellings = data.Dwellings ? data.Dwellings.map(dwellingViewModelItem => new DwellingViewModel(dwellingViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDwellingsViewModel {
			return {
				Dwellings: (this.Dwellings ? this.Dwellings.map(dwellingViewModelItem => new DwellingViewModel(dwellingViewModelItem).ToJsonContract()): [])
			}
		}
	}
