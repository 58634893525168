import * as ViewModelsKostenElement from '.././Models/KostenElement';
import * as ViewModelsKostenMJOP from '.././Models/KostenMJOP';




	export class KostenPrijsregel {
		Naam: string;
		Code: string;
		Bedrag: number;
		BedragFloat: number;
		BedragStr: string;
		BedragFloatExBTW: number;
		BTWpercentage: number;
		WerkOmschrijving: string;
		WerkToelichting: string;
		Tekst: string;
		Categorie: string;
		VerdeelSleutelFactor: number;
		Hoeveelheid: number;
		TotaalHoeveelheid: number;
		TotaalBedrag: number;
		Eenheid: string;
		EenheidsPrijs: number;
		StartJaar: number;
		EindJaar: number;
		Cyclus: number;
		GUID: string;

		Tonen: boolean = false;


		MJOPregel: ViewModelsKostenMJOP.KostenMJOP;
		constructor(public Element: ViewModelsKostenElement.KostenElement, data: any
		) {
			//ng.extend(this, data);
			Object.assign(this, data)
			this.BedragStr = (data.BedragFloat == null ? 0 : data.BedragFloat).formatMoney(2, ',', '.');
			this.TotaalHoeveelheid = 0;
			this.TotaalBedrag = 0;
			this.MJOPregel = new ViewModelsKostenMJOP.KostenMJOP(this, data.MJOP);
		}

		MaakLeeg() {
			this.Bedrag = 0;
			this.BedragFloat = 0;
			this.BedragFloatExBTW = 0;
			this.Hoeveelheid = 0;
			this.TotaalBedrag = 0;
			this.TotaalHoeveelheid = 0;
			this.Tonen = false;

			this.MJOPregel.MaakLeeg();
		}
	}
