//import * as ng from 'angular';
import * as HelpersObjects from '../../Helpers/Objects';
import * as HelpersStateController from '../../Helpers/StateController';
import * as HelpersOverlay from '../../Helpers/Overlay';

import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
	selector: 'wct-bezig',
  templateUrl: './WctBezig.html',
  styleUrls: ['./WctBezig.scss']
})

export class WctBezigComponent implements OnInit {
	@Input() bezigmodel: HelpersOverlay.IBezigState;
	@Output() StateChangeCb = new EventEmitter<boolean>();

	constructor() {
		// this.controller = () => {
		// 	return new HelpersStateController.StateController<HelpersOverlay.BezigActionEnum, HelpersOverlay.IBezigState>((input: string) => {
		// 		return HelpersObjects.Objects.ConvertIfType<HelpersOverlay.BezigActionEnum>(input, () => HelpersOverlay.BezigActionEnum[input]);
		// 	});
		// };
	}

	MustHide(): boolean
	{
		var m = this.model()
		var r = !m.ToonOverlay;
		return r;
	}

	CallClick()
	{
		//this.StateChangeCb('Close', model())
		this.StateChangeCb.emit(true);
	}

	model(): HelpersOverlay.IBezigState
	{
		if (this.bezigmodel == null)
		{
			/* Requested model but it is not correctly binded */
			return HelpersOverlay.BezigState.Default();
		}

		return this.bezigmodel;

	}

	ngOnInit()
	{
	}

	IsActiveMode(mode: string)
	{
		var b = this.bezigmodel.Type == 'circular';
		return b;
	}

	CalculateClasses()
	{
		let size = 'txt-explanation-' + this.bezigmodel.ExplanationSize;
		return {
            size: true,
		};
	}
}
