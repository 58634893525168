/// <reference path='../consumerinterfaces/DCController.ts' />
/**
 * @ref WoonConnectApiController/Controllers/DC/DCController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/DCController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as DcLayout from "@datacontracts/DcLayout";
import * as WijzigEvent from "@datacontracts/WijzigEvent";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class DCConsumer implements Interface.IDCConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public ZetParameters_Observable(layoutModel: DcLayout.IDCLayout & {ToJsonContract(noContent?: boolean): DcLayout.IDCLayout}): Observable<WijzigEvent.IWijzigEvent[]> {
				const layout = layoutModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = layout;
				let apiURL = `api/DC/ZetParameters`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<WijzigEvent.IWijzigEvent[]>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ZetParameters(layoutModel: DcLayout.IDCLayout & {ToJsonContract(noContent?: boolean): DcLayout.IDCLayout}): ng.IPromise<WijzigEvent.IWijzigEvent[]> {
				let obs = this.ZetParameters_Observable(layoutModel);
				let promise = new Promise<WijzigEvent.IWijzigEvent[]>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefParameters_Observable(): Observable<DcLayout.IDCLayout> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/DC/GeefParameters`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<DcLayout.IDCLayout>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefParameters(): ng.IPromise<DcLayout.IDCLayout> {
				let obs = this.GeefParameters_Observable();
				let promise = new Promise<DcLayout.IDCLayout>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


