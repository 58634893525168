import { NgModule,ErrorHandler, APP_INITIALIZER   } from '@angular/core';

//import { HttpClientModule } from '@angular/common/http';
import MyErrorHandler from './errorhandler';

import { AppConfigService } from '../infrastructure/app.configservice';
import { WINDOW_PROVIDERS } from '../infrastructure/windowprovider';
import { DwellingStoreModule } from '../services/dwellingstore/dwelling.module';

export function appInit(appConfigService: AppConfigService) {
	return () => appConfigService.load();
}

@NgModule({
    imports: [
		//HttpClientModule,
		DwellingStoreModule,
    ],
	providers: [
		WINDOW_PROVIDERS,
		{
			provide: APP_INITIALIZER,
			useFactory: appInit,
			multi: true,
			deps: [AppConfigService]
		},
		{ provide: ErrorHandler, useClass: MyErrorHandler },
	],    
})
export class CoreProvidersModule {
	constructor() { }
}