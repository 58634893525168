import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NoteConsumer } from '@consumers/NoteController';
import { INoteModel } from '@datacontracts/GetNotesModel';
import { WoningSelectie } from '@models/WoningSelectie';
import { GetNotesRequestModel } from '@models/GetNotesModel';
import { UpdateNoteRequestModel } from '@models/UpdateNoteModel';
import { DownloadService } from '@services/DownloadService';
import { DownloadNoteRequestModel } from '@models/DownloadNoteModel';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadNoteRequestModel } from '@models/UploadNoteModel';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { UserService } from 'src/app/auth/shared/services/user.service';


class Note {
  ServerNote: INoteModel;
  Contents: ArrayBuffer;
  ImageLoaded: boolean;
}

@Component({
  selector: 'wct-survey-notes',
  templateUrl: './wct-survey-notes.component.html',
  styleUrls: ['./wct-survey-notes.component.scss']
})
export class WctSurveyNotesComponent implements OnInit {

  @Input() Dwelling: WoningSelectie = null;
  @Input() ObjectId: string = null;
  Notes: Note[] = [];
  fileName: string = '';

  constructor(private notesConsume: NoteConsumer, private downloadService: DownloadService, private sanitizer: DomSanitizer, private userservice: UserService,private http: HttpClient) {
  }



  ngOnInit(): void {
    let req = new GetNotesRequestModel();
    req.Dwelling = this.Dwelling;
    req.NoteId = "00000000-0000-0000-0000-000000000000";
    req.ObjectId = this.ObjectId;
    this.notesConsume.GetNotes_Observable(req).subscribe(x => {
      this.Notes = x.Notes.map(x => {
        let note = new Note();
        note.ServerNote = x;
        note.Contents = null;
        return note;
      });

      this.LoadImages();
    });
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  LoadImages() {

    this.Notes.forEach(x => {
      if (x.ServerNote.ContainsFile) {
        let reqdownload = new DownloadNoteRequestModel();
        reqdownload.Dwelling = this.Dwelling;
        reqdownload.NoteId = x.ServerNote.NoteId;

        this.notesConsume.DownloadNote_Observable(reqdownload).subscribe(y => {
          this.downloadService.DownloadFileGetBuffer(y.Contents.Url, y.Contents.Filename, y.Contents.MimeType).subscribe(z => {
            x.ImageLoaded = true;
            x.Contents = z;
          });
        });
      }
    });
  }

  AddNote() {
    let req = new UpdateNoteRequestModel();
    req.Dwelling = this.Dwelling;
    req.ObjectId = this.ObjectId;
    req.NoteId = "00000000-0000-0000-0000-000000000000";
    req.Adding = true;
    this.notesConsume.UpdateNote_Observable(req).subscribe(x => {
      // todo
    });
  }

  @ViewChild('file', { static: true }) myFile: ElementRef;

	upload(files) {
		if (files.length === 0)
			return;

    this.fileName = files.name;
		let req = new UploadNoteRequestModel();

		const formData = new FormData();

		formData.append('data', JSON.stringify(req));
		for (let file of files) {
			formData.append(file.name, file);
		}

		const blobOverrides = new Blob([JSON.stringify(req)], {
			type: 'application/json',
		});

		formData.append('json', blobOverrides);

		this.myFile.nativeElement.value = '';

		const request = new HttpRequest('POST', `api/Notes/UploadNote`, formData);
		const authToken = this.userservice.getAuthorizationToken();
		let headers = request.headers.set('Authorization', `Bearer ${authToken}`)
		const authReq = request.clone({
			headers: headers
		});
		this.http.request<UploadNoteRequestModel>(authReq).subscribe(r => {
			if (r.type == HttpEventType.Response) {
        let newNote = r.body.NoteId;
        // todo
			}
		});
  }

}
