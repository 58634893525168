<div class="cont-activate-key-and-button">
	<i class="wct-icon wct-icon-activatiesleutel"></i>
	<p class="txt-activate-key"><span>Voer hier uw digitale sleutel in</span><span> :</span></p>
	<div class="cont-input-activate-key">
		<input class="input-activate-key" id="character-1" #input1 [(ngModel)]="karakter1" (ngModelChange)="constructActivationKey()" maxlength="1" type="text" (paste)="ProcessPaste($event)" (click)="onClick($event)"/>
		<input class="input-activate-key" id="character-2" #input2 [(ngModel)]="karakter2" (ngModelChange)="constructActivationKey()" maxlength="1" type="text" (click)="onClick($event)"/>
		<input class="input-activate-key" id="character-3" #input3 [(ngModel)]="karakter3" (ngModelChange)="constructActivationKey()" maxlength="1" type="text" (click)="onClick($event)"/>
		<input class="input-activate-key" id="character-4" #input4 [(ngModel)]="karakter4" (ngModelChange)="constructActivationKey()" maxlength="1" type="text" (click)="onClick($event)"/>
		<input class="input-activate-key" id="character-5" #input5 [(ngModel)]="karakter5" (ngModelChange)="constructActivationKey()" maxlength="1" type="text" (click)="onClick($event)"/>
		<input class="input-activate-key" id="character-6" #input6 [(ngModel)]="karakter6" (ngModelChange)="constructActivationKey()" maxlength="1" type="text" (click)="onClick($event)"/>
	</div>
</div>
