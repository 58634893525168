// [conv] import "(./WctBreadcrumb).less";
import { Component, OnInit, Inject, OnDestroy, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
//import { StateService } from '../../../infrastructure/ajs-upgraded-providers';
//import { IStateService } from '../../../services/StateService';

@Component({
	selector: 'wct-breadcrumb',
	templateUrl: './WctBreadcrumb.html'
})

export class WctBreadcrumb {
	@Input() public levelOne: string;
	@Input() public levelTwo: string;
	@Input() public levelThree: string;
	@Input() public levelFour: string;
	// @Input() public selectedTenderTitle: string;
	@Input() public subject: string;

	@Output() public ongohome = new EventEmitter<any>();
	@Output() public ongotolevelone = new EventEmitter<any>();
	@Output() public ongotoleveltwo = new EventEmitter<any>();
	@Output() public ongotolevelthree = new EventEmitter<any>();
	@Output() public ongotolevelfour = new EventEmitter<any>();

	public BreadcrumbLevel: number = 1;

	constructor(
		//@Inject(StateService) private stateservice: IStateService
	) {
		'ngInject';
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.levelTwo.previousValue !== undefined && changes.levelTwo.currentValue !== undefined)  {
			if (changes.levelTwo.previousValue !== changes.levelTwo.currentValue) {
				if (changes.levelTwo.currentValue.length > 0) {
					this.BreadcrumbLevel = 2;
				} else {
					this.BreadcrumbLevel = 1;
				}
			}		
		}
  }

	public GoHome(): void {
		this.BreadcrumbLevel = 1;
		this.ongohome.emit();
	}

	public GoBackToPreviousLevel(): void {
		if (this.BreadcrumbLevel == 1) {
			this.GoHome();
		} else if (this.BreadcrumbLevel == 2) {
			this.GoToBreadcrumbLevelOne();
		} else if (this.BreadcrumbLevel == 3) {
			this.GoToBreadcrumbLevelTwo();
		} else if (this.BreadcrumbLevel == 4) {
			this.GoToBreadcrumbLevelThree();
		}
	}

	public GoToBreadcrumbLevelOne(): void {
		this.BreadcrumbLevel = 1;
		this.ongotolevelone.emit();
	}

	public GoToBreadcrumbLevelTwo(): void {
		this.BreadcrumbLevel = 2;	
		this.ongotoleveltwo.emit();
	}

	public GoToBreadcrumbLevelThree(): void {
		this.BreadcrumbLevel = 3;
	}

	public GoToBreadcrumbLevelFour(): void {
		this.BreadcrumbLevel = 4;
	}

	// public IsCurrentSituation(): boolean {
	// 	return this.stateservice.IsCurrentSituation();
	// }
}
