import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'wct-stui-main-header',
  templateUrl: './wct-stui-main-header.component.html',
  styleUrls: ['./wct-stui-main-header.component.scss']
})
export class WctStuiMainHeaderComponent implements OnInit {
  @Output() LogoClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  ClickLogo() {
    this.LogoClicked.emit();
  }
}
