// [conv] import "(./WctDocumentPopup).less";
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DocumentService } from '../../services/DocumentService';
import { IDocumentModel } from '@datacontracts/DocumentModel';
import { BezigState, IBezigState } from '../../Helpers/Overlay';

@Component({
   selector: 'wct-document-popup',
   templateUrl: './WctDocumentPopup.html'
})
export class WctDocumentPopupComponent {
   public id: number;
   public document: IDocumentModel;
   public bezigViewModel: IBezigState;
   
   constructor(
      private documentService: DocumentService,
      private dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) private data: any
   ) {
      this.id = data.id;
      this.bezigViewModel = BezigState.Default();
      
   }

   ngOnInit(){
      this.loadDocument();
   }
   
   public loadDocument(): void {
      this.bezigViewModel.ToonOverlay = true;
      if (this.id > 0) {
         this.documentService.GeefDocument(this.id).then(document => {
            this.document = document;
            this.bezigViewModel.ToonOverlay = false;
         }).catch(err => {
            this.bezigViewModel.ToonOverlay = false;
            console.log(err);
         });
      } else {
         this.bezigViewModel.ToonOverlay = false;
      }
   }

   public save(): void {
      this.bezigViewModel.ToonOverlay = true;
      this.documentService.OpslaanDocument(this.document).then(() => {
         this.bezigViewModel.ToonOverlay = false;
         this.dialog.closeAll();
      }).catch(err => {
         this.bezigViewModel.ToonOverlay = false;
         console.log(err);
         this.dialog.closeAll();
      })
   }

   public cancel(): void {
      this.dialog.closeAll();
   }
}