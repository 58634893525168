/**
 * @ref WoonConnectViewModel/User/RolesViewModel.cs
 */

 import * as DataContracts from "@datacontracts/RolesViewModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.User.RoleViewModel
	 */
	 

	export interface IRoleViewModel {
		Rol: string;
		ToJsonContract(): DataContracts.IRoleViewModel;
	}

	export class RoleViewModel {
		// property-Properties
		public get Rol(): string {
			return this._Rol;
		}
		public set Rol(newRol: string) {
			this._Rol = newRol;
		}

		// field-Properties

		// fields
		protected _Rol: string = "";

		constructor(data?: DataContracts.IRoleViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IRoleViewModel): void {
			if(data) {
				// vul properties
				this._Rol = data.Rol;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IRoleViewModel {
			return {
				Rol: this.Rol
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.RolesViewModel
	 */
	 

	export interface IRolesViewModel {
		Roles: RoleViewModel[];
	}

	export class RolesViewModel {
		// property-Properties
		public get Roles(): RoleViewModel[] {
			return this._Roles;
		}
		public set Roles(newRoles: RoleViewModel[]) {
			this._Roles = newRoles;
		}

		// field-Properties

		// fields
		protected _Roles: RoleViewModel[] = [];

		constructor(data?: DataContracts.IRolesViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IRolesViewModel): void {
			if(data) {
				// vul properties
				this._Roles = data.Roles ? data.Roles.map(roleViewModelItem => new RoleViewModel(roleViewModelItem)): [];
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.AddUserToRoleRequest
	 */
	 

	export interface IAddUserToRoleRequest {
		Rol: string;
		UserId: number;
		ToJsonContract(): DataContracts.IAddUserToRoleRequest;
	}

	export class AddUserToRoleRequest {
		// property-Properties
		public get Rol(): string {
			return this._Rol;
		}
		public set Rol(newRol: string) {
			this._Rol = newRol;
		}

		public get UserId(): number {
			return this._UserId;
		}
		public set UserId(newUserId: number) {
			this._UserId = newUserId;
		}

		// field-Properties

		// fields
		protected _Rol: string = "";
		protected _UserId: number = 0;

		constructor(data?: DataContracts.IAddUserToRoleRequest) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddUserToRoleRequest): void {
			if(data) {
				// vul properties
				this._Rol = data.Rol;
				this._UserId = data.UserId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAddUserToRoleRequest {
			return {
				Rol: this.Rol,
				UserId: this.UserId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.RemoveUserFromRoleRequest
	 */
	 

	export interface IRemoveUserFromRoleRequest {
		Rol: string;
		UserId: number;
		ToJsonContract(): DataContracts.IRemoveUserFromRoleRequest;
	}

	export class RemoveUserFromRoleRequest {
		// property-Properties
		public get Rol(): string {
			return this._Rol;
		}
		public set Rol(newRol: string) {
			this._Rol = newRol;
		}

		public get UserId(): number {
			return this._UserId;
		}
		public set UserId(newUserId: number) {
			this._UserId = newUserId;
		}

		// field-Properties

		// fields
		protected _Rol: string = "";
		protected _UserId: number = 0;

		constructor(data?: DataContracts.IRemoveUserFromRoleRequest) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IRemoveUserFromRoleRequest): void {
			if(data) {
				// vul properties
				this._Rol = data.Rol;
				this._UserId = data.UserId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IRemoveUserFromRoleRequest {
			return {
				Rol: this.Rol,
				UserId: this.UserId
			}
		}
	}
