import { IFilterViewModel, IFilterOptionViewModel } from '@datacontracts/FilterViewModel';
import { FilterDisplayLevelEnum } from '@enums/FilterViewModel';
import { FilterViewModel } from '@models/FilterViewModel';
import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import * as _ from 'lodash';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { FabrikantenConsumer } from '@consumers/FabrikantenController';

import { BCBProductsOverviewModel } from '@models/BCBProductsOverviewModel';
import { BCBProductViewModel, IBCBProductViewModel } from '@models/BCBProductModel';
import { FabrikantenFilterRequestModel, FabrikantenFilterResponseModel } from '@models/FabrikantFilterModel';
import { FabrikantenExcelResponseModel } from '@models/FabrikantExcelModel';
import { PDFViewModel, OfferteAanvraagViewModel, PDFRequestViewModel, RekenModuleInputViewModel } from '@models/PDFViewModel';
import { GoogleAnalyticsService } from '../../../../../services/GoogleAnalyticsService'

@Component({
  selector: 'category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CategoryComponent {

  Product: string;
  Filters: string;

  Loading: boolean = true;
  LoadingExcel: boolean = true;
  ValidateActive: boolean = false;

  FabrikantenFilterResponseModel: FabrikantenFilterResponseModel;
  FabrikantenExcelResponseModel: FabrikantenExcelResponseModel;
  OfferteAanvraagViewModel: OfferteAanvraagViewModel;
  PDFRequestViewModel: PDFRequestViewModel;
  PDFViewModel: PDFViewModel;

  public ActiveProduct: string = "";

  public VrijeInvoerLambda: number = null;

  public OffertePopupTonen: boolean = false;
  public OfferteVerzondenPopupTonen: boolean = false;
  public OfferteSlimfortTonen: boolean = false;
  public IsolatieToelichtingTonen: boolean = false;
  public AfschotImage1Tonen: boolean = false;
  public AfschotImage2Tonen: boolean = false;
  public AfschotImage3Tonen: boolean = false;
  public AfschotImage4Tonen: boolean = false;

  public Afschotvlakken: Array<DakDeel> = new Array<DakDeel>();
  public Graatvlakken: Array<DakDeel> = new Array<DakDeel>();
  public Kilvlakken: Array<DakDeel> = new Array<DakDeel>();
  public Vlakken: Array<DakDeel> = new Array<DakDeel>();

  public TussenUitkomst1: string = "α<sub>fa</sub> = (0,8 x (d<sub>fa</sub>/d<sub>iso</sub>)) x ((n<sub>fa</sub> x λ<sub>fa</sub> x A<sub>fa</sub>)/d<sub>iso</sub>)";
  public TussenUitkomst2: string = "∆U<sub>fa</sub> = α<sub>fa</sub> x (R<sub>1</sub>/R<sub>T</sub>)<sup>2</sup>";
  public TussenUitkomst3: string = "∆U = ∆U<sub>a</sub> + ∆U<sub>fa</sub> + ∆U<sub>r</sub>    (∆U<sub>a</sub>=0  ,  ∆U<sub>r</sub>=0)";
  public TussenUitkomst4: string = "∆<sub>U</sub>≤3% van  Ut (NTA8800 8.2.2.1) ∆<sub>U</sub> niet meerekenen";
  public TussenUitkomst5: string = "U<sub>C</sub> = U<sub>T</sub>/f<sub>prac</sub> + ∆U  (F<sub>prac</sub> = 1)";
  public TussenUitkomst6: string = "Rc = 1/U<sub>C</sub> – R<sub>si</sub> – R<sub>se</sub>";

  public InclAfschotUT: string = "Incl. Afschot <b>U<sub>T</sub>=(1/R<sub>T</sub>)</b> [W/m<sup>2</sup>K]";
  public InclAfschotRT: string = "Incl. Afschot R<sub>T</sub>";

  public Toelichting: string = "<table><tr><td colspan='3'>Advies m.b.t. productkeuze:</td></tr><tr><td></td></tr></table>"

  constructor(
    private fab: FabrikantenConsumer,
    private route: ActivatedRoute,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {

    this.OfferteAanvraagViewModel = new OfferteAanvraagViewModel();
    this.FabrikantenFilterResponseModel = new FabrikantenFilterResponseModel();
    this.PDFRequestViewModel = new PDFRequestViewModel();

    this.PDFRequestViewModel.GewenstAfschotpercentage = "?";
    this.PDFRequestViewModel.ToepassingKilGraatPlaten = "?";

    this.AddVlak(this.Afschotvlakken, "Afschot vlak 1", "Afschotvlak1");
    this.AddVlak(this.Afschotvlakken, "Afschot vlak 2", "Afschotvlak2");
    this.AddVlak(this.Afschotvlakken, "Afschot vlak 3", "Afschotvlak3");
    this.AddVlak(this.Afschotvlakken, "Afschot vlak 4", "Afschotvlak4");
    this.AddVlak(this.Afschotvlakken, "Afschot vlak 5", "Afschotvlak5");
    this.AddVlak(this.Afschotvlakken, "Afschot vlak 6", "Afschotvlak6");

    this.AddVlak(this.Graatvlakken, "Graat vlak 1", "Graatvlak1");
    this.AddVlak(this.Graatvlakken, "Graat vlak 2", "Graatvlak2");
    this.AddVlak(this.Graatvlakken, "Graat vlak 3", "Graatvlak3");
    this.AddVlak(this.Graatvlakken, "Graat vlak 4", "Graatvlak4");
    this.AddVlak(this.Graatvlakken, "Graat vlak 5", "Graatvlak5");
    this.AddVlak(this.Graatvlakken, "Graat vlak 6", "Graatvlak6");

    this.AddVlak(this.Kilvlakken, "Kil vlak 1", "Kilvlak1");
    this.AddVlak(this.Kilvlakken, "Kil vlak 2", "Kilvlak2");
    this.AddVlak(this.Kilvlakken, "Kil vlak 3", "Kilvlak3");
    this.AddVlak(this.Kilvlakken, "Kil vlak 4", "Kilvlak4");
    this.AddVlak(this.Kilvlakken, "Kil vlak 5", "Kilvlak5");
    this.AddVlak(this.Kilvlakken, "Kil vlak 6", "Kilvlak6");

    this.AddVlak(this.Vlakken, "Plat vlak 1", "Vlak1");
    this.AddVlak(this.Vlakken, "Plat vlak 2", "Vlak2");
    this.AddVlak(this.Vlakken, "Plat vlak 3", "Vlak3");
    this.AddVlak(this.Vlakken, "Plat vlak 4", "Vlak4");
    this.AddVlak(this.Vlakken, "Plat vlak 5", "Vlak5");
    this.AddVlak(this.Vlakken, "Plat vlak 6", "Vlak6");

    //var url = (<any>this.route)._routerState.snapshot.url;

    //if (url != undefined) {
    //  const tree = this.router.parseUrl(url);
    //  if (tree != undefined) {
    //    const children = tree.root.children.primary;

    //    if (children != undefined) {
    //      const segments = children.segments;

    //      if (segments.length > 1) {
    //        this.Product = segments[1].path;
    //        if (this.Product != undefined) {
    //          this.FabrikantenFilterResponseModel.ProductParameter = this.Product;

    //          this.googleAnalyticsService.sendCustomEvent(
    //            "Bestekservice",
    //            "ProductViewedDeeplink",
    //            this.Product,
    //            1);
    //        }
    //      }

    //      if (segments.length > 2) {
    //        this.Filters = "";

    //        for (var i = 2; i < segments.length; i++) {

    //          if (this.Filters != "") {
    //            this.Filters += "_";
    //          }

    //          this.Filters += segments[i].path;
    //        }

    //        if (this.Filters != undefined) {
    //          this.FabrikantenFilterResponseModel.FiltersParameter = this.Filters;
    //        }
    //      }
    //    }
    //  }
    //}

    this.Refresh(true);
  }

  public NavigateToURL(): void {
    var categorie = null;

    if (this.FabrikantenFilterResponseModel != null) {
      for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Categorie") {
          categorie = this.FabrikantenFilterResponseModel.Filters[i];
        }
      }
    }

    if (categorie != null) {
      if (categorie.SelectedOption != null) {
        if (categorie.SelectedOption.Name == "Spouw") {
          this.router.navigateByUrl('/spouw');
        }

        if (categorie.SelectedOption.Name == "Vloer") {
          this.router.navigateByUrl('/vloer');
        }

        if (categorie.SelectedOption.Name == "Plat dak") {

          var subcategorie = null;

          if (this.FabrikantenFilterResponseModel != null) {
            for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
              if (this.FabrikantenFilterResponseModel.Filters[i].Name == "SubCategorie") {
                subcategorie = this.FabrikantenFilterResponseModel.Filters[i];
              }
            }
          }

          if (subcategorie != null && subcategorie.SelectedOption != null && subcategorie.SelectedOption.Name == "Vlakke isolatieplaten") {
            this.router.navigateByUrl('/platdak/vlak');
          }
          else if (subcategorie != null && subcategorie.SelectedOption != null && subcategorie.SelectedOption.Name == "Afschotsysteem") {

            var subcategorie2 = null;

            if (this.FabrikantenFilterResponseModel != null) {
              for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
                if (this.FabrikantenFilterResponseModel.Filters[i].Name == "SubCategorie2") {
                  subcategorie2 = this.FabrikantenFilterResponseModel.Filters[i];
                }
              }
            }

            if (subcategorie2 != null && subcategorie2.SelectedOption != null && subcategorie2.SelectedOption.Name == "Voor een Rc-berekening zonder afschotplan en/of voor het aanvragen van een afschotplan") {
              this.router.navigateByUrl('/platdak/afschot/geenafschotplan');
            }
            else if (subcategorie2 != null && subcategorie2.SelectedOption != null && subcategorie2.SelectedOption.Name == "Voor een Rc-berekening gebaseerd op een afschotplan") {
              this.router.navigateByUrl('/platdak/afschot/afschotplan');
            }
            else {
              this.router.navigateByUrl('/platdak/afschot');
            }
          }
          else {
            this.router.navigateByUrl('/platdak');
          }
        }
      }
    }
  }

  public ProcessParameters(): void {
    var category1 = this.route.snapshot.paramMap.get("category1");

    if (category1 == "spouw") {
      var cat = this.GetFirstImportantFilter();
      if (cat != null && cat.Name == "Categorie") {
        var option = null;

        for (var i = 0; i < cat.Options.length; i++) {
          if (cat.Options[i].Name == "Spouw") {
            option = cat.Options[i].Id;
          }
        }

        this.SelectOption(cat.Id, option);
      }
    }
    else if (category1 == "vloer") {
      var cat = this.GetFirstImportantFilter();
      if (cat != null && cat.Name == "Categorie") {
        var option = null;

        for (var i = 0; i < cat.Options.length; i++) {
          if (cat.Options[i].Name == "Vloer") {
            option = cat.Options[i].Id;
          }
        }

        this.SelectOption(cat.Id, option);
      }
    }
    else if (category1 == "platdak") {
      var category2 = this.route.snapshot.paramMap.get("category2");

      var cat = this.GetFirstImportantFilter();

      var option = null;

      for (var i = 0; i < cat.Options.length; i++) {
        if (cat.Options[i].Name == "Plat dak") {
          option = cat.Options[i].Id;
        }
      }

      if (category2 == "vlak") {
        var subcat;

        if (this.FabrikantenFilterResponseModel != null) {
          for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
            if (this.FabrikantenFilterResponseModel.Filters[i].Name == "SubCategorie") {
              subcat = this.FabrikantenFilterResponseModel.Filters[i];
            }
          }
        }

        var option = null;

        for (var i = 0; i < subcat.Options.length; i++) {
          if (subcat.Options[i].Name == "Vlakke isolatieplaten") {
            option = subcat.Options[i].Id;
          }
        }

        this.SelectOption(subcat.Id, option);
      }
      else if (category2 == "afschot") {
        var category3 = this.route.snapshot.paramMap.get("category3");

        if (category3 == "geenafschotplan") {
          var subcat;

          if (this.FabrikantenFilterResponseModel != null) {
            for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
              if (this.FabrikantenFilterResponseModel.Filters[i].Name == "SubCategorie2") {
                subcat = this.FabrikantenFilterResponseModel.Filters[i];
              }
            }
          }

          var option = null;

          for (var i = 0; i < subcat.Options.length; i++) {
            if (subcat.Options[i].Name == "Voor een Rc-berekening zonder afschotplan en/of voor het aanvragen van een afschotplan") {
              option = subcat.Options[i].Id;
            }
          }

          if (subcat.SelectedOption == null) {
            this.SelectOption(subcat.Id, option);
          }
        }
        else if (category3 == "afschotplan") {
          var subcat;

          if (this.FabrikantenFilterResponseModel != null) {
            for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
              if (this.FabrikantenFilterResponseModel.Filters[i].Name == "SubCategorie2") {
                subcat = this.FabrikantenFilterResponseModel.Filters[i];
              }
            }
          }

          var option = null;

          for (var i = 0; i < subcat.Options.length; i++) {
            if (subcat.Options[i].Name == "Voor een Rc-berekening gebaseerd op een afschotplan") {
              option = subcat.Options[i].Id;
            }
          }

          if (subcat.SelectedOption == null) {
            this.SelectOption(subcat.Id, option);
          }
        }
        else {
          var subcat;

          if (this.FabrikantenFilterResponseModel != null) {
            for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
              if (this.FabrikantenFilterResponseModel.Filters[i].Name == "SubCategorie") {
                subcat = this.FabrikantenFilterResponseModel.Filters[i];
              }
            }
          }

          var option = null;

          for (var i = 0; i < subcat.Options.length; i++) {
            if (subcat.Options[i].Name == "Afschotsysteem") {
              option = subcat.Options[i].Id;
            }
          }

          if (subcat.SelectedOption == null) {
            this.SelectOption(subcat.Id, option);
          }
        }
      }
      else {
        this.SelectOption(cat.Id, option);
      }
    }
  }

  public SelectProduct(product: BCBProductViewModel) {

    this.googleAnalyticsService.sendCustomEvent(
      "Bestekservice",
      "ProductViewed",
      product.DisplayName,
      product.Id);

    this.FabrikantenFilterResponseModel.SelectedProduct = product;
    this.ActiveProduct = product.Naam;
    this.Refresh(false);
  }

  public GetProductImageUrl(product: BCBProductViewModel): string {

    for (var i = 0; i < product.Links.length; i++) {
      if (product.Links[i].Type == "img") {

        var tSplit1 = product.Links[i].URL.split('|');

        for (var j1 = 0; j1 < tSplit1.length; j1++) {

          var tSplit2 = tSplit1[j1].split(',');

          for (var j = 0; j < tSplit2.length; j++) {
            if (tSplit2[j].startsWith("http")) {
              return tSplit2[j];
            }
          }
        }
      }
    }

    return "";
  }

  public Refresh(First: boolean = false) {

    if (!First) {
      if (this.FabrikantenFilterResponseModel.FiltersParameter != undefined) {
        this.FabrikantenFilterResponseModel.FiltersParameter = undefined;
      }
    }

    this.LoadFilters();
  }

  public IsLoading(): boolean {
    return this.Loading || this.LoadingExcel;
  }

  public VrijeLambdaChange(): void {
    if (this.FabrikantenFilterResponseModel != null) {

    }
  }

  private LoadFilters(): void {
    this.Loading = true;

    this.fab.FilterOptions_Observable(this.FabrikantenFilterResponseModel).subscribe((data) => {
      this.FabrikantenFilterResponseModel = new FabrikantenFilterResponseModel(data);
      this.Loading = false;

      if (this.FabrikantenFilterResponseModel.SelectedProduct == undefined) {
        if (this.FabrikantenFilterResponseModel.Products.length == 1) {
          this.FabrikantenFilterResponseModel.SelectedProduct = this.FabrikantenFilterResponseModel.Products[0];
        }
      }

      this.SetDefaults();

      this.LoadExcel();
      this.ProcessParameters();
    });
  }

  public PopupTonen(): boolean {
    return this.OffertePopupTonen || this.OfferteVerzondenPopupTonen || this.OfferteSlimfortTonen || this.IsolatieToelichtingTonen ||
      this.AfschotImage1Tonen || this.AfschotImage2Tonen || this.AfschotImage3Tonen || this.AfschotImage4Tonen;
  }

  public ShouldDisplayInfo(cat: string): boolean {

    if (this.FabrikantenFilterResponseModel != null) {
      if (this.FabrikantenFilterResponseModel.SelectedProduct != null) {
        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 2) {
          if (cat == "Isolatie") {
            return true;
          }
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 3) {
          if (cat == "Representatie afschot isolatie") {
            return true;
          }
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 7) {
          if (cat == "Representatie afschot isolatie") {
            return true;
          }
        }
      }
    }

    return false;
  }

  public OfferteAanvragen(): void {
    this.OffertePopupTonen = true;
  }

  public PDFDownloaden(): void {
    this.Loading = true;

    var RekenModuleViewModel: RekenModuleInputViewModel = new RekenModuleInputViewModel();

    this.PDFRequestViewModel.Title = this.GetTitle();

    RekenModuleViewModel.FabrikantenFilterResponseModel = this.FabrikantenFilterResponseModel;
    RekenModuleViewModel.OfferteAanvraagViewModel = this.OfferteAanvraagViewModel;
    RekenModuleViewModel.PDFRequestViewModel = this.PDFRequestViewModel;

    this.fab.GetPDFModel_Observable(RekenModuleViewModel).subscribe((data) => {
      this.PDFViewModel = new PDFViewModel(data);
      this.Loading = false;
      window.open(this.PDFViewModel.Url);
    });
  }

  public Annuleren(): void {
    this.OffertePopupTonen = false;
  }

  public PopupSluiten(): void {
    this.OffertePopupTonen = false;
    this.OfferteSlimfortTonen = false;
    this.OfferteVerzondenPopupTonen = false;
    this.IsolatieToelichtingTonen = false;
    this.AfschotImage1Tonen = false;
    this.AfschotImage2Tonen = false;
    this.AfschotImage3Tonen = false;
    this.AfschotImage4Tonen = false;
  }

  public VerstuurAanvraag(): void {

    this.ValidateActive = true;

    if (this.IsPlaatsBedrijfValid() && this.IsBedrijfsnaamValid() && this.IsNaamContactPersoonValid() && this.IsTelContactPersoonValid() && this.IsEmailContactPersoonValid()) {
      this.Loading = true;

      var RekenModuleViewModel: RekenModuleInputViewModel = new RekenModuleInputViewModel();

      RekenModuleViewModel.FabrikantenFilterResponseModel = this.FabrikantenFilterResponseModel;
      RekenModuleViewModel.OfferteAanvraagViewModel = this.OfferteAanvraagViewModel;
      RekenModuleViewModel.PDFRequestViewModel = this.PDFRequestViewModel;

      this.fab.SendOfferteAanvraag_Observable(RekenModuleViewModel).subscribe(() => {
        this.OfferteVerzondenPopupTonen = true;
        this.OffertePopupTonen = false;
        this.Loading = false;
      });
    }
  }

  public GetTitle(): string {

    if (this.FabrikantenFilterResponseModel?.SelectedProduct != null) {
      return this.FabrikantenFilterResponseModel.SelectedProduct.Naam;
    }

    return "R<sub>C</sub> - Rekentool";
  }

  public LoadExcel(): void {
    this.LoadingExcel = true;


    if (this.VrijeInvoerLambda != null) {
      this.FabrikantenFilterResponseModel.FiltersParameter = this.VrijeInvoerLambda.toString();
    }

    this.fab.GetExcelModel_Observable(this.FabrikantenFilterResponseModel).subscribe((data) => {
      this.FabrikantenExcelResponseModel = new FabrikantenExcelResponseModel(data);
      this.LoadingExcel = false;
    });
  }

  public SetLastSelectedView(lastselectedView: string) {
    if (this.FabrikantenFilterResponseModel.SelectedProduct != undefined) {
      this.FabrikantenFilterResponseModel.SelectedProduct.ViewSelected = lastselectedView;
    }
  }

  public SetLastSelectedProduct(lastselectedproduct: any) {
    this.FabrikantenFilterResponseModel.SelectedProduct = lastselectedproduct;
  }

  public IsBedrijfsnaamValid(): boolean {
    if (this.ValidateActive) {
      return this.OfferteAanvraagViewModel.BedrijfsnaamAanvrager.length > 0;
    }

    return true;
  }

  public IsPlaatsBedrijfValid(): boolean {
    if (this.ValidateActive) {
      return this.OfferteAanvraagViewModel.PlaatsBedrijf.length > 0;
    }

    return true;
  }

  public IsNaamContactPersoonValid(): boolean {
    if (this.ValidateActive) {
      return this.OfferteAanvraagViewModel.NaamContactPersoon.length > 0;
    }

    return true;
  }

  public IsTelContactPersoonValid(): boolean {
    if (this.ValidateActive) {
      return this.OfferteAanvraagViewModel.TelContactPersoon.length > 0;
    }

    return true;
  }

  public IsEmailContactPersoonValid(): boolean {
    if (this.ValidateActive) {
      return this.OfferteAanvraagViewModel.EmailContactPersoon.length > 0;
    }

    return true;
  }

  public ShouldDisplay(filter: IFilterViewModel) {

    if (filter.Name == 'Categorie') {
      return false;
    }

    if (this.FabrikantenFilterResponseModel.SelectedProduct == undefined) {
      if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.leveltwo) {
        return false;
      }
    }
    else {
      if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.levelone) {
        return false;
      }
    }

    if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.none) {
      return false;
    }

    return true;
  }

  public Terug() {

    var subcat2: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      return filter.Name == "SubCategorie2";
    });

    var subcat: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      return filter.Name == "SubCategorie";
    });

    var maincat: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      return filter.Name == "Categorie";
    });

    var subcat2_option = subcat2?.SelectedOption;
    var subcat_option = subcat?.SelectedOption;
    var maincat_option = maincat?.SelectedOption;

    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      filter.SelectedOption = null;
      filter.SelectedOptionId = "";
      if (filter.SelectedOptions.length > 0) {
        filter.SelectedOptions.splice(0, filter.SelectedOptions.length);
      }
      filter.RangeValue = null;
    });

    if (subcat2_option != null && subcat2_option.Name != "Geen") {
      maincat.SelectedOption = maincat_option;
      maincat.SelectedOptionId = maincat_option.Id.toString();

      subcat.SelectedOption = subcat_option;
      subcat.SelectedOptionId = subcat_option.Id.toString();
    }
    else if (subcat_option != null && subcat_option.Name != "Geen") {
      maincat.SelectedOption = maincat_option;
      maincat.SelectedOptionId = maincat_option.Id.toString();
    }

    this.FabrikantenFilterResponseModel.SelectedProduct = null;

    this.Refresh(false);
  }

  public RemoveAllFilters() {

    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      filter.SelectedOption = null;
      filter.SelectedOptionId = "";
      if (filter.SelectedOptions.length > 0) {
        filter.SelectedOptions.splice(0, filter.SelectedOptions.length);
      }
      filter.RangeValue = null;
    });

    this.FabrikantenFilterResponseModel.SelectedProduct = null;

    this.Refresh(false);
  }

  public RemoveNonImportantFilters() {

    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      if (!filter.Important) {
        filter.SelectedOption = null;
        filter.SelectedOptionId = "";
        if (filter.SelectedOptions.length > 0) {
          filter.SelectedOptions.splice(0, filter.SelectedOptions.length);
        }
        filter.RangeValue = null;
      }
    });
    this.Refresh(false);
  }

  public FiltersSelected() {
    let ret: boolean = false;
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      if (filter.Name !== "Categorie") {
        if ((filter.SelectedOptionId !== null) && (filter.SelectedOptionId !== "")) {
          ret = true;
        }
      }
    });
    return ret;
  }

  public GetFirstImportantFilter(): IFilterViewModel {

    if (this.FabrikantenFilterResponseModel != null) {
      for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
        if (this.FabrikantenFilterResponseModel.Filters[i].Important && (
          this.FabrikantenFilterResponseModel.Filters[i].SelectedOption == null &&
          this.FabrikantenFilterResponseModel.Filters[i].Options.length != 1
        )) {
          return this.FabrikantenFilterResponseModel.Filters[i];
        }
      }
    }

    return null;
  }

  public SelectOption(filterid, optionid) {

    if (filterid == 1 && optionid == 1) { // categorie gevel
      window.open('https://slimfort-tool.isobouw.nl/', '_blank');
    }
    else {
      if (this.FabrikantenFilterResponseModel != null) {

        var filter: IFilterViewModel = null;

        for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {

          if (this.FabrikantenFilterResponseModel.Filters[i].Id.toString() == filterid.toString()) {
            filter = this.FabrikantenFilterResponseModel.Filters[i];
          }
        }

        if (filter != null) {
          for (var i = 0; i < filter.Options.length; i++) {

            if (filter.Options[i].Id.toString() == optionid.toString()) {
              filter.SelectedOption = filter.Options[i];
            }
          }
        }
      }
    }

    this.Refresh();
  }

  public GetOrderOptions(filter: IFilterViewModel): IFilterOptionViewModel[] {

    filter.Options.sort((a: IFilterOptionViewModel, b: IFilterOptionViewModel) => {
      if (a.Position < b.Position) {
        return -1;
      } else if (a.Position > b.Position) {
        return 1;
      } else {
        return 0;
      }
    });

    return filter.Options;
  }

  public GetCategories(): string[] {
    var cats = new Array<string>();

    if (this.FabrikantenFilterResponseModel != undefined) {
      for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
        if (this.FabrikantenFilterResponseModel.Filters[i].Name.indexOf('_') != -1) {

          var cat = this.FabrikantenFilterResponseModel.Filters[i].Name.split('_')[0];

          var found = false;

          for (var j = 0; j < cats.length; j++) {
            if (cats[j] == cat) {
              found = true;
            }
          }

          if (!found) {
            if (cat.indexOf("Tule") != -1) {

              var bevestigers = _.find(this.FabrikantenFilterResponseModel.Filters, filter => {
                if (filter.Name == "Bevestigers aantal en diameter_Materiaal") {
                  return true;
                }

                return false;
              });

              if (bevestigers != null && bevestigers.SelectedOption != null && bevestigers.SelectedOption.Name.indexOf("tule") != -1) {
                cats.push(cat);
              }
            }
            else if (cat.indexOf("Afschotvlak") == -1 && cat.indexOf("Incl. Afschot") == -1 && cat.indexOf("Graatvlak") == -1 && cat.indexOf("Kilvlak") == -1 && cat.indexOf("Vlak") == -1) {
              cats.push(cat);
            }
          }
        }
      }
    }

    return cats;
  }

  public GetFilter(categorie: string, filter: string): IFilterViewModel {
    if (this.FabrikantenFilterResponseModel != undefined) {
      for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
        if (this.FabrikantenFilterResponseModel.Filters[i].Name.indexOf('_') != -1) {

          var cat = this.FabrikantenFilterResponseModel.Filters[i].Name.split('_')[0];
          var filter2 = this.FabrikantenFilterResponseModel.Filters[i].Name.split('_')[1];

          if (categorie == cat && filter == filter2) {
            return this.FabrikantenFilterResponseModel.Filters[i];
          }
        }
      }
    }

    return null;
  }

  public GetExcelValue(key: string, decimals: number): string {
    if (this.FabrikantenExcelResponseModel != undefined) {
      for (var i = 0; i < this.FabrikantenExcelResponseModel.Values.length; i++) {
        if (this.FabrikantenExcelResponseModel.Values[i].Name == key) {

          if (this.FabrikantenExcelResponseModel.Values[i].Value == "") {
            return "";
          }

          var value = parseFloat(this.FabrikantenExcelResponseModel.Values[i].Value.replace(',', '.'));

          if (decimals == undefined) {
            return value.toFixed(3);
          }
          else {
            return value.toFixed(decimals);
          }
        }
      }
    }


    return key;
  }

  public AfschotSysteemTonen(): boolean {

    if (this.FabrikantenFilterResponseModel != null) {
      if (this.FabrikantenFilterResponseModel.SelectedProduct != null) {
        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 3) {
          return true;
        }
      }
    }

    return false;
  }

  public SubTitleTonen(): boolean {

    if (this.FabrikantenFilterResponseModel != null) {
      if (this.FabrikantenFilterResponseModel.SelectedProduct != null) {
        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 3 ||
          this.FabrikantenFilterResponseModel.SelectedProduct.Id == 7) {
          return true;
        }
      }
    }

    return false;
  }

  public GetMinimum(): number {
    if (this.FabrikantenFilterResponseModel != undefined && this.FabrikantenFilterResponseModel.SelectedProduct != undefined) {

      if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 1) { // vloer
        return 3.7;
      }

      if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 2) { // vlak
        return 6.3;
      }

      if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 3) { // 
        return 6.3;
      }

      if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 4) { // spouw
        return 4.7;
      }

      if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 7) { //
        return 6.3;
      }
    }

    return 0;
  }

  public ShouldDisplayFreeLambda(filter: IFilterViewModel): boolean {
    if (filter != undefined) {
      if (filter.SelectedOptionId == "30") // Vrije invoer
      {
        return true;
      }

      if (filter.SelectedOptionId == "702") // Vrije invoer
      {
        return true;
      }
    }

    return false;
  }

  public ShouldDisplayNoDikte(filter: IFilterViewModel): boolean {
    if (filter != undefined) {
      if (filter.SelectedOptionId == "47") // Geen luchtspouw
      {
        return true;
      }
    }

    return false;
  }

  public GetAfschotTBVAfschot(): boolean {
    if (this.FabrikantenFilterResponseModel != undefined) {
      if (this.FabrikantenFilterResponseModel.SelectedProduct != undefined) {
        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 7) {
          return true;
        }
      }
    }

    return false;
  }

  public GetProductImage(): string {

    if (this.FabrikantenFilterResponseModel != null) {
      if (this.FabrikantenFilterResponseModel.SelectedProduct != null) {

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 1) { // vloer
          return "/Content/KlantSpecifiek/BCB21097/plaatjes/vloerplaat.jpg";
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 2) { // berekening plat dak constructie met vlakke isolatieplaten conform NTA 8800
          var materiaal_filter: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, filter => { return filter.Name == "Isolatie_Materiaal"; });

          if (materiaal_filter?.SelectedOption?.Name != null) {
            return materiaal_filter.SelectedOption.Image;
          }
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 3) { // berekening plat dak constructie met afschotsysteem conform NTA 8800
          var materiaal_filter: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, filter => { return filter.Name == "Representatie afschot isolatie_Materiaal"; });

          if (materiaal_filter?.SelectedOption?.Name != null) {
            return materiaal_filter.SelectedOption.Image;
          }
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 4) { // berekening spouwmuurconstructie conform NTA 8800
          var materiaal_filter: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, filter => { return filter.Name == "Isolatie_Materiaal"; });

          if (materiaal_filter?.SelectedOption?.Name != null) {
            if (materiaal_filter.SelectedOption.Name.toLowerCase().indexOf("isofort") != -1) {
              return "/Content/KlantSpecifiek/BCB21097/plaatjes/IsoFort.jpg";
            }

            if (materiaal_filter.SelectedOption.Name.toLowerCase().indexOf("polyfortpro") != -1) {
              return "/Content/KlantSpecifiek/BCB21097/plaatjes/PolyFort Pro.jpg";
            }
          }
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 7) { // berekening plat dak constructie met afschotsysteem conform NTA 8800 tbv afschot
          var materiaal_filter: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, filter => { return filter.Name == "Representatie afschot isolatie_Materiaal"; });

          if (materiaal_filter?.SelectedOption?.Name != null) {
            return materiaal_filter.SelectedOption.Image;
          }
        }
      }
    }

    return "";
  }

  public GetProductLink(): string {

    if (this.FabrikantenFilterResponseModel != null) {
      if (this.FabrikantenFilterResponseModel.SelectedProduct != null) {

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 1) { // vloer
          return "https://www.isobouw.nl/nl/producten/vloer-isoleren/";
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 2) { // berekening plat dak constructie met vlakke isolatieplaten conform NTA 8800
          var materiaal_filter: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, filter => { return filter.Name == "Isolatie_Materiaal"; });

          if (materiaal_filter?.SelectedOption?.Name != null) {
            return materiaal_filter.SelectedOption.Description;
          }
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 3) { // berekening plat dak constructie met afschotsysteem conform NTA 8800
          var materiaal_filter: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, filter => { return filter.Name == "Representatie afschot isolatie_Materiaal"; });

          if (materiaal_filter?.SelectedOption?.Name != null) {
            return materiaal_filter.SelectedOption.Description;
          }
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 4) { // berekening spouwmuurconstructie conform NTA 8800
          var materiaal_filter: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, filter => { return filter.Name == "Isolatie_Materiaal"; });

          if (materiaal_filter?.SelectedOption?.Name != null) {
            if (materiaal_filter.SelectedOption.Name.toLowerCase().indexOf("isofort") != -1) {
              return "https://www.isobouw.nl/nl/producten/spouwmuur-isoleren/isofort-spouwmuurisolatie/";
            }

            if (materiaal_filter.SelectedOption.Name.toLowerCase().indexOf("polyfortpro") != -1) {
              return "https://www.isobouw.nl/nl/producten/spouwmuur-isoleren/polyfort-pro-spouwisolatie/";
            }
          }
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 7) { // berekening plat dak constructie met afschotsysteem conform NTA 8800 tbv afschot
          var materiaal_filter: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, filter => { return filter.Name == "Representatie afschot isolatie_Materiaal"; });

          if (materiaal_filter?.SelectedOption?.Name != null) {
            return materiaal_filter.SelectedOption.Description;
          }
        }
      }
    }

    return "";
  }

  public GetBestekLink(): string {
    if (this.FabrikantenFilterResponseModel != null) {
      if (this.FabrikantenFilterResponseModel.SelectedProduct != null) {

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 1) { // vloer
          return "https://bestekservice.isobouw.nl/nl-nl/vloer/g19/";
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 2) { // berekening plat dak constructie met vlakke isolatieplaten conform NTA 8800
          var materiaal_filter: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, filter => { return filter.Name == "Isolatie_Materiaal"; });

          if (materiaal_filter?.SelectedOption?.Name != null) {
            return materiaal_filter.SelectedOption.DescriptionShort;
          }
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 3) { // berekening plat dak constructie met afschotsysteem conform NTA 8800
          var materiaal_filter: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, filter => { return filter.Name == "Representatie afschot isolatie_Materiaal"; });

          if (materiaal_filter?.SelectedOption?.Name != null) {
            return materiaal_filter.SelectedOption.DescriptionShort;
          }
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 4) { // berekening spouwmuurconstructie conform NTA 8800
          var materiaal_filter: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, filter => { return filter.Name == "Isolatie_Materiaal"; });

          if (materiaal_filter?.SelectedOption?.Name != null) {
            if (materiaal_filter.SelectedOption.Name.toLowerCase().indexOf("isofort") != -1) {
              return "https://bestekservice.isobouw.nl/nl-nl/gevel/isobouw-isofort/p4/";
            }

            if (materiaal_filter.SelectedOption.Name.toLowerCase().indexOf("polyfortpro") != -1) {
              return "https://bestekservice.isobouw.nl/nl-nl/gevel/isobouw-polyfortpro/p7/";
            }
          }
        }

        if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 7) { // berekening plat dak constructie met afschotsysteem conform NTA 8800 tbv afschot
          var materiaal_filter: IFilterViewModel = _.find(this.FabrikantenFilterResponseModel.Filters, filter => { return filter.Name == "Representatie afschot isolatie_Materiaal"; });

          if (materiaal_filter?.SelectedOption?.Name != null) {
            return materiaal_filter.SelectedOption.DescriptionShort;
          }
        }
      }
    }

    return "";
  }

  public GetRCValue(): string {
    var rc = parseFloat(this.GetExcelValue("Rc Waarde", 2));

    return (Math.round(rc * 10) / 10) + "";
  }

  private SetDefaults(): void {

    if (this.GetFirstImportantFilter() == null) {
      if (this.FabrikantenFilterResponseModel != undefined) {
        _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
          if (filter.SelectedOption == null) {

            if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 4) { // spouw
              this.TryToSet(filter, "Binnenspouwblad_Materiaal", "Kalkzandsteen 1850 kg/m3");
              this.TryToSet(filter, "Isolatie_Materiaal", "PolyFortPro® 124");
              this.TryToSet(filter, "Luchtspouw (+ reflectie)_Materiaal", "Niet geventileerd");
              this.TryToSet(filter, "Bevestigers_Materiaal", "RVS Spouwanker 4,0");
              this.TryToSet(filter, "Buitenspouwblad_Materiaal", "Baksteen metselwerk (1900-2100 kg/m3)");
              this.TryToSet(filter, "Luchtspouw (+ reflectie)_Dikte", "≥ 20mm");
              this.TryToSet(filter, "Bevestigers_Aantal", "6");
            }

            if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 1) { // vloer
              this.TryToSet(filter, "Isolatie_Materiaal", "EPS 100 SE");
              this.TryToSet(filter, "Isolatie_Dikte", "130");
              //this.TryToSetRange(filter, "Constructieve vloer_Dikte", 200);
              //this.TryToSetRange(filter, "Afwerkvloer_Dikte", 50);
            }

            if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 2) {
              this.TryToSet(filter, "Isolatie_Materiaal", "PolyTop HR (Flex) (100SE)");
              this.TryToSet(filter, "Isolatie_Dikte", "195");
              this.TryToSet(filter, "Extra isolerende onderlaag (indien van toepassing)_Materiaal", "-");
              this.TryToSet(filter, "Bevestigers aantal en diameter_Materiaal", "RVS");
              this.TryToSet(filter, "Bevestigers aantal en diameter_Aantal", "4");
              this.TryToSet(filter, "Bevestigers aantal en diameter_Dikte", "4,2");
              this.TryToSet(filter, "Onderconstructie_Materiaal", "Staal");
              this.TryToSetRange(filter, "Onderconstructie_Dikte", 0.75);
              this.TryToSet(filter, "Verlaagd plafond met luchtlaag_Materiaal", "Nee");
            }

            if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 3) { // afschotsysteem zonder plan

              this.TryToSet(filter, "Representatie afschot isolatie_Materiaal", "PolyTop HR (Flex) (100SE)");
              this.TryToSet(filter, "Onderconstructie_Materiaal", "Beton");
              this.TryToSetRange(filter, "Onderconstructie_Dikte", 220);
              this.TryToSet(filter, "Verlaagd plafond met luchtlaag_Materiaal", "Nee");
              this.TryToSetRange(filter, "Afschotvlak1_Lengte", 1);
              this.TryToSetRange(filter, "Afschotvlak1_Breedte", 1);
              this.TryToSetRange(filter, "Afschotvlak1_Dikte", 100);
              this.TryToSet(filter, "Afschotvlak1_Afschot", "2,0%");
            }

            if (this.FabrikantenFilterResponseModel.SelectedProduct.Id == 7) {
              this.TryToSet(filter, "Representatie afschot isolatie_Materiaal", "PolyTop HR (Flex) (100SE)");
              this.TryToSetRange(filter, "Representatie afschot isolatie_Dikte", 190);
              this.TryToSet(filter, "Extra isolerende onderlaag (indien van toepassing)_Materiaal", "-");
              this.TryToSet(filter, "Bevestigers aantal en diameter_Materiaal", "geen");
              this.TryToSet(filter, "Onderconstructie_Materiaal", "Beton");
              this.TryToSetRange(filter, "Onderconstructie_Dikte", 220);
              this.TryToSet(filter, "Verlaagd plafond met luchtlaag_Materiaal", "Nee");

            }

            if (filter.Options.length == 1) {
              filter.SelectedOption = filter.Options[0];
              filter.SelectedOptionId = filter.SelectedOption.Id.toString();
            }
          }
        });
      }
    }
  }

  private TryToSet(filter: IFilterViewModel, name: string, option_name: string) {
    if (filter.Name == name) {
      filter.SelectedOption = _.find(filter.Options, (option) => { return option.Name == option_name });

      if (filter.SelectedOption == null) {
        console.log(option_name + " niet gevonden!!");
      }
      else {
        filter.SelectedOptionId = filter.SelectedOption.Id.toString();
      }
    }
  }

  private TryToSetRange(filter: IFilterViewModel, name: string, option_name: number) {
    if (filter.Name == name) {
      if (filter.RangeValue == null) {
        filter.RangeValue = option_name;
      }
    }
  }

  private AddVlak(array: Array<DakDeel>, naam, naam_intern) {
    var Vlak = new DakDeel();
    Vlak.Naam = naam;
    Vlak.NaamIntern = naam_intern;

    array.push(Vlak);
  }
}

export class DakDeel {
  public Naam: string;
  public NaamIntern: string;
}
