/**
 * @ref WoonConnectViewModel/Survey/SurveyGeneric.cs
 */

 import * as DataContracts from "@datacontracts/SurveyGeneric";

 
import * as SurveyProperty from "./SurveyProperty";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.SurveyGeneric
	 */
	 

	export interface ISurveyGeneric {
		SurveyDateRegistration: SurveyProperty.ISurveyProperty;
		SurveyPerson: SurveyProperty.ISurveyProperty;
		SurveyDateVisit: SurveyProperty.ISurveyProperty;
		ActivitySourceBuildingData: SurveyProperty.ISurveyProperty;
		ActivityLevelSurvey: SurveyProperty.ISurveyProperty;
		ActivityReasonSubmit: SurveyProperty.ISurveyProperty;
		ToJsonContract(): DataContracts.ISurveyGeneric;
	}

	export class SurveyGeneric {
		// property-Properties
		public get SurveyDateRegistration(): SurveyProperty.ISurveyProperty {
			return this._SurveyDateRegistration;
		}
		public set SurveyDateRegistration(newSurveyDateRegistration: SurveyProperty.ISurveyProperty) {
			this._SurveyDateRegistration = newSurveyDateRegistration;
		}

		public get SurveyPerson(): SurveyProperty.ISurveyProperty {
			return this._SurveyPerson;
		}
		public set SurveyPerson(newSurveyPerson: SurveyProperty.ISurveyProperty) {
			this._SurveyPerson = newSurveyPerson;
		}

		public get SurveyDateVisit(): SurveyProperty.ISurveyProperty {
			return this._SurveyDateVisit;
		}
		public set SurveyDateVisit(newSurveyDateVisit: SurveyProperty.ISurveyProperty) {
			this._SurveyDateVisit = newSurveyDateVisit;
		}

		public get ActivitySourceBuildingData(): SurveyProperty.ISurveyProperty {
			return this._ActivitySourceBuildingData;
		}
		public set ActivitySourceBuildingData(newActivitySourceBuildingData: SurveyProperty.ISurveyProperty) {
			this._ActivitySourceBuildingData = newActivitySourceBuildingData;
		}

		public get ActivityLevelSurvey(): SurveyProperty.ISurveyProperty {
			return this._ActivityLevelSurvey;
		}
		public set ActivityLevelSurvey(newActivityLevelSurvey: SurveyProperty.ISurveyProperty) {
			this._ActivityLevelSurvey = newActivityLevelSurvey;
		}

		public get ActivityReasonSubmit(): SurveyProperty.ISurveyProperty {
			return this._ActivityReasonSubmit;
		}
		public set ActivityReasonSubmit(newActivityReasonSubmit: SurveyProperty.ISurveyProperty) {
			this._ActivityReasonSubmit = newActivityReasonSubmit;
		}

		// field-Properties

		// fields
		protected _SurveyDateRegistration: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _SurveyPerson: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _SurveyDateVisit: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _ActivitySourceBuildingData: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _ActivityLevelSurvey: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _ActivityReasonSubmit: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);

		constructor(data?: DataContracts.ISurveyGeneric) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISurveyGeneric): void {
			if(data) {
				// vul properties
				this._SurveyDateRegistration = data.SurveyDateRegistration ? new SurveyProperty.SurveyProperty(data.SurveyDateRegistration): undefined;
				this._SurveyPerson = data.SurveyPerson ? new SurveyProperty.SurveyProperty(data.SurveyPerson): undefined;
				this._SurveyDateVisit = data.SurveyDateVisit ? new SurveyProperty.SurveyProperty(data.SurveyDateVisit): undefined;
				this._ActivitySourceBuildingData = data.ActivitySourceBuildingData ? new SurveyProperty.SurveyProperty(data.ActivitySourceBuildingData): undefined;
				this._ActivityLevelSurvey = data.ActivityLevelSurvey ? new SurveyProperty.SurveyProperty(data.ActivityLevelSurvey): undefined;
				this._ActivityReasonSubmit = data.ActivityReasonSubmit ? new SurveyProperty.SurveyProperty(data.ActivityReasonSubmit): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISurveyGeneric {
			return {
				SurveyDateRegistration: (this.SurveyDateRegistration ? new SurveyProperty.SurveyProperty(this.SurveyDateRegistration).ToJsonContract(): this.SurveyDateRegistration),
				SurveyPerson: (this.SurveyPerson ? new SurveyProperty.SurveyProperty(this.SurveyPerson).ToJsonContract(): this.SurveyPerson),
				SurveyDateVisit: (this.SurveyDateVisit ? new SurveyProperty.SurveyProperty(this.SurveyDateVisit).ToJsonContract(): this.SurveyDateVisit),
				ActivitySourceBuildingData: (this.ActivitySourceBuildingData ? new SurveyProperty.SurveyProperty(this.ActivitySourceBuildingData).ToJsonContract(): this.ActivitySourceBuildingData),
				ActivityLevelSurvey: (this.ActivityLevelSurvey ? new SurveyProperty.SurveyProperty(this.ActivityLevelSurvey).ToJsonContract(): this.ActivityLevelSurvey),
				ActivityReasonSubmit: (this.ActivityReasonSubmit ? new SurveyProperty.SurveyProperty(this.ActivityReasonSubmit).ToJsonContract(): this.ActivityReasonSubmit)
			}
		}
	}
