//import * as HelpersBackwards from '.././Helpers/Backwards';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../infrastructure/app.configservice';
import { ISoftware }  from '@datacontracts/Constanten';
//test
export class ConsoleColor {

	public static Erik(): ConsoleColor {
		return new ConsoleColor('yellow', 'DarkOrange', '12px');
	}

	public static Erik2(): string {
		return 'color: yellow; background-color: DarkOrange; font-size: 12px;';
	}

	constructor(public color?: string, public backgroundColor?: string, public fontSize?: string) { };
	'ngInject';

	public Css(): string {
		var resultaat: string = '';

		if (this.color) {
			resultaat = `color: ${this.color};`;
		}

		if (this.backgroundColor) {
			resultaat += `background-color: ${this.backgroundColor};`;
		}

		if (this.fontSize) {
			resultaat += `font-size: ${this.fontSize};`;
		}

		if (resultaat !== "") {
			return resultaat;
		} else { // Waar zijn we mee bezig?
			return "text-shadow: 2px 2px #FF0000; font-size: 12px; text-decoration: underline;";
		}
	};
}

export interface IWctLogService {
	// Gesorteerd op "serverity"
	log(message?: any, ...optionalParams: any[]): void;
	color(message: string, color: string | ConsoleColor, ...optionalParams: any[]): void;
	// trace
	debug(message?: any, ...optionalParams: any[]): void;
	info(message?: any, ...optionalParams: any[]): void;
	warn(message?: any, ...optionalParams: any[]): void;
	error(error: Error, ...optionalParams: any[]): void;
	error(error: string, ...optionalParams: any[]): void;
	// fatal
}

@Injectable({
	providedIn: 'root',
})
export class WctLogService implements IWctLogService {
	
	Const: ISoftware;

	/**
	 * Om in chrome de juiste bron bestand en regel nummer te zien dien je "WctLogService.*" te blacklisten in
	 * de Developer tool (debugger). F1 -> Blackboxing
	 * Deze truck werkt niet in Fire Fox
	 */

	constructor() {
		'ngInject';
	}

	private MoetLoggen(): boolean {
		if (this.Const == null)
		{
			return false;
		}
		return (this.Const.ConsoleLogging);
	}

	private MoetServerSideLoggen(): boolean {
		if (this.Const == null)
		{
			return false;
		}
		return (this.Const.ServerSideConsoleLogging);
	}

	private JL(): any {
		//return HelpersBackwards.JL('Angular');
	}

	private ServerTrace(message): any {
		//return this.JL().trace(message);
	}

	private ServerDebug(message): any {
		//return this.JL().debug(message);
	}

	private ServerInfo(message): any {
		//return this.JL().info(message);
	}

	private ServerError(message: any) {
		//return this.JL().error(message);
	}

	private ServerWarn(message: any) {
		//return this.JL().warn(message);
	}

	public ConsumerCall(url: string, operation: number, data: any) {
		this.debug("Webservice call " + url + " (" + operation + ")");
	}

	public ConsumerReturn(url: string, operation: number, data: any) {
		this.debug("Webservice return  " + url + " (" + operation + ")");
	}

	ConsumerError(url: string, operation: number, err: any): any {
		this.debug("Webservice error  " + url + " (" + operation + ")");
	}

	public log(message?: any, ...optionalParams: any[]): void {
		if (this.MoetServerSideLoggen) {
			this.ServerTrace(message);
		}
		if (this.MoetLoggen()) {
			console.log(message, ...optionalParams);
		}
	};

	private FormatKleurTekst(tekst: string): string {
		if ((tekst.indexOf('%c', 0) !== 0) && (tekst.indexOf('%C', 0) !== 0)) {
			return '%c' + tekst;
		}
		return tekst;
	}

	public color(message: string, color: string | ConsoleColor, ...optionalParams: any[]): void {
		var css: string = '';
		if (typeof color === "string") {
			css = color;
		} else {
			if (typeof color === "object") {
				if (color !== null) {
					css = color.Css();
				}
			}
		}

		if (css !== '') {
			this.log(this.FormatKleurTekst(message), css, ...optionalParams);
		} else {
			this.log(message, ...optionalParams);
		}
	};

	public debug(message?: any, ...optionalParams: any[]): void {
		if (this.MoetServerSideLoggen()) {
			this.ServerDebug(message);
		}

		if (this.MoetLoggen()) {
			console.log(message, ...optionalParams);
		}
	};

	public info(message?: any, ...optionalParams: any[]): void {
		if (this.MoetServerSideLoggen()) {
			this.ServerInfo(message);
		}
		if (this.MoetLoggen()) {
			console.info(message, ...optionalParams);
		}
	};

	public warn(message?: any, ...optionalParams: any[]): void {
		this.ServerWarn(message);
		if (this.MoetLoggen()) {
			console.warn(message, ...optionalParams);
		}
	};

	public error(error: Error, ...optionalParams: any[]): void;
	public error(error: string, ...optionalParams: any[]): void;
	error(error: any, ...optionalParams: any[]): void {
		this.ServerError(error);

		if (this.MoetLoggen()) {
			if (typeof error === "string") {
				console.error(error, ...optionalParams);

			} else if (typeof error == "undefined") {
				console.error("undefined error?");
			} else if (error instanceof Error) {
				try {
					//console.group();
					console.log(error.name, ...optionalParams);
					console.log(error.message);
					if (error.stack != null) {
						console.error(error.stack);
					}
				} catch (Error) {
					console.log("Console logging error: " + Error);
				}

				//console.groupEnd();
			}
		}
	}
}