// [conv] import "(./WctDocumentMap).less";
import * as _ from 'lodash';
import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { TypeRecht } from '@enums/RechtenRegel';
import { WctDocumentMapPopupComponent } from '../../components/documenten/WctDocumentMapPopup';
import { IDocumentModel } from '@datacontracts/DocumentModel';
import { MapType } from '@enums/IDocumentRepository';
import { ConstSoftware } from '../../Helpers/Backwards';
import { DocumentService } from '../../services/DocumentService';
import { IDocumentMapModel } from '@datacontracts/DocumentMapModel';
import { AppConfigService } from '../../infrastructure/app.configservice';

@Component({
	selector: 'wct-document-map',
	templateUrl: './WctDocumentMap.html'
})

export class WctDocumentMapComponent {
	@Input() folder: IDocumentMapModel;
	@Input() public openFirstSubmap: boolean;
	@Input() public isFirstSubmap: string;
	public sortedDocuments: IDocumentModel[];

	constructor(
		private documentService: DocumentService,
		private dialog: MatDialog,
		private config: AppConfigService,
	) {
	}

	ngOnInit() {

		let documenten = null;
		if (this.folder != null)
		{
			documenten = this.folder.Documenten;
		}

		this.sortedDocuments = this.sortDocuments(documenten);
		if (this.openFirstSubmap && (this.isFirstSubmap == "true")) {
			this.documentService.OpenMap(this.folder);
		}
	}

	public getDisplayName(): string {
		if (this.folder != undefined) {
			if (this.isAdmin() || (this.folder.DisplayNameForBewoner == undefined)) {
				return this.folder.Naam;
			}
			else {
				return this.folder.DisplayNameForBewoner;
			}
		}
		else {
			return "";
		}
	}

	private isAdmin(): boolean {
		return this.config.Software.Rollen.toLowerCase().indexOf("beheerder") != -1;
	}

	private expandOrCollapseFolder(): void {
		if (this.documentService.IsOpen(this.folder)) {
			if (this.activeDocumentIsChildOfFolder() == false) {
				this.documentService.SluitMap(this.folder);
			}
		}
		else {
			this.documentService.OpenMap(this.folder);
		}
	}

	private activeDocumentIsChildOfFolder(): boolean {
		let ret = false;

		if (this.documentService.activeDocumentId != null) {
			if (this.documentService.activeFolderId = this.folder.Id) {
				ret = true;
			}
		}

		return ret;
	}

	public getChildFolders(): IDocumentMapModel[] {
		var beheerder = this.isAdmin();

		var folders = new Array<IDocumentMapModel>();
		var visibleFolders = new Array<IDocumentMapModel>();

		if (this.folder != undefined) {
			folders = this.documentService.GeefZichtbareChildMappen(this.folder);
		}
		
		_.each(folders, (folder: IDocumentMapModel) => {
			if (!folder.Verbergen) {
				if (beheerder || this.isFolderAllowedInCurrentState(folder)) {
					visibleFolders.push(folder);
				}
			}
		});

		return visibleFolders;
	}

	public isFolderAllowedInCurrentState(folder: IDocumentMapModel): boolean {
		let ret: boolean = false;

		if (window.location.href.includes('*.Nu.**')) {
			if (folder.MapType != MapType.IngediendeOpties && folder.MapType != MapType.Offertes) {
				ret = true;
			}
		} else {
			if (folder.MapType == MapType.IngediendeOpties || folder.MapType == MapType.Offertes) {
				ret = true;
			}
		}

		return ret;
	}

	public isOpen(): boolean {
		return this.documentService.IsOpen(this.folder);
	}

	public selectFolder(): void {
		this.expandOrCollapseFolder();
		if (this.documentService.IsOpen(this.folder)) {
			this.documentService.ZetActieveMap(this.folder);
		}
		
	}

	public selectedFolder(): boolean {
		if (this.folder != undefined) {
			return this.documentService.activeFolderId == this.folder.Id;
		}
		else {
			return false;
		}
	}

	public selectDocument() {
		this.documentService.activeFolderId = this.folder.Id;
	}

	public selectedDocument(doc: IDocumentModel): boolean {
		if (this.folder != undefined) {
			return this.documentService.activeDocumentId == doc.Id;
		}
		else {
			return false;
		}
	}

	public documentIsSelected() {
		let ret = false;
		if (this.documentService.activeDocumentId !== null) {
			ret = true;
		}
		return ret;
	}

	public openPopup(parentFolderId: number, folderId: number): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			parentFolderId: parentFolderId,
			folderId: folderId,
			cause: 'editFolder'
		}
		this.dialog.open(WctDocumentMapPopupComponent, dialogConfig);
	}

	public deleteFolder(parentFolderId: number, folderId: number): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			parentFolderId: parentFolderId,
			folderId: folderId,
			cause: 'deleteFolder'
		}
		this.dialog.open(WctDocumentMapPopupComponent, dialogConfig);
	}

	public uploadFiles(files): void {
		this.documentService.UploadFiles(this.folder, files);
	}

	public canAddFolder(): boolean {
		if (this.folder != undefined) {
			for (var i = 0; i < this.folder.Rechten.RechtenVoorGebruiker.length; i++) {
				if (this.folder.Rechten.RechtenVoorGebruiker[i] == TypeRecht.MapBeheer) {
					return true;
				}
			}
		}

		return false;
	}

	public isFolderAdmin(): boolean {
		if (this.folder != undefined) {
			for (var i = 0; i < this.folder.Rechten.RechtenVoorGebruiker.length; i++) {
				if (this.folder.Rechten.RechtenVoorGebruiker[i] == TypeRecht.MapBeheer) {
					return true;
				}
			}
		}

		return false;
	}

	public canEdit(): boolean {
		if (this.folder != undefined) {
			if (this.folder.IsRoot) {
				return false;
			}

			for (var i = 0; i < this.folder.Rechten.RechtenVoorGebruiker.length; i++) {
				if (this.folder.Rechten.RechtenVoorGebruiker[i] == TypeRecht.Toevoegen) {
					return true;
				}
			}
		}

		return false;
	}

	public hasSubFolders(): boolean {
		let ret: boolean = false;
		if (this.folder == undefined) {
			return ret;
		}
		let subFolders = this.getChildFolders();
		if (subFolders !== undefined) {
			if (subFolders.length > 0) {
				ret = true;
			}
		}

		return ret;
	}

	public showEmptyText(): boolean {
		let ret = false;
		if (this.folder != undefined) {
			if ((this.folder.Naam !== "Hoofdmap") && (this.folder.ChildMappen.length == 0) && (this.folder.Documenten.length == 0)) {
				ret = true;
			}
		}

		return ret;
	}

	public isFirstSubfolder(submap: IDocumentMapModel): boolean {
		let ret: boolean = false;
		if (this.getChildFolders().length > 0) {
			if (submap == this.getChildFolders()[0]) {
				ret = true;
			}
		}
		return ret;
	}

	public uploadDateFunction(document: IDocumentModel) {
		let ret: string = "";

	 	if (document != undefined && document.UploadDate != undefined) {
			ret = document.UploadDate.replace(/-/g, " ");
	 	}

		ret = ret.replace(/:/g, " ");
		var arrayDate: Array<string> = ret.split(' ');
		let d = new Date(Number(arrayDate[2]), Number(arrayDate[1]), Number(arrayDate[0]), Number(arrayDate[3]), Number(arrayDate[4]));
		return d;
	}

	private sortDocuments(documents: IDocumentModel[]): IDocumentModel[] {
		return _.sortBy(documents, (document => document.UploadDate)).reverse();
	}
}
