import { Component, OnInit, Inject, Input, Injector } from '@angular/core';

@Component({
	selector: 'wct-button',
  templateUrl: './WctButton.html',
  styleUrls: ['./WctButton.scss']
})

export class WctButton {
	@Input() public buttonText: string = "";
	@Input() public buttonAlign: string = "left";
	@Input() public buttonSize: string = "small";
	@Input() public buttonDisabled: string = "false";
	@Input() public buttonRole: string = "primary";
	@Input() public buttonBusy: string = "false";
	@Input() public buttonIconFa: string = "";
	@Input() public buttonIconWct: string = "";
	@Input() public buttonOnlyIcon: string = "false";
	@Input() public buttonActive: string = "false";

	public buttonIconFaBoolean: boolean = false;
	public buttonIconWctBoolean: boolean = false;
	public buttonOnlyIconBoolean: boolean = false;
	public buttonDisabledBoolean: boolean = false;
	public buttonBusyBoolean: boolean = false;
	public buttonActiveBoolean: boolean = false;

	ngOnChanges() {
		this.buttonIconFaBoolean = this.buttonIconFa.length > 0;
		this.buttonIconWctBoolean = this.buttonIconWct.length > 0;
		this.buttonOnlyIconBoolean = this.buttonOnlyIcon == "true";
		this.buttonDisabledBoolean = this.buttonDisabled == "true";
		this.buttonBusyBoolean = this.buttonBusy == "true";
		this.buttonActiveBoolean = this.buttonActive == "true";
	}
}
