import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from '../../services/TranslateService';
import { AccountService } from '../../services/AccountService';
import { AppConfigService } from '../../infrastructure/app.configservice';
// Translation category: SIGNINGIN

@Component({
	selector: 'wct-login-popup',
	styleUrls: ['./WctLoginPopup.scss'],
	templateUrl: './WctLoginPopup.html'
})
export class WctLoginPopupComponent {
	oorzaak: string;
	melding: string;
	width: number;

	Emailadres: string = "";
	EmailadresBevestig: string = "";
	ToonActivatieFormulierHulp: boolean = false;
	BezigMetLaden: boolean = false;
	AntwoordModus: boolean = false;
	Antwoord: string = "";
	public existingAccount: boolean = true;
	public loginPopupWachtwoordVergeten = false;
	public email_verplicht = true;
	public naam: string = "e-mail adres";

	constructor(
		private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private accountservice: AccountService,
		private config: AppConfigService,
		private translateservice: TranslateService
	) {
		'ngInject';

		this.oorzaak = data.oorzaak;
		this.melding = data.melding;
		this.width = data.width;
		this.email_verplicht = this.config.Software.ActivatieEmailVerplicht;

		if (!this.email_verplicht) {
			this.naam = "inlognaam";
		}

		if (this.melding == null) {
			this.melding = '';
		}
		if (this.melding.indexOf("periode") > 0) {
			this.AntwoordModus = true;
		}

		if (this.oorzaak == null) {
			this.oorzaak = '';
		}

		if (this.oorzaak == "wachtwoordVergeten") {
			this.existingAccount = false;
		}
	}

	public getTxtTitle() {
		this.loginPopupWachtwoordVergeten = false;

		if (this.melding == "Uw e-mailadres is bevestigd.  U kunt nu inloggen met het e-mailadres en wachtwoord dat u tijdens activatie hebt ingevoerd.") {
			this.oorzaak = "activatiegeslaagd";
		}

		switch (this.oorzaak) {
			case "demoNietToegestaan":
				//return "Demo is niet toegestaan";
				var ret: string = this.translateservice.GetTranslation("SIGNINGIN", "DEMO-IS-NOT-ALLOWED"); //return "Demo is niet toegestaan";
				return ret;
			case "loginnaamFout":
				//return "Ongeldige loginnaam";
				var ret: string = this.translateservice.GetTranslation("SIGNINGIN", "INVALID-LOGIN-NAME"); //return "Ongeldige loginnaam";
				return ret;
			case "emailadresNogNietBevestigd":
				//return "Nog niet bevestigd e-mailadres";
				var ret: string = this.translateservice.GetTranslation("SIGNINGIN", "EMAIL-ADDRESS-NOT-CONFIRMED"); //return "Nog niet bevestigd e-mailadres";
				return ret;
			case "inlognaamOnbekend":
				//return "Onjuiste loginnaam of wachtwoord";
				var ret: string = this.translateservice.GetTranslation("SIGNINGIN", "INCORRECT-LOGIN-NAME-OR-PASSWORD"); //return "Onjuiste loginnaam of wachtwoord";
				return ret;
			case "teveelFouteInlogpogingen":
				//return "Te veel foute inlogpogingen";
				var ret: string = this.translateservice.GetTranslation("SIGNINGIN", "EXCLUDED-FROM-LOGGING-IN"); //return "Te veel foute inlogpogingen";
				return ret;
			case "uitgeslotenVanToegang":
				//return "Te veel foute inlogpogingen";
				var ret: string = this.translateservice.GetTranslation("SIGNINGIN", "EXCLUDED-FROM-LOGGING-IN"); //return "Te veel foute inlogpogingen";
				return ret;
			case "loginnaamOfWachtwoordFout":
				//return "Onjuiste loginnaam of wachtwoord";
				var ret: string = this.translateservice.GetTranslation("SIGNINGIN", "INCORRECT-LOGIN-NAME-OR-PASSWORD"); //return "Onjuiste loginnaam of wachtwoord";
				return ret;
			case "activatiegeslaagd":
				//return "Activatie is geslaagd";
				var ret: string = this.translateservice.GetTranslation("SIGNINGIN", "ACTIVATION-SUCCEEDED"); //return "Activatie is geslaagd";
				return ret;
			case "wachtwoordVergeten":
				this.loginPopupWachtwoordVergeten = true;
				this.existingAccount = false;
				//return "Wachtwoord vergeten";
				var ret: string = this.translateservice.GetTranslation("SIGNINGIN", "FORGOT-PASSWORD"); //return "Wachtwoord vergeten";
				return ret;
			default:
				var ret: string = this.oorzaak + " " + this.melding;
				return ret;
				break;
		}
	}

	public EmailadresHulp(): boolean {
		let inputCheck = false;

		if (this.Emailadres !== undefined) {
			if ((this.Emailadres.length === 0) && (this.ToonActivatieFormulierHulp)) {
				inputCheck = true;
			}
		}

		return inputCheck;
	}

	public EmailadresBevestigHulp(): boolean {
		let inputCheck = false;

		if (this.Emailadres !== undefined) {
			if ((this.Emailadres.length > 0) && (this.EmailadresBevestig.length > 0) && (this.Emailadres != this.EmailadresBevestig) && (this.ToonActivatieFormulierHulp)) {
				inputCheck = true;
			}
		}

		return inputCheck;
	}

	public ControleerVelden() {
		if ((this.AntwoordModus) || (!this.loginPopupWachtwoordVergeten)) {
			this.closeDialog();
		}
		else {
			this.ToonActivatieFormulierHulp = true;

			if ((this.EmailadresHulp() == false) && (this.Emailadres != undefined)) {
				this.sendEmail();
			} else {
				console.log("Error: nu zijn niet alle velden ingevuld of er is iets fout gegaan");
			}
		}
	}

	closeDialog(): void {
		this.dialog.closeAll();
	}

	sendEmail() {
		this.BezigMetLaden = true;

		this.accountservice.WachtwoordVergeten(this.Emailadres).then(b => {
			this.BezigMetLaden = false;
			this.AntwoordModus = true;
			this.Antwoord = b;
		});
	}
}
