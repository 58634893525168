import * as _ from 'lodash';
import * as ViewModelsWoningType from '.././Models/WoningType';
import * as ViewModelsWoningTypologieDeel from '.././Models/WoningTypologieDeel';




	export class WoningTypologie {
		Delen: { [deelid: string]: ViewModelsWoningTypologieDeel.WoningTypologieDeel; } = {};

		constructor(
			data: any,
			public Naam: string,
			public VoorGespiegeld: boolean,
			public WoningType: ViewModelsWoningType.WoningType,
		)
		{
			_.each(data.Delen, (d: any) => {
				var deel: ViewModelsWoningTypologieDeel.WoningTypologieDeel = new ViewModelsWoningTypologieDeel.WoningTypologieDeel(d);
				this.Delen[deel.DeelId] = deel;
			});
		}
	}
