// [conv] import "(./WctBeheerAccountMenu).less";
import * as _ from 'lodash';
import { Component, OnInit, Inject, ElementRef, HostListener, Input } from '@angular/core';
import { WoningEventService } from '../../services/WoningEventService';
import { AccountService, IAccountService } from '../../services/AccountService';
import { IWoningEventService } from '../../services/WoningEventService';
import { Woning } from '../../Models/Woning';
import * as rxjs from 'rxjs';
import { ConstSoftware } from '../../Helpers/Backwards';
import { TranslateService } from '../../services/TranslateService';
import { AppConfigService } from '../../infrastructure/app.configservice';
// import { WijzigDetectieService } from '../../services/WijzigDetectieService';


@Component({
	selector: 'wct-beheer-account-menu',
    templateUrl: './WctBeheerAccountMenu.html',
    styleUrls: ['./WctBeheerMenuHeader.scss']
})

export class WctBeheerAccountMenu  {
	private m_Subscriptions: Array<rxjs.Subscription> = [];

	public LoginName: string = "";
	public ShowDropdown: boolean = false;
	public chosenTab: string = "";
	public AccountSettingsAreShown = false;
	public HuidigeWoning: Woning;

	constructor(
		@Inject(AccountService) private accountservice: IAccountService,
		@Inject(WoningEventService) private woningeventservice: IWoningEventService,
		private eRef: ElementRef,
		private config: AppConfigService,
		// private wijzigdetectieservice: WijzigDetectieService
	) {
		'ngInject';
		this.LoginName = this.accountservice.GeefLoginNaam();
		this.HuidigeWoning = this.woningeventservice.GeefHuidigeWoning();
		this.m_Subscriptions.push(
			this.woningeventservice.DwellingId$.subscribe((d) => {
				this.HuidigeWoning = this.woningeventservice.GeefHuidigeWoning();
			})
		);
	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this.eRef.nativeElement.contains(event.target) == false) {
			if (this.ShowDropdown) {
				this.ToggleDropdown();
			}		
		}
	}

	ngOnDestroy(): void {
		this.m_Subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
	}

	public Logout(): void {
		//this.wijzigdetectieservice.ExecuteActionWithPermission().then(res => {
			//if (res) {
				window.location.href = "/Account/LogOff";
			//}
		//});
	}

	public ToggleDropdown(): void {
		this.ShowDropdown = !this.ShowDropdown;
		// this.wijzigdetectieservice.ExecuteActionWithPermission().then(res => {
		// 	console.log('wijzigdetectieservice: execute action with permission');
		// });
	}

	public OpenAccountSettings(chosenTab: string): void {
		this.chosenTab = chosenTab;
		this.ToggleDropdown();
		this.AccountSettingsAreShown = true;
	}

	public CloseAccountSettings(): void {
		this.AccountSettingsAreShown = false;
	}

	public HasRole(role: string) {
		let ret: boolean = false;
		if (this.accountservice.HeeftRol(role)) {
			ret = true;
		}
		return ret;
	}

	public ShowAddress(): boolean {
		let ret: boolean = false;
		if (this.HuidigeWoning != null) {
			if (
				(this.config.Software.Rollen.toLowerCase().indexOf("eigenaar") != -1) || (this.config.Software.Rollen.toLowerCase().indexOf("tester") != -1) ||
				(this.config.Software.Rollen.toLowerCase().indexOf("huurder") != -1)
			) {
				ret = true;
			}
		}
		return ret;
	}
}