<div class="cont-document-total" [ngClass]="{selected: isSelected(), notselected: !isSelected()}">
   <!-- <div [hidden]="!document.NogLeeg" class="flex-cont cont-progress-circular">
      <mat-progress-spinner mode="indeterminate" color="#0078be" strokeWidth="2" diameter="50"></mat-progress-spinner>
   </div> -->
   <div class="cont-document-icon-name" (click)="select()">
      <img [src]="getIconUrl()" />
      <p>{{filterDocumentName(document.Naam)}}</p>
      <!-- <div *ngIf="!document.NogLeeg && documentIsSelectedBoolean && (isFolderAdmin() || canDelete())" class="cont-bottomline"></div> -->
   </div>
   <div class="cont-document-icons" *ngIf="documentIsSelectedBoolean">
      <i *ngIf="!readOnly" (click)="downloadDocument()" class="wct-icon wct-icon-download-file"></i>
      <i *ngIf="isFolderAdmin()" (click)="openPopup()" class="wct-icon wct-icon-pencil-md"></i>
      <i *ngIf="canDelete()" (click)="openRemovePopup()" class="fal fa-trash-alt"></i>
   </div>
</div>

