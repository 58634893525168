/// <reference path='../consumerinterfaces/AccountController.ts' />
/**
 * @ref WoonConnectApiController/Account/AccountController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/AccountController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as AccountActivationModel from "@datacontracts/AccountActivationModel";
import * as ForgotPasswordModel from "@datacontracts/ForgotPasswordModel";
import * as ChangePasswordWithMailValidationModel from "@datacontracts/ChangePasswordWithMailValidationModel";
import * as request from "../../../helpers/IRequestHandler";





	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class AccountConsumer implements Interface.IAccountConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public UseActivationKey_Observable(inputModel: AccountActivationModel.IAccountActivationRequestModel & {ToJsonContract(noContent?: boolean): AccountActivationModel.IAccountActivationRequestModel}): Observable<AccountActivationModel.IAccountActivationResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/Account/UseActivationKey`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AccountActivationModel.IAccountActivationResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UseActivationKey(inputModel: AccountActivationModel.IAccountActivationRequestModel & {ToJsonContract(noContent?: boolean): AccountActivationModel.IAccountActivationRequestModel}): ng.IPromise<AccountActivationModel.IAccountActivationResponseModel> {
				let obs = this.UseActivationKey_Observable(inputModel);
				let promise = new Promise<AccountActivationModel.IAccountActivationResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ActivatieGegevensInvullen_Observable(inputModel: AccountActivationModel.IAccountActivationRequestModel & {ToJsonContract(noContent?: boolean): AccountActivationModel.IAccountActivationRequestModel}): Observable<AccountActivationModel.IAccountActivationResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/Account/ActivateAccount`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AccountActivationModel.IAccountActivationResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ActivatieGegevensInvullen(inputModel: AccountActivationModel.IAccountActivationRequestModel & {ToJsonContract(noContent?: boolean): AccountActivationModel.IAccountActivationRequestModel}): ng.IPromise<AccountActivationModel.IAccountActivationResponseModel> {
				let obs = this.ActivatieGegevensInvullen_Observable(inputModel);
				let promise = new Promise<AccountActivationModel.IAccountActivationResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public BevestigEmailAdres_Observable(code: string = null): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Account/BevestigEmailAdres`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public BevestigEmailAdres(code: string = null): ng.IPromise<string> {
				let obs = this.BevestigEmailAdres_Observable(code);
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ForgotPassword_Observable(inputModel: ForgotPasswordModel.IForgotPasswordRequestModel & {ToJsonContract(noContent?: boolean): ForgotPasswordModel.IForgotPasswordRequestModel}): Observable<ForgotPasswordModel.IForgotPasswordResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/Account/ForgotPassword`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ForgotPasswordModel.IForgotPasswordResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ForgotPassword(inputModel: ForgotPasswordModel.IForgotPasswordRequestModel & {ToJsonContract(noContent?: boolean): ForgotPasswordModel.IForgotPasswordRequestModel}): ng.IPromise<ForgotPasswordModel.IForgotPasswordResponseModel> {
				let obs = this.ForgotPassword_Observable(inputModel);
				let promise = new Promise<ForgotPasswordModel.IForgotPasswordResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ChangePasswordWithMailValidation_Observable(inputModel: ChangePasswordWithMailValidationModel.IChangePasswordWithMailValidationRequestModel & {ToJsonContract(noContent?: boolean): ChangePasswordWithMailValidationModel.IChangePasswordWithMailValidationRequestModel}): Observable<ChangePasswordWithMailValidationModel.IChangePasswordWithMailValidationResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/Account/ChangePasswordWithMailValidation`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ChangePasswordWithMailValidationModel.IChangePasswordWithMailValidationResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ChangePasswordWithMailValidation(inputModel: ChangePasswordWithMailValidationModel.IChangePasswordWithMailValidationRequestModel & {ToJsonContract(noContent?: boolean): ChangePasswordWithMailValidationModel.IChangePasswordWithMailValidationRequestModel}): ng.IPromise<ChangePasswordWithMailValidationModel.IChangePasswordWithMailValidationResponseModel> {
				let obs = this.ChangePasswordWithMailValidation_Observable(inputModel);
				let promise = new Promise<ChangePasswordWithMailValidationModel.IChangePasswordWithMailValidationResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}
