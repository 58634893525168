// [conv] import "(./FabBestekDetail).less";
import { Component } from '@angular/core';
import * as _ from 'lodash';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { BCBProductInputModel } from '@models/BCBProductModel';
import { BCBProductViewModel } from '@models/BCBProductModel';

@Component({
  selector: 'fab-bestek-detail',
  templateUrl: './FabBestekDetail.html',
  styleUrls: ['./FabBestekDetail.scss']
})

export class FabBestekDetailComponent {
  Laden: boolean = true;
  ProductId: number;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private fab: FabrikantenConsumer) {
    this.Laden = false;
  }
}
