<nav class="nav-menu-header">
	<ul class="links">
		
		<li *ngFor="let item of menuItems" (click)="clickMenu(item)" [ngClass]="{'actief': isActive(item)}">{{ item.Titel }}</li>
	</ul>
	<!-- *ngIf="showMenuItem(item.RedirectTarget)" -->
	<div *ngIf="showButton" class="cont-meer-menu-items" [ngClass]="{'cont-meer-menu-items-is-uitgeklapt': dropdownIsOpen, 'cont-meer-menu-items-is-hidden': showOnlyFirstMenuItem }" (click)="dropdownIsOpen = !dropdownIsOpen">
		<p class="txt-menu">Menu</p>
		<div class="col">
			<div class="con">
				<div class="bar top"></div>
				<div class="bar middle"></div>
				<div class="bar bottom"></div>
			</div>
		</div>
		<!-- <p class="txt-aantal-menuitems">{{AantalMenuItemsDieNietZichtbaarZijn}}</p> -->
	</div>
	<ul class="hidden-links" *ngIf="dropdownIsOpen"></ul>
</nav>

<!-- <nav class='nav-menu-header'>
	<ul class='links'>
		<li ng-repeat="item in $ctrl.menuItems" ng-if="$ctrl.ToonMenuItem(item.RedirectTarget)" ng-click="$ctrl.ClickMenu(item)" ng-class="{'actief' : $ctrl.IsActief(item)}">{{ item.Titel }}</li>
	</ul>

	<div *ngIf="showButton" class="cont-meer-menu-items" ng-class="{'cont-meer-menu-items-is-uitgeklapt' : $ctrl.DropdownIsUitgeklapt, 'cont-meer-menu-items-is-hidden' : $ctrl.GeefToonAlleenEersteMenuItem() }" ng-click="$ctrl.DropdownIsUitgeklapt = !$ctrl.DropdownIsUitgeklapt">
		<p class="txt-menu">Menu</p>
		<div class="col">
			<div class="con">
				<div class="bar top"></div>
				<div class="bar middle"></div>
				<div class="bar bottom"></div>
			</div>
		</div>
		<p class="txt-aantal-menuitems">{{$ctrl.AantalMenuItemsDieNietZichtbaarZijn}}</p>
	</div>
	<ul class='hidden-links ng-hide' ng-show="$ctrl.DropdownIsUitgeklapt"></ul>
</nav> -->