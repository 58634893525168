/**
 * @ref WoonConnectViewModel/ImageAnnotation/GetElementsModel.cs
 */

 import * as DataContracts from "@datacontracts/GetElementsModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetElementsRequestModel
	 */
	 

	export interface IGetElementsRequestModel {
		ProjectId: number;
		ToJsonContract(): DataContracts.IGetElementsRequestModel;
	}

	export class GetElementsRequestModel {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		// field-Properties

		// fields
		protected _ProjectId: number = 0;

		constructor(data?: DataContracts.IGetElementsRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetElementsRequestModel): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IGetElementsRequestModel {
			return {
				ProjectId: this.ProjectId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetElementsResponseModel
	 */
	 

	export interface IGetElementsResponseModel {
		elements: ImageAnnotationElement[];
	}

	export class GetElementsResponseModel {
		// property-Properties
		// field-Properties
		public get elements(): ImageAnnotationElement[] {
			return this._elements;
		}
		public set elements(newelements: ImageAnnotationElement[]) {
			this._elements = newelements;
		}

		// fields
		protected _elements: ImageAnnotationElement[] = [];

		constructor(data?: DataContracts.IGetElementsResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetElementsResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._elements = data.elements ? data.elements.map(imageAnnotationElementItem => new ImageAnnotationElement(imageAnnotationElementItem)): [];
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.ImageAnnotationElement
	 */
	 

	export interface IImageAnnotationElement {
		Type: string;
		Id: number;
		ToJsonContract(): DataContracts.IImageAnnotationElement;
	}

	export class ImageAnnotationElement {
		// property-Properties
		public get Type(): string {
			return this._Type;
		}
		public set Type(newType: string) {
			this._Type = newType;
		}

		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		// field-Properties

		// fields
		protected _Type: string = "";
		protected _Id: number = 0;

		constructor(data?: DataContracts.IImageAnnotationElement) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IImageAnnotationElement): void {
			if(data) {
				// vul properties
				this._Type = data.Type;
				this._Id = data.Id;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IImageAnnotationElement {
			return {
				Type: this.Type,
				Id: this.Id
			}
		}
	}
