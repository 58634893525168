<div *ngIf="DisplayDisclaimer" class="disclaimer-popup">
  <div class="disclaimer-popup-inner" (click)="DisplayDisclaimer = false;">
    Bovenstaand productadvies is geheel vrijblijvend. Het productadvies wordt zonder enige vorm van garantie verstrekt. Sto Isoned is niet aansprakelijk voor enige directe/ indirecte of gevolgschade voorvloeiende uit of verband houdende met eventuele onjuistheden, onvolledigheden, opgegeven maten, calculatieadviezen, adviezen betreffende de geschiktheid van het product in het project e.d. De gebruiker dient zelfstandig alle dimensies projectspecifiek te onderzoeken en vast te stellen, middels visuele keuring en voorts met gebruikmaking van al die technieken en hulpmiddelen die overeenkomstig de stand der wetenschap en techniek op het moment van verwerking in de branche gangbaar zijn. De gebruiker is zelfstandig verantwoordelijk voor het bepalen van de geschiktheid van het betreffende product in relatie tot de ondergrond, het project, de lokale omstandigheden, de constructieve eisen en condities, bouwkundige wetgeving en andere regels en voorschriften. De gebruiker zal in een voorkomend geval Sto Isoned vrijwaren voor alle aanspraken van derden die mogelijk voortvloeien uit het gebruik van de verwerkte en door Sto Isoned afgeleverde zaken alsmede door het gebruik van het door Sto Isoned uitgebrachte (vrijblijvende) technisch (applicatie) advies, dit alles in de meest ruime zin van het woord.
    <br />
    <span style="color: #0078be;">sluiten</span>
  </div>
</div>

<div *ngIf="FabFiltersIsShown()" class="fab-filters" [style.height.px]="GetMaximimumHeight()">
  <div *ngIf="Loading" class="mat-progress-spinner__cont" style="display: grid;">
    <div>
      <table style="height: 100%">
        <tr>
          <td>
            <table>
              <tr>
                <td style="text-align: center;"><mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner></td>
              </tr>
              <tr>
                <td><p>De producten worden geladen</p></td>
              </tr>
            </table>
          </td>
        </tr>

      </table>
    </div>
  </div>
  <!-- [perfectScrollbar]="config" -->
  <div *ngIf="FilterMenuDesktopIsShown" class="fab-filters__filter-menu">
    <div class="scroll-panel">
      <div *ngIf="ActiveProduct == ''" class="fab-filters__filter-titlebar" [ngClass]="{'fab-filters__filter-titlebar--disabled': FiltersSelected() == false}">
        <p>Filter</p>
        <p (click)="RemoveSelectedFilters()">Reset filters</p>
      </div>
      <div *ngIf="ActiveProduct !== ''" class="fab-filters__filter-titlebar" [ngClass]="{'fab-filters__filter-titlebar--disabled': FiltersSelected() == false}">
        <p>Eigenschappen</p>
        <p (click)="RemoveSelectedFilters()">Reset eigenschappen</p>
      </div>
      <div class="grid-container">
        <ng-container *ngFor="let filter of FabrikantenFilterResponseModel?.Filters">
          <div *ngIf="ShouldDisplay(filter)">
            <fab-filter [FilterViewModel]="filter" [IsFilterFirstLevel]="FabrikantenFilterResponseModel.SelectedProduct == undefined" [HeeftCADAbstract]="FabrikantenFilterResponseModel.SelectedProduct?.HeeftCADAbstract" (FilterChanged)="Refresh()" (ValueChanged)="SetSavedValue($event.filterviewmodelid, $event.savedvalue)"></fab-filter>
          </div>
        </ng-container>

      </div>
      <p class="fab-filters__explanation-text" *ngIf="!BlockedByProduct()"><span class="fab-filters__explanation-text-star">*</span> Deze velden zijn verplicht.</p>
      <!--<p class="fab-filters__explanation-text" *ngIf="((ActiveProduct?.length > 0) && !FabrikantenFilterResponseModel.SelectedProduct.HeeftCADAbstract) || (FabrikantenFilterResponseModel.DeeplinkModus == true)"><span class="fab-filters__explanation-text-number">1</span> Wijzigingen in deze optie(s) zijn niet zichtbaar in de 3D en 2D modellen.</p>-->
    </div>
  </div>
  <div *ngIf="!FilterMenuDesktopIsShown" class="fab-filters__filter-menu-mobile" [ngClass]="{'fab-filters__filter-menu-mobile--is-shown' : FilterMenuMobileIsShown}">
    <div class="fab-filters__filter-menu-background" [ngClass]="{'fab-filters__filter-menu-background--is-shown' : FilterMenuMobileBackgroundIsShown}">
      <div>
        <div class="fab-filters__icon-close-filter-menu" (click)="HideFilterMenuMobile()">
          <i class="fal fa-times"></i>
        </div>

        <div *ngFor="let filter of FabrikantenFilterResponseModel?.Filters">
          <fab-filter *ngIf="ShouldDisplay(filter)" [FilterViewModel]="filter" [IsFilterFirstLevel]="FabrikantenFilterResponseModel.SelectedProduct == undefined" [HeeftCADAbstract]="FabrikantenFilterResponseModel.SelectedProduct?.HeeftCADAbstract" (FilterChanged)="Refresh()" (ValueChanged)="SetSavedValue($event.filterviewmodelid, $event.savedvalue)"></fab-filter>
        </div>

        <p class="fab-filters__explanation-text"><span class="fab-filters__explanation-text-star">*</span> Deze velden zijn verplicht.</p>
        <!--<p class="fab-filters__explanation-text" *ngIf="((ActiveProduct?.length > 0) && !FabrikantenFilterResponseModel.SelectedProduct.HeeftCADAbstract) || (FabrikantenFilterResponseModel.DeeplinkModus == true)"><span class="fab-filters__explanation-text-number">1</span> Wijzigingen in deze optie(s) zijn niet zichtbaar in de 3D en 2D modellen.</p>-->

        <wct-button *ngIf="!FilterMenuDesktopIsShown" class="fab-filters__button-close-filter-menu" buttonRole="primary" buttonSize="small" (click)="HideFilterMenuMobile()">Toon resultaten</wct-button>
      </div>
    </div>
    <div (click)="HideFilterMenuMobile()" class="fab-filters__filter-menu-transparent-background" [style.height.px]="GetMaximimumHeightFilterMenu()"></div>
  </div>
  <div class="fab-filters__main" [ngClass]="{'fab-filters__main--full-width' : !FilterMenuDesktopIsShown}">
    <div class="fab-filters__breadcrumb">
      <wct-breadcrumb [levelOne]="ActiveCategory" *ngIf="(FabrikantenFilterResponseModel.DeeplinkModus != true)" [levelTwo]="ActiveProduct" (ongohome)="GoToChooseCategories()" (ongotolevelone)="DeselectActiveProduct()"></wct-breadcrumb>
      <span class="disclaimer" (click)="DisplayDisclaimer = true;">disclaimer</span>
    </div>

    <div class="fab-filters__button-filter-menu">
      <wct-button *ngIf="!FilterMenuDesktopIsShown" buttonRole="primary" buttonSize="small" buttonIconFa="fal fa-filter" (click)="ShowFilterMenuMobile()">Toon keuzemenu</wct-button>
    </div>

    <!-- [lastselectedview]="LastSelectedView"  -->
    <fab-product *ngIf="(FabrikantenFilterResponseModel.SelectedProduct !== undefined) && (FabrikantenFilterResponseModel.VerplichteWaardenIngevuld == true)" [product]="FabrikantenFilterResponseModel.SelectedProduct" (setlastselectedview)="SetLastSelectedView('Bestek')" (setlastselectedproduct)="SetLastSelectedProduct($event.selectedproduct)"></fab-product>

    <div *ngIf="FabrikantenFilterResponseModel.SelectedProduct == undefined">
      <h1>Productselectie</h1>
      <p class="fab-filters__product-selection-text">Maak uw keuze uit de filters om het passende producttype te verkrijgen, of selecteer direct onderstaand het producttype. Daarna kunt u de gewenste eigenschappen en opties aangeven.</p>
      <h2 *ngIf="FabrikantenFilterResponseModel.ShowLeftOverProducts && FabrikantenFilterResponseModel.LeftOverProducts.length > 0">Advies</h2>
      <div class="fab-filters__product-selection-buttons">
        <div *ngFor="let product of FabrikantenFilterResponseModel.Products" (click)="SelectProduct(product)" class="product">
          <img style="max-width: 200px;" src="{{ GetFirstImage(product) }}" />
          <div class="productname">
            {{ product.Naam }}
          </div>
          <div class="productnamesub">
            {{ product.Description }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(FabrikantenFilterResponseModel.VerplichteWaardenIngevuld != true) && (FabrikantenFilterResponseModel.SelectedProduct != undefined)">
      <h1 *ngIf="(FabrikantenFilterResponseModel.DeeplinkModus != true)">Productconfiguratie</h1>
      <h1 *ngIf="(FabrikantenFilterResponseModel.DeeplinkModus == true)">Productconfiguratie {{ FabrikantenFilterResponseModel.SelectedProduct.Naam }}</h1>
      <p class="fab-filters__product-selection-text">Vul eerst de gewenste eigenschappen in.</p>
    </div>
  </div>
</div>
