/**
 * @ref WoonConnectViewModel/Menu/GetMenusModel.cs
 */

 import * as DataContracts from "@datacontracts/GetMenusModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Menu.GetMenusRequestModel
	 */
	 

	export interface IGetMenusRequestModel {
		ProjectId: number;
		ToJsonContract(): DataContracts.IGetMenusRequestModel;
	}

	export class GetMenusRequestModel {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		// field-Properties

		// fields
		protected _ProjectId: number = 0;

		constructor(data?: DataContracts.IGetMenusRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetMenusRequestModel): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IGetMenusRequestModel {
			return {
				ProjectId: this.ProjectId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Menu.GetMenusResponseModel
	 */
	 

	export interface IGetMenusResponseModel {
		Menus: MenuDetails[];
	}

	export class GetMenusResponseModel {
		// property-Properties
		public get Menus(): MenuDetails[] {
			return this._Menus;
		}
		public set Menus(newMenus: MenuDetails[]) {
			this._Menus = newMenus;
		}

		// field-Properties

		// fields
		protected _Menus: MenuDetails[] = [];

		constructor(data?: DataContracts.IGetMenusResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetMenusResponseModel): void {
			if(data) {
				// vul properties
				this._Menus = data.Menus ? data.Menus.map(menuDetailsItem => new MenuDetails(menuDetailsItem)): [];
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Menu.MenuDetails
	 */
	 

	export interface IMenuDetails {
		ProjectId: number;
		Titel: string;
		Niveau: number;
		ToolTip: string;
		Type: string;
		Link: string;
		RedirectTarget: string;
		Image: string;
		SubMenus: MenuDetails[];
		AanUit: boolean;
		Volgorde: number;
		MenuItemId: number;
		TitelAanpasbaar: boolean;
		ProjectFunctie: string;
		isClickable: boolean;
	}

	export class MenuDetails {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		public get Titel(): string {
			return this._Titel;
		}
		public set Titel(newTitel: string) {
			this._Titel = newTitel;
		}

		public get Niveau(): number {
			return this._Niveau;
		}
		public set Niveau(newNiveau: number) {
			this._Niveau = newNiveau;
		}

		public get ToolTip(): string {
			return this._ToolTip;
		}
		public set ToolTip(newToolTip: string) {
			this._ToolTip = newToolTip;
		}

		public get Type(): string {
			return this._Type;
		}
		public set Type(newType: string) {
			this._Type = newType;
		}

		public get Link(): string {
			return this._Link;
		}
		public set Link(newLink: string) {
			this._Link = newLink;
		}

		public get RedirectTarget(): string {
			return this._RedirectTarget;
		}
		public set RedirectTarget(newRedirectTarget: string) {
			this._RedirectTarget = newRedirectTarget;
		}

		public get Image(): string {
			return this._Image;
		}
		public set Image(newImage: string) {
			this._Image = newImage;
		}

		public get SubMenus(): MenuDetails[] {
			return this._SubMenus;
		}
		public set SubMenus(newSubMenus: MenuDetails[]) {
			this._SubMenus = newSubMenus;
		}

		public get AanUit(): boolean {
			return this._AanUit;
		}
		public set AanUit(newAanUit: boolean) {
			this._AanUit = newAanUit;
		}

		public get Volgorde(): number {
			return this._Volgorde;
		}
		public set Volgorde(newVolgorde: number) {
			this._Volgorde = newVolgorde;
		}

		public get MenuItemId(): number {
			return this._MenuItemId;
		}
		public set MenuItemId(newMenuItemId: number) {
			this._MenuItemId = newMenuItemId;
		}

		public get TitelAanpasbaar(): boolean {
			return this._TitelAanpasbaar;
		}
		public set TitelAanpasbaar(newTitelAanpasbaar: boolean) {
			this._TitelAanpasbaar = newTitelAanpasbaar;
		}

		public get ProjectFunctie(): string {
			return this._ProjectFunctie;
		}
		public set ProjectFunctie(newProjectFunctie: string) {
			this._ProjectFunctie = newProjectFunctie;
		}

		public get isClickable(): boolean {
			return this._isClickable;
		}
		public set isClickable(newisClickable: boolean) {
			this._isClickable = newisClickable;
		}

		// field-Properties

		// fields
		protected _ProjectId: number = 0;
		protected _Titel: string = "";
		protected _Niveau: number = 0;
		protected _ToolTip: string = "";
		protected _Type: string = "";
		protected _Link: string = "";
		protected _RedirectTarget: string = "";
		protected _Image: string = "";
		protected _SubMenus: MenuDetails[] = [];
		protected _AanUit: boolean = false;
		protected _Volgorde: number = 0;
		protected _MenuItemId: number = 0;
		protected _TitelAanpasbaar: boolean = false;
		protected _ProjectFunctie: string = "";
		protected _isClickable: boolean = false;

		constructor(data?: DataContracts.IMenuDetails) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IMenuDetails): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				this._Titel = data.Titel;
				this._Niveau = data.Niveau;
				this._ToolTip = data.ToolTip;
				this._Type = data.Type;
				this._Link = data.Link;
				this._RedirectTarget = data.RedirectTarget;
				this._Image = data.Image;
				this._SubMenus = data.SubMenus ? data.SubMenus.map(menuDetailsItem => new MenuDetails(menuDetailsItem)): [];
				this._AanUit = data.AanUit;
				this._Volgorde = data.Volgorde;
				this._MenuItemId = data.MenuItemId;
				this._TitelAanpasbaar = data.TitelAanpasbaar;
				this._ProjectFunctie = data.ProjectFunctie;
				this._isClickable = data.isClickable;
				// vul fields
			}
		}
	}
