// [conv] import "(./WctDocumentenPopup).less";
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DocumentService } from '../../services/DocumentService';

@Component({
	selector: 'wct-documenten-popup',
	templateUrl: './WctDocumentenPopup.html'
})
export class WctDocumentenPopupComponent  {
	documentName: string;
	documentId: number;

	constructor(
		private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA)  private data,
		private documentService: DocumentService
	) {
		this.documentName = data.documentName;
		this.documentId = data.documentId;
	}

	closeDialog(): void {
		this.dialog.closeAll();
	}

	deleteDocument(): void {
		this.documentService.VerwijderDocument(this.documentId);
		this.dialog.closeAll();
	}
}