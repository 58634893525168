
<div *ngIf="Laden">
  Laden...
</div>

<div *ngIf="!Laden">
  <div class="container">
    <table style="width: 100%">
      <tr>
        <td>
          <div class="container--left">
            <div>
              <h1>Product Information: {{updateProduct.Name}}</h1>
              <fieldset [disabled]="update">
                <div class="row">
                  <table style="width: 100%;">
                    <tr>
                      <td>
                        <div>
                          <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Productnaam</mat-label>
                            <input matInput name="productNaam" [(ngModel)]="updateProduct.Name" ngModel required min-length="4" #name="ngModel">
                          </mat-form-field>
                        </div>
                      </td>
                      <td>
                        <div>
                          <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Omschrijving</mat-label>
                            <input matInput name="productDescription" [(ngModel)]="updateProduct.Description">
                          </mat-form-field>
                        </div>
                      </td>
                      <td>
                        <div>
                          <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Bot</mat-label>
                            <input matInput name="productBot" [(ngModel)]="updateProduct.Bot" ngModel required #bot="ngModel">
                          </mat-form-field>
                        </div>
                      </td>
                      <td>
                        <div>
                          <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Kenmerk</mat-label>
                            <input matInput name="productKenmerk" [(ngModel)]="updateProduct.Kenmerk" ngModel required #kenmerk="ngModel">
                          </mat-form-field>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>DO1 Type</mat-label>
                            <input matInput name="productD01Type" [(ngModel)]="updateProduct.DO1Type">
                          </mat-form-field>
                        </p>
                      </td>
                      <td>
                        <p>
                          <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{updateProduct.DO1Type}} (DO1)</mat-label>
                            <input matInput name="productD01Naam" [(ngModel)]="updateProduct.DO1Naam">
                          </mat-form-field>
                        </p>
                      </td>
                      <td>
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>DO2 Type</mat-label>
                          <input matInput name="productD02Type" [(ngModel)]="updateProduct.DO2Type">
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>{{updateProduct.DO2Type}} (DO2)</mat-label>
                          <input matInput name="productD02Naam" [(ngModel)]="updateProduct.DO2Naam">
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>DO3 Type</mat-label>
                          <input matInput name="productD03Type" [(ngModel)]="updateProduct.DO3Type">
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>{{updateProduct.DO3Type}} (DO3)</mat-label>
                          <input matInput name="productD03Naam" [(ngModel)]="updateProduct.DO3Naam">
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>DO4 Type</mat-label>
                          <input matInput name="productD04Type" [(ngModel)]="updateProduct.DO4Type">
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>{{updateProduct.DO4Type}} (DO4)</mat-label>
                          <input matInput name="productD04Naam" [(ngModel)]="updateProduct.DO4Naam">
                        </mat-form-field>
                      </td>
                    </tr>
                  </table>
                </div>
               
              </fieldset>

              <div class="row">
                <div class="item" style="justify-items:end;">
                  <div *ngIf="!update">
                    <button mat-raised-button color="primary" (click)="Update()">Update</button>
                  </div>
                  <div *ngIf="update">
                    <button mat-raised-button color="accent" (click)="update = !update">Wijzig</button>
                    <button mat-raised-button color="warn" (click)="Delete()">Verwijder</button>
                    <div>
                      <button mat-raised-button color="warn" (click)="Import()">Import</button>
                      <button mat-raised-button color="warn" (click)="ResetImportFlags()">ResetImportFlags</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <style>
              .rood {
                background-color: lightcoral !important;
              }
            </style>

            <div *ngFor="let variant of ProductViewModel.ProductVariant; let indexOfElement = index" [ngClass]="{ 'rood': !variant.Connected }">
              <a href="/fab-admin/product-variant/{{ variant.Id }}">go to</a>
              <fieldset [disabled]="variant.Id != editRowNumber" style="border:none; margin: 12px;">

                <div class="row variant-click" (click)="changeFilter(variant.Id)" (dblclick)="editRow(variant)" *ngIf="variant.Id !=editRowNumber">
                  <table>
                    <tr>
                      <td>
                        <p>{{indexOfElement + 1}}</p>
                      </td>
                      <td>
                        <div class="item">

                          <!-- (click)="goToVariant(variant.Id)" -->
                          <button mat-icon-button color="{{VariantId == variant.Id ? 'primary' : 'warn'}}" aria-label="Show Filter">
                            <i class="fas fa-circle"></i>

                          </button>
                        </div>
                      </td>
                      <td>
                        <div class="item">
                          <mat-form-field appearance="outline" style="width: 50px;" floatLabel="always" class="fab-admin__input_row">
                            <mat-label>Variant Id</mat-label>
                            <input matInput name="Name" readonly value="{{variant.Id}}">
                          </mat-form-field>
                        </div>
                      </td>
                      <td>
                        <div class="item">
                          <mat-form-field appearance="outline" floatLabel="always" class="fab-admin__input_row">
                            <mat-label>Variant Naam</mat-label>
                            <input matInput name="Name" value="{{variant.Name}}">
                          </mat-form-field>
                        </div>
                      </td>
                      <td>
                        <div class="item">
                          <mat-form-field appearance="outline" style="width: 200px;" floatLabel="always" class="fab-admin__input_row">
                            <mat-label>Kenmerk</mat-label>
                            <input matInput name="Kenmerk" style="width: 400px;" value="{{variant.Kenmerk}}">
                          </mat-form-field>
                        </div>
                      </td>
                      <td>
                        {{variant.DO1Type}}: {{variant.DO1Naam}} {{variant.DO2Type}}: {{variant.DO2Naam}} {{variant.DO3Type}}: {{variant.DO3Naam}} {{variant.DO4Type}}: {{variant.DO4Naam}}

                      </td>
                     
                    </tr>
                  </table>
                  
               
                  <div *ngIf="Importing">
                    <span style="color: red;" *ngIf="variant.Import == ''">X</span>
                    <span *ngIf="variant.Import == 'Bezig'">...</span>
                    <span style="color: green;" *ngIf="variant.Import == 'Klaar'">V</span>
                  </div>
                </div>
                <div class="row" *ngIf="variant.Id == editRowNumber">
                  <div class="item">

                    <button mat-icon-button color="{{VariantId == variant.Id ? 'primary' : 'warn'}}" aria-label="Show Filter" (click)="router.navigate(['/fab-admin/product-variant/', variant.Id]);">
                      <i class="fas fa-eye"></i>

                    </button>
                  </div>
                  <div class="item">
                    <mat-form-field appearance="outline" floatLabel="always" class="fab-admin__input_row">
                      <mat-label>Variant Naam</mat-label>
                      <input matInput name="Name" [(ngModel)]="fVariant.Name">
                    </mat-form-field>
                  </div>
                  <div class="item">
                    <mat-form-field appearance="outline" floatLabel="always" class="fab-admin__input_row">
                      <mat-label>Bot</mat-label>
                      <input matInput name="Bot" [(ngModel)]="fVariant.Bot">
                    </mat-form-field>
                  </div>
                  <div class="item">
                    <mat-form-field appearance="outline" floatLabel="always" class="fab-admin__input_row">
                      <mat-label>Kenmerk</mat-label>
                      <input matInput name="Kenmerk" [(ngModel)]="fVariant.Kenmerk">
                    </mat-form-field>
                  </div>
                  <div class="item">
                    <mat-form-field appearance="outline" floatLabel="always" class="fab-admin__input_row">
                      <mat-label>{{updateProduct.DO1Type}} (DO1)</mat-label>
                      <input matInput name="DO1Naam" [(ngModel)]="fVariant.DO1Naam">
                    </mat-form-field>
                  </div>
                  <div class="item">
                    <mat-form-field appearance="outline" floatLabel="always" class="fab-admin__input_row">
                      <mat-label>{{updateProduct.DO2Type}} (DO2)</mat-label>
                      <input matInput name="DO1Naam" [(ngModel)]="fVariant.DO2Naam">
                    </mat-form-field>
                  </div>
                  <div class="item">
                    <mat-form-field appearance="outline" floatLabel="always" class="fab-admin__input_row">
                      <mat-label>{{updateProduct.DO3Type}} (DO3)</mat-label>
                      <input matInput name="DO1Naam" [(ngModel)]="fVariant.DO3Naam">
                    </mat-form-field>
                  </div>
                  <div class="item">
                    <mat-form-field appearance="outline" floatLabel="always" class="fab-admin__input_row">
                      <mat-label>{{updateProduct.DO4Type}} (DO4)</mat-label>
                      <input matInput name="DO1Naam" [(ngModel)]="fVariant.DO4Naam">
                    </mat-form-field>
                  </div>
                  <div class="item">
                    <button mat-icon-button color="success-icon" aria-label="Nieuwe variant toevoegen" (click)="saveRow()">
                      <i class="fas fa-check"></i>
                    </button>
                    <button mat-icon-button color="accent" aria-label="Stop toevoegen" (click)="editRowNumber = 0;">
                      <i class="fas fa-times"></i>
                    </button>
                    <button mat-icon-button color="warn" aria-label="Delete toevoegen" (click)="deleteVariant(variant.Id)">
                      <i class="fas fa-trash"></i>
                    </button>


                  </div>
                </div>
              </fieldset>
              <div style="font-size: 16px;">
                Filter: <span *ngFor="let filter of variant.FilterRelations">{{ filter.Name }}</span><br />
                Options: <span *ngFor="let filteroption of variant.FilterOptionsRelations">({{ filteroption.FilterName }}: {{ filteroption.Name }}),</span>
              </div>
            </div>
          </div>
        </td>
        <td style="vertical-align:top;">
          <div class="container--right">
            <mat-card>
              <mat-card-title>
                Filter
              </mat-card-title>
              <mat-card-content>
                <div>
                  <div *ngIf="!variantFilter">
                    <span>Filters worden weergevens van het hoofd product</span>
                    <filter-relation [ProductId]="ProductId"></filter-relation>
                  </div>
                  <div *ngIf="variantFilter">

                    <span (click)="variantFilter = !variantFilter">Filter weergave van {{VariantId}} - Ga terug naar hoofdproduct {{ updateProduct.Name}} </span>

                    <filter-variant-relation [ProductVariantId]="VariantId"></filter-variant-relation>
                  </div>

                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </td>
      </tr>
    </table>



  </div>




</div>

