
<div *ngIf="Laden">
    Laden...
</div>

<div *ngIf="!Laden">
    <div class="container">
        <div class="flex-item">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Product Property
                        </mat-panel-title>
                        <mat-panel-description>
                            {{ProductPropertyViewModel.Name}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form #form="ngForm">
                        <fieldset [disabled]="update" style="border:none; margin: 12px;">
                            <p>
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <mat-label>Product Property Name</mat-label>
                                    <input matInput name="productPropertyName" [(ngModel)]="ProductPropertyViewModel.Name">
                                </mat-form-field>
                            </p>
                            <p *ngIf="update ">
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <mat-label>Product Property Type</mat-label>
                                    <input matInput name="productPropertyType" [(ngModel)]="ProductPropertyViewModel.ProductPropertyType.Name">
                                </mat-form-field>
                            </p>
                            <p *ngIf="!update">
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <mat-label>Product Property Type</mat-label>
                                    <mat-select matNativeControl [(ngModel)]="ProductPropertyViewModel.ProductPropertyType.Id" [ngModelOptions]="{standalone: true}" ng-init="ProductPropertyType.Id = option[ProductPropertyViewModel.ProductPropertyType.Id]">
                                        <mat-option *ngFor="let propertytype of ProductPropertyTypesViewModel.ProductPropertyType" [value]="propertytype.Id">
                                            {{propertytype.Name}}
                                        </mat-option>
                                        <mat-option value="0">
                                            Voeg nieuwe type toe
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </p>
                        </fieldset>
                        <div *ngIf="!update">
                            <button mat-raised-button color="primary" (click)="Update()">Update</button>
                        </div>
                        <div *ngIf="update">
                            <button mat-raised-button color="accent" (click)="update = !update">Wijzig</button>
                            <!-- <button mat-raised-button color="warn" (click)="Delete()">Verwijder</button> -->

                        </div>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Product Property Options
                        </mat-panel-title>
                        <mat-panel-description>
                            geef product property options weer
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                            <h3>
                                Product Property Options
                                <button mat-icon-button color="primary" aria-label="Options Toevoegen" (click)="addOption = !addOption">
                                    <mat-icon>
                                        <i class="{{addOption ? 'fal fa-times' : 'fal fa-plus'}}"></i>
                                    </mat-icon>
                                </button>
                            </h3>
                            <mat-nav-list *ngIf="!addOption">
                                <mat-list-item *ngFor="let options of ProductPropertyOptionsViewModel.ProductPropertyOption">
                                    <a matLine href="fab-admin/product-property-option/{{options.Id}}">{{ options.Bot }} ({{ options.Id }})</a>

                                    <button mat-icon-button color="primary" aria-label="Product Property Verwijderen" (click)="deletePropertyOption(options.Id)">
                                        <mat-icon>
                                            <i class="fal fa-times"></i>
                                        </mat-icon>
                                    </button>
                                </mat-list-item>

                            </mat-nav-list>

                            <div *ngIf="addOption">
                                <form>
                                    <p>
                                        <mat-form-field appearance="outline" floatLabel="always">
                                            <mat-label>Bot</mat-label>
                                            <input matInput name="productNaam" [(ngModel)]="newProductPropertyOption.Bot" ngModel required min-length="4" #name="ngModel">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" floatLabel="always">
                                            <mat-label>Kenmerk</mat-label>
                                            <input matInput name="productKenmerk" [(ngModel)]="newProductPropertyOption.Kenmerk" ngModel required #kenmerk="ngModel">
                                        </mat-form-field>
                                    </p>

                                    <p>
                                        <mat-form-field appearance="outline" floatLabel="always">
                                            <mat-label>DO1 Type</mat-label>
                                            <input matInput name="productD01Type" [(ngModel)]="newProductPropertyOption.DO1Type">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" floatLabel="always">
                                            <mat-label>Do1 Naam</mat-label>
                                            <input matInput name="productD01Naam" [(ngModel)]="newProductPropertyOption.DO1Naam">
                                        </mat-form-field>
                                    </p>
                                    <p>
                                        <mat-form-field appearance="outline" floatLabel="always">
                                            <mat-label>DO2 Type</mat-label>
                                            <input matInput name="productD02Type" [(ngModel)]="newProductPropertyOption.DO2Type">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" floatLabel="always">
                                            <mat-label>DO2 Naam</mat-label>
                                            <input matInput name="productD02Naam" [(ngModel)]="newProductPropertyOption.DO2Naam">
                                        </mat-form-field>
                                    </p>
                                    <p>
                                        <mat-form-field appearance="outline" floatLabel="always">
                                            <mat-label>DO3 Type</mat-label>
                                            <input matInput name="productD03Type" [(ngModel)]="newProductPropertyOption.DO3Type">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" floatLabel="always">
                                            <mat-label>DO3 Naam</mat-label>
                                            <input matInput name="productD03Naam" [(ngModel)]="newProductPropertyOption.DO3Naam">
                                        </mat-form-field>
                                    </p>
                                    <p>
                                        <mat-form-field appearance="outline" floatLabel="always">
                                            <mat-label>DO4 Type</mat-label>
                                            <input matInput name="productD04Type" [(ngModel)]="newProductPropertyOption.DO4Type">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" floatLabel="always">
                                            <mat-label>DO4 Naam</mat-label>
                                            <input matInput name="productD04Naam" [(ngModel)]="newProductPropertyOption.DO4Naam">
                                        </mat-form-field>
                                    </p>

                                    <p>
                                        <button mat-raised-button color="primary" (click)="saveOption()">Voeg Options Toe</button>
                                    </p>
                                </form>

                                </div>

                        
                   

                </mat-expansion-panel>
            </mat-accordion>

            <div class="container">
              <table>
                  <tr>
                      <td>
                        ID
                      </td>
                      <td>
                          Product
                      </td>
                  </tr>
                  <tr *ngFor="let product of Products.Products">
                      <td>
                          {{product.Id}}
                      </td>
                      <td>
                        {{product.Name}}
                      </td>

                  </tr>
              </table>
            </div>
        </div>






      <div class="flex-item">
               <mat-card>
                   <mat-card-title>
                       Filters
                   </mat-card-title>
                   <mat-card-content>
                       <div style="padding:25px;">
                           Filters
                           <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                               <!-- This is the tree node template for leaf nodes -->
                               <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                                   <!-- use a disabled button to provide padding for tree leaf -->
                                   <button mat-icon-button disabled></button>
                                   <mat-checkbox class="checklist-leaf-node"
                                                 [checked]="node.Active"
                                                 (change)="filterChange($event.checked, node )">
                                       {{node.Name}}
                                   </mat-checkbox>

                               </mat-tree-node>
                               <!-- This is the tree node template for expandable nodes -->
                               <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>

                                   <button mat-icon-button matTreeNodeToggle
                                           [attr.aria-label]="'toggle ' + node.Name">
                                       <mat-icon class="mat-icon-rtl-mirror">
                                           <i class="{{treeControl.isExpanded(node) ? 'fal fa-chevron-down' : 'fal fa-chevron-right'}}"></i>
                                       </mat-icon>
                                   </button>
                                   {{node.Name}}
                               </mat-tree-node>
                           </mat-tree>
                       </div>
                   </mat-card-content>
               </mat-card>
                  
                
        </div>
    </div>

</div>