/**
 * @ref WoonConnectViewModel/ImageAnnotation/AddPhotoSetsToProjectModel.cs
 */

 import * as DataContracts from "@datacontracts/AddPhotoSetsToProjectModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.AddPhotoSetsToProjectRequestModel
	 */
	 

	export interface IAddPhotoSetsToProjectRequestModel {
		ProjectId: number;
		PhotoSetIds: number[];
		ToJsonContract(): DataContracts.IAddPhotoSetsToProjectRequestModel;
	}

	export class AddPhotoSetsToProjectRequestModel {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		public get PhotoSetIds(): number[] {
			return this._PhotoSetIds;
		}
		public set PhotoSetIds(newPhotoSetIds: number[]) {
			this._PhotoSetIds = newPhotoSetIds;
		}

		// field-Properties

		// fields
		protected _ProjectId: number = 0;
		protected _PhotoSetIds: number[] = [];

		constructor(data?: DataContracts.IAddPhotoSetsToProjectRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddPhotoSetsToProjectRequestModel): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				this._PhotoSetIds = data.PhotoSetIds;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAddPhotoSetsToProjectRequestModel {
			return {
				ProjectId: this.ProjectId,
				PhotoSetIds: this.PhotoSetIds
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.AddPhotoSetsToProjectResponseModel
	 */
	 

	export interface IAddPhotoSetsToProjectResponseModel {
		Success: boolean;
	}

	export class AddPhotoSetsToProjectResponseModel {
		// property-Properties
		// field-Properties
		public get Success(): boolean {
			return this._Success;
		}
		public set Success(newSuccess: boolean) {
			this._Success = newSuccess;
		}

		// fields
		protected _Success: boolean = false;

		constructor(data?: DataContracts.IAddPhotoSetsToProjectResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddPhotoSetsToProjectResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Success = data.Success;
			}
		}
	}
