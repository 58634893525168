import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { ProductsConsumer } from '@consumers/ProductsController';
import { ProductsViewModel, ProductVariantModel, ProductViewModel, IProductViewModel } from '@models/ProductsViewModel';
import { FilterOptionViewModel, IFilterOptionViewModel, FilterViewModel, IFilterViewModel } from '../../../../data/WoonConnectKern/Models/FilterViewModel';
import { PrijslijstConsumer } from '@consumers/PrijslijstController';
import { PrijsModuleService } from '@services/PrijsModuleService';
import { PrijsSchemasViewModel, PrijsSchemaViewModel, CategoriesViewModel, DownloadExcelViewModel, ExcelsViewModel, CategorieViewModel, RequestCategorieViewModel, FabUserViewModel, CookieViewModel, InfoViewModel } from '@models/PrijslijstViewModel';

@Component({
  selector: 'overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class OverviewComponent {

  public Loading = true;
  public LoadingUser = true;


  //public ProductViewModel: ProductViewModel = null;
  public DownloadExcelViewModel: DownloadExcelViewModel = null;
  //public ExcelsViewModel: ExcelsViewModel = null;


  //public ProductViewModelCache: Array<ProductViewModel> = new Array();
  public PhotoName: string = "";

  constructor(
    private fab: FabrikantenConsumer,
    private prijslijst: PrijslijstConsumer,
    private route: ActivatedRoute,
    public prijsmoduleservice: PrijsModuleService,
    public router: Router
  ) {
    //this.LoadCategorieen();
    // this.LoadPrijsSchemas();
    this.GetLoggedInUser();
    // this.LoadExcels();

    this.ValidateLoggedIn();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.get('categorieId') != null) {
        this.prijsmoduleservice.CategorieId = parseInt(params.get('categorieId'));

        this.RefreshPageIndex();
      }
    })
  }

  public IsLoading(): boolean {
    //return this.Loading || this.LoadingPrijsSchemas || this.LoadingUser;
    return this.prijsmoduleservice.Loading;
  }

  public Download(): void {
    this.Loading = true;
    this.DownloadExcelViewModel = null;

    var model = new RequestCategorieViewModel();
    model.ProductId = this.prijsmoduleservice.CategorieId;
    model.PrijsSchemaId = this.prijsmoduleservice.PrijsSchemaSelected.Id;

    this.prijslijst.GetExcelDownload_Observable(model).subscribe((data) => {
      this.DownloadExcelViewModel = new DownloadExcelViewModel(data);
      window.location.href = this.DownloadExcelViewModel.Path;
      this.Loading = false;
    });
  }

  public OpenPhotoDialog(filename: string) {
    this.PhotoName = filename;
  }

  public ShouldCenter(col: string): boolean {
    if (col.toLowerCase() == "omschrijving") {
      return false;
    }
    if (col.toLowerCase() == "type") {
      return false;
    }
    if (col.toLowerCase() == "systeem") {
      return false;
    }
    if (col.toLowerCase() == "bewerking") {
      return false;
    }
    return true;
  }

  public GetFilterName(filtername: string): string {
    if (filtername.indexOf("**") != -1) {
      var filtername_new = filtername.replace("**", "");
      var filtername_new = "<div class=\"tooltip tooltip-span\"><img src='/Content/Images/Information.png' /><span class=\"tooltiptext\">Op aanvraag, prijs indicatief en afhankelijk van kleur en hoeveelheid.</span></div>" + filtername_new;
      return filtername_new;
    }
    else {
      return filtername;
    }
    
  }

  public GetRotateCells(): boolean {
    return this.prijsmoduleservice.CategorieId == 1; // profielen
  }

  public GetForceSecondCol(): boolean {
    return this.prijsmoduleservice.CategorieId == 1 || this.prijsmoduleservice.CategorieId == 2; // profielen
  }

  public GetPagesAmount(): number {
    var Amount = 1;

    if (this.prijsmoduleservice.GetActiveCategorie() != null) {
      Amount = Math.floor(this.prijsmoduleservice.AllProducts.length / this.prijsmoduleservice.ProductsPerPage);

      if (this.prijsmoduleservice.AllProducts.length % this.prijsmoduleservice.ProductsPerPage > 0) {
        Amount++;
      }
    }

    if (Amount == 0) {
      Amount = 1;
    }

    return Amount;
  }

  public RefreshPageIndex(): void {
    if (this.prijsmoduleservice.PageIndex > this.GetPagesAmount()) {
      this.prijsmoduleservice.PageIndex = this.GetPagesAmount();
    }

    this.prijsmoduleservice.LoadingProducts = true;
    this.prijsmoduleservice.LoadProductsAsync();
  }

  //private LoadExcels(): void {
  //  this.Loading = true;

  //  this.prijslijst.GetExcelsViewModel_Observable().subscribe((data) => {
  //    this.ExcelsViewModel = new ExcelsViewModel(data);
  //    this.Loading = false;
  //  });
  //}

  //private LoadCategorieFull(): void {
  //  if (this.CategoriesViewModel != null && this.PrijsSchemaSelected != null) {
  //    if (this.CategorieId != "" && this.CategorieId != null && this.CategorieId != "-1") {
  //      this.LoadingCategorieFull = true;

  //      var model = new RequestCategorieViewModel();
  //      model.ProductId = parseInt(this.CategorieId);
  //      model.PrijsSchemaId = this.PrijsSchemaSelected.Id;

  //      this.prijslijst.GetCategorieFull_Observable(model).subscribe((data) => {
  //        this.ProductViewModel = new ProductViewModel(data);

  //        this.PageIndex = 1;
  //        this.LoadingCategorieFull = false;
  //      });
  //    }
  //  }
  //}

  //public ShouldDisplayBorderLeft(index: number): boolean {
  //  var cats = this.prijsmoduleservice.GetSubCategories1(this.prijsmoduleservice.ToonSchaal);
  //  for (var i = 0; i < cats.length; i++) {
  //    if (cats[i].Start == index) {
  //      return true;
  //    }
  //  }

  //  return false;
  //}

  public ShouldDisplayBorderRight(index: number): boolean {

    if (this.prijsmoduleservice.CategorieId == 1) {
      if (index == 2) {
        return true;
      }
      if (index == 3) {
        return true;
      }
      if (index == 7) {
        return true;
      }
      if (index == 9) {
        return true;
      }

      if (index == 12) {
        return true;
      }
    }

    if (this.prijsmoduleservice.CategorieId == 2) {
      if (index == 2) {
        return true;
      }
    }

    if (this.prijsmoduleservice.CategorieId == 3) {
      if (index == 2) {
        return true;
      }

      if (index == 5) {
        return true;
      }

      if (index == 8) {
        return true;
      }
    }

    if (this.prijsmoduleservice.CategorieId == 4) {
      if (index == 2) {
        return true;
      }

      if (index == 3) {
        return true;
      }

      if (index == 13) {
        return true;
      }

      if (index == 14) {
        return true;
      }
    }

    if (this.prijsmoduleservice.CategorieId == 5) {
      if (index == 3) {
        return true;
      }

      if (index == 5) {
        return true;
      }
    }

    if (this.prijsmoduleservice.CategorieId == 6) {
      if (index == 2) {
        return true;
      }

      if (index == 5) {
        return true;
      }

      if (index == 6) {
        return true;
      }
    }

    if (this.prijsmoduleservice.CategorieId == 7) {
      if (index == 2) {
        return true;
      }

      if (index == 5) {
        return true;
      }

      if (index == 6) {
        return true;
      }
    }

    if (this.prijsmoduleservice.CategorieId == 8) {
      if (index == 2) {
        return true;
      }

      if (index == 5) {
        return true;
      }

      if (index == 6) {
        return true;
      }
    }

    var cats = this.prijsmoduleservice.GetSubCategories1(this.prijsmoduleservice.ToonSchaal);
    for (var i = 0; i < cats.length; i++) {
      if (cats[i].End == (index + 1)) {
        return true;
      }
    }


    return false;
  }

  private ValidateLoggedIn(): void {

    this.prijslijst.GetCookies_Observable().subscribe((data_cookies: CookieViewModel) => {

      var request = new CookieViewModel();

      request.TokenValue = this.getCookie(data_cookies.TokenName);
      request.VerifyValue = this.getCookie(data_cookies.VerifyName);

      this.prijslijst.Info_Observable(request).subscribe((data: InfoViewModel) => {
        if (data.Message == "") {
          window.location.href = "/login";
        }
      });
    });
  }

  public getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  private GetLoggedInUser(): void {
    this.LoadingUser = true;

    this.prijslijst.GetLoggedInUser_Observable().subscribe((data) => {
      if (data == null) {
        this.router.navigateByUrl('/login');
      }
      this.LoadingUser = false;
    });
  }

  public UpdatePrijsSchemaSelected() {
    if (this.prijsmoduleservice.PrijsSchemasViewModel != null) {
      if (this.prijsmoduleservice.PrijsSchemaSelected != null) {
        if (this.prijsmoduleservice.PrijsSchemaSelectedId == this.prijsmoduleservice.PrijsSchemaSelected.Id.toString()) {
          return;
        }
      }

      _.forEach(this.prijsmoduleservice.PrijsSchemasViewModel.PrijsSchemas, prijsschema => {
        if (prijsschema.Id.toString() == this.prijsmoduleservice.PrijsSchemaSelectedId) {
          this.prijsmoduleservice.PrijsSchemaSelected = prijsschema;
        }
      });

      this.prijsmoduleservice.LoadCategorieen();
    }
  }
}





