import { Component } from '@angular/core';
import { Observable } from 'rxjs';
// import { UIRouterModule } from "@uirouter/angular";
import * as _ from 'lodash';
import { AppConfigService } from '../../infrastructure/app.configservice';
import { AccountService } from '../../services/AccountService';
import { MenuSelectorService } from '../MenuSelectorService';


@Component({
    selector: 'wct-beheer-main',
    templateUrl: './WctBeheerMain.html',
    styleUrls: ['./WctBeheerMain.scss']
 })
 export class WctBeheerMainComponent {
    private leftCSSclasses: string = "";
    private logoText: string = "";
    private uiMode: string;
    private bootstrap: boolean = false;
    private legacy: boolean = false;
    showDocuments$: Observable<boolean>;
    showDwellings$: Observable<boolean>;
    showImageAnnotation$: Observable<boolean>;
    showDocument = false;

    constructor(private accountService: AccountService,
        private config: AppConfigService,
        private menuSelectorService: MenuSelectorService) {
        this.logoText = config.Software.TekstBijWoonconnectLogo;
        this.uiMode = config.Software.UImode;
        this.showDocuments$ = this.menuSelectorService.showDocuments$;
        this.showDwellings$ = this.menuSelectorService.showDwellings$;
        this.showImageAnnotation$ = this.menuSelectorService.showImageAnnotation$;
    }

    showBreadcrumb(): boolean {
        var roles: Array<string> = ["Beheerder", "Snoek"];
        _.each(roles, (role) => {
            if (this.accountService.HeeftRol(role)) {
                return true;
            }
        });

        return false;
    }

    showLogoText(): boolean {
        return (this.logoText != "");
    }
 }


    

