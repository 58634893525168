<div [hidden]="!loading" class="cont-progress-circular">
	<mat-progress-spinner mode="indeterminate" color="#0078be" strokeWidth="2" diameter="50"></mat-progress-spinner>
</div>

<div class="mappenlijstContainer">
	<p class="txt-title">{{ "DOCUMENTMANAGEMENT_DOCUMENT-FOLDERS" | translate }}</p>
	<div [hidden]="!showArchive" class="cont-checkbox">
		<mat-checkbox [(ngModel)]="archiveActive" ngModelChange="loadModel()" aria-label="Toon gearchiveerde mappen">Toon gearchiveerde mappen</mat-checkbox>
	</div>
	<div>
		<wct-document-map [folder]="model?.Root" class="documenten-hoofdmap" [openFirstSubmap]="openfirstsubmap"></wct-document-map>
	</div>
	<wct-button (click)="generateResidentialFolders()" [hidden]="!isFolderAdmin()" buttonText="Genereer woningmappen" buttonSize="small" buttonRole="tertiary" class="btn-documentmap-toevoegen"></wct-button>
	<wct-button (click)="generatePrivateFolders()" [hidden]="!isFolderAdmin()" buttonText="Genereer privemappen" buttonSize="small" buttonRole="tertiary" class="btn-documentmap-toevoegen"></wct-button>
	<wct-button (click)="generateGeneralFolders()" [hidden]="!isFolderAdmin()" buttonText="Genereer algemene mappen" buttonSize="small" buttonRole="tertiary" class="btn-documentmap-toevoegen"></wct-button>
	<wct-button (click)="generateOptionsFolders()" [hidden]="!isFolderAdmin()" buttonText="Genereer ingediende opties mappen" buttonSize="small" buttonRole="tertiary" class="btn-documentmap-toevoegen"></wct-button>
	<wct-button (click)="generateQuotesFolders()" [hidden]="!isFolderAdmin()" buttonText="Genereer offertes mappen" buttonSize="small" buttonRole="tertiary" class="btn-documentmap-toevoegen"></wct-button>
	<wct-button (click)="generatePersonalFolders()" [hidden]="!isFolderAdmin()" buttonText="Genereer bewoner mappen" buttonSize="small" buttonRole="tertiary" class="btn-documentmap-toevoegen"></wct-button>
</div>

<p [hidden]="documentMimePreviewUrl() != null || stateIsFuture()" class="txt-intro"> {{ "DOCUMENTMANAGEMENT_STORE-ALL-DOCUMENTS-SAFELY" | translate }}.</p>
<p [hidden]="documentMimePreviewUrl() != null || !stateIsFuture()" class="txt-intro"> {{ "DOCUMENTMANAGEMENT_SENT-GENERATED-DOCUMENTS-STORED-SAFELY" | translate }}.</p>

<div class="documentPreviewContainer" [hidden]="!documentPreview()">
	<p class="txt-title"> {{ "DOCUMENTMANAGEMENT_PREVIEW" | translate }} {{ getCurrentDocument() }}</p>
	<wct-button [hidden]="documentPreviewIsHTML()" buttonText="Download document" buttonSize="extrasmall" buttonRole="tertiary" (click)="download()" class="btn-download-document"></wct-button>
	<iframe class="documentpreviewDownload" *ngIf="documentPreviewIsPDF()" [src]="documentMimePreviewUrl() | safe"></iframe>
	<div class="documentpreview" [hidden]="!documentPreviewIsImage()">
		<img [src]="documentMimePreviewUrl() | safe" name="getCurrentDocument()" typeof="image/jpeg" />
	</div>
	<div class="documentpreview" *ngIf="documentPreviewIsHTML()">
		<iframe class="documentpreviewDownload" [src]="documentMimePreviewUrl() | safe"></iframe>
	</div>
</div>

<div *ngIf="windowWidth > 1024" class="documentPreviewContainer" [hidden]="documentMimePreviewUrl() == '' || documentPreviewIsImage()">
	<p class="txt-title">{{ "DOCUMENTMANAGEMENT_PREVIEW" | translate }} {{ getCurrentDocument() }}</p>
	<div class="documentpreview">
		<img [hidden]="documentPreviewUrl == null" class="documentPreviewImage" [src]="documentPreviewUrl" />
		<iframe class="documentpreviewDownload" *ngIf="documentMimePreviewUrl() != '' && documentPreviewIsImage()" [src]="documentMimePreviewUrl()"></iframe>
	</div>
	<div class="toolbar">
		<p class="toolbar_melding_grootte">{{ "DOCUMENTMANAGEMENT_FILE-SIZE" | translate }}: {{ fileSize }} MB</p>
	</div>
</div>

<div *ngIf="previewIsNotAvailable()" class="cont-txt-no-preview-available">
	<p>{{ "DOCUMENTMANAGEMENT_NO-PREVIEW-AVAILABLE-FOR-THIS-FILE" | translate }}.</p>
	<wct-button buttonText="Download document" buttonSize="extrasmall" buttonRole="tertiary" (click)="download()" class="btn-download-document"></wct-button>
</div>

<div [hidden]="!loading" class="cont-progress-circular">
	<mat-progress-spinner mode="indeterminate" color="#0078be" strokeWidth="2" diameter="50"></mat-progress-spinner>
</div>