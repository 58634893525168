/**
 * @ref WoonConnectViewModel/Login/LogonStatus.cs
 */

 import * as DataContracts from "@datacontracts/LogonStatus";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Login.LogonStatus
	 */
	 

	export interface ILogonStatus {
		Gelukt: boolean;
		Melding: string;
		Oorzaak: string;
		Rollen: string[];
		BewonerID: number;
		PersoonID: number;
		EersteInlog: boolean;
		RedirectTarget: string;
		DemoActief: boolean;
		WachtwoordVergetenActief: boolean;
		metActivatieMogelijkheid: boolean;
		toonHeeftUHulpNodig: boolean;
		toonDisclaimer: boolean;
		AngularStartUrl: string;
		Availability: string;
		AngularStartState: string;
		TekstUit: string;
	}

	export class LogonStatus {
		// property-Properties
		public get Gelukt(): boolean {
			return this._Gelukt;
		}
		public set Gelukt(newGelukt: boolean) {
			this._Gelukt = newGelukt;
		}

		public get Melding(): string {
			return this._Melding;
		}
		public set Melding(newMelding: string) {
			this._Melding = newMelding;
		}

		public get Oorzaak(): string {
			return this._Oorzaak;
		}
		public set Oorzaak(newOorzaak: string) {
			this._Oorzaak = newOorzaak;
		}

		public get Rollen(): string[] {
			return this._Rollen;
		}
		public set Rollen(newRollen: string[]) {
			this._Rollen = newRollen;
		}

		public get BewonerID(): number {
			return this._BewonerID;
		}
		public set BewonerID(newBewonerID: number) {
			this._BewonerID = newBewonerID;
		}

		public get PersoonID(): number {
			return this._PersoonID;
		}
		public set PersoonID(newPersoonID: number) {
			this._PersoonID = newPersoonID;
		}

		public get EersteInlog(): boolean {
			return this._EersteInlog;
		}
		public set EersteInlog(newEersteInlog: boolean) {
			this._EersteInlog = newEersteInlog;
		}

		public get RedirectTarget(): string {
			return this._RedirectTarget;
		}
		public set RedirectTarget(newRedirectTarget: string) {
			this._RedirectTarget = newRedirectTarget;
		}

		public get DemoActief(): boolean {
			return this._DemoActief;
		}
		public set DemoActief(newDemoActief: boolean) {
			this._DemoActief = newDemoActief;
		}

		public get WachtwoordVergetenActief(): boolean {
			return this._WachtwoordVergetenActief;
		}
		public set WachtwoordVergetenActief(newWachtwoordVergetenActief: boolean) {
			this._WachtwoordVergetenActief = newWachtwoordVergetenActief;
		}

		public get metActivatieMogelijkheid(): boolean {
			return this._metActivatieMogelijkheid;
		}
		public set metActivatieMogelijkheid(newmetActivatieMogelijkheid: boolean) {
			this._metActivatieMogelijkheid = newmetActivatieMogelijkheid;
		}

		public get toonHeeftUHulpNodig(): boolean {
			return this._toonHeeftUHulpNodig;
		}
		public set toonHeeftUHulpNodig(newtoonHeeftUHulpNodig: boolean) {
			this._toonHeeftUHulpNodig = newtoonHeeftUHulpNodig;
		}

		public get toonDisclaimer(): boolean {
			return this._toonDisclaimer;
		}
		public set toonDisclaimer(newtoonDisclaimer: boolean) {
			this._toonDisclaimer = newtoonDisclaimer;
		}

		public get AngularStartUrl(): string {
			return this._AngularStartUrl;
		}
		public set AngularStartUrl(newAngularStartUrl: string) {
			this._AngularStartUrl = newAngularStartUrl;
		}

		public get Availability(): string {
			return this._Availability;
		}
		public set Availability(newAvailability: string) {
			this._Availability = newAvailability;
		}

		public get AngularStartState(): string {
			return this._AngularStartState;
		}
		public set AngularStartState(newAngularStartState: string) {
			this._AngularStartState = newAngularStartState;
		}

		// field-Properties
		public get TekstUit(): string {
			return this._TekstUit;
		}
		public set TekstUit(newTekstUit: string) {
			this._TekstUit = newTekstUit;
		}

		// fields
		protected _Gelukt: boolean = false;
		protected _Melding: string = "";
		protected _Oorzaak: string = "";
		protected _Rollen: string[] = [];
		protected _BewonerID: number = 0;
		protected _PersoonID: number = 0;
		protected _EersteInlog: boolean = false;
		protected _RedirectTarget: string = "";
		protected _DemoActief: boolean = false;
		protected _WachtwoordVergetenActief: boolean = false;
		protected _metActivatieMogelijkheid: boolean = false;
		protected _toonHeeftUHulpNodig: boolean = false;
		protected _toonDisclaimer: boolean = false;
		protected _AngularStartUrl: string = "";
		protected _Availability: string = "";
		protected _AngularStartState: string = "";
		protected _TekstUit: string = "";

		constructor(data?: DataContracts.ILogonStatus) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ILogonStatus): void {
			if(data) {
				// vul properties
				this._Gelukt = data.Gelukt;
				this._Melding = data.Melding;
				this._Oorzaak = data.Oorzaak;
				this._Rollen = data.Rollen;
				this._BewonerID = data.BewonerID;
				this._PersoonID = data.PersoonID;
				this._EersteInlog = data.EersteInlog;
				this._RedirectTarget = data.RedirectTarget;
				this._DemoActief = data.DemoActief;
				this._WachtwoordVergetenActief = data.WachtwoordVergetenActief;
				this._metActivatieMogelijkheid = data.metActivatieMogelijkheid;
				this._toonHeeftUHulpNodig = data.toonHeeftUHulpNodig;
				this._toonDisclaimer = data.toonDisclaimer;
				this._AngularStartUrl = data.AngularStartUrl;
				this._Availability = data.Availability;
				this._AngularStartState = data.AngularStartState;
				// vul fields
				this._TekstUit = data.TekstUit;
			}
		}
	}
