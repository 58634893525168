/**
 * @ref WoonConnectViewModel/Fabrikanten/PDFViewModel.cs
 */

 import * as DataContracts from "@datacontracts/PDFViewModel";

 
import * as FabrikantFilterModel from "./FabrikantFilterModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.PDFViewModel
	 */
	 

	export interface IPDFViewModel {
		Url: string;
		ToJsonContract(): DataContracts.IPDFViewModel;
	}

	export class PDFViewModel {
		// property-Properties
		public get Url(): string {
			return this._Url;
		}
		public set Url(newUrl: string) {
			this._Url = newUrl;
		}

		// field-Properties

		// fields
		protected _Url: string = "";

		constructor(data?: DataContracts.IPDFViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPDFViewModel): void {
			if(data) {
				// vul properties
				this._Url = data.Url;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPDFViewModel {
			return {
				Url: this.Url
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.PDFRequestViewModel
	 */
	 

	export interface IPDFRequestViewModel {
		Title: string;
		NaamProject: string;
		PlaatsProject: string;
		GewenstAfschotpercentage: string;
		ToepassingKilGraatPlaten: string;
		ExtraOpmerkingen: string;
		ToJsonContract(): DataContracts.IPDFRequestViewModel;
	}

	export class PDFRequestViewModel {
		// property-Properties
		public get Title(): string {
			return this._Title;
		}
		public set Title(newTitle: string) {
			this._Title = newTitle;
		}

		public get NaamProject(): string {
			return this._NaamProject;
		}
		public set NaamProject(newNaamProject: string) {
			this._NaamProject = newNaamProject;
		}

		public get PlaatsProject(): string {
			return this._PlaatsProject;
		}
		public set PlaatsProject(newPlaatsProject: string) {
			this._PlaatsProject = newPlaatsProject;
		}

		public get GewenstAfschotpercentage(): string {
			return this._GewenstAfschotpercentage;
		}
		public set GewenstAfschotpercentage(newGewenstAfschotpercentage: string) {
			this._GewenstAfschotpercentage = newGewenstAfschotpercentage;
		}

		public get ToepassingKilGraatPlaten(): string {
			return this._ToepassingKilGraatPlaten;
		}
		public set ToepassingKilGraatPlaten(newToepassingKilGraatPlaten: string) {
			this._ToepassingKilGraatPlaten = newToepassingKilGraatPlaten;
		}

		public get ExtraOpmerkingen(): string {
			return this._ExtraOpmerkingen;
		}
		public set ExtraOpmerkingen(newExtraOpmerkingen: string) {
			this._ExtraOpmerkingen = newExtraOpmerkingen;
		}

		// field-Properties

		// fields
		protected _Title: string = "";
		protected _NaamProject: string = "";
		protected _PlaatsProject: string = "";
		protected _GewenstAfschotpercentage: string = "";
		protected _ToepassingKilGraatPlaten: string = "";
		protected _ExtraOpmerkingen: string = "";

		constructor(data?: DataContracts.IPDFRequestViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPDFRequestViewModel): void {
			if(data) {
				// vul properties
				this._Title = data.Title;
				this._NaamProject = data.NaamProject;
				this._PlaatsProject = data.PlaatsProject;
				this._GewenstAfschotpercentage = data.GewenstAfschotpercentage;
				this._ToepassingKilGraatPlaten = data.ToepassingKilGraatPlaten;
				this._ExtraOpmerkingen = data.ExtraOpmerkingen;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPDFRequestViewModel {
			return {
				Title: this.Title,
				NaamProject: this.NaamProject,
				PlaatsProject: this.PlaatsProject,
				GewenstAfschotpercentage: this.GewenstAfschotpercentage,
				ToepassingKilGraatPlaten: this.ToepassingKilGraatPlaten,
				ExtraOpmerkingen: this.ExtraOpmerkingen
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.OfferteAanvraagViewModel
	 */
	 

	export interface IOfferteAanvraagViewModel {
		BedrijfsnaamAanvrager: string;
		PlaatsBedrijf: string;
		Bedrijfsactiviteit: string;
		NaamContactPersoon: string;
		TelContactPersoon: string;
		EmailContactPersoon: string;
		UitvoeringsDatumProject: string;
		AantalM2Project: string;
		VoorkeurHandelaar: string;
		HandelaarNaam: string;
		HandelaarPlaats: string;
		ToJsonContract(): DataContracts.IOfferteAanvraagViewModel;
	}

	export class OfferteAanvraagViewModel {
		// property-Properties
		public get BedrijfsnaamAanvrager(): string {
			return this._BedrijfsnaamAanvrager;
		}
		public set BedrijfsnaamAanvrager(newBedrijfsnaamAanvrager: string) {
			this._BedrijfsnaamAanvrager = newBedrijfsnaamAanvrager;
		}

		public get PlaatsBedrijf(): string {
			return this._PlaatsBedrijf;
		}
		public set PlaatsBedrijf(newPlaatsBedrijf: string) {
			this._PlaatsBedrijf = newPlaatsBedrijf;
		}

		public get Bedrijfsactiviteit(): string {
			return this._Bedrijfsactiviteit;
		}
		public set Bedrijfsactiviteit(newBedrijfsactiviteit: string) {
			this._Bedrijfsactiviteit = newBedrijfsactiviteit;
		}

		public get NaamContactPersoon(): string {
			return this._NaamContactPersoon;
		}
		public set NaamContactPersoon(newNaamContactPersoon: string) {
			this._NaamContactPersoon = newNaamContactPersoon;
		}

		public get TelContactPersoon(): string {
			return this._TelContactPersoon;
		}
		public set TelContactPersoon(newTelContactPersoon: string) {
			this._TelContactPersoon = newTelContactPersoon;
		}

		public get EmailContactPersoon(): string {
			return this._EmailContactPersoon;
		}
		public set EmailContactPersoon(newEmailContactPersoon: string) {
			this._EmailContactPersoon = newEmailContactPersoon;
		}

		public get UitvoeringsDatumProject(): string {
			return this._UitvoeringsDatumProject;
		}
		public set UitvoeringsDatumProject(newUitvoeringsDatumProject: string) {
			this._UitvoeringsDatumProject = newUitvoeringsDatumProject;
		}

		public get AantalM2Project(): string {
			return this._AantalM2Project;
		}
		public set AantalM2Project(newAantalM2Project: string) {
			this._AantalM2Project = newAantalM2Project;
		}

		public get VoorkeurHandelaar(): string {
			return this._VoorkeurHandelaar;
		}
		public set VoorkeurHandelaar(newVoorkeurHandelaar: string) {
			this._VoorkeurHandelaar = newVoorkeurHandelaar;
		}

		public get HandelaarNaam(): string {
			return this._HandelaarNaam;
		}
		public set HandelaarNaam(newHandelaarNaam: string) {
			this._HandelaarNaam = newHandelaarNaam;
		}

		public get HandelaarPlaats(): string {
			return this._HandelaarPlaats;
		}
		public set HandelaarPlaats(newHandelaarPlaats: string) {
			this._HandelaarPlaats = newHandelaarPlaats;
		}

		// field-Properties

		// fields
		protected _BedrijfsnaamAanvrager: string = "";
		protected _PlaatsBedrijf: string = "";
		protected _Bedrijfsactiviteit: string = "";
		protected _NaamContactPersoon: string = "";
		protected _TelContactPersoon: string = "";
		protected _EmailContactPersoon: string = "";
		protected _UitvoeringsDatumProject: string = "";
		protected _AantalM2Project: string = "";
		protected _VoorkeurHandelaar: string = "";
		protected _HandelaarNaam: string = "";
		protected _HandelaarPlaats: string = "";

		constructor(data?: DataContracts.IOfferteAanvraagViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IOfferteAanvraagViewModel): void {
			if(data) {
				// vul properties
				this._BedrijfsnaamAanvrager = data.BedrijfsnaamAanvrager;
				this._PlaatsBedrijf = data.PlaatsBedrijf;
				this._Bedrijfsactiviteit = data.Bedrijfsactiviteit;
				this._NaamContactPersoon = data.NaamContactPersoon;
				this._TelContactPersoon = data.TelContactPersoon;
				this._EmailContactPersoon = data.EmailContactPersoon;
				this._UitvoeringsDatumProject = data.UitvoeringsDatumProject;
				this._AantalM2Project = data.AantalM2Project;
				this._VoorkeurHandelaar = data.VoorkeurHandelaar;
				this._HandelaarNaam = data.HandelaarNaam;
				this._HandelaarPlaats = data.HandelaarPlaats;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IOfferteAanvraagViewModel {
			return {
				BedrijfsnaamAanvrager: this.BedrijfsnaamAanvrager,
				PlaatsBedrijf: this.PlaatsBedrijf,
				Bedrijfsactiviteit: this.Bedrijfsactiviteit,
				NaamContactPersoon: this.NaamContactPersoon,
				TelContactPersoon: this.TelContactPersoon,
				EmailContactPersoon: this.EmailContactPersoon,
				UitvoeringsDatumProject: this.UitvoeringsDatumProject,
				AantalM2Project: this.AantalM2Project,
				VoorkeurHandelaar: this.VoorkeurHandelaar,
				HandelaarNaam: this.HandelaarNaam,
				HandelaarPlaats: this.HandelaarPlaats
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.RekenModuleInputViewModel
	 */
	 

	export interface IRekenModuleInputViewModel {
		OfferteAanvraagViewModel: OfferteAanvraagViewModel;
		PDFRequestViewModel: PDFRequestViewModel;
		FabrikantenFilterResponseModel: FabrikantFilterModel.IFabrikantenFilterResponseModel;
		ToJsonContract(): DataContracts.IRekenModuleInputViewModel;
	}

	export class RekenModuleInputViewModel {
		// property-Properties
		public get OfferteAanvraagViewModel(): OfferteAanvraagViewModel {
			return this._OfferteAanvraagViewModel;
		}
		public set OfferteAanvraagViewModel(newOfferteAanvraagViewModel: OfferteAanvraagViewModel) {
			this._OfferteAanvraagViewModel = newOfferteAanvraagViewModel;
		}

		public get PDFRequestViewModel(): PDFRequestViewModel {
			return this._PDFRequestViewModel;
		}
		public set PDFRequestViewModel(newPDFRequestViewModel: PDFRequestViewModel) {
			this._PDFRequestViewModel = newPDFRequestViewModel;
		}

		public get FabrikantenFilterResponseModel(): FabrikantFilterModel.IFabrikantenFilterResponseModel {
			return this._FabrikantenFilterResponseModel;
		}
		public set FabrikantenFilterResponseModel(newFabrikantenFilterResponseModel: FabrikantFilterModel.IFabrikantenFilterResponseModel) {
			this._FabrikantenFilterResponseModel = newFabrikantenFilterResponseModel;
		}

		// field-Properties

		// fields
		protected _OfferteAanvraagViewModel: OfferteAanvraagViewModel = new OfferteAanvraagViewModel(undefined);
		protected _PDFRequestViewModel: PDFRequestViewModel = new PDFRequestViewModel(undefined);
		protected _FabrikantenFilterResponseModel: FabrikantFilterModel.IFabrikantenFilterResponseModel = new FabrikantFilterModel.FabrikantenFilterResponseModel(undefined);

		constructor(data?: DataContracts.IRekenModuleInputViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IRekenModuleInputViewModel): void {
			if(data) {
				// vul properties
				this._OfferteAanvraagViewModel = data.OfferteAanvraagViewModel ? new OfferteAanvraagViewModel(data.OfferteAanvraagViewModel): undefined;
				this._PDFRequestViewModel = data.PDFRequestViewModel ? new PDFRequestViewModel(data.PDFRequestViewModel): undefined;
				this._FabrikantenFilterResponseModel = data.FabrikantenFilterResponseModel ? new FabrikantFilterModel.FabrikantenFilterResponseModel(data.FabrikantenFilterResponseModel): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IRekenModuleInputViewModel {
			return {
				OfferteAanvraagViewModel: (this.OfferteAanvraagViewModel ? new OfferteAanvraagViewModel(this.OfferteAanvraagViewModel).ToJsonContract(): this.OfferteAanvraagViewModel),
				PDFRequestViewModel: (this.PDFRequestViewModel ? new PDFRequestViewModel(this.PDFRequestViewModel).ToJsonContract(): this.PDFRequestViewModel),
				FabrikantenFilterResponseModel: (this.FabrikantenFilterResponseModel ? new FabrikantFilterModel.FabrikantenFilterResponseModel(this.FabrikantenFilterResponseModel).ToJsonContract(): this.FabrikantenFilterResponseModel)
			}
		}
	}
