import * as _ from 'lodash';
import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';
import * as ServicesOptieService from '.././services/OptieService';
import * as ViewModelsGebouwBrok from '.././Models/GebouwBrok';
import * as ViewModelsKostenWoning from '.././Models/KostenWoning';
import * as ViewModelsKostenHoofdstuk from '.././Models/KostenHoofdstuk';
import * as ViewModelsInstantieDeel from '.././Models/InstantieDeel';
import * as ViewModelsWoningTypologie from '.././Models/WoningTypologie';

export class WoningInstantie {
	Typologie: ViewModelsWoningTypologie.WoningTypologie;
	Gespiegeld: boolean;
	WoningID: number;
	ScenarioID: number;
	OptiesIngediend: Date;
	Brokken: { [deelid: string]: ViewModelsInstantieDeel.InstantieDeel; } = {};
	//Kosten: { [code: string]: KostenHoofdstuk; } = {};
	Kosten: Array<ViewModelsKostenHoofdstuk.KostenHoofdstuk> = [];
	KostenBestaand: Array<ViewModelsKostenHoofdstuk.KostenHoofdstuk> = [];
	KostenEigenaar: ViewModelsKostenWoning.KostenWoningEigenaar;
	KostenEigenaarBestaand: ViewModelsKostenWoning.KostenWoningEigenaar;
	Event: Array<number> = [];
	EnergieBerekend: boolean = false;


	constructor(data: any) {
		this.Typologie = data.Typologie;
		this.Gespiegeld = data.Gespiegeld;
		this.WoningID = data.WoningID;
		this.ScenarioID = data.ScenarioID;
		this.OptiesIngediend = data.OptiesIngediend;
	}

	private MaakBrokLijst(broktype?: ViewModelsGebouwBrok.GebouwType): Array<ViewModelsGebouwBrok.GebouwType> {
		var arr: Array<ViewModelsGebouwBrok.GebouwType> = [];
		if (broktype == null) {
			arr.push(ViewModelsGebouwBrok.GebouwType.BestaandeWoning);
			arr.push(ViewModelsGebouwBrok.GebouwType.BestaandGebouw);
			arr.push(ViewModelsGebouwBrok.GebouwType.BestaandeWijk);
			arr.push(ViewModelsGebouwBrok.GebouwType.NieuweWoning);
			arr.push(ViewModelsGebouwBrok.GebouwType.NieuwGebouw);
			arr.push(ViewModelsGebouwBrok.GebouwType.NieuweWijk);
		}
		else {
			arr.push(broktype);
		}
		return arr;
	}

	GeefBrokMetNaam(naam: string): ViewModelsInstantieDeel.InstantieDeel {
		var deel = this.GeefBrokDefinitieMetNaam(naam);

		if (deel == null) {
			return null;
		}

		var instantiedeel = this.Brokken[deel.DeelId];

		return instantiedeel;
	}

	GeefBrokDefinitieMetNaam(naam: string): ViewModelsGebouwBrok.GebouwBrok {
		var deel = this.Typologie.WoningType.GeefGebouwDeelMetNaam(naam);
		return deel;
	}

	IsToegestaan(broknaam: string, optienaam: string) {
		var brok = this.GeefBrokMetNaam(broknaam);
		if (brok == null) {
			return false;
		}
		var optiestring = optienaam.toString();
		if (brok != null) {
			var exists = _.find(brok.GeefToegestaneNamen(), (b) => b == optiestring);
			return exists != null;
		}

		return false;
	}

	IsActief(broknaam: string, optienaam: string): boolean {

		var instantiebrok = this.GeefBrokMetNaam(broknaam);

		var optie = instantiebrok.GeefDefinitie().GeefOptieMetNaam(optienaam);
		if (instantiebrok.ActieveOptie == optie.OptieId) {
			return true;
		}
		return false;
	}

	// Geeft event nummer; indien aanwezig, dan null
	GeefEventnummer(broktype: ViewModelsGebouwBrok.GebouwType) {
		return this.Event[broktype];
	}

	woningisuptodate(service: ServicesOptieService.IOptieService, broktype?: ViewModelsGebouwBrok.GebouwType, metKosten?: boolean, metEnergie?: boolean): boolean {
		var s: ServicesOptieService.ScopeSelectie = new ServicesOptieService.ScopeSelectie();
		s.Scope = EnumsWijzigScopeEnum.WijzigScopeEnum.Woning;
		s.ID = this.WoningID;
		s.Scenario = this.ScenarioID;
		var huidigEvent = service.GeefEventID(s);
		var typen: Array<ViewModelsGebouwBrok.GebouwType> = this.MaakBrokLijst(broktype);

		if (metKosten && this.KostenEigenaar == undefined) {
			return false;// bij kosten check of eigenaar kosten al gevuld
		}

		if (metEnergie && (this.KostenEigenaar == undefined || !this.EnergieBerekend)) {
			return false;// bij kosten check of energie al gevuld
		}

		var ok = _.every(typen, (t) => {
			var mijnEvent = this.Event[t];
			if (mijnEvent == null) {
				/* We hebben nog geen data voor dit type brok opgehaald, dus altijd out of date */
				return false;
			}
			if (huidigEvent == null) {
				/* Er is nog geen update event binnen gekomen, dus dan zijn we altijd uptodate */
				return true;
			}
			if (huidigEvent > mijnEvent) {
				/* Er heeft een wijziging plaats gevonden, dus niet up to date */
				return false;
			}
			return true;
		});

		return ok;
	}

	private ZetEvent(broktype: ViewModelsGebouwBrok.GebouwType, ev: number) {

	}

	_VulGebouwdelen(data: any, broktype?: ViewModelsGebouwBrok.GebouwType) {
		var eventid = data.EventID;
		var typen: Array<ViewModelsGebouwBrok.GebouwType> = this.MaakBrokLijst(broktype);
		_.each(typen, (t) => {
			this.Event[t] = eventid;
		});

		_.each(Object.keys(this.Brokken), (b) => {
			var gebouwtype = this.Typologie.WoningType.GeefGebouwDeel(b).GebouwType;
			var exists = _.filter(typen, (t) => t == gebouwtype);
			if (exists.length > 0) {
				delete this.Brokken[b];
				//this.Brokken[b] = null;
			}
		});

		_.each(data.Gebouwdelen, (d) => {
			var deel = new ViewModelsInstantieDeel.InstantieDeel(this, d);
			this.Brokken[deel.DeelID] = deel;
		});

		_.each(this.Brokken, (d) => d.BepaalKeuzes());
	}

	_LeegKosten() {
		{
			while (this.Kosten.length > 0) {
				this.Kosten.pop();
			}
			while (this.KostenBestaand.length > 0) {
				this.KostenBestaand.pop();
			}
		}
	}

	_VulKosten(data: any) {
		while (this.Kosten.length > 0) {
			this.Kosten.pop();
		}
		_.each(data.Hoofdstukken, (h) => {
			var hfd = new ViewModelsKostenHoofdstuk.KostenHoofdstuk(this, h);
			//this.Kosten[hfd.Code] = hfd;
			this.Kosten.push(hfd);
		});
	}
	_VulKostenEigenaar(data: any) {
		this.KostenEigenaar = new ViewModelsKostenWoning.KostenWoningEigenaar();
		this.KostenEigenaar.VulModel(data);
		this.KostenEigenaar.KostenHoofdstukken = this.Kosten;
	}

	_VulKostenBestaand(data: any) {
		while (this.KostenBestaand.length > 0) {
			this.KostenBestaand.pop();
		}
		if (data != null) {
			_.each(data.Hoofdstukken, (h) => {
				var hfd = new ViewModelsKostenHoofdstuk.KostenHoofdstuk(this, h);
				//this.Kosten[hfd.Code] = hfd;
				this.KostenBestaand.push(hfd);
			});
		}
	}
	_VulKostenEigenaarBestaand(data: any) {
		this.KostenEigenaarBestaand = new ViewModelsKostenWoning.KostenWoningEigenaar();
		if (data != null) {
			this.KostenEigenaarBestaand.VulModel(data);
			this.KostenEigenaarBestaand.KostenHoofdstukken = this.Kosten;
		}
	}
}

