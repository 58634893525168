/**
 * @ref WoonConnectViewModel/Transfer/FileDownload.cs
 */

 import * as DataContracts from "@datacontracts/FileDownload";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Transfer.FileDownload
	 */
	 

	export interface IFileDownload {
		Url: string;
		Filename: string;
		MimeType: string;
	}

	export class FileDownload {
		// property-Properties
		// field-Properties
		public get Url(): string {
			return this._Url;
		}
		public set Url(newUrl: string) {
			this._Url = newUrl;
		}
		
		public get Filename(): string {
			return this._Filename;
		}
		public set Filename(newFilename: string) {
			this._Filename = newFilename;
		}
		
		public get MimeType(): string {
			return this._MimeType;
		}
		public set MimeType(newMimeType: string) {
			this._MimeType = newMimeType;
		}

		// fields
		protected _Url: string = "";
		protected _Filename: string = "";
		protected _MimeType: string = "";

		constructor(data?: DataContracts.IFileDownload) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFileDownload): void {
			if(data) {
				// vul properties
				// vul fields
				this._Url = data.Url;
				this._Filename = data.Filename;
				this._MimeType = data.MimeType;
			}
		}
	}
