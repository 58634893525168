import * as ng from 'angular';
import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';
import * as ServicesModelCameraService from '../services/ModelCameraService';
import * as ServicesWoningEventService from '../services/WoningEventService';
import * as ServicesViewer3DService from '../services/Viewer3DService';
import * as ServicesAccountService from '../services/AccountService';
import * as ServicesSceneTypescript from '../services/SceneTypescript';
import * as ViewModelsWebGLSessie from '../Models/WebGLSessie';
import { WebGLViewerServiceTypescript } from '../services/WebGLViewerService';
import * as ServicesWctLogService from '../services/WctLogService';
import * as HelpersOverlay from '.././Helpers/Overlay';
import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { WctLogService } from '../services/WctLogService';


// interface I3DViewerBindings {
// 	onselect: (key) => any;
// 	actief: string;
// 	sessie: ViewModelsWebGLSessie.WebGLSessie;
// 	selectie: boolean;
// 	suppress: boolean;
// 	drawscene: boolean;
// }

// interface I3DViewerController extends I3DViewerBindings {
// 	SessieGeregistreerd(session: ServicesSceneTypescript.SceneSession);
// 	LadenBezig(): boolean;
// 	Voortgang(): number;
// 	PanOmhoog();
// 	PanRechts();
// 	PanBeneden();
// 	PanLinks();

// 	ToonZonTijd(): boolean;
// 	GeefZonTijd(): string;
// 	ToonPijltjes(): boolean;

// 	ToonPanButtons: boolean;
// }

@Component({
	selector: 'wct-viewer-3d',
  templateUrl: './WctViewer3d.html',
  styleUrls: ['./WctViewer3d.scss']
})
export class wct3DViewerComponent implements OnChanges, DoCheck {

	m_SelectieAanHetWijzigen: boolean = false;
	@Output() onselect = new EventEmitter<string[]>();
	@Input() actief: string;
	@Input() sessie: ViewModelsWebGLSessie.WebGLSessie;
	@Input() selectie: boolean;
	@Input() suppress: boolean;
	@Input() drawscene: boolean;

	m_BezigViewModel: HelpersOverlay.IBezigState;
	ToonPanButtons: boolean;
	m_PreviousActief: string = null;

	//constructor(private $q: ng.IQService, private $scope: ng.IScope, viewer3dservice: IViewer3DService, accountservice: ServicesAccountService.IAccountService, private $attrs) {
	constructor(private $log: WctLogService, private woningeventservice: ServicesWoningEventService.WoningEventService, accountservice: ServicesAccountService.AccountService,  private modelcameraservice: ServicesModelCameraService.ModelCameraService, private webglviewerservice: WebGLViewerServiceTypescript) {

		this.ToonPanButtons = accountservice.HeeftRol('bewoner') || accountservice.HeeftRol('huurder');

		this.m_BezigViewModel = HelpersOverlay.BezigState.Default();
		this.m_BezigViewModel.ToonOverlay = false;
		this.m_BezigViewModel.Mode = "determinate";
	}

	public BezigViewModel() : HelpersOverlay.IBezigState
	{
		return this.m_BezigViewModel;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.m_PreviousActief != this.actief) {
			if (this.sessie.SceneSession != null) {
				if (this.m_PreviousActief != this.actief)
					if (this.actief != "Initieel") {
						var fragment = this.sessie.SceneSession.GeefConfigKeyFragment(this.actief);
						var lijst = this.sessie.SceneSession.GeefViewer().getSelectie();
						this.m_SelectieAanHetWijzigen = true;
						this.sessie.SceneSession.GeefViewer().setSelectie([fragment]);
						this.sessie.SceneSession.GeefViewer().toonModel();
						this.m_SelectieAanHetWijzigen = false;
					}
			}
		}

		this.m_PreviousActief = this.actief;
	}

	ngDoCheck()
	{
		this.m_BezigViewModel.ToonOverlay = this.LadenBezig();
		this.m_BezigViewModel.Value = this.Voortgang();

		this.m_BezigViewModel.ToonLadenMelding = this.LadenBezig();
		this.m_BezigViewModel.LadenMelding = Math.round(this.Voortgang()) + '%';
	}

	ToonZonTijd() {
		var tijd = this.GeefZonTijd();
		return tijd.length > 0;
	}

	GeefZonTijd() {
		if (this.sessie != null && this.sessie.SceneSession != null) {
			return this.sessie.SceneSession.ZonneTijd;
		}
		return "";
	}

	PanOmhoog() {
		this.sessie.SceneSession.Pan(0, 500);
	}

	PanRechts() {
		this.sessie.SceneSession.Pan(500, 0);
	}

	PanBeneden() {
		this.sessie.SceneSession.Pan(0, -500);
	}

	PanLinks() {
		this.sessie.SceneSession.Pan(-500, 0);
	}

	ToonPijltjes(): boolean {

		var tonen = true;

		if (this.modelcameraservice.GeefWijkLopen() && (this.woningeventservice.GeefScope() == EnumsWijzigScopeEnum.WijzigScopeEnum.Blok)) {
			tonen = false;
		}

		return tonen;
	}

	LadenBezig(): boolean {
		if (this.sessie != null) {
			if (this.sessie.SceneSession != null) {
				return this.sessie.SceneSession.LadenBezig;
			}
		}
		return false;
	}

	Voortgang(): number {
		if (this.sessie == null) {
			return 0;
		}
		if (this.sessie.SceneSession == null) {
			return 0;
		}
		return this.sessie.SceneSession.VoortgangPercentage;
	}




	SessieGeregistreerd(session: ServicesSceneTypescript.SceneSession) {
		//this.sessie.ZetSessie(session);
		var viewer = session.GeefViewer();

		//session.DrawScene();

		//viewer.ZetSelectie(true);
		if (this.onselect != null) {
			//if (this.$attrs.onselect) {
			this.webglviewerservice.ZetSelectieToegestaan(viewer, true);

			this.webglviewerservice.ZetSelectieVeranderd(viewer, (e) => {
				this.$log.debug("Veranderend" + e);
				return true;
			}, (e2) => {
				this.$log.debug("Veranderd " + e2);
				let configkey : string[] = [];
				if (e2.length > 0) {
					configkey = session.GeefFragmentConfigKeys(e2);
				}

				if (!this.m_SelectieAanHetWijzigen) {
					this.$log.debug(configkey);

					// this.$scope.$apply(() => {
					// 	//this.actief = configkey;
					// });

					this.onselect.next(configkey);
				}
				else {
					this.$log.debug('Zelf aan het wijzigen; niet propageren');
				}

			});
		}
	}

	public PanLinksValtOnderOverzichtsmenu(): boolean {
		let pakketkostenresultaat = document.getElementsByClassName("cont-pakket-kosten-resultaat");
		let resultaat = false;

		if (pakketkostenresultaat.length > 0) {
			if (pakketkostenresultaat[0].clientHeight > 420) {
				resultaat = true;
			}
		}

		return resultaat;
	}
}
