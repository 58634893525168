<div class="content">
  <table style="width: 100%;">
    <tr>
      <td>
        HOPPE AG   
      </td>
      <td>
        Angelo van Aken, Projectadviseur      
      </td>
      <td>
        T: +31 (0)683692558
      </td>
      <td>
        E: <a href="mailto:angelo.vanaken@hoppe.com">angelo.vanaken@hoppe.com</a>
      </td>
    </tr>
  </table>
</div>
