// [conv] import "(./WctAdminStreets).less";
import { Component, Input, ViewChild } from '@angular/core';
import * as _ from 'lodash';


@Component({
    selector: 'wct-admin-streets',
    templateUrl: './WctAdminStreets.html'
})
export class WctAdminStreetsComponent {


    constructor() {


    }
    ngOnInit() {


    }


}