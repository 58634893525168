<a href="fab-admin-filters">Terug naar FiltersOverzicht</a> 

<div *ngIf="Laden">
    Laden...
</div>

<div *ngIf="!Laden">
    <div id="left">
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Actie</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="update">
                    <td></td>
                    <td><input placeholder="Filter Type Naam" [(ngModel)]="newFilterType.Name" [ngModelOptions]="{standalone: true}" /></td>
                    <td><button (click)="addFilterType()">Toevoegen</button></td>
                </tr>

                <tr *ngFor="let FilterType of FilterTypesViewModel.FilterTypes">
                    <ng-container>
                        <ng-template [ngIf]="newFilterType.Id != FilterType.Id" [ngIfElse]="FormDisplay">
                            <td>{{ FilterType.Id}}</td>
                            <td (dblclick)="editField(FilterType)">{{FilterType.Name}}</td>
                            <td>

                                <button (click)="deleteFilterType(FilterType.Id)">Verwijder</button>
                            </td>
                        </ng-template>
                        <ng-template #FormDisplay>
                            <td>{{newFilterType.Id}}</td>
                            <td><input placeholder="Filter Type Naam" [(ngModel)]="newFilterType.Name" [ngModelOptions]="{standalone: true}" /></td>
                            <td><button (click)="addFilterType()">Update</button></td>
                        </ng-template>
                    </ng-container>

                </tr>
            </tbody>
        </table>
    </div>

</div>