/**
 * @ref WoonConnectViewModel/Documenten/RechtenModel.cs
 */

 import * as DataContracts from "@datacontracts/RechtenModel";

 
import * as RechtenRegelModel from "./RechtenRegelModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Documenten.RechtenModel
	 */
	 

	export interface IRechtenModel {
		RechtenRegels: RechtenRegelModel.IRechtenRegelModel[];
		InheritedRechtenRegels: RechtenRegelModel.IRechtenRegelModel[];
		RechtenVoorGebruiker: number[];
		ToJsonContract(): DataContracts.IRechtenModel;
	}

	export class RechtenModel {
		// property-Properties
		public get RechtenRegels(): RechtenRegelModel.IRechtenRegelModel[] {
			return this._RechtenRegels;
		}
		public set RechtenRegels(newRechtenRegels: RechtenRegelModel.IRechtenRegelModel[]) {
			this._RechtenRegels = newRechtenRegels;
		}

		public get InheritedRechtenRegels(): RechtenRegelModel.IRechtenRegelModel[] {
			return this._InheritedRechtenRegels;
		}
		public set InheritedRechtenRegels(newInheritedRechtenRegels: RechtenRegelModel.IRechtenRegelModel[]) {
			this._InheritedRechtenRegels = newInheritedRechtenRegels;
		}

		public get RechtenVoorGebruiker(): number[] {
			return this._RechtenVoorGebruiker;
		}
		public set RechtenVoorGebruiker(newRechtenVoorGebruiker: number[]) {
			this._RechtenVoorGebruiker = newRechtenVoorGebruiker;
		}

		// field-Properties

		// fields
		protected _RechtenRegels: RechtenRegelModel.IRechtenRegelModel[] = [];
		protected _InheritedRechtenRegels: RechtenRegelModel.IRechtenRegelModel[] = [];
		protected _RechtenVoorGebruiker: number[] = [];

		constructor(data?: DataContracts.IRechtenModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IRechtenModel): void {
			if(data) {
				// vul properties
				this._RechtenRegels = data.RechtenRegels ? data.RechtenRegels.map(rechtenRegelModelItem => new RechtenRegelModel.RechtenRegelModel(rechtenRegelModelItem)): [];
				this._InheritedRechtenRegels = data.InheritedRechtenRegels ? data.InheritedRechtenRegels.map(rechtenRegelModelItem => new RechtenRegelModel.RechtenRegelModel(rechtenRegelModelItem)): [];
				this._RechtenVoorGebruiker = data.RechtenVoorGebruiker;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IRechtenModel {
			return {
				RechtenRegels: (this.RechtenRegels ? this.RechtenRegels.map(rechtenRegelModelItem => new RechtenRegelModel.RechtenRegelModel(rechtenRegelModelItem).ToJsonContract()): []),
				InheritedRechtenRegels: (this.InheritedRechtenRegels ? this.InheritedRechtenRegels.map(rechtenRegelModelItem => new RechtenRegelModel.RechtenRegelModel(rechtenRegelModelItem).ToJsonContract()): []),
				RechtenVoorGebruiker: this.RechtenVoorGebruiker
			}
		}
	}
