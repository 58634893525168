<div class="content" *ngIf="!Loading">
  <div class="panel" style="padding-right: 40px; padding-top: 33px; min-width: 531px;" *ngIf="ActiveProduct != ''">
    <div class="bar" style="display: flex;">
      <table style="width: 100%;">
        <tr>
          <td>
          </td>
          <td>
            <div style="text-align: right;">
              <a (click)="RemoveSelectedFilters()" class="reset">Keuzes herstellen</a>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div *ngFor="let filter of FabrikantenFilterResponseModel?.Filters">
      <filter *ngIf="ShouldDisplay(filter)" [FilterViewModel]="filter" [IsFilterFirstLevel]="FabrikantenFilterResponseModel.SelectedProduct == undefined" [HeeftCADAbstract]="FabrikantenFilterResponseModel.SelectedProduct?.HeeftCADAbstract" (FilterChanged)="Refresh()" (ValueChanged)="SetSavedValue($event.filterviewmodelid, $event.savedvalue)"></filter>
      <div style="clear: both;"></div>
    </div>
  </div>
  <div class="panel" *ngIf="ActiveProduct == ''" style="padding: 40px; background-color: #fdc600;">
    <div>
      <a routerLink=""><img src="/Content/KlantSpecifiek/BCB21075/icon-arrow-left.svg" width="14" height="12"> Terug</a>
      &nbsp;
      <a routerLink="" style="color: #000;"><i class="wct-icon wct-icon-home"></i></a>
      >
      <span>{{ ActiveCategory }}</span>
    </div>
    <br />
    <div style="display: flex; flex-wrap: wrap;">
      <div *ngFor="let product of FabrikantenFilterResponseModel.Products" class="product-panel">
        <div class="img-block" (click)="SelectProduct(product)" [ngStyle]="{backgroundImage:'url(\''+  GetProductImageUrl(product) + '\')'}">
          <!--<img class="img" src="{{ GetProductImageUrl(product) }}" />-->
        </div>
        <div class="product-div">
          <a class="product" (click)="SelectProduct(product)">{{ product.Naam }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="panel" *ngIf="ActiveProduct !== ''" style="background-color: #fdc600; text-align: left; padding: 40px; width: 100%;">
    <div>
      <a (click)="DeselectActiveProduct()" class="reset"><img src="/Content/KlantSpecifiek/BCB21075/icon-arrow-left.svg" width="14" height="12"> Terug</a>
      &nbsp;
      <a routerLink="" style="color: #000;"><i class="wct-icon wct-icon-home"></i></a>
      >
      <a (click)="DeselectActiveProduct()" class="reset">
        <span>{{ ActiveCategory }}</span>
      </a>
      >
      <span>{{ ActiveProduct }}</span>
    </div>
    <br />
    <div>
      <table style="width: 100%;">
        <tr>
          <td>
            <span style="font-size: 30px;">
              {{ ActiveProduct }}
            </span>
          </td>
          <td style="width: 200px;">
            <div class="bestek-button" *ngIf="(FabrikantenFilterResponseModel.SelectedProduct !== undefined) && FabrikantenFilterResponseModel.SelectedProduct.BCBWUIDLink != '' && FabrikantenFilterResponseModel.SelectedProduct.BCBWUIDLink != null" (click)="OpenBCB(FabrikantenFilterResponseModel.SelectedProduct.BCBWUIDLink)">
              <i aria-hidden="true" class="wct-icon wct-icon-bouwconnect-circled ng-star-inserted"></i>
              BCB link
              <i class="fal fa-info-circle" matTooltip="link naar dit product op de BouwConnect Bibliotheek online" matTooltipPosition="below" style="margin-left: 5px;"></i>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <br />

    <div>
      <div *ngIf="(FabrikantenFilterResponseModel.SelectedProduct !== undefined) && (!FabrikantenFilterResponseModel.VerplichteWaardenIngevuld)">
        <br />
        <br />
        Vul eerst de verplichte waarden (*) in.
      </div>

      <bestek *ngIf="(FabrikantenFilterResponseModel.SelectedProduct !== undefined) && (FabrikantenFilterResponseModel.VerplichteWaardenIngevuld == true)" [product]="FabrikantenFilterResponseModel.SelectedProduct" (setlastselectedproduct)="SetLastSelectedProduct($event.selectedproduct)"></bestek>
    </div>
  </div>
</div>

<div *ngIf="Loading" class="mat-progress-spinner__cont" style="position:absolute; height: 100%; width: 100%; top:0px; background: rgba(255,255,255,0.5); text-align:center;">
  <div style="padding-top: 300px;">
    <div>
      <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
    </div>
    <div>
      <p>De producten worden geladen</p>
    </div>
  </div>
</div>
