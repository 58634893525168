import { IFilterViewModel } from '@datacontracts/FilterViewModel';
import { FilterDisplayLevelEnum } from '@enums/FilterViewModel';
import { FilterViewModel } from '@models/FilterViewModel';
// [conv] import "(./FabFilters).less";
import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { FabFiltersDialogComponent } from './FabFiltersDialog'
import * as _ from 'lodash';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { FabrikantenConsumer } from '@consumers/FabrikantenController';

import { BCBProductsOverviewModel } from '@models/BCBProductsOverviewModel';
import { BCBProductViewModel, IBCBProductViewModel } from '@models/BCBProductModel';
import { FabrikantenFilterRequestModel, FabrikantenFilterResponseModel } from '@models/FabrikantFilterModel';
import { GoogleAnalyticsService } from '../../../../services/GoogleAnalyticsService'
//import { ThemeService } from '../../../shared/theme';


@Component({
  selector: 'fab-filters',
  templateUrl: './FabFilters.html',
  styleUrls: ['./FabFilters.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FabFiltersComponent {

  Product: string;
  Filters: string;
  DisplayDisclaimer: boolean = false;

  Loading: boolean = true;

  FabrikantenFilterResponseModel: FabrikantenFilterResponseModel;
  public ActiveCategory: string = "";
  public ActiveProduct: string = "";
  public FilterMenuDesktopIsShown: boolean = true;
  public FilterMenuMobileIsShown: boolean = false;
  public FilterMenuMobileBackgroundIsShown: boolean = false;
  public SavedValues: Array<FabFilterSavedValue> = new Array<FabFilterSavedValue>();
  public IsLocalhost: boolean = false;
  public SelectedProductIdOld: number = undefined;

  public slideConfig = {
    "prevArrow": "<button type='button' class='slick-prev pull-left'><i class='fal fa-chevron-left' aria-hidden='true'></i></button>",
    "nextArrow": "<button type='button' class='slick-next pull-right'><i class='fal fa-chevron-right' aria-hidden='true'></i></button>",
    "dots": true,
    "infinite": false,
    "slidesToShow": 8,
    "slidesToScroll": 8,
    "responsive": [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 6
        }
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  constructor(
    private fab: FabrikantenConsumer,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    //private themeService: ThemeService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {

    //let theme: any = this.themeService.getTheme("default");

    //this.themeService.setTheme(theme.name);

    this.FabrikantenFilterResponseModel = new FabrikantenFilterResponseModel();

    var url = (<any>this.route)._routerState.snapshot.url;

    if (url != undefined) {
      const tree = this.router.parseUrl(url);
      if (tree != undefined) {
        const children = tree.root.children.primary;

        if (children != undefined) {
          const segments = children.segments;

          if (segments.length > 1) {
            this.Product = segments[1].path;
            if (this.Product != undefined) {
              this.FabrikantenFilterResponseModel.ProductParameter = this.Product;

              this.googleAnalyticsService.sendCustomEvent(
                "Bestekservice",
                "ProductViewedDeeplink",
                this.Product,
                1);
            }
          }

          if (segments.length > 2) {
            this.Filters = "";

            for (var i = 2; i < segments.length; i++) {

              if (this.Filters != "") {
                this.Filters += "_";
              }

              this.Filters += segments[i].path;
            }

            if (this.Filters != undefined) {
              this.FabrikantenFilterResponseModel.FiltersParameter = this.Filters;
            }
          }
        }
      }
    }





    this.Refresh(true);

    this.CheckMenuFilterIsShown(window);

    if (window.location.href.indexOf("localhost") > -1) {
      this.IsLocalhost = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //    //this.breakpointMedium = event.target.innerWidth - 275;
    //    //this.breakpointMedium = event.target.innerWidth - 275;
    //    if (event.target.innerWidth >= 768) {
    //       this.slidesToShow = 8;
    //    } else {
    //       this.slidesToShow = 4;
    //    }
    // if (event.target.innerWidth >= 768) {
    //    $('.initialized-carousel').slick('slickSetOption', {
    //       slidesToShow: 3,
    //       slidesToScroll: 1
    //    }, true);
    // }  
    this.CheckMenuFilterIsShown(event.target);
    this.CheckSlickButtonsAreShown(event.target);
  }

  public CheckMenuFilterIsShown(window) {
    if (window.innerWidth >= 1000) {
      this.FilterMenuDesktopIsShown = true;
    } else {
      this.FilterMenuDesktopIsShown = false;
    }
  }

  public CheckSlickButtonsAreShown(window) {
    let SlickTrackElement: any = document.getElementsByClassName("slick-track")[0];
    let FabFiltersButtonsForProductElement: any = document.getElementsByClassName("carousel__fab-filters")[0];
    let FabFiltersButtonsButtonsForProductsElement: any = document.getElementsByClassName("fab-filters__buttons-for-products")[0];
    if (SlickTrackElement !== undefined && FabFiltersButtonsForProductElement !== undefined) {
      if (SlickTrackElement.offsetWidth > FabFiltersButtonsForProductElement.offsetWidth) {
        FabFiltersButtonsButtonsForProductsElement.style.margin = "0 25px 0px 25px";
      } else {
        FabFiltersButtonsButtonsForProductsElement.style.margin = "0 -7.5px";
      }
    }

    // let FabFiltersMainElement: any = document.getElementsByClassName("fab-filters__main")[0];
    // let FabFiltersMainTitleElement: any = document.getElementsByClassName("fab-filters__title-for-products")[0];
    // let FabFiltersButtonsForProductsElement: any = document.getElementsByClassName("fab-filters__buttons-for-products")[0];
    // let FabFiltersBreadcrumbElement: any = document.getElementsByClassName("fab-filters__breadcrumb")[0]; 
    // let FabProductTitleElement: any = document.getElementsByClassName("fab-product__title")[0];
    // let FabProductDescriptionElement: any = document.getElementsByClassName("fab-product__description")[0];   
    // let FabFiltersButtonFilterMenuElement: any = document.getElementsByClassName(" fab-filters__button-filter-menu")[0];

    // if (
    //    (FabFiltersMainElement !== undefined) && (FabFiltersMainTitleElement !== undefined) &&
    //    (FabFiltersButtonsForProductsElement !== undefined) && (FabFiltersBreadcrumbElement !== undefined) &&
    //    (FabProductTitleElement !== undefined) && (FabProductDescriptionElement !== undefined) &&
    //    (FabFiltersButtonFilterMenuElement !== undefined) 
    // ) {
    //    ret = FabFiltersMainElement.offsetHeight
  }

  public HideFilterMenuMobile() {
    this.FilterMenuMobileBackgroundIsShown = false;
    setTimeout(() => {
      this.FilterMenuMobileIsShown = false;
    }, 300);
  }

  public ShowFilterMenuMobile() {
    this.FilterMenuMobileIsShown = true;
    setTimeout(() => {
      this.FilterMenuMobileBackgroundIsShown = true;
    }, 300);
  }

  public GetMaximimumHeight() {
    let ret: number = 0;
    if (window.innerHeight > 600) {
      ret = window.innerHeight - 30;
    } else {
      ret = window.innerHeight;
    }
    return ret;
  }

  public SelectProduct(product: BCBProductViewModel) {

    this.googleAnalyticsService.sendCustomEvent(
      "Bestekservice",
      "ProductViewed",
      product.Naam,
      product.Id);

    this.FabrikantenFilterResponseModel.SelectedProduct = product;
    this.ActiveProduct = product.Naam;
    this.SavedValues = new Array<FabFilterSavedValue>();
    this.Refresh(false);
  }

  public Refresh(First: boolean = false) {

    if (!First) {
      if (this.FabrikantenFilterResponseModel.FiltersParameter != undefined) {
        this.FabrikantenFilterResponseModel.FiltersParameter = undefined;
      }
    }

    this.LoadFilters();
  }

  private LoadFilters(): void {
    this.Loading = true;

    this.fab.FilterOptions_Observable(this.FabrikantenFilterResponseModel).subscribe((data) => {
      this.FabrikantenFilterResponseModel = new FabrikantenFilterResponseModel(data);

      if (this.SavedValues.length > 0) {
        _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
          _.forEach(this.SavedValues, (savedValue) => {
            if (savedValue.Id == filter.Id) {
              if (savedValue.Value != undefined) {
                filter.RangeValue = savedValue.Value;
              }
            }
          });
        });
      }

      this.Loading = false;

      setTimeout(() => {
        this.CheckSlickButtonsAreShown(window);
      }, 0);

      const FilterCategorie = _.find(this.FabrikantenFilterResponseModel.Filters, (filter) => filter.Important);
      if (FilterCategorie !== undefined) {
        if (FilterCategorie.SelectedOption == undefined) {
          this.openDialog();
        }
        else {
          this.ActiveCategory = FilterCategorie.SelectedOption.Name;
        }
      }

      if (this.FabrikantenFilterResponseModel.SelectedProduct != undefined) {
        if (this.SelectedProductIdOld == undefined || (this.SelectedProductIdOld !== this.FabrikantenFilterResponseModel.SelectedProduct.Id)) {
          this.ShowScrollbar();
          this.SelectedProductIdOld = this.FabrikantenFilterResponseModel.SelectedProduct.Id;
        }
      }

      if (this.FabrikantenFilterResponseModel.DeeplinkModus) {
        console.log("deeplink modus active");
      }
    });
  }

  public ShowScrollbar() {
    let FabFiltersFilterElement: any = document.getElementsByClassName("fab-filters__filter-menu")[0];
    if (FabFiltersFilterElement !== undefined) {
      FabFiltersFilterElement.scroll(0, 1);
      FabFiltersFilterElement.scroll(0, -1);
    }
  }

  public GetButtonRole(product: IBCBProductViewModel): string {
    let ret = "secundary";
    if ((this.FabrikantenFilterResponseModel.SelectedProduct !== undefined) && (this.FabrikantenFilterResponseModel.SelectedProduct !== null)) {
      if (product.Id == this.FabrikantenFilterResponseModel.SelectedProduct.Id) {
        ret = "primary";
      }
    }
    return ret;
  }

  public SetLastSelectedView(lastselectedView: string) {
    //if (this.FabrikantenFilterResponseModel.SelectedProduct != undefined) {
    //  this.FabrikantenFilterResponseModel.SelectedProduct.ViewSelected = lastselectedView;
    //}
  }

  public SetLastSelectedProduct(lastselectedproduct: any) {
    this.FabrikantenFilterResponseModel.SelectedProduct = lastselectedproduct;
  }

  public openDialog(): void {
    this.ActiveCategory = "";
    this.ActiveProduct = "";
    const dialogConfig = new MatDialogConfig();
    let fabfilterdialogoptions = new Array<FabFilterDialogOption>();

    const FilterCategorie = _.find(this.FabrikantenFilterResponseModel.Filters, (filter) => filter.Important);

    _.forEach(FilterCategorie.Options, (option) => {
      //@vuhuy: looks like some merford specific code. Unnecesary If-statement?
      //if ((option.Name == "Deuren") || (option.Name == "Roosters en roosterpuien")) {
      let fabfilterdialogoption = new FabFilterDialogOption();
      fabfilterdialogoption.Name = option.Name;
      fabfilterdialogoption.Description = option.Description;
      fabfilterdialogoption.DescriptionShort = option.DescriptionShort;
      fabfilterdialogoption.Image = option.Image;
      fabfilterdialogoption.Id = option.Id;
      fabfilterdialogoptions.push(fabfilterdialogoption);
      //}
    });
    fabfilterdialogoptions = _.sortBy(fabfilterdialogoptions, 'Name');

    dialogConfig.data = {
      fabfilterdialogoptions: fabfilterdialogoptions
    };
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "fab-filters-dialog--overlay";

    const dialogRef = this.dialog.open(FabFiltersDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.SelectCategory(result);
      //this.dialog.closeAll();
    });
  }

  public SelectCategory(id: number): void {
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      if (filter.Important) {
        _.forEach(filter.Options, (option, index) => {
          if (option.Id == id) {
            filter.SelectedOption = filter.Options[index];
            this.ActiveCategory = filter.SelectedOption.Name;
            this.SetSavedValue(filter.Id, filter.Options[index].Id);
          }
        });
      }
    });

    if (this.ActiveProduct !== "") {
      this.DeselectActiveProduct();
    } else {
      this.Refresh(false);
    }

    setTimeout(() => {
      this.ShowScrollbar();
    }, 2000);
  }

  public GoToChooseCategories() {
    this.FabrikantenFilterResponseModel.SelectedProduct = null;
    this.ActiveProduct = "";
    this.ActiveCategory = "";

    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      filter.SelectedOption = null;
      filter.SelectedOptions = [];
    });

    this.SavedValues = new Array<FabFilterSavedValue>();
    this.Refresh(false);
    this.ActiveCategory = "";
  }

  public GetMaximimumHeightFilterMenu() {
    let ret: number = 0;
    let FabFiltersMenuBackgroundElement: any = document.getElementsByClassName("fab-filters__filter-menu-background")[0];
    if (FabFiltersMenuBackgroundElement !== undefined
    ) {
      ret = FabFiltersMenuBackgroundElement.offsetHeight;
    }
    return ret;
  }

  public GetNumberOfShownProducts() {
    let ret: string = "...";
    if (!this.Loading) {
      ret = this.FabrikantenFilterResponseModel.Products.length.toString();
    }
    return ret;
  }

  public SetSavedValue(id: number, savedValueSet: number) {
    if (this.SavedValues.some(savedValue => savedValue.Id === id)) {
      _.forEach(this.SavedValues, (savedValue: FabFilterSavedValue) => {
        if (savedValue.Id == id) {
          savedValue.Value = savedValueSet;
        }
      });
    } else {
      let savedValue: FabFilterSavedValue = new FabFilterSavedValue(id, savedValueSet);
      this.SavedValues.push(savedValue);
    }
  }

  public FabFiltersIsShown() {
    let ret: boolean = true;

    const FilterWithNameCategoryExists = this.FabrikantenFilterResponseModel.Filters.some(filter => filter.Name.includes("Categorie"));
    if (FilterWithNameCategoryExists && (this.ActiveCategory.length == 0)) {
      ret = false;
    }

    return ret;
  }

  public ShouldDisplay(filter: IFilterViewModel) {

    if (filter.Name == 'Categorie') {
      return false;
    }

    if (this.BlockedByProduct()) {
      return false;
    }

    if (this.FabrikantenFilterResponseModel.SelectedProduct == undefined) {
      if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.leveltwo) {
        return false;
      }
    }
    else {
      if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.levelone) {
        return false;
      }
    }

    if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.none) {
      return false;
    }

    return true;
  }

  public BlockedByProduct(): boolean {

    if (this.FabrikantenFilterResponseModel.SelectedProduct != undefined) {
      if (this.FabrikantenFilterResponseModel.SelectedProduct.Naam == "StoVentec") {
        return true;
      }

      if (this.FabrikantenFilterResponseModel.SelectedProduct.Naam == "StoTherm Wood") {
        return true;
      }

      if (this.FabrikantenFilterResponseModel.SelectedProduct.Naam == "StoTherm Resol") {
        return true;
      }
    }

    return false;
  }

  public DeselectActiveProduct() {
    this.FabrikantenFilterResponseModel.ProductParameter = undefined;
    this.FabrikantenFilterResponseModel.FiltersParameter = undefined;
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      if (filter.Name !== "Categorie") {
        filter.SelectedOption = null;
        filter.SelectedOptionId = "";
        filter.RangeValue = null;
      }
    });
    this.FabrikantenFilterResponseModel.SelectedProduct = undefined;
    //this.FabrikantenFilterResponseModel.SelectedProduct = product;

    this.ActiveProduct = "";
    this.SavedValues = new Array<FabFilterSavedValue>();
    this.Refresh(false);
  }

  public RemoveSelectedFilters() {
    this.SavedValues = new Array<FabFilterSavedValue>();
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      if (filter.Name !== "Categorie") {
        filter.SelectedOption = null;
        filter.SelectedOptionId = "";
        if (filter.SelectedOptions.length > 0) {
          filter.SelectedOptions.splice(0, filter.SelectedOptions.length);
        }
        filter.RangeValue = null;
      }
    });
    this.Refresh(false);
  }

  public FiltersSelected() {
    let ret: boolean = false;
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      if (filter.Name !== "Categorie") {
        if ((filter.SelectedOptionId !== null) && (filter.SelectedOptionId !== "")) {
          ret = true;
        }
      }
    });
    return ret;
  }

  public GetFirstImage(product: BCBProductViewModel) {

    var url = "";

    for (var i = 0; i < product.Links.length; i++) {
      if (product.Links[i].Type == "img") {
        return product.Links[i].URL;
      }
    }

    return url;
  }
}

export class FabFilterDialogOption {
  Name: string;
  Image: string;
  Description: string;
  DescriptionShort: string;
  Id: number;
}

class FabFilterSavedValue {
  Id: number;
  Value: number;

  constructor(id: number, value: number) {
    this.Id = id;
    this.Value = value;
  }
}
