import { Component, Input, OnInit } from '@angular/core';
import { treenode } from '../wct-stui-tree-menu/wct-stui-tree-menu.component';

@Component({
  selector: 'wct-stui-tree-node',
  templateUrl: './wct-stui-tree-node.component.html',
  styleUrls: ['./wct-stui-tree-node.component.scss']
})
export class WctStuiTreeNodeComponent implements OnInit {
  @Input() treenode: treenode

  constructor() { }

  ngOnInit(): void {
  }

}
