<div class="container-fluid">
    <h3>Overzicht Projecten</h3>
    <h5>Organisatie: [.Wat moet hier komen?]</h5>

    <div>
        <button mat-raised-button color="primary">Nieuw Project</button>
    </div>

    <table>
        <tr>
            <td>Hier Moet nog een table worden uitgelzen met alle projecten</td>
        </tr>
    </table>

    <div>
        Resulataten:
        <div>
            <button mat-raised-button color="primary">Importeer woningen + aanmaak hoofdbewoners voor nieuwe woningen</button>
        </div>
        <hr />
        <div>
            <button mat-raised-button color="primary">
                Importeer woningen + aanmaak hoofdbewoners voor neiwue en reeds geimporteerde wonignen

            </button>
        </div>

        <p>
            Project Settings
        </p>
    </div>
</div>