import { Components } from '../../development/initialization/Components';
// // [conv] import "(./WctAccount).less";
// // [conv] import "(./WctStartschermBasisversie).less";
import * as _ from 'lodash';
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router, ActivatedRoute } from '@angular/router';
import * as ConsumersFrontendController from '@consumers/FrontendController';
//import * as ConsumersHulpApiController from '@consumers/HulpApiController';
import { WindowService } from '../../viewers/WindowService';
import { AccountService } from '../../services/AccountService';
// import * as HelpersBackwards from '../../Helpers/Backwards';
import { AppConfigService } from '../../infrastructure/app.configservice';
import * as HelpersOverlay from '../../Helpers/Overlay';
import * as ViewModelsActivatie from '../../Models/Activatie';
import { WctActivatiePopupComponent } from "./WctActivatiePopup";
import { WctDisclaimerDialog } from "../../account/components/disclaimer/WctDisclaimerDialog";
import { ProjectService, IProjectService } from '../../services/ProjectService';


@Component({
   selector: 'wct-startscherm',
   styleUrls: ['./WctStartschermBasisversie.scss'],
   templateUrl: './WctStartscherm.html',
   encapsulation: ViewEncapsulation.None
})
export class WctStartschermComponent {
   @Input() modus: string;
   @Input() melding: string;
   startscherm: boolean = true;
   startschermActivatie: boolean = false;
   startschermLogin: boolean = true;
   formulierActivatie: boolean = false;
   FormulierActivatieIsBeschikbaar: boolean = false;
   DemoActief: boolean = this.appconfigservice.Software.DemoActief;
   startschermGereed: boolean = false;
   windowWidth: number = 0;
   foutmeldingOpSmartphone: string = "";
   foutmeldingOpSmartphoneZichtbaar: boolean = false;
   isVerkoopSite: boolean = false;
   urlStartschermAchtergrond: string = "";
   urlStartschermAfbeelding: string = "";
   urlStartschermGrootLogoVanKlant: string = "";
   StartschermGrootLogoVanKlantBestaat: boolean = false;
   firstCheckStartschermAchtergrond: boolean = false;

   private activationKey: string = "";

   LogoUrl: string = "";

   public NaamVanOpdrachtgever: string = "";
   public AlgemeneIntrotekst: string = "";
   public UitlegActiverentekst: string = "";
   public ToonLoginPaginaMetGroteAchtergrondafbeelding: boolean = false;
   public BezigViewModel: HelpersOverlay.IBezigState;

   constructor(
      private accountservice: AccountService,
      private windowservice: WindowService,
      //private hulpservice: ConsumersHulpApiController.HulpApiConsumer,
      private frontendconsumer: ConsumersFrontendController.FrontendConsumer,
      private dialog: MatDialog,
      private router: Router,
		  private route: ActivatedRoute,
      private appconfigservice: AppConfigService,
      private projectservice: ProjectService
   ) {
      'ngInject';
      this.isVerkoopSite = this.appconfigservice.Software.IsVerkoopsite;
      //HelpersBackwards.ConstSoftware.LaptopVerkoop;
      this.ToonStartschermEersteKeer();
      this.DemoActief = this.appconfigservice.Software.DemoActief;
      if (this.modus == 'logon') {
         this.startschermActivatie = false;
         this.startschermLogin = true;
      } else if (this.modus == 'logonzonderactiveren') {
         this.startschermActivatie = false;
         this.startschermLogin = true;
         this.FormulierActivatieIsBeschikbaar = false;
      } else if (this.modus == 'activatie') {
         this.startschermActivatie = true;
         this.startschermLogin = false;
      }
      this.windowWidth = window.innerWidth || document.documentElement.clientWidth;
      this.windowservice.changeSubject.subscribe({
         next: () => this.windowWidth = this.windowservice.GeefWindowWidth()
      });

      this.LogoUrl = `/Content/KlantSpecifiek/${this.appconfigservice.Software.CSSMap}/images/logo-klant.png`;

      let self = this;
      if (this.modus != 'logonzonderactiveren') {
         accountservice.LoginSchermSettings().then(b => {
            if (accountservice.ToonActivatieMogelijkheid()) {
               self.FormulierActivatieIsBeschikbaar = true;
            }
         });
      }

      this.projectservice.GeefOpdrachtgever().then((res) => {
        this.NaamVanOpdrachtgever = res;
			}).catch((error) => {
				console.log("GeefOpdrachtgever mislukt: " + error.status + " <-- and --> " + error.statusText);
			});

      //this.HaalAfbeeldingenOp();
      // this.urlStartschermAchtergrond = '/Content/Images/startpagina/startpagina-achtergrond-basis.jpg';
      this.urlStartschermGrootLogoVanKlant = '/Content/Images/configurator/startscreen/logos-gemeente-aalsmeer-en-regionaal-energieloket.jpg';
      this.urlStartschermAfbeelding = '/Content/Images/configurator/startscreen/Start-3.svg';

      this.BezigViewModel = HelpersOverlay.BezigState.Default();
      this.BezigViewModel.Diameter = 75;

      this.AlgemeneIntrotekst = this.appconfigservice.Software.TekstLoginAlgemeneIntro;
      this.UitlegActiverentekst = this.appconfigservice.Software.TekstLoginUitlegActiveren;
      this.ToonLoginPaginaMetGroteAchtergrondafbeelding = this.appconfigservice.Software.ToonLoginPaginaMetGroteAchtergrondafbeelding;
   }

   public ToggleLoader() {
      this.BezigViewModel.ToonOverlay = !this.BezigViewModel.ToonOverlay;
   }

   public ToegestaneBrowser(): boolean {
      //if (navigator.appVersion.indexOf("MSIE 10") !== -1) {
      //   return false;
      //}
      //alert(IE.isTheBrowser + " - " + IE.actualVersion);

      return true;
   }

   public GetStartschermAchtergrond(startschermAchtergrond: string): any {
      if (startschermAchtergrond != "" && this.ToonLoginPaginaMetGroteAchtergrondafbeelding) {
         if (!this.firstCheckStartschermAchtergrond) {

            let jpgUrlSubmap = null;
            let pngUrlSubmap = null;

            if (this.appconfigservice.Software.CSSSubMap != undefined) {
               let cssmap = this.appconfigservice.Software.CSSSubMap.replace("\\", "/");

               jpgUrlSubmap = '/Content/KlantSpecifiek/' + cssmap + '/images/' + startschermAchtergrond + ".jpg";
               pngUrlSubmap = '/Content/KlantSpecifiek/' + cssmap + '/images/' + startschermAchtergrond + ".png";
            }

            let jpg_url = '/Content/KlantSpecifiek/' + this.appconfigservice.Software.CSSMap + '/images/' + startschermAchtergrond + ".jpg";
            let png_url = '/Content/KlantSpecifiek/' + this.appconfigservice.Software.CSSMap + '/images/' + startschermAchtergrond + ".png";


            if (jpgUrlSubmap != null && this.UrlExists(jpgUrlSubmap)) {
               this.urlStartschermAchtergrond = jpgUrlSubmap;
            } else if (pngUrlSubmap != undefined && this.UrlExists(pngUrlSubmap)) {
               this.urlStartschermAchtergrond = pngUrlSubmap;
            } else if (this.UrlExists(jpg_url)) {
               this.urlStartschermAchtergrond = jpg_url;
            } else if (this.UrlExists(png_url)) {
               this.urlStartschermAchtergrond = png_url;
            } else {
               this.urlStartschermAchtergrond = '/Content/images/startpagina/startpagina-achtergrond-basis.jpg';
            }

            this.firstCheckStartschermAchtergrond = true;
         }

         this.urlStartschermAchtergrond = this.urlStartschermAchtergrond.replace(/ /g, "%20");
         return this.urlStartschermAchtergrond;
      }
      else {
         return "";
      }
   }

   public HaalAfbeeldingenOp(): void {
      this.frontendconsumer.GeefAfbeelding("startpagina-afbeelding-basis", "startpagina").then(response => {
         this.urlStartschermAfbeelding = response;
      });
      this.frontendconsumer.GeefAfbeelding("logo-op-startpagina-voor-inloggen", "startpagina").then(response => {
         this.urlStartschermGrootLogoVanKlant = response;
      });
   }

   public GeefStartschermGrootLogoVanKlantBestaat(): boolean {
      var ret: boolean = false;
      var element: any = document.getElementById("startschermgrootlogovanklant");
      var elementHeight = element.naturalHeight;
      var elementWidth = element.naturalWidth;
      if (elementHeight > 1 && elementWidth > 1) {
         this.StartschermGrootLogoVanKlantBestaat = true;
         ret = true;
      }
      return ret;
   }

   public UrlExists(urlStartschermAchtergrond): boolean {
      var http = new XMLHttpRequest();
      http.open('HEAD', urlStartschermAchtergrond, false);
      http.send();
      return http.status != 404;
   }

   public ToonStartschermEersteKeer() {
      this.startschermGereed = true;
   };

   public Demo() {
      this.accountservice.DemoLogin().then(b => {
         window.location.replace('/');
      });
   }

   public ToonStartschermActivatie() {
      this.startschermLogin = false;
      this.startschermActivatie = true;
      scroll(0, 0);
   }

   public ToonRegistrerenScherm() {
      let urlWithoutParams = 'root.registreren';
            this.router.navigate(
               [urlWithoutParams],
               {
                  relativeTo: this.route,
                  queryParamsHandling: 'merge', // remove to replace all query params by provided
               }
            );
   }

   public ToonStartschermLogin() {
      this.startschermActivatie = false;
      this.startschermLogin = true;

      // removed code. We don't support angularjs anymore + there is no element called startpaginaStap4
      // if (ng.element('#startpaginaStap4').length) {
      //    let currentState = this;
      //    setTimeout(function () {
      //       currentState.scrollToElement('#startpaginaStap4', 1000);
      //    }, 100);
      // }
   }

   public receiveActivationKey(event){
      this.activationKey = event;
   }

   public GebruikActivatieSleutel() {
      this.accountservice.GebruikActivatieSleutel(this.activationKey).subscribe(act => {
         if (act.Melding.length > 0) {
            if (this.windowWidth < 768) {
               this.foutmeldingOpSmartphone = act.Melding;
               this.foutmeldingOpSmartphoneZichtbaar = true;
            } else {
               let oorzaak: string = "";
               if (act.Melding.toLowerCase().indexOf('geen activatiecode opgegeven') == 0) {
                  oorzaak = "FoutGeenCode";
               } else if (act.Melding.toLowerCase().indexOf('woning') > -1) {
                  oorzaak = "FoutWoningCode";
               } else {
                  oorzaak = "FoutVerkeerdeCode";
               }

               const dialogConfig = new MatDialogConfig();
               dialogConfig.data = {
                  oorzaak: oorzaak
               };
               dialogConfig.panelClass = "activatieformulier__mat-dialog";
               this.dialog.open(WctActivatiePopupComponent, dialogConfig);
            }
         } else {
            this.ToonFormulierActivatie();
         }
      });
   }

   public ToonFormulierActivatie() {
      // states werkend maken voor ook de account pagina niet logon.cshtml te maken maar via /angular/index
      var wId: number = 0;
      var act: ViewModelsActivatie.Activatie = this.accountservice.GeefActivatieAccount();
      if (act != null) {
         wId = act.WoningId;
      }
      let urlWithoutParams: string = "";

      urlWithoutParams = '/activeer';
            this.router.navigate(
               [urlWithoutParams],
               {
                  relativeTo: this.route,
                  queryParamsHandling: 'merge', // remove to replace all query params by provided
               }
            );
      // switch (act.Rol) {
      //    case "Bewoner":
      //       urlWithoutParams = 'activeer';
      //       this.router.navigate(
      //          [urlWithoutParams],
      //          {
      //             relativeTo: this.route,
      //             queryParamsHandling: 'merge', // remove to replace all query params by provided
      //          }
      //       );
      //       break;
      //    case "Huurder":
      //       urlWithoutParams = 'bewoner.ActivatieHuurder';
      //       this.router.navigate(
      //          [urlWithoutParams],
      //          {
      //             relativeTo: this.route,
      //             queryParamsHandling: 'merge', // remove to replace all query params by provided
      //          }
      //       );
      //       break;
      //    default:
      //       urlWithoutParams = 'bewoner.ActivatieWoning';
      //       this.router.navigate(
      //          [urlWithoutParams],
      //          {
      //             relativeTo: this.route,
      //             queryParamsHandling: 'merge', // remove to replace all query params by provided
      //          }
      //       );
      //       break;
      // }
   }

   public scrollToElement(id: string, duration: number) {
      if (duration === null || duration === undefined) {
         duration = 0;
      }

      $('body,html').animate({
         scrollTop: $(id).offset().top + 'px'
      }, duration);
   }

   public ToonDemoKnop(): boolean {
		return this.appconfigservice.Software.DemoActief;
   }

   public goToWebsite(url: string, openInNewTab: boolean) {
      if (openInNewTab) {
         window.open(url, '_blank');
      } else {
         window.open(url);
      }
   }

   public ToonDisclaimer(): void {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = "disclaimer__mat-dialog";

      const dialogRef = this.dialog.open(WctDisclaimerDialog, dialogConfig);
    }
}



