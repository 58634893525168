import * as _ from 'lodash';
import * as HelpersBackwards from '.././Helpers/Backwards';
import * as HelpersMasterTypescript from '.././Helpers/MasterTypescript';
// Module

export enum WachtenType {
	WachtenOpties,
	WachtenViewer
}



export class WachtenObject {
	private Type: WachtenType;

	GeefType() {
		return this.Type;
	}

	constructor(t: WachtenType) {
		this.Type = t;
	}
}

export class MasterController {
	public Gebruik_Angular_Routing: boolean = false;
	private ViewModeEventName: string = "VIEWMODE";
	private NieuweWoningEventName: string = "NIEUWEWONING";
	private AangevraagdeNieuweWoningEventName: string = "AANGEVRAAGDENIEUWEWONING";
	private EnergieHerberekenenEventName: string = "HERBEREKENENERGIE";
	private OptieAangepastEventName: string = "OPTIEAANGEPAST";
	private VoordatOptieAangepastEventName: string = "VOORDATOPTIEAANGEPAST";
	private ScopeEventName: string = "SCOPE";
	private CameraWijzigingEventName: string = "CAMERAWIJZIGING";
	private FocusScenarioEventName: string = "FOCUSSCENARIO";
	private WachtenEventName: string = "WACHTENEVENT";
	private ResizeEventName: string = "RESIZE";
	private StartSiteEventName: string = "STARTSIZE";

	private AangevraagdeNieuweWoning: number = -1;
	private AangevraagdeNieuwScenario: number = -1;
	private ExtraAangevraagdeNieuweWoning: number = -1;
	private ExtraAangevraagdeNieuwScenario: number = -1;


	private HuidigScenarioMinimum: boolean = false;
	//public HuidigeWijk: number = -1;

	private WoningGekozen: boolean = null;
	private HuidigeXml: string = "";
	private HuidigeTypelogie: string = "";
	private HuidigeGespiegeld: boolean = false;



	private FocusScenario: number = -1;

	private WachtenModules: Array<WachtenObject> = [];
	private WachtenMelding: string = "";

	private ClientEventId: number = 0;
	private LaatsteEvents: any = [];

	private CameraWijziging2DVerdieping: number = -1;
	private CameraWijziging2DInvalshoek: string = "";
	private CameraWijziging2DTekening: string = "";

	private ReadyFuncties: Array<() => void> = [];

	private mIsUitvraagAktief: boolean = false;
	private mRollen: string[] = [];
	private mCSSMap: string;

	private mWisselWoningAanvraagBezig: boolean = false;
	private mExtraWisselWoningAanvraag: boolean = false;

	private mKeepAliveTimeOut: any = null;
	public IsGestart: boolean = false;

	constructor() {
	}

	ZetIngediend() {
		//this.HuidigScenarioReadonly = true;
		this.HuidigScenarioMinimum = false;
	}

	// public AngularScopeApply() {
	// 	if (!IsLegacy())
	// 	{
	// 		return;
	// 	}

	// 	var selector = "#HeleScherm";

	// 	if (typeof ng == 'undefined') {
	// 		return;
	// 	}
	// 	var el = ng.element(selector);
	// 	if (el == null) {
	// 		return;
	// 	}
	// 	var scope = el.scope();
	// 	scope.$applyAsync();
	// }

	// public AngularScopeApplyAlsKan() {
	// 	if (!IsLegacy())
	// 	{
	// 		return;
	// 	}

	// 	if (scope != undefined) {
	// 		var selector = "#HeleScherm";

	// 		if (typeof ng == 'undefined') {
	// 			return;
	// 		}
	// 		var el = ng.element(document.querySelector(selector));
	// 		if (el == null) {
	// 			return;
	// 		}

	// 		var scope = el.scope();


	// 		if (scope.$root.$$phase != '$apply' && scope.$root.$$phase != '$digest') {
	// 			scope.$apply();
	// 		}
	// 	}
	// }

	//public AngularBroadcast(event, message, supressApply) {
		// if (!IsLegacy())
		// {
		// 	return;
		// }
		// var selector = "#HeleScherm";

		// if (typeof ng == 'undefined') {
		// 	return;
		// }

		// var el = ng.element(selector);
		// if (el == null) {
		// 	return;
		// }

		// var scope = el.scope();

		// if (!supressApply) {
		// 	scope.$apply(function () {
		// 		scope.$broadcast(event, message);
		// 	});
		// }
		// else {
		// 	scope.$broadcast(event, message);
		// }
	//};

	public ZetInitieelWoningEnScenario(woningid: number, wijk: number, blok: number, scenario: number, readonly: boolean, minimum: boolean, clienteventid: number) {
		//this.HuidigScenarioReadonly = readonly;
		this.HuidigScenarioMinimum = minimum;
		this.AangevraagdeNieuweWoning = woningid;
		this.AangevraagdeNieuwScenario = scenario;
		this.ClientEventId = clienteventid;
	}

	public ZetInitieelParams(uitvraag: boolean, rollen: string[], cssmap: string) {
		this.mIsUitvraagAktief = uitvraag;
		this.mRollen = rollen;
		this.mCSSMap = cssmap;
	}

	public IsUitvraagAktief(): boolean {
		return this.mIsUitvraagAktief;
	}

	public HeeftRol(s: string): boolean {
		const p = _.filter(this.mRollen, (f) => f.toLowerCase() === s.toLowerCase());
		return p.length === 1;
	}

	public CSSMap(): string {
		return this.mCSSMap;
	}

	/* Client event ID */
	public GeefNieuwClientId(): number {
		this.ClientEventId++;
		return this.GeefHuidigClientId();
	}

	public GeefHuidigClientId(): number {
		return this.ClientEventId;
	}

	public ZetNieuweTimeout(timeVal?: number): void {
		var self = this;
		$.get("/Home/KeepAlive", function (timeoutVal) {
			var nieuweTimeoutVal: number;
			if (typeof timeVal === "undefined") {
				nieuweTimeoutVal = (timeoutVal - 1) * 60000;
			} else {
				nieuweTimeoutVal = timeVal;
			}
			//clearTimeout(self.mKeepAliveTimeOut);
			self.mKeepAliveTimeOut = setTimeout(function () { HelpersMasterTypescript.MasterControllerInstance.ZetNieuweTimeout(timeVal) }, nieuweTimeoutVal);
		});
	}

	public GeefLaatsteEvents(): any {
		return this.LaatsteEvents;
	}

	public GeefCameraWijziging2DVerdieping(): number { return this.CameraWijziging2DVerdieping; }
	public GeefCameraWijziging2DInvalshoek(): string { return this.CameraWijziging2DInvalshoek; }
	public GeefCameraWijziging2DTekening(): string { return this.CameraWijziging2DTekening; }


	GeefWachtenMelding = function () {
		return this.WachtenMelding;
	}

	GeefUpdateBenodigd = function (m) {
		if (this.WachtenModule == null) {
			return false;
		}
		var wachtenAantal = this.WachtenModule.length;
		for (var i = 0; i < wachtenAantal; i++) {
			var obj = this.WachtenModule[i];
			var t = obj.GeefType();
			if (t == m) {
				return true;
			}
		}
		return false;
	}

	public GeefAangevraagdeNieuweWoning(): number {
		return this.AangevraagdeNieuweWoning;
	}

	public GeefAangevraagdeNieuwScenario(): number {
		return this.AangevraagdeNieuwScenario;
	}

	public GeefHuidigScenarioMinimum(): boolean {
		return this.HuidigScenarioMinimum;
	}

	public GeefWoningGekozen(): boolean {
		return this.WoningGekozen;
	}

	public GeefFocusScenario(): number {
		return this.FocusScenario;
	}

}


export class MasterViewModel {
	constructor() {

	}
}



export class MasterFooterViewModel {
	RekenBezig: boolean = (false);
	DownloadBezig: boolean = (false);
	ViewerBezig: boolean = (false);
	OpslaanGelukt: boolean = (false);

	//Reken: HelpersBackwards.BezigCollectie = new HelpersBackwards.BezigCollectie(this.RekenBezig);
	//Download: HelpersBackwards.BezigCollectie = new HelpersBackwards.BezigCollectie(this.DownloadBezig);
	//Viewer: HelpersBackwards.BezigCollectie = new HelpersBackwards.BezigCollectie(this.ViewerBezig);
	//Opslaan: HelpersBackwards.BezigCollectie = new HelpersBackwards.BezigCollectie(this.OpslaanGelukt);

	RekenDebugFunctie: () => void;
	DownloadDebugFunctie: () => void;
	ViewerDebugFunctie: () => void;


	constructor() {
	}
}

export function ZetLegacy()
{
	let anywindow : any = window;
	anywindow.WctLegacy = true;
}

export function IsLegacy()
{
	let anywindow : any = window;
	if (anywindow.WctLegacy)
	{
		return true;
	}
	return false;
}

export var MasterControllerInstance = new MasterController();
export var MasterViewModelInstance = new MasterViewModel();
export var MasterFooterViewModelInstance = new MasterFooterViewModel();

// LET OP WEER UITZETTEN......
if (typeof HelpersBackwards.UnitTestMode != "undefined") {
	MasterControllerInstance.ZetNieuweTimeout(50000);  // niet default aanroepen
}
