import { ErrorHandler, Injectable } from '@angular/core';
import { JL } from 'jsnlog';
import { WctLogService } from '../services/WctLogService';

@Injectable()
export default class WctErrorHandler extends ErrorHandler {

    //constructor(private $log: WctLogService) {
	constructor() {
        // The true paramter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
        // when an error happens. If we do not rethrow, bootstrap will always succeed.
        super();
    }

    handleError(error) {
        // send the error to the server

        // JL('Angular').fatalException(error);
        
		// var exmsg = "";
		// if (exception.message) {
		// 	exmsg += exception.message;
		// }
		// if (exception.stack) {
		// 	exmsg += " | stack: " + exception.stack;
		// }
		// if (console.error) {
		// 	console.error(exmsg);
		// }
		// else {
		// 	console.log(exmsg);
		// }

		//this.$log.error(error);

        // delegate to the default handler
        super.handleError(error);
    }
}
