// [conv] import "(./WctFooter).less";
import * as _ from 'lodash';
import * as ng from 'angular';
import * as ComponentslichtWctLichtDialoog from '../../components/licht/WctLichtDialoog';
import * as ComponentsenergietraceWctEnergieTrace from '../../components/energietrace/WctEnergieTrace';
import * as ServicesSceneService from '../../viewers/SceneService';
//import * as ServicesScenarioService from '../../services/ScenarioService';
import * as ServicesWoningEventService from '../../services/WoningEventService';
import * as HelpersBackwards from '../../Helpers/Backwards';


	interface IFooterBindings {
	}

	export interface IFooterController extends IFooterBindings {
		WebsiteNaam: string;
		Jaar: string;
		isVerkoopSite: boolean;

		Disclaimer();

		RekenKlik(event: any);
		DownloadKlik(event: any);
		ViewerKlik(event: any);

		Reken: BezigCollectie;
		Download: BezigCollectie;
		Viewer: BezigCollectie;
		Opslaan: BezigCollectie;
	}

	export class BezigContext {
		public Bezig: boolean;
		//Gewijzigd: () => void;
		public Parent: BezigCollectie;

		constructor(coll: BezigCollectie) {
'ngInject';
			this.Parent = coll;
		}

		public Waarde(b: boolean): boolean {
			if (b != null) {
				this.Bezig = b;
				//this.Parent.Herbereken();

			}
			return this.Bezig;
		}
	}

	export class BezigCollectie {
		public Contexten: Array<BezigContext> = [];

		constructor() {
'ngInject';
		}

		public GeefWaarde() {
			var ok = _.some(this.Contexten, (c) => c.Bezig);
			return (ok);
		}

		public MaakContext(): BezigContext {
			var ctx: BezigContext = new BezigContext(this);
			this.Contexten.push(ctx);
			return ctx;
		}
	}


	class FooterController implements IFooterController {
		public WebsiteNaam: string = `Powered by WoonConnect ${HelpersBackwards.ConstSoftware.WoonConnectVersieNummer}`;
		public Jaar: string = new Date().getFullYear().toString();
		public isVerkoopSite: boolean = false;

		public Reken: BezigCollectie;
		public Download: BezigCollectie;
		public Viewer: BezigCollectie;
		public Opslaan: BezigCollectie;

		public ShowResetButton = HelpersBackwards.ConstSoftware.BuildMode === "DEBUG";

		constructor(
			private readonly woningeventservice: ServicesWoningEventService.IWoningEventService,
			//private readonly scenarioservice: ServicesScenarioService.IScenarioService,
			private $window,
			private $state,
			private $mdDialog: any, //ng.material.IDialogService,
			private sceneservice: ServicesSceneService.ISceneService) {
'ngInject';
			this.Reken = this.sceneservice.Reken;
			this.Download = this.sceneservice.Download;
			this.Viewer = this.sceneservice.Viewer;
			this.Opslaan = this.sceneservice.Opslaan;
			this.isVerkoopSite = HelpersBackwards.ConstSoftware.IsVerkoopsite;
		}

		public ResetCache(): void {
			//this.scenarioservice.ResetCache().then(() => {
			//	const selectie = this.woningeventservice.GeefScopeSelectie();
			//	this.scenarioservice.BerekenScenariosOpnieuw(selectie);
			//});
		}

		public RekenKlik(event: any) {
			if (event.ctrlKey) {
				// dump van huidige configs
				var dialog: any = this.$mdDialog;
				dialog.show({
					locals: {},
					clickOutsideToClose: true,
					template: '<wct-energie-trace>',
					controller: ComponentsenergietraceWctEnergieTrace.WctEnergieTraceStartController,
					onRemoving: () => {
						// code uit te voeren wanneer dialoog gesloten is
					}
				});
			}
		}

		public DownloadKlik(event: any) {
			if (event.ctrlKey) {
				// dump van huidige configs
				this.sceneservice.DumpConfigSleutels();
			}
		}

		public ViewerKlik(event: any) {
			if (event.ctrlKey) {
				// lichtdialoog

				ComponentslichtWctLichtDialoog.StartLichtDialoog(this.$mdDialog, this.sceneservice.GeefActieveSessie());
			}
			//var nabewerkingen = viewer.nabewerkingen;
			//if (nabewerkingen != null) {
			//    nabewerkingen.idlePeriode = 3;
			//    nabewerkingen.ssao.toepassen = true;
			//    nabewerkingen.fxaa.toepassen = true;
			//   // nabewerkingen.bokeh.toepassen = true;
			//   // nabewerkingen.vignette.toepassen = true;
			//}


		}

		public Disclaimer() {
			this.$mdDialog.show({
				locals: { oorzaak: "disclaimer" },
				clickOutsideToClose: true,
				//fullscreen: this.customFullscreen,
				templateUrl: '/app/components/account/WctDisclaimer.html',
				controller: FooterController,
				controllerAs: '$ctrl'
			});
			// this.$state.go('root.Disclaimer');
		}
	}


	export class WctFooter implements ng.IComponentOptions {
		public bindings: any;
		public controller: any;
		public templateUrl: string;

		public transclude: any;
		public $routeConfig: any;

		constructor() {
			this.bindings = {
			};
			this.controller = FooterController;
			this.templateUrl = '/app/components/footer/wctFooter.html';
		}

		public static Factory() {
			return new WctFooter();
		}
	}

