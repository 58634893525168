import * as _ from 'lodash';
//import * as ng from 'angular';
import * as ViewModelsWoning from '.././Models/Woning';
import * as ViewModelsKostenBlok from '.././Models/KostenBlok';
import * as ViewModelsKostenPrijsregel from '.././Models/KostenPrijsregel';
import * as ViewModelsKostenDetails from '.././Models/KostenDetails';
import * as ViewModelsKostenHoofdstuk from '.././Models/KostenHoofdstuk';
import { IMJOPJaar } from '../interfaces/imjopjaar';
import { formatMoney } from '../Helpers/Backwards';

	export enum SubsidieEnergiebesparingEigenHuisEnum {
		Geen,
		VloerIsolatie,
		GevelIsolatie,
		DakIsolatie,
		Beglazing,
		IsolerendeDeur,
	}

	export enum SubsidieRegelingTypeEnum {
		Geen,
		SubsidieEnergiebesparingEigenHuis,
		BtwTeruggaafZonnepanelen
	}

	export enum LeningTypeEnum {
		Geen,
		EnergieBespaarLeningParticulier,
		EnergieBespaarLeningVVE,
		ProjectstimuleringsleningParticulier,
		ProjectstimuleringsleningVVE,
		BankLening,
	}

	export enum LeningMogelijkheidTypeEnum {
		Geen,
		Particulier,
		VVE,		
	}

	export class BerekeningTotaals {
		Totaal_bijkomende_kosten: number = 0.00;
		Totaal_opslagen: number = 0.00;
		Totaal_BTW: number = 0.00;
		Totaal_sub_kosten: number = 0.00;
		Totaal_kosten_excl_BTW: number = 0.00;
		Totaal_kosten_incl_BTW: number = 0.00;
	}

	export class BerekeningData {
		Id: number;
		ProjectId: number;
		PersonenId: number;
		Soort: string;
		Elementcode: string;
		Waarde: number;
		Calculation: number;
		VariantNummer: number;
	}

	export class KostenWoning {
		ScenarioID: number;
		//Kosten: { [code: string]: KostenHoofdstuk; } = {};
		Kosten: Array<ViewModelsKostenHoofdstuk.KostenHoofdstuk> = [];
		TotaleKosten: number;
		TotaleKostenNoodzakelijk: number;
		TotaleKostenVerbeter: number;
		//TotaleKostenStr: number;
		//TotaleKostenNoodzakelijkStr: number;
		//TotaleKostenVerbeterStr: string;
		//SubTotaalInversteringenStr: string;
		//TotaleKostenDuurzaamStr: string;
		TotaleKostenDuurzaam: number;

		KostenRegelsNoodzakelijk: ViewModelsKostenDetails.KostenDetails;
		KostenRegelsVerbeter: ViewModelsKostenDetails.KostenDetails;
		KostenRegelsInvestering: ViewModelsKostenDetails.KostenDetails;
		KostenRegelsDuurzaam: ViewModelsKostenDetails.KostenDetails;

		//TotaleSubsidieStr: string;
		TeFinancieren: number;
		//TeFinancierenStr: string;
		m_IsUitGeklapt: boolean = false;
		Tonen: boolean = false;
		ToonNulBedragen: boolean = false;

		isOnderdeelVanVVE(): boolean {
			return this.Woning.isOnderdeelVanVVE;
		}

		AlleKosten(): string {
			var totaal = 0;
			_.each(this.AlleRegels(), (r) => {
				totaal += r.BedragFloatExBTW;
			});
			return formatMoney(totaal, 0, ',', '.');
		}

		AlleRegels(): Array<ViewModelsKostenPrijsregel.KostenPrijsregel> {
			if (this.ToonNulBedragen) {
				return this.AlleRegelsMetNulBedragen();
			}
			return this.AlleRegelsZonderNulBedragen();
		}

		AlleRegelsMetNulBedragen(): Array<ViewModelsKostenPrijsregel.KostenPrijsregel> {
			var ret: Array<ViewModelsKostenPrijsregel.KostenPrijsregel> = [];

			_.each(this.Kosten, ((k) => {
				_.each(k.Elementen, (e) => {
					_.each(e.PrijsRegels, (r) => {
						if (r.BedragFloat >= 0) {
							if (r.Code === null || r.Code.length == 0) {
								r.Code = e.Code;
							}
							if (r.Cyclus == 0 && r.StartJaar == 0) {
								ret.push(r);
							}
						}
					});
					_.each(e.SubElementen, (s) => {
						_.each(s.PrijsRegels, (r) => {
							if (r.BedragFloat >= 0) {
								if (r.Code === null || r.Code.length == 0) {
									r.Code = s.Code;
								}
								if (r.Cyclus == 0 && r.StartJaar == 0) {
									ret.push(r);
								}
							}
						});
					});
				});
			}));

			return ret;
		}

		AlleRegelsZonderNulBedragen(): Array<ViewModelsKostenPrijsregel.KostenPrijsregel> {
			var ret: Array<ViewModelsKostenPrijsregel.KostenPrijsregel> = [];

			_.each(this.Kosten, ((k) => {
				_.each(k.Elementen, (e) => {
					_.each(e.PrijsRegels, (r) => {
						//if (r.BedragFloat > 0)
						{
							if (r.Code === null || r.Code.length == 0) {
								r.Code = e.Code;
							}
							if (r.Cyclus == 0 && r.StartJaar == 0) {
								ret.push(r);
							}
						}
					});
					_.each(e.SubElementen, (s) => {
						_.each(s.PrijsRegels, (r) => {
							//if (r.BedragFloat > 0)
							{
								if (r.Code === null || r.Code.length == 0) {
									r.Code = s.Code;
								}
								if (r.Cyclus == 0 && r.StartJaar == 0) {
									ret.push(r);
								}
							}
						});
					});
				});
			}));

			return ret;
		}



		get IsUitGeklapt(): boolean {
			return this.m_IsUitGeklapt;
		}
		set IsUitGeklapt(b: boolean) {
			this.m_IsUitGeklapt = b;
		}
		getWoningId() {
			return this.Woning.WoningID;
		}

		Titel() {
			return this.Woning.AdresStraat + " " + this.Woning.AdresNummer;
		}

		constructor(public Ouder: ViewModelsKostenBlok.KostenBlok, public Woning: ViewModelsWoning.Woning) {
			this.KostenRegelsDuurzaam = new ViewModelsKostenDetails.KostenDetails();
			this.KostenRegelsInvestering = new ViewModelsKostenDetails.KostenDetails();
			this.KostenRegelsNoodzakelijk = new ViewModelsKostenDetails.KostenDetails();
			this.KostenRegelsVerbeter = new ViewModelsKostenDetails.KostenDetails();
		}

	}

	export class Lening {
		LeningType: LeningTypeEnum;
		Naam: string;
		RentePercentage: number;
		Looptijd: number;
		LeenBedrag: number;
		JaarBedrag: number;
		MaandBedrag: number;
		Link: string;
	}

	export class LeningMogelijkheden {
		Leningen: Array<Lening> = [];
		Naam: string;
		Omschrijving: string;
		Totalen: Array<IMJOPJaar> = [];
		Type: LeningMogelijkheidTypeEnum;

		constructor(data: any) {
			//ng.copy(data,this);
			Object.assign(this, data)
		}
	}

	export class KostenWoningEigenaar {
		ScenarioID: number;
		//Kosten: { [code: string]: KostenHoofdstuk; } = {};
		KostenRegels: Array<EigenaarKostenRegel> = [];
		KostenHoofdstukken: Array<ViewModelsKostenHoofdstuk.KostenHoofdstuk> = [];
		SubsidieRegelingen: Array<EigenaarSubsidieRegeling> = [];
		LeningMogelijkheden: Array<LeningMogelijkheden> = []
		Subsidie: number;
		TotaalEnergetisch: number;
		TotaalOverig: number;
		TeFinancieren: number;
		EnergetischeRente: number;
		EnergetischeLooptijd: number;
		EnergetischeLening: number;
		EnergetischeLeningPerMaand: number;
		OverigeLeningPerMaand: number;
		OverigeLeningLooptijd: number;
		OverigeRente: number;
		TotaalLeningPerMaand: number;
		TotaalNietSubsidie: number;
		TotaalExBTW: number;
		TotaalInBTW: number;
		EnergetischeLeningLink: string;
		KostenCategorie: string;
		EnergieBesparingPerJaar: number;
		EigenInleg: number;
		Kas: number;
		KasReserve: number;
		VVEBijdrage: number;
		Woning: ViewModelsWoning.Woning;		
		BreukDeel: string;

		m_IsUitGeklapt: boolean = false;
		Tonen: boolean = false;

		get IsUitGeklapt(): boolean {
			return this.m_IsUitGeklapt;
		}
		set IsUitGeklapt(b: boolean) {
			this.m_IsUitGeklapt = b;
		}
		getWoningId() {
			return this.Woning.WoningID;
		}

		Titel() {
			return this.Woning.AdresStraat + " " + this.Woning.AdresNummer;
		}

		VulModel(data: any) {			
			Object.assign(this, data)
			//ng.extend(this, data);
		}

		AlleRegels(): Array<EigenaarKostenRegel> {
			var ret: Array<EigenaarKostenRegel> = [];

			_.each(this.KostenRegels, ((k) => {
				if (k.KostenInBTW > 0 && (k.StartJaar == 2016 || k.StartJaar == 0)) {
					ret.push(k);
				}
			}));

			return ret;
		}

		constructor(public Ouder?: ViewModelsKostenBlok.KostenBlok, public tWoning?: ViewModelsWoning.Woning) {
			this.Woning = tWoning;
			this.Subsidie = 0;
			this.EigenInleg = 0;
			this.TotaalEnergetisch = 0;
			this.TotaalOverig = 0;
			this.TeFinancieren = 0;
			this.EnergetischeRente = 0;
			this.EnergetischeLooptijd = 0;
			this.EnergetischeLening = 0;
			this.EnergetischeLeningPerMaand = 0;
			this.OverigeLeningPerMaand = 0;
			this.OverigeLeningLooptijd = 0;
			this.OverigeRente = 0;
			this.TotaalLeningPerMaand = 0;
			this.TotaalNietSubsidie = 0;
			this.TotaalExBTW = 0;
			this.TotaalInBTW = 0;						
			this.EnergieBesparingPerJaar = 0;
			this.EigenInleg = 0;
			this.Kas = 0;
			this.KasReserve = 0;
			this.VVEBijdrage = 0;
			this.BreukDeel = "";
		}

	}

	export class EigenaarKostenRegel {
		BtwPercentage: number;
		BtwType: string;
		Eenheid: string;
		HoeveelheidNumeriek: number;
		EenheidsPrijsNumeriek: number;
		KostenCategorie: string;
		ElementCode: string;
		WerkOmschrijving: string;
		KostenExBTW: number;
		EenheidsPrijsBron: string;
		HoeveelheidBron: string;
		ElementNaam: string;
		IsProcentueel: boolean;
		KostenInBTW: number;
		Energetisch: boolean;
		PVWattPiek: number;
		PVWattPiekPerPaneel: number;
		PVAantalPanelen: number;
		PVSubsidie: number;
		PVForfait: number;
		WerkToelichting: string;
		StartJaar: number;
		SubsidieType: SubsidieEnergiebesparingEigenHuisEnum;
		RegelId: string;
	}

	export class EigenaarSubsidieRegeling {
		Naam: string;
		SubsidieRegelingType: SubsidieRegelingTypeEnum;
		Link: string;
		SubsidieRegels: Array<SubsidieRegel> = [];
		SubsidieTotaal: number;
	}

	export class SubsidieRegel {
		Naam: string;
		SubsidieTotaal: number;
	}

	export class SubsidieEnergiebesparingEigenHuis extends SubsidieRegel {
		BenodigdeHoeveelheid: number;
		IsolatieType: SubsidieEnergiebesparingEigenHuisEnum;
		SubsidiePerEenheid: number;
		TotaalHoeveelheid: number;
	}

	export class SubsidieRegelZonnePanelen extends SubsidieRegel {
		Forfait: number;
		PVWattPiek: number;
	}
