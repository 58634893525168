/**
 * @ref WoonConnectViewModel/Resident/GetResidentModel.cs
 */

 import * as DataContracts from "@datacontracts/GetResidentModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Resident.GetResidentModelRequestModel
	 */
	 

	export interface IGetResidentModelRequestModel {
		ToJsonContract(): DataContracts.IGetResidentModelRequestModel;
	}

	export class GetResidentModelRequestModel {
		// property-Properties
		// field-Properties

		// fields

		constructor(data?: DataContracts.IGetResidentModelRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetResidentModelRequestModel): void {
			if(data) {
				// vul properties
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IGetResidentModelRequestModel {
			return {
				
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Resident.ProjectData
	 */
	 

	export interface IProjectData {
		pId: number;
		pName: string;
		ToJsonContract(): DataContracts.IProjectData;
	}

	export class ProjectData {
		// property-Properties
		public get pId(): number {
			return this._pId;
		}
		public set pId(newpId: number) {
			this._pId = newpId;
		}

		public get pName(): string {
			return this._pName;
		}
		public set pName(newpName: string) {
			this._pName = newpName;
		}

		// field-Properties

		// fields
		protected _pId: number = 0;
		protected _pName: string = "";

		constructor(data?: DataContracts.IProjectData) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProjectData): void {
			if(data) {
				// vul properties
				this._pId = data.pId;
				this._pName = data.pName;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProjectData {
			return {
				pId: this.pId,
				pName: this.pName
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Resident.ResidentModel
	 */
	 

	export interface IResidentModel {
		Inlognaam: string;
		Geslacht: string;
		Voorletters: string;
		Voornaam: string;
		Tussenvoegsel: string;
		Achternaam: string;
		Telefoonnummer: string;
		GeboorteDatum: string;
		Straat: string;
		Nummer: string;
		Postcode: string;
		Plaats: string;
		Email: string;
		Bedrijfsnaam: string;
		PersoonId: number;
		OrganisationName: string;
		ProjectData: ProjectData[];
		SelectedProject: number;
		ToJsonContract(): DataContracts.IResidentModel;
	}

	export class ResidentModel {
		// property-Properties
		public get Inlognaam(): string {
			return this._Inlognaam;
		}
		public set Inlognaam(newInlognaam: string) {
			this._Inlognaam = newInlognaam;
		}

		public get Geslacht(): string {
			return this._Geslacht;
		}
		public set Geslacht(newGeslacht: string) {
			this._Geslacht = newGeslacht;
		}

		public get Voorletters(): string {
			return this._Voorletters;
		}
		public set Voorletters(newVoorletters: string) {
			this._Voorletters = newVoorletters;
		}

		public get Voornaam(): string {
			return this._Voornaam;
		}
		public set Voornaam(newVoornaam: string) {
			this._Voornaam = newVoornaam;
		}

		public get Tussenvoegsel(): string {
			return this._Tussenvoegsel;
		}
		public set Tussenvoegsel(newTussenvoegsel: string) {
			this._Tussenvoegsel = newTussenvoegsel;
		}

		public get Achternaam(): string {
			return this._Achternaam;
		}
		public set Achternaam(newAchternaam: string) {
			this._Achternaam = newAchternaam;
		}

		public get Telefoonnummer(): string {
			return this._Telefoonnummer;
		}
		public set Telefoonnummer(newTelefoonnummer: string) {
			this._Telefoonnummer = newTelefoonnummer;
		}

		public get GeboorteDatum(): string {
			return this._GeboorteDatum;
		}
		public set GeboorteDatum(newGeboorteDatum: string) {
			this._GeboorteDatum = newGeboorteDatum;
		}

		public get Straat(): string {
			return this._Straat;
		}
		public set Straat(newStraat: string) {
			this._Straat = newStraat;
		}

		public get Nummer(): string {
			return this._Nummer;
		}
		public set Nummer(newNummer: string) {
			this._Nummer = newNummer;
		}

		public get Postcode(): string {
			return this._Postcode;
		}
		public set Postcode(newPostcode: string) {
			this._Postcode = newPostcode;
		}

		public get Plaats(): string {
			return this._Plaats;
		}
		public set Plaats(newPlaats: string) {
			this._Plaats = newPlaats;
		}

		public get Email(): string {
			return this._Email;
		}
		public set Email(newEmail: string) {
			this._Email = newEmail;
		}

		public get Bedrijfsnaam(): string {
			return this._Bedrijfsnaam;
		}
		public set Bedrijfsnaam(newBedrijfsnaam: string) {
			this._Bedrijfsnaam = newBedrijfsnaam;
		}

		public get PersoonId(): number {
			return this._PersoonId;
		}
		public set PersoonId(newPersoonId: number) {
			this._PersoonId = newPersoonId;
		}

		public get OrganisationName(): string {
			return this._OrganisationName;
		}
		public set OrganisationName(newOrganisationName: string) {
			this._OrganisationName = newOrganisationName;
		}

		public get ProjectData(): ProjectData[] {
			return this._ProjectData;
		}
		public set ProjectData(newProjectData: ProjectData[]) {
			this._ProjectData = newProjectData;
		}

		public get SelectedProject(): number {
			return this._SelectedProject;
		}
		public set SelectedProject(newSelectedProject: number) {
			this._SelectedProject = newSelectedProject;
		}

		// field-Properties

		// fields
		protected _Inlognaam: string = "";
		protected _Geslacht: string = "";
		protected _Voorletters: string = "";
		protected _Voornaam: string = "";
		protected _Tussenvoegsel: string = "";
		protected _Achternaam: string = "";
		protected _Telefoonnummer: string = "";
		protected _GeboorteDatum: string = "";
		protected _Straat: string = "";
		protected _Nummer: string = "";
		protected _Postcode: string = "";
		protected _Plaats: string = "";
		protected _Email: string = "";
		protected _Bedrijfsnaam: string = "";
		protected _PersoonId: number = 0;
		protected _OrganisationName: string = "";
		protected _ProjectData: ProjectData[] = [];
		protected _SelectedProject: number = 0;

		constructor(data?: DataContracts.IResidentModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IResidentModel): void {
			if(data) {
				// vul properties
				this._Inlognaam = data.Inlognaam;
				this._Geslacht = data.Geslacht;
				this._Voorletters = data.Voorletters;
				this._Voornaam = data.Voornaam;
				this._Tussenvoegsel = data.Tussenvoegsel;
				this._Achternaam = data.Achternaam;
				this._Telefoonnummer = data.Telefoonnummer;
				this._GeboorteDatum = data.GeboorteDatum;
				this._Straat = data.Straat;
				this._Nummer = data.Nummer;
				this._Postcode = data.Postcode;
				this._Plaats = data.Plaats;
				this._Email = data.Email;
				this._Bedrijfsnaam = data.Bedrijfsnaam;
				this._PersoonId = data.PersoonId;
				this._OrganisationName = data.OrganisationName;
				this._ProjectData = data.ProjectData ? data.ProjectData.map(projectDataItem => new ProjectData(projectDataItem)): [];
				this._SelectedProject = data.SelectedProject;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IResidentModel {
			return {
				Inlognaam: this.Inlognaam,
				Geslacht: this.Geslacht,
				Voorletters: this.Voorletters,
				Voornaam: this.Voornaam,
				Tussenvoegsel: this.Tussenvoegsel,
				Achternaam: this.Achternaam,
				Telefoonnummer: this.Telefoonnummer,
				GeboorteDatum: this.GeboorteDatum,
				Straat: this.Straat,
				Nummer: this.Nummer,
				Postcode: this.Postcode,
				Plaats: this.Plaats,
				Email: this.Email,
				Bedrijfsnaam: this.Bedrijfsnaam,
				PersoonId: this.PersoonId,
				OrganisationName: this.OrganisationName,
				ProjectData: (this.ProjectData ? this.ProjectData.map(projectDataItem => new ProjectData(projectDataItem).ToJsonContract()): []),
				SelectedProject: this.SelectedProject
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Resident.GetResidentModelResponseModel
	 */
	 

	export interface IGetResidentModelResponseModel {
		ResidentModel: ResidentModel;
	}

	export class GetResidentModelResponseModel {
		// property-Properties
		public get ResidentModel(): ResidentModel {
			return this._ResidentModel;
		}
		public set ResidentModel(newResidentModel: ResidentModel) {
			this._ResidentModel = newResidentModel;
		}

		// field-Properties

		// fields
		protected _ResidentModel: ResidentModel = new ResidentModel(undefined);

		constructor(data?: DataContracts.IGetResidentModelResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetResidentModelResponseModel): void {
			if(data) {
				// vul properties
				this._ResidentModel = data.ResidentModel ? new ResidentModel(data.ResidentModel): undefined;
				// vul fields
			}
		}
	}
