/**
 * @ref WoonConnectViewModel/Fabrikanten/BCBProductModel.cs
 */

 import * as DataContracts from "@datacontracts/BCBProductModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.BCBProductInputModel
	 */
	 

	export interface IBCBProductInputModel {
		ProductId: number;
		ToJsonContract(): DataContracts.IBCBProductInputModel;
	}

	export class BCBProductInputModel {
		// property-Properties
		public get ProductId(): number {
			return this._ProductId;
		}
		public set ProductId(newProductId: number) {
			this._ProductId = newProductId;
		}

		// field-Properties

		// fields
		protected _ProductId: number = 0;

		constructor(data?: DataContracts.IBCBProductInputModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBCBProductInputModel): void {
			if(data) {
				// vul properties
				this._ProductId = data.ProductId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBCBProductInputModel {
			return {
				ProductId: this.ProductId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.BCBProductViewModel
	 */
	 

	export interface IBCBProductViewModel {
		Id: number;
		Naam: string;
		DisplayName: string;
		Position: number;
		InstantieNaam: string;
		Inbouwhoogte: string;
		Inbouwbreedte: string;
		Inbouwdiepte: string;
		Variants: BCBProductVariantModel[];
		CAD: BCBProductCADViewModel[];
		Properties: BCBProductPropertyModel[];
		Opbouw: BCBProductOpbouwModel[];
		MultiOpbouw: BCBProductMultiOpbouwModel[];
		Links: BCBLinkModel[];
		SVG: string;
		Bestek: string;
		SUF: string;
		OSFHTML: string;
		Kosten: string;
		HdhRevit: string;
		WebGLJSON: string;
		Snedes: string;
		Heeft2D5: boolean;
		Heeft3D: boolean;
		HeeftRfa: boolean;
		Server: string;
		WUID: string;
		BCBWUIDLink: string;
		Description: string;
		HeeftCADAbstract: boolean;
		VrijBestekHTML: string;
		SWUID: string;
		SVGWidth: number;
		SVGHeight: number;
		ViewSelected: string;
		ToJsonContract(): DataContracts.IBCBProductViewModel;
	}

	export class BCBProductViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Naam(): string {
			return this._Naam;
		}
		public set Naam(newNaam: string) {
			this._Naam = newNaam;
		}

		public get DisplayName(): string {
			return this._DisplayName;
		}
		public set DisplayName(newDisplayName: string) {
			this._DisplayName = newDisplayName;
		}

		public get Position(): number {
			return this._Position;
		}
		public set Position(newPosition: number) {
			this._Position = newPosition;
		}

		public get InstantieNaam(): string {
			return this._InstantieNaam;
		}
		public set InstantieNaam(newInstantieNaam: string) {
			this._InstantieNaam = newInstantieNaam;
		}

		public get Inbouwhoogte(): string {
			return this._Inbouwhoogte;
		}
		public set Inbouwhoogte(newInbouwhoogte: string) {
			this._Inbouwhoogte = newInbouwhoogte;
		}

		public get Inbouwbreedte(): string {
			return this._Inbouwbreedte;
		}
		public set Inbouwbreedte(newInbouwbreedte: string) {
			this._Inbouwbreedte = newInbouwbreedte;
		}

		public get Inbouwdiepte(): string {
			return this._Inbouwdiepte;
		}
		public set Inbouwdiepte(newInbouwdiepte: string) {
			this._Inbouwdiepte = newInbouwdiepte;
		}

		public get Variants(): BCBProductVariantModel[] {
			return this._Variants;
		}
		public set Variants(newVariants: BCBProductVariantModel[]) {
			this._Variants = newVariants;
		}

		public get CAD(): BCBProductCADViewModel[] {
			return this._CAD;
		}
		public set CAD(newCAD: BCBProductCADViewModel[]) {
			this._CAD = newCAD;
		}

		public get Properties(): BCBProductPropertyModel[] {
			return this._Properties;
		}
		public set Properties(newProperties: BCBProductPropertyModel[]) {
			this._Properties = newProperties;
		}

		public get Opbouw(): BCBProductOpbouwModel[] {
			return this._Opbouw;
		}
		public set Opbouw(newOpbouw: BCBProductOpbouwModel[]) {
			this._Opbouw = newOpbouw;
		}

		public get MultiOpbouw(): BCBProductMultiOpbouwModel[] {
			return this._MultiOpbouw;
		}
		public set MultiOpbouw(newMultiOpbouw: BCBProductMultiOpbouwModel[]) {
			this._MultiOpbouw = newMultiOpbouw;
		}

		public get Links(): BCBLinkModel[] {
			return this._Links;
		}
		public set Links(newLinks: BCBLinkModel[]) {
			this._Links = newLinks;
		}

		public get SVG(): string {
			return this._SVG;
		}
		public set SVG(newSVG: string) {
			this._SVG = newSVG;
		}

		public get Bestek(): string {
			return this._Bestek;
		}
		public set Bestek(newBestek: string) {
			this._Bestek = newBestek;
		}

		public get SUF(): string {
			return this._SUF;
		}
		public set SUF(newSUF: string) {
			this._SUF = newSUF;
		}

		public get OSFHTML(): string {
			return this._OSFHTML;
		}
		public set OSFHTML(newOSFHTML: string) {
			this._OSFHTML = newOSFHTML;
		}

		public get Kosten(): string {
			return this._Kosten;
		}
		public set Kosten(newKosten: string) {
			this._Kosten = newKosten;
		}

		public get HdhRevit(): string {
			return this._HdhRevit;
		}
		public set HdhRevit(newHdhRevit: string) {
			this._HdhRevit = newHdhRevit;
		}

		public get WebGLJSON(): string {
			return this._WebGLJSON;
		}
		public set WebGLJSON(newWebGLJSON: string) {
			this._WebGLJSON = newWebGLJSON;
		}

		public get Snedes(): string {
			return this._Snedes;
		}
		public set Snedes(newSnedes: string) {
			this._Snedes = newSnedes;
		}

		public get Heeft2D5(): boolean {
			return this._Heeft2D5;
		}
		public set Heeft2D5(newHeeft2D5: boolean) {
			this._Heeft2D5 = newHeeft2D5;
		}

		public get Heeft3D(): boolean {
			return this._Heeft3D;
		}
		public set Heeft3D(newHeeft3D: boolean) {
			this._Heeft3D = newHeeft3D;
		}

		public get HeeftRfa(): boolean {
			return this._HeeftRfa;
		}
		public set HeeftRfa(newHeeftRfa: boolean) {
			this._HeeftRfa = newHeeftRfa;
		}

		public get Server(): string {
			return this._Server;
		}
		public set Server(newServer: string) {
			this._Server = newServer;
		}

		public get WUID(): string {
			return this._WUID;
		}
		public set WUID(newWUID: string) {
			this._WUID = newWUID;
		}

		public get BCBWUIDLink(): string {
			return this._BCBWUIDLink;
		}
		public set BCBWUIDLink(newBCBWUIDLink: string) {
			this._BCBWUIDLink = newBCBWUIDLink;
		}

		public get Description(): string {
			return this._Description;
		}
		public set Description(newDescription: string) {
			this._Description = newDescription;
		}

		public get HeeftCADAbstract(): boolean {
			return this._HeeftCADAbstract;
		}
		public set HeeftCADAbstract(newHeeftCADAbstract: boolean) {
			this._HeeftCADAbstract = newHeeftCADAbstract;
		}

		public get VrijBestekHTML(): string {
			return this._VrijBestekHTML;
		}
		public set VrijBestekHTML(newVrijBestekHTML: string) {
			this._VrijBestekHTML = newVrijBestekHTML;
		}

		public get SWUID(): string {
			return this._SWUID;
		}
		public set SWUID(newSWUID: string) {
			this._SWUID = newSWUID;
		}

		public get SVGWidth(): number {
			return this._SVGWidth;
		}
		public set SVGWidth(newSVGWidth: number) {
			this._SVGWidth = newSVGWidth;
		}

		public get SVGHeight(): number {
			return this._SVGHeight;
		}
		public set SVGHeight(newSVGHeight: number) {
			this._SVGHeight = newSVGHeight;
		}

		public get ViewSelected(): string {
			return this._ViewSelected;
		}
		public set ViewSelected(newViewSelected: string) {
			this._ViewSelected = newViewSelected;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Naam: string = "";
		protected _DisplayName: string = "";
		protected _Position: number = 0;
		protected _InstantieNaam: string = "";
		protected _Inbouwhoogte: string = "";
		protected _Inbouwbreedte: string = "";
		protected _Inbouwdiepte: string = "";
		protected _Variants: BCBProductVariantModel[] = [];
		protected _CAD: BCBProductCADViewModel[] = [];
		protected _Properties: BCBProductPropertyModel[] = [];
		protected _Opbouw: BCBProductOpbouwModel[] = [];
		protected _MultiOpbouw: BCBProductMultiOpbouwModel[] = [];
		protected _Links: BCBLinkModel[] = [];
		protected _SVG: string = "";
		protected _Bestek: string = "";
		protected _SUF: string = "";
		protected _OSFHTML: string = "";
		protected _Kosten: string = "";
		protected _HdhRevit: string = "";
		protected _WebGLJSON: string = "";
		protected _Snedes: string = "";
		protected _Heeft2D5: boolean = false;
		protected _Heeft3D: boolean = false;
		protected _HeeftRfa: boolean = false;
		protected _Server: string = "";
		protected _WUID: string = "";
		protected _BCBWUIDLink: string = "";
		protected _Description: string = "";
		protected _HeeftCADAbstract: boolean = false;
		protected _VrijBestekHTML: string = "";
		protected _SWUID: string = "";
		protected _SVGWidth: number = 0;
		protected _SVGHeight: number = 0;
		protected _ViewSelected: string = "";

		constructor(data?: DataContracts.IBCBProductViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBCBProductViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Naam = data.Naam;
				this._DisplayName = data.DisplayName;
				this._Position = data.Position;
				this._InstantieNaam = data.InstantieNaam;
				this._Inbouwhoogte = data.Inbouwhoogte;
				this._Inbouwbreedte = data.Inbouwbreedte;
				this._Inbouwdiepte = data.Inbouwdiepte;
				this._Variants = data.Variants ? data.Variants.map(bCBProductVariantModelItem => new BCBProductVariantModel(bCBProductVariantModelItem)): [];
				this._CAD = data.CAD ? data.CAD.map(bCBProductCADViewModelItem => new BCBProductCADViewModel(bCBProductCADViewModelItem)): [];
				this._Properties = data.Properties ? data.Properties.map(bCBProductPropertyModelItem => new BCBProductPropertyModel(bCBProductPropertyModelItem)): [];
				this._Opbouw = data.Opbouw ? data.Opbouw.map(bCBProductOpbouwModelItem => new BCBProductOpbouwModel(bCBProductOpbouwModelItem)): [];
				this._MultiOpbouw = data.MultiOpbouw ? data.MultiOpbouw.map(bCBProductMultiOpbouwModelItem => new BCBProductMultiOpbouwModel(bCBProductMultiOpbouwModelItem)): [];
				this._Links = data.Links ? data.Links.map(bCBLinkModelItem => new BCBLinkModel(bCBLinkModelItem)): [];
				this._SVG = data.SVG;
				this._Bestek = data.Bestek;
				this._SUF = data.SUF;
				this._OSFHTML = data.OSFHTML;
				this._Kosten = data.Kosten;
				this._HdhRevit = data.HdhRevit;
				this._WebGLJSON = data.WebGLJSON;
				this._Snedes = data.Snedes;
				this._Heeft2D5 = data.Heeft2D5;
				this._Heeft3D = data.Heeft3D;
				this._HeeftRfa = data.HeeftRfa;
				this._Server = data.Server;
				this._WUID = data.WUID;
				this._BCBWUIDLink = data.BCBWUIDLink;
				this._Description = data.Description;
				this._HeeftCADAbstract = data.HeeftCADAbstract;
				this._VrijBestekHTML = data.VrijBestekHTML;
				this._SWUID = data.SWUID;
				this._SVGWidth = data.SVGWidth;
				this._SVGHeight = data.SVGHeight;
				this._ViewSelected = data.ViewSelected;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBCBProductViewModel {
			return {
				Id: this.Id,
				Naam: this.Naam,
				DisplayName: this.DisplayName,
				Position: this.Position,
				InstantieNaam: this.InstantieNaam,
				Inbouwhoogte: this.Inbouwhoogte,
				Inbouwbreedte: this.Inbouwbreedte,
				Inbouwdiepte: this.Inbouwdiepte,
				Variants: (this.Variants ? this.Variants.map(bCBProductVariantModelItem => new BCBProductVariantModel(bCBProductVariantModelItem).ToJsonContract()): []),
				CAD: (this.CAD ? this.CAD.map(bCBProductCADViewModelItem => new BCBProductCADViewModel(bCBProductCADViewModelItem).ToJsonContract()): []),
				Properties: (this.Properties ? this.Properties.map(bCBProductPropertyModelItem => new BCBProductPropertyModel(bCBProductPropertyModelItem).ToJsonContract()): []),
				Opbouw: (this.Opbouw ? this.Opbouw.map(bCBProductOpbouwModelItem => new BCBProductOpbouwModel(bCBProductOpbouwModelItem).ToJsonContract()): []),
				MultiOpbouw: (this.MultiOpbouw ? this.MultiOpbouw.map(bCBProductMultiOpbouwModelItem => new BCBProductMultiOpbouwModel(bCBProductMultiOpbouwModelItem).ToJsonContract()): []),
				Links: (this.Links ? this.Links.map(bCBLinkModelItem => new BCBLinkModel(bCBLinkModelItem).ToJsonContract()): []),
				SVG: this.SVG,
				Bestek: this.Bestek,
				SUF: this.SUF,
				OSFHTML: this.OSFHTML,
				Kosten: this.Kosten,
				HdhRevit: this.HdhRevit,
				WebGLJSON: this.WebGLJSON,
				Snedes: this.Snedes,
				Heeft2D5: this.Heeft2D5,
				Heeft3D: this.Heeft3D,
				HeeftRfa: this.HeeftRfa,
				Server: this.Server,
				WUID: this.WUID,
				BCBWUIDLink: this.BCBWUIDLink,
				Description: this.Description,
				HeeftCADAbstract: this.HeeftCADAbstract,
				VrijBestekHTML: this.VrijBestekHTML,
				SWUID: this.SWUID,
				SVGWidth: this.SVGWidth,
				SVGHeight: this.SVGHeight,
				ViewSelected: this.ViewSelected
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.BCBUrlViewModel
	 */
	 

	export interface IBCBUrlViewModel {
		URL: string;
		ToJsonContract(): DataContracts.IBCBUrlViewModel;
	}

	export class BCBUrlViewModel {
		// property-Properties
		public get URL(): string {
			return this._URL;
		}
		public set URL(newURL: string) {
			this._URL = newURL;
		}

		// field-Properties

		// fields
		protected _URL: string = "";

		constructor(data?: DataContracts.IBCBUrlViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBCBUrlViewModel): void {
			if(data) {
				// vul properties
				this._URL = data.URL;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBCBUrlViewModel {
			return {
				URL: this.URL
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.BCBProductVariantModel
	 */
	 

	export interface IBCBProductVariantModel {
		Id: number;
		Name: string;
		Description: string;
		ToJsonContract(): DataContracts.IBCBProductVariantModel;
	}

	export class BCBProductVariantModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Description(): string {
			return this._Description;
		}
		public set Description(newDescription: string) {
			this._Description = newDescription;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _Description: string = "";

		constructor(data?: DataContracts.IBCBProductVariantModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBCBProductVariantModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._Description = data.Description;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBCBProductVariantModel {
			return {
				Id: this.Id,
				Name: this.Name,
				Description: this.Description
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.BCBLinkModel
	 */
	 

	export interface IBCBLinkModel {
		Id: number;
		Name: string;
		Type: string;
		URL: string;
		ProductVariantName: string;
		ToJsonContract(): DataContracts.IBCBLinkModel;
	}

	export class BCBLinkModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Type(): string {
			return this._Type;
		}
		public set Type(newType: string) {
			this._Type = newType;
		}

		public get URL(): string {
			return this._URL;
		}
		public set URL(newURL: string) {
			this._URL = newURL;
		}

		public get ProductVariantName(): string {
			return this._ProductVariantName;
		}
		public set ProductVariantName(newProductVariantName: string) {
			this._ProductVariantName = newProductVariantName;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _Type: string = "";
		protected _URL: string = "";
		protected _ProductVariantName: string = "";

		constructor(data?: DataContracts.IBCBLinkModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBCBLinkModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._Type = data.Type;
				this._URL = data.URL;
				this._ProductVariantName = data.ProductVariantName;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBCBLinkModel {
			return {
				Id: this.Id,
				Name: this.Name,
				Type: this.Type,
				URL: this.URL,
				ProductVariantName: this.ProductVariantName
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.BCBProductCADViewModel
	 */
	 

	export interface IBCBProductCADViewModel {
		Naam: string;
		Waarde: string;
		ToJsonContract(): DataContracts.IBCBProductCADViewModel;
	}

	export class BCBProductCADViewModel {
		// property-Properties
		public get Naam(): string {
			return this._Naam;
		}
		public set Naam(newNaam: string) {
			this._Naam = newNaam;
		}

		public get Waarde(): string {
			return this._Waarde;
		}
		public set Waarde(newWaarde: string) {
			this._Waarde = newWaarde;
		}

		// field-Properties

		// fields
		protected _Naam: string = "";
		protected _Waarde: string = "";

		constructor(data?: DataContracts.IBCBProductCADViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBCBProductCADViewModel): void {
			if(data) {
				// vul properties
				this._Naam = data.Naam;
				this._Waarde = data.Waarde;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBCBProductCADViewModel {
			return {
				Naam: this.Naam,
				Waarde: this.Waarde
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.BCBProductPropertyModel
	 */
	 

	export interface IBCBProductPropertyModel {
		Name: string;
		Value: string;
		ProductPropertyId: number;
		ToJsonContract(): DataContracts.IBCBProductPropertyModel;
	}

	export class BCBProductPropertyModel {
		// property-Properties
		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Value(): string {
			return this._Value;
		}
		public set Value(newValue: string) {
			this._Value = newValue;
		}

		public get ProductPropertyId(): number {
			return this._ProductPropertyId;
		}
		public set ProductPropertyId(newProductPropertyId: number) {
			this._ProductPropertyId = newProductPropertyId;
		}

		// field-Properties

		// fields
		protected _Name: string = "";
		protected _Value: string = "";
		protected _ProductPropertyId: number = 0;

		constructor(data?: DataContracts.IBCBProductPropertyModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBCBProductPropertyModel): void {
			if(data) {
				// vul properties
				this._Name = data.Name;
				this._Value = data.Value;
				this._ProductPropertyId = data.ProductPropertyId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBCBProductPropertyModel {
			return {
				Name: this.Name,
				Value: this.Value,
				ProductPropertyId: this.ProductPropertyId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.BCBProductOpbouwModel
	 */
	 

	export interface IBCBProductOpbouwModel {
		Name: string;
		Bot: string;
		DO1Type: string;
		DO1Naam: string;
		DO2Type: string;
		DO2Naam: string;
		DO3Type: string;
		DO3Naam: string;
		DO4Type: string;
		DO4Naam: string;
		Kenmerk: string;
		Selected: boolean;
		ToJsonContract(): DataContracts.IBCBProductOpbouwModel;
	}

	export class BCBProductOpbouwModel {
		// property-Properties
		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Bot(): string {
			return this._Bot;
		}
		public set Bot(newBot: string) {
			this._Bot = newBot;
		}

		public get DO1Type(): string {
			return this._DO1Type;
		}
		public set DO1Type(newDO1Type: string) {
			this._DO1Type = newDO1Type;
		}

		public get DO1Naam(): string {
			return this._DO1Naam;
		}
		public set DO1Naam(newDO1Naam: string) {
			this._DO1Naam = newDO1Naam;
		}

		public get DO2Type(): string {
			return this._DO2Type;
		}
		public set DO2Type(newDO2Type: string) {
			this._DO2Type = newDO2Type;
		}

		public get DO2Naam(): string {
			return this._DO2Naam;
		}
		public set DO2Naam(newDO2Naam: string) {
			this._DO2Naam = newDO2Naam;
		}

		public get DO3Type(): string {
			return this._DO3Type;
		}
		public set DO3Type(newDO3Type: string) {
			this._DO3Type = newDO3Type;
		}

		public get DO3Naam(): string {
			return this._DO3Naam;
		}
		public set DO3Naam(newDO3Naam: string) {
			this._DO3Naam = newDO3Naam;
		}

		public get DO4Type(): string {
			return this._DO4Type;
		}
		public set DO4Type(newDO4Type: string) {
			this._DO4Type = newDO4Type;
		}

		public get DO4Naam(): string {
			return this._DO4Naam;
		}
		public set DO4Naam(newDO4Naam: string) {
			this._DO4Naam = newDO4Naam;
		}

		public get Kenmerk(): string {
			return this._Kenmerk;
		}
		public set Kenmerk(newKenmerk: string) {
			this._Kenmerk = newKenmerk;
		}

		public get Selected(): boolean {
			return this._Selected;
		}
		public set Selected(newSelected: boolean) {
			this._Selected = newSelected;
		}

		// field-Properties

		// fields
		protected _Name: string = "";
		protected _Bot: string = "";
		protected _DO1Type: string = "";
		protected _DO1Naam: string = "";
		protected _DO2Type: string = "";
		protected _DO2Naam: string = "";
		protected _DO3Type: string = "";
		protected _DO3Naam: string = "";
		protected _DO4Type: string = "";
		protected _DO4Naam: string = "";
		protected _Kenmerk: string = "";
		protected _Selected: boolean = false;

		constructor(data?: DataContracts.IBCBProductOpbouwModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBCBProductOpbouwModel): void {
			if(data) {
				// vul properties
				this._Name = data.Name;
				this._Bot = data.Bot;
				this._DO1Type = data.DO1Type;
				this._DO1Naam = data.DO1Naam;
				this._DO2Type = data.DO2Type;
				this._DO2Naam = data.DO2Naam;
				this._DO3Type = data.DO3Type;
				this._DO3Naam = data.DO3Naam;
				this._DO4Type = data.DO4Type;
				this._DO4Naam = data.DO4Naam;
				this._Kenmerk = data.Kenmerk;
				this._Selected = data.Selected;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBCBProductOpbouwModel {
			return {
				Name: this.Name,
				Bot: this.Bot,
				DO1Type: this.DO1Type,
				DO1Naam: this.DO1Naam,
				DO2Type: this.DO2Type,
				DO2Naam: this.DO2Naam,
				DO3Type: this.DO3Type,
				DO3Naam: this.DO3Naam,
				DO4Type: this.DO4Type,
				DO4Naam: this.DO4Naam,
				Kenmerk: this.Kenmerk,
				Selected: this.Selected
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.BCBProductMultiOpbouwModel
	 */
	 

	export interface IBCBProductMultiOpbouwModel {
		Name: string;
		Opbouwen: BCBProductOpbouwModel[];
		ToJsonContract(): DataContracts.IBCBProductMultiOpbouwModel;
	}

	export class BCBProductMultiOpbouwModel {
		// property-Properties
		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Opbouwen(): BCBProductOpbouwModel[] {
			return this._Opbouwen;
		}
		public set Opbouwen(newOpbouwen: BCBProductOpbouwModel[]) {
			this._Opbouwen = newOpbouwen;
		}

		// field-Properties

		// fields
		protected _Name: string = "";
		protected _Opbouwen: BCBProductOpbouwModel[] = [];

		constructor(data?: DataContracts.IBCBProductMultiOpbouwModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBCBProductMultiOpbouwModel): void {
			if(data) {
				// vul properties
				this._Name = data.Name;
				this._Opbouwen = data.Opbouwen ? data.Opbouwen.map(bCBProductOpbouwModelItem => new BCBProductOpbouwModel(bCBProductOpbouwModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBCBProductMultiOpbouwModel {
			return {
				Name: this.Name,
				Opbouwen: (this.Opbouwen ? this.Opbouwen.map(bCBProductOpbouwModelItem => new BCBProductOpbouwModel(bCBProductOpbouwModelItem).ToJsonContract()): [])
			}
		}
	}
