// [conv] import '(./WctBeheerHeader).less';
import { Component } from '@angular/core';

@Component({
    selector: 'wct-beheer-header',
    templateUrl: './WctBeheerHeader.html',
    styleUrls: ['./WctBeheerHeader.scss']
})
export class WctBeheerHeaderComponent {

}