<div class="content" *ngIf="!Loading">
  <div class="panel" style="padding-right: 40px; padding-top: 33px;">
    <div class="bar" style="display: flex;">
      <table style="width: 100%;">
        <tr>
          <td>
          </td>
          <td>
            <div style="text-align: right;">
              <a (click)="RemoveSelectedFilters()" class="reset">Keuzes herstellen</a>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div *ngFor="let filter of FabrikantenFilterResponseModel?.Filters">
      <filter *ngIf="ShouldDisplay(filter)" [FilterViewModel]="filter" [IsFilterFirstLevel]="FabrikantenFilterResponseModel.SelectedProduct == undefined" [HeeftCADAbstract]="FabrikantenFilterResponseModel.SelectedProduct?.HeeftCADAbstract" (FilterChanged)="Refresh()" (ValueChanged)="SetSavedValue($event.filterviewmodelid, $event.savedvalue)"></filter>
      <div style="clear: both;"></div>
    </div>
    <div *ngIf="ShowHalfGarnituur()">
      Let op: Dit betreft een halfgarnituur. Om dit garnituur compleet te maken dient er nog een halfgarnituur geconfigureerd te worden.
    </div>
    <div *ngIf="ShowHalfGarnituurKomgreep()">
      Let op: Dit betreft een halfgarnituur. Om dit garnituur compleet te maken dient er nog een schuifkomgreep geconfigureerd te worden.
    </div>
    <div *ngIf="ShowVeiligheidsRozet()">
      Op dit rozet kan ieder willekeurig snelstift deurkruk gatdeel gemonteerd worden uit het HOPPE assortiment
    </div>
  </div>
  <div class="panel" *ngIf="ActiveProduct == ''" style="padding: 40px;">
    <div>
      <a routerLink=""><img src="/Content/KlantSpecifiek/BCB20343/icon-arrow-left.svg" width="14" height="12"> Terug</a>
      &nbsp;
      <a routerLink="" style="color: #000;"><i class="wct-icon wct-icon-home"></i></a>
      >
      <span>{{ GetActiveCategory() }}</span>
      <!--<a routerLink="" class="reset"><img src="/Content/KlantSpecifiek/BCB20343/icon-arrow-left.svg" width="14" height="12"> Terug naar categorie keuze</a>-->
    </div>
    <br />
    <div style="display: flex; flex-wrap: wrap;">
      <div *ngFor="let product of FabrikantenFilterResponseModel.Products" class="product-panel">
        <div class="img-block" (click)="SelectProduct(product)">
          <img class="img" src="{{ GetProductImageUrl(product) }}" />
        </div>
        <div class="product-div">
          <a class="product" (click)="SelectProduct(product)">{{ product.Naam }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="panel" *ngIf="ActiveProduct !== ''" style="background-color: #f4f6f7; text-align: left; padding-top: 40px;">
    <div>
      <a (click)="DeselectActiveProduct()" class="reset"><img src="/Content/KlantSpecifiek/BCB20343/icon-arrow-left.svg" width="14" height="12"> Terug</a>
      &nbsp;
      <a routerLink="" style="color: #000;"><i class="wct-icon wct-icon-home"></i></a>
      >
      <a (click)="DeselectActiveProduct()" class="reset">
        <span>{{ GetActiveCategory() }}</span>
      </a>
      >
      <span>{{ ActiveProduct }}</span>
    </div>
    <br />

    <div *ngIf="(FabrikantenFilterResponseModel.SelectedProduct !== undefined) && (FabrikantenFilterResponseModel.VerplichteWaardenIngevuld)">
      <div>
        <table style="width: 100%;">
          <tr>
            <td>
              <span style="font-size: 30px;">
                {{ ActiveProduct }}
              </span>
            </td>
            <td style="text-align:right;">
              <wct-button *ngIf="(FabrikantenFilterResponseModel.SelectedProduct !== undefined) && FabrikantenFilterResponseModel.SelectedProduct.BCBWUIDLink != '' && FabrikantenFilterResponseModel.SelectedProduct.BCBWUIDLink != null" buttonSize="small" buttonIconWct="wct-icon wct-icon-bouwconnect-circled" (click)="OpenBCB(FabrikantenFilterResponseModel.SelectedProduct.BCBWUIDLink)" style="width: 140px;">BCB link<i class="fal fa-info-circle" matTooltip="link naar dit product op de BouwConnect Bibliotheek online" matTooltipPosition="below" style="margin-left: 5px;"></i></wct-button>
            </td>
          </tr>
        </table>
      </div>

      <div *ngIf="GetProductDescription() != ''" [innerHTML]="GetProductDescription()">

      </div>

      <div *ngIf="GetLijnTekening() != ''" class="bar" style="display: flex; background-color: #FFF; cursor: pointer;">
        <table style="width: 100%;" cellpadding="0" cellspacing="0">
          <tr>
            <td class="header-step" [class.active-header-step]="ActiveStep == 0"></td>
            <td class="header-step" [class.active-header-step]="ActiveStep == 1"></td>
            <td class="header-step" [class.active-header-step]="ActiveStep == 2"></td>
          </tr>
          <tr>
            <td class="step" [class.active-step]="ActiveStep == 0" (click)="ActiveStep = 0;">
              Afbeelding
            </td>
            <td class="step" [class.active-step]="ActiveStep == 1" (click)="ActiveStep = 1;">
              Lijntekening
            </td>
            <td class="step" [class.active-step]="ActiveStep == 2" (click)="ActiveStep = 2;">
              Bestek
            </td>
          </tr>
        </table>
      </div>

      <div *ngIf="ActiveStep == 0">
        <br />
        <img src="{{ GetProductImageUrl(FabrikantenFilterResponseModel.SelectedProduct) }}" style="max-width: 100%; max-height: 400px;" />
      </div>

      <div *ngIf="ActiveStep == 1">
        <br />
        <img src="{{ GetLijnTekening() }}" style="max-width: 100%; max-height: 400px;" />
      </div>

      <div *ngIf="ActiveStep == 2 || GetLijnTekening() == ''">
        <bestek *ngIf="(FabrikantenFilterResponseModel.SelectedProduct !== undefined) && (FabrikantenFilterResponseModel.VerplichteWaardenIngevuld == true)" [product]="FabrikantenFilterResponseModel.SelectedProduct" (setlastselectedview)="SetLastSelectedView('Bestek')" (setlastselectedproduct)="SetLastSelectedProduct($event.selectedproduct)"></bestek>
        <div *ngIf="(FabrikantenFilterResponseModel.SelectedProduct !== undefined) && (!FabrikantenFilterResponseModel.VerplichteWaardenIngevuld)">
          <br />
          <br />
          Vul eerst de verplichte waarden (*) in.
        </div>
        <div style="clear: both;"></div>
      </div>
    </div>

    <div *ngIf="(FabrikantenFilterResponseModel.SelectedProduct !== undefined) && (!FabrikantenFilterResponseModel.VerplichteWaardenIngevuld)">
      <br />
      <br />
      Vul eerst de verplichte waarden (*) in.
    </div>
  </div>
</div>

<div *ngIf="Loading" class="mat-progress-spinner__cont" style="position:absolute; height: 100%; width: 100%; top:0px; background: rgba(255,255,255,0.5); text-align:center;">
  <div style="padding-top: 300px;">
    <div>
      <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
    </div>
    <div>
      <p>De producten worden geladen</p>
    </div>
  </div>
</div>
