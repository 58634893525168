/**
 * @ref WoonConnectViewModel/FabrikantenAdmin/FabrikantenAdminModels.cs
 */

 import * as DataContracts from "@datacontracts/FabrikantenAdminModels";

 
import * as ProductsViewModel from "./ProductsViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.FabrikantenAdmin.FabrikantenAdminSearchProductRequestModel
	 */
	 

	export interface IFabrikantenAdminSearchProductRequestModel {
		Input: string;
		ToJsonContract(): DataContracts.IFabrikantenAdminSearchProductRequestModel;
	}

	export class FabrikantenAdminSearchProductRequestModel {
		// property-Properties
		public get Input(): string {
			return this._Input;
		}
		public set Input(newInput: string) {
			this._Input = newInput;
		}

		// field-Properties

		// fields
		protected _Input: string = "";

		constructor(data?: DataContracts.IFabrikantenAdminSearchProductRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabrikantenAdminSearchProductRequestModel): void {
			if(data) {
				// vul properties
				this._Input = data.Input;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabrikantenAdminSearchProductRequestModel {
			return {
				Input: this.Input
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.FabrikantenAdmin.FabrikantenAdminSearchProductResponseModel
	 */
	 

	export interface IFabrikantenAdminSearchProductResponseModel {
		Products: ProductsViewModel.IProductViewModel[];
		ToJsonContract(): DataContracts.IFabrikantenAdminSearchProductResponseModel;
	}

	export class FabrikantenAdminSearchProductResponseModel {
		// property-Properties
		public get Products(): ProductsViewModel.IProductViewModel[] {
			return this._Products;
		}
		public set Products(newProducts: ProductsViewModel.IProductViewModel[]) {
			this._Products = newProducts;
		}

		// field-Properties

		// fields
		protected _Products: ProductsViewModel.IProductViewModel[] = [];

		constructor(data?: DataContracts.IFabrikantenAdminSearchProductResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabrikantenAdminSearchProductResponseModel): void {
			if(data) {
				// vul properties
				this._Products = data.Products ? data.Products.map(productViewModelItem => new ProductsViewModel.ProductViewModel(productViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabrikantenAdminSearchProductResponseModel {
			return {
				Products: (this.Products ? this.Products.map(productViewModelItem => new ProductsViewModel.ProductViewModel(productViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.FabrikantenAdmin.FabrikantenAdminKoppelFilterOptionRequestModel
	 */
	 

	export interface IFabrikantenAdminKoppelFilterOptionRequestModel {
		Naam: string;
		BOT: string;
		DO1Naam: string;
		DO2Naam: string;
		DO3Naam: string;
		DO4Naam: string;
		Kenmerk: string;
		NaamIgnore1: string;
		BOTIgnore1: string;
		DO1NaamIgnore1: string;
		DO2NaamIgnore1: string;
		DO3NaamIgnore1: string;
		DO4NaamIgnore1: string;
		KenmerkIgnore1: string;
		NaamIgnore2: string;
		BOTIgnore2: string;
		DO1NaamIgnore2: string;
		DO2NaamIgnore2: string;
		DO3NaamIgnore2: string;
		DO4NaamIgnore2: string;
		KenmerkIgnore2: string;
		NaamIgnore3: string;
		BOTIgnore3: string;
		DO1NaamIgnore3: string;
		DO2NaamIgnore3: string;
		DO3NaamIgnore3: string;
		DO4NaamIgnore3: string;
		KenmerkIgnore3: string;
		NaamIgnore4: string;
		BOTIgnore4: string;
		DO1NaamIgnore4: string;
		DO2NaamIgnore4: string;
		DO3NaamIgnore4: string;
		DO4NaamIgnore4: string;
		KenmerkIgnore4: string;
		NaamIgnore5: string;
		BOTIgnore5: string;
		DO1NaamIgnore5: string;
		DO2NaamIgnore5: string;
		DO3NaamIgnore5: string;
		DO4NaamIgnore5: string;
		KenmerkIgnore5: string;
		FilterOptionId: number;
		ProductProperty: string;
		ProductPropertyOptionId: string;
		ExecuteF: boolean;
		ExecutePP: boolean;
		ToJsonContract(): DataContracts.IFabrikantenAdminKoppelFilterOptionRequestModel;
	}

	export class FabrikantenAdminKoppelFilterOptionRequestModel {
		// property-Properties
		public get Naam(): string {
			return this._Naam;
		}
		public set Naam(newNaam: string) {
			this._Naam = newNaam;
		}

		public get BOT(): string {
			return this._BOT;
		}
		public set BOT(newBOT: string) {
			this._BOT = newBOT;
		}

		public get DO1Naam(): string {
			return this._DO1Naam;
		}
		public set DO1Naam(newDO1Naam: string) {
			this._DO1Naam = newDO1Naam;
		}

		public get DO2Naam(): string {
			return this._DO2Naam;
		}
		public set DO2Naam(newDO2Naam: string) {
			this._DO2Naam = newDO2Naam;
		}

		public get DO3Naam(): string {
			return this._DO3Naam;
		}
		public set DO3Naam(newDO3Naam: string) {
			this._DO3Naam = newDO3Naam;
		}

		public get DO4Naam(): string {
			return this._DO4Naam;
		}
		public set DO4Naam(newDO4Naam: string) {
			this._DO4Naam = newDO4Naam;
		}

		public get Kenmerk(): string {
			return this._Kenmerk;
		}
		public set Kenmerk(newKenmerk: string) {
			this._Kenmerk = newKenmerk;
		}

		public get NaamIgnore1(): string {
			return this._NaamIgnore1;
		}
		public set NaamIgnore1(newNaamIgnore1: string) {
			this._NaamIgnore1 = newNaamIgnore1;
		}

		public get BOTIgnore1(): string {
			return this._BOTIgnore1;
		}
		public set BOTIgnore1(newBOTIgnore1: string) {
			this._BOTIgnore1 = newBOTIgnore1;
		}

		public get DO1NaamIgnore1(): string {
			return this._DO1NaamIgnore1;
		}
		public set DO1NaamIgnore1(newDO1NaamIgnore1: string) {
			this._DO1NaamIgnore1 = newDO1NaamIgnore1;
		}

		public get DO2NaamIgnore1(): string {
			return this._DO2NaamIgnore1;
		}
		public set DO2NaamIgnore1(newDO2NaamIgnore1: string) {
			this._DO2NaamIgnore1 = newDO2NaamIgnore1;
		}

		public get DO3NaamIgnore1(): string {
			return this._DO3NaamIgnore1;
		}
		public set DO3NaamIgnore1(newDO3NaamIgnore1: string) {
			this._DO3NaamIgnore1 = newDO3NaamIgnore1;
		}

		public get DO4NaamIgnore1(): string {
			return this._DO4NaamIgnore1;
		}
		public set DO4NaamIgnore1(newDO4NaamIgnore1: string) {
			this._DO4NaamIgnore1 = newDO4NaamIgnore1;
		}

		public get KenmerkIgnore1(): string {
			return this._KenmerkIgnore1;
		}
		public set KenmerkIgnore1(newKenmerkIgnore1: string) {
			this._KenmerkIgnore1 = newKenmerkIgnore1;
		}

		public get NaamIgnore2(): string {
			return this._NaamIgnore2;
		}
		public set NaamIgnore2(newNaamIgnore2: string) {
			this._NaamIgnore2 = newNaamIgnore2;
		}

		public get BOTIgnore2(): string {
			return this._BOTIgnore2;
		}
		public set BOTIgnore2(newBOTIgnore2: string) {
			this._BOTIgnore2 = newBOTIgnore2;
		}

		public get DO1NaamIgnore2(): string {
			return this._DO1NaamIgnore2;
		}
		public set DO1NaamIgnore2(newDO1NaamIgnore2: string) {
			this._DO1NaamIgnore2 = newDO1NaamIgnore2;
		}

		public get DO2NaamIgnore2(): string {
			return this._DO2NaamIgnore2;
		}
		public set DO2NaamIgnore2(newDO2NaamIgnore2: string) {
			this._DO2NaamIgnore2 = newDO2NaamIgnore2;
		}

		public get DO3NaamIgnore2(): string {
			return this._DO3NaamIgnore2;
		}
		public set DO3NaamIgnore2(newDO3NaamIgnore2: string) {
			this._DO3NaamIgnore2 = newDO3NaamIgnore2;
		}

		public get DO4NaamIgnore2(): string {
			return this._DO4NaamIgnore2;
		}
		public set DO4NaamIgnore2(newDO4NaamIgnore2: string) {
			this._DO4NaamIgnore2 = newDO4NaamIgnore2;
		}

		public get KenmerkIgnore2(): string {
			return this._KenmerkIgnore2;
		}
		public set KenmerkIgnore2(newKenmerkIgnore2: string) {
			this._KenmerkIgnore2 = newKenmerkIgnore2;
		}

		public get NaamIgnore3(): string {
			return this._NaamIgnore3;
		}
		public set NaamIgnore3(newNaamIgnore3: string) {
			this._NaamIgnore3 = newNaamIgnore3;
		}

		public get BOTIgnore3(): string {
			return this._BOTIgnore3;
		}
		public set BOTIgnore3(newBOTIgnore3: string) {
			this._BOTIgnore3 = newBOTIgnore3;
		}

		public get DO1NaamIgnore3(): string {
			return this._DO1NaamIgnore3;
		}
		public set DO1NaamIgnore3(newDO1NaamIgnore3: string) {
			this._DO1NaamIgnore3 = newDO1NaamIgnore3;
		}

		public get DO2NaamIgnore3(): string {
			return this._DO2NaamIgnore3;
		}
		public set DO2NaamIgnore3(newDO2NaamIgnore3: string) {
			this._DO2NaamIgnore3 = newDO2NaamIgnore3;
		}

		public get DO3NaamIgnore3(): string {
			return this._DO3NaamIgnore3;
		}
		public set DO3NaamIgnore3(newDO3NaamIgnore3: string) {
			this._DO3NaamIgnore3 = newDO3NaamIgnore3;
		}

		public get DO4NaamIgnore3(): string {
			return this._DO4NaamIgnore3;
		}
		public set DO4NaamIgnore3(newDO4NaamIgnore3: string) {
			this._DO4NaamIgnore3 = newDO4NaamIgnore3;
		}

		public get KenmerkIgnore3(): string {
			return this._KenmerkIgnore3;
		}
		public set KenmerkIgnore3(newKenmerkIgnore3: string) {
			this._KenmerkIgnore3 = newKenmerkIgnore3;
		}

		public get NaamIgnore4(): string {
			return this._NaamIgnore4;
		}
		public set NaamIgnore4(newNaamIgnore4: string) {
			this._NaamIgnore4 = newNaamIgnore4;
		}

		public get BOTIgnore4(): string {
			return this._BOTIgnore4;
		}
		public set BOTIgnore4(newBOTIgnore4: string) {
			this._BOTIgnore4 = newBOTIgnore4;
		}

		public get DO1NaamIgnore4(): string {
			return this._DO1NaamIgnore4;
		}
		public set DO1NaamIgnore4(newDO1NaamIgnore4: string) {
			this._DO1NaamIgnore4 = newDO1NaamIgnore4;
		}

		public get DO2NaamIgnore4(): string {
			return this._DO2NaamIgnore4;
		}
		public set DO2NaamIgnore4(newDO2NaamIgnore4: string) {
			this._DO2NaamIgnore4 = newDO2NaamIgnore4;
		}

		public get DO3NaamIgnore4(): string {
			return this._DO3NaamIgnore4;
		}
		public set DO3NaamIgnore4(newDO3NaamIgnore4: string) {
			this._DO3NaamIgnore4 = newDO3NaamIgnore4;
		}

		public get DO4NaamIgnore4(): string {
			return this._DO4NaamIgnore4;
		}
		public set DO4NaamIgnore4(newDO4NaamIgnore4: string) {
			this._DO4NaamIgnore4 = newDO4NaamIgnore4;
		}

		public get KenmerkIgnore4(): string {
			return this._KenmerkIgnore4;
		}
		public set KenmerkIgnore4(newKenmerkIgnore4: string) {
			this._KenmerkIgnore4 = newKenmerkIgnore4;
		}

		public get NaamIgnore5(): string {
			return this._NaamIgnore5;
		}
		public set NaamIgnore5(newNaamIgnore5: string) {
			this._NaamIgnore5 = newNaamIgnore5;
		}

		public get BOTIgnore5(): string {
			return this._BOTIgnore5;
		}
		public set BOTIgnore5(newBOTIgnore5: string) {
			this._BOTIgnore5 = newBOTIgnore5;
		}

		public get DO1NaamIgnore5(): string {
			return this._DO1NaamIgnore5;
		}
		public set DO1NaamIgnore5(newDO1NaamIgnore5: string) {
			this._DO1NaamIgnore5 = newDO1NaamIgnore5;
		}

		public get DO2NaamIgnore5(): string {
			return this._DO2NaamIgnore5;
		}
		public set DO2NaamIgnore5(newDO2NaamIgnore5: string) {
			this._DO2NaamIgnore5 = newDO2NaamIgnore5;
		}

		public get DO3NaamIgnore5(): string {
			return this._DO3NaamIgnore5;
		}
		public set DO3NaamIgnore5(newDO3NaamIgnore5: string) {
			this._DO3NaamIgnore5 = newDO3NaamIgnore5;
		}

		public get DO4NaamIgnore5(): string {
			return this._DO4NaamIgnore5;
		}
		public set DO4NaamIgnore5(newDO4NaamIgnore5: string) {
			this._DO4NaamIgnore5 = newDO4NaamIgnore5;
		}

		public get KenmerkIgnore5(): string {
			return this._KenmerkIgnore5;
		}
		public set KenmerkIgnore5(newKenmerkIgnore5: string) {
			this._KenmerkIgnore5 = newKenmerkIgnore5;
		}

		public get FilterOptionId(): number {
			return this._FilterOptionId;
		}
		public set FilterOptionId(newFilterOptionId: number) {
			this._FilterOptionId = newFilterOptionId;
		}

		public get ProductProperty(): string {
			return this._ProductProperty;
		}
		public set ProductProperty(newProductProperty: string) {
			this._ProductProperty = newProductProperty;
		}

		public get ProductPropertyOptionId(): string {
			return this._ProductPropertyOptionId;
		}
		public set ProductPropertyOptionId(newProductPropertyOptionId: string) {
			this._ProductPropertyOptionId = newProductPropertyOptionId;
		}

		public get ExecuteF(): boolean {
			return this._ExecuteF;
		}
		public set ExecuteF(newExecuteF: boolean) {
			this._ExecuteF = newExecuteF;
		}

		public get ExecutePP(): boolean {
			return this._ExecutePP;
		}
		public set ExecutePP(newExecutePP: boolean) {
			this._ExecutePP = newExecutePP;
		}

		// field-Properties

		// fields
		protected _Naam: string = "";
		protected _BOT: string = "";
		protected _DO1Naam: string = "";
		protected _DO2Naam: string = "";
		protected _DO3Naam: string = "";
		protected _DO4Naam: string = "";
		protected _Kenmerk: string = "";
		protected _NaamIgnore1: string = "";
		protected _BOTIgnore1: string = "";
		protected _DO1NaamIgnore1: string = "";
		protected _DO2NaamIgnore1: string = "";
		protected _DO3NaamIgnore1: string = "";
		protected _DO4NaamIgnore1: string = "";
		protected _KenmerkIgnore1: string = "";
		protected _NaamIgnore2: string = "";
		protected _BOTIgnore2: string = "";
		protected _DO1NaamIgnore2: string = "";
		protected _DO2NaamIgnore2: string = "";
		protected _DO3NaamIgnore2: string = "";
		protected _DO4NaamIgnore2: string = "";
		protected _KenmerkIgnore2: string = "";
		protected _NaamIgnore3: string = "";
		protected _BOTIgnore3: string = "";
		protected _DO1NaamIgnore3: string = "";
		protected _DO2NaamIgnore3: string = "";
		protected _DO3NaamIgnore3: string = "";
		protected _DO4NaamIgnore3: string = "";
		protected _KenmerkIgnore3: string = "";
		protected _NaamIgnore4: string = "";
		protected _BOTIgnore4: string = "";
		protected _DO1NaamIgnore4: string = "";
		protected _DO2NaamIgnore4: string = "";
		protected _DO3NaamIgnore4: string = "";
		protected _DO4NaamIgnore4: string = "";
		protected _KenmerkIgnore4: string = "";
		protected _NaamIgnore5: string = "";
		protected _BOTIgnore5: string = "";
		protected _DO1NaamIgnore5: string = "";
		protected _DO2NaamIgnore5: string = "";
		protected _DO3NaamIgnore5: string = "";
		protected _DO4NaamIgnore5: string = "";
		protected _KenmerkIgnore5: string = "";
		protected _FilterOptionId: number = 0;
		protected _ProductProperty: string = "";
		protected _ProductPropertyOptionId: string = "";
		protected _ExecuteF: boolean = false;
		protected _ExecutePP: boolean = false;

		constructor(data?: DataContracts.IFabrikantenAdminKoppelFilterOptionRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabrikantenAdminKoppelFilterOptionRequestModel): void {
			if(data) {
				// vul properties
				this._Naam = data.Naam;
				this._BOT = data.BOT;
				this._DO1Naam = data.DO1Naam;
				this._DO2Naam = data.DO2Naam;
				this._DO3Naam = data.DO3Naam;
				this._DO4Naam = data.DO4Naam;
				this._Kenmerk = data.Kenmerk;
				this._NaamIgnore1 = data.NaamIgnore1;
				this._BOTIgnore1 = data.BOTIgnore1;
				this._DO1NaamIgnore1 = data.DO1NaamIgnore1;
				this._DO2NaamIgnore1 = data.DO2NaamIgnore1;
				this._DO3NaamIgnore1 = data.DO3NaamIgnore1;
				this._DO4NaamIgnore1 = data.DO4NaamIgnore1;
				this._KenmerkIgnore1 = data.KenmerkIgnore1;
				this._NaamIgnore2 = data.NaamIgnore2;
				this._BOTIgnore2 = data.BOTIgnore2;
				this._DO1NaamIgnore2 = data.DO1NaamIgnore2;
				this._DO2NaamIgnore2 = data.DO2NaamIgnore2;
				this._DO3NaamIgnore2 = data.DO3NaamIgnore2;
				this._DO4NaamIgnore2 = data.DO4NaamIgnore2;
				this._KenmerkIgnore2 = data.KenmerkIgnore2;
				this._NaamIgnore3 = data.NaamIgnore3;
				this._BOTIgnore3 = data.BOTIgnore3;
				this._DO1NaamIgnore3 = data.DO1NaamIgnore3;
				this._DO2NaamIgnore3 = data.DO2NaamIgnore3;
				this._DO3NaamIgnore3 = data.DO3NaamIgnore3;
				this._DO4NaamIgnore3 = data.DO4NaamIgnore3;
				this._KenmerkIgnore3 = data.KenmerkIgnore3;
				this._NaamIgnore4 = data.NaamIgnore4;
				this._BOTIgnore4 = data.BOTIgnore4;
				this._DO1NaamIgnore4 = data.DO1NaamIgnore4;
				this._DO2NaamIgnore4 = data.DO2NaamIgnore4;
				this._DO3NaamIgnore4 = data.DO3NaamIgnore4;
				this._DO4NaamIgnore4 = data.DO4NaamIgnore4;
				this._KenmerkIgnore4 = data.KenmerkIgnore4;
				this._NaamIgnore5 = data.NaamIgnore5;
				this._BOTIgnore5 = data.BOTIgnore5;
				this._DO1NaamIgnore5 = data.DO1NaamIgnore5;
				this._DO2NaamIgnore5 = data.DO2NaamIgnore5;
				this._DO3NaamIgnore5 = data.DO3NaamIgnore5;
				this._DO4NaamIgnore5 = data.DO4NaamIgnore5;
				this._KenmerkIgnore5 = data.KenmerkIgnore5;
				this._FilterOptionId = data.FilterOptionId;
				this._ProductProperty = data.ProductProperty;
				this._ProductPropertyOptionId = data.ProductPropertyOptionId;
				this._ExecuteF = data.ExecuteF;
				this._ExecutePP = data.ExecutePP;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabrikantenAdminKoppelFilterOptionRequestModel {
			return {
				Naam: this.Naam,
				BOT: this.BOT,
				DO1Naam: this.DO1Naam,
				DO2Naam: this.DO2Naam,
				DO3Naam: this.DO3Naam,
				DO4Naam: this.DO4Naam,
				Kenmerk: this.Kenmerk,
				NaamIgnore1: this.NaamIgnore1,
				BOTIgnore1: this.BOTIgnore1,
				DO1NaamIgnore1: this.DO1NaamIgnore1,
				DO2NaamIgnore1: this.DO2NaamIgnore1,
				DO3NaamIgnore1: this.DO3NaamIgnore1,
				DO4NaamIgnore1: this.DO4NaamIgnore1,
				KenmerkIgnore1: this.KenmerkIgnore1,
				NaamIgnore2: this.NaamIgnore2,
				BOTIgnore2: this.BOTIgnore2,
				DO1NaamIgnore2: this.DO1NaamIgnore2,
				DO2NaamIgnore2: this.DO2NaamIgnore2,
				DO3NaamIgnore2: this.DO3NaamIgnore2,
				DO4NaamIgnore2: this.DO4NaamIgnore2,
				KenmerkIgnore2: this.KenmerkIgnore2,
				NaamIgnore3: this.NaamIgnore3,
				BOTIgnore3: this.BOTIgnore3,
				DO1NaamIgnore3: this.DO1NaamIgnore3,
				DO2NaamIgnore3: this.DO2NaamIgnore3,
				DO3NaamIgnore3: this.DO3NaamIgnore3,
				DO4NaamIgnore3: this.DO4NaamIgnore3,
				KenmerkIgnore3: this.KenmerkIgnore3,
				NaamIgnore4: this.NaamIgnore4,
				BOTIgnore4: this.BOTIgnore4,
				DO1NaamIgnore4: this.DO1NaamIgnore4,
				DO2NaamIgnore4: this.DO2NaamIgnore4,
				DO3NaamIgnore4: this.DO3NaamIgnore4,
				DO4NaamIgnore4: this.DO4NaamIgnore4,
				KenmerkIgnore4: this.KenmerkIgnore4,
				NaamIgnore5: this.NaamIgnore5,
				BOTIgnore5: this.BOTIgnore5,
				DO1NaamIgnore5: this.DO1NaamIgnore5,
				DO2NaamIgnore5: this.DO2NaamIgnore5,
				DO3NaamIgnore5: this.DO3NaamIgnore5,
				DO4NaamIgnore5: this.DO4NaamIgnore5,
				KenmerkIgnore5: this.KenmerkIgnore5,
				FilterOptionId: this.FilterOptionId,
				ProductProperty: this.ProductProperty,
				ProductPropertyOptionId: this.ProductPropertyOptionId,
				ExecuteF: this.ExecuteF,
				ExecutePP: this.ExecutePP
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.FabrikantenAdmin.FabrikantenAdminKoppelFilterOptionResponseModel
	 */
	 

	export interface IFabrikantenAdminKoppelFilterOptionResponseModel {
		Message: string;
		ProductVariantModels: ProductsViewModel.IProductVariantModel[];
		ToJsonContract(): DataContracts.IFabrikantenAdminKoppelFilterOptionResponseModel;
	}

	export class FabrikantenAdminKoppelFilterOptionResponseModel {
		// property-Properties
		public get Message(): string {
			return this._Message;
		}
		public set Message(newMessage: string) {
			this._Message = newMessage;
		}

		public get ProductVariantModels(): ProductsViewModel.IProductVariantModel[] {
			return this._ProductVariantModels;
		}
		public set ProductVariantModels(newProductVariantModels: ProductsViewModel.IProductVariantModel[]) {
			this._ProductVariantModels = newProductVariantModels;
		}

		// field-Properties

		// fields
		protected _Message: string = "";
		protected _ProductVariantModels: ProductsViewModel.IProductVariantModel[] = [];

		constructor(data?: DataContracts.IFabrikantenAdminKoppelFilterOptionResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabrikantenAdminKoppelFilterOptionResponseModel): void {
			if(data) {
				// vul properties
				this._Message = data.Message;
				this._ProductVariantModels = data.ProductVariantModels ? data.ProductVariantModels.map(productVariantModelItem => new ProductsViewModel.ProductVariantModel(productVariantModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabrikantenAdminKoppelFilterOptionResponseModel {
			return {
				Message: this.Message,
				ProductVariantModels: (this.ProductVariantModels ? this.ProductVariantModels.map(productVariantModelItem => new ProductsViewModel.ProductVariantModel(productVariantModelItem).ToJsonContract()): [])
			}
		}
	}
