import * as ViewModelsWoning from '.././Models/Woning';
import * as ServicesPersoonService from '.././services/PersoonService';
import * as ViewModelsWoningGroepType from '.././Models/WoningGroepType';
import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';
import * as ServicesWoningService from '.././services/WoningService';



	export class WoningGroep implements ServicesWoningService.IBreadcrumbSelectie {
		public GeefLabel(): string {
			return this.Naam;
		}

		public GeefGroepTypeNum(): EnumsWijzigScopeEnum.WijzigScopeEnum {
			return this.Type.GroepType;
		}

		public GeefID(): number {
			return this.ID;
		}

		public Naam: string;
		public ID: number;
		public Type: ViewModelsWoningGroepType.WoningGroepType;
		public Plaats: string;
		public Postcode: string;
		public Straat: string;
		public Nummer: string;
		public Longitude: string;
		public Latitude: string;
		public OppervlaktePolygoon: string;
		public MaximaleHuurverhoging: number;
		public WoningType: string;
		public WoningTypeNaam: string;
		public Typologie: string;

		public WoningID: number;
		public BlokID: number;
		public AdresStraat: string;
		public AdresNummer: string;
		public WoningEigenschappenWoningType: string;

		public RD_x: number;
		public RD_y: number;
		public BAGID: string;

		public HoofdBewonerId: number;
		public HoofdBewonerIdString: string;
		public HoofdBewonerNaam: string;
		public HoofdBewonerAccountNaam: string;
		public HoofdBewonerAccountGuid: string;
		public HoofdBewonerVerificatieCode: string;
		public HoofdBewonerVerificatieCompleted: boolean;
		public HoofdBewonerAccountLocked: boolean;
		public HoofdBewonerAccountApproved: boolean;
		public HoofdBewonerRol: string;
		public ComplexNaam: string;

		//public StartDatum: string;
		//public EindDatum: string;
		//public Ingediend: string;
		//public IsIngediend: boolean;
		public Status: string;
		public KavelStatus: string;
		//public HeeftAfspraak: boolean;

		public Index: number;
		public Laag: number;
		//public autozoom: number = 1;

		public Personen: Array<ServicesPersoonService.PersoonInfo>;
		public WOZWaarde: number;

		public WijkNaam: string;

		public Kavelstatussen: Array<string>;
		public isOnderdeelVanVVE: boolean = false;

		// interface props
		public Tonen: boolean = false;
		public Clickable: boolean = false;
		public BezigMetLaden: boolean = false;

		public AdresTekst(): string { return this.AdresStraat + " " + this.AdresNummer }

		IsClickable(): boolean {
			//return true;
			return this.Clickable;
		}

		IsToonbaar(): boolean {
			//return true;
			return this.Tonen;
		}

		public SubGroepIDs: number[];
		public SubGroepen: ServicesWoningService.IBreadcrumbSelectie[];
		public SuperGroep: WoningGroep;
		public Woningen: ViewModelsWoning.Woning[];
		public GeefSubGroepen(): ServicesWoningService.IBreadcrumbSelectie[] {
			if (this.Woningen.length > 0) {
				return this.Woningen;
			}
			else {
				return this.SubGroepen;
			}
		}

		public LaadData(data: any) {
			//this.HeeftAfspraak = false;
			//this.Ingediend = data.Ingediend;
			//this.IsIngediend = data.Ingediend != null;
			this.WoningID = data.Id;
			this.AdresStraat = data.Straat;
			this.AdresNummer = data.Nummer;
			this.Postcode = data.Postcode;
			this.Plaats = data.Plaats;
			this.Status = data.Status;
			this.HoofdBewonerId = data.HoofdBewonerId;
			this.HoofdBewonerIdString = data.HoofdBewonerId + "";
			this.HoofdBewonerNaam = data.HoofdBewonerNaam;
			this.HoofdBewonerAccountGuid = data.HoofdBewonerAccountGuid;
			this.HoofdBewonerVerificatieCode = data.HoofdBewonerVerificatieCode;
			this.HoofdBewonerVerificatieCompleted = data.HoofdBewonerVerificatieCompleted;
			this.HoofdBewonerAccountLocked = data.HoofdBewonerAccountLocked;
			this.HoofdBewonerAccountApproved = data.HoofdBewonerAccountApproved;
			//this.EindDatum = data.EindDatum;
			//this.StartDatum = data.StartDatum;
			this.BlokID = data.BlokId;
			this.WoningEigenschappenWoningType = data.WoningEigenschappenWoningType;
			this.MaximaleHuurverhoging = data.MaximaleHuurverhoging;

			this.Personen = new Array();

			this.Kavelstatussen = $.map((<{ Kavelstatussen: Array<string> }>data).Kavelstatussen, function (item) { return item; });
			this.KavelStatus = data.KavelStatus;

			this.Latitude = data.Lat;
			this.Longitude = data.Lng;
			this.OppervlaktePolygoon = data.OppervlaktePolygoon;
			this.WOZWaarde = data.WOZWaarde;
		}

		public ToonWoning() {
			alert(this.WoningID);
		}
	}

