<div class="content">

  <h2>Instructie</h2>

  <div *ngIf="Loading">
    <div style="padding-top: 300px; padding-bottom: 300px; text-align: center;">
      <div>
        <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
      </div>
      <div>
        <p>Bezig met laden...</p>
      </div>
    </div>
  </div>

  <div *ngIf="!Loading">
    <table style="width: 100%;">
      <tr>
        <td style="width: 40%;">
          <a href="/Content/KlantSpecifiek/BCB21111/PDF/Instructie/{{ InstructieViewModel.FileName}}?a={{ GetTime() }}" target="_blank">
            <img src="/Content/KlantSpecifiek/BCB21111/PDF/InstructieImage/{{ InstructieViewModel.FileNameImage }}?a={{ GetTime() }}" style="width: 100%;" />
          </a>
        </td>
        <td style="vertical-align: top;">
          <a href="/Content/KlantSpecifiek/BCB21111/PDF/Instructie/{{ InstructieViewModel.FileName}}?a={{ GetTime() }}" target="_blank">
            {{ InstructieViewModel.FileName}}
          </a>
        </td>
      </tr>
    </table>
  </div>
</div>
