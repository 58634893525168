/// <reference path='../consumerinterfaces/TenderController.ts' />
/**
 * @ref WoonConnectApiController/Controllers/Projecten/TenderController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/TenderController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as TenderViewModel from "@datacontracts/TenderViewModel";
import * as ContractorOfferViewModel from "@datacontracts/ContractorOfferViewModel";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class TenderAPIConsumer implements Interface.ITenderAPIConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public GetTenders_Observable(pTenderRequestAllModelModel: TenderViewModel.ITenderRequestAllModel & {ToJsonContract(noContent?: boolean): TenderViewModel.ITenderRequestAllModel}): Observable<TenderViewModel.ITenderResultAllModel> {
				const pTenderRequestAllModel = pTenderRequestAllModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pTenderRequestAllModel;
				let apiURL = `api/TenderAPI/GetTenders/${pTenderRequestAllModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<TenderViewModel.ITenderResultAllModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetTenders(pTenderRequestAllModelModel: TenderViewModel.ITenderRequestAllModel & {ToJsonContract(noContent?: boolean): TenderViewModel.ITenderRequestAllModel}): ng.IPromise<TenderViewModel.ITenderResultAllModel> {
				let obs = this.GetTenders_Observable(pTenderRequestAllModelModel);
				let promise = new Promise<TenderViewModel.ITenderResultAllModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveTender_Observable(pTenderViewModelModel: TenderViewModel.ITenderViewModel & {ToJsonContract(noContent?: boolean): TenderViewModel.ITenderViewModel}): Observable<TenderViewModel.ITenderViewModel> {
				const pTenderViewModel = pTenderViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pTenderViewModel;
				let apiURL = `api/TenderAPI/SaveTender/${pTenderViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<TenderViewModel.ITenderViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveTender(pTenderViewModelModel: TenderViewModel.ITenderViewModel & {ToJsonContract(noContent?: boolean): TenderViewModel.ITenderViewModel}): ng.IPromise<TenderViewModel.ITenderViewModel> {
				let obs = this.SaveTender_Observable(pTenderViewModelModel);
				let promise = new Promise<TenderViewModel.ITenderViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public RemoveTender_Observable(pTenderViewModelModel: TenderViewModel.ITenderViewModel & {ToJsonContract(noContent?: boolean): TenderViewModel.ITenderViewModel}): Observable<void> {
				const pTenderViewModel = pTenderViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pTenderViewModel;
				let apiURL = `api/TenderAPI/RemoveTender/${pTenderViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public RemoveTender(pTenderViewModelModel: TenderViewModel.ITenderViewModel & {ToJsonContract(noContent?: boolean): TenderViewModel.ITenderViewModel}): ng.IPromise<void> {
				let obs = this.RemoveTender_Observable(pTenderViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ChangeContractorOfferStatus_Observable(pContractorOfferViewModelModel: ContractorOfferViewModel.IContractorOfferViewModel & {ToJsonContract(noContent?: boolean): ContractorOfferViewModel.IContractorOfferViewModel}): Observable<void> {
				const pContractorOfferViewModel = pContractorOfferViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pContractorOfferViewModel;
				let apiURL = `api/TenderAPI/ChangeContractorOfferStatus/${pContractorOfferViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ChangeContractorOfferStatus(pContractorOfferViewModelModel: ContractorOfferViewModel.IContractorOfferViewModel & {ToJsonContract(noContent?: boolean): ContractorOfferViewModel.IContractorOfferViewModel}): ng.IPromise<void> {
				let obs = this.ChangeContractorOfferStatus_Observable(pContractorOfferViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


