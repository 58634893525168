<ng-container *ngIf="facades">
  <div *ngFor="let control of facades.controls;
  trackBy: trackByIndex;
  let i = index">
    <label>Control {{ i }}</label>
    <wct-survey-string [prop]='control.controls.Name'>
    </wct-survey-string>
  </div>
</ng-container>

