/**
 * @ref WoonConnectViewModel/Survey/SurveyClimateSystem.cs
 */

 import * as DataContracts from "@datacontracts/SurveyClimateSystem";

 
import * as SurveyProperty from "./SurveyProperty";
import * as SurveyClimateSystemEnum from "@enums/SurveyClimateSystem";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.SurveyClimateSystem
	 */
	 

	export interface ISurveyClimateSystem {
		ObjectId: string;
		Name: SurveyProperty.ISurveyProperty;
		Functions: SurveyClimateSystemEnum.Functions;
		HeatingType: SurveyClimateSystemEnum.HeatingType;
		HeatingDevice: SurveyClimateSystemEnum.HeatingDevice;
		HeatingDeviceEnergyType: SurveyClimateSystemEnum.HeatingDeviceEnergyType;
		HeatingDistribution: SurveyClimateSystemEnum.HeatingDistribution;
		HeatingPumpSource: SurveyClimateSystemEnum.HeatingPumpSource;
		AllElectric: SurveyClimateSystemEnum.AllElectric;
		OtherFunctions: SurveyClimateSystemEnum.OtherFunctions;
		BoilerType: SurveyClimateSystemEnum.BoilerType;
		VentilationSupply: SurveyClimateSystemEnum.VentilationSupply;
		LocalHeatingType: SurveyClimateSystemEnum.LocalHeatingType;
		Drain: SurveyClimateSystemEnum.Drain;
		BiomassAccumulate: SurveyClimateSystemEnum.BiomassAccumulate;
		DistributionSystemType: SurveyClimateSystemEnum.DistributionSystemType;
		DistributionInsulationPipes: SurveyClimateSystemEnum.DistributionInsulationPipes;
		DistributionPipesThroughNonInsulatedRoom: SurveyClimateSystemEnum.DistributionPipesThroughNonInsulatedRoom;
		CWClass: SurveyClimateSystemEnum.CWClass;
		GasHrLabel: SurveyClimateSystemEnum.GasHrLabel;
		GeneratorPowerHreWKK: SurveyClimateSystemEnum.GeneratorPowerHreWKK;
		OnlineWuid: string;
		ToJsonContract(): DataContracts.ISurveyClimateSystem;
	}

	export class SurveyClimateSystem {
		// property-Properties
		// field-Properties
		public get ObjectId(): string {
			return this._ObjectId;
		}
		public set ObjectId(newObjectId: string) {
			this._ObjectId = newObjectId;
		}
		
		public get Name(): SurveyProperty.ISurveyProperty {
			return this._Name;
		}
		public set Name(newName: SurveyProperty.ISurveyProperty) {
			this._Name = newName;
		}
		
		public get Functions(): SurveyClimateSystemEnum.Functions {
			return this._Functions;
		}
		public set Functions(newFunctions: SurveyClimateSystemEnum.Functions) {
			this._Functions = newFunctions;
		}
		
		public get HeatingType(): SurveyClimateSystemEnum.HeatingType {
			return this._HeatingType;
		}
		public set HeatingType(newHeatingType: SurveyClimateSystemEnum.HeatingType) {
			this._HeatingType = newHeatingType;
		}
		
		public get HeatingDevice(): SurveyClimateSystemEnum.HeatingDevice {
			return this._HeatingDevice;
		}
		public set HeatingDevice(newHeatingDevice: SurveyClimateSystemEnum.HeatingDevice) {
			this._HeatingDevice = newHeatingDevice;
		}
		
		public get HeatingDeviceEnergyType(): SurveyClimateSystemEnum.HeatingDeviceEnergyType {
			return this._HeatingDeviceEnergyType;
		}
		public set HeatingDeviceEnergyType(newHeatingDeviceEnergyType: SurveyClimateSystemEnum.HeatingDeviceEnergyType) {
			this._HeatingDeviceEnergyType = newHeatingDeviceEnergyType;
		}
		
		public get HeatingDistribution(): SurveyClimateSystemEnum.HeatingDistribution {
			return this._HeatingDistribution;
		}
		public set HeatingDistribution(newHeatingDistribution: SurveyClimateSystemEnum.HeatingDistribution) {
			this._HeatingDistribution = newHeatingDistribution;
		}
		
		public get HeatingPumpSource(): SurveyClimateSystemEnum.HeatingPumpSource {
			return this._HeatingPumpSource;
		}
		public set HeatingPumpSource(newHeatingPumpSource: SurveyClimateSystemEnum.HeatingPumpSource) {
			this._HeatingPumpSource = newHeatingPumpSource;
		}
		
		public get AllElectric(): SurveyClimateSystemEnum.AllElectric {
			return this._AllElectric;
		}
		public set AllElectric(newAllElectric: SurveyClimateSystemEnum.AllElectric) {
			this._AllElectric = newAllElectric;
		}
		
		public get OtherFunctions(): SurveyClimateSystemEnum.OtherFunctions {
			return this._OtherFunctions;
		}
		public set OtherFunctions(newOtherFunctions: SurveyClimateSystemEnum.OtherFunctions) {
			this._OtherFunctions = newOtherFunctions;
		}
		
		public get BoilerType(): SurveyClimateSystemEnum.BoilerType {
			return this._BoilerType;
		}
		public set BoilerType(newBoilerType: SurveyClimateSystemEnum.BoilerType) {
			this._BoilerType = newBoilerType;
		}
		
		public get VentilationSupply(): SurveyClimateSystemEnum.VentilationSupply {
			return this._VentilationSupply;
		}
		public set VentilationSupply(newVentilationSupply: SurveyClimateSystemEnum.VentilationSupply) {
			this._VentilationSupply = newVentilationSupply;
		}
		
		public get LocalHeatingType(): SurveyClimateSystemEnum.LocalHeatingType {
			return this._LocalHeatingType;
		}
		public set LocalHeatingType(newLocalHeatingType: SurveyClimateSystemEnum.LocalHeatingType) {
			this._LocalHeatingType = newLocalHeatingType;
		}
		
		public get Drain(): SurveyClimateSystemEnum.Drain {
			return this._Drain;
		}
		public set Drain(newDrain: SurveyClimateSystemEnum.Drain) {
			this._Drain = newDrain;
		}
		
		public get BiomassAccumulate(): SurveyClimateSystemEnum.BiomassAccumulate {
			return this._BiomassAccumulate;
		}
		public set BiomassAccumulate(newBiomassAccumulate: SurveyClimateSystemEnum.BiomassAccumulate) {
			this._BiomassAccumulate = newBiomassAccumulate;
		}
		
		public get DistributionSystemType(): SurveyClimateSystemEnum.DistributionSystemType {
			return this._DistributionSystemType;
		}
		public set DistributionSystemType(newDistributionSystemType: SurveyClimateSystemEnum.DistributionSystemType) {
			this._DistributionSystemType = newDistributionSystemType;
		}
		
		public get DistributionInsulationPipes(): SurveyClimateSystemEnum.DistributionInsulationPipes {
			return this._DistributionInsulationPipes;
		}
		public set DistributionInsulationPipes(newDistributionInsulationPipes: SurveyClimateSystemEnum.DistributionInsulationPipes) {
			this._DistributionInsulationPipes = newDistributionInsulationPipes;
		}
		
		public get DistributionPipesThroughNonInsulatedRoom(): SurveyClimateSystemEnum.DistributionPipesThroughNonInsulatedRoom {
			return this._DistributionPipesThroughNonInsulatedRoom;
		}
		public set DistributionPipesThroughNonInsulatedRoom(newDistributionPipesThroughNonInsulatedRoom: SurveyClimateSystemEnum.DistributionPipesThroughNonInsulatedRoom) {
			this._DistributionPipesThroughNonInsulatedRoom = newDistributionPipesThroughNonInsulatedRoom;
		}
		
		public get CWClass(): SurveyClimateSystemEnum.CWClass {
			return this._CWClass;
		}
		public set CWClass(newCWClass: SurveyClimateSystemEnum.CWClass) {
			this._CWClass = newCWClass;
		}
		
		public get GasHrLabel(): SurveyClimateSystemEnum.GasHrLabel {
			return this._GasHrLabel;
		}
		public set GasHrLabel(newGasHrLabel: SurveyClimateSystemEnum.GasHrLabel) {
			this._GasHrLabel = newGasHrLabel;
		}
		
		public get GeneratorPowerHreWKK(): SurveyClimateSystemEnum.GeneratorPowerHreWKK {
			return this._GeneratorPowerHreWKK;
		}
		public set GeneratorPowerHreWKK(newGeneratorPowerHreWKK: SurveyClimateSystemEnum.GeneratorPowerHreWKK) {
			this._GeneratorPowerHreWKK = newGeneratorPowerHreWKK;
		}
		
		public get OnlineWuid(): string {
			return this._OnlineWuid;
		}
		public set OnlineWuid(newOnlineWuid: string) {
			this._OnlineWuid = newOnlineWuid;
		}

		// fields
		protected _ObjectId: string = "";
		protected _Name: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _Functions: SurveyClimateSystemEnum.Functions = undefined;
		protected _HeatingType: SurveyClimateSystemEnum.HeatingType = undefined;
		protected _HeatingDevice: SurveyClimateSystemEnum.HeatingDevice = undefined;
		protected _HeatingDeviceEnergyType: SurveyClimateSystemEnum.HeatingDeviceEnergyType = undefined;
		protected _HeatingDistribution: SurveyClimateSystemEnum.HeatingDistribution = undefined;
		protected _HeatingPumpSource: SurveyClimateSystemEnum.HeatingPumpSource = undefined;
		protected _AllElectric: SurveyClimateSystemEnum.AllElectric = undefined;
		protected _OtherFunctions: SurveyClimateSystemEnum.OtherFunctions = undefined;
		protected _BoilerType: SurveyClimateSystemEnum.BoilerType = undefined;
		protected _VentilationSupply: SurveyClimateSystemEnum.VentilationSupply = undefined;
		protected _LocalHeatingType: SurveyClimateSystemEnum.LocalHeatingType = undefined;
		protected _Drain: SurveyClimateSystemEnum.Drain = undefined;
		protected _BiomassAccumulate: SurveyClimateSystemEnum.BiomassAccumulate = undefined;
		protected _DistributionSystemType: SurveyClimateSystemEnum.DistributionSystemType = undefined;
		protected _DistributionInsulationPipes: SurveyClimateSystemEnum.DistributionInsulationPipes = undefined;
		protected _DistributionPipesThroughNonInsulatedRoom: SurveyClimateSystemEnum.DistributionPipesThroughNonInsulatedRoom = undefined;
		protected _CWClass: SurveyClimateSystemEnum.CWClass = undefined;
		protected _GasHrLabel: SurveyClimateSystemEnum.GasHrLabel = undefined;
		protected _GeneratorPowerHreWKK: SurveyClimateSystemEnum.GeneratorPowerHreWKK = undefined;
		protected _OnlineWuid: string = "";

		constructor(data?: DataContracts.ISurveyClimateSystem) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISurveyClimateSystem): void {
			if(data) {
				// vul properties
				// vul fields
				this._ObjectId = data.ObjectId;
				this._Name = data.Name ? new SurveyProperty.SurveyProperty(data.Name): undefined;
				this._Functions = data.Functions;
				this._HeatingType = data.HeatingType;
				this._HeatingDevice = data.HeatingDevice;
				this._HeatingDeviceEnergyType = data.HeatingDeviceEnergyType;
				this._HeatingDistribution = data.HeatingDistribution;
				this._HeatingPumpSource = data.HeatingPumpSource;
				this._AllElectric = data.AllElectric;
				this._OtherFunctions = data.OtherFunctions;
				this._BoilerType = data.BoilerType;
				this._VentilationSupply = data.VentilationSupply;
				this._LocalHeatingType = data.LocalHeatingType;
				this._Drain = data.Drain;
				this._BiomassAccumulate = data.BiomassAccumulate;
				this._DistributionSystemType = data.DistributionSystemType;
				this._DistributionInsulationPipes = data.DistributionInsulationPipes;
				this._DistributionPipesThroughNonInsulatedRoom = data.DistributionPipesThroughNonInsulatedRoom;
				this._CWClass = data.CWClass;
				this._GasHrLabel = data.GasHrLabel;
				this._GeneratorPowerHreWKK = data.GeneratorPowerHreWKK;
				this._OnlineWuid = data.OnlineWuid;
			}
		}

		public ToJsonContract(): DataContracts.ISurveyClimateSystem {
			return {
				ObjectId: this.ObjectId,
				Name: (this.Name ? new SurveyProperty.SurveyProperty(this.Name).ToJsonContract(): this.Name),
				Functions: this.Functions,
				HeatingType: this.HeatingType,
				HeatingDevice: this.HeatingDevice,
				HeatingDeviceEnergyType: this.HeatingDeviceEnergyType,
				HeatingDistribution: this.HeatingDistribution,
				HeatingPumpSource: this.HeatingPumpSource,
				AllElectric: this.AllElectric,
				OtherFunctions: this.OtherFunctions,
				BoilerType: this.BoilerType,
				VentilationSupply: this.VentilationSupply,
				LocalHeatingType: this.LocalHeatingType,
				Drain: this.Drain,
				BiomassAccumulate: this.BiomassAccumulate,
				DistributionSystemType: this.DistributionSystemType,
				DistributionInsulationPipes: this.DistributionInsulationPipes,
				DistributionPipesThroughNonInsulatedRoom: this.DistributionPipesThroughNonInsulatedRoom,
				CWClass: this.CWClass,
				GasHrLabel: this.GasHrLabel,
				GeneratorPowerHreWKK: this.GeneratorPowerHreWKK,
				OnlineWuid: this.OnlineWuid
			}
		}
	}
