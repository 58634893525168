/**
 * @ref /WijzigScopeEnum.cs
 */
	/**
	* Originele namespace: WoonConnectDomein.Enumeraties.WijzigScopeEnum
	*/
	export enum WijzigScopeEnum {
		Niks = 0,
		
		AlleComplexen = 1,
		
		Complex = 2,
		
		Blok = 3,
		
		Woning = 4,
		
		EnergieProfiel = 5
	}

