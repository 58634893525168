/**
 * @ref WoonConnectViewModel/Project/GeneratePasswordsModel.cs
 */

 import * as DataContracts from "@datacontracts/GeneratePasswordsModel";

 
import * as FileDownload from "./FileDownload";



	/**
	 * Originele namespace: WoonConnectViewModel.Project.GeneratePasswordsRequestModel
	 */
	 

	export interface IGeneratePasswordsRequestModel {
		VoorbeeldTekstIn: string;
		Doorvoeren: boolean;
		ToegestaneChars: string;
		Aantal: number;
		ToJsonContract(): DataContracts.IGeneratePasswordsRequestModel;
	}

	export class GeneratePasswordsRequestModel {
		// property-Properties
		public get VoorbeeldTekstIn(): string {
			return this._VoorbeeldTekstIn;
		}
		public set VoorbeeldTekstIn(newVoorbeeldTekstIn: string) {
			this._VoorbeeldTekstIn = newVoorbeeldTekstIn;
		}

		public get Doorvoeren(): boolean {
			return this._Doorvoeren;
		}
		public set Doorvoeren(newDoorvoeren: boolean) {
			this._Doorvoeren = newDoorvoeren;
		}

		public get ToegestaneChars(): string {
			return this._ToegestaneChars;
		}
		public set ToegestaneChars(newToegestaneChars: string) {
			this._ToegestaneChars = newToegestaneChars;
		}

		public get Aantal(): number {
			return this._Aantal;
		}
		public set Aantal(newAantal: number) {
			this._Aantal = newAantal;
		}

		// field-Properties

		// fields
		protected _VoorbeeldTekstIn: string = "";
		protected _Doorvoeren: boolean = false;
		protected _ToegestaneChars: string = "";
		protected _Aantal: number = 0;

		constructor(data?: DataContracts.IGeneratePasswordsRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGeneratePasswordsRequestModel): void {
			if(data) {
				// vul properties
				this._VoorbeeldTekstIn = data.VoorbeeldTekstIn;
				this._Doorvoeren = data.Doorvoeren;
				this._ToegestaneChars = data.ToegestaneChars;
				this._Aantal = data.Aantal;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IGeneratePasswordsRequestModel {
			return {
				VoorbeeldTekstIn: this.VoorbeeldTekstIn,
				Doorvoeren: this.Doorvoeren,
				ToegestaneChars: this.ToegestaneChars,
				Aantal: this.Aantal
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Project.AccountGenereerWachtwoordModelAdres
	 */
	 

	export interface IAccountGenereerWachtwoordModelAdres {
		WoningId: number;
		PersoonId: number;
		EmailAdres: string;
		Adres: string;
		Nummer: string;
		HoofdbewonerUsername: string;
		Wachtwoord: string;
	}

	export class AccountGenereerWachtwoordModelAdres {
		// property-Properties
		public get WoningId(): number {
			return this._WoningId;
		}
		public set WoningId(newWoningId: number) {
			this._WoningId = newWoningId;
		}

		public get PersoonId(): number {
			return this._PersoonId;
		}
		public set PersoonId(newPersoonId: number) {
			this._PersoonId = newPersoonId;
		}

		public get EmailAdres(): string {
			return this._EmailAdres;
		}
		public set EmailAdres(newEmailAdres: string) {
			this._EmailAdres = newEmailAdres;
		}

		public get Adres(): string {
			return this._Adres;
		}
		public set Adres(newAdres: string) {
			this._Adres = newAdres;
		}

		public get Nummer(): string {
			return this._Nummer;
		}
		public set Nummer(newNummer: string) {
			this._Nummer = newNummer;
		}

		public get HoofdbewonerUsername(): string {
			return this._HoofdbewonerUsername;
		}
		public set HoofdbewonerUsername(newHoofdbewonerUsername: string) {
			this._HoofdbewonerUsername = newHoofdbewonerUsername;
		}

		public get Wachtwoord(): string {
			return this._Wachtwoord;
		}
		public set Wachtwoord(newWachtwoord: string) {
			this._Wachtwoord = newWachtwoord;
		}

		// field-Properties

		// fields
		protected _WoningId: number = 0;
		protected _PersoonId: number = 0;
		protected _EmailAdres: string = "";
		protected _Adres: string = "";
		protected _Nummer: string = "";
		protected _HoofdbewonerUsername: string = "";
		protected _Wachtwoord: string = "";

		constructor(data?: DataContracts.IAccountGenereerWachtwoordModelAdres) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAccountGenereerWachtwoordModelAdres): void {
			if(data) {
				// vul properties
				this._WoningId = data.WoningId;
				this._PersoonId = data.PersoonId;
				this._EmailAdres = data.EmailAdres;
				this._Adres = data.Adres;
				this._Nummer = data.Nummer;
				this._HoofdbewonerUsername = data.HoofdbewonerUsername;
				this._Wachtwoord = data.Wachtwoord;
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Project.GeneratePasswordsResponseModel
	 */
	 

	export interface IGeneratePasswordsResponseModel {
		OpenenGelukt: boolean;
		Adressen: AccountGenereerWachtwoordModelAdres[];
		ModelFile: FileDownload.IFileDownload;
		Path: string;
		TekstUit: string;
	}

	export class GeneratePasswordsResponseModel {
		// property-Properties
		public get OpenenGelukt(): boolean {
			return this._OpenenGelukt;
		}
		public set OpenenGelukt(newOpenenGelukt: boolean) {
			this._OpenenGelukt = newOpenenGelukt;
		}

		public get Adressen(): AccountGenereerWachtwoordModelAdres[] {
			return this._Adressen;
		}
		public set Adressen(newAdressen: AccountGenereerWachtwoordModelAdres[]) {
			this._Adressen = newAdressen;
		}

		public get ModelFile(): FileDownload.IFileDownload {
			return this._ModelFile;
		}
		public set ModelFile(newModelFile: FileDownload.IFileDownload) {
			this._ModelFile = newModelFile;
		}

		public get Path(): string {
			return this._Path;
		}
		public set Path(newPath: string) {
			this._Path = newPath;
		}

		// field-Properties
		public get TekstUit(): string {
			return this._TekstUit;
		}
		public set TekstUit(newTekstUit: string) {
			this._TekstUit = newTekstUit;
		}

		// fields
		protected _OpenenGelukt: boolean = false;
		protected _Adressen: AccountGenereerWachtwoordModelAdres[] = [];
		protected _ModelFile: FileDownload.IFileDownload = new FileDownload.FileDownload(undefined);
		protected _Path: string = "";
		protected _TekstUit: string = "";

		constructor(data?: DataContracts.IGeneratePasswordsResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGeneratePasswordsResponseModel): void {
			if(data) {
				// vul properties
				this._OpenenGelukt = data.OpenenGelukt;
				this._Adressen = data.Adressen ? data.Adressen.map(accountGenereerWachtwoordModelAdresItem => new AccountGenereerWachtwoordModelAdres(accountGenereerWachtwoordModelAdresItem)): [];
				this._ModelFile = data.ModelFile ? new FileDownload.FileDownload(data.ModelFile): undefined;
				this._Path = data.Path;
				// vul fields
				this._TekstUit = data.TekstUit;
			}
		}
	}
