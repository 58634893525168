
<div *ngIf="Laden">
   Laden...
</div>

<div *ngIf="!Laden">
   <div style="float: left; width: 40%; padding: 20px;">

      <mat-card>
         <mat-card-title>{{ProductPropertyViewModel.Name}} Details</mat-card-title>
         <mat-card-content>
            <form #form="ngForm">
               <fieldset [disabled]="update" style="border:none; margin: 12px;">

                  <p>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Bot</mat-label>
                        <input matInput name="productNaam" [(ngModel)]="ProductPropertyOption.Bot" ngModel required min-length="4" #name="ngModel">
                     </mat-form-field>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Kenmerk</mat-label>
                        <input matInput name="productKenmerk" [(ngModel)]="ProductPropertyOption.Kenmerk" ngModel required #kenmerk="ngModel">
                     </mat-form-field>
                  </p>

                  <p>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO1 Type</mat-label>
                        <input matInput name="productD01Type" [(ngModel)]="ProductPropertyOption.DO1Type">
                     </mat-form-field>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Do1 Naam</mat-label>
                        <input matInput name="productD01Naam" [(ngModel)]="ProductPropertyOption.DO1Naam">
                     </mat-form-field>
                  </p>
                  <p>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO2 Type</mat-label>
                        <input matInput name="productD02Type" [(ngModel)]="ProductPropertyOption.DO2Type">
                     </mat-form-field>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO2 Naam</mat-label>
                        <input matInput name="productD02Naam" [(ngModel)]="ProductPropertyOption.DO2Naam">
                     </mat-form-field>
                  </p>
                  <p>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO3 Type</mat-label>
                        <input matInput name="productD03Type" [(ngModel)]="ProductPropertyOption.DO3Type">
                     </mat-form-field>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO3 Naam</mat-label>
                        <input matInput name="productD03Naam" [(ngModel)]="ProductPropertyOption.DO3Naam">
                     </mat-form-field>
                  </p>
                  <p>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO4 Type</mat-label>
                        <input matInput name="productD04Type" [(ngModel)]="ProductPropertyOption.DO4Type">
                     </mat-form-field>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO4 Naam</mat-label>
                        <input matInput name="productD04Naam" [(ngModel)]="ProductPropertyOption.DO4Naam">
                     </mat-form-field>
                  </p>

               </fieldset>
               <div *ngIf="!update">
                  <button mat-raised-button color="primary" (click)="Update()">Update</button>
               </div>
               <div *ngIf="update">
                  <button mat-raised-button color="accent" (click)="update = !update">Wijzig</button>
                  <button mat-raised-button color="warn" (click)="Delete()">Verwijder</button>

               </div>
            </form>
         </mat-card-content>

      </mat-card>


   </div>



   <div style="float: left; width: 50%; padding: 20px;">
      <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
         <mat-tab label="Filters">
            <div style="padding:25px;">
               <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                  <!-- This is the tree node template for leaf nodes -->
                  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                     <!-- use a disabled button to provide padding for tree leaf -->
                     <button mat-icon-button disabled></button>
                     <mat-checkbox class="checklist-leaf-node"
                                   [checked]="node.Active"
                                   [disabled]="node.level == 0"
                                   (change)="filterChange($event.checked, node )">
                        {{node.Name}}
                     </mat-checkbox>

                  </mat-tree-node>
                  <!-- This is the tree node template for expandable nodes -->
                  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>

                     <button mat-icon-button matTreeNodeToggle
                             [attr.aria-label]="'toggle ' + node.Name">
                        <mat-icon class="mat-icon-rtl-mirror">
                           <i class="{{treeControl.isExpanded(node) ? 'fal fa-chevron-down' : 'fal fa-chevron-right'}}"></i>
                        </mat-icon>
                     </button>
                     {{node.Name}}
                  </mat-tree-node>
               </mat-tree>
            </div>
         </mat-tab>

      </mat-tab-group>

   </div>

</div>