/**
 * @ref WoonConnectViewModel/PersonViewModel.cs
 */

 import * as DataContracts from "@datacontracts/PersonViewModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.PersonViewModel
	 */
	 

	export interface IPersonViewModel {
		Id: number;
		AccountName: string;
		FirstName: string;
		MiddleName: string;
		LastName: string;
		EmailAddress: string;
		PhoneNumber: string;
		ToJsonContract(): DataContracts.IPersonViewModel;
	}

	export class PersonViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get AccountName(): string {
			return this._AccountName;
		}
		public set AccountName(newAccountName: string) {
			this._AccountName = newAccountName;
		}

		public get FirstName(): string {
			return this._FirstName;
		}
		public set FirstName(newFirstName: string) {
			this._FirstName = newFirstName;
		}

		public get MiddleName(): string {
			return this._MiddleName;
		}
		public set MiddleName(newMiddleName: string) {
			this._MiddleName = newMiddleName;
		}

		public get LastName(): string {
			return this._LastName;
		}
		public set LastName(newLastName: string) {
			this._LastName = newLastName;
		}

		public get EmailAddress(): string {
			return this._EmailAddress;
		}
		public set EmailAddress(newEmailAddress: string) {
			this._EmailAddress = newEmailAddress;
		}

		public get PhoneNumber(): string {
			return this._PhoneNumber;
		}
		public set PhoneNumber(newPhoneNumber: string) {
			this._PhoneNumber = newPhoneNumber;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _AccountName: string = "";
		protected _FirstName: string = "";
		protected _MiddleName: string = "";
		protected _LastName: string = "";
		protected _EmailAddress: string = "";
		protected _PhoneNumber: string = "";

		constructor(data?: DataContracts.IPersonViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPersonViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._AccountName = data.AccountName;
				this._FirstName = data.FirstName;
				this._MiddleName = data.MiddleName;
				this._LastName = data.LastName;
				this._EmailAddress = data.EmailAddress;
				this._PhoneNumber = data.PhoneNumber;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPersonViewModel {
			return {
				Id: this.Id,
				AccountName: this.AccountName,
				FirstName: this.FirstName,
				MiddleName: this.MiddleName,
				LastName: this.LastName,
				EmailAddress: this.EmailAddress,
				PhoneNumber: this.PhoneNumber
			}
		}
	}
