/**
 * @ref WoonConnectViewModel/ImageAnnotation/GetPhotoSetsModel.cs
 */

 import * as DataContracts from "@datacontracts/GetPhotoSetsModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetPhotoSetsResponseModel
	 */
	 

	export interface IGetPhotoSetsResponseModel {
		PhotoSets: ImageAnnotationPhotoSet[];
	}

	export class GetPhotoSetsResponseModel {
		// property-Properties
		public get PhotoSets(): ImageAnnotationPhotoSet[] {
			return this._PhotoSets;
		}
		public set PhotoSets(newPhotoSets: ImageAnnotationPhotoSet[]) {
			this._PhotoSets = newPhotoSets;
		}

		// field-Properties

		// fields
		protected _PhotoSets: ImageAnnotationPhotoSet[] = [];

		constructor(data?: DataContracts.IGetPhotoSetsResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetPhotoSetsResponseModel): void {
			if(data) {
				// vul properties
				this._PhotoSets = data.PhotoSets ? data.PhotoSets.map(imageAnnotationPhotoSetItem => new ImageAnnotationPhotoSet(imageAnnotationPhotoSetItem)): [];
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.ImageAnnotationPhotoSet
	 */
	 

	export interface IImageAnnotationPhotoSet {
		ID: number;
		Name: string;
		ToJsonContract(): DataContracts.IImageAnnotationPhotoSet;
	}

	export class ImageAnnotationPhotoSet {
		// property-Properties
		public get ID(): number {
			return this._ID;
		}
		public set ID(newID: number) {
			this._ID = newID;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		// field-Properties

		// fields
		protected _ID: number = 0;
		protected _Name: string = "";

		constructor(data?: DataContracts.IImageAnnotationPhotoSet) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IImageAnnotationPhotoSet): void {
			if(data) {
				// vul properties
				this._ID = data.ID;
				this._Name = data.Name;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IImageAnnotationPhotoSet {
			return {
				ID: this.ID,
				Name: this.Name
			}
		}
	}
