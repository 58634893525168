import { NgModule } from '@angular/core'; 
import { CommonModule } from '@angular/common'; 
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { WctBeheerMainComponent } from '../beheer/components/WctBeheerMain';
import { WctBeheerAccountMenu } from '../beheer/components/WctBeheerAccountMenu';
import { WctBeheerCompanyLogoComponent } from '../beheer/components/WctBeheerCompanyLogo';
import { WctBeheerMenuHeaderComponent } from '../beheer/components/WctBeheerMenuHeader';
import { WctBeheerHeaderComponent } from '../beheer/components/WctBeheerHeader';
import { RouterModule, Routes } from '@angular/router';
import { WctDocumentMapComponent } from '../components/documenten/WctDocumentMap';
import { WctWoningenComponent } from '../components/woningen/WctWoningen';
import { DocumentModule } from './documenten.module';
import { menuReducer, menuFeatureKey } from '../beheer/menu/menu.reducer';
import { ImageAnnotationModule } from './imageannotation.module';

const beheerRoutes: Routes = [
    { path: 'documenten', component: WctDocumentMapComponent },
   // { path: 'woningen', component: WctWoningenComponent }
]

const AngularMaterialModules = [
	MatInputModule,
	MatFormFieldModule,
    MatCheckboxModule
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(beheerRoutes),
        DocumentModule,
        AngularMaterialModules,
        StoreModule.forFeature(menuFeatureKey, menuReducer),
        FormsModule,
        ImageAnnotationModule
    ],
    declarations: [
        WctBeheerMainComponent,
        WctBeheerAccountMenu,
        WctBeheerCompanyLogoComponent,
        WctBeheerMenuHeaderComponent,
        WctBeheerHeaderComponent,
        //WctWoningenComponent
    ],
    exports: [
        RouterModule,
        WctBeheerMainComponent,
        WctBeheerAccountMenu,
        WctBeheerCompanyLogoComponent,
        WctBeheerMenuHeaderComponent,
        WctBeheerHeaderComponent,
        //WctWoningenComponent
    ],
    entryComponents: [
        WctBeheerMainComponent
    ],
    providers: []
})

export class BeheerModule {
	constructor() { }
}