overview

<div *ngIf="Laden">
   Laden...
</div>

<div *ngIf="!Laden">
   <table style="width: 100%">
      <tr>
         <td>
            <b>Producten</b>
            <table border="1">
               <tr>
                  <td><b>Id ($)</b></td>
                  <td><b>Naam</b></td>
               </tr>
               <tr *ngFor="let product of Model.Products">
                  <td>{{ product.Id }}</td>
                  <td>{{ product.Name }}</td>
               </tr>
            </table>

         </td>
         <td>
            <b>Filters</b>
            <table border="1">
               <tr>
                  <td colspan="2"><b>Id ($)</b></td>
                  <td><b>Naam</b></td>
               </tr>
               <ng-container *ngFor="let filter of Model.Filters">
                  <tr>
                     <td>{{ filter.Id }}</td>
                     <td></td>
                     <td>{{ filter.Name }}</td>
                  </tr>
                  <tr *ngFor="let filteroption of filter.Options">
                     <td></td>
                     <td>{{ filteroption.Id }}</td>
                     <td>{{ filteroption.Name }}</td>
                  </tr>
               </ng-container>

            </table>
         </td>
      </tr>
   </table>
</div>