import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'wct-main',
  templateUrl: './prijslijst.component.html',
  styleUrls: ['./prijslijst.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PrijslijstComponent {

  constructor() {
    var link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = '/Content/KlantSpecifiek/BCB21111/favicon.ico';
  }

}
