//// [conv] import "(./FabAdminProductProperty).less";
import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AddEigenschapInputModel, ProductPropertyViewModel, ProductPropertyOptionViewModel, AddOptionInputModel } from '@models/ProductsViewModel';
import { FiltersConsumer } from "@consumers/FiltersController";
import { ProductsConsumer } from '@consumers/ProductsController';
import {
  FiltersViewModel,
  FilterOptionViewModel
} from "@models/FilterViewModel";

@Component({
  selector: 'display-product-property',
  templateUrl: './FabAdminDisplayProductProperty.html'
})
export class FabAdminDisplayProductPropertyComponent {

  @Input() public ProductProperty: ProductPropertyViewModel;
  @Input() public ProductVariantId: number;
  @Input() public parentproductname: string = "";
  @Output() public onRefresh = new EventEmitter<any>();


  public ShowFiltersSelectedId: number = -1;
  public FiltersViewModel: FiltersViewModel = new FiltersViewModel();
  public FilterOptions: FilterOptionViewModel[] = new Array<FilterOptionViewModel>();
  public SelectedFilterId: number;
  public SelectedFilterOption: number;
  public NewEigenschapName: string;
  public NewOptionName: string;

  constructor(private route: ActivatedRoute, private filters: FiltersConsumer, private prod: ProductsConsumer) {

  }

  public Refresh(): void {
    this.onRefresh.emit();
  }

  public FilterChanged(): void {
    for (var i = 0; i < this.FiltersViewModel.Filters.length; i++) {
      if (this.FiltersViewModel.Filters[i].Id == this.SelectedFilterId) {
        this.FilterOptions = this.FiltersViewModel.Filters[i].Options;
      }
    }
  }

  public ShowFilters(selectedId: number, option: boolean): void {
    this.SelectedFilterId = selectedId;
    this.ShowFiltersSelectedId = selectedId;
    this.filters.GetFilters(this.FiltersViewModel).then((data) => {
      this.FiltersViewModel = new FiltersViewModel(data);

      if (option) {
        _.forEach(this.ProductProperty.Options, (option) => {
          option.ShowFilters = true;
        });
      }
      else {
        this.ProductProperty.ShowFilters = true;
      }
    });
  }

  public AddEigenschap(propertyoptionId: number): void {
    var input: AddEigenschapInputModel = new AddEigenschapInputModel();
    input.Name = this.NewEigenschapName;
    input.ParentPropertyOptionId = propertyoptionId;
    input.ProductVariantId = this.ProductVariantId;

    this.prod.AddEigenschap_Observable(input).subscribe(() => {
      this.onRefresh.emit();
    });
  }

  public AddOption(): void {
    var input: AddOptionInputModel = new AddOptionInputModel();
    input.Name = this.NewOptionName;
    input.ParentProductPropertyId = this.ProductProperty.Id;
    input.ProductVariantId = this.ProductVariantId;

    this.prod.AddOption_Observable(input).subscribe(() => {
      this.onRefresh.emit();
    });
  }

  public UpdateLiveUpdate(): void {
    this.prod.UpdateLiveUpdate_Observable(this.ProductProperty.Id, this.ProductProperty.LiveUpdate).subscribe(() => {
      this.onRefresh.emit();
    });
  }

  public RemoveEigenschap(): void {
    this.prod.RemoveEigenschap_Observable(this.ProductProperty.Id, this.ProductVariantId).subscribe(() => {
      this.onRefresh.emit();
    });
  }

  public RemoveOption(optionId: number): void {
    this.prod.RemoveOption_Observable(optionId, this.ProductVariantId).subscribe(() => {
      this.onRefresh.emit();
    });
  }

  public Connect(): void {
    this.prod.ConnectProductPropertyToFilter(this.ProductProperty.Id, this.SelectedFilterId).then((data) => {
      this.onRefresh.emit();
    });
  }

  public ConvertToOption(): void {

    this.prod.ConvertProductPropertyToOption_Observable(this.ProductProperty.Id, this.ProductVariantId).subscribe((data) => {
      this.onRefresh.emit();
    });
  }

  public Ignore(): void {
    if (!this.ProductProperty.IgnoreFilter) {
      this.prod.IgnoreProductProperty_Observable(this.ProductProperty.Id).subscribe((data) => {
        this.onRefresh.emit();
      });
    }
  }

  public IgnoreAlways(): void {
    if (!this.ProductProperty.IgnoreFilter) {
      this.prod.IgnoreProductProperty_Observable(this.ProductProperty.Id).subscribe((data) => {
        this.onRefresh.emit();
      });
    }
  }

  public Disconnect(): void {
    if (this.ProductProperty.IgnoreFilter) {
      this.prod.UnignoreProductProperty_Observable(this.ProductProperty.Id).subscribe((data) => {
        this.onRefresh.emit();
      });
    }
    else {
      this.prod.DisconnectProductPropertyToFilter_Observable(this.ProductProperty.Id, this.ProductProperty.Filter.Id).subscribe((data) => {
        this.onRefresh.emit();
      });
    }
  }

  public GetParent(): string {

    if (this.ProductProperty != undefined) {
      return this.ProductProperty.Parent;
    }

    return "";
  }

  public GetParentParent(): string {
    if (this.ProductProperty != undefined) {
      return this.ProductProperty.ParentParent;
    }
    return "";
  }

  public HasParent(): boolean {
    return this.GetParent() != "";
  }

  public HasParentParent(): boolean {
    return this.GetParentParent() != "";
  }

  public ConnectOption(propoption: ProductPropertyOptionViewModel): void {
    this.prod.ConnectProductPropertyOptionToFilterOption_Observable(propoption.Id, this.SelectedFilterOption).subscribe((data) => {
      this.onRefresh.emit();
    });
  }

  public ConnectOptionAlways(propoption: ProductPropertyOptionViewModel): void {
    var retVal = confirm("Weet je zeker dat je deze productproperty ALTIJD wilt connecten?");
    if (retVal == true) {
      this.prod.ConnectProductPropertyOptionToFilterOptionAlways_Observable(propoption.Id, this.SelectedFilterOption).subscribe((data) => {
        this.onRefresh.emit();
      });
    }
  }

  public ConnectOptionAlwaysParent(propoption: ProductPropertyOptionViewModel): void {
    var retVal = confirm("Weet je zeker dat je deze productproperty ALTIJD wilt connecten?");
    if (retVal == true) {
      this.prod.ConnectProductPropertyOptionToFilterOptionAlwaysParent_Observable(propoption.Id, this.SelectedFilterOption).subscribe((data) => {
        this.onRefresh.emit();
      });
    }
  }

  public ConnectOptionAlwaysParentParent(propoption: ProductPropertyOptionViewModel): void {
    var retVal = confirm("Weet je zeker dat je deze productproperty ALTIJD wilt connecten?");
    if (retVal == true) {
      this.prod.ConnectProductPropertyOptionToFilterOptionAlwaysParentParent_Observable(propoption.Id, this.SelectedFilterOption).subscribe((data) => {
        this.onRefresh.emit();
      });
    }
  }

  public SuperConnectBot(propoption: ProductPropertyOptionViewModel): void {
    var retVal = confirm("Weet je zeker dat je deze productproperty wilt super connecten?");
    if (retVal == true) {
      this.prod.SuperConnect_Observable(propoption.Id, this.SelectedFilterId, "Bot").subscribe((data) => {
        this.onRefresh.emit();
      });
    }
  }

  public SuperConnectKenmerk(propoption: ProductPropertyOptionViewModel): void {
    var retVal = confirm("Weet je zeker dat je deze productproperty wilt super connecten?");
    if (retVal == true) {
      this.prod.SuperConnect_Observable(propoption.Id, this.SelectedFilterId, "Kenmerk").subscribe((data) => {
        this.onRefresh.emit();
      });
    }
  }

  public IgnoreOption(propoption: ProductPropertyOptionViewModel): void {
    if (!propoption.IgnoreFilterOption) {
      this.prod.IgnoreProductPropertyOption_Observable(propoption.Id).subscribe((data) => {
        this.onRefresh.emit();
      });
    }
  }

  public IgnoreOptionAlways(propoption: ProductPropertyOptionViewModel): void {
    var retVal = confirm("Weet je zeker dat je deze productproperty ALTIJD wilt ignoren?");
    if (retVal == true) {
      if (!propoption.IgnoreFilterOption) {
        this.prod.IgnoreProductPropertyOptionAlways_Observable(propoption.Id).subscribe((data) => {
          this.onRefresh.emit();
        });
      }
    }
  }

  public DisconnectOption(filteroption: FilterOptionViewModel, propoption: ProductPropertyOptionViewModel): void {
    if (propoption.IgnoreFilterOption) {
      this.prod.UnignoreProductPropertyOption_Observable(propoption.Id).subscribe((data) => {
        this.onRefresh.emit();
      });
    }
    else {
      this.prod.DisconnectProductPropertyOptionToFilterOption_Observable(propoption.Id, filteroption.Id).subscribe((data) => {
        this.onRefresh.emit();
      });
    }
  }

  public Block(): void {
    var retVal = confirm("Weet je zeker dat je deze productproperty wilt blokkeren?");
    if (retVal == true) {
      if (!this.ProductProperty.IgnoreFilter) {
        this.prod.BlockProductProperty_Observable(this.ProductProperty.Id).subscribe((data) => {
          this.onRefresh.emit();
        });
      }
    }
  }

  public BlockAlways(): void {
    var retVal = confirm("Weet je zeker dat je deze productproperty ALTIJD wilt blokkeren?");
    if (retVal == true) {
      if (!this.ProductProperty.IgnoreFilter) {
        this.prod.BlockProductPropertyAlways(this.ProductProperty.Id).then((data) => {
          this.onRefresh.emit();
        });
      }
    }
  }

  public BlockAlwaysSameParent(): void {
    var retVal = confirm("Weet je zeker dat je deze productproperty ALTIJD wilt blokkeren?");
    if (retVal == true) {
      if (!this.ProductProperty.IgnoreFilter) {
        this.prod.BlockProductPropertyAlwaysSameParent(this.ProductProperty.Id).then((data) => {
          this.onRefresh.emit();
        });
      }
    }
  }

  public BlockAlwaysSameParentParent(): void {
    var retVal = confirm("Weet je zeker dat je deze productproperty ALTIJD wilt blokkeren?");
    if (retVal == true) {
      if (!this.ProductProperty.IgnoreFilter) {
        this.prod.BlockProductPropertyAlwaysSameParentParent(this.ProductProperty.Id).then((data) => {
          this.onRefresh.emit();
        });
      }
    }
  }
}
