import * as _ from 'lodash';
import * as ViewModelsWoningTypologieOptie from '.././Models/WoningTypologieOptie';




	export class WoningTypologieDeel {
		AfbeeldNaam: string;
		DeelId: string;
		ReadOnly: boolean;
		Opties: { [optieid: string]: ViewModelsWoningTypologieOptie.WoningTypologieOptie; } = {};
		DefaultOptieId: string;
		Tags: Array<string> = [];

		constructor(data: any) {
			this.AfbeeldNaam = data.AfbeeldNaam;
			this.DeelId = data.DeelId;
			this.ReadOnly = data.ReadOnly;
			this.DefaultOptieId = data.DefaultOptieId;
			_.each(data.Opties, (a: any) => {
				var optie: ViewModelsWoningTypologieOptie.WoningTypologieOptie = new ViewModelsWoningTypologieOptie.WoningTypologieOptie(a);
				this.Opties[optie.OptieId] = optie;
			});
		}
	}
