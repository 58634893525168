// [conv] import "(./FabAdminFilter).less";
import { Component, Input, Output } from '@angular/core';
import * as _ from 'lodash';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FiltersConsumer } from "@consumers/FiltersController";
import { FilterViewModel, FilterTypesViewModel, FilterTypeViewModel } from '@models/FilterViewModel';


@Component({
    selector: 'filter-type',
  templateUrl: './FabAdminFilterType.html',
  styleUrls: ['./FabAdminFilterType.scss']
})
export class FabAdminFilterTypeComponent {
    Laden: boolean = true;
    update: boolean = true;
    FilterViewModel: FilterViewModel;
    FilterTypesViewModel: FilterTypesViewModel;
    newFilterType: FilterTypeViewModel = new FilterTypeViewModel();



    constructor(private route: ActivatedRoute, private prod: FiltersConsumer, private router: Router) {

        this.Refresh();

    }

    public Refresh(): void {
        this.Laden = true;

        this.prod.GetFilterTypes_Observable().subscribe((data) => {
            console.log(data);
            this.FilterTypesViewModel = new FilterTypesViewModel(data);

            this.Laden = false;
        });
    } 

    public editField(filtertype) {
        console.log("double click");
        this.newFilterType = new FilterTypeViewModel(filtertype);
        console.log(this.newFilterType);
        this.update = false;
    }

    public addFilterType() {
        console.log(this.newFilterType);

        this.prod.SaveFilterType_Observable(this.newFilterType).subscribe((data) => {
            console.log("Geslaagd " + data);
            this.Refresh();

            this.newFilterType = new FilterTypeViewModel();
            this.update = true;
        })
    }

    public deleteFilterType(deleteId) {
        console.log(deleteId); 
        this.prod.DeleteFilterType_Observable(deleteId).subscribe((data) => {
            console.log(data);
            this.Refresh();
        })
    }


}
