// [conv] import "(./FabAdminAddProduct).less";
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
import {FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductsConsumer } from '@consumers/ProductsController';
import { ProductsViewModel } from '@models/ProductsViewModel';
import { ProductViewModel } from '@models/ProductsViewModel';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';





@Component({
   selector: 'add-product',
  templateUrl: './FabAdminAddProduct.html',
  styleUrls: ['./FabAdminAddProduct.scss']
})
export class FabAdminAddProductComponent {
    
   newProduct: ProductViewModel = new ProductViewModel();
   @Output() public ProductSaved = new EventEmitter<any>();
   


   constructor(private prod: ProductsConsumer, private route:ActivatedRoute, private router:Router) {
        
   }
  
    
   productToevoegen() {
      this.prod.AddProduct_Observable(this.newProduct).subscribe((data) => {
            console.log(data);
          this.ProductSaved.emit();
          this.router.navigate(['/fab-admin/product/' + data.Id]);
            
    });
        
   }

}
