/**
 * @ref WoonConnectViewModel/Survey/SurveyProperty.cs
 */

 import * as DataContracts from "@datacontracts/SurveyProperty";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.SurveyProperty
	 */
	 

	export interface ISurveyProperty {
		Name: string;
		ObjectID: string;
		StringValue: string;
		NumericValue: number;
		SetByUser: boolean;
		ToJsonContract(): DataContracts.ISurveyProperty;
	}

	export class SurveyProperty {
		// property-Properties
		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get ObjectID(): string {
			return this._ObjectID;
		}
		public set ObjectID(newObjectID: string) {
			this._ObjectID = newObjectID;
		}

		public get StringValue(): string {
			return this._StringValue;
		}
		public set StringValue(newStringValue: string) {
			this._StringValue = newStringValue;
		}

		public get NumericValue(): number {
			return this._NumericValue;
		}
		public set NumericValue(newNumericValue: number) {
			this._NumericValue = newNumericValue;
		}

		public get SetByUser(): boolean {
			return this._SetByUser;
		}
		public set SetByUser(newSetByUser: boolean) {
			this._SetByUser = newSetByUser;
		}

		// field-Properties

		// fields
		protected _Name: string = "";
		protected _ObjectID: string = "";
		protected _StringValue: string = "";
		protected _NumericValue: number = 0;
		protected _SetByUser: boolean = false;

		constructor(data?: DataContracts.ISurveyProperty) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISurveyProperty): void {
			if(data) {
				// vul properties
				this._Name = data.Name;
				this._ObjectID = data.ObjectID;
				this._StringValue = data.StringValue;
				this._NumericValue = data.NumericValue;
				this._SetByUser = data.SetByUser;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISurveyProperty {
			return {
				Name: this.Name,
				ObjectID: this.ObjectID,
				StringValue: this.StringValue,
				NumericValue: this.NumericValue,
				SetByUser: this.SetByUser
			}
		}
	}
