<div [hidden]="folder?.IsRoot" class="mapRegel" [ngClass]="{'selected': selectedFolder()}">
   <div class="cont-mapregel-left">
      <i class="far" [ngClass]="{'fa-chevron-down': isOpen(), 'fa-chevron-right': !isOpen()}" (click)="selectFolder()"></i>
      <div class="cont-map-iconen" (click)="selectFolder()">
         <i class="fal" [ngClass]="{'fa-folder-open': isOpen(), 'fa-folder': !isOpen()}"></i>
      </div>
      <p class="mapNaam" (click)="selectFolder()" [attr.title]="folder?.Naam">{{ getDisplayName() }}</p>
      <!-- <div *ngIf="folder?.Archief" class="cont-archief">
         <p> {{ "DOCUMENTMANAGEMENT_ARCHIVE" | translate }}</p>
      </div> -->
      <div [hidden]="!(selectedFolder() && (documentIsSelected() == false) && (canEdit() || canAddFolder() || isFolderAdmin()))" class="cont-bottomline"></div>
   </div>

   <div class="cont-mapregel-right">
      
      <div [popover]="'Upload bestanden'" [popoverOnHover]="true" popoverPlacement="bottom-left">
         <button (click)="fileInput.click()" *ngIf="selectedFolder() && (documentIsSelected() == false) && canEdit()" class="wct-icon wct-icon-upload-file">
            <input #fileInput type="file" (change)="uploadFiles($event.target.files)" style="display:none;" />
         </button>
      </div> 
      <!-- <div [popover]="'Upload bestanden'" [popoverOnHover]="true" popoverPlacement="bottom-left">
         <input type="file" style="display:none;" *ngIf="selectedFolder() && (documentIsSelected() == false) && canEdit()" (change)="uploadFiles(files)" multiple ngf-multiple="true" ngf-capture="camera" ngf-accept="" accept="" class="wct-icon wct-icon-upload-file" />
      </div> -->
      <div [popover]="'Voeg submap toe'" [popoverOnHover]="true" popoverPlacement="bottom-left">
         <i *ngIf="selectedFolder() && (documentIsSelected() == false) && canAddFolder()" (click)="openPopup(folder.Id, -1)" class="fal fa-folder-plus"></i>
      </div>
      <div [popover]="'Bewerk map'" [popoverOnHover]="true" popoverPlacement="bottom-left">
         <i *ngIf="selectedFolder() && (documentIsSelected() == false) && isFolderAdmin()" (click)="openPopup(folder.ParentId, folder.Id)" class="wct-icon wct-icon-pencil-md"></i>
      </div>
      <div [popover]="'Verwijder map'" [popoverOnHover]="true" popoverPlacement="bottom-left">
         <i *ngIf="selectedFolder() && (documentIsSelected() == false) && isFolderAdmin()" (click)="deleteFolder(folder.ParentId, folder.Id)" class="fal fa-trash-alt"></i>
      </div>
   </div>
</div>

<div [hidden]="!isOpen()" class="mapInhoud">
   <div [hidden]="!showEmptyText()">
      <p class="txt-geen-submappen-en-documenten">Geen submappen en documenten aanwezig</p>
   </div>

   <div class="mappenlijst" *ngFor="let subfolder of getChildFolders()">
      <div class="mappenlijsttemplate" x-data-bind="template:{name: 'MapTemplate'}">
         <wct-document-map [folder]="subfolder" class="documenten-submap" [isFirstSubmap]="(isFirstSubfolder(subfolder) == true)" [openFirstSubmap]="openFirstSubmap" ></wct-document-map>
      </div>
   </div>
   
   <div class="document" [ngClass]="{ 'heeft-submappen': hasSubFolders() }" *ngFor="let doc of sortedDocuments "  (click)="selectDocument()">
      <popover-content #documentPopover>
         <p><span>Grootte: </span><span>{{ ((doc.Grootte / 1024) / 1024) | number}} Mb</span></p><p><span>Uploaddatum: </span><span>{{doc.UploadDate}}</span></p>
      </popover-content>
      <div [popover]="documentPopover" [popoverOnHover]="true" popoverPlacement="bottom-left">
         <wct-document [document]="doc" documentIsSelected="{{ selectedDocument(doc) }}"></wct-document>
      </div>
      <span id="result"></span>
   </div> 
</div>

<wct-button (click)="openPopup(-1, folder.Id)" *ngIf="isFolderAdmin() && folder?.IsRoot" buttonText="Voeg documentmap toe" buttonSize="small" buttonRole="tertiary" class="btn-documentmap-toevoegen"></wct-button>