<!-- <div class='wct-header'>
    <button mat-button [matMenuTriggerFor]="menu">Gebruikers</button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item  [routerLink]="'/admin/users'">Gebruikers</button>
        <button mat-menu-item>Beheerder Toevoegen</button>
    </mat-menu>
    <button mat-button [matMenuTriggerFor]="woning">Woningen</button>
    <mat-menu #woning="matMenu">
        <button mat-menu-item  [routerLink]="'/admin/buildings'">Alle woningen</button>
        <button mat-menu-item>Woning Toevoegen</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="woning">Project</button>
    <mat-menu #Project="matMenu">
        <button mat-menu-item>Projecten</button>
        <button mat-menu-item>Nieuw Project</button>
    </mat-menu>

    <button mat-button>Energie</button>
    <button mat-button>Straten</button>
    <button mat-button>Concepten</button>
    <button mat-button>Wijken</button>
    <button mat-button>Recepten</button>
    <button mat-button>Vragenlijst</button>
    <button mat-button>Statistieken</button>
    <button mat-button>Menu</button>
    <button mat-button>Clusters</button>
    <button mat-button>Admin(nieuw)</button>

</div> -->
<div class="header">
    <p [routerLink]="'/admin/users'" (click)="SetActivePage('users')" [ngClass]="{'is-active': ActivePage == 'users'}" class="is-active">Gebruikers</p>
    <p [routerLink]="'/admin/buildings'" (click)="SetActivePage('buildings')" [ngClass]="{'is-active': ActivePage == 'buildings'}">Woningen</p>
    <p [routerLink]="'/admin/projects'" (click)="SetActivePage('projects')" [ngClass]="{'is-active': ActivePage == 'projects'}">Projecten</p>
    <p [routerLink]="'/admin/streets'" (click)="SetActivePage('streets')" [ngClass]="{'is-active': ActivePage == 'streets'}">Straten</p>
    <p [routerLink]="'/admin/districts'" (click)="SetActivePage('districts')" [ngClass]="{'is-active': ActivePage == 'districts'}">Wijken</p>
    <!-- <p>Recepten</p>
    <p>Vragenlijst</p>
    <p>Statistieken</p>
    <p>Menu</p>
    <p>Clusters</p> -->
    <p [routerLink]="'/admin/project-settings'" (click)="SetActivePage('project-settings')" [ngClass]="{'is-active': ActivePage == 'project-settings'}">Admin(nieuw)</p>
    <p (click)="logoff()">Logoff</p>
</div>

<router-outlet></router-outlet>
