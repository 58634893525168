/**
 * @ref WoonConnectViewModel/User/BuildingViewModels.cs
 */

 import * as DataContracts from "@datacontracts/BuildingViewModels";

 
import * as ProjectsViewModel from "./ProjectsViewModel";
import * as WoningViewModel from "./WoningViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.User.BuildingRetrieveRequestModel
	 */
	 

	export interface IBuildingRetrieveRequestModel {
		UserId: number;
		PageSize: number;
		Page: number;
		sortOrder: string;
		sortDirection: string;
		ToJsonContract(): DataContracts.IBuildingRetrieveRequestModel;
	}

	export class BuildingRetrieveRequestModel {
		// property-Properties
		public get UserId(): number {
			return this._UserId;
		}
		public set UserId(newUserId: number) {
			this._UserId = newUserId;
		}

		public get PageSize(): number {
			return this._PageSize;
		}
		public set PageSize(newPageSize: number) {
			this._PageSize = newPageSize;
		}

		public get Page(): number {
			return this._Page;
		}
		public set Page(newPage: number) {
			this._Page = newPage;
		}

		public get sortOrder(): string {
			return this._sortOrder;
		}
		public set sortOrder(newsortOrder: string) {
			this._sortOrder = newsortOrder;
		}

		public get sortDirection(): string {
			return this._sortDirection;
		}
		public set sortDirection(newsortDirection: string) {
			this._sortDirection = newsortDirection;
		}

		// field-Properties

		// fields
		protected _UserId: number = 0;
		protected _PageSize: number = 0;
		protected _Page: number = 0;
		protected _sortOrder: string = "";
		protected _sortDirection: string = "";

		constructor(data?: DataContracts.IBuildingRetrieveRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingRetrieveRequestModel): void {
			if(data) {
				// vul properties
				this._UserId = data.UserId;
				this._PageSize = data.PageSize;
				this._Page = data.Page;
				this._sortOrder = data.sortOrder;
				this._sortDirection = data.sortDirection;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingRetrieveRequestModel {
			return {
				UserId: this.UserId,
				PageSize: this.PageSize,
				Page: this.Page,
				sortOrder: this.sortOrder,
				sortDirection: this.sortDirection
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.RowCountViewModel
	 */
	 

	export interface IRowCountViewModel {
		Rows: number;
		ToJsonContract(): DataContracts.IRowCountViewModel;
	}

	export class RowCountViewModel {
		// property-Properties
		public get Rows(): number {
			return this._Rows;
		}
		public set Rows(newRows: number) {
			this._Rows = newRows;
		}

		// field-Properties

		// fields
		protected _Rows: number = 0;

		constructor(data?: DataContracts.IRowCountViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IRowCountViewModel): void {
			if(data) {
				// vul properties
				this._Rows = data.Rows;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IRowCountViewModel {
			return {
				Rows: this.Rows
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.AddressesViewModel
	 */
	 

	export interface IAddressesViewModel {
		Addresses: AddressViewModel[];
		AllowAdd: boolean;
		ToJsonContract(): DataContracts.IAddressesViewModel;
	}

	export class AddressesViewModel {
		// property-Properties
		public get Addresses(): AddressViewModel[] {
			return this._Addresses;
		}
		public set Addresses(newAddresses: AddressViewModel[]) {
			this._Addresses = newAddresses;
		}

		public get AllowAdd(): boolean {
			return this._AllowAdd;
		}
		public set AllowAdd(newAllowAdd: boolean) {
			this._AllowAdd = newAllowAdd;
		}

		// field-Properties

		// fields
		protected _Addresses: AddressViewModel[] = [];
		protected _AllowAdd: boolean = false;

		constructor(data?: DataContracts.IAddressesViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddressesViewModel): void {
			if(data) {
				// vul properties
				this._Addresses = data.Addresses ? data.Addresses.map(addressViewModelItem => new AddressViewModel(addressViewModelItem)): [];
				this._AllowAdd = data.AllowAdd;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAddressesViewModel {
			return {
				Addresses: (this.Addresses ? this.Addresses.map(addressViewModelItem => new AddressViewModel(addressViewModelItem).ToJsonContract()): []),
				AllowAdd: this.AllowAdd
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.AddressViewModel
	 */
	 

	export interface IAddressViewModel {
		Id: number;
		Straat: string;
		Nummer: string;
		Postcode: string;
		Plaats: string;
		Land: string;
		Latitude: string;
		Longitude: string;
		ToJsonContract(): DataContracts.IAddressViewModel;
	}

	export class AddressViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Straat(): string {
			return this._Straat;
		}
		public set Straat(newStraat: string) {
			this._Straat = newStraat;
		}

		public get Nummer(): string {
			return this._Nummer;
		}
		public set Nummer(newNummer: string) {
			this._Nummer = newNummer;
		}

		public get Postcode(): string {
			return this._Postcode;
		}
		public set Postcode(newPostcode: string) {
			this._Postcode = newPostcode;
		}

		public get Plaats(): string {
			return this._Plaats;
		}
		public set Plaats(newPlaats: string) {
			this._Plaats = newPlaats;
		}

		public get Land(): string {
			return this._Land;
		}
		public set Land(newLand: string) {
			this._Land = newLand;
		}

		public get Latitude(): string {
			return this._Latitude;
		}
		public set Latitude(newLatitude: string) {
			this._Latitude = newLatitude;
		}

		public get Longitude(): string {
			return this._Longitude;
		}
		public set Longitude(newLongitude: string) {
			this._Longitude = newLongitude;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Straat: string = "";
		protected _Nummer: string = "";
		protected _Postcode: string = "";
		protected _Plaats: string = "";
		protected _Land: string = "";
		protected _Latitude: string = "";
		protected _Longitude: string = "";

		constructor(data?: DataContracts.IAddressViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddressViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Straat = data.Straat;
				this._Nummer = data.Nummer;
				this._Postcode = data.Postcode;
				this._Plaats = data.Plaats;
				this._Land = data.Land;
				this._Latitude = data.Latitude;
				this._Longitude = data.Longitude;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAddressViewModel {
			return {
				Id: this.Id,
				Straat: this.Straat,
				Nummer: this.Nummer,
				Postcode: this.Postcode,
				Plaats: this.Plaats,
				Land: this.Land,
				Latitude: this.Latitude,
				Longitude: this.Longitude
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.GetRequestByIdModel
	 */
	 

	export interface IGetRequestByIdModel {
		Id: number;
		SecondId: number;
		ToJsonContract(): DataContracts.IGetRequestByIdModel;
	}

	export class GetRequestByIdModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get SecondId(): number {
			return this._SecondId;
		}
		public set SecondId(newSecondId: number) {
			this._SecondId = newSecondId;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _SecondId: number = 0;

		constructor(data?: DataContracts.IGetRequestByIdModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetRequestByIdModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._SecondId = data.SecondId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IGetRequestByIdModel {
			return {
				Id: this.Id,
				SecondId: this.SecondId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.BuildingUserLinkRequest
	 */
	 

	export interface IBuildingUserLinkRequest {
		BuilidingId: number;
		UserGuid: string;
		UserId: number;
		Succeed: boolean;
		ToJsonContract(): DataContracts.IBuildingUserLinkRequest;
	}

	export class BuildingUserLinkRequest {
		// property-Properties
		public get BuilidingId(): number {
			return this._BuilidingId;
		}
		public set BuilidingId(newBuilidingId: number) {
			this._BuilidingId = newBuilidingId;
		}

		public get UserGuid(): string {
			return this._UserGuid;
		}
		public set UserGuid(newUserGuid: string) {
			this._UserGuid = newUserGuid;
		}

		public get UserId(): number {
			return this._UserId;
		}
		public set UserId(newUserId: number) {
			this._UserId = newUserId;
		}

		public get Succeed(): boolean {
			return this._Succeed;
		}
		public set Succeed(newSucceed: boolean) {
			this._Succeed = newSucceed;
		}

		// field-Properties

		// fields
		protected _BuilidingId: number = 0;
		protected _UserGuid: string = "";
		protected _UserId: number = 0;
		protected _Succeed: boolean = false;

		constructor(data?: DataContracts.IBuildingUserLinkRequest) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingUserLinkRequest): void {
			if(data) {
				// vul properties
				this._BuilidingId = data.BuilidingId;
				this._UserGuid = data.UserGuid;
				this._UserId = data.UserId;
				this._Succeed = data.Succeed;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingUserLinkRequest {
			return {
				BuilidingId: this.BuilidingId,
				UserGuid: this.UserGuid,
				UserId: this.UserId,
				Succeed: this.Succeed
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.BuildingsViewModel
	 */
	 

	export interface IBuildingsViewModel {
		Buildings: BuildingViewModel[];
		ToJsonContract(): DataContracts.IBuildingsViewModel;
	}

	export class BuildingsViewModel {
		// property-Properties
		public get Buildings(): BuildingViewModel[] {
			return this._Buildings;
		}
		public set Buildings(newBuildings: BuildingViewModel[]) {
			this._Buildings = newBuildings;
		}

		// field-Properties

		// fields
		protected _Buildings: BuildingViewModel[] = [];

		constructor(data?: DataContracts.IBuildingsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingsViewModel): void {
			if(data) {
				// vul properties
				this._Buildings = data.Buildings ? data.Buildings.map(buildingViewModelItem => new BuildingViewModel(buildingViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingsViewModel {
			return {
				Buildings: (this.Buildings ? this.Buildings.map(buildingViewModelItem => new BuildingViewModel(buildingViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.BuildingViewModel
	 */
	 

	export interface IBuildingViewModel {
		Id: number;
		Adres: AddressViewModel;
		Status: number;
		ToJsonContract(): DataContracts.IBuildingViewModel;
	}

	export class BuildingViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Adres(): AddressViewModel {
			return this._Adres;
		}
		public set Adres(newAdres: AddressViewModel) {
			this._Adres = newAdres;
		}

		public get Status(): number {
			return this._Status;
		}
		public set Status(newStatus: number) {
			this._Status = newStatus;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Adres: AddressViewModel = new AddressViewModel(undefined);
		protected _Status: number = 0;

		constructor(data?: DataContracts.IBuildingViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Adres = data.Adres ? new AddressViewModel(data.Adres): undefined;
				this._Status = data.Status;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingViewModel {
			return {
				Id: this.Id,
				Adres: (this.Adres ? new AddressViewModel(this.Adres).ToJsonContract(): this.Adres),
				Status: this.Status
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.BuildingDetailViewModel
	 */
	 

	export interface IBuildingDetailViewModel {
		Id: number;
		Project: ProjectsViewModel.IProjectsViewModel;
		Residents: WoningViewModel.IResidencesViewModel;
		Adres: AddressViewModel;
		Status: number;
		Typelogie: string;
		Eigenschappen: string;
		Kavelbreedte: number;
		KavelPlaatjeLokatieLeft: number;
		KavelPlaatjeLokatieTop: number;
		KavelPlaatjeLokatieBottomLeft: number;
		KavelPlaatjeLokatieBottomTop: number;
		KavelPlaatjeLokatiePath: string;
		KavelGrondplaat: string;
		LocatieX: number;
		LocatieY: number;
		ToJsonContract(): DataContracts.IBuildingDetailViewModel;
	}

	export class BuildingDetailViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Project(): ProjectsViewModel.IProjectsViewModel {
			return this._Project;
		}
		public set Project(newProject: ProjectsViewModel.IProjectsViewModel) {
			this._Project = newProject;
		}

		public get Residents(): WoningViewModel.IResidencesViewModel {
			return this._Residents;
		}
		public set Residents(newResidents: WoningViewModel.IResidencesViewModel) {
			this._Residents = newResidents;
		}

		public get Adres(): AddressViewModel {
			return this._Adres;
		}
		public set Adres(newAdres: AddressViewModel) {
			this._Adres = newAdres;
		}

		public get Status(): number {
			return this._Status;
		}
		public set Status(newStatus: number) {
			this._Status = newStatus;
		}

		public get Typelogie(): string {
			return this._Typelogie;
		}
		public set Typelogie(newTypelogie: string) {
			this._Typelogie = newTypelogie;
		}

		public get Eigenschappen(): string {
			return this._Eigenschappen;
		}
		public set Eigenschappen(newEigenschappen: string) {
			this._Eigenschappen = newEigenschappen;
		}

		public get Kavelbreedte(): number {
			return this._Kavelbreedte;
		}
		public set Kavelbreedte(newKavelbreedte: number) {
			this._Kavelbreedte = newKavelbreedte;
		}

		public get KavelPlaatjeLokatieLeft(): number {
			return this._KavelPlaatjeLokatieLeft;
		}
		public set KavelPlaatjeLokatieLeft(newKavelPlaatjeLokatieLeft: number) {
			this._KavelPlaatjeLokatieLeft = newKavelPlaatjeLokatieLeft;
		}

		public get KavelPlaatjeLokatieTop(): number {
			return this._KavelPlaatjeLokatieTop;
		}
		public set KavelPlaatjeLokatieTop(newKavelPlaatjeLokatieTop: number) {
			this._KavelPlaatjeLokatieTop = newKavelPlaatjeLokatieTop;
		}

		public get KavelPlaatjeLokatieBottomLeft(): number {
			return this._KavelPlaatjeLokatieBottomLeft;
		}
		public set KavelPlaatjeLokatieBottomLeft(newKavelPlaatjeLokatieBottomLeft: number) {
			this._KavelPlaatjeLokatieBottomLeft = newKavelPlaatjeLokatieBottomLeft;
		}

		public get KavelPlaatjeLokatieBottomTop(): number {
			return this._KavelPlaatjeLokatieBottomTop;
		}
		public set KavelPlaatjeLokatieBottomTop(newKavelPlaatjeLokatieBottomTop: number) {
			this._KavelPlaatjeLokatieBottomTop = newKavelPlaatjeLokatieBottomTop;
		}

		public get KavelPlaatjeLokatiePath(): string {
			return this._KavelPlaatjeLokatiePath;
		}
		public set KavelPlaatjeLokatiePath(newKavelPlaatjeLokatiePath: string) {
			this._KavelPlaatjeLokatiePath = newKavelPlaatjeLokatiePath;
		}

		public get KavelGrondplaat(): string {
			return this._KavelGrondplaat;
		}
		public set KavelGrondplaat(newKavelGrondplaat: string) {
			this._KavelGrondplaat = newKavelGrondplaat;
		}

		public get LocatieX(): number {
			return this._LocatieX;
		}
		public set LocatieX(newLocatieX: number) {
			this._LocatieX = newLocatieX;
		}

		public get LocatieY(): number {
			return this._LocatieY;
		}
		public set LocatieY(newLocatieY: number) {
			this._LocatieY = newLocatieY;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Project: ProjectsViewModel.IProjectsViewModel = new ProjectsViewModel.ProjectsViewModel(undefined);
		protected _Residents: WoningViewModel.IResidencesViewModel = new WoningViewModel.ResidencesViewModel(undefined);
		protected _Adres: AddressViewModel = new AddressViewModel(undefined);
		protected _Status: number = 0;
		protected _Typelogie: string = "";
		protected _Eigenschappen: string = "";
		protected _Kavelbreedte: number = 0;
		protected _KavelPlaatjeLokatieLeft: number = 0;
		protected _KavelPlaatjeLokatieTop: number = 0;
		protected _KavelPlaatjeLokatieBottomLeft: number = 0;
		protected _KavelPlaatjeLokatieBottomTop: number = 0;
		protected _KavelPlaatjeLokatiePath: string = "";
		protected _KavelGrondplaat: string = "";
		protected _LocatieX: number = 0;
		protected _LocatieY: number = 0;

		constructor(data?: DataContracts.IBuildingDetailViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingDetailViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Project = data.Project ? new ProjectsViewModel.ProjectsViewModel(data.Project): undefined;
				this._Residents = data.Residents ? new WoningViewModel.ResidencesViewModel(data.Residents): undefined;
				this._Adres = data.Adres ? new AddressViewModel(data.Adres): undefined;
				this._Status = data.Status;
				this._Typelogie = data.Typelogie;
				this._Eigenschappen = data.Eigenschappen;
				this._Kavelbreedte = data.Kavelbreedte;
				this._KavelPlaatjeLokatieLeft = data.KavelPlaatjeLokatieLeft;
				this._KavelPlaatjeLokatieTop = data.KavelPlaatjeLokatieTop;
				this._KavelPlaatjeLokatieBottomLeft = data.KavelPlaatjeLokatieBottomLeft;
				this._KavelPlaatjeLokatieBottomTop = data.KavelPlaatjeLokatieBottomTop;
				this._KavelPlaatjeLokatiePath = data.KavelPlaatjeLokatiePath;
				this._KavelGrondplaat = data.KavelGrondplaat;
				this._LocatieX = data.LocatieX;
				this._LocatieY = data.LocatieY;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingDetailViewModel {
			return {
				Id: this.Id,
				Project: (this.Project ? new ProjectsViewModel.ProjectsViewModel(this.Project).ToJsonContract(): this.Project),
				Residents: (this.Residents ? new WoningViewModel.ResidencesViewModel(this.Residents).ToJsonContract(): this.Residents),
				Adres: (this.Adres ? new AddressViewModel(this.Adres).ToJsonContract(): this.Adres),
				Status: this.Status,
				Typelogie: this.Typelogie,
				Eigenschappen: this.Eigenschappen,
				Kavelbreedte: this.Kavelbreedte,
				KavelPlaatjeLokatieLeft: this.KavelPlaatjeLokatieLeft,
				KavelPlaatjeLokatieTop: this.KavelPlaatjeLokatieTop,
				KavelPlaatjeLokatieBottomLeft: this.KavelPlaatjeLokatieBottomLeft,
				KavelPlaatjeLokatieBottomTop: this.KavelPlaatjeLokatieBottomTop,
				KavelPlaatjeLokatiePath: this.KavelPlaatjeLokatiePath,
				KavelGrondplaat: this.KavelGrondplaat,
				LocatieX: this.LocatieX,
				LocatieY: this.LocatieY
			}
		}
	}
