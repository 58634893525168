import * as _ from 'lodash';
import * as HelpersMasterTypescript from '.././Helpers/MasterTypescript';
import * as ViewModelsMenuItem from '.././Models/MenuItem';
///<reference path="../../app/models/menuitem.ts" />

//declare function VerbergMenuAccordionOverlay(metWachten: boolean): void;

export class MenuViewModel {
	Callback: (item: ViewModelsMenuItem.MenuItem) => void;
	MenuItems: Array<ViewModelsMenuItem.MenuItem> = [];
	Url: string = window.location.pathname;
	DisplayDirection: string = "";

	constructor() {
		this.Callback = null;
		//this.GeefMenus();
	}

	ForceerLink(): void {
		this.Url = window.location.href;
	}

	ForceerPathName(url: string): void {
		this.Url = url;
	}

	OnMenuWissel(callback: (item: ViewModelsMenuItem.MenuItem) => void): void {
		this.Callback = callback;
	}

	//GeefActiefMenu(): string {
	//	var items = _.map(this.MenuItems, (i: ViewModelsMenuItem.ViewModelsMenuItem.MenuItem) => i.GeefActiefMenuItem());
	//	var filtered = _.filter(items, (i: ViewModelsMenuItem.ViewModelsMenuItem.MenuItem) => i != null);
	//	if (filtered.length == 0) {
	//		return "";
	//	}
	//	else {
	//		return filtered[0].RedirectTarget;
	//	}
	//}

	KiesMenu(targetNaam: string, supressApply: any): void {
		var items: ViewModelsMenuItem.MenuItem[] = _.filter(this.MenuItems, (i: ViewModelsMenuItem.MenuItem) => i.RedirectTarget === targetNaam);
		if (items.length > 0) {
			items[0].ClickMenu(null, null, supressApply);
		}
	}

	VerwerkMenu(data: any): Array<ViewModelsMenuItem.MenuItem> {
		var GereedDoorBewoner: boolean = false;
		if (!HelpersMasterTypescript.MasterControllerInstance.HeeftRol("huurder")) {
			GereedDoorBewoner = true;
		}

		$.ajax({
			type: "GET",
			url: "/Semantiek/GeefGereedDoorBewoner",
			contentType: "application/json; charset=utf-8",
			dataType: "json",

			success: (data: any): any => {
				if (data.Afgemeld) {
					GereedDoorBewoner = true;
					_.each(this.MenuItems, (item: ViewModelsMenuItem.MenuItem) => { item.isClickable = true; });
				}
			}
		});
		var mappedMenuItems: ViewModelsMenuItem.MenuItem[] = _.map(data, (item: any) => { return new ViewModelsMenuItem.MenuItem(item, GereedDoorBewoner, null); });
		this.MenuItems = mappedMenuItems;

		//HelpersMasterTypescript.MasterControllerInstance.AngularBroadcast("MenuVerwerkt", "", true);

		return this.MenuItems;
	}
}

export var MenuViewModelInstance: MenuViewModel = new MenuViewModel();

