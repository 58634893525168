<div *ngIf="Laden" class="mat-progress-spinner__cont" style="display: grid; width: 100%;">
 
      <table style="height: 100%; width: 100%;">
        <tr>
          <td style="text-align: center;">
            <table style="display: inline-block;">
              <tr>
                <td style="text-align: center;"><mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner></td>
              </tr>
              <tr>
                <td><p>Het productmodel wordt geladen</p></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

</div>

<div *ngIf="!ErrorBijLaden && !Laden" class="fab-product">
  <h1 class="fab-product__title">
    {{ BCBProductModel.InstantieNaam }}
  </h1>
  

  <div class="fab-product__viewer" [ngClass]="{'fab-product__viewer--bestek': BCBProductModel.ViewSelected == 'Bestek', 'fab-product__viewer--suf': BCBProductModel.ViewSelected == 'SUF'}" [style.height.px]="GetMaximimumHeight()">
    <div class="fab-product__viewer-left-total">
      <div class="fab-product__viewer-buttons" [ngClass]="{'fab-product__viewer-buttons--heeft-cad-abstract': BCBProductModel.HeeftCADAbstract == true}">
        <div *ngIf="BCBProductModel.CAD != undefined && BCBProductModel.CAD.length > 0" (click)="SetViewSelected('3D')" class="fab-product__viewer-button" [ngClass]="{'fab-product__viewer-button--is-active': BCBProductModel.ViewSelected == '3D'}">
          <p>3D</p>
        </div>
        <div *ngIf="BCBProductModel.SVG != undefined && BCBProductModel.SVG != ''" (click)="SetViewSelected('2D')" class="fab-product__viewer-button" [ngClass]="{'fab-product__viewer-button--is-active': BCBProductModel.ViewSelected == '2D'}">
          <p>2D</p>
        </div>
        <div (click)="SetViewSelected('Informatie')" class="fab-product__viewer-button" [ngClass]="{'fab-product__viewer-button--is-active': BCBProductModel.ViewSelected == 'Informatie'}">
          <p>Informatie</p>
        </div>
        <div (click)="SetViewSelected('Bestek')" class="fab-product__viewer-button" [ngClass]="{'fab-product__viewer-button--is-active': BCBProductModel.ViewSelected == 'Bestek'}">
          <p>Bestek</p>
        </div>
      </div>
      <div class="fab-product__viewer-left" [ngClass]="{'fab-product__viewer-left--full-width': BCBProductModel.HeeftCADAbstract == true}" #fabProductViewerLeft>
        <div *ngIf="BCBProductModel.ViewSelected == '3D' && !BCBProductModel.HeeftCADAbstract" class="fab-product__viewer-3d">
          <div class="webgl-viewer">
            <div class="cont-model-viewable-area">
              <a class="link-model-pan-top" (click)="PanOmhoog()">
                <!-- <div class="img-arrow"></div> -->
                <i class="fal fa-chevron-up"></i>
              </a>
              <a class="link-model-pan-right" (click)="PanRechts()">
                <i class="fal fa-chevron-right"></i>
              </a>
              <a class="link-model-pan-bottom" (click)="PanBeneden()">
                <i class="fal fa-chevron-down"></i>
              </a>
              <a class="link-model-pan-left" (click)="PanLinks()">
                <i class="fal fa-chevron-left"></i>
              </a>
            </div>
            <wct-webgl-canvas (sessiegeregistreerd)="SessieGeregistreerd($event)" [sessie]="ViewerSessie">
            </wct-webgl-canvas>
          </div>
        </div>
        <div *ngIf="BCBProductModel.ViewSelected == 'Informatie'" style="height: 100%; overflow-y: auto;">
          <div *ngIf="SelectedBCBLinkModel == null" class="informatie-inner" [innerHTML]="GetDescription()"></div>
          <div *ngIf="SelectedBCBLinkModel == null" class="photos-block">
            <div *ngFor="let photo of GetPhotos()" class="photo-block" (click)="SelectedBCBLinkModel = photo;">
              <img src="{{ photo.URL }}" />
              {{ photo.Name }}
            </div>
          </div>
          <div *ngIf="SelectedBCBLinkModel != null" class="popup-photo" (click)="SelectedBCBLinkModel = null;">
            <table style="width: 100%;" cellpadding="10">
              <tr>
                <td>{{ SelectedBCBLinkModel.Name }}</td>
                <td style="text-align:right;"><span style="color: #0078be;">Sluiten</span></td>
              </tr>
              <tr>
                <td colspan="2" style="text-align: center;">
                  <img src="{{ SelectedBCBLinkModel.URL }}" />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf="BCBProductModel.ViewSelected == '2D' && !BCBProductModel.HeeftCADAbstract">
          <object [data]="TrustedSVGUrl" type="image/svg+xml" style="width: 100%;"></object>
        </div>
        <div *ngIf="BCBProductModel.ViewSelected == 'Bestek'" class="fab-product__bestek">
          <p [innerHTML]="BCBProductModel.Bestek" #fabProductBestek></p>
        </div>
        <!-- <div *ngIf="ViewSelected == 'Revit'">
     {{ BCBProductModel.HdhRevit }}
  </div> -->

        <div *ngFor="let BCBProductCADViewModel of BCBProductModel.CAD">
          <div *ngIf="BCBProductModel.ViewSelected == BCBProductCADViewModel.Naam">
            {{ BCBProductCADViewModel.Naam }}<br />
            <img src="{{ BCBProductCADViewModel.Waarde }}" />
          </div>
        </div>
      </div>

    </div>
    <div class="fab-product__viewer-right">
      <div [hidden]="BCBProductModel.ViewSelected == 'Informatie'" class="fab-product__kopieer-bestekteksten">
        <h3>Kopieer bestekteksten</h3>
        <wct-button *ngIf="BCBProductModel.ViewSelected == 'Bestek'" buttonText="Tekst naar klembord" buttonSize="small" buttonIconFa="fal fa-copy" (click)="CopyBestekToClipboard()"></wct-button>
        <wct-button *ngIf="BCBProductModel.ViewSelected == 'Bestek'" buttonText="STABU SUF naar klembord" buttonSize="small" buttonIconFa="fal fa-copy" (click)="CopySUFToClipboard()"></wct-button>
        <div class="fab-product__viewer-notification">
          <p *ngIf="ShowNotificationCopiedToClipboard" [@inOutAnimation] (@inOutAnimation.done)="HideNotificationCopiedToClipboard($event)">
            Is gekopieerd naar clipboard
          </p>
        </div>
      </div>
      <div>
        <h3>Links</h3>
        <wct-button *ngIf="BCBProductModel.BCBWUIDLink != ''" buttonSize="small" buttonIconWct="wct-icon wct-icon-bouwconnect-circled" (click)="OpenBCB(BCBProductModel.BCBWUIDLink)">BCB link<i class="fal fa-info-circle" matTooltip="link naar dit product op de BouwConnect Bibliotheek online" matTooltipPosition="below" style="margin-left: 5px;"></i></wct-button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="ErrorBijLaden">
  Error bij laden van product...
</div>
