import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable,of,empty } from 'rxjs';
import { map, mergeMap, switchMap, catchError, withLatestFrom, filter } from 'rxjs/operators';
import * as GridActions from './survey.actions';
import * as DwellingActions from '@services/dwellingstore/dwelling.actions';
import { SurveyState } from './survey.state';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { DwellingState } from '@services/dwellingstore/dwelling.state';
import { SurveyConsumer } from '@consumers/SurveyController';
import { GetSurveyRequestModel } from '@models/GetSurveyModel';
import { GlobalGetDwellingScope } from '@services/WoningEventService';
import { SetSurveyRequestModel } from '@models/SetSurveyModel';
import { DwellingSurvey } from '@models/DwellingSurvey';
import { ErrorService } from '../../../configurator/ErrorService';
import { TestSurveyRequestModel } from 'src/app/data/woonconnectkern/models/TestSurveyModel';
import { changeScenarioAndDwellingSuccess } from '@services/dwellingstore/dwelling.actions';

@Injectable()
export class SurveyEffects { //  implements OnInitEffects
    error(str: string, err: any): Observable<never> {
        this.errorservice.BackendError(str, err);
        return EMPTY;
    }

   loadSurvey = createEffect(() => this.actions$.pipe(
        ofType(GridActions.requestLoadSurvey, changeScenarioAndDwellingSuccess),
        withLatestFrom(this.dwelling, (action, state) => ({ action: action, dwelling: state.dwelling })),
        switchMap((state) => {
            let req = new GetSurveyRequestModel();
		    req.Dwelling = GlobalGetDwellingScope(state.dwelling).Model;
            return this.consumer.GetSurvey_Observable(req);
        }), map((x) => GridActions.surveyLoaded({ survey: x.DwellingSurvey }))));

        saveSurvey = createEffect(() => this.actions$.pipe(
            ofType(GridActions.requestSave),
            withLatestFrom(this.dwelling, this.store, (action, state, store) => ({ action: action, dwelling: state.dwelling, survey: store.survey })),
            switchMap((state) => {
                let req = new SetSurveyRequestModel();
                req.Dwelling = GlobalGetDwellingScope(state.dwelling).Model;
                req.DwellingSurvey = new DwellingSurvey(state.survey.Survey.value);
                return this.consumer.SetSurvey_Observable(req);
            }), 
            catchError(err => this.error("saveSurvey", err)),
            map((x) => GridActions.surveySaved())));     
            
            testSurvey = createEffect(() => this.actions$.pipe(
                ofType(GridActions.requestTest),
                withLatestFrom(this.dwelling, this.store, (action, state, store) => ({ action: action, dwelling: state.dwelling, survey: store.survey })),
                switchMap((state) => {
                    let req = new TestSurveyRequestModel();
                    req.Dwelling = GlobalGetDwellingScope(state.dwelling).Model;
                    return this.consumer.TestSurvey_Observable(req);
                }), 
                catchError(err => this.error("testSurvey", err)),
                map((x) => GridActions.surveyTested())));      

    constructor(
        private store: Store<{ survey: SurveyState }>,
        private consumer: SurveyConsumer,
        private errorservice: ErrorService,
        private dwelling: Store<{ dwelling: DwellingState  }>,
        //private woningeventservice: WoningEventService,
        private actions$: Actions,
    ) { }

    // ngrxOnInitEffects(): Action {
    //     return GridActions.requestLoadSurvey;
    // }
}