/**
 * @ref WoonConnectViewModel/Documenten/RechtenRegelModel.cs
 */

 import * as DataContracts from "@datacontracts/RechtenRegelModel";

 
import * as DocumentGebruikerModel from "./DocumentGebruikerModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Documenten.RechtenRegelModel
	 */
	 

	export interface IRechtenRegelModel {
		TypeRechten: number[];
		Gebruiker: DocumentGebruikerModel.IDocumentGebruikerModel;
		Inherit: boolean;
		ToJsonContract(): DataContracts.IRechtenRegelModel;
	}

	export class RechtenRegelModel {
		// property-Properties
		public get TypeRechten(): number[] {
			return this._TypeRechten;
		}
		public set TypeRechten(newTypeRechten: number[]) {
			this._TypeRechten = newTypeRechten;
		}

		public get Gebruiker(): DocumentGebruikerModel.IDocumentGebruikerModel {
			return this._Gebruiker;
		}
		public set Gebruiker(newGebruiker: DocumentGebruikerModel.IDocumentGebruikerModel) {
			this._Gebruiker = newGebruiker;
		}

		public get Inherit(): boolean {
			return this._Inherit;
		}
		public set Inherit(newInherit: boolean) {
			this._Inherit = newInherit;
		}

		// field-Properties

		// fields
		protected _TypeRechten: number[] = [];
		protected _Gebruiker: DocumentGebruikerModel.IDocumentGebruikerModel = new DocumentGebruikerModel.DocumentGebruikerModel(undefined);
		protected _Inherit: boolean = false;

		constructor(data?: DataContracts.IRechtenRegelModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IRechtenRegelModel): void {
			if(data) {
				// vul properties
				this._TypeRechten = data.TypeRechten;
				this._Gebruiker = data.Gebruiker ? new DocumentGebruikerModel.DocumentGebruikerModel(data.Gebruiker): undefined;
				this._Inherit = data.Inherit;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IRechtenRegelModel {
			return {
				TypeRechten: this.TypeRechten,
				Gebruiker: (this.Gebruiker ? new DocumentGebruikerModel.DocumentGebruikerModel(this.Gebruiker).ToJsonContract(): this.Gebruiker),
				Inherit: this.Inherit
			}
		}
	}
