<wct-bezig [bezigmodel]="BezigViewModel">
	<p class= "txt-subtitle">Rechten</p>
	<div class="cont-line cont-line-header cont-line-9-columns">
		<div class= "cont-field"><p>Type</p></div>
		<div class= "cont-field"><p>Naam</p></div>
		<div class= "cont-field"><p>Toegang</p></div>
		<div class= "cont-field"><p>Lezen</p></div>
		<div class= "cont-field"><p>Toevoegen</p></div>
		<div class= "cont-field"><p>Vervangen</p></div>
		<div class= "cont-field"><p>Verwijderen</p></div>
		<div class= "cont-field"><p>Mapbeheer</p></div>
		<div class= "cont-field cont-field-txt-icon">
			<p>Overerven</p>
			<div class="cont-icon" [popover]="'Hiermee geeft u aan of u onderliggende mappen en bestanden automatisch de eigenschappen erven van deze map.'" [popoverOnHover]="true" popoverPlacement="bottom">
				<p>i</p>
			</div>
		</div>
	</div>
	
	<div class="cont-line cont-line-9-columns" *ngFor="let rechtenregel of RechtenRegels; trackBy: trackByFunction">
		<div class= "cont-field">
			<select [(ngModel)]="rechtenregel.RechtenRegel.Gebruiker.Type">
				<option>Persoon</option>
				<option>Woning</option>
				<option>Wijk</option>
				<option>Rol</option>
			</select>
		</div>
		<div class="cont-field">
			<select [(ngModel)]= "rechtenregel.RechtenRegel.Gebruiker.PersoonId" *ngIf="rechtenregel.RechtenRegel.Gebruiker.Type == 'Persoon'">
				<option value="{{ persoon.PersoonId }}" *ngFor="let persoon of Personen">{{ persoon.Inlognaam }}</option>
			</select>
			<select [(ngModel)]="rechtenregel.RechtenRegel.Gebruiker.WoningId" *ngIf="rechtenregel.RechtenRegel.Gebruiker.Type == 'Woning'">
				<option value= "{{ woning.WoningID }}" *ngFor="let woning of Woningen">{{ woning.AdresTekst() }}</option>
			</select>
			<select [(ngModel)]="rechtenregel.RechtenRegel.Gebruiker.Rol" *ngIf="rechtenregel.RechtenRegel.Gebruiker.Type == 'Rol'">
				<option>Beheerder</option>
				<option>Bewoner</option>
				<option>Eigenaar</option>
				<option>Huurder</option>
				<option>Contractor</option>
			</select>
			<select [(ngModel)]="rechtenregel.RechtenRegel.Gebruiker.WijkId" *ngIf="rechtenregel.RechtenRegel.Gebruiker.Type == 'Wijk'">
				<option value="{{ wijk.ID }}" *ngFor="let wijk of Wijken">{{ wijk.Naam }}</option>
			</select>
		</div>
		<div class="cont-field"><mat-checkbox [(ngModel)]="rechtenregel.IsActief[0]" (ngModelChange)="UpdateRechtenregels()" aria-label="Toegang"></mat-checkbox></div>
		<div class="cont-field"><mat-checkbox [(ngModel)]="rechtenregel.IsActief[1]" (ngModelChange)="UpdateRechtenregels()" aria-label="Lezen"></mat-checkbox></div>
		<div class="cont-field"><mat-checkbox [(ngModel)]="rechtenregel.IsActief[2]" (ngModelChange)="UpdateRechtenregels()" aria-label="Toevoegen"></mat-checkbox></div>
		<div class="cont-field"><mat-checkbox [(ngModel)]="rechtenregel.IsActief[3]" (ngModelChange)="UpdateRechtenregels()" aria-label="Vervangen"></mat-checkbox></div>
		<div class="cont-field"><mat-checkbox [(ngModel)]="rechtenregel.IsActief[4]" (ngModelChange)="UpdateRechtenregels()" aria-label="Verwijderen"></mat-checkbox></div>
		<div class="cont-field"><mat-checkbox [(ngModel)]="rechtenregel.IsActief[5]" (ngModelChange)="UpdateRechtenregels()" aria-label="Mapbeheer"></mat-checkbox></div>
		<div class="cont-field"><mat-checkbox [(ngModel)]="rechtenregel.RechtenRegel.Inherit" (ngModelChange)="UpdateRechtenregels()" aria-label="Overnemen"></mat-checkbox></div>
	</div>
	<div class="cont-line cont-line-add-row">
		<wct-button buttonRole="tertiary" buttonSize="small" buttonText="Voeg persoon, woning, wijk of rol toe" (click)="AddNewRow()"></wct-button>
	</div>
	<div *ngIf="InheritedRechtenRegels.length > 0" class="cont-inherited-rechten-regels">
		<p class="txt-subtitle">Overgenomen rechten van bovenliggende documentmap</p>
		<div class="cont-line cont-line-header cont-line-9-columns">
			<div class="cont-field"><p>Type</p></div>
			<div class="cont-field"><p>Naam</p></div>
			<div class="cont-field"><p>Toegang</p></div>
			<div class="cont-field"><p>Lezen</p></div>
			<div class="cont-field"><p>Toevoegen</p></div>
			<div class="cont-field"><p>Vervangen</p></div>
			<div class="cont-field"><p>Verwijderen</p></div>
			<div class="cont-field"><p>Mapbeheer</p></div>
		</div>

		<div class="cont-line cont-line-9-columns" *ngFor="let inheritedRechtenregel of InheritedRechtenRegels">
			<div class="cont-field"><p>{{ inheritedRechtenregel.InheritedRechtenRegel.Gebruiker.Type }}</p></div>
			<div class="cont-field">
				<p *ngIf="inheritedRechtenregel.InheritedRechtenRegel.Gebruiker.Type == 'Persoon'">{{ GeefNaamPersoon(inheritedRechtenregel.InheritedRechtenRegel.Gebruiker.PersoonId) }}</p>
				<p *ngIf="inheritedRechtenregel.InheritedRechtenRegel.Gebruiker.Type == 'Woning'">{{ GeefNaamWoning(inheritedRechtenregel.InheritedRechtenRegel.Gebruiker.WoningId) }}</p>
				<p *ngIf="inheritedRechtenregel.InheritedRechtenRegel.Gebruiker.Type == 'Rol'">{{ inheritedRechtenregel.InheritedRechtenRegel.Gebruiker.Rol }}</p>
       			<p *ngIf="inheritedRechtenregel.InheritedRechtenRegel.Gebruiker.Type == 'Wijk'">{{ inheritedRechtenregel.InheritedRechtenRegel.Gebruiker.WijkId }}</p>
			</div>
			<div class="cont-field"><mat-checkbox [(ngModel)]="inheritedRechtenregel.IsActief[0]" (ngModelChange)="UpdateInheritedRechtenRegels()" aria-label="Toegang"></mat-checkbox></div>
			<div class="cont-field"><mat-checkbox [(ngModel)]="inheritedRechtenregel.IsActief[1]" (ngModelChange)="UpdateInheritedRechtenRegels()" aria-label="Lezen"></mat-checkbox></div>
			<div class="cont-field"><mat-checkbox [(ngModel)]="inheritedRechtenregel.IsActief[2]" (ngModelChange)="UpdateInheritedRechtenRegels()" aria-label="Toevoegen"></mat-checkbox></div>
			<div class="cont-field"><mat-checkbox [(ngModel)]="inheritedRechtenregel.IsActief[3]" (ngModelChange)="UpdateInheritedRechtenRegels()" aria-label="Vervangen"></mat-checkbox></div>
			<div class="cont-field"><mat-checkbox [(ngModel)]="inheritedRechtenregel.IsActief[4]" (ngModelChange)="UpdateInheritedRechtenRegels()" aria-label="Verwijderen"></mat-checkbox></div>
			<div class="cont-field"><mat-checkbox [(ngModel)]="inheritedRechtenregel.IsActief[5]" (ngModelChange)="UpdateInheritedRechtenRegels()" aria-label="Mapbeheer"></mat-checkbox></div>
		</div>
	</div>
</wct-bezig>