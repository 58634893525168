
<h2 class="dialog__title">Kies een productcategorie</h2>
<div class="dialog__options">
  <div *ngFor="let fabfilterdialogoption of data.fabfilterdialogoptions" class="dialog__option" (click)="Close(fabfilterdialogoption)">
    <div class="dialog__option-margin">
      <div class="dialog__image">
        <img src="{{fabfilterdialogoption.Image}}">
      </div>
      <div class="dialog-text" [ngClass]="{ 'dialog-text--title-has-two-lines' : GetTitleHasTwoLines(fabfilterdialogoption)}">
        <h3>{{fabfilterdialogoption.Name}}</h3>
        <p>{{GetDescription(fabfilterdialogoption)}}</p>
      </div>
    </div>
  </div>
</div>
