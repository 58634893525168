import * as _ from 'lodash';
// import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';
// import * as HelpersAngularEvent from '.././Helpers/AngularEvent';
import * as ViewModelsWoningSelectie from '../../Models/WoningSelectie';
import { WijzigScopeEnum }  from '@enums/WijzigScopeEnum';
import { Observable, Subscriber } from 'rxjs';
import * as ViewModelsWoningGroepType from '../../Models/WoningGroepType';
import * as ViewModelsWoningGroep from '../../Models/WoningGroep';
import * as ViewModelsWoning from '../../Models/Woning';

export interface DwellingState {
	DwellingListVersion: number;
	ScenarioId: number;
	DwellingId: number;
	BlockId: number;
	AreaId: number;
	ProjectId: number;
	BewonerId: number;
	ScenarioIsReadOnly: boolean;
	GlobaleScopeSelectie: ViewModelsWoningSelectie.GlobaleScopeSelectie;
	Scope: WijzigScopeEnum;
	GlobaleWoningSelectie: ViewModelsWoningSelectie.WoningSelectie;
	WoningGroepen: ViewModelsWoningGroep.WoningGroep[];
	WoningGroepTypen: ViewModelsWoningGroepType.WoningGroepType[];
	Woningen: { [id: number]: ViewModelsWoning.Woning; }
	WoningenGeladen: boolean
}

export const initialDwellingState: DwellingState =
{
	ScenarioId: -1,
	DwellingId: -1,
	BlockId: -1,
	AreaId: -1,
	ProjectId: -1,
	BewonerId: -1,
	ScenarioIsReadOnly: true,
	GlobaleScopeSelectie: null,
	Scope: WijzigScopeEnum.Blok,
	WoningGroepen: [],
	WoningGroepTypen: [],
	Woningen: {},
	GlobaleWoningSelectie: null,
	DwellingListVersion: 0,
	WoningenGeladen: false,
};

export interface IAppState {
	dwelling: DwellingState;
}
