<div *ngIf="!done" class="row">
    <div class="column1">
        <div class="intro-text">
            Welkom bij de image annotation tool! Aan de rechterkant van dit scherm kun je selecteren welk element je wilt annoteren. 
            Annoteren doe je door de vier hoekpunten van het object aan te klikken, daarna verschijnt er automatisch een lijn door de geselecteerde punten.
            Bestaande lijnen kun je verplaatsen door ze met de muis te slepen, en je kan ze verwijderen door ze aan te klikken en op de 'Delete' toets op het toesenbord te drukken.
            Als er op de afbeelding geen elementen te zien zijn kun je de foto overslaan door op de knop 'Volgende foto' te klikken.
        </div>
        <div style="position: relative;">
            <canvas #boundingBoxLayer id="boundingBoxLayer" style="position: absolute; left: 0; top: 0; z-index: 0;"></canvas>
            
            <div class="row">
            </div>
        </div>
    </div>
    
    <div class="column2">
        <div class="annotation-label">
            Elementen:
        </div>
        
        <div [style.background-color]="selectionColor" class="square"></div>
        
        <mat-radio-group
            class="radio-group"
            [(ngModel)]="selectionMode">
            <mat-radio-button class="radio-button" *ngFor="let selectionMode of selectionModes" [value]="selectionMode" color="primary" (change)="radioChange($event)">
                {{selectionMode.Type}}
            </mat-radio-button>
        </mat-radio-group>
        
    </div>
    <wct-button buttonRole="primary" buttonText="Opslaan" buttonSize="medium" (click)="saveObjects()"></wct-button>
    <wct-button buttonRole="primary" buttonText="Foto overslaan" buttonSize="medium" (click)="nextPhotoClick()"></wct-button>
    
</div>

<div class="intro-text" *ngIf="done">
    Er zijn geen nieuwe afbeeldingen meer om te tonen! Je kunt dit scherm sluiten.
</div>