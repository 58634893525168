<div *ngIf="InloggenNaarInterfaceMetModelActief" class="cont-progress-circular">
	<mat-progress-spinner mode="indeterminate" diameter="75px"></mat-progress-spinner>
	<h3>{{ "SIGNINGIN_ONE-MOMENT-HOUSE-BEING-BUILT" | translate }}</h3>
</div>
<div *ngIf="InloggenNaarInterfaceZonderModelActief" class="cont-progress-circular">
	<mat-progress-spinner mode="indeterminate" diameter="75px"></mat-progress-spinner>
	<h3>{{ "SIGNINGIN_ONE-MOMENT-WOONCONNECT-BEING-BUILT" | translate }}</h3>
</div>
<section *ngIf="!LoginHeeftKleinFormaat()" class="login-cont-section">
	<div class="login-cont-window">
		<form class="login-form" method="post">
			<div class="form-group">
				<input [(ngModel)]="account.LoginNaam" name="username" type="text" class="form-control"  placeholder="{{ username_placeholder }}" tabindex="1" />
				<!-- <i class="glyphicon glyphicon-user"></i> -->
				<i class="fal fa-user-circle"></i>
				<p *ngIf="usernameHulp()" class="txt-nog-invullen">{{ 'SIGNINGIN_ENTER-EMAIL-ADRESS' | translate }}</p>
			</div>

			<div class="form-group">
				<input [(ngModel)]="account.Password" name="password" type="password" autocomplete="on" class="form-control"  placeholder="{{ 'SIGNINGIN_YOUR-PASSWORD' | translate }}" (keydown)="ControleerInlognaamEnWachtwoord($event)" tabindex="2" />
				<!-- <i class="glyphicon glyphicon-lock"></i> -->
				<i class="fal fa-lock"></i>
				<p *ngIf="passwordHulp()" class="txt-nog-invullen">{{ 'SIGNINGIN_FIRST-ENTER-PASSWORD' | translate }}</p>
			</div>

			<div class="cont-aangemeldblijven-wachtwoordvergeten">
				<mat-checkbox [(ngModel)]="Rememberme" name="rememberme" aria-label="Uitlijnen" tabindex="3">
					{{ "SIGNINGIN_KEEP-ME-SIGNED-IN" | translate }}
				</mat-checkbox>
				<p *ngIf="ToonWachtwoordVergeten()" (click)="toonWachtwoordVergetenTekst()">Wachtwoord vergeten?</p>
			</div>

			<div class="cont-btn-link">
				<wct-button buttonText="{{ 'SIGNINGIN_SIGN-IN' | translate }}" buttonSize="small" buttonAlign="center" buttonRole="primary" class="no-margin wct-login-btn-inloggen" (click)="Inloggen()" tabindex="4"></wct-button>
			</div>
		</form>
		<div id="meldingWachtwoordVergeten" class="cont-main-wachtwoord-vergeten" *ngIf="wachtwoordVergetenZichtbaar">
			<p>Weet u uw wachtwoord niet meer? Vraag dan een nieuw wachtwoord aan via <a href="{{geefSupportEmailadresLinkToEmailProgram('wachtwoord vergeten')}}">{{geefSupportEmailadres()}}</a></p>
		</div>
		<div id="meldingSchermIsTeKlein" class="cont-scherm-te-klein" *ngIf="schermIsTeKleinZichtbaar">
			<img [src]="'../../../Content/Images/only-tablet-and-desktop.svg'" class="img-scherm-te-klein" />
			<h3>Ga verder op een groter scherm</h3>
			<p>Uw account is geactiveerd! Bekijk uw digitale huis op een tablet, laptop of pc. De applicatie is nog niet geschikt voor smartphones.</p>
		</div>
	</div>
</section>

<section *ngIf="LoginHeeftKleinFormaat()" class="cont-login-small">
	<form class="form-login">
		<div class="cont-inputs-btn">
			<input [ngClass]="{ inputProblem: usernameHulp }" type="text" name="username" [(ngModel)]="account.LoginNaam" placeholder="{{ username_placeholder }}" />
			<input [ngClass]="{ inputProblem: passwordHulp }" type="password" name="password" autocomplete="on" [(ngModel)]="account.Password" (keydown)="ControleerInlognaamEnWachtwoord($event)" placeholder="Uw wachtwoord" />
			<wct-button buttonText="Log in" buttonSize="extrasmall" buttonAlign="center" (click)="Inloggen()"></wct-button>
		</div>
		<a href="" (click)="toonWachtwoordVergetenTekst()" class="txt-form-login-password-forgotten">Wachtwoord vergeten?</a>
	</form>
</section>
