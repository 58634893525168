<b>product search</b>
<br />
<br />

<div *ngIf="Loading">
   Laden...
</div>

<div *ngIf="!Loading">
   <input type="text" [(ngModel)]="Request.Input" /> <button mat-raised-button color="primary" type="submit" (click)="Search()">zoek</button>
   <br />

   <div *ngIf="Response != undefined">
      <table>
         <tr>
            <td><input type="checkbox" (click)="ToggleAll()" /></td>
            <td><b>Name</b></td>
            <td><b>Bot</b></td>
            <td><b>DO1Naam</b></td>
            <td><b>DO1Type</b></td>
            <td><b>DO2Naam</b></td>
            <td><b>DO2Type</b></td>
            <td><b>DO3Naam</b></td>
            <td><b>DO3Type</b></td>
            <td><b>DO4Naam</b></td>
            <td><b>DO4Type</b></td>
            <td><b>Kenmerk</b></td>
         </tr>
         <ng-container *ngFor="let product of Response.Products">
            <tr>
               <td><input type="checkbox" [(ngModel)]="product.Connected" /></td>
               <td>{{ product.Name }}</td>
               <td>{{ product.Bot }}</td>
               <td>{{ product.DO1Type }}</td>
               <td>{{ product.DO1Naam }}</td>
               <td>{{ product.DO2Type }}</td>
               <td>{{ product.DO2Naam }}</td>
               <td>{{ product.DO3Type }}</td>
               <td>{{ product.DO3Naam }}</td>
               <td>{{ product.DO4Type }}</td>
               <td>{{ product.DO4Naam }}</td>
               <td>{{ product.Kenmerk }}</td>
            </tr>
            <!--<tr *ngFor="let filteroption of filter.Options">
               <td></td>
               <td>{{ filteroption.Id }}</td>
               <td>{{ filteroption.Name }}</td>
            </tr>-->
         </ng-container>
      </table>
   </div>

   <button mat-raised-button color="primary" type="submit" (click)="AddProducts()">Add</button>
</div>
