/**
 * @ref WoonConnectViewModel/Certification/CertificationSigningModel.cs
 */

 import * as DataContracts from "@datacontracts/CertificationSigningModel";

 
import * as WoningSelectie from "./WoningSelectie";



	/**
	 * Originele namespace: WoonConnectViewModel.Certification.CertificationSigningRequestModel
	 */
	 

	export interface ICertificationSigningRequestModel {
		Token: string;
		Dwelling: WoningSelectie.IWoningSelectie;
		ToJsonContract(): DataContracts.ICertificationSigningRequestModel;
	}

	export class CertificationSigningRequestModel {
		// property-Properties
		public get Token(): string {
			return this._Token;
		}
		public set Token(newToken: string) {
			this._Token = newToken;
		}

		public get Dwelling(): WoningSelectie.IWoningSelectie {
			return this._Dwelling;
		}
		public set Dwelling(newDwelling: WoningSelectie.IWoningSelectie) {
			this._Dwelling = newDwelling;
		}

		// field-Properties

		// fields
		protected _Token: string = "";
		protected _Dwelling: WoningSelectie.IWoningSelectie = new WoningSelectie.WoningSelectie(undefined);

		constructor(data?: DataContracts.ICertificationSigningRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICertificationSigningRequestModel): void {
			if(data) {
				// vul properties
				this._Token = data.Token;
				this._Dwelling = data.Dwelling ? new WoningSelectie.WoningSelectie(data.Dwelling): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ICertificationSigningRequestModel {
			return {
				Token: this.Token,
				Dwelling: (this.Dwelling ? new WoningSelectie.WoningSelectie(this.Dwelling).ToJsonContract(): this.Dwelling)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Certification.CertificationSigningResponseModel
	 */
	 

	export interface ICertificationSigningResponseModel {
		StatusCode: string;
		Content: string;
	}

	export class CertificationSigningResponseModel {
		// property-Properties
		// field-Properties
		public get StatusCode(): string {
			return this._StatusCode;
		}
		public set StatusCode(newStatusCode: string) {
			this._StatusCode = newStatusCode;
		}
		
		public get Content(): string {
			return this._Content;
		}
		public set Content(newContent: string) {
			this._Content = newContent;
		}

		// fields
		protected _StatusCode: string = "";
		protected _Content: string = "";

		constructor(data?: DataContracts.ICertificationSigningResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICertificationSigningResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._StatusCode = data.StatusCode;
				this._Content = data.Content;
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Certification.CertificationTokenUrlRequestModel
	 */
	 

	export interface ICertificationTokenUrlRequestModel {
		Inpuy: string;
		ToJsonContract(): DataContracts.ICertificationTokenUrlRequestModel;
	}

	export class CertificationTokenUrlRequestModel {
		// property-Properties
		public get Inpuy(): string {
			return this._Inpuy;
		}
		public set Inpuy(newInpuy: string) {
			this._Inpuy = newInpuy;
		}

		// field-Properties

		// fields
		protected _Inpuy: string = "";

		constructor(data?: DataContracts.ICertificationTokenUrlRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICertificationTokenUrlRequestModel): void {
			if(data) {
				// vul properties
				this._Inpuy = data.Inpuy;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ICertificationTokenUrlRequestModel {
			return {
				Inpuy: this.Inpuy
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Certification.CertificationTokenUrlResponseModel
	 */
	 

	export interface ICertificationTokenUrlResponseModel {
		Url: string;
	}

	export class CertificationTokenUrlResponseModel {
		// property-Properties
		// field-Properties
		public get Url(): string {
			return this._Url;
		}
		public set Url(newUrl: string) {
			this._Url = newUrl;
		}

		// fields
		protected _Url: string = "";

		constructor(data?: DataContracts.ICertificationTokenUrlResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICertificationTokenUrlResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Url = data.Url;
			}
		}
	}
