/**
 * @ref WoonConnectViewModel/SubContractorViewModel.cs
 */

 import * as DataContracts from "@datacontracts/SubContractorViewModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.SubContractorViewModel
	 */
	 

	export interface ISubContractorViewModel {
		Id: number;
		ContractorId: number;
		Name: string;
		VCA: boolean;
		ToJsonContract(): DataContracts.ISubContractorViewModel;
	}

	export class SubContractorViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ContractorId(): number {
			return this._ContractorId;
		}
		public set ContractorId(newContractorId: number) {
			this._ContractorId = newContractorId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get VCA(): boolean {
			return this._VCA;
		}
		public set VCA(newVCA: boolean) {
			this._VCA = newVCA;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ContractorId: number = 0;
		protected _Name: string = "";
		protected _VCA: boolean = false;

		constructor(data?: DataContracts.ISubContractorViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISubContractorViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ContractorId = data.ContractorId;
				this._Name = data.Name;
				this._VCA = data.VCA;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISubContractorViewModel {
			return {
				Id: this.Id,
				ContractorId: this.ContractorId,
				Name: this.Name,
				VCA: this.VCA
			}
		}
	}
