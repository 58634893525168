/**
 * @ref WoonConnectViewModel/AdresViewModel.cs
 */

 import * as DataContracts from "@datacontracts/AdresViewModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.AdresViewModel
	 */
	 

	export interface IAdresViewModel {
		Straat: string;
		Nummer: string;
		Postcode: string;
		Plaats: string;
		Land: string;
		ToJsonContract(): DataContracts.IAdresViewModel;
	}

	export class AdresViewModel {
		// property-Properties
		public get Straat(): string {
			return this._Straat;
		}
		public set Straat(newStraat: string) {
			this._Straat = newStraat;
		}

		public get Nummer(): string {
			return this._Nummer;
		}
		public set Nummer(newNummer: string) {
			this._Nummer = newNummer;
		}

		public get Postcode(): string {
			return this._Postcode;
		}
		public set Postcode(newPostcode: string) {
			this._Postcode = newPostcode;
		}

		public get Plaats(): string {
			return this._Plaats;
		}
		public set Plaats(newPlaats: string) {
			this._Plaats = newPlaats;
		}

		public get Land(): string {
			return this._Land;
		}
		public set Land(newLand: string) {
			this._Land = newLand;
		}

		// field-Properties

		// fields
		protected _Straat: string = "";
		protected _Nummer: string = "";
		protected _Postcode: string = "";
		protected _Plaats: string = "";
		protected _Land: string = "";

		constructor(data?: DataContracts.IAdresViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAdresViewModel): void {
			if(data) {
				// vul properties
				this._Straat = data.Straat;
				this._Nummer = data.Nummer;
				this._Postcode = data.Postcode;
				this._Plaats = data.Plaats;
				this._Land = data.Land;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAdresViewModel {
			return {
				Straat: this.Straat,
				Nummer: this.Nummer,
				Postcode: this.Postcode,
				Plaats: this.Plaats,
				Land: this.Land
			}
		}
	}
