// [conv] import "(./FabAdminFilters).less";
import { Component, Output } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FiltersConsumer } from "@consumers/FiltersController";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import {
    FiltersViewModel,
    FilterOptionsViewModel,
    FilterOptionsVariantRelationsViewModel,
    FilterOptionRelationsViewModel,
    FilterRelationsViewModel,
    ProductPropertyFilterRelationsViewModel,

    FilterViewModel
} from "@models/FilterViewModel";

@Component({
    selector: 'filters',
  templateUrl: './FabAdminFilters.html',
  styleUrls: ['./FabAdminFilters.scss']
})
export class FabAdminFiltersComponent {
   Laden: boolean = true;
   FiltersViewModel: FiltersViewModel;
   @Output() TotalFilters: number;
   @Output() FilterId: number = 0;
   FilterOptionsVariantRelationsViewModel: FilterOptionsVariantRelationsViewModel;
   FilterOptionRelationsViewModel: FilterOptionRelationsViewModel;
   FilterRelationsViewModel: FilterRelationsViewModel;
   ProductPropertyFilterRelationsViewModel: ProductPropertyFilterRelationsViewModel;


   constructor(private route: ActivatedRoute, private prod: FiltersConsumer) {
      this.Refresh();
   }

   public Refresh(): void {
      this.Laden = true;
      if (this.FiltersViewModel == undefined) {
         this.FiltersViewModel = new FiltersViewModel();
      }

      this.prod.GetFilters_Observable(this.FiltersViewModel).subscribe((data) => {
         this.FiltersViewModel = new FiltersViewModel(data);
         this.TotalFilters = this.FiltersViewModel.Filters.length;
         this.Laden = false;
         console.log(this.FiltersViewModel);
      });
   }

   Herlaad() {
      this.Refresh();
   }

   onSelectedAtIndex(index: number) {
      this.selecteerFilter(this.FiltersViewModel.Filters[index]);
	}

   selecteerFilter(filter: FilterViewModel): void {
      this.FilterId = filter.Id;
   }

   resetFilterSelect() {        
      this.FilterId = 0;
      console.log(this.FilterId);
   }

   onArrayChange() {
      //update the position values in the array
      for (var i = 0; i < this.FiltersViewModel.Filters.length; i++) {
         this.FiltersViewModel.Filters[i].Position = i;
      }

      //set loading to true while we send the changes to the server.
      console.log("Saving filter positions...");
      this.Laden = true;
      this.prod.SaveFilters_Observable(this.FiltersViewModel).subscribe((data) => {
         this.FiltersViewModel = new FiltersViewModel(data);
         this.TotalFilters = this.FiltersViewModel.Filters.length;
         this.Laden = false;
         console.log("Saved!");
      }, error => {
         console.error("Something went wrong while saving filters... " + error);
      });
	}
}
