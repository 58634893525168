import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DataLoadMonitorService } from '../configurator/data-load-monitor.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { UpgradeModule } from '@angular/upgrade/static';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AdminGuard } from '../auth/auth.guard';

import { WctMainComponent } from './wct-main-component/WctMain';
import { WctStartComponent } from './start/WctStart';
//import { ConfiguratorModule } from '../configurator/configurator.module';
import { SurveyModule } from '../modules/survey/survey.module';
import { WctSurveyMainComponent } from '../modules/survey/components/wct-survey-main/wct-survey-main.component';
import { WctAfmeldenComponent } from '../modules/survey/components/WctAfmelden';

import { AdminModule } from '../admin/admin.module';
import { WctAdminNg8Component } from '../admin/WctAdminNg8';

//import { FabrikantenModule } from '../fabrikanten/fabrikanten.module';
//import { FabrikantenAdminModule } from '../fabrikanten/fabrikanten-admin.module';
//import { FabFiltersComponent } from '../fabrikanten/generic/bestekservice/FabFilters';
//import { FabAdminProductsComponent } from '../fabrikanten/bestekken/FabAdminProducts';

//import { WctConfiguratorMainComponent } from '../configurator/components/WctConfiguratorMain';

import { WctBeheerMainComponent } from '../beheer/components/WctBeheerMain';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { GetInfraStatus } from '../modules/webpack/sitestatus';

import { SharedModule } from './shared.module';
import { UserService } from '../auth/shared/services/user.service';
import { ConfigService } from '../auth/shared/utils/config.service';
//import { LoginFormComponent } from '../auth/account/login-form/login-form.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../auth/auth-interceptor';

import { TranslateModule } from './translate.module';
import { CoreUiModule } from '../coreui/coreui.module';
import { BeheerModule } from './beheer.module';

import { LoginModule } from '../login/login.module';
import { WctLoginModuleComponent } from '../login/WctLoginModule';
import { WctForgotPasswordComponent } from '../login/components/WctForgotPassword';
import { WctConfirmEmailComponent } from '../login/components/WctConfirmEmail';
import { WctActivatieFormulierComponent } from '../login/components/WctActivatieFormulier';

// import { ConfigureerderGuardNoRedirect } from '../auth/auth.guard';

import { WctWoningenComponent } from '../components/woningen/WctWoningen';
import { DocumentModule } from './documenten.module';
import { WctDocumentMapComponent } from '../components/documenten/WctDocumentMap';
// import { WctPakketKostenResultaatComponent } from '../components/resultaat/WctPakketKostenResultaat';
import { WctImageAnnotationMainComponent } from '../imageannotation/WctImageAnnotationMain';
import { ImageAnnotationModule } from './imageannotation.module';
import { AccountModule } from '../account/account.module';
import { MatInputModule } from '@angular/material/input';
import { extModules } from './build-specifics';

export function debugStore(reducer: ActionReducer<any>): ActionReducer<any> {
	return function (state, action) {
		console.log('ngrx store: pre', state);
		console.log('ngrx store action', action.type, action);
		let post = reducer(state, action);
		console.log('ngrx store: post', state);
		return post;
	};
}

export const metaReducers: MetaReducer<any>[] = [debugStore];

const routes: Routes = [
	//{ path: 'cfg', component: WctConfiguratorMainComponent, loadChildren: () => ConfiguratorModule },
	//{ path: 'fab-filters', component: FabFiltersComponent, loadChildren: () => FabrikantenModule },
	{ path: 'admin', component: WctAdminNg8Component, canActivate: [AdminGuard], loadChildren: () => AdminModule },
	//{ path: 'fabadmin', component: FabAdminProductsComponent, loadChildren: () => FabrikantenAdminModule },
	//{ path: 'main', component: WctMainComponent },
	{ path: 'accountlogin', component: WctLoginModuleComponent, loadChildren: () => LoginModule },
	{ path: 'WachtwoordVergeten/:code', component: WctForgotPasswordComponent, loadChildren: () => LoginModule },
	{ path: 'EmailConfirmed', component: WctConfirmEmailComponent, loadChildren: () => LoginModule },
	{ path: 'activeer', component: WctActivatieFormulierComponent },
	{ path: 'beheer', component: WctBeheerMainComponent, loadChildren: () => BeheerModule },
  { path: 'woningen', component: WctWoningenComponent },
  { path: 'survey', component: WctSurveyMainComponent },
	{ path: 'survey/**', component: WctSurveyMainComponent },
	{ path: 'documenten', component: WctDocumentMapComponent },
	// { path: 'pakketkosten', component: WctPakketKostenResultaatComponent },
	{ path: 'imageannotation', component: WctImageAnnotationMainComponent, loadChildren: () => ImageAnnotationModule },
	{ path: '', component: WctStartComponent, pathMatch: 'full' },
	{ path: '**', component: WctStartComponent }
];

let production = GetInfraStatus().ClientsideProduction;

@NgModule({
	imports: [
		SharedModule.forRoot(),
		HttpClientModule,
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
		StoreModule.forRoot({}, !production ? { metaReducers } : {}),
		EffectsModule.forRoot(),
		FormsModule,

		//!production ? StoreDevtoolsModule.instrument({ maxAge: 5,logOnly: true }) : [],
		// !production ? StoreDevtoolsModule.instrument({ maxAge: 5 }) : [],
		extModules,

		//{ enableTracing: true }, // <-- debugging purposes only
		SurveyModule,
		//ConfiguratorModule,
		//FabrikantenModule,
		AdminModule,
		TranslateModule,
		LoginModule,
		CoreUiModule,
		BeheerModule,
		DocumentModule,
		MatProgressSpinnerModule,
		ImageAnnotationModule,
		AccountModule,

	],
	exports: [
		RouterModule,
	],
	declarations: [
		//LoginFormComponent,
		WctMainComponent,
		// WctPakketKostenResultaatComponent
	],
	entryComponents: [
		//WctMainComponent,
	],
	bootstrap: [
		WctMainComponent,
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		ConfigService,
		UserService,
	]
})

export class MainModule {
}

