

	export class WoningTypologieOptie{
		OptieId: string;
		AfbeeldNaam: string;
		Afbeelding: string;
		PreviewAfbeelding: string;

		constructor(data: any) {
			Object.assign(this, data)
			//ng.extend(this, data);
		}
	}
