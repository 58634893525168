/**
 * @ref WoonConnectViewModel/EfficacyViewModel.cs
 */

 import * as DataContracts from "@datacontracts/EfficacyViewModel";

 
import * as EfficacyViewModelEnum from "@enums/EfficacyViewModel";
import * as MeasureViewModel from "./MeasureViewModel";
import * as HourlyTarifViewModel from "./HourlyTarifViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.EfficaciesRequestModel
	 */
	 

	export interface IEfficaciesRequestModel {
		Temp: string;
		ToJsonContract(): DataContracts.IEfficaciesRequestModel;
	}

	export class EfficaciesRequestModel {
		// property-Properties
		// field-Properties
		public get Temp(): string {
			return this._Temp;
		}
		public set Temp(newTemp: string) {
			this._Temp = newTemp;
		}

		// fields
		protected _Temp: string = "";

		constructor(data?: DataContracts.IEfficaciesRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IEfficaciesRequestModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Temp = data.Temp;
			}
		}

		public ToJsonContract(): DataContracts.IEfficaciesRequestModel {
			return {
				Temp: this.Temp
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.EfficaciesViewModel
	 */
	 

	export interface IEfficaciesViewModel {
		Efficacies: EfficacyViewModel[];
		ToJsonContract(): DataContracts.IEfficaciesViewModel;
	}

	export class EfficaciesViewModel {
		// property-Properties
		public get Efficacies(): EfficacyViewModel[] {
			return this._Efficacies;
		}
		public set Efficacies(newEfficacies: EfficacyViewModel[]) {
			this._Efficacies = newEfficacies;
		}

		// field-Properties

		// fields
		protected _Efficacies: EfficacyViewModel[] = [];

		constructor(data?: DataContracts.IEfficaciesViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IEfficaciesViewModel): void {
			if(data) {
				// vul properties
				this._Efficacies = data.Efficacies ? data.Efficacies.map(efficacyViewModelItem => new EfficacyViewModel(efficacyViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IEfficaciesViewModel {
			return {
				Efficacies: (this.Efficacies ? this.Efficacies.map(efficacyViewModelItem => new EfficacyViewModel(efficacyViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.EfficacyViewModel
	 */
	 

	export interface IEfficacyViewModel {
		Id: number;
		IsAdditionalWork: boolean;
		DisplayName: string;
		IsExpanded: boolean;
		JobDescription: string;
		QuantityType: EfficacyViewModelEnum.QuantityType;
		Measure: MeasureViewModel.IMeasureViewModel;
		HourlyTarif: HourlyTarifViewModel.IHourlyTarifViewModel;
		LabourHoursPerUnit: number;
		MaterialCostPerUnit: number;
		EquipmentCostPerUnit: number;
		SubcontractorCostPerUnit: number;
		TotalPriceOverrulePerUnit: number;
		ToJsonContract(): DataContracts.IEfficacyViewModel;
	}

	export class EfficacyViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get IsAdditionalWork(): boolean {
			return this._IsAdditionalWork;
		}
		public set IsAdditionalWork(newIsAdditionalWork: boolean) {
			this._IsAdditionalWork = newIsAdditionalWork;
		}

		public get DisplayName(): string {
			return this._DisplayName;
		}
		public set DisplayName(newDisplayName: string) {
			this._DisplayName = newDisplayName;
		}

		public get IsExpanded(): boolean {
			return this._IsExpanded;
		}
		public set IsExpanded(newIsExpanded: boolean) {
			this._IsExpanded = newIsExpanded;
		}

		public get JobDescription(): string {
			return this._JobDescription;
		}
		public set JobDescription(newJobDescription: string) {
			this._JobDescription = newJobDescription;
		}

		public get QuantityType(): EfficacyViewModelEnum.QuantityType {
			return this._QuantityType;
		}
		public set QuantityType(newQuantityType: EfficacyViewModelEnum.QuantityType) {
			this._QuantityType = newQuantityType;
		}

		public get Measure(): MeasureViewModel.IMeasureViewModel {
			return this._Measure;
		}
		public set Measure(newMeasure: MeasureViewModel.IMeasureViewModel) {
			this._Measure = newMeasure;
		}

		public get HourlyTarif(): HourlyTarifViewModel.IHourlyTarifViewModel {
			return this._HourlyTarif;
		}
		public set HourlyTarif(newHourlyTarif: HourlyTarifViewModel.IHourlyTarifViewModel) {
			this._HourlyTarif = newHourlyTarif;
		}

		public get LabourHoursPerUnit(): number {
			return this._LabourHoursPerUnit;
		}
		public set LabourHoursPerUnit(newLabourHoursPerUnit: number) {
			this._LabourHoursPerUnit = newLabourHoursPerUnit;
		}

		public get MaterialCostPerUnit(): number {
			return this._MaterialCostPerUnit;
		}
		public set MaterialCostPerUnit(newMaterialCostPerUnit: number) {
			this._MaterialCostPerUnit = newMaterialCostPerUnit;
		}

		public get EquipmentCostPerUnit(): number {
			return this._EquipmentCostPerUnit;
		}
		public set EquipmentCostPerUnit(newEquipmentCostPerUnit: number) {
			this._EquipmentCostPerUnit = newEquipmentCostPerUnit;
		}

		public get SubcontractorCostPerUnit(): number {
			return this._SubcontractorCostPerUnit;
		}
		public set SubcontractorCostPerUnit(newSubcontractorCostPerUnit: number) {
			this._SubcontractorCostPerUnit = newSubcontractorCostPerUnit;
		}

		public get TotalPriceOverrulePerUnit(): number {
			return this._TotalPriceOverrulePerUnit;
		}
		public set TotalPriceOverrulePerUnit(newTotalPriceOverrulePerUnit: number) {
			this._TotalPriceOverrulePerUnit = newTotalPriceOverrulePerUnit;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _IsAdditionalWork: boolean = false;
		protected _DisplayName: string = "";
		protected _IsExpanded: boolean = false;
		protected _JobDescription: string = "";
		protected _QuantityType: EfficacyViewModelEnum.QuantityType = undefined;
		protected _Measure: MeasureViewModel.IMeasureViewModel = new MeasureViewModel.MeasureViewModel(undefined);
		protected _HourlyTarif: HourlyTarifViewModel.IHourlyTarifViewModel = new HourlyTarifViewModel.HourlyTarifViewModel(undefined);
		protected _LabourHoursPerUnit: number = 0;
		protected _MaterialCostPerUnit: number = 0;
		protected _EquipmentCostPerUnit: number = 0;
		protected _SubcontractorCostPerUnit: number = 0;
		protected _TotalPriceOverrulePerUnit: number = 0;

		constructor(data?: DataContracts.IEfficacyViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IEfficacyViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._IsAdditionalWork = data.IsAdditionalWork;
				this._DisplayName = data.DisplayName;
				this._IsExpanded = data.IsExpanded;
				this._JobDescription = data.JobDescription;
				this._QuantityType = data.QuantityType;
				this._Measure = data.Measure ? new MeasureViewModel.MeasureViewModel(data.Measure): undefined;
				this._HourlyTarif = data.HourlyTarif ? new HourlyTarifViewModel.HourlyTarifViewModel(data.HourlyTarif): undefined;
				this._LabourHoursPerUnit = data.LabourHoursPerUnit;
				this._MaterialCostPerUnit = data.MaterialCostPerUnit;
				this._EquipmentCostPerUnit = data.EquipmentCostPerUnit;
				this._SubcontractorCostPerUnit = data.SubcontractorCostPerUnit;
				this._TotalPriceOverrulePerUnit = data.TotalPriceOverrulePerUnit;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IEfficacyViewModel {
			return {
				Id: this.Id,
				IsAdditionalWork: this.IsAdditionalWork,
				DisplayName: this.DisplayName,
				IsExpanded: this.IsExpanded,
				JobDescription: this.JobDescription,
				QuantityType: this.QuantityType,
				Measure: (this.Measure ? new MeasureViewModel.MeasureViewModel(this.Measure).ToJsonContract(): this.Measure),
				HourlyTarif: (this.HourlyTarif ? new HourlyTarifViewModel.HourlyTarifViewModel(this.HourlyTarif).ToJsonContract(): this.HourlyTarif),
				LabourHoursPerUnit: this.LabourHoursPerUnit,
				MaterialCostPerUnit: this.MaterialCostPerUnit,
				EquipmentCostPerUnit: this.EquipmentCostPerUnit,
				SubcontractorCostPerUnit: this.SubcontractorCostPerUnit,
				TotalPriceOverrulePerUnit: this.TotalPriceOverrulePerUnit
			}
		}
	}
