<div *ngIf="Laden" class="mat-progress-spinner__cont" style="display: grid; width: 100%;">
 
      <table style="height: 100%; width: 100%;">
        <tr>
          <td style="text-align: center;">
            <table style="display: inline-block;">
              <tr>
                <td style="text-align: center;"><mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner></td>
              </tr>
              <tr>
                <td><p>Het productmodel wordt geladen</p></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

</div>

<div *ngIf="!ErrorBijLaden && !Laden" class="fab-product">
  <h1 class="fab-product__title">
    {{ BCBProductModel.InstantieNaam }}
  </h1>
  <p class="fab-product__description" [ngClass]="{'fab-product__description--is-empty' : BCBProductModel.Description == null}">
    {{ BCBProductModel.Description }}<br>
    <span *ngIf="ParameterIsShown(BCBProductModel.Inbouwbreedte)">
      Inbouwbreedte: {{ BCBProductModel.Inbouwbreedte }}mm<span *ngIf="ParameterIsShown(BCBProductModel.Inbouwdiepte) || ParameterIsShown(BCBProductModel.Inbouwhoogte)">, </span>
    </span>
    <span *ngIf="ParameterIsShown(BCBProductModel.Inbouwdiepte)">
      Inbouwdiepte: {{ BCBProductModel.Inbouwdiepte }}mm<span *ngIf="ParameterIsShown(BCBProductModel.Inbouwhoogte)">, </span>
    </span>
    <span *ngIf="(BCBProductModel.Inbouwhoogte != null) && (BCBProductModel.Inbouwhoogte != '')">
      Inbouwhoogte: {{ BCBProductModel.Inbouwhoogte }}mm
    </span>
  </p>

  <div class="fab-product__viewer" [ngClass]="{'fab-product__viewer--bestek': BCBProductModel.ViewSelected == 'Bestek', 'fab-product__viewer--suf': BCBProductModel.ViewSelected == 'SUF'}" [style.height.px]="GetMaximimumHeight()">
    <div class="fab-product__viewer-left-total">
      <div class="fab-product__viewer-buttons" [ngClass]="{'fab-product__viewer-buttons--heeft-cad-abstract': BCBProductModel.HeeftCADAbstract == true}">
        <div *ngIf="BCBProductModel.CAD != undefined && BCBProductModel.CAD.length > 0" (click)="SetViewSelected('3D')" class="fab-product__viewer-button" [ngClass]="{'fab-product__viewer-button--is-active': BCBProductModel.ViewSelected == '3D'}">
          <p>3D</p>
        </div>
        <div *ngIf="BCBProductModel.SVG != undefined && BCBProductModel.SVG != ''" (click)="SetViewSelected('2D')" class="fab-product__viewer-button" [ngClass]="{'fab-product__viewer-button--is-active': BCBProductModel.ViewSelected == '2D'}">
          <p>2D</p>
        </div>
        <div (click)="SetViewSelected('Bestek')" class="fab-product__viewer-button" [ngClass]="{'fab-product__viewer-button--is-active': BCBProductModel.ViewSelected == 'Bestek'}">
          <p>Bestek</p>
        </div>
      </div>
      <div class="fab-product__viewer-left" [ngClass]="{'fab-product__viewer-left--full-width': BCBProductModel.HeeftCADAbstract == true}" [style.maxHeight]="GetMaximimumHeightForViewerLeft()" #fabProductViewerLeft>
        <div *ngIf="BCBProductModel.ViewSelected == '3D' && !BCBProductModel.HeeftCADAbstract" class="fab-product__viewer-3d">
          <div class="webgl-viewer">
            <div class="cont-model-viewable-area">
              <a class="link-model-pan-top" (click)="PanOmhoog()">
                <!-- <div class="img-arrow"></div> -->
                <i class="fal fa-chevron-up"></i>
              </a>
              <a class="link-model-pan-right" (click)="PanRechts()">
                <i class="fal fa-chevron-right"></i>
              </a>
              <a class="link-model-pan-bottom" (click)="PanBeneden()">
                <i class="fal fa-chevron-down"></i>
              </a>
              <a class="link-model-pan-left" (click)="PanLinks()">
                <i class="fal fa-chevron-left"></i>
              </a>
            </div>
            <wct-webgl-canvas (sessiegeregistreerd)="SessieGeregistreerd($event)" [sessie]="ViewerSessie">
            </wct-webgl-canvas>
          </div>
        </div>
        <div *ngIf="BCBProductModel.ViewSelected == '2D' && !BCBProductModel.HeeftCADAbstract">
          <object [data]="TrustedSVGUrl" type="image/svg+xml" style="width: 100%;"></object>
        </div>
        <div *ngIf="BCBProductModel.ViewSelected == 'Bestek'" class="fab-product__bestek">
          <p [innerHTML]="BCBProductModel.Bestek"></p>
        </div>
        <!-- <div *ngIf="ViewSelected == 'Revit'">
           {{ BCBProductModel.HdhRevit }}
        </div> -->

        <div *ngFor="let BCBProductCADViewModel of BCBProductModel.CAD">
          <div *ngIf="BCBProductModel.ViewSelected == BCBProductCADViewModel.Naam">
            {{ BCBProductCADViewModel.Naam }}<br />
            <img src="{{ BCBProductCADViewModel.Waarde }}" />
          </div>
        </div>
      </div>

    </div>
    <div class="fab-product__viewer-right">
      <div *ngIf="!BCBProductModel.HeeftCADAbstract" [hidden]="BCBProductModel.ViewSelected == 'Bestek'">
        <h3>Downloads</h3>
        <wct-button buttonDisabled="{{CadLaden == true}}" buttonText="ARKEY/Adomi met ASD (dhg)" buttonSize="small" buttonIconWct="wct-icon wct-icon-download" (click)="LoadCAD()"></wct-button>
        <wct-button buttonDisabled="{{IfcLaden == true}}" buttonText="IFC" buttonSize="small" buttonIconWct="wct-icon wct-icon-download" (click)="LoadCADIFC()"></wct-button>
        <wct-button buttonDisabled="{{Dwg3DLaden == true}}" buttonText="3D AutoCAD Dwg" [hidden]="BCBProductModel.ViewSelected == '2D'" buttonSize="small" buttonIconWct="wct-icon wct-icon-download" (click)="LoadCADDwg3D()"></wct-button>
        <wct-button buttonDisabled="{{Dwg2DLaden == true}}" buttonText="2D AutoCAD Dwg" [hidden]="BCBProductModel.ViewSelected == '3D'" buttonSize="small" buttonIconWct="wct-icon wct-icon-download" (click)="LoadCADDwg2D()"></wct-button>
        <wct-button buttonDisabled="{{Dxf3DLaden == true}}" buttonText="3D Dxf" [hidden]="BCBProductModel.ViewSelected == '2D'" buttonSize="small" buttonIconWct="wct-icon wct-icon-download" (click)="LoadCADDxf3D()"></wct-button>
        <wct-button buttonDisabled="{{Dxf2DLaden == true}}" buttonText="2D Dxf" [hidden]="BCBProductModel.ViewSelected == '3D'" buttonSize="small" buttonIconWct="wct-icon wct-icon-download" (click)="LoadCADDxf2D()"></wct-button>
      </div>
      <div [hidden]="BCBProductModel.ViewSelected == '2D' || BCBProductModel.ViewSelected == '3D'" class="fab-product__kopieer-bestekteksten">
        <h3>Kopieer bestekteksten</h3>
        <wct-button *ngIf="BCBProductModel.ViewSelected == 'Bestek'" buttonText="Tekst naar klembord" buttonSize="small" buttonIconFa="fal fa-copy" (click)="CopyBestekToClipboard()"></wct-button>
        <wct-button *ngIf="BCBProductModel.ViewSelected == 'Bestek'" buttonText="STABU SUF naar klembord" buttonSize="small" buttonIconFa="fal fa-copy" (click)="CopySUFToClipboard()"></wct-button>
        <!-- <p *ngIf="ShowNotificationCopiedToClipboard" [@inOutAnimation] (@inOutAnimation.done)="HideNotificationCopiedToClipboard($event)" class="fab-product__viewer-notification">
           Is gekopieerd naar clipboard
        </p> -->
        <div class="fab-product__viewer-notification">
          <p *ngIf="ShowNotificationCopiedToClipboard" [@inOutAnimation] (@inOutAnimation.done)="HideNotificationCopiedToClipboard($event)">
            Is gekopieerd naar clipboard
          </p>
        </div>
      </div>
      <div>
        <h3>Links</h3>
        <wct-button *ngFor="let link of BCBProductModel.Links" buttonSize="small" buttonIconFa="fal fa-file-pdf" (click)="DownloadProductInformatie(link.URL)">{{ link.Name }}</wct-button>
        <wct-button *ngIf="BCBProductModel.BCBWUIDLink != ''" buttonSize="small" buttonIconWct="wct-icon wct-icon-bouwconnect-circled" (click)="OpenBCB(BCBProductModel.BCBWUIDLink)">BCB link<i class="fal fa-info-circle" matTooltip="link naar dit product op de BouwConnect Bibliotheek online" matTooltipPosition="below" style="margin-left: 5px;"></i></wct-button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="ErrorBijLaden">
  Error bij laden van product...
</div>
