<div *ngIf="showMe">
    <form>
        <p>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="fVariant.Name" [ngModelOptions]="{standalone: true}" ngModel required min-length="4" #name="ngModel">
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Bot</mat-label>
                <input matInput [(ngModel)]="fVariant.Bot" [ngModelOptions]="{standalone: true}" ngModel required #kenmerk="ngModel">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Kenmerk</mat-label>
                <input matInput [(ngModel)]="fVariant.Kenmerk" [ngModelOptions]="{standalone: true}" ngModel required #bot="ngModel">
            </mat-form-field>

        </p>
        <p>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>DO1 Type</mat-label>
                <input matInput [(ngModel)]="fVariant.DO1Type" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Do1 Naam</mat-label>
                <input matInput [(ngModel)]="fVariant.DO1Naam" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>DO2 Type</mat-label>
                <input matInput [(ngModel)]="fVariant.DO2Type" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>DO2 Naam</mat-label>
                <input matInput [(ngModel)]="fVariant.DO2Naam" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>DO3 Type</mat-label>
                <input matInput [(ngModel)]="fVariant.DO3Type" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>DO3 Naam</mat-label>
                <input matInput [(ngModel)]="fVariant.DO3Naam" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>DO4 Type</mat-label>
                <input matInput [(ngModel)]="fVariant.DO4Type" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>DO4 Naam</mat-label>
                <input matInput [(ngModel)]="fVariant.DO4Naam" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
        </p>
        <button mat-stroked-button color="primary" type="submit" (click)="addVariant()">Product Variant Toevoegen</button>

    </form>

</div>
    