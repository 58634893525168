import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { WctImageAnnotationBeheerComponent } from '../imageannotation/WctImageAnnotationBeheer';
import { WctImageAnnotationCanvasComponent } from '../imageannotation/WctImageAnnotationCanvas';
import { WctImageAnnotationStartComponent } from '../imageannotation/WctImageAnnotationStart'; 
import { CoreUiModule } from '../coreui/coreui.module';
import { RouterModule, Routes } from '@angular/router';
import { WctImageAnnotationMainComponent } from '../imageannotation/WctImageAnnotationMain';

const imageAnnotationRoutes: Routes = [
    { path: 'start', component: WctImageAnnotationStartComponent },
    { path: 'canvas/:projectId/:photoSetId', component: WctImageAnnotationCanvasComponent }
]

const AngularMaterialModules = [
	MatSelectModule,
    MatRadioModule,
    MatInputModule
];

@NgModule({
    imports: [
        CoreUiModule,
        FormsModule,
        CommonModule,
        AngularMaterialModules,
        RouterModule.forChild(imageAnnotationRoutes),
    ],
    declarations: [
        WctImageAnnotationCanvasComponent,
        WctImageAnnotationBeheerComponent,
        WctImageAnnotationStartComponent,
        WctImageAnnotationMainComponent
    ],
    exports: [
        WctImageAnnotationCanvasComponent,
        WctImageAnnotationBeheerComponent,
        WctImageAnnotationStartComponent,
        WctImageAnnotationMainComponent
    ]
})

export class ImageAnnotationModule {

}