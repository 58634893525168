/**
 * @ref WoonConnectViewModel/Configurator/AddMultipleDwellingsModel.cs
 */

 import * as DataContracts from "@datacontracts/AddMultipleDwellingsModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Configurator.AddMultipleDwellingsRequestModel
	 */
	 

	export interface IAddMultipleDwellingsRequestModel {
		Dwellings: Dwelling[];
		ToJsonContract(): DataContracts.IAddMultipleDwellingsRequestModel;
	}

	export class AddMultipleDwellingsRequestModel {
		// property-Properties
		public get Dwellings(): Dwelling[] {
			return this._Dwellings;
		}
		public set Dwellings(newDwellings: Dwelling[]) {
			this._Dwellings = newDwellings;
		}

		// field-Properties

		// fields
		protected _Dwellings: Dwelling[] = [];

		constructor(data?: DataContracts.IAddMultipleDwellingsRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddMultipleDwellingsRequestModel): void {
			if(data) {
				// vul properties
				this._Dwellings = data.Dwellings ? data.Dwellings.map(dwellingItem => new Dwelling(dwellingItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAddMultipleDwellingsRequestModel {
			return {
				Dwellings: (this.Dwellings ? this.Dwellings.map(dwellingItem => new Dwelling(dwellingItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Configurator.Dwelling
	 */
	 

	export interface IDwelling {
		Zipcode: string;
		Number: number;
		NumberExtension: string;
		UseBGT: boolean;
		Streetname: string;
		Domicile: string;
		ToJsonContract(): DataContracts.IDwelling;
	}

	export class Dwelling {
		// property-Properties
		public get Zipcode(): string {
			return this._Zipcode;
		}
		public set Zipcode(newZipcode: string) {
			this._Zipcode = newZipcode;
		}

		public get Number(): number {
			return this._Number;
		}
		public set Number(newNumber: number) {
			this._Number = newNumber;
		}

		public get NumberExtension(): string {
			return this._NumberExtension;
		}
		public set NumberExtension(newNumberExtension: string) {
			this._NumberExtension = newNumberExtension;
		}

		public get UseBGT(): boolean {
			return this._UseBGT;
		}
		public set UseBGT(newUseBGT: boolean) {
			this._UseBGT = newUseBGT;
		}

		public get Streetname(): string {
			return this._Streetname;
		}
		public set Streetname(newStreetname: string) {
			this._Streetname = newStreetname;
		}

		public get Domicile(): string {
			return this._Domicile;
		}
		public set Domicile(newDomicile: string) {
			this._Domicile = newDomicile;
		}

		// field-Properties

		// fields
		protected _Zipcode: string = "";
		protected _Number: number = 0;
		protected _NumberExtension: string = "";
		protected _UseBGT: boolean = false;
		protected _Streetname: string = "";
		protected _Domicile: string = "";

		constructor(data?: DataContracts.IDwelling) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDwelling): void {
			if(data) {
				// vul properties
				this._Zipcode = data.Zipcode;
				this._Number = data.Number;
				this._NumberExtension = data.NumberExtension;
				this._UseBGT = data.UseBGT;
				this._Streetname = data.Streetname;
				this._Domicile = data.Domicile;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDwelling {
			return {
				Zipcode: this.Zipcode,
				Number: this.Number,
				NumberExtension: this.NumberExtension,
				UseBGT: this.UseBGT,
				Streetname: this.Streetname,
				Domicile: this.Domicile
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Configurator.AddMultipleDwellingsResponseModel
	 */
	 

	export interface IAddMultipleDwellingsResponseModel {
		Warnings: string;
		DwellingIds: number[];
		ModelAvailable: boolean;
		Error: string;
	}

	export class AddMultipleDwellingsResponseModel {
		// property-Properties
		public get Warnings(): string {
			return this._Warnings;
		}
		public set Warnings(newWarnings: string) {
			this._Warnings = newWarnings;
		}

		// field-Properties
		public get DwellingIds(): number[] {
			return this._DwellingIds;
		}
		public set DwellingIds(newDwellingIds: number[]) {
			this._DwellingIds = newDwellingIds;
		}
		
		public get ModelAvailable(): boolean {
			return this._ModelAvailable;
		}
		public set ModelAvailable(newModelAvailable: boolean) {
			this._ModelAvailable = newModelAvailable;
		}
		
		public get Error(): string {
			return this._Error;
		}
		public set Error(newError: string) {
			this._Error = newError;
		}

		// fields
		protected _Warnings: string = "";
		protected _DwellingIds: number[] = [];
		protected _ModelAvailable: boolean = false;
		protected _Error: string = "";

		constructor(data?: DataContracts.IAddMultipleDwellingsResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddMultipleDwellingsResponseModel): void {
			if(data) {
				// vul properties
				this._Warnings = data.Warnings;
				// vul fields
				this._DwellingIds = data.DwellingIds;
				this._ModelAvailable = data.ModelAvailable;
				this._Error = data.Error;
			}
		}
	}
