/**
 * @ref WoonConnectViewModel/ImageAnnotation/GetPhotoDataModel.cs
 */

 import * as DataContracts from "@datacontracts/GetPhotoDataModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetPhotoDataRequestModel
	 */
	 

	export interface IGetPhotoDataRequestModel {
		PhotoPath: string;
		ToJsonContract(): DataContracts.IGetPhotoDataRequestModel;
	}

	export class GetPhotoDataRequestModel {
		// property-Properties
		public get PhotoPath(): string {
			return this._PhotoPath;
		}
		public set PhotoPath(newPhotoPath: string) {
			this._PhotoPath = newPhotoPath;
		}

		// field-Properties

		// fields
		protected _PhotoPath: string = "";

		constructor(data?: DataContracts.IGetPhotoDataRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetPhotoDataRequestModel): void {
			if(data) {
				// vul properties
				this._PhotoPath = data.PhotoPath;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IGetPhotoDataRequestModel {
			return {
				PhotoPath: this.PhotoPath
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetPhotoDataResponseModel
	 */
	 

	export interface IGetPhotoDataResponseModel {
		PhotoData: ImageAnnotationPhotoData;
	}

	export class GetPhotoDataResponseModel {
		// property-Properties
		public get PhotoData(): ImageAnnotationPhotoData {
			return this._PhotoData;
		}
		public set PhotoData(newPhotoData: ImageAnnotationPhotoData) {
			this._PhotoData = newPhotoData;
		}

		// field-Properties

		// fields
		protected _PhotoData: ImageAnnotationPhotoData = new ImageAnnotationPhotoData(undefined);

		constructor(data?: DataContracts.IGetPhotoDataResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetPhotoDataResponseModel): void {
			if(data) {
				// vul properties
				this._PhotoData = data.PhotoData ? new ImageAnnotationPhotoData(data.PhotoData): undefined;
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.ImageAnnotationPhotoData
	 */
	 

	export interface IImageAnnotationPhotoData {
		Success: boolean;
		Base64: string;
		Mime: string;
		ToJsonContract(): DataContracts.IImageAnnotationPhotoData;
	}

	export class ImageAnnotationPhotoData {
		// property-Properties
		public get Success(): boolean {
			return this._Success;
		}
		public set Success(newSuccess: boolean) {
			this._Success = newSuccess;
		}

		public get Base64(): string {
			return this._Base64;
		}
		public set Base64(newBase64: string) {
			this._Base64 = newBase64;
		}

		public get Mime(): string {
			return this._Mime;
		}
		public set Mime(newMime: string) {
			this._Mime = newMime;
		}

		// field-Properties

		// fields
		protected _Success: boolean = false;
		protected _Base64: string = "";
		protected _Mime: string = "";

		constructor(data?: DataContracts.IImageAnnotationPhotoData) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IImageAnnotationPhotoData): void {
			if(data) {
				// vul properties
				this._Success = data.Success;
				this._Base64 = data.Base64;
				this._Mime = data.Mime;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IImageAnnotationPhotoData {
			return {
				Success: this.Success,
				Base64: this.Base64,
				Mime: this.Mime
			}
		}
	}
