
import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { CertificationSigningConsumer } from '@consumers/CertificationSigningController';
import { CertificationSigningRequestModel, CertificationSigningResponseModel, CertificationTokenUrlRequestModel, CertificationTokenUrlResponseModel } from '@models/CertificationSigningModel';
import { WoningEventService } from '../../../services/WoningEventService';

@Component({
  selector: 'wct-afmelden',
  templateUrl: './WctAfmelden.html',
  styleUrls: ['./WctAfmelden.scss']
})
export class WctAfmeldenComponent {
  private LoadingUrl: boolean = true;
  private LoadingAfmelden: boolean = false;

  public SafeUrl: SafeUrl;
  public Token: string = "";
  public Output: CertificationSigningResponseModel = null;


  constructor(
    private certificationsigningconsumer: CertificationSigningConsumer,
    private sanitizer: DomSanitizer,
    private woningeventservice: WoningEventService,
    private route: ActivatedRoute
  ) {
    this.LoadUrl();
  }

  ngOnInit() {
    this.Token = this.route.snapshot.queryParams['ep_token'];

    if (this.Token == undefined) {
      this.Token = "";
    }
  }

  public LoadUrl(): void {
    this.LoadingUrl = true;

    var input = new CertificationTokenUrlRequestModel();

    this.certificationsigningconsumer.GetTokenUrl_Observable(input).subscribe((data: any) => {
      var output = new CertificationTokenUrlResponseModel(data);

      this.SafeUrl = this.sanitizer.bypassSecurityTrustUrl(output.Url);

      this.LoadingUrl = false;
    });
  }

  public StartAfmelden(): void {
    this.LoadingAfmelden = true;

    var input = new CertificationSigningRequestModel();

    input.Token = this.Token;
    input.Dwelling = this.woningeventservice.GeefScopeSelectieWoning().Model;

    this.certificationsigningconsumer.CertificationSigning_Observable(input).subscribe((data: any) => {
      this.Output = new CertificationSigningResponseModel(data);

      this.LoadingAfmelden = false;
    });
  }

  public Loading(): boolean {

    if (this.LoadingUrl) {
      return true;
    }

    if (this.LoadingAfmelden) {
      return true;
    }

    return false;
  }
}
