// [conv] import "(./FabAdmin).less";
import { Component, OnInit, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Component({
    selector: 'fab-admin',
  templateUrl: './FabAdmin.html',
  styleUrls: ['./FabAdmin.scss']
})
@Injectable()
export class FabAdminComponent {
    Header: string;
    private history = [];

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private _location: Location) {

    }


    public loadRouting(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this.history = [...this.history, urlAfterRedirects];
            });
    }

    public getHistory(): string[] {
        return this.history;
    }

    goBack() {
        this._location.back();
    }

    public getPreviousUrl(): string {
        return this.history[this.history.length - 2] || '';
    }


}
