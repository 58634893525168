// [conv] import "(./WctDocumenten).less";
import { Component, Input, OnChanges, SimpleChanges, SecurityContext } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { IDocumentModel } from '@datacontracts/DocumentModel';
import { IDocumentMapModel } from '@datacontracts/DocumentMapModel';
import { DocumentService } from '../../services/DocumentService';
import { AppConfigService } from '../../infrastructure/app.configservice';
import { IGeefDocumentenResultaatModel } from '@datacontracts/GeefDocumentenResultaatModel';
import { MapType } from '@enums/IDocumentRepository';
import { CapabilitiesService } from '../../viewers/CapabilitiesService';

@Component({
   selector: 'wct-documenten',
   templateUrl: './WctDocumenten.html'
})
export class WctDocumentenComponent implements OnChanges {
   @Input() maptype: string;
   @Input() persoonid: number;
	@Input() filters: MapType[];
	@Input() openfirstsubmap: boolean;
   @Input() startopen: boolean;
   
   public loading: boolean = true;
   public model: IGeefDocumentenResultaatModel;

   m_initialised: boolean = false;

   fileSize: number;

   public showArchive: boolean = false;
   public archiveActive: boolean = true;

   documentPreviewUrl: string;

   windowWidth: number;

   constructor(
      private documentService: DocumentService,
      private capabilitiesService: CapabilitiesService,
      private sanitizer: DomSanitizer,
      private config: AppConfigService
   ) {
      'ngInject';
         if (this.persoonid != undefined && this.persoonid != -1) {
            this.showArchive = false;
            this.archiveActive = true;
         }
         else if (this.isFolderAdmin()) {
            this.showArchive = true;
            this.archiveActive = true;
         }
         else if (this.isAdmin()) {
            this.showArchive = true;
            this.archiveActive = false;
         }
         this.documentService.changeSubject.subscribe({
            next: () => this.loadModel()
          });
      }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['persoonid'] && changes['persoonid'].currentValue !== changes['persoonid'].previousValue) {
         this.loadModel();
      }
   }

   ngOnInit() {
      this.loadModel();
   }

   private findFirstFolder(folder: IDocumentMapModel): IDocumentMapModel
		{
			if (!folder.Verbergen && !folder.IsRoot)
			{
				return folder;
			}
			for (let i = 0; i < folder.ChildMappen.length; i++)
			{
				var subFolder = this.findFirstFolder(folder.ChildMappen[i]);
				if (subFolder != null)
				{
					return subFolder;
				}
			}
			return null;
		}

      public loadModel(): void {
            this.loading = true;
            var tFilters = new Array<MapType>();
            if (this.filters != undefined) {
               tFilters = this.filters;
            }

            this.documentService.GeefDocumentenModel(this.persoonid, this.archiveActive, tFilters).then(d => {
               this.model = d;
               this.documentService.OpenMap(this.model.Root);
               var firstFolder = this.findFirstFolder(this.model.Root);
               if (this.startopen && firstFolder != null) {
                  this.documentService.OpenMap(firstFolder);
                  this.documentService.ZetActieveMap(firstFolder);

                  if (firstFolder.Documenten.length > 0) {
                     // we willen voor nu het laatste (=nieuwste) document
                     this.documentService.ZetActiefDocument(firstFolder.Documenten[firstFolder.Documenten.length -1]);
                  }
               }
               this.loading = false;
            }).catch(err => {
               this.loading = false;
               console.log(err);
            });
      }

      public isFolderAdmin(): boolean {
         return this.documentService.IsDocumentBeheerder();
      }

      public isAdmin(): boolean {
         return this.config.Software.Rollen.toLowerCase().indexOf("beheerder") != -1;
      }

      public generatePersonalFolders(): void {
         if (this.config.Software.PersoonsGebondenMappenActief) {
            this.loading = true;

            this.documentService.GenereerPersoonsGebondenMappen().then(d => {
               this.loading = false;
               this.loadModel();
            }).catch(err => {
               this.loading = false;
               console.log(err);
            });
         } else {
            alert("Zet eerst de bewonermappen project setting aan");
         }
      }

      public generateResidentialFolders(): void {
         this.loading = true;

         this.documentService.GenereerWoningMappenStructuur().then(d => {
            this.loading = false;
            this.loadModel();
         }).catch(err => {
            this.loading = false;
            console.log(err);
         });
      }

      public generatePrivateFolders(): void {
         if (this.config.Software.PriveMappenActief) {
            this.loading = true;

            this.documentService.GenereerPriveMappenStructuur().then(d => {
               this.loading = false;
               this.loadModel();
            }).catch(err => {
               this.loading = false;
               console.log(err);
            });
         }
         else {
            alert("Zet eerst de privemappen projectsetting aan");
         }
      }

      public generateOptionsFolders(): void {
         if (this.config.Software.IngediendeOptiesMappenActief) {
            this.loading = true;

            this.documentService.GenereerIngediendeOptiesMappenStructuur().then(d => {
               this.loading = false;
               this.loadModel();
            }).catch(err => {
               this.loading = false;
               console.log(err);
            });
         }
         else {
            alert("Zet eerst de ingediendeopties projectsetting aan");
         }
      }

      public generateQuotesFolders(): void {
         if (this.config.Software.OfferteMappenActief) {
            this.loading = true;

            this.documentService.GenereerOffertesMappenStructuur().then(d => {
               this.loading = false;
               this.loadModel();
            }).catch(err => {
               this.loading = false;
               console.log(err);
            });
         } else {
            alert("Zet eerst de offerte projectsetting aan");
         }
      }

      public generateGeneralFolders(): void {
         this.loading = true;

         this.documentService.GenereerAlgemeneMappenStructuur().then(d => {
            this.loading = false;
            this.loadModel();
         }).catch(err => {
            this.loading = false;
            console.log(err);
         });
      }

      public getCurrentDocument(): IDocumentModel {
         return this.documentService.GeefActiefDocument(this.model);
      }

      public documentPreview(): boolean {
         return this.documentPreviewIsImage() || this.documentPreviewIsPDF() || this.documentPreviewIsHTML();
      }

      public documentPreviewIsImage(): boolean {
         var document = this.getCurrentDocument();

         if (document != undefined) {
            if (document.ContentType.indexOf('image/') != -1) {
               return true;
            }
         }

         return false;
      }

      public documentPreviewIsPDF(): boolean {
         var document = this.getCurrentDocument();

         if (document != undefined) {
            if (document.ContentType.indexOf('pdf') != -1) {
               return true;
            }
         }

         return false;
      }

      public documentPreviewIsHTML(): boolean {
         var document = this.getCurrentDocument();

         if (document != undefined) {
            if (document.ContentType.indexOf('html') != -1) {
               return true;
            }
         }

         return false;
      }

      public documentMimePreviewUrl(): string {
         if (this.getCurrentDocument() != undefined) {
            return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(this.documentService.GeefPreviewUrl(this.getCurrentDocument(), true))); 
          } else {
            return "";
         }
      }

      public getSelectedDocumentName(): string {
         if (this.getCurrentDocument() != undefined) {
            return this.getCurrentDocument().Naam;
         }

         return "";
      }

      public stateIsFuture(): boolean {
         let ret: boolean = window.location.href.includes("*.Toekomst.**");
         return ret;
      }

      public activateDocument(url: string) {
         if (url.toLowerCase().indexOf('document') >= 0) {
            if (!this.m_initialised) {
               this.m_initialised = true;
            }
         }
      }

      public download(): void {
			let document: IDocumentModel = this.getCurrentDocument();
			if (this.capabilitiesService.IsiPadTablet) {
				window.open('/DocumentenDownload/DownloadDocument?id=' + document.Id);
			} else {
				$("body").append("<iframe src='/DocumentenDownload/DownloadDocument?id=" + document.Id + "' style='display: none;' ></iframe>");
			}
		}

		public previewIsNotAvailable(): boolean {
			let ret: boolean = false;
			if ((this.documentService.activeDocumentId !== null) && !this.documentPreview()) {
				return true;
			}
			return ret;
		}

}