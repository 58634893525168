// [conv] import "(./WctAdminAddBuilding).less";
import { Component, Input, ViewChild } from '@angular/core';
import * as _ from 'lodash';


import { BuildingManagementConsumer } from '@consumers/BuildingManagementController';
import { BuildingViewModel } from '@models/BuildingViewModels';





@Component({
    selector: 'wct-admin-add-building',
    templateUrl: './WctAdminAddBuilding.html'
})
export class WctAdminAddBuildingComponent {
    newBuilding: BuildingViewModel = new BuildingViewModel();

    constructor(private building: BuildingManagementConsumer) {
       

    }


    onAddBuilding() {
       
        console.log(this.newBuilding)
    }



}