import * as _ from 'lodash';
import * as HelpersMasterTypescript from '.././Helpers/MasterTypescript';
import * as HelpersMenuTypescript from '.././Helpers/MenuTypescript';
import * as HelpersBackwards from '.././Helpers/Backwards';




	export class MenuItem {
		ProjectId: number = null;
		Titel: string = "";
		Niveau: number = null;
		ToolTip: string = null;
		Type: string = null;
		Link: string = null;
		RedirectTarget: string = null;
		ProjectFunctie: string = null;
		SubMenus: Array<MenuItem> = [];
		Image: string = "";
		Parent: MenuItem = null;
		Id: number;
		ActiveBadge: boolean; // Wordt nergens gebruikt
		State: string;

		//Active: KnockoutComputed<boolean>;
		Zichtbaar: boolean = true; // [TODO] Wordt niet gezet in constructor
		Conditie: () => boolean = null; // Wordt gezet in MenuTypescript.ts (function injection)
		isClickable: boolean; // Gezet met behulp van SetMenuClickable
		ActiveOverride: boolean = false; // Gezet met behulp van SetMenuActive en SetMenuInActive
		m_ToonDropDown: boolean = false; // Gezet met behulp van SetMenuActive en SetMenuInActive

		constructor(data: any, GereedDoorBewoner: any, parent: any) {
			this.ProjectId = data.ProjectId;
			this.Titel = data.Titel;
			this.Niveau = data.Niveau;
			this.ToolTip = data.ToolTip;
			this.Type = data.Type;
			this.Link = "/" + data.Link;
			this.RedirectTarget = data.RedirectTarget;
			this.Image = data.Image;
			this.ProjectFunctie = data.ProjectFunctie;
			this.Parent = parent;
			this.Id = data.MenuItemId;
			this.ActiveBadge = false;
			this.State = this.RedirectTarget;
			this.isClickable = data.isClickable;
			if (this.ProjectFunctie != undefined && this.ProjectFunctie.length > 0) {
				if (this.ProjectFunctie == "EIGENAAR-NU") {
					this.State = "nu."+ this.RedirectTarget;
				}
				else if (this.ProjectFunctie == "EIGENAAR-TOEKOMST") {
					this.State = "toekomst." + this.RedirectTarget;
				}				
			} 

			var isModelOfEnergieProfiel: boolean = data.Link === "Configurator/EnergieProfielNew" || data.Link === "Configurator/Model";

			//if (MasterControllerInstance.HeeftRol("Huurder") && isModelOfEnergieProfiel && !GereedDoorBewoner) {
			// Beetje lelijk.. moet nog eens netjes opgelost worden.. Zet alle menu items Unclickable als huurder rol.
			//this.Clickable = (false);
			//}
			if (data.SubMenus != null) {
				var _this: any = this;
				var mappedSubMenus: MenuItem[] = $.map(data.SubMenus, (item: any): MenuItem => {
					return new MenuItem(item, GereedDoorBewoner, _this);
				});
				this.SubMenus = mappedSubMenus; // TODO: waarom is dit niet _this.SubMenus?
			}
		}

		//GeefActiefMenuItem(): MenuItem {
		//	if (this.Type == "Link" && this.IsLinkActief()) {
		//		return this;
		//	}
		//	if (this.Type == "Parent") {

		//		for (var i = 0; i < this.SubMenus.length; i++) {
		//			var item = this.SubMenus[i].GeefActiefMenuItem();
		//			if (item != null) {
		//				return item;
		//			}
		//		}
		//	}
		//	return null;
		//}

		isActiveState(state: any, keuze: string): boolean {
			var act: boolean = false;
			//act = state.includes('*.' + keuze + this.RedirectTarget + '.**')
			var curUrl = state.$current.url.pattern;
			var redirUrl: string = keuze ;
			_.each(this.RedirectTarget.split("."), (part: string) => {
				redirUrl += "/" + part.toLowerCase();
			});
			act = curUrl.indexOf(redirUrl) !== -1;
			return act;
		}

		SetMenuClickable(aanuit: boolean): void {
			this.isClickable = aanuit;
		}

		SetMenuActive(): void {
			if (this.Type === "Enquete" || this.Type === "Parent" || this.Type === "OptieMenu" || this.Type === "OptieMenuGebouw" || this.Type === "Link") {
				//$('#' + this.Titel + '-dropdown').show();
				this.m_ToonDropDown = true;
			}
			if (this.Type === "Anchor" || this.Type === "Link") {
				this.ActiveOverride = true;
			}
		}

		SetMenuInActive(): void {
			//if (this.Type == 'Parent' || this.Type == 'OptieMenu' || this.Type == 'Link') {
			this.m_ToonDropDown = false;
			this.ActiveOverride = false;
			//$('#' + this.Titel + '-dropdown').hide();
			//}
		}

		ToonDropdown(): boolean {
			return this.m_ToonDropDown;
		}

		ClickMenu(data: any, e: any, supressApply: boolean): void {
			if (this.isClickable) {
				if (this.RedirectTarget == null) {
					 HelpersBackwards.BeginWachten(this.Titel + " laden");
					window.location.href = this.Link;
				} else {
					//if (HelpersMenuTypescript.MenuViewModelInstance.Callback != null) {
					//window.history.pushState(null, null, this.Link());
					HelpersMenuTypescript.MenuViewModelInstance.ForceerPathName(this.Link);
					//HelpersMasterTypescript.MasterControllerInstance.AngularBroadcast("MenuGewijzigd", this.Link, true);
					//HelpersMenuTypescript.MenuViewModelInstance.Callback(this);
					//}
					//else {
					//	var RedirectTargetMetPrefix: string = "KiesPagina" + this.RedirectTarget;
					//	RedirectNaarFunctieInPagina(RedirectTargetMetPrefix, this.RedirectTarget + ' configurator laden', this.Link);

					//	MasterControllerInstance.AngularBroadcast('MenuGewijzigd', this.Link, true);

					//	HelpersMenuTypescript.MenuViewModelInstance.Url = (this.Link);
					//	//VerbergMenuAccordionOverlay(false);
					//}
				}
			} else {
				if (e != null) {
					var xcoord = e.target.offsetLeft;
					$("#MenuInaktiefPopup").css("left", xcoord);
				}
				$("#MenuInaktiefPopup").show();
			}
		}

		//public ConditieCheck(): boolean {
		//	return (this.Conditie == null || this.Conditie());

		//}

	}
