import { Component, OnInit } from '@angular/core';
import { FabrikantenConsumer } from '../../../../../data/WoonConnectKern/Consumers/FabrikantenController';
import { FabrikantenCMSModel } from '../../../../../data/WoonConnectKern/Models/FabrikantFilterModel';

@Component({
  selector: 'app-meer',
  templateUrl: './meer.component.html',
  styleUrls: ['./meer.component.scss']
})
export class MeerComponent implements OnInit {

  public Loading: boolean = true;
  public FabrikantenCMSModel: FabrikantenCMSModel;

  constructor(private fab: FabrikantenConsumer) { }

  ngOnInit(): void {
    this.LoadCMS();
  }

  public LoadCMS(): void {

    this.Loading = true;

    this.fab.GetCMS_Observable().subscribe((data) => {
      this.FabrikantenCMSModel = new FabrikantenCMSModel(data);

      this.Loading = false;
    });
  }

  public Open(link: string) {
    window.open(link, "_blank");
  }
}
