// Angular components and modules
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreUiModule } from '../../../coreui/coreui.module';
import { EffectsModule } from '@ngrx/effects';
import { NgxWigModule } from 'ngx-wig';

// Site components
import { PrijslijstComponent } from './prijslijst.component';
import { HeaderComponent } from './components/header.component';
import { FooterComponent } from './components/footer.component';
import { OverviewComponent } from './components/overview.component';
import { CategorieComponent } from './components/categorie.component';
import { PrijsSchemaComponent } from './components/prijsschema.component';
import { ProductComponent } from './components/product.component';
import { VoorwaardenComponent } from './components/voorwaarden.component';
import { InstructieComponent } from './components/instructie.component';
import { VoorwaardenBeheerComponent } from './components/voorwaarden.beheer.component';
import { InstructieBeheerComponent } from './components/instructie.beheer.component';
import { GebruikerComponent } from './components/gebruiker.component';
import { LoginComponent } from './components/login.component';

// Themes and Material
//import { ThemeModule, DefaultTheme } from '../../shared/theme';
import { MaterialModule } from '../../shared/material';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// Other modules
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FabAdminModule } from '../../admin/fab-admin.module';
import { ViewerModule } from '../../../viewers/viewer.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';

// Consumers
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { FabrikantenAdminConsumer } from '@consumers/FabrikantenAdminController';

// Services
import { GoogleAnalyticsService } from '../../../services/GoogleAnalyticsService';
import { PrijsModuleService } from '../../../services/PrijsModuleService';

// Localization
import { registerLocaleData, DatePipe } from '@angular/common'
import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeNl, 'nl');

// Store
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { GetInfraStatus } from '../../../modules/webpack/sitestatus';
import { over } from 'lodash';


//import { MatPaginatorModule } from '@angular/material/paginator';
//import { MatSortModule } from '@angular/material/sort';


// Some logic
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function debugStore(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log('ngrx store: pre', state);
    console.log('ngrx store action', action.type, action);
    let post = reducer(state, action);
    console.log('ngrx store: post', state);
    return post;
  };
}

export const metaReducers: MetaReducer<any>[] = [debugStore];

let production = GetInfraStatus().ClientsideProduction;

// Routes
const routes: Routes = [
  { path: '', component: OverviewComponent },
  { path: 'overview', component: OverviewComponent },
  { path: 'overview/:categorieId', component: OverviewComponent },
  { path: 'categorie', component: CategorieComponent },
  { path: 'prijsschema', component: PrijsSchemaComponent },
  { path: 'voorwaarden', component: VoorwaardenComponent },
  { path: 'voorwaardenbeheer', component: VoorwaardenBeheerComponent },
  { path: 'instructie', component: InstructieComponent },
  { path: 'instructiebeheer', component: InstructieBeheerComponent },
  { path: 'product', component: ProductComponent },
  { path: 'product/:categorieId', component: ProductComponent },
  { path: 'gebruiker', component: GebruikerComponent },
  { path: 'login', component: LoginComponent }
];

// Module definition
@NgModule({
  imports: [
    ViewerModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CoreUiModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    StoreModule.forRoot({}, !production ? { metaReducers } : {}),
    EffectsModule.forRoot(),
    PerfectScrollbarModule,
    SlickCarouselModule,
    HttpClientModule,
    FabAdminModule,
    NgxWigModule
  ],
  exports: [
    RouterModule,
  ],
  declarations: [
    PrijslijstComponent,
    HeaderComponent,
    FooterComponent,
    OverviewComponent,
    CategorieComponent,
    PrijsSchemaComponent,
    ProductComponent,
    VoorwaardenComponent,
    InstructieComponent,
    VoorwaardenBeheerComponent,
    InstructieBeheerComponent,
    GebruikerComponent,
    LoginComponent
  ],
  bootstrap: [
    PrijslijstComponent
  ],
  providers: [
    FabrikantenConsumer,
    FabrikantenAdminConsumer,
    DatePipe,
    GoogleAnalyticsService,
    PrijsModuleService,
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: MAT_DATE_LOCALE, useValue: 'nl' },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ]
})

export class PrijslijstModule {
}
