<div class='wct-header'>
    <button mat-button [matMenuTriggerFor]="menu">Gebruikers</button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item  [routerLink]="'/wct-admin/users'">Gebruikers</button>
        <button mat-menu-item>Beheerder Toevoegen</button>
    </mat-menu>
    <button mat-button [matMenuTriggerFor]="woning">Woningen</button>
    <mat-menu #woning="matMenu">
        <button mat-menu-item  [routerLink]="'/wct-admin/buildings'">Alle woningen</button>
        <button mat-menu-item>Woning Toevoegen</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="woning">Project</button>
    <mat-menu #Project="matMenu">
        <button mat-menu-item>Projecten</button>
        <button mat-menu-item>Nieuw Project</button>
    </mat-menu>

    <button mat-button>Energie</button>
    <button mat-button>Straten</button>
    <button mat-button>Concepten</button>
    <button mat-button>Wijken</button>
    <button mat-button>Recepten</button>
    <button mat-button>Vragenlijst</button>
    <button mat-button>Statistieken</button>
    <button mat-button>Menu</button>
    <button mat-button>Clusters</button>
    <button mat-button>Admin(nieuw)</button>

</div>

<router-outlet></router-outlet>