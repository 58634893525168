<div class="flex-cont cont-title">
	<p class="txt-title">{{getTxtTitle()}}</p>
	<img (click)="closeDialog()" [src]="'/Content/Images/menu/menu-close-white.svg'" class="img-title" />
</div>

<div *ngIf="!BezigMetLaden">
	<div class="col-xs-12 no-padding cont-main" *ngIf="!AntwoordModus">
		<p *ngIf="oorzaak === 'wachtwoordVergeten'" class="col-xs-12 txt-main">{{ 'SIGNINGIN_DID-YOU-FORGET-YOUR' | translate }} {{ naam }} {{ 'SIGNINGIN_DID-YOU-FORGET-YOUR-AFTER' | translate | lowercase }}, {{ 'SIGNINGIN_THEN-YOU-RECEIVE-EMAIL-CONTAINS-LINK' | translate | lowercase }}.</p>
		<p *ngIf="oorzaak !== 'wachtwoordVergeten'" [innerHtml]="melding"></p>
		<div *ngIf="!existingAccount" class="col-xs-6 no-padding-left cont-input-form">
			<label>{{ 'SIGNINGIN_WHAT-IS-YOUR' | translate }} {{ naam }}?</label><input type="text" [(ngModel)]="Emailadres" placeholder="{{ 'SIGNINGIN_YOUR' | translate }} {{ naam }}" />
			<p *ngIf="EmailadresHulp()" class="txt-nog-invullen">{{ 'SIGNINGIN_FIRST-ENTER-YOUR' | translate }} {{ naam }} {{ 'SIGNINGIN_FIRST-ENTER-YOUR-AFTER' | translate | lowercase }}</p>
		</div>
	</div>
	<div *ngIf="AntwoordModus" class="col-xs-12 no-padding cont-main">
		<p class="col-xs-12 txt-main">{{ Antwoord }}</p>
	</div>

	<div class="col-xs-12 flex-cont cont-btns">
		<wct-button buttonText="{{ 'SIGNINGIN_CANCEL' | translate }}" buttonSize="small" buttonAlign="center" buttonRole="secundary" *ngIf="!AntwoordModus && (oorzaak!='inlognaamOnbekend') && (oorzaak!='loginnaamOfWachtwoordFout') && (oorzaak != 'teveelFouteInlogpogingen')" (click)="closeDialog()"></wct-button>
		<wct-button class="button-ok" buttonText="{{ 'SIGNINGIN_OK' | translate }}" buttonSize="small" buttonAlign="center" (click)="ControleerVelden()"></wct-button>
	</div>
</div>
<div *ngIf="BezigMetLaden">
	<div style="position: initial; padding: 30px; width: 740px;" class="flex-cont cont-progress-circular">
		<mat-progress-spinner mode="indeterminate"  diameter="60px"></mat-progress-spinner>
	</div>
</div>
