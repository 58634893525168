import { Pipe, PipeTransform, Injectable, Inject } from '@angular/core';
import { TranslateService } from './TranslateService';
import { ITranslateService } from './TranslateService';


@Pipe({ name: 'translate' })
export class Translate implements PipeTransform {
	constructor(
		@Inject(TranslateService) private translateservice: ITranslateService
	) {

	}

	transform(value: string): string {
		let arrayOfTranslation: Array<string> = value.split("_");
		let part: string = arrayOfTranslation[0];
		let word: string = arrayOfTranslation[1];
		let ret: string = this.translateservice.GetTranslation(part, word);
		return ret;
	}
}