import { Component, Input, OnInit } from '@angular/core';
import { ISurveyClimateSystem } from '@models/SurveyClimateSystem';
import { FormGroupState } from 'ngrx-forms';
import { AllElectric, BiomassAccumulate, BoilerType, CWClass, DistributionInsulationPipes, DistributionPipesThroughNonInsulatedRoom, DistributionSystemType, Drain, GasHrLabel, GeneratorPowerHreWKK, HeatingDevice, HeatingDeviceEnergyType, HeatingDistribution, HeatingPumpSource, HeatingType, LocalHeatingType, OtherFunctions, VentilationSupply  } from '@enums/SurveyClimateSystem';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'wct-survey-climate-system',
  templateUrl: './wct-survey-climate-system.component.html',
  styleUrls: ['./wct-survey-climate-system.component.scss']
})
export class WctSurveyClimateSystemComponent implements OnInit {

  @Input() system : FormGroupState<ISurveyClimateSystem> = null;

  get control() {
    return this.system.controls;
  }

  get isCombiheatpump(): boolean {
    return this.control.HeatingDevice.value == HeatingDevice.CombiHeatpump;
  }

  get hasVentilationfunction(): boolean {
    return this.control.OtherFunctions.value == OtherFunctions.Ventilation || this.control.OtherFunctions.value == OtherFunctions.VentilatingAndCooling;
  }

  get isHeatpump(): boolean {
    return this.control.HeatingDevice.value == HeatingDevice.Heatpump;
  }

  get hasOnlyExtraCoolingFunction(): boolean {
    return this.control.OtherFunctions.value == OtherFunctions.Cooling;
  }

  get hasNoOtherFunction(): boolean {
    return this.control.OtherFunctions.value == OtherFunctions.None;
  }

  public HeatingAllowed(device: HeatingDevice )
  {
    if (this.system.controls.HeatingType.value == HeatingType.Common && device == this.HeatingDevice.Local)
    {
      return false;
    }

    if (this.system.controls.HeatingType.value == HeatingType.Individual && device == this.HeatingDevice.Unknown)
    {
      return false;
    }

    return true;
  }

  // public HeatingDeviceEnergyTypeAllowed(device: HeatingDeviceEnergyType)
  // {
  //   return this.system.controls.HeatingDevice.value == HeatingDevice.Boiler || this.system.controls.HeatingDevice.value == HeatingDevice.Combiboiler;
  // }

  public HeatingDeviceEnergyTypeAllowedAny()
  {
    return this.system.controls.HeatingDevice.value == HeatingDevice.Boiler || this.system.controls.HeatingDevice.value == HeatingDevice.Combiboiler;
  }

  public OtherFunctionsAny()
  {
    return this.system.controls.HeatingDevice.value == HeatingDevice.Heatpump || this.system.controls.HeatingDevice.value == HeatingDevice.CombiHeatpump;
  }

  public HeatingDistributionAny()
  {
    return this.control.HeatingDevice.value == HeatingDevice.Boiler || this.control.HeatingDevice.value == HeatingDevice.Heatpump;
  }

  public BoilerTypeAny()
  {
    return this.control.HeatingDistribution.value == HeatingDistribution.Water && this.control.HeatingDeviceEnergyType.value == HeatingDeviceEnergyType.Gas || this.control.HeatingDevice.value == HeatingDevice.Combiboiler;
  }

  public HeatingPumpSourceAny()
  {
    

    if (this.isHeatpump && this.hasNoOtherFunction && this.control.HeatingDistribution.value == HeatingDistribution.Water)
    {
      return true;
    }

    if (this.isHeatpump && this.hasOnlyExtraCoolingFunction)
    {
      return true;
    }

    if (this.isCombiheatpump && (this.hasOnlyExtraCoolingFunction || this.hasNoOtherFunction))
    {
      return true;
    }

    return false;
  }

  public AllElectricAny()
  {
    return (this.isCombiheatpump && this.hasVentilationfunction);
  }

  public VentilationSupplyAny()
  {
    return (this.isCombiheatpump && this.hasVentilationfunction);
  }

  public ShowLocalHeatingTypeQuestion()
  {
    return this.control.HeatingType.value == HeatingType.Individual && this.control.HeatingDevice.value == HeatingDevice.Local;
  }

  public ShowDrainQuestion()
  {
    return this.ShowLocalHeatingTypeQuestion() && (this.control.LocalHeatingType.value == LocalHeatingType.LocalOilStove || this.control.LocalHeatingType.value == LocalHeatingType.LocalGasStove);
  }

  public ShowBiomassAccumulateQuestion()
  {
    return this.ShowLocalHeatingTypeQuestion() && (this.control.LocalHeatingType.value == LocalHeatingType.BiomassStoveBuiltIn || this.control.LocalHeatingType.value == LocalHeatingType.BiomassStoveFreestanding  || this.control.LocalHeatingType.value == LocalHeatingType.BiomassStovePellet);
  }

  public ShowDistributionSystemTypeQuestion()
  {
    if (this.control.HeatingDevice.value == HeatingDevice.Local)
    {
      return false;
    }

    if (this.control.HeatingDevice.value == HeatingDevice.Boiler && this.control.HeatingDistribution.value == HeatingDistribution.Air)
    {
      return false;
    }

    return true;
  }

  public ShowDistributionInsulationPipesQuestion()
  {
    return this.ShowDistributionSystemTypeQuestion();
  }

  public ShowDistributionPipesThroughNonInsulatedRoomQuestion()
  {
    return this.ShowDistributionSystemTypeQuestion();
  }
  
  public ShowCWClassQuestion()
  {
    if (this.control.HeatingDevice.value == HeatingDevice.Combiboiler && (this.control.BoilerType.value == BoilerType.HR100 || this.control.BoilerType.value == BoilerType.HR104 || this.control.BoilerType.value == BoilerType.HR107))
    {
      return true;
    }

    return false;
  }

  public ShowGasHrLabelQuestion()
  {
    return this.ShowCWClassQuestion();
  }

  public ShowGeneratorPowerHreWKKQuestion()
  {
    return this.control.HeatingDevice.value == HeatingDevice.BoilerWithHeatPowerCoupling || this.control.HeatingDevice.value == HeatingDevice.CombiboilerHeatPowerCoupling;
  }

  // make enum accessibly in the HTML
  public HeatingType = HeatingType;
  public HeatingDevice = HeatingDevice;
  public HeatingDeviceEnergyType = HeatingDeviceEnergyType;
  public OtherFunctions = OtherFunctions;
  public HeatingDistribution = HeatingDistribution;
  public BoilerType = BoilerType;
  public HeatingPumpSource = HeatingPumpSource;
  public AllElectric = AllElectric;
  public VentilationSupply = VentilationSupply;
  public LocalHeatingType = LocalHeatingType;
  public Drain = Drain;
  public BiomassAccumulate = BiomassAccumulate;
  public DistributionSystemType = DistributionSystemType;
  public DistributionInsulationPipes = DistributionInsulationPipes;
  public DistributionPipesThroughNonInsulatedRoom = DistributionPipesThroughNonInsulatedRoom;
  public CWClass = CWClass;
  public GasHrLabel = GasHrLabel;
  public GeneratorPowerHreWKK = GeneratorPowerHreWKK;

  constructor()
  {
  }

  ngOnInit(): void {
    //console.log(this.system);
  }

}
