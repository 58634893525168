import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InfraConsumer } from '@consumers/InfraController';
import { ConstRequestModel } from '@models/ConstRequestModel';
import * as ISoftware from '@datacontracts/Constanten';
import { WctLogService } from '../services/WctLogService';
import { Observable }  from 'rxjs';
import { tap }  from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  public Software: ISoftware.ISoftware;

  constructor(private http: HttpClient, private consumer: InfraConsumer, private logservice: WctLogService ) {
  }

  loadobs(): Observable<any> {
    let me = this;
    let input = new ConstRequestModel();
    input.ProjectId = -1;
    const obs = this.consumer.Const_Observable(input).pipe(tap(x =>
      {
        me.Software = x.Contents;
        this.logservice.Const = me.Software;   // work around to break cyclic dependencies
        return x.Contents;
      }));

    return obs;
  }

  load(): Promise<any> {
    return this.loadobs().toPromise();
  }
}