import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SurveyCategories } from 'src/app/modules/survey/store/survey.state';

export interface treenode {
  id: string,
  image: string,
  title: string,
  category?: SurveyCategories,
  active: boolean,
  children: treenode[],
  tag?: any
}

@Component({
  selector: 'wct-stui-tree-menu',
  templateUrl: './wct-stui-tree-menu.component.html',
  styleUrls: ['./wct-stui-tree-menu.component.scss']
})
export class WctStuiTreeMenuComponent implements OnInit {
  @Input() tree: treenode[];
  @Output() nodeSelected = new EventEmitter<treenode>();

  constructor() { }

  ngOnInit(): void {
  }

  // setNodeActive(id: number, nodesArray: treenode[]) {
  //   nodesArray.forEach((node) => {
  //     if (node.id == id) {
  //       nodesArray.map((node) => {
  //         if(node.id !== id) {
  //           node.active = false;
  //         }
  //       })
  //       node.active = node.active ? false : true;
  //       if (node.active) {
  //         this.nodeSelected.emit(node);
  //       }
  //     }
  //     if (node.children.length > 0) {
  //       this.setNodeActive(id, node.children);
  //     }
  //   })
  // }

  trackByIndex(index, item) {
		return index;
	}

  setActive(node: treenode) {
    this.nodeSelected.emit(node);
  }
}
