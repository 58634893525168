
<div *ngIf="Laden">
   Laden...
</div>

<div *ngIf="!Laden">
   <div style="float: left; width: 45%; padding: 20px;">



      <mat-card>
         <mat-card-title>
            {{fVariant.Name}} Details
         </mat-card-title>
         <mat-card-content>
            <form>
               <fieldset [disabled]="update" style="border:none; margin: 12px;">
                  <p>
                     Dit product variant is een variant van het product <b>{{ProductViewModel.Name}}</b>. <br />
                     Wanneer de variant geen overschrijndende waardes heeft voor het Product worden blijven de waardes leeg. <br />
                     De waardes van de producten zijn beschreven in de placeholders
                  </p>
                  <p>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Name</mat-label>
                        <input matInput [(ngModel)]="fVariant.Name" [ngModelOptions]="{standalone: true}" ngModel required min-length="4" #name="ngModel">
                     </mat-form-field>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Bot</mat-label>
                        <input matInput [(ngModel)]="fVariant.Bot" [ngModelOptions]="{standalone: true}" ngModel required #kenmerk="ngModel" placeholder="{{ProductViewModel.Bot}}">
                     </mat-form-field>
                  </p>
                  <p>
                     <mat-form-field appearance="outline" floatLabel="always" style="width: 400px;">
                        <mat-label>Kenmerk</mat-label>
                        <input matInput [(ngModel)]="fVariant.Kenmerk" style="width: 400px;" [ngModelOptions]="{standalone: true}" ngModel placeholder="{{ProductViewModel.Kenmerk}}">
                     </mat-form-field>

                  </p>
                  <p>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO1 Type</mat-label>
                        <input matInput [(ngModel)]="fVariant.DO1Type" [ngModelOptions]="{standalone: true}" placeholder="{{ProductViewModel.DO1Type}}">
                     </mat-form-field>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Do1 Naam</mat-label>
                        <input matInput [(ngModel)]="fVariant.DO1Naam" [ngModelOptions]="{standalone: true}" placeholder="{{ProductViewModel.DO1Naam}}">
                     </mat-form-field>
                  </p>
                  <p>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO2 Type</mat-label>
                        <input matInput [(ngModel)]="fVariant.DO2Type" [ngModelOptions]="{standalone: true}" placeholder="{{ProductViewModel.DO2Type}}">
                     </mat-form-field>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO2 Naam</mat-label>
                        <input matInput [(ngModel)]="fVariant.DO2Naam" [ngModelOptions]="{standalone: true}" placeholder="{{ProductViewModel.DO2Naam}}">
                     </mat-form-field>
                  </p>
                  <p>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO3 Type</mat-label>
                        <input matInput [(ngModel)]="fVariant.DO3Type" [ngModelOptions]="{standalone: true}" placeholder="{{ProductViewModel.DO3Type}}">
                     </mat-form-field>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO3 Naam</mat-label>
                        <input matInput [(ngModel)]="fVariant.DO3Naam" [ngModelOptions]="{standalone: true}" placeholder="{{ProductViewModel.DO3Naam}}">
                     </mat-form-field>
                  </p>
                  <p>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO4 Type</mat-label>
                        <input matInput [(ngModel)]="fVariant.DO4Type" [ngModelOptions]="{standalone: true}" placeholder="{{ProductViewModel.DO4Type}}">
                     </mat-form-field>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO4 Naam</mat-label>
                        <input matInput [(ngModel)]="fVariant.DO4Naam" [ngModelOptions]="{standalone: true}" placeholder="{{ProductViewModel.DO4Naam}}">
                     </mat-form-field>
                  </p>
               </fieldset>
            </form>
            <div>
              <button mat-raised-button color="warn" (click)="Import()">Import</button>
              <button mat-raised-button color="warn" (click)="ResetImportFlags()">ResetImportFlags</button>
            </div>
            <div *ngIf="!update">
               <button mat-raised-button color="primary" (click)="addVariant()">Update Product Variant</button>
            </div>
            <div *ngIf="update">
               <button mat-raised-button color="accent" (click)="update = !update">Wijzig</button>
               <!-- <button mat-raised-button color="warn" (click)="Delete()">Verwijder</button> -->

            </div>

         </mat-card-content>
      </mat-card>
   </div>

   <div style="float: left; width: 45%; padding: 20px;">
      <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
         <mat-tab label="Filters">
            <div style="padding:25px;">
               <filter-variant-relation [ProductVariantId]="ProductVariantId"></filter-variant-relation>
            </div>
         </mat-tab>
         <mat-tab label="Properties">
            <div class="fab-admin__mat-tab">
               <h3>
                  Product Property
               </h3>

               <div *ngIf="showProductPropertyRelation" style="font-size: 16px;">
                  <b style="font-size: 1.6rem;margin: 15px 5px;display: flex;">Opbouw</b>
                  <div *ngFor="let property of fVariant.ProductPropertiesOpbouw" style="padding: 5px;">
                     <display-product-property [ProductProperty]="property" [ProductVariantId]="ProductVariantId" (onRefresh)="Refresh()"></display-product-property>
                  </div>
                  <b>Eigenschappen</b>
                  <div *ngFor="let property of fVariant.ProductPropertiesEigenschappen" style="padding: 5px;">
                     <display-product-property [ProductProperty]="property" [ProductVariantId]="ProductVariantId" (onRefresh)="Refresh()"></display-product-property>
                  </div>
                  <div>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Name</mat-label>
                        <input matInput [(ngModel)]="NewEigenschapName" [ngModelOptions]="{standalone: true}">
                     </mat-form-field>
                     <button mat-raised-button color="primary" (click)="AddEigenschap()">Add</button>
                  </div>
               </div>
              
               <!--Zet hier een div neer waar je een nieuwe property kan toevoegen-->
               <div *ngIf="!showProductPropertyRelation">
                  <p>
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Select Property</mat-label>
                        <mat-select matNativeControl [(ngModel)]="ProductProperty.Id">
                           <mat-option *ngFor="let property of ProductProperties.Property" [value]="property.Id">
                              {{property.Name}}
                           </mat-option>
                           <mat-option value="-1">
                              Voeg nieuwe type toe
                           </mat-option>
                        </mat-select>
                     </mat-form-field>
                  </p>
                  <p *ngIf="ProductProperty.Id == -1">
                     <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Product Property</mat-label>
                        <input matInput name="propertyName" [(ngModel)]="ProductProperty.Name" ngModel required min-length="4" #productproperty="ngModel">
                     </mat-form-field>
                  </p>
                  <div *ngIf="ProductProperty.Id == -1">
                     <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                           <mat-label>Select Property Type</mat-label>
                           <mat-select matNativeControl [(ngModel)]="ProductPropertyType.Id">
                              <mat-option *ngFor="let propertytype of ProductPropertyTypesViewModel.ProductPropertyType" [value]="propertytype.Id">
                                 {{propertytype.Name}}
                              </mat-option>
                              <mat-option value="-1">
                                 Voeg nieuwe type toe
                              </mat-option>
                           </mat-select>
                        </mat-form-field>
                     </p>
                     <p *ngIf="ProductPropertyType.Id == -1">
                        <mat-form-field appearance="outline" floatLabel="always">
                           <mat-label>Property Type</mat-label>
                           <input matInput name="propertyType" [(ngModel)]="ProductPropertyType.Name" ngModel required #type="ngModel">
                        </mat-form-field>
                     </p>
                  </div>

                  <p>
                     <button mat-raised-button color="primary" (click)="saveProductProperty()">Voeg Toe</button>
                  </p>
               </div>
            </div>
         </mat-tab>
      </mat-tab-group>
   </div>
</div>
