/**
 * @ref WoonConnectViewModel/ContractorOfferViewModel.cs
 */

 import * as DataContracts from "@datacontracts/ContractorOfferViewModel";

 
import * as ContractorOfferViewModelEnum from "@enums/ContractorOfferViewModel";
import * as Cost from "./Cost";
import * as PriceSetViewModel from "./PriceSetViewModel";
import * as ContractorViewModel from "./ContractorViewModel";
import * as TenderRequestViewModel from "./TenderRequestViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.ContractorOffersRequestModel
	 */
	 

	export interface IContractorOffersRequestModel {
		Test: string;
		ToJsonContract(): DataContracts.IContractorOffersRequestModel;
	}

	export class ContractorOffersRequestModel {
		// property-Properties
		public get Test(): string {
			return this._Test;
		}
		public set Test(newTest: string) {
			this._Test = newTest;
		}

		// field-Properties

		// fields
		protected _Test: string = "";

		constructor(data?: DataContracts.IContractorOffersRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IContractorOffersRequestModel): void {
			if(data) {
				// vul properties
				this._Test = data.Test;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IContractorOffersRequestModel {
			return {
				Test: this.Test
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ContractorOffersViewModel
	 */
	 

	export interface IContractorOffersViewModel {
		Offers: ContractorOfferViewModel[];
		ToJsonContract(): DataContracts.IContractorOffersViewModel;
	}

	export class ContractorOffersViewModel {
		// property-Properties
		public get Offers(): ContractorOfferViewModel[] {
			return this._Offers;
		}
		public set Offers(newOffers: ContractorOfferViewModel[]) {
			this._Offers = newOffers;
		}

		// field-Properties

		// fields
		protected _Offers: ContractorOfferViewModel[] = [];

		constructor(data?: DataContracts.IContractorOffersViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IContractorOffersViewModel): void {
			if(data) {
				// vul properties
				this._Offers = data.Offers ? data.Offers.map(contractorOfferViewModelItem => new ContractorOfferViewModel(contractorOfferViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IContractorOffersViewModel {
			return {
				Offers: (this.Offers ? this.Offers.map(contractorOfferViewModelItem => new ContractorOfferViewModel(contractorOfferViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ContractorOfferViewModel
	 */
	 

	export interface IContractorOfferViewModel {
		Id: number;
		Status: ContractorOfferViewModelEnum.ContractorOfferStatus;
		Explanation: string;
		Cost: Cost.ICost;
		FTE: number;
		PriceSet: PriceSetViewModel.IPriceSetViewModel;
		Contractor: ContractorViewModel.IContractorViewModel;
		TenderRequest: TenderRequestViewModel.ITenderRequestViewModel;
		IsExpanded: boolean;
		ToJsonContract(): DataContracts.IContractorOfferViewModel;
	}

	export class ContractorOfferViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Status(): ContractorOfferViewModelEnum.ContractorOfferStatus {
			return this._Status;
		}
		public set Status(newStatus: ContractorOfferViewModelEnum.ContractorOfferStatus) {
			this._Status = newStatus;
		}

		public get Explanation(): string {
			return this._Explanation;
		}
		public set Explanation(newExplanation: string) {
			this._Explanation = newExplanation;
		}

		public get Cost(): Cost.ICost {
			return this._Cost;
		}
		public set Cost(newCost: Cost.ICost) {
			this._Cost = newCost;
		}

		public get FTE(): number {
			return this._FTE;
		}
		public set FTE(newFTE: number) {
			this._FTE = newFTE;
		}

		public get PriceSet(): PriceSetViewModel.IPriceSetViewModel {
			return this._PriceSet;
		}
		public set PriceSet(newPriceSet: PriceSetViewModel.IPriceSetViewModel) {
			this._PriceSet = newPriceSet;
		}

		public get Contractor(): ContractorViewModel.IContractorViewModel {
			return this._Contractor;
		}
		public set Contractor(newContractor: ContractorViewModel.IContractorViewModel) {
			this._Contractor = newContractor;
		}

		public get TenderRequest(): TenderRequestViewModel.ITenderRequestViewModel {
			return this._TenderRequest;
		}
		public set TenderRequest(newTenderRequest: TenderRequestViewModel.ITenderRequestViewModel) {
			this._TenderRequest = newTenderRequest;
		}

		public get IsExpanded(): boolean {
			return this._IsExpanded;
		}
		public set IsExpanded(newIsExpanded: boolean) {
			this._IsExpanded = newIsExpanded;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Status: ContractorOfferViewModelEnum.ContractorOfferStatus = undefined;
		protected _Explanation: string = "";
		protected _Cost: Cost.ICost = new Cost.Cost(undefined);
		protected _FTE: number = 0;
		protected _PriceSet: PriceSetViewModel.IPriceSetViewModel = new PriceSetViewModel.PriceSetViewModel(undefined);
		protected _Contractor: ContractorViewModel.IContractorViewModel = new ContractorViewModel.ContractorViewModel(undefined);
		protected _TenderRequest: TenderRequestViewModel.ITenderRequestViewModel = new TenderRequestViewModel.TenderRequestViewModel(undefined);
		protected _IsExpanded: boolean = false;

		constructor(data?: DataContracts.IContractorOfferViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IContractorOfferViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Status = data.Status;
				this._Explanation = data.Explanation;
				this._Cost = data.Cost ? new Cost.Cost(data.Cost): undefined;
				this._FTE = data.FTE;
				this._PriceSet = data.PriceSet ? new PriceSetViewModel.PriceSetViewModel(data.PriceSet): undefined;
				this._Contractor = data.Contractor ? new ContractorViewModel.ContractorViewModel(data.Contractor): undefined;
				this._TenderRequest = data.TenderRequest ? new TenderRequestViewModel.TenderRequestViewModel(data.TenderRequest): undefined;
				this._IsExpanded = data.IsExpanded;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IContractorOfferViewModel {
			return {
				Id: this.Id,
				Status: this.Status,
				Explanation: this.Explanation,
				Cost: (this.Cost ? new Cost.Cost(this.Cost).ToJsonContract(): this.Cost),
				FTE: this.FTE,
				PriceSet: (this.PriceSet ? new PriceSetViewModel.PriceSetViewModel(this.PriceSet).ToJsonContract(): this.PriceSet),
				Contractor: (this.Contractor ? new ContractorViewModel.ContractorViewModel(this.Contractor).ToJsonContract(): this.Contractor),
				TenderRequest: (this.TenderRequest ? new TenderRequestViewModel.TenderRequestViewModel(this.TenderRequest).ToJsonContract(): this.TenderRequest),
				IsExpanded: this.IsExpanded
			}
		}
	}
