import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { ProductsConsumer } from '@consumers/ProductsController';
import { PrijslijstConsumer } from '@consumers/PrijslijstController';
import { ProductsViewModel, ProductViewModel, ProductVariantModel } from '@models/ProductsViewModel';
import { PrijslijstNewProductViewModel, PrijslijstNewProductFilterViewModel, ExcelsViewModel, PlaatjesViewModel, ExcelViewModel, UploadViewModel } from '@models/PrijslijstViewModel';
import { HttpClient, HttpRequest, HttpEventType, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ProductComponent {

  public InUse: boolean = false;

  public Loading = true;
  public LoadingPlaatjes = true;
  public ExcelsViewModel: ExcelsViewModel = null;
  public PlaatjesViewModel: PlaatjesViewModel = null;

  public Categorie: string = "";

  constructor(
    private http: HttpClient,
    private fab: FabrikantenConsumer,
    private prijslijst: PrijslijstConsumer,
    private prod: ProductsConsumer,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.LoadExcels();
    this.LoadPlaatjes();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.Categorie = params.get('categorieId');
    })
  }

  public Delete(plaatje: string) {
    if (confirm("Weet je het zeker?")) {
      this.LoadingPlaatjes = true;

      this.prijslijst.DeletePlaatje_Observable(plaatje).subscribe(() => {
        this.LoadPlaatjes();
      });
    }
  }

  public GetActiveExcel(): ExcelViewModel {

    if (this.ExcelsViewModel != null) {
      return this.ExcelsViewModel.ActiveExcel;
    }

    return null;
  }

  public GetOldExcels(): Array<ExcelViewModel> {

    if (this.ExcelsViewModel != null) {
      return this.ExcelsViewModel.OldExcels;
    }

    return null;
  }

  upload(files) {
    if (files.length === 0)
      return;

    this.Loading = true;

    const formData = new FormData();

    for (let file of files) {
      formData.append(file.name, file);
    }

    if (this.Categorie == null) {
      this.Categorie = "Profielen";
    }

    const request = new HttpRequest('POST', `api/Fabrikanten/UploadHttp`, formData);
    const authToken = "";// this.userservice.getAuthorizationToken();
    let headers = request.headers.set('Authorization', `Bearer ${authToken}`)
    const authReq = request.clone({
      headers: headers
    });
    this.http.request<void>(authReq).subscribe(r => {
      if (r.type == HttpEventType.Response) {
        this.LoadExcels();
      }
    });
  }

  uploadPlaatje(files) {
    if (files.length === 0)
      return;

    this.LoadingPlaatjes = true;

    const formData = new FormData();

    for (let file of files) {
      formData.append(file.name, file);
    }

    if (this.Categorie == null) {
      this.Categorie = "Profielen";
    }

    const request = new HttpRequest('POST', `api/Fabrikanten/UploadHttpPlaatje`, formData);
    const authToken = "";// this.userservice.getAuthorizationToken();
    let headers = request.headers.set('Authorization', `Bearer ${authToken}`)
    const authReq = request.clone({
      headers: headers
    });
    this.http.request<void>(authReq).subscribe(r => {
      if (r.type == HttpEventType.Response) {
        this.LoadPlaatjes();
      }
    });
  }

  private LoadExcels(): void {
    this.Loading = true;

    this.prijslijst.GetExcelsViewModel_Observable().subscribe((data) => {
      this.ExcelsViewModel = new ExcelsViewModel(data);
      this.Loading = false;
    });
  }

  private LoadPlaatjes(): void {
    this.LoadingPlaatjes = true;

    this.prijslijst.GetPlaatjesViewModel_Observable().subscribe((data) => {
      this.PlaatjesViewModel = new PlaatjesViewModel(data);
      this.LoadingPlaatjes = false;
    });
  }
}
