<div class="content">

  <div *ngIf="Loading" class="mat-progress-spinner__cont" style="position: absolute; background: rgba(255,255,255,0.9); width: 1440px; z-index: 1234; ">
    <div style="padding-top: 300px; padding-bottom: 300px; text-align: center;">
      <div>
        <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
      </div>
      <div>
        <p>De producten worden geladen</p>
      </div>
    </div>
  </div>

  <h1>Instructie</h1>

  <div *ngIf="!Loading">
    <textarea cols="20" class="tinymce" id="text-vak-in" name="text-vak-in" rows="2" style="width:100%; height: 500px;"></textarea>
    <br />
    <a href="#" onclick="return false;" (click)="SaveInstructie()">Opslaan</a>
    <br />
    <br />
  </div>
</div>
