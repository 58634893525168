import { animate, style, transition, trigger } from '@angular/animations';

export var FadeInOut:any[] = [
  trigger('FadeInOut', [
    transition('void => *', [style({opacity: 0}),
      animate('1s ease', style({opacity: 1.0}))
    ]),
    transition('* => void', [
      animate('1s ease', style({
        opacity: 0
      }))
    ])
  ])
]
