// [conv] import "(../FabAdminAddProduct).less";
import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductsConsumer } from '@consumers/ProductsController';
import { ProductViewModel, ProductPropertyViewModel, ProductRequestModel, ProductPropertiesViewModel } from '@models/ProductsViewModel';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


interface ProductPropertyTable {
   Id: number;
   Name: string;
   Type: string;
   Products: number;
   Filters: number;
   Variants: number;
}


@Component({
   selector: 'product-properties',
   templateUrl: './FabAdminProductProperties.html'
})
export class FabAdminProductPropertiesComponent {

   @Input() ProductId: number;
   ProductRelation: ProductRequestModel;
   ProductProperty: ProductPropertyViewModel = new ProductPropertyViewModel();
   ProductProperties: ProductPropertiesViewModel;

   displayedColumns: string[] = ['Id', 'Name', 'Type', 'Products', 'Filters', 'Variants'];

   dataSource = new MatTableDataSource<ProductPropertyTable>();


   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   @ViewChild(MatSort, { static: true }) sort: MatSort;

   constructor(private prod: ProductsConsumer, private route: ActivatedRoute, private router: Router) {
      this.Refresh();
   }

   public DeleteAll(): void {
      this.prod.DeleteAllProductProperties_Observable().subscribe((data) => {
         alert('Success');
      });
   }

   public Refresh(): void {
      if (this.ProductId == undefined) {
         this.ProductId = 0;
      }
      //

      this.ProductRelation = new ProductRequestModel();
      this.ProductRelation.Id = this.ProductId;

      this.ProductProperties = new ProductPropertiesViewModel();
      //this.prod.GetAllProperties_Observable(this.ProductProperties).subscribe((data) => {
      //    this.ProductProperties = new ProductPropertiesViewModel(data);
      //    let property_data = [];
      //    this.ProductProperties.Property.forEach(elem => {
      //        let property_node: any = {
      //            Id: elem.Id,
      //            Name: elem.Name,
      //            Type: elem.ProductPropertyType.Name,
      //            Products: elem.Products,
      //            Filters: elem.Filters,
      //            Variants: elem.Variants
      //        }
      //        property_data.push(property_node);
      //    });
      //    this.dataSource.data = property_data;
      //    console.log(this.dataSource);



      //})
   }
}
