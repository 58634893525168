<div class="breadcrumb">
  <a routerLink="">Home</a>
  <span>></span>
  <a routerLink="">{{ ActiveCategory }}</a>

  <div class="callToActionMain">
    <div class="callToAction" (click)="ContactOpen()">
      Vraag?<br />
      <div class="callToActionSubText">Meer informatie nodig?</div>
    </div>
    <div class="callToActionFold">
      <table style="width: 100%;">
        <tr>
          <td> <a href="tel:+0310492561020" style="color: #0078ff;">+31 (0)492 - 56 10 20</a></td>
        </tr>
        <tr>
          <td> <a href="mailto:info@reynaers.nl" style="color: #0078ff;">info@reynaers.nl</a></td>
        </tr>
      </table>
    </div>
  </div>
</div>

<div style="min-height: 700px;">
  <div class="content" *ngIf="Init">
    <div *ngIf="HasUnfilledMandatoryFilters() && ActiveProduct !== ''" style="margin-left: 100px; margin-right: 100px;">
      <div *ngIf="ActiveProduct != ''">
        <a (click)="DeselectActiveProduct()" class="reset"><img src="/Content/KlantSpecifiek/BCB21016/img/icon-arrow-left.svg" width="14" height="12"> Terug naar systeem keuze</a>
      </div>
      <div class="fab-filter__option-name-and-icon fab-filter__option-name-and-icon--required">
        <p>{{ GetFirstUnfilledMandatoryFilter().Name }} </p>
      </div>
      <div style="display: flex; flex-wrap: wrap;">
        <div *ngFor="let option of GetFirstUnfilledMandatoryFilter().Options" (click)="SelectOption(GetFirstUnfilledMandatoryFilter(), option)" class="product-tile">
          <div style="height: 167px; vertical-align: middle; display: table-cell;">
            <img src="/Content/KlantSpecifiek/BCB21016/img/{{option.Name}}.png" style="width: 200px; vertical-align: middle;" />
          </div>
          <div>
            {{ option.Name }}
          </div>
        </div>
      </div>
    </div>

    <div class="panel" style="padding-right: 40px; padding-top: 33px;" *ngIf="!HasUnfilledMandatoryFilters() || ActiveProduct == ''">
      <div class="bar" style="display: flex;">
        <table style="width: 100%;" cellpadding="0" cellspacing="0">
          <tr>
            <td class="header-step" [class.active-header-step]="ActiveStep == 1"></td>
            <td class="header-step" [class.active-header-step]="ActiveStep == 2"></td>
          </tr>
          <tr>
            <td class="step" [class.active-step]="ActiveStep == 1" [class.activelink]="true" (click)="SelectStep(1)">
              Technische specificaties
            </td>
            <td class="step" [class.active-step]="ActiveStep == 2" [class.activelink]="this.ActiveProduct != ''" (click)="SelectStep(2)">
              Oppervlaktebehandeling
            </td>
          </tr>
        </table>
      </div>
      <br />
      <div class="bar" style="display: flex;">
        <table style="width: 100%;">
          <tr>
            <td>
            </td>
            <td>
              <div style="text-align: right;">
                <a (click)="RemoveSelectedFilters()" class="reset">Keuzes herstellen</a>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div *ngFor="let filter of FabrikantenFilterResponseModel?.Filters">
        <filter *ngIf="ShouldDisplay(filter)" [FilterViewModel]="filter" [IsFilterFirstLevel]="FabrikantenFilterResponseModel.SelectedProduct == undefined" [HeeftCADAbstract]="FabrikantenFilterResponseModel.SelectedProduct?.HeeftCADAbstract" (FilterChanged)="Refresh()" (ValueChanged)="SetSavedValue($event.filterviewmodelid, $event.savedvalue)"></filter>
        <div style="clear: both;"></div>
      </div>
    </div>
    <div class="panel" *ngIf="ActiveProduct == ''" style="display: flex; flex-wrap: wrap;">
      <div *ngFor="let product of FabrikantenFilterResponseModel.Products" class="product-panel">
        <div class="img-block" (click)="SelectProduct(product)">
          <img class="img" src="{{ GetProductImageUrl(product) }}" />
        </div>
        <a class="product" (click)="SelectProduct(product)">{{ product.Naam }}</a>
      </div>
    </div>
    <div class="panel" *ngIf="!HasUnfilledMandatoryFilters() && ActiveProduct !== ''" style="background-color: #f4f6f7; text-align: left; padding: 40px;">
      <div>
        <table style="width: 100%;">
          <tr>
            <td>
              <span style="font-size: 50px;">
                {{ ActiveProduct }}
              </span>
            </td>
            <td style="text-align:right;">
              <wct-button *ngIf="(FabrikantenFilterResponseModel.SelectedProduct !== undefined) && FabrikantenFilterResponseModel.SelectedProduct.BCBWUIDLink != ''" buttonSize="small" buttonIconWct="wct-icon wct-icon-bouwconnect-circled" (click)="OpenBCB(FabrikantenFilterResponseModel.SelectedProduct.BCBWUIDLink)" style="width: 140px;">BCB link<i class="fal fa-info-circle" matTooltip="link naar dit product op de BouwConnect Bibliotheek online" matTooltipPosition="below" style="margin-left: 5px;"></i></wct-button>
            </td>
          </tr>
        </table>
      </div>
      <div *ngIf="ActiveProduct != ''">
        <a (click)="DeselectActiveProduct()" class="reset"><img src="/Content/KlantSpecifiek/BCB21016/img/icon-arrow-left.svg" width="14" height="12"> Terug naar systeem keuze</a>
      </div>
      <bestek *ngIf="(FabrikantenFilterResponseModel.SelectedProduct !== undefined) && (FabrikantenFilterResponseModel.VerplichteWaardenIngevuld == true)" [product]="FabrikantenFilterResponseModel.SelectedProduct" (setlastselectedview)="SetLastSelectedView('Bestek')" (setlastselectedproduct)="SetLastSelectedProduct($event.selectedproduct)"></bestek>
      <div style="clear: both;"></div>
    </div>
  </div>
</div>

<div *ngIf="Loading" class="mat-progress-spinner__cont" style="position:absolute; height: 100%; width: 100%; left: 0px; top:0px; background: rgba(255,255,255,0.5); text-align:center;">
  <div style="padding-top: 300px;">
    <div>
      <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
    </div>
    <div>
      <p>De producten worden geladen</p>
    </div>
  </div>
</div>
