/**
 * @ref WoonConnectViewModel/TenderRequestViewModel.cs
 */

 import * as DataContracts from "@datacontracts/TenderRequestViewModel";

 
import * as TenderViewModel from "./TenderViewModel";
import * as TenderRequestViewModelEnum from "@enums/TenderRequestViewModel";
import * as BuildingComponentImprovementViewModel from "./BuildingComponentImprovementViewModel";
import * as ContractorOfferViewModel from "./ContractorOfferViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.TenderRequestViewModel
	 */
	 

	export interface ITenderRequestViewModel {
		Id: number;
		Tender: TenderViewModel.ITenderViewModel;
		Status: TenderRequestViewModelEnum.TenderRequestStatus;
		ContractorParticipationStartDate: Date;
		ContractorParticipationEndDate: Date;
		ExecutionStartDate: Date;
		ExecutionEndDate: Date;
		Explanation: string;
		BuildingComponentImprovements: BuildingComponentImprovementViewModel.IBuildingComponentImprovementViewModel[];
		ContractorOffers: ContractorOfferViewModel.IContractorOfferViewModel[];
		IsSelected: boolean;
		AllowSetContractorParticipationStartDate: boolean;
		IsDeleteAllowed: boolean;
		ToJsonContract(): DataContracts.ITenderRequestViewModel;
	}

	export class TenderRequestViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Tender(): TenderViewModel.ITenderViewModel {
			return this._Tender;
		}
		public set Tender(newTender: TenderViewModel.ITenderViewModel) {
			this._Tender = newTender;
		}

		public get Status(): TenderRequestViewModelEnum.TenderRequestStatus {
			return this._Status;
		}
		public set Status(newStatus: TenderRequestViewModelEnum.TenderRequestStatus) {
			this._Status = newStatus;
		}

		public get ContractorParticipationStartDate(): Date {
			return this._ContractorParticipationStartDate;
		}
		public set ContractorParticipationStartDate(newContractorParticipationStartDate: Date) {
			this._ContractorParticipationStartDate = newContractorParticipationStartDate;
		}

		public get ContractorParticipationEndDate(): Date {
			return this._ContractorParticipationEndDate;
		}
		public set ContractorParticipationEndDate(newContractorParticipationEndDate: Date) {
			this._ContractorParticipationEndDate = newContractorParticipationEndDate;
		}

		public get ExecutionStartDate(): Date {
			return this._ExecutionStartDate;
		}
		public set ExecutionStartDate(newExecutionStartDate: Date) {
			this._ExecutionStartDate = newExecutionStartDate;
		}

		public get ExecutionEndDate(): Date {
			return this._ExecutionEndDate;
		}
		public set ExecutionEndDate(newExecutionEndDate: Date) {
			this._ExecutionEndDate = newExecutionEndDate;
		}

		public get Explanation(): string {
			return this._Explanation;
		}
		public set Explanation(newExplanation: string) {
			this._Explanation = newExplanation;
		}

		public get BuildingComponentImprovements(): BuildingComponentImprovementViewModel.IBuildingComponentImprovementViewModel[] {
			return this._BuildingComponentImprovements;
		}
		public set BuildingComponentImprovements(newBuildingComponentImprovements: BuildingComponentImprovementViewModel.IBuildingComponentImprovementViewModel[]) {
			this._BuildingComponentImprovements = newBuildingComponentImprovements;
		}

		public get ContractorOffers(): ContractorOfferViewModel.IContractorOfferViewModel[] {
			return this._ContractorOffers;
		}
		public set ContractorOffers(newContractorOffers: ContractorOfferViewModel.IContractorOfferViewModel[]) {
			this._ContractorOffers = newContractorOffers;
		}

		public get IsSelected(): boolean {
			return this._IsSelected;
		}
		public set IsSelected(newIsSelected: boolean) {
			this._IsSelected = newIsSelected;
		}

		public get AllowSetContractorParticipationStartDate(): boolean {
			return this._AllowSetContractorParticipationStartDate;
		}
		public set AllowSetContractorParticipationStartDate(newAllowSetContractorParticipationStartDate: boolean) {
			this._AllowSetContractorParticipationStartDate = newAllowSetContractorParticipationStartDate;
		}

		public get IsDeleteAllowed(): boolean {
			return this._IsDeleteAllowed;
		}
		public set IsDeleteAllowed(newIsDeleteAllowed: boolean) {
			this._IsDeleteAllowed = newIsDeleteAllowed;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Tender: TenderViewModel.ITenderViewModel = new TenderViewModel.TenderViewModel(undefined);
		protected _Status: TenderRequestViewModelEnum.TenderRequestStatus = undefined;
		protected _ContractorParticipationStartDate: Date = new Date();
		protected _ContractorParticipationEndDate: Date = new Date();
		protected _ExecutionStartDate: Date = new Date();
		protected _ExecutionEndDate: Date = new Date();
		protected _Explanation: string = "";
		protected _BuildingComponentImprovements: BuildingComponentImprovementViewModel.IBuildingComponentImprovementViewModel[] = [];
		protected _ContractorOffers: ContractorOfferViewModel.IContractorOfferViewModel[] = [];
		protected _IsSelected: boolean = false;
		protected _AllowSetContractorParticipationStartDate: boolean = false;
		protected _IsDeleteAllowed: boolean = false;

		constructor(data?: DataContracts.ITenderRequestViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITenderRequestViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Tender = data.Tender ? new TenderViewModel.TenderViewModel(data.Tender): undefined;
				this._Status = data.Status;
				this._ContractorParticipationStartDate = data.ContractorParticipationStartDate;
				this._ContractorParticipationEndDate = data.ContractorParticipationEndDate;
				this._ExecutionStartDate = data.ExecutionStartDate;
				this._ExecutionEndDate = data.ExecutionEndDate;
				this._Explanation = data.Explanation;
				this._BuildingComponentImprovements = data.BuildingComponentImprovements ? data.BuildingComponentImprovements.map(buildingComponentImprovementViewModelItem => new BuildingComponentImprovementViewModel.BuildingComponentImprovementViewModel(buildingComponentImprovementViewModelItem)): [];
				this._ContractorOffers = data.ContractorOffers ? data.ContractorOffers.map(contractorOfferViewModelItem => new ContractorOfferViewModel.ContractorOfferViewModel(contractorOfferViewModelItem)): [];
				this._IsSelected = data.IsSelected;
				this._AllowSetContractorParticipationStartDate = data.AllowSetContractorParticipationStartDate;
				this._IsDeleteAllowed = data.IsDeleteAllowed;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITenderRequestViewModel {
			return {
				Id: this.Id,
				Tender: (this.Tender ? new TenderViewModel.TenderViewModel(this.Tender).ToJsonContract(): this.Tender),
				Status: this.Status,
				ContractorParticipationStartDate: this.ContractorParticipationStartDate,
				ContractorParticipationEndDate: this.ContractorParticipationEndDate,
				ExecutionStartDate: this.ExecutionStartDate,
				ExecutionEndDate: this.ExecutionEndDate,
				Explanation: this.Explanation,
				BuildingComponentImprovements: (this.BuildingComponentImprovements ? this.BuildingComponentImprovements.map(buildingComponentImprovementViewModelItem => new BuildingComponentImprovementViewModel.BuildingComponentImprovementViewModel(buildingComponentImprovementViewModelItem).ToJsonContract()): []),
				ContractorOffers: (this.ContractorOffers ? this.ContractorOffers.map(contractorOfferViewModelItem => new ContractorOfferViewModel.ContractorOfferViewModel(contractorOfferViewModelItem).ToJsonContract()): []),
				IsSelected: this.IsSelected,
				AllowSetContractorParticipationStartDate: this.AllowSetContractorParticipationStartDate,
				IsDeleteAllowed: this.IsDeleteAllowed
			}
		}
	}
