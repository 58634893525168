import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import * as ng from 'angular';
import * as ModelsRetrieveDwellingTypeUseCaseModel from '@models/RetrieveDwellingTypeUseCaseModel';
import * as DataContractsKiesOptieDataUit from '@datacontracts/KiesOptieDataUit';
import * as HelpersMasterTypescript from '.././Helpers/MasterTypescript';
import * as ModelsWijzigEvent from '@models/WijzigEvent';
import * as DataContractsWijzigEvent from '@datacontracts/WijzigEvent';
import * as ConsumerInterfacesDwellingTypeController from '@consumerinterfaces/DwellingTypeController';
import * as ServicesEnergieAdviesComparisonTableService from '.././services/EnergieAdviesComparisonTableService';
import * as ServicesFatalErrorService from '.././services/FatalErrorService';
import * as ServicesProjectService from '.././services/ProjectService';
import * as ServicesKostenService from '.././services/KostenService';
import * as ServicesWoningEventService from '.././services/WoningEventService';
import * as ServicesWebBerichtService from '.././services/WebBerichtService';
import * as ServicesWoningService from '.././services/WoningService';
import * as HelpersBackwards from '.././Helpers/Backwards';
import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';
import * as ServicesOptieService from '.././services/OptieService';
import * as HelpersAngularEvent from '.././Helpers/AngularEvent';
import * as DataContractsKiesOptieDataIn from '@datacontracts/KiesOptieDataIn';
import * as ViewModelsWoningTypologie from '.././Models/WoningTypologie';
import * as ViewModelsWoningType from '.././Models/WoningType'; 
import * as ViewModelsWoningSelectie from '.././Models/WoningSelectie';
import * as ViewModelsWoningInstantie from '.././Models/WoningInstantie';
import * as ViewModelsWoning from '.././Models/Woning';
import * as ViewModelsIGebouwBrokKeuze from '.././Models/IGebouwBrokKeuze';
import * as ViewModelsGebouwBrokOptieKeuze from '.././Models/GebouwBrokOptieKeuze';
import * as ViewModelsGebouwBrok from '.././Models/GebouwBrok';
import * as ViewModelsBlok from '.././Models/Blok';
import * as ModelsWoningSelectie from '@models/WoningSelectie';

@Injectable({
    providedIn: 'root',
})
export class OptieEventService
{
    private m_ScopeEvents: _.Dictionary<number> = {};

	Broadcast(kiesOptieArgument: DataContractsKiesOptieDataIn.IKiesOptieDataIn) {
		this.optieAangepastEventEmitter.Emitter.BroadCast(HelpersAngularEvent.AngularEventArg.WithData(kiesOptieArgument, true, 0));
	}
    public get OptieAangepastEvent(): HelpersAngularEvent.AngularEvent<HelpersAngularEvent.AngularEventArg<DataContractsKiesOptieDataIn.IKiesOptieDataIn>> {
		return this.optieAangepastEventEmitter.Event;
    }
    
    private optieAangepastEventEmitter: HelpersAngularEvent.IAngularEventEmitter<HelpersAngularEvent.AngularEventArg<DataContractsKiesOptieDataIn.IKiesOptieDataIn>>;

    constructor()
    {
        this.optieAangepastEventEmitter = HelpersAngularEvent.AngularEvent.Default<HelpersAngularEvent.AngularEventArg<DataContractsKiesOptieDataIn.IKiesOptieDataIn>>("OptieService-event",null);
    }

    public GeefEventID(scope: ServicesOptieService.ScopeSelectie): number {
		const id = this.m_ScopeEvents[scope.Sleutel];
		if (id == null) {
			return -2;
			/* conversie van undefined? */
			//return null;
		}
		return id;
    }
    
    public  ZetEventNummer(event: DataContractsWijzigEvent.IWijzigEvent) {
		const sleutel = `${event.Scope}_${event.ID}_${event.Variant}_${event.BewonerID}`;
		
		this.m_ScopeEvents[sleutel] = event.Generatie;
	}


}