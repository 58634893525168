<div class="fab-admin__header">
  <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" (click)="goBack()">
    <i class="fas fa-step-backward"></i>
  </button>
  <!--<button mat-button [matMenuTriggerFor]="producten">Producten</button>
  <mat-menu #producten="matMenu">
    <button mat-menu-item >Producten Overzicht</button>
  </mat-menu>-->
  
  <button mat-button [routerLink]="'/fab-admin/products'">Producten</button>
  <button mat-button [routerLink]="'/fab-admin/filters'">Filters</button>
  <button mat-button [routerLink]="'/fab-admin/search-product'">Zoek</button>
  <button mat-button [routerLink]="'/fab-admin/koppel-filteroption'">Koppel</button>

</div>

<router-outlet></router-outlet>
