/**
 * @ref WoonConnectViewModel/ImageAnnotation/GetAllProjectsModel.cs
 */

 import * as DataContracts from "@datacontracts/GetAllProjectsModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetAllProjectsResponseModel
	 */
	 

	export interface IGetAllProjectsResponseModel {
		Projects: ImageAnnotationProject[];
	}

	export class GetAllProjectsResponseModel {
		// property-Properties
		public get Projects(): ImageAnnotationProject[] {
			return this._Projects;
		}
		public set Projects(newProjects: ImageAnnotationProject[]) {
			this._Projects = newProjects;
		}

		// field-Properties

		// fields
		protected _Projects: ImageAnnotationProject[] = [];

		constructor(data?: DataContracts.IGetAllProjectsResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetAllProjectsResponseModel): void {
			if(data) {
				// vul properties
				this._Projects = data.Projects ? data.Projects.map(imageAnnotationProjectItem => new ImageAnnotationProject(imageAnnotationProjectItem)): [];
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.ImageAnnotationProject
	 */
	 

	export interface IImageAnnotationProject {
		Id: number;
		Naam: string;
		ToJsonContract(): DataContracts.IImageAnnotationProject;
	}

	export class ImageAnnotationProject {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Naam(): string {
			return this._Naam;
		}
		public set Naam(newNaam: string) {
			this._Naam = newNaam;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Naam: string = "";

		constructor(data?: DataContracts.IImageAnnotationProject) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IImageAnnotationProject): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Naam = data.Naam;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IImageAnnotationProject {
			return {
				Id: this.Id,
				Naam: this.Naam
			}
		}
	}
