import { Component, OnInit, Inject, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
//import * as ng from 'angular';
//import * as ServicesProjectService from '../../services/ProjectService';
//import { ProjectService } from '../../../infrastructure/ajs-upgraded-providers';
import { ProjectService, IProjectService } from '../../../services/ProjectService';
import { DomSanitizer } from '@angular/platform-browser'


@Component({
	selector: 'wct-disclaimer',
  styleUrls: ['./WctDisclaimer.scss'],
	templateUrl: './WctDisclaimer.html'
})

export class WctDisclaimer {
		Tekst: any = "Geen disclaimer";
		BezigMetLaden: boolean = true;

		constructor(
			private projectservice: ProjectService,
			private sanitizer: DomSanitizer
		) {
'ngInject';
			this.HaalTekstOp();
		}

		HaalTekstOp() {
			this.projectservice.GeefDisclaimer().then((response) => {
        
				console.log("GeefDisclaimerTekst: AJAX success");
				this.Tekst = this.sanitizer.bypassSecurityTrustHtml(response);
				this.BezigMetLaden = false;
			}).catch((error) => {
				console.log("GeefDisclaimerTekst mislukt: " + error.status + " <-- and --> " + error.statusText);
			});
		}
	}

