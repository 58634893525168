/// <reference path='../consumerinterfaces/NoteController.ts' />
/**
 * @ref WoonConnectApiController/Survey/NoteController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/NoteController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as GetNotesModel from "@datacontracts/GetNotesModel";
import * as UpdateNoteModel from "@datacontracts/UpdateNoteModel";
import * as DeleteNoteModel from "@datacontracts/DeleteNoteModel";
import * as UploadNoteModel from "@datacontracts/UploadNoteModel";
import * as DownloadNoteModel from "@datacontracts/DownloadNoteModel";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class NoteConsumer implements Interface.INoteConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public GetNotes_Observable(inputModel: GetNotesModel.IGetNotesRequestModel & {ToJsonContract(noContent?: boolean): GetNotesModel.IGetNotesRequestModel}): Observable<GetNotesModel.IGetNotesResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/Note/GetNotes`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GetNotesModel.IGetNotesResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetNotes(inputModel: GetNotesModel.IGetNotesRequestModel & {ToJsonContract(noContent?: boolean): GetNotesModel.IGetNotesRequestModel}): ng.IPromise<GetNotesModel.IGetNotesResponseModel> {
				let obs = this.GetNotes_Observable(inputModel);
				let promise = new Promise<GetNotesModel.IGetNotesResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UpdateNote_Observable(inputModel: UpdateNoteModel.IUpdateNoteRequestModel & {ToJsonContract(noContent?: boolean): UpdateNoteModel.IUpdateNoteRequestModel}): Observable<UpdateNoteModel.IUpdateNoteResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/Note/UpdateNote`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UpdateNoteModel.IUpdateNoteResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UpdateNote(inputModel: UpdateNoteModel.IUpdateNoteRequestModel & {ToJsonContract(noContent?: boolean): UpdateNoteModel.IUpdateNoteRequestModel}): ng.IPromise<UpdateNoteModel.IUpdateNoteResponseModel> {
				let obs = this.UpdateNote_Observable(inputModel);
				let promise = new Promise<UpdateNoteModel.IUpdateNoteResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DeleteNote_Observable(inputModel: DeleteNoteModel.IDeleteNoteRequestModel & {ToJsonContract(noContent?: boolean): DeleteNoteModel.IDeleteNoteRequestModel}): Observable<DeleteNoteModel.IDeleteNoteResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/Note/DeleteNote`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<DeleteNoteModel.IDeleteNoteResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DeleteNote(inputModel: DeleteNoteModel.IDeleteNoteRequestModel & {ToJsonContract(noContent?: boolean): DeleteNoteModel.IDeleteNoteRequestModel}): ng.IPromise<DeleteNoteModel.IDeleteNoteResponseModel> {
				let obs = this.DeleteNote_Observable(inputModel);
				let promise = new Promise<DeleteNoteModel.IDeleteNoteResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UploadNote_Observable(dwellingid: number, userid: number, scenario: number, noteid: string): Observable<UploadNoteModel.IUploadNoteResponseModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Note/UploadNote`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UploadNoteModel.IUploadNoteResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UploadNote(dwellingid: number, userid: number, scenario: number, noteid: string): ng.IPromise<UploadNoteModel.IUploadNoteResponseModel> {
				let obs = this.UploadNote_Observable(dwellingid, userid, scenario, noteid);
				let promise = new Promise<UploadNoteModel.IUploadNoteResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DownloadNote_Observable(inputModel: DownloadNoteModel.IDownloadNoteRequestModel & {ToJsonContract(noContent?: boolean): DownloadNoteModel.IDownloadNoteRequestModel}): Observable<DownloadNoteModel.IDownloadNoteResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/Note/DownloadNote`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<DownloadNoteModel.IDownloadNoteResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DownloadNote(inputModel: DownloadNoteModel.IDownloadNoteRequestModel & {ToJsonContract(noContent?: boolean): DownloadNoteModel.IDownloadNoteRequestModel}): ng.IPromise<DownloadNoteModel.IDownloadNoteResponseModel> {
				let obs = this.DownloadNote_Observable(inputModel);
				let promise = new Promise<DownloadNoteModel.IDownloadNoteResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


