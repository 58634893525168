/**
 * @ref WoonConnectViewModel/HourlyTarifSetViewModel.cs
 */

 import * as DataContracts from "@datacontracts/HourlyTarifSetViewModel";

 
import * as HourlyTarifViewModel from "./HourlyTarifViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.HourlyTarifSetViewModel
	 */
	 

	export interface IHourlyTarifSetViewModel {
		Id: number;
		Name: string;
		Tarifs: HourlyTarifViewModel.IHourlyTarifViewModel[];
		ToJsonContract(): DataContracts.IHourlyTarifSetViewModel;
	}

	export class HourlyTarifSetViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Tarifs(): HourlyTarifViewModel.IHourlyTarifViewModel[] {
			return this._Tarifs;
		}
		public set Tarifs(newTarifs: HourlyTarifViewModel.IHourlyTarifViewModel[]) {
			this._Tarifs = newTarifs;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _Tarifs: HourlyTarifViewModel.IHourlyTarifViewModel[] = [];

		constructor(data?: DataContracts.IHourlyTarifSetViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IHourlyTarifSetViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._Tarifs = data.Tarifs ? data.Tarifs.map(hourlyTarifViewModelItem => new HourlyTarifViewModel.HourlyTarifViewModel(hourlyTarifViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IHourlyTarifSetViewModel {
			return {
				Id: this.Id,
				Name: this.Name,
				Tarifs: (this.Tarifs ? this.Tarifs.map(hourlyTarifViewModelItem => new HourlyTarifViewModel.HourlyTarifViewModel(hourlyTarifViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.HourlyTarifSetsViewModel
	 */
	 

	export interface IHourlyTarifSetsViewModel {
		Sets: HourlyTarifSetViewModel[];
		ToJsonContract(): DataContracts.IHourlyTarifSetsViewModel;
	}

	export class HourlyTarifSetsViewModel {
		// property-Properties
		public get Sets(): HourlyTarifSetViewModel[] {
			return this._Sets;
		}
		public set Sets(newSets: HourlyTarifSetViewModel[]) {
			this._Sets = newSets;
		}

		// field-Properties

		// fields
		protected _Sets: HourlyTarifSetViewModel[] = [];

		constructor(data?: DataContracts.IHourlyTarifSetsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IHourlyTarifSetsViewModel): void {
			if(data) {
				// vul properties
				this._Sets = data.Sets ? data.Sets.map(hourlyTarifSetViewModelItem => new HourlyTarifSetViewModel(hourlyTarifSetViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IHourlyTarifSetsViewModel {
			return {
				Sets: (this.Sets ? this.Sets.map(hourlyTarifSetViewModelItem => new HourlyTarifSetViewModel(hourlyTarifSetViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.HourlyTarifSetRequestModel
	 */
	 

	export interface IHourlyTarifSetRequestModel {
		Temp: number;
		ToJsonContract(): DataContracts.IHourlyTarifSetRequestModel;
	}

	export class HourlyTarifSetRequestModel {
		// property-Properties
		public get Temp(): number {
			return this._Temp;
		}
		public set Temp(newTemp: number) {
			this._Temp = newTemp;
		}

		// field-Properties

		// fields
		protected _Temp: number = 0;

		constructor(data?: DataContracts.IHourlyTarifSetRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IHourlyTarifSetRequestModel): void {
			if(data) {
				// vul properties
				this._Temp = data.Temp;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IHourlyTarifSetRequestModel {
			return {
				Temp: this.Temp
			}
		}
	}
