<!-- Algemeen -->
<wct-survey-question-group groupname="Algemeen">

  <wct-survey-question question="Massa constructie">
    <mat-radio-group [ngrxFormControlState]="system.controls.ConstructionMass">
      <mat-radio-button class="mat-body-1" [value]="ConstructionMass.LessThan250">Massa constructie < 250</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="ConstructionMass.Between250And500">250 <= Massa constructie < 500</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="ConstructionMass.Between500And750">500 <= Massa constructie < 750</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="ConstructionMass.MoreThan750">Massa constructie >= 750</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question question="Verticale doorvoeren">
    <mat-radio-group [ngrxFormControlState]="system.controls.ConduitType">
      <mat-radio-button class="mat-body-1" [value]="ConduitType.NoConduit">Geen verticale doorvoeren</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="ConduitType.Uninsulated">Ongeïsoleerde doorvoeren</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="ConduitType.Insulated">Geïsoleerde doorvoeren</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question question="Aantal verticale leidingen">
    <wct-survey-string [prop]='system.controls.NumberOfConduits'></wct-survey-string>
  </wct-survey-question>

  <wct-survey-question question="Aantal verdiepingen">
    <wct-survey-string [prop]='system.controls.NumberOfFloors'></wct-survey-string>
  </wct-survey-question>

  <wct-survey-question question="Specifieke luchtdoorlatendheid">
    <wct-survey-string [prop]='system.controls.SpecificInfiltration'></wct-survey-string>
  </wct-survey-question>

</wct-survey-question-group>
