import * as _ from 'lodash';
import * as ViewModelsKostenHoofdstuk from '.././Models/KostenHoofdstuk';
import * as ViewModelsKostenPrijsregel from '.././Models/KostenPrijsregel';




	export enum KostenElementType {
		WoningElement,
		BlokElement,
	}

	export class KostenElement {
		Naam: string;
		Bouwjaar: number;
		Locatie: string;
		Code: string;
		GUID: string;
		PrijsRegels: Array<ViewModelsKostenPrijsregel.KostenPrijsregel> = [];
		SubElementen: Array<KostenElement> = [];
		Tonen: boolean;
		ElementType: KostenElementType;


		constructor(public Hoofdstuk: ViewModelsKostenHoofdstuk.KostenHoofdstuk, data: any
		) {
			Object.assign(this, data)
			//ng.extend(this, data);
			this.PrijsRegels = _.map(data.PrijsRegels, (p) => { return new ViewModelsKostenPrijsregel.KostenPrijsregel(this, p); });

			this.Naam = data.Naam;
			this.Bouwjaar = data.Bouwjaar;
			this.Locatie = data.Locatie;
			this.Code = data.Code;
			this.ElementType = data.ElementType;
			this.Tonen = false;

			this.SubElementen = _.map(data.SubElementen, (s) => {
				return new KostenElement(Hoofdstuk, s);
			});
		}

		MaakLeeg() {
			this.Tonen = false;
			_.each(this.PrijsRegels, (p) => {
				p.MaakLeeg();
			});
			_.each(this.SubElementen, (s) => {
				s.MaakLeeg();
			});
		}
	}
