<wct-bezig [bezigmodel]="bezigViewModel">
	<div class="flex-cont cont-title">
		<p class="txt-title">Eigenschappen van {{ document?.Bestand }} wijzigen</p>
		<img (click)="cancel()" [src]="'/Content/Images/menu/menu-close-white.svg'" class="img-title" />
	</div>

	<div class="cont-main">
		<wct-rechten-control [rechten]="document?.Rechten"></wct-rechten-control>
	</div>

	<div class="col-xs-12 flex-cont cont-btns">
		<wct-button buttonText="Annuleren" buttonSize="small" buttonRole="secundary" (click)="cancel()"></wct-button>
		<wct-button buttonText="Opslaan" buttonSize="small" buttonRole="primary" (click)="save()"></wct-button>
	</div>
</wct-bezig>