<div class="dialog__header">
  <p *ngIf="!IsIntern" class="dialog__title">Oeps, er gaat wat mis...</p>
  <i *ngIf="!IsIntern" (click)="ReloadPage()" class="fal fa-times"></i>

  <p *ngIf="IsIntern" class="dialog__title">Fout opgetreden</p>
  <i *ngIf="IsIntern" (click)="closeDialog()" class="fal fa-times"></i>
</div>
<div class="dialog__main">
  <div *ngIf="!IsIntern" class="dialog__main-extern">
    <img src="/Content/Images/configurator/icon-error.svg" />
    <p>De pagina moet helaas opnieuw worden geopend.</p>
  </div>
  <div *ngIf="IsIntern">
    <p>De fout heeft de volgende omschrijving:<br>
      {{Context}}<br>
      {{Error}}
    </p>
    <div *ngIf="HttpError != null" class="error-dailog__http-error">
      HttpError: <div [innerHTML]="HttpError"></div>
      ExceptionMessage: {{HttpError.ExceptionMessage}}<br>
      ExceptionType: {{HttpError.ExceptionType}}<br>
      Message: {{HttpError.Message}}<br>
      StackTrace: {{HttpError.StackTrace}}<br>
    </div>
  </div>
</div>
<div class="dialog__btns">
  <button *ngIf="!IsIntern" (click)="ReloadPage()" class="dialog__btn__primary">Open pagina opnieuw</button>
  <button *ngIf="IsIntern" (click)="closeDialog()" class="dialog__btn__primary">Ok</button>
</div>
