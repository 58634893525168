import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { ProductsConsumer } from '@consumers/ProductsController';
import { ProductsViewModel, ProductViewModel } from '@models/ProductsViewModel';


@Component({
  selector: 'categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CategorieComponent {

  public Loading: boolean = true;
  public ProductsViewModel: ProductsViewModel = null;

  constructor(
    private fab: FabrikantenConsumer,
    private prod: ProductsConsumer,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.LoadCategorieen();
  }

  public GetEigenschappen(pProductViewModel: ProductViewModel): string {
    var eigenschappen = "";

    _.forEach(pProductViewModel.FilterRelations, filter => {
      if (eigenschappen != "") {
        eigenschappen += ", ";
      }

      eigenschappen += filter.Name;
    });

    return eigenschappen;
  }

  private LoadCategorieen(): void {
    //this.Loading = true;

    //var model = new ProductsViewModel();

    //this.prod.GetProductsWithFilters_Observable(model).subscribe((data) => {
    //  this.ProductsViewModel = new ProductsViewModel(data);
    //  this.Loading = false;
    //});
  }
}
