// [conv] import "(./Buildings).less";
import { Component, Input, ViewChild } from '@angular/core';
import * as _ from 'lodash';


import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BuildingRetrieveRequestModel, RowCountViewModel, AddressesViewModel } from '@models/BuildingViewModels';
import { BuildingManagementConsumer } from '@consumers/BuildingManagementController';

interface BuildingTable {
    Id: number;
    Straat: string;
    Nummer: string;
    Postcode: string;
    Plaats: string;
    Land: string;
    Latitude: string;
    Longitude: string;
}



@Component({
    selector: 'buildings',
  templateUrl: './Buildings.html'
})
export class WoonBuildingsComponent {
    displayedColumns: string[] = ['Id', 'Straat', 'Nummer', 'Postcode', 'Plaats', 'Land', 'Options'];
    Laden: boolean = true;
    BuildingRetrieveRequestModel: BuildingRetrieveRequestModel;
    AddressesViewModel: AddressesViewModel;
    RowCountViewModel: RowCountViewModel = new RowCountViewModel();
    dataSource = new MatTableDataSource<BuildingTable>();
    pageOptions = [50, 100, 200, 500, 1000];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;


    constructor(private building: BuildingManagementConsumer) {
        this.BuildingRetrieveRequestModel = new BuildingRetrieveRequestModel();

        this.BuildingRetrieveRequestModel.Page = 0;
        this.BuildingRetrieveRequestModel.PageSize = this.pageOptions[0];

        this.Refresh();

    }
    ngOnInit() {


    }


    public Refresh(): void {
        this.Laden = false;
       

        console.log(this.BuildingRetrieveRequestModel);
        //Get All Users
        this.building.GetAddresses_Observable(this.BuildingRetrieveRequestModel).subscribe((data) => {

            this.AddressesViewModel= new AddressesViewModel(data);
            let builders_data = [];
            this.AddressesViewModel.Addresses.forEach(elem => {

                let builders_node: any = {
                    Id: elem.Id,
                    Straat: elem.Straat,
                    Nummer: elem.Nummer,
                    Postcode: elem.Postcode,
                    Plaats: elem.Plaats,
                    Land: elem.Land,
                    Latitude: elem.Latitude,
                    Longitude: elem.Longitude,
                }
                builders_data.push(builders_node);
            })
            this.dataSource.data = builders_data;
            console.log(this.dataSource);
            this.Laden = true;

        })

        this.building.CountBuildings_Observable(this.RowCountViewModel).subscribe((data) => {
            this.RowCountViewModel = new RowCountViewModel(data);
        });

        
    }

    pageChange(event) {
        console.log(event);
        this.BuildingRetrieveRequestModel.Page = event.pageIndex;
        this.BuildingRetrieveRequestModel.PageSize = event.pageSize;

        this.Refresh();
    }

    sortData(event) {
        console.log(event);

        this.BuildingRetrieveRequestModel.sortOrder = event.active;
        this.BuildingRetrieveRequestModel.sortDirection = event.direction;

        console.log()
        this.Refresh();
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    

}
