/**
 * @ref WoonConnectKern/Models/Selectie/WoningSelectie.cs
 */

 import * as DataContracts from "@datacontracts/WoningSelectie";

 
import * as WoningSelectieEnum from "@enums/WoningSelectie";
import * as WijzigScopeEnumEnum from "@enums/WijzigScopeEnum";



	/**
	 * Originele namespace: WoonConnectKern.Models.Selectie.WoningSelectie
	 */
	 

	export interface IWoningSelectie {
		DwellingSelectionType: WoningSelectieEnum.DwellingSelectionType;
		Scope: WijzigScopeEnumEnum.WijzigScopeEnum;
		ID: number;
		BewonerID: number;
		Scenario: number;
		ToJsonContract(): DataContracts.IWoningSelectie;
	}

	export class WoningSelectie {
		// property-Properties
		public get DwellingSelectionType(): WoningSelectieEnum.DwellingSelectionType {
			return this._DwellingSelectionType;
		}
		public set DwellingSelectionType(newDwellingSelectionType: WoningSelectieEnum.DwellingSelectionType) {
			this._DwellingSelectionType = newDwellingSelectionType;
		}

		public get Scope(): WijzigScopeEnumEnum.WijzigScopeEnum {
			return this._Scope;
		}
		public set Scope(newScope: WijzigScopeEnumEnum.WijzigScopeEnum) {
			this._Scope = newScope;
		}

		public get ID(): number {
			return this._ID;
		}
		public set ID(newID: number) {
			this._ID = newID;
		}

		public get BewonerID(): number {
			return this._BewonerID;
		}
		public set BewonerID(newBewonerID: number) {
			this._BewonerID = newBewonerID;
		}

		public get Scenario(): number {
			return this._Scenario;
		}
		public set Scenario(newScenario: number) {
			this._Scenario = newScenario;
		}

		// field-Properties

		// fields
		protected _DwellingSelectionType: WoningSelectieEnum.DwellingSelectionType = undefined;
		protected _Scope: WijzigScopeEnumEnum.WijzigScopeEnum = undefined;
		protected _ID: number = 0;
		protected _BewonerID: number = 0;
		protected _Scenario: number = 0;

		constructor(data?: DataContracts.IWoningSelectie) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IWoningSelectie): void {
			if(data) {
				// vul properties
				this._DwellingSelectionType = data.DwellingSelectionType;
				this._Scope = data.Scope;
				this._ID = data.ID;
				this._BewonerID = data.BewonerID;
				this._Scenario = data.Scenario;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IWoningSelectie {
			return {
				DwellingSelectionType: this.DwellingSelectionType,
				Scope: this.Scope,
				ID: this.ID,
				BewonerID: this.BewonerID,
				Scenario: this.Scenario
			}
		}
	}
