
import { Component, Input, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { UserManagementConsumer } from '@consumers/UserManagementController';
import { UserViewModel, UsersViewModel, UsersCountViewModel, MultipleVerificationsViewModel } from '@models/UserViewModel';
import {
    UserRetrieveRequestModel, SortOrderViewModel, UserRetrieveCriterea} from '@models/UserRetrieveModel';

import { UserRetrieveCritereaType } from '@enums/UserRetrieveModel';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Sort } from '../Helpers/page';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ProjectSettingsService } from '../../services/ProjectSettingsService';
import { IProjectSettingsAngular } from '@datacontracts/ProjectSettingsModel';
import { ProjectService } from '../../services/ProjectService';
import { WctAdminUsersDialog } from "./WctAdminUsersDialog";
import { UserAddRequestModel } from '@models/UserAddModel';


interface UserTable{
    UserName : string;
    Id: number;
    Name: string;
    VerificatieCode: string;
    Verificatie: boolean;
    LastSeen: Date;
}
@Component({
    selector: 'wct-admin-users',
    templateUrl: './WctAdminUsers.html',
    styleUrls: ['./WctAdminUsers.scss']
})
export class WctAdminUsersComponent {
    displayedColumns: string[] = ['Id', 'UserName', 'Name', 'VerificatieCode', 'LastSeen', 'Adres'];
    Laden: boolean = true;
    UserViewModel: UserViewModel;
    UserRetrieveRequestModel: UserRetrieveRequestModel;
    UsersViewModel: UsersViewModel;
    SortOrderViewModel: SortOrderViewModel = new SortOrderViewModel();
    UsersCountViewModel: UsersCountViewModel = new UsersCountViewModel();
    dataSource = new MatTableDataSource<UserTable>();
    filterSubject = new Subject<string>();
    pageOptions = [50, 100, 200, 500, 1000];
    overwriteVerificationCodes: boolean = false;
    projectSettings: Array<IProjectSettingsAngular> = []
    allUsers: UsersViewModel;
    userButtonDisabled: boolean = true;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    public Filter: string = "";


    constructor(
      private user: UserManagementConsumer,
      private projectSettingsService: ProjectSettingsService,
      private projectService: ProjectService,
      private dialog: MatDialog
      ) {
        this.UserRetrieveRequestModel = new UserRetrieveRequestModel();

        this.UserRetrieveRequestModel.Page = 0;
        this.UserRetrieveRequestModel.PageSize = this.pageOptions[0];

        this.projectSettingsService.GeefProjectSettingsHuidigeProject(this.projectService.GeefProjectId()).subscribe(response => {
            this.projectSettings = response;
        });

        this.user.GetUsers_Observable().subscribe(data => {
            this.allUsers = new UsersViewModel(data);
            this.userButtonDisabled = false;
        })

        this.Refresh();

        this.filterSubject.pipe(debounceTime(250)).subscribe((x) =>
        {
            this.Refresh();
        });

    }



    ngOnInit() {
        //this.dataSource.filterPredicate = (data: UserTable, filter: string) => {
            this.filterSubject.next("");
    }


    public Refresh(): void {
        this.Laden = false;
        if (this.UsersViewModel == undefined) {
            this.UsersViewModel = new UsersViewModel();
        }

        //Get All Users

        this.reload();

        this.user.CountUsers_Observable(this.UsersCountViewModel).subscribe((data) => {
            this.UsersCountViewModel = new UsersCountViewModel(data);
        })
    }

    reload() {
        let crit = new UserRetrieveCriterea();
        crit.UserRetrieveCritereaType = UserRetrieveCritereaType.Any;
        crit.Critera = this.Filter;
        this.UserRetrieveRequestModel.SearchCriterea = [ crit ];
        this.user.SelectUsers_Observable(this.UserRetrieveRequestModel).subscribe((data) => {
            this.UsersViewModel = new UsersViewModel(data);

            this.dataSource.data = this.filter();
            this.Laden = true;

        })
    }

    match(vm: UserViewModel): boolean
    {
        if (this.Filter.length == 0)
        {
            return true;
        }
        if (vm.UserName.toLocaleLowerCase().indexOf(this.Filter.toLocaleLowerCase()) >= 0)
        {
            return true;
        }
        if (vm.FirstName.toLocaleLowerCase().indexOf(this.Filter.toLocaleLowerCase()) >= 0)
        {
            return true;
        }
        if (vm.LastName.toLocaleLowerCase().indexOf(this.Filter.toLocaleLowerCase()) >= 0)
        {
            return true;
        }

        return false;
    }

    filter(): UserTable[] {
        let users_data = [];
        this.UsersViewModel.Users.forEach(elem     => {

            if (this.match(elem))
            {
                let users_node: any = {
                    Id: elem.PersoonId,
                    UserName: elem.UserName,
                    VerificatieCode: elem.VerificatieCode,
                    Verificatie: elem.Verificatie,
                    LastSeen: elem.LastSeen,
                    Adres: elem.MainResidentDwelling.Straat + ' ' + elem.MainResidentDwelling.Nummer + ' ' + elem.MainResidentDwelling.Plaats,
                    Name: elem.FirstName + ' ' + elem.LastName
                }
                users_data.push(users_node);
            }
        })
        return users_data;
    }

    pageChange(event) {
        this.UserRetrieveRequestModel.Page = event.pageIndex * event.pageSize;
        this.UserRetrieveRequestModel.PageSize = event.pageSize;

        this.Refresh();
    }

    sortData(event) {
        this.UserRetrieveRequestModel.sortOrder = event.active;
        this.UserRetrieveRequestModel.sortDirection = event.direction;

        this.Refresh();
    }

    applyFilter(event: Event) {
        this.filterSubject.next(this.Filter);
    }

    generateVerificationCodes() {
        if (this.allUsers) {
            if (confirm("Weet je zeker dat je de verificatiecodes wilt wijzigen? Dit kan enkele minuten duren.")) {
                let verificationCodeLength = parseInt(this.projectSettings.find(setting => setting.Naam === 'VerificatieCodeLengte').Waarde);
                let verificationCodeUsableChars = this.projectSettings.find(setting => setting.Naam === 'VerificatieCodeUseableChars').Waarde;
                let verificationRequest = new MultipleVerificationsViewModel();
                let verificationCodes = [];
                let userIds = [];
                this.allUsers.Users.forEach(element => {
                    if(this.overwriteVerificationCodes || !element.VerificatieCode) {
                        userIds.push(element.PersoonId);
                    } 
                });
                for( let i = 0; i < userIds.length; i++){
                    let result = '';
                    let numberOfCharacters = verificationCodeUsableChars.length;
                    for ( var j = 0; j < verificationCodeLength; j++ ) {
                        result += verificationCodeUsableChars.charAt(Math.floor(Math.random() * numberOfCharacters));
                    }
                    
                    verificationCodes.push(result);
                }
                verificationRequest.Ids = userIds;
                verificationRequest.VerificatieCodes = verificationCodes;
                this.user.SetMultipleVerifications_Observable(verificationRequest).subscribe(() => {
                    alert('Verificatiecodes gewijzigd!')
                    this.Refresh();
                });
            }
        } else {
            alert('Even geduld aub, de gebruikers zijn nog niet allemaal ingeladen.');
        }
    }

    ShowDialogAddNewUser() {
        let name = prompt('Geef nieuwe gebruikersnaam');
        let addRequest = new UserAddRequestModel();
        addRequest.AccountNaam = name;
        addRequest.Voornaam = '-';
        addRequest.Achternaam = '-';
        this.user.Add_Observable(addRequest).subscribe(x =>
            {
                if (x.Succeeded)
                {
                    this.reload();
                }
                else
                {
                    alert(x.Message);
                }
            });
            
        // if (this.aanpasbaar) {
    //   const dialogConfig = new MatDialogConfig();
    //   dialogConfig.data = {
    //     profielnaam: this.profielnaam,
    //     personen: this.Personen,
    //     persoon: persoon,
    //     persoonid: this.persoonid
    //   };
    //   dialogConfig.panelClass = "energybuddy--mat-dialog";

    //   const dialogRef = this.dialog.open(WctHuishoudenPersoonComponent, dialogConfig);
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.GeefPersonen();
    //     this.gaDoorrekenen();
    //       // this.BerekenAantallen();
    //       this.UpdateBreedtesVanPersonenInUI();
    //   });
    // }

    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.panelClass = "mat-dialog__no-margin";

    // //this.dialog.open(WctDisclaimer);
    // const dialogRef = this.dialog.open(WctAdminUsersDialog, dialogConfig);
    }
}
