import * as ServicesAfbeeldingControlerenService from '../../services/AfbeeldingControlerenService';
import * as ServicesAccountService from '../../services/AccountService';
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from '../../services/TranslateService';
import { AccountService } from '../../services/AccountService';
import { AppConfigService } from '../../infrastructure/app.configservice';
import { Router,ActivatedRoute } from '@angular/router';

// interface IWachtwoordVergetenBindings {
// 	code: string;
// }

// export interface IWachtwoordVergetenController extends IWachtwoordVergetenBindings {
// }

@Component({
	selector: 'wct-forgot-password',
	templateUrl: './WctForgotPassword.html',
	styleUrls: ['./WctForgotPassword.scss'],
})
export class WctForgotPasswordComponent {

	//class WachtwoordVergetenController implements IWachtwoordVergetenController {
	code: string;
	codeGeldig: boolean = true;

	wachtwoord: string;
	wachtwoordBevestig: string;
	wachtwoordLengte: number;

	userName: string;
	Emailadres: string = "";

	bezigMetLaden: boolean = true;
	klaarMetWijzigen: boolean = false;

	error: string = "";

	constructor(private accountservice: ServicesAccountService.AccountService, private afbeeldingcontrolerenservice: ServicesAfbeeldingControlerenService.AfbeeldingControlerenService, private router: Router, private route:ActivatedRoute) {
		//this.code = $stateParams.code;

		
	}

	ngOnInit(){
        // 'bank' is the name of the route parameter
        this.code = this.route.snapshot.params['code'];

		this.accountservice.GeefVeranderdWachtwoordModel(this.code).subscribe(m => {
			this.userName = m.User;
			this.codeGeldig = m.GeldigeCode;
			this.wachtwoordLengte = m.WachtwoordLengte;
			this.Emailadres = m.Emailadres;

			this.bezigMetLaden = false;
		});
    }

	ToonImageWachtwoordVergeten() {
		let urlAfbeelding = this.afbeeldingcontrolerenservice.geefAfbeelding('wachtwoord-vergeten');
		return urlAfbeelding;
	}

	public TerugNaarLogin(): void {
		this.router.navigate(['/']);
	}

	public WachtwoordBevestigHulp(): boolean {
		if (this.wachtwoord !== undefined) {
			if (this.wachtwoord.length < this.wachtwoordLengte) {
				this.error = "Het wachtwoord is niet lang genoeg (minimaal " + this.wachtwoordLengte + " karakters)";
				return true;
			}

			if (this.wachtwoord != this.wachtwoordBevestig) {
				this.error = "De ingevoerde wachtwoorden komen niet overeen";
				return true;
			}
		}

		this.error = "";
	}

	public Aanpassen(): void {
		if (this.error == "") {
			this.bezigMetLaden = true;
			this.accountservice.WachtwoordVeranderen(this.code, this.wachtwoord).then(a => {

				this.bezigMetLaden = false;

				if (a == "" || a == null) {
					this.klaarMetWijzigen = true;
				}
				else {
					alert(a);
				}
			});
		}
	}
}