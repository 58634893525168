/**
 * @ref WoonConnectViewModel/Account/AccountActivationModel.cs
 */

 import * as DataContracts from "@datacontracts/AccountActivationModel";

 
import * as AccountActivationStatus from "./AccountActivationStatus";



	/**
	 * Originele namespace: WoonConnectViewModel.Account.AccountActivationRequestModel
	 */
	 

	export interface IAccountActivationRequestModel {
		ActivationCode: string;
		ActivationModel: AccountActivationStatus.IAccountActivationStatus;
		ToJsonContract(): DataContracts.IAccountActivationRequestModel;
	}

	export class AccountActivationRequestModel {
		// property-Properties
		public get ActivationCode(): string {
			return this._ActivationCode;
		}
		public set ActivationCode(newActivationCode: string) {
			this._ActivationCode = newActivationCode;
		}

		public get ActivationModel(): AccountActivationStatus.IAccountActivationStatus {
			return this._ActivationModel;
		}
		public set ActivationModel(newActivationModel: AccountActivationStatus.IAccountActivationStatus) {
			this._ActivationModel = newActivationModel;
		}

		// field-Properties

		// fields
		protected _ActivationCode: string = "";
		protected _ActivationModel: AccountActivationStatus.IAccountActivationStatus = new AccountActivationStatus.AccountActivationStatus(undefined);

		constructor(data?: DataContracts.IAccountActivationRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAccountActivationRequestModel): void {
			if(data) {
				// vul properties
				this._ActivationCode = data.ActivationCode;
				this._ActivationModel = data.ActivationModel ? new AccountActivationStatus.AccountActivationStatus(data.ActivationModel): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAccountActivationRequestModel {
			return {
				ActivationCode: this.ActivationCode,
				ActivationModel: (this.ActivationModel ? new AccountActivationStatus.AccountActivationStatus(this.ActivationModel).ToJsonContract(): this.ActivationModel)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Account.AccountActivationResponseModel
	 */
	 

	export interface IAccountActivationResponseModel {
		ActivationStatus: AccountActivationStatus.IAccountActivationStatus;
	}

	export class AccountActivationResponseModel {
		// property-Properties
		// field-Properties
		public get ActivationStatus(): AccountActivationStatus.IAccountActivationStatus {
			return this._ActivationStatus;
		}
		public set ActivationStatus(newActivationStatus: AccountActivationStatus.IAccountActivationStatus) {
			this._ActivationStatus = newActivationStatus;
		}

		// fields
		protected _ActivationStatus: AccountActivationStatus.IAccountActivationStatus = new AccountActivationStatus.AccountActivationStatus(undefined);

		constructor(data?: DataContracts.IAccountActivationResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAccountActivationResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._ActivationStatus = data.ActivationStatus ? new AccountActivationStatus.AccountActivationStatus(data.ActivationStatus): undefined;
			}
		}
	}
