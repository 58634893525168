import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { ProductsConsumer } from '@consumers/ProductsController';
import { PrijslijstConsumer } from '@consumers/PrijslijstController';
import { ProductsViewModel, ProductViewModel, ProductVariantModel } from '@models/ProductsViewModel';
import { PrijslijstNewProductViewModel, PrijslijstNewProductFilterViewModel, ExcelsViewModel, InstructieViewModel, ExcelViewModel, UploadViewModel } from '@models/PrijslijstViewModel';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'instructiebeheer',
  templateUrl: './instructie.beheer.component.html',
  styleUrls: ['./instructie.beheer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class InstructieBeheerComponent {


  public Loading = true;
  public InstructieViewModel: InstructieViewModel = null;

  public Categorie: string = "";

  constructor(

    private prijslijst: PrijslijstConsumer

  ) {
    this.LoadInstructie();
  }

  private LoadInstructie(): void {
    this.Loading = true;

    this.prijslijst.GetInstructieViewModel_Observable().subscribe((data) => {
      this.InstructieViewModel = new InstructieViewModel(data);
      this.Loading = false;
    });
  }

  public SaveInstructie(): void {
    this.Loading = true;

    this.prijslijst.SaveInstructieViewModel_Observable(this.InstructieViewModel).subscribe((data) => {
      this.Loading = false;
      this.LoadInstructie();
    });
  }
}
