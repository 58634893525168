/**
 * @ref WoonConnectViewModel/Fabrikanten/FilterViewModel.cs
 */
	/**
	* Originele namespace: WoonConnectViewModel.Fabrikanten.FilterDisplayTypeEnum
	*/
	export enum FilterDisplayTypeEnum {
		automatic = 0,
		
		radiobuttons = 1,
		
		dropdown = 2,
		
		multiselect = 3
	}
	/**
	* Originele namespace: WoonConnectViewModel.Fabrikanten.FilterDisplayLevelEnum
	*/
	export enum FilterDisplayLevelEnum {
		none = -1,
		
		levelone = 0,
		
		leveltwo = 1,
		
		levelonetwo = 2
	}

