import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'wct-stui-button',
  templateUrl: './wct-stui-button.component.html',
  styleUrls: ['./wct-stui-button.component.scss']
})
export class WctStuiButtonComponent implements OnInit {
  @Input() noPropagation: boolean = false;
	@Input() disabled: boolean = false;
	@Output() action = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  IsDisabled(): boolean
	{
		return this.disabled;
	}

	buttonClicked(event: Event) {
    if (this.noPropagation) {
      event.stopPropagation();
    }
		this.action.emit();
	}
}
