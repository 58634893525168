import { NgModule} from '@angular/core';
//import { StoreModule } from '@ngrx';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { dwellingFeatureKey, dwellingReducer } from './dwelling.reducer';
import {DwellingEffects } from './dwelling.effects';
import { } from './dwelling.effects';


@NgModule({
	imports: [
        StoreModule.forFeature(dwellingFeatureKey, dwellingReducer),
		EffectsModule.forFeature([DwellingEffects]),
	],
	exports: [
	],
	declarations: [ 
	],
	entryComponents: [ 
	],
	bootstrap: [
	],
	providers: [	
	]
})

// this module is needed when we will do anything with dwellingeventservice, list of dwellings, etc.
export class DwellingStoreModule {
}

