/// <reference path='../consumerinterfaces/KostenController.ts' />
/**
 * @ref WoonConnectApiController/Controllers/Kosten/KostenController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/KostenController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as UittrekstaatKostenModel from "@datacontracts/UittrekstaatKostenModel";
import * as UittrekstaatKostenResultaat from "@datacontracts/UittrekstaatKostenResultaat";
import * as KostenTotaalModel from "@datacontracts/KostenTotaalModel";
import * as KostenVariabelenModel from "@datacontracts/KostenVariabelenModel";
import * as VerkoopAfprijsKostenModel from "@datacontracts/VerkoopAfprijsKostenModel";
import * as BeschikbareLeningen from "@datacontracts/BeschikbareLeningen";
import * as OptieKostenKeuzeServiceResultaat from "@datacontracts/OptieKostenKeuzeServiceResultaat";
import * as WijzigScopeEnumEnum from "@enums/WijzigScopeEnum";
import * as LeningKeuze from "@datacontracts/LeningKeuze";
import * as SubsidieKeuze from "@datacontracts/SubsidieKeuze";
import * as TekortOplossing from "@datacontracts/TekortOplossing";
import * as TekortOplossingLijst from "@datacontracts/TekortOplossingLijst";
import * as MJOPAanpassingenLijst from "@datacontracts/MJOPAanpassingenLijst";
import * as WoningIdLijst from "@datacontracts/WoningIdLijst";
import * as KostenPerObjectViewModel from "@datacontracts/KostenPerObjectViewModel";
import * as MJOPKostenViewModel from "@datacontracts/MJOPKostenViewModel";
import * as MJOPExcelRapportage from "@datacontracts/MJOPExcelRapportage";
import * as MJOPTabs from "@datacontracts/MJOPTabs";
import * as TarievenViewModel from "@datacontracts/TarievenViewModel";
import * as KostenVariabelenInfoModel from "@datacontracts/KostenVariabelenInfoModel";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class KostenConsumer implements Interface.IKostenConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public GeefUittrekstaatKosten_Observable(requestModel: UittrekstaatKostenModel.IUittrekstaatKostenRequestModel & {ToJsonContract(noContent?: boolean): UittrekstaatKostenModel.IUittrekstaatKostenRequestModel}): Observable<UittrekstaatKostenResultaat.IUittrekstaatKostenResultaat> {
				const request = requestModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = request;
				let apiURL = `api/Kosten/GeefUittrekstaatKosten/${request}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UittrekstaatKostenResultaat.IUittrekstaatKostenResultaat>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefUittrekstaatKosten(requestModel: UittrekstaatKostenModel.IUittrekstaatKostenRequestModel & {ToJsonContract(noContent?: boolean): UittrekstaatKostenModel.IUittrekstaatKostenRequestModel}): ng.IPromise<UittrekstaatKostenResultaat.IUittrekstaatKostenResultaat> {
				let obs = this.GeefUittrekstaatKosten_Observable(requestModel);
				let promise = new Promise<UittrekstaatKostenResultaat.IUittrekstaatKostenResultaat>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefKostenTotaal_Observable(requestModel: KostenTotaalModel.IKostenTotaalRequestModel & {ToJsonContract(noContent?: boolean): KostenTotaalModel.IKostenTotaalRequestModel}): Observable<KostenTotaalModel.IKostenTotaalModel> {
				const request = requestModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = request;
				let apiURL = `api/Kosten/GeefKostenTotaal/${request}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<KostenTotaalModel.IKostenTotaalModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefKostenTotaal(requestModel: KostenTotaalModel.IKostenTotaalRequestModel & {ToJsonContract(noContent?: boolean): KostenTotaalModel.IKostenTotaalRequestModel}): ng.IPromise<KostenTotaalModel.IKostenTotaalModel> {
				let obs = this.GeefKostenTotaal_Observable(requestModel);
				let promise = new Promise<KostenTotaalModel.IKostenTotaalModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefKostenVariabelen_Observable(requestModel: KostenVariabelenModel.IKostenVariabelenRequestModel & {ToJsonContract(noContent?: boolean): KostenVariabelenModel.IKostenVariabelenRequestModel}): Observable<KostenVariabelenModel.IKostenVariabelenModel> {
				const request = requestModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = request;
				let apiURL = `api/Kosten/GeefKostenVariabelen/${request}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<KostenVariabelenModel.IKostenVariabelenModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefKostenVariabelen(requestModel: KostenVariabelenModel.IKostenVariabelenRequestModel & {ToJsonContract(noContent?: boolean): KostenVariabelenModel.IKostenVariabelenRequestModel}): ng.IPromise<KostenVariabelenModel.IKostenVariabelenModel> {
				let obs = this.GeefKostenVariabelen_Observable(requestModel);
				let promise = new Promise<KostenVariabelenModel.IKostenVariabelenModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public OpslaanKostenVariabelen_Observable(modelModel: KostenVariabelenModel.IKostenVariabelenModel & {ToJsonContract(noContent?: boolean): KostenVariabelenModel.IKostenVariabelenModel}): Observable<void> {
				const model = modelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = model;
				let apiURL = `api/Kosten/OpslaanKostenVariabelen/${model}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public OpslaanKostenVariabelen(modelModel: KostenVariabelenModel.IKostenVariabelenModel & {ToJsonContract(noContent?: boolean): KostenVariabelenModel.IKostenVariabelenModel}): ng.IPromise<void> {
				let obs = this.OpslaanKostenVariabelen_Observable(modelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefVerkoopAfprijsKosten_Observable(): Observable<VerkoopAfprijsKostenModel.IVerkoopAfprijsKostenModelResultaat> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/GeefVerkoopAfprijsKosten`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<VerkoopAfprijsKostenModel.IVerkoopAfprijsKostenModelResultaat>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefVerkoopAfprijsKosten(): ng.IPromise<VerkoopAfprijsKostenModel.IVerkoopAfprijsKostenModelResultaat> {
				let obs = this.GeefVerkoopAfprijsKosten_Observable();
				let promise = new Promise<VerkoopAfprijsKostenModel.IVerkoopAfprijsKostenModelResultaat>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ZetVerkoopAfprijsKosten_Observable(requestModel: VerkoopAfprijsKostenModel.IVerkoopAfprijsKostenModelUpdate & {ToJsonContract(noContent?: boolean): VerkoopAfprijsKostenModel.IVerkoopAfprijsKostenModelUpdate}): Observable<VerkoopAfprijsKostenModel.IVerkoopAfprijsKostenModelUpdateResultaat> {
				const request = requestModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = request;
				let apiURL = `api/Kosten/ZetVerkoopAfprijsKosten`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<VerkoopAfprijsKostenModel.IVerkoopAfprijsKostenModelUpdateResultaat>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ZetVerkoopAfprijsKosten(requestModel: VerkoopAfprijsKostenModel.IVerkoopAfprijsKostenModelUpdate & {ToJsonContract(noContent?: boolean): VerkoopAfprijsKostenModel.IVerkoopAfprijsKostenModelUpdate}): ng.IPromise<VerkoopAfprijsKostenModel.IVerkoopAfprijsKostenModelUpdateResultaat> {
				let obs = this.ZetVerkoopAfprijsKosten_Observable(requestModel);
				let promise = new Promise<VerkoopAfprijsKostenModel.IVerkoopAfprijsKostenModelUpdateResultaat>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ImporteerLeningen_Observable(): Observable<BeschikbareLeningen.IBeschikbareLeningen> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/ImporteerLeningen/`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BeschikbareLeningen.IBeschikbareLeningen>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ImporteerLeningen(): ng.IPromise<BeschikbareLeningen.IBeschikbareLeningen> {
				let obs = this.ImporteerLeningen_Observable();
				let promise = new Promise<BeschikbareLeningen.IBeschikbareLeningen>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public OptieKostenKeuzeService_Observable(optieid: string, deelid: number): Observable<OptieKostenKeuzeServiceResultaat.IOptieKostenKeuzeServiceReturn> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/OptieKostenKeuzeService/${optieid}/${deelid}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<OptieKostenKeuzeServiceResultaat.IOptieKostenKeuzeServiceReturn>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public OptieKostenKeuzeService(optieid: string, deelid: number): ng.IPromise<OptieKostenKeuzeServiceResultaat.IOptieKostenKeuzeServiceReturn> {
				let obs = this.OptieKostenKeuzeService_Observable(optieid, deelid);
				let promise = new Promise<OptieKostenKeuzeServiceResultaat.IOptieKostenKeuzeServiceReturn>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ZetLeningAanUit_Observable(LeningId: number, LeenBedrag: number, StartJaar: number, Aanzetten: boolean, Id: number, Scope: WijzigScopeEnumEnum.WijzigScopeEnum, Looptijd: number, RentePercentage: number): Observable<LeningKeuze.ILeningKeuze> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/ZetLeningAanUit/${LeningId}/${LeenBedrag}/${StartJaar}/${Aanzetten}/${Id}/${Scope}/${Looptijd}/${RentePercentage}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<LeningKeuze.ILeningKeuze>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ZetLeningAanUit(LeningId: number, LeenBedrag: number, StartJaar: number, Aanzetten: boolean, Id: number, Scope: WijzigScopeEnumEnum.WijzigScopeEnum, Looptijd: number, RentePercentage: number): ng.IPromise<LeningKeuze.ILeningKeuze> {
				let obs = this.ZetLeningAanUit_Observable(LeningId, LeenBedrag, StartJaar, Aanzetten, Id, Scope, Looptijd, RentePercentage);
				let promise = new Promise<LeningKeuze.ILeningKeuze>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ZetSubsidieAanUit_Observable(Naam: string, Aanzetten: boolean, Id: number, Scope: WijzigScopeEnumEnum.WijzigScopeEnum): Observable<SubsidieKeuze.ISubsidieKeuze> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/ZetSubsidieAanUit/${Naam}/${Aanzetten}/${Id}/${Scope}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<SubsidieKeuze.ISubsidieKeuze>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ZetSubsidieAanUit(Naam: string, Aanzetten: boolean, Id: number, Scope: WijzigScopeEnumEnum.WijzigScopeEnum): ng.IPromise<SubsidieKeuze.ISubsidieKeuze> {
				let obs = this.ZetSubsidieAanUit_Observable(Naam, Aanzetten, Id, Scope);
				let promise = new Promise<SubsidieKeuze.ISubsidieKeuze>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public KiesOplossing_Observable(tekortoplossingModel: TekortOplossing.ITekortOplossing & {ToJsonContract(noContent?: boolean): TekortOplossing.ITekortOplossing}): Observable<boolean> {
				const tekortoplossing = tekortoplossingModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = tekortoplossing;
				let apiURL = `api/Kosten/KiesOplossing/${tekortoplossing}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public KiesOplossing(tekortoplossingModel: TekortOplossing.ITekortOplossing & {ToJsonContract(noContent?: boolean): TekortOplossing.ITekortOplossing}): ng.IPromise<boolean> {
				let obs = this.KiesOplossing_Observable(tekortoplossingModel);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public VerwijderOplossing_Observable(tekortoplossingModel: TekortOplossing.ITekortOplossing & {ToJsonContract(noContent?: boolean): TekortOplossing.ITekortOplossing}): Observable<boolean> {
				const tekortoplossing = tekortoplossingModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = tekortoplossing;
				let apiURL = `api/Kosten/VerwijderOplossing/${tekortoplossing}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public VerwijderOplossing(tekortoplossingModel: TekortOplossing.ITekortOplossing & {ToJsonContract(noContent?: boolean): TekortOplossing.ITekortOplossing}): ng.IPromise<boolean> {
				let obs = this.VerwijderOplossing_Observable(tekortoplossingModel);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public VerwijderOplossingen_Observable(tekortoplossingenModel: TekortOplossingLijst.ITekortOplossingLijst & {ToJsonContract(noContent?: boolean): TekortOplossingLijst.ITekortOplossingLijst}): Observable<boolean> {
				const tekortoplossingen = tekortoplossingenModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = tekortoplossingen;
				let apiURL = `api/Kosten/VerwijderOplossingen/${tekortoplossingen}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public VerwijderOplossingen(tekortoplossingenModel: TekortOplossingLijst.ITekortOplossingLijst & {ToJsonContract(noContent?: boolean): TekortOplossingLijst.ITekortOplossingLijst}): ng.IPromise<boolean> {
				let obs = this.VerwijderOplossingen_Observable(tekortoplossingenModel);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public MJOPregelsAanpassen_Observable(mjopregelsModel: MJOPAanpassingenLijst.IMJOPAanpassingenLijst & {ToJsonContract(noContent?: boolean): MJOPAanpassingenLijst.IMJOPAanpassingenLijst}): Observable<boolean> {
				const mjopregels = mjopregelsModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = mjopregels;
				let apiURL = `api/Kosten/MJOPregelsAanpassen/${mjopregels}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public MJOPregelsAanpassen(mjopregelsModel: MJOPAanpassingenLijst.IMJOPAanpassingenLijst & {ToJsonContract(noContent?: boolean): MJOPAanpassingenLijst.IMJOPAanpassingenLijst}): ng.IPromise<boolean> {
				let obs = this.MJOPregelsAanpassen_Observable(mjopregelsModel);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefKostenPerObjectLijst_Observable(woningIdsModel: WoningIdLijst.IWoningIdLijst & {ToJsonContract(noContent?: boolean): WoningIdLijst.IWoningIdLijst}, variantnr: number): Observable<KostenPerObjectViewModel.IKostenPerObjectViewModel[]> {
				const woningIds = woningIdsModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = woningIds;
				let apiURL = `api/Kosten/GeefKostenPerObjectLijst/${woningIds}/${variantnr}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<KostenPerObjectViewModel.IKostenPerObjectViewModel[]>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefKostenPerObjectLijst(woningIdsModel: WoningIdLijst.IWoningIdLijst & {ToJsonContract(noContent?: boolean): WoningIdLijst.IWoningIdLijst}, variantnr: number): ng.IPromise<KostenPerObjectViewModel.IKostenPerObjectViewModel[]> {
				let obs = this.GeefKostenPerObjectLijst_Observable(woningIdsModel, variantnr);
				let promise = new Promise<KostenPerObjectViewModel.IKostenPerObjectViewModel[]>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefMJOP_Observable(variant: number, Id: number = 0, scope: number = 3, startjaar: number = null, eindjaar: number = null, CommunicatieToken: string = null, tariefsetid: number = 0): Observable<MJOPKostenViewModel.IMJOPKostenViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/GeefMJOP/${variant}/${Id}/${scope}/${startjaar}/${eindjaar}/${CommunicatieToken}/${tariefsetid}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<MJOPKostenViewModel.IMJOPKostenViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefMJOP(variant: number, Id: number = 0, scope: number = 3, startjaar: number = null, eindjaar: number = null, CommunicatieToken: string = null, tariefsetid: number = 0): ng.IPromise<MJOPKostenViewModel.IMJOPKostenViewModel> {
				let obs = this.GeefMJOP_Observable(variant, Id, scope, startjaar, eindjaar, CommunicatieToken, tariefsetid);
				let promise = new Promise<MJOPKostenViewModel.IMJOPKostenViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public MaakMJOPExcel_Observable(requestModel: MJOPExcelRapportage.IMJOPExcelRaportageRequest & {ToJsonContract(noContent?: boolean): MJOPExcelRapportage.IMJOPExcelRaportageRequest}): Observable<MJOPExcelRapportage.IMJOPExcelRaportageResultaat> {
				const request = requestModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = request;
				let apiURL = `api/Kosten/MaakMJOPExcel/${request}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<MJOPExcelRapportage.IMJOPExcelRaportageResultaat>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public MaakMJOPExcel(requestModel: MJOPExcelRapportage.IMJOPExcelRaportageRequest & {ToJsonContract(noContent?: boolean): MJOPExcelRapportage.IMJOPExcelRaportageRequest}): ng.IPromise<MJOPExcelRapportage.IMJOPExcelRaportageResultaat> {
				let obs = this.MaakMJOPExcel_Observable(requestModel);
				let promise = new Promise<MJOPExcelRapportage.IMJOPExcelRaportageResultaat>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefActieveMJOPtabs_Observable(): Observable<MJOPTabs.IMJOPTabs> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/GeefActieveMJOPtabs/`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<MJOPTabs.IMJOPTabs>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefActieveMJOPtabs(): ng.IPromise<MJOPTabs.IMJOPTabs> {
				let obs = this.GeefActieveMJOPtabs_Observable();
				let promise = new Promise<MJOPTabs.IMJOPTabs>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefTarievenSets_Observable(projectid: number, variantid: number): Observable<TarievenViewModel.ITarievenViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/GeefTarievenSets/${projectid}/${variantid}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<TarievenViewModel.ITarievenViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefTarievenSets(projectid: number, variantid: number): ng.IPromise<TarievenViewModel.ITarievenViewModel> {
				let obs = this.GeefTarievenSets_Observable(projectid, variantid);
				let promise = new Promise<TarievenViewModel.ITarievenViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public MaakTariefSetActief_Observable(projectid: number, variantid: number, tariefsetid: number): Observable<TarievenViewModel.ITarievenViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/MaakTariefSetActief/${projectid}/${variantid}/${tariefsetid}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<TarievenViewModel.ITarievenViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public MaakTariefSetActief(projectid: number, variantid: number, tariefsetid: number): ng.IPromise<TarievenViewModel.ITarievenViewModel> {
				let obs = this.MaakTariefSetActief_Observable(projectid, variantid, tariefsetid);
				let promise = new Promise<TarievenViewModel.ITarievenViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public MaakNieuweTariefSet_Observable(projectid: number, variantid: number): Observable<TarievenViewModel.ITarievenViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/MaakNieuweTariefSet/${projectid}/${variantid}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<TarievenViewModel.ITarievenViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public MaakNieuweTariefSet(projectid: number, variantid: number): ng.IPromise<TarievenViewModel.ITarievenViewModel> {
				let obs = this.MaakNieuweTariefSet_Observable(projectid, variantid);
				let promise = new Promise<TarievenViewModel.ITarievenViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public VerwijderTariefSet_Observable(projectid: number, id: number, variantid: number): Observable<TarievenViewModel.ITarievenViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/VerwijderTariefSet/${projectid}/${id}/${variantid}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<TarievenViewModel.ITarievenViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public VerwijderTariefSet(projectid: number, id: number, variantid: number): ng.IPromise<TarievenViewModel.ITarievenViewModel> {
				let obs = this.VerwijderTariefSet_Observable(projectid, id, variantid);
				let promise = new Promise<TarievenViewModel.ITarievenViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public MaakNieuweTariefSetRegel_Observable(projectid: number, setid: number, variantid: number): Observable<TarievenViewModel.ITarievenViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/MaakNieuweTariefSetRegel/${projectid}/${setid}/${variantid}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<TarievenViewModel.ITarievenViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public MaakNieuweTariefSetRegel(projectid: number, setid: number, variantid: number): ng.IPromise<TarievenViewModel.ITarievenViewModel> {
				let obs = this.MaakNieuweTariefSetRegel_Observable(projectid, setid, variantid);
				let promise = new Promise<TarievenViewModel.ITarievenViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public VerwijderTariefSetRegel_Observable(projectid: number, setid: number, regelid: number, variantid: number): Observable<TarievenViewModel.ITarievenViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/VerwijderTariefSetRegel/${projectid}/${setid}/${regelid}/${variantid}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<TarievenViewModel.ITarievenViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public VerwijderTariefSetRegel(projectid: number, setid: number, regelid: number, variantid: number): ng.IPromise<TarievenViewModel.ITarievenViewModel> {
				let obs = this.VerwijderTariefSetRegel_Observable(projectid, setid, regelid, variantid);
				let promise = new Promise<TarievenViewModel.ITarievenViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SetOpslaan_Observable(setModel: TarievenViewModel.ITariefSetViewModel & {ToJsonContract(noContent?: boolean): TarievenViewModel.ITariefSetViewModel}): Observable<TarievenViewModel.ITarievenViewModel> {
				const set = setModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = set;
				let apiURL = `api/Kosten/SetOpslaan/${set}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<TarievenViewModel.ITarievenViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SetOpslaan(setModel: TarievenViewModel.ITariefSetViewModel & {ToJsonContract(noContent?: boolean): TarievenViewModel.ITariefSetViewModel}): ng.IPromise<TarievenViewModel.ITarievenViewModel> {
				let obs = this.SetOpslaan_Observable(setModel);
				let promise = new Promise<TarievenViewModel.ITarievenViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public RegelOpslaan_Observable(regelModel: TarievenViewModel.ITariefSetRegelViewModel & {ToJsonContract(noContent?: boolean): TarievenViewModel.ITariefSetRegelViewModel}, projectid: number, variantid: number): Observable<TarievenViewModel.ITarievenViewModel> {
				const regel = regelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = regel;
				let apiURL = `api/Kosten/RegelOpslaan/${regel}/${projectid}/${variantid}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<TarievenViewModel.ITarievenViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public RegelOpslaan(regelModel: TarievenViewModel.ITariefSetRegelViewModel & {ToJsonContract(noContent?: boolean): TarievenViewModel.ITariefSetRegelViewModel}, projectid: number, variantid: number): ng.IPromise<TarievenViewModel.ITarievenViewModel> {
				let obs = this.RegelOpslaan_Observable(regelModel, projectid, variantid);
				let promise = new Promise<TarievenViewModel.ITarievenViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefKostenVariabelenInfo_Observable(): Observable<KostenVariabelenInfoModel.IKostenVariabelenInfoModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Kosten/GeefKostenVariabelenInfo`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<KostenVariabelenInfoModel.IKostenVariabelenInfoModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefKostenVariabelenInfo(): ng.IPromise<KostenVariabelenInfoModel.IKostenVariabelenInfoModel> {
				let obs = this.GeefKostenVariabelenInfo_Observable();
				let promise = new Promise<KostenVariabelenInfoModel.IKostenVariabelenInfoModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


