/// <reference path='../consumerinterfaces/ProductsController.ts' />
/**
 * @ref WoonConnectApiController/Fabrikanten/ProductsController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/ProductsController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as ProductsViewModel from "@datacontracts/ProductsViewModel";
import * as OverviewViewModel from "@datacontracts/OverviewViewModel";
import * as request from "../../../helpers/IRequestHandler";





	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class ProductsConsumer implements Interface.IProductsConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public GetProducts_Observable(pInputModel: ProductsViewModel.IProductsViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductsViewModel}): Observable<ProductsViewModel.IProductsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetProducts/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetProducts(pInputModel: ProductsViewModel.IProductsViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductsViewModel}): ng.IPromise<ProductsViewModel.IProductsViewModel> {
				let obs = this.GetProducts_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveProducts_Observable(pInputModel: ProductsViewModel.IProductsViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductsViewModel}): Observable<ProductsViewModel.IProductsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/SaveProducts/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveProducts(pInputModel: ProductsViewModel.IProductsViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductsViewModel}): ng.IPromise<ProductsViewModel.IProductsViewModel> {
				let obs = this.SaveProducts_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetOverview_Observable(): Observable<OverviewViewModel.IOverviewViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetOverview`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<OverviewViewModel.IOverviewViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetOverview(): ng.IPromise<OverviewViewModel.IOverviewViewModel> {
				let obs = this.GetOverview_Observable();
				let promise = new Promise<OverviewViewModel.IOverviewViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetProduct_Observable(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): Observable<ProductsViewModel.IProductViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetProduct/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetProduct(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): ng.IPromise<ProductsViewModel.IProductViewModel> {
				let obs = this.GetProduct_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AddProduct_Observable(pInputModel: ProductsViewModel.IProductViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductViewModel}): Observable<ProductsViewModel.IProductViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/AddProduct/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AddProduct(pInputModel: ProductsViewModel.IProductViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductViewModel}): ng.IPromise<ProductsViewModel.IProductViewModel> {
				let obs = this.AddProduct_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DeleteProduct_Observable(pInput: number): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/DeleteProduct/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DeleteProduct(pInput: number): ng.IPromise<string> {
				let obs = this.DeleteProduct_Observable(pInput);
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public CheckProduct_Observable(pInput: number): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/CheckProduct/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public CheckProduct(pInput: number): ng.IPromise<string> {
				let obs = this.CheckProduct_Observable(pInput);
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UpdateRequiredVariant_Observable(pInputModel: ProductsViewModel.IProductVariantModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductVariantModel}): Observable<void> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/UpdateRequiredVariant/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UpdateRequiredVariant(pInputModel: ProductsViewModel.IProductVariantModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductVariantModel}): ng.IPromise<void> {
				let obs = this.UpdateRequiredVariant_Observable(pInputModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ImportVariant_Observable(pInputModel: ProductsViewModel.IProductVariantModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductVariantModel}): Observable<ProductsViewModel.IProductVariantModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/ImportVariant/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductVariantModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ImportVariant(pInputModel: ProductsViewModel.IProductVariantModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductVariantModel}): ng.IPromise<ProductsViewModel.IProductVariantModel> {
				let obs = this.ImportVariant_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductVariantModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveVariant_Observable(pInputModel: ProductsViewModel.IProductVariantModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductVariantModel}): Observable<ProductsViewModel.IProductVariantModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/SaveVariant/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductVariantModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveVariant(pInputModel: ProductsViewModel.IProductVariantModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductVariantModel}): ng.IPromise<ProductsViewModel.IProductVariantModel> {
				let obs = this.SaveVariant_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductVariantModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetVariant_Observable(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): Observable<ProductsViewModel.IProductVariantModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetVariant/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductVariantModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetVariant(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): ng.IPromise<ProductsViewModel.IProductVariantModel> {
				let obs = this.GetVariant_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductVariantModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DeleteProperty_Observable(pInput: number): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/DeleteProperty/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DeleteProperty(pInput: number): ng.IPromise<string> {
				let obs = this.DeleteProperty_Observable(pInput);
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetProductPropertyRelation_Observable(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): Observable<ProductsViewModel.IProductPropertyRelationsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetProductPropertyRelation/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductPropertyRelationsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetProductPropertyRelation(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): ng.IPromise<ProductsViewModel.IProductPropertyRelationsViewModel> {
				let obs = this.GetProductPropertyRelation_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductPropertyRelationsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetProductPropertyVariantRelation_Observable(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): Observable<ProductsViewModel.IProductPropertyVariantRelationsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetProductPropertyVariantRelation/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductPropertyVariantRelationsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetProductPropertyVariantRelation(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): ng.IPromise<ProductsViewModel.IProductPropertyVariantRelationsViewModel> {
				let obs = this.GetProductPropertyVariantRelation_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductPropertyVariantRelationsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveProductPropertyRelation_Observable(pInputModel: ProductsViewModel.IProductPropertySaveModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductPropertySaveModel}): Observable<ProductsViewModel.IProductPropertySaveModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/SaveProductPropertyRelation/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductPropertySaveModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveProductPropertyRelation(pInputModel: ProductsViewModel.IProductPropertySaveModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductPropertySaveModel}): ng.IPromise<ProductsViewModel.IProductPropertySaveModel> {
				let obs = this.SaveProductPropertyRelation_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductPropertySaveModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveProductPropertyVariantRelation_Observable(pInputModel: ProductsViewModel.IProductPropertySaveModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductPropertySaveModel}): Observable<ProductsViewModel.IProductPropertySaveModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/SaveProductPropertyVariantRelation/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductPropertySaveModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveProductPropertyVariantRelation(pInputModel: ProductsViewModel.IProductPropertySaveModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductPropertySaveModel}): ng.IPromise<ProductsViewModel.IProductPropertySaveModel> {
				let obs = this.SaveProductPropertyVariantRelation_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductPropertySaveModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetAllProductPropertyTypes_Observable(pInputModel: ProductsViewModel.IProductPropertyTypesViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductPropertyTypesViewModel}): Observable<ProductsViewModel.IProductPropertyTypesViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetProductPropertyTypes/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductPropertyTypesViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetAllProductPropertyTypes(pInputModel: ProductsViewModel.IProductPropertyTypesViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductPropertyTypesViewModel}): ng.IPromise<ProductsViewModel.IProductPropertyTypesViewModel> {
				let obs = this.GetAllProductPropertyTypes_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductPropertyTypesViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveProductPropertyType_Observable(pInputModel: ProductsViewModel.IProductPropertyTypeViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductPropertyTypeViewModel}): Observable<ProductsViewModel.IProductPropertyTypeViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/SaveProductPropertyType/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductPropertyTypeViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveProductPropertyType(pInputModel: ProductsViewModel.IProductPropertyTypeViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductPropertyTypeViewModel}): ng.IPromise<ProductsViewModel.IProductPropertyTypeViewModel> {
				let obs = this.SaveProductPropertyType_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductPropertyTypeViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DeleteAllProductProperties_Observable(): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/DeleteAllProductProperties`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DeleteAllProductProperties(): ng.IPromise<void> {
				let obs = this.DeleteAllProductProperties_Observable();
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DeleteProductPropertyOption_Observable(pInputModel: ProductsViewModel.IProductPropertyOptionViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductPropertyOptionViewModel}): Observable<ProductsViewModel.IProductPropertyOptionViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/DeleteProductPropertyOption/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductPropertyOptionViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DeleteProductPropertyOption(pInputModel: ProductsViewModel.IProductPropertyOptionViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductPropertyOptionViewModel}): ng.IPromise<ProductsViewModel.IProductPropertyOptionViewModel> {
				let obs = this.DeleteProductPropertyOption_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductPropertyOptionViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetVariants_Observable(pInputModel: ProductsViewModel.IProductVariantsViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductVariantsViewModel}): Observable<ProductsViewModel.IProductVariantsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetVariants/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductVariantsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetVariants(pInputModel: ProductsViewModel.IProductVariantsViewModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductVariantsViewModel}): ng.IPromise<ProductsViewModel.IProductVariantsViewModel> {
				let obs = this.GetVariants_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductVariantsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DeleteVariant_Observable(pInput: number): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/DeleteVariant/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DeleteVariant(pInput: number): ng.IPromise<string> {
				let obs = this.DeleteVariant_Observable(pInput);
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ConnectProductPropertyToFilter_Observable(pProductPropertyId: number, pFilterId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/ConnectProductPropertyToFilter/${pProductPropertyId}/${pFilterId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ConnectProductPropertyToFilter(pProductPropertyId: number, pFilterId: number): ng.IPromise<void> {
				let obs = this.ConnectProductPropertyToFilter_Observable(pProductPropertyId, pFilterId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DisconnectProductPropertyToFilter_Observable(pProductPropertyId: number, pFilterId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/DisconnectProductPropertyToFilter/${pProductPropertyId}/${pFilterId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DisconnectProductPropertyToFilter(pProductPropertyId: number, pFilterId: number): ng.IPromise<void> {
				let obs = this.DisconnectProductPropertyToFilter_Observable(pProductPropertyId, pFilterId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ConvertProductPropertyToOption_Observable(pProductPropertyId: number, pProductVariantId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/ConvertProductPropertyToOption/${pProductPropertyId}/${pProductVariantId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ConvertProductPropertyToOption(pProductPropertyId: number, pProductVariantId: number): ng.IPromise<void> {
				let obs = this.ConvertProductPropertyToOption_Observable(pProductPropertyId, pProductVariantId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public IgnoreProductProperty_Observable(pProductPropertyId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/IgnoreProductProperty/${pProductPropertyId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public IgnoreProductProperty(pProductPropertyId: number): ng.IPromise<void> {
				let obs = this.IgnoreProductProperty_Observable(pProductPropertyId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UnignoreProductProperty_Observable(pProductPropertyId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/UnignoreProductProperty/${pProductPropertyId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UnignoreProductProperty(pProductPropertyId: number): ng.IPromise<void> {
				let obs = this.UnignoreProductProperty_Observable(pProductPropertyId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ConnectProductPropertyOptionToFilterOption_Observable(pProductPropertyOptionId: number, pFilterOptionId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/ConnectProductPropertyOptionToFilterOption/${pProductPropertyOptionId}/${pFilterOptionId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ConnectProductPropertyOptionToFilterOption(pProductPropertyOptionId: number, pFilterOptionId: number): ng.IPromise<void> {
				let obs = this.ConnectProductPropertyOptionToFilterOption_Observable(pProductPropertyOptionId, pFilterOptionId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ConnectProductPropertyOptionToFilterOptionAlways_Observable(pProductPropertyOptionId: number, pFilterOptionId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/ConnectProductPropertyOptionToFilterOptionAlways/${pProductPropertyOptionId}/${pFilterOptionId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ConnectProductPropertyOptionToFilterOptionAlways(pProductPropertyOptionId: number, pFilterOptionId: number): ng.IPromise<void> {
				let obs = this.ConnectProductPropertyOptionToFilterOptionAlways_Observable(pProductPropertyOptionId, pFilterOptionId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ConnectProductPropertyOptionToFilterOptionAlwaysParent_Observable(pProductPropertyOptionId: number, pFilterOptionId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/ConnectProductPropertyOptionToFilterOptionAlwaysParent/${pProductPropertyOptionId}/${pFilterOptionId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ConnectProductPropertyOptionToFilterOptionAlwaysParent(pProductPropertyOptionId: number, pFilterOptionId: number): ng.IPromise<void> {
				let obs = this.ConnectProductPropertyOptionToFilterOptionAlwaysParent_Observable(pProductPropertyOptionId, pFilterOptionId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ConnectProductPropertyOptionToFilterOptionAlwaysParentParent_Observable(pProductPropertyOptionId: number, pFilterOptionId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/ConnectProductPropertyOptionToFilterOptionAlwaysParentParent/${pProductPropertyOptionId}/${pFilterOptionId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ConnectProductPropertyOptionToFilterOptionAlwaysParentParent(pProductPropertyOptionId: number, pFilterOptionId: number): ng.IPromise<void> {
				let obs = this.ConnectProductPropertyOptionToFilterOptionAlwaysParentParent_Observable(pProductPropertyOptionId, pFilterOptionId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DisconnectProductPropertyOptionToFilterOption_Observable(pProductPropertyOptionId: number, pFilterOptionId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/DisconnectProductPropertyOptionToFilterOption/${pProductPropertyOptionId}/${pFilterOptionId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DisconnectProductPropertyOptionToFilterOption(pProductPropertyOptionId: number, pFilterOptionId: number): ng.IPromise<void> {
				let obs = this.DisconnectProductPropertyOptionToFilterOption_Observable(pProductPropertyOptionId, pFilterOptionId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public BlockProductProperty_Observable(pProductPropertyId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/BlockProductProperty/${pProductPropertyId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public BlockProductProperty(pProductPropertyId: number): ng.IPromise<void> {
				let obs = this.BlockProductProperty_Observable(pProductPropertyId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public BlockProductPropertyAlways_Observable(pProductPropertyId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/BlockProductPropertyAlways/${pProductPropertyId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public BlockProductPropertyAlways(pProductPropertyId: number): ng.IPromise<void> {
				let obs = this.BlockProductPropertyAlways_Observable(pProductPropertyId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public BlockProductPropertyAlwaysSameParent_Observable(pProductPropertyId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/BlockProductPropertyAlwaysSameParent/${pProductPropertyId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public BlockProductPropertyAlwaysSameParent(pProductPropertyId: number): ng.IPromise<void> {
				let obs = this.BlockProductPropertyAlwaysSameParent_Observable(pProductPropertyId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public BlockProductPropertyAlwaysSameParentParent_Observable(pProductPropertyId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/BlockProductPropertyAlwaysSameParentParent/${pProductPropertyId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public BlockProductPropertyAlwaysSameParentParent(pProductPropertyId: number): ng.IPromise<void> {
				let obs = this.BlockProductPropertyAlwaysSameParentParent_Observable(pProductPropertyId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public IgnoreProductPropertyOption_Observable(pProductPropertyOptionId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/IgnoreProductPropertyOption/${pProductPropertyOptionId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public IgnoreProductPropertyOption(pProductPropertyOptionId: number): ng.IPromise<void> {
				let obs = this.IgnoreProductPropertyOption_Observable(pProductPropertyOptionId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public IgnoreProductPropertyOptionAlways_Observable(pProductPropertyOptionId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/IgnoreProductPropertyOptionAlways/${pProductPropertyOptionId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public IgnoreProductPropertyOptionAlways(pProductPropertyOptionId: number): ng.IPromise<void> {
				let obs = this.IgnoreProductPropertyOptionAlways_Observable(pProductPropertyOptionId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UnignoreProductPropertyOption_Observable(pProductPropertyOptionId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/UnignoreProductPropertyOption/${pProductPropertyOptionId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UnignoreProductPropertyOption(pProductPropertyOptionId: number): ng.IPromise<void> {
				let obs = this.UnignoreProductPropertyOption_Observable(pProductPropertyOptionId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AddEigenschap_Observable(pAddEigenschapInputModelModel: ProductsViewModel.IAddEigenschapInputModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IAddEigenschapInputModel}): Observable<void> {
				const pAddEigenschapInputModel = pAddEigenschapInputModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pAddEigenschapInputModel;
				let apiURL = `api/Fabrikanten/AddEigenschap/${pAddEigenschapInputModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AddEigenschap(pAddEigenschapInputModelModel: ProductsViewModel.IAddEigenschapInputModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IAddEigenschapInputModel}): ng.IPromise<void> {
				let obs = this.AddEigenschap_Observable(pAddEigenschapInputModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public RemoveEigenschap_Observable(pProductPropertyId: number, pProductVariantId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/RemoveEigenschap/${pProductPropertyId}/${pProductVariantId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public RemoveEigenschap(pProductPropertyId: number, pProductVariantId: number): ng.IPromise<void> {
				let obs = this.RemoveEigenschap_Observable(pProductPropertyId, pProductVariantId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AddOption_Observable(pAddOptionInputModelModel: ProductsViewModel.IAddOptionInputModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IAddOptionInputModel}): Observable<void> {
				const pAddOptionInputModel = pAddOptionInputModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pAddOptionInputModel;
				let apiURL = `api/Fabrikanten/AddOption/${pAddOptionInputModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AddOption(pAddOptionInputModelModel: ProductsViewModel.IAddOptionInputModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IAddOptionInputModel}): ng.IPromise<void> {
				let obs = this.AddOption_Observable(pAddOptionInputModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public RemoveOption_Observable(pProductPropertyOptionId: number, pProductVariantId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/RemoveOption/${pProductPropertyOptionId}/${pProductVariantId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public RemoveOption(pProductPropertyOptionId: number, pProductVariantId: number): ng.IPromise<void> {
				let obs = this.RemoveOption_Observable(pProductPropertyOptionId, pProductVariantId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UpdateLiveUpdate_Observable(pProductPropertyId: number, pLiveUpdate: boolean): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/UpdateLiveUpdate/${pProductPropertyId}/${pLiveUpdate}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UpdateLiveUpdate(pProductPropertyId: number, pLiveUpdate: boolean): ng.IPromise<void> {
				let obs = this.UpdateLiveUpdate_Observable(pProductPropertyId, pLiveUpdate);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SuperConnect_Observable(pProductPropertyOptionId: number, pFilterId: number, pVeld: string): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/SuperConnect/${pProductPropertyOptionId}/${pFilterId}/${pVeld}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SuperConnect(pProductPropertyOptionId: number, pFilterId: number, pVeld: string): ng.IPromise<void> {
				let obs = this.SuperConnect_Observable(pProductPropertyOptionId, pFilterId, pVeld);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ResetImportFlags_Observable(pProductId: number, pProductVariantId: number): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/ResetImportFlags/${pProductId}/${pProductVariantId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ResetImportFlags(pProductId: number, pProductVariantId: number): ng.IPromise<void> {
				let obs = this.ResetImportFlags_Observable(pProductId, pProductVariantId);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}
