<div class="wct-viewer3d-container" style="height: 100%;" [ngClass]="{ 'WoningDivKnockoutWachten': LadenBezig() }">
   <wct-bezig [bezigmodel]="BezigViewModel()">
      <!-- <div *ngIf="LadenBezig()" class="flex-cont cont-progress-circular">
         <mat-progress-spinner diameter="75px" mode="determinate" [value]="Voortgang()"></mat-progress-spinner>
         <p>{{Voortgang() | number:0}}%</p>
      </div> -->

      <div class="cont-model-viewable-area" [hidden]="!ToonPanButtons">
         <a class="link-model-pan-top" (click)="PanOmhoog()">
            <div class="img-arrow"></div>
         </a>
         <a class="link-model-pan-right" (click)="PanRechts()">
            <div class="img-arrow"></div>
         </a>
         <a class="link-model-pan-bottom" (click)="PanBeneden()">
            <div class="img-arrow"></div>
         </a>
         <a class="link-model-pan-left"
            [ngClass]="{'link-model-pan-left-extra-space-left' : PanLinksValtOnderOverzichtsmenu()}"
            (click)="PanLinks()">
            <div class="img-arrow"></div>
         </a>
      </div>
      <wct-webgl-canvas (sessiegeregistreerd)="SessieGeregistreerd($event)" [sessie]="sessie" [selectie]="selectie"
         [drawscene]="drawscene" [suppress]="suppress">
      </wct-webgl-canvas>
   </wct-bezig>
</div>