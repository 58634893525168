import * as _ from 'lodash';
import * as ViewModelsDocumentMap from '.././Models/DocumentMap';




	export class FileType {
		imageName: string = "";
		extention: string = "";

		constructor(imgName, ext) {
			this.imageName = imgName;
			this.extention = ext;
		}
	}
	export class FileTypes {
		Types: FileType[] = [];

		constructor() {

			var filetypes: Array<string> = ["aac",
				"ai",
				"aiff",
				"avi",
				"b.txt",
				"bmp",
				"c",
				"cpp",
				"css",
				"dat",
				"dmg",
				"doc",
				"dotx",
				"dwg",
				"dxf",
				"eps",
				"exe",
				"flv",
				"gif",
				"h",
				"hpp",
				"html",
				"ics",
				"iso",
				"java",
				"jpg",
				"js",
				"key",
				"less",
				"mid",
				"mp3",
				"mp4",
				"mpg",
				"odf",
				"ods",
				"odt",
				"otp",
				"ots",
				"ott",
				"pdf",
				"php",
				"png",
				"ppt",
				"psd",
				"py",
				"qt",
				"rar",
				"rb",
				"rtf",
				"sass",
				"scss",
				"sql",
				"tga",
				"tgz",
				"tiff",
				"txt",
				"wav",
				"xls",
				"xlsx",
				"xml",
				"yml",
				"zip"
			];

			_.forEach(filetypes, (f) => {
				this.Types[f] = new FileType(f + ".png", f);
			});

			this.Types['xlsx'] = new FileType("xls.png", "xlsx");
			this.Types['pptx'] = new FileType("ppt.png", "pptx");
			this.Types['docx'] = new FileType("doc.png", "docx");
		}

		GeefImgType(bestandsnaam: string): FileType {
			var NaamSplit: string[] = bestandsnaam.split(".");
			var ext: string = NaamSplit[NaamSplit.length - 1];
			var type: FileType = this.Types[ext.toLowerCase()];
			return type;
		}

		GeefImg(bestandsnaam: string): string {
			var imgName: string = "_blank.png";
			var type = this.GeefImgType(bestandsnaam);
            
			if (type != undefined) {
				imgName = type.imageName;
			}
			return imgName
		}
	}
	export class Document {
		Id: number;
		ContentType: string;
		Bestand: string;
		ProjectId: number;
		AdresId: any;
		ReceptId: any;
		Naam: string;
		Map: ViewModelsDocumentMap.DocumentMap;
		NogLeeg: boolean;
		filetypeImg: string;
		Grootte: number;
		UploadDate: string;
		Rechten: ViewModelsDocumentMap.RechtenEnum;
		AlleenLezen: boolean;
		FileType: FileType;

		Types: FileTypes = new FileTypes();

		constructor() {
			this.NogLeeg = (true);
			this.Id = (null);
			this.ContentType = (null);
			this.Naam = (null);
			this.ProjectId = (null);

			this.Bestand = (null);
			this.AdresId = (null);
			this.ReceptId = (null);
			this.filetypeImg = ("");
			this.Grootte = 0;
			this.UploadDate = ("");

			this.Map = (null);
			this.Rechten = (null);
			this.AlleenLezen = (null);
		}

		VulModel(data) {
			this.Id = (data.Id);
			this.ContentType = (data.ContentType);
			this.Naam = (data.Naam);
			this.ProjectId = (data.ProjectId);

			this.Bestand = (data.Bestand);
			this.AdresId = (data.AdresId);
			this.ReceptId = (data.ReceptId);
			this.NogLeeg = (false);
			this.Grootte = data.Grootte;
			this.UploadDate = data.UploadDate;

			this.Map = (null);
			this.Rechten = (data.Rechten);

			this.ZetOverigeVelden();
		}

		ZetOverigeVelden() {
			var alleenLezen: boolean = ((this.Rechten == ViewModelsDocumentMap.RechtenEnum.AlleenLezen) || (this.Rechten == ViewModelsDocumentMap.RechtenEnum.Geen))
			this.AlleenLezen = (alleenLezen);


			this.FileType = this.Types.GeefImgType(this.Naam);

			var typeImg: string = this.Types.GeefImg(this.Naam);
			this.filetypeImg = (typeImg);
		}

	}
