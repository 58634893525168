import * as ServicesAfbeeldingControlerenService from '../../services/AfbeeldingControlerenService';
import * as ServicesAccountService from '../../services/AccountService';
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from '../../services/TranslateService';
import { AccountService } from '../../services/AccountService';
import { AppConfigService } from '../../infrastructure/app.configservice';
import { Router,ActivatedRoute } from '@angular/router';

// interface IWachtwoordVergetenBindings {
// 	code: string;
// }

// export interface IWachtwoordVergetenController extends IWachtwoordVergetenBindings {
// }

@Component({
	selector: 'wct-confirm-email',
	templateUrl: './WctConfirmEmail.html',
	styleUrls: ['./WctConfirmEmail.scss'],
})
export class WctConfirmEmailComponent {


	constructor(private accountservice: ServicesAccountService.AccountService, private afbeeldingcontrolerenservice: ServicesAfbeeldingControlerenService.AfbeeldingControlerenService, private router: Router, private route:ActivatedRoute) {
	}

	ngOnInit(){
     
    }

	ToonImageWachtwoordVergeten() {
		let urlAfbeelding = this.afbeeldingcontrolerenservice.geefAfbeelding('email-bevestigen');
		return urlAfbeelding;
	}

	public TerugNaarLogin(): void {
		this.router.navigate(['/']);
	}
}