import * as _ from 'lodash';
import * as ng from 'angular';
import { AppConfigService } from '../infrastructure/app.configservice';
import { DocumentMapModel } from '@models/DocumentMapModel';
import { DocumentModel } from '@models/DocumentModel';
import { GeefDocumentenInputModel } from '@models/GeefDocumentenInputModel';
import { AccountService } from '../services/AccountService';
import { DocumentenConsumer } from '@consumers/DocumentenController';
import { IDocumentModel } from '@datacontracts/DocumentModel';
import { IDocumentMapModel } from '@datacontracts/DocumentMapModel';
import { IGeefDocumentenResultaatModel } from '@datacontracts/GeefDocumentenResultaatModel';
import { MapType } from '@enums/IDocumentRepository';
import { DocumentMap } from '../Models/DocumentMap';
import { Document } from '../Models/Document';
import { Injectable, Inject } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';

export interface IDocumentService {
   activeFolderId: number;
   activeDocumentId: number;

   GeefDocumentenModel(personId: number, archive: boolean, filters: MapType[]): ng.IPromise<IGeefDocumentenResultaatModel>;
   triggerChange(): void;

   ZetActieveMap(folder: IDocumentMapModel);
   GeefActieveMap(model: IGeefDocumentenResultaatModel): IDocumentMapModel;

   OpenMap(folder: IDocumentMapModel): void;
   SluitMap(folder: IDocumentMapModel): void;
   IsOpen(folder: IDocumentMapModel): boolean;

   ZetActiefDocument(doc: IDocumentModel);
   GeefActiefDocument(model: IGeefDocumentenResultaatModel): IDocumentModel;

   GeefDocument(id: number): ng.IPromise<IDocumentModel>;
   OpslaanDocument(doc: IDocumentModel): ng.IPromise<boolean>;
   VerwijderDocument(id: number): ng.IPromise<boolean>;
   GeefPreviewUrl(doc: IDocumentModel, mimetype: boolean): string;

   GeefMap(folderId: number): ng.IPromise<IDocumentMapModel>;
   GeefOfferteMap(): ng.IPromise<IDocumentMapModel>;
   GeefContractorMap(): ng.IPromise<IDocumentMapModel>;
   OpslaanMap(map: IDocumentMapModel): ng.IPromise<boolean>;
   VerwijderMap(folderId: number): ng.IPromise<boolean>;

   UploadFiles(folderId: IDocumentMapModel, files: any, errFiles: any): ng.IPromise<any>;
   IsDocumentBeheerder(): boolean;

   GenereerWoningMappenStructuur(): ng.IPromise<{}>;
   GenereerPriveMappenStructuur(): ng.IPromise<{}>;
   GenereerAlgemeneMappenStructuur(): ng.IPromise<{}>;
   GenereerIngediendeOptiesMappenStructuur(): ng.IPromise<{}>;
   GenereerOffertesMappenStructuur(): ng.IPromise<{}>;
   GenereerPersoonsGebondenMappen(): ng.IPromise<{}>;

   IsBewoner(): boolean;

   GeefZichtbareChildMappen(folder: IDocumentMapModel): IDocumentMapModel[];

   // oud
   MaakAdviesAanvraagMelding(opmerking: string, documentid: number): ng.IPromise<boolean>;
   AddFile(filenaam: string, relatiefPad: string, persoonId: number, mapid: number): ng.IPromise<string>;
   UploadStringToFile(map: DocumentMap, stringToUpload: string, filenaam: string, persoonId: number);

}

@Injectable({
	providedIn: 'root',
})
export class DocumentService implements IDocumentService {
   serviceEventName: string = 'DocumentService-event';

   public activeFolderId: number = null;
   public activeDocumentId: number = null;

   openFolders: Array<number> = [];
   changeSubject: BehaviorSubject<any>;
   
   constructor(private $http: HttpClient,
      private documentConsumer: DocumentenConsumer,
      private accountService: AccountService,
      private config: AppConfigService) {
      'ngInject';
      this.changeSubject = new BehaviorSubject(0);
      
   }

   public GeefDocumentenModel(personId: number, archive: boolean, filters: MapType[]): ng.IPromise<IGeefDocumentenResultaatModel> {
      console.log('documenten service');
      var documentInput = new GeefDocumentenInputModel();

      documentInput.Archief = archive;
      documentInput.MapTypes = filters;

      if (personId != undefined && personId != -1) {
         documentInput.PersoonId = personId;
      }
      else {
         documentInput.PersoonId = this.accountService.GeefHuidigPersoonID();
      }

      return this.documentConsumer.GeefDocumentenModel_Observable(documentInput).toPromise();
   }

   public GeefZichtbareChildMappen(folder: IDocumentMapModel): IDocumentMapModel[] {
      var childFolders: Array<IDocumentMapModel> = new Array();

      for (var i = 0; i < folder.ChildMappen.length; i++) {
         if (folder.ChildMappen[i].Verbergen) {

            var tempchildarray = this.GeefZichtbareChildMappen(folder.ChildMappen[i]);

            _.each(tempchildarray, (map) => {
               childFolders.push(map);
            });
         }
         else {
            childFolders.push(folder.ChildMappen[i]);
         }
      }

      return childFolders;
   }

   public GenereerPersoonsGebondenMappen(): ng.IPromise<{}> {

      let obs = new Observable(observer => {
         this.documentConsumer.GenereerPersoonsGebondenMappenStructuur_Observable().toPromise().then(r => {
            observer.next(r);
            observer.complete();
         });
      });
      
      return obs.toPromise();
   }

   public GenereerWoningMappenStructuur(): ng.IPromise<{}> {

      let obs = new Observable(observer => {
         this.documentConsumer.GenereerWoningMappenStructuur_Observable().toPromise().then(r => {
            observer.next(r);
            observer.complete();
         });
      });
      
      return obs.toPromise();
   }

   public GenereerPriveMappenStructuur(): ng.IPromise<{}> {

      let obs = new Observable(observer => {
         this.documentConsumer.GenereerPriveMappenStructuur_Observable().toPromise().then(r => {
            observer.next(r);
            observer.complete();
         });
      });
      
      return obs.toPromise();
   }

   public GenereerAlgemeneMappenStructuur(): ng.IPromise<{}> {

      let obs = new Observable(observer => {
         this.documentConsumer.GenereerAlgemeneDocumentenStructuur_Observable().toPromise().then(r => {
            observer.next(r);
            observer.complete();
         });
      });
      
      return obs.toPromise();
   }

   public GenereerIngediendeOptiesMappenStructuur(): ng.IPromise<{}> {

      let obs = new Observable(observer => {
         this.documentConsumer.GenereerIngediendeOptiesMappenStructuur_Observable().toPromise().then(r => {
            observer.next(r);
            observer.complete();
         });
      });
      
      return obs.toPromise();
   }

   public GenereerOffertesMappenStructuur(): ng.IPromise<{}> {
      return this.documentConsumer.GenereerOfferteMappenStructuur_Observable().toPromise();
   }

   public triggerChange(): void {
      this.changeSubject.next(0);
   } 

   public OpenMap(folder: IDocumentMapModel): void {
      this.openFolders.push(folder.Id);
   }

   public SluitMap(folder: IDocumentMapModel): void {
      for (var i = 0; i < this.openFolders.length; i++) {
         if (this.openFolders[i] == folder.Id) {
            this.openFolders.splice(i, 1);
         }
      }
   }

   public IsOpen(folder: IDocumentMapModel): boolean {
      if (folder != undefined) {
         for (var i = 0; i < this.openFolders.length; i++) {
            if (this.openFolders[i] == folder.Id) {
               return true;
            }
         }
      }

      return false;
   }

   public ZetActieveMap(folder: IDocumentMapModel): void {
      if (this.activeFolderId != folder.Id) {
         this.activeFolderId = folder.Id;
      }

      this.activeDocumentId = null;
   }

   public GeefActieveMap(model: IGeefDocumentenResultaatModel): IDocumentMapModel {
      if (this.activeFolderId != undefined) {
         for (var i = 0; i < model.Root.ChildMappen.length; i++) {
            var folder = this.VindActieveMap(model.Root.ChildMappen[i]);

            if (folder != undefined) {
               return folder;
            }
         }
      }

      return null;
   }

   private VindActieveMap(folder: IDocumentMapModel): IDocumentMapModel {
      if (folder.Id == this.activeFolderId) {
         return folder;
      }

      for (var i = 0; i < folder.ChildMappen.length; i++) {
         var foundFolder = this.VindActieveMap(folder.ChildMappen[i]);

         if (foundFolder != undefined) {
            return foundFolder;
         }
      }

      return null;
   }

   public ZetActiefDocument(doc: IDocumentModel): void {
      if (this.activeDocumentId != doc.Id) {
         this.activeDocumentId = doc.Id;
      }
   }

   public GeefActiefDocument(model: IGeefDocumentenResultaatModel): IDocumentModel {
      if (this.activeDocumentId != undefined) {
         if (model != undefined) {
            for (var i = 0; i < model.Root.ChildMappen.length; i++) {
               var doc = this.VindActiefDocument(model.Root.ChildMappen[i]);

               if (doc != undefined) {
                  return doc;
               }
            }
         }
      }

      return null;
   }

   private VindActiefDocument(folder: IDocumentMapModel): IDocumentModel {
      for (var i = 0; i < folder.Documenten.length; i++) {
         if (folder.Documenten[i].Id == this.activeDocumentId) {
            return folder.Documenten[i];
         }
      }

      for (var i = 0; i < folder.ChildMappen.length; i++) {
         var foundFolder = this.VindActiefDocument(folder.ChildMappen[i]);

         if (foundFolder != undefined) {
            return foundFolder;
         }
      }

      return null;
   }

   public GeefDocument(id: number): ng.IPromise<IDocumentModel> {

      let obs = new Observable<IDocumentModel>(observer => {
         this.documentConsumer.GeefDocumentModel_Observable(id).toPromise().then(r => {
            observer.next(r);
            observer.complete();
         });
      });
      
      return obs.toPromise();
   }

   public OpslaanDocument(doc: IDocumentModel): ng.IPromise<boolean> {

      let documentModel = new DocumentModel(doc);

      let obs = new Observable<boolean>(observer => {
         this.documentConsumer.OpslaanDocumentModel_Observable(documentModel).toPromise().then(r => {
            observer.next(r);
            observer.complete();
            this.triggerChange();
         });
      });
      
      return obs.toPromise();
   }

   public VerwijderDocument(id: number): ng.IPromise<boolean> {

      let obs = new Observable<boolean>(observer => {
         this.documentConsumer.VerwijderDocument_Observable(id).toPromise().then(r => {
            observer.next(r);
            observer.complete();
            this.triggerChange();
         });
      });

      return obs.toPromise();
   }

   public GeefPreviewUrl(doc: IDocumentModel, mime: boolean): string {
      return '/DocumentenDownload/InlineDocument?mime=' + mime + '&id=' + doc.Id;
   }

   public GeefMap(folderId: number): ng.IPromise<IDocumentMapModel> {

      let obs = new Observable<IDocumentMapModel>(observer => {
         this.documentConsumer.GeefDocumentMapModel_Observable(folderId).toPromise().then(r => {
            observer.next(r);
            observer.complete();
         });
      });
      return obs.toPromise();
   }

   public GeefOfferteMap(): ng.IPromise<IDocumentMapModel> {

      let obs = new Observable<IDocumentMapModel>(observer => {
         this.documentConsumer.GeefOffertesDocumentMapModel_Observable().toPromise().then(r => {
            observer.next(r);
            observer.complete();
         });
      });
      return obs.toPromise();
   }

   public GeefContractorMap(): ng.IPromise<IDocumentMapModel> {

      let obs = new Observable<IDocumentMapModel>(observer => {
         this.documentConsumer.GeefContractorDocumentMapModel_Observable().toPromise().then(r => {
            observer.next(r);
            observer.complete();
         });
      });
      return obs.toPromise();
   }

   public OpslaanMap(folder: IDocumentMapModel): ng.IPromise<boolean> {

      let obs = new Observable<boolean>(observer => {
         let documentMapModel = new DocumentMapModel(folder);
         this.documentConsumer.OpslaanDocumentMapModel_Observable(documentMapModel).toPromise().then(r => {
            observer.next(r);
            observer.complete();
            this.triggerChange();
         });
      });
      return obs.toPromise();
   }

   public VerwijderMap(folderId: number): ng.IPromise<boolean> {

      let obs = new Observable<boolean>(observer => {
         this.documentConsumer.VerwijderDocumentMapModel_Observable(folderId).toPromise().then(r => {
            observer.next(r);
            observer.complete();
            this.activeDocumentId = null;
            this.activeFolderId = null;
            this.triggerChange();
         });
      });
      return obs.toPromise();
   }

   public UploadFiles(folder: IDocumentMapModel, files: any): ng.IPromise<any> {
      if (files.length === 0) {
         return;
      }
      let obs = new Observable(observer => {
         let folderId = folder.Id;
         let url = '/documenten/UploadHttp?mapid=' + folderId;
         const formData: FormData = new FormData();
         for (let file of files) {
            formData.append('uploadFiles', file, file.name);
         }
         const request = new HttpRequest('POST', url, formData);
         this.$http.request(request).subscribe(r => {
            if (r.type == HttpEventType.Response) {
               observer.next(r.body[0]);
               observer.complete();
               this.triggerChange();
            }
         });
      });
      return obs.toPromise();
   }

   public IsDocumentBeheerder(): boolean {
      return this.config.Software.Rollen.toLowerCase().indexOf("documentenbeheerder") != -1;
   }

   public IsBewoner(): boolean {
      let isBewoner: boolean = (this.accountService.HeeftRol("Huurder") || this.accountService.HeeftRol("Eigenaar"));
      return isBewoner;
   }

   // oud

   public MaakAdviesAanvraagMelding(opmerking: string, documentid: number): ng.IPromise<boolean> {

      var data: any = {};
      data.pOpmerking = opmerking;
      data.pDocumentId = documentid;

      return this.$http.post<boolean>('/Documenten/MaakAdviesAanvraagMelding', data).toPromise();
   }

   public AddFile(fileName: string, relativePath: string, personId: number, folderId: number): ng.IPromise<string> {

      var data: any = {};
      data.fileName = fileName;
      data.mapid = folderId;
      data.persoonId = personId;
      data.relatiefPad = relativePath;

      return this.$http.post<string>('/Documenten/AddFile', data).pipe(map( () => {
         let document: Document = new Document();
         document.Naam = fileName;
         document.Grootte = 0;
         document.NogLeeg = true;

         document.ZetOverigeVelden();
         return fileName;
      })).toPromise();
   }

   public UploadStringToFile(documentMap: DocumentMap, stringToUpload: string, fileName: string, personId: number): ng.IPromise<number> {
      
      var mapid = documentMap.Id;

      var data: any = {};
      data.uploadString = stringToUpload;
      data.fileName = fileName;
      data.mapid = mapid;
      data.persoonId = personId;

      return this.$http.post('/Documenten/UploadStringToFile', data).pipe(map(response => {
         console.log("Documenten-UploadStringToFile: AJAX success");
         
         var document: Document = new Document();
         document.Naam = fileName;
         document.Grootte = stringToUpload.length;
         document.NogLeeg = true;

         document.ZetOverigeVelden();

         documentMap.Documenten.push(document);
         let id = (<{ Id: number }>response['data']).Id;
         return id; 
      })).toPromise();
   }
}

