/**
 * @ref WoonConnectViewModel/ImageAnnotation/CreateNewProjectModel.cs
 */

 import * as DataContracts from "@datacontracts/CreateNewProjectModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.CreateNewProjectRequestModel
	 */
	 

	export interface ICreateNewProjectRequestModel {
		ProjectName: string;
		ToJsonContract(): DataContracts.ICreateNewProjectRequestModel;
	}

	export class CreateNewProjectRequestModel {
		// property-Properties
		public get ProjectName(): string {
			return this._ProjectName;
		}
		public set ProjectName(newProjectName: string) {
			this._ProjectName = newProjectName;
		}

		// field-Properties

		// fields
		protected _ProjectName: string = "";

		constructor(data?: DataContracts.ICreateNewProjectRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICreateNewProjectRequestModel): void {
			if(data) {
				// vul properties
				this._ProjectName = data.ProjectName;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ICreateNewProjectRequestModel {
			return {
				ProjectName: this.ProjectName
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.CreateNewProjectResponseModel
	 */
	 

	export interface ICreateNewProjectResponseModel {
		Success: boolean;
	}

	export class CreateNewProjectResponseModel {
		// property-Properties
		// field-Properties
		public get Success(): boolean {
			return this._Success;
		}
		public set Success(newSuccess: boolean) {
			this._Success = newSuccess;
		}

		// fields
		protected _Success: boolean = false;

		constructor(data?: DataContracts.ICreateNewProjectResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICreateNewProjectResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Success = data.Success;
			}
		}
	}
