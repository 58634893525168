/**
 * @ref WoonConnectViewModel/Account/ChangePasswordWithMailValidationModel.cs
 */

 import * as DataContracts from "@datacontracts/ChangePasswordWithMailValidationModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Account.ChangePasswordWithMailValidationRequestModel
	 */
	 

	export interface IChangePasswordWithMailValidationRequestModel {
		Code: string;
		NewPassword: string;
		ToJsonContract(): DataContracts.IChangePasswordWithMailValidationRequestModel;
	}

	export class ChangePasswordWithMailValidationRequestModel {
		// property-Properties
		public get Code(): string {
			return this._Code;
		}
		public set Code(newCode: string) {
			this._Code = newCode;
		}

		public get NewPassword(): string {
			return this._NewPassword;
		}
		public set NewPassword(newNewPassword: string) {
			this._NewPassword = newNewPassword;
		}

		// field-Properties

		// fields
		protected _Code: string = "";
		protected _NewPassword: string = "";

		constructor(data?: DataContracts.IChangePasswordWithMailValidationRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IChangePasswordWithMailValidationRequestModel): void {
			if(data) {
				// vul properties
				this._Code = data.Code;
				this._NewPassword = data.NewPassword;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IChangePasswordWithMailValidationRequestModel {
			return {
				Code: this.Code,
				NewPassword: this.NewPassword
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Account.ChangePasswordWithMailValidationResponseModel
	 */
	 

	export interface IChangePasswordWithMailValidationResponseModel {
		Bericht: string;
		PasswordChanged: boolean;
		WachtwoordLengte: number;
		Code: string;
		GeldigeCode: boolean;
		User: string;
		Emailadres: string;
	}

	export class ChangePasswordWithMailValidationResponseModel {
		// property-Properties
		public get Bericht(): string {
			return this._Bericht;
		}
		public set Bericht(newBericht: string) {
			this._Bericht = newBericht;
		}

		public get PasswordChanged(): boolean {
			return this._PasswordChanged;
		}
		public set PasswordChanged(newPasswordChanged: boolean) {
			this._PasswordChanged = newPasswordChanged;
		}

		// field-Properties
		public get WachtwoordLengte(): number {
			return this._WachtwoordLengte;
		}
		public set WachtwoordLengte(newWachtwoordLengte: number) {
			this._WachtwoordLengte = newWachtwoordLengte;
		}
		
		public get Code(): string {
			return this._Code;
		}
		public set Code(newCode: string) {
			this._Code = newCode;
		}
		
		public get GeldigeCode(): boolean {
			return this._GeldigeCode;
		}
		public set GeldigeCode(newGeldigeCode: boolean) {
			this._GeldigeCode = newGeldigeCode;
		}
		
		public get User(): string {
			return this._User;
		}
		public set User(newUser: string) {
			this._User = newUser;
		}
		
		public get Emailadres(): string {
			return this._Emailadres;
		}
		public set Emailadres(newEmailadres: string) {
			this._Emailadres = newEmailadres;
		}

		// fields
		protected _Bericht: string = "";
		protected _PasswordChanged: boolean = false;
		protected _WachtwoordLengte: number = 0;
		protected _Code: string = "";
		protected _GeldigeCode: boolean = false;
		protected _User: string = "";
		protected _Emailadres: string = "";

		constructor(data?: DataContracts.IChangePasswordWithMailValidationResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IChangePasswordWithMailValidationResponseModel): void {
			if(data) {
				// vul properties
				this._Bericht = data.Bericht;
				this._PasswordChanged = data.PasswordChanged;
				// vul fields
				this._WachtwoordLengte = data.WachtwoordLengte;
				this._Code = data.Code;
				this._GeldigeCode = data.GeldigeCode;
				this._User = data.User;
				this._Emailadres = data.Emailadres;
			}
		}
	}
