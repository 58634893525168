// [conv] import "(./BuildingDetail).less";
import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { UserManagementConsumer } from '@consumers/UserManagementController';
import { RolesConsumer } from '@consumers/RolesController';


import { ActivatedRoute, Router } from '@angular/router';
import { BuildingDetailViewModel, GetRequestByIdModel, AddressViewModel } from '@models/BuildingViewModels';
import { BuildingManagementConsumer } from '@consumers/BuildingManagementController';
import { ProjectsViewModel } from '@models/ProjectsViewModel';






@Component({
    selector: 'building-detail',
  templateUrl: './BuildingDetail.html'
})
export class WoonBuildingDetailComponent {
    AdresId: string;
    buildingDetails: BuildingDetailViewModel = new BuildingDetailViewModel();
    GetRequestByIdModel: GetRequestByIdModel;
    ProjectsViewModel: ProjectsViewModel = new ProjectsViewModel();

    //Model for Saving 
    AddressModel: AddressViewModel = new AddressViewModel();

    constructor(private user: UserManagementConsumer, private route: ActivatedRoute, private router: Router, private building: BuildingManagementConsumer) {
        this.AdresId = (<any>this.route.params).value.adresid;
        this.GetRequestByIdModel = new GetRequestByIdModel();
        this.GetRequestByIdModel.Id = parseInt(this.AdresId);
        this.Refresh();
    }

    public Refresh(): void {
        console.log("Test of het wordt geladen");

        this.building.GetUserBuilding_Observable(this.GetRequestByIdModel).subscribe((data) => {

            this.buildingDetails = new BuildingDetailViewModel(data);
            console.log(this.buildingDetails);

            if (this.buildingDetails.Project == undefined) {
                this.ProjectsViewModel.Naam = "Nog niet ingevuld";
                this.buildingDetails.Project = this.ProjectsViewModel;
            }

            this.AddressModel = new AddressViewModel(this.buildingDetails.Adres);
          
        })

    }

    onAddressSave() {
        console.log(this.AddressModel);
        this.building.SaveAddress_Observable(this.AddressModel).subscribe((data) => {
            console.log("Saved the model!");
        })
    }

    

}
