// auth.guard.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserService } from './shared/services/user.service';
import { AccountService } from '../services/AccountService';
import * as _ from 'lodash';


export class Guard implements CanActivate {
  constructor(private user: UserService, private router: Router, private acc: AccountService, private roles: string[], private redirect: boolean = true) { }

  valid(): boolean
  {
    if (!this.user.isLoggedIn()) {
      return false;
    }
    if (this.roles.length == 0)
    {
      return true;
    }

    let s = _.some(this.roles, (r) => this.acc.HeeftRol(r));
    if (s)
    {
      return true;
    }
    return false;
  }

  canActivate() {
    if (!this.valid()) {
      if (this.redirect)
      {
        this.router.navigate(['/accountlogin']);
      }
      return false;
    }

    return true;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AdminGuard extends Guard {
  constructor(user: UserService, router: Router, acc: AccountService) {
    super(user, router, acc, ["admin"]);
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserGuard extends Guard {
  constructor(user: UserService, router: Router, acc: AccountService) {

    super(user, router, acc, []);
  }
}
@Injectable({
  providedIn: 'root',
})
export class ConfigureerderGuard extends Guard {
  constructor(user: UserService, router: Router, acc: AccountService) {

    super(user, router, acc, ['MassaConfigureerder']);
  }
}

@Injectable({
  providedIn: 'root',
})
export class DwellingOwnerGuard extends Guard {
  constructor(user: UserService, router: Router, acc: AccountService) {

    super(user, router, acc, ['Eigenaar']);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ConfigureerderOrDwellingOwnerGuard extends Guard {
  constructor(user: UserService, router: Router, acc: AccountService) {

    super(user, router, acc, ['Eigenaar', 'MassaConfigureerder']);
  }
}

// export class ConfigureerderGuardNoRedirect extends Guard {
//   constructor(user: UserService, router: Router, acc: AccountService) {

//     super(user, router, acc, ['MassaConfigureerder'], false);
//   }
// }
