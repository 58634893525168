/**
 * @ref WoonConnectViewModel/TenderDwellingViewModel.cs
 */

 import * as DataContracts from "@datacontracts/TenderDwellingViewModel";

 
import * as DwellingViewModel from "./DwellingViewModel";
import * as TenderViewModel from "./TenderViewModel";
import * as DwellingCostViewModel from "./DwellingCostViewModel";
import * as TenderDwellingViewModelEnum from "@enums/TenderDwellingViewModel";
import * as BuildingComponentImprovementViewModel from "./BuildingComponentImprovementViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.TenderDwellingViewModel
	 */
	 

	export interface ITenderDwellingViewModel {
		Id: number;
		DwellingViewModel: DwellingViewModel.IDwellingViewModel;
		Tender: TenderViewModel.ITenderViewModel;
		DwellingCost: DwellingCostViewModel.IDwellingCostViewModel;
		Status: TenderDwellingViewModelEnum.TenderDwellingStatus;
		Explanation: string;
		DateResidentApproval: Date;
		BuildingComponentImprovements: BuildingComponentImprovementViewModel.IBuildingComponentImprovementViewModel[];
		IsExpanded: boolean;
		IsAllowedToSignIn: boolean;
		ToJsonContract(): DataContracts.ITenderDwellingViewModel;
	}

	export class TenderDwellingViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get DwellingViewModel(): DwellingViewModel.IDwellingViewModel {
			return this._DwellingViewModel;
		}
		public set DwellingViewModel(newDwellingViewModel: DwellingViewModel.IDwellingViewModel) {
			this._DwellingViewModel = newDwellingViewModel;
		}

		public get Tender(): TenderViewModel.ITenderViewModel {
			return this._Tender;
		}
		public set Tender(newTender: TenderViewModel.ITenderViewModel) {
			this._Tender = newTender;
		}

		public get DwellingCost(): DwellingCostViewModel.IDwellingCostViewModel {
			return this._DwellingCost;
		}
		public set DwellingCost(newDwellingCost: DwellingCostViewModel.IDwellingCostViewModel) {
			this._DwellingCost = newDwellingCost;
		}

		public get Status(): TenderDwellingViewModelEnum.TenderDwellingStatus {
			return this._Status;
		}
		public set Status(newStatus: TenderDwellingViewModelEnum.TenderDwellingStatus) {
			this._Status = newStatus;
		}

		public get Explanation(): string {
			return this._Explanation;
		}
		public set Explanation(newExplanation: string) {
			this._Explanation = newExplanation;
		}

		public get DateResidentApproval(): Date {
			return this._DateResidentApproval;
		}
		public set DateResidentApproval(newDateResidentApproval: Date) {
			this._DateResidentApproval = newDateResidentApproval;
		}

		public get BuildingComponentImprovements(): BuildingComponentImprovementViewModel.IBuildingComponentImprovementViewModel[] {
			return this._BuildingComponentImprovements;
		}
		public set BuildingComponentImprovements(newBuildingComponentImprovements: BuildingComponentImprovementViewModel.IBuildingComponentImprovementViewModel[]) {
			this._BuildingComponentImprovements = newBuildingComponentImprovements;
		}

		public get IsExpanded(): boolean {
			return this._IsExpanded;
		}
		public set IsExpanded(newIsExpanded: boolean) {
			this._IsExpanded = newIsExpanded;
		}

		public get IsAllowedToSignIn(): boolean {
			return this._IsAllowedToSignIn;
		}
		public set IsAllowedToSignIn(newIsAllowedToSignIn: boolean) {
			this._IsAllowedToSignIn = newIsAllowedToSignIn;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _DwellingViewModel: DwellingViewModel.IDwellingViewModel = new DwellingViewModel.DwellingViewModel(undefined);
		protected _Tender: TenderViewModel.ITenderViewModel = new TenderViewModel.TenderViewModel(undefined);
		protected _DwellingCost: DwellingCostViewModel.IDwellingCostViewModel = new DwellingCostViewModel.DwellingCostViewModel(undefined);
		protected _Status: TenderDwellingViewModelEnum.TenderDwellingStatus = undefined;
		protected _Explanation: string = "";
		protected _DateResidentApproval: Date = new Date();
		protected _BuildingComponentImprovements: BuildingComponentImprovementViewModel.IBuildingComponentImprovementViewModel[] = [];
		protected _IsExpanded: boolean = false;
		protected _IsAllowedToSignIn: boolean = false;

		constructor(data?: DataContracts.ITenderDwellingViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITenderDwellingViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._DwellingViewModel = data.DwellingViewModel ? new DwellingViewModel.DwellingViewModel(data.DwellingViewModel): undefined;
				this._Tender = data.Tender ? new TenderViewModel.TenderViewModel(data.Tender): undefined;
				this._DwellingCost = data.DwellingCost ? new DwellingCostViewModel.DwellingCostViewModel(data.DwellingCost): undefined;
				this._Status = data.Status;
				this._Explanation = data.Explanation;
				this._DateResidentApproval = data.DateResidentApproval;
				this._BuildingComponentImprovements = data.BuildingComponentImprovements ? data.BuildingComponentImprovements.map(buildingComponentImprovementViewModelItem => new BuildingComponentImprovementViewModel.BuildingComponentImprovementViewModel(buildingComponentImprovementViewModelItem)): [];
				this._IsExpanded = data.IsExpanded;
				this._IsAllowedToSignIn = data.IsAllowedToSignIn;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITenderDwellingViewModel {
			return {
				Id: this.Id,
				DwellingViewModel: (this.DwellingViewModel ? new DwellingViewModel.DwellingViewModel(this.DwellingViewModel).ToJsonContract(): this.DwellingViewModel),
				Tender: (this.Tender ? new TenderViewModel.TenderViewModel(this.Tender).ToJsonContract(): this.Tender),
				DwellingCost: (this.DwellingCost ? new DwellingCostViewModel.DwellingCostViewModel(this.DwellingCost).ToJsonContract(): this.DwellingCost),
				Status: this.Status,
				Explanation: this.Explanation,
				DateResidentApproval: this.DateResidentApproval,
				BuildingComponentImprovements: (this.BuildingComponentImprovements ? this.BuildingComponentImprovements.map(buildingComponentImprovementViewModelItem => new BuildingComponentImprovementViewModel.BuildingComponentImprovementViewModel(buildingComponentImprovementViewModelItem).ToJsonContract()): []),
				IsExpanded: this.IsExpanded,
				IsAllowedToSignIn: this.IsAllowedToSignIn
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.TenderDwellingRequestAllModel
	 */
	 

	export interface ITenderDwellingRequestAllModel {
		Temp: string;
		ToJsonContract(): DataContracts.ITenderDwellingRequestAllModel;
	}

	export class TenderDwellingRequestAllModel {
		// property-Properties
		public get Temp(): string {
			return this._Temp;
		}
		public set Temp(newTemp: string) {
			this._Temp = newTemp;
		}

		// field-Properties

		// fields
		protected _Temp: string = "";

		constructor(data?: DataContracts.ITenderDwellingRequestAllModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITenderDwellingRequestAllModel): void {
			if(data) {
				// vul properties
				this._Temp = data.Temp;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITenderDwellingRequestAllModel {
			return {
				Temp: this.Temp
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.TenderDwellingsViewModel
	 */
	 

	export interface ITenderDwellingsViewModel {
		TenderDwellings: TenderDwellingViewModel[];
		ToJsonContract(): DataContracts.ITenderDwellingsViewModel;
	}

	export class TenderDwellingsViewModel {
		// property-Properties
		public get TenderDwellings(): TenderDwellingViewModel[] {
			return this._TenderDwellings;
		}
		public set TenderDwellings(newTenderDwellings: TenderDwellingViewModel[]) {
			this._TenderDwellings = newTenderDwellings;
		}

		// field-Properties

		// fields
		protected _TenderDwellings: TenderDwellingViewModel[] = [];

		constructor(data?: DataContracts.ITenderDwellingsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITenderDwellingsViewModel): void {
			if(data) {
				// vul properties
				this._TenderDwellings = data.TenderDwellings ? data.TenderDwellings.map(tenderDwellingViewModelItem => new TenderDwellingViewModel(tenderDwellingViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITenderDwellingsViewModel {
			return {
				TenderDwellings: (this.TenderDwellings ? this.TenderDwellings.map(tenderDwellingViewModelItem => new TenderDwellingViewModel(tenderDwellingViewModelItem).ToJsonContract()): [])
			}
		}
	}
