import { Inject, Injectable } from '@angular/core';
import * as ServicesProjectService from '../services/ProjectService';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { WINDOW } from '../infrastructure/windowprovider';
import { AccountService } from '../services/AccountService';
import { LogonConsumer } from '@consumers/LogonController';
import { CapabilitiesRequestModel } from '../data/woonconnectkern/models/CapabilitiesModel';
export interface ICapabilitiesService {
	/**
	 * Whether the client is using IE10+.
	 */
	IsMicrosoftInternetExplorer: boolean;
	/**
	 * Whether the client is using MS Edge.
	 */
	IsMicrosoftEdge: boolean;
	/**
	 * Whether the client is using an iPod, iPad or iPhone.
	 */
	IsiOS: boolean;
	/**
	 * Whether the client is using a iMac or Macbook computer.
	 */
	IsImacOfMacbook: boolean;
	/**
	 * Whether the client is using FireFox.
	 */
	IsFirefox: boolean;
	/**
	 * Whether the client is using Safari browser.
	 */
	IsSafari: boolean;
	/**
	 * Whether the client is using Chrome browser.
	 */
	IsChrome: boolean;
	/**
	 * ??
	 */
	BrowserUA: string;

	DetectBrowserCapabilities(): void;
	IsiPadTablet: boolean;
	IsAndroidTablet: boolean;
}

///**
// * Singleton capabilities service provider.
// */
//export class CapbilitiesServiceProvider implements ng.IServiceProvider {
//	capabilitiesservice: ICapabilitiesService;

//	constructor(private $http: ng.IHttpService) {
//'ngInject';
//		this.capabilitiesservice = new CapabilitiesService($http);
//	}

//	$get(): ICapabilitiesService {
//		return this.capabilitiesservice;
//	}
//}

@Injectable({
	providedIn: 'root',
})
export class CapabilitiesService implements ICapabilitiesService {
	deviceInfo: DeviceInfo;
	get BrowserUA(): string {
		return this._BrowserUA;
	}

	get IsiOS(): boolean {
		return this._IsiOS;
	}

	get IsiPadTablet(): boolean {
		return this._IsiPadTablet;
	}

	get IsAndroidTablet(): boolean {
		return this._IsAndroidTablet;
	}

	get IsImacOfMacbook(): boolean {
		return this._IsImacOfMacbook;
	}

	get IsFirefox(): boolean {
		return this._IsFirefox;
	}

	get IsMicrosoftEdge(): boolean {
		return this._IsMicrosoftEdge;
	}

	get IsMicrosoftInternetExplorer(): boolean {
		return this._IsMicrosoftInternetExplorer;
	}

	get IsSafari(): boolean {
		return this._IsSafari;
	}

	get IsChrome(): boolean {
		return this._IsChrome;
	}

	_BrowserUA: string;
	_IsMicrosoftInternetExplorer: boolean;
	_IsiOS: boolean;
	_IsiPadTablet: boolean;
	_IsAndroidTablet: boolean;
	_IsImacOfMacbook: boolean;
	_IsMicrosoftEdge: boolean;
	_IsFirefox: boolean;
	_IsSafari: boolean;
	_IsChrome: boolean;

	constructor(private deviceService: DeviceDetectorService, 
		//private accountservice: AccountService,
		private logonConsumer: LogonConsumer,
		@Inject(WINDOW) private window: Window,
		) {
		//constructor(private $http: HttpClient, private projectservice: ServicesProjectService.ProjectService) {
		this.DetectBrowserCapabilities();
	}


	DetectBrowserCapabilities(): void {
		this._IsMicrosoftInternetExplorer = (navigator.userAgent.match(/(MSIE|Trident)/g) != null);
		this._IsiOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) != null);
		this._IsiPadTablet = (navigator.userAgent.match(/(iPad)/g) != null);
		this._IsAndroidTablet = (navigator.userAgent.match(/(Android)/g) != null);
		this._IsImacOfMacbook = (navigator.platform.match(/(Mac)/i) != null);
		this._IsMicrosoftEdge = (navigator.userAgent.match(/Edge\/\d+/g) != null);
		this._IsFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
		this._IsSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		this._IsChrome = navigator.vendor.toLowerCase().indexOf('google') > - 1;
		this._BrowserUA = this._IsiOS ? 'IOS' : (this._IsMicrosoftInternetExplorer ? 'MSIE' : 'Other');

		this.deviceInfo = this.deviceService.getDeviceInfo();
		
		const browser = "browser=" + this.deviceInfo.browser + ", version " + this.deviceInfo.browser_version;
		const device = "device=" + this.deviceInfo.device + ", type " + this.deviceInfo.deviceType;
		const os = "osfamily=" + this.deviceInfo.os + ", version " + this.deviceInfo.os_version;
		const orientation = "orientation=" + this.deviceInfo.orientation;
		const resolutie = "outerresolution=" + this.window.outerWidth + " x " + this.window.outerHeight + ", innerresolution=" + this.window.innerWidth + " x " + this.window.innerHeight + ", devicepixelratio=" + this.window.devicePixelRatio;

		let message = browser + ', ' + device + ', ' + os + ', ' + orientation + ', ' + resolutie;

		//message += ", PersoonID=" + this.accountservice.GeefHuidigPersoonID();

		var req = new CapabilitiesRequestModel();
		req.Browser = this.deviceInfo.browser;
		req.BrowserVersion = this.deviceInfo.browser_version;
		req.Device = this.deviceInfo.device;
		req.DeviceType = this.deviceInfo.deviceType;
		req.InnerHeight = this.window.innerHeight;
		req.InnerWidth = this.window.innerWidth;
		req.OuterHeight = this.window.outerHeight;
		req.OuterWidth = this.window.outerWidth;
		req.DevicePixelRatio = this.window.devicePixelRatio;
		req.OS = this.deviceInfo.os;
		req.OSVersion = this.deviceInfo.os_version;
		req.Orientation = this.deviceInfo.orientation;
		req.UserAgent = navigator.userAgent;

		this.logonConsumer.Capabilities_Observable(req).subscribe(x =>
			{
				// done
				
			});

		// Speciale severity, die altijd naar NLog wordt gestuurd
		//JL().log(3500, "CAPABILITIES " + message);
		//HelpersBackwards.JL('Javascript.Capabilities').info(message);
	}
}
