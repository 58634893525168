/**
 * @ref WoonConnectKern/Models/AccountModels.cs
 */

 import * as DataContracts from "@datacontracts/AccountModels";

 
import * as ISecurityServiceEnum from "@enums/ISecurityService";



	/**
	 * Originele namespace: WoonConnectKern.Models.AccountViewModel
	 */
	 

	export interface IAccountViewModel {
		ActivatieCode: string;
		AlGeactiveerd: boolean;
		PasswordResult: ISecurityServiceEnum.SetNewPasswordResult;
		Melding: string;
		PersoonId: number;
		EmailAdres: string;
		BevestigEmailAdres: string;
		Wachtwoord: string;
		BevestigWachtwoord: string;
		Voorletters: string;
		Voornaam: string;
		Tussenvoegsel: string;
		Achternaam: string;
		Telefoonnummer: string;
		Straat: string;
		Huisnr: string;
		Postcode: string;
		Stad: string;
		SamengesteldAdres: string;
		WoningId: number;
		MetMailBevestiging: boolean;
		WachtOpBevestiging: boolean;
		Geboortedatum: Date;
		Geslacht: number;
		Rol: string;
		UserName: string;
		ToJsonContract(): DataContracts.IAccountViewModel;
	}

	export class AccountViewModel {
		// property-Properties
		public get ActivatieCode(): string {
			return this._ActivatieCode;
		}
		public set ActivatieCode(newActivatieCode: string) {
			this._ActivatieCode = newActivatieCode;
		}

		public get AlGeactiveerd(): boolean {
			return this._AlGeactiveerd;
		}
		public set AlGeactiveerd(newAlGeactiveerd: boolean) {
			this._AlGeactiveerd = newAlGeactiveerd;
		}

		public get PasswordResult(): ISecurityServiceEnum.SetNewPasswordResult {
			return this._PasswordResult;
		}

		public get Melding(): string {
			return this._Melding;
		}
		public set Melding(newMelding: string) {
			this._Melding = newMelding;
		}

		public get PersoonId(): number {
			return this._PersoonId;
		}
		public set PersoonId(newPersoonId: number) {
			this._PersoonId = newPersoonId;
		}

		public get EmailAdres(): string {
			return this._EmailAdres;
		}
		public set EmailAdres(newEmailAdres: string) {
			this._EmailAdres = newEmailAdres;
		}

		public get BevestigEmailAdres(): string {
			return this._BevestigEmailAdres;
		}
		public set BevestigEmailAdres(newBevestigEmailAdres: string) {
			this._BevestigEmailAdres = newBevestigEmailAdres;
		}

		public get Wachtwoord(): string {
			return this._Wachtwoord;
		}
		public set Wachtwoord(newWachtwoord: string) {
			this._Wachtwoord = newWachtwoord;
		}

		public get BevestigWachtwoord(): string {
			return this._BevestigWachtwoord;
		}
		public set BevestigWachtwoord(newBevestigWachtwoord: string) {
			this._BevestigWachtwoord = newBevestigWachtwoord;
		}

		public get Voorletters(): string {
			return this._Voorletters;
		}
		public set Voorletters(newVoorletters: string) {
			this._Voorletters = newVoorletters;
		}

		public get Voornaam(): string {
			return this._Voornaam;
		}
		public set Voornaam(newVoornaam: string) {
			this._Voornaam = newVoornaam;
		}

		public get Tussenvoegsel(): string {
			return this._Tussenvoegsel;
		}
		public set Tussenvoegsel(newTussenvoegsel: string) {
			this._Tussenvoegsel = newTussenvoegsel;
		}

		public get Achternaam(): string {
			return this._Achternaam;
		}
		public set Achternaam(newAchternaam: string) {
			this._Achternaam = newAchternaam;
		}

		public get Telefoonnummer(): string {
			return this._Telefoonnummer;
		}
		public set Telefoonnummer(newTelefoonnummer: string) {
			this._Telefoonnummer = newTelefoonnummer;
		}

		public get Straat(): string {
			return this._Straat;
		}
		public set Straat(newStraat: string) {
			this._Straat = newStraat;
		}

		public get Huisnr(): string {
			return this._Huisnr;
		}
		public set Huisnr(newHuisnr: string) {
			this._Huisnr = newHuisnr;
		}

		public get Postcode(): string {
			return this._Postcode;
		}
		public set Postcode(newPostcode: string) {
			this._Postcode = newPostcode;
		}

		public get Stad(): string {
			return this._Stad;
		}
		public set Stad(newStad: string) {
			this._Stad = newStad;
		}

		public get SamengesteldAdres(): string {
			return this._SamengesteldAdres;
		}
		public set SamengesteldAdres(newSamengesteldAdres: string) {
			this._SamengesteldAdres = newSamengesteldAdres;
		}

		public get WoningId(): number {
			return this._WoningId;
		}
		public set WoningId(newWoningId: number) {
			this._WoningId = newWoningId;
		}

		public get MetMailBevestiging(): boolean {
			return this._MetMailBevestiging;
		}
		public set MetMailBevestiging(newMetMailBevestiging: boolean) {
			this._MetMailBevestiging = newMetMailBevestiging;
		}

		public get WachtOpBevestiging(): boolean {
			return this._WachtOpBevestiging;
		}
		public set WachtOpBevestiging(newWachtOpBevestiging: boolean) {
			this._WachtOpBevestiging = newWachtOpBevestiging;
		}

		public get Geboortedatum(): Date {
			return this._Geboortedatum;
		}
		public set Geboortedatum(newGeboortedatum: Date) {
			this._Geboortedatum = newGeboortedatum;
		}

		public get Geslacht(): number {
			return this._Geslacht;
		}
		public set Geslacht(newGeslacht: number) {
			this._Geslacht = newGeslacht;
		}

		public get Rol(): string {
			return this._Rol;
		}
		public set Rol(newRol: string) {
			this._Rol = newRol;
		}

		public get UserName(): string {
			return this._UserName;
		}
		public set UserName(newUserName: string) {
			this._UserName = newUserName;
		}

		// field-Properties

		// fields
		protected _ActivatieCode: string = "";
		protected _AlGeactiveerd: boolean = false;
		protected _PasswordResult: ISecurityServiceEnum.SetNewPasswordResult = undefined;
		protected _Melding: string = "";
		protected _PersoonId: number = 0;
		protected _EmailAdres: string = "";
		protected _BevestigEmailAdres: string = "";
		protected _Wachtwoord: string = "";
		protected _BevestigWachtwoord: string = "";
		protected _Voorletters: string = "";
		protected _Voornaam: string = "";
		protected _Tussenvoegsel: string = "";
		protected _Achternaam: string = "";
		protected _Telefoonnummer: string = "";
		protected _Straat: string = "";
		protected _Huisnr: string = "";
		protected _Postcode: string = "";
		protected _Stad: string = "";
		protected _SamengesteldAdres: string = "";
		protected _WoningId: number = 0;
		protected _MetMailBevestiging: boolean = false;
		protected _WachtOpBevestiging: boolean = false;
		protected _Geboortedatum: Date = new Date();
		protected _Geslacht: number = 0;
		protected _Rol: string = "";
		protected _UserName: string = "";

		constructor(data?: DataContracts.IAccountViewModel) {
			if(data){
				this._PasswordResult = data.PasswordResult;
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAccountViewModel): void {
			if(data) {
				// vul properties
				this._ActivatieCode = data.ActivatieCode;
				this._AlGeactiveerd = data.AlGeactiveerd;
				this._Melding = data.Melding;
				this._PersoonId = data.PersoonId;
				this._EmailAdres = data.EmailAdres;
				this._BevestigEmailAdres = data.BevestigEmailAdres;
				this._Wachtwoord = data.Wachtwoord;
				this._BevestigWachtwoord = data.BevestigWachtwoord;
				this._Voorletters = data.Voorletters;
				this._Voornaam = data.Voornaam;
				this._Tussenvoegsel = data.Tussenvoegsel;
				this._Achternaam = data.Achternaam;
				this._Telefoonnummer = data.Telefoonnummer;
				this._Straat = data.Straat;
				this._Huisnr = data.Huisnr;
				this._Postcode = data.Postcode;
				this._Stad = data.Stad;
				this._SamengesteldAdres = data.SamengesteldAdres;
				this._WoningId = data.WoningId;
				this._MetMailBevestiging = data.MetMailBevestiging;
				this._WachtOpBevestiging = data.WachtOpBevestiging;
				this._Geboortedatum = data.Geboortedatum;
				this._Geslacht = data.Geslacht;
				this._Rol = data.Rol;
				this._UserName = data.UserName;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAccountViewModel {
			return {
				ActivatieCode: this.ActivatieCode,
				AlGeactiveerd: this.AlGeactiveerd,
				PasswordResult: this.PasswordResult,
				Melding: this.Melding,
				PersoonId: this.PersoonId,
				EmailAdres: this.EmailAdres,
				BevestigEmailAdres: this.BevestigEmailAdres,
				Wachtwoord: this.Wachtwoord,
				BevestigWachtwoord: this.BevestigWachtwoord,
				Voorletters: this.Voorletters,
				Voornaam: this.Voornaam,
				Tussenvoegsel: this.Tussenvoegsel,
				Achternaam: this.Achternaam,
				Telefoonnummer: this.Telefoonnummer,
				Straat: this.Straat,
				Huisnr: this.Huisnr,
				Postcode: this.Postcode,
				Stad: this.Stad,
				SamengesteldAdres: this.SamengesteldAdres,
				WoningId: this.WoningId,
				MetMailBevestiging: this.MetMailBevestiging,
				WachtOpBevestiging: this.WachtOpBevestiging,
				Geboortedatum: this.Geboortedatum,
				Geslacht: this.Geslacht,
				Rol: this.Rol,
				UserName: this.UserName
			}
		}
	}
