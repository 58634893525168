import { NgModule, } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'

import { WctSurveyComponent } from './components/WctSurvey';
import { WctAfmeldenComponent } from './components/WctAfmelden';
import { WctSurveyStringComponent } from './components/WctSurveyString';

//import { StoreModule } from '@ngrx';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { surveyFeatureKey, surveyReducer } from './store/survey.reducer';
import { SurveyEffects } from './store/survey.effects';
import { } from './store/survey.effects';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgrxFormsModule } from 'ngrx-forms';
import { SmarttwinUiModule } from '../smarttwin-ui/smarttwin-ui.module';
import { WctSurveyCalculationZoneComponent } from './components/wct-survey-calculation-zone/wct-survey-calculation-zone.component';
import { WctSurveyClimateSystemComponent } from './components/wct-survey-climate-system/wct-survey-climate-system.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { WctSurveyNotesComponent } from './components/wct-survey-notes/wct-survey-notes.component';
import { WctSurveyMainComponent } from './components/wct-survey-main/wct-survey-main.component';

import { CustomErrorStateMatcherDirective } from '../../directives/ngrxFormsMaterial/error-state-matcher';
import { MatListOptionFixDirective } from '../../directives/ngrxFormsMaterial/mat-list-option-fix';
import { NgrxMatSelectViewAdapter } from '../../directives/ngrxFormsMaterial/mat-select-view-adapter';
import { WctSurveyQuestionComponent } from './components/wct-survey-question/wct-survey-question.component';
import { WctSurveyQuestionGroupComponent } from './components/wct-survey-question-group/wct-survey-question-group.component';
import { WctSurveyFacadesComponent } from './components/wct-survey-facades/wct-survey-facades.component';
import { WctSurveyGenericComponent } from './components/wct-survey-generic/wct-survey-generic.component';
import { WctSurveyBuildingComponent } from './components/wct-survey-building/wct-survey-building.component';
import { WctSurveyClimateSystemsComponent } from './components/wct-survey-climate-systems/wct-survey-climate-systems.component';
import { DwellingmodelModule } from '../../modules/dwellingmodel/dwellingmodel.module';

const configuratorRoutes: Routes = [

];

const AngularMaterialModules = [
	MatRadioModule,
	MatSelectModule,
  MatInputModule,
  MatIconModule
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		NgrxFormsModule,
		StoreModule.forFeature(surveyFeatureKey, surveyReducer),
		EffectsModule.forFeature([SurveyEffects]),
		SmarttwinUiModule,
		DwellingmodelModule,
		AngularMaterialModules
	],
	providers: [

	],
	declarations: [
		WctSurveyComponent,
		WctSurveyGenericComponent,
		WctAfmeldenComponent,
		WctSurveyCalculationZoneComponent,
		WctSurveyClimateSystemComponent,
		WctSurveyStringComponent,
		WctSurveyMainComponent,
		WctSurveyNotesComponent,
		CustomErrorStateMatcherDirective,
		MatListOptionFixDirective,
		NgrxMatSelectViewAdapter,
		WctSurveyQuestionComponent,
		WctSurveyQuestionGroupComponent,
		WctSurveyFacadesComponent,
		WctSurveyBuildingComponent,
		WctSurveyClimateSystemsComponent,
	],
	exports: [
		WctSurveyMainComponent,
	],
	entryComponents: [
		WctSurveyMainComponent,
	]
})
export class SurveyModule {
	constructor() { }
}
