import { IFilterViewModel, IFilterOptionViewModel } from '@datacontracts/FilterViewModel';
import { FilterDisplayLevelEnum } from '@enums/FilterViewModel';
import { FilterViewModel } from '@models/FilterViewModel';
// [conv] import "(./FabFilters).less";
import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import * as _ from 'lodash';

import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart } from '@angular/router';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { FabrikantenConsumer } from '@consumers/FabrikantenController';

import { BCBProductsOverviewModel } from '@models/BCBProductsOverviewModel';
import { BCBProductViewModel, IBCBProductViewModel } from '@models/BCBProductModel';
import { FabrikantenFilterRequestModel, FabrikantenFilterResponseModel } from '@models/FabrikantFilterModel';
import { GoogleAnalyticsService } from '../../../../../services/GoogleAnalyticsService'
//import { ThemeService } from '../../../shared/theme';

import { ConstSoftware } from '../../../../../Helpers/Backwards';
//import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

@Component({
  selector: 'product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProductComponent {

  Product: string;
  Filters: string;

  Loading: boolean = true;

  FabrikantenFilterResponseModel: FabrikantenFilterResponseModel;
  public ActiveCategory: string = "";
  public ActiveProduct: string = "";
  public FilterMenuDesktopIsShown: boolean = true;
  public FilterMenuMobileIsShown: boolean = false;
  public FilterMenuMobileBackgroundIsShown: boolean = false;
  public SavedValues: Array<FabFilterSavedValue> = new Array<FabFilterSavedValue>();
  public IsLocalhost: boolean = false;
  public SelectedProductIdOld: number = undefined;
  public ActiveStep: number = 1;

  public CategoryId: number;

  constructor(
    private fab: FabrikantenConsumer,
    private route: ActivatedRoute,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {


    this.FabrikantenFilterResponseModel = new FabrikantenFilterResponseModel();
    this.Refresh(true);

    this.CheckMenuFilterIsShown(window);

    if (window.location.href.indexOf("localhost") > -1) {
      this.IsLocalhost = true;
    }

    this.route.paramMap.subscribe(paramMap => {
      let catIdStr = paramMap.get('categoryid');
      this.CategoryId = +catIdStr;
      this.SelectCategory(this.CategoryId);
      this.GoToChooseCategories();
    });

    //history.pushState = (f => function pushState() {
    //  var ret = f.apply(this, arguments);
    //  window.dispatchEvent(new Event('pushstate'));
    //  window.dispatchEvent(new Event('locationchange'));
    //  return ret;
    //})(history.pushState);

    //history.replaceState = (f => function replaceState() {
    //  var ret = f.apply(this, arguments);
    //  window.dispatchEvent(new Event('replacestate'));
    //  window.dispatchEvent(new Event('locationchange'));
    //  return ret;
    //})(history.replaceState);

    //window.addEventListener('popstate', () => {
    //  window.dispatchEvent(new Event('locationchange'))
    //});

    //window.addEventListener('hashchange', () => {
    //  try {
    //    if (window.location.href.indexOf("categorie") != -1) {
    //      this.DeselectActiveProduct();
    //    }
    //  }
    //  catch (exp) {

    //  }
    //});

    //window.addEventListener('locationchange', function () {
    //  try {
    //    if (window.location.href.indexOf("categorie") != -1) {
    //      this.DeselectActiveProduct();
    //    }
    //  }
    //  catch (exp) {

    //  }
    //});

    //this.router.events.subscribe(e => {
    //  if (e instanceof NavigationStart || e instanceof NavigationEnd) {
    //    try {
    //      if (window.location.href.indexOf("categorie") != -1) {
    //        this.DeselectActiveProduct();
    //      }
    //    }
    //    catch (exp) {

    //    }
    //  }
    //});
  }

  public CheckMenuFilterIsShown(window) {
    if (window.innerWidth >= 1000) {
      this.FilterMenuDesktopIsShown = true;
    } else {
      this.FilterMenuDesktopIsShown = false;
    }
  }

  public CheckSlickButtonsAreShown(window) {
    let SlickTrackElement: any = document.getElementsByClassName("slick-track")[0];
    let FabFiltersButtonsForProductElement: any = document.getElementsByClassName("carousel__fab-filters")[0];
    let FabFiltersButtonsButtonsForProductsElement: any = document.getElementsByClassName("fab-filters__buttons-for-products")[0];
    if (SlickTrackElement !== undefined && FabFiltersButtonsForProductElement !== undefined) {
      if (SlickTrackElement.offsetWidth > FabFiltersButtonsForProductElement.offsetWidth) {
        FabFiltersButtonsButtonsForProductsElement.style.margin = "0 25px 0px 25px";
      } else {
        FabFiltersButtonsButtonsForProductsElement.style.margin = "0 -7.5px";
      }
    }

    // let FabFiltersMainElement: any = document.getElementsByClassName("fab-filters__main")[0];
    // let FabFiltersMainTitleElement: any = document.getElementsByClassName("fab-filters__title-for-products")[0];
    // let FabFiltersButtonsForProductsElement: any = document.getElementsByClassName("fab-filters__buttons-for-products")[0];
    // let FabFiltersBreadcrumbElement: any = document.getElementsByClassName("fab-filters__breadcrumb")[0]; 
    // let FabProductTitleElement: any = document.getElementsByClassName("fab-product__title")[0];
    // let FabProductDescriptionElement: any = document.getElementsByClassName("fab-product__description")[0];   
    // let FabFiltersButtonFilterMenuElement: any = document.getElementsByClassName(" fab-filters__button-filter-menu")[0];

    // if (
    //    (FabFiltersMainElement !== undefined) && (FabFiltersMainTitleElement !== undefined) &&
    //    (FabFiltersButtonsForProductsElement !== undefined) && (FabFiltersBreadcrumbElement !== undefined) &&
    //    (FabProductTitleElement !== undefined) && (FabProductDescriptionElement !== undefined) &&
    //    (FabFiltersButtonFilterMenuElement !== undefined) 
    // ) {
    //    ret = FabFiltersMainElement.offsetHeight
  }

  public HideFilterMenuMobile() {
    this.FilterMenuMobileBackgroundIsShown = false;
    setTimeout(() => {
      this.FilterMenuMobileIsShown = false;
    }, 300);
  }

  public ShowFilterMenuMobile() {
    this.FilterMenuMobileIsShown = true;
    setTimeout(() => {
      this.FilterMenuMobileBackgroundIsShown = true;
    }, 300);
  }

  public GetMaximimumHeight() {
    let ret: number = 0;
    if (window.innerHeight > 600) {
      ret = window.innerHeight - 30;
    } else {
      ret = window.innerHeight;
    }
    return ret;
  }

  public SelectProduct(product: BCBProductViewModel) {

    this.googleAnalyticsService.sendCustomEvent(
      "Bestekservice",
      "ProductViewed",
      product.DisplayName,
      product.Id);

    //history.pushState(null, null, "product");

    this.FabrikantenFilterResponseModel.SelectedProduct = product;
    this.ActiveProduct = product.Naam;
    this.SavedValues = new Array<FabFilterSavedValue>();
    this.Refresh(false);
  }

  public GetProductImageUrl(product: BCBProductViewModel): string {

    for (var i = 0; i < product.Links.length; i++) {
      if (product.Links[i].Type == "img") {

        var tSplit1 = product.Links[i].URL.split('|');

        for (var j1 = 0; j1 < tSplit1.length; j1++) {

          var tSplit2 = tSplit1[j1].split(',');

          for (var j = 0; j < tSplit2.length; j++) {
            if (tSplit2[j].startsWith("http")) {
              return this.replaceAll(tSplit2[j], "\\", "/");
            }
          }
        }
      }
    }

    return "";
  }

  public replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  public escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  public GetProductDescription(): string {
    var description = "";

    if (this.FabrikantenFilterResponseModel.SelectedProduct != null) {
      _.forEach(this.FabrikantenFilterResponseModel.SelectedProduct.Variants, variant => {
        description = variant.Description;
      });
    }

    return description;
  }

  public Refresh(First: boolean = false) {

    if (!First) {
      if (this.FabrikantenFilterResponseModel.FiltersParameter != undefined) {
        this.FabrikantenFilterResponseModel.FiltersParameter = undefined;
      }
    }

    this.LoadFilters();
  }

  private LoadFilters(): void {
    this.Loading = true;

    this.fab.FilterOptions_Observable(this.FabrikantenFilterResponseModel).subscribe((data) => {
      this.FabrikantenFilterResponseModel = new FabrikantenFilterResponseModel(data);

      this.Loading = false;

      setTimeout(() => {
        this.CheckSlickButtonsAreShown(window);
      }, 0);

      const FilterCategorie = _.find(this.FabrikantenFilterResponseModel.Filters, (filter) => filter.Name == "Categorie");
      if (FilterCategorie !== undefined) {

        let categoryId = this.route.snapshot.paramMap.get('categoryid');

        if (FilterCategorie.SelectedOption == undefined || FilterCategorie.SelectedOption.Id != +categoryId) {
          this.SelectCategory(+categoryId);
        }
        else {
          this.ActiveCategory = FilterCategorie.SelectedOption.Name;
        }
      }

      if (this.FabrikantenFilterResponseModel.SelectedProduct != undefined) {
        if (this.SelectedProductIdOld == undefined || (this.SelectedProductIdOld !== this.FabrikantenFilterResponseModel.SelectedProduct.Id)) {
          this.SelectedProductIdOld = this.FabrikantenFilterResponseModel.SelectedProduct.Id;
        }
      }

      if (this.FabrikantenFilterResponseModel.DeeplinkModus) {
        console.log("deeplink modus active");
      }

      if (this.UpdateVolgenAfwerkStaat()) {
        this.LoadFilters();
      }

      this.ForceIsolatieTonen();

    });
  }

  public GetButtonRole(product: IBCBProductViewModel): string {
    let ret = "secundary";
    if ((this.FabrikantenFilterResponseModel.SelectedProduct !== undefined) && (this.FabrikantenFilterResponseModel.SelectedProduct !== null)) {
      if (product.Id == this.FabrikantenFilterResponseModel.SelectedProduct.Id) {
        ret = "primary";
      }
    }
    return ret;
  }

  public SetLastSelectedView(lastselectedView: string) {
    if (this.FabrikantenFilterResponseModel.SelectedProduct != undefined) {
      this.FabrikantenFilterResponseModel.SelectedProduct.ViewSelected = lastselectedView;
    }
  }

  public SetLastSelectedProduct(lastselectedproduct: any) {
    this.FabrikantenFilterResponseModel.SelectedProduct = lastselectedproduct;
  }

  public SelectCategory(id: number): void {

    if (this.ActiveProduct != "") {
      this.DeselectActiveProduct();
    }

    if (this.FabrikantenFilterResponseModel.Filters.length == 0) {
      console.log("SelectCategory met 0");
    }
    else {

      var found = false;

      _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
        if (filter.Name == "Categorie") {
          _.forEach(filter.Options, (option, index) => {
            if (option.Id == id) {
              filter.SelectedOption = filter.Options[index];
              this.ActiveCategory = filter.SelectedOption.Name;
              this.SetSavedValue(filter.Id, filter.Options[index].Id);
              found = true;
            }
          });
        }
      });

      if (!found) {
        this.RemoveSelectedAllFilters();
        //alert("Select categorie mislukt!");
      }
      else {
        this.Refresh(false);
      }
    }
  }

  public GoToChooseCategories() {

    //history.pushState(null, null, "categorie/" + this.CategoryId);

    this.FabrikantenFilterResponseModel.SelectedProduct = null;
    this.ActiveProduct = "";
    this.ActiveCategory = "";

    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      filter.SelectedOption = null;
      filter.SelectedOptions = [];
    });

    this.SavedValues = new Array<FabFilterSavedValue>();
    this.Refresh(false);
    this.ActiveCategory = "";
  }

  public GetMaximimumHeightFilterMenu() {
    let ret: number = 0;
    let FabFiltersMenuBackgroundElement: any = document.getElementsByClassName("fab-filters__filter-menu-background")[0];
    if (FabFiltersMenuBackgroundElement !== undefined
    ) {
      ret = FabFiltersMenuBackgroundElement.offsetHeight;
    }
    return ret;
  }

  public GetNumberOfShownProducts() {
    let ret: string = "...";
    if (!this.Loading) {
      ret = this.FabrikantenFilterResponseModel.Products.length.toString();
    }
    return ret;
  }

  public SetSavedValue(id: number, savedValueSet: number) {
    if (this.SavedValues.some(savedValue => savedValue.Id === id)) {
      _.forEach(this.SavedValues, (savedValue: FabFilterSavedValue) => {
        if (savedValue.Id == id) {
          savedValue.Value = savedValueSet;
        }
      });
    } else {
      let savedValue: FabFilterSavedValue = new FabFilterSavedValue(id, savedValueSet);
      this.SavedValues.push(savedValue);
    }
  }

  public FabFiltersIsShown() {
    let ret: boolean = true;

    const FilterWithNameCategoryExists = this.FabrikantenFilterResponseModel.Filters.some(filter => filter.Name.includes("Categorie"));
    if (FilterWithNameCategoryExists && (this.ActiveCategory.length == 0)) {
      ret = false;
    }

    return ret;
  }

  public GetLijnTekening(): string {
    if (this.FabrikantenFilterResponseModel.SelectedProduct.Links.length > 0) {
      if (this.FabrikantenFilterResponseModel.SelectedProduct.Links[0].URL.indexOf('|') != -1) {
        return this.FabrikantenFilterResponseModel.SelectedProduct.Links[0].URL.split('|')[1].split(',')[1];
      }
    }

    return "";
  }

  public ShouldDisplay(filter: IFilterViewModel) {

    if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.none) {
      return false;
    }

    if (this.ActiveProduct == null || this.ActiveProduct == "") {
      if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.leveltwo) {
        return false;
      }
    }
    else {
      if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.levelone) {
        return false;
      }
    }

    return true;
  }

  public DeselectActiveProduct() {
    this.FabrikantenFilterResponseModel.ProductParameter = undefined;
    this.FabrikantenFilterResponseModel.FiltersParameter = undefined;
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      if (filter.Name !== "Categorie") {
        filter.SelectedOption = null;
        filter.SelectedOptionId = "";
        filter.RangeValue = null;
        filter.TextField = "";
      }
    });
    this.FabrikantenFilterResponseModel.SelectedProduct = undefined;

    //history.pushState(null, null, "categorie/" + this.CategoryId);
    //this.FabrikantenFilterResponseModel.SelectedProduct = product;

    this.ActiveProduct = "";
    this.SavedValues = new Array<FabFilterSavedValue>();
    this.Refresh(false);
  }

  public OpenBCB(url: string) {
    this.googleAnalyticsService.sendCustomEvent("Bestekservice", "OpenBCB", this.FabrikantenFilterResponseModel.SelectedProduct.Naam, this.FabrikantenFilterResponseModel.SelectedProduct.Id);
    window.open(url, '_blank');
  }

  public RemoveSelectedFilters() {
    this.SavedValues = new Array<FabFilterSavedValue>();

    //if (this.ActiveStep == 1) {
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      if (filter.Name !== "Categorie") {
        filter.SelectedOption = null;
        filter.SelectedOptionId = "";
        if (filter.SelectedOptions.length > 0) {
          filter.SelectedOptions.splice(0, filter.SelectedOptions.length);
        }
        filter.RangeValue = null;
        filter.TextField = "";
      }
    });
    //}
    //else {
    //  _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
    //    if (filter.Name !== "Categorie" && filter.DisplayLevelEnum == FilterDisplayLevelEnum.leveltwo) {
    //      filter.SelectedOption = null;
    //      filter.SelectedOptionId = "";
    //      if (filter.SelectedOptions.length > 0) {
    //        filter.SelectedOptions.splice(0, filter.SelectedOptions.length);
    //      }
    //      filter.RangeValue = null;
    //      filter.TextField = "";
    //    }
    //  });
    //}

    this.Refresh(false);
  }

  public RemoveSelectedAllFilters() {
    this.SavedValues = new Array<FabFilterSavedValue>();
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      filter.SelectedOption = null;
      filter.SelectedOptionId = "";
      if (filter.SelectedOptions.length > 0) {
        filter.SelectedOptions.splice(0, filter.SelectedOptions.length);
      }
      filter.RangeValue = null;
    });
    this.Refresh(false);
  }

  public FiltersSelected() {
    let ret: boolean = false;
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      if (filter.Name !== "Categorie") {
        if ((filter.SelectedOptionId !== null) && (filter.SelectedOptionId !== "")) {
          ret = true;
        }
      }
    });
    return ret;
  }

  public SelectOption(filter: IFilterViewModel, option: IFilterOptionViewModel) {

    _.forEach(this.FabrikantenFilterResponseModel.Filters, filtertemp => {
      if (filtertemp.Id == filter.Id) {
        if (option == null) {
          filter.SelectedOption = null;
        }
        else {
          _.forEach(filtertemp.Options, filteroption => {
            if (option.Id == filteroption.Id) {
              filter.SelectedOption = option;
            }
          });
        }
      }
    });

    this.Refresh();
  }

  public UpdateVolgenAfwerkStaat(): boolean {

    var adjusted = false;

    if (this.FabrikantenFilterResponseModel != null) {
      var volgensafwerkstaat = false;

      _.forEach(this.FabrikantenFilterResponseModel.Filters, filter => {
        if (filter.Name == "Oppervlakte behandeling") {
          if (filter.SelectedOption != null) {
            if (filter.SelectedOption.Name.toLowerCase() == "volgens afwerkstaat") {
              volgensafwerkstaat = true;
            }
          }
        }
      });

      _.forEach(this.FabrikantenFilterResponseModel.Filters, filter => {
        if (filter.Name == "Kleur buiten") {
          if (volgensafwerkstaat) {
            if (filter.TextField != 'volgens afwerkstaat') {
              filter.TextField = "volgens afwerkstaat";
              adjusted = true;
            }
          }
          else {
            if (filter.TextField == 'volgens afwerkstaat') {
              filter.TextField = "";
              adjusted = true;
            }
          }
        }

        if (filter.Name == "Kleur binnen") {
          if (volgensafwerkstaat) {
            if (filter.TextField != 'volgens afwerkstaat') {
              filter.TextField = "volgens afwerkstaat";
              adjusted = true;
            }
          }
          else {
            if (filter.TextField == 'volgens afwerkstaat') {
              filter.TextField = "";
              adjusted = true;
            }
          }
        }

        if (filter.Name == "Kleur draaiende delen buiten") {
          if (volgensafwerkstaat) {
            if (filter.TextField != 'volgens afwerkstaat') {
              filter.TextField = "volgens afwerkstaat";
              adjusted = true;
            }
          }
          else {
            if (filter.TextField == 'volgens afwerkstaat') {
              filter.TextField = "";
              adjusted = true;
            }
          }
        }

        if (filter.Name == "Kleur draaiende delen binnen") {
          if (volgensafwerkstaat) {
            if (filter.TextField != 'volgens afwerkstaat') {
              filter.TextField = "volgens afwerkstaat";
              adjusted = true;
            }
          }
          else {
            if (filter.TextField == 'volgens afwerkstaat') {
              filter.TextField = "";
              adjusted = true;
            }
          }
        }
      });
    }

    return adjusted;
  }

  public HasUnfilledMandatoryFilters(): boolean {
    var value = false;

    _.forEach(this.FabrikantenFilterResponseModel.Filters, filter => {
      if (filter.Mandatory && filter.SelectedOption == null) {
        value = true;
      }
    });

    return value;
  }

  public GetFirstUnfilledMandatoryFilter(): IFilterViewModel {
    var value: IFilterViewModel = null;

    _.forEach(this.FabrikantenFilterResponseModel.Filters, filter => {
      if (filter.Mandatory && filter.SelectedOption == null) {
        value = filter;
      }
    });

    return value;
  }

  private ForceIsolatieTonen(): void {
    if (this.FabrikantenFilterResponseModel != null) {
      var isolatietonen: IFilterViewModel = null;
      var isolatie: IFilterViewModel = null;

      _.forEach(this.FabrikantenFilterResponseModel.Filters, filter => {
        if (filter.Name == "Isolatiemateriaal tonen") {
          isolatietonen = filter;
        }
      });

      _.forEach(this.FabrikantenFilterResponseModel.Filters, filter => {
        if (filter.Name == "Isolatiemateriaal") {
          isolatie = filter;
        }
      });

      if (isolatietonen != null) {
        //if (isolatie == null ||
        //  (isolatie.Options.length > 1 && isolatie.SelectedOption == null) ||
        //  (isolatie.Options.length == 1 && isolatie.Options[0].Name.toLowerCase() == "geen") ||
        //  (isolatie.SelectedOption != null && isolatie.SelectedOption.Name.toLowerCase() == "geen")) {

        //  if (isolatietonen.SelectedOption != null) {
        //    this.SelectOption(isolatietonen, null);
        //  }
        //}
        //else {
          if (isolatietonen.SelectedOption == null) {
            var option: IFilterOptionViewModel = null;

            _.forEach(isolatietonen.Options, optiontemp => {
              if (optiontemp.Name == "ja") {
                option = optiontemp;
              }
            });

            if (option != null) {
              this.SelectOption(isolatietonen, option);
            }
          }
        //}
      }
    }
  }
}

export class FabFilterDialogOption {
  Name: string;
  Image: string;
  Description: string;
  DescriptionShort: string;
  Id: number;
}

class FabFilterSavedValue {
  Id: number;
  Value: number;

  constructor(id: number, value: number) {
    this.Id = id;
    this.Value = value;
  }
}
