<div>
    Ingelogd als: {{ username }}
</div>

<div>
    <mat-form-field *ngIf="dataIsLoaded" appearance="outline" floatLabel="always">
        <mat-label>Projecten:</mat-label>
        <mat-select [(ngModel)]="selectedProject"
            [ngModelOptions]="{standalone: true}"
            (selectionChange)="getPhotoSets($event)">
            <mat-option *ngFor="let project of projects" [value]="project">
                {{project.Naam}}
            </mat-option>

        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="projectSelected" appearance="outline" floatLabel="always">
        <mat-label>Fotosets:</mat-label>
        <mat-select [(ngModel)]="selectedPhotoSet"
            [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let photoSet of photoSets" [value]="photoSet">
                {{photoSet.Name}}
            </mat-option>

        </mat-select>
    </mat-form-field>
    <wct-button buttonRole="primary" buttonSize="medium" buttonText="Ga verder" [routerLink]="['../canvas', selectedProject.Id, selectedPhotoSet.ID]"></wct-button>
</div>

