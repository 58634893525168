import { IFilterViewModel, IFilterOptionViewModel } from '@datacontracts/FilterViewModel';
import { FilterDisplayLevelEnum } from '@enums/FilterViewModel';
import { FilterViewModel } from '@models/FilterViewModel';
// [conv] import "(./FabFilters).less";
import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import * as _ from 'lodash';

import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart } from '@angular/router';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { FabrikantenConsumer } from '@consumers/FabrikantenController';

import { BCBProductsOverviewModel } from '@models/BCBProductsOverviewModel';
import { BCBLinkModel, BCBProductViewModel, IBCBProductViewModel } from '@models/BCBProductModel';
import { FabrikantenFilterRequestModel, FabrikantenFilterResponseModel } from '@models/FabrikantFilterModel';
import { GoogleAnalyticsService } from '../../../../../services/GoogleAnalyticsService'
//import { ThemeService } from '../../../shared/theme';


@Component({
  selector: 'product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProductComponent {

  private CatBinnendeurNormaalId = 655;
  private CatBinnendeurIntensiefId = 657;
  private CatBeugelgreepId = 670;
  private CatMiniRozetId = 658;
  private CatAntiBacterieelId = 659;
  private CatSnelstiftPlusId = 660;
  private CatHefSchuifId = 656;
  private CatVeiligheidProfielDeurId = 662;
  private CatVeiligheidHoutenDeurId = 654;
  private CatVeiligheidHefSchuifId = 663;
  private CatDraaiKiepId = 661;



  Product: string;
  Filters: string;

  Loading: boolean = true;

  FabrikantenFilterResponseModel: FabrikantenFilterResponseModel;
  public ActiveCategory: string = "";
  public ActiveProduct: string = "";
  public FilterMenuDesktopIsShown: boolean = true;
  public FilterMenuMobileIsShown: boolean = false;
  public FilterMenuMobileBackgroundIsShown: boolean = false;
  public SavedValues: Array<FabFilterSavedValue> = new Array<FabFilterSavedValue>();
  public ImagePreferenceCategories: Array<ImagePreferenceCategorie> = new Array<ImagePreferenceCategorie>();
  public IsLocalhost: boolean = false;
  public SelectedProductIdOld: number = undefined;
  public ActiveStep: number = 0;

  public CategoryId: number;

  constructor(
    private fab: FabrikantenConsumer,
    private route: ActivatedRoute,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {

    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F1 / 8 / OB / 37 - 42 / RFL - R / L / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F1 / 8 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F1 / 8 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F1 / 8 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F1 / 8 / 8.5 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST - LL / F97 - 1 - R / 8 / 8.5 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F1 / 8 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F1 / 8 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F1 / 8 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F1 / 8 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F1 / 8 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F1 / 8 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F69 / 8 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F69 / 8 / OB / 37 - 42 / 9999");
    this.AddImagePreference("Binnendeur, Cat. 3", "GRT - SST / F1 / 8 / OB / 37 - 42 / 9999");

    this.AddImagePreference("Binnendeur, Cat. 4", "GRT / F69 / 8 / PZ / 40 - 45 / KA - RF / 9999");
    this.AddImagePreference("Binnendeur, Cat. 4", "GRT / F69 / 8 / PZ / 40 - 45 / KA - RF / 9999");
    this.AddImagePreference("Binnendeur, Cat. 4", "GRT / F69 / 8 / PZ / 40 - 45 / KA - RF / 9999");
    this.AddImagePreference("Binnendeur, Cat. 4", "GRT / F69 / 8 / PZ / 40 - 45 / KA - RF / 9999");
    this.AddImagePreference("Binnendeur, Cat. 4", "GRT / F69 / 8 / PZ / 40 - 45 / KA - RF / 9999");
    this.AddImagePreference("Binnendeur, Cat. 4", "GRT / F69 / 8 / PZ / 40 - 45 / KA - RF / 9999");
    this.AddImagePreference("Binnendeur, Cat. 4", "GRT / F69 / 8 / PZ / 40 - 45 / KA - RF / 9999");

    this.AddImagePreference("Deurgreep", "handgreep Hoppe - Beugelgrepen E5000(F69 roestvaststaal)");
    this.AddImagePreference("Deurgreep", "handgreep Hoppe - Beugelgrepen E5011(F69 roestvaststaal)");
    this.AddImagePreference("Deurgreep", "handgreep Hoppe - Beugelgrepen E5012(F69 roestvaststaal)");
    this.AddImagePreference("Deurgreep", "handgreep Hoppe - Beugelgrepen E5091(F69 roestvaststaal)");
    this.AddImagePreference("Deurgreep", "handgreep Hoppe - Beugelgrepen E5092(F69 roestvaststaal)");
    this.AddImagePreference("Deurgreep", "handgreep Hoppe - Beugelgrepen E5724(F69 roestvaststaal)");
    this.AddImagePreference("Deurgreep", "handgreep Hoppe - Beugelgrepen E5726(F69 roestvaststaal)");
    this.AddImagePreference("Deurgreep", "handgreep Hoppe - Beugelgrepen E5764(F69 roestvaststaal)");
    this.AddImagePreference("Deurgreep", "handgreep Hoppe - Beugelgrepen E5766(F69 roestvaststaal)");
    this.AddImagePreference("Deurgreep", "handgreep Hoppe - Beugelgrepen 5710(F1 - 2 natur mat)");


    this.AddImagePreference("SnelstiftPlus", "GRT - SST / F69 / 8 / 35 - 45 / 5.9 / 9999");
    this.AddImagePreference("SnelstiftPlus", "GRT - SST - LL / F97 - 1 - R / 8 / 8.5 / 35 - 45 / 5.9 / 9999");
    this.AddImagePreference("SnelstiftPlus", "GRT - SST / F69 / 8 / 35 - 45 / 5.9 / 9999");
    this.AddImagePreference("SnelstiftPlus", "GRT - SST / F69 / 8 / 35 - 45 / 5.9 / 9999");
    this.AddImagePreference("SnelstiftPlus", "GRT - SST / F69 / 8 / 35 - 45 / 5.9 / 9999");
    this.AddImagePreference("SnelstiftPlus", "GRT - SST / F69 / 8 / 35 - 45 / 5.9 / 9999");
    this.AddImagePreference("SnelstiftPlus", "GRT - SST / F69 / 8 / 35 - 45 / 5.9 / 9999");

    this.AddImagePreference("SecuSan", "GRT - SST / F69 - S / 8 / PZ / 37 - 42 / 9999");
    this.AddImagePreference("SecuSan", "GRT - SST / F69 - S / 8 / PZ / 37 - 42 / 9999");
    this.AddImagePreference("SecuSan", "GRT - SST / F69 - S / 8 / PZ / 37 - 42 / RFL - R / L / 9999");
    this.AddImagePreference("SecuSan", "GRT - SST / F69 - S / 8 / PZ / 37 - 42 / 9999");

    this.AddImagePreference("Hefschuifdeur", "HG - I / F69 / 10 / UG / 9999 / 180 - KUGEL");
    this.AddImagePreference("Hefschuifdeur", "HG - I / F1 / 10 / UG / 9999");
    this.AddImagePreference("Hefschuifdeur", "HG - I / F41 - R / 10 / UG / 9999");
    this.AddImagePreference("Hefschuifdeur", "HG - I / F1 / 10 / UG / 9999");
    this.AddImagePreference("Hefschuifdeur", "HG - I / F97 - 1 - R / 10 / UG / 9999");
    this.AddImagePreference("Hefschuifdeur", "HG - I / F1 / 10 / UG / 9999 / 180 - KUGEL");

    this.AddImagePreference("Veiligheid Profieldeur", "HG-A / F-SKG3 / F1 / 8 / PZ-92 / 12692 (halfgar. buitenzijde)");

    this.AddImagePreference("Veiligheid Hout", "GRT - SST / SKG3 / F69 / 8 / PZ - 72 / 52 - 57 / 19589");
    this.AddImagePreference("Veiligheid Hout", "GRT - SST / SKG3 / F69 / 8 / PZ - 72 / 52 - 57 / 19589");
    this.AddImagePreference("Veiligheid Hout", "GRT - SST / SKG3 / F69 / 8 / PZ - 72 / 52 - 57 / 19589");
    this.AddImagePreference("Veiligheid Hout", "GRT - SST / SKG3 / F69 / 8 / PZ - 72 / 52 - 57 / 19589");
    this.AddImagePreference("Veiligheid Hout", "GRT - SST / SKG3 / F69 / 8 / PZ - 72 / 52 - 57 / 19589");
    this.AddImagePreference("Veiligheid Hout", "GRT - SST / SKG3 / F69 / 8 / PZ - 72 / 52 - 57 / 19589");
    this.AddImagePreference("Veiligheid Hout", "GRT - SST / SKG3 / F69 / 8 / PZ - 72 / 52 - 57 / 19589");
    this.AddImagePreference("Veiligheid Hout", "GRT - SST / SKG3 / F1 / 8 / PZ - 72 / 53 - 58 / 12692 / 3 - NO - 21.5U");
    this.AddImagePreference("Veiligheid Hout", "GRT - SST / SKG3 / F1 / 8 / PZ - 72 / 53 - 58 / 12692 / 3 - NO - 21.5U");
    this.AddImagePreference("Veiligheid Hout", "GRT - SST / SKG3 / F1 / 8 / PZ - 72 / 53 - 58 / 12692 / 3 - NO - 21.5U");
    this.AddImagePreference("Veiligheid Hout", "GRT - SST / SKG3 / F1 / 8 / PZ - 72 / 53 - 58 / 12692 / 3 - NO - 21.5U");
    this.AddImagePreference("Veiligheid Hout", "GRT / SKG3 / F1 / 8 / PZ - 72 / 53 - 58 / 12692 / 3 - NO - 21.5U");

    this.AddImagePreference("Minirozet", "GRT - SST - LL / F69 / 8 / 36 - 54 / 9999");
    this.AddImagePreference("Minirozet", "GRT - SST - LL / F69 / 8 / 36 - 54 / 9999");
    this.AddImagePreference("Minirozet", "GRT - SST - LL / F69 / 8 / 36 - 54 / 9999");
    this.AddImagePreference("Minirozet", "PAAR / F69 / OB");
    this.AddImagePreference("Minirozet", "GRT - SST - LL / F31 - 1 / 8 / 35 - 45 / 9999");
    this.AddImagePreference("Minirozet", "PAAR / F31 - 1 / OB");
    this.AddImagePreference("Minirozet", "PAAR / F31 - 1 / PZ");
    this.AddImagePreference("Minirozet", "PAAR / F31 - 1 / 5 - 8 / SK / OL / 38 - 43 / SK802 / OL46");
    this.AddImagePreference("Minirozet", "GRT-SST-LL / F49-R / 8 / 40-54 / 9999");



    this.FabrikantenFilterResponseModel = new FabrikantenFilterResponseModel();

    var url = (<any>this.route)._routerState.snapshot.url;

    if (url != undefined) {
      const tree = this.router.parseUrl(url);
      if (tree != undefined) {
        const children = tree.root.children.primary;

        if (children != undefined) {
          const segments = children.segments;

          if (segments.length > 1) {
            this.Product = segments[1].path;
            if (this.Product != undefined) {
              this.FabrikantenFilterResponseModel.ProductParameter = this.Product;

              this.googleAnalyticsService.sendCustomEvent(
                "Bestekservice",
                "ProductViewedDeeplink",
                this.Product,
                1);
            }
          }

          if (segments.length > 2) {
            this.Filters = "";

            for (var i = 2; i < segments.length; i++) {

              if (this.Filters != "") {
                this.Filters += "_";
              }

              this.Filters += segments[i].path;
            }

            if (this.Filters != undefined) {
              this.FabrikantenFilterResponseModel.FiltersParameter = this.Filters;
            }
          }
        }
      }
    }

    this.Refresh(true);

    this.CheckMenuFilterIsShown(window);

    if (window.location.href.indexOf("localhost") > -1) {
      this.IsLocalhost = true;
    }

    this.route.paramMap.subscribe(paramMap => {
      let catIdStr = paramMap.get('categoryid');
      this.CategoryId = +catIdStr;
      this.SelectCategory(this.CategoryId);
      this.GoToChooseCategories();
    });
  }

  public CheckMenuFilterIsShown(window) {
    if (window.innerWidth >= 1000) {
      this.FilterMenuDesktopIsShown = true;
    } else {
      this.FilterMenuDesktopIsShown = false;
    }
  }

  public CheckSlickButtonsAreShown(window) {
    let SlickTrackElement: any = document.getElementsByClassName("slick-track")[0];
    let FabFiltersButtonsForProductElement: any = document.getElementsByClassName("carousel__fab-filters")[0];
    let FabFiltersButtonsButtonsForProductsElement: any = document.getElementsByClassName("fab-filters__buttons-for-products")[0];
    if (SlickTrackElement !== undefined && FabFiltersButtonsForProductElement !== undefined) {
      if (SlickTrackElement.offsetWidth > FabFiltersButtonsForProductElement.offsetWidth) {
        FabFiltersButtonsButtonsForProductsElement.style.margin = "0 25px 0px 25px";
      } else {
        FabFiltersButtonsButtonsForProductsElement.style.margin = "0 -7.5px";
      }
    }

    // let FabFiltersMainElement: any = document.getElementsByClassName("fab-filters__main")[0];
    // let FabFiltersMainTitleElement: any = document.getElementsByClassName("fab-filters__title-for-products")[0];
    // let FabFiltersButtonsForProductsElement: any = document.getElementsByClassName("fab-filters__buttons-for-products")[0];
    // let FabFiltersBreadcrumbElement: any = document.getElementsByClassName("fab-filters__breadcrumb")[0]; 
    // let FabProductTitleElement: any = document.getElementsByClassName("fab-product__title")[0];
    // let FabProductDescriptionElement: any = document.getElementsByClassName("fab-product__description")[0];   
    // let FabFiltersButtonFilterMenuElement: any = document.getElementsByClassName(" fab-filters__button-filter-menu")[0];

    // if (
    //    (FabFiltersMainElement !== undefined) && (FabFiltersMainTitleElement !== undefined) &&
    //    (FabFiltersButtonsForProductsElement !== undefined) && (FabFiltersBreadcrumbElement !== undefined) &&
    //    (FabProductTitleElement !== undefined) && (FabProductDescriptionElement !== undefined) &&
    //    (FabFiltersButtonFilterMenuElement !== undefined) 
    // ) {
    //    ret = FabFiltersMainElement.offsetHeight
  }

  public HideFilterMenuMobile() {
    this.FilterMenuMobileBackgroundIsShown = false;
    setTimeout(() => {
      this.FilterMenuMobileIsShown = false;
    }, 300);
  }

  public ShowFilterMenuMobile() {
    this.FilterMenuMobileIsShown = true;
    setTimeout(() => {
      this.FilterMenuMobileBackgroundIsShown = true;
    }, 300);
  }

  public GetMaximimumHeight() {
    let ret: number = 0;
    if (window.innerHeight > 600) {
      ret = window.innerHeight - 30;
    } else {
      ret = window.innerHeight;
    }
    return ret;
  }

  public SelectProduct(product: BCBProductViewModel) {

    this.googleAnalyticsService.sendCustomEvent(
      "Bestekservice",
      "ProductViewed",
      product.DisplayName,
      product.Id);

    //history.pushState(null, null, "product");

    this.FabrikantenFilterResponseModel.SelectedProduct = product;
    this.ActiveProduct = product.Naam;
    this.SavedValues = new Array<FabFilterSavedValue>();
    this.Refresh(false);
  }

  public GetProductImageUrl(product: IBCBProductViewModel): string {

    var link = this.FilterBCBLinkModel(product);

    if (link != null) {
      if (this.GetProductImageUrlBCBLink(link) != "") {
        return this.GetProductImageUrlBCBLink(link);
      }
    }

    for (var i = 0; i < product.Links.length; i++) {
      if (this.GetProductImageUrlBCBLink(product.Links[i]) != "") {
        return this.GetProductImageUrlBCBLink(product.Links[i]);
      }
    }

    return "";
  }

  public GetProductImageUrlBCBLink(linkmodel: BCBLinkModel): string {
    if (linkmodel.Type == "img") {
      var tSplit1 = linkmodel.URL.split('|');

      for (var j1 = 0; j1 < tSplit1.length; j1++) {

        var tSplit2 = tSplit1[j1].split(',');

        for (var j = 0; j < tSplit2.length; j++) {
          if (tSplit2[j].startsWith("http")) {
            return tSplit2[j];
          }
        }
      }
    }

    return "";
  }

  public FilterBCBLinkModel(product: IBCBProductViewModel): BCBLinkModel {
    var imagecate: ImagePreferenceCategorie = null;

    if (this.CategoryId == this.CatBinnendeurNormaalId) { // Binnendeur, normaal gebruik (Cat. 2 en Cat. 3)
      imagecate = _.find(this.ImagePreferenceCategories, cat => { return cat.Name == "Binnendeur, Cat. 3" });
    }

    if (this.CategoryId == this.CatBinnendeurIntensiefId) { // Binnendeur, intensief gebruik (Cat. 4 vastdraaibaar)
      imagecate = _.find(this.ImagePreferenceCategories, cat => { return cat.Name == "Binnendeur, Cat. 4" });
    }

    if (this.CategoryId == this.CatSnelstiftPlusId) { // SnelstiftPlus
      imagecate = _.find(this.ImagePreferenceCategories, cat => { return cat.Name == "SnelstiftPlus" });
    }

    if (this.CategoryId == this.CatBeugelgreepId) { // Beugelgreep
      imagecate = _.find(this.ImagePreferenceCategories, cat => { return cat.Name == "Deurgreep" });
    }

    if (this.CategoryId == this.CatAntiBacterieelId) { // Antibacterieel beslag SecuSan ®
      imagecate = _.find(this.ImagePreferenceCategories, cat => { return cat.Name == "SecuSan" });
    }

    if (this.CategoryId == this.CatHefSchuifId) { // Hef- / Schuifdeur
      imagecate = _.find(this.ImagePreferenceCategories, cat => { return cat.Name == "Hefschuifdeur" });
    }

    if (this.CategoryId == this.CatVeiligheidProfielDeurId) {
      imagecate = _.find(this.ImagePreferenceCategories, cat => { return cat.Name == "Veiligheid Profieldeur" });
    }

    if (this.CategoryId == this.CatVeiligheidHoutenDeurId) {
      imagecate = _.find(this.ImagePreferenceCategories, cat => { return cat.Name == "Veiligheid Hout" });
    }

    if (this.CategoryId == this.CatMiniRozetId) {
      imagecate = _.find(this.ImagePreferenceCategories, cat => { return cat.Name == "Minirozet" });
    }

    if (imagecate != null) {
      for (var i = 0; i < product.Variants.length; i++) {
        var imageval: ImagePreferenceValue = null;
        var productvarid: number = -1;
        var varname = this.replaceAll(product.Variants[i].Name, " ", "");

        for (var j = 0; j < imagecate.Values.length; j++) {
          if (varname.indexOf(imagecate.Values[j].Name) != -1) {
            imageval = imagecate.Values[j];
            productvarid = product.Variants[i].Id;
          }
        }
        var count = 0;
        if (imageval != null) {
          for (var j = 0; j < product.Links.length; j++) {
            if (product.Links[j].ProductVariantName == product.Variants[i].Name) {
              //console.log("preferred image voor: " + product.Naam + " cat_id:" + this.CategoryId + " " + product.Links[j].ProductVariantName);
              if (this.CategoryId == this.CatAntiBacterieelId) {
                if (product.Id == 1) // amsterdam
                {
                  if (count == 0) {
                    count++;
                  }
                  else {
                    return product.Links[j];
                  }
                }
                else {
                  return product.Links[j];
                }
              }
              else {
                return product.Links[j];
              }
            }
          }
        }
      }
      //console.error("geen preferred image voor: " + product.Naam + " cat_id:" + this.CategoryId);
    }
    else {
      //console.log("geen cat voor: " + product.Naam + " cat_id:" + this.CategoryId);
    }

    

    return null;
  }

  public AddImagePreference(cat: string, name: string) {
    var catObj: ImagePreferenceCategorie = _.find(this.ImagePreferenceCategories, catTemp => { return catTemp.Name == cat });

    if (catObj == null) {
      catObj = new ImagePreferenceCategorie();

      catObj.Name = cat;
      catObj.Values = new Array<ImagePreferenceValue>();

      this.ImagePreferenceCategories.push(catObj);
    }

    var valObj: ImagePreferenceValue = _.find(catObj.Values, valTemp => { return valTemp.Name == name });

    if (valObj == null) {
      valObj = new ImagePreferenceValue();

      valObj.Name = this.replaceAll(name, " ", "");

      catObj.Values.push(valObj);
    }
  }

  public GetProductDescription(): string {
    var description = "";

    if (this.FabrikantenFilterResponseModel.SelectedProduct != null) {
      _.forEach(this.FabrikantenFilterResponseModel.SelectedProduct.Variants, variant => {
        description = variant.Description;
      });
    }

    return description;
  }

  public Refresh(First: boolean = false) {

    if (!First) {
      if (this.FabrikantenFilterResponseModel.FiltersParameter != undefined) {
        this.FabrikantenFilterResponseModel.FiltersParameter = undefined;
      }
    }

    this.LoadFilters();
  }

  private LoadFilters(): void {
    this.Loading = true;

    this.fab.FilterOptions_Observable(this.FabrikantenFilterResponseModel).subscribe((data) => {
      this.FabrikantenFilterResponseModel = new FabrikantenFilterResponseModel(data);

      this.Loading = false;
      this.RenameFilters();

      setTimeout(() => {
        this.CheckSlickButtonsAreShown(window);
      }, 0);

      const FilterCategorie = _.find(this.FabrikantenFilterResponseModel.Filters, (filter) => filter.Name == "Categorie");
      if (FilterCategorie !== undefined) {

        let categoryId = this.route.snapshot.paramMap.get('categoryid');

        if (FilterCategorie.SelectedOption == undefined || FilterCategorie.SelectedOption.Id != +categoryId) {
          this.SelectCategory(+categoryId);
        }
        else {
          this.ActiveCategory = FilterCategorie.SelectedOption.Name;
        }
      }

      if (this.FabrikantenFilterResponseModel.SelectedProduct != undefined) {
        if (this.SelectedProductIdOld == undefined || (this.SelectedProductIdOld !== this.FabrikantenFilterResponseModel.SelectedProduct.Id)) {
          this.SelectedProductIdOld = this.FabrikantenFilterResponseModel.SelectedProduct.Id;
        }
      }

      if (this.FabrikantenFilterResponseModel.DeeplinkModus) {
        console.log("deeplink modus active");
      }

      if (this.UpdateVolgenAfwerkStaat()) {
        this.LoadFilters();
      }
    });
  }

  public GetButtonRole(product: IBCBProductViewModel): string {
    let ret = "secundary";
    if ((this.FabrikantenFilterResponseModel.SelectedProduct !== undefined) && (this.FabrikantenFilterResponseModel.SelectedProduct !== null)) {
      if (product.Id == this.FabrikantenFilterResponseModel.SelectedProduct.Id) {
        ret = "primary";
      }
    }
    return ret;
  }

  public SetLastSelectedView(lastselectedView: string) {
    if (this.FabrikantenFilterResponseModel.SelectedProduct != undefined) {
      this.FabrikantenFilterResponseModel.SelectedProduct.ViewSelected = lastselectedView;
    }
  }

  public SetLastSelectedProduct(lastselectedproduct: any) {
    this.FabrikantenFilterResponseModel.SelectedProduct = lastselectedproduct;
  }

  public SelectCategory(id: number): void {

    if (this.ActiveProduct != "") {
      this.DeselectActiveProduct();
    }

    if (this.FabrikantenFilterResponseModel.Filters.length == 0) {
      console.log("SelectCategory met 0");
    }
    else {

      var found = false;

      _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
        if (filter.Name == "Categorie") {
          _.forEach(filter.Options, (option, index) => {
            if (option.Id == id) {
              filter.SelectedOption = filter.Options[index];
              this.ActiveCategory = filter.SelectedOption.Name;
              this.SetSavedValue(filter.Id, filter.Options[index].Id);
              found = true;
            }
          });
        }
      });

      if (!found) {
        this.RemoveSelectedAllFilters();
        //alert("Select categorie mislukt!");
      }
      else {
        this.Refresh(false);
      }
    }
  }

  public GoToChooseCategories() {

    //history.pushState(null, null, "categorie/" + this.CategoryId);

    this.FabrikantenFilterResponseModel.SelectedProduct = null;
    this.ActiveProduct = "";
    this.ActiveCategory = "";

    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      filter.SelectedOption = null;
      filter.SelectedOptions = [];
    });

    this.SavedValues = new Array<FabFilterSavedValue>();
    this.Refresh(false);
    this.ActiveCategory = "";
  }

  public GetMaximimumHeightFilterMenu() {
    let ret: number = 0;
    let FabFiltersMenuBackgroundElement: any = document.getElementsByClassName("fab-filters__filter-menu-background")[0];
    if (FabFiltersMenuBackgroundElement !== undefined
    ) {
      ret = FabFiltersMenuBackgroundElement.offsetHeight;
    }
    return ret;
  }

  public GetNumberOfShownProducts() {
    let ret: string = "...";
    if (!this.Loading) {
      ret = this.FabrikantenFilterResponseModel.Products.length.toString();
    }
    return ret;
  }

  public SetSavedValue(id: number, savedValueSet: number) {
    if (this.SavedValues.some(savedValue => savedValue.Id === id)) {
      _.forEach(this.SavedValues, (savedValue: FabFilterSavedValue) => {
        if (savedValue.Id == id) {
          savedValue.Value = savedValueSet;
        }
      });
    } else {
      let savedValue: FabFilterSavedValue = new FabFilterSavedValue(id, savedValueSet);
      this.SavedValues.push(savedValue);
    }
  }

  public FabFiltersIsShown() {
    let ret: boolean = true;

    const FilterWithNameCategoryExists = this.FabrikantenFilterResponseModel.Filters.some(filter => filter.Name.includes("Categorie"));
    if (FilterWithNameCategoryExists && (this.ActiveCategory.length == 0)) {
      ret = false;
    }

    return ret;
  }

  public GetLijnTekening(): string {
    if (this.FabrikantenFilterResponseModel.SelectedProduct.Links.length > 0) {
      if (this.FabrikantenFilterResponseModel.SelectedProduct.Links[0].URL.indexOf('|') != -1) {
        return this.FabrikantenFilterResponseModel.SelectedProduct.Links[0].URL.split('|')[1].split(',')[1];
      }
    }

    return "";
  }

  public ShouldDisplay(filter: IFilterViewModel) {

    if (filter.Name == 'Categorie') {
      return false;
    }

    if (filter.Hidden) {
      return false;
    }

    if (this.FabrikantenFilterResponseModel?.SelectedProduct == null) {
      if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.leveltwo) {
        return false;
      }
    }

    if (this.GetActiveCategory() == "Binnendeur normaal gebruik (Cat. 2 en Cat. 3)") {
      if (filter.Name == 'Deurdikte') {
        return false;
      }
    }

    //if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.levelone) {
    //  return true;
    //}

    if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.none) {
      return false;
    }

    return true;
  }

  public DeselectActiveProduct() {
    this.FabrikantenFilterResponseModel.ProductParameter = undefined;
    this.FabrikantenFilterResponseModel.FiltersParameter = undefined;
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      if (filter.Name !== "Categorie" && filter.DisplayLevelEnum == FilterDisplayLevelEnum.leveltwo) {
        filter.SelectedOption = null;
        filter.SelectedOptionId = "";
        filter.RangeValue = null;
        filter.TextField = "";
      }
    });
    this.FabrikantenFilterResponseModel.SelectedProduct = undefined;

    //history.pushState(null, null, "categorie/" + this.CategoryId);
    //this.FabrikantenFilterResponseModel.SelectedProduct = product;

    this.ActiveProduct = "";
    this.SavedValues = new Array<FabFilterSavedValue>();
    this.Refresh(false);
  }

  public OpenBCB(url: string) {
    this.googleAnalyticsService.sendCustomEvent("Bestekservice", "OpenBCB", this.FabrikantenFilterResponseModel.SelectedProduct.Naam, this.FabrikantenFilterResponseModel.SelectedProduct.Id);
    window.open(url, '_blank');
  }

  public RemoveSelectedFilters() {
    this.SavedValues = new Array<FabFilterSavedValue>();

    //if (this.ActiveStep == 1) {
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      if (filter.Name !== "Categorie") {
        filter.SelectedOption = null;
        filter.SelectedOptionId = "";
        if (filter.SelectedOptions.length > 0) {
          filter.SelectedOptions.splice(0, filter.SelectedOptions.length);
        }
        filter.RangeValue = null;
        filter.TextField = "";
      }
    });
    //}
    //else {
    //  _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
    //    if (filter.Name !== "Categorie" && filter.DisplayLevelEnum == FilterDisplayLevelEnum.leveltwo) {
    //      filter.SelectedOption = null;
    //      filter.SelectedOptionId = "";
    //      if (filter.SelectedOptions.length > 0) {
    //        filter.SelectedOptions.splice(0, filter.SelectedOptions.length);
    //      }
    //      filter.RangeValue = null;
    //      filter.TextField = "";
    //    }
    //  });
    //}

    this.Refresh(false);
  }

  public RemoveSelectedAllFilters() {
    this.SavedValues = new Array<FabFilterSavedValue>();
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      filter.SelectedOption = null;
      filter.SelectedOptionId = "";
      if (filter.SelectedOptions.length > 0) {
        filter.SelectedOptions.splice(0, filter.SelectedOptions.length);
      }
      filter.RangeValue = null;
    });
    this.Refresh(false);
  }

  public FiltersSelected() {
    let ret: boolean = false;
    _.forEach(this.FabrikantenFilterResponseModel.Filters, (filter) => {
      if (filter.Name !== "Categorie") {
        if ((filter.SelectedOptionId !== null) && (filter.SelectedOptionId !== "")) {
          ret = true;
        }
      }
    });
    return ret;
  }

  //public RefreshSteps() {
  //  if (this.ActiveStep == 1 && this.ActiveProduct != '') {
  //    var progress = true;

  //    _.forEach(this.FabrikantenFilterResponseModel.Filters, filter => {
  //      if (filter.DisplayLevelEnum == FilterDisplayLevelEnum.levelone) {
  //        if (filter.Options.length > 1 && filter.SelectedOption == null) {
  //          progress = false;
  //        }
  //      }
  //    });

  //    if (progress) {
  //      this.ActiveStep = 2;
  //    }
  //  }
  //}

  public ShowHalfGarnituur(): boolean {
    var catId = this.GetSelectedCatId();
    if (catId == this.CatVeiligheidHefSchuifId) {
      return true;
    }

    if (catId == this.CatVeiligheidProfielDeurId) {
      return true;
    }

    return false;
  }

  public ShowHalfGarnituurKomgreep(): boolean {
    var catId = this.GetSelectedCatId();
   
    if (catId == this.CatHefSchuifId) {
      return true;
    }

    return false;
  }

  public ShowVeiligheidsRozet(): boolean {
    if (this.ActiveProduct == "Veiligheidsrozet") {
      return true;
    }

    return false;
  }

  public SelectOption(filter: IFilterViewModel, option: IFilterOptionViewModel) {

    _.forEach(this.FabrikantenFilterResponseModel.Filters, filtertemp => {
      if (filtertemp.Id == filter.Id) {
        _.forEach(filtertemp.Options, filteroption => {
          if (option.Id == filteroption.Id) {
            filter.SelectedOption = option;
          }
        });
      }
    });

    this.Refresh();
  }

  public UpdateVolgenAfwerkStaat(): boolean {

    var adjusted = false;

    if (this.FabrikantenFilterResponseModel != null) {
      var volgensafwerkstaat = false;

      _.forEach(this.FabrikantenFilterResponseModel.Filters, filter => {
        if (filter.Name == "Oppervlakte behandeling") {
          if (filter.SelectedOption != null) {
            if (filter.SelectedOption.Name.toLowerCase() == "volgens afwerkstaat") {
              volgensafwerkstaat = true;
            }
          }
        }
      });

      _.forEach(this.FabrikantenFilterResponseModel.Filters, filter => {
        if (filter.Name == "Kleur buiten") {
          if (volgensafwerkstaat) {
            if (filter.TextField != 'volgens afwerkstaat') {
              filter.TextField = "volgens afwerkstaat";
              adjusted = true;
            }
          }
          else {
            if (filter.TextField == 'volgens afwerkstaat') {
              filter.TextField = "";
              adjusted = true;
            }
          }
        }

        if (filter.Name == "Kleur binnen") {
          if (volgensafwerkstaat) {
            if (filter.TextField != 'volgens afwerkstaat') {
              filter.TextField = "volgens afwerkstaat";
              adjusted = true;
            }
          }
          else {
            if (filter.TextField == 'volgens afwerkstaat') {
              filter.TextField = "";
              adjusted = true;
            }
          }
        }

        if (filter.Name == "Kleur draaiende delen buiten") {
          if (volgensafwerkstaat) {
            if (filter.TextField != 'volgens afwerkstaat') {
              filter.TextField = "volgens afwerkstaat";
              adjusted = true;
            }
          }
          else {
            if (filter.TextField == 'volgens afwerkstaat') {
              filter.TextField = "";
              adjusted = true;
            }
          }
        }

        if (filter.Name == "Kleur draaiende delen binnen") {
          if (volgensafwerkstaat) {
            if (filter.TextField != 'volgens afwerkstaat') {
              filter.TextField = "volgens afwerkstaat";
              adjusted = true;
            }
          }
          else {
            if (filter.TextField == 'volgens afwerkstaat') {
              filter.TextField = "";
              adjusted = true;
            }
          }
        }
      });
    }

    return adjusted;
  }

  public HasUnfilledMandatoryFilters(): boolean {
    var value = false;

    _.forEach(this.FabrikantenFilterResponseModel.Filters, filter => {
      if (filter.Mandatory && filter.SelectedOption == null) {
        value = true;
      }
    });

    return value;
  }

  public GetFirstUnfilledMandatoryFilter(): IFilterViewModel {
    var value: IFilterViewModel = null;

    _.forEach(this.FabrikantenFilterResponseModel.Filters, filter => {
      if (filter.Mandatory && filter.SelectedOption == null) {
        value = filter;
      }
    });

    return value;
  }

  private GetSelectedCatId(): number {

    if (this.FabrikantenFilterResponseModel?.Filters != null) {
      for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Categorie") {
          if (this.FabrikantenFilterResponseModel.Filters[i].SelectedOption != null) {
            return this.FabrikantenFilterResponseModel.Filters[i].SelectedOption.Id;
          }
        }
      }
    }

    return -1;
  }

  private RenameFilters(): void {
    var catId = this.GetSelectedCatId();

    if (catId == this.CatVeiligheidHefSchuifId) {
      for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk buiten") {
          this.FabrikantenFilterResponseModel.Filters[i].Name = "Greep buiten";
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk binnen") {
          this.FabrikantenFilterResponseModel.Filters[i].Name = "Greep binnen";
        }
      }
    }

    if (catId == this.CatHefSchuifId) {
      for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk binnen") {
          this.FabrikantenFilterResponseModel.Filters[i].Name = "Greep";
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Schild binnen") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }
      }
    }

    if (catId == this.CatSnelstiftPlusId) {
      for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Schild buiten") {
          this.FabrikantenFilterResponseModel.Filters[i].Name = "Rozet";
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk binnen") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk buiten") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Schild binnen") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }
      }
    }

    if (catId == this.CatAntiBacterieelId) {
      for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk binnen") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk buiten") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Schild binnen") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Schild buiten") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }
      }
    }

    if (catId == this.CatMiniRozetId) {
      for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk buiten") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Schild buiten") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }
      }
    }

    if (catId == this.CatBinnendeurIntensiefId) {
      for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {
        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Schild binnen") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk binnen") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk buiten") {
          this.FabrikantenFilterResponseModel.Filters[i].Name = "Kruk";
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Schild buiten") {
          this.FabrikantenFilterResponseModel.Filters[i].Name = "Uitvoering";
        }
      }
    }

    if (catId == this.CatBinnendeurNormaalId) {
      for (var i = 0; i < this.FabrikantenFilterResponseModel.Filters.length; i++) {

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk binnen") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Kruk buiten") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Schild binnen") {
          this.FabrikantenFilterResponseModel.Filters[i].Hidden = true;
        }

        if (this.FabrikantenFilterResponseModel.Filters[i].Name == "Schild buiten") {
          this.FabrikantenFilterResponseModel.Filters[i].Name = "Uitvoering";
        }
      }
    }
  }

  public GetActiveCategory(): string {
    return this.replaceAll(this.ActiveCategory, "<br/>", " ");
  }

  public escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  public replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }
}

export class FabFilterDialogOption {
  Name: string;
  Image: string;
  Description: string;
  DescriptionShort: string;
  Id: number;
}

export class ImagePreferenceCategorie {
  Name: string;
  Values: Array<ImagePreferenceValue>;
}

export class ImagePreferenceValue {
  Name: string;
}

class FabFilterSavedValue {
  Id: number;
  Value: number;

  constructor(id: number, value: number) {
    this.Id = id;
    this.Value = value;
  }
}
