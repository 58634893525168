/**
 * @ref WoonConnectViewModel/BuildingComponentImprovementViewModel.cs
 */

 import * as DataContracts from "@datacontracts/BuildingComponentImprovementViewModel";

 
import * as MeasureViewModel from "./MeasureViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.BuildingComponentImprovementRequestAllModel
	 */
	 

	export interface IBuildingComponentImprovementRequestAllModel {
		Temp: string;
		ToJsonContract(): DataContracts.IBuildingComponentImprovementRequestAllModel;
	}

	export class BuildingComponentImprovementRequestAllModel {
		// property-Properties
		public get Temp(): string {
			return this._Temp;
		}
		public set Temp(newTemp: string) {
			this._Temp = newTemp;
		}

		// field-Properties

		// fields
		protected _Temp: string = "";

		constructor(data?: DataContracts.IBuildingComponentImprovementRequestAllModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentImprovementRequestAllModel): void {
			if(data) {
				// vul properties
				this._Temp = data.Temp;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentImprovementRequestAllModel {
			return {
				Temp: this.Temp
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.BuildingComponentImprovementViewModel
	 */
	 

	export interface IBuildingComponentImprovementViewModel {
		Id: number;
		DisplayName: string;
		ModelCode: string;
		Measures: MeasureViewModel.IMeasureViewModel[];
		IsInUse: boolean;
		IsSelected: boolean;
		IconUrl: string;
		ToJsonContract(): DataContracts.IBuildingComponentImprovementViewModel;
	}

	export class BuildingComponentImprovementViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get DisplayName(): string {
			return this._DisplayName;
		}
		public set DisplayName(newDisplayName: string) {
			this._DisplayName = newDisplayName;
		}

		public get ModelCode(): string {
			return this._ModelCode;
		}
		public set ModelCode(newModelCode: string) {
			this._ModelCode = newModelCode;
		}

		public get Measures(): MeasureViewModel.IMeasureViewModel[] {
			return this._Measures;
		}
		public set Measures(newMeasures: MeasureViewModel.IMeasureViewModel[]) {
			this._Measures = newMeasures;
		}

		public get IsInUse(): boolean {
			return this._IsInUse;
		}
		public set IsInUse(newIsInUse: boolean) {
			this._IsInUse = newIsInUse;
		}

		public get IsSelected(): boolean {
			return this._IsSelected;
		}
		public set IsSelected(newIsSelected: boolean) {
			this._IsSelected = newIsSelected;
		}

		public get IconUrl(): string {
			return this._IconUrl;
		}
		public set IconUrl(newIconUrl: string) {
			this._IconUrl = newIconUrl;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _DisplayName: string = "";
		protected _ModelCode: string = "";
		protected _Measures: MeasureViewModel.IMeasureViewModel[] = [];
		protected _IsInUse: boolean = false;
		protected _IsSelected: boolean = false;
		protected _IconUrl: string = "";

		constructor(data?: DataContracts.IBuildingComponentImprovementViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentImprovementViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._DisplayName = data.DisplayName;
				this._ModelCode = data.ModelCode;
				this._Measures = data.Measures ? data.Measures.map(measureViewModelItem => new MeasureViewModel.MeasureViewModel(measureViewModelItem)): [];
				this._IsInUse = data.IsInUse;
				this._IsSelected = data.IsSelected;
				this._IconUrl = data.IconUrl;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentImprovementViewModel {
			return {
				Id: this.Id,
				DisplayName: this.DisplayName,
				ModelCode: this.ModelCode,
				Measures: (this.Measures ? this.Measures.map(measureViewModelItem => new MeasureViewModel.MeasureViewModel(measureViewModelItem).ToJsonContract()): []),
				IsInUse: this.IsInUse,
				IsSelected: this.IsSelected,
				IconUrl: this.IconUrl
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.BuildingComponentImprovementsViewModel
	 */
	 

	export interface IBuildingComponentImprovementsViewModel {
		BuildingComponentImprovements: BuildingComponentImprovementViewModel[];
		ToJsonContract(): DataContracts.IBuildingComponentImprovementsViewModel;
	}

	export class BuildingComponentImprovementsViewModel {
		// property-Properties
		public get BuildingComponentImprovements(): BuildingComponentImprovementViewModel[] {
			return this._BuildingComponentImprovements;
		}
		public set BuildingComponentImprovements(newBuildingComponentImprovements: BuildingComponentImprovementViewModel[]) {
			this._BuildingComponentImprovements = newBuildingComponentImprovements;
		}

		// field-Properties

		// fields
		protected _BuildingComponentImprovements: BuildingComponentImprovementViewModel[] = [];

		constructor(data?: DataContracts.IBuildingComponentImprovementsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentImprovementsViewModel): void {
			if(data) {
				// vul properties
				this._BuildingComponentImprovements = data.BuildingComponentImprovements ? data.BuildingComponentImprovements.map(buildingComponentImprovementViewModelItem => new BuildingComponentImprovementViewModel(buildingComponentImprovementViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentImprovementsViewModel {
			return {
				BuildingComponentImprovements: (this.BuildingComponentImprovements ? this.BuildingComponentImprovements.map(buildingComponentImprovementViewModelItem => new BuildingComponentImprovementViewModel(buildingComponentImprovementViewModelItem).ToJsonContract()): [])
			}
		}
	}
