<wct-button class="cont-breadcrumb__btn-back" (click)="GoBackToPreviousLevel()" buttonText="Terug" buttonRole="secundary" buttonIconFa="far fa-arrow-left"></wct-button>

<!-- <p (click)="HideOverviewOfOptions()">Home</p> -->
<i (click)="GoHome()" class="wct-icon wct-icon-home"></i>

<!-- <div class="cont-breadcrumb__icon-level" (click)="GoToBreadcrumbLevelOne()">
	<i class="fal fa-chevron-right"></i>
	<p *ngIf="IsCurrentSituation()">Woninginstellingen aanpassen</p>

	<div *ngIf="!IsCurrentSituation()">
		<p *ngIf="subject == 'options'">Opties kiezen</p>
		<p *ngIf="subject == 'tenders'">Aanbestedingen</p>
	</div> 
	<p>Test</p>
</div -->

<div class="cont-breadcrumb__icon-level" *ngIf="levelOne?.length > 0" (click)="GoToBreadcrumbLevelOne()">
	<i class="fal fa-chevron-right"></i>
	<p>{{levelOne}}</p>
</div>
<div class="cont-breadcrumb__icon-level" *ngIf="levelTwo?.length > 0" (click)="GoToBreadcrumbLevelTwo()">
	<i class="fal fa-chevron-right"></i>
	<p>{{levelTwo}}</p>
</div>
<div class="cont-breadcrumb__icon-level" *ngIf="levelThree?.length > 0" (click)="GoToBreadcrumbLevelThree()">
	<i class="fal fa-chevron-right"></i>
	<p>{{levelThree}}</p>
</div>
<div class="cont-breadcrumb__icon-level" *ngIf="levelFour?.length > 0" (click)="GoToBreadcrumbLevelFour()">
	<i class="fal fa-chevron-right"></i>
	<p>{{levelFour}}</p>
</div>

<!-- <div *ngIf="subject == 'tenders'" class="cont-breadcrumb__icon-level" [hidden]="selectedTenderTitle?.length == 0" (click)="GoToBreadcrumbLevelTwo()">
	<i class="fal fa-chevron-right"></i>
	<p>{{selectedTenderTitle}}</p>
</div> -->
