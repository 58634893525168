import { Component, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { fabric } from 'fabric';
import { result } from 'lodash';
import { Point } from 'poly2tri';
import { Subscription } from 'rxjs';
//import { RGBA_ASTC_10x10_Format } from 'three';
import { ImageAnnotationElement } from '../data/woonconnectkern/models/GetElementsModel';
import { ImageAnnotationPhotoData } from '../data/woonconnectkern/models/GetPhotoDataModel';
import { ImageAnnotationPhoto } from '../data/woonconnectkern/models/GetPhotosModel';
import { ImageAnnotationResultElement } from '../data/woonconnectkern/models/SaveAnnotationModel';
import { AccountService } from '../services/AccountService';

import { ImageAnnotationService } from '../services/ImageAnnotationService';


@Component({    
    selector: 'image-annotation',
    templateUrl: './WctImageAnnotationCanvas.html',
    styleUrls: ['./WctImageAnnotationCanvas.scss']
}) 
export class WctImageAnnotationCanvasComponent {
    
    private canvas;
    public selectionMode: ImageAnnotationElement;
    public selectionModes: ImageAnnotationElement[];
    private elements: ImageAnnotationResultElement[] = [];
    readonly colors = ['red', 'green', 'blue', 'orange', 'cyan'];
    public selectionColor: string;
    private currentPhotoPath: string;
    private photos: ImageAnnotationPhoto[] = [];
    public done: boolean = false;
    private projectId: number;
    private photoSetId: number;
    private userId: number;
    private currentPhotoId: number;
    private currentPhoto: ImageAnnotationPhoto;
    private currentPhotoData: ImageAnnotationPhotoData;
    private imagePath;
    
    min = 99;
    max = 999999;
    polygonMode = true;
    pointArray = new Array();
    lineArray = new Array();
    activeLine;

    constructor(private imageAnnotationService: ImageAnnotationService, private accountService: AccountService, private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer){
        window.addEventListener('keyup', event => this.onKeyup(event));
        this.userId = this.accountService.GeefHuidigPersoonID();

        let self = this;
        fabric.polygon = {
             polygonMode : true,
            pointArray : new Array(),
            lineArray : new Array(),
            activeLine : undefined,
            drawPolygon : function() {
                this.polygonMode = true;
                this.pointArray = new Array();
                this.lineArray = new Array();
                this.activeLine;
            },
            addPoint : function(options) {
                var random = Math.floor(Math.random() * (self.max - self.min + 1)) + self.min;
                var id = new Date().getTime() + random;
                var circle = new fabric.Circle({
                    radius: 5,
                    fill: '#ffffff',
                    stroke: '#333333',
                    strokeWidth: 0.5,
                    left: (options.e.layerX/self.canvas.getZoom()),
                    top: (options.e.layerY/self.canvas.getZoom()),
                    selectable: false,
                    hasBorders: false,
                    hasControls: false,
                    originX:'center',
                    originY:'center',
                    id:id,
                        objectCaching:false
                });
                if(self.pointArray.length == 0){
                    circle.set({
                        fill:'red'
                    })
                }
                var points = [(options.e.layerX/self.canvas.getZoom()),(options.e.layerY/self.canvas.getZoom()),(options.e.layerX/self.canvas.getZoom()),(options.e.layerY/self.canvas.getZoom())];
                let line = new fabric.Line(points, {
                    strokeWidth: 2,
                    fill: '#999999',
                    stroke: '#999999',
                    class:'line',
                    originX:'center',
                    originY:'center',
                    selectable: false,
                    hasBorders: false,
                    hasControls: false,
                    evented: false,
                    objectCaching:false
                });
                if(this.activeShape){
                    var pos = self.canvas.getPointer(options.e);
                    let points = this.activeShape.get("points");
                    points.push({
                        x: pos.x,
                        y: pos.y
                    });
                    var polygon = new fabric.Polygon(points,{
                        stroke:'#333333',
                        strokeWidth:1,
                        fill: '#cccccc',
                        opacity: 0.3,
                        selectable: false,
                        hasBorders: false,
                        hasControls: false,
                        evented: false,
                        objectCaching:false
                    });
                    self.canvas.remove(this.activeShape);
                    self.canvas.add(polygon);
                    this.activeShape = polygon;
                    self.canvas.renderAll();
                }
                else{
                    var polyPoint = [{x:(options.e.layerX/self.canvas.getZoom()),y:(options.e.layerY/self.canvas.getZoom())}];
                    var polygon = new fabric.Polygon(polyPoint,{
                        stroke:'#333333',
                        strokeWidth:1,
                        fill: '#cccccc',
                        opacity: 0.3,
                        selectable: false,
                        hasBorders: false,
                        hasControls: false,
                        evented: false,
                        objectCaching:false
                    });
                    this.activeShape = polygon;
                    self.canvas.add(polygon);
                }
                self.activeLine = line;
        
                self.pointArray.push(circle);
                self.lineArray.push(line);
        
                self.canvas.add(line);
                self.canvas.add(circle);
                self.canvas.selection = false;
            },
            generatePolygon : function(pointArray){
                var points = new Array();
                pointArray.forEach(point => {
                    points.push({
                        x:point.left,
                        y:point.top
                    });
                    self.canvas.remove(point);
                });
                self.lineArray.forEach(line => {
                    self.canvas.remove(line);
                });
                self.canvas.remove(this.activeShape).remove(self.activeLine);
                var polygon = new fabric.Polygon(points,{
                    stroke: self.selectionColor,
                    strokeWidth: 5,
                    fill: 'rgba(0,0,0,0)',
                    opacity: 1,
                    hasBorders: false,
                    hasControls: false,
                    perPixelTargetFind: true,
                    name: self.selectionMode.Type
                });
                self.canvas.add(polygon);
        
                self.activeLine = [];
                self.pointArray = [];
                this.activeShape = null;
                self.polygonMode = false;
                self.canvas.selection = true;
            }
        }
    }

    ngAfterViewInit() {
        this.canvas = new fabric.Canvas('boundingBoxLayer');
        this.canvas.on('mouse:down', options => this.onMouseDown(options));
        this.canvas.on('mouse:move', options => this.onMouseMove(options));
        this.projectId = (<any>this.activatedRoute.params).value.projectId;
        this.photoSetId =  (<any>this.activatedRoute.params).value.photoSetId;

        this.imageAnnotationService.getImageAnnotationElements(this.projectId).subscribe(response => {
            this.selectionModes = response;
            this.selectionMode = this.selectionModes[0];
            this.selectionColor = this.colors[this.selectionModes.indexOf(this.selectionMode)];
        });
        this.imageAnnotationService.getPhotos(this.photoSetId, this.userId).subscribe(response => {
            // Ensures not every user annotates the same pictures in the same order
            this.photos = this.shuffle(response);
            this.currentPhoto = this.photos[0];
            this.currentPhotoPath = this.currentPhoto.PhotoPath;
            this.currentPhotoId = this.currentPhoto.PhotoId;
            this.imageAnnotationService.getPhotoData(this.currentPhotoPath).subscribe(response => {
                this.currentPhotoData = response;
                if (this.currentPhotoData.Success) {
                    this.imagePath = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl('data:' + this.currentPhotoData.Mime + ';base64,' + this.currentPhotoData.Base64));
                }
                else {
                    this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl("pad/naar/een/afbeelding/met/nietgevonden.png");
                }
                var img = new Image();
                var self = this;
                img.onload = function() {
                    var f_img = new fabric.Image(img);
                    self.canvas.setBackgroundImage(f_img);
                    self.canvas.setWidth(f_img.width);
                    self.canvas.setHeight(f_img.height);
                    self.canvas.renderAll();
                };
                img.src = this.imagePath;
            });
            
        });
        
                 
    }

    shuffle(array) {
        let currentIndex = array.length; 
        let temporaryValue;
        let randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    onMouseDown(options) {
        if (!this.canvas.getActiveObject()) {
            if(!this.polygonMode) {
                this.polygonMode = true;
                fabric.polygon.addPoint(options);
            } else {
                
                if(this.polygonMode){
                    fabric.polygon.addPoint(options);
                }
                if(this.pointArray.length == 4){
                    fabric.polygon.generatePolygon(this.pointArray);
                }
            }
        } 
    }

    onMouseMove(options) {
        if(this.activeLine && this.activeLine.class == "line"){
            let pointer = this.canvas.getPointer(options.e);
            this.activeLine.set({ x2: pointer.x, y2: pointer.y });
            let points = fabric.polygon.activeShape.get("points");
            points[this.pointArray.length] = {
                x: pointer.x,
                y: pointer.y
            };
            fabric.polygon.activeShape.set({
                points: points
            });
            this.canvas.renderAll();
        }
        this.canvas.renderAll();
    }

    onKeyup(event) {
        if (event.code == 'Delete' && this.canvas.getActiveObject()) {
            this.canvas.remove(this.canvas.getActiveObject());
        }
    }

    radioChange(event) {
        this.selectionColor = this.colors[this.selectionModes.indexOf(event.value)];
    }

    saveObjects() {
        this.elements = [];
        let objects = this.canvas.getObjects();
        objects.forEach(object => {
            this.elements.push(this.fillModel(object));
        });
        
        this.imageAnnotationService.saveResults(this.elements, this.currentPhotoId, this.userId, this.projectId).subscribe(succeeded => {
            if(succeeded) {
                this.nextPhoto();
            } else {
                alert('Er ging iets mis. Probeer het opnieuw.');
            }
            
        });
    }

    fillModel(object: any): ImageAnnotationResultElement {
        let result = new ImageAnnotationResultElement();
        result.Vertices = object.points;
        result.Type = object.name;
        return result;
    }

    nextPhotoClick() {
        if (confirm('Weet je zeker dat je naar de volgende foto wilt zonder de resultaten op te slaan?')) {
            this.imageAnnotationService.skipPhoto(this.currentPhotoId, this.projectId, this.photoSetId).subscribe(succeeded => {
                if(succeeded) {
                    this.nextPhoto(); 
                } else {
                    alert('Er ging iets mis. Probeer het opnieuw.');
                }
            });
        }
    }

    nextPhoto() {
        let newIndex = this.photos.indexOf(this.currentPhoto) + 1;
        if (newIndex >= this.photos.length) {
            this.done = true;
        } else {
            this.currentPhoto = this.photos[newIndex];
            this.currentPhotoId = this.currentPhoto.PhotoId;
            this.currentPhotoPath = this.currentPhoto.PhotoPath;
            this.canvas.clear();
            this.imageAnnotationService.getPhotoData(this.currentPhotoPath).subscribe(response => {
                this.currentPhotoData = response;
                if (this.currentPhotoData.Success) {
                    this.imagePath = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl('data:' + this.currentPhotoData.Mime + ';base64,' + this.currentPhotoData.Base64));
                }
                else {
                    this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl("pad/naar/een/afbeelding/met/nietgevonden.png");
                }
                var img = new Image();
                var self = this;
                img.onload = function() {
                    var f_img = new fabric.Image(img);
                    self.canvas.setBackgroundImage(f_img);
                    self.canvas.setWidth(f_img.width);
                    self.canvas.setHeight(f_img.height);
                    self.canvas.renderAll();
                };
                img.src = this.imagePath;
            });
            this.elements = [];
            this.pointArray = [];
        } 
    }



     


        
}





