<div *ngIf="Laden">
    Laden...
 </div>
<div>
   
</div>

<div *ngIf="!Laden">
    <div class="container">
        <div class="box-1">
            <h3>
                Filters Overzicht
                <button mat-icon-button color="primary" aria-label="Voeg nieuwe filter toe" (click)="resetFilterSelect()">
                    <i class="fal fa-plus"></i>
                </button>
            </h3>

            <fab-drag-and-drop [ItemArray]="FiltersViewModel.Filters" PropertyToShow="Name" (ArrayUpdated)="onArrayChange()" (SelectedAtIndex)="onSelectedAtIndex($event)"> </fab-drag-and-drop> -->
            <!-- <wct-drag-and-drop PropertyToShow="Name" (ArrayUpdated)="onArrayChange()" (SelectedAtIndex)="onSelectedAtIndex($event)"> </wct-drag-and-drop> -->

        </div>

        <div class="box-1">
            <filter [FilterId]="FilterId" (FiltersRefresh)="Herlaad()"></filter>
        </div>
    </div>
    
</div>

