// [conv] import "(./WctBeheerCompanyLogo).less";
import { FrontendConsumer } from '@consumers/FrontendController';
import { AppConfigService } from '../../infrastructure/app.configservice';
import { Component, Input } from "@angular/core";

@Component({
    selector: 'wct-beheer-company-logo',
    templateUrl: './WctBeheerCompanyLogo.html',
    styleUrls: ['./WctBeheerCompanyLogo.scss']
})
export class WctBeheerCompanyLogoComponent {

    @Input() public companyLogo: string = "";
    @Input() public imageHeight: string = "";
    urlCompanyLogo: string = "";
    first: boolean = false;
    url: string = "";
    
    constructor(
        private frontendConsumer: FrontendConsumer,
        private config: AppConfigService
    ) {

        this.HaalAfbeeldingenOp();
    }

    ngOnChanges() {
        this.HaalAfbeeldingenOp();
    }

    public HaalAfbeeldingenOp(): void {
        if (this.companyLogo != "") {
            this.frontendConsumer.GeefAfbeelding(this.companyLogo, "header").then(response => {
                console.log('found afbeelding');
                this.urlCompanyLogo = response;
            });
        }
    }

    GetCompanyLogo() {
        if (this.companyLogo != "") {
            if (!this.first) {

                let svg_url_submap = null;
                let png_url_submap = null;

                if (this.config.Software.CSSSubMap != undefined) {
                    svg_url_submap = '/Content/KlantSpecifiek/' + this.config.Software.CSSSubMap + '/images/' + this.companyLogo + ".svg";
                    png_url_submap = '/Content/KlantSpecifiek/' + this.config.Software.CSSSubMap + '/images/' + this.companyLogo + ".png";
                }

                let svg_url = '/Content/KlantSpecifiek/' + this.config.Software.CSSMap + '/images/' + this.companyLogo + ".svg";
                let png_url = '/Content/KlantSpecifiek/' + this.config.Software.CSSMap + '/images/' + this.companyLogo + ".png";


                if (svg_url_submap != null && this.UrlExists(svg_url_submap))
                {
                    this.url = svg_url_submap;
                } else if (png_url_submap != undefined && this.UrlExists(png_url_submap))
                {
                    this.url = png_url_submap;
                } else if (this.UrlExists(svg_url)) {
                    this.url = svg_url;
                } else if (this.UrlExists(png_url)) {
                    this.url = png_url;
                } else {
                    this.url = '/Content/images/header/' + this.companyLogo + ".svg";
                }

                this.first = true;
            }

            return this.url;
        }
        else
        {
            return "";
        }
    }

    UrlExists(url): boolean {
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        return http.status != 404;
    }

    
}


	
