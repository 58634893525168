
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { AccountService } from '../services/AccountService';

@Component({
    selector: 'wct-admin-ng8',
    styleUrls: ['./WctAdminNg8.scss'],
    templateUrl: './WctAdminNg8.html',
    encapsulation: ViewEncapsulation.None
})
export class WctAdminNg8Component {
    Header: string;
    previousUrl: string;
    ActivePage: string = "users";
    // constructor(private router: Router, private activatedRoute: ActivatedRoute, private _location: Location) {
    //     router.events
    //         .pipe(filter(
    //             (evt: any) => evt instanceof RoutesRecognized), pairwise())
    //         .subscribe((events: RoutesRecognized[]) => {
    //             console.log('prev', events[0].urlAfterRedirects);
    //             this.previousUrl = events[0].urlAfterRedirects;
    //         })
    //     console.log("Admin Components ");

    // }
    constructor(private accountservice: AccountService) {
      if (!window.location.href.includes(this.ActivePage)) {
        this.ActivePage = window.location.href.split("/admin/")[1];
        this.SetActivePage(this.ActivePage);
      }
    }

    // backClicked() {
    //     this._location.back();
    // }

    SetActivePage(page) {
      this.ActivePage = page;
    }

    logoff()
    {
        this.accountservice.Logout();
    }
}
