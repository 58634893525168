// [conv] import "(./WctAdminDistricts).less";
import { Component, Input, ViewChild } from '@angular/core';
import * as _ from 'lodash';




@Component({
    selector: 'wct-admin-districts',
    templateUrl: './WctAdminDistricts.html'
})
export class WctAdminDistrictsComponent {
 
    constructor() {


    }
    ngOnInit() {


    }


 
}