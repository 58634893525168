// [conv] import "(./WctRechtenControl).less";
import * as _ from 'lodash';
import * as ng from 'angular';
import { Component, Input } from '@angular/core';
import * as ModelsDocumentGebruikerModel from '@models/DocumentGebruikerModel';
import * as ModelsRechtenRegelModel from '@models/RechtenRegelModel';
import { WoningService, IBreadcrumbSelectie } from '../../services/WoningService';
import { PersoonInfo, PersoonService } from '../../services/PersoonService';
import { ProjectService } from '../../services/ProjectService';
import * as DataContractsRechtenRegelModel from '@datacontracts/RechtenRegelModel';
import * as HelpersOverlay from '../../Helpers/Overlay';
import * as DataContractsRechtenModel from '@datacontracts/RechtenModel';
import * as ViewModelsWoning from '../../Models/Woning';
import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';

class RechtenRegelViewModel {
   RechtenRegel: DataContractsRechtenRegelModel.IRechtenRegelModel;
   IsActief: Array<boolean>;

   constructor(
   ) {
      'ngInject';
      this.IsActief = new Array();
      this.IsActief = [false, false, false, false, false, false];
   }
}

class InheritedRechtenRegelViewModel {
   InheritedRechtenRegel: DataContractsRechtenRegelModel.IRechtenRegelModel;
   IsActief: Array<boolean>;

   constructor(
   ) {
      'ngInject';
      this.IsActief = new Array();
      this.IsActief = [false, false, false, false, false, false];
   }
}

@Component({
   selector: 'wct-rechten-control',
   templateUrl: './WctRechtenControl.html',
   providers: [WoningService, PersoonService, ProjectService] 
})
export class WctRechtenControlComponent {
   @Input() public rechten: DataContractsRechtenModel.IRechtenModel;
   public RechtenRegels: RechtenRegelViewModel[];
   public InheritedRechtenRegels: InheritedRechtenRegelViewModel[];

   private BezigMetLadenWoningen: boolean = true;
   public BezigViewModel: HelpersOverlay.IBezigState;
   public Woningen: ViewModelsWoning.Woning[];
   public Wijken: IBreadcrumbSelectie[];

   private BezigMetLadenPersonen: boolean = true;
   public Personen: PersoonInfo[];

   

   constructor(
      private woningService: WoningService,
      private persoonservice: PersoonService
   ) {
      'ngInject';
      this.BezigViewModel = HelpersOverlay.BezigState.Default();
   }

   ngOnInit(): void {
      this.BezigViewModel.ToonOverlay = true;

      this.Wijken = this.GetWijken();
      this.ZetRechtenViewModel();

      this.woningService.GeefBeheerWoningen().then(woningen => {
         this.Woningen = woningen;
         this.BezigMetLadenWoningen = false;
         this.DisableBezigViewModel();
      });
      this.persoonservice.GeefAlleInlogs().then(personen => {
         this.Personen = personen;
         this.BezigMetLadenPersonen = false;
         this.DisableBezigViewModel();
      });
   }

   public DisableBezigViewModel() {
      if (!this.BezigMetLadenWoningen && !this.BezigMetLadenPersonen) {
         this.BezigViewModel.ToonOverlay = false;
      }
   }

   ngOnChanges(): void {
      this.ZetRechtenViewModel();
   }

   public ZetRechtenViewModel(): void {
      this.RechtenRegels = [];
      this.InheritedRechtenRegels = [];
      if (this.rechten != undefined) {

         this.RechtenRegels = new Array();
         for (let i: number = 0; i < this.rechten.RechtenRegels.length; i++) {
            let model: RechtenRegelViewModel = new RechtenRegelViewModel();
            model.RechtenRegel = this.rechten.RechtenRegels[i];

            let ArrayIsActief: Array<boolean> = new Array();
            for (let j: number = 0; j < this.rechten.RechtenRegels[i].TypeRechten.length; j++) {
               let index: number = this.rechten.RechtenRegels[i].TypeRechten[j];
               model.IsActief[index] = true;
            }

            this.RechtenRegels.push(model);
         }
         this.InheritedRechtenRegels = new Array();
         for (let a: number = 0; a < this.rechten.InheritedRechtenRegels.length; a++) {
            let model: InheritedRechtenRegelViewModel = new InheritedRechtenRegelViewModel();
            model.InheritedRechtenRegel = this.rechten.InheritedRechtenRegels[a];

            let ArrayIsActief: Array<boolean> = new Array();
            for (let b: number = 0; b < this.rechten.InheritedRechtenRegels[a].TypeRechten.length; b++) {
               let index: number = this.rechten.InheritedRechtenRegels[a].TypeRechten[b];
               model.IsActief[index] = true;
            }

            this.InheritedRechtenRegels.push(model);
         }
      }
   }

   public AddNewRow(): void {
      let rechtenregel = new RechtenRegelViewModel;
      rechtenregel.RechtenRegel = new ModelsRechtenRegelModel.RechtenRegelModel();
      rechtenregel.RechtenRegel.Gebruiker = new ModelsDocumentGebruikerModel.DocumentGebruikerModel();
      rechtenregel.RechtenRegel.Gebruiker.Type = "Persoon";
      rechtenregel.RechtenRegel.TypeRechten = new Array<number>();

      this.RechtenRegels.push(rechtenregel);
   }

   public UpdateRechtenregels(): void {
      for (let i: number = 0; i < this.RechtenRegels.length; i++) {
         this.rechten.RechtenRegels[i] = new ModelsRechtenRegelModel.RechtenRegelModel();
         this.rechten.RechtenRegels[i].Gebruiker = this.RechtenRegels[i].RechtenRegel.Gebruiker;
         this.rechten.RechtenRegels[i].Inherit = this.RechtenRegels[i].RechtenRegel.Inherit;

         let typerechten: Array<number> = new Array<number>();
         for (let j: number = 0; j < this.RechtenRegels[i].IsActief.length; j++) {
            if (this.RechtenRegels[i].IsActief[j]) {
               typerechten.push(j);
            };
         }
         this.rechten.RechtenRegels[i].TypeRechten = typerechten;
      }
   }

   public UpdateInheritedRechtenRegels(): void {
      for (let i: number = 0; i < this.InheritedRechtenRegels.length; i++) {
         this.rechten.InheritedRechtenRegels[i] = new ModelsRechtenRegelModel.RechtenRegelModel();
         this.rechten.InheritedRechtenRegels[i].Gebruiker = this.InheritedRechtenRegels[i].InheritedRechtenRegel.Gebruiker;
         this.rechten.InheritedRechtenRegels[i].Inherit = this.InheritedRechtenRegels[i].InheritedRechtenRegel.Inherit;

         let typerechten: Array<number> = new Array<number>();
         for (let j: number = 0; j < this.InheritedRechtenRegels[i].IsActief.length; j++) {
            if (this.InheritedRechtenRegels[i].IsActief[j]) {
               typerechten.push(j);
            }
         }
         this.rechten.InheritedRechtenRegels[i].TypeRechten = typerechten;
      }
   }

   public GeefNaamPersoon(PersoonId: number): string {
      let ret: string = "";
      _.forEach(this.Personen, (persoon) => {
         if (persoon.PersoonId == PersoonId) {
            ret = persoon.Inlognaam;
         }
      });
      return ret;
   }

   public GeefNaamWoning(WoningID: number): string {
      let ret: string = "";
      _.forEach(this.Woningen, (woning) => {
         if (woning.WoningID == WoningID) {
            ret = woning.AdresTekst();
         }
      });
      return ret;
   }

   private GetWijken(): IBreadcrumbSelectie[] {
      if (this.woningService.GeefWoningGroepenMember().length > 0) {
         if (this.woningService.GeefWoningGroepenMember()[0].Naam == "Project")
         {
            return this.woningService.GeefWoningGroepenMember()[0].SubGroepen;
         }
      }

      return null;
   }

   trackByFunction(index, item) {
      return index;
   }
}