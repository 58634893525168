<div class="cont-documenten-popup">
	<div class="cont-title">
		<h4>{{ "DOCUMENTMANAGEMENT_REMOVE-DOCUMENT" | translate }}</h4>
		<img (click)="closeDialog()"[src]="'/Content/Images/menu/menu-close-white.svg'" class="img-title" /> 
	</div>
	<div class="cont-main">
		<p>{{ 'DOCUMENTMANAGEMENT_SURE-YOU-REMOVE-THIS-DOCUMENT' | translate }}?</p>
	</div>
	<div class="cont-btns">
		<wct-button buttonText="{{ 'DOCUMENTMANAGEMENT_CANCEL' | translate }}" buttonSize="small" buttonRole="secundary" (click)="closeDialog()"></wct-button>
		<wct-button buttonText="{{ 'DOCUMENTMANAGEMENT_OK' | translate }}" buttonSize="small" buttonRole="primary" (click)="deleteDocument()"></wct-button>
	</div>
</div>