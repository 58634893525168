import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GoogleAnalyticsService } from '../../../../services/GoogleAnalyticsService';
import { FabFilterDialogOption } from './FabFilters'; 

@Component({
  selector: 'fab-filters-dialog',
  styleUrls: ['./FabFiltersDialog.scss'],
  templateUrl: './FabFiltersDialog.html'
})

export class FabFiltersDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<FabFiltersDialogComponent>,
		private googleAnalyticsService: GoogleAnalyticsService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		'ngInject';		
		let overlay: any = document.getElementsByClassName("cdk-overlay-dark-backdrop")[0];
		overlay.style.background = "white";
	}

	Close(fabfilterdialogoption: FabFilterDialogOption) {
		// Google Analytics event
		this.googleAnalyticsService.sendCustomEvent("Bestekservice", "FilterCategoryChanged", fabfilterdialogoption.Name, fabfilterdialogoption.Id);
		
		this.dialogRef.close(fabfilterdialogoption.Id);
	}

	public GetTitleHasTwoLines(fabfilterdialogoption: FabFilterDialogOption) {
		let ret: boolean = false;
		if ((fabfilterdialogoption.Name.length > 10) && (window.innerWidth < 430)) {
			ret = true;
		}
		return ret;
	}

	public GetDescription(fabfilterdialogoption: FabFilterDialogOption) {
		let ret: string = fabfilterdialogoption.Description;
		if (fabfilterdialogoption.DescriptionShort !== null && (window.innerWidth < 600)) {
			ret = fabfilterdialogoption.DescriptionShort;
		} 
		return ret;
	}
}
