/// <reference path="webgl.d.ts"/>
/// <reference path="gl-matrix.d.ts"/>

//
// Note (1):
// In deze file komt het op een aantal plaatsen voor dat een functie (of set-property) een parameter van een gegeven type heeft,
// terwijl in de body van die functie alsnog het type van de parameter gecheckt wordt.
// Dit speelt met name bij boolean parameters.
// Dit lijkt nogal dubbelop, maar de reden is dat die functie ook vanuit een JavaScript-context kan worden aangeroepen.
// De expliciete typecheck is dan zinvol. 
// Zie echter note (2) hieronder.
// 
// Note (2):
// Bij overnemen van velden uit een niet-strong-typed object (b.v. na JSON.parse), is type-checking belangrijk.
// Soms wordt die typechecking gedaan door de aangeroepen set-property.
// Zie echter note (1) hierboven: het is denkbaar dat ooit sommige typechecks uit die set-properties zullen verdwijnen
// (namelijk als er geen aanroepende JavaScript contexten meer zijn). 
// Maar bij het overnemen van gegevens uit een niet-strong-typed object moeten die typechecks toch blijven, 
// of expliciet terugkomen bij de plaats van aanroep.
//
// Note (3):
// Het aanroepen van de functies mat3.create, mat4.create en mat4.createFrom levert een GLM.IArray op. 
// Dat is een interface definitie, maar die heeft (formeel gesproken) geen length-property.
// In werkelijkheid zal die property er wel zijn, dus is er niet echt een probleem. In de code wordt verder type 'number[]' gebruikt.
// Maar dan moet ik bij het aanroepen van genoemde functies wel geforceerd casten naar number[] om TypeScript tevreden te stellen.
//


/*
 *  WebGLViewer library
 * ====================
 *
 * Displays 3D objects in a canvas-element with zoom, rotate and pan functionality.
 * - Zooming by mouse or touch.
 * - Rotating and panning by mouse or touch.
 * - Supports key input through the parent element of the canvas-element.
 * Adds selection functionality to the canvas-element.
 * - Supports selection by mouse or touch.
 *
 * Requirements:
 *
 * - This library uses the JavaScript-file "gl-matrix.min.js". It should be loaded.
 * - A reference to this JavaScript-file should be added to the html-document in question, 
 *   e.g. by means of a script element <script type="text/javascript" src="WebGLViewer.js"></script>
 *
 * Supported browsers:
 *
 * - Google Chrome 41
 * - Mozilla Firefox 35
 * - Microsoft IE 11
 * - Microsoft Edge 12
 * - Opera 27
 * - Apple Safari 5.1 for Mac OS X
 *
 */

'use strict';

export const enum eViewerWorkModus { normal, createDrawing, createBridgeLine, moveDrawingElements, deleteShape, deleteBridgeLine, 
	align, placeLibraryObject, placeOpening }
export const eViewerWorkModusTags: string[] = ["normal", "createDrawing", "createBridgeLine", "moveDrawingElements", 
	"deleteShape", "deleteBridgeLine", "align", "placeLibraryObject", "placeOpening"]; // For printing eViewerWorkModus values.

export const enum eMouseEvent { mouseDown, mouseMove, mouseUp }

export namespace VLib
{
	export class Marker3D {

		constructor() {
			const sa: number = 0.05; // symboolafmeting
			const sa1_8: number = sa * Math.cos(Math.PI / 8.0);
			const sa2_8: number = sa * Math.cos(Math.PI / 4.0);
			const sa3_8: number = sa * Math.cos(3.0 * Math.PI / 8.0);
			this.ParamRek = [
				-sa, 0, 0,
				sa, 0, 0,
				0, 0, 0,
				0, sa, 0,
				0, -sa, 0
			];
			this.ParamSchaal = [
				-sa, 0, 0,
				sa, 0, 0,
				sa, -sa, 0,
				0, -sa, 0,
				0, sa, 0
			];
			this.ParamAfrondings = [
				-sa, 0, 0,
				sa, 0, 0,
				sa1_8, sa3_8, 0,
				sa2_8, sa2_8, 0,
				sa3_8, sa1_8, 0,
				0, sa, 0,
				0, -sa, 0
			];
			this.ParamStraal = [
				-sa, 0, 0,
				sa, 0, 0,
				sa1_8, sa3_8, 0,
				sa2_8, sa2_8, 0,
				sa3_8, sa1_8, 0,
				0, sa, 0,
				0, -sa, 0,
				-sa3_8, -sa1_8, 0,
				-sa2_8, -sa2_8, 0,
				-sa1_8, -sa3_8, 0,
				-sa, 0, 0
			];
			this.ParamRotatie = [
				-sa, 0, 0,
				sa, 0, 0,
				sa1_8, -sa3_8, 0,
				sa2_8, -sa2_8, 0,
				sa3_8, -sa1_8, 0,
				0, -sa, 0,
				-sa3_8, -sa1_8, 0,
				-sa2_8, -sa2_8, 0,
				-sa1_8, -sa3_8, 0,
				-sa, 0, 0,
				-sa1_8, sa3_8, 0,
				-sa2_8, sa2_8, 0,
				-sa3_8, sa1_8, 0,
				0, sa, 0,
				0, -sa, 0
			];
			this.Referentie = [
				0, 0, 0,
				0, sa, 0,
				sa, 0, 0,
				0, 0, 0
			];
			this.Aanklik = [
				-sa2_8, -sa2_8, 0,
				-sa2_8, sa2_8, 0,
				sa2_8, sa2_8, 0,
				sa2_8, -sa2_8, 0,
				-sa2_8, -sa2_8, 0
			];
			this.Controle = [
				sa, 0, 0,
				-sa, 0, 0,
				0, sa, 0,
				0, -sa, 0,
				-sa, 0, 0
			];
			this.Invoeg = [
				0, sa, 0,
				0, -sa, 0,
				-sa, 0, 0,
				sa, 0, 0,
				0, -sa, 0
			];
			this.Default = [
				-sa2_8, sa2_8, 0,
				sa2_8, -sa2_8, 0,
				0, 0, 0,
				sa2_8, sa2_8, 0,
				-sa2_8, -sa2_8, 0
			];
		}
	
		public ParamRek: number[];
		public ParamSchaal: number[];
		public ParamAfrondings: number[];
		public ParamStraal: number[];
		public ParamRotatie: number[];
		public Referentie: number[];
		public Aanklik: number[];
		public Controle: number[];
		public Invoeg: number[];
		public Default: number[];
	} // class Marker3D
	
	//
	// Type for field navData in class Viewer3DPrivates
	//
	export type NavDataType = {
		muisNeerPositie: number[], // X en Y.
		muisPositie: number[], // X en Y.
		muisDrempel: number, // in pixels
		muisIsKlik: boolean,
		touch1NeerPositie: number[], // X en Y.
		touch1Positie: number[], // X en Y.
		touch1Identifier: number,
		touch2NeerPositie: number[], // X en Y.
		touch2Positie: number[], // X en Y.
		touch2Afstand: number,
		touch2Identifier: number,
		touchDrempel: number, // in pixels
		touchIsTap: boolean,
		touchIsKneep: boolean,
		draaienOmPuntData: DraaienOmPuntDataType,
		wandelImpuls: number,
		wandelAmplificatie: number,
		wandelSnelheidHuidig: number[], // in mm/s
		verversenAanhangig: boolean,
		animatieOnderbroken: boolean
	}
	
	//
	// Hulp-class voor class Viewer3D.
	// Bevat de ingekapselde eigenschappen ('privates') binnen Viewer3D.
	//
	export class Viewer3DPrivates {
	
		constructor() {
			//
			// Is bewust leeg.
			//
		}
	
		//
		// Let op: In de gegenereerde JavaScript komen de functies onderaan te staan.
		//
	
		//
		// Constanten:
		//
		public readonly defTypeMarkeerpunt: number = 0;
		public readonly defTypeLijn: number = 1;
		public readonly defTypeVlak: number = 2;
		public readonly defTypeVolume: number = 3;
		public readonly standaardShadowMapAfmeting: number = 2048;
	
		//
		// Canvas, context en shader programs:
		//
		public canvasWrap: CanvasWrapType = undefined;
		public glWrap: WebGLRenderingContextWrapType = undefined;
		public shaderProgramWrap: WebGLProgramWrapType = undefined;               // het actieve shader program
		public shaderProgramStdWrap: WebGLProgramWrapType = undefined;            // standaard shader program
		public shaderProgramPickingWrap: WebGLProgramWrapType = undefined;        // shader program voor selectie
		public shaderProgramShadowMappingWrap: WebGLProgramWrapType = undefined;  // shader program voor shadow mapping
		public shaderProgramZBufferMappingWrap: WebGLProgramWrapType = undefined; // shader program voor Z-buffer mapping
		public shaderProgramTextureCopyWrap: WebGLProgramWrapType = undefined;    // shader program voor het kopieren van een texture (een nabewerking)
		public shaderProgramFXAAWrap: WebGLProgramWrapType = undefined;           // shader program voor FXAA (een nabewerking)
		public shaderProgramBokehWrap: WebGLProgramWrapType = undefined;          // shader program voor bokeh (een nabewerking)
		public shaderProgramVignetteWrap: WebGLProgramWrapType = undefined;       // shader program voor een vignette (een nabewerking)
		public shaderProgramSSAOBerekenenWrap: WebGLProgramWrapType = undefined;  // shader program voor SSAO (een nabewerking)
		public shaderProgramSSAOAanbrengenWrap: WebGLProgramWrapType = undefined; // shader program voor SSAO (een nabewerking)
	
		//
		// Matrices:
		//
		public projectieMatrix: number[] = <number[]>mat4.create(); // projectiematrix // M.b.t. de cast: zie note (3) bovenin deze file.
		public modelviewMatrix: number[] = <number[]>mat4.create(); // modelviewmatrix // M.b.t. de cast: zie note (3) bovenin deze file.
		public rotatieMatrix: number[] = <number[]>mat3.create();   // rotatiematrix // M.b.t. de cast: zie note (3) bovenin deze file.
	
		//
		// Clipping-frustum: tbv uitvoer 'culling' op de fragmenten.
		// Alleen gevuld voor firmamentcamera.
		//
		public projectieClippingFrustum: ProjectieClippingFrustumType = { Frustum: undefined };
	
		//
		// Blikveldconstanten:
		//
		public readonly constBereik: number = 1.0; // bereik (helft van de kleinste afmeting) van de viewport in WebGL eenheden
		public readonly constAfstand: number = 1.0 / Math.sin((45 / 2.0) * Math.PI / 180.0); // camera-afstand tot de oorsprong in WebGL eenheden
	
		//
		// Blikveld:
		//
		public _zoomSchaal: number = 1.0; // Voor 'getten' wordt naast de get-property ook direct dit veld gebruikt, i.v.m. performance. Daarom is dit veld public.
		//
		// Stelt de schaal gebruikt bij het zoomen in.
		// Parameter 'value' is een float.
		//
		public set zoomSchaal(value: number) {
			if (this.glWrap && Lib.isGetal(value)) {
				value = Math.max(value, 0.5);
				value = Math.min(value, 1000.0);
				this._zoomSchaal = value;
			}
		};
		//
		// Geeft de schaal gebruikt bij het zoomen.
		// Return-waarde is een float.
		//
		public get zoomSchaal(): number {
			return this._zoomSchaal;
		};
	
		public _verschuivingRechts: number = 0.0; // Voor 'getten' wordt naast de get-property ook direct dit veld gebruikt, i.v.m. performance. Daarom is dit veld public.
		//
		// Stelt de verschuiving naar rechts vanaf oorsprong uitgedrukt in ratio van viewportbereik in.
		// Parameter 'value' is een float.
		//
		public set verschuivingRechts(value: number) {
			if (this.glWrap && Lib.isGetal(value)) {
				value = Math.max(value, -10.0);
				value = Math.min(value, 10.0);
				this._verschuivingRechts = value;
			}
		};
		//
		// Geeft de verschuiving naar rechts vanaf oorsprong uitgedrukt in ratio van viewportbereik. 
		// Return-waarde is een float.
		//
		public get verschuivingRechts(): number {
			return this._verschuivingRechts;
		};
	
		public _verschuivingOmhoog: number = 0.0; // Voor 'getten' wordt naast de get-property ook direct dit veld gebruikt, i.v.m. performance. Daarom is dit veld public.
		//
		// Stelt de verschuiving omhoog vanaf oorsprong uitgedrukt in ratio van viewportbereik in.
		// Parameter 'value' is een float.
		//
		public set verschuivingOmhoog(value: number) {
			if (this.glWrap && Lib.isGetal(value)) {
				value = Math.max(value, -10.0);
				value = Math.min(value, 10.0);
				this._verschuivingOmhoog = value;
			}
		};
		//
		// Geeft de verschuiving omhoog vanaf oorsprong uitgedrukt in ratio van viewportbereik. 
		// Return-waarde is een float.
		//
		public get verschuivingOmhoog(): number {
			return this._verschuivingOmhoog;
		};
	
		public _richting: number = 90.0; // Voor 'getten' wordt naast de get-property ook direct dit veld gebruikt, i.v.m. performance. Daarom is dit veld public.
		//
		// Stelt de richting in graden in. De hoek in het horizontale vlak met de positieve X-as. 
		// Parameter 'value' is een float.
		//
		public set richting(value: number) {
			if (this.glWrap && Lib.isGetal(value)) {
				this._richting = ((value % 360.0) + 360.0) % 360.0;
			}
		};
		//
		// Geeft de richting in graden. De hoek in het horizontale vlak met de positieve X-as. 
		// Return-waarde is een float.
		//
		public get richting(): number {
			return this._richting;
		};
	
		public _helling: number = 0.0; // Voor 'getten' wordt naast de get-property ook direct dit veld gebruikt, i.v.m. performance. Daarom is dit veld public.
		//
		// Stelt de helling in graden in. De hoek met het horizontale vlak, waarbij neerwaarts positief is en waarbij 
		// horizontaal nul is.
		// Parameter 'value' is een float.
		//
		public set helling(value: number) {
			if (this.glWrap && Lib.isGetal(value)) {
				value = ((value % 360.0) + 360.0) % 360.0;
	
				switch (this.hellingRestrictie) {
					default:
					case 0: // Geen
						break;
					case 1: // Horizontaal
						{
							value = 0.0;
							break;
						}
					case 2: // Half
						{
							if (value <= 180.0) {
								value = Math.min(value, 90.0);
							}
							else {
								value = Math.max(value, 270.0);
							}
							break;
						}
					case 3: // Kwart
						{
							if (value <= 180.0) {
								value = Math.min(value, 45.0);
							}
							else {
								value = Math.max(value, 315.0);
							}
							break;
						}
					case 4: // KwartOmlaag
						{
							if (value <= 225.0) {
								value = Math.min(value, 90.0);
							}
							else {
								value = 0.0;
							}
							break;
						}
					case 5: // KwartOmhoog
						{
							if (value <= 135.0) {
								value = 0.0;
							}
							else {
								value = Math.max(value, 270.0);
							}
							break;
						}
				}
	
				this._helling = value;
			}
		};
		//
		// Geeft de helling in graden. De hoek met het horizontale vlak, waarbij neerwaarts positief is en waarbij 
		// horizontaal nul is.
		// Return-waarde is een float.
		//
		public get helling(): number {
			return this._helling;
		};
	
		//
		// _rolhoek:
		// Voor 'getten' wordt in principe naast de get-property ook direct dit veld gebruikt, i.v.m. performance. Daarom is dit veld public.
		// Momenteel (15-1-2019) wordt de set-property niet gebruikt. Veld _rolhoek is momenteel dus in de praktijk een constante.
		// Ook de get-property wordt momenteel (15-1-2019) niet gebruikt. Telkens wordt dit veld direct gebruikt.
		//
		public _rolhoek: number = 0.0;
		//
		// Stelt de rolhoek in graden in. De hoek om de kijkrichting, waarbij met de wijzers van de klok mee positief 
		// is en waarbij omhoog nul is.
		// Parameter 'value' is een float.
		//
		public set rolhoek(value: number) {
			if (this.glWrap && Lib.isGetal(value)) {
				this._rolhoek = ((value % 360.0) + 360.0) % 360.0;
			}
		};
		//
		// Geeft de rolhoek in graden. De hoek om de kijkrichting, waarbij met de wijzers van de klok mee positief is 
		// en waarbij omhoog nul is.
		// Return-waarde is een float.
		//
		public get rolhoek(): number {
			return this._rolhoek;
		};
	
		//
		// Weergave-opties:
		//
		public achtergrondKleur: number[] = [0, 0, 0]; // in ubyte[3], zwart
		public toonAssen: boolean = false;
		public orthografischeProjectie: boolean = true;
	
		//
		// Camera:
		//
		public cameraType: boolean = true; // true voor firmamentcamera, false voor terreincamera
		//
		// Bepaalt of de toegepaste camera een firmamentcamera is.
		// Return-waarde is een boolean.
		//
		public isFirmamentCamera(): boolean {
			return (this.cameraType === true);
		};
		//
		// Bepaalt of de toegepaste camera een terreincamera is.
		// Return-waarde is een boolean.
		//
		public isTerreinCamera(): boolean {
			return (this.cameraType === false);
		};
	
		public _positie: number[] = [0.0, 0.0, 0.0]; // Voor 'getten' wordt naast de get-property ook direct dit veld gebruikt, i.v.m. performance. Daarom is dit veld public.
		//
		// Stelt de positie van de camera in.
		// Parameter 'value' is een array van floats met lengte 3 (x-, y- en z-componenten).
		//
		public set positie(value: number[]) {
			if (this.glWrap && Lib.isVector(value, true)) {
				this._positie = value.slice();
			}
		};
		//
		// Geeft de positie van de camera.
		// Return-waarde is een array van floats met lengte 3 (x-, y- en z-componenten).
		//
		public get positie(): number[] {
			return this._positie.slice();
		};
	
		//
		// Belichting:
		//
		public belichting: Belichting = new Belichting();
	
		//
		// Lampen:
		//
		public lampen: Lamp[] = undefined;
		public lampenMaxAantal: number = null; // is null wanneer kunstlichtbronnen niet ondersteund worden
		public lichtAttenuatieAfstand: number = 3000.0;
	
		//
		// Assenstelsel:
		//
		public assenItem: AssenItemType = undefined;
	
		//
		// Hemel:
		//
		public hemelItem: FragmentItemType = undefined;
		public hemelProjectieMatrix: number[] = <number[]>mat4.create(); // projectiematrix // M.b.t. de cast: zie note (3) bovenin deze file.
		public hemelModelviewMatrix: number[] = <number[]>mat4.create(); // modelviewmatrix // M.b.t. de cast: zie note (3) bovenin deze file.
		//
		// Kompas:
		//
		public yAsTovNoord: number = 0.0;
	
		//
		// Animatie en lus:
		//
		public isGeanimeerd: boolean = false;
		public animatieCallback: V3DAnimatieCallbackType = undefined;
		public animatieStartTijd: number = undefined; // in ms
		public animatieVorigeTijd: number = undefined; // in ms
		public animatieOnderbreken: boolean = true;
		public isGelust: boolean = false;
		public lusVorigeTijd: number = undefined; // in ms
	
		//
		// Navigatie:
		//
		public navigeerbaar: boolean = true;
		public wandelType: number = 0; // 0 of 1.
		public wandelStap: number = 250.0; // in mm
		public wandelSnelheid: number = 1400.0; // in mm/s
		public wandelVersnellingsfactor: number = 1.0;
		public wandelVertragingsfactor: number = 2.0;
		public wandelRestrictie: number = 0;
		public hellingRestrictie: number = 0;
		public perimeter: Perimeter = undefined;
		public positieAanpassen: V3DPositieAanpassenFunctieType = undefined;
		public navData: NavDataType = {
			muisNeerPositie: null, // number[]: X en Y.
			muisPositie: null, // number[]: X en Y.
			muisDrempel: 5, // number, in pixels
			muisIsKlik: null, // boolean
			touch1NeerPositie: null, // number[]: X en Y.
			touch1Positie: null, // number[]: X en Y.
			touch1Identifier: null, // number
			touch2NeerPositie: null, // number[]: X en Y.
			touch2Positie: null, // number[]: X en Y.
			touch2Afstand: null, // number
			touch2Identifier: null, // number
			touchDrempel: 20, // number, in pixels
			touchIsTap: null, // boolean
			touchIsKneep: null, // boolean
			draaienOmPuntData: undefined, // DraaienOmPuntDataType
			wandelImpuls: 0,
			wandelAmplificatie: 1.0,
			wandelSnelheidHuidig: [0, 0, 0], // in mm/s
			verversenAanhangig: false,
			animatieOnderbroken: false
		};
	
		//
		// Het af te beelden model:
		//
		public modelDims: ModelDimensiesType = undefined; // nader te bepalen (bij een leeg model is de waarde null)
		public fragmenten: FragmentType[] = []; // array van fragment-objecten
		public texBronnen: TexBronType[] = []; // array van texBron-objecten
		public texNamenBlacklist: string[] = []; // array van bestandsnaam-strings; lijst van onlaadbare texturen
	
		//
		// Selectie:
		//
		public selectieKleur: number[] = [255, 255, 255, 255]; // in ubyte[4], wit
		public selectieKleurIntern: number[] = [255, 255, 255, 255]; // in ubyte[4], wit
		public selectieKleurInternF: number[] = [1.0, 1.0, 1.0, 1.0]; // in float[4], wit
		public isSelectieKleurOpaak: boolean = true;
		public selectieToestaan: boolean = false;
		public multiselectieToestaan: boolean = true;
		public selectieLock: boolean = false; // vlag om selectie te blokkeren
		public selectieIDs: SelectieIDInternType[] = [];
		public selectieVeranderendCallback: V3DSelectieVeranderendCallbackType = undefined;
		public selectieVeranderdCallback: V3DSelectieVeranderdCallbackType = undefined;
		public editorSelectionChangedCallback: V3DEditorSelectionChangedCallbackType = undefined;
	
		//
		// Extensies:
		//
		public extDepthTexture: WEBGL_depth_texture = undefined; // depth texture extension
		public extTextureFilterAnisotropicWrap: EXT_texture_filter_anisotropic_WrapType = undefined; // anisotrope textuurfiltering extension
	
		//
		// Editor3D fields for 'move drawing elements':
		//
		public selectedEditorFragmentID: string = null; // Active if not null.
		public mouseHasMovedForSelectedEditorFragment: boolean = false; // This enables the editor to do something special for the first mousemove action after a mousedown.
		public moveDrawingElementsModusMouseMoveCallback: V3MoveDrawingElementsModusMouseMoveCallback = undefined;
		//
		// Editor3D fields for 'create drawing', 'create bridgeLine', 'align' and 'placeLibraryObject':
		//
		public createDrawingModusCallback: V3CreateDrawingModusCallback = undefined;
		public createBridgeLineModusCallback: V3CreateBridgeLineModusCallback = undefined;
		public movedElementCallback: V3MovedElementCallback = undefined;
		public alignModusCallback: V3AlignModusCallback = undefined;
		public placeLibraryObjectCallback: V3PlaceLibraryObjectCallback = undefined;
		public placeOpeningCallback: V3PlaceOpeningCallback = undefined;
		//
		// Editor3D fields for 'delete drawing' and 'delete bridgeLine':
		// Uses also field 'selectedEditorFragmentID'.
		//
		public deleteShapeModusCallback: V3DeleteShapeModusCallback = undefined;
		public deleteBridgeLineModusCallback: V3DeleteBridgeLineModusCallback = undefined;
		//
		// Editor3D fields for 'end action':
		//
		public endEditorActionCallback: V3EndEditorActionCallback = undefined;

		//
		// Diversen:
		//
		public logEnabled: boolean = false;
		public workModus: eViewerWorkModus = eViewerWorkModus.normal;
		public berichtCallback: V3DBerichtCallbackType = undefined; // Zie comment bij set-property: berichtCallback.
		public paramMaxTextureSize: number = 64; // schatting van de maximale texture-afmeting
		public tekenCyclus: boolean = false; // vlag voor de tekencyclus (even/oneven), ten behoeve van cache-verversing
		public metBumpmappingEis: boolean = true;
		public metBumpmappingHint: boolean = true;
		public eventListenerOpties: EventListenerOptiesType = null;
		public nabewerkingen: Nabewerkingen = undefined;
		public clearMengKleur: number[] = [0.0, 0.0, 0.0, 0.0]; // in float[4], zwart onzichtbaar
		public afterIdleActief: boolean = false; // Tbv (de)activeren functie afterIdle.
		public dummyTexture: WebGLTexture = null;
		public keyPressCallback: V3DPressedKeyCallbackType = undefined;
	
		public showOrientation(tag: string) { // For debugging
			console.log(tag + "|Viewer orientation: zoom=" + this._zoomSchaal + "|versR=" + this._verschuivingRechts + "|versO=" + this.verschuivingOmhoog +
				"|richt=" + this._richting + "|hell=" + this._helling + "|rolh=" + this._rolhoek + "|pos=" + this._positie);
		}
	
	} // class Viewer3DPrivates
	
	//
	// PerimeterSegment en PerimeterDeel:
	// Mini-classes voor perimeter-data binnen de Perimeter-class.
	// Worden gevuld a.d.h.v. JSON-input voor de constructor van class Perimeter, zie de typedefinitief hier direct boven.
	// De constructors zijn verantwoordelijk voor het checken van de input.
	//
	
	export class PerimeterSegment {
	
		constructor(perimeterSegmentJson: PerimeterSegmentJsonType, perimeterDeel: PerimeterDeel) {
			if (typeof perimeterSegmentJson != 'object') { throw "PerimeterSegment is geen object" };
			if (perimeterSegmentJson == null) { throw "PerimeterSegment is null" }; // Let op m.b.t. vorige regel: typeof null == 'object'.
	
			let punten: number[] = perimeterSegmentJson.ptn;
			if (!Lib.isGetalLijst(punten, 4)) { throw "PerimeterSegment.ptn is geen getal-array of heeft foute lengte" };
			this.ptn = punten;
			//
			// bounding box van segment = [ Min(beginX, eindX), Max(beginX, eindX), Min(beginY, eindY), Max(beginY, eindY) ]
			//
			this.bb = [Math.min(punten[0], punten[2]), Math.max(punten[0], punten[2]),
			Math.min(punten[1], punten[3]), Math.max(punten[1], punten[3])];
			this.ouder = perimeterDeel;
			//
			// Velden buurOuder en buur worden later gezet (optioneel).
			//
		}
	
		public ptn: number[]; // arraylengte is 4
		public buurOuder?: PerimeterDeel;
		public buur?: PerimeterSegment;
		public bb: number[]; // arraylengte is 4 // bounding box van segment
		public ouder: PerimeterDeel;
	} // class PerimeterSegment
	
	export class PerimeterDeel {
		//
		// In geval van schuin vlak (C#: PerimeterDeel.IsHorizontaal == false), dan properties helling, basis en opwaarts aanwezig. Anders alledrie niet.
		//
	
		constructor(perimeterDeelJson: PerimeterDeelJsonType) {
			if (typeof perimeterDeelJson != 'object') { throw "PerimeterDeel is geen object" };
			if (perimeterDeelJson == null) { throw "PerimeterDeel is null" }; // Let op m.b.t. vorige regel: typeof null == 'object'.
	
			let IdJson: string = perimeterDeelJson.ID;
			if (IdJson != undefined) {
				if (typeof IdJson != 'string') { throw "PerimeterDeel.ID is geen string" };
				this.ID = IdJson;
			}
	
			let bbJson: number[] = perimeterDeelJson.bb;
			if (!Lib.isGetalLijst(bbJson, 6)) { throw "PerimeterDeel.bb is geen getal-array of heeft foute lengte" };
			this.bb = bbJson;
	
			let hellingJson: number = perimeterDeelJson.helling;
			let basisJson: number[] = perimeterDeelJson.basis;
			let opwaartsJson: number[] = perimeterDeelJson.opwaarts;
			if (hellingJson != undefined) {
				if (!Lib.isGetal(hellingJson)) { throw "PerimeterDeel.helling is geen geldig getal" };
				if (!Lib.isGetalLijst(basisJson, 3)) { throw "PerimeterDeel.basis is geen geldig getal-array" };
				if (!Lib.isGetalLijst(opwaartsJson, 2)) { throw "PerimeterDeel.opwaarts is geen geldig getal-array" };
				this.helling = hellingJson;
				this.basis = basisJson;
				this.opwaarts = opwaartsJson;
			}
			else { // hellingJson==undefined, dan moeten basisJson en opwaartsJson ook undefined zijn.
				if (basisJson != undefined) { throw "In PerimeterDeel is drietal helling, basis en opwaarts deels gevuld" };
				if (opwaartsJson != undefined) { throw "In PerimeterDeel is drietal helling, basis en opwaarts deels gevuld" };
			}
	
			let segmenten: PerimeterSegmentJsonType[] = perimeterDeelJson.sgmn;
			if (!Array.isArray(segmenten)) { throw "PerimeterDeel bevat geen segmenten-array" };
			for (let j: number = 0; j < segmenten.length; j++) {
				let segmentJson: PerimeterSegmentJsonType = segmenten[j];
				let segment: PerimeterSegment = new PerimeterSegment(segmentJson, this);
				this.sgmn.push(segment);
			}
		}
	
		public ID?: string;
		public bb: number[]; // arraylengte is 6 (want 3 dimensies).
		public helling?: number;
		public basis?: number[]; // arraylengte is 3
		public opwaarts?: number[]; // arraylengte is 2
		public sgmn: PerimeterSegment[] = [];
	} // class PerimeterDeel
	
	//
	// Code van de vertex en fragment shaders //////////////////////////////////////////////////////////////////////////////
	//
	// De functies in class ShaderSet zijn static.
	// Je kunt dus simpelweg volstaan met:
	//		let code: string = ShaderSet.vertexShaderCodeStd();
	// Class ShaderSet hoeft dus nooit geïnstantieerd te worden (new ShaderSet()).
	//
	
	export class ShaderSet {
	
		constructor() {
			//
			// Is bewust leeg.
			//
		}
	
		public static vertexShaderCodeStd(): string {
			return ''
				+ '#define MARKEERPUNT 0 \n'
				+ '#define LIJN 1 \n'
				+ '#define VLAK 2 \n'
				+ '#define VOLUME 3 \n'
				+ '\n'
				+ 'const float cEen = 1.0; \n'
				+ 'const float cEen255ste = cEen / 255.0; \n'
				+ '\n'
				+ 'attribute vec3 aVertexCoor; \n'
				+ 'attribute vec3 aNormaal; \n'
				+ 'attribute vec3 aTangent; \n'
				+ 'attribute vec2 aTextuurCoor; \n'
				+ 'attribute vec4 aKleur; \n'
				+ '\n'
				+ 'uniform mat4 uModelviewMatrix; \n'
				+ 'uniform mat4 uProjectieMatrix; \n'
				+ 'uniform mat3 uRotatieMatrix; \n'
				+ '\n'
				+ 'uniform lowp int uElemType; \n'
				+ '// \n'
				+ '// Ten behoeve van texturing: \n'
				+ '// \n'
				+ 'uniform bool uTableauTexturing; \n'
				+ 'uniform bool uTableauVormTexturing; \n'
				+ '#ifdef MET_BUMPMAP \n'
				+ 'uniform bool uBumpmapTexturing; \n'
				+ 'uniform vec3 uBiTangent; \n'
				+ '#endif \n'
				+ '// \n'
				+ '// Ten behoeve van slagschaduw generatie en sampling: \n'
				+ '// \n'
				+ 'uniform bool uToonSlagschaduw; \n'
				+ 'uniform mat4 uDiepteMatrix; \n'
				+ 'varying vec3 vDiepteCoor; \n'
				+ '// \n'
				+ '// Ten behoeve van markeerpunten: \n'
				+ '// \n'
				+ 'uniform vec3 uElemPositie; \n'
				+ 'uniform float uZoomSchaal; \n'
				+ '// \n'
				+ '// Vertex output: \n'
				+ '// \n'
				+ 'varying vec4 vPositie; \n'
				+ 'varying vec4 vModelviewPositie; \n'
				+ 'varying vec3 vModelviewNormaal; \n'
				+ 'varying vec4 vKleur; \n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '#ifdef MET_BUMPMAP \n'
				+ 'varying mat3 vTangentSpaceRotatieMatrix; \n'
				+ '#endif \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'if (uElemType == VOLUME || uElemType == VLAK) \n'
				+ '{ \n'
				+ 'vPositie = vec4(aVertexCoor, cEen); \n'
				+ 'vModelviewPositie = uModelviewMatrix * vPositie; \n'
				+ 'gl_Position = uProjectieMatrix * vModelviewPositie; \n'
				+ 'vModelviewNormaal = uRotatieMatrix * aNormaal; \n'
				+ '#ifdef MET_BUMPMAP \n'
				+ 'if (uBumpmapTexturing) \n'
				+ '{ \n'
				+ 'vec3 modelviewTangent = uRotatieMatrix * aTangent; \n'
				+ 'vec3 modelviewBiTangent; \n'
				+ 'if (uElemType == VLAK) \n'
				+ '{ \n'
				+ 'modelviewBiTangent = uRotatieMatrix * uBiTangent; \n'
				+ '} \n'
				+ 'else \n'
				+ '{ \n'
				+ 'modelviewBiTangent = cross(vModelviewNormaal, modelviewTangent); \n'
				+ '} \n'
				+ 'vTangentSpaceRotatieMatrix = mat3(modelviewTangent, modelviewBiTangent, vModelviewNormaal); \n'
				+ '} \n'
				+ 'if (uTableauTexturing || uTableauVormTexturing || uBumpmapTexturing) \n'
				+ '#else \n'
				+ 'if (uTableauTexturing || uTableauVormTexturing) \n'
				+ '#endif \n'
				+ '{ \n'
				+ 'vTextuurCoor = aTextuurCoor; \n'
				+ '} \n'
				+ 'if (uToonSlagschaduw) \n'
				+ '{ \n'
				+ 'vDiepteCoor = (uDiepteMatrix * vPositie).xyz; \n'
				+ '} \n'
				+ '} \n'
				+ 'else if (uElemType == LIJN) \n'
				+ '{ \n'
				+ 'gl_Position = uProjectieMatrix * uModelviewMatrix * vec4(aVertexCoor, cEen); \n'
				+ '} \n'
				+ 'else if (uElemType == MARKEERPUNT) \n'
				+ '{ \n'
				+ '// \n'
				+ '// Bepaal het centrum van de marker: \n'
				+ '// \n'
				+ 'vec4 positie = uModelviewMatrix * vec4(uElemPositie, cEen); \n'
				+ '// \n'
				+ '// Voeg de vorm van de marker toe, parallel aan het scherm: \n'
				+ '// \n'
				+ 'positie.xy += aVertexCoor.xy / uZoomSchaal; \n'
				+ '\n'
				+ 'gl_Position = uProjectieMatrix * positie; \n'
				+ '} \n'
				+ '\n'
				+ 'vKleur = aKleur * cEen255ste; // kleurkanalen van bereik [0, 255] naar [0, 1] \n'
				+ '}';
		};
	
		public static fragmentShaderCodeStd(): string {
			//
			// Originele code: return '' + '...' + ... + '...';
			// Dus het returnen van een concatenatie van heel erg veel deelstrings.
			// In JavaScript geen probleem. Maar als het aantal deelstrings te groot is, is het wel een probleem voor TypeScript.
			// Je krijgt bij bouwen dan als error: "tsc.exe" exited with code 1.
			// In de build output (mits verbosity-level op 'detailed' i.p.v. 'minimal') zie je dan de fouten:
			// "Script failed with error: 'JsErrorScriptException (0x30001)'", en "Out of stack space".
			//
			// Zie: https://github.com/Microsoft/TypeScript/issues/26721
			// RyanCavanaugh (Development lead for the TypeScript team at Microsoft.) schrijft op 29-8-2018:
			// Just to run through a checklist of things that are commonly a problem, do you have any long-chained expressions like 1 + 2 + 3 + 4 + 5 + 6 + 7 + 8 ... in your code? 
			// Very "deep" trees like this can cause problems in the emitter because we're recursively stepping in to the binary tree that represents the syntax.
			//
			// Daarom heb ik hieronder de set van deelstrings in twee stukken gedeeld.
			//
			// In alle andere shader-code functies treedt dit probleem niet op.
			// Daar is de set van deelstrings kleiner. Blijkbaar klein geneog om geen probleem op te leveren.
			// Dus alleen in de huidige functie heb ik deze aanpassing moeten maken.
			//
			//@@@Q1 return ''
			let deel1: string = ''
				+ '#ifdef GL_FRAGMENT_PRECISION_HIGH \n'
				+ 'precision highp float; \n'
				+ '#else \n'
				+ 'precision mediump float; \n'
				+ '#endif \n'
				+ '\n'
				+ '#define MARKEERPUNT 0 \n'
				+ '#define LIJN 1 \n'
				+ '#define VLAK 2 \n'
				+ '#define VOLUME 3 \n'
				+ '\n'
				+ 'const float cNul = 0.0; \n'
				+ 'const float cKwart = 0.25; \n'
				+ 'const float cDrieKwart = 0.75; \n'
				+ 'const float cEen = 1.0; \n'
				+ 'const float cTwee = 2.0; \n'
				+ 'const int ciNul = 0; \n'
				+ 'const int ciEen = 1; \n'
				+ 'const int ciTwee = 2; \n'
				+ 'const int ciDrie = 3; \n'
				+ 'const int ciVier = 4; \n'
				+ 'const int ciAcht = 8; \n'
				+ '#ifdef MET_LAMPEN \n'
				+ 'const int ciMaxAantalLampen = 5; \n'
				+ '\n'
				+ 'uniform mat4 uModelviewMatrixFS; \n'
				+ '#endif \n'
				+ 'uniform mat3 uRotatieMatrixFS; \n'
				+ '\n'
				+ 'uniform lowp int uElemType; \n'
				+ '// \n'
				+ '// Ten behoeve van texturing: \n'
				+ '// \n'
				+ 'uniform bool uTableauTexturing; \n'
				+ 'uniform bool uTableauVormTexturing; \n'
				+ 'uniform sampler2D uTableauSampler; \n'
				+ '#ifdef MET_BUMPMAP \n'
				+ 'uniform bool uBumpmapTexturing; \n'
				+ 'uniform sampler2D uBumpmapSampler; \n'
				+ '#endif \n'
				+ 'uniform vec4 uMengKleur; \n'
				+ '// \n'
				+ '// Ten behoeve van belichting: \n'
				+ '// \n'
				+ 'uniform bool uVloedlicht; \n'
				+ 'uniform vec4 uLichtval; \n'
				+ 'uniform float uOmgevingLichtNiveau; \n'
				+ 'uniform float uDiffuusLichtNiveau; \n'
				+ 'uniform float uSchitteringLichtNiveau; \n'
				+ 'uniform float uSchitteringMateriaal; \n'
				+ 'uniform float uSchitteringMateriaalMacht; \n'
				+ 'uniform bool uToonSlagschaduw; \n'
				+ 'uniform float uSlagschaduwVerzadiging; \n'
				+ 'uniform sampler2D uDiepteSampler; \n'
				+ 'varying vec3 vDiepteCoor; \n'
				+ '#ifdef MET_LAMPEN \n'
				+ '// \n'
				+ '// Ten behoeve van kunstlichtbronnen: \n'
				+ '// \n'
				+ 'struct LampStruct \n'
				+ '{ \n'
				+ 'vec4 positie; \n'
				+ 'vec3 richting; \n'
				+ 'float cosBuitenHoek; \n'
				+ 'float cosBinnenHoek; \n'
				+ 'vec3 kleur; \n'
				+ 'float diffuusLichtNiveau; \n'
				+ 'float schitteringLichtNiveau; \n'
				+ 'mat4 diepteMatrix; \n'
				+ '}; \n'
				+ 'uniform LampStruct uLamp[ciMaxAantalLampen]; \n'
				+ 'uniform sampler2D uLampDiepteSampler[ciMaxAantalLampen]; \n'
				+ 'uniform float uLichtAttenuatieAfstand; \n'
				+ '#endif \n'
				+ '// \n'
				+ '// Vertex input: \n'
				+ '// \n'
				+ '#ifdef MET_LAMPEN \n'
				+ 'varying vec4 vPositie; \n'
				+ '#endif \n'
				+ 'varying vec4 vModelviewPositie; \n'
				+ 'varying vec3 vModelviewNormaal; \n'
				+ 'varying vec4 vKleur; \n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '#ifdef MET_BUMPMAP \n'
				+ 'varying mat3 vTangentSpaceRotatieMatrix; \n'
				+ '#endif \n'
				+ '\n'
				+ '\n'
				+ '#ifdef MET_DEPTHPACKING \n'
				+ 'float unpackFloat(in vec4 value) \n'
				+ '{ \n'
				+ 'const vec4 bitUnshift = vec4(cEen, cEen / 255.0, cEen / 65025.0, cEen / 16581375.0); \n'
				+ 'return dot(value, bitUnshift); \n'
				+ '} \n'
				+ '#endif \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'if (uElemType == VOLUME || uElemType == VLAK) \n'
				+ '{ \n'
				+ 'vec4 fragmentKleur; \n'
				+ 'if (uTableauTexturing || uTableauVormTexturing) \n'
				+ '{ \n'
				+ 'fragmentKleur = texture2D(uTableauSampler, vTextuurCoor); \n'
				+ 'if (fragmentKleur.a < cDrieKwart) \n'
				+ '{ \n'
				+ '// \n'
				+ '// NB: Alleen transparante texturen waarbij de pixels ofwel opaak, ofwel \n'
				+ '// (quasi-)onzichtbaar zijn worden ondersteund; correcte weergave bij andere \n'
				+ '// situaties worden niet gegarandeerd. Een limiet op de alpha-waarde hoger dan \n'
				+ '// nul voorkomt aura-effecten. \n'
				+ '// \n'
				+ 'discard; \n'
				+ '} \n'
				+ 'if (uTableauTexturing) \n'
				+ '{ \n'
				+ 'if (uMengKleur.a > cNul) // kleurkanalen zijn in bereik [0, 1] \n'
				+ '{ \n'
				+ 'fragmentKleur.rgb = mix(fragmentKleur.rgb, uMengKleur.rgb, uMengKleur.a); \n'
				+ '} \n'
				+ '} \n'
				+ 'else \n'
				+ '{ \n'
				+ 'fragmentKleur = vKleur; \n'
				+ '} \n'
				+ '} \n'
				+ '#ifdef MET_BUMPMAP \n'
				+ 'else if (uBumpmapTexturing) \n'
				+ '{ \n'
				+ 'fragmentKleur = vec4(uMengKleur.rgb, cEen); \n'
				+ '} \n'
				+ '#endif \n'
				+ 'else \n'
				+ '{ \n'
				+ 'fragmentKleur = vKleur; \n'
				+ '} \n'
				+ '\n'
				+ 'if (!uVloedlicht) \n'
				+ '{ \n'
				+ 'vec3 normaal = vModelviewNormaal; \n'
				+ '#ifdef MET_BUMPMAP \n'
				+ 'if (uBumpmapTexturing) \n'
				+ '{ \n'
				+ 'vec3 bumpVector = texture2D(uBumpmapSampler, vTextuurCoor).rgb; \n'
				+ 'bumpVector = normalize((bumpVector * cTwee) - cEen); \n'
				+ 'normaal = vTangentSpaceRotatieMatrix * bumpVector; \n'
				+ '} \n'
				+ '#endif \n'
				+ 'if (uElemType == VLAK && gl_FrontFacing == false) \n'
				+ '{ \n'
				+ '// \n'
				+ '// De vlaknormaal is afgeleid van de winding van de getesseleerde vertices, \n'
				+ '// waarbij counterclockwise vertices front-facing zijn. De werkelijke voorkant \n'
				+ '// van het vlak-element is echter willekeurig. Daarom moeten beide zijden getekend \n'
				+ '// worden (hetgeen bereikt wordt door back-face culling uit te schakelen voor \n'
				+ '// vlak-elementen). Bij back-facing fragments moet de normaal "omgeklapt" worden: \n'
				+ '// \n'
				+ '#ifdef MET_BUMPMAP \n'
				+ 'if (uBumpmapTexturing) \n'
				+ '{ \n'
				+ 'normaal = reflect(normaal, vModelviewNormaal); \n'
				+ '} \n'
				+ 'else \n'
				+ '{ \n'
				+ 'normaal = -normaal; \n'
				+ '} \n'
				+ '#else \n'
				+ 'normaal = -normaal; \n'
				+ '#endif \n'
				+ '// \n'
				+ '// NB: Fragments van volume-elementen zijn altijd front-facing als gevolg van \n'
				+ '// back-face culling. \n'
				+ '// \n'
				+ '} \n'
				+ '\n'
				+ 'vec3 bronRichting = uLichtval.xyz; \n'
				+ 'if (uLichtval.w < cEen) \n'
				+ '{ \n'
				+ '// \n'
				+ '// Het betreft een modellichtbron (in tegenstelling tot een cameralichtbron): \n'
				+ '// \n'
				+ 'bronRichting = uRotatieMatrixFS * bronRichting; \n'
				+ '} \n'
				+ 'float cosInvalshoek = dot(bronRichting, normaal); \n'
				+ '// \n'
				+ '// Bij stompe hoeken is het fragment van het licht afgekeerd: \n'
				+ '// \n'
				+ 'if (fragmentKleur.a < cKwart) \n'
				+ '{ \n'
				+ '// \n'
				+ '// Sterk transparante fragmenten kunnen echter "van achteren" belicht worden: \n'
				+ '// \n'
				+ 'cosInvalshoek = abs(cosInvalshoek); \n'
				+ '} \n'
				+ 'else \n'
				+ '{ \n'
				+ 'cosInvalshoek = max(cosInvalshoek, cNul); \n'
				+ '} \n'
				+ '\n'
				+ 'float diffuusLichtGewicht = cosInvalshoek; \n'
				+ '\n'
				+ 'vec3 oogRichting = normalize(vModelviewPositie.xyz); \n'
				+ 'vec3 reflectieRichting = reflect(bronRichting, normaal); \n'
				+ 'float schitteringLichtGewicht = max(dot(reflectieRichting, oogRichting), cNul); \n'
				+ 'schitteringLichtGewicht = pow(schitteringLichtGewicht, uSchitteringMateriaalMacht); \n'
				+ '\n';
			let deel2: string = ''
				+ '#ifdef MET_LAMPEN \n'
				+ 'vec2 poissonDisk[8]; \n'
				+ 'poissonDisk[0] = vec2(0.8405113, 0.3099893); \n'
				+ 'poissonDisk[1] = vec2(-0.9670954, -0.1311434); \n'
				+ 'poissonDisk[2] = vec2(0.4209824, 0.8564497); \n'
				+ 'poissonDisk[3] = vec2(-0.3023786, -0.9522050); \n'
				+ 'poissonDisk[4] = vec2(0.7540891, -0.1761894); \n'
				+ 'poissonDisk[5] = vec2(0.5869891, -0.7516982); \n'
				+ 'poissonDisk[6] = vec2(0.04525682, -0.4295722); \n'
				+ 'poissonDisk[7] = vec2(0.1102269, 0.03456858); \n'
				+ '#endif \n'
				+ '\n'
				+ 'float totaalLichtGewicht = cEen; \n'
				+ 'if (uToonSlagschaduw) \n'
				+ '{ \n'
				+ 'if (cosInvalshoek > cNul) \n'
				+ '{ \n'
				+ '#ifndef MET_LAMPEN \n'
				+ 'vec2 poissonDisk[8]; \n'
				+ 'poissonDisk[0] = vec2(0.8405113, 0.3099893); \n'
				+ 'poissonDisk[1] = vec2(-0.9670954, -0.1311434); \n'
				+ 'poissonDisk[2] = vec2(0.4209824, 0.8564497); \n'
				+ 'poissonDisk[3] = vec2(-0.3023786, -0.9522050); \n'
				+ 'poissonDisk[4] = vec2(0.7540891, -0.1761894); \n'
				+ 'poissonDisk[5] = vec2(0.5869891, -0.7516982); \n'
				+ 'poissonDisk[6] = vec2(0.04525682, -0.4295722); \n'
				+ 'poissonDisk[7] = vec2(0.1102269, 0.03456858); \n'
				+ '#endif \n'
				+ '\n'
				+ 'float bias = 0.001 * tan(acos(cosInvalshoek)); \n'
				+ 'float lichtGewichtAfname = uSlagschaduwVerzadiging / float(ciAcht); \n'
				+ 'lowp int aantalBeschaduwdeSamples = ciNul; \n'
				+ 'for (lowp int i = ciNul; i < ciAcht; i++) \n'
				+ '{ \n'
				+ 'vec4 diepteAlsVec = texture2D(uDiepteSampler, \n'
				+ 'vDiepteCoor.xy + poissonDisk[i] / 1000.0); \n'
				+ '#ifdef MET_DEPTHPACKING \n'
				+ 'float diepte = unpackFloat(diepteAlsVec); \n'
				+ '#else \n'
				+ 'float diepte = diepteAlsVec.x; \n'
				+ '#endif \n'
				+ 'if (diepte < vDiepteCoor.z - bias) \n'
				+ '{ \n'
				+ 'totaalLichtGewicht -= lichtGewichtAfname; \n'
				+ 'aantalBeschaduwdeSamples++; \n'
				+ '} \n'
				+ 'if (i == ciDrie) \n'
				+ '{ \n'
				+ 'if (aantalBeschaduwdeSamples == ciNul) \n'
				+ '{ \n'
				+ 'break; \n'
				+ '} \n'
				+ 'else if (aantalBeschaduwdeSamples == ciVier) \n'
				+ '{ \n'
				+ 'totaalLichtGewicht = cEen - uSlagschaduwVerzadiging; \n'
				+ 'break; \n'
				+ '} \n'
				+ '} \n'
				+ '} \n'
				+ '} \n'
				+ '} \n'
				+ '\n'
				+ 'float lichtNiveau = uOmgevingLichtNiveau + totaalLichtGewicht * \n'
				+ '(uDiffuusLichtNiveau * diffuusLichtGewicht + \n'
				+ 'uSchitteringLichtNiveau * uSchitteringMateriaal * schitteringLichtGewicht); \n'
				+ 'fragmentKleur.rgb = fragmentKleur.rgb * lichtNiveau; \n'
				+ '\n'
				+ '#ifdef MET_LAMPEN \n'
				+ 'if (uLichtAttenuatieAfstand > cNul) \n'
				+ '{ \n'
				+ 'vec3 lampenLicht = vec3(cNul, cNul, cNul); \n'
				+ 'for (lowp int i = ciNul; i < ciMaxAantalLampen; i++) \n'
				+ '{ \n'
				+ 'LampStruct lamp = uLamp[i]; \n'
				+ 'if (lamp.cosBuitenHoek > cEen) \n'
				+ '{ \n'
				+ '// \n'
				+ '// De lamp staat uit: \n'
				+ '// \n'
				+ 'continue; \n'
				+ '} \n'
				+ 'vec3 bronRichting = ((uModelviewMatrixFS * lamp.positie) - vModelviewPositie).xyz; \n'
				+ 'float afstand = length(bronRichting); \n'
				+ 'bronRichting = normalize(bronRichting); \n'
				+ '\n'
				+ 'cosInvalshoek = dot(bronRichting, normaal); \n'
				+ '// \n'
				+ '// Bij stompe hoeken is het fragment van het licht afgekeerd: \n'
				+ '// \n'
				+ 'if (fragmentKleur.a < cKwart) \n'
				+ '{ \n'
				+ '// \n'
				+ '// Sterk transparante fragmenten kunnen echter "van achteren" belicht worden: \n'
				+ '// \n'
				+ 'cosInvalshoek = abs(cosInvalshoek); \n'
				+ '} \n'
				+ 'else \n'
				+ '{ \n'
				+ 'cosInvalshoek = max(cosInvalshoek, cNul); \n'
				+ '} \n'
				+ 'diffuusLichtGewicht = cosInvalshoek; \n'
				+ '\n'
				+ 'vec3 reflectieRichting = reflect(bronRichting, normaal); \n'
				+ 'float schitteringLichtGewicht = max(dot(reflectieRichting, oogRichting), cNul); \n'
				+ 'schitteringLichtGewicht = pow(schitteringLichtGewicht, uSchitteringMateriaalMacht); \n'
				+ '\n'
				+ 'float lampLichtGewicht = dot(-bronRichting, uRotatieMatrixFS * lamp.richting); \n'
				+ 'if (lampLichtGewicht < lamp.cosBuitenHoek) \n'
				+ '{ \n'
				+ '// \n'
				+ '// Buiten de invloedssfeer van de lamp: \n'
				+ '// \n'
				+ 'continue; \n'
				+ '} \n'
				+ 'else if (lampLichtGewicht >= lamp.cosBinnenHoek) \n'
				+ '{ \n'
				+ 'lampLichtGewicht = cEen; \n'
				+ '} \n'
				+ 'else \n'
				+ '{ \n'
				+ 'lampLichtGewicht = (lampLichtGewicht - lamp.cosBuitenHoek) /  \n'
				+ '(lamp.cosBinnenHoek - lamp.cosBuitenHoek); \n'
				+ '} \n'
				+ 'float attenuatie = cEen + afstand / uLichtAttenuatieAfstand; \n'
				+ 'attenuatie = cEen / (attenuatie * attenuatie); \n'
				+ 'if (attenuatie < 0.004) \n'
				+ '{ \n'
				+ '// \n'
				+ '// Lamplicht draagt minder dan 1/255 kleurverschil bij (zonder overbelichting) \n'
				+ '// voorbij deze afstand, dus buiten de invloedssfeer van de lamp: \n'
				+ '// \n'
				+ 'continue; \n'
				+ '} \n'
				+ 'lampLichtGewicht = lampLichtGewicht * attenuatie; \n'
				+ '\n'
				+ 'if (cosInvalshoek > cNul) \n'
				+ '{ \n'
				+ 'vec4 diepteCoor = lamp.diepteMatrix * vPositie; \n'
				+ 'diepteCoor.xyz = diepteCoor.xyz / diepteCoor.w; \n'
				+ 'float bias = 0.0001 * tan(acos(cosInvalshoek)); \n'
				+ '\n'
				+ 'totaalLichtGewicht = cEen; \n'
				+ 'float lichtGewichtAfname = cEen / float(ciAcht); \n'
				+ 'lowp int aantalBeschaduwdeSamples = ciNul; \n'
				+ 'for (lowp int j = ciNul; j < ciAcht; j++) \n'
				+ '{ \n'
				+ 'vec2 coor = diepteCoor.xy + poissonDisk[j] / 1000.0; \n'
				+ 'vec4 diepteAlsVec; \n'
				+ 'if (i == ciNul) \n'
				+ '{ \n'
				+ 'diepteAlsVec = texture2D(uLampDiepteSampler[ciNul], coor); \n'
				+ '} \n'
				+ 'else if (i == ciEen) \n'
				+ '{ \n'
				+ 'diepteAlsVec = texture2D(uLampDiepteSampler[ciEen], coor); \n'
				+ '} \n'
				+ 'else if (i == ciTwee) \n'
				+ '{ \n'
				+ 'diepteAlsVec = texture2D(uLampDiepteSampler[ciTwee], coor); \n'
				+ '} \n'
				+ 'else if (i == ciDrie) \n'
				+ '{ \n'
				+ 'diepteAlsVec = texture2D(uLampDiepteSampler[ciDrie], coor); \n'
				+ '} \n'
				+ 'else \n'
				+ '{ \n'
				+ 'diepteAlsVec = texture2D(uLampDiepteSampler[ciVier], coor); \n'
				+ '} \n'
				+ '#ifdef MET_DEPTHPACKING \n'
				+ 'float diepte = unpackFloat(diepteAlsVec); \n'
				+ '#else \n'
				+ 'float diepte = diepteAlsVec.x; \n'
				+ '#endif \n'
				+ 'if (diepte < diepteCoor.z - bias) \n'
				+ '{ \n'
				+ 'totaalLichtGewicht -= lichtGewichtAfname; \n'
				+ 'aantalBeschaduwdeSamples++; \n'
				+ '} \n'
				+ 'if (j == ciDrie) \n'
				+ '{ \n'
				+ 'if (aantalBeschaduwdeSamples == ciNul) \n'
				+ '{ \n'
				+ 'break; \n'
				+ '} \n'
				+ 'else if (aantalBeschaduwdeSamples == ciVier) \n'
				+ '{ \n'
				+ 'totaalLichtGewicht = cNul; \n'
				+ 'break; \n'
				+ '} \n'
				+ '} \n'
				+ '} \n'
				+ 'lampLichtGewicht = lampLichtGewicht * totaalLichtGewicht; \n'
				+ '} \n'
				+ '\n'
				+ 'vec3 lampLicht = lamp.kleur * (lampLichtGewicht *  \n'
				+ '(lamp.diffuusLichtNiveau * diffuusLichtGewicht +  \n'
				+ 'lamp.schitteringLichtNiveau * uSchitteringMateriaal * schitteringLichtGewicht)); \n'
				+ 'lampenLicht = lampenLicht + lampLicht; \n'
				+ '} \n'
				+ 'fragmentKleur.rgb = fragmentKleur.rgb + lampenLicht; \n'
				+ '} \n'
				+ '#endif \n'
				+ '} \n'
				+ 'gl_FragColor = fragmentKleur; \n'
				+ '} \n'
				+ 'else if (uElemType == LIJN || uElemType == MARKEERPUNT) \n'
				+ '{ \n'
				+ 'gl_FragColor = vKleur; \n'
				+ '} \n'
				+ '}';
			return deel1 + deel2;
		};
	
		public static vertexShaderCodePicking(): string {
			return ''
				+ '#define MARKEERPUNT 0 \n'
				+ '#define LIJN 1 \n'
				+ '#define VLAK 2 \n'
				+ '#define VOLUME 3 \n'
				+ '\n'
				+ 'const float cEen = 1.0; \n'
				+ 'const float cEen255ste = cEen / 255.0; \n'
				+ '\n'
				+ 'attribute vec3 aVertexCoor; \n'
				+ 'attribute vec2 aTextuurCoor; \n'
				+ 'attribute vec4 aKleur; \n'
				+ '\n'
				+ 'uniform mat4 uModelviewMatrix; \n'
				+ 'uniform mat4 uProjectieMatrix; \n'
				+ '\n'
				+ 'uniform lowp int uElemType; \n'
				+ '// \n'
				+ '// Ten behoeve van texturing: \n'
				+ '// \n'
				+ 'uniform bool uTableauVormTexturing; \n'
				+ '// \n'
				+ '// Ten behoeve van markeerpunten: \n'
				+ '// \n'
				+ 'uniform vec3 uElemPositie; \n'
				+ 'uniform float uZoomSchaal; \n'
				+ '// \n'
				+ '// Vertex output: \n'
				+ '// \n'
				+ 'varying vec4 vKleur; \n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'if (uElemType == VOLUME || uElemType == VLAK) \n'
				+ '{ \n'
				+ 'gl_Position = uProjectieMatrix * uModelviewMatrix * vec4(aVertexCoor, cEen); \n'
				+ 'if (uTableauVormTexturing) \n'
				+ '{ \n'
				+ 'vTextuurCoor = aTextuurCoor; \n'
				+ '} \n'
				+ '} \n'
				+ 'else if (uElemType == LIJN) \n'
				+ '{ \n'
				+ 'gl_Position = uProjectieMatrix * uModelviewMatrix * vec4(aVertexCoor, cEen); \n'
				+ '} \n'
				+ 'else if (uElemType == MARKEERPUNT) \n'
				+ '{ \n'
				+ '// \n'
				+ '// Bepaal het centrum van de marker: \n'
				+ '// \n'
				+ 'vec4 positie = uModelviewMatrix * vec4(uElemPositie, cEen); \n'
				+ '// \n'
				+ '// Voeg de vorm van de marker toe, parallel aan het scherm: \n'
				+ '// \n'
				+ 'positie.xy += aVertexCoor.xy / uZoomSchaal; \n'
				+ '\n'
				+ 'gl_Position = uProjectieMatrix * positie; \n'
				+ '} \n'
				+ '\n'
				+ 'vKleur = aKleur * cEen255ste; // kleurkanalen van bereik [0, 255] naar [0, 1] \n'
				+ '}';
		};
	
		public static fragmentShaderCodePicking(): string {
			return ''
				+ '#ifdef GL_FRAGMENT_PRECISION_HIGH \n'
				+ 'precision highp float; \n'
				+ '#else \n'
				+ 'precision mediump float; \n'
				+ '#endif \n'
				+ '\n'
				+ 'const float cDrieKwart = 0.75; \n'
				+ '// \n'
				+ '// Ten behoeve van texturing: \n'
				+ '// \n'
				+ 'uniform bool uTableauVormTexturing; \n'
				+ 'uniform sampler2D uTableauSampler; \n'
				+ '// \n'
				+ '// Vertex input: \n'
				+ '// \n'
				+ 'varying vec4 vKleur; \n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'if (uTableauVormTexturing) \n'
				+ '{ \n'
				+ 'if (texture2D(uTableauSampler, vTextuurCoor).a < cDrieKwart) \n'
				+ '{ \n'
				+ 'discard; \n'
				+ '} \n'
				+ '} \n'
				+ 'gl_FragColor = vKleur; \n'
				+ '}';
		};
	
		public static vertexShaderCodeDepthMapping(): string {
			return ''
				+ 'const float cEen = 1.0; \n'
				+ '\n'
				+ 'attribute vec3 aVertexCoor; \n'
				+ 'attribute vec2 aTextuurCoor; \n'
				+ '\n'
				+ 'uniform mat4 uModelviewMatrix; \n'
				+ 'uniform mat4 uProjectieMatrix; \n'
				+ '// \n'
				+ '// Ten behoeve van texturing: \n'
				+ '// \n'
				+ 'uniform bool uTableauVormTexturing; \n'
				+ '// \n'
				+ '// Vertex output: \n'
				+ '// \n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'gl_Position = uProjectieMatrix * uModelviewMatrix * vec4(aVertexCoor, cEen); \n'
				+ 'if (uTableauVormTexturing) \n'
				+ '{ \n'
				+ 'vTextuurCoor = aTextuurCoor; \n'
				+ '} \n'
				+ '}';
		};
	
		public static fragmentShaderCodeDepthMapping(): string {
			return ''
				+ '#ifdef GL_FRAGMENT_PRECISION_HIGH \n'
				+ 'precision highp float; \n'
				+ '#else \n'
				+ 'precision mediump float; \n'
				+ '#endif \n'
				+ '\n'
				+ '#ifdef MET_DEPTHPACKING \n'
				+ 'const float cEen255ste = 1.0 / 255.0; \n'
				+ '#endif \n'
				+ 'const float cDrieKwart = 0.75; \n'
				+ '// \n'
				+ '// Ten behoeve van texturing: \n'
				+ '// \n'
				+ 'uniform bool uTableauVormTexturing; \n'
				+ 'uniform sampler2D uTableauSampler; \n'
				+ '// \n'
				+ '// Vertex input: \n'
				+ '// \n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ '\n'
				+ '#ifdef MET_DEPTHPACKING \n'
				+ 'vec4 packFloat(in float value) \n'
				+ '{ \n'
				+ 'const vec4 bitShift = vec4(1.0, 255.0, 65025.0, 16581375.0); \n'
				+ 'const vec4 bitMask = vec4(cEen255ste, cEen255ste, cEen255ste, 0.0); \n'
				+ 'vec4 result = fract(value * bitShift); \n'
				+ 'result -= result.yzww * bitMask; \n'
				+ 'return result; \n'
				+ '} \n'
				+ '#endif \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'if (uTableauVormTexturing) \n'
				+ '{ \n'
				+ 'if (texture2D(uTableauSampler, vTextuurCoor).a < cDrieKwart) \n'
				+ '{ \n'
				+ 'discard; \n'
				+ '} \n'
				+ '} \n'
				+ '#ifdef MET_DEPTHPACKING \n'
				+ 'gl_FragColor = packFloat(gl_FragCoord.z); \n'
				+ '#endif \n'
				+ '}';
		};
	
		public static vertexShaderCodeTextureCopy(): string {
			return ''
				+ 'attribute vec2 aVertexCoor; \n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'gl_Position = vec4(aVertexCoor, 0.0, 1.0); \n'
				+ 'vTextuurCoor = aVertexCoor.xy * 0.5 + 0.5; \n'
				+ '}';
		};
	
		public static fragmentShaderCodeTextureCopy(): string {
			return ''
				+ '#ifdef GL_FRAGMENT_PRECISION_HIGH \n'
				+ 'precision highp float; \n'
				+ '#else \n'
				+ 'precision mediump float; \n'
				+ '#endif \n'
				+ '\n'
				+ 'uniform sampler2D uSampler; \n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'gl_FragColor = vec4(texture2D(uSampler, vTextuurCoor).rgb, 1.0); \n'
				+ '}';
		};
	
		public static vertexShaderCodeFXAA(): string {
			return ''
				+ 'attribute vec2 aVertexCoor; \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'gl_Position = vec4(aVertexCoor, 0.0, 1.0); \n'
				+ '}';
		};
	
		public static fragmentShaderCodeFXAA(): string {
			//
			// NVIDIA FXAA door Timothy Lottes 2009
			// WebGL port door @supereggbert
			//
			return ''
				+ '#ifdef GL_FRAGMENT_PRECISION_HIGH \n'
				+ 'precision highp float; \n'
				+ '#else \n'
				+ 'precision mediump float; \n'
				+ '#endif \n'
				+ '\n'
				+ '#define FXAA_REDUCE_MIN (1.0/128.0) \n'
				+ '#define FXAA_REDUCE_MUL (1.0/8.0) \n'
				+ '#define FXAA_SPAN_MAX 8.0 \n'
				+ '\n'
				+ 'uniform sampler2D uSampler; \n'
				+ 'uniform vec2 uInvResolutie; \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'vec3 rgbNW = texture2D(uSampler, (gl_FragCoord.xy + vec2(-1.0, -1.0)) * uInvResolutie).rgb; \n'
				+ 'vec3 rgbNE = texture2D(uSampler, (gl_FragCoord.xy + vec2(1.0, -1.0)) * uInvResolutie).rgb; \n'
				+ 'vec3 rgbSW = texture2D(uSampler, (gl_FragCoord.xy + vec2(-1.0, 1.0)) * uInvResolutie).rgb; \n'
				+ 'vec3 rgbSE = texture2D(uSampler, (gl_FragCoord.xy + vec2(1.0, 1.0)) * uInvResolutie).rgb; \n'
				+ 'vec3 rgbM = texture2D(uSampler,  gl_FragCoord.xy * uInvResolutie).rgb; \n'
				+ 'vec3 lumaCoef = vec3(0.299, 0.587, 0.114); // volgens CCIR 601 \n'
				+ '\n'
				+ 'float lumaNW = dot(rgbNW, lumaCoef); \n'
				+ 'float lumaNE = dot(rgbNE, lumaCoef); \n'
				+ 'float lumaSW = dot(rgbSW, lumaCoef); \n'
				+ 'float lumaSE = dot(rgbSE, lumaCoef); \n'
				+ 'float lumaM  = dot(rgbM,  lumaCoef); \n'
				+ 'float lumaMin = min(lumaM, min(min(lumaNW, lumaNE), min(lumaSW, lumaSE))); \n'
				+ 'float lumaMax = max(lumaM, max(max(lumaNW, lumaNE), max(lumaSW, lumaSE))); \n'
				+ '\n'
				+ 'vec2 dir; \n'
				+ 'dir.x = -((lumaNW + lumaNE) - (lumaSW + lumaSE)); \n'
				+ 'dir.y =  ((lumaNW + lumaSW) - (lumaNE + lumaSE)); \n'
				+ '\n'
				+ 'float dirReduce = max((lumaNW + lumaNE + lumaSW + lumaSE) * (0.25 * FXAA_REDUCE_MUL), '
				+ 'FXAA_REDUCE_MIN); \n'
				+ '\n'
				+ 'float rcpDirMin = 1.0 / (min(abs(dir.x), abs(dir.y)) + dirReduce); \n'
				+ 'dir = min(vec2(FXAA_SPAN_MAX, FXAA_SPAN_MAX), '
				+ 'max(vec2(-FXAA_SPAN_MAX, -FXAA_SPAN_MAX), dir * rcpDirMin)) * uInvResolutie; \n'
				+ 'vec3 rgbA = (1.0/2.0) * '
				+ '(texture2D(uSampler, gl_FragCoord.xy * uInvResolutie + dir * (1.0/3.0 - 0.5)).rgb + '
				+ 'texture2D(uSampler, gl_FragCoord.xy * uInvResolutie + dir * (2.0/3.0 - 0.5)).rgb); \n'
				+ 'vec3 rgbB = rgbA * (1.0/2.0) + (1.0/4.0) * '
				+ '(texture2D(uSampler, gl_FragCoord.xy * uInvResolutie + dir * (0.0/3.0 - 0.5)).rgb + '
				+ 'texture2D(uSampler, gl_FragCoord.xy * uInvResolutie + dir * (3.0/3.0 - 0.5)).rgb); \n'
				+ 'float lumaB = dot(rgbB, lumaCoef); \n'
				+ '\n'
				+ 'if (lumaB < lumaMin || lumaB > lumaMax) \n'
				+ '{ \n'
				+ 'gl_FragColor = vec4(rgbA, 1.0); \n'
				+ '} \n'
				+ 'else \n'
				+ '{ \n'
				+ 'gl_FragColor = vec4(rgbB, 1.0); \n'
				+ '} \n'
				+ '}';
		};
	
		public static vertexShaderCodeBokeh(): string {
			return ''
				+ 'attribute vec2 aVertexCoor; \n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'gl_Position = vec4(aVertexCoor, 0.0, 1.0); \n'
				+ 'vTextuurCoor = aVertexCoor.xy * 0.5 + 0.5; \n'
				+ '}';
		};
	
		public static fragmentShaderCodeBokeh(): string {
			return ''
				+ '#ifdef GL_FRAGMENT_PRECISION_HIGH \n'
				+ 'precision highp float; \n'
				+ '#else \n'
				+ 'precision mediump float; \n'
				+ '#endif \n'
				+ '\n'
				+ 'uniform sampler2D uTableauSampler; \n'
				+ 'uniform sampler2D uDiepteSampler; \n'
				+ 'uniform vec2 uInvResolutie; \n'
				+ '\n'
				+ 'uniform float uNear; \n'
				+ 'uniform float uFar; \n'
				+ 'uniform float uFocusDiepte; \n'
				+ 'uniform bool uAutoFocus; \n'
				+ '\n'
				+ 'uniform float uCoCCurve[6]; \n'
				+ 'uniform float uWaasSterkte; \n'
				+ 'uniform float uRuissSterkte; \n'
				+ '\n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ '\n'
				+ 'float random(in vec2 coor, in float seed) \n'
				+ '{ \n'
				+ '// Een "canonical" hash-functie in bereik [0, 1>, afgeleid van oorspronkelijk werk door W.J.J Rey, 1998. \n'
				+ 'return fract(sin(dot(coor, vec2(12.9898, 78.233) * seed)) * 43758.5453); \n'
				+ '} \n'
				+ '\n'
				+ 'float lineaireModelviewDiepte(in float diepte) \n'
				+ '{ \n'
				+ '// Non-lineaire input-diepte in bereik [0, 1]; lineaire output-diepte in bereik [near, far]. \n'
				+ 'return (2.0 * uNear * uFar) / (uFar + uNear - (2.0 * diepte - 1.0) * (uFar - uNear)); \n'
				+ '} \n'
				+ '\n'
				+ 'float bepaalCoCRadius(in float diepte, in float focusDiepte) \n'
				+ '{ \n'
				+ '// Bepaalt de straal van de verstrooiingscirkel (i.e. circle of confusion) aan de hand van \n'
				+ '// een discontinue curve vastgelegd door parameters. De CoC-radius is in bereik [0, 1]. \n'
				+ '// Param 0 = afstand tussen scherptediepte en maximale nabije bokeh, in mm. \n'
				+ '// Param 1 = scherptediepte (ook wel depth-of-field, ook wel focus range), gecentreerd op de focusdiepte, in mm. \n'
				+ '// Param 2 = afstand tussen scherptediepte en maximale verre bokeh, in mm. \n'
				+ '// Param 3 = maximale CoC-radius van de nabije bokeh. \n'
				+ '// Param 4 = CoC-radius in de scherptediepte. \n'
				+ '// Param 5 = maximale CoC-radius van de verre bokeh. \n'
				+ 'float absDiepteDiff = abs(diepte - focusDiepte); \n'
				+ 'float halfDoF = 0.5 * uCoCCurve[1]; \n'
				+ 'float radiusCoC; \n'
				+ 'if (absDiepteDiff <= halfDoF) // dan binnen scherptediepte \n'
				+ '{ \n'
				+ 'radiusCoC = uCoCCurve[4]; \n'
				+ '} \n'
				+ 'else \n'
				+ '{ \n'
				+ 'absDiepteDiff = absDiepteDiff - halfDoF; \n'
				+ 'float topDiepte, topCoC; \n'
				+ 'if (diepte < focusDiepte) // dan nabije bokeh \n'
				+ '{ \n'
				+ 'topDiepte = uCoCCurve[0]; \n'
				+ 'topCoC = uCoCCurve[3]; \n'
				+ '} \n'
				+ 'else // dan verre bokeh \n'
				+ '{ \n'
				+ 'topDiepte = uCoCCurve[2]; \n'
				+ 'topCoC = uCoCCurve[5]; \n'
				+ '} \n'
				+ 'radiusCoC = smoothstep(0.0, topDiepte, absDiepteDiff) * (topCoC - uCoCCurve[4]) + uCoCCurve[4]; \n'
				+ '} \n'
				+ 'return radiusCoC; \n'
				+ '} \n'
				+ '\n'
				+ 'vec3 gaussianBlur(in float sigma, in vec2 ruis) \n'
				+ '{ \n'
				+ '// Gaussian blur op basis van de gaussverdeling: \n'
				+ '// G(x) = 1/(sigma*sqrt(2*pi)) * exp(-(x*x)/(2*sigma*sigma)) \n'
				+ 'vec2 pos = vTextuurCoor + ruis * uInvResolutie; \n'
				+ 'if (sigma < 0.25) // dan is er een piek smaller dan een pixel \n'
				+ '{ \n'
				+ 'return texture2D(uTableauSampler, pos).rgb; \n'
				+ '} \n'
				+ 'const int bereik = 5; \n'
				+ 'const int aantal = 2 * bereik + 1; \n'
				+ 'float prob[aantal]; \n'
				+ 'for (int i = 0; i <= bereik; i++) \n'
				+ '{ \n'
				+ 'float p = exp(-float(i) * float(i) / (sigma * sigma)); \n'
				+ '// factor 1/(sigma*sqrt(2*pi)) overbodig vanwege latere schaling met gewichten \n'
				+ 'prob[bereik - i] = prob[bereik + i] = p; \n'
				+ '} \n'
				+ '\n'
				+ 'float somGewicht = 0.0; \n'
				+ 'vec3 kleur = vec3(0.0); \n'
				+ 'for (int i = -bereik; i <= bereik; i++) \n'
				+ '{ \n'
				+ 'for (int j = -bereik; j <= bereik; j++) \n'
				+ '{ \n'
				+ 'float gewicht = prob[bereik + i] * prob[bereik + j]; \n'
				+ 'somGewicht += gewicht; \n'
				+ 'kleur += texture2D(uTableauSampler, pos + vec2(float(i), float(j)) * uInvResolutie).rgb * gewicht; \n'
				+ '} \n'
				+ '} \n'
				+ 'kleur /= somGewicht; \n'
				+ 'return kleur; \n'
				+ '} \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'float diepte = texture2D(uDiepteSampler, vTextuurCoor).x; \n'
				+ 'diepte = lineaireModelviewDiepte(diepte); \n'
				+ 'float focusDiepte = uFocusDiepte; \n'
				+ 'if (uAutoFocus) \n'
				+ '{ \n'
				+ 'focusDiepte = lineaireModelviewDiepte(texture2D(uDiepteSampler, vec2(0.5, 0.5)).x); \n'
				+ '} \n'
				+ 'float radiusCoC = bepaalCoCRadius(diepte, focusDiepte); \n'
				+ 'float waasSterkte = radiusCoC * uWaasSterkte; \n'
				+ 'vec2 ruis = vec2(0.0); \n'
				+ 'if (uRuissSterkte > 0.0) \n'
				+ '{ \n'
				+ 'float ruisX = random(vTextuurCoor, 1.0); \n'
				+ 'float ruisY = random(vTextuurCoor, ruisX); \n'
				+ 'ruis = vec2(ruisX, ruisY) * uRuissSterkte * radiusCoC; \n'
				+ '} \n'
				+ 'gl_FragColor = vec4(gaussianBlur(waasSterkte, ruis), 1.0); \n'
				+ '}';
		};
	
		public static vertexShaderCodeVignette(): string {
			return ''
				+ 'attribute vec2 aVertexCoor; \n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'gl_Position = vec4(aVertexCoor, 0.0, 1.0); \n'
				+ 'vTextuurCoor = aVertexCoor.xy * 0.5 + 0.5; \n'
				+ '}';
		};
	
		public static fragmentShaderCodeVignette(): string {
			return ''
				+ '#ifdef GL_FRAGMENT_PRECISION_HIGH \n'
				+ 'precision highp float; \n'
				+ '#else \n'
				+ 'precision mediump float; \n'
				+ '#endif \n'
				+ '\n'
				+ 'uniform sampler2D uTableauSampler; \n'
				+ '\n'
				+ 'uniform float uStraal; \n'
				+ 'uniform float uWijdte; \n'
				+ 'uniform float uIntensiteit; \n'
				+ '\n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'vec4 kleur = texture2D(uTableauSampler, vTextuurCoor); \n'
				+ 'float afstTotCentrum = distance(vTextuurCoor, vec2(0.5)); \n'
				+ 'float donkerte = smoothstep(uStraal + 0.5*uWijdte, uStraal - 0.5*uWijdte, afstTotCentrum); \n'
				+ 'kleur.rgb = mix(kleur.rgb, kleur.rgb * donkerte, uIntensiteit); \n'
				+ 'gl_FragColor = kleur; \n'
				+ '}';
		};
	
		public static vertexShaderCodeSSAO(): string {
			return ''
				+ 'attribute vec2 aVertexCoor; \n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'gl_Position = vec4(aVertexCoor, 0.0, 1.0); \n'
				+ 'vTextuurCoor = aVertexCoor.xy * 0.5 + 0.5; \n'
				+ '}';
		};
	
		public static fragmentShaderCodeSSAOBerekenen(): string {
			return ''
				+ '#ifdef GL_FRAGMENT_PRECISION_HIGH \n'
				+ 'precision highp float; \n'
				+ '#else \n'
				+ 'precision mediump float; \n'
				+ '#endif \n'
				+ '\n'
				+ 'uniform sampler2D uTableauSampler; \n'
				+ 'uniform sampler2D uDiepteSampler; \n'
				+ '\n'
				+ 'uniform float uNear; \n'
				+ 'uniform float uFar; \n'
				+ 'uniform float uTop; \n'
				+ 'uniform float uRight; \n'
				+ '\n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ 'uniform float uProefRadius; \n'
				+ 'uniform float uOcclusieMacht; \n'
				+ '\n'
				+ 'float random(in vec2 coor, in float seed) \n'
				+ '{ \n'
				+ '// Een "canonical" hash-functie in bereik [0, 1>, afgeleid van oorspronkelijk werk door W.J.J Rey, 1998. \n'
				+ 'return fract(sin(dot(coor, vec2(12.9898, 78.233) * seed)) * 43758.5453); \n'
				+ '} \n'
				+ '\n'
				+ 'float lineaireModelviewDiepte(in float diepte) \n'
				+ '{ \n'
				+ '// Non-lineaire input-diepte in bereik [0, 1]; lineaire output-diepte in bereik [near, far]. \n'
				+ 'return (2.0 * uNear * uFar) / (uFar + uNear - (2.0 * diepte - 1.0) * (uFar - uNear)); \n'
				+ '// Equivalent aan: (uProjectieMatrix[3].z / (2.0 * diepte - 1.0 + uProjectieMatrix[2].z)); \n'
				+ '} \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ 'const float cTweePi = 6.283185; \n'
				+ 'const float cPhi = 1.618034; // de gulden snede \n'
				+ 'const float cReciPhi = 1.0 / cPhi; \n'
				+ 'const float cEen = 1.0; \n'
				+ 'const float cNul = 0.0; \n'
				+ 'const float cReciWortelDrie = 0.577350; // normaliseert de dodecahedron straal \n'
				+ 'const int cProefAantal = 20; \n'
				+ '\n'
				+ 'vec3 dodecahedron[cProefAantal]; \n'
				+ 'dodecahedron[0] = vec3(cEen, cEen, cEen) * cReciWortelDrie; \n'
				+ 'dodecahedron[1] = vec3(cEen, cEen, -cEen) * cReciWortelDrie; \n'
				+ 'dodecahedron[2] = vec3(cEen, -cEen, cEen) * cReciWortelDrie; \n'
				+ 'dodecahedron[3] = vec3(cEen, -cEen, -cEen) * cReciWortelDrie; \n'
				+ 'dodecahedron[4] = vec3(-cEen, cEen, cEen) * cReciWortelDrie; \n'
				+ 'dodecahedron[5] = vec3(-cEen, cEen, -cEen) * cReciWortelDrie; \n'
				+ 'dodecahedron[6] = vec3(-cEen, -cEen, cEen) * cReciWortelDrie; \n'
				+ 'dodecahedron[7] = vec3(-cEen, -cEen, -cEen) * cReciWortelDrie; \n'
				+ 'dodecahedron[8] = vec3(cNul, cReciPhi, cPhi) * cReciWortelDrie; \n'
				+ 'dodecahedron[9] = vec3(cNul, cReciPhi, -cPhi) * cReciWortelDrie; \n'
				+ 'dodecahedron[10] = vec3(cNul, -cReciPhi, cPhi) * cReciWortelDrie; \n'
				+ 'dodecahedron[11] = vec3(cNul, -cReciPhi, -cPhi) * cReciWortelDrie; \n'
				+ 'dodecahedron[12] = vec3(cReciPhi, cPhi, cNul) * cReciWortelDrie; \n'
				+ 'dodecahedron[13] = vec3(cReciPhi, -cPhi, cNul) * cReciWortelDrie; \n'
				+ 'dodecahedron[14] = vec3(-cReciPhi, cPhi, cNul) * cReciWortelDrie; \n'
				+ 'dodecahedron[15] = vec3(-cReciPhi, -cPhi, cNul) * cReciWortelDrie; \n'
				+ 'dodecahedron[16] = vec3(cPhi, cNul, cReciPhi) * cReciWortelDrie; \n'
				+ 'dodecahedron[17] = vec3(cPhi, cNul, -cReciPhi) * cReciWortelDrie; \n'
				+ 'dodecahedron[18] = vec3(-cPhi, cNul, cReciPhi) * cReciWortelDrie; \n'
				+ 'dodecahedron[19] = vec3(-cPhi, cNul, -cReciPhi) * cReciWortelDrie; \n'
				+ '\n'
				+ 'vec2 viewport = vec2(uRight, uTop); \n'
				+ 'vec2 coorOpNearPlane = (vTextuurCoor * 2.0 - 1.0) * viewport; \n'
				+ 'float diepte = texture2D(uDiepteSampler, vTextuurCoor).x; \n'
				+ 'float zDiepte = -lineaireModelviewDiepte(diepte); // in bereik [-near, -far] \n'
				+ 'vec3 coorViewmodel = vec3(coorOpNearPlane * -zDiepte / uNear, zDiepte); \n'
				+ '\n'
				+ 'float hoek = random(gl_FragCoord.xy, 1.0) * cTweePi; \n'
				+ 'float cosH = cos(hoek); \n'
				+ 'float sinH = sin(hoek); \n'
				+ 'mat3 ruisRot = mat3(1.0, 0.0, 0.0,  0.0, cosH, sinH,  0.0, -sinH, cosH); // kolom1, kolom2, kolom3 \n'
				+ 'hoek = random(gl_FragCoord.xy, hoek) * cTweePi; \n'
				+ 'cosH = cos(hoek); \n'
				+ 'sinH = sin(hoek); \n'
				+ 'ruisRot *= mat3(cosH, 0.0, -sinH,  0.0, 1.0, 0.0,  sinH, 0.0, cosH); \n'
				+ 'hoek = random(gl_FragCoord.xy, hoek) * cTweePi; \n'
				+ 'cosH = cos(hoek); \n'
				+ 'sinH = sin(hoek); \n'
				+ 'ruisRot *= mat3(cosH, sinH, 0.0,  -sinH, cosH, 0.0,  0.0, 0.0, 1.0); \n'
				+ '\n'
				+ 'float occlusieScore = 0.0; \n'
				+ 'for (lowp int i = 0; i < cProefAantal; i++) \n'
				+ '{ \n'
				+ 'vec3 proefCoorShift = ruisRot * (dodecahedron[i] * uProefRadius); \n'
				+ 'vec3 coorViewmodelProef = coorViewmodel + proefCoorShift; \n'
				+ 'vec2 coorOpNearPlaneProef = vec2(coorViewmodelProef.xy * -uNear / coorViewmodelProef.z); \n'
				+ 'vec2 textuurCoorProef = (coorOpNearPlaneProef / viewport) * 0.5 + 0.5; \n'
				+ 'float diepteProef = texture2D(uDiepteSampler, textuurCoorProef).x; \n'
				+ 'float zDiepteProef = -lineaireModelviewDiepte(diepteProef); \n'
				+ '\n'
				+ '// Wanneer de steekproefpositie achter de daadwerkelijke geometrische positie ligt (waarbij '
				+ '// z-waarden negatief zijn; kleinere z is verder weg), dan is er een bijdrage aan occlusie: \n'
				+ 'if (zDiepteProef >= coorViewmodelProef.z) \n'
				+ '{ \n'
				+ 'occlusieScore += smoothstep(0.0, 1.0, uProefRadius / abs(zDiepteProef - zDiepte)); \n'
				+ '} \n'
				+ '} \n'
				+ '// Bij een convexe contour wordt verwacht dat de occlusiescore kleiner is dan de helft van '
				+ '// het aantal steekproeven; bij een concave groter. Verminder daarom altijd met de helft: \n'
				+ 'float occlusie = occlusieScore / float(cProefAantal); \n'
				+ 'occlusie = 2.0 * max(occlusie - 0.5, 0.0); \n'
				+ 'occlusie = pow(occlusie, uOcclusieMacht); \n'
				+ '\n'
				+ 'gl_FragColor = vec4(occlusie, occlusie, occlusie, 1.0); \n'
				+ '}';
		};
	
		public static fragmentShaderCodeSSAOAanbrengen(): string {
			return ''
				+ '#ifdef GL_FRAGMENT_PRECISION_HIGH \n'
				+ 'precision highp float; \n'
				+ '#else \n'
				+ 'precision mediump float; \n'
				+ '#endif \n'
				+ '\n'
				+ 'uniform sampler2D uTableauSampler; \n'
				+ 'uniform sampler2D uOcclusieSampler; \n'
				+ 'uniform vec2 uInvResolutie; \n'
				+ '\n'
				+ 'varying vec2 vTextuurCoor; \n'
				+ '\n'
				+ 'const int cBlokGrootte = 7; \n'
				+ '\n'
				+ '\n'
				+ 'void main(void) \n'
				+ '{ \n'
				+ '// Blur de occlusie: \n'
				+ 'float occlusie = 0.0; \n'
				+ 'float blokStart = -0.5 * float(cBlokGrootte - 1); \n'
				+ 'for (lowp int i = 0; i < cBlokGrootte; i++) \n'
				+ '{ \n'
				+ 'for (lowp int j = 0; j < cBlokGrootte; j++) \n'
				+ '{ \n'
				+ 'vec2 offset = vec2(blokStart + float(i), blokStart + float(j)) * uInvResolutie; \n'
				+ 'occlusie += texture2D(uOcclusieSampler, vTextuurCoor + offset).x; \n'
				+ '} \n'
				+ '} \n'
				+ 'occlusie /= float(cBlokGrootte * cBlokGrootte); \n'
				+ '// Voeg de occlusie toe aan het beeld: \n'
				+ 'vec3 kleur = texture2D(uTableauSampler, vTextuurCoor).rgb * (1.0 - occlusie); \n'
				+ 'gl_FragColor = vec4(kleur, 1.0); \n'
				+ '}';
		};
	
	} // class ShaderSet
	
	type NumberNaarBoolFunctieType = (waarde: number) => boolean;
	
	type StringOfHtmlElementOfDocumentType = string | HTMLElement | Document;
	type HtmlElementOfDocumentType = HTMLElement | Document;
	type EventListenerOptiesType = boolean | AddEventListenerOptions;
	
	export type PositionType = {
		x: number,
		y: number
	}
	
	export class Lib {
	
		constructor() {
			//
			// Is bewust leeg.
			//
		}
	
		//
		// Module-constanten ///////////////////////////////////////////////////////////////////////////////////////////////////
		//
	
		public static readonly _epsilon: number = 1e-3;
		public static readonly _vanDegNaarRad: number = Math.PI / 180.0;
		public static readonly _vanRadNaarDeg: number = 180.0 / Math.PI;
		public static readonly _webGLFalse: number = 0; // Tbv de 2e parameter bij aanroepen van WebGLRenderingContext.uniform1i.
		public static readonly _webGLTrue: number = 1; // Tbv de 2e parameter bij aanroepen van WebGLRenderingContext.uniform1i.
	
		//
		// Hulpfuncties ////////////////////////////////////////////////////////////////////////////////////////////////////////
		//
	
		//
		// Functie vec3_project is overgenomen van een externe bron.
		// Daarom is hij niet omgezet naar TypeScript.
		// Alleen in de functieheader is 'function' gewijzigd naar 'public static'.
		//
		/**
		 * Projects the specified vec3 from object space into screen space
		 * Extents the gl-matrix library by Brandon Jones (https://github.com/toji/gl-matrix)
		 *
		 * @param {vec3} vec object-space vector to project
		 * @param {mat4} view View matrix
		 * @param {mat4} proj Projection matrix
		 * @param {vec4} viewport Viewport as given to gl.viewport [x, y, width, height]
		 * @param {vec3} [dest] vec3 receiving projected result. If not specified result is written to vec
		 *
		 * @returns {vec3} dest if specified, vec otherwise
		 */
		public static vec3_project(vec, view, proj, viewport, dest) {
			if (!dest) { dest = vec; }
	
			var v = new MatrixArray(4);
	
			v[0] = vec[0];
			v[1] = vec[1];
			v[2] = vec[2];
			v[3] = 1.0;
	
			mat4.multiplyVec4(view, v);
			mat4.multiplyVec4(proj, v);
			if (v[3] === 0.0) { return null; }
	
			dest[0] = v[0] / v[3];
			dest[1] = v[1] / v[3];
			dest[2] = v[2] / v[3];
	
			// Map x, y, z from range [-1, 1] to range [0, 1]
			dest[0] = dest[0] * 0.5 + 0.5;
			dest[1] = dest[1] * 0.5 + 0.5;
			dest[2] = dest[2] * 0.5 + 0.5;
	
			// Map x, y to viewport
			dest[0] = dest[0] * viewport[2] + viewport[0];
			dest[1] = dest[1] * viewport[3] + viewport[1];
	
			return dest;
		};
	
		//
		// Registers an event.
		//
		public static registerEvent(oTarget: StringOfHtmlElementOfDocumentType, sEventName: string, fnEventHandler: EventListener, oEventHandlerOptions: EventListenerOptiesType): void {
			let oTargetHTMLElmOfDoc: HtmlElementOfDocumentType;
			if (typeof (oTarget) == 'string') {
				if (document) {
					oTargetHTMLElmOfDoc = document.getElementById(oTarget);
				}
				else {
					throw 'document is niet gezet';
				}
			}
			else {
				oTargetHTMLElmOfDoc = oTarget;
			}
			if (oTargetHTMLElmOfDoc != null) {
				if (sEventName == 'mousewheel') {
					oTargetHTMLElmOfDoc.addEventListener('DOMMouseScroll', fnEventHandler, oEventHandlerOptions);
				}
				oTargetHTMLElmOfDoc.addEventListener(sEventName, fnEventHandler, oEventHandlerOptions);
			}
		};
	
		//
		// Unregisters an event.
		//
		public static unregisterEvent(oTarget: StringOfHtmlElementOfDocumentType, sEventName: string, fnEventHandler: EventListener, oEventHandlerOptions: EventListenerOptiesType): void {
			let oTargetHTMLElmOfDoc: HtmlElementOfDocumentType;
			if (typeof (oTarget) == 'string') {
				if (document) {
					oTargetHTMLElmOfDoc = document.getElementById(oTarget);
				}
				else {
					throw 'document is niet gezet';
				}
			}
			else {
				oTargetHTMLElmOfDoc = oTarget;
			}
			if (oTargetHTMLElmOfDoc != null) {
				if (sEventName == 'mousewheel') {
					oTargetHTMLElmOfDoc.removeEventListener('DOMMouseScroll', fnEventHandler, oEventHandlerOptions);
				}
				oTargetHTMLElmOfDoc.removeEventListener(sEventName, fnEventHandler, oEventHandlerOptions);
			}
		};
	
		//
		// Cancels (default and further) execution of the specified event.
		//
		public static cancelEvent(evt: Event): void {
			evt.preventDefault();
			evt.stopPropagation();
		};
	
		//
		// Gets (an index of) the button to which the specified mouse event pertains.
		// Return value is one of: 0 for none; 1 for left; 2 for middle; 3 for right button.
		//
		public static getButton(evt: MouseEvent): number { //@@@Q1 niet in gebruik
			//
			// Note: The property evt.which seems more reliable than the property evt.button .
			//
			if (evt.which)
				return evt.which;
			else if (evt.button != undefined) {
				if (evt.button == 1)
					return 1; // left
				else if (evt.button == 2)
					return 3; // right
				else if (evt.button == 4)
					return 2; // middle
				else
					return 0; // none
			}
			else
				return 0; // none
		};
	
		//
		// Gets the value of the mouse wheel rotation, normalized for different browsers.
		// Return value is a number. Scrolling up yields a positive value.
		//
		public static getWheel(evt: WheelEvent): number {
			evt = evt ? evt : <WheelEvent>window.event;
			let wheelData: number = evt.detail ? evt.detail * -1 : -evt.deltaY / 20;
			return wheelData;
		};
	
		//
		// Gets the mouse or touch position relative to the target html-element.
		// Return value is an object with properties 'x' (increasing to the right) and 'y' (increasing downward).
		//
		public static getPosition(target: HTMLElement, clientX: number, clientY: number): PositionType {
			//
			// Note: In mouse and touch events the client area is the current window, not the area of the target element.
			//
			let elem: HTMLElement = target;
			let top: number = 0;
			let left: number = 0;
			while (elem && elem.tagName != 'BODY') {
				top += elem.offsetTop;
				left += elem.offsetLeft;
				elem = <HTMLElement>elem.offsetParent; // Cast van Element naar HTMLElement, want properties offsetTop en offsetLeft zijn nodig.
			}
	
			let targetX: number = clientX - left + window.pageXOffset;
			let targetY: number = clientY - top + window.pageYOffset;
			return { x: targetX, y: targetY };
		};
	
		//
		// Gets (the value of) the key to which the specified key event pertains.
		//
		public static getKey(evt: KeyboardEvent): number {
			if (evt.which != null)
				return evt.which;
			else if (evt.charCode != null)
				return evt.charCode;
			else if (evt.keyCode != null)
				return evt.keyCode;
			else
				return undefined;
		};
	
		//
		// Finds the touch with the specified identifier in the non-empty list of touches.
		// Returns null when the touch is not found.
		//
		public static findTouch(touchIdentifier: number, touchList: TouchList): Touch {
			let searchTouch: Touch = touchList[0];
			if (searchTouch.identifier == touchIdentifier) {
				return searchTouch;
			}
			else if (touchList.length > 1) {
				searchTouch = touchList[1];
				if (searchTouch.identifier == touchIdentifier) {
					return searchTouch;
				}
				else {
					for (let i: number = 2; i < touchList.length; i++) {
						searchTouch = touchList[i];
						if (searchTouch.identifier == touchIdentifier) {
							return searchTouch;
						}
					}
				}
			}
	
			return null;
		};
	
		//
		// Bepaalt of de aangeleverde waarde een getal is.
		// Parameter 'waarde' is het vermoedelijke getal, een integer of een float.
		// Return-waarde is een boolean.
		//
		public static isGetal_(waarde: number): boolean {
			return (typeof (waarde) == 'number' && isFinite(waarde));
		};
		public static isGetal: NumberNaarBoolFunctieType = Number.isFinite || Lib.isGetal_;
	
		//
		// Bepaalt of de aangeleverde waarde een geheel getal is.
		// Parameter 'waarde' is het vermoedelijke geheel getal.
		// Return-waarde is een boolean.
		//
		public static isGeheelGetal_(waarde: number): boolean {
			return (typeof (waarde) == 'number' && isFinite(waarde) && Math.floor(waarde) == waarde);
		};
		public static isGeheelGetal: NumberNaarBoolFunctieType = Number.isInteger || Lib.isGeheelGetal_;
	
		//
		// Bepaalt of de aangeleverde waarde een lijst van getallen is.
		// Parameter 'waarde' is de vermoedelijke lijst van getallen, een array van floats.
		// Parameter 'lengte' bepaalt de verwachte lengte van de lijst.
		// Optionele parameter 'minWaarde' is de minimale waarde van alle getallen.
		// Optionele parameter 'maxWaarde' is de maximale waarde van alle getallen.
		// Return-waarde is een boolean.
		//
		public static isGetalLijst(waarde: number[], lengte: number, minWaarde?: number, maxWaarde?: number): boolean {
			if (Array.isArray(waarde) && Lib.isGetal(lengte) && lengte > 0 && waarde.length == lengte &&
				(minWaarde == null || Lib.isGetal(minWaarde)) &&
				(maxWaarde == null || Lib.isGetal(maxWaarde))) {
				for (let i: number = 0; i < lengte; i++) {
					let getal: number = waarde[i];
					if (Lib.isGetal(getal) == false) {
						//
						// De lijst bevat een waarde die niet een getal is:
						//
						return false;
					}
					if ((minWaarde != null && getal < minWaarde) || (maxWaarde != null && getal > maxWaarde)) {
						//
						// De waarde ligt buiten het toegestane bereik:
						//
						return false;
					}
				}
				return true;
			}
			return false;
		};
	
		//
		// Bepaalt of de aangeleverde waarde een drie-dimensionale vector is.
		// Parameter 'vec' is de vermoedelijke vector, een array van floats met lengte 'vectorLengte'.
		// Parameter 'nulvectorToegestaan' is een boolean die aangeeft of een nulvector een geldige vector is. De default is false.
		// Parameter 'vectorLengte' is de lengte van de vector. Default is 3.
		// Return-waarde is een boolean.
		//
		public static isVector(vec: number[], nulvectorToegestaan: boolean, vectorLengte: number = 3): boolean {
			if (Array.isArray(vec) && Lib.isGetal(vectorLengte) && vec.length == vectorLengte) {
				let som: number = 0.0;
				for (let i: number = 0; i < vectorLengte; i++) {
					if (Lib.isGetal(vec[i]) == false) {
						//
						// De waarde is geen vector:
						//
						return false;
					}
					som = som + Math.abs(vec[i]);
				}
				if (som < Lib._epsilon && nulvectorToegestaan !== true) {
					//
					// De vector is (nagenoeg) een nulvector:
					//
					return false;
				}
				return true;
			}
			return false;
		};
	
		//
		// Calculate the length of a vector.
		//
		public static vectorLength(vec: number[]): number {
			//
			// Here no check for vec.length == 2 or 3.
			//
			let vecLength: number;
			if (vec.length == 3) {
				vecLength = Math.hypot(vec[0], vec[1], vec[2]);
			}
			else {
				vecLength = Math.hypot(vec[0], vec[1]);
			}
			return vecLength;
		}
	
		//
		// Calculate the distance between two points.
		//
		public static distance(point1: number[], point2: number[]): number {
			//
			// Here no check for point1.length == 2 or 3.
			// And for point1.length==point2.length.
			//
			let vecLength: number;
			if (point1.length == 3) { 
				vecLength = Math.hypot(point1[0] - point2[0], point1[1] - point2[1], point1[2] - point2[2]);
			}
			else {
				vecLength = Math.hypot(point1[0] - point2[0], point1[1] - point2[1]);
			}
			return vecLength;
		}
	
		//
		// Returns the normalized vector: the input vector with the length set to 1.
		// If the length of the vector is too small, an exception will be thrown.
		//
		public static normalizeVector(vec: number[]): number[] {
			//
			// Here no check for vec.length == 2 or 3.
			//
			let vecLength: number = Lib.vectorLength(vec);
			if (vecLength < VLib.Lib._epsilon) { throw "Vector is too small for normalizing" };
			let vec2: number[];
			if (vec.length == 3) {
				vec2 = [vec[0] / vecLength, vec[1] / vecLength, vec[2] / vecLength];
			}
			else {
				vec2 = [vec[0] / vecLength, vec[1] / vecLength];
			}
			return vec2;
		}
	
		//
		// Calcutate dot product for two vectors.
		//
		public static dotProduct(vec1: number[], vec2: number[]): number {
			//
			// Here no check for vec1.length == 2 or 3.
			// And for vec1.length==vec2.length.
			//
			let dotProd: number;
			if (vec1.length == 3) {
				dotProd = vec1[0] * vec2[0] + vec1[1] * vec2[1] + vec1[2] * vec2[2];
			}
			else {
				dotProd = vec1[0] * vec2[0] + vec1[1] * vec2[1];
			}
			return dotProd;
		}

		//
		// Calcutate cross product for two vectors.
		// The order of the arguments matters. 
		// The order determines whether you get a right-handed or a left-handed coordinate system.
		//
		public static crossProduct3D(vec1: number[], vec2: number[]): number[] {
			//
			// Here no check for vec1.length==3.
			// Same for vec2.
			//
			let crosProd: number[] = [];
			crosProd.length = 3;
			crosProd[0] = vec1[1] * vec2[2] - vec1[2] * vec2[1];
			crosProd[1] = vec1[2] * vec2[0] - vec1[0] * vec2[2];
			crosProd[2] = vec1[0] * vec2[1] - vec1[1] * vec2[0];
			return crosProd;
		}
	
		//
		// Calculates the 2D-normal for a given 2D-vector.
		// It creates a right-handed coordinate system.
		// This means: 
		// vector = (v1, v2); calculated normal = (n1, n2). If vector has vector-length==1:
		// Then: crossproduct (v1, v2, 0) x (n1, n2, 0) = (0, 0, 1).
		// (positive z-axis)
		//
		public static get2DNormalVector(vector: number[]): number[] {
			//
			// Here no check for vector.length==2.
			//
			let normal: number[] = [-vector[1], vector[0]];
			return normal;
		}
	
		//
		// Returns vec2 - vec1.
		//
		public static vec2MinusVec1(vec1: number[], vec2: number[]): number[] {
			//
			// Here no check for vec1.length == 2 or 3.
			// And for vec1.length==vec2.length.
			//
			let result: number[];
			if (vec1.length == 3) {
				result = [
					vec2[0] - vec1[0],
					vec2[1] - vec1[1],
					vec2[2] - vec1[2]];
			}
			else {
				result = [
					vec2[0] - vec1[0],
					vec2[1] - vec1[1]];
			}
			return result;
		}
	
		//
		// Returns vec1 + vec2.
		//
		public static vec1PlusVec2(vec1: number[], vec2: number[]): number[] {
			//
			// Here no check for vec1.length == 2 or 3.
			// And for vec1.length==vec2.length.
			//
			let result: number[];
			if (vec1.length == 3) {
				result = [
					vec1[0] + vec2[0],
					vec1[1] + vec2[1],
					vec1[2] + vec2[2]];
			}
			else {
				result = [
					vec1[0] + vec2[0],
					vec1[1] + vec2[1]];
			}
			return result;
		}
	
		//
		// Multiplies a vector with a scalar.
		//
		public static scaleVector(scale: number, vector: number[]): number[] {
			//
			// Here no check for vector.length == 2 or 3.
			//
			let result: number[];
			switch (scale) { // For -1, 1 and 0 no multiplication is done. For better accuracy.
				case -1: {
					if (vector.length == 3) {
						result = [-vector[0], -vector[1], -vector[2]];
					}
					else {
						result = [-vector[0], -vector[1]];
					}
					break;
				}
				case 0: {
					if (vector.length == 3) {
						result = [0, 0, 0];
					}
					else {
						result = [0, 0];
					}
					break;
				}
				case 1: {
					if (vector.length == 3) {
						result = [vector[0], vector[1], vector[2]];
					}
					else {
						result = [vector[0], vector[1]];
					}
					break;
				}
				default: {
					if (vector.length == 3) {
						result = [scale * vector[0], scale * vector[1], scale * vector[2]];
					}
					else {
						result = [scale * vector[0], scale * vector[1]];
					}
					break;
				}
			}
			return result;
		}
	
		//
		// Calculates the sum of vectors multiplied with scalars.
		//
		public static scaledVectorSum(scales: number[], vectors: number[][]): number[] {
			//
			// Here no check for vectors[i].length == 2 or 3 (for all i).
			// And no check for all vectors having the same length.
			// And no check for scales are numbers, and for scales.length==vectors.length.
			// And no check for scales.length > 0.
			//
			let is3D: boolean = (vectors[0].length == 3); // false for 2D.
			let result: number[];
			if (is3D) {
				result = [0.0, 0.0, 0.0];
			}
			else {
				result = [0.0, 0.0];
			}
			for (let i: number = 0; i < scales.length; i++) {
				let scale: number = scales[i];
				let vector: number[] = vectors[i];
				switch (scale) { // For -1, 1 and 0 no multiplication is done. For better accuracy.
					case -1: {
						result[0] -= vector[0];
						result[1] -= vector[1];
						if (is3D) {
							result[2] -= vector[2];
						}
						break;
					}
					case 0: {
						break;
					}
					case 1: {
						result[0] += vector[0];
						result[1] += vector[1];
						if (is3D) {
							result[2] += vector[2];
						}
						break;
					}
					default: {
						result[0] += scale * vector[0];
						result[1] += scale * vector[1];
						if (is3D) {
							result[2] += scale * vector[2];
						}
						break;
					}
				}
			}
			return result;
		}
	
		//
		// Bepaalt of het aangeleverde getal een macht van twee is.
		// Parameter 'x' is een positieve integer.
		// Return-waarde is een boolean.
		//
		public static isMachtVanTwee(x: number): boolean {
			return (x & (x - 1)) == 0;
		};
	
		//
		// Bepaalt de eerstvolgende macht van twee.
		// Parameter 'x' is een positieve integer.
		// Return-waarde is een positieve integer.
		//
		public static eerstvolgendeMachtVanTwee(x: number): number {
			--x;
			for (let i: number = 1; i < 32; i <<= 1) {
				x = x | x >> i;
			}
			return x + 1;
		};
	
		//
		// Converteert een (non-nul)vector in termen van de richtings- en hellingshoek.
		// Parameter 'vector' is een array van floats met lengte 3 (x-, y- en z-componenten).
		// Return-waarde is een array met lengte 2 (richting- en hellingcomponenten in graden).
		//
		public static vanVectorNaarHoeken(vector: number[]): number[] {
			//
			// De richting is de hoek in het horizontale vlak met de positieve x-as. De helling is de hoek met het 
			// horizontale vlak, waarbij neerwaarts positief is en waarbij horizontaal nul is.
			//
			let projVectorLengte: number = Math.hypot(vector[0], vector[1]);
			let richting: number;
			if (projVectorLengte > Lib._epsilon) {
				let hoekMetXAs: number = Math.atan2(vector[1], vector[0]);
				richting = hoekMetXAs * Lib._vanRadNaarDeg;
			}
			else {
				richting = 90.0;
			}
			let hoekMetHorizontaal: number = Math.atan2(vector[2], projVectorLengte);
			let helling: number = -hoekMetHorizontaal * Lib._vanRadNaarDeg;
	
			return [richting, helling];
		};
	
		//
		// Converteert een richtings- en hellingshoek naar een vector.
		// Parameter 'hoeken' is een array van floats met lengte 2 (richting- en hellingcomponenten in graden).
		// Return-waarde is een array met lengte 3 (x-, y- en z-componenten).
		//
		public static vanHoekenNaarVector(hoeken: number[]): number[] {
			let hoekMetXAs: number = hoeken[0] * Lib._vanDegNaarRad;
			let hoekMetHorizontaal: number = -hoeken[1] * Lib._vanDegNaarRad;
			let cosHoekMetHorizontaal: number = Math.cos(hoekMetHorizontaal);
			let x: number = Math.cos(hoekMetXAs) * cosHoekMetHorizontaal;
			let y: number = Math.sin(hoekMetXAs) * cosHoekMetHorizontaal;
			let z: number = Math.sin(hoekMetHorizontaal);
	
			return [x, y, z];
		};
	
		//
		// Neemt een waarde over van het ene object naar het ander aan de hand van een property-naam en de 
		// getter en setter op basis van die naam, indien mogelijk.
		// Parameter 'propNaam' is een string, de naam van de property.
		// Parameter 'getterContext' is het bronobject.
		// Parameter 'setterContext' is het bestemmingobject.
		// Return-waarde is een boolean die aangeeft of het overnemen van de waarde uitgevoerd kon worden.
		//
		public static trySet(propNaam: string, getterContext: object, setterContext: object): boolean { //@@@Q1 niet in gebruik
			if (typeof (propNaam) != 'string' || propNaam.length == 0 ||
				getterContext == null || typeof (getterContext) != 'object' ||
				setterContext == null || typeof (setterContext) != 'object') {
				return false;
			}
	
			//
			// Let op: In TypeScript worden properties veelal in het 'prototype' van de class gedefinieerd.
			// Functie hasOwnProperty ziet ze dan niet. Eventueel kan operator 'in' gebruikt worden.
			// Dus: voor gebruik binnen TypeScript moet de inhoud van deze functie waarschijnlijk opnieuw overwogen worden.
			// Dan aan te passen afhankelijk van de precieze gebruiks-context.
			//
			if (getterContext.hasOwnProperty(propNaam) && setterContext.hasOwnProperty(propNaam)) {
				let waarde: any = getterContext[propNaam]; // Type is bewust 'any', ik weet gewoon niet beter.
				setterContext[propNaam] = waarde;
				return true;
			}
			return false;
		};
	
		//
		// Maakt een melding ten behoeve van debugging.
		// Parameter 'msg' is de tekst van de boodschap.
		// Parameter 'level' is het niveau van de ernst van de melding, waarbij 0 = log (default); 1 = info; 2 = warning; 
		// 3 = error.
		//
		public static log(msg: string, level: number): void {
			if (window.console) {
				switch (level) {
					default:
					case 0:
						window.console.log(msg);
						break;
					case 1:
						window.console.info(msg);
						break;
					case 2:
						window.console.warn(msg);
						break;
					case 3:
						window.console.error(msg);
						break;
				}
			}
			else {
				alert(msg);
			}
		};
	
	} // class Lib
	
	type CanvasEnViewerType = {
		canvas: HTMLCanvasElement,
		viewer: Viewer3D
	}
	
	type WebGLContextAttributesType = {
		antialias: boolean
	}
	
	export class Viewer3DManager {
	
		constructor() {
			//
			// Bewust leeg.
			//
		}
	
		//
		// Module-variabelen ///////////////////////////////////////////////////////////////////////////////////////////////////
		//
	
	
		private viewer3DTabel: CanvasEnViewerType[] = []; // de tabel van alle gecreëerde viewers
	
		//
		// Module-functies /////////////////////////////////////////////////////////////////////////////////////////////////////
		//
	
		//
		// Geeft de 3D-viewer terug die hoort bij het aangeleverde canvas-element.
		// Parameter 'canvas' is een HTML canvas-element waarvan de viewer gemaakt is of moet worden, of het ID van het element.
		// Optionele parameter 'opties' is een object waarmee verscheidene viewer-opties gekozen kunnen worden, met de volgende 
		// content: 
		//   property 'metLampen'
		//     Een boolean die aangeeft of er kunstlichtbronnen in de viewer toegepast kunnen worden. De default is false; 
		//     het gebruik van lampen wordt alleen ondersteund wanneer deze property aanwezig is en de waarde true heeft. 
		//     (Bijkomend effect is dat de tekensnelheid van de viewer sterk afneemt.)
		//   property 'zonderAntialiasing'
		//      Een boolean die aangeeft of anti-aliasing bij het tekenen achterwege gelaten moet worden. De default is false.
		//   property 'enableLog'
		//     Een boolean die aangeeft of er meldingen gemaakt moeten worden ten behoeve van debugging. De default is false.
		//   property 'metNabewerkingen'
		//     Een boolean die aangeeft of er nabewerkingen in de viewer toegepast kunnen worden. De default is false.
		// Optionele parameter 'nietCreeeren' is een boolean die aangeeft of een viewer gemaakt moet worden indien die nog niet 
		// bestaat. De default is false.
		// Return-waarde is een viewer-object, of null wanneer er geen viewer gemaakt kon worden.
		//
		public get(canvas: V3DIdOfHTMLCanvasElementType, opties?: Viewer3DOptiesType, nietCreeeren?: boolean): Viewer3D {
			let htmlCanvasElm: HTMLCanvasElement = this.converteerNaarHTMLCanvasElement(canvas);
			if (!htmlCanvasElm) {
				return null;
			}
			return this.retrieve(htmlCanvasElm, opties, nietCreeeren);
		};
	
		private converteerNaarHTMLCanvasElement(canvas: V3DIdOfHTMLCanvasElementType): HTMLCanvasElement {
			let htmlCanvasElm: HTMLCanvasElement;
			if (typeof (canvas) == 'string') {
				let htmlElm: HTMLElement = document.getElementById(canvas);
				if (!(htmlElm instanceof HTMLCanvasElement)) {
					return null;
				}
				htmlCanvasElm = <HTMLCanvasElement>htmlElm;
			}
			else { // Dan zou het een HTMLCanvasElement moeten zijn.
				if (!(canvas instanceof HTMLCanvasElement)) {
					return null;
				}
				htmlCanvasElm = <HTMLCanvasElement>canvas;
			}
			return htmlCanvasElm;
		}
	
		//
		// Geeft de eerder aangemaakte 3D-viewer terug die hoort bij het aangeleverde canvas-element, of maakt een nieuwe 
		// wanneer er nog geen is.
		// Parameter 'canvas' is een HTML canvas-element waarvan de viewer gemaakt is of moet worden.
		// Optionele parameter 'opties' is een object waarmee verscheidene viewer-opties gekozen kunnen worden.
		// Optionele parameter 'nietCreeeren' is een boolean die aangeeft of een viewer gemaakt moet worden indien die nog niet 
		// bestaat. De default is false.
		// Return-waarde is een viewer-object, of null wanneer er geen viewer gemaakt kon worden.
		//
		private retrieve(canvas: HTMLCanvasElement, opties: Viewer3DOptiesType, nietCreeeren: boolean): Viewer3D {
			//
			// Bepaal of de viewer al bestaat:
			//
			for (let i: number = 0; i < this.viewer3DTabel.length; i++) {
				if (this.viewer3DTabel[i].canvas === canvas) {
					let gevondenViewer3D: Viewer3D = this.viewer3DTabel[i].viewer;
					return gevondenViewer3D;
				}
			}
			if (nietCreeeren == null || nietCreeeren === false) {
				//
				// Maak een nieuwe viewer:
				//
				let nieuweViewer3D: Viewer3D = this.create(canvas, opties);
				if (!nieuweViewer3D) {
					return null;
				}
				let canvasEnViewer: CanvasEnViewerType = { canvas: canvas, viewer: nieuweViewer3D };
				this.viewer3DTabel.push(canvasEnViewer);
				return nieuweViewer3D;
			}
			else {
				return null;
			}
		};
	
		//
		// Maakt een 3D-viewer die hoort bij het aangeleverde canvas-element.
		// Parameter 'canvas' is het HTML canvas-element waarvan de viewer gemaakt moet worden.
		// Optionele parameter 'opties' is een object waarmee verscheidene viewer-opties gekozen kunnen worden.
		// Return-waarde is een viewer-object, of null wanneer er geen viewer gemaakt kon worden.
		//
		private create(canvas: HTMLCanvasElement, opties: Viewer3DOptiesType): Viewer3D {
			let gl: WebGLRenderingContext = this.setupWebGL(canvas, opties);
			if (!gl) {
				return null;
			}
			let nieuweViewer3D: Viewer3D = new Viewer3D(canvas, gl, this, opties);
			if (nieuweViewer3D.isDisposed() == true) {
				return null;
			}
			return nieuweViewer3D;
		};
	
		//
		// Probeert een WebGL rendering-context aan te maken voor het aangeleverde canvas-element.
		// Parameter 'canvas' is het HTML canvas-element waarvoor de rendering-context gemaakt moet worden.
		// Optionele parameter 'opties' is een object waarmee verscheidene viewer-opties gekozen kunnen worden.
		// Return-waarde is een WebGL rendering-context, of null wanneer er geen gemaakt kon worden.
		//
		private setupWebGL(canvas: HTMLCanvasElement, opties: Viewer3DOptiesType): WebGLRenderingContext {
			let gl: WebGLRenderingContext;
			let foutdetails: string = '';
			let contextIDs: string[] = ['webgl', 'experimental-webgl'];
			let contextAttrs: WebGLContextAttributesType;
			if (opties && opties.zonderAntialiasing === true) {
				contextAttrs = { antialias: false };
			}
			for (let i: number = 0; i < contextIDs.length; i++) {
				try {
					//
					// Mbt de cast in onderstaand sattement: 
					// Bij de gegeven waarden voor de 1e parameter is het resultaat altijd een WebGLRenderingContext.
					//
					gl = <WebGLRenderingContext>canvas.getContext(contextIDs[i], contextAttrs); 
				}
				catch (exc) {
					foutdetails = foutdetails + ' \r\n Details voor context-ID ' + contextIDs[i] + ': ' +
						(exc.message ? exc.message : exc);
				}
				if (gl) {
					break;
				}
			}
			if (!gl) {
				if (opties && opties.enableLog === true) {
					let afz: string = 'WebGLViewer ' + (canvas.id ? '\'' + canvas.id + '\'' : '(zonder id)') + ' --- ';
					let msg: string = 'WebGL context kon niet geinitieerd worden. Foutdetails: ' + foutdetails;
					VLib.Lib.log(afz + msg, 2);
				}
				return null;
			}
	
			return gl;
		};
	
		public verwijder(canvas: HTMLCanvasElement): Viewer3D {
			let gevondenViewer3D: Viewer3D = null;
			for (let i: number = 0; i < this.viewer3DTabel.length; i++) {
				if (this.viewer3DTabel[i].canvas === canvas) {
					gevondenViewer3D = this.viewer3DTabel[i].viewer;
					//
					// Verwijder de viewer uit de viewer-tabel:
					//
					this.viewer3DTabel.splice(i, 1);
					break;
				}
			}
			return gevondenViewer3D;
		}
	
		//
		// Ruimt de 3D-viewer op die hoort bij het aangeleverde canvas-element.
		// Parameter 'canvas' is een HTML canvas-element waarvan de viewer gemaakt is, of het ID van het element.
		// Return-waarde is true wanneer de viewer opgeruimd is; false wanneer de viewer niet bestaat of niet met succes 
		// opgeruimd kon worden.
		//
		public dispose(canvas: V3DIdOfHTMLCanvasElementType): boolean {
			let htmlCanvasElm: HTMLCanvasElement = this.converteerNaarHTMLCanvasElement(canvas);
			if (!htmlCanvasElm) {
				return false;
			}
			let gevondenViewer3D: Viewer3D = this.verwijder(htmlCanvasElm);
			if (!gevondenViewer3D) {
				return false;
			}
			//
			// Ruim de viewer op:
			//
			gevondenViewer3D.dispose();
			return true;
		};
	
	
	} // class ModuleViewer3DClass
	
	export class PolyFills {
	
		constructor() {
			if (PolyFills.gedaan) {
				return;
			}
	
			//
			// Polyfill functies ///////////////////////////////////////////////////////////////////////////////////////////////////
			//
	
			//
			// Stelt een cross-browser implementatie van window.requestAnimationFrame in.
			//
			window.requestAnimationFrame = window.requestAnimationFrame ||
				//window.webkitRequestAnimationFrame ||
				function (callback) {
					return window.setTimeout(callback, 1000 / 60);
				};
	
			//
			// Stelt een cross-browser implementatie van Math.hypot in.
			//
			Math.hypot = Math.hypot || function (): number {
				let som: number = 0;
				let aantal: number = arguments.length;
				for (let i: number = 0; i < aantal; i++) {
					let waarde: number = arguments[i];
					som += waarde * waarde;
				}
				return Math.sqrt(som);
			};
	
			//
			// Stelt een cross-browser implementatie van Math.sign in.
			// Voor TypeScript: bron is [https://github.com/MaxArt2501/es6-math/blob/master/es6-math.js] 
			// (binnen[https://github.com/MaxArt2501/es6-math]).
			//
			Math.sign = Math.sign || function (x: number): number {
				// If -0, must return -0.
				return isNaN(x) ? NaN : x < 0 ? -1 : x > 0 ? 1 : +x;
			};
	
			//
			// The following works because NaN is the only value in javascript which is not equal to itself.
			//
			Number.isNaN = Number.isNaN || function (value: number): boolean {
				return value !== value;
			}
	
			PolyFills.gedaan = true;
		}
		private static gedaan: boolean = false; // Zorgt ervoor dat de polyfills hoogstens éénmaal worden gedefinieerd.
	
	} // class PolyFills
	
	//
	// Types om een JSON-variant van een perimeter te interpreteren, zoals gebruikt door de constructor van class Perimeter.
	// Gebaseerd op: Src\Dh3DVisueel\ConversieWebGL\Perimeter.cs: functie Serialiseer(), en onderliggende functies.
	//
	
	export type PerimeterSegmentJsonType = {
		ptn: number[]; // arraylengte is 4
		//
		// In geval er een buur is (C#: Segment.Buur != null), dan properties buurOuder en buur aanwezig. Anders allebei niet.
		//
		buurOuder?: number;
		buur?: number;
	}
	
	export type PerimeterDeelJsonType = {
		ID?: string;
		bb: number[]; // arraylengte is 6 (want 3 dimensies).
		//
		// In geval van schuin vlak (C#: PerimeterDeel.IsHorizontaal == false), dan properties helling, basis en opwaarts aanwezig. Anders alledrie niet.
		//
		helling?: number;
		basis?: number[]; // arraylengte is 3
		opwaarts?: number[]; // arraylengte is 2
		sgmn: PerimeterSegmentJsonType[];
	}
	
	export type PerimeterJsonType = {
		wat: string;
		versie: number;
		delen: PerimeterDeelJsonType[];
	}
	
	//
	// Types gebruikt binnen class Perimeter
	//
	
	export type DichtstbijzijndePuntType = {
		punt: number[]; // 2 floats (x- en y-componenten) met het dichtstbijzijnde punt.
		afstand2: number; // Gekwadrateerde afstand.
	}
	
	export type PerimeterInfoObjType = {
		deel: VLib.PerimeterDeel;
		positie: number[];
		triggers: string[]; // Kan undefined zijn.
	}
	
	//
	// Interne functie-signatuur types voor Viewer3D:
	//
	
	export type GeladenTekstCallbackType = (geladenTekst: string) => void;
	export type LaadBitmapGereedCallbackType = (bitmap: HTMLImageElement, parameters: LaadBitmapGereedCallbackParametersType) => void;
	export type BitmapLadenGereedCallbackType = (resultaatBitmap: HTMLImageElement, isLadenGeslaagd: boolean) => void;
	export type TexBronLadenGereedCallbackType = (resultaatBitmap: HTMLImageElement, texNaam: string, isLadenGeslaagd: boolean) => void;
	export type WandelFunctieType = (koers: number, amplificatie: number) => boolean;
	export type SorteerNumbersFunctieType = (a: number, b: number) => number; // Om mee te geven bij een call naar Array<number>.sort.
	export type FilterNumberDoubluresFunctieType = (elem: number, pos: number, arr: number[]) => boolean; // Om mee te geven bij een call naar Array<number>.filter.
	export type TekenActieFunctieType = (transLijstIndex: number) => void;
	export type TexBronVervangFunctieType = (tbHuidig: TexBronType, tbVervanging: TexBronType) => number;
	export type TelFragmentItemsTypenFunctie = (items: FragmentItemType[]) => string;
	export type PassTekenFunctieType = (passFBO: WebGLFramebuffer, kleurTexturesIn: WebGLTexture[], diepteTexturesIn: WebGLTexture[]) => void;
	
	//
	// Wrapper-types voor Viewer3D.
	//
	
	export type CanvasWrapType = {
		canvas: HTMLCanvasElement,
		toetsAfvanger?: HTMLElement
	}
	
	export type VertexDataBufferWrapType = {
		vertexDataBuffer: WebGLBuffer,
		aantal: number,
		//
		// De stride en de 3 offsets worden gebruikt in calls naar gl.vertexAttribPointer.
		//
		stride?: number,
		normaalOffset?: number,
		tangentOffset?: number,
		textuurCoorOffset?: number
	}
	
	export type VertexIndicesBufferWrapType = {
		vertexIndicesBuffer: WebGLBuffer,
		aantal: number
	}
	
	export type WebGLProgramWrapType = {
		shaderProgram: WebGLProgram,
		naam?: string,
		vertexCoorAttribute?: number,
		textuurCoorAttribute?: number,
		tableauSamplerUniform?: WebGLUniformLocation,
		tableauVormTexturingUniform?: WebGLUniformLocation,
		projectieMatrixUniform?: WebGLUniformLocation,
		modelviewMatrixUniform?: WebGLUniformLocation,
		normaalAttribute?: number,
		tangentAttribute?: number,
		kleurAttribute?: number,
		bumpmapSamplerUniform?: WebGLUniformLocation,
		tableauTexturingUniform?: WebGLUniformLocation,
		bumpmapTexturingUniform?: WebGLUniformLocation,
		biTangentUniform?: WebGLUniformLocation,
		mengKleurUniform?: WebGLUniformLocation,
		elemTypeUniform?: WebGLUniformLocation,
		elemPositieUniform?: WebGLUniformLocation,
		zoomSchaalUniform?: WebGLUniformLocation,
		modelviewMatrixFSUniform?: WebGLUniformLocation,
		rotatieMatrixUniform?: WebGLUniformLocation,
		rotatieMatrixFSUniform?: WebGLUniformLocation,
		diepteMatrixUniform?: WebGLUniformLocation,
		diepteSamplerUniform?: WebGLUniformLocation,
		vloedlichtUniform?: WebGLUniformLocation,
		lichtvalUniform?: WebGLUniformLocation,
		omgevingLichtNiveauUniform?: WebGLUniformLocation,
		diffuusLichtNiveauUniform?: WebGLUniformLocation,
		schitteringLichtNiveauUniform?: WebGLUniformLocation,
		schitteringMateriaalUniform?: WebGLUniformLocation,
		toonSlagschaduwUniform?: WebGLUniformLocation,
		slagschaduwVerzadigingUniform?: WebGLUniformLocation,
		lichtAttenuatieAfstandUniform?: WebGLUniformLocation,
		lampenUniforms?: LampUniformsType[],
		samplerUniform?: WebGLUniformLocation,
		vertexDataBufferWrap?: VertexDataBufferWrapType,
		invResolutieUniform?: WebGLUniformLocation,
		nearUniform?: WebGLUniformLocation,
		farUniform?: WebGLUniformLocation,
		focusDiepteUniform?: WebGLUniformLocation,
		autoFocusUniform?: WebGLUniformLocation,
		cocCurveUniform?: WebGLUniformLocation,
		waasSterkteUniform?: WebGLUniformLocation,
		ruisSterkteUniform?: WebGLUniformLocation,
		straalUniform?: WebGLUniformLocation,
		wijdteUniform?: WebGLUniformLocation,
		intensiteitUniform?: WebGLUniformLocation,
		topUniform?: WebGLUniformLocation,
		rightUniform?: WebGLUniformLocation,
		proefRadiusUniform?: WebGLUniformLocation,
		occlusieMachtUniform?: WebGLUniformLocation,
		occlusieSamplerUniform?: WebGLUniformLocation
	}
	
	export type EXT_texture_filter_anisotropic_WrapType = {
		extTextureFilterAnisotropic: EXT_texture_filter_anisotropic; // anisotrope textuurfiltering extension
		maxAnisotropy: number;
	}
	
	export type WebGLRenderingContextWrapType = {
		gl: WebGLRenderingContext,
		drawingBufferAutoSize: boolean // Default true.
	}
	
	export type WebGLFramebufferWrapType = {
		FBO: WebGLFramebuffer,
		doel: string,
		kleurTexture?: WebGLTexture,
		kleurRBO?: WebGLRenderbuffer,
		diepteTexture?: WebGLTexture,
		diepteRBO?: WebGLRenderbuffer
	}
	
	export type ShadowMapTextureWrapType = {
		texture: WebGLTexture,
		zoomSchaal?: number,
		richting?: number,
		helling?: number,
		rolhoek?: number,
		verschuivingRechts?: number,
		verschuivingOmhoog?: number,
		cameraType?: boolean,
		orthografischeProjectie?: boolean,
		positie?: number[]
	}
	
	export type LampUniformsType = {
		diepteSampler: WebGLUniformLocation,
		diepteTextureUnit: number,
		positie: WebGLUniformLocation,
		richting: WebGLUniformLocation,
		cosBuitenHoek: WebGLUniformLocation,
		cosBinnenHoek: WebGLUniformLocation,
		diffuusLichtNiveau: WebGLUniformLocation,
		schitteringLichtNiveau: WebGLUniformLocation,
		kleur: WebGLUniformLocation,
		diepteMatrix: WebGLUniformLocation,
	}
	
	export const enum eFragmentOrigin { regular, editor }
	
	//
	// Velden wat, versie, ID, bb en bbTrans (optioneel) vormen de header.
	// Velden kleurBronnen en texNamen (optioneel) vormen het bronnen-gedeelte.
	// Dan volgt de optionele transLijst (transformatielijst).
	// Het eigenlijke model bestaat uit de itemsOpaak en de (optionele) itemsTransparant.
	// In functie controleerFragmentJson (en dieper) worden een aantal controles uitgevoerd.
	//
	export type FragmentJsonType = {
		wat: string,
		versie: number,
		fragmentOrigin?: eFragmentOrigin,
		hideWhenPicking?: boolean,
		ID: string,
		bb: number[], // fragment bounding box = [ xMin, xMax, yMin, yMax, zMin, zMax ]
		bbTrans?: number[], // fragment bounding box = [ xMin, xMax, yMin, yMax, zMin, zMax ]
		kleurBronnen: number[][],
		texNamen?: string[],
		transLijst?: TransLijstJsonType,
		itemsOpaak: FragmentItemJsonType[], // Is verplicht veld. Zie commentaar binnen functie controleerTransformatielijstJson.
		itemsTransparant?: FragmentItemJsonType[],
		isZichtbaar?: boolean // Also determines the fragment-fields werptSlagschaduw and moetGetekend.
	}
	
	//
	// Wordt deels gevuld uit een FragmentJsonType.
	//
	export type FragmentType = {
		versie: number,
		ID: string,
		fragmentOrigin: eFragmentOrigin,
		bb: number[], // fragment bounding box = [ xMin, xMax, yMin, yMax, zMin, zMax ]
		bbTrans?: number[], // fragment bounding box = [ xMin, xMax, yMin, yMax, zMin, zMax ]
		kleurBronnen: number[][],
		transLijst?: TransLijstType,
		itemsOpaak: FragmentItemType[],
		itemsTransparant?: FragmentItemType[],
		isZichtbaar: boolean,
		werptSlagschaduw: boolean,
		moetGetekend: boolean,
		hideWhenPicking?: boolean,
		afmetingen: number[],
		centrum: number[],
		bbRadius: number,
		buffers: WebGLBuffer[]
	}
	
	export type FragmentItemJsonType = {
		type: number,
		subtype?: string,
		kleur: number,
		schitteringMat?: number,
		positie?: number[],
		vertexData?: number[],
		vertexIndices?: number[],
		normaal?: number[],
		tableau?: number,
		bumpmap?: number,
		tangent?: number[],
		biTangent?: number[],
		mengKleur?: number
	}
	
	//
	// Wordt deels gevuld uit een FragmentItemJsonType.
	//
	export type FragmentItemType = {
		type: number,
		subtype?: string,
		kleur: number[],
		schitteringMat?: number,
		positie?: number[],
		normaal?: number[],
		tableau?: TexBronType,
		bumpmap?: TexBronType,
		tangent?: number[],
		biTangent?: number[],
		mengKleur?: number[],
		vertexDataBufferWrap: VLib.VertexDataBufferWrapType,
		vertexIndicesBufferWrap?: VLib.VertexIndicesBufferWrapType,
		kleurGesel?: number[],
		kleurOngesel?: number[],
		mengKleurGesel?: number[],
		mengKleurOngesel?: number[]
	}
	
	export type TransLijstWrapJsonType = {
		transLijst: TransLijstJsonType
	}
	
	export type TransLijstJsonType = {
		aantal: number,
		transMats: number[][], // transMats[i].length == 16
		rotMats: number[][],  // rotMats[i].length == 9
		spiegelMats: boolean[]
	}
	
	//
	// Wordt deels gevuld uit een TransLijstJsonType.
	//
	export type TransLijstType = {
		aantal: number,
		transMats: number[][], // transMats[i].length == 16
		rotMats: number[][],  // rotMats[i].length == 9
		spiegelMats: boolean[],
		cacheTekenCyclus: boolean,
		cachedModelviewMats: number[][],
		cachedRotatieMats: number[][],
		cachedDiepteMats: number[][],
		cachedLampenDiepteMats: number[][][]
	}
	
	//
	// Types for field projectieClippingFrustum in class Viewer3DPrivates
	//
	export type FrustumType = {
		near: number,
		far: number,
		fovy: number,
		aspectRatio: number,
		apex: number[],
		as: number[],
		rolhoek: number,
		conusHoek: number,
		sinConusHoek: number,
		cosConusHoek: number
	}
	
	export type ProjectieClippingFrustumType = {
		Frustum: FrustumType;
	}
	
	export type DraaienOmPuntDataType = {
		draaiPunt: number[], // x, y, z
		relDraaiPuntVerschuivingRechts: number,
		relDraaiPuntVerschuivingOmhoog: number
	}
	
	export type AssenItemType = {
		vertexDataBuffer: WebGLBuffer
	}
	
	export type AangemaaktShadowMapResultaatType = {
		shadowMapTexture: WebGLTexture,
		diepteMatrix: number[]
	}
	
	export type PassResultType = {
		kleurTexture: WebGLTexture,
		diepteTexture: WebGLTexture
	}
	
	export type PickingType = {
		fragmentIndex: number,
		fragmentID: string,
		transLijstIndex: number
	}
	
	export type SelectieIDInternType = V3DSelectieIDType & { fragmentIndex?: number } // Is SelectieIDType, met extra veld fragmentIndex. Voor intern gebruik.
	
	export type TexBronType = {
		tex: WebGLTexture,
		heeftAlpha: boolean,
		naam?: string,
		refs?: string[]
	}
	
	export type ModelDimensiesType = {
		bb: number[], // [ xMin, xMax, yMin, yMax, zMin, zMax ]
		afmetingen: number[], // [ x-lengte, y-diepte, z-hoogte ]
		centrum: number[], // [ x, y, z ]
		stelselStraal: number,
		modelStraal: number
	}
	
	export type LaadBitmapGereedCallbackParametersType = any; // Zie LaadBitmapGereedCallbackType. Vooralsnog is 'parameters' niet in gebruik, en daarom is type nu 'any'.
	

}

//
// Module-objecten /////////////////////////////////////////////////////////////////////////////////////////////////////
//

type V3DPuntOfBoolType = number[] | boolean; // Gebruikt als returntype van functies 'positieAanpassen'.
type V3DObjectOfStringType = object | string;
type V3DStringOfStringArrayType = string | string[];
type V3DStringOfNumberArrayType = string | number[];

export type V3DSelectieIDType = {
	fragmentID: string,
	transLijstAantal?: number,
	transLijstIndices?: number[]
}

type Viewer3DOptiesType = {
	zonderAntialiasing?: boolean,
	enableLog?: boolean,
	metLampen?: boolean,
	metNabewerkingen?: boolean
}

//
// Public functie-signatuur types voor Viewer3D:
//

export type V3DFragmentGereedCallbackType = (fragmentID: string) => void;
export type V3DFragmentenGereedCallbackType = (fragmentIDs: string[]) => void;
export type V3DSimpelCallbackType = () => void;
export type V3DAantalCallbackType = (aantal: number) => void;
export type V3DPressedKeyCallbackType = (pressedKey: number) => void;
export type V3DAnimatieCallbackType = (canvas: HTMLCanvasElement, deltaTijd: number, verstrekenTijd: number) => void;
export type V3DSelectieVeranderendCallbackType = (selectieIDs: V3DSelectieIDType[], veroorzaaktDoorGebruiker: boolean) => boolean;
export type V3DSelectieVeranderdCallbackType = (selectieIDs: V3DSelectieIDType[], veroorzaaktDoorGebruiker: boolean) => void;
export type V3DEditorSelectionChangedCallbackType = (selectionID: V3DSelectieIDType) => void;
export type V3DBerichtCallbackType = (canvas: HTMLCanvasElement, berichtType: number, berichtData: string) => void;
export type V3MoveDrawingElementsModusMouseMoveCallback = (fragmentID: string, spacePoint: number[], startSpacePoint: number[], shiftKey: boolean, ctrlKey: boolean, altKey: boolean, mouseHasMovedForSelectedEditorFragment: boolean) => void;
export type V3CreateDrawingModusCallback = (mouseEvent: eMouseEvent, spacePoint: number[], mouseButton: number) => void;
export type V3CreateBridgeLineModusCallback = (fragmentID: string, spacePoint: number[]) => void;
export type V3MovedElementCallback = (fragmentID: string) => void;
export type V3DeleteShapeModusCallback = (fragmentID: string) => void;
export type V3DeleteBridgeLineModusCallback = (fragmentID: string) => void;
export type V3EndEditorActionCallback = (modus: eViewerWorkModus) => void;
export type V3AlignModusCallback = (fragmentID: string) => void;
export type V3PlaceLibraryObjectCallback = (spacePoint: number[]) => void;
export type V3PlaceOpeningCallback = (fragmentID: string, spacePoint: number[]) => void;
export type V3DPositieAanpassenFunctieType = (huidigPunt: number[], nieuwPunt: number[]) => V3DPuntOfBoolType;
export type V3DMouseEventHandlerType = (evt: MouseEvent) => void;
export type V3DTouchEventHandlerType = (evt: TouchEvent) => void;
export type V3DWheelEventHandlerType = (evt: WheelEvent) => void;
export type V3DPointerEventHandlerType = (evt: PointerEvent) => void;
export type V3DKeyboardEventHandlerType = (evt: KeyboardEvent) => void;
export type V3DFocusEventHandlerType = (evt: FocusEvent) => void;
export type V3DWebGLContextEventHandlerType = (evt: WebGLContextEvent) => void;

//
// Class voor een viewer3D-object.
//
export class Viewer3D {

	//
	// Constructor-functie voor een viewer-object, een object dat de eigenschappen van de 3D-viewer beheert en de API ervan 
	// bevat.
	// Parameter 'canvas' is het HTML canvas-element waarvan de viewer gemaakt moet worden.
	// Parameter 'gl' is de WebGL rendering-context.
	// Optionele parameter 'opties' is een object waarmee verscheidene viewer-opties gekozen kunnen worden.
	//
	constructor(canvas: HTMLCanvasElement, gl: WebGLRenderingContext, viewer3DManager: VLib.Viewer3DManager, opties: Viewer3DOptiesType) {
		if (this.init(canvas, gl, viewer3DManager, opties) !== true) {
			this.dispose();
		}
	}

	//
	// Ingekapselde eigenschappen:
	//
	public static readonly MAX_LEN_FRAGMENT_ID: number = 32;
	private privates: VLib.Viewer3DPrivates = new VLib.Viewer3DPrivates();
	private viewer3DManager: VLib.Viewer3DManager;
	private static readonly marker3D: VLib.Marker3D = new VLib.Marker3D();

	//
	// Functies /////////////////////////////////////////////////////////////////////////////////////////////////////////
	//

	//
	// Voegt een modelfragment toe aan het model.
	// Parameter 'fragmentStr' is een modelfragment geserialiseerd in de vorm van een JSON-tekst.
	// Parameter 'gereedCallback' is een callback die wordt aangeroepen zodra het verwerken van het modelfragment gereed 
	// is. Een callback is nodig omdat het verwerken van het modelfragment asynchroon verloopt. De callback heeft één 
	// parameter: de ID-string van het toegevoegde modelfragment, of null wanneer het toevoegen niet geslaagd is.
	//
	public add(fragmentStr: string, gereedCallback: V3DFragmentGereedCallbackType): void {
		if (this.privates.glWrap && typeof (gereedCallback) == 'function') {
			if (typeof (fragmentStr) != 'string') {
				gereedCallback(null);
				return;
			}

			let fragmentJson: VLib.FragmentJsonType;
			try {
				fragmentJson = JSON.parse(fragmentStr);
			}
			catch (exc) {
				this.conditionalLog(exc, 1);
				gereedCallback(null);
				return;
			}
			try {
				this.controleerFragmentJson(fragmentJson, true, true);
			}
			catch (exc) {
				this.conditionalLog(exc, 12);
				gereedCallback(null);
				return;
			}

			this.addInternal(fragmentJson, gereedCallback);
		}
	};

	//
	// Adds a model fragment. Specially designed for WebGLEditor.
	// There is no callback parameter. This is a synchronous function.
	// But field texNamen should be empty. Because the processing textures is asynchronous, and for that a callback would be needed.
	//
	public addJsonFragment(fragmentJson: VLib.FragmentJsonType): void {
		if (this.privates.glWrap) {
			try {
				//
				// Parameter texAllowed is false. For synchronous processing.
				// Parameter transAllowed is false. We want to be able to replace the bb (boundingBox) en vertexData.
				//		Without having to strugle with the bbtrans and transLijst.
				//
				this.controleerFragmentJson(fragmentJson, false, false);
			}
			catch (exp) {
				throw "Check on fragment fails: " + exp;
			}
			if (this.exists(fragmentJson.ID)) {
				throw "Fragment with ID=[" + fragmentJson.ID + "] already exists";
			}
			let fragment = this.prefillFragment(fragmentJson);
			let texBronnen: VLib.TexBronType[] = []; // Must be empty in this context. For synchronous processing.
			try {
				this.verwerkFragment(fragmentJson, fragment, texBronnen);
			}
			catch (exp) {
				throw "Processing fragment fails: " + exp;
			}
		}
	}

	//
	// Replaces the bounding box and the vertex data of a fragment.
	// Specially designed for WebGLEditor.
	// vertexDataOpaque: Nr of buffers must equal the nr of opaque items in the fragment. Must contain at least one buffer. 
	// vertexDataTransparent: Nr of buffers must equal the nr of transparent items in the fragment. Can be null or empty.
	//
	public replaceFragmentData(fragmentID: string, boundingBox: number[], vertexDataOpaque: number[][], vertexDataTransparent: number[][], vertexIndicesOpaque: number[][], vertexIndicesTransparent: number[][]): void {
		if (this.privates.glWrap) {
			let fragment: VLib.FragmentType = this.find(fragmentID);
			if (fragment == null) { throw "Fragment [" + fragmentID + "] not found"};
			if (fragment.transLijst != null) { throw "Fragment [" + fragmentID + "] contains transLijst" }; // Because of the field bbTrans is replacing not possible (at this moment).

			let nrOfOpaqueVertexDataBuffers: number = (vertexDataOpaque == null ? 0 : vertexDataOpaque.length);
			let nrOfTransparentVertexDataBuffers: number = (vertexDataTransparent == null ? 0 : vertexDataTransparent.length);
			let nrOfOpaqueItems: number = (fragment.itemsOpaak == null ? 0 : fragment.itemsOpaak.length);
			let nrOfTransparentItems: number = (fragment.itemsTransparant == null ? 0 : fragment.itemsTransparant.length);
			if (nrOfOpaqueItems != nrOfOpaqueVertexDataBuffers) { throw "Fragment [" + fragmentID + "] has " + nrOfOpaqueItems + " opaque items, instead of " + nrOfOpaqueVertexDataBuffers };
			if (nrOfTransparentItems != nrOfTransparentVertexDataBuffers) { throw "Fragment [" + fragmentID + "] has " + nrOfTransparentItems + " opaque items, instead of " + nrOfTransparentVertexDataBuffers };

			fragment.bb = boundingBox;
			fragment.bbTrans = null; // Else the next call wil not work properly.
			this.berekenFragmentDimensies(fragment);

			let gl: WebGLRenderingContext = this.privates.glWrap.gl;
			this.replaceItemData(fragment.itemsOpaak, vertexDataOpaque, vertexIndicesOpaque, gl);
			this.replaceItemData(fragment.itemsTransparant, vertexDataTransparent, vertexIndicesTransparent, gl);
		}
	}

	private replaceItemData(items: VLib.FragmentItemType[], vertexData: number[][], vertexIndices: number[][], gl: WebGLRenderingContext): void {
		if (items != null) {
			for (let i: number = 0; i < items.length; i++) {
				let item: VLib.FragmentItemType = items[i];
				let vertexDataBuffer: WebGLBuffer = item.vertexDataBufferWrap.vertexDataBuffer;
				gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
				gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertexData[i]), gl.STATIC_DRAW); // Here no check on 'compatibility' with the previous vertex data buffer.

				if (vertexIndices && vertexIndices[i]) {
					let vertexIndicesBuffer: WebGLBuffer = item.vertexIndicesBufferWrap.vertexIndicesBuffer;
					item.vertexIndicesBufferWrap.aantal = vertexIndices[i].length;
					gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, vertexIndicesBuffer);
					gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(vertexIndices[i]), gl.STATIC_DRAW);
				}
			}
		}
	}

	//
	// Voegt een modelfragment toe aan het model.
	// Parameter 'fragmentJson' is het toe te voegen fragment.
	// Parameter 'gereedCallback' is een callback die wordt aangeroepen zodra het verwerken van het modelfragment gereed 
	// is. De callback heeft één parameter: de ID-string van het toegevoegde modelfragment, of null wanneer het 
	// toevoegen niet geslaagd is.
	// Voor intern gebruik.
	//
	private addInternal(fragmentJson: VLib.FragmentJsonType, gereedCallback: V3DFragmentGereedCallbackType): void {
		let fragmentID: string = fragmentJson.ID;
		if (fragmentID == null) {
			this.conditionalLog(null, 4);
			gereedCallback(null);
			return;
		}
		if (this.isValidId(fragmentID) == false) {
			this.conditionalLog(fragmentID, 5);
			gereedCallback(null);
			return;
		}
		if (this.exists(fragmentID) == true) {
			this.conditionalLog(fragmentID, 6);
			gereedCallback(null);
			return;
		}

		let fragment = this.prefillFragment(fragmentJson);
		let texBronnen: VLib.TexBronType[] = []; // De te vullen set van textuurbronnen.
		try {
			let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
			let verwerkFragmentBronnenGereedCallback: V3DSimpelCallbackType = function (): void {
				try {
					_this.verwerkFragment(fragmentJson, fragment, texBronnen);
				}
				catch (exc1) {
					_this.conditionalLog(exc1, 3);
					gereedCallback(null);
					return;
				}
				gereedCallback(fragmentID);
			};
			this.verwerkFragmentBronnen(fragmentJson, texBronnen, verwerkFragmentBronnenGereedCallback);
		}
		catch (exc2) {
			this.conditionalLog(exc2, 3);
			gereedCallback(null);
			return;
		}
	};

	private prefillFragment(fragmentJson: VLib.FragmentJsonType): VLib.FragmentType {
		//
		// Op dit punt de velden invullen die rechtstreeks (zonder conversie) uit fragmentJson worden overgenomen.
		// De verplichte velden krijgen sowieso alvast een waarde, zonodig undefined.
		// Velden transLijst en itemsTransparant volgen later.
		//
		let fragmentOrigin: VLib.eFragmentOrigin = (fragmentJson.fragmentOrigin ? fragmentJson.fragmentOrigin : VLib.eFragmentOrigin.regular); // Default is regular.
		let fragment: VLib.FragmentType = { // Velden transLijst en itemsTransparant volgen later.
			versie: fragmentJson.versie, ID: fragmentJson.ID, fragmentOrigin: fragmentOrigin, bb: fragmentJson.bb, bbTrans: fragmentJson.bbTrans,
			hideWhenPicking: fragmentJson.hideWhenPicking, kleurBronnen: fragmentJson.kleurBronnen, itemsOpaak: undefined,
			isZichtbaar: undefined, werptSlagschaduw: undefined, moetGetekend: undefined,
			afmetingen: undefined, centrum: undefined, bbRadius: undefined, buffers: undefined
		};
		return fragment;
	}

	//
	// Voegt een modelfragment toe aan het model.
	// Parameter 'fragmentBestandURL' is de bestandsnaam met pad van het bestand met het modelfragment in de vorm van 
	// een JSON-tekst.
	// Parameter 'gereedCallback' is een callback die wordt aangeroepen zodra het verwerken van het modelfragment gereed 
	// is. Een callback is nodig omdat het verwerken van het modelfragment asynchroon verloopt. De callback heeft één 
	// parameter: de ID-string van het toegevoegde modelfragment, of null wanneer het toevoegen niet geslaagd is.
	//
	public addFromFile(fragmentBestandURL: string, gereedCallback: V3DFragmentGereedCallbackType): void {
		if (this.privates.glWrap && typeof (gereedCallback) == 'function') {
			let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
			let geladenTekstCallback: VLib.GeladenTekstCallbackType = function (geladenTekst: string): void {
				if (geladenTekst == null) {
					gereedCallback(null);
					return;
				}
				_this.add(geladenTekst, gereedCallback);
			};
			this.loadTextFromFile(fragmentBestandURL, geladenTekstCallback);
		}
	};

	//
	// Voegt een aantal modelfragmenten toe aan het model.
	// Parameter 'fragmenten' is een array van modelfragmenten, elk geserialiseerd in de vorm van een JSON-tekst.
	// Parameter 'gereedCallback' is een callback die wordt aangeroepen zodra het verwerken van de modelfragmenten 
	// gereed is. Een callback is nodig omdat het verwerken van het modelfragment asynchroon verloopt. De callback heeft 
	// één parameter: een array van de ID-strings van de toegevoegde modelfragmenten, of null wanneer het toevoegen van 
	// het betreffende fragment niet geslaagd is.
	//
	public addRange(fragmenten: string[], gereedCallback: V3DFragmentenGereedCallbackType): void {
		if (this.privates.glWrap && typeof (gereedCallback) == 'function') {
			if (Array.isArray(fragmenten) == false || fragmenten.length == 0) {
				gereedCallback(null);
				return;
			}

			let fragmentIDs: string[] = [];
			let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
			let toegevoegd: V3DFragmentGereedCallbackType = function (toegevoegdFragmentID: string): void {
				fragmentIDs.push(toegevoegdFragmentID);
				let volgende: number = fragmentIDs.length;
				if (volgende < fragmenten.length) {
					_this.add(fragmenten[volgende], toegevoegd);
				}
				else {
					gereedCallback(fragmentIDs);
				}
			};
			this.add(fragmenten[0], toegevoegd);
		}
	};

	//
	// Voegt een aantal modelfragmenten toe aan het model.
	// Parameter 'fragmentBestandURLs' is een array van bestandsnamen met paden van de bestanden met modelfragmenten, 
	// elk in de vorm van een JSON-tekst.
	// Parameter 'gereedCallback' is een callback die wordt aangeroepen zodra het verwerken van de modelfragmenten 
	// gereed is. Een callback is nodig omdat het verwerken van het modelfragment asynchroon verloopt. De callback heeft 
	// één parameter: een array van de ID-strings van de toegevoegde modelfragmenten, of null wanneer het toevoegen van 
	// het betreffende fragment niet geslaagd is.
	//
	public addRangeFromFile(fragmentBestandURLs: string[], gereedCallback: V3DFragmentenGereedCallbackType): void {
		if (this.privates.glWrap && typeof (gereedCallback) == 'function') {
			if (Array.isArray(fragmentBestandURLs) == false || fragmentBestandURLs.length == 0) {
				gereedCallback(null);
				return;
			}

			let fragmentIDs: string[] = [];
			let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
			let toegevoegd: V3DFragmentGereedCallbackType = function (toegevoegdFragmentID: string): void {
				fragmentIDs.push(toegevoegdFragmentID);
				let volgende: number = fragmentIDs.length;
				if (volgende < fragmentBestandURLs.length) {
					_this.addFromFile(fragmentBestandURLs[volgende], toegevoegd);
				}
				else {
					gereedCallback(fragmentIDs);
				}
			};
			this.addFromFile(fragmentBestandURLs[0], toegevoegd);
		}
	};

	//
	// Vervangt een modelfragment in het model.
	// Parameter 'fragment' is het vervangende modelfragment geserialiseerd in de vorm van een JSON-tekst.
	// Parameter 'teVervangenFragmentID' is de ID-string van het te vervangen modelfragment.
	// Parameter 'gereedCallback' is een callback die wordt aangeroepen zodra het verwerken van het modelfragment gereed 
	// is. Een callback is nodig omdat het verwerken van het modelfragment asynchroon verloopt. De callback heeft één 
	// parameter: de ID-string van het vervangende modelfragment, of null wanneer het vervangen niet geslaagd is.
	// Optionele parameter 'behoudTransformatielijst' is een boolean die aangeeft of de aan het te vervangen 
	// modelfragment toegewezen transformatielijst overgenomen moet worden (true) of niet (false; default).
	//
	// Kan het selectie-veranderd event afvuren.
	//
	public replace(fragmentStr: string, teVervangenFragmentID: string, gereedCallback: V3DFragmentGereedCallbackType, behoudTransformatielijst?: boolean): void {
		if (this.privates.glWrap && typeof (gereedCallback) == 'function') {
			if (typeof (fragmentStr) != 'string' || typeof (teVervangenFragmentID) != 'string') {
				gereedCallback(null);
				return;
			}

			let teVervangenFragment: VLib.FragmentType = this.find(teVervangenFragmentID);
			if (teVervangenFragment == null) {
				this.conditionalLog(teVervangenFragmentID, 7);
				gereedCallback(null);
				return;
			}
			let fragmentJson: VLib.FragmentJsonType;
			try {
				fragmentJson = JSON.parse(fragmentStr);
			}
			catch (exc) {
				this.conditionalLog(exc, 1);
				gereedCallback(null);
				return;
			}
			try {
				this.controleerFragmentJson(fragmentJson, true, true);
			}
			catch (exc) {
				this.conditionalLog(exc, 12);
				gereedCallback(null);
				return;
			}

			let teVerwijderenFragmentID: string = teVervangenFragmentID;
			if (fragmentJson.ID === teVervangenFragmentID) {
				//
				// Verander het ID van het te vervangen fragment om conflicten met het vervangende fragment, dat dezelfde 
				// ID heeft, te voorkomen:
				//
				teVerwijderenFragmentID = '___removing___' + teVervangenFragmentID;
				teVervangenFragment.ID = teVerwijderenFragmentID;
				this.changeIdInBronnen(teVervangenFragmentID, teVerwijderenFragmentID);
			}

			if (behoudTransformatielijst === true) {
				//
				// TransLijst overnemen. Maar alleen het deel van de gegevens die in een TransLijstJsonType voorkomen.
				//
				let Bron: VLib.TransLijstType = teVervangenFragment.transLijst;
				let Doel: VLib.TransLijstJsonType = { aantal: Bron.aantal, transMats: Bron.transMats, rotMats: Bron.rotMats, spiegelMats: Bron.spiegelMats };
				fragmentJson.transLijst = Doel;
				fragmentJson.bbTrans = null;
			}

			let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
			let fragmentGereedCallback: V3DFragmentGereedCallbackType = function (toegevoegdFragmentID: string): void {
				if (toegevoegdFragmentID == null) {
					//
					// Het toevoegen van het vervangende fragment is mislukt.
					//
					if (teVervangenFragmentID != teVerwijderenFragmentID) {
						//
						// Herstel het ID van het te vervangen fragment:
						//
						teVervangenFragment.ID = teVervangenFragmentID;
						_this.changeIdInBronnen(teVerwijderenFragmentID, teVervangenFragmentID);
					}
					gereedCallback(null);
				}
				else {
					//
					// Het toevoegen van het vervangende fragment is gelukt. Nu het te vervangen fragment verwijderen:
					//
					let index: number = _this.indexOf(teVerwijderenFragmentID);
					let teVerwijderenFragment: VLib.FragmentType = _this.privates.fragmenten.splice(index, 1)[0];
					_this.wisBuffers(teVerwijderenFragment);
					_this.verschoonTexBronnen(teVerwijderenFragmentID);

					_this.privates.modelDims = undefined;
					_this.wisShadowMaps();

					let gevonden: number = _this.findIndexInSelectie(_this.privates.selectieIDs, teVervangenFragmentID);
					if (gevonden != -1) {
						//
						// Het te vervangen fragment zit in de selectie.
						//
						let selectieIDsNieuw: VLib.SelectieIDInternType[];
						if (teVervangenFragmentID != toegevoegdFragmentID) {
							selectieIDsNieuw = _this.selectie;
							selectieIDsNieuw[gevonden].fragmentID = toegevoegdFragmentID;
						}
						if (behoudTransformatielijst !== true &&
							teVervangenFragment.transLijst && _this.privates.selectieIDs[gevonden].transLijstIndices) {
							//
							// Het te vervangen fragment had een transformatielijst en niet alle transformaties in die 
							// transformatielijst zijn geselecteerd.
							// Omdat de te vervangen transformatielijst en de (eventuele) nieuwe transformatielijst 
							// mogelijk incongruent zijn, hetgeen tot moeilijk te ontraadselen selectiegedrag kan leiden, 
							// wordt het fragment gedeselecteerd:
							//
							selectieIDsNieuw = selectieIDsNieuw || _this.selectie;
							selectieIDsNieuw.splice(gevonden, 1);
						}
						if (selectieIDsNieuw) {
							_this.updateSelectieEnMeld(selectieIDsNieuw, false);
						}
					}

					gereedCallback(toegevoegdFragmentID);
				}
			};
			this.addInternal(fragmentJson, fragmentGereedCallback);
		}
	};

	//
	// Vervangt een modelfragment in het model.
	// Parameter 'fragmentBestandURL' is de bestandsnaam met pad van het bestand met het modelfragment in de vorm van 
	// een JSON-tekst.
	// Parameter 'teVervangenFragmentID' is de ID-string van het te vervangen modelfragment.
	// Parameter 'gereedCallback' is een callback die wordt aangeroepen zodra het verwerken van het modelfragment gereed 
	// is. Een callback is nodig omdat het verwerken van het modelfragment asynchroon verloopt. De callback heeft één 
	// parameter: de ID-string van het vervangende modelfragment, of null wanneer het vervangen niet geslaagd is.
	//
	// Kan het selectie-veranderd event afvuren.
	//
	public replaceFromFile(fragmentBestandURL: string, teVervangenFragmentID: string, gereedCallback: V3DFragmentGereedCallbackType): void {
		if (this.privates.glWrap && typeof (gereedCallback) == 'function') {
			let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
			let geladenTekstCallback: VLib.GeladenTekstCallbackType = function (geladenTekst: string): void {
				if (geladenTekst == null) {
					gereedCallback(null);
					return;
				}
				_this.replace(geladenTekst, teVervangenFragmentID, gereedCallback);
			};
			this.loadTextFromFile(fragmentBestandURL, geladenTekstCallback);
		}
	};

	//
	// Verwijdert één of meer modelfragmenten uit het model.
	// Parameter 'fragmentIDs' een ID-string of een lijst met ID-strings van de te verwijderen modelfragmenten.
	// Return-waarde is een boolean. Waarde is true wanneer alle documentfragmenten zijn verwijderd; anders false.
	//
	// Kan het selectie-veranderd event afvuren.
	//
	public remove(fragmentIDs: V3DStringOfStringArrayType): boolean {
		if (this.privates.glWrap && fragmentIDs != null) {
			let fragmentIDsArray: string[];
			if (Array.isArray(fragmentIDs) == false) {
				if (typeof fragmentIDs != 'string') { throw 'fragmentIDs is geen string en geen string[]' };
				fragmentIDsArray = [fragmentIDs];
			}
			else {
				if (fragmentIDs.length == 0) {
					return false;
				}
				fragmentIDsArray = <string[]>fragmentIDs;
			}

			let selectieIDsNieuw: VLib.SelectieIDInternType[] = this.selectie;
			let succes: boolean = true;
			let gewijzigd: boolean = false;
			for (let i: number = 0; i < fragmentIDsArray.length; i++) {
				let fragmentID: string = fragmentIDsArray[i];
				if (typeof fragmentID != 'string') { throw 'fragmentIDs[' + i + '] is geen string' };
				let fragmentIndex: number = this.indexOf(fragmentID);
				if (fragmentIndex == -1) {
					this.conditionalLog(fragmentID, 7);
					succes = false;
					continue;
				}

				gewijzigd = true;
				let fragment: VLib.FragmentType = this.privates.fragmenten.splice(fragmentIndex, 1)[0];
				this.wisBuffers(fragment);
				this.verschoonTexBronnen(fragmentID);

				let gevonden: number = this.findIndexInSelectie(selectieIDsNieuw, fragmentID);
				if (gevonden != -1) {
					selectieIDsNieuw.splice(gevonden, 1);
				}
			}

			if (gewijzigd) {
				this.privates.modelDims = undefined;
				this.wisShadowMaps();

				if (selectieIDsNieuw.length < this.privates.selectieIDs.length) {
					//
					// Een of meer van de verwijderde fragmenten zaten in de selectie:
					//
					this.updateSelectieEnMeld(selectieIDsNieuw, false);
				}
			}
			return succes;
		}
		return false;
	};

	//
	// Verwijdert alle modelfragmenten.
	//
	// Kan het selectie-veranderd event afvuren.
	//
	public clear(keepDummyTexture: boolean = false): void {
		if (this.privates.glWrap) {
			this.wisBuffers();
			this.verschoonTexBronnen();
			if (!keepDummyTexture && this.privates.dummyTexture) {
				this.privates.glWrap.gl.deleteTexture(this.privates.dummyTexture);
			}
			this.privates.fragmenten = [];

			this.privates.modelDims = undefined;
			this.wisShadowMaps();

			if (this.privates.selectieIDs.length > 0) {
				//
				// De selectie wordt ook geleegd:
				//
				let selectieIDsNieuw: VLib.SelectieIDInternType[] = [];
				this.updateSelectieEnMeld(selectieIDsNieuw, false);
			}
		}
	};

	//
	// Vervangt het ID van een modelfragment.
	// Parameters 'oudFragmentID' en 'nieuwFragmentID' zijn ID-strings.
	// Return-waarde is een boolean. Waarde is true wanneer het ID met succes is veranderd; anders false.
	//
	// Kan het selectie-veranderd event afvuren.
	//
	public changeId(oudFragmentID: string, nieuwFragmentID: string): boolean {
		if (this.privates.glWrap && typeof (oudFragmentID) == 'string' && typeof (nieuwFragmentID) == 'string') {
			if (this.isValidId(nieuwFragmentID) == false) {
				this.conditionalLog(nieuwFragmentID, 5);
				return false;
			}
			if (this.exists(nieuwFragmentID)) {
				this.conditionalLog(nieuwFragmentID, 6);
				return false;
			}
			let fragment: VLib.FragmentType = this.find(oudFragmentID);
			if (fragment == null) {
				this.conditionalLog(oudFragmentID, 7);
				return false;
			}

			fragment.ID = nieuwFragmentID;
			this.changeIdInBronnen(oudFragmentID, nieuwFragmentID);

			let gevonden: number = this.findIndexInSelectie(this.privates.selectieIDs, oudFragmentID);
			if (gevonden != -1) {
				//
				// Het hernoemde fragment zit in de selectie:
				//
				let selectieIDsNieuw: VLib.SelectieIDInternType[] = this.selectie;
				selectieIDsNieuw[gevonden].fragmentID = nieuwFragmentID;
				this.updateSelectieEnMeld(selectieIDsNieuw, false);
			}
			return true;
		}
		return false;
	};

	//
	// Geeft het aantal modelfragmenten in het model.
	// Return-waarde is een natuurlijk getal.
	//
	public count(): number {
		if (this.privates.glWrap) {
			return this.privates.fragmenten.length;
		}
		return 0;
	};

	//
	// Bepaalt of het model een modelfragment met het aangegeven ID bevat.
	// Parameter 'fragmentID' is de ID-string van het te zoeken modelfragment.
	// Return-waarde is een boolean. Waarde is true wanneer het modelfragment bestaat; anders false.
	//
	public exists(fragmentID: string): boolean {
		if (this.privates.glWrap && typeof (fragmentID) == 'string') {
			let fragmentIndex: number = this.indexOf(fragmentID);
			return (fragmentIndex != -1);
		}
		return false;
	};

	//
	// Geeft de lijst van ID's van alle modelfragmenten in het model.
	// Return-waarde is een array van ID-strings, of null wanneer er geen lijst bepaald kon worden.
	//
	public list(): string[] {
		if (this.privates.glWrap) {
			let IDs: string[] = [];
			for (let i: number = 0; i < this.privates.fragmenten.length; i++) {
				IDs.push(this.privates.fragmenten[i].ID);
			}
			return IDs;
		}
		return null;
	};

	//
	// Maakt het modelfragment met het aangegeven ID zichtbaar.
	// Parameter 'fragmentID' is de ID-string van het zichtbaar te maken modelfragment.
	// Optionele parameter 'zonderSlagschaduw' is een boolean die aangeeft dat het modelfragment geen slagschaduw moet 
	// werpen. Bij afwezigheid werpt het modelfragment een slagschaduw.
	//
	public show(fragmentID: string, zonderSlagschaduw: boolean): void {
		if (this.privates.glWrap && typeof (fragmentID) == 'string') {
			let fragment: VLib.FragmentType = this.find(fragmentID);
			if (fragment == null) {
				this.conditionalLog(fragmentID, 7);
				return;
			}

			fragment.isZichtbaar = true;
			fragment.moetGetekend = true;
			let metSlagschaduw: boolean = (zonderSlagschaduw !== true);
			if (fragment.werptSlagschaduw != metSlagschaduw) {
				fragment.werptSlagschaduw = metSlagschaduw;
				this.wisShadowMaps();
			}
			if (fragment.transLijst) {
				fragment.transLijst.cacheTekenCyclus = undefined;
			}
		}
	};

	//
	// Maakt het modelfragment met het aangegeven ID onzichtbaar.
	// Parameter 'fragmentID' is de ID-string van het zichtbaar te maken modelfragment.
	// Optionele parameter 'metSlagschaduw' is een boolean die aangeeft dat het modelfragment toch een slagschaduw moet 
	// werpen. Bij afwezigheid werpt het modelfragment geen slagschaduw.
	//
	// Past de selectie niet aan.
	//
	public hide(fragmentID: string, metSlagschaduw: boolean): void {
		if (this.privates.glWrap && typeof (fragmentID) == 'string') {
			let fragment: VLib.FragmentType = this.find(fragmentID);
			if (fragment == null) {
				this.conditionalLog(fragmentID, 7);
				return;
			}

			fragment.isZichtbaar = false;
			fragment.moetGetekend = false;
			metSlagschaduw = (metSlagschaduw === true);
			if (fragment.werptSlagschaduw != metSlagschaduw) {
				fragment.werptSlagschaduw = metSlagschaduw;
				this.wisShadowMaps();
			}
			if (fragment.transLijst) {
				fragment.transLijst.cacheTekenCyclus = undefined;
			}
		}
	};

	//
	// Voegt een transformatielijst toe aan modelfragmenten.
	// Parameter 'transformatielijstStr' is de vervangende transformatielijst geserialiseerd in de vorm van een JSON-tekst. 
	// Bij waarde null worden bestaande transformatielijsten vervangen door lege.
	// Parameter 'fragmentIDs' is een ID-string of een array van ID-strings van de modelfragmenten waaraan de 
	// transformatielijst gekoppeld moet worden.
	// Return-waarde is een getal dat aangeeft hoe vaak de vervanging is uitgevoerd, of null wanneer de vervanging niet 
	// is geslaagd.
	//
	// Kan het selectie-veranderd event afvuren.
	//
	public replaceTrans(transformatielijstStr: string, fragmentIDs: V3DStringOfStringArrayType): number {
		if (this.privates.glWrap && fragmentIDs != null) {
			if (transformatielijstStr != null && typeof (transformatielijstStr) != 'string') {
				return null;
			}
			let fragmentIDsArray: string[];
			if (Array.isArray(fragmentIDs) == false) {
				if (typeof fragmentIDs != 'string') { throw 'fragmentIDs is geen string en geen string[]' };
				fragmentIDsArray = [fragmentIDs];
			}
			else {
				if (fragmentIDs.length == 0) {
					return null;
				}
				fragmentIDsArray = <string[]>fragmentIDs;
			}

			let transLijstJson: VLib.TransLijstJsonType = null;
			let transformatielijstWrapJson: VLib.TransLijstWrapJsonType = null;
			if (transformatielijstStr != null) {
				try {
					transformatielijstWrapJson = JSON.parse(transformatielijstStr);
				}
				catch (exc) {
					this.conditionalLog(exc, 1);
					return null;
				}
				if (transformatielijstWrapJson == null || transformatielijstWrapJson.transLijst == null) {
					this.conditionalLog('Transformatielijst is niet aanwezig.', 11);
					return null;
				}
				transLijstJson = transformatielijstWrapJson.transLijst;
			}
			try {
				this.controleerTransformatielijstJson(transLijstJson);
			}
			catch (exc) {
				this.conditionalLog(exc, 11);
				return null;
			}
			let transLijst: VLib.TransLijstType = this.initTransformatielijst(transLijstJson);

			let selectieIDsNieuw: VLib.SelectieIDInternType[];
			let aantal: number = 0;
			for (let i: number = 0; i < fragmentIDsArray.length; i++) {
				let fragmentID: string = fragmentIDsArray[i];
				if (typeof (fragmentID) == 'string') {
					let fragment: VLib.FragmentType = this.find(fragmentID);
					if (fragment == null) {
						this.conditionalLog(fragmentID, 7);
						continue;
					}

					fragment.transLijst = transLijst;
					fragment.bbTrans = null;
					this.berekenFragmentDimensies(fragment);

					let gevonden: number = this.findIndexInSelectie(this.privates.selectieIDs, fragmentID);
					if (gevonden != -1) {
						//
						// Het fragment zit in de selectie.
						//
						if (transLijst && this.privates.selectieIDs[gevonden].transLijstIndices) //@@@Q bugfix: was "translijst" (met kleine letter L).
						{
							//
							// Het fragment heeft een transformatielijst en niet alle transformaties in die transformatielijst 
							// zijn geselecteerd.
							// Omdat de te vervangen transformatielijst en de nieuwe transformatielijst mogelijk incongruent 
							// zijn, hetgeen tot moeilijk te ontraadselen selectiegedrag kan leiden, wordt het fragment 
							// gedeselecteerd:
							//
							selectieIDsNieuw = selectieIDsNieuw || this.selectie;
							//
							// Onderstaande uitgecommentarieerde regel is een bug.
							// 'gevonden' is de plek in this.privates.selectieIDs. 
							// Het bijbehorende item in selectieIDsNieuw kan op andere plek zitten als er al eerder in selectieIDsNieuw geknipt is.
							// Oplossing: hier nog niet knippen, maar alleen gevonden item op null zetten.
							// Pas later de null-items er uit knippen.
							//
							//selectieIDsNieuw.splice(gevonden, 1); // Is bug!
							selectieIDsNieuw[gevonden] = null;
						}
					}

					aantal++;
				}
			}
			if (aantal > 0) {
				this.privates.modelDims = undefined;

				if (selectieIDsNieuw) {
					//
					// De null-items er uit knippen.
					// Hiervoor het array achterwaarts doorlopen.
					//
					for (let i: number = selectieIDsNieuw.length - 1; i >= 0; i--) {
						if (selectieIDsNieuw[i] == null) {
							selectieIDsNieuw.splice(i, 1);
						}
					}
					this.updateSelectieEnMeld(selectieIDsNieuw, false);
				}

				return aantal;
			}
			return null;
		}
		return null;
	};

	//
	// Voegt een transformatielijst toe aan modelfragmenten.
	// Parameter 'transformatielijstBestandURL' is de bestandsnaam met pad van het bestand met de transformatielijst in 
	// de vorm van een JSON-tekst.
	// Parameter 'fragmentIDs' is een ID-string of een array van ID-strings van de modelfragmenten waaraan de 
	// transformatielijst gekoppeld moet worden.
	// Parameter 'gereedCallback' is een callback die wordt aangeroepen zodra het verwerken van de transformatielijst 
	// gereed is. Een callback is nodig omdat het verwerken van de transformatielijst asynchroon verloopt. De callback 
	// heeft één parameter: een getal dat aangeeft hoe vaak de vervanging is uitgevoerd, of null wanneer de vervanging 
	// niet is geslaagd.
	//
	// Kan het selectie-veranderd event afvuren.
	//
	public replaceTransFromFile(transformatielijstBestandURL: string, fragmentIDs: V3DStringOfStringArrayType, gereedCallback: V3DAantalCallbackType): void {
		if (this.privates.glWrap && typeof (gereedCallback) == 'function') {
			let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
			let geladenTekstCallback: VLib.GeladenTekstCallbackType = function (geladenTekst: string): void {
				if (geladenTekst == null) {
					gereedCallback(null);
					return;
				}
				let aantal: number = _this.replaceTrans(geladenTekst, fragmentIDs);
				gereedCallback(aantal);
			};
			this.loadTextFromFile(transformatielijstBestandURL, geladenTekstCallback);
		}
	};

	//
	// Vervangt een ingredient van de opmaak in een modelfragment.
	// Parameters 'van' en 'naar' zijn respectievelijk de waarde van het betreffende opmaak-ingredient waarna gezocht 
	// moet worden en de waarde die de huidige moet vervangen. Is ofwel een kleur, in de vorm van een array met lengte 4 
	// met waarden in het bereik [0, 255], ofwel een textuur, in de vorm van een string met de bestandsnaam (met pad) 
	// van de bitmap.
	// Parameter 'fragmentID' is de ID-string van het modelfragment waarin het opmaak-ingredient vervangen moet worden.
	// Parameter 'gereedCallback' is een callback die wordt aangeroepen zodra het vervangen van het opmaak-ingredient 
	// gereed is. Een callback is nodig omdat het verwerken van de vervanging mogelijk asynchroon verloopt. De callback 
	// heeft één parameter: een getal dat aangeeft hoe vaak de vervanging is uitgevoerd, of null wanneer de vervanging 
	// niet is geslaagd.
	// Optionele parameter 'vanOrigineleKleur' is een boolean die aangeeft of er gezocht moet worden naar de originele 
	// kleur (true) of naar de huidige kleur (false; default).
	// Optionele parameter 'isMengKleur' is een boolean die aangeeft of het een mengkleur betreft (true) of niet (false; 
	// default).
	//
	public replaceStyle(van: V3DStringOfNumberArrayType, naar: V3DStringOfNumberArrayType, fragmentID: string, gereedCallback: V3DAantalCallbackType, vanOrigineleKleur: boolean, isMengKleur: boolean): void {
		if (this.privates.glWrap && typeof (gereedCallback) == 'function') {
			if (typeof (van) == 'string') {
				this.vervangTextuur(van, <string>naar, fragmentID, gereedCallback);
			}
			else {
				let aantal: number = null;
				if (isMengKleur === true) {
					aantal = this.vervangMengKleur(van, <number[]>naar, fragmentID, vanOrigineleKleur);
				}
				else {
					aantal = this.vervangKleur(van, <number[]>naar, fragmentID, vanOrigineleKleur);
				}
				gereedCallback(aantal);
			}
		}
	};

	//
	// Ververst de view. Te gebruiken om aangepaste settings door te voeren (met uitzondering van de functie 
	// 'setAnimatie' die verversing zelf verzorgt).
	//
	public toonModel(): void {
		if (this.privates.glWrap) {
			//
			// NB: Bij animatie gaat het verversen vanzelf.
			//
			if (this.privates.isGeanimeerd == false && this.privates.isGelust == false) {
				//this.showOrientation("before calling teken"); //@@@QF
				this.teken();
				//this.showOrientation("after  calling teken"); //@@@QF
			}
		}
	};

	//
	// Geeft de dimensies van het model, dat wil zeggen van alle modelfragmenten tezamen.
	// Return-waarde is een object met de dimensies, of null wanneer het model leeg is.
	//
	private getModelDimensies(): VLib.ModelDimensiesType {
		if (this.privates.modelDims == undefined) {
			this.privates.modelDims = this.berekenModelDimensies();
		}
		return this.privates.modelDims;
	};

	//
	// Stelt de achtergrondkleur van de view in.
	// Parameter 'RGB' is een array met lengte 3 (R-, G-, B-componenten) met waarden in het bereik [0, 255].
	//
	public set achtergrondKleur(RGB: number[]) {
		if (this.privates.glWrap && VLib.Lib.isGetalLijst(RGB, 3, 0, 255)) {
			this.privates.achtergrondKleur = RGB.slice();
			this.privates.glWrap.gl.clearColor(this.privates.achtergrondKleur[0] / 255.0,
				this.privates.achtergrondKleur[1] / 255.0, this.privates.achtergrondKleur[2] / 255.0, 1.0);
		}
	};
	//
	// Geeft de huidige achtergrondkleur van de view.
	// Return-waarde is een array met lengte 3 (R-, G-, B-componenten) met waarden in het bereik [0, 255].
	//
	public get achtergrondKleur(): number[] {
		return this.privates.achtergrondKleur.slice();
	};

	//
	// Stelt in of het assenstelsel getoond moet worden in de view.
	// Parameter 'value' is een boolean.
	//
	public set toonAssen(value: boolean) {
		if (this.privates.glWrap && typeof (value) == 'boolean') {
			if (value != this.privates.toonAssen) {
				this.privates.toonAssen = value;
			}
		}
	};
	//
	// Geeft aan of het assenstelsel getoond wordt in de view.
	// Return-waarde is een boolean.
	//
	public get toonAssen(): boolean {
		return this.privates.toonAssen;
	};

	//
	// Stelt het toe te passen cameratype in.
	// Parameter 'value' is een boolean, waarbij: 
	//   waarde 'true' staat voor een firmamentcamera; 
	//   waarde 'false' staat voor een terreincamera. 
	// Bij een firmamentcamera bevindt de camera zich buiten het model (als het ware, aan het firmament); bij een 
	// terreincamera bevindt de camera zich te midden van het model.
	//
	public set camera(value: boolean) {
		if (this.privates.glWrap && typeof (value) == 'boolean') {
			if (value != this.privates.cameraType) {
				this.privates.cameraType = value;

				let fragmenten: VLib.FragmentType[] = this.privates.fragmenten;
				for (let i: number = 0; i < fragmenten.length; i++) {
					let fragment: VLib.FragmentType = fragmenten[i];
					fragment.moetGetekend = fragment.isZichtbaar;
				}

				this.setLus(false);
			}
		}
	};
	//
	// Geeft het toegepaste cameratype.
	// Return-waarde is een boolean, waarbij: 
	//   waarde 'true' staat voor een firmamentcamera; 
	//   waarde 'false' staat voor een terreincamera. 
	//
	public get camera(): boolean {
		return this.privates.cameraType;
	};

	//
	// Stelt de toe te passen belichting van het model in.
	// Parameter 'value' is een belichting-object.
	//
	public set belichting(value: Belichting) {
		if (this.privates.glWrap && value && value instanceof Belichting) {
			this.privates.belichting.read(value);
		}
	};
	//
	// Geeft de toegepaste belichting van het model.
	// Return-waarde is een belichting-object.
	//
	public get belichting(): Belichting {
		return this.privates.belichting;
	};

	//
	// Stelt de toe te passen lampen van het model in.
	// Parameter 'value' is een array van lamp-objecten. Waarde null wist de lijst van lampen.
	//
	public set lampen(value: Lamp[]) {
		if (this.privates.glWrap && this.privates.lampenMaxAantal != null) {
			if (value == null) {
				value = [];
			}

			if (Array.isArray(value)) {
				let nieuweLampen: Lamp[] = [];
				for (let i: number = 0; i < value.length; i++) {
					let item: Lamp = value[i];
					if (item == null || (item instanceof Lamp) == false) {
						//
						// Negeer eventuele ongeldige items in de array:
						//
						continue;
					}
					else {
						let lamp: Lamp = new Lamp();
						lamp.read(item);
						//
						// NB: Door een kopie te maken van de lamp wordt deze gemarkeerd als 'vuil'.
						//
						if (nieuweLampen.push(lamp) == this.privates.lampenMaxAantal) {
							//
							// Het maximale aantal lampen is bereikt:
							//
							break;
						}
					}
				}

				if (this.privates.lampen != null) {
					for (let i: number = 0; i < this.privates.lampen.length; i++) {
						this.wisShadowMapVanLamp(this.privates.lampen[i]);
					}
					for (let i: number = nieuweLampen.length; i < this.privates.lampenMaxAantal; i++) {
						this.doofLampen(i);
					}
				}

				if (nieuweLampen.length == 0) {
					this.privates.lampen = undefined;
				}
				else {
					this.privates.lampen = nieuweLampen;
				}
			}
		}
	};
	//
	// Geeft de toegepaste lampen van het model.
	// Return-waarde is een array van lamp-objecten.
	//
	public get lampen(): Lamp[] {
		if (this.privates.lampenMaxAantal != null && this.privates.lampen != null) {
			return this.privates.lampen.slice();
		}
		else {
			return [];
		}
	};

	//
	// Stelt de toe te passen hemel van het model in. 
	// Parameter 'bestandsnaam' is een string met een bestandsnaam van een bitmap. 
	// Optionele parameter 'bereik' is een float in het bereik [90, 180]. 
	// Optionele parameter 'mercatorProjectie' is een boolean.
	// De hemel wordt gewist wanneer er geen variabelen worden aangeleverd.
	// Parameter 'gereedCallback' is een parameterloze callback die wordt aangeroepen zodra het aanmaken van de hemel 
	// gereed is. Deze parameter is optioneel maar gebruik ervan wordt aanbevolen omdat het aanmaken van de hemel 
	// asynchroon verloopt.
	//
	public zetHemel(bestandsnaam: string, bereik: number, mercatorProjectie: boolean, gereedCallback: V3DSimpelCallbackType): void {
		if (this.privates.glWrap) {
			if (typeof (bestandsnaam) == 'string' &&
				(bereik == null || VLib.Lib.isGetal(bereik)) &&
				(mercatorProjectie == null || typeof (mercatorProjectie) == 'boolean')) {
				this.maakSkyDome(bestandsnaam, bereik, mercatorProjectie, gereedCallback);
			}
			else {
				this.wisHemel();
				if (gereedCallback) {
					gereedCallback();
				}
			}
		}
	};

	//
	// Stelt de kompasrichting van de Y-as van het assenstelsel van het model in, kloksgewijs vanaf Noord in graden.
	// Parameter 'value' is een float.
	//
	public set yAsTovNoord(value: number) {
		if (this.privates.glWrap && VLib.Lib.isGetal(value)) {
			this.privates.yAsTovNoord = value;
		}
	};
	//
	// Geeft de kompasrichting van de Y-as van het assenstelsel van het model, kloksgewijs vanaf Noord in graden.
	// Return-waarde is een float. 
	//
	public get yAsTovNoord(): number {
		return this.privates.yAsTovNoord;
	};

	//
	// Stelt in of de view orthografische (parallele) projectie (true) of perspectieve projectie (false) gebruikt.
	// Parameter 'value' is een boolean. 
	// De ingestelde waarde is alleen betekenisvol in geval van een firmamentcamera.
	//
	public set orthografischeProjectie(value: boolean) {
		if (this.privates.glWrap && typeof (value) == 'boolean') {
			if (value != this.privates.orthografischeProjectie) {
				this.privates.orthografischeProjectie = value;
			}
		}
	};
	//
	// Geeft aan of de view orthografische (parallele) projectie (true) of perspectieve projectie (false) gebruikt.
	// Return-waarde is een boolean. 
	// De ingestelde waarde is alleen betekenisvol in geval van een firmamentcamera.
	//
	public get orthografischeProjectie(): boolean {
		return this.privates.orthografischeProjectie;
	};

	//
	// Stelt de rolhoek in graden in. De hoek om de kijkrichting, waarbij met de wijzers van de klok mee positief 
	// is en waarbij omhoog nul is.
	// Parameter 'value' is een float.
	//
	public set rolhoek(value: number) {
		if (this.privates.glWrap) {
			this.privates.rolhoek = value;
		}
	}

	//
	// Geeft de rolhoek in graden. De hoek om de kijkrichting, waarbij met de wijzers van de klok mee positief is 
	// en waarbij omhoog nul is.
	// Return-waarde is een float.
	//
	public get rolhoek(): number {
		return this.privates.rolhoek;
	}

	//
	// Stelt de kijkrichting van de camera in.
	// Parameter 'vector' is een array van floats met lengte 3 (x-, y- en z-componenten).
	//
	public set kijkrichting(vector: number[]) {
		if (this.privates.glWrap && VLib.Lib.isVector(vector, false)) {
			let hoeken: number[] = VLib.Lib.vanVectorNaarHoeken(vector);
			this.privates.richting = hoeken[0];
			this.privates.helling = hoeken[1];
		}
	};
	//
	// Geeft de kijkrichting van de camera.
	// Return-waarde is een array van floats met lengte 3 (x-, y- en z-componenten).
	//
	public get kijkrichting(): number[] {
		let vector: number[] = VLib.Lib.vanHoekenNaarVector(this.kijkhoeken);
		return vector;
	};

	//
	// Stelt de kijkrichting van de camera in.
	// Parameter 'hoeken' is een array van floats met lengte 2 (richting en helling componenten).
	//
	public set kijkhoeken(hoeken: number[]) {
		if (this.privates.glWrap && VLib.Lib.isGetalLijst(hoeken, 2)) {
			this.privates.richting = hoeken[0];
			this.privates.helling = hoeken[1];
		}
	};
	//
	// Geeft de kijkrichting van de camera.
	// Return-waarde is een array van floats met lengte 2 (richting en helling componenten).
	//
	public get kijkhoeken(): number[] {
		return [this.privates.richting, this.privates.helling];
	};

	//
	// Stelt de zoomfactor in.
	// Parameter 'value' is een getal.
	//
	public set zoomSchaal(value: number) {
		this.privates.zoomSchaal = value;
	};
	//
	// Geeft de zoomfactor.
	// Return-waarde is een getal.
	//
	public get zoomSchaal(): number {
		return this.privates.zoomSchaal;
	};
	//
	// Neemt een omgeschreven blok om op in te zoomen.
	// Parameter 'value' is een array van twee of meer punten (elk een array van floats met lengte 3 
	// (x-, y- en z-componenten)) die een omgeschreven blok opspannen om op in te zoomen.
	// Deze functie is alleen geldig in geval van een firmamentcamera.
	//
	public zetZoom(value: number[][]): void {
		//console.log("zetZoom called|#value=" + value.length); //@@@QF
		if (this.privates.cameraType === false) // geen firmamentcamera
		{
			return;
		}

		let modelDims: VLib.ModelDimensiesType = this.getModelDimensies();
		if (this.privates.glWrap && modelDims && Array.isArray(value)) {
			for (let i: number = value.length - 1; i >= 0; i--) {
				//
				// Verwijder eventuele ongeldige items in de punten-array:
				//
				if (VLib.Lib.isVector(value[i], true) == false) {
					value.splice(i, 1);
				}
			}
			if (value.length < 2)
				return;

			let inf: number = 1e10;
			let schermMin: number[] = [inf, inf];
			let schermMax: number[] = [-inf, -inf];
			for (let i: number = 0; i < value.length; i++) {
				//
				// Bepaal de schermcoordinaten van het punt:
				//
				let schermCoor: number[] = this.converteerNaarSchermCoordinaten(value[i]);
				schermMin[0] = Math.min(schermMin[0], schermCoor[0]);
				schermMax[0] = Math.max(schermMax[0], schermCoor[0]);
				schermMin[1] = Math.min(schermMin[1], schermCoor[1]);
				schermMax[1] = Math.max(schermMax[1], schermCoor[1]);
			}
			//
			// Bepaal de verhouding van het zoomgebied en het gebied van de view:
			//
			let viewportBreedte: number = this.privates.glWrap.gl.drawingBufferWidth;
			let viewportHoogte: number = this.privates.glWrap.gl.drawingBufferHeight;
			let zoomRatio: number = Math.max((schermMax[0] - schermMin[0]) / viewportBreedte,
				(schermMax[1] - schermMin[1]) / viewportHoogte);
			let epsilon: number = 1e-9;
			if (zoomRatio < epsilon)
				//
				// In geval van een zeer klein opgespannen blok, bijvoorbeeld bij 
				// een model met één punt:
				//
				zoomRatio = epsilon;
			this.privates.zoomSchaal = 1.0 / zoomRatio;
			//
			// Verschuif het centrum van het zoomgebied naar het midden van de view:
			//
			let schuifBasis: number = 1.0 / Math.min(viewportBreedte, viewportHoogte);
			this.privates.verschuivingRechts = -schuifBasis * (schermMin[0] + schermMax[0] - viewportBreedte);
			this.privates.verschuivingOmhoog = -schuifBasis * (schermMin[1] + schermMax[1] - viewportHoogte);
		}
	};
	//
	// Stelt de zoom in waarbij het model geheel in beeld is.
	// Deze functie is alleen geldig in geval van een firmamentcamera.
	//
	public zetZoomGeheel(): void {
		if (this.privates.cameraType === false) // geen firmamentcamera
		{
			return;
		}

		let modelDims: VLib.ModelDimensiesType = this.getModelDimensies();
		if (this.privates.glWrap && modelDims) {
			//
			// Bepaal de acht hoekpunten van het omvattende blok:
			//
			let afmetingen: number[] = modelDims.afmetingen;
			let centrum: number[] = modelDims.centrum;

			let cZoomSchaalGeheel: number = 1.1;
			let minsEnMaxs: number[][] = [];
			for (let i: number = 0; i < 3; i++) {
				minsEnMaxs.push([
					centrum[i] - afmetingen[i] * cZoomSchaalGeheel / 2.0, 	// min
					centrum[i] + afmetingen[i] * cZoomSchaalGeheel / 2.0]);	// max
			}
			let hoeken: number[][] = [];
			for (let i: number = 0; i < 8; i++) {
				hoeken.push([
					minsEnMaxs[0][Math.floor(i / 4)], 		// x
					minsEnMaxs[1][Math.floor(i / 2) % 2], 	// y
					minsEnMaxs[2][i % 2]]);					// z
			}

			this.zetZoom(hoeken);
		}
	};
	//
	// Stelt een standaard kijkrichting en zoom in. De uitwerking hiervan verschilt per cameratype.
	//
	public zetZichtStandaard(): void {
		//console.log("zetZichtStandaard called"); //@@@QF
		let modelDims: VLib.ModelDimensiesType = this.getModelDimensies();
		if (this.privates.glWrap && modelDims) {
			if (this.privates.cameraType === true) // firmamentcamera
			{
				this.zetZoomGeheel();
			}
			else // terreincamera
			{
				this.zoomSchaal = 1.0;
				let centrum: number[] = modelDims.centrum;
				let positie: number[] = this.privates._positie;
				let naarCentrum: number[] = [centrum[0] - positie[0], centrum[1] - positie[1], centrum[2] - positie[2]];
				if (VLib.Lib.isVector(naarCentrum, false) == false) {
					naarCentrum = [1, 0, 0];
				}
				this.kijkrichting = naarCentrum;
			}
		}
	};

	//
	// Stelt een punt in waarop de view gecentreerd moet worden.
	// Parameter 'punt' is een array van floats met lengte 3 (x-, y- en z-componenten).
	// Deze functie is alleen geldig in geval van een firmamentcamera.
	//
	public set centrum(punt: number[]) {
		if (this.privates.cameraType === false) // geen firmamentcamera
		{
			return;
		}

		let modelDims: VLib.ModelDimensiesType = this.getModelDimensies();
		if (this.privates.glWrap && modelDims && VLib.Lib.isVector(punt, true)) {
			//
			// Bepaal de schermcoordinaten van het punt:
			//
			let schermCoor: number[] = this.converteerNaarSchermCoordinaten(punt);
			//
			// Verschuif het centrum van het zoomgebied naar het midden van de view:
			//
			let viewportBreedte: number = this.privates.glWrap.gl.drawingBufferWidth;
			let viewportHoogte: number = this.privates.glWrap.gl.drawingBufferHeight;
			let schuifBasis: number = 1.0 / Math.min(viewportBreedte, viewportHoogte);
			this.privates.verschuivingRechts = -schuifBasis * (2.0 * schermCoor[0] - viewportBreedte);
			this.privates.verschuivingOmhoog = -schuifBasis * (2.0 * schermCoor[1] - viewportHoogte);
		}
	};
	//
	// Geeft het punt waarop de view gecentreerd is.
	// Return-waarde is een array van floats met lengte 3 (x-, y- en z-componenten).
	// Deze functie is alleen geldig in geval van een firmamentcamera.
	//
	public get centrum(): number[] {
		if (this.privates.cameraType === false) // geen firmamentcamera
		{
			return undefined;
		}

		if (this.privates.glWrap) {
			let modelDims: VLib.ModelDimensiesType = this.getModelDimensies();
			if (modelDims) {
				//
				// Bepaal de ruimtecoordinaten van het middelpunt van de view:
				//
				let viewportBreedte: number = this.privates.glWrap.gl.drawingBufferWidth;
				let viewportHoogte: number = this.privates.glWrap.gl.drawingBufferHeight;
				let schuifBasis: number = Math.min(viewportBreedte, viewportHoogte);
				let schermCoor: number[] =
					[0.5 * (-this.privates.verschuivingRechts * schuifBasis + viewportBreedte),
					0.5 * (-this.privates.verschuivingOmhoog * schuifBasis + viewportHoogte)];

				//return this.converteerVanSchermCoordinaten(schermCoor, this.privates._zoomSchaal); //@@@Q gewijzigd naar:
				return this.converteerVanSchermCoordinaten(schermCoor, false, true);
			}
			else {
				return [0, 0, 0];
			}
		}
		return null;
	};

	//
	// Centreert het blikveld op het centrum van het model. Laat de zoomfactor en de draaiing ongemoeid.
	// Deze functie is alleen geldig in geval van een firmamentcamera.
	//
	private zetCentrumStandaard(): void { //@@@1 Niet in gebruik.
		if (this.privates.cameraType === false) // geen firmamentcamera
		{
			return;
		}

		if (this.privates.glWrap) {
			this.privates.verschuivingRechts = 0.0;
			this.privates.verschuivingOmhoog = 0.0;
		}
	};

	//
	// Zie function setPositie hieronder.
	// positie wordt gebruikt als set-property in function Viewer3D, en mag dan maar exact één parameter hebben. //@@@Q1 naam was: setPositie_Property, als intern bedoelde functie.
	//
	public set positie(punt: number[]) { //@@@Q1 naam was: setPositie_Property, als intern bedoelde functie.
		this.setPositie(punt, false);
	}

	//
	// Stelt de positie van de camera in.
	// Parameter 'punt' is een array van floats met lengte 3 (x-, y- en z-componenten).
	// Optionele parameter 'isBeweging' is een boolean die aangeeft of het een camerabeweging vanuit de huidige positie 
	// behelst (true) of een volledige herplaatsing (false; default).
	// Return-waarde is doorgaans true, maar is false wanneer alle aanhangige verwerking geannuleerd dient te worden.
	// Deze functie is alleen geldig in geval van een terreincamera.
	//
	private setPositie(punt: number[], isBeweging: boolean): boolean {
		if (this.privates.cameraType === true) // geen terreincamera
		{
			return true;
		}

		if (this.privates.glWrap && VLib.Lib.isVector(punt, true)) {
			if (this.privates.positieAanpassen) {
				let huidigPunt: number[] = (isBeweging === true) ? this.privates.positie : null;
				let puntOfBool: V3DPuntOfBoolType = this.privates.positieAanpassen(huidigPunt, punt); // NB: Kan geen true opleveren. (null kan wel)
				if (!puntOfBool) { // puntOfBool is null of false.
					//
					// De waarde is 'falsy'.
					//
					if (puntOfBool === false) {
						//
						// Alle aanhangige verwerking moet worden geannuleerd (inclusief de uiteindelijke verversing van de 
						// view):
						//
						return false;
					}
					else { // puntOfBool is null.
						//
						// Aanpassing van de positie moet worden geannuleerd (maar niet de uiteindelijke verversing van de 
						// view):
						//
						return true;
					}
				}
				else { // puntOfBool is een number[] (niet null) // En kan ook niet true zijn.
					punt = <number[]>puntOfBool;
				}
			}

			this.privates.positie = punt;
		}
		return true;
	};
	//
	// Geeft de positie van de camera.
	// Return-waarde is een array van floats met lengte 3 (x-, y- en z-componenten).
	// Deze functie is alleen geldig in geval van een terreincamera.
	//
	public get positie(): number[] {
		if (this.privates.cameraType === true) // geen terreincamera
		{
			return undefined;
		}

		if (this.privates.glWrap) {
			return this.privates.positie;
		}
		return null;
	};

	//
	// Stelt in of de view gelust (i.e. geanimeerd in lus-iteraties) moet worden.
	// Parameter 'value' is een boolean.
	//
	private setLus(value: boolean): void {
		if (this.privates.glWrap && typeof (value) == 'boolean') {
			if (value != this.privates.isGelust) {
				this.privates.isGelust = value;
				if (this.privates.isGelust) {
					VLib.Lib.registerEvent(this.privates.canvasWrap.toetsAfvanger, 'keyup', this.handleKeyUp, this.privates.eventListenerOpties);
					VLib.Lib.registerEvent(this.privates.canvasWrap.toetsAfvanger, 'blur', this.handleBlur, this.privates.eventListenerOpties);

					//
					// Start de lus-iteraties:
					//
					this.lusInit();
					this.privates.lusVorigeTijd = Date.now(); // in ms
					if (this.privates.isGeanimeerd == false) {
						this.teken();
					}
				}
				else {
					VLib.Lib.unregisterEvent(this.privates.canvasWrap.toetsAfvanger, 'keyup', this.handleKeyUp, this.privates.eventListenerOpties);
					VLib.Lib.unregisterEvent(this.privates.canvasWrap.toetsAfvanger, 'blur', this.handleBlur, this.privates.eventListenerOpties);
					//
					// Stop de lus-iteraties:
					//
					this.lusReset();
					this.privates.lusVorigeTijd = undefined;
				}
			}
		}
	};
	//
	// Geeft aan of de view gelust (i.e. geanimeerd in lus-iteraties) is.
	// Return-waarde is een boolean.
	//
	private getLus(): boolean { //@@@Q1 Niet in gebruik.
		return this.privates.isGelust;
	};

	//
	// Stelt in of de view geanimeerd moet worden. Parameter 'value' is een boolean.
	//
	public set animatie(value: boolean) {
		if (this.privates.glWrap && typeof (value) == 'boolean') {
			if (this.privates.animatieCallback == null) {
				value = false;
			}

			if (value != this.privates.isGeanimeerd) {
				this.privates.isGeanimeerd = value;
				if (this.privates.isGeanimeerd) {
					//
					// (Her)start de animatie:
					//
					this.privates.animatieStartTijd = undefined;
					this.privates.animatieVorigeTijd = undefined;
					if (this.privates.isGelust == false) {
						this.teken();
					}
				}
			}
		}
	};
	//
	// Geeft aan of de view geanimeerd is.
	// Return-waarde is een boolean.
	//
	public get animatie(): boolean {
		return this.privates.isGeanimeerd;
	};

	//
	// Stelt in of een animatie van de view opgeschort moet worden tijdens muisnavigatie.
	// Parameter 'value' is een boolean.
	//
	public set animatieOnderbreken(value: boolean) {
		if (this.privates.glWrap && typeof (value) == 'boolean') {
			this.privates.animatieOnderbreken = value;
		}
	};
	//
	// Geeft aan of een animatie van de view opgeschort moet worden tijdens muisnavigatie.
	// Return-waarde is een boolean.
	//
	public get animatieOnderbreken(): boolean {
		return this.privates.animatieOnderbreken;
	};

	//
	// Stelt de callback-functie voor de animatie van de view in. 
	// Parameter 'animatieCallback' is een functie met als eerste parameter het HTML canvas-element waarvan de viewer 
	// is gemaakt, als tweede de verstreken tijd in ms sinds de vorige animatiestap en als derde de verstreken tijd 
	// in ms sinds de start van de animatie. 
	// De functie wordt onthouden tot een andere of waarde null wordt aangeleverd.
	//
	public set animatieCallback(animatieCallback: V3DAnimatieCallbackType) {
		if (animatieCallback && typeof (animatieCallback) == 'function') {
			this.privates.animatieCallback = animatieCallback;
		}
		else {
			this.privates.animatieCallback = undefined;
			this.animatie = false;
		}
	};

	//
	// geeft de callback-functie voor de animatie van de view. 
	// Return-waarde is een functie, of null.
	//
	public get animatieCallback(): V3DAnimatieCallbackType {
		return this.privates.animatieCallback;
	};

	//
	// Stelt de callback-functie voor keypress in. 
	// Parameter 'keyPressCallback' is een functie met als enige parameter de numerieke code van de pressed key.
	// De functie wordt onthouden tot een andere of waarde null wordt aangeleverd.
	//
	public set keyPressCallback(keyPressCallback: V3DPressedKeyCallbackType) {
		if (keyPressCallback && typeof (keyPressCallback) == 'function') {
			this.privates.keyPressCallback = keyPressCallback;
		}
		else {
			this.privates.keyPressCallback = undefined;
		}
	};

	//
	// geeft de callback-functie voor de animatie van de view. 
	// Return-waarde is een functie, of null.
	//
	public get keyPressCallback(): V3DPressedKeyCallbackType {
		return this.privates.keyPressCallback;
	};

	//
	// Sets the callback-function for mouse move in 'move drawing elements modus'.
	// Parameter 'moveDrawingElementsModusMouseMoveCallback' is a function with parameters: the ID of the fragment, and the coordinates of a point in space.
	// The function is kept until an other function or null is set.
	//
	public set moveDrawingElementsModusMouseMoveCallback(moveDrawingElementsModusMouseMoveCallback: V3MoveDrawingElementsModusMouseMoveCallback) {
		if (moveDrawingElementsModusMouseMoveCallback) {
			this.privates.moveDrawingElementsModusMouseMoveCallback = moveDrawingElementsModusMouseMoveCallback;
		}
		else {
			this.privates.moveDrawingElementsModusMouseMoveCallback = undefined;
			if (this.privates.workModus == eViewerWorkModus.moveDrawingElements) {
				this.privates.workModus = eViewerWorkModus.normal;
			}
		}
	};

	//
	// Gives the callback-function for mouse move in 'move drawing elements modus'.
	// Return-value is a function, or null.
	//
	public get moveDrawingElementsModusMouseMoveCallback(): V3MoveDrawingElementsModusMouseMoveCallback {
		return this.privates.moveDrawingElementsModusMouseMoveCallback;
	};

	//
	// Sets the callback-function for 'create drawing modus'.
	// Parameter 'createDrawingModusCallback' is a function with parameters: the ID of the drawing, the mouse event kind, and the coordinates of a point in space.
	// The function is kept until an other function or null is set.
	//
	public set createDrawingModusCallback(createDrawingModusCallback: V3CreateDrawingModusCallback) {
		if (createDrawingModusCallback) {
			this.privates.createDrawingModusCallback = createDrawingModusCallback;
		}
		else {
			this.privates.createDrawingModusCallback = undefined;
			if (this.privates.workModus == eViewerWorkModus.createDrawing) {
				this.privates.workModus = eViewerWorkModus.normal;
			}
		}
	};

	//
	// Gives the callback-function for 'create drawing modus'.
	// Return-value is a function, or null.
	//
	public get createDrawingModusCallback(): V3CreateDrawingModusCallback {
		return this.privates.createDrawingModusCallback;
	};

	//
	// Sets the callback-function for 'create drawing modus'.
	// Parameter 'createDrawingModusCallback' is a function with parameters: the ID of the drawing, the mouse event kind, and the coordinates of a point in space.
	// The function is kept until an other function or null is set.
	//
	public set createBridgeLineModusCallback(createBridgeLineModusCallback: V3CreateBridgeLineModusCallback) {
		if (createBridgeLineModusCallback) {
			this.privates.createBridgeLineModusCallback = createBridgeLineModusCallback;
		}
		else {
			this.privates.createBridgeLineModusCallback = undefined;
			if (this.privates.workModus == eViewerWorkModus.createBridgeLine) {
				this.privates.workModus = eViewerWorkModus.normal;
			}
		}
	};

	//
	//
	// Sets the callback-function for 'moved element'.
	// Parameter 'movedElementCallback' is a function with parameters: the ID of the drawing.
	// The function is kept until an other function or null is set.
	//
	public set movedElementCallback(movedElementCallback: V3MovedElementCallback) {
		if (movedElementCallback) {
			this.privates.movedElementCallback = movedElementCallback;
		}
		else {
			this.privates.movedElementCallback = undefined;
		}
	};

	//
	// Gives the callback-function for 'create bridgeLine modus'.
	// Return-value is a function, or null.
	//
	public get createBridgeLineModusCallback(): V3CreateBridgeLineModusCallback {
		return this.privates.createBridgeLineModusCallback;
	};

	//
	// Gives the callback-function for 'moved element'.
	// Return-value is a function, or null.
	//
	public get movedElementCallback(): V3MovedElementCallback {
		return this.privates.movedElementCallback;
	};

	//
	// Sets the callback-function for 'delete shape modus'.
	// Parameter 'deleteShapeModusCallback' is a function with parameter: the ID of the fragment.
	// The function is kept until an other function or null is set.
	//
	public set deleteShapeModusCallback(deleteShapeModusCallback: V3DeleteShapeModusCallback) {
		if (deleteShapeModusCallback) {
			this.privates.deleteShapeModusCallback = deleteShapeModusCallback;
		}
		else {
			this.privates.deleteShapeModusCallback = undefined;
			if (this.privates.workModus == eViewerWorkModus.deleteShape) {
				this.privates.workModus = eViewerWorkModus.normal;
			}
		}
	};

	//
	// Gives the callback-function for 'delete shape modus'.
	// Return-value is a function, or null.
	//
	public get deleteShapeModusCallback(): V3DeleteShapeModusCallback {
		return this.privates.deleteShapeModusCallback;
	};

	//
	// Sets the callback-function for 'delete bridgeLine modus'.
	// Parameter 'deleteBridgeLineModusCallback' is a function with parameter: the ID of the fragment.
	// The function is kept until an other function or null is set.
	//
	public set deleteBridgeLineModusCallback(deleteBridgeLineModusCallback: V3DeleteBridgeLineModusCallback) {
		if (deleteBridgeLineModusCallback) {
			this.privates.deleteBridgeLineModusCallback = deleteBridgeLineModusCallback;
		}
		else {
			this.privates.deleteBridgeLineModusCallback = undefined;
			if (this.privates.workModus == eViewerWorkModus.deleteBridgeLine) {
				this.privates.workModus = eViewerWorkModus.normal;
			}
		}
	};

	//
	// Gives the callback-function for 'delete bridgeLine modus'.
	// Return-value is a function, or null.
	//
	public get deleteBridgeLineModusCallback(): V3DeleteBridgeLineModusCallback {
		return this.privates.deleteBridgeLineModusCallback;
	};

	//
	// Sets the callback-function for 'align modus'.
	// Parameter 'alignModusCallback' is a function with parameter: the ID of the fragment.
	// The function is kept until an other function or null is set.
	//
	public set alignModusCallback(alignModusCallback: V3AlignModusCallback) {
		if (alignModusCallback) {
			this.privates.alignModusCallback = alignModusCallback;
		}
		else {
			this.privates.alignModusCallback = undefined;
			if (this.privates.workModus == eViewerWorkModus.align) {
				this.privates.workModus = eViewerWorkModus.normal;
			}
		}
	};

	//
	// Gives the callback-function for 'align modus'.
	// Return-value is a function, or null.
	//
	public get alignModusCallback(): V3AlignModusCallback {
		return this.privates.alignModusCallback;
	};

	//
	// Sets the callback-function for 'place LibraryObject modus'.
	// Parameter 'placeLibraryObjectCallback' is a function with parameter: a point in space.
	// The function is kept until an other function or null is set.
	//
	public set placeLibraryObjectCallback(placeLibraryObjectCallback: V3PlaceLibraryObjectCallback) {
		if (placeLibraryObjectCallback) {
			this.privates.placeLibraryObjectCallback = placeLibraryObjectCallback;
		}
		else {
			this.privates.placeLibraryObjectCallback = undefined;
			if (this.privates.workModus == eViewerWorkModus.placeLibraryObject) {
				this.privates.workModus = eViewerWorkModus.normal;
			}
		}
	};

	//
	// Gives the callback-function for 'place LibraryObject modus'.
	// Return-value is a function, or null.
	//
	public get placeLibraryObjectCallback(): V3PlaceLibraryObjectCallback {
		return this.privates.placeLibraryObjectCallback;
	};

	//
	// Sets the callback-function for 'place Opening modus'.
	// Parameter 'placeOpeningCallback' is a function with two parameters: the id of the construction and a point in space.
	// The function is kept until an other function or null is set.
	//
	public set placeOpeningCallback(placeOpeningCallback: V3PlaceOpeningCallback) {
		if (placeOpeningCallback) {
			this.privates.placeOpeningCallback = placeOpeningCallback;
		}
		else {
			this.privates.placeOpeningCallback = undefined;
			if (this.privates.workModus == eViewerWorkModus.placeOpening) {
				this.privates.workModus = eViewerWorkModus.normal;
			}
		}
	};

	//
	// Gives the callback-function for 'place Opening modus'.
	// Return-value is a function, or null.
	//
	public get placeOpeningCallback(): V3PlaceOpeningCallback {
		return this.privates.placeOpeningCallback;
	};

	//
	// Sets the callback-function for 'end action modus'.
	// Parameter 'modus' is an enum indication the active mode.
	//
	public set endEditorActionCallback(endEditorActionCallback: V3EndEditorActionCallback) {
		if (endEditorActionCallback) {
			this.privates.endEditorActionCallback = endEditorActionCallback;
		}
		else {
			this.privates.endEditorActionCallback = undefined;
		}
	};

	//
	// Gives the callback-function for 'place LibraryObject modus'.
	// Return-value is a function, or null.
	//
	public get endEditorActionCallback(): V3EndEditorActionCallback {
		return this.privates.endEditorActionCallback;
	};

	//
	// Stelt in of de viewer gebruikersinteractie accepteert.
	// Parameter 'value' is een boolean.
	//
	public set navigeerbaar(value: boolean) {
		if (this.privates.glWrap && typeof (value) == 'boolean') {
			this.privates.navigeerbaar = value;
		}
	};
	//
	// Geeft aan of de viewer gebruikersinteractie accepteert.
	// Return-waarde is een boolean.
	//
	public get navigeerbaar(): boolean {
		return this.privates.navigeerbaar;
	};

	//
	// Stelt het toe te passen wandeltype in.
	// Parameter 'value' is een enumeratiegetal. De mogelijke enumeratiewaarden zijn: 0 = Voortbewegen in discrete 
	// stappen; 1 = Voortbewegen met versnelling/vertraging.
	// Deze functie is alleen geldig in geval van een terreincamera.
	//
	public set wandelType(value: number) {
		if (this.privates.glWrap && VLib.Lib.isGeheelGetal(value) && value >= 0 && value <= 1) {
			this.privates.wandelType = value;
		}
	};
	//
	// Geeft het toegepaste wandeltype.
	// Return-waarde is een enumeratiegetal. De mogelijke enumeratiewaarden zijn: 0 = Voortbewegen in discrete stappen; 
	// 1 = Voortbewegen met versnelling/vertraging.
	// Deze functie is alleen geldig in geval van een terreincamera.
	//
	public get wandelType(): number {
		return this.privates.wandelType;
	};

	//
	// Stelt de grootte van een wandelstap in.
	// Parameter 'value' is een getal (in mm).
	//
	public set wandelStap(value: number) {
		if (this.privates.glWrap && VLib.Lib.isGetal(value)) {
			value = Math.max(value, 1.0);
			value = Math.min(value, 2000.0);
			this.privates.wandelStap = value;
		}
	};
	//
	// Geeft de grootte van een wandelstap.
	// Return-waarde is een getal (in mm).
	//
	public get wandelStap(): number {
		return this.privates.wandelStap;
	};

	//
	// Stelt de wandeltopsnelheid in.
	// Parameter 'value' is een getal (in km/u).
	//
	public set wandelSnelheid(value: number) {
		if (this.privates.glWrap && VLib.Lib.isGetal(value)) {
			value = Math.abs(value);
			value = value * 1000.0 * 1000.0 / 3600.0; // van km/u naar mm/s
			this.privates.wandelSnelheid = value;
		}
	};
	//
	// Geeft de wandeltopsnelheid.
	// Return-waarde is een getal (in km/u).
	//
	public get wandelSnelheid(): number {
		return this.privates.wandelSnelheid * 3600.0 / (1000.0 * 1000.0); // van mm/s naar km/u
	};

	//
	// Stelt de versnellingsfactor voor de wandelsnelheid in.
	// Parameter 'value' is een getal in het bereik [0.5, 5].
	//
	public set wandelVersnellingsfactor(value: number) {
		if (this.privates.glWrap && VLib.Lib.isGetal(value)) {
			value = Math.abs(value);
			value = Math.max(value, 0.5);
			value = Math.min(value, 5.0);
			this.privates.wandelVersnellingsfactor = value;
		}
	};
	//
	// Geeft de versnellingsfactor voor de wandelsnelheid.
	// Return-waarde is een getal.
	//
	public get wandelVersnellingsfactor(): number {
		return this.privates.wandelVersnellingsfactor;
	};

	//
	// Stelt de vertragingsfactor voor de wandelsnelheid in.
	// Parameter 'value' is een getal in het bereik [0.5, 5].
	//
	public set wandelVertragingsfactor(value: number) {
		if (this.privates.glWrap && VLib.Lib.isGetal(value)) {
			value = Math.abs(value);
			value = Math.max(value, 0.5);
			value = Math.min(value, 5.0);
			this.privates.wandelVertragingsfactor = value;
		}
	};
	//
	// Geeft de vertragingsfactor voor de wandelsnelheid.
	// Return-waarde is een getal.
	//
	public get wandelVertragingsfactor(): number {
		return this.privates.wandelVertragingsfactor;
	};

	//
	// Stelt de wijze waarop het wandelen met de camera beperkt wordt in.
	// Parameter 'value' is een functie. 
	// Deze functie is alleen geldig in geval van een terreincamera.
	//
	public set wandelRestrictieAlsFunctie(positieAanpassen: V3DPositieAanpassenFunctieType) {
		if (this.privates.glWrap) {
			if (typeof (positieAanpassen) == 'function') {
				this.privates.wandelRestrictie = 5;
				this.privates.perimeter = undefined;
				this.privates.positieAanpassen = positieAanpassen;
				//
				// Initieer de positie:
				//
				this.setPositie(this.privates.positie, false);
			}
		}
	};

	//
	// Stelt de wijze waarop het wandelen met de camera beperkt wordt in.
	// Parameter 'value' is een Perimeter-object. 
	// Deze functie is alleen geldig in geval van een terreincamera.
	//
	public set wandelRestrictieAlsPerimeter(perimeter: Perimeter) {
		if (this.privates.glWrap) {
			if (perimeter instanceof Perimeter) {
				if (perimeter.isGeldig === true) {
					this.privates.wandelRestrictie = 4;
					this.privates.perimeter = perimeter;
					this.privates.positieAanpassen = perimeter.positieAanpassen;
					//
					// Initieer de positie in de perimeter:
					//
					this.setPositie(this.privates.positie, false);
				}
			}
		}
	};

	//
	// Stelt de wijze waarop het wandelen met de camera beperkt wordt in.
	// Parameter 'value' is een enumeratiegetal. De mogelijke enumeratiewaarden zijn: 
	// 0 = Mobiel; 1 = MobielHorizontaal; 2 = MobielVerticaal; 3 = Immobiel.
	// Deze functie is alleen geldig in geval van een terreincamera.
	//
	public set wandelRestrictie(value: number) {
		if (this.privates.glWrap) {
			if (VLib.Lib.isGeheelGetal(value) && value >= 0 && value <= 3) {
				this.privates.wandelRestrictie = value;
				this.privates.perimeter = undefined;
				this.privates.positieAanpassen = undefined;
			}
		}
	};

	//
	// Geeft de wijze waarop het wandelen met de camera beperkt wordt.
	// Return-waarde is een enumeratiegetal. De mogelijke enumeratiewaarden zijn: 0 = Mobiel; 1 = MobielHorizontaal; 
	// 2 = MobielVerticaal; 3 = Immobiel; 4 = Perimeter; 5 = Custom.
	// Deze functie is alleen geldig in geval van een terreincamera.
	//
	public get wandelRestrictie(): number {
		return this.privates.wandelRestrictie;
	};

	//
	// Stelt de beperking op het bereik van de helling in.
	// Parameter 'value' is een enumeratiegetal. De mogelijke enumeratiewaarden zijn: 0 = Geen; 1 = Horizontaal; 
	// 2 = Half; 3 = Kwart; 4 = KwartOmlaag; 5 = KwartOmhoog.
	//
	public set hellingRestrictie(value: number) {
		if (this.privates.glWrap && VLib.Lib.isGeheelGetal(value) && value >= 0 && value <= 5) {
			if (this.privates.hellingRestrictie != value) {
				this.privates.hellingRestrictie = value;
				//
				// Corrigeer de huidige helling, indien nodig:
				//
				this.privates.helling = this.privates.helling;
			}
		}
	};
	//
	// Geeft de beperking op het bereik van de helling.
	// Return-waarde is een enumeratiegetal.
	//
	public get hellingRestrictie(): number {
		return this.privates.hellingRestrictie;
	};

	//
	// Stelt de kleur waarmee de selectie wordt weergegeven in.
	// Parameters 'RGBA' is een array met lengte 4 (R-, G-, B-, A-componenten) met waarden in het bereik [0, 255].
	//
	public set selectieKleur(RGBA: number[]) {
		if (this.privates.glWrap && VLib.Lib.isGetalLijst(RGBA, 4, 0, 255)) {
			this.privates.selectieKleur = RGBA.slice();
			let kleur: number[] = RGBA.slice();
			if (kleur[3] > 230) {
				kleur[3] = 255;
			}
			else if (kleur[3] < 64) {
				kleur[3] = 64;
			}
			this.privates.selectieKleurIntern = kleur;
			this.privates.selectieKleurInternF = [kleur[0] / 255, kleur[1] / 255, kleur[2] / 255, kleur[3] / 255];
			this.privates.isSelectieKleurOpaak = (kleur[3] == 255);

			//
			// Bepaal de opmaak van de teken-items van alle fragmenten in de (on)geselecteerde staat:
			//
			let fragmenten: VLib.FragmentType[] = this.privates.fragmenten;
			for (let i: number = 0; i < fragmenten.length; i++) {
				let fragment: VLib.FragmentType = fragmenten[i];
				this.bepaalItemsSelectieOpmaak(fragment.itemsOpaak);
				this.bepaalItemsSelectieOpmaak(fragment.itemsTransparant);
			}
		}
	};
	//
	// Geeft de kleur waarmee de selectie wordt weergegeven.
	// Return-waarde is een array met lengte 4 (R-, G-, B-, A-componenten) met waarden in het bereik [0, 255].
	//
	public get selectieKleur(): number[] {
		return this.privates.selectieKleur.slice();
	};

	//
	// Stelt in of de gebruiker een selectie mag maken of niet.
	// Parameter 'value' is een boolean.
	//
	public set selectieToestaan(value: boolean) {
		if (this.privates.glWrap && typeof (value) == 'boolean') {
			this.privates.selectieToestaan = value;
		}
	};
	//
	// Geeft terug of de gebruiker een selectie mag maken of niet.
	// Return-waarde is een boolean.
	//
	public get selectieToestaan(): boolean {
		return this.privates.selectieToestaan;
	};

	//
	// Stelt in of de gebruiker een multiselectie mag maken of niet.
	// Parameter 'value' is een boolean.
	//
	public set multiselectieToestaan(value: boolean) {
		if (this.privates.glWrap && typeof (value) == 'boolean') {
			this.privates.multiselectieToestaan = value;
		}
	};
	//
	// Geeft terug of de gebruiker een multiselectie mag maken of niet.
	// Return-waarde is een boolean.
	//
	public get multiselectieToestaan(): boolean {
		return this.privates.multiselectieToestaan;
	};

	//
	// Stelt de selectie in. 
	// Parameter 'selectieIDs' is een array van selectie-ID objecten. Waarde null wist de selectie.
	//
	// Vuurt het selectie-veranderd event af. Ongeldige ID's worden uit de lijst verwijderd.
	//
	public set selectie(selectieIDs: V3DSelectieIDType[]) {
		//
		// NB: Zie functie 'updateSelectie' voor een beschrijving van waar een geldig selectie-ID aan moet voldoen.
		//
		if (this.privates.glWrap) {
			if (selectieIDs == null) {
				selectieIDs = [];
			}
			if (Array.isArray(selectieIDs)) {
				this.updateSelectieEnMeld(selectieIDs, false);
			}
		}
	};
	//
	// Geeft de huidige selectie.
	// Return-waarde is een array van selectie-ID objecten.
	//
	public get selectie(): V3DSelectieIDType[] {
		let selectieIDs: VLib.SelectieIDInternType[] = [];
		for (let i: number = 0; i < this.privates.selectieIDs.length; i++) {
			//
			// NB: De volgorde van het originele lijst wordt welbewust behouden in de kopie opdat een opgezochte index 
			// in de één hergebruikt kan worden in de ander.
			//
			let orig: VLib.SelectieIDInternType = this.privates.selectieIDs[i];
			let kopie: V3DSelectieIDType = { fragmentID: orig.fragmentID, transLijstAantal: orig.transLijstAantal, transLijstIndices: undefined };
			if (orig.transLijstIndices) {
				kopie.transLijstIndices = orig.transLijstIndices.slice();
			}
			selectieIDs.push(kopie);
		}
		return selectieIDs;
	};

	//
	// Bepaalt of de huidige selectie een gegeven modelfragment cq. een gegeven transformatie van een modelfragment 
	// bevat.
	// Parameter 'fragmentID' is de ID-string van het te zoeken modelfragment.
	// Parameter 'transformatielijstIndex' is de index van de transformatie in de transformatielijst. Mag ontbreken 
	// wanneer het modelfragment geen transformatielijst heeft, of om op te vragen of alle transformaties in de 
	// transformatielijst geselecteerd zijn. Gebruik waarde -1 om op te vragen of tenminste één transformatie in de 
	// transformatielijst geselecteerd is. 
	// Return-waarde is een boolean. Waarde is true wanneer hetgeen is opgevraagd in de huidige selectie zit; anders 
	// false.
	//
	public isGeselecteerd(fragmentID: string, transformatielijstIndex: number): boolean {
		if (this.privates.glWrap && typeof (fragmentID) == 'string') {
			let selectieID: VLib.SelectieIDInternType = this.findInSelectie(this.privates.selectieIDs, fragmentID);
			if (selectieID) {
				//
				// De ID-string is geldig en het bijbehorende fragment zit in de selectie.
				//
				if (selectieID.transLijstAantal == 0) {
					//
					// Het fragment heeft geen transformatielijst.
					//
					return (transformatielijstIndex == null);
				}
				else {
					//
					// Het fragment heeft een transformatielijst.
					//
					if (transformatielijstIndex == null) {
						//
						// Zijn alle transformaties in de transformatielijst inderdaad geselecteerd?
						//
						return (selectieID.transLijstIndices == null);
					}
					else if (VLib.Lib.isGeheelGetal(transformatielijstIndex)) {
						if (transformatielijstIndex == -1) {
							//
							// Uit het feit dat het modelfragment in de selectie zit, valt af te leiden dat er tenminste één 
							// transformatie in de transformatielijst geselecteerd moet zijn:
							//
							return true;
						}
						else {
							if (selectieID.transLijstIndices) {
								//
								// Sommige transformaties in de transformatielijst zijn geselecteerd. De aangeleverde index 
								// moet in die verzameling voorkomen:
								//
								return (selectieID.transLijstIndices.indexOf(transformatielijstIndex) != -1);
							}
							else {
								//
								// Alle transformaties in de transformatielijst zijn geselecteerd. De aangeleverde index moet 
								// alleen nog geldig zijn:
								//
								return (transformatielijstIndex >= 0 && transformatielijstIndex < selectieID.transLijstAantal);
							}
						}
					}
				}
			}
		}
		return false;
	};

	//
	// Stelt de callback-functies voor de selectie-veranderend event in.
	// Parameter 'callback' is een functie die wordt aangeroepen wanneer de selectie op het punt staat te veranderen. De 
	// functie heeft twee argumenten: de nieuwe selectie als een array van selectie-ID objecten en een boolean die 
	// aangeeft of de selectieverandering veroorzaakt is door de gebruiker.
	//
	// Waarde null ontkoppelt een eventueel bestaande callback-functie.
	//
	public set selectieVeranderendCallback(callback: V3DSelectieVeranderendCallbackType) {
		if (callback == null || typeof (callback) != 'function') {
			callback = undefined;
		}
		this.privates.selectieVeranderendCallback = callback;
	};
	//
	// Geeft de callback-functies voor de selectie-veranderend event.
	// Return-waarde is een functie, of null.
	//
	public get selectieVeranderendCallback(): V3DSelectieVeranderendCallbackType {
		return this.privates.selectieVeranderendCallback;
	};

	//
	// Stelt de callback-functies voor de selectie-veranderd event in.
	// Parameter 'callback' is een functie die wordt aangeroepen wanneer de selectie is veranderd. De functie heeft twee 
	// argumenten: de nieuwe selectie als een array van selectie-ID objecten en een boolean die aangeeft of de 
	// selectieverandering veroorzaakt is door de gebruiker.
	//
	// Waarde null ontkoppelt een eventueel bestaande callback-functie.
	//
	public set selectieVeranderdCallback(callback: V3DSelectieVeranderdCallbackType) {
		if (callback == null || typeof (callback) != 'function') {
			callback = undefined;
		}
		this.privates.selectieVeranderdCallback = callback;
	};

	//
	// Stelt de callback-functies voor de editorselectie-veranderd event in.
	// Parameter 'callback' is een functie die wordt aangeroepen wanneer de selectie is veranderd. De functie heeft twee 
	// argumenten: de nieuwe selectie als een array van selectie-ID objecten en een boolean die aangeeft of de 
	// selectieverandering veroorzaakt is door de gebruiker.
	//
	// Waarde null ontkoppelt een eventueel bestaande callback-functie.
	//
	public set editorSelectionChangedCallback(callback: V3DEditorSelectionChangedCallbackType) {
		if (callback == null || typeof (callback) != 'function') {
			callback = undefined;
		}
		this.privates.editorSelectionChangedCallback = callback;
	};

	//
	// Geeft de callback-functies voor de selectie-veranderd event.
	// Return-waarde is een functie, of null.
	//
	public get selectieVeranderdCallback(): V3DSelectieVeranderdCallbackType {
		return this.privates.selectieVeranderdCallback;
	};

	//
	// Geeft de callback-functies voor de editorselectie-veranderd event.
	// Return-waarde is een functie, of null.
	//
	public get editorSelectionChangedCallback(): V3DEditorSelectionChangedCallbackType {
		return this.privates.editorSelectionChangedCallback;
	};

	//
	// Stelt de callback-functie voor het bericht-event in.
	// Parameter 'callback' is een functie die wordt aangeroepen wanneer de viewer een aankondiging doet. De functie 
	// heeft drie argument: het HTML canvas-element waarvan de viewer gemaakt is, de enumeratiewaarde van het 
	// berichttype en tenslotte de berichtdata.
	// De mogelijke enumeratiewaarden zijn: 0 = A-OK; 1 = ViewerGestaakt (wanneer het systeem de viewer gestaakt heeft).
	//
	// Waarde null ontkoppelt een eventueel bestaande callback-functie.
	//
	public set berichtCallback(callback: V3DBerichtCallbackType) {
		if (callback == null || typeof (callback) != 'function') {
			callback = undefined;
		}
		this.privates.berichtCallback = callback;
	};
	//
	// Geeft de callback-functie voor het bericht-event.
	// Return-waarde is een functie, of null.
	//
	public get berichtCallback(): V3DBerichtCallbackType {
		return this.privates.berichtCallback;
	};

	//
	// Stelt hints voor de kwaliteit/prestatie van het afbeelden in.
	// Optionele parameter 'bumpmappingHint' is een boolean die aangeeft of bumpmapping uitgevoerd moet worden (true) 
	// of niet (false). Wordt per modelfragment effectief wanneer het wordt toegevoegd aan het model.
	//
	public zetTekenHints(bumpmappingHint: boolean): void {
		if (this.privates.glWrap) {
			if (typeof (bumpmappingHint) == 'boolean') {
				this.privates.metBumpmappingHint = bumpmappingHint;
			}
		}
	};

	//
	// Geeft de toegang tot de instellingen van de nabewerkingen.
	// Return-value is een Nabewerkingen-object, of null wanneer nabewerkingen niet ondersteund worden.
	//
	public get nabewerkingen(): Nabewerkingen {
		return this.privates.nabewerkingen;
	};

	//
	// Zet het maken van meldingen ten behoeve van debugging aan of uit.
	// Parameter 'value' is een boolean.
	//
	public set logEnabled(value: boolean) {
		if (this.privates.glWrap && typeof (value) == 'boolean') {
			this.privates.logEnabled = value;
		}
	};
	//
	// Geeft aan of het maken van meldingen ten behoeve van debugging aan of uit staat.
	// Return-waarde is een boolean.
	//
	public get logEnabled(): boolean {
		return this.privates.logEnabled;
	};

	public set workModus(value: eViewerWorkModus) {
		if (this.privates.glWrap && (this.privates.workModus != value)) {
			switch (value) {
				case eViewerWorkModus.createDrawing: {
					if (this.privates.createDrawingModusCallback == null) { throw "No callback present for 'create drawing' workmodus" };
					break;
				}
				case eViewerWorkModus.createBridgeLine: {
					if (this.privates.createBridgeLineModusCallback == null) { throw "No callback present for 'create bridgeLine' workmodus" };
					break;
				}
				case eViewerWorkModus.moveDrawingElements: {
					if (this.privates.moveDrawingElementsModusMouseMoveCallback == null) { throw "No callback present for 'move drawing elements' workmodus" };
					break;
				}
				case eViewerWorkModus.deleteShape: {
					if (this.privates.deleteShapeModusCallback == null) { throw "No callback present for 'delete shape' workmodus" };
					break;
				}
				case eViewerWorkModus.deleteBridgeLine: {
					if (this.privates.deleteBridgeLineModusCallback == null) { throw "No callback present for 'delete bridgeLine' workmodus" };
					break;
				}
				case eViewerWorkModus.align: {
					if (this.privates.alignModusCallback == null) { throw "No callback present for 'align' workmodus" };
					break;
				}
				case eViewerWorkModus.placeLibraryObject: {
					if (this.privates.placeLibraryObjectCallback == null) { throw "No callback present for 'place LibraryObject' workmodus" };
					break;
				}
				case eViewerWorkModus.placeOpening: {
					if (this.privates.placeOpeningCallback == null) { throw "No callback present for 'place Opening' workmodus" };
					break;
				}
				case eViewerWorkModus.normal: {
					break; // No further checks.
				}
				default: {
					throw "Unknown value for viewerWorkModus: " + value + "=" + eViewerWorkModusTags[value];
				}
			}
			this.privates.workModus = value;
			this.privates.selectedEditorFragmentID = null;
			this.privates.mouseHasMovedForSelectedEditorFragment = false;
		}
	}

	public get workModus(): eViewerWorkModus {
		return this.privates.workModus;
	}

	//
	// Geeft diagnostische informatie over een modelfragment.
	// Optionele parameter 'fragmentID' is de ID-string van het te diagnostiseren modelfragment. Bij afwezigheid wordt 
	// informatie over het model opgevraagd.
	// Return-waarde is een string met diagnostische informatie.
	//
	public geefInfo(fragmentID: string): string {
		if (this.privates.glWrap) {
			let info: string;
			if (fragmentID == null) {
				info = 'opt=' +
					(this.privates.lampenMaxAantal == null ? 'noLamp' : 'lamp' + this.privates.lampenMaxAantal) + ';' +
					(this.privates.metBumpmappingEis == false ? 'noBumpmapping;' : '') +
					(this.privates.nabewerkingen == null ? 'noNabewerkingen;' : '') +
					(this.privates.extDepthTexture == null ? 'noExtDepthTexture;' : '');
				let modelDims: VLib.ModelDimensiesType = this.getModelDimensies();
				if (modelDims) {
					let texNamen: string[] = [];
					for (let i: number = 0; i < this.privates.texBronnen.length; i++) {
						let texBron: VLib.TexBronType = this.privates.texBronnen[i];
						texNamen.push(texBron.naam + ' (' + texBron.refs.length + ')');
					}
					info = info +
						' afm=[' + modelDims.afmetingen + '] ctr=[' + modelDims.centrum + ']' +
						' frgm=(' + this.privates.fragmenten.length + ')[' + this.list() + ']' +
						' texNm=(' + texNamen.length + ')[' + texNamen + ']' +
						' texNmBl=(' + this.privates.texNamenBlacklist.length + ')[' + this.privates.texNamenBlacklist + ']';
				}
				else {
					info = info + ' Model is leeg';
				}
			}
			else if (typeof (fragmentID) == 'string') {
				let fragment: VLib.FragmentType = this.find(fragmentID);
				if (fragment != null) {
					let telTypen: VLib.TelFragmentItemsTypenFunctie = function (items: VLib.FragmentItemType[]): string {
						let aantal: number[] = [0, 0, 0, 0];
						if (items != null) {
							for (let i: number = 0; i < items.length; i++) {
								aantal[items[i].type]++;
							}
						}
						return aantal.join('|');
					};
					let texNamen: string[] = [];
					for (let i: number = 0; i < this.privates.texBronnen.length; i++) {
						let texBron: VLib.TexBronType = this.privates.texBronnen[i];
						if (texBron.refs.indexOf(fragmentID) != -1) {
							texNamen.push(texBron.naam);
						}
					}
					info = 'v=' + fragment.versie +
						' vis=' + (fragment.isZichtbaar ? 'shown' : 'hidden') +
						(fragment.werptSlagschaduw ? '' : ';noshadow') +
						' afm=[' + fragment.afmetingen + '] ctr=[' + fragment.centrum + ']' +
						' itOpk=' + telTypen(fragment.itemsOpaak) +
						' itTrp=' + telTypen(fragment.itemsTransparant) +
						' texNm=(' + texNamen.length + ')[' + texNamen + ']' +
						' trnLs=(' + (fragment.transLijst ? fragment.transLijst.aantal : 0) + ')';
				}
				else {
					info = 'ID ongeldig';
				}

			}
			return info;
		}
		return null;
	};

	//
	// Zoomt het blikveld met een bepaalde hoeveelheid.
	// Parameter 'teZoomen' is een dimensieloos getal.
	//
	private zoom(teZoomen: number): void {
		if (this.privates.glWrap) {
			if (teZoomen > 0) {
				//
				// Inzoomen:
				//
				teZoomen = this.privates._zoomSchaal * (1.0 + teZoomen);
			}
			else {
				//
				// Uitzoomen:
				//
				teZoomen = this.privates._zoomSchaal / (1.0 - teZoomen);
			}
			this.privates.zoomSchaal = teZoomen;
		}
	};

	//
	// Zoomt het blikveld met een bepaalde hoeveelheid op een bepaalde schermpositie.
	// Parameter 'teZoomen' is een dimensieloos getal.
	// Parameter 'x' is de x-coordinaat van de positie (oplopend naar rechts) in pixels.
	// Parameter 'y' is de y-coordinaat van de positie (oplopend naar boven) in pixels.
	//
	private zoomOp(teZoomen: number, x: number, y: number): void {
		if (this.privates.cameraType === false) // geen firmamentcamera
		{
			return;
		}

		if (this.privates.glWrap) {
			//
			// Bepaal de ruimtecoordinaten behorende bij het zoom-punt:
			//
			//let ruimtePunt: number[] = this.converteerVanSchermCoordinaten([x, y], this.privates._zoomSchaal); //@@@Q gewijzigd naar:
			let ruimtePunt: number[] = this.converteerVanSchermCoordinaten([x, y], false, true);
			//
			// Pas de zoomschaal aan:
			//
			this.zoom(teZoomen);
			//
			// Bepaal de nieuwe schermcoordinaten van het zoom-punt:
			//
			let schermPunt: number[] = this.converteerNaarSchermCoordinaten(ruimtePunt, this.privates._zoomSchaal);
			//
			// Verschuif het zoom-punt terug van de nieuwe schermpositie naar de oude:
			//
			this.verschuif(x - schermPunt[0], y - schermPunt[1]);
		}
	};

	//
	// Verschuift het blikveld. Parameters 'deltaRechts' en 'deltaOmhoog' zijn getallen in pixels. 
	//
	private verschuif(deltaRechts: number, deltaOmhoog: number): void {
		if (this.privates.cameraType === false) // geen firmamentcamera
		{
			return;
		}

		if (this.privates.glWrap) {
			let schuifBasis: number = 2.0 / (Math.min(this.privates.glWrap.gl.drawingBufferWidth, this.privates.glWrap.gl.drawingBufferHeight) *
				this.privates._zoomSchaal);
			let teSchuivenRechts: number = deltaRechts * schuifBasis;
			let teSchuivenOmhoog: number = deltaOmhoog * schuifBasis;
			this.privates.verschuivingRechts = this.privates._verschuivingRechts + teSchuivenRechts;
			this.privates.verschuivingOmhoog = this.privates._verschuivingOmhoog + teSchuivenOmhoog;
		}
	};

	//
	// Draait het blikveld. Parameters 'deltaRechts' en 'deltaOmhoog' zijn getallen in pixels. 
	// Parameter 'amplificatie' is een float.
	//
	private draai(deltaRechts: number, deltaOmhoog: number, amplificatie: number): void {
		if (this.privates.glWrap) {
			let draaiBasis: number = - Math.max(amplificatie, 1.0) * 2.0 * this.privates.constBereik /
				Math.min(this.privates.glWrap.gl.drawingBufferWidth, this.privates.glWrap.gl.drawingBufferHeight);
			if (this.privates.cameraType === true) // firmamentcamera
			{
				draaiBasis = draaiBasis / this.privates._zoomSchaal;
			}
			let teDraaienHorizontaal: number = deltaRechts * draaiBasis;
			let teDraaienVerticaal: number = deltaOmhoog * draaiBasis;

			if (Math.abs(this.privates._rolhoek) < VLib.Lib._epsilon) {
				this.privates.richting = this.privates._richting + teDraaienHorizontaal;
				this.privates.helling = this.privates._helling + teDraaienVerticaal;
			}
			else {
				let rolhoek: number = this.privates._rolhoek * VLib.Lib._vanDegNaarRad;
				let cosRolhoek: number = Math.cos(rolhoek);
				let sinRolhoek: number = Math.sin(rolhoek);

				this.privates.richting = this.privates._richting + teDraaienHorizontaal * cosRolhoek +
					teDraaienVerticaal * sinRolhoek;
				this.privates.helling = this.privates._helling + teDraaienVerticaal * cosRolhoek -
					teDraaienVerticaal * sinRolhoek;
			}
		}
	};

	//
	// Initieert het draaien van het blikveld om een modelpunt dat bepaald wordt aan de hand van gegeven schermpositie.
	// Parameter 'x' is de x-coordinaat van de positie (oplopend naar rechts) in pixels.
	// Parameter 'y' is de y-coordinaat van de positie (oplopend naar boven) in pixels.
	// Return-waarde is een object met navigatie-data ten behoeve van het aanstaande draaien om een punt, of null 
	// wanneer het initiëren niet geslaagd is.
	//
	private initDraaiOm(x: number, y: number): VLib.DraaienOmPuntDataType {
		if (this.privates.cameraType === false) // geen firmamentcamera
		{
			return null;
		}

		if (this.privates.glWrap) {
			let modelDims: VLib.ModelDimensiesType = this.getModelDimensies();
			if (modelDims == null) {
				return null;
			}

			let bufferBreedte: number = this.privates.glWrap.gl.drawingBufferWidth;
			let bufferHoogte: number = this.privates.glWrap.gl.drawingBufferHeight;
			if (VLib.Lib.isGetal(x) && x >= 0 && x < bufferBreedte && VLib.Lib.isGetal(y) && y >= 0 && y < bufferHoogte) {
				//
				// Gebruik de muispositie om een modelpunt te bepalen die als draaipunt moet gaan dienen.
				//
				// Bepaal de Z-buffer map van een verticale lijn in de huidige view:
				//
				let diepteBufferArray: Float32Array = this.maakZBufferMap(x, 0, x, bufferHoogte - 1);
				let z: number = diepteBufferArray[y];
				if (VLib.Lib.isGetal(z) == false) {
					//
					// Dit is de grootst mogelijke diepte, oftewel de achtergrond. Zoek een punt op de verticale lijn dat 
					// daadwerkelijk deel uitmaakt van het model (of het eventueel getoonde assenstelsel):
					//
					let yAfstand: number = 0;
					for (let yZoek: number = y - 1; yZoek >= 0; yZoek--) {
						if (VLib.Lib.isGetal(diepteBufferArray[yZoek])) {
							yAfstand = yZoek - y;
							break;
						}
					}
					for (let yZoek: number = y + 1; yZoek < bufferHoogte; yZoek++) {
						if (yAfstand != 0 && yZoek - y >= Math.abs(yAfstand))
							break;
						if (VLib.Lib.isGetal(diepteBufferArray[yZoek])) {
							yAfstand = yZoek - y;
							break;
						}
					}
					if (yAfstand != 0) {
						z = diepteBufferArray[y + yAfstand];
					}
					//else
					// De verticale lijn bevat alleen maar achtergrond.
				}

				let draaiPunt: number[]; // x, y, z
				if (VLib.Lib.isGetal(z)) {
					//
					// Converteer de schermcoordinaten naar model-coordinaten:
					//
					let viewport: number[] = [0, 0, bufferBreedte, bufferHoogte];
					draaiPunt = [x, y, z];
					vec3.unproject(draaiPunt, this.privates.modelviewMatrix, this.privates.projectieMatrix,
						viewport);
				}
				else {
					//
					// Gebruik het modelmiddelpunt:
					//
					draaiPunt = modelDims.centrum.slice();
				}

				//
				// Bepaal de verschuiving wanneer de gecentreerd zou worden op het draaipunt:
				//
				let schermPunt: number[] = this.converteerNaarSchermCoordinaten(draaiPunt);
				let schuifBasis: number = 1.0 / Math.min(bufferBreedte, bufferHoogte);
				let draaiPuntVerschuivingRechts: number = - schuifBasis * (2.0 * schermPunt[0] - bufferBreedte);
				let draaiPuntVerschuivingOmhoog: number = - schuifBasis * (2.0 * schermPunt[1] - bufferHoogte);
				//
				// Bepaal de verschuiving ten opzichte van het draaipunt. Deze relatieve verschuiving moet constant blijven 
				// tijdens het draaien:
				//
				let relDraaiPuntVerschuivingRechts: number = draaiPuntVerschuivingRechts - this.privates._verschuivingRechts;
				let relDraaiPuntVerschuivingOmhoog: number = draaiPuntVerschuivingOmhoog - this.privates._verschuivingOmhoog

				let data: VLib.DraaienOmPuntDataType = {
					draaiPunt: draaiPunt,
					relDraaiPuntVerschuivingRechts: relDraaiPuntVerschuivingRechts,
					relDraaiPuntVerschuivingOmhoog: relDraaiPuntVerschuivingOmhoog
				};

				return data;
			}
		}
		return null;
	};

	//
	// Draait het blikveld om een modelpunt.
	// Parameters 'deltaRechts' en 'deltaOmhoog' zijn getallen in pixels. 
	// Parameter 'amplificatie' is een float.
	// Parameter 'data' is een object met navigatie-data ten behoeve van het draaien om een punt.
	//
	private draaiOm(deltaRechts: number, deltaOmhoog: number, amplificatie: number, data: VLib.DraaienOmPuntDataType): void {
		if (this.privates.cameraType === false) // geen firmamentcamera
		{
			return;
		}

		if (this.privates.glWrap) {
			this.draai(deltaRechts, deltaOmhoog, amplificatie);
			//
			// Bepaal de verschuiving wanneer de gecentreerd zou worden op het draaipunt:
			//
			let schermPunt: number[] = this.converteerNaarSchermCoordinaten(data.draaiPunt);
			let schuifBasis: number = 1.0 /
				Math.min(this.privates.glWrap.gl.drawingBufferWidth, this.privates.glWrap.gl.drawingBufferHeight);
			let draaiPuntVerschuivingRechts: number = - schuifBasis *
				(2.0 * schermPunt[0] - this.privates.glWrap.gl.drawingBufferWidth);
			let draaiPuntVerschuivingOmhoog: number = - schuifBasis *
				(2.0 * schermPunt[1] - this.privates.glWrap.gl.drawingBufferHeight);
			//
			// Zorg vervolgens dat de relatieve verschuiving, bepaald bij aanvang van het draaien, in stand blijft:
			//
			this.privates.verschuivingRechts = draaiPuntVerschuivingRechts -
				data.relDraaiPuntVerschuivingRechts;
			this.privates.verschuivingOmhoog = draaiPuntVerschuivingOmhoog -
				data.relDraaiPuntVerschuivingOmhoog;
		}
	};

	private wandel(koers: number, amplificatie: number): boolean {
		let result: boolean;
		if (this.privates.wandelType === 0) {
			result = this.wandel_Stap(koers, amplificatie);
		}
		else {
			result =  this.wandel_Impuls(koers, amplificatie);
		}
		return result;
	}

	//
	// Wandelt stapsgewijs met de camera.
	// Parameters 'koers' is een enumeratiegetal. De mogelijke enumeratiewaarden zijn: 0 = Stilstand; 1 = Naar voren; 
	// 2 = Naar achteren; 4 = Naar links; 8 = Naar rechts; 16 = Omhoog; 32 = Omlaag.
	// Parameter 'amplificatie' is een float.
	// Return-waarde is doorgaans true, maar is false wanneer alle aanhangige verwerking geannuleerd dient te worden.
	//
	private wandel_Stap(koers: number, amplificatie: number): boolean {
		if (this.privates.cameraType === true) // geen terreincamera
		{
			return true;
		}

		switch (this.privates.wandelRestrictie) {
			case 1: // MobielHorizontaal
			case 4: // Perimeter
				koers &= ~(16 + 32); // schakel opwaarts uit
				break;
			case 2: // MobielVerticaal
				koers &= ~(1 + 2 + 4 + 8); // schakel voorwaarts en zijwaarts uit
				break;
			case 0: // Mobiel
			case 5: // Custom
				break;
			case 3: // Immobiel
			default:
				return true;
		}
		amplificatie *= this.wandelStap;
		let k: number = koers & (1 + 2);
		let deltaVoorwaarts: number = (((k & 1) != 0 ? 1.0 : 0.0) - ((k & 2) != 0 ? 1.0 : 0.0)) * amplificatie;
		k = koers & (4 + 8);
		let deltaZijwaarts: number = (((k & 4) != 0 ? 0.5 : 0.0) - ((k & 8) != 0 ? 0.5 : 0.0)) * amplificatie;
		k = koers & (16 + 32);
		let deltaOpwaarts: number = (((k & 16) != 0 ? 1.0 : 0.0) - ((k & 32) != 0 ? 1.0 : 0.0)) * amplificatie;

		if (this.privates.glWrap) {
			let hoekX: number = this.privates._richting * VLib.Lib._vanDegNaarRad;
			// voorwaarts = < cos(hoekX), -sin(hoekX), 0 >
			// zijwaarts = < sin(hoekX), cos(hoekX), 0 >
			// opwaarts = < 0, 0, 1 >
			let cosHoekX: number = Math.cos(hoekX);
			let sinHoekX: number = Math.sin(hoekX);

			let positie: number[] = this.privates._positie;
			let nieuwePositie: number[] = [
				positie[0] + cosHoekX * deltaVoorwaarts - sinHoekX * deltaZijwaarts, // X
				positie[1] + sinHoekX * deltaVoorwaarts + cosHoekX * deltaZijwaarts, // Y
				positie[2] + deltaOpwaarts]; // Z

			return this.setPositie(nieuwePositie, true);
		}
		return true;
	};

	//
	// Wandelt versnellend/vertragend met de camera.
	// Parameters 'koers' is een enumeratiegetal. De mogelijke enumeratiewaarden zijn: 0 = Stilstand; 1 = Naar voren; 
	// 2 = Naar achteren; 4 = Naar links; 8 = Naar rechts; 16 = Omhoog; 32 = Omlaag.
	// Parameter 'amplificatie' is een float, maar mag null zijn.
	// Return-waarde is altijd true. Deze functie moet dezelfde signatuur hebben als functie wandel_Stap.
	//
	private wandel_Impuls(koers: number, amplificatie: number): boolean {
		if (this.privates.cameraType === true) // geen terreincamera
		{
			return true;
		}
		if (this.privates.wandelType !== 1) // voortbewegen in discrete stappen
		{
			return true;
		}

		if (this.privates.glWrap) {
			this.setLus(true);

			let impuls: number = this.privates.navData.wandelImpuls;
			if (amplificatie != null) {
				//
				// Impuls aanzetten:
				//
				impuls |= koers;
				this.privates.navData.wandelAmplificatie = amplificatie;
			}
			else {
				//
				// Impuls uitzetten:
				//
				impuls &= ~koers;
			}
			this.privates.navData.wandelImpuls = impuls;
		}
		return true;
	}

	//
	// Past versnellend/vertragend wandelen met de camera toe.
	// Parameter 'deltaTijd' is de verstreken tijd (in ms) sinds de vorige lus-iteratie.
	//
	private wandel_ImpulsToepassen(deltaTijd: number): void {
		if (this.privates.cameraType === true) // geen terreincamera
		{
			return;
		}
		if (this.privates.wandelType !== 1) // voortbewegen in discrete stappen
		{
			return;
		}
		if (deltaTijd < VLib.Lib._epsilon) {
			//
			// Sla de eerste lus-iteratie over:
			//
			return;
		}

		if (this.privates.glWrap) {
			let impuls: number = this.privates.navData.wandelImpuls;
			switch (this.privates.wandelRestrictie) {
				case 1: // MobielHorizontaal
				case 4: // Perimeter
					impuls &= ~(16 + 32); // schakel opwaarts uit
					break;
				case 2: // MobielVerticaal
					impuls &= ~(1 + 2 + 4 + 8); // schakel voorwaarts en zijwaarts uit
					break;
				case 0: // Mobiel
				case 5: // Custom
					break;
				case 3: // Immobiel
				default:
					return;
			}
			let amplificatie: number = this.privates.navData.wandelAmplificatie;
			deltaTijd /= 1000.0;
			let huidigeSnelheid: number[] = this.privates.navData.wandelSnelheidHuidig; // in mm/s

			let delta: number[] = [];
			delta[0] = ((impuls & 1) != 0 ? 1.0 : 0.0) - ((impuls & 2) != 0 ? 1.0 : 0.0); // voorwaarts
			delta[1] = ((impuls & 4) != 0 ? 0.5 : 0.0) - ((impuls & 8) != 0 ? 0.5 : 0.0); // zijwaarts
			delta[2] = ((impuls & 16) != 0 ? 1.0 : 0.0) - ((impuls & 32) != 0 ? 1.0 : 0.0); // opwaarts

			let isStilstand: number = 0;
			let nieuweSnelheid: number[] = [];
			for (let i: number = 0; i < 3; i++) {
				let acceleratie: number = (delta[i] * amplificatie * this.privates.wandelSnelheid) - huidigeSnelheid[i];
				acceleratie *= ((acceleratie > 0.0) ? this.privates.wandelVersnellingsfactor : this.privates.wandelVertragingsfactor);
				nieuweSnelheid[i] = huidigeSnelheid[i] + acceleratie * deltaTijd;
				if (Math.abs(nieuweSnelheid[i]) < 10.0) // minder dan 10 mm/s
				{
					nieuweSnelheid[i] = 0.0;
					isStilstand++;
				}
			}
			if (isStilstand == 3) {
				this.setLus(false);
				return;
			}

			let hoekX: number = this.privates._richting * VLib.Lib._vanDegNaarRad;
			// voorwaarts = < cos(hoekX), -sin(hoekX), 0 >
			// zijwaarts = < sin(hoekX), cos(hoekX), 0 >
			// opwaarts = < 0, 0, 1 >
			let cosHoekX: number = Math.cos(hoekX);
			let sinHoekX: number = Math.sin(hoekX);
			let verplaatsing: number[] = [
				(cosHoekX * nieuweSnelheid[0] - sinHoekX * nieuweSnelheid[1]) * deltaTijd, // X
				(sinHoekX * nieuweSnelheid[0] + cosHoekX * nieuweSnelheid[1]) * deltaTijd, // Y
				nieuweSnelheid[2] * deltaTijd]; // Z

			let vorigePositie: number[] = this.privates.positie;
			let nieuwePositie: number[] = [vorigePositie[0] + verplaatsing[0],
			vorigePositie[1] + verplaatsing[1], vorigePositie[2] + verplaatsing[2]]; // voorgestelde nieuwe positie

			if (this.setPositie(nieuwePositie, true) === false) {
				//
				// De verplaatsing is geannuleerd:
				//
				this.setLus(false);
				return;
			}

			nieuwePositie = this.privates._positie; // daadwerkelijke nieuwe positie
			verplaatsing = [nieuwePositie[0] - vorigePositie[0],
			nieuwePositie[1] - vorigePositie[1], nieuwePositie[2] - vorigePositie[2]];
			if (Math.abs(verplaatsing[0]) < VLib.Lib._epsilon &&
				Math.abs(verplaatsing[1]) < VLib.Lib._epsilon &&
				Math.abs(verplaatsing[2]) < VLib.Lib._epsilon) {
				//
				// De verplaatsing is miniem of nihil:
				//
				this.setLus(false);
				return;
			}
			nieuweSnelheid = [
				(cosHoekX * verplaatsing[0] + sinHoekX * verplaatsing[1]) / deltaTijd, // voorwaarts
				(-sinHoekX * verplaatsing[0] + cosHoekX * verplaatsing[1]) / deltaTijd, // zijwaarts
				verplaatsing[2] / deltaTijd]; // opwaarts
			this.privates.navData.wandelSnelheidHuidig = nieuweSnelheid;
			return;
		}
	};

	//
	// Initieert variabelen voor het starten van lus-iteraties.
	//
	private lusInit(): void {
	};

	//
	// Herstelt variabelen na het stopzetten van lus-iteraties.
	//
	private lusReset(): void {
		this.privates.navData.wandelImpuls = 0;
		this.privates.navData.wandelAmplificatie = 1.0;
		this.privates.navData.wandelSnelheidHuidig = [0, 0, 0];
	};

	//
	// Past een lus-iteratie toe.
	// Parameter 'deltaTijd' is de verstreken tijd (in ms) sinds de vorige lus-iteratie.
	//
	private lusToepassen(deltaTijd: number): void {
		this.wandel_ImpulsToepassen(deltaTijd);
	};

	//
	// Past de selectie aan met het dichtstbijzijnde modelfragment onder de aangeleverde schermpositie.
	// Parameter 'x' is de x-coordinaat van de positie (oplopend naar rechts) in pixels.
	// Parameter 'y' is de y-coordinaat van de positie (oplopend naar boven) in pixels.
	// Parameter 'metCtrlToets' is een boolean die aangeeft of de Ctrl-toets ingedrukt was bij het maken van de selectie.
	//
	private selecteer(x: number, y: number, metCtrlToets: boolean): void {
		if (this.privates.glWrap) {
			let pick: VLib.PickingType = this.bepaalPicking(x, y, VLib.eFragmentOrigin.regular, false);
			if (pick == null) {
				//
				// Het bepalen van de selectie is mislukt:
				//
				return;
			}
			if (pick.fragmentIndex < -1 || pick.fragmentIndex > this.privates.fragmenten.length - 1) {
				//
				// Het bepalen van de selectie is mislukt:
				//
				return;
			}

			let selectieIDsNieuw: VLib.SelectieIDInternType[];
			let toevoegen: boolean = metCtrlToets && this.privates.multiselectieToestaan;
			if (pick.fragmentIndex != -1) {
				let fragmentID: string = pick.fragmentID;
				let fragmentIndexInSelectie: number = this.findIndexInSelectie(this.privates.selectieIDs, fragmentID);
				if (toevoegen == false) {
					//
					// De selectie moet vervangen worden als het gepickte fragment niet in de huidige selectie zit of als 
					// het gepickte fragment in de huidige selectie zit, maar niet als enige:
					//
					let vervang: boolean = (fragmentIndexInSelectie == -1 || this.privates.selectieIDs.length > 1);
					if (!vervang && pick.transLijstIndex != null) {
						//
						// Het gepickte fragment zit als enige in de huidige selectie, en heeft een transformatielijst.
						// De selectie moet vervangen worden als de gepickte transformatie een andere is dan die in de 
						// huidige selectie zit:
						//
						let huidigeTLI: number[] = this.privates.selectieIDs[fragmentIndexInSelectie].transLijstIndices;
						vervang = (huidigeTLI == null || huidigeTLI.length != 1 || huidigeTLI[0] != pick.transLijstIndex);
					}
					if (vervang) {
						//
						// Vervang de selectie:
						//
						let selectieID: VLib.SelectieIDInternType = { fragmentID: fragmentID, transLijstIndices: undefined };
						if (pick.transLijstIndex != null) {
							selectieID.transLijstIndices = [pick.transLijstIndex];
						}
						selectieIDsNieuw = [selectieID];
					}
				}
				else {
					selectieIDsNieuw = this.selectie;
					if (fragmentIndexInSelectie == -1) {
						//
						// Het gepickte fragment zit niet in de huidige selectie. Voeg dit fragment (met eventuele gepickte 
						// transformatie) toe aan de selectie:
						//
						let selectieID: VLib.SelectieIDInternType = { fragmentID: fragmentID, transLijstIndices: undefined };
						if (pick.transLijstIndex != null) {
							selectieID.transLijstIndices = [pick.transLijstIndex];
						}
						selectieIDsNieuw.push(selectieID);
					}
					else {
						//
						// Het gepickte fragment zit in de huidige selectie.
						//
						if (pick.transLijstIndex == null) {
							//
							// Het gepickte fragment heeft geen transformatielijst. Verwijder dit fragment uit de selectie:
							//
							selectieIDsNieuw.splice(fragmentIndexInSelectie, 1);
						}
						else {
							//
							// Het fragment heeft een transformatielijst.
							//
							let selectieID: VLib.SelectieIDInternType = selectieIDsNieuw[fragmentIndexInSelectie];
							let huidigeTLI: number[] = selectieID.transLijstIndices;
							if (huidigeTLI) {
								let transformatieIndexInSelectie: number = huidigeTLI.indexOf(pick.transLijstIndex);
								if (transformatieIndexInSelectie == -1) {
									//
									// De gepickte transformatie zit niet in de huidige selectie. Voeg deze transformatie toe 
									// aan de selectie:
									//
									if (huidigeTLI.length == selectieID.transLijstAantal - 1) {
										//
										// Alle transformaties in de transformatielijst dienen te worden geselecteerd. Dit wordt 
										// aangegeven door middel van de afwezigheid van een array van indices:
										//
										delete selectieID.transLijstIndices;
									}
									else {
										huidigeTLI.push(pick.transLijstIndex);
										let sorteerFunctie: VLib.SorteerNumbersFunctieType = function (a: number, b: number): number {
											return a - b;
										}
										huidigeTLI.sort(sorteerFunctie);
									}
								}
								else {
									//
									// De gepickte transformatie zit in de huidige selectie. Verwijder deze transformatie uit de 
									// selectie:
									//
									if (huidigeTLI.length == 1) {
										//
										// Geen van de transformaties in de transformatielijst dienen te worden geselecteerd. 
										// Verwijder het gepickte fragment uit de selectie:
										//
										selectieIDsNieuw.splice(fragmentIndexInSelectie, 1);
									}
									else {
										huidigeTLI.splice(transformatieIndexInSelectie, 1);
									}
								}
							}
							else {
								//
								// Alle transformaties in de transformatielijst zitten (impliciet) in de huidige selectie. 
								// Verwijder de gepickte transformatie uit de selectie:
								//
								if (selectieID.transLijstAantal == 1) {
									//
									// Geen van de transformaties in de transformatielijst dienen te worden geselecteerd. 
									// Verwijder het gepickte fragment uit de selectie:
									//
									selectieIDsNieuw.splice(fragmentIndexInSelectie, 1);
								}
								else {
									let nieuweTLI: number[] = [];
									for (let i: number = 0; i < selectieID.transLijstAantal; i++) {
										nieuweTLI.push(i);
									}
									nieuweTLI.splice(pick.transLijstIndex, 1);
									selectieID.transLijstIndices = nieuweTLI;
								}
							}
						}
					}
				}
			}
			else {
				if (toevoegen == false) {
					if (this.privates.selectieIDs.length > 0) {
						//
						// Maak de selectie leeg:
						//
						selectieIDsNieuw = [];
					}
				}
			}

			if (selectieIDsNieuw == null) {
				return;
			}

			this.updateSelectieEnMeld(selectieIDsNieuw, true, true);
			this.toonModel();
		}
	};

	//
	// Initieert de 3D-viewer.
	// Return-waarde is true wanneer de initiatie geslaagd is; anders false.
	//
	private init(canvas: HTMLCanvasElement, gl: WebGLRenderingContext, viewer3DManager: VLib.Viewer3DManager, opties: Viewer3DOptiesType): boolean {
		if (canvas && gl) {

			if (!(canvas instanceof HTMLCanvasElement)) { throw 'canvas is geen HTMLCanvasElement' };
			if (!(gl instanceof WebGLRenderingContext)) { throw 'gl is geen WebGLRenderingContext' };
			//
			// Bewaar de initiatie-variabelen:
			//
			this.viewer3DManager = viewer3DManager;
			this.privates.canvasWrap = { canvas: canvas };
			this.privates.glWrap = { gl: gl, drawingBufferAutoSize: true };

			if (gl.drawingBufferWidth == undefined || gl.drawingBufferHeight == undefined) {
				this.zetSpecifiekeGLProperties(gl, canvas); // NB: Zie de opmerking in die functie.
				this.privates.glWrap.drawingBufferAutoSize = false;
			}

			//
			// Cache vaakgebruikte state variables:
			//
			this.privates.paramMaxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);

			//
			// Laat eventuele foutmeldingen tonen:
			//
			if (opties && opties.enableLog === true) {
				this.logEnabled = true;
			}

			//
			// Stel de ondersteuning van kunstlichtbronnen in:
			//
			if (opties && opties.metLampen === true) {
				let stdLampenMaxAantal: number = 5;
				//
				// Er zijn maximaal 5 lampen beschikbaar. Dit aantal wordt verminderd wanneer het aantal in gebruik te 
				// nemen texture units het aantal beschikbare zal overschreiden. Voor andere doeleinden zijn al 5 texture 
				// units in gebruik:
				//
				let beschikbaar: number = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS) - 5; // MAX_TEXTURE_IMAGE_UNITS is minimaal 8
				let lampenMaxAantal: number = Math.min(stdLampenMaxAantal, beschikbaar);
				if (lampenMaxAantal > 0) {
					this.privates.lampenMaxAantal = lampenMaxAantal;
				}
				//else
				//
				// Kunstlichtbronnen worden niet ondersteund.
				//
				if (lampenMaxAantal < stdLampenMaxAantal) {
					this.conditionalLog('Aantal beschikbare lampen gereduceerd van ' +
						stdLampenMaxAantal + ' naar ' + lampenMaxAantal + '.');
				}
			}

			//
			// Controleer ondersteuning van extensions:
			//
			let extDepthTexture: WEBGL_depth_texture = gl.getExtension("WEBGL_depth_texture");
			if (extDepthTexture) {
				//
				// Gebruik een depth-texture als depth-attachment voor het maken van shadow maps.
				//
				this.privates.extDepthTexture = extDepthTexture;
			}
			let extTextureFilterAnisotropic: EXT_texture_filter_anisotropic = gl.getExtension("EXT_texture_filter_anisotropic");
			if (extTextureFilterAnisotropic) {
				//
				// Gebruik anisotrope textuurfiltering.
				//
				let maxAnisotropy: number = gl.getParameter(extTextureFilterAnisotropic.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
				this.privates.extTextureFilterAnisotropicWrap = { extTextureFilterAnisotropic: extTextureFilterAnisotropic, maxAnisotropy: maxAnisotropy };
			}

			//
			// Controleer ondersteuning:
			//
			let varyingsMaxAantal: number = gl.getParameter(gl.MAX_VARYING_VECTORS); // MAX_VARYING_VECTORS is minimaal 8
			let varyingsBenodigd: number = 10 + ((this.privates.lampenMaxAantal == null) ? 0 : 1);
			if (varyingsMaxAantal < varyingsBenodigd) {
				//
				// Er zijn minder varyings beschikbaar dan benodigd. Bumpmapping, welke 3 varyings in beslag neemt, wordt 
				// opgeofferd:
				//
				this.privates.metBumpmappingEis = false;
				this.conditionalLog('Ondersteuning van bumpmapping uitgeschakeld.');
			}

			//
			// Stel de ondersteuning van nabewerkingen in:
			//
			if (opties && opties.metNabewerkingen === true && this.privates.extDepthTexture) // de depth-texture extension is vereist
			{
				this.privates.nabewerkingen = new Nabewerkingen();
			}
			//else
			//
			// Nabewerkingen worden niet ondersteund.
			//

			//
			// Stel WebGL-resources en -states in:
			//
			if (this.initResources() !== true) {
				return false;
			}

			//
			// Bind dummy-texturen aan units TEXTURE0, TEXTURE2 en TEXTURE4.
			// Zie commentaar bij deze functie.
			//
			this.bindDummyTexturen(gl);

			//
			// Pas een polyfill toe voor browsers die geen passive event listeners ondersteunen:
			//
			let ondersteuntPassive: boolean = false;
			try {
				let testPassiveOptions: AddEventListenerOptions = Object.defineProperty({}, 'passive', {
					get: function () { ondersteuntPassive = true; }
				});
				window.addEventListener("testPassive", null, testPassiveOptions);
				window.removeEventListener("testPassive", null, testPassiveOptions);
			}
			catch (exc) {
				//
				// Exception is intentionally ignored here.
				//
			}
			this.privates.eventListenerOpties = ondersteuntPassive ? { passive: false } : false;

			//
			// Stel de event-handlers in:
			// NB: Duplicaat event-handlers vervangen eerder toegevoegde.
			//
			VLib.Lib.registerEvent(this.privates.canvasWrap.canvas, 'mousedown', this.handleMouseDown, this.privates.eventListenerOpties);
			VLib.Lib.registerEvent(document, 'mouseup', this.handleMouseUp, this.privates.eventListenerOpties);
			VLib.Lib.registerEvent(this.privates.canvasWrap.canvas, 'mousewheel', this.handleMouseWheel, this.privates.eventListenerOpties);
			VLib.Lib.registerEvent(this.privates.canvasWrap.canvas, 'contextmenu', this.handleContextMenu, this.privates.eventListenerOpties);
			VLib.Lib.registerEvent(this.privates.canvasWrap.canvas, 'touchstart', this.handleTouchStart, this.privates.eventListenerOpties);
			VLib.Lib.registerEvent(this.privates.canvasWrap.canvas, 'touchmove', this.handleTouchMove, this.privates.eventListenerOpties);
			VLib.Lib.registerEvent(this.privates.canvasWrap.canvas, 'touchend', this.handleTouchEnd, this.privates.eventListenerOpties);
			VLib.Lib.registerEvent(this.privates.canvasWrap.canvas, 'webglcontextlost', this.handleWebGLContextLost, this.privates.eventListenerOpties);

			//
			// M.b.t. ouderElem:
			// Code was hier oorspronkelijk: ...canvas.parentNode. 
			// Maar Node heeft niet property tabIndex en method getElementsByTagName.
			//
			let ouderElem: HTMLElement = this.privates.canvasWrap.canvas.parentElement;
			if (ouderElem && (ouderElem.nodeName === 'DIV' || ouderElem.nodeName === 'SPAN' ||
				ouderElem.nodeName === 'TD')) {
				//
				// Het div-, span- of td-element moet een ingevulde tab-index attribute hebben om focus te kunnen krijgen 
				// (en zodoende toetsenbordinvoer te kunnen ontvangen) en moet precies één canvas-element bevatten:
				//
				if (ouderElem.tabIndex != -1 && ouderElem.getElementsByTagName('canvas').length == 1) {
					this.privates.canvasWrap.toetsAfvanger = ouderElem;
					VLib.Lib.registerEvent(this.privates.canvasWrap.toetsAfvanger, 'keydown', this.handleKeyDown, this.privates.eventListenerOpties);
				}
			}

			//
			// Stel de achtergrondkleur in:
			//
			let achtergrondkleurUitCanvasStyle: boolean = false;
			if (this.privates.canvasWrap.canvas.style) {
				//
				// Neem, indien mogelijk, de achtergrondkleur van het canvas-element over:
				//
				let canvasStyle: CSSStyleDeclaration = window.getComputedStyle(this.privates.canvasWrap.canvas, null);
				let canvasAchtergrondKleur: string = canvasStyle.getPropertyValue('background-color');
				if (canvasAchtergrondKleur) {
					let patternRGBColors: RegExp = new RegExp("rgb\\(\\s*((?:[0-2]?[0-9])?[0-9])\\s*,\\s*((?:[0-2]?[0-9])?[0-9])\\s*,\\s*((?:[0-2]?[0-9])?[0-9])\\s*\\)$", 'i');
					let resultaat: RegExpExecArray = patternRGBColors.exec(canvasAchtergrondKleur);
					if (resultaat && resultaat.length == 4) {
						//
						// Het eerste element is de originele tekst, de tweede t/m vierde zijn R, G en B:
						//
						this.achtergrondKleur = ([parseInt(resultaat[1], 10), parseInt(resultaat[2], 10), parseInt(resultaat[3], 10)]);
						achtergrondkleurUitCanvasStyle = true;
					}
				}
				//
				// De (geërfde) achtergrondkleur van het canvas-element beinvloedt blending. Zet de achtergrondkleur op 
				// zwart opdat transparante kleuren goed worden weergegeven:
				//
				this.privates.canvasWrap.canvas.style.backgroundColor = '#000';
			}
			if (achtergrondkleurUitCanvasStyle == false) {
				this.achtergrondKleur = (this.privates.achtergrondKleur);
			}
			gl.clear(gl.COLOR_BUFFER_BIT);

			return true;
		}
		return false;
	};

	//
	// Als er geen textuur gebonden wordt aan TEXTURE0/2/4,
	// dan worden talloze warnings gegeven:
	// [...]RENDER WARNING: there is no texture bound to the unit x
	// Hierbij is x dan 0 of 2 of 4.
	// Daarom hier dummy-texturen binden.
	// Die dummy-texturen worden vaak weer overschreven door echte texturen voor de betrokken 'units'.
	//
	private bindDummyTexturen(gl: WebGLRenderingContext): void {
		this.privates.dummyTexture = gl.createTexture();
		this.bindDummyTextuur(gl.TEXTURE0);
		this.bindDummyTextuur(gl.TEXTURE2);
		this.bindDummyTextuur(gl.TEXTURE4);
		if (this.privates.lampenMaxAantal != null) { // kunstlichtbronnen worden ondersteund
			for (let i: number = 0; i < this.privates.lampenMaxAantal; i++) {
				this.bindDummyTextuur(gl.TEXTURE5 + i);
			}
		}
		let img: Uint8Array = new Uint8Array([255, 0, 0, 255]); // kleur rood
		gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 1, 1, 0, gl.RGBA, gl.UNSIGNED_BYTE, img);
	}

	//
	// Zie comment bij functie bindDummyTexturen.
	// Parameter textuurNummer kan zijn gl.TEXTURE0 en hoger (gl.TEXTUREn).
	// Je kan ook gl.ACTIVE_TEXTURE meegeven, dan wordt er geen textuurNummer actief gemaakt.
	//
	private bindDummyTextuur(textuurNummer: number): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		if (textuurNummer != gl.ACTIVE_TEXTURE) {
			gl.activeTexture(textuurNummer);
		}
		gl.bindTexture(gl.TEXTURE_2D, this.privates.dummyTexture);
	}

	private zetSpecifiekeGLProperties(gl: WebGLRenderingContext, canvas: HTMLCanvasElement): void {
		//
		// Dit is een fix voor legacy browsers (met name Safari 5.1) die de drawing buffer niet aanpassen bij een 
		// verandering van de afmetingen van het canvas-element.
		// Het probleem wordt gesignaleerd door het ontbreken van de properties 'drawingBufferWidth' en 
		// 'drawingBufferHeight' in de rendering context, en de fix bestaat uit het toevoegen van deze properties 
		// (polyfill) en het beschikbaar stellen van deze functie in de API.
		//
		// Beide opties zijn readonly, dus ik kan hier niet doen:
		// gl.drawingBufferWidth = canvas.width;
		// gl.drawingBufferHeight = canvas.height;
		// Omzeil dit probleem m.b.v. Object.defineProperties.
		//
		Object.defineProperties(gl, {
			'drawingBufferWidth': {
				value: canvas.width,
				writable: false
			},
			'drawingBufferHeight': {
				value: canvas.height,
				writable: false
			}
		});
	}

	//
	// Initieert WebGL-resources en -states.
	// Return-waarde is true wanneer de initiatie geslaagd is; anders false.
	//
	private initResources(): boolean {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		if (this.initShaderPrograms() !== true) {
			return false;
		}

		gl.enable(gl.DEPTH_TEST);
		gl.depthFunc(gl.LESS);
		gl.enable(gl.CULL_FACE);
		gl.cullFace(gl.BACK);
		gl.frontFace(gl.CCW);
		gl.enable(gl.BLEND);
		gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

		return true;
	};

	//
	// Initieert alle WebGL-shader programs van de viewer.
	// Return-waarde is true wanneer de initiatie geslaagd is; anders false.
	//
	private initShaderPrograms(): boolean {
		if (this.initStdShaderProgram() !== true) {
			return false;
		}
		if (this.initPickingShaderProgram() !== true) {
			return false;
		}
		if (this.initDepthMappingShaderPrograms() !== true) {
			return false;
		}
		if (this.privates.nabewerkingen != null) {
			if (this.initTextureCopyShaderProgram() !== true) {
				return false;
			}
			if (this.initFXAAShaderProgram() !== true) {
				return false;
			}
			if (this.initBokehShaderProgram() !== true) {
				return false;
			}
			if (this.initVignetteShaderProgram() !== true) {
				return false;
			}
			if (this.initSSAOBerekenenShaderProgram() !== true) {
				return false;
			}
			if (this.initSSAOAanbrengenShaderProgram() !== true) {
				return false;
			}
		}
		//
		// Stel het actieve shader program in:
		//
		this.privates.shaderProgramWrap = this.privates.shaderProgramStdWrap;
		this.privates.glWrap.gl.useProgram(this.privates.shaderProgramWrap.shaderProgram);
		return true;
	};

	//
	// Initieert het WebGL-shader program van de viewer.
	// Return-waarde is true wanneer de initiatie geslaagd is; anders false.
	//
	private initStdShaderProgram(): boolean {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		let naam: string = 'standaard';
		let code: string = VLib.ShaderSet.vertexShaderCodeStd();
		if (this.privates.metBumpmappingEis == true) // bumpmapping wordt ondersteund
		{
			code = '#define MET_BUMPMAP \n' + code;
		}
		let vertexShader: WebGLShader = this.getShader(gl, gl.VERTEX_SHADER, code, naam);
		if (!vertexShader) {
			return false;
		}

		code = VLib.ShaderSet.fragmentShaderCodeStd();
		if (this.privates.metBumpmappingEis == true) // bumpmapping wordt ondersteund
		{
			code = '#define MET_BUMPMAP \n' + code;
		}
		if (this.privates.lampenMaxAantal != null) // kunstlichtbronnen worden ondersteund
		{
			code = '#define MET_LAMPEN \n' + code;
		}
		if (this.privates.extDepthTexture == null) // depth-texture extension wordt niet ondersteund
		{
			code = '#define MET_DEPTHPACKING \n' + code;
		}
		let fragmentShader: WebGLShader = this.getShader(gl, gl.FRAGMENT_SHADER, code, naam);
		if (!fragmentShader) {
			return false;
		}
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.getShaderProgram(gl, vertexShader, fragmentShader, naam);
		if (!shaderProgramWrap) {
			return false;
		}

		let shaderProgram: WebGLProgram = shaderProgramWrap.shaderProgram;
		gl.useProgram(shaderProgram);
		this.privates.shaderProgramStdWrap = shaderProgramWrap;
		shaderProgramWrap.naam = naam;

		//
		// Vertex-attributes ten behoeve van vertexposities, normalen, kleuren en texturen:
		//
		shaderProgramWrap.vertexCoorAttribute = gl.getAttribLocation(shaderProgram, 'aVertexCoor');
		gl.enableVertexAttribArray(shaderProgramWrap.vertexCoorAttribute);
		shaderProgramWrap.normaalAttribute = gl.getAttribLocation(shaderProgram, 'aNormaal');
		shaderProgramWrap.tangentAttribute = gl.getAttribLocation(shaderProgram, 'aTangent');
		shaderProgramWrap.textuurCoorAttribute = gl.getAttribLocation(shaderProgram, 'aTextuurCoor');
		shaderProgramWrap.kleurAttribute = gl.getAttribLocation(shaderProgram, 'aKleur');

		//
		// Uniforms ten behoeve van texturing:
		//
		shaderProgramWrap.tableauSamplerUniform = gl.getUniformLocation(shaderProgram, 'uTableauSampler');
		gl.uniform1i(shaderProgramWrap.tableauSamplerUniform, 0);
		shaderProgramWrap.bumpmapSamplerUniform = gl.getUniformLocation(shaderProgram, 'uBumpmapSampler');
		gl.uniform1i(shaderProgramWrap.bumpmapSamplerUniform, 2);
		shaderProgramWrap.tableauTexturingUniform = gl.getUniformLocation(shaderProgram, 'uTableauTexturing');
		shaderProgramWrap.tableauVormTexturingUniform = gl.getUniformLocation(shaderProgram, 'uTableauVormTexturing');
		shaderProgramWrap.bumpmapTexturingUniform = gl.getUniformLocation(shaderProgram, 'uBumpmapTexturing');
		shaderProgramWrap.biTangentUniform = gl.getUniformLocation(shaderProgram, 'uBiTangent');
		shaderProgramWrap.mengKleurUniform = gl.getUniformLocation(shaderProgram, 'uMengKleur');

		//
		// Uniforms ten behoeve van elementidentificatie:
		//
		shaderProgramWrap.elemTypeUniform = gl.getUniformLocation(shaderProgram, 'uElemType');

		//
		// Uniforms ten behoeve van markeer-elementen:
		//
		shaderProgramWrap.elemPositieUniform = gl.getUniformLocation(shaderProgram, 'uElemPositie');
		shaderProgramWrap.zoomSchaalUniform = gl.getUniformLocation(shaderProgram, 'uZoomSchaal');

		//
		// Uniforms ten behoeve van projectiematrix en modelviewmatrix:
		//
		shaderProgramWrap.projectieMatrixUniform = gl.getUniformLocation(shaderProgram, 'uProjectieMatrix');
		shaderProgramWrap.modelviewMatrixUniform = gl.getUniformLocation(shaderProgram, 'uModelviewMatrix');
		shaderProgramWrap.modelviewMatrixFSUniform = gl.getUniformLocation(shaderProgram, 'uModelviewMatrixFS');
		shaderProgramWrap.rotatieMatrixUniform = gl.getUniformLocation(shaderProgram, 'uRotatieMatrix');
		shaderProgramWrap.rotatieMatrixFSUniform = gl.getUniformLocation(shaderProgram, 'uRotatieMatrixFS');

		//
		// Uniforms ten behoeve van van shadow map generatie en shadow map sampling:
		//
		shaderProgramWrap.diepteMatrixUniform = gl.getUniformLocation(shaderProgram, 'uDiepteMatrix');
		shaderProgramWrap.diepteSamplerUniform = gl.getUniformLocation(shaderProgram, 'uDiepteSampler');
		gl.uniform1i(shaderProgramWrap.diepteSamplerUniform, 4);

		//
		// Uniforms ten behoeve van belichting en materiaal:
		//
		shaderProgramWrap.vloedlichtUniform = gl.getUniformLocation(shaderProgram, 'uVloedlicht');
		shaderProgramWrap.lichtvalUniform = gl.getUniformLocation(shaderProgram, 'uLichtval');
		shaderProgramWrap.omgevingLichtNiveauUniform = gl.getUniformLocation(shaderProgram, 'uOmgevingLichtNiveau');
		shaderProgramWrap.diffuusLichtNiveauUniform = gl.getUniformLocation(shaderProgram, 'uDiffuusLichtNiveau');
		shaderProgramWrap.schitteringLichtNiveauUniform = gl.getUniformLocation(shaderProgram, 'uSchitteringLichtNiveau');
		shaderProgramWrap.schitteringMateriaalUniform = gl.getUniformLocation(shaderProgram, 'uSchitteringMateriaal');
		shaderProgramWrap.toonSlagschaduwUniform = gl.getUniformLocation(shaderProgram, 'uToonSlagschaduw');
		shaderProgramWrap.slagschaduwVerzadigingUniform = gl.getUniformLocation(shaderProgram, 'uSlagschaduwVerzadiging');

		//
		// Onveranderlijke uniforms ten behoeve van belichting en materiaal:
		//
		let schitteringMateriaalMachtUniform: WebGLUniformLocation = gl.getUniformLocation(shaderProgram, 'uSchitteringMateriaalMacht');
		gl.uniform1f(schitteringMateriaalMachtUniform, this.privates.belichting.schitteringMateriaalMacht);

		//
		// Uniforms ten behoeve van kunstlichtbronnen:
		//
		if (this.privates.lampenMaxAantal != null) // kunstlichtbronnen worden ondersteund
		{
			shaderProgramWrap.lampenUniforms = [];
			for (let i: number = 0; i < this.privates.lampenMaxAantal; i++) {
				let lampUniforms: VLib.LampUniformsType = {
					diepteSampler: undefined,
					diepteTextureUnit: undefined,
					positie: gl.getUniformLocation(shaderProgram, 'uLamp[' + i + '].positie'),
					richting: gl.getUniformLocation(shaderProgram, 'uLamp[' + i + '].richting'),
					cosBuitenHoek: gl.getUniformLocation(shaderProgram, 'uLamp[' + i + '].cosBuitenHoek'),
					cosBinnenHoek: gl.getUniformLocation(shaderProgram, 'uLamp[' + i + '].cosBinnenHoek'),
					diffuusLichtNiveau: gl.getUniformLocation(shaderProgram, 'uLamp[' + i + '].diffuusLichtNiveau'),
					schitteringLichtNiveau: gl.getUniformLocation(shaderProgram, 'uLamp[' + i + '].schitteringLichtNiveau'),
					kleur: gl.getUniformLocation(shaderProgram, 'uLamp[' + i + '].kleur'),
					diepteMatrix: gl.getUniformLocation(shaderProgram, 'uLamp[' + i + '].diepteMatrix'),
				};
				//
				// NB: Samplers mogen in WebGL niet in een struct opgenomen worden (en arrays van samplers mogen alleen met 
				// compile-time constante indices benaderd worden).
				//
				lampUniforms.diepteSampler =
					gl.getUniformLocation(shaderProgram, 'uLampDiepteSampler[' + i + ']');
				gl.uniform1i(lampUniforms.diepteSampler, 5 + i);
				lampUniforms.diepteTextureUnit = gl.TEXTURE5 + i;

				shaderProgramWrap.lampenUniforms.push(lampUniforms);
			}

			shaderProgramWrap.lichtAttenuatieAfstandUniform =
				gl.getUniformLocation(shaderProgram, 'uLichtAttenuatieAfstand');

			this.privates.shaderProgramWrap = shaderProgramWrap;
			this.doofLampen();
			this.privates.shaderProgramWrap = undefined;
		}

		return true;
	};

	//
	// Initieert het WebGL-shader program van de viewer ten behoeve van selectie.
	// Return-waarde is true wanneer de initiatie geslaagd is; anders false.
	//
	private initPickingShaderProgram(): boolean {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		let naam: string = 'picking';
		let code: string = VLib.ShaderSet.vertexShaderCodePicking();
		let vertexShader: WebGLShader = this.getShader(gl, gl.VERTEX_SHADER, code, naam);
		if (!vertexShader) {
			return false;
		}

		code = VLib.ShaderSet.fragmentShaderCodePicking();
		let fragmentShader: WebGLShader = this.getShader(gl, gl.FRAGMENT_SHADER, code, naam);
		if (!fragmentShader) {
			return false;
		}
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.getShaderProgram(gl, vertexShader, fragmentShader, naam);
		if (!shaderProgramWrap) {
			return false;
		}

		let shaderProgram: WebGLProgram = shaderProgramWrap.shaderProgram;
		gl.useProgram(shaderProgram);
		this.privates.shaderProgramPickingWrap = shaderProgramWrap;
		shaderProgramWrap.naam = naam;

		//
		// Vertex-attributes ten behoeve van vertexposities, kleuren en texturen:
		//
		shaderProgramWrap.vertexCoorAttribute = gl.getAttribLocation(shaderProgram, 'aVertexCoor');
		gl.enableVertexAttribArray(shaderProgramWrap.vertexCoorAttribute);
		shaderProgramWrap.textuurCoorAttribute = gl.getAttribLocation(shaderProgram, 'aTextuurCoor');
		shaderProgramWrap.kleurAttribute = gl.getAttribLocation(shaderProgram, 'aKleur');

		//
		// Uniforms ten behoeve van texturing:
		//
		shaderProgramWrap.tableauSamplerUniform = gl.getUniformLocation(shaderProgram, 'uTableauSampler');
		gl.uniform1i(shaderProgramWrap.tableauSamplerUniform, 0);
		shaderProgramWrap.tableauVormTexturingUniform = gl.getUniformLocation(shaderProgram, 'uTableauVormTexturing');

		//
		// Uniforms ten behoeve van elementidentificatie:
		//
		shaderProgramWrap.elemTypeUniform = gl.getUniformLocation(shaderProgram, 'uElemType');

		//
		// Uniforms ten behoeve van markeer-elementen:
		//
		shaderProgramWrap.elemPositieUniform = gl.getUniformLocation(shaderProgram, 'uElemPositie');
		shaderProgramWrap.zoomSchaalUniform = gl.getUniformLocation(shaderProgram, 'uZoomSchaal');

		//
		// Uniforms ten behoeve van projectiematrix en modelviewmatrix:
		//
		shaderProgramWrap.projectieMatrixUniform = gl.getUniformLocation(shaderProgram, 'uProjectieMatrix');
		shaderProgramWrap.modelviewMatrixUniform = gl.getUniformLocation(shaderProgram, 'uModelviewMatrix');

		return true;
	};

	//
	// Initieert het WebGL-shader program van de viewer ten behoeve van shadow mapping en Z-buffer mapping.
	// Return-waarde is true wanneer de initiatie geslaagd is; anders false.
	//
	private initDepthMappingShaderPrograms(): boolean {
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.initDepthMappingShaderProgram(true);
		if (shaderProgramWrap == null) {
			return false;
		}
		//
		// Gebruik het shader program voor Z-buffer mapping:
		//
		this.privates.shaderProgramZBufferMappingWrap = shaderProgramWrap;

		if (this.privates.extDepthTexture == null) // depth-texture extension wordt niet ondersteund
		{
			//
			// Hergebruik het shader program (dat gebruik maakt van depth-packing) voor shadow mapping:
			//
			this.privates.shaderProgramShadowMappingWrap = shaderProgramWrap;
		}
		else {
			shaderProgramWrap = this.initDepthMappingShaderProgram(false);
			if (shaderProgramWrap == null) {
				return false;
			}
			//
			// Gebruik het shader program (dat gebruik maakt van de depth-texture extension) voor shadow mapping:
			//
			this.privates.shaderProgramShadowMappingWrap = shaderProgramWrap;
		}

		return true;
	};

	//
	// Initieert het WebGL-shader program van de viewer ten behoeve van depth mapping.
	// Parameter 'metDepthPacking' is een boolean die aangeeft dat de diepte in de kleurenbuffer ingepakt dient te 
	// worden (i.e. versleuteld als RGBA) (true) of dat er gebruik gemaakt moet worden van de depth-texture extension 
	// (false).
	// Return-waarde is het shader program-object, of null wanneer er geen shader program gemaakt kon worden.
	//
	private initDepthMappingShaderProgram(metDepthPacking: boolean): VLib.WebGLProgramWrapType {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		let naam: string = 'depth mapping w\\ depth-' + (metDepthPacking ? 'packing' : 'texture');
		let code: string = VLib.ShaderSet.vertexShaderCodeDepthMapping();
		let vertexShader: WebGLShader = this.getShader(gl, gl.VERTEX_SHADER, code, naam);
		if (!vertexShader) {
			return null;
		}

		code = VLib.ShaderSet.fragmentShaderCodeDepthMapping();
		if (metDepthPacking === true) {
			code = '#define MET_DEPTHPACKING \n' + code;
		}
		let fragmentShader: WebGLShader = this.getShader(gl, gl.FRAGMENT_SHADER, code, naam);
		if (!fragmentShader) {
			return null;
		}
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.getShaderProgram(gl, vertexShader, fragmentShader, naam);
		if (!shaderProgramWrap) {
			return null;
		}

		let shaderProgram: WebGLProgram = shaderProgramWrap.shaderProgram;
		gl.useProgram(shaderProgram);
		shaderProgramWrap.naam = naam;

		//
		// Vertex-attributes ten behoeve van vertexposities en texturen:
		//
		shaderProgramWrap.vertexCoorAttribute = gl.getAttribLocation(shaderProgram, 'aVertexCoor');
		gl.enableVertexAttribArray(shaderProgramWrap.vertexCoorAttribute);
		shaderProgramWrap.textuurCoorAttribute = gl.getAttribLocation(shaderProgram, 'aTextuurCoor');

		//
		// Uniforms ten behoeve van texturing:
		//
		shaderProgramWrap.tableauSamplerUniform = gl.getUniformLocation(shaderProgram, 'uTableauSampler');
		gl.uniform1i(shaderProgramWrap.tableauSamplerUniform, 0);
		shaderProgramWrap.tableauVormTexturingUniform = gl.getUniformLocation(shaderProgram, 'uTableauVormTexturing');

		//
		// Uniforms ten behoeve van projectiematrix en modelviewmatrix:
		//
		shaderProgramWrap.projectieMatrixUniform = gl.getUniformLocation(shaderProgram, 'uProjectieMatrix');
		shaderProgramWrap.modelviewMatrixUniform = gl.getUniformLocation(shaderProgram, 'uModelviewMatrix');

		return shaderProgramWrap;
	};

	//
	// Initieert het WebGL-shader program van de viewer ten behoeve van het kopieren van een texture.
	// Return-waarde is het shader program-object, of null wanneer er geen shader program gemaakt kon worden.
	//
	private initTextureCopyShaderProgram(): boolean {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		let naam: string = 'texture copy';
		let code: string = VLib.ShaderSet.vertexShaderCodeTextureCopy();
		let vertexShader: WebGLShader = this.getShader(gl, gl.VERTEX_SHADER, code, naam);
		if (!vertexShader) {
			return false;
		}

		code = VLib.ShaderSet.fragmentShaderCodeTextureCopy();
		let fragmentShader: WebGLShader = this.getShader(gl, gl.FRAGMENT_SHADER, code, naam);
		if (!fragmentShader) {
			return false;
		}
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.getShaderProgram(gl, vertexShader, fragmentShader, naam);
		if (!shaderProgramWrap) {
			return false;
		}

		let shaderProgram: WebGLProgram = shaderProgramWrap.shaderProgram;
		gl.useProgram(shaderProgram);
		this.privates.shaderProgramTextureCopyWrap = shaderProgramWrap;
		shaderProgramWrap.naam = naam;
		//
		// Vertex-attributes ten behoeve van vertexposities:
		//
		shaderProgramWrap.vertexCoorAttribute = gl.getAttribLocation(shaderProgram, 'aVertexCoor');
		gl.enableVertexAttribArray(shaderProgramWrap.vertexCoorAttribute);

		//
		// Uniforms:
		//
		shaderProgramWrap.samplerUniform = gl.getUniformLocation(shaderProgram, 'uSampler');
		gl.uniform1i(shaderProgramWrap.samplerUniform, 0);

		//
		// Buffer met schermvullende quad:
		//
		let vertexDataBuffer: WebGLBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
		gl.bufferData(gl.ARRAY_BUFFER,
			new Float32Array([-1, -1, 1, -1, 1, 1, 1, 1, -1, 1, -1, -1]), // x-, y-coordinaten 
			// van vertices (z-coordinaat is irrelevant) van 2 driehoeken in de quad
			gl.STATIC_DRAW);
		shaderProgramWrap.vertexDataBufferWrap = { vertexDataBuffer: vertexDataBuffer, aantal: 6 }; // aantal vertices

		return true;
	}

	//
	// Initieert het WebGL-shader program van de viewer ten behoeve van FXAA.
	// Return-waarde is het shader program-object, of null wanneer er geen shader program gemaakt kon worden.
	//
	private initFXAAShaderProgram(): boolean {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		let naam: string = 'fxaa';
		let code: string = VLib.ShaderSet.vertexShaderCodeFXAA();
		let vertexShader: WebGLShader = this.getShader(gl, gl.VERTEX_SHADER, code, naam);
		if (!vertexShader) {
			return false;
		}

		code = VLib.ShaderSet.fragmentShaderCodeFXAA();
		let fragmentShader: WebGLShader = this.getShader(gl, gl.FRAGMENT_SHADER, code, naam);
		if (!fragmentShader) {
			return false;
		}
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.getShaderProgram(gl, vertexShader, fragmentShader, naam);
		if (!shaderProgramWrap) {
			return false;
		}

		let shaderProgram: WebGLProgram = shaderProgramWrap.shaderProgram;
		gl.useProgram(shaderProgram);
		this.privates.shaderProgramFXAAWrap = shaderProgramWrap;
		shaderProgramWrap.naam = naam;
		//
		// Vertex-attributes ten behoeve van vertexposities:
		//
		shaderProgramWrap.vertexCoorAttribute = gl.getAttribLocation(shaderProgram, 'aVertexCoor');
		gl.enableVertexAttribArray(shaderProgramWrap.vertexCoorAttribute);

		//
		// Uniforms:
		//
		shaderProgramWrap.samplerUniform = gl.getUniformLocation(shaderProgram, 'uSampler');
		gl.uniform1i(shaderProgramWrap.samplerUniform, 0);
		shaderProgramWrap.invResolutieUniform = gl.getUniformLocation(shaderProgram, 'uInvResolutie');

		//
		// Buffer met schermvullende quad:
		//
		let vertexDataBuffer: WebGLBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
		gl.bufferData(gl.ARRAY_BUFFER,
			new Float32Array([-1, -1, 1, -1, 1, 1, 1, 1, -1, 1, -1, -1]), // x-, y-coordinaten 
			// van vertices (z-coordinaat is irrelevant) van 2 driehoeken in de quad
			gl.STATIC_DRAW);
		shaderProgramWrap.vertexDataBufferWrap = { vertexDataBuffer: vertexDataBuffer, aantal: 6 }; // aantal vertices

		return true;
	};

	//
	// Initieert het WebGL-shader program van de viewer ten behoeve van bokeh.
	// Return-waarde is het shader program-object, of null wanneer er geen shader program gemaakt kon worden.
	//
	private initBokehShaderProgram(): boolean {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		let naam: string = 'bokeh';
		let code: string = VLib.ShaderSet.vertexShaderCodeBokeh();
		let vertexShader: WebGLShader = this.getShader(gl, gl.VERTEX_SHADER, code, naam);
		if (!vertexShader) {
			return false;
		}

		code = VLib.ShaderSet.fragmentShaderCodeBokeh();
		let fragmentShader: WebGLShader = this.getShader(gl, gl.FRAGMENT_SHADER, code, naam);
		if (!fragmentShader) {
			return false;
		}
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.getShaderProgram(gl, vertexShader, fragmentShader, naam);
		if (!shaderProgramWrap) {
			return false;
		}

		let shaderProgram: WebGLProgram = shaderProgramWrap.shaderProgram;
		gl.useProgram(shaderProgram);
		this.privates.shaderProgramBokehWrap = shaderProgramWrap;
		shaderProgramWrap.naam = naam;
		//
		// Vertex-attributes ten behoeve van vertexposities:
		//
		shaderProgramWrap.vertexCoorAttribute = gl.getAttribLocation(shaderProgram, 'aVertexCoor');
		gl.enableVertexAttribArray(shaderProgramWrap.vertexCoorAttribute);

		//
		// Uniforms:
		//
		shaderProgramWrap.tableauSamplerUniform = gl.getUniformLocation(shaderProgram, 'uTableauSampler');
		gl.uniform1i(shaderProgramWrap.tableauSamplerUniform, 0);
		shaderProgramWrap.diepteSamplerUniform = gl.getUniformLocation(shaderProgram, 'uDiepteSampler');
		gl.uniform1i(shaderProgramWrap.diepteSamplerUniform, 1);
		shaderProgramWrap.invResolutieUniform = gl.getUniformLocation(shaderProgram, 'uInvResolutie');
		shaderProgramWrap.nearUniform = gl.getUniformLocation(shaderProgram, 'uNear');
		shaderProgramWrap.farUniform = gl.getUniformLocation(shaderProgram, 'uFar');
		shaderProgramWrap.focusDiepteUniform = gl.getUniformLocation(shaderProgram, 'uFocusDiepte');
		shaderProgramWrap.autoFocusUniform = gl.getUniformLocation(shaderProgram, 'uAutoFocus');
		shaderProgramWrap.cocCurveUniform = gl.getUniformLocation(shaderProgram, 'uCoCCurve');
		shaderProgramWrap.waasSterkteUniform = gl.getUniformLocation(shaderProgram, 'uWaasSterkte');
		shaderProgramWrap.ruisSterkteUniform = gl.getUniformLocation(shaderProgram, 'uRuissSterkte');

		//
		// Buffer met schermvullende quad:
		//
		let vertexDataBuffer: WebGLBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
		gl.bufferData(gl.ARRAY_BUFFER,
			new Float32Array([-1, -1, 1, -1, 1, 1, 1, 1, -1, 1, -1, -1]), // x-, y-coordinaten 
			// van vertices (z-coordinaat is irrelevant) van 2 driehoeken in de quad
			gl.STATIC_DRAW);
		shaderProgramWrap.vertexDataBufferWrap = { vertexDataBuffer: vertexDataBuffer, aantal: 6 }; // aantal vertices

		return true;
	};

	//
	// Initieert het WebGL-shader program van de viewer ten behoeve van een vignette.
	// Return-waarde is het shader program-object, of null wanneer er geen shader program gemaakt kon worden.
	//
	private initVignetteShaderProgram(): boolean {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		let naam: string = 'vignette';
		let code: string = VLib.ShaderSet.vertexShaderCodeVignette();
		let vertexShader: WebGLShader = this.getShader(gl, gl.VERTEX_SHADER, code, naam);
		if (!vertexShader) {
			return false;
		}

		code = VLib.ShaderSet.fragmentShaderCodeVignette();
		let fragmentShader: WebGLShader = this.getShader(gl, gl.FRAGMENT_SHADER, code, naam);
		if (!fragmentShader) {
			return false;
		}
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.getShaderProgram(gl, vertexShader, fragmentShader, naam);
		if (!shaderProgramWrap) {
			return false;
		}

		let shaderProgram: WebGLProgram = shaderProgramWrap.shaderProgram;
		gl.useProgram(shaderProgram);
		this.privates.shaderProgramVignetteWrap = shaderProgramWrap;
		shaderProgramWrap.naam = naam;
		//
		// Vertex-attributes ten behoeve van vertexposities:
		//
		shaderProgramWrap.vertexCoorAttribute = gl.getAttribLocation(shaderProgram, 'aVertexCoor');
		gl.enableVertexAttribArray(shaderProgramWrap.vertexCoorAttribute);

		//
		// Uniforms:
		//
		shaderProgramWrap.tableauSamplerUniform = gl.getUniformLocation(shaderProgram, 'uTableauSampler');
		gl.uniform1i(shaderProgramWrap.tableauSamplerUniform, 0);
		shaderProgramWrap.straalUniform = gl.getUniformLocation(shaderProgram, 'uStraal');
		shaderProgramWrap.wijdteUniform = gl.getUniformLocation(shaderProgram, 'uWijdte');
		shaderProgramWrap.intensiteitUniform = gl.getUniformLocation(shaderProgram, 'uIntensiteit');

		//
		// Buffer met schermvullende quad:
		//
		let vertexDataBuffer: WebGLBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
		gl.bufferData(gl.ARRAY_BUFFER,
			new Float32Array([-1, -1, 1, -1, 1, 1, 1, 1, -1, 1, -1, -1]), // x-, y-coordinaten 
			// van vertices (z-coordinaat is irrelevant) van 2 driehoeken in de quad
			gl.STATIC_DRAW);
		shaderProgramWrap.vertexDataBufferWrap = { vertexDataBuffer: vertexDataBuffer, aantal: 6 }; // aantal vertices

		return true;
	};

	//
	// Initieert het WebGL-shader program van de viewer ten behoeve van het berekenen van SSAO.
	// Return-waarde is het shader program-object, of null wanneer er geen shader program gemaakt kon worden.
	//
	private initSSAOBerekenenShaderProgram(): boolean {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		let naam: string = 'SSAO (berekenen)';
		let code: string = VLib.ShaderSet.vertexShaderCodeSSAO();
		let vertexShader: WebGLShader = this.getShader(gl, gl.VERTEX_SHADER, code, naam);
		if (!vertexShader) {
			return false;
		}

		code = VLib.ShaderSet.fragmentShaderCodeSSAOBerekenen();
		let fragmentShader: WebGLShader = this.getShader(gl, gl.FRAGMENT_SHADER, code, naam);
		if (!fragmentShader) {
			return false;
		}
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.getShaderProgram(gl, vertexShader, fragmentShader, naam);
		if (!shaderProgramWrap) {
			return false;
		}

		let shaderProgram: WebGLProgram = shaderProgramWrap.shaderProgram;
		gl.useProgram(shaderProgram);
		this.privates.shaderProgramSSAOBerekenenWrap = shaderProgramWrap;
		shaderProgramWrap.naam = naam;
		//
		// Vertex-attributes ten behoeve van vertexposities:
		//
		shaderProgramWrap.vertexCoorAttribute = gl.getAttribLocation(shaderProgram, 'aVertexCoor');
		gl.enableVertexAttribArray(shaderProgramWrap.vertexCoorAttribute);

		//
		// Uniforms:
		//
		shaderProgramWrap.tableauSamplerUniform = gl.getUniformLocation(shaderProgram, 'uTableauSampler');
		gl.uniform1i(shaderProgramWrap.tableauSamplerUniform, 0);
		shaderProgramWrap.diepteSamplerUniform = gl.getUniformLocation(shaderProgram, 'uDiepteSampler');
		gl.uniform1i(shaderProgramWrap.diepteSamplerUniform, 1);
		shaderProgramWrap.nearUniform = gl.getUniformLocation(shaderProgram, 'uNear');
		shaderProgramWrap.farUniform = gl.getUniformLocation(shaderProgram, 'uFar');
		shaderProgramWrap.topUniform = gl.getUniformLocation(shaderProgram, 'uTop');
		shaderProgramWrap.rightUniform = gl.getUniformLocation(shaderProgram, 'uRight');
		shaderProgramWrap.proefRadiusUniform = gl.getUniformLocation(shaderProgram, 'uProefRadius');
		shaderProgramWrap.occlusieMachtUniform = gl.getUniformLocation(shaderProgram, 'uOcclusieMacht');

		//
		// Buffer met schermvullende quad:
		//
		let vertexDataBuffer: WebGLBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
		gl.bufferData(gl.ARRAY_BUFFER,
			new Float32Array([-1, -1, 1, -1, 1, 1, 1, 1, -1, 1, -1, -1]), // x-, y-coordinaten 
			// van vertices (z-coordinaat is irrelevant) van 2 driehoeken in de quad
			gl.STATIC_DRAW);
		shaderProgramWrap.vertexDataBufferWrap = { vertexDataBuffer: vertexDataBuffer, aantal: 6 }; // aantal vertices

		return true;
	}

	//
	// Initieert het WebGL-shader program van de viewer ten behoeve van het aanbrengen van SSAO.
	// Return-waarde is het shader program-object, of null wanneer er geen shader program gemaakt kon worden.
	//
	private initSSAOAanbrengenShaderProgram(): boolean {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		let naam: string = 'SSAO (aanbrengen)';
		let code: string = VLib.ShaderSet.vertexShaderCodeSSAO();
		let vertexShader: WebGLShader = this.getShader(gl, gl.VERTEX_SHADER, code, naam);
		if (!vertexShader) {
			return false;
		}

		code = VLib.ShaderSet.fragmentShaderCodeSSAOAanbrengen();
		let fragmentShader: WebGLShader = this.getShader(gl, gl.FRAGMENT_SHADER, code, naam);
		if (!fragmentShader) {
			return false;
		}
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.getShaderProgram(gl, vertexShader, fragmentShader, naam);
		if (!shaderProgramWrap) {
			return false;
		}

		let shaderProgram: WebGLProgram = shaderProgramWrap.shaderProgram;
		gl.useProgram(shaderProgram);
		this.privates.shaderProgramSSAOAanbrengenWrap = shaderProgramWrap;
		shaderProgramWrap.naam = naam;
		//
		// Vertex-attributes ten behoeve van vertexposities:
		//
		shaderProgramWrap.vertexCoorAttribute = gl.getAttribLocation(shaderProgram, 'aVertexCoor');
		gl.enableVertexAttribArray(shaderProgramWrap.vertexCoorAttribute);

		//
		// Uniforms:
		//
		shaderProgramWrap.tableauSamplerUniform = gl.getUniformLocation(shaderProgram, 'uTableauSampler');
		gl.uniform1i(shaderProgramWrap.tableauSamplerUniform, 0);
		shaderProgramWrap.occlusieSamplerUniform = gl.getUniformLocation(shaderProgram, 'uOcclusieSampler');
		gl.uniform1i(shaderProgramWrap.occlusieSamplerUniform, 1);
		shaderProgramWrap.invResolutieUniform = gl.getUniformLocation(shaderProgram, 'uInvResolutie');

		//
		// Buffer met schermvullende quad:
		//
		let vertexDataBuffer: WebGLBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
		gl.bufferData(gl.ARRAY_BUFFER,
			new Float32Array([-1, -1, 1, -1, 1, 1, 1, 1, -1, 1, -1, -1]), // x-, y-coordinaten 
			// van vertices (z-coordinaat is irrelevant) van 2 driehoeken in de quad
			gl.STATIC_DRAW);
		shaderProgramWrap.vertexDataBufferWrap = { vertexDataBuffer: vertexDataBuffer, aantal: 6 }; // aantal vertices

		return true;
	}

	//
	// Maakt een WebGL-shader program met de aangeleverde shaders.
	// Return-waarde is het shader program-object, of null wanneer er geen shader program gemaakt kon worden.
	//
	private getShaderProgram(gl: WebGLRenderingContext, vertexShader: WebGLShader, fragmentShader: WebGLShader, naam: string): VLib.WebGLProgramWrapType {
		let shaderProgram: WebGLProgram = gl.createProgram();
		if (!shaderProgram) {
			this.conditionalLog('Shader program kon niet gemaakt worden.' +
				' Naam: "' + naam + '".');
			return null;
		}

		gl.attachShader(shaderProgram, vertexShader);
		gl.attachShader(shaderProgram, fragmentShader);
		gl.linkProgram(shaderProgram);

		if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
			this.conditionalLog('Shader program kon niet gelinkt worden.' +
				' Naam: "' + naam + '".' +
				' Foutdetails: ' + gl.getProgramInfoLog(shaderProgram));
			gl.deleteProgram(shaderProgram);
			return null;
		}

		return { shaderProgram };
	};

	//
	// Maakt een WebGL-shader aan aan de hand van de aangeleverde shader code.
	// Return-waarde is het shader-object, of null wanneer er geen shader gemaakt kon worden.
	//
	private getShader(gl: WebGLRenderingContext, shaderType: number, shaderCode: string, naam: string): WebGLShader {
		if (!shaderCode || (shaderType != gl.VERTEX_SHADER && shaderType != gl.FRAGMENT_SHADER)) {
			return null;
		}

		let shader: WebGLShader = gl.createShader(shaderType);
		if (!shader) {
			this.conditionalLog('Shader kon niet gemaakt worden.' +
				' Shader type: "' + ((shaderType == gl.VERTEX_SHADER) ? 'vertex' : 'fragment') + '".' +
				' Naam: "' + naam + '".');
			return null;
		}

		gl.shaderSource(shader, shaderCode);
		gl.compileShader(shader);

		if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
			this.conditionalLog('Shader kon niet gecompileerd worden.' +
				' Shader type: "' + ((shaderType == gl.VERTEX_SHADER) ? 'vertex' : 'fragment') + '".' +
				' Naam: "' + naam + '".' +
				' Foutdetails: ' + gl.getShaderInfoLog(shader));
			gl.deleteShader(shader);
			return null;
		}

		return shader;
	};

	//
	// Ruimt de 3D-viewer op.
	// Openbare API-functie bedoeld voor intern gebruik.
	//
	public dispose(): void {
		if (this.privates.glWrap) {
			//
			// Ruim het model en andere bronnen op:
			//
			this.clear();
			this.wisHemel();
			this.wisAssen();
			//
			// Ruim de rendering context op:
			//
			this.disposeShaders();
			this.privates.canvasWrap = undefined;
			this.privates.glWrap = undefined;
		}
	};

	//
	// Geeft terug of de 3D-viewer opgeruimd (cq. ongeinitieerd) is.
	// Openbare API-functie bedoeld voor intern gebruik.
	//
	public isDisposed(): boolean {
		return (this.privates.glWrap == null || this.privates.canvasWrap == null);
	};

	//
	// Ruimt de WebGL-shaders van de viewer op.
	//
	private disposeShaders(): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		let shaderProgramsWraps: VLib.WebGLProgramWrapType[] = [this.privates.shaderProgramStdWrap, this.privates.shaderProgramPickingWrap,
			this.privates.shaderProgramShadowMappingWrap, this.privates.shaderProgramZBufferMappingWrap,
			this.privates.shaderProgramTextureCopyWrap, this.privates.shaderProgramFXAAWrap,
			this.privates.shaderProgramBokehWrap, this.privates.shaderProgramVignetteWrap,
			this.privates.shaderProgramSSAOBerekenenWrap, this.privates.shaderProgramSSAOAanbrengenWrap];

		for (let i: number = 0; i < shaderProgramsWraps.length; i++) {
			let shaderProgramWrap: VLib.WebGLProgramWrapType = shaderProgramsWraps[i];
			if (shaderProgramWrap) {
				let shaders: WebGLShader[] = gl.getAttachedShaders(shaderProgramWrap.shaderProgram);
				if (shaders) {
					for (let j: number = 0; j < shaders.length; j++) {
						let shader: WebGLShader = shaders[j];
						gl.detachShader(shaderProgramWrap.shaderProgram, shader);
						gl.deleteShader(shader);
					}
				}
				gl.deleteProgram(shaderProgramWrap.shaderProgram);
			}
		}
	};

	//
	// Geeft de index van het modelfragment in de lijst van fragmenten.
	// Parameter 'fragmentID' is de ID-string van het te zoeken modelfragment.
	// Return-waarde is een natuurlijk getal, of -1 wanneer het opvragen niet geslaagd is.
	//
	private indexOf(fragmentID: string): number {
		let fragmenten: VLib.FragmentType[] = this.privates.fragmenten;
		for (let i: number = 0; i < fragmenten.length; i++) {
			if (fragmenten[i].ID == fragmentID) {
				return i;
			}
		}
		return -1;
	};

	//
	// Geeft het modelfragment in de lijst van fragmenten.
	// Parameter 'fragmentID' is de ID-string van het te zoeken modelfragment.
	// Return-waarde een fragment-object, of null wanneer het opvragen niet geslaagd is.
	//
	private find(fragmentID: string): VLib.FragmentType {
		let fragmenten: VLib.FragmentType[] = this.privates.fragmenten;
		for (let i: number = 0; i < fragmenten.length; i++) {
			if (fragmenten[i].ID == fragmentID) {
				return fragmenten[i];
			}
		}
		return null;
	};

	//
	// Controleert de validiteit van een vermeende ID-string.
	// Parameter 'ID' is een te controleren ID-string.
	// Return-waarde is een boolean die true is wanneer de ID-string valide is; anders false.
	//
	private isValidId(ID: string): boolean {
		return (typeof (ID) == 'string' && ID.length > 0 && ID.length <= Viewer3D.MAX_LEN_FRAGMENT_ID &&
			//
			// ...en begint of eindigt niet met...
			//
			ID.indexOf('_') != 0 && ID.indexOf(' ') != 0 && ID.lastIndexOf(' ') != ID.length - 1 &&
			//
			// ...en bevat niet...
			//
			ID.indexOf('\'') == -1 && ID.indexOf('"') == -1 && ID.indexOf('+') == -1);
	};

	//
	// Verwerkt het aangeleverde modelfragment.
	// Parameter 'nieuwFragmentJson' is het te verwerken fragment.
	// Parameter 'nieuwFragment' is het fragment in wording.
	// Parameter texBronnen is set van textuurbronnen.
	//
	private verwerkFragment(nieuwFragmentJson: VLib.FragmentJsonType, nieuwFragment: VLib.FragmentType, texBronnen: VLib.TexBronType[]): void {
		try {
			nieuwFragment.itemsOpaak = []; // Veld itemsOpaak is verplicht. Zie commentaar binnen functie controleerTransformatielijstJson.
			this.verwerkFragmentItems(nieuwFragmentJson.itemsOpaak, nieuwFragment.itemsOpaak, nieuwFragment, texBronnen);

			if (nieuwFragmentJson.itemsTransparant) {
				nieuwFragment.itemsTransparant = []
				this.verwerkFragmentItems(nieuwFragmentJson.itemsTransparant, nieuwFragment.itemsTransparant, nieuwFragment, texBronnen);
			}
			//@@@Q1 Dit stukje kan nu weg.
			////
			//// Nu de object-verwijzingen naar de bronnen per teken-item zijn aangelegd (en deze de plaats van de 
			//// arrayindex-verwijzingen ingenomen hebben) kunnen de bron-arrays van het fragment verdwijnen:
			////
			//nieuwFragmentJson.texBronnen = undefined;
			//nieuwFragmentJson.texNamen = undefined;

			let transLijst: VLib.TransLijstType = this.initTransformatielijst(nieuwFragmentJson.transLijst);
			nieuwFragment.transLijst = transLijst;
			this.berekenFragmentDimensies(nieuwFragment);

			let visible: boolean = !(nieuwFragmentJson.isZichtbaar === false); // False for false. True for undefined, null or true. 
			nieuwFragment.isZichtbaar = visible;
			nieuwFragment.werptSlagschaduw = visible; // Note: can be independant of isZichtbaar and moetGetekend. But we don't use that at this moment.
			nieuwFragment.moetGetekend = visible;
		}
		catch (exc) {
			this.wisBuffers(nieuwFragment);
			this.verschoonTexBronnen(nieuwFragment.ID);
			throw exc;
		}

		this.privates.fragmenten.push(nieuwFragment);
		this.privates.modelDims = undefined;

		this.wisAssen();
		this.wisShadowMaps();
	};

	//
	// Verwerkt de aangeleverde teken-items van een modelfragment.
	// Parameter 'itemsJson' is een array van teken-items van een fragmentJson. Mag niet null zijn.
	// Parameter 'items' is het te vullen array van teken-items van een fragment.
	// Parameter 'fragment' is het betreffende fragment.
	// Parameter texBronnen is de set van textuurbronnen.
	//
	private verwerkFragmentItems(itemsJson: VLib.FragmentItemJsonType[], items: VLib.FragmentItemType[], fragment: VLib.FragmentType, texBronnen: VLib.TexBronType[]): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		for (let itemNr: number = 0; itemNr < itemsJson.length; itemNr++) {
			let itemJson: VLib.FragmentItemJsonType = itemsJson[itemNr];
			let item: VLib.FragmentItemType = { // Velden kleur, tablau, bumpmap, mengKleur, vertexDataBufferWrap en vertexIndicesBufferWrap worden pas na conversie (echt) gevuld.
				type: itemJson.type, subtype: itemJson.subtype, kleur: undefined, schitteringMat: itemJson.schitteringMat,
				positie: itemJson.positie, normaal: itemJson.normaal, tangent: itemJson.tangent, biTangent: itemJson.biTangent, vertexDataBufferWrap: undefined,
			};
			items[itemNr] = item;

			if (itemJson.type === this.privates.defTypeMarkeerpunt) {
				itemJson.vertexData = Viewer3D.marker3D[itemJson.subtype];
			}

			let vertexDataBuffer: WebGLBuffer = this.maakBuffer(fragment);
			gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
			gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(itemJson.vertexData), gl.STATIC_DRAW);
			let vertexDataBufferWrap: VLib.VertexDataBufferWrapType = {
				vertexDataBuffer: vertexDataBuffer,
				aantal: undefined // Wordt hieronder gezet.
			};
			item.vertexDataBufferWrap = vertexDataBufferWrap;

			let stride: number;
			let normaalOffset: number;
			let tangentOffset: number;
			let textuurCoorOffset: number;

			if (itemJson.type === this.privates.defTypeVolume) {
				let vertexIndicesBuffer: WebGLBuffer = this.maakBuffer(fragment);
				gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, vertexIndicesBuffer);
				gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(itemJson.vertexIndices), gl.STATIC_DRAW);
				let vertexIndicesBufferWrap: VLib.VertexIndicesBufferWrapType = {
					vertexIndicesBuffer: vertexIndicesBuffer,
					aantal: itemJson.vertexIndices.length
				};
				item.vertexIndicesBufferWrap = vertexIndicesBufferWrap;

				stride = 6; // x-, y-, z-, xn-, yn-, zn-coordinaten
				normaalOffset = 3; // 3 floats
				if (itemJson.tableau != undefined || itemJson.bumpmap != undefined) {
					stride = 8; // x-, y-, z-, xn-, yn-, zn-, s-, t-coordinaten
					textuurCoorOffset = 6; // 6 floats
				}
				if (itemJson.bumpmap != undefined) {
					stride = 11; // x-, y-, z-, xn-, yn-, zn-, s-, t-, xt-, yt-, zt-coordinaten
					tangentOffset = 8; // 8 floats
				}
			}
			else if (itemJson.type === this.privates.defTypeVlak) {
				if (itemJson.tableau != undefined || itemJson.bumpmap != undefined) {
					stride = 5; // x-, y-, z-, s-, t-coordinaten
					textuurCoorOffset = 3; // 3 floats
				}
				else {
					stride = 3; // x-, y-, z-coordinaten
				}
			}
			else if (itemJson.type === this.privates.defTypeLijn) {
				stride = 3; // x-, y-, z-coordinaten
			}
			else if (itemJson.type === this.privates.defTypeMarkeerpunt) {
				stride = 3; // x-, y-, z-coordinaten
			}

			vertexDataBufferWrap.aantal = itemJson.vertexData.length / stride;
			vertexDataBufferWrap.stride = stride * 4 /* sizeof(float) */;
			if (normaalOffset) {
				vertexDataBufferWrap.normaalOffset = normaalOffset * 4 /* sizeof(float) */;
			}
			if (tangentOffset) {
				vertexDataBufferWrap.tangentOffset = tangentOffset * 4 /* sizeof(float) */;
			}
			if (textuurCoorOffset) {
				vertexDataBufferWrap.textuurCoorOffset = textuurCoorOffset * 4 /* sizeof(float) */;
			}

			//
			// Vervang arrayindex-verwijzingen naar de verscheidene fragmentbron-arrays door object-verwijzingen:
			//
			item.kleur = fragment.kleurBronnen[itemJson.kleur];
			if (itemJson.tableau != null) {
				item.tableau = texBronnen[itemJson.tableau];
			}
			if (itemJson.bumpmap != null && this.privates.metBumpmappingEis && this.privates.metBumpmappingHint) {
				item.bumpmap = texBronnen[itemJson.bumpmap];
			}
			//@@@Q1 dit stukje kan nu weg: (was het wissen in itemJson)
			//else {
			//	itemJson.bumpmap = null;
			//}
			if (itemJson.mengKleur != null) {
				let mengKleurF: number[] = fragment.kleurBronnen[itemJson.mengKleur].slice();
				for (let i: number = 0; i < mengKleurF.length; i++) {
					mengKleurF[i] = Number((mengKleurF[i] / 255.0).toFixed(5));
				}
				item.mengKleur = mengKleurF;
			}
			//// //@@@Q1 dit stukje kan nu weg:
			//// Nu de vertex-data in buffers geplaatst is, kunnen de getal-arrays uit het teken-item verdwijnen:
			////
			//itemJson.vertexData = undefined;
			//if (itemJson.vertexIndices) {
			//	itemJson.vertexIndices = undefined;
			//}

			//
			// Bepaal de opmaak van het teken-item in de geselecteerde staat:
			//
			this.bepaalItemSelectieOpmaak(item);
		} // endloop items
	};

	//
	// Bepaalt de opmaak van teken-items van een modelfragment in de (on)geselecteerde staat.
	// Parameter 'items' is een array van te verwerken teken-item. Mag null zijn.
	//
	private bepaalItemsSelectieOpmaak(items: VLib.FragmentItemType[]): void {
		if (items) {
			for (let itemNr: number = 0; itemNr < items.length; itemNr++) {
				let item: VLib.FragmentItemType = items[itemNr];
				this.bepaalItemSelectieOpmaak(item);
			}
		}
	};

	//
	// Bepaalt de opmaak van een teken-item van een modelfragment in de (on)geselecteerde staat.
	// Parameter 'item' is het te verwerken teken-item.
	//
	private bepaalItemSelectieOpmaak(item: VLib.FragmentItemType): void {
		item.kleurGesel = undefined;
		item.kleurOngesel = undefined;
		item.mengKleurGesel = undefined;
		item.mengKleurOngesel = undefined;

		if (item.tableau || item.bumpmap) {
			item.mengKleurOngesel = item.mengKleur;

			if (this.privates.isSelectieKleurOpaak) {
				if (item.bumpmap) {
					//
					// Pas de opake selectiekleur toe als mengkleur:
					// NB: Een eventueel tableau wordt door de opake selectiekleur afgedekt.
					//
					item.mengKleurGesel = this.privates.selectieKleurInternF;
				}
				else {
					//
					// Vervang de tableau door een effen kleur:
					//
					item.kleurGesel = this.privates.selectieKleurIntern;
				}
			}
			else {
				if (item.mengKleur) {
					//
					// Het item heeft zelf al een mengkleur; combineer deze met de transparante selectiekleur:
					//
					let selKleur: number[] = this.privates.selectieKleurInternF;
					let alpha: number;
					let selKleurRatio: number;
					let mengKleurRatio: number;
					if (item.tableau) {
						//
						// De itemmengkleur wordt toegepast met een tableau (waarbij de alpha-waarde van de mengkleur de 
						// mengverhouding bepaald).
						// Het toepassen van de selectiekleur is niet commutatief: de selectiekleur dient toegepast te 
						// worden nádat de itemmengkleur op het tableau is aangebracht. Er kan echter afgeleid worden 
						// welke menging van selectiekleur en itemmengkleur vooràf hetzelfde resultaat oplevert.
						//
						alpha = 1.0 - (1.0 - selKleur[3]) * (1.0 - item.mengKleur[3]);
						selKleurRatio = selKleur[3] / alpha;
						mengKleurRatio = ((1.0 - selKleur[3]) * item.mengKleur[3]) / alpha;
					}
					else {
						//
						// De itemmengkleur wordt toegepast met enkel een bumpmap (waarbij de mengkleur opaak aangewend 
						// wordt).
						//
						alpha = 1.0;
						selKleurRatio = selKleur[3];
						mengKleurRatio = 1.0 - selKleurRatio;
					}
					item.mengKleurGesel = [
						mengKleurRatio * item.mengKleur[0] + selKleurRatio * selKleur[0],
						mengKleurRatio * item.mengKleur[1] + selKleurRatio * selKleur[1],
						mengKleurRatio * item.mengKleur[2] + selKleurRatio * selKleur[2],
						alpha];
				}
				else {
					//
					// Pas de transparante selectiekleur toe als mengkleur:
					//
					item.mengKleurGesel = this.privates.selectieKleurInternF;
				}
			}
		}
		else {
			item.kleurOngesel = item.kleur;

			if (this.privates.isSelectieKleurOpaak) {
				item.kleurGesel = this.privates.selectieKleurIntern;
			}
			else {
				let selKleur: number[] = this.privates.selectieKleurIntern;
				let selKleurRatio: number = this.privates.selectieKleurInternF[3];
				let kleurRatio: number = 1.0 - selKleurRatio;
				item.kleurGesel = [
					kleurRatio * item.kleur[0] + selKleurRatio * selKleur[0],
					kleurRatio * item.kleur[1] + selKleurRatio * selKleur[1],
					kleurRatio * item.kleur[2] + selKleurRatio * selKleur[2],
					255.0];
			}
		}
	};

	//
	// Verwerkt de bronnen van een nieuw fragment.
	// Parameter 'nieuwFragmentJson' is het te verwerken fragment.
	// Parameter texBronnen is de te vullen set van textuurbronnen.
	// Parameter 'gereedCallback' is een parameterloze functie die na voltooiing aangeroepen moet worden.
	//
	private verwerkFragmentBronnen(nieuwFragmentJson: VLib.FragmentJsonType, texBronnen: VLib.TexBronType[], gereedCallback: V3DSimpelCallbackType): void {
		let teLadenTexNamen: string[] = this.mergeTexBronnen(nieuwFragmentJson, texBronnen);
		if (teLadenTexNamen.length > 0) {
			this.maakTexBronnen(nieuwFragmentJson, teLadenTexNamen, texBronnen, gereedCallback);
		}
		else {
			gereedCallback();
		}
	};

	//
	// Verenigt de textuurnamen van een nieuw fragment met de textuurbronnen van het model.
	// Parameter 'nieuwFragmentJson' is het te verenigen fragment.
	// Parameter texBronnen is de te vullen set van textuurbronnen.
	// Return-waarde is een array van textuurnamen die voorheen niet aanwezig waren.
	//
	private mergeTexBronnen(nieuwFragmentJson: VLib.FragmentJsonType, texBronnen: VLib.TexBronType[]): string[] {
		if (nieuwFragmentJson.texNamen == null) {
			let dummy: string[] = [];
			return dummy;
		}

		//
		// Maak een lijst aan van textuurbronnen voor het fragment, parallel aan de lijst van textuurnamen. Later zullen 
		// de arrayindex-verwijzingen in de teken-items vervangen worden door object-verwijzingen. 
		//
		//@@@Q1 nieuwFragmentJson.texBronnen = [];
		//@@@Q1 nieuwFragmentJson.texBronnen.length = nieuwFragmentJson.texNamen.length;
		texBronnen.length = nieuwFragmentJson.texNamen.length;
		let texNamenTemp: string[] = nieuwFragmentJson.texNamen.slice();

		for (let i: number = 0; i < this.privates.texBronnen.length; i++) {
			let texBron: VLib.TexBronType = this.privates.texBronnen[i];
			for (let j: number = 0; j < texNamenTemp.length; j++) {
				if (texNamenTemp[j] == texBron.naam) {
					//
					// De textuurbron die hoort bij deze textuurnaam bestaat al in het model. Geef bij de textuurbronnen van 
					// het model aan dat dit fragment er ook gebruik van gaat maken en voeg een verwijzing toe aan de 
					// textuurbronnen van het fragment:
					//
					texBron.refs.push(nieuwFragmentJson.ID);
					//@@@Q1 nieuwFragmentJson.texBronnen[j] = texBron;
					texBronnen[j] = texBron;
					//
					// Deze textuurnaam is verwerkt. Maak de komende vergelijkingen sneller door de naam te wissen:
					//
					texNamenTemp[j] = null;
				}
			}
		}

		//
		// Verwijder de verwerkte (en gewiste) textuurnamen. Wat overblijft moet nog geladen worden:
		//
		for (let j: number = texNamenTemp.length - 1; j >= 0; j--) {
			if (texNamenTemp[j] == null) {
				texNamenTemp.splice(j, 1);
			}
		}

		return texNamenTemp;
	};

	//
	// Maakt nieuwe textuurbronnen die door het nieuwe fragment gebruikt worden.
	// Parameter 'nieuwFragmentJson' is het fragment.
	// Parameter 'teLadenTexNamen' is een array van textuurnamen.
	// Parameter texBronnen is de te vullen set van textuurbronnen.
	// Parameter 'gereedCallback' is een parameterloze functie die na voltooiing aangeroepen moet worden.
	//
	private maakTexBronnen(nieuwFragmentJson: VLib.FragmentJsonType, teLadenTexNamen: string[], texBronnen: VLib.TexBronType[], gereedCallback: V3DSimpelCallbackType): void {
		for (let i: number = teLadenTexNamen.length - 1; i >= 0; i--) {
			let texNaam: string = teLadenTexNamen[i];
			if (this.privates.texNamenBlacklist.indexOf(texNaam) != -1) {
				//
				// Er is al een keer geprobeerd deze bitmap te laden en dat was toen niet gelukt:
				//
				teLadenTexNamen.splice(i, 1);
			}
		}
		if (teLadenTexNamen.length > 0) {
			let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
			let ladenGereed: VLib.TexBronLadenGereedCallbackType = function (resultaatBitmap: HTMLImageElement, texNaam: string, isLadenGeslaagd: boolean): void {
				if (isLadenGeslaagd === true) {
					if (VLib.Lib.isMachtVanTwee(resultaatBitmap.width) && VLib.Lib.isMachtVanTwee(resultaatBitmap.height)) {
						let texBron: VLib.TexBronType = _this.maakTexBron(texNaam, resultaatBitmap);
						if (texBron != null) {
							texBron.refs.push(nieuwFragmentJson.ID);
							_this.privates.texBronnen.push(texBron);
							let index: number = nieuwFragmentJson.texNamen.indexOf(texNaam);
							//@@@Q1 nieuwFragmentJson.texBronnen[index] = texBron;
							texBronnen[index] = texBron;
						}
					}
					else {
						_this.privates.texNamenBlacklist.push(texNaam);
						_this.conditionalLog(texNaam, 9);
					}
				}
				else {
					_this.privates.texNamenBlacklist.push(texNaam);
					_this.conditionalLog(texNaam, 8);
				}

				let index: number = teLadenTexNamen.indexOf(texNaam);
				teLadenTexNamen.splice(index, 1);
				resultaatBitmap = undefined;
				if (teLadenTexNamen.length == 0) {
					gereedCallback();
				}
			};
			for (let i: number = 0; i < teLadenTexNamen.length; i++) {
				let texNaam: string = teLadenTexNamen[i];

				let bitmap: HTMLImageElement = new Image();
				bitmap.onload = function () {
					ladenGereed(<HTMLImageElement>this, texNaam, true);
				};
				bitmap.onerror = function () {
					ladenGereed(<HTMLImageElement>this, texNaam, false);
				};
				bitmap.onabort = function () {
					ladenGereed(<HTMLImageElement>this, texNaam, false);
				};
				bitmap.src = texNaam;
			}
		}
		else {
			gereedCallback();
		}
	};


	//
	// Maakt een nieuwe textuurbron.
	// Parameter 'texNaam' is de textuurnaam.
	// Parameter 'bitmap' is het geladen bitmap-object.
	// Return-waarde is een textuurbron, of null wanneer het maken niet geslaagd is.
	//
	private maakTexBron(texNaam: string, bitmap: HTMLImageElement): VLib.TexBronType {
		let texture: WebGLTexture;
		try {
			texture = this.maakTexture(bitmap);
		}
		catch (exc) {
			this.privates.texNamenBlacklist.push(texNaam);
			this.conditionalLog(texNaam, 10);
			return null;
		}

		let texBron: VLib.TexBronType = {
			naam: texNaam, // de bestandsnaam met (relatief) pad van de bitmap
			tex: texture, // de texure (i.e het texture buffer-object)
			heeftAlpha: (texNaam.search(/transparantOO/i) != -1),
			refs: [] // array met de ID-strings van de gebruikmakende fragmenten
		};
		return texBron;
	};

	//
	// Ruimt de textuurbronnen op die gebruikt werden door een modelfragment of alle modelfragmenten.
	// Parameter 'fragmentID' is de ID-string van het verwijderde modelfragment, of null om de textuurbronnen van alle 
	// fragmenten op te ruimen.
	//
	private verschoonTexBronnen(fragmentID?: string): void {
		if (fragmentID == null) {
			for (let i: number = 0; i < this.privates.texBronnen.length; i++) {
				let texBron: VLib.TexBronType = this.privates.texBronnen[i];
				this.privates.glWrap.gl.deleteTexture(texBron.tex);
			}
			this.privates.texBronnen = [];
		}
		else {
			for (let i: number = this.privates.texBronnen.length - 1; i >= 0; i--) {
				let texBron: VLib.TexBronType = this.privates.texBronnen[i];
				let index: number = texBron.refs.indexOf(fragmentID);
				if (index != -1) {
					texBron.refs.splice(index, 1);
					if (texBron.refs.length == 0) {
						this.privates.glWrap.gl.deleteTexture(texBron.tex);
						this.privates.texBronnen.splice(i, 1);
					}
				}
			}
		}
	};

	//
	// Maakt een nieuwe texture.
	// Parameter 'bitmap' is het geladen bitmap-object.
	// Return-waarde is de gemaakte texture (i.e een texture buffer-object). Werpt een exceptie op wanneer het maken 
	// niet slaagt.
	//
	private maakTexture(bitmap: HTMLImageElement): WebGLTexture {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let texture: WebGLTexture;
		try {
			texture = gl.createTexture();
			gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);
			gl.activeTexture(gl.TEXTURE0); //@@@QB Statement toegevoegd.
			gl.bindTexture(gl.TEXTURE_2D, texture);

			gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, bitmap);

			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
			if (this.privates.extTextureFilterAnisotropicWrap) {
				gl.texParameteri(gl.TEXTURE_2D,
					this.privates.extTextureFilterAnisotropicWrap.extTextureFilterAnisotropic.TEXTURE_MAX_ANISOTROPY_EXT,
					this.privates.extTextureFilterAnisotropicWrap.maxAnisotropy);
			}
			gl.generateMipmap(gl.TEXTURE_2D);
			//gl.bindTexture(gl.TEXTURE_2D, null); //@@@QB Null-parameter is niet de bedoeling, kan leiden tot warnings: [...]RENDER WARNING: there is no texture bound to the unit x.
			this.bindDummyTextuur(gl.ACTIVE_TEXTURE); //@@@QB Bugfix, tegen warnings: [...]RENDER WARNING: there is no texture bound to the unit x.

			return texture;
		}
		catch (exc) {
			//
			// Waarschijnlijk een cross-domain access violation.
			//
			//gl.bindTexture(gl.TEXTURE_2D, null); //@@@QB Null-parameter is niet de bedoeling, kan leiden tot warnings: [...]RENDER WARNING: there is no texture bound to the unit x.
			this.bindDummyTextuur(gl.TEXTURE0); //@@@QB Bugfix, tegen warnings: [...]RENDER WARNING: there is no texture bound to the unit x.
			gl.deleteTexture(texture);
			throw exc;
		}
	};

	//
	// Laadt een bitmap.
	// Parameter 'bestandsnaam' is een string met de bestandsnaam (met pad) van de bitmap.
	// Parameter 'gereedCallback' is een callback die wordt aangeroepen zodra het laden gereed is. De eerste parameter 
	// van de callback is het geladen bitmap-object, of null wanneer het laden niet geslaagd is. 
	// Parameter 'gereedCallbackParams' bevat optionele additionele parameters voor de callback. 
	// Vooralsnog zijn die parameters niet in gebruik, en daarom is type nu 'any' (= LaadBitmapGereedCallbackParametersType).
	//
	private laadBitmap(bestandsnaam: string, gereedCallback: VLib.LaadBitmapGereedCallbackType, gereedCallbackParams: VLib.LaadBitmapGereedCallbackParametersType): void {
		if (this.privates.texNamenBlacklist.indexOf(bestandsnaam) != -1) {
			//
			// Er is al een keer geprobeerd deze bitmap te laden en dat was toen niet gelukt:
			//
			gereedCallback(null, gereedCallbackParams);
			return;
		}

		let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
		let ladenGereed: VLib.BitmapLadenGereedCallbackType = function (resultaatBitmap: HTMLImageElement, isLadenGeslaagd: boolean): void {
			if (isLadenGeslaagd === true) {
				if (VLib.Lib.isMachtVanTwee(resultaatBitmap.width) == false ||
					VLib.Lib.isMachtVanTwee(resultaatBitmap.height) == false) {
					resultaatBitmap = null;
					_this.privates.texNamenBlacklist.push(bestandsnaam);
					_this.conditionalLog(bestandsnaam, 9);
				}
			}
			else {
				resultaatBitmap = null;
				_this.privates.texNamenBlacklist.push(bestandsnaam);
				_this.conditionalLog(bestandsnaam, 8);
			}
			gereedCallback(resultaatBitmap, gereedCallbackParams);
			resultaatBitmap = undefined;
		};

		let bitmap: HTMLImageElement = new Image();
		bitmap.onload = function () {
			ladenGereed(<HTMLImageElement>this, true);
		};
		bitmap.onerror = function () {
			ladenGereed(<HTMLImageElement>this, false);
		};
		bitmap.onabort = function () {
			ladenGereed(<HTMLImageElement>this, false);
		};
		bitmap.src = bestandsnaam;
	};

	//
	// Initieert de transformatielijst.
	// Parameter 'transformatielijstJson' is de betreffende transformatielijst. Mag null zijn.
	//
	private initTransformatielijst(transformatielijstJson: VLib.TransLijstJsonType): VLib.TransLijstType {
		if (transformatielijstJson == null) {
			return null;
		}

		let tlj: VLib.TransLijstJsonType = transformatielijstJson;
		//@@@Q1 to kill:
		//@@@Q1 Checking zit nu in nieuwe functie: controleerTransformatielijstJson.
		//let aantal: number = tlj.aantal;
		//if (isGetal(aantal) == false || aantal == 0 ||
		//	Array.isArray(tlj.transMats) == false || tlj.transMats.length != aantal ||
		//	isGetalLijst(tlj.transMats[0], 16) == false ||
		//	Array.isArray(tlj.rotMats) == false || tlj.rotMats.length != aantal ||
		//	isGetalLijst(tlj.rotMats[0], 9) == false ||
		//	Array.isArray(tlj.spiegelMats) == false || tlj.spiegelMats.length != aantal ||
		//	typeof (tlj.spiegelMats[0]) != 'boolean') {
		//	this.conditionalLog('Transformatielijst is niet geldig.', 11);
		//	return null;
		//}

		let transLijst: VLib.TransLijstType = {
			aantal: tlj.aantal, transMats: tlj.transMats, rotMats: tlj.rotMats, spiegelMats: tlj.spiegelMats,
			cacheTekenCyclus: undefined, cachedModelviewMats: undefined, cachedRotatieMats: undefined,
			cachedDiepteMats: undefined, cachedLampenDiepteMats: undefined
		};
		//
		// Voeg caches toe aan de transformatielijst:
		//
		let modelviewMatrices: number[][] = [];
		let rotatieMatrices: number[][] = [];
		let diepteMatrices: number[][] = [];
		let lampenDiepteMatrices: number[][][];
		if (this.privates.lampenMaxAantal != null) // kunstlichtbronnen worden ondersteund
		{
			lampenDiepteMatrices = [];
		}
		for (let i: number = 0; i < tlj.aantal; i++) {
			modelviewMatrices.push(<number[]>mat4.create()); // M.b.t. de cast: zie note (3) bovenin deze file.
			rotatieMatrices.push(<number[]>mat3.create()); // M.b.t. de cast: zie note (3) bovenin deze file.
			diepteMatrices.push(<number[]>mat4.create()); // M.b.t. de cast: zie note (3) bovenin deze file.
			if (lampenDiepteMatrices != null) {
				let lampDiepteMatrices: number[][] = [];
				for (let j: number = 0; j < this.privates.lampenMaxAantal; j++) {
					lampDiepteMatrices.push(<number[]>mat4.create()); // M.b.t. de cast: zie note (3) bovenin deze file.
				}
				lampenDiepteMatrices.push(lampDiepteMatrices);
			}
		}

		transLijst.cachedModelviewMats = modelviewMatrices;
		transLijst.cachedRotatieMats = rotatieMatrices;
		transLijst.cachedDiepteMats = diepteMatrices;
		transLijst.cachedLampenDiepteMats = lampenDiepteMatrices;
		transLijst.cacheTekenCyclus = undefined;
		return transLijst;
	};

	//
	// Vervangt een kleur in een modelfragment.
	// Parameter 'kleurIndex' is de index van de kleur in het fragment.
	// Parameter 'naarRGBA' is de kleur die de huidige moet vervangen, een array met lengte 4 met waarden in het 
	// bereik [0, 255]. Een opake kleur moet opaak blijven; een transparante kleur moet transparant blijven.
	// Parameter 'fragmentID' is de ID-string van het modelfragment waarin de kleur vervangen moet worden.
	//
	public vervangKleurOpIndex(kleurIndex: number, naarRGBA: number[], fragmentID: string): void {
		if (this.privates.glWrap && VLib.Lib.isGetalLijst(naarRGBA, 4, 0, 255) && (kleurIndex >= 0)) {
			let fragment: VLib.FragmentType = this.find(fragmentID);
			if (fragment == null) {
				this.conditionalLog(fragmentID, 7);
				return;
			}
			if (kleurIndex >= fragment.kleurBronnen.length) { throw "In fragment geen kleurBron aanwezig met index=" + kleurIndex }
			let vanRGBA: number[] = fragment.kleurBronnen[kleurIndex];
			//
			// Opake kleuren moeten opaak blijven; transparante transparant:
			//
			let behoudAlpha: boolean = (vanRGBA[3] == 255) || (naarRGBA[3] == 255);
			let loopLengte: number = (behoudAlpha ? 3 : 4);
			//
			// Wijzig de kleur.
			// De wijziging werkt vanzelf door naar de fragmentItems.
			//
			let isVerschillend: boolean = false;
			for (let i: number = 0; i < loopLengte; i++) {
				if (isVerschillend) {
					vanRGBA[i] = naarRGBA[i];
				}
				else if (vanRGBA[i] != naarRGBA[i]) {
					isVerschillend = true;
					vanRGBA[i] = naarRGBA[i];
				} // ELSE: Tot en met index i is alles gelijk.
			}
			if (isVerschillend) {
				this.bepaalItemsSelectieOpmaak(fragment.itemsOpaak);
				this.bepaalItemsSelectieOpmaak(fragment.itemsTransparant);
			} // ELSE: De kleur is niet veranderd.
		}
	};

	//
	// Vervangt een kleur in een modelfragment.
	// Parameter 'vanRGBA' is de kleur waarnaar gezocht moet worden, een array met lengte 4 met waarden in het 
	// bereik [0, 255].
	// Parameter 'naarRGBA' is de kleur die de huidige moet vervangen, een array met lengte 4 met waarden in het 
	// bereik [0, 255]. Een opake kleur moet opaak blijven; een transparante kleur 
	// moet transparant blijven. Bij afwezigheid wordt de originele kleur hersteld.
	// Parameter 'fragmentID' is de ID-string van het modelfragment waarin de kleur vervangen moet worden.
	// Optionele parameter 'vanOrigineel' is een boolean die aangeeft of er gezocht moet worden naar de originele 
	// kleur (true) of naar de huidige kleur (false; default).
	// Return-waarde is een getal dat aangeeft hoe vaak de vervanging is uitgevoerd, of null wanneer de vervanging niet 
	// is geslaagd.
	//
	private vervangKleur(vanRGBA: number[], naarRGBA: number[], fragmentID: string, vanOrigineel: boolean): number {
		if (this.privates.glWrap && VLib.Lib.isGetalLijst(vanRGBA, 4, 0, 255) &&
			(naarRGBA == null || VLib.Lib.isGetalLijst(naarRGBA, 4, 0, 255))) {
			vanOrigineel = (vanOrigineel === true);

			let fragment: VLib.FragmentType = this.find(fragmentID);
			if (fragment == null) {
				this.conditionalLog(fragmentID, 7);
				return null;
			}

			if (naarRGBA && vanRGBA[0] == naarRGBA[0] && vanRGBA[1] == naarRGBA[1] &&
				vanRGBA[2] == naarRGBA[2] && vanRGBA[3] == naarRGBA[3]) {
				if (vanOrigineel) {
					//
					// Er wordt geprobeerd de originele kleur te vervangen met een gelijke kleur. Dit is equivalent aan het 
					// herstellen van de originele kleur:
					//
					naarRGBA = null;
				}
				else {
					//
					// Er wordt geprobeerd de huidige kleur te vervangen met een gelijke kleur:
					//
					return 0;
				}
			}
			if (naarRGBA) {
				//
				// Opake kleuren moeten opaak blijven; transparante transparant:
				//
				if (vanRGBA[3] == 255) {
					naarRGBA[3] = 255;
				}
				else if (naarRGBA[3] == 255) {
					naarRGBA[3] = vanRGBA[3];
				}
			}

			let aantal: number = 0;
			for (let j: number = 0; j < fragment.kleurBronnen.length; j++) {
				let bronRGBA: number[] = fragment.kleurBronnen[j];
				//
				// Is de huidige kleur van de kleurbron de originele kleur of is deze ooit vervangen? Een vervangende kleur 
				// gaat in de array vooraf aan de originele kleur:
				//
				let isOrigineel: boolean = (bronRGBA.length == 4);

				let kStart: number = (vanOrigineel == false || isOrigineel) ? 0 : 4;
				for (let k: number = 0; k < 4; k++) {
					if (bronRGBA[k + kStart] != vanRGBA[k]) {
						bronRGBA = null;
						break;
					}
				}
				if (bronRGBA) {
					if (naarRGBA == null && isOrigineel) {
						//
						// De originele kleur moet hersteld worden, maar de huidige kleur is de originele kleur; herstellen 
						// is dus niet nodig:
						//
						break;
					}

					let teWissenAantal: number = isOrigineel ? 0 : 4;
					let spliceArgs: number[] = [0, teWissenAantal];
					if (naarRGBA) {
						spliceArgs = spliceArgs.concat(naarRGBA);
					}
					Array.prototype.splice.apply(bronRGBA, spliceArgs);

					aantal++;
					break;
				}
			}

			if (aantal > 0) {
				this.bepaalItemsSelectieOpmaak(fragment.itemsOpaak);
				this.bepaalItemsSelectieOpmaak(fragment.itemsTransparant);
			}

			return aantal;
		}
		return null;
	};

	//
	// Vervangt een mengkleur in een modelfragment.
	// Parameter 'vanRGBA' is de kleur waarnaar gezocht moet worden, een array met lengte 4 met waarden in het 
	// bereik [0, 255].
	// Parameter 'naarRGBA' is de kleur die de huidige moet vervangen, een array met lengte 4 met waarden in het 
	// bereik [0, 255]. Bij afwezigheid wordt de originele kleur hersteld.
	// Parameter 'fragmentID' is de ID-string van het modelfragment waarin de mengkleur vervangen moet worden.
	// Optionele parameter 'vanOrigineel' is een boolean die aangeeft of er gezocht moet worden naar originele 
	// kleur (true) of de huidige kleur (false; default).
	// Return-waarde is een getal dat aangeeft hoe vaak de vervanging is uitgevoerd, of null wanneer de vervanging niet 
	// is geslaagd.
	//
	private vervangMengKleur(vanRGBA: number[], naarRGBA: number[], fragmentID: string, vanOrigineel: boolean): number {
		if (this.privates.glWrap && VLib.Lib.isGetalLijst(vanRGBA, 4, 0, 255) &&
			(naarRGBA == null || VLib.Lib.isGetalLijst(naarRGBA, 4, 0, 255))) {
			vanOrigineel = (vanOrigineel === true);

			let fragment: VLib.FragmentType = this.find(fragmentID);
			if (fragment == null) {
				this.conditionalLog(fragmentID, 7);
				return null;
			}

			if (naarRGBA && vanRGBA[0] == naarRGBA[0] && vanRGBA[1] == naarRGBA[1] &&
				vanRGBA[2] == naarRGBA[2] && vanRGBA[3] == naarRGBA[3]) {
				if (vanOrigineel) {
					//
					// Er wordt geprobeerd de originele mengkleur te vervangen met een gelijke kleur. 
					// Dit is equivalent aan het herstellen van de originele kleur:
					//
					naarRGBA = null;
				}
				else {
					//
					// Er wordt geprobeerd de huidige mengkleur te vervangen met een gelijke kleur:
					//
					return 0;
				}
			}

			//
			// Breng de mengkleur in het bereik [0, 1]:
			//
			vanRGBA = vanRGBA.slice();
			for (let k: number = 0; k < 4; k++) {
				vanRGBA[k] = Number((vanRGBA[k] / 255.0).toFixed(5));
			}
			if (naarRGBA) {
				naarRGBA = naarRGBA.slice();
				for (let k: number = 0; k < 4; k++) {
					naarRGBA[k] = Number((naarRGBA[k] / 255.0).toFixed(5));
				}
			}

			let aantal: number = 0;
			//
			// NB: Mengkleuren zijn geassocieerd met texturen; een item met een textuur is altijd opaak.
			//
			let items: VLib.FragmentItemType[] = fragment.itemsOpaak;
			for (let i: number = 0; i < items.length; i++) {
				let mengKleur: number[] = items[i].mengKleur;
				if (mengKleur) {
					//
					// Is de huidige mengkleur de originele of is deze ooit vervangen? Een vervangende kleur gaat in de 
					// array vooraf aan de originele kleur:
					//
					let isOrigineel: boolean = (mengKleur.length == 4);

					if (naarRGBA == null && isOrigineel) {
						//
						// De originele mengkleur moet hersteld worden, maar de huidige kleur is de originele kleur; 
						// herstellen is dus niet nodig:
						//
						continue;
					}

					let kStart: number = (vanOrigineel == false || isOrigineel) ? 0 : 4;
					for (let k: number = 0; k < 4; k++) {
						if (mengKleur[k + kStart] != vanRGBA[k]) {
							mengKleur = null;
							break;
						}
					}
					if (mengKleur) {
						let teWissenAantal: number = isOrigineel ? 0 : 4;
						let spliceArgs: number[] = [0, teWissenAantal];
						if (naarRGBA) {
							spliceArgs = spliceArgs.concat(naarRGBA);
						}
						Array.prototype.splice.apply(mengKleur, spliceArgs);

						aantal++;
					}
				}
			}

			if (aantal > 0) {
				this.bepaalItemsSelectieOpmaak(items);
			}

			return aantal;
		}
		return null;
	};

	//
	// Vervangt een textuur in een modelfragment.
	// Parameter 'bestandsnaamHuidig' is een string met de bestandsnaam (met pad) van de huidige, te vervangen bitmap.
	// Parameter 'bestandsnaamVervanging' is een string met de bestandsnaam (met pad) van de vervangende bitmap.
	// Parameter 'fragmentID' is de ID-string van het modelfragment waarin de textuur vervangen moet worden.
	// Parameter 'gereedCallback' is een callback die wordt aangeroepen zodra het vervangen van de textuur gereed is. 
	// Een callback is nodig omdat het verwerken van de vervanging mogelijk asynchroon verloopt. De callback heeft één 
	// parameter: een getal dat aangeeft hoe vaak de vervanging is uitgevoerd, of null wanneer de vervanging niet is 
	// geslaagd.
	//
	private vervangTextuur(bestandsnaamHuidig: string, bestandsnaamVervanging: string, fragmentID: string, gereedCallback: V3DAantalCallbackType): void {
		if (this.privates.glWrap && typeof (gereedCallback) == 'function') {
			if (typeof (bestandsnaamHuidig) != 'string' ||
				typeof (bestandsnaamVervanging) != 'string' || typeof (fragmentID) != 'string') {
				gereedCallback(null);
				return;
			}

			bestandsnaamHuidig = bestandsnaamHuidig.toLowerCase();
			bestandsnaamVervanging = bestandsnaamVervanging.toLowerCase();
			if (bestandsnaamHuidig == bestandsnaamVervanging) {
				gereedCallback(null);
				return;
			}

			let fragment: VLib.FragmentType = this.find(fragmentID);
			if (fragment == null) {
				this.conditionalLog(fragmentID, 7);
				gereedCallback(null);
				return;
			}

			let texBronHuidig: VLib.TexBronType;
			let texBronVervanging: VLib.TexBronType;
			for (let i: number = 0; i < this.privates.texBronnen.length; i++) {
				let texBron: VLib.TexBronType = this.privates.texBronnen[i];
				if (texBronHuidig == null && texBron.naam == bestandsnaamHuidig) {
					texBronHuidig = texBron;
				}
				if (texBronVervanging == null && texBron.naam == bestandsnaamVervanging) {
					texBronVervanging = texBron;
				}
				if (texBronHuidig && texBronVervanging) {
					break;
				}
			}
			if (texBronHuidig == null || texBronHuidig.refs.indexOf(fragmentID) == -1) {
				//
				// De textuur wordt niet gebruikt in het fragment en kan dus niet vervangen worden:
				//
				gereedCallback(null);
				return;
			}

			let _this: Viewer3D = this; // Tbv functiedefinities binnen deze functie.
			let voerVervangingUit: VLib.TexBronVervangFunctieType = function (tbHuidig: VLib.TexBronType, tbVervanging: VLib.TexBronType): number {
				let aantal: number = 0;

				//
				// Verwijder de referentie naar het fragment uit de vervangen textuurbron:
				//
				let index: number = tbHuidig.refs.indexOf(fragmentID);
				tbHuidig.refs.splice(index, 1);
				if (tbHuidig.refs.length == 0) {
					//
					// Ruim de in onbruik gevallen textuurbron op:
					//
					_this.privates.glWrap.gl.deleteTexture(tbHuidig.tex);
					index = _this.privates.texBronnen.indexOf(tbHuidig);
					_this.privates.texBronnen.splice(index, 1);
				}

				//
				// NB: Een item met een textuur is altijd opaak.
				//
				let items: VLib.FragmentItemType[] = fragment.itemsOpaak;
				for (let i: number = 0; i < items.length; i++) {
					let item: VLib.FragmentItemType = items[i];
					if (item.tableau === texBronHuidig) {
						item.tableau = tbVervanging;
						aantal++;
					}
					else if (item.bumpmap === texBronHuidig) {
						item.bumpmap = tbVervanging;
						aantal++;
					}
				}

				return aantal;
			};

			if (texBronVervanging) {
				//
				// Er bestaat al een textuurbron voor de vervangende bitmap.
				//
				if (texBronVervanging.refs.indexOf(fragmentID) == -1) {
					texBronVervanging.refs.push(fragmentID);
				}
				//else
				//
				// De textuurbron wordt al gebruikt in het fragment.
				//

				let aantal: number = voerVervangingUit(texBronHuidig, texBronVervanging);
				gereedCallback(aantal);
			}
			else {
				//
				// Er bestaat nog geen textuurbron voor de vervangende bitmap.
				//
				// M.b.t. onderstaande functie-definitie:
				// Vooralsnog is 'parameters' niet in gebruik, en daarom is type nu 'any' (= LaadBitmapGereedCallbackParametersType).
				//
				let ladenUitgevoerd: VLib.LaadBitmapGereedCallbackType = function (bitmap: HTMLImageElement, parameters: VLib.LaadBitmapGereedCallbackParametersType): void { 
					let aantal: number = null;
					if (bitmap) {
						texBronVervanging = _this.maakTexBron(bestandsnaamVervanging, bitmap);
						if (texBronVervanging) {
							_this.privates.texBronnen.push(texBronVervanging);
							texBronVervanging.refs.push(fragmentID);

							aantal = voerVervangingUit(texBronHuidig, texBronVervanging);
						}
						//else
						//
						// Het aanmaken van de textuurbron is niet geslaagd.
						//
					}
					//else
					//
					// Het laden van de bitmap is niet geslaagd.
					//

					gereedCallback(aantal);
				};

				this.laadBitmap(bestandsnaamVervanging, ladenUitgevoerd, null);
			}
		}
	};

	//
	// Voert de verandering van het ID van een modelfragment door in de bronnen.
	// Parameter 'oudFragmentID' is de oude ID-string van het modelfragment.
	// Parameter 'nieuwFragmentID' is de nieuwe ID-string van het modelfragment.
	//
	private changeIdInBronnen(oudFragmentID: string, nieuwFragmentID: string): void {
		for (let i: number = this.privates.texBronnen.length - 1; i >= 0; i--) {
			let texBron: VLib.TexBronType = this.privates.texBronnen[i];
			let index: number = texBron.refs.indexOf(oudFragmentID);
			if (index != -1) {
				texBron.refs.splice(index, 1, nieuwFragmentID);
			}
		}
	};

	//
	// Maakt een WebGL-buffer voor het aangeleverde modelfragment.
	// Parameter 'fragment' is een fragment.
	// Return-waarde is een bufferobject.
	//
	private maakBuffer(fragment: VLib.FragmentType): WebGLBuffer {
		if (fragment.buffers == null) {
			fragment.buffers = [];
		}

		let nieuweBuffer: WebGLBuffer = this.privates.glWrap.gl.createBuffer();
		fragment.buffers.push(nieuweBuffer);

		return nieuweBuffer;
	};

	//
	// Wist de WebGL-buffers die gebruikt werden door het aangeleverde modelfragment.
	// Parameter 'fragment' is het verwijderde modelfragment, of null om de buffers van alle fragmenten op te ruimen.
	//
	private wisBuffers(fragment?: VLib.FragmentType): void {
		if (fragment == null) {
			for (let i: number = 0; i < this.privates.fragmenten.length; i++) {
				let fragment: VLib.FragmentType = this.privates.fragmenten[i];
				this.wisBuffers(fragment);
			}
		}
		else {
			let buffers: WebGLBuffer[] = fragment.buffers;
			if (buffers != null) {
				for (let i: number = 0; i < buffers.length; i++) {
					this.privates.glWrap.gl.deleteBuffer(buffers[i]);
				}
				fragment.buffers = undefined;
			}
		}
	};

	//
	// Berekent de dimensies van een modelfragment.
	// Parameter 'fragment' is het fragment.
	//
	private berekenFragmentDimensies(fragment: VLib.FragmentType): void {
		if (fragment.bbTrans == null) // fragment bounding box = [ xMin, xMax, yMin, yMax, zMin, zMax ]
		{
			if (fragment.transLijst == null) {
				fragment.bbTrans = fragment.bb;
			}
			else {
				//
				// Bepaal de acht hoekpunten van het ongetransformeerde omvattende blok:
				//
				let hoeken: number[][] = [];
				for (let i: number = 0; i < 8; i++) {
					let hoek: number[] = [
						fragment.bb[Math.floor(i / 4)], 			// x
						fragment.bb[2 + Math.floor(i / 2) % 2], 	// y
						fragment.bb[4 + (i % 2)]];					// z
					hoeken.push(hoek);
				}

				let bbTransNieuw: number[] = [1e10, -1e10, 1e10, -1e10, 1e10, -1e10];
				let transMats: number[][] = fragment.transLijst.transMats;
				for (let i: number = 0; i < transMats.length; i++) {
					let transMat: number[] = transMats[i];
					//
					// Transformeer de hoekpunten van het ongetransformeerde omvattende blok:
					//
					for (let j: number = 0; j < 8; j++) {
						let hoek: number[] = hoeken[j].slice();
						mat4.multiplyVec3(transMat, hoek);
						for (let k: number = 0, m = 0; k < 6; k += 2, m++) {
							bbTransNieuw[k] = Math.min(bbTransNieuw[k], hoek[m]);
							bbTransNieuw[k + 1] = Math.max(bbTransNieuw[k + 1], hoek[m]);
						}
					}
				}
				fragment.bbTrans = bbTransNieuw;
			}
		}
		let bbTrans: number[] = fragment.bbTrans;

		let afm: number[] = [];
		let ctr: number[] = [];
		let radius2: number = 0;
		for (let i: number = 0; i < 6; i += 2) {
			let min: number = bbTrans[i];
			let max: number = bbTrans[i + 1];
			afm.push(max - min);
			ctr.push(0.5 * (min + max));
			let halfAfm: number = 0.5 * (max - min);
			radius2 += halfAfm * halfAfm;
		}
		fragment.afmetingen = afm;
		fragment.centrum = ctr;
		fragment.bbRadius = Math.sqrt(radius2);
	};

	//
	// Berekent de dimensies van het model, dat wil zeggen van alle modelfragmenten tezamen.
	// Return-waarde is een object met de dimensies, of null wanneer het model leeg is.
	//
	private berekenModelDimensies(): VLib.ModelDimensiesType {
		let fragmenten: VLib.FragmentType[] = this.privates.fragmenten;
		if (fragmenten.length == 0) {
			return null;
		}
		//
		// Bepaal het omvattende blok van het model:
		//
		let bb: number[] = fragmenten[0].bbTrans.slice();
		for (let i: number = 1; i < fragmenten.length; i++) {
			let bbI: number[] = fragmenten[i].bbTrans;
			for (let j: number = 0; j < 6; j += 2) {
				bb[j] = Math.min(bb[j], bbI[j]);
				bb[j + 1] = Math.max(bb[j + 1], bbI[j + 1]);
			}
		}
		//
		// Bepaal de afmentingen en het centrum van het model:
		//
		let afm: number[] = [];
		let ctr: number[] = [];
		for (let i: number = 0; i < 6; i += 2) {
			let min: number = bb[i];
			let max: number = bb[i + 1];
			afm.push(max - min);
			ctr.push(0.5 * (min + max));
		}
		//
		// Bepaal de schaal van het stelsel, de straal van een bol met als middelpunt de oorsprong van het assenstelsel:
		//
		let afstandVersteHoek: number = 0.0;
		for (let i: number = 0; i < 6; i += 2) {
			let versteHoekpunt: number = Math.max(Math.abs(bb[i]), Math.abs(bb[i + 1]));
			// afstand van hoekpunt tot oorsprong
			afstandVersteHoek = afstandVersteHoek + versteHoekpunt * versteHoekpunt;
		}
		afstandVersteHoek = Math.sqrt(afstandVersteHoek);
		if (afstandVersteHoek < VLib.Lib._epsilon) {
			afstandVersteHoek = 1.0;
		}
		//
		// Bepaal de schaal van het model, de straal van een bol met als middelpunt het centrum van het model:
		//
		let halveLichaamsdiagonaal: number = 0.5 * Math.hypot(afm[0], afm[1], afm[2]);
		if (halveLichaamsdiagonaal < VLib.Lib._epsilon) {
			halveLichaamsdiagonaal = 1.0;
		}

		let modelDims: VLib.ModelDimensiesType = {
			bb: bb, // [ xMin, xMax, yMin, yMax, zMin, zMax ]
			afmetingen: afm, // [ x-lengte, y-diepte, z-hoogte ]
			centrum: ctr, // [ x, y, z ]
			stelselStraal: afstandVersteHoek,
			modelStraal: halveLichaamsdiagonaal
		};
		return modelDims;
	};

	//
	// Controleer of JSON-vorm van fragment in orde is.
	// Parameter fragmentJson mag niet null zijn.
	//
	private controleerFragmentJson(fragmentJson: VLib.FragmentJsonType, texAllowed: boolean, transAllowed: boolean): void {
		let fJ: VLib.FragmentJsonType = fragmentJson;

		if (typeof fJ != 'object') { throw "JSON-fragment is geen object" };
		if (fJ == null) { throw "JSON-fragment is null" }; // Let op m.b.t. vorige regel: typeof null == 'object'.

		if (typeof fJ.wat != 'string') { throw "JSON-fragment.wat is geen string" };
		if (fJ.wat != 'fragment') { throw "JSON-fragment.wat heeft niet waarde 'fragment'" };

		if (!VLib.Lib.isGeheelGetal(fJ.versie)) { throw "JSON-fragment.versie is geen geheel getal" };

		if (fJ.ID == null) { throw "JSON-fragment.ID not present" };
		if (!this.isValidId(fJ.ID)) { throw "JSON-fragment.ID is geen valide ID" };

		if (!VLib.Lib.isGetalLijst(fJ.bb, 6)) { throw "JSON-fragment.bb is geen getal-array of heeft foute lengte" };

		if (fJ.bbTrans != null) {
			if (!transAllowed) { throw "JSON-fragment.bbTrans is not allowed" };
			if (!VLib.Lib.isGetalLijst(fJ.bbTrans, 6)) { throw "JSON-fragment.bbTrans is geen getal-array of heeft foute lengte" };
		}

		if (!Array.isArray(fJ.kleurBronnen)) { throw "JSON-fragment.kleurBronnen is geen array" };
		if (fJ.kleurBronnen.length <= 0) { throw "JSON-fragment.kleurBronnen is leeg array" };
		if (!VLib.Lib.isGetalLijst(fJ.kleurBronnen[0], 4)) { // Aanname: als voor i=0 OK, dan ook voor i>0.
			throw "JSON-fragment.kleurBronnen[i] is geen getal-array of heeft foute lengte"
		};

		if (fJ.texNamen != null) {
			if (!texAllowed) { throw "JSON-fragment.texNamen is not allowed" };
			if (!Array.isArray(fJ.texNamen)) { throw "JSON-fragment.texNamen is geen array" };
			if (fJ.texNamen.length <= 0) { throw "JSON-fragment.texNamen is leeg array" };
			if (typeof fJ.texNamen[0] != 'string') { throw "JSON-fragment.texNamen[i] is geen string" }; // Aanname: als voor i=0 OK, dan ook voor i>0.
		}

		if (fJ.transLijst != null) {
			if (!transAllowed) { throw "JSON-fragment.transLijst is not allowed" };
		}
		//
		// Velden bbTrans en transLijst moeten beide aanwezig zijn, of beide afwezig.
		//
		if (fJ.bbTrans == null) {
			if (fJ.transLijst != null) { throw "JSON-fragment heeft wel transLijst maar geen bbTrans" };
		}
		else { // fJ.bbTrans != null
			if (fJ.transLijst == null) { throw "JSON-fragment heeft wel bbTrans maar geen transLijst" };
		}

		this.controleerTransformatielijstJson(fJ.transLijst);
		//
		// Momenteel moet er minimaal 1 opaak item zijn. De code in deze class gaat daar op meerdere plekken vanuit.
		// Echter: daar waar de JSON-fragmenten ontstaan bestaat wel degelijk de mogelijkheid dat er geen opake items zijn.
		// Dus wellicht in de toekomst zal de code in deze class daarop aangepast moeten worden.
		// De voorwaarde zal dan wellicht worden: er moet minimaal 1 item zijn, opaak of transparant.
		// Vooralsnog gaan we uit van: er moet minimaal 1 opaak item zijn.
		//
		this.controleerItemsJson(fJ.itemsOpaak, fJ, true, texAllowed);
		if (fJ.itemsTransparant != null) {
			this.controleerItemsJson(fJ.itemsTransparant, fJ, false, texAllowed);
		}
	}

	//
	// Controleer of JSON-vorm van transformatielijst in orde is.
	// Parameter transformatielijstJson mag null zijn.
	//
	private controleerTransformatielijstJson(transformatielijstJson: VLib.TransLijstJsonType): void {
		let tJ: VLib.TransLijstJsonType = transformatielijstJson;
		if (tJ == null) { return };
		if (typeof tJ != 'object') { throw "JSON-transformatielijst is geen object" };

		let aant: number = tJ.aantal;
		if (!VLib.Lib.isGeheelGetal(aant)) { throw "JSON-transformatielijst.aantal is geen geheel getal" };
		if (aant <= 0) { throw "JSON-transformatielijst.aantal is negatief of 0" };

		if (!Array.isArray(tJ.transMats)) { throw "JSON-transformatielijst.transMats is geen array" };
		if (tJ.transMats.length != aant) { throw "JSON-transformatielijst.transMats heeft foute lengte" };
		if (!VLib.Lib.isGetalLijst(tJ.transMats[0], 16)) { // Aanname: als voor i=0 OK, dan ook voor i>0.
			throw "JSON-transformatielijst.transMats[i] is geen getal-array of heeft foute lengte"
		};

		if (!Array.isArray(tJ.rotMats)) { throw "JSON-transformatielijst.rotMats is geen array" };
		if (tJ.rotMats.length != aant) { throw "JSON-transformatielijst.rotMats heeft foute lengte" };
		if (!VLib.Lib.isGetalLijst(tJ.rotMats[0], 9)) { // Aanname: als voor i=0 OK, dan ook voor i>0.
			throw "JSON-transformatielijst.rotMats[i] is geen getal-array of heeft foute lengte"
		};

		if (!Array.isArray(tJ.spiegelMats)) { throw "JSON-transformatielijst.spiegelMats is geen array" };
		if (tJ.spiegelMats.length != aant) { throw "JSON-transformatielijst.spiegelMats heeft foute lengte" };
		if (typeof tJ.spiegelMats[0] != 'boolean') { throw "JSON-transformatielijst.spiegelMats[i] is geen boolean" }; // Aanname: als voor i=0 OK, dan ook voor i>0.
	}

	//
	// Controleer of JSON-vorm van een lijst items in orde is.
	// Parameter itemsJson mag niet null zijn, en mag niet leeg zijn.
	// Parameter fragmentJson: het fragment waar de items deel van uitmaken.
	// Parameter isOpaak: true voor een opaak item, false voor een transparant item. T.b.v. exception-messages.
	//
	private controleerItemsJson(itemsJson: VLib.FragmentItemJsonType[], fragmentJson: VLib.FragmentJsonType, isOpaak: boolean, texAllowed: boolean): void {
		let tag: string = "JSON-" + (isOpaak ? "opaak" : "transparant");
		let iJs: VLib.FragmentItemJsonType[] = itemsJson;
		let fJ: VLib.FragmentJsonType = fragmentJson;

		if (iJs == null) { throw tag + "-itemarray is null" };
		if (!Array.isArray(iJs)) { throw tag + "-itemarray is geen array" };
		if (iJs.length <= 0) { throw tag + "-itemarray is leeg" };

		for (let i: number = 0; i < iJs.length; i++) {
			let iJ: VLib.FragmentItemJsonType = iJs[i];
			//
			// Eerst kijken naar type-issues.
			//
			if (typeof iJ != 'object') { throw tag + "-item is geen object" };
			if (iJ == null) { throw tag + "-item is null" }; // Let op m.b.t. vorige regel: typeof null == 'object'.

			if (!VLib.Lib.isGeheelGetal(iJ.type)) { throw tag + "-item.type is geen geheel getal" };

			if (iJ.subtype != null) {
				if (typeof iJ.subtype != 'string') { throw tag + "-item.subtype is geen string" };
			}

			if (!VLib.Lib.isGeheelGetal(iJ.kleur)) { throw tag + "-item.kleur is geen geheel getal" };

			if (iJ.schitteringMat != null) {
				if (!VLib.Lib.isGetal(iJ.schitteringMat)) { throw tag + "-item.schitteringMat is geen getal" };
			}

			if (iJ.positie != null) {
				if (!VLib.Lib.isGetalLijst(iJ.positie, 3)) { throw tag + "-item.positie is geen getal-array of heeft foute lengte" };
			}

			if (iJ.vertexData != null) {
				//
				// Kan heel groot array zijn. 
				// Vanwege de performance hier niet functie isGetalLijst gebruikt.
				//
				if (!Array.isArray(iJ.vertexData, )) { throw tag + "-item.vertexData is geen array" };
				if (iJ.vertexData.length <= 0) { throw tag + "-item.vertexData is een leeg array" };
				if (!VLib.Lib.isGetal(iJ.vertexData[0])) { throw tag + "-item.vertexData[i] is geen getal" }; // Aanname: als voor i=0 OK, dan ook voor i>0.
			}

			if (iJ.vertexIndices != null) {
				//
				// Kan heel groot array zijn. 
				// Vanwege de performance hier niet functie isGetalLijst gebruikt.
				//
				if (!Array.isArray(iJ.vertexIndices, )) { throw tag + "-item.vertexIndices is geen array" };
				if (iJ.vertexIndices.length <= 0) { throw tag + "-item.vertexIndices is een leeg array" };
				if (!VLib.Lib.isGeheelGetal(iJ.vertexIndices[0])) { throw tag + "-item.vertexIndices[i] is geen geheel getal" }; // Aanname: als voor i=0 OK, dan ook voor i>0.
			}

			if (iJ.normaal != null) {
				if (!VLib.Lib.isGetalLijst(iJ.normaal, 3)) { throw tag + "-item.normaal is geen getal-array of heeft foute lengte" };
			}

			if (iJ.tableau != null) {
				if (!texAllowed) { throw tag + "-item.tableau is not allowed" };
				if (!VLib.Lib.isGeheelGetal(iJ.tableau)) { throw tag + "-item.tableau is geen geheel getal" };
			}

			if (iJ.bumpmap != null) {
				if (!texAllowed) { throw tag + "-item.bumpmap is not allowed" };
				if (!VLib.Lib.isGeheelGetal(iJ.bumpmap)) { throw tag + "-item.bumpmap is geen geheel getal" };
			}

			if (iJ.tangent != null) {
				if (!VLib.Lib.isGetalLijst(iJ.tangent, 3)) { throw tag + "-item.tangent is geen getal-array of heeft foute lengte" };
			}

			if (iJ.biTangent != null) {
				if (!VLib.Lib.isGetalLijst(iJ.biTangent, 3)) { throw tag + "-item.biTangent is geen getal-array of heeft foute lengte" };
			}

			if (iJ.mengKleur != null) {
				if (!VLib.Lib.isGeheelGetal(iJ.mengKleur)) { throw tag + "-item.mengKleur is geen geheel getal" };
			}

			//
			// Nu de meer inhoudelijke issues.
			//

			if (iJ.type < this.privates.defTypeMarkeerpunt) { throw tag + "-item.type is negatief" };
			if (iJ.type > this.privates.defTypeVolume) { throw tag + "-item.type is te groot" };

			if (iJ.type == this.privates.defTypeMarkeerpunt) {
				if (iJ.subtype == null) { throw tag + "-item.subtype is afwezig bij markeerpunt" };
				if (iJ.subtype.length <= 0) { throw tag + "-item.subtype is leeg bij markeerpunt" };
				//
				// Momenteel geen controle op de toegestane waarden van veld subtype.
				//
				if (iJ.positie == null) { throw tag + "-item.positie is afwezig bij markeerpunt" };
				if (iJ.vertexData != null) { // Let op: krijgt later in functie verwerkFragmentItems alsnog een waarde voor een markeerpunt.
					throw tag + "-item.vertexData is aanwezig bij markeerpunt"
				};
			}
			else {
				if (iJ.subtype != null) { throw tag + "-item.subtype is aanwezig bij lijn, vlak of volume" };
				if (iJ.positie != null) { throw tag + "-item.positie is aanwezig bij lijn, vlak of volume" };
				if (iJ.vertexData == null) { throw tag + "-item.vertexData is afwezig bij lijn, vlak of volume" };
				//
				// Momenteel geen controle of de lengte van veld vertexData wel 'klopt', gegeven het type en de aan/afwezigheid van tableau en/of bumpmap.
				//
			}

			if (iJ.kleur < 0) { throw tag + "-item.kleur is negatief" };
			if (iJ.kleur > fJ.kleurBronnen.length) { throw tag + "-item.kleur is te groot" }; // Veld kleur is een index op fragment-veld kleurBronnen.

			if ((iJ.type == this.privates.defTypeVlak) || (iJ.type == this.privates.defTypeVolume)) {
				if (iJ.schitteringMat == null) { throw tag + "-item.schitteringMat is afwezig bij vlak of volume" };
			}
			else {
				if (iJ.schitteringMat != null) { throw tag + "-item.schitteringMat is aanwezig bij markeerpunt of lijn" };
			}

			if (iJ.type == this.privates.defTypeVolume) {
				if (iJ.vertexIndices == null) { throw tag + "-item.vertexIndices is afwezig bij volume" };
				//
				// Momenteel geen controle of de inhoud van veld vertexData wel klopt met de lengte van veld vertexData. En of de waarden niet negatief zijn.
				// Is tevens  een performance-kwestie, want vertexData kan een heel groot array zijn.
				//
			}
			else {
				if (iJ.vertexIndices != null) { throw tag + "-item.vertexIndices is aanwezig bij markeerpunt, lijn of vlak" };
			}

			if (iJ.type == this.privates.defTypeVlak) {
				if (iJ.normaal == null) { throw tag + "-item.normaal is afwezig bij vlak" };
			}
			else {
				if (iJ.normaal != null) { throw tag + "-item.normaal is aanwezig bij markeerpunt, lijn of volume" };
			}

			if (iJ.tableau != null) {
				if ((iJ.type == this.privates.defTypeMarkeerpunt) || (iJ.type == this.privates.defTypeLijn)) { throw tag + "-item.tableau is aanwezig bij markeerpunt of lijn" };
				// else: bij vlak of volume is veld wel toegestaan, maar niet verplicht.
				if (iJ.tableau < 0) { throw tag + "-item.tableau is negatief" };
				if (iJ.tableau > fJ.texNamen.length) { throw tag + "-item.tableau is te groot" }; // Veld tableau is een index op fragment-veld texNamen.
			}

			if (iJ.bumpmap != null) {
				if ((iJ.type == this.privates.defTypeMarkeerpunt) || (iJ.type == this.privates.defTypeLijn)) { throw tag + "-item.bumpmap is aanwezig bij markeerpunt of lijn" };
				// else: bij vlak of volume is veld wel toegestaan, maar niet verplicht.
				if (iJ.bumpmap < 0) { throw tag + "-item.bumpmap is negatief" };
				if (iJ.bumpmap > fJ.texNamen.length) { throw tag + "-item.bumpmap is te groot" }; // Veld bumpmap is een index op fragment-veld texNamen.
			}

			if ((iJ.type == this.privates.defTypeVlak) && (iJ.bumpmap != null)) {
				if (iJ.tangent == null) { throw tag + "-item.tangent is afwezig bij vlak met bumpmap" };
				if (iJ.biTangent == null) { throw tag + "-item.biTangent is afwezig bij vlak met bumpmap" };
			}
			else {
				if (iJ.tangent != null) { throw tag + "-item.tangent is aanwezig bij markeerpunt, lijn of volume, of bij vlak zonder bumpmap" };
				if (iJ.biTangent != null) { throw tag + "-item.biTangent is aanwezig bij markeerpunt, lijn of volume, of bij vlak zonder bumpmap" };
			}

			if (iJ.mengKleur != null) {
				if ((iJ.type == this.privates.defTypeMarkeerpunt) || (iJ.type == this.privates.defTypeLijn) ||
					((iJ.type == this.privates.defTypeVlak) && (iJ.tableau == null) && (iJ.bumpmap == null))) {
					throw tag + "-item.mengKleur is aanwezig bij markeerpunt of lijn, of bij vlak zonder tableau en bumpmap"
				}; // else: bij volume of [vlak met tableau of bumpmap] is veld wel toegestaan, maar niet verplicht.
				if (iJ.mengKleur < 0) { throw tag + "-item.mengKleur is negatief" };
				if (iJ.mengKleur > fJ.kleurBronnen.length) { throw tag + "-item.mengKleur is te groot" }; // Veld mengKleur is een index op fragment-veld kleurBronnen.
			}

			if ((iJ.type == this.privates.defTypeMarkeerpunt) || (iJ.type == this.privates.defTypeLijn) ||
				((iJ.type == this.privates.defTypeVlak) && ((iJ.tableau != null) || (iJ.bumpmap != null)))) {
				if (!isOpaak) { // Markeerpunten, lijnen en [vlakken met tableau en/of bumpmap] mogen alleen opaak zijn.
					throw tag + "-item is een transparant markeerpunt, lijn of vlak met tableau en/of bumpmap"
				};
				// else: [vlak met tableau of bumpmap] of volume: mag zowel opaak als transparant zijn.
			}
		} // endloop items
	}

	//
	// Tekent de (eventueel geanimeerde) view.
	//
	private tekenCallback: V3DSimpelCallbackType = (): void => {
		this.teken();
	}

	private teken(): void {
		if (this.privates.glWrap) {
			if (this.privates.nabewerkingen) //@@@Q Conditie zit nu binnen onderstaande call: [ && this.privates.nabewerkingen.idleTimeoutHandle != null)]
			{
				//
				// Stop met aftellen van de lopende idle-periode:
				//
				//@@@Q vervangen door onderstaande call: window.clearTimeout(this.privates.nabewerkingen.idleTimeoutHandle);
				//@@@Q vervangen door onderstaande call: this.privates.nabewerkingen.idleTimeoutHandle = null;
				this.privates.nabewerkingen.staakIdlePeriode();
			}
			else if (this.privates.afterIdleActief) {
				this.afterIdle();
				//
				// Deactiveer de functie weer:
				//
				this.privates.afterIdleActief = false;
			}

			if (this.privates.isGeanimeerd || this.privates.isGelust) {
				window.requestAnimationFrame(this.tekenCallback);

				let huidigeTijd: number = Date.now(); // in ms

				if (this.privates.isGelust) {
					let deltaTijd: number = huidigeTijd - this.privates.lusVorigeTijd;
					this.privates.lusVorigeTijd = huidigeTijd;
					this.lusToepassen(deltaTijd);
				}

				if (this.privates.isGeanimeerd) {
					if (this.privates.animatieStartTijd == null) {
						this.privates.animatieStartTijd = huidigeTijd;
						this.privates.animatieVorigeTijd = huidigeTijd;
					}
					let deltaTijd: number = huidigeTijd - this.privates.animatieVorigeTijd;
					let verstrekenTijd: number = huidigeTijd - this.privates.animatieStartTijd;
					this.privates.animatieVorigeTijd = huidigeTijd;

					this.privates.animatieCallback(this.privates.canvasWrap.canvas, deltaTijd, verstrekenTijd);
				}
			}
			else if (this.privates.nabewerkingen && //@@@Q conditie zit nu binnen onderstaande de call: [this.privates.nabewerkingen.idlePeriode > 0 && ]
				this.privates.fragmenten.length > 0) {
				//
				// Start met aftellen van een nieuwe idle-periode:
				//
				//@@@Q vervangen door onderstaande call: this.privates.nabewerkingen.idleTimeoutHandle = window.setTimeout(onIdle, 
				//@@@Q    this.privates.nabewerkingen.idlePeriode * 1000);
				this.privates.nabewerkingen.zetTimeout(this.onIdleCallback);
			}

			try {
				this.tekenScene();
			}
			catch (exc) {
				this.conditionalLog('Model kon niet getekend worden. Foutdetails: ' + exc);
			}
		}
	};

	//
	// Tekent een scene van de view.
	//
	private tekenScene(): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
		gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

		if (this.privates.fragmenten.length == 0) {
			return;
		}

		this.tekenHemel();

		this.stelProjectieMatrixIn(this.privates.projectieMatrix, this.privates.projectieClippingFrustum);
		this.stelModelviewMatrixIn(this.privates.modelviewMatrix, this.privates.rotatieMatrix);

		this.stelLichtbronIn();
		this.stelCameraIn();

		if (this.privates.toonAssen === true) {
			this.tekenAssen();
		}

		//
		// Wissel de teken-cyclus vóór het tekenen van de scene (maar nà het eventuele tekenen van de shadow-map scene):
		//
		this.privates.tekenCyclus = !this.privates.tekenCyclus;

		let fragmenten: VLib.FragmentType[] = this.privates.fragmenten;
		//
		// Voer 'culling' uit op de fragmenten:
		//
		if (this.privates.projectieClippingFrustum.Frustum) {
			let frustum: VLib.FrustumType = this.privates.projectieClippingFrustum.Frustum;
			for (let i: number = 0; i < fragmenten.length; i++) {
				let fragment: VLib.FragmentType = fragmenten[i];
				fragment.moetGetekend = this.bepaalMoetGetekend(fragment, frustum);
				if (fragment.transLijst) {
					fragment.transLijst.cacheTekenCyclus = undefined;
				}
			}
		}

		if (this.privates.selectieIDs.length == 0) {
			//
			// Teken opake items:
			//
			for (let i: number = 0; i < fragmenten.length; i++) {
				let fragment: VLib.FragmentType = fragmenten[i];
				if (fragment.moetGetekend) {
					this.tekenItems(fragment.itemsOpaak, null, false, fragment.transLijst, null);
				}
			}
			//
			// Teken transparante items:
			//
			gl.depthMask(false);
			gl.disable(gl.CULL_FACE);
			for (let i: number = 0; i < fragmenten.length; i++) {
				let fragment: VLib.FragmentType = fragmenten[i];
				if (fragment.moetGetekend) {
					this.tekenItems(fragment.itemsTransparant, null, false, fragment.transLijst, null);
				}
			}
			gl.enable(gl.CULL_FACE);
			gl.depthMask(true);
		}
		else {
			//
			// Teken de ongeselecteerde opake en geselecteerde items:
			//
			for (let i: number = 0; i < fragmenten.length; i++) {
				let fragment: VLib.FragmentType = fragmenten[i];
				if (fragment.moetGetekend) {
					let selectieID: VLib.SelectieIDInternType = this.findInSelectie(this.privates.selectieIDs, fragment.ID);
					let fragmentInSelectie: boolean = (selectieID != null);
					let items: VLib.FragmentItemType[] = fragment.itemsOpaak;
					let meerItems: VLib.FragmentItemType[] = fragmentInSelectie ? fragment.itemsTransparant : null;
					let transLijstIndices: number[] = selectieID ? selectieID.transLijstIndices : null;

					if (!transLijstIndices) {
						//
						// Het fragment heeft geen transformatielijst, of heeft deze wel maar alle transformaties in die 
						// transformatielijst hebben dezelfde selectiestaat.
						//
						this.tekenItems(items, meerItems, fragmentInSelectie, fragment.transLijst, null);
					}
					else {
						//
						// Het fragment heeft een transformatielijst en niet alle transformaties in die transformatielijst 
						// zijn geselecteerd.  
						//
						this.tekenItems(items, meerItems, false, fragment.transLijst, transLijstIndices, true); // de ongeselecteerde; buiten de indices-array
						this.tekenItems(items, meerItems, true, fragment.transLijst, transLijstIndices, false); // de geselecteerde; binnen de indices-array
					}
				}
			}
			//
			// Teken de ongeselecteerde transparante items:
			//
			gl.depthMask(false);
			gl.disable(gl.CULL_FACE);
			for (let i: number = 0; i < fragmenten.length; i++) {
				let fragment: VLib.FragmentType = fragmenten[i];
				if (fragment.moetGetekend) {
					let items: VLib.FragmentItemType[] = fragment.itemsTransparant;
					if (items) {
						//
						// NB: De kans is groot dat transparante items afwezig zijn, dus bespaar waar mogelijk het onnodig 
						// opzoeken van de selectiestaat.
						//
						let selectieID: VLib.SelectieIDInternType = this.findInSelectie(this.privates.selectieIDs, fragment.ID);
						let fragmentInSelectie: boolean = (selectieID != null);
						if (!fragmentInSelectie) {
							let transLijstIndices: number[] = selectieID ? selectieID.transLijstIndices : null;

							this.tekenItems(items, null, false, fragment.transLijst, transLijstIndices, false); // de ongeselecteerde; binnen de eventuele indices-array
						}
					}
				}
			}
			gl.enable(gl.CULL_FACE);
			gl.depthMask(true);
		}
	};

	//
	// Tekent een aantal items van een modelfragment.
	// Parameter 'items' is een array met te tekenen items. Mag null zijn.
	// Parameter 'meerItems' is nog een array met te tekenen items. Mag null zijn.
	// Parameter 'geselecteerd' is een boolean die aangeeft of de items in geselecteerde staat moeten worden getekend.
	// Parameter 'transLijst' is de te gebruiken transformatielijst. Mag null zijn.
	// Parameter 'transLijstIndices' is de array van indices van de transformatielijst. Wordt genegeerd wanneer er geen 
	// transformatielijst is. Waarde null duidt alle transformaties in de transformatielijst aan.
	// Optionele parameter 'buiten' is een boolean die aangeeft of de elementen buiten de aangeleverde array 
	// 'transLijstIndices' moeten worden getekend (true) of de elementen daarbinnen (false; default).
	//
	private tekenItems(items: VLib.FragmentItemType[], meerItems: VLib.FragmentItemType[], geselecteerd: boolean, transLijst: VLib.TransLijstType, transLijstIndices: number[], buiten?: boolean): void {
		buiten = (buiten === true);

		if (items || meerItems) {
			if (transLijst) {
				let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
				let tekenActieFunctie: VLib.TekenActieFunctieType = function (transLijstIndexHuidig: number): void {
					if (transLijstIndices) {
						let isBinnen: boolean = (transLijstIndices.indexOf(transLijstIndexHuidig) != -1)
						if (buiten == isBinnen) {
							return;
						}
					}

					if (items) {
						for (let i: number = 0; i < items.length; i++) {
							_this.tekenItem(items[i], geselecteerd);
						}
					}
					if (meerItems) {
						for (let i: number = 0; i < meerItems.length; i++) {
							_this.tekenItem(meerItems[i], geselecteerd);
						}
					}
				};
				this.tekenMetTransLijst(tekenActieFunctie, transLijst);
			}
			else {
				if (items) {
					for (let i: number = 0; i < items.length; i++) {
						this.tekenItem(items[i], geselecteerd);
					}
				}
				if (meerItems) {
					for (let i: number = 0; i < meerItems.length; i++) {
						this.tekenItem(meerItems[i], geselecteerd);
					}
				}
			}
		}
	};

	//
	// Tekent een item van een modelfragment.
	// Parameter 'item' is het te tekenen item.
	// Parameter 'geselecteerd' is een boolean die aangeeft of het item in geselecteerde staat moet worden getekend.
	//
	private tekenItem(item: VLib.FragmentItemType, geselecteerd: boolean): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramWrap;

		let kleur: number[] = geselecteerd ? item.kleurGesel : item.kleurOngesel;
		let mengKleur: number[] = geselecteerd ? item.mengKleurGesel : item.mengKleurOngesel;
		let vertexDataBufferWrap: VLib.VertexDataBufferWrapType = item.vertexDataBufferWrap;
		let vertexDataBuffer: WebGLBuffer = vertexDataBufferWrap.vertexDataBuffer;

		let aangezetTextuurCoorAttribute: boolean = false;
		let aangezetTangentAttribute: boolean = false;
		let aangezetTableauTexturing: boolean = false;
		let aangezetTableauVormTexturing: boolean = false;
		let aangezetBumpmapTexturing: boolean = false;
		let aangezetMengKleur: boolean = false;
		let uitgezetCullFace: boolean = false;

		if (item.type === this.privates.defTypeVolume) {
			gl.uniform1i(shaderProgramWrap.elemTypeUniform, this.privates.defTypeVolume);

			gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
			gl.vertexAttribPointer(shaderProgramWrap.vertexCoorAttribute, 3 /* x-, y-, z-coordinaten */, gl.FLOAT,
				false, vertexDataBufferWrap.stride, 0);
			gl.vertexAttribPointer(shaderProgramWrap.normaalAttribute, 3 /* xn-, yn-, zn-coordinaten */, gl.FLOAT,
				false, vertexDataBufferWrap.stride, vertexDataBufferWrap.normaalOffset);
			gl.enableVertexAttribArray(shaderProgramWrap.normaalAttribute);

			if (kleur == null) {
				if (item.tableau) {
					gl.uniform1i(shaderProgramWrap.tableauTexturingUniform, VLib.Lib._webGLTrue);
					aangezetTableauTexturing = true;

					gl.activeTexture(gl.TEXTURE0);
					gl.bindTexture(gl.TEXTURE_2D, item.tableau.tex);

					if (item.tableau.heeftAlpha === true) {
						gl.disable(gl.CULL_FACE);
						uitgezetCullFace = true;
					}
				}
				if (item.bumpmap) {
					gl.uniform1i(shaderProgramWrap.bumpmapTexturingUniform, VLib.Lib._webGLTrue);
					aangezetBumpmapTexturing = true;

					gl.activeTexture(gl.TEXTURE2);
					gl.bindTexture(gl.TEXTURE_2D, item.bumpmap.tex);

					gl.vertexAttribPointer(shaderProgramWrap.tangentAttribute, 3 /* xt-, yt-, zt-coordinaten */, gl.FLOAT,
						false, vertexDataBufferWrap.stride, vertexDataBufferWrap.tangentOffset);
					gl.enableVertexAttribArray(shaderProgramWrap.tangentAttribute);
					aangezetTangentAttribute = true;
				}
				if (mengKleur) {
					gl.uniform4f(shaderProgramWrap.mengKleurUniform, mengKleur[0], mengKleur[1], mengKleur[2], mengKleur[3]);
					aangezetMengKleur = true;
				}

				gl.vertexAttribPointer(shaderProgramWrap.textuurCoorAttribute, 2 /* s-, t-coordinaten */, gl.FLOAT,
					false, vertexDataBufferWrap.stride, vertexDataBufferWrap.textuurCoorOffset);
				gl.enableVertexAttribArray(shaderProgramWrap.textuurCoorAttribute);
				aangezetTextuurCoorAttribute = true;
			}
			else if (item.tableau && item.tableau.heeftAlpha === true) {
				gl.uniform1i(shaderProgramWrap.tableauVormTexturingUniform, VLib.Lib._webGLTrue);
				aangezetTableauVormTexturing = true;

				gl.activeTexture(gl.TEXTURE0);
				gl.bindTexture(gl.TEXTURE_2D, item.tableau.tex);

				gl.vertexAttrib4fv(shaderProgramWrap.kleurAttribute, kleur);

				gl.vertexAttribPointer(shaderProgramWrap.textuurCoorAttribute, 2 /* s-, t-coordinaten */, gl.FLOAT,
					false, vertexDataBufferWrap.stride, vertexDataBufferWrap.textuurCoorOffset);
				gl.enableVertexAttribArray(shaderProgramWrap.textuurCoorAttribute);
				aangezetTextuurCoorAttribute = true;

				gl.disable(gl.CULL_FACE);
				uitgezetCullFace = true;
			}
			else {
				gl.vertexAttrib4fv(shaderProgramWrap.kleurAttribute, kleur);
			}
			gl.uniform1f(shaderProgramWrap.schitteringMateriaalUniform, item.schitteringMat);

			gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, item.vertexIndicesBufferWrap.vertexIndicesBuffer);
			gl.drawElements(gl.TRIANGLES, item.vertexIndicesBufferWrap.aantal, gl.UNSIGNED_SHORT, 0);

			gl.disableVertexAttribArray(shaderProgramWrap.normaalAttribute);
			if (aangezetTextuurCoorAttribute) {
				gl.disableVertexAttribArray(shaderProgramWrap.textuurCoorAttribute);

				if (aangezetTangentAttribute) {
					gl.disableVertexAttribArray(shaderProgramWrap.tangentAttribute);
				}
				if (aangezetTableauTexturing) {
					gl.uniform1i(shaderProgramWrap.tableauTexturingUniform, VLib.Lib._webGLFalse);
				}
				else if (aangezetTableauVormTexturing) {
					gl.uniform1i(shaderProgramWrap.tableauVormTexturingUniform, VLib.Lib._webGLFalse);
				}
				if (aangezetBumpmapTexturing) {
					gl.uniform1i(shaderProgramWrap.bumpmapTexturingUniform, VLib.Lib._webGLFalse);
				}
				if (aangezetMengKleur) {
					gl.uniform4fv(shaderProgramWrap.mengKleurUniform, this.privates.clearMengKleur);
				}
				if (uitgezetCullFace) {
					gl.enable(gl.CULL_FACE);
				}
			}
		}
		else if (item.type === this.privates.defTypeVlak) {
			gl.uniform1i(shaderProgramWrap.elemTypeUniform, this.privates.defTypeVlak);

			gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
			gl.vertexAttribPointer(shaderProgramWrap.vertexCoorAttribute, 3 /* x-, y-, z-coordinaten */, gl.FLOAT,
				false, vertexDataBufferWrap.stride, 0);
			gl.vertexAttrib3fv(shaderProgramWrap.normaalAttribute, item.normaal);

			if (kleur == null) {
				if (item.tableau) {
					gl.uniform1i(shaderProgramWrap.tableauTexturingUniform, VLib.Lib._webGLTrue);
					aangezetTableauTexturing = true;

					gl.activeTexture(gl.TEXTURE0);
					gl.bindTexture(gl.TEXTURE_2D, item.tableau.tex);
				}
				if (item.bumpmap) {
					gl.uniform1i(shaderProgramWrap.bumpmapTexturingUniform, VLib.Lib._webGLTrue);
					aangezetBumpmapTexturing = true;

					gl.activeTexture(gl.TEXTURE2);
					gl.bindTexture(gl.TEXTURE_2D, item.bumpmap.tex);

					gl.vertexAttrib3fv(shaderProgramWrap.tangentAttribute, item.tangent);
					gl.uniform3fv(shaderProgramWrap.biTangentUniform, item.biTangent);
				}
				if (mengKleur) {
					gl.uniform4f(shaderProgramWrap.mengKleurUniform, mengKleur[0], mengKleur[1], mengKleur[2], mengKleur[3]);
					aangezetMengKleur = true;
				}

				gl.vertexAttribPointer(shaderProgramWrap.textuurCoorAttribute, 2 /* s-, t-coordinaten */, gl.FLOAT,
					false, vertexDataBufferWrap.stride, vertexDataBufferWrap.textuurCoorOffset);
				gl.enableVertexAttribArray(shaderProgramWrap.textuurCoorAttribute);
				aangezetTextuurCoorAttribute = true;
			}
			else if (item.tableau && item.tableau.heeftAlpha === true) {
				gl.uniform1i(shaderProgramWrap.tableauVormTexturingUniform, VLib.Lib._webGLTrue);
				aangezetTableauVormTexturing = true;

				gl.activeTexture(gl.TEXTURE0);
				gl.bindTexture(gl.TEXTURE_2D, item.tableau.tex);

				gl.vertexAttrib4fv(shaderProgramWrap.kleurAttribute, kleur);

				gl.vertexAttribPointer(shaderProgramWrap.textuurCoorAttribute, 2 /* s-, t-coordinaten */, gl.FLOAT,
					false, vertexDataBufferWrap.stride, vertexDataBufferWrap.textuurCoorOffset);
				gl.enableVertexAttribArray(shaderProgramWrap.textuurCoorAttribute);
				aangezetTextuurCoorAttribute = true;
			}
			else {
				gl.vertexAttrib4fv(shaderProgramWrap.kleurAttribute, kleur);
			}
			gl.uniform1f(shaderProgramWrap.schitteringMateriaalUniform, item.schitteringMat);

			gl.disable(gl.CULL_FACE);
			gl.drawArrays(gl.TRIANGLES, 0, vertexDataBufferWrap.aantal);
			gl.enable(gl.CULL_FACE);

			if (aangezetTextuurCoorAttribute) {
				gl.disableVertexAttribArray(shaderProgramWrap.textuurCoorAttribute);

				if (aangezetTableauTexturing) {
					gl.uniform1i(shaderProgramWrap.tableauTexturingUniform, VLib.Lib._webGLFalse);
				}
				else if (aangezetTableauVormTexturing) {
					gl.uniform1i(shaderProgramWrap.tableauVormTexturingUniform, VLib.Lib._webGLFalse);
				}
				if (aangezetBumpmapTexturing) {
					gl.uniform1i(shaderProgramWrap.bumpmapTexturingUniform, VLib.Lib._webGLFalse);
				}
				if (aangezetMengKleur) {
					gl.uniform4fv(shaderProgramWrap.mengKleurUniform, this.privates.clearMengKleur);
				}
			}
		}
		else if (item.type === this.privates.defTypeLijn) {
			gl.uniform1i(shaderProgramWrap.elemTypeUniform, this.privates.defTypeLijn);

			gl.vertexAttrib4fv(shaderProgramWrap.kleurAttribute, kleur);

			gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
			gl.vertexAttribPointer(shaderProgramWrap.vertexCoorAttribute, 3 /* x-, y-, z-coordinaten */, gl.FLOAT,
				false, 0, 0);

			gl.drawArrays(gl.LINES, 0, vertexDataBufferWrap.aantal);
		}
		else if (item.type === this.privates.defTypeMarkeerpunt) {
			gl.uniform1i(shaderProgramWrap.elemTypeUniform, this.privates.defTypeMarkeerpunt);

			gl.vertexAttrib4fv(shaderProgramWrap.kleurAttribute, kleur);

			gl.uniform3fv(shaderProgramWrap.elemPositieUniform, item.positie);
			gl.uniform1f(shaderProgramWrap.zoomSchaalUniform, this.privates._zoomSchaal);

			gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
			gl.vertexAttribPointer(shaderProgramWrap.vertexCoorAttribute, 3 /* x-, y-, z-coordinaten */, gl.FLOAT,
				false, 0, 0);

			gl.drawArrays(gl.LINE_STRIP, 0, vertexDataBufferWrap.aantal);
		}

	};

	//
	// Voert een tekenactie uit met de aangeleverde transformatielijst.
	// Parameter 'tekenActie' is een functie die de tekenactie uitvoert. De functie heeft één parameter: de index van de 
	// transformatie in de transformatielijst.
	// Parameter 'transLijst' is de te gebruiken transformatielijst.
	// Optionele parameter 'modelviewMatrix' is een mat4 die getransformeerd dient te worden (in de plaats van de 
	// modelviewmatrix van de huidig ingestelde camera; default).
	// Optionele parameter 'metRotatieMatrix' is een boolean die aangeeft of de rotatiematrix (van de huidig ingestelde 
	// camera) moet worden getransformeerd (default) of niet (false).
	// Optionele parameter 'metDiepteMatrix' is een boolean die aangeeft of de dieptematrix (van de huidig ingestelde 
	// camera) moet worden getransformeerd (default) of niet (false).
	//
	private tekenMetTransLijst(tekenActie: VLib.TekenActieFunctieType, transLijst: VLib.TransLijstType, modelviewMatrix?: number[], metRotatieMatrix?: boolean, metDiepteMatrix?: boolean): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramWrap;

		modelviewMatrix = modelviewMatrix ? modelviewMatrix : this.privates.modelviewMatrix;
		metRotatieMatrix = (metRotatieMatrix !== false);
		let metBelichtingDiepteMatrix: boolean = (metDiepteMatrix !== false && this.privates.belichting.diepteMatrix != null);
		let metLampDiepteMatrix: boolean = (metDiepteMatrix !== false && this.privates.lampen != null && this.privates.lampen.length > 0);

		if (transLijst.cacheTekenCyclus !== this.privates.tekenCyclus) {
			//
			// De gecachete gegevens van de transformatielijst zijn verouderd.
			//
			for (let j: number = 0; j < transLijst.aantal; j++) {
				//
				// Bepaal de modelview-, rotatie- en dieptematrices voor elke transformatie in de transformatielijst en 
				// cache deze opdat ze deze ronde eventueel nogmaals bij een tekenactie gebruikt kunnen worden:
				//
				mat4.multiply(modelviewMatrix, transLijst.transMats[j],
					transLijst.cachedModelviewMats[j]);
				if (metRotatieMatrix) {
					mat3.multiply(this.privates.rotatieMatrix, transLijst.rotMats[j],
						transLijst.cachedRotatieMats[j]);
				}
				if (metBelichtingDiepteMatrix) {
					mat4.multiply(this.privates.belichting.diepteMatrix, transLijst.transMats[j], transLijst.cachedDiepteMats[j]);
				}
				if (metLampDiepteMatrix) {
					for (let i: number = 0; i < this.privates.lampen.length; i++) {
						let lamp: Lamp = this.privates.lampen[i];
						if (lamp.isAan) {
							mat4.multiply(lamp.diepteMatrix, transLijst.transMats[j], transLijst.cachedLampenDiepteMats[j][i]);
						}
					}
				}
			}
			transLijst.cacheTekenCyclus = this.privates.tekenCyclus;
		}

		for (let j: number = 0; j < transLijst.aantal; j++) {
			//
			// Pas de gecachete matrices toe en voer de tekenactie uit:
			//
			gl.uniformMatrix4fv(shaderProgramWrap.modelviewMatrixUniform, false, transLijst.cachedModelviewMats[j]);
			if (metRotatieMatrix) {
				gl.uniformMatrix3fv(shaderProgramWrap.rotatieMatrixUniform, false, transLijst.cachedRotatieMats[j]);
			}
			if (metBelichtingDiepteMatrix) {
				gl.uniformMatrix4fv(shaderProgramWrap.diepteMatrixUniform, false, transLijst.cachedDiepteMats[j]);
			}
			if (metLampDiepteMatrix) {
				for (let i: number = 0; i < this.privates.lampen.length; i++) {
					let lamp: Lamp = this.privates.lampen[i];
					if (lamp.isAan) {
						let lampUniforms: VLib.LampUniformsType = shaderProgramWrap.lampenUniforms[i];
						gl.uniformMatrix4fv(lampUniforms.diepteMatrix, false, transLijst.cachedLampenDiepteMats[j][i]);
					}
				}
			}

			if (transLijst.spiegelMats[j]) {
				gl.frontFace(gl.CW);
				tekenActie(j);
				gl.frontFace(gl.CCW);
			}
			else {
				tekenActie(j);
			}
		}

		//
		// Herstel de modelview-, rotatie- en eventueel dieptematrix:
		//
		gl.uniformMatrix4fv(shaderProgramWrap.modelviewMatrixUniform, false, modelviewMatrix);
		if (metRotatieMatrix) {
			gl.uniformMatrix3fv(shaderProgramWrap.rotatieMatrixUniform, false, this.privates.rotatieMatrix);
		}
		if (metBelichtingDiepteMatrix) {
			gl.uniformMatrix4fv(shaderProgramWrap.diepteMatrixUniform, false, this.privates.belichting.diepteMatrix);
		}
		if (metLampDiepteMatrix) {
			for (let i: number = 0; i < this.privates.lampen.length; i++) {
				let lamp: Lamp = this.privates.lampen[i];
				if (lamp.isAan) {
					let lampUniforms: VLib.LampUniformsType = shaderProgramWrap.lampenUniforms[i];
					gl.uniformMatrix4fv(lampUniforms.diepteMatrix, false, lamp.diepteMatrix);
				}
			}
		}
	};

	//
	// Stelt de camera (het oogpunt) voor WebGL in.
	//
	private stelCameraIn(): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramWrap;
		gl.uniformMatrix4fv(shaderProgramWrap.projectieMatrixUniform, false, this.privates.projectieMatrix);
		gl.uniformMatrix4fv(shaderProgramWrap.modelviewMatrixUniform, false, this.privates.modelviewMatrix);
		gl.uniformMatrix4fv(shaderProgramWrap.modelviewMatrixFSUniform, false, this.privates.modelviewMatrix);
		gl.uniformMatrix3fv(shaderProgramWrap.rotatieMatrixUniform, false, this.privates.rotatieMatrix);
		gl.uniformMatrix3fv(shaderProgramWrap.rotatieMatrixFSUniform, false, this.privates.rotatieMatrix);
	};

	//
	// Stelt de lichtbron voor WebGL in.
	//
	private stelLichtbronIn(): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramWrap;
		let belichting: Belichting = this.privates.belichting;
		let lampen: Lamp[] = this.privates.lampen;

		//
		// Stel de belichting in:
		//
		if (belichting.isVuil) {
			gl.uniform4fv(shaderProgramWrap.lichtvalUniform, belichting.lichtvalF);
			gl.uniform1f(shaderProgramWrap.omgevingLichtNiveauUniform, belichting.omgevingLichtNiveau);
			gl.uniform1f(shaderProgramWrap.diffuusLichtNiveauUniform, belichting.diffuusLichtNiveau);
			gl.uniform1f(shaderProgramWrap.schitteringLichtNiveauUniform, belichting.schitteringLichtNiveau);
			gl.uniform1i(shaderProgramWrap.toonSlagschaduwUniform, belichting.toonSlagschaduw ? VLib.Lib._webGLTrue : VLib.Lib._webGLFalse);
			gl.uniform1f(shaderProgramWrap.slagschaduwVerzadigingUniform, belichting.slagschaduwVerzadiging);

			belichting.isVuil = false;
			if (belichting.isShadowMapVuil) {
				this.wisShadowMapVanBelichting(belichting);
				belichting.isShadowMapVuil = false;
			}
		}

		if (belichting.toonSlagschaduw) {
			let shadowMapTxtrWrap: VLib.ShadowMapTextureWrapType = belichting.shadowMapTextureWrap;
			if (belichting.toonSlagschaduwVerfijnd) {
				//
				// Maak een frustum shadow map, indien nodig; deze is herbruikbaar zolang het 
				// oogpunt niet verandert:
				//
				if (shadowMapTxtrWrap == null ||
					shadowMapTxtrWrap.zoomSchaal != this.privates._zoomSchaal ||
					shadowMapTxtrWrap.richting != this.privates._richting ||
					shadowMapTxtrWrap.helling != this.privates._helling ||
					shadowMapTxtrWrap.rolhoek != this.privates._rolhoek ||
					shadowMapTxtrWrap.verschuivingRechts != this.privates._verschuivingRechts ||
					shadowMapTxtrWrap.verschuivingOmhoog != this.privates._verschuivingOmhoog ||
					shadowMapTxtrWrap.cameraType != this.privates.cameraType ||
					shadowMapTxtrWrap.orthografischeProjectie != this.privates.orthografischeProjectie ||
					shadowMapTxtrWrap.positie == null ||
					shadowMapTxtrWrap.positie[0] != this.privates._positie[0] ||
					shadowMapTxtrWrap.positie[1] != this.privates._positie[1] ||
					shadowMapTxtrWrap.positie[2] != this.privates._positie[2]) {

					this.maakBelichtingFrustumShadowMap();

					shadowMapTxtrWrap = belichting.shadowMapTextureWrap
					shadowMapTxtrWrap.zoomSchaal = this.privates._zoomSchaal;
					shadowMapTxtrWrap.richting = this.privates._richting;
					shadowMapTxtrWrap.helling = this.privates._helling;
					shadowMapTxtrWrap.rolhoek = this.privates._rolhoek;
					shadowMapTxtrWrap.verschuivingRechts = this.privates._verschuivingRechts;
					shadowMapTxtrWrap.verschuivingOmhoog = this.privates._verschuivingOmhoog;
					shadowMapTxtrWrap.cameraType = this.privates.cameraType;
					shadowMapTxtrWrap.orthografischeProjectie = this.privates.orthografischeProjectie;
					shadowMapTxtrWrap.positie = this.privates.positie;
				}
			}
			else {
				if (belichting.isCameraLicht) {
					//
					// Maak een shadow map, indien nodig; deze is herbruikbaar zolang de kijkrichting niet verandert:
					//
					if (shadowMapTxtrWrap == null ||
						shadowMapTxtrWrap.richting != this.privates._richting ||
						shadowMapTxtrWrap.helling != this.privates._helling ||
						shadowMapTxtrWrap.rolhoek != this.privates._rolhoek) {
						this.maakBelichtingShadowMap();

						shadowMapTxtrWrap = belichting.shadowMapTextureWrap
						shadowMapTxtrWrap.richting = this.privates._richting;
						shadowMapTxtrWrap.helling = this.privates._helling;
						shadowMapTxtrWrap.rolhoek = this.privates._rolhoek;
					}
				}
				else {
					//
					// Maak een shadow map, indien nodig; deze is herbruikbaar bij alle kijkrichtingen:
					//
					if (shadowMapTxtrWrap == null) {
						this.maakBelichtingShadowMap();
					}
				}
			}
		}

		//
		// Stel de kunstlichtbronnen in:
		//
		if (lampen != null) {
			let isMinimaalEenLampAan: boolean = false;
			for (let i: number = 0; i < lampen.length; i++) {
				let lamp: Lamp = lampen[i];
				if (lamp.isAan) {
					isMinimaalEenLampAan = true;

					if (lamp.isVuil) {
						let lampUniforms: VLib.LampUniformsType = shaderProgramWrap.lampenUniforms[i];
						let positie: number[] = lamp.positie;
						positie.push(1.0);
						gl.uniform4fv(lampUniforms.positie, positie);
						gl.uniform3fv(lampUniforms.richting, lamp.richting);
						gl.uniform1f(lampUniforms.cosBuitenHoek, lamp.cosBuitenHoek);
						gl.uniform1f(lampUniforms.cosBinnenHoek, lamp.cosBinnenHoek);
						gl.uniform3fv(lampUniforms.kleur, lamp.kleurF);
						gl.uniform1f(lampUniforms.diffuusLichtNiveau, lamp.diffuusLichtNiveau);
						gl.uniform1f(lampUniforms.schitteringLichtNiveau, lamp.schitteringLichtNiveau);

						lamp.isVuil = false;
						if (lamp.isShadowMapVuil) {
							this.wisShadowMapVanLamp(lamp);
							lamp.isShadowMapVuil = false;
						}
					}

					if (lamp.shadowMapTexture == null) {
						this.maakLampShadowMap(lamp, i);
					}
				}
				else {
					if (lamp.isVuil) {
						this.doofLampen(i);
						//
						// NB: Een gedoofde lamp blijft voorlopig als 'vuil' gemarkeerd.
						//
					}
				}
			}

			if (isMinimaalEenLampAan) {
				let attAfstandInModelview: number = this.privates.lichtAttenuatieAfstand;
				if (this.privates.cameraType === true) // firmamentcamera
				{
					let modelDims: VLib.ModelDimensiesType = this.getModelDimensies();
					attAfstandInModelview = attAfstandInModelview / modelDims.stelselStraal;
				}
				gl.uniform1f(shaderProgramWrap.lichtAttenuatieAfstandUniform, attAfstandInModelview);
			}
			else {
				//
				// Stel alle lampen buiten gebruik (aangegeven met een lichtattenuatieafstand waarde kleiner dan nul):
				//
				gl.uniform1f(shaderProgramWrap.lichtAttenuatieAfstandUniform, -1.0);
			}
		}
	};

	//
	// Stelt de projectiematrix voor WebGL in.
	// Parameter 'projectieMatrix' is een mat4. 
	//
	private stelProjectieMatrixIn(projectieMatrix: number[], projectieClippingFrustum: VLib.ProjectieClippingFrustumType): void {
		if (this.privates.cameraType === true) // firmamentcamera
		{
			this.stelProjectieMatrixIn_FirmamentCamera(projectieMatrix,
				this.privates.glWrap.gl.drawingBufferWidth / this.privates.glWrap.gl.drawingBufferHeight,
				this.privates._zoomSchaal, this.privates._verschuivingRechts, this.privates._verschuivingOmhoog,
				this.privates.orthografischeProjectie);
			projectieClippingFrustum.Frustum = undefined;
		}
		else // terreincamera
		{
			this.stelProjectieMatrixIn_TerreinCamera(projectieMatrix, projectieClippingFrustum, this.privates._zoomSchaal);
		}
	};

	//
	// Stelt de projectiematrix voor WebGL in voor een firmamentcamera.
	// Parameter 'projectieMatrix' is een mat4.
	// Parameters 'aspectRatio', 'zoomSchaal', 'verschuivingRechts' en 'verschuivingOmhoog' zijn floats.
	// Parameter 'orthografischeProjectie' is een boolean.
	//
	private stelProjectieMatrixIn_FirmamentCamera(projectieMatrix: number[], aspectRatio: number, zoomSchaal: number, verschuivingRechts: number, verschuivingOmhoog: number, orthografischeProjectie: boolean): void {
		//
		// Het vastleggen van de frustum wordt gebruikt om het ingezoomde gebied af te bakenen. 
		// Er wordt een schalingsfactor en een translatie toegepast:
		//
		let geschaald: number = this.privates.constBereik / zoomSchaal;
		let left: number = -geschaald;
		let right: number = geschaald;
		let bottom: number = -geschaald;
		let top: number = geschaald;
		if (aspectRatio < 1.0) // width < height
		{
			bottom = bottom / aspectRatio;
			top = top / aspectRatio;
		}
		else // width >= height
		{
			left = left * aspectRatio;
			right = right * aspectRatio;
		}
		let getransleerdRechts: number = - this.privates.constBereik * verschuivingRechts;
		let getransleerdOmhoog: number = - this.privates.constBereik * verschuivingOmhoog;
		left = left + getransleerdRechts;
		right = right + getransleerdRechts;
		bottom = bottom + getransleerdOmhoog;
		top = top + getransleerdOmhoog;

		let near: number = this.privates.constAfstand - 1.0;
		let far: number = this.privates.constAfstand + 1.0;

		if (orthografischeProjectie) {
			mat4.ortho(left, right, bottom, top, near, far, projectieMatrix);
		}
		else {
			mat4.frustum(left, right, bottom, top, near, far, projectieMatrix);
		}
		//console.log("projectieMatrix=" + projectieMatrix); //@@@QF
	};

	//
	// Stelt de projectiematrix voor WebGL in voor een terreincamera.
	// Parameter 'projectieMatrix' is een mat4.
	// Parameter 'zoomSchaal' is een float.
	//
	private stelProjectieMatrixIn_TerreinCamera(projectieMatrix: number[], projectieClippingFrustum: VLib.ProjectieClippingFrustumType, zoomSchaal: number): void {
		let aspectRatio: number = this.privates.glWrap.gl.drawingBufferWidth / this.privates.glWrap.gl.drawingBufferHeight;

		let factor: number = 1.0 + Math.log(zoomSchaal) / Math.LN10;
		let fovy: number = 50.0 / factor;

		let modelDims: VLib.ModelDimensiesType = this.getModelDimensies();
		let centrum: number[] = modelDims.centrum;
		let positie: number[] = this.privates.positie;
		let naarCentrum: number[] = [centrum[0] - positie[0], centrum[1] - positie[1], centrum[2] - positie[2]];
		let kijkrichting: number[] = this.kijkrichting;
		let afstandTotCentrumLangsKijkrichting: number = kijkrichting[0] * naarCentrum[0] +
			kijkrichting[1] * naarCentrum[1] + kijkrichting[2] * naarCentrum[2];

		let near: number = Math.max(afstandTotCentrumLangsKijkrichting - modelDims.modelStraal, 100.0);
		let far: number = Math.max(afstandTotCentrumLangsKijkrichting + modelDims.modelStraal, 1000.0);
		mat4.perspective(fovy, aspectRatio, near, far, projectieMatrix);

		//
		// Bewaar de kenmerken van de clipping-frustum:
		// NB: Omdat er hier bij frustum culling alleen naar de bounding boxen van fragmenten gekeken wordt waar 
		// eventuele transformaties al in verwerkt zijn, is de clipping-frustum eenvoudig te herleiden aan de hand van de 
		// invoergrootheden van de projectie- en modelviewmatrices.
		//
		let conusHoek: number = Math.atan(Math.sqrt(aspectRatio * aspectRatio + 1) * Math.tan(0.5 * fovy * VLib.Lib._vanDegNaarRad));
		projectieClippingFrustum.Frustum = {
			fovy: fovy, aspectRatio: aspectRatio, near: near, far: far,
			apex: positie, as: kijkrichting, rolhoek: this.privates._rolhoek,
			conusHoek: conusHoek * VLib.Lib._vanRadNaarDeg,
			sinConusHoek: Math.sin(conusHoek), cosConusHoek: Math.cos(conusHoek)
		};
	};

	//
	// Stelt de modelviewmatrix (de positie van het oogpunt) voor WebGL in.
	// Parameter 'modelviewMatrix' is een mat4.
	// Parameter 'rotatieMatrix' is een mat3. 
	//
	private stelModelviewMatrixIn(modelviewMatrix: number[], rotatieMatrix: number[]): void {
		if (this.privates.cameraType === true) // firmamentcamera
		{
			this.stelModelviewMatrixIn_FirmamentCamera(modelviewMatrix, rotatieMatrix,
				this.privates._richting, this.privates._helling, this.privates._rolhoek);
		}
		else // terreincamera
		{
			this.stelModelviewMatrixIn_TerreinCamera(modelviewMatrix, rotatieMatrix,
				this.privates._richting, this.privates._helling, this.privates._rolhoek, this.privates._positie);
		}
	};

	//
	// Stelt de modelviewmatrix (de positie van het oogpunt) voor WebGL in voor een firmamentcamera.
	// Parameter 'modelviewMatrix' is een mat4.
	// Parameter 'rotatieMatrix' is een mat3.
	// Parameters 'richting', 'helling' en 'rolhoek' zijn floats.
	//
	private stelModelviewMatrixIn_FirmamentCamera(modelviewMatrix: number[], rotatieMatrix: number[], richting: number, helling: number, rolhoek: number): void {
		mat4.identity(modelviewMatrix);
		//
		// Roteer een kwartslag om de verticale as:
		//   Y _ _ X      X'
		//    |            |
		//    |       =>   |_ _
		//    Z           Y'    Z'
		//
		mat4.rotateY(modelviewMatrix, 0.5 * Math.PI);
		//
		// Roteer een kwartslag om de as naar voren:
		//   X'                 X"
		//    |                |
		//    |_ _    =>    _ _|
		//   Y'    Z'     Y"    Z"
		//
		mat4.rotateX(modelviewMatrix, -0.5 * Math.PI);
		//
		// De assen zijn nu zo georiënteerd zodat in model-coordinaten kan worden getekend, 
		// met de model X-as naar achteren, de model Y-as naar links en de model Z-as naar boven.
		//
		// Transleer van het scherm weg (langs de negatieve X-as):
		//
		mat4.translate(modelviewMatrix, [this.privates.constAfstand, 0, 0]);
		//
		// Roteer om de as naar voren (de X-as):
		//
		mat4.rotateX(modelviewMatrix, -rolhoek * VLib.Lib._vanDegNaarRad);
		//
		// Roteer om de as naar rechts (de Y-as):
		//
		mat4.rotateY(modelviewMatrix, -helling * VLib.Lib._vanDegNaarRad);
		//
		// Roteer om de verticale as (de Z-as):
		//
		mat4.rotateZ(modelviewMatrix, -richting * VLib.Lib._vanDegNaarRad);

		//
		// Bepaal de rotatiematrix, de benodigde transformatie van de vlaknormalen (en eventuele andere richtingvectoren). 
		// Deze matrix bevat de rotaties van de modelview, maar niet de schaling (en de translaties vervallen). 
		// Eenheidsvectoren blijven dus eenheidsvectoren na transformatie met deze matrix:
		//
		mat4.toMat3(modelviewMatrix, rotatieMatrix);
		//console.log("modelviewMatrix  prescale=" + modelviewMatrix + "|rotatieMatrix=" + rotatieMatrix); //@@@QF
		//return; //@@@QF // Dit gaat echt niet werken. Schaalfactor van ca. 10000 zou je missen. Je eindigt "met je neus op de oorsprong".
		//
		// Verander de grootte:
		//
		let modelDims: VLib.ModelDimensiesType = this.getModelDimensies();
			if (modelDims == null) {
			//console.log("modelviewMatrix=" + modelviewMatrix + "|rotatieMatrix=" + rotatieMatrix + "|modelDims=null"); //@@@QF
			return;
		}
		let schaal: number = 1.0 / modelDims.modelStraal;
		mat4.scale(modelviewMatrix, [schaal, schaal, schaal]);
		//
		// Transleer naar het centrum van het model:
		//
		let centrum: number[] = modelDims.centrum;
		mat4.translate(modelviewMatrix, [-centrum[0], -centrum[1], -centrum[2]]);
		//console.log("modelviewMatrix postscale=" + modelviewMatrix + "|rotatieMatrix=" + rotatieMatrix + "|modelDims<>null|schaal=" + schaal + "|centrum=" + centrum); //@@@QF
	};

	//
	// Stelt de modelviewmatrix (de positie van het oogpunt) voor WebGL in voor een terreincamera.
	// Parameter 'modelviewMatrix' is een mat4.
	// Parameter 'rotatieMatrix' is een mat3.
	// Parameters 'richting', 'helling' en 'rolhoek' zijn floats.
	// Parameter 'positie' is een array van floats met lengte 3 (x-, y- en z-componenten).
	//
	private stelModelviewMatrixIn_TerreinCamera(modelviewMatrix: number[], rotatieMatrix: number[], richting: number, helling: number, rolhoek: number, positie: number[]): void {
		mat4.identity(modelviewMatrix);
		//
		// Roteer een kwartslag om de verticale as:
		//   Y _ _ X      X'
		//    |            |
		//    |       =>   |_ _
		//    Z           Y'    Z'
		//
		mat4.rotateY(modelviewMatrix, 0.5 * Math.PI);
		//
		// Roteer een kwartslag om de as naar voren:
		//   X'                 X"
		//    |                |
		//    |_ _    =>    _ _|
		//   Y'    Z'     Y"    Z"
		//
		mat4.rotateX(modelviewMatrix, -0.5 * Math.PI);
		//
		// De assen zijn nu zo georiënteerd zodat in model-coordinaten kan worden getekend, 
		// met de model X-as naar achteren, de model Y-as naar links en de model Z-as naar boven.
		//
		// Roteer om de as naar voren (de X-as):
		//
		mat4.rotateX(modelviewMatrix, -rolhoek * VLib.Lib._vanDegNaarRad);
		//
		// Roteer om de as naar rechts (de Y-as):
		//
		mat4.rotateY(modelviewMatrix, -helling * VLib.Lib._vanDegNaarRad);
		//
		// Roteer om de verticale as (de Z-as):
		//
		mat4.rotateZ(modelviewMatrix, -richting * VLib.Lib._vanDegNaarRad);

		//
		// Bepaal de rotatiematrix, de benodigde transformatie van de vlaknormalen (en eventuele andere richtingvectoren). 
		// Deze matrix bevat de rotaties van de modelview, maar niet de schaling (en de translaties vervallen). 
		// Eenheidsvectoren blijven dus eenheidsvectoren na transformatie met deze matrix:
		//
		mat4.toMat3(modelviewMatrix, rotatieMatrix);

		//
		// Transleer naar de positie van de camera:
		//
		mat4.translate(modelviewMatrix, [-positie[0], -positie[1], -positie[2]]);
	};

	//
	// Bepaalt of een fragment getekend moet worden.
	// Parameter 'fragment' is een fragment.
	// Parameter 'frustum' is een object met de kenmerken van de clipping-frustum.
	// Return-waarde is een boolean die true is wanneer het fragment getekend moet worden; false wanneer dat niet het 
	// geval is.
	//
	private bepaalMoetGetekend(fragment: VLib.FragmentType, frustum: VLib.FrustumType): boolean {
		let moetGetekend: boolean = fragment.isZichtbaar;
		moetGetekend = moetGetekend && this.inClippingFrustum(fragment, frustum);
		return moetGetekend;
	};

	//
	// Bepaalt of een fragment binnen de clipping-frustum ligt.
	// Parameter 'fragment' is een fragment.
	// Parameter 'frustum' is een object met de kenmerken van de clipping-frustum.
	// Return-waarde is een boolean die true is wanneer het fragment geheel of gedeeltelijk in de frustum ligt; false 
	// wanneer dat niet het geval is.
	//
	private inClippingFrustum(fragment: VLib.FragmentType, frustum: VLib.FrustumType): boolean {
		//
		// Schifting 1: Ligt de frustum-apex binnen de bounding box van het fragment?
		//
		let apex: number[] = frustum.apex;
		let bb: number[] = fragment.bbTrans; // fragment bounding box = [ xMin, xMax, yMin, yMax, zMin, zMax ]
		if (apex[0] > bb[0] && apex[0] < bb[1] && apex[1] > bb[2] && apex[1] < bb[3] &&
			apex[2] > bb[4] && apex[2] < bb[5]) {
			//
			// De camera ligt binnen het fragment:
			//
			return true;
		}
		//
		// Schifting 2: Ligt de omgeschreven bol van de bounding box van het fragment 'achter' de frustum?
		//
		let as: number[] = frustum.as;
		let ctr: number[] = fragment.centrum;
		let projOpAs: number = as[0] * (ctr[0] - apex[0]) + as[1] * (ctr[1] - apex[1]) +
			as[2] * (ctr[2] - apex[2]);
		if (projOpAs <= -fragment.bbRadius + frustum.near) {
			//
			// Het fragment ligt achter de camera:
			//
			return false;
		}
		//
		// Schifting 3: Ligt de omgeschreven bol van de bounding box van het fragment geheel of gedeeltelijk in de 
		// omgeschreven kegel van de frustum?
		//
		// Construeer een kegel met een apex 'achter' de frustum-apex, zodanig dat de afstand tussen de oppervlakken van 
		// deze ruime kegel en de frustumkegel gelijk is aan de straal van de bol:
		//
		let apexShift: number = fragment.bbRadius / frustum.sinConusHoek;
		let naarCtr: number[] = [ctr[0] - (apex[0] - as[0] * apexShift),
		ctr[1] - (apex[1] - as[1] * apexShift), ctr[2] - (apex[2] - as[2] * apexShift)];
		let lengte2: number = naarCtr[0] * naarCtr[0] + naarCtr[1] * naarCtr[1] + naarCtr[2] * naarCtr[2];
		projOpAs = as[0] * naarCtr[0] + as[1] * naarCtr[1] + as[2] * naarCtr[2];
		if (projOpAs * projOpAs <= lengte2 * frustum.cosConusHoek * frustum.cosConusHoek) {
			//
			// Het fragment ligt (ruim) buiten de frustum:
			//
			return false;
		}
		//
		// Schifting 4: Ligt de bounding box van het fragment geheel naast de omgeschreven kegel van de frustum?
		//
		// Bepaal de hoek van de frustum-as met de betreffende zijde van de bounding box.
		// Voor het YZ-vlak is dit: acos(sqrt( sqr(asY) + sqr(asZ) )) * sign(asY)
		//    ofwel: asin(cos(richting) * cos(inclinatie))
		// Voor het XZ-vlak is dit: acos(sqrt( sqr(asX) + sqr(asZ) )) * sign(asY)
		//    ofwel: asin(sin(richting) * cos(inclinatie))
		// Voor het XY-vlak is dit: acos(sqrt( sqr(asX) + sqr(asY) )) * sign(asZ)
		//    ofwel: inclinatie
		//
		// Langs het YZ-vlak:
		//
		let hoek: number = Math.acos(Math.hypot(as[1], as[2])) * (as[0] < 0 ? -1 : 1) * VLib.Lib._vanRadNaarDeg;
		if (apex[0] >= bb[1]) // apexX >= bbXMax
		{
			if (hoek >= frustum.conusHoek) {
				return false;
			}
		}
		else if (apex[0] <= bb[0]) // apexX <= bbXMin
		{
			if (hoek <= -frustum.conusHoek) {
				return false;
			}
		}
		//
		// Langs het XZ-vlak:
		//
		hoek = Math.acos(Math.hypot(as[0], as[2])) * (as[1] < 0 ? -1 : 1) * VLib.Lib._vanRadNaarDeg;
		if (apex[1] >= bb[3]) // apexY >= bbYMax
		{
			if (hoek >= frustum.conusHoek) {
				return false;
			}
		}
		else if (apex[1] <= bb[2]) // apexY <= bbYMin
		{
			if (hoek <= -frustum.conusHoek) {
				return false;
			}
		}
		//
		// Langs het XY-vlak:
		//
		hoek = Math.acos(Math.hypot(as[0], as[1])) * (as[2] < 0 ? -1 : 1) * VLib.Lib._vanRadNaarDeg;
		if (apex[2] >= bb[5]) // apexZ >= bbZMax
		{
			if (hoek >= frustum.conusHoek) {
				return false;
			}
		}
		else if (apex[2] <= bb[4]) // apexZ <= bbYMax
		{
			if (hoek <= -frustum.conusHoek) {
				return false;
			}
		}
		//
		// Meer schifting is mogelijk na de bepaling van de clipping-vlakken van de frustum.
		//
		return true;
	};

	//
	// Converteert een punt van model-coordinaten naar viewport schermcoordinaten.
	// Parameter 'ruimtePunt' is een array van floats met lengte 3 (x-, y- en z-componenten).
	// Optionele parameter 'zoomSchaal' is de eventueel afwijkende zoomschaal.
	// Return-waarde is een array van floats met lengte 2 (x-, y-componenten).
	//
	private converteerNaarSchermCoordinaten(ruimtePunt: number[], zoomSchaal?: number): number[] {
		let viewport: number[] = [0, 0, this.privates.glWrap.gl.drawingBufferWidth, this.privates.glWrap.gl.drawingBufferHeight];
		let aspectRatio: number = this.privates.glWrap.gl.drawingBufferWidth / this.privates.glWrap.gl.drawingBufferHeight;

		let modelviewMatrix: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		let rotatieMatrix: number[] = <number[]>mat3.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		this.stelModelviewMatrixIn_FirmamentCamera(modelviewMatrix, rotatieMatrix,
			this.privates._richting, this.privates._helling, this.privates._rolhoek);
		let projectieMatrix: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		if (zoomSchaal == null) {
			zoomSchaal = 1.0;
		}
		this.stelProjectieMatrixIn_FirmamentCamera(projectieMatrix,
			aspectRatio, zoomSchaal, 0.0, 0.0, this.privates.orthografischeProjectie);
		//
		// Converteer model-coordinaten naar viewport schermcoordinaten:
		//
		let schermPunt: number[] = [];
		VLib.Lib.vec3_project(ruimtePunt, modelviewMatrix, projectieMatrix, viewport, schermPunt);

		return schermPunt.slice(0, 2);
	};

	//
	// Converteert een punt van viewport schermcoordinaten naar model-coordinaten.
	// Parameter 'schermPunt' is een array van floats met lengte 2 (x-, y-componenten).
	// Parameter 'metVerschuiving': use the current verschuivingRechts and verschuivingOmhoog, or use 0 and 0.
	// Parameter 'metZoomSchaal': use the current zoomSchaal, or use 1.
	// Return-waarde is een array van floats met lengte 3 (x-, y- en z-componenten).
	//
	//private converteerVanSchermCoordinaten(schermPunt: number[], zoomSchaal?: number): number[] { //@@@Q gewijzigd naar:
	private converteerVanSchermCoordinaten(schermPunt: number[], metVerschuiving: boolean, metZoomSchaal: boolean): number[] {
		let viewport: number[] = [0, 0, this.privates.glWrap.gl.drawingBufferWidth, this.privates.glWrap.gl.drawingBufferHeight];
		let aspectRatio: number = this.privates.glWrap.gl.drawingBufferWidth / this.privates.glWrap.gl.drawingBufferHeight;

		let modelviewMatrix: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		let rotatieMatrix: number[] = <number[]>mat3.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		this.stelModelviewMatrixIn_FirmamentCamera(modelviewMatrix, rotatieMatrix,
			this.privates._richting, this.privates._helling, this.privates._rolhoek);
		let projectieMatrix: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		let verschuivingRechts: number;
		let verschuivingOmhoog: number;
		let zoomSchaal: number;
		if (metVerschuiving) {
			verschuivingRechts = this.privates._verschuivingRechts;
			verschuivingOmhoog = this.privates._verschuivingOmhoog;
		}
		else {
			verschuivingRechts = 0.0;
			verschuivingOmhoog = 0.0;
		}
		if (metZoomSchaal) {
			zoomSchaal = this.privates._zoomSchaal;
		}
		else {
			zoomSchaal = 1.0;
		}
		this.stelProjectieMatrixIn_FirmamentCamera(projectieMatrix,
			aspectRatio, zoomSchaal, verschuivingRechts, verschuivingOmhoog, this.privates.orthografischeProjectie);
		//
		// NB: De Z-waarde is niet eenduidig bij een set schermcoordinaten.
		// Voor orthografische projectie is dit niet van belang (alle Z-waarden worden in hetzelfde punt op het scherm 
		// geprojecteerd), maar voor perspectief moet een redelijke keuze gemaakt worden.
		//
		schermPunt = schermPunt.slice(0, 2);
		schermPunt[2] = 0.5;
		//
		// Converteer viewport schermcoordinaten naar model-coordinaten:
		//
		let ruimtePunt: number[] = [];
		vec3.unproject(schermPunt, modelviewMatrix, projectieMatrix, viewport, ruimtePunt);

		return ruimtePunt;
	};

	public TEMPconverteerNaarSchermCoordinaten(ruimtePunt: number[], zoomSchaal?: number): number[] { //@@@QC PATCH
		let viewport: number[] = [0, 0, this.privates.glWrap.gl.drawingBufferWidth, this.privates.glWrap.gl.drawingBufferHeight];
		let aspectRatio: number = this.privates.glWrap.gl.drawingBufferWidth / this.privates.glWrap.gl.drawingBufferHeight;

		let modelviewMatrix: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		let rotatieMatrix: number[] = <number[]>mat3.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		this.stelModelviewMatrixIn_FirmamentCamera(modelviewMatrix, rotatieMatrix,
			this.privates._richting, this.privates._helling, this.privates._rolhoek);
		let projectieMatrix: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		if (zoomSchaal == null) {
			zoomSchaal = 1.0;
		}
		this.stelProjectieMatrixIn_FirmamentCamera(projectieMatrix,
			aspectRatio, zoomSchaal, 0.0, 0.0, this.privates.orthografischeProjectie);
		//
		// Converteer model-coordinaten naar viewport schermcoordinaten:
		//
		let schermPunt: number[] = [];
		VLib.Lib.vec3_project(ruimtePunt, modelviewMatrix, projectieMatrix, viewport, schermPunt);

		//return schermPunt.slice(0, 2);
		return schermPunt;
	};

	public TEMPconverteerVanSchermCoordinaten(schermPunt: number[], zoomSchaal?: number): number[] { //@@@QC PATCH
		let viewport: number[] = [0, 0, this.privates.glWrap.gl.drawingBufferWidth, this.privates.glWrap.gl.drawingBufferHeight];
		let aspectRatio: number = this.privates.glWrap.gl.drawingBufferWidth / this.privates.glWrap.gl.drawingBufferHeight;

		let modelviewMatrix: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		let rotatieMatrix: number[] = <number[]>mat3.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		this.stelModelviewMatrixIn_FirmamentCamera(modelviewMatrix, rotatieMatrix,
			this.privates._richting, this.privates._helling, this.privates._rolhoek);
		let projectieMatrix: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		if (zoomSchaal == null) {
			zoomSchaal = 1.0;
		}
		this.stelProjectieMatrixIn_FirmamentCamera(projectieMatrix,
			aspectRatio, zoomSchaal, 0.0, 0.0, this.privates.orthografischeProjectie);
		//
		// NB: De Z-waarde is niet eenduidig bij een set schermcoordinaten.
		// Voor orthografische projectie is dit niet van belang (alle Z-waarden worden in hetzelfde punt op het scherm 
		// geprojecteerd), maar voor perspectief moet een redelijke keuze gemaakt worden.
		//
		//schermPunt = schermPunt.slice(0, 2);
		//schermPunt[2] = 0.5;
		//
		// Converteer viewport schermcoordinaten naar model-coordinaten:
		//
		let ruimtePunt: number[] = [];
		vec3.unproject(schermPunt, modelviewMatrix, projectieMatrix, viewport, ruimtePunt);

		return ruimtePunt;
	};

	//
	// Verricht bijzondere handelingen na het verlopen van een wachttijd na de laatste tekenactiviteit.
	//
	private onIdleCallback: V3DSimpelCallbackType = (): void => {
		this.onIdle();
	}

	private onIdle(): void {
		if (this.privates.glWrap && this.privates.nabewerkingen) {
			//let belichting: Belichting = this.privates.belichting; //@@@Q Wordt hier niet gebruikt.
			let nabwrkngn: Nabewerkingen = this.privates.nabewerkingen;
			//@@@Q nabwrkngn.idleTimeoutHandle = null; // Vervangen door call:
			nabwrkngn.wisIdleTimeoutHandle();

			let isFrustumShadowMapBenut: boolean = nabwrkngn.toonSlagschaduwVerfijnd &&
				this.startBelichtingFrustumShadowMap();

			let fxaaToepassen: boolean = nabwrkngn.fxaa.toepassen;
			let ssaoToepassen: boolean = (nabwrkngn.ssao.toepassen && this.privates.cameraType === false);
			let bokehToepassen: boolean = (nabwrkngn.bokeh.toepassen && this.privates.cameraType === false);
			let vignetteToepassen: boolean = (nabwrkngn.vignette.toepassen && this.privates.cameraType === false);
			let geenNabewerking: boolean = ((fxaaToepassen || ssaoToepassen || bokehToepassen || vignetteToepassen) == false);

			if (isFrustumShadowMapBenut == false && geenNabewerking) {
				return;
			}

			let gl: WebGLRenderingContext = this.privates.glWrap.gl;
			let multipassKleurTexture: WebGLTexture = undefined;
			let multipassDiepteTexture: WebGLTexture = undefined;
			try {

				let nabewerkFBO: VLib.WebGLFramebufferWrapType = undefined;
				if (geenNabewerking == false) {
					nabewerkFBO = this.maakFBO(gl.drawingBufferWidth, gl.drawingBufferHeight, true, true, 'scene (pass)');
				}
				try {
					this.tekenScene();
				}
				catch (exc) {
					exc = 'Model kon niet getekend worden. Foutdetails: ' + exc;
					throw exc;
				}
				if (nabewerkFBO) {
					multipassKleurTexture = nabewerkFBO.kleurTexture;
					nabewerkFBO.kleurTexture = undefined;
					multipassDiepteTexture = nabewerkFBO.diepteTexture;
					nabewerkFBO.diepteTexture = undefined;
					this.wisFBO(nabewerkFBO);
				}

				if (isFrustumShadowMapBenut) {
					//
					// Activeer de functie die aangeroepen moet worden vóór de eerste tekenactiviteit:
					//
					this.privates.afterIdleActief = true;
				}

				if (geenNabewerking) {
					return;
				}

				if (fxaaToepassen) {
					let passResultaat: VLib.PassResultType = this.doePass(this.tekenPassFXAA, 'FXAA',
						[multipassKleurTexture], [true], true,
						null, null, false);
					multipassKleurTexture = passResultaat.kleurTexture;
				}

				if (ssaoToepassen) {
					let passResultaat: VLib.PassResultType = this.doePass(this.tekenPassSSAOBerekenen, 'SSAO (berekenen)',
						[multipassKleurTexture], [false], true,
						[multipassDiepteTexture], [false], false);
					let occlusieKleurTexture: WebGLTexture = passResultaat.kleurTexture;
					passResultaat = this.doePass(this.tekenPassSSAOAanbrengen, 'SSAO (aanbrengen)',
						[multipassKleurTexture, occlusieKleurTexture], [true, true], true,
						null, null, false);
					multipassKleurTexture = passResultaat.kleurTexture;
				}

				if (bokehToepassen) {
					let passResultaat: VLib.PassResultType = this.doePass(this.tekenPassBokeh, 'bokeh',
						[multipassKleurTexture], [true], true,
						[multipassDiepteTexture], [false], false);
					multipassKleurTexture = passResultaat.kleurTexture;
				}

				if (vignetteToepassen) {
					let passResultaat: VLib.PassResultType = this.doePass(this.tekenPassVignette, 'vignette',
						[multipassKleurTexture], [true], true,
						null, null, false);
					multipassKleurTexture = passResultaat.kleurTexture;
				}

				this.tekenMultipassFinale(multipassKleurTexture);
			}
			catch (exc) {
				if (isFrustumShadowMapBenut) {
					this.beeindigBelichtingFrustumShadowMap();
				}
				this.conditionalLog('Nabewerkingen konden niet uitgevoerd worden. Foutdetails: ' + exc);
				return;
			}
			finally {
				//
				// Ruim de textures op:
				//
				gl.deleteTexture(multipassKleurTexture);
				gl.deleteTexture(multipassDiepteTexture);
				this.bindDummyTextuur(gl.TEXTURE0); // TEXTURE0 wordt ook gebruikt in de standaard shader. Zorg dat er altijd een textuur aan gekoppeld is. //@@@QB Bugfix: tegen warnings: [...]RENDER WARNING: there is no texture bound to the unit x.
				//
				// Heractiveer het standaard shader program:
				//
				this.privates.shaderProgramWrap = this.privates.shaderProgramStdWrap;
				gl.useProgram(this.privates.shaderProgramWrap.shaderProgram);
			}
		}
	};

	//
	// Verricht handelingen vóór de eerste tekenactiviteit na het verrichten van de bijzondere handelingen.
	// De functie is initieel gedeactiveerd.
	//
	private afterIdle(): void {
		this.beeindigBelichtingFrustumShadowMap();
	}

	//
	// Voert een pass van een multi-pass nabewerking uit.
	// Parameter 'passTekenFunctie' is een functie die het tekenwerk van de pass uitvoert.
	// Parameter 'passNaam' is de naam van de pass.
	// Parameter 'kleurTexturesIn' is een array met de ingaande kleur-texture voor de pass. Array mag leeg of null zijn.
	// Parameter 'disposeKleurTexturesIn' is een array van booleans die aangeven of de ingaande kleur-textures na het 
	// tekenen opgeruimd moeten worden. Array-lengte moet gelijk zijn aan het aantal ingaande kleur-textures.
	// Parameter 'returnKleurTextureUit' is een boolean die aangeven of de getekende kleur-texture uitgaand moet zijn.
	// Parameter 'diepteTexturesIn' is een array met de ingaande diepte-texture voor de pass. Array mag leeg zijn.
	// Parameter 'disposeDiepteTexturesIn' is een array van booleans die aangeven of de ingaande diepte-textures na het 
	// tekenen opgeruimd moeten worden. Array-lengte moet gelijk zijn aan het aantal ingaande diepte-textures.
	// Parameter 'returnDiepteTextureUit' is een boolean die aangeven of de getekende diepte-texture uitgaand moet zijn.
	// Return-waarde is een object, met daaraan als properties toegevoegd de uitgaande kleur- en diepte-textures onder 
	// de respectievelijke namen kleurTexture en diepteTexture.
	//
	private doePass(passTekenFunctie: VLib.PassTekenFunctieType, passNaam: string, kleurTexturesIn: WebGLTexture[], disposeKleurTexturesIn: boolean[], returnKleurTextureUit: boolean, diepteTexturesIn: WebGLTexture[], disposeDiepteTexturesIn: boolean[], returnDiepteTextureUit: boolean): VLib.PassResultType {
		let passFBO: VLib.WebGLFramebufferWrapType = undefined;
		try {
			let gl: WebGLRenderingContext = this.privates.glWrap.gl;
			passFBO = this.maakFBO(gl.drawingBufferWidth, gl.drawingBufferHeight, true, true, passNaam);

			passTekenFunctie(passFBO.FBO, kleurTexturesIn, diepteTexturesIn);

			let uit: VLib.PassResultType = { kleurTexture: undefined, diepteTexture: undefined };
			if (kleurTexturesIn) {
				for (let i: number = 0; i < kleurTexturesIn.length; i++) {
					if (disposeKleurTexturesIn[i] === true) {
						gl.deleteTexture(kleurTexturesIn[i]);
						kleurTexturesIn[i] = null;
					}
				}
			}
			if (returnKleurTextureUit === true) {
				uit.kleurTexture = passFBO.kleurTexture;
				passFBO.kleurTexture = undefined;
			}
			if (diepteTexturesIn) {
				for (let i: number = 0; i < diepteTexturesIn.length; i++) {
					if (disposeDiepteTexturesIn[i] === true) {
						gl.deleteTexture(diepteTexturesIn[i]);
						diepteTexturesIn[i] = null;
					}
				}
			}
			if (returnDiepteTextureUit === true) {
				uit.diepteTexture = passFBO.diepteTexture;
				passFBO.diepteTexture = undefined;
			}
			return uit;
		}
		catch (exc) {
			exc = 'Pass \"' + passNaam + '\" kon niet uitgevoerd worden. Foutdetails: ' + exc;
			throw exc;
		}
		finally {
			this.wisFBO(passFBO);
		}
	};

	//
	// Past Fast Approximate Antialiasing (FXAA) toe op het aangeleverde multi-pass tussenresultaat.
	// Parameter 'passFBO' is het framebuffer-object waarin getekent moet worden.
	// Parameter 'kleurTexturesIn' is een array met ingaande kleur-textures voor de pass.
	// Parameter 'diepteTexturesIn' is een array met ingaande diepte-textures voor de pass.
	//
	private tekenPassFXAA: VLib.PassTekenFunctieType = (passFBO: WebGLFramebuffer, kleurTexturesIn: WebGLTexture[], diepteTexturesIn: WebGLTexture[]): void => {
		this.tekenPassFXAAIntern(passFBO, kleurTexturesIn, diepteTexturesIn);
	}

	private tekenPassFXAAIntern(passFBO: WebGLFramebuffer, kleurTexturesIn: WebGLTexture[], diepteTexturesIn: WebGLTexture[]): void {
		//
		// Pas Fast Approximate Antialiasing (FXAA) toe op het aangeleverde tussenresultaat:
		//
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramFXAAWrap;
		gl.useProgram(shaderProgramWrap.shaderProgram);

		gl.clear(gl.DEPTH_BUFFER_BIT);

		gl.activeTexture(gl.TEXTURE0);
		gl.bindTexture(gl.TEXTURE_2D, kleurTexturesIn[0]);

		gl.uniform2fv(shaderProgramWrap.invResolutieUniform, [1.0 / gl.drawingBufferWidth, 1.0 / gl.drawingBufferHeight]);

		gl.bindBuffer(gl.ARRAY_BUFFER, shaderProgramWrap.vertexDataBufferWrap.vertexDataBuffer);
		gl.vertexAttribPointer(shaderProgramWrap.vertexCoorAttribute, 2 /* x-, y-coordinaten */, gl.FLOAT,
			false, 0, 0);

		gl.drawArrays(gl.TRIANGLES, 0, shaderProgramWrap.vertexDataBufferWrap.aantal);
	};

	//
	// Voegt bokeh toe op het aangeleverde multi-pass tussenresultaat.
	// Parameter 'passFBO' is het framebuffer-object waarin getekent moet worden.
	// Parameter 'kleurTexturesIn' is een array met ingaande kleur-textures voor de pass.
	// Parameter 'diepteTexturesIn' is een array met ingaande diepte-textures voor de pass.
	//
	private tekenPassBokeh: VLib.PassTekenFunctieType = (passFBO: WebGLFramebuffer, kleurTexturesIn: WebGLTexture[], diepteTexturesIn: WebGLTexture[]): void => {
		this.tekenPassBokehIntern(passFBO, kleurTexturesIn, diepteTexturesIn);
	}

	private tekenPassBokehIntern(passFBO: WebGLFramebuffer, kleurTexturesIn: WebGLTexture[], diepteTexturesIn: WebGLTexture[]): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramBokehWrap;
		gl.useProgram(shaderProgramWrap.shaderProgram);

		gl.clear(gl.DEPTH_BUFFER_BIT);

		gl.activeTexture(gl.TEXTURE0);
		gl.bindTexture(gl.TEXTURE_2D, kleurTexturesIn[0]);
		gl.activeTexture(gl.TEXTURE1);
		gl.bindTexture(gl.TEXTURE_2D, diepteTexturesIn[0]);

		let bokeh: NabewerkingBokeh = this.privates.nabewerkingen.bokeh;
		let near: number = this.privates.projectieClippingFrustum.Frustum.near;
		let far: number = this.privates.projectieClippingFrustum.Frustum.far;
		gl.uniform1f(shaderProgramWrap.nearUniform, near);
		gl.uniform1f(shaderProgramWrap.farUniform, far);
		gl.uniform2fv(shaderProgramWrap.invResolutieUniform, [1.0 / gl.drawingBufferWidth, 1.0 / gl.drawingBufferHeight]);
		if (bokeh.isVuil) {
			gl.uniform1f(shaderProgramWrap.focusDiepteUniform, bokeh.focusAfstand);
			gl.uniform1i(shaderProgramWrap.autoFocusUniform, bokeh.autofocus ? VLib.Lib._webGLTrue : VLib.Lib._webGLFalse); //@@@Q1 bugfix: er stond 'bokeh.autoFocus'.
			gl.uniform1fv(shaderProgramWrap.cocCurveUniform, bokeh.cocCurveParams);
			gl.uniform1f(shaderProgramWrap.waasSterkteUniform, bokeh.waasSterkte);
			gl.uniform1f(shaderProgramWrap.ruisSterkteUniform, bokeh.ruisSterkte);
			bokeh.isVuil = false;
		}

		gl.bindBuffer(gl.ARRAY_BUFFER, shaderProgramWrap.vertexDataBufferWrap.vertexDataBuffer);
		gl.vertexAttribPointer(shaderProgramWrap.vertexCoorAttribute, 2 /* x-, y-coordinaten */, gl.FLOAT,
			false, 0, 0);

		gl.drawArrays(gl.TRIANGLES, 0, shaderProgramWrap.vertexDataBufferWrap.aantal);
	};

	//
	// Voegt een vignette toe op het aangeleverde multi-pass tussenresultaat.
	// Parameter 'passFBO' is het framebuffer-object waarin getekent moet worden.
	// Parameter 'kleurTexturesIn' is een array met ingaande kleur-textures voor de pass.
	// Parameter 'diepteTexturesIn' is een array met ingaande diepte-textures voor de pass.
	//
	private tekenPassVignette: VLib.PassTekenFunctieType = (passFBO: WebGLFramebuffer, kleurTexturesIn: WebGLTexture[], diepteTexturesIn: WebGLTexture[]): void => {
		this.tekenPassVignetteIntern(passFBO, kleurTexturesIn, diepteTexturesIn);
	}

	private tekenPassVignetteIntern(passFBO: WebGLFramebuffer, kleurTexturesIn: WebGLTexture[], diepteTexturesIn: WebGLTexture[]): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramVignetteWrap;
		gl.useProgram(shaderProgramWrap.shaderProgram);

		gl.clear(gl.DEPTH_BUFFER_BIT);

		gl.activeTexture(gl.TEXTURE0);
		gl.bindTexture(gl.TEXTURE_2D, kleurTexturesIn[0]);

		let vignette: NabewerkingVignette = this.privates.nabewerkingen.vignette;
		if (vignette.isVuil) {
			gl.uniform1f(shaderProgramWrap.straalUniform, vignette.straal);
			gl.uniform1f(shaderProgramWrap.wijdteUniform, vignette.wijdte);
			gl.uniform1f(shaderProgramWrap.intensiteitUniform, vignette.intensiteit);
			vignette.isVuil = false;
		}

		gl.bindBuffer(gl.ARRAY_BUFFER, shaderProgramWrap.vertexDataBufferWrap.vertexDataBuffer);
		gl.vertexAttribPointer(shaderProgramWrap.vertexCoorAttribute, 2 /* x-, y-coordinaten */, gl.FLOAT,
			false, 0, 0);

		gl.drawArrays(gl.TRIANGLES, 0, shaderProgramWrap.vertexDataBufferWrap.aantal);
	};

	//
	// Voegt Screen-Space Ambient Occlusion (SSAO) toe op het aangeleverde multi-pass 
	// tussenresultaat.
	// Parameter 'passFBO' is het framebuffer-object waarin getekent moet worden.
	// Parameter 'kleurTexturesIn' is een array met ingaande kleur-textures voor de pass.
	// Parameter 'diepteTexturesIn' is een array met ingaande diepte-textures voor de pass.
	//
	private tekenPassSSAOBerekenen: VLib.PassTekenFunctieType = (passFBO: WebGLFramebuffer, kleurTexturesIn: WebGLTexture[], diepteTexturesIn: WebGLTexture[]): void => {
		this.tekenPassSSAOBerekenenIntern(passFBO, kleurTexturesIn, diepteTexturesIn);
	}

	private tekenPassSSAOBerekenenIntern(passFBO: WebGLFramebuffer, kleurTexturesIn: WebGLTexture[], diepteTexturesIn: WebGLTexture[]): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramSSAOBerekenenWrap;
		gl.useProgram(shaderProgramWrap.shaderProgram);

		gl.clear(gl.DEPTH_BUFFER_BIT);

		gl.activeTexture(gl.TEXTURE0);
		gl.bindTexture(gl.TEXTURE_2D, kleurTexturesIn[0]);
		gl.activeTexture(gl.TEXTURE1);
		gl.bindTexture(gl.TEXTURE_2D, diepteTexturesIn[0]);

		let near: number = this.privates.projectieClippingFrustum.Frustum.near;
		let far: number = this.privates.projectieClippingFrustum.Frustum.far;
		let top: number = near * Math.tan(0.5 * this.privates.projectieClippingFrustum.Frustum.fovy * VLib.Lib._vanDegNaarRad);
		let right: number = top * this.privates.projectieClippingFrustum.Frustum.aspectRatio;
		gl.uniform1f(shaderProgramWrap.nearUniform, near);
		gl.uniform1f(shaderProgramWrap.farUniform, far);
		gl.uniform1f(shaderProgramWrap.topUniform, top);
		gl.uniform1f(shaderProgramWrap.rightUniform, right);

		let ssao: NabewerkingSSAO = this.privates.nabewerkingen.ssao;
		if (ssao.isVuil) {
			gl.uniform1f(shaderProgramWrap.proefRadiusUniform, ssao.straal);
			gl.uniform1f(shaderProgramWrap.occlusieMachtUniform, ssao.macht);
			ssao.isVuil = false;
		}

		gl.bindBuffer(gl.ARRAY_BUFFER, shaderProgramWrap.vertexDataBufferWrap.vertexDataBuffer);
		gl.vertexAttribPointer(shaderProgramWrap.vertexCoorAttribute, 2 /* x-, y-coordinaten */, gl.FLOAT,
			false, 0, 0);

		gl.drawArrays(gl.TRIANGLES, 0, shaderProgramWrap.vertexDataBufferWrap.aantal);
	};

	//
	// Voegt Screen-Space Ambient Occlusion (SSAO) toe op het aangeleverde multi-pass 
	// tussenresultaat.
	// Parameter 'passFBO' is het framebuffer-object waarin getekent moet worden.
	// Parameter 'kleurTexturesIn' is een array met ingaande kleur-textures voor de pass.
	// Parameter 'diepteTexturesIn' is een array met ingaande diepte-textures voor de pass.
	//
	private tekenPassSSAOAanbrengen: VLib.PassTekenFunctieType = (passFBO: WebGLFramebuffer, kleurTexturesIn: WebGLTexture[], diepteTexturesIn: WebGLTexture[]): void => {
		this.tekenPassSSAOAanbrengenIntern(passFBO, kleurTexturesIn, diepteTexturesIn);
	}

	private tekenPassSSAOAanbrengenIntern(passFBO: WebGLFramebuffer, kleurTexturesIn: WebGLTexture[], diepteTexturesIn: WebGLTexture[]): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramSSAOAanbrengenWrap;
		gl.useProgram(shaderProgramWrap.shaderProgram);

		gl.clear(gl.DEPTH_BUFFER_BIT);

		gl.activeTexture(gl.TEXTURE0);
		gl.bindTexture(gl.TEXTURE_2D, kleurTexturesIn[0]);
		gl.activeTexture(gl.TEXTURE1);
		gl.bindTexture(gl.TEXTURE_2D, kleurTexturesIn[1]);

		gl.uniform2fv(shaderProgramWrap.invResolutieUniform, [1.0 / gl.drawingBufferWidth, 1.0 / gl.drawingBufferHeight]);

		gl.bindBuffer(gl.ARRAY_BUFFER, shaderProgramWrap.vertexDataBufferWrap.vertexDataBuffer);
		gl.vertexAttribPointer(shaderProgramWrap.vertexCoorAttribute, 2 /* x-, y-coordinaten */, gl.FLOAT,
			false, 0, 0);

		gl.drawArrays(gl.TRIANGLES, 0, shaderProgramWrap.vertexDataBufferWrap.aantal);
	};

	//
	// Tekent het eindresultaat van de multi-pass naar het scherm.
	// Parameter 'kleurTextureIn' is de kleur-texture met het eindresultaat van de multi-pass.
	//
	private tekenMultipassFinale(kleurTexture: WebGLTexture): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shaderProgram: VLib.WebGLProgramWrapType = this.privates.shaderProgramTextureCopyWrap;
		gl.useProgram(shaderProgram.shaderProgram);

		gl.bindFramebuffer(gl.FRAMEBUFFER, null);

		gl.activeTexture(gl.TEXTURE0);
		gl.bindTexture(gl.TEXTURE_2D, kleurTexture);

		gl.bindBuffer(gl.ARRAY_BUFFER, shaderProgram.vertexDataBufferWrap.vertexDataBuffer);
		gl.vertexAttribPointer(shaderProgram.vertexCoorAttribute, 2 /* x-, y-coordinaten */, gl.FLOAT,
			false, 0, 0);

		gl.drawArrays(gl.TRIANGLES, 0, shaderProgram.vertexDataBufferWrap.aantal);
	};

	//
	// Leidt het toepassen een frustum shadow map van de belichting (i.e. een shadow map voor het gedeelte van het model 
	// binnen de view-frustum) in.
	//
	private startBelichtingFrustumShadowMap(): boolean {
		if (this.privates.glWrap) {
			let isBenut: boolean = false;

			let belichting: Belichting = this.privates.belichting;
			if (belichting.toonSlagschaduw &&
				belichting.toonSlagschaduwVerfijnd == false &&
				this.bepaalMoetBelichtingFrustumShadowMap()) {
				//
				// Pas een frustum shadow map toe. Stal eerst de huidige overzicht-shadow map:
				//
				belichting.gestaldeShadowMapTextureWrap = belichting.shadowMapTextureWrap;
				belichting.gestaldeDiepteMatrix = belichting.diepteMatrix;
				belichting.shadowMapTextureWrap = undefined;
				belichting.diepteMatrix = undefined;
				//
				// Maak de frustum shadow map:
				//
				this.maakBelichtingFrustumShadowMap();
				if (belichting.isCameraLicht &&
					belichting.shadowMapTextureWrap && belichting.gestaldeShadowMapTextureWrap) {
					let overzichtShadowMap: VLib.ShadowMapTextureWrapType = belichting.gestaldeShadowMapTextureWrap;
					let frustumShadowMapWrap: VLib.ShadowMapTextureWrapType = belichting.shadowMapTextureWrap;
					frustumShadowMapWrap.richting = overzichtShadowMap.richting;
					frustumShadowMapWrap.helling = overzichtShadowMap.helling;
					frustumShadowMapWrap.rolhoek = overzichtShadowMap.rolhoek;
				}
				isBenut = true;
			}

			return isBenut;
		}
		return false;
	};

	//
	// Beeindigd het toepassen van een frustum shadow map van de belichting.
	//
	private beeindigBelichtingFrustumShadowMap(): void {
		if (this.privates.glWrap) {
			let belichting: Belichting = this.privates.belichting;
			let gl: WebGLRenderingContext = this.privates.glWrap.gl;

			if (belichting.shadowMapTextureWrap && belichting.gestaldeShadowMapTextureWrap) {
				let frustumShadowMapWrap: VLib.ShadowMapTextureWrapType = belichting.shadowMapTextureWrap;
				belichting.shadowMapTextureWrap = belichting.gestaldeShadowMapTextureWrap;
				belichting.diepteMatrix = belichting.gestaldeDiepteMatrix;
				belichting.gestaldeShadowMapTextureWrap = undefined;
				belichting.gestaldeDiepteMatrix = undefined;

				//
				// Wis de achterhaalde frustum-shadow map:
				//
				gl.deleteTexture(frustumShadowMapWrap.texture);

				//
				// Stel de diepte-texture en de dieptematrix van de overzicht-shadow map in:
				//
				gl.activeTexture(gl.TEXTURE4);
				gl.bindTexture(gl.TEXTURE_2D, belichting.shadowMapTextureWrap.texture);
				gl.uniformMatrix4fv(this.privates.shaderProgramWrap.diepteMatrixUniform, false, belichting.diepteMatrix);
			}
		}
	};

	//
	// Bepaalt of een frustum shadow map van de belichting gemaakt moet worden.
	// Return-waarde is een boolean die true is wanneer de frustum shadow map moet worden; false wanneer dat niet het 
	// geval is.
	//
	private bepaalMoetBelichtingFrustumShadowMap(): boolean {
		if (this.privates.isFirmamentCamera()) {
			let huidigeZoomSchaal: number = this.privates.zoomSchaal;
			let huidigeVerschuivingRechts: number = this.privates.verschuivingRechts;
			let huidigeVerschuivingOmhoog: number = this.privates.verschuivingOmhoog;
			this.zetZoomGeheel();
			let geheelZoomSchaal: number = this.privates.zoomSchaal;
			this.privates.zoomSchaal = huidigeZoomSchaal;
			this.privates.verschuivingRechts = huidigeVerschuivingRechts;
			this.privates.verschuivingOmhoog = huidigeVerschuivingOmhoog;
			if (huidigeZoomSchaal < 3.0 * geheelZoomSchaal) {
				//
				// Er is niet of te weinig ingezoomd om de tol van het maken van een frustum shadow map te rechtvaardigen:
				//
				return false;
			}
		}
		return true;
	};

	//
	// Maakt een shadow map van de belichting.
	//
	private maakBelichtingShadowMap(): void {
		if (this.privates.glWrap && this.privates.fragmenten.length > 0) {
			let belichting: Belichting = this.privates.belichting;
			//
			// Bepaal de afmeting de shadow map:
			//
			let afmeting: number = this.bepaalShadowMapAfmetingVanBelichting(belichting);

			//
			// Bepaal de projectie- en modelviewmatrices van de shadow map. Er is sprake van parallel licht, dus een 
			// orthogonale projectie is toepasselijk:
			//
			let lichtval: number[] = belichting.lichtval;
			if (belichting.isCameraLicht) {
				//
				// Er is een cameralichtbron. Converteer de lichtval in generieke lichtbroncoordinaten naar 
				// WebGL-assenstelsel en vervolgens naar modelcoordinaten:
				//
				lichtval = [-lichtval[1], lichtval[2], -lichtval[0]];
				let rotatieMtrxInv: number[] = <number[]>mat3.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
				mat3.inverse(this.privates.rotatieMatrix, rotatieMtrxInv);
				mat3.multiplyVec3(rotatieMtrxInv, lichtval);
			}
			let lichtvalHoeken: number[] = VLib.Lib.vanVectorNaarHoeken(lichtval);

			let projMtrx: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
			this.stelProjectieMatrixIn_FirmamentCamera(projMtrx, 1.0, 1.0, 0.0, 0.0, true);
			let modelviewMtrx: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
			let rotatieMtrx: number[] = <number[]>mat3.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
			this.stelModelviewMatrixIn_FirmamentCamera(modelviewMtrx, rotatieMtrx,
				lichtvalHoeken[0], lichtvalHoeken[1], 0.0);

			//
			// Maak de shadow map:
			//
			this.wisShadowMapVanBelichting(belichting);
			let resultaat: VLib.AangemaaktShadowMapResultaatType = this.maakShadowMap(afmeting, modelviewMtrx, projMtrx);
			belichting.shadowMapTextureWrap = { texture: resultaat.shadowMapTexture };
			belichting.diepteMatrix = resultaat.diepteMatrix;
			resultaat.shadowMapTexture = null;

			//
			// Stel de diepte-texture en de dieptematrix van de shadow map in:
			//
			let gl: WebGLRenderingContext = this.privates.glWrap.gl;
			gl.activeTexture(gl.TEXTURE4);
			gl.bindTexture(gl.TEXTURE_2D, belichting.shadowMapTextureWrap.texture);
			gl.uniformMatrix4fv(this.privates.shaderProgramWrap.diepteMatrixUniform, false, belichting.diepteMatrix);
		}
	};

	//
	// Maakt een frustum shadow map van de belichting, i.e. een shadow map voor het gedeelte van het model binnen de 
	// view-frustum.
	//
	private maakBelichtingFrustumShadowMap(): void {
		if (this.privates.glWrap && this.privates.fragmenten.length > 0) {
			if (this.bepaalMoetBelichtingFrustumShadowMap() == false) {
				this.maakBelichtingShadowMap();
				return;
			}

			let gl: WebGLRenderingContext = this.privates.glWrap.gl;
			let belichting: Belichting = this.privates.belichting;
			//
			// Bepaal de afmeting de frustum shadow map:
			//
			let afmeting: number = this.bepaalShadowMapAfmetingVanBelichting(belichting);

			//
			// Bepaal de Z-buffer map van de huidige view:
			//
			let diepteBufferArray: Float32Array = this.maakZBufferMap();
			//
			// De Z-buffer map geeft de minimale diepten achter elk viewport schermcoordinaat. 
			// Converteer deze punten naar model-coordinaten en projecteer deze vervolgens naar het vlak loodrecht op de 
			// lichtval, i.e. het vlak van de shadow map.
			//
			let viewMVP: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
			mat4.multiply(this.privates.projectieMatrix, this.privates.modelviewMatrix, viewMVP);
			mat4.inverse(viewMVP);

			let lichtval: number[] = belichting.lichtval;
			if (belichting.isCameraLicht) {
				//
				// Er is een cameralichtbron. Converteer de lichtval in generieke lichtbroncoordinaten naar 
				// WebGL-assenstelsel en vervolgens naar modelcoordinaten:
				//
				lichtval = [-lichtval[1], lichtval[2], -lichtval[0]];
				let rotatieMtrxInv: number[] = <number[]>mat3.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
				mat3.inverse(this.privates.rotatieMatrix, rotatieMtrxInv);
				mat3.multiplyVec3(rotatieMtrxInv, lichtval);
			}
			let lichtvalHoeken: number[] = VLib.Lib.vanVectorNaarHoeken(lichtval);

			let lichtvalProjMtrx: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
			this.stelProjectieMatrixIn_FirmamentCamera(lichtvalProjMtrx, 1.0, 1.0, 0.0, 0.0, true);
			let lichtvalModelviewMtrx: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
			let lichtvalRotatieMtrx: number[] = <number[]>mat3.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
			this.stelModelviewMatrixIn_FirmamentCamera(lichtvalModelviewMtrx, lichtvalRotatieMtrx,
				lichtvalHoeken[0], lichtvalHoeken[1], 0.0);
			let lichtvalMVP: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
			mat4.multiply(lichtvalProjMtrx, lichtvalModelviewMtrx, lichtvalMVP);

			//
			// Voer de puntenconversie uit:
			// NB: Omwille van snelheidswinst wordt de Z-buffer map 'gedownsampled'.
			//
			let mapMin: number[] = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY];
			let mapMax: number[] = [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY];
			let bufferBreedte: number = gl.drawingBufferWidth;
			let bufferHoogte: number = gl.drawingBufferHeight;
			//let aantalPixels: number = bufferBreedte * bufferHoogte; //@@@Q Wordt hier niet gebruikt.
			//let aantalAchtergrondPixels: number = 0; //@@@Q Wordt hier niet gebruikt.

			let punt: number[] = [];
			for (let j: number = 0; j < bufferHoogte; j++) {
				let i: number = (j % 2); // 'dambord' van pixels
				for (; i < bufferBreedte; i += 2) {
					let diepte: number = diepteBufferArray[j * bufferBreedte + i];
					if (VLib.Lib.isGetal(diepte)) {
						//
						// Converteer viewport schermcoordinaten naar model-coordinaten en vervolgens 
						// van model-coordinaten naar coordinaten in het vlak loodrecht op de lichtval. 
						//
						// NB: Deze conversies zouden uitgevoerd kunnen worden door achtereenvolgende 
						// aanroepen van 
						//   vec3.unproject(punt, viewModelviewMatrix, viewProjectieMatrix, viewViewport);
						// en 
						//   vec3_project(punt, lichtvalModelviewMatrix, lichtvalProjMatrix, lichtvalViewport);
						// waarbij lichtvalViewport = [0, 0, 1, 1].
						// Echter, omwille van snelheidswinst is de code van beide functies hier 
						// ontleed en gereduceerd. Zie de genoemde functies voor nadere toelichting.
						//
						punt[0] = (i / bufferBreedte) * 2.0 - 1.0;
						punt[1] = (j / bufferHoogte) * 2.0 - 1.0;
						punt[2] = diepte * 2.0 - 1.0;
						punt[3] = 1.0;
						mat4.multiplyVec4(viewMVP, punt);
						punt[0] = punt[0] / punt[3];
						punt[1] = punt[1] / punt[3];
						punt[2] = punt[2] / punt[3];

						mat4.multiplyVec3(lichtvalMVP, punt);
						punt[0] = punt[0] * 0.5 + 0.5;
						punt[1] = punt[1] * 0.5 + 0.5;
						//
						// Bewaar de extreme waarden:
						//
						mapMin[0] = Math.min(mapMin[0], punt[0]);
						mapMax[0] = Math.max(mapMax[0], punt[0]);
						mapMin[1] = Math.min(mapMin[1], punt[1]);
						mapMax[1] = Math.max(mapMax[1], punt[1]);
					}
					//else
					//
					// Hier is op deze pixel sprake van de achtergrond. Het punt is niet van 
					// belang en wordt genegeerd.
					//
				}
			}

			let epsilon: number = 1.0 / afmeting;
			if (VLib.Lib.isGetal(mapMin[0]) == false) {
				//
				// Er is alleen achtergrond in beeld waarop nooit slagschaduw te zien zal zijn. 
				// Hoewel deze in dit geval nooit zal worden aangesproken, moet er toch een shadow map aanwezig zijn. Veins 
				// een uitsnedegebied ver buiten de scene:
				//
				mapMin = [100.0, 100.0];
				mapMax = [100.0 + epsilon, 100.0 + epsilon];
			}
			else {
				//
				// Controleer of het uitsnedegebied niet minuscuul wordt en corrigeer zonodig:
				//
				if (mapMax[0] - mapMin[0] < epsilon) {
					mapMin[0] = 0.5 * (mapMin[0] + mapMax[0] - epsilon);
					mapMax[0] = mapMin[0] + epsilon;
				}
				if (mapMax[1] - mapMin[1] < epsilon) {
					mapMin[1] = 0.5 * (mapMin[1] + mapMax[1] - epsilon);
					mapMax[1] = mapMin[1] + epsilon;
				}
			}

			//
			//
			// Herbepaal de projectiematrix van de frustum shadow map.
			// Bepaal de verhouding van het uitsnedegebied en het totaalgebied, en daarmee de zoomschaal:
			//
			let zoomRatio: number = Math.max(mapMax[0] - mapMin[0], mapMax[1] - mapMin[1]);
			let mapZoomSchaal: number = 1.0 / zoomRatio;
			//
			// Verschuif het centrum van het uitsnedegebied naar het midden van het totaalgebied:
			//
			let mapVerschuivingRechts: number = 1.0 - (mapMin[0] + mapMax[0]);
			let mapVerschuivingOmhoog: number = 1.0 - (mapMin[1] + mapMax[1]);

			this.stelProjectieMatrixIn_FirmamentCamera(lichtvalProjMtrx, 1.0, mapZoomSchaal,
				mapVerschuivingRechts, mapVerschuivingOmhoog, true);

			//
			// Maak de frustum shadow map:
			//
			this.wisShadowMapVanBelichting(belichting);
			let resultaat: VLib.AangemaaktShadowMapResultaatType = this.maakShadowMap(afmeting, lichtvalModelviewMtrx, lichtvalProjMtrx);
			belichting.shadowMapTextureWrap = { texture: resultaat.shadowMapTexture };
			belichting.diepteMatrix = resultaat.diepteMatrix;
			resultaat.shadowMapTexture = null;

			//
			// Stel de diepte-texture en de dieptematrix van de frustum shadow map in:
			//
			gl.activeTexture(gl.TEXTURE4);
			gl.bindTexture(gl.TEXTURE_2D, belichting.shadowMapTextureWrap.texture);
			gl.uniformMatrix4fv(this.privates.shaderProgramWrap.diepteMatrixUniform, false, belichting.diepteMatrix);
		}
	};

	//
	// Maakt een shadow map van een lamp.
	// Parameter 'lamp' is een lamp-object.
	// Parameter 'lampIndex' is de index van de lamp.
	//
	private maakLampShadowMap(lamp: Lamp, lampIndex: number): void {
		if (this.privates.glWrap && this.privates.fragmenten.length > 0) {
			//
			// Bepaal de afmeting de shadow map:
			//
			let afmeting: number = this.bepaalShadowMapAfmetingVanLamp(lamp)

			//
			// Bepaal de projectie- en modelviewmatrices van de shadow map. Er is sprake van radiaal licht, dus een 
			// perspectiefprojectie is toepasselijk:
			//
			let positie: number[] = lamp.positie;
			let lichtval: number[] = lamp.richting;
			let lichtvalHoeken: number[] = VLib.Lib.vanVectorNaarHoeken(lichtval);
			let fovy: number = 2.0 * lamp.radiusHoek;
			//
			// Voorbij ruwweg 15 maal de lichtattenuatieafstand draagt het lamplicht minder dan 1/255 kleurverschil bij 
			// (zonder overbelichting), dus dat kan buiten de invloedssfeer van de lamp beschouwd worden:
			//
			let modelDims: VLib.ModelDimensiesType = this.getModelDimensies();
			let schaal: number = 1.0 / modelDims.modelStraal;
			let far: number = 15.0 * this.privates.lichtAttenuatieAfstand * schaal;

			let projMtrx: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
			mat4.perspective(fovy, 1.0, 0.001, far, projMtrx);
			let modelviewMtrx: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
			let rotatieMtrx: number[] = <number[]>mat3.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
			this.stelModelviewMatrixIn_TerreinCamera(modelviewMtrx, rotatieMtrx,
				lichtvalHoeken[0], lichtvalHoeken[1], 0.0, [0, 0, 0]);
			mat4.scale(modelviewMtrx, [schaal, schaal, schaal]);
			mat4.translate(modelviewMtrx, [-positie[0], -positie[1], -positie[2]]);

			//
			// Maak de shadow map:
			//
			this.wisShadowMapVanLamp(lamp);
			let resultaat: VLib.AangemaaktShadowMapResultaatType = this.maakShadowMap(afmeting, modelviewMtrx, projMtrx);
			lamp.shadowMapTexture = resultaat.shadowMapTexture;
			lamp.diepteMatrix = resultaat.diepteMatrix;
			resultaat.shadowMapTexture = null;

			//
			// Stel de diepte-texture en de dieptematrix van de shadow map in:
			//
			let gl: WebGLRenderingContext = this.privates.glWrap.gl;
			let lampUniforms: VLib.LampUniformsType = this.privates.shaderProgramWrap.lampenUniforms[lampIndex];
			gl.activeTexture(lampUniforms.diepteTextureUnit);
			gl.bindTexture(gl.TEXTURE_2D, lamp.shadowMapTexture);
			gl.uniformMatrix4fv(lampUniforms.diepteMatrix, false, lamp.diepteMatrix);
		}
	};

	//
	// Maakt een shadow map van een lichtbron (belichting of lamp).
	// Parameter 'afmeting' is de afmeting van de shadow map, in pixels, welke de resolutie bepaald.
	// Parameter 'modelviewMatrix' is een mat4, de modelview ten opzichte van de lichtbron.
	// Parameter 'projectieMatrix' is een mat4, de projectie op de lichtbron.
	// Return-waarde is een object met de shadow map texture en de dieptematix.
	//
	private maakShadowMap(afmeting: number, modelviewMatrix: number[], projectieMatrix: number[]): VLib.AangemaaktShadowMapResultaatType {
		//
		// Maak een framebufferobject om in te tekenen:
		// NB: Deze WebGL-versie staat niet toe een texture te gebruiken voor de diepte-buffer. Echter, er bestaat een 
		// extension 'WEBGL_depth_texture' waarmee dit wel kan. Indien beschikbaar wordt de extension gebruikt; zo niet, 
		// dan versleuteld de fragment shader de diepte in de texture kleur-buffer.
		//
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shadowMapTexture: WebGLTexture;
		try {
			//
			// Activeer het shader program voor shadow mapping:
			//
			this.privates.shaderProgramWrap = this.privates.shaderProgramShadowMappingWrap;
			gl.useProgram(this.privates.shaderProgramWrap.shaderProgram);

			let shadowMapFBO: VLib.WebGLFramebufferWrapType = undefined;
			if (this.privates.extDepthTexture) {
				//
				// Laat de fragment shader de diepte rechtstreeks in een depth-texture wegschrijven:
				//
				shadowMapFBO = this.maakFBO(afmeting, afmeting, false, true, 'shadow map');
				shadowMapTexture = shadowMapFBO.diepteTexture;
				shadowMapFBO.diepteTexture = undefined;

				gl.colorMask(false, false, false, false);
			}
			else {
				//
				// Laat de fragment shader de diepte versleuteld wegschrijven in een kleur texture:
				//
				shadowMapFBO = this.maakFBO(afmeting, afmeting, true, false, 'shadow map');
				shadowMapTexture = shadowMapFBO.kleurTexture;
				shadowMapFBO.kleurTexture = undefined;
			}

			gl.uniformMatrix4fv(this.privates.shaderProgramWrap.projectieMatrixUniform, false,
				projectieMatrix);
			gl.uniformMatrix4fv(this.privates.shaderProgramWrap.modelviewMatrixUniform, false,
				modelviewMatrix);

			gl.viewport(0, 0, afmeting, afmeting);
			//
			// Teken de afbeeldingen van het model die slagschaduwen maken:
			//
			this.tekenDepthMappingScene(modelviewMatrix, true);

			gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
			//
			// Ruim de framebuffer op:
			//
			this.wisFBO(shadowMapFBO);
			if (this.privates.extDepthTexture) {
				gl.colorMask(true, true, true, true);
			}
		}
		catch (exc) {
			//this.conditionalLog('Maken van shadow map kon niet uitgevoerd worden. Foutdetails: ' + exc);
			//return; //@@@Q Bugfix: Als je een return doet, moet je wel het juiste type returnen.
			throw 'Maken van shadow map kon niet uitgevoerd worden. Foutdetails: ' + exc;
		}
		finally {
			//
			// Heractiveer het standaard shader program:
			//
			this.privates.shaderProgramWrap = this.privates.shaderProgramStdWrap;
			gl.useProgram(this.privates.shaderProgramWrap.shaderProgram);
		}

		//
		// Bepaal de dieptematrix, de matrix die een model-coordinaat transformeert naar een coordinaat in de shadow map 
		// (i.e. bias-matrix * shadow map projectiematrix * shadow map modelviewmatrix):
		// NB: De zogeheten bias-matrix transformeert het bereik [-1, 1] van de projectie naar een bereik [0, 1] van 
		// texture-coordinaten.
		//
		let mtrx: number[] = <number[]>mat4.create(); // M.b.t. de cast: zie note (3) bovenin deze file.
		mat4.multiply(projectieMatrix, modelviewMatrix, mtrx);
		let biasMatrix: number[] = <number[]>mat4.createFrom( // M.b.t. de cast: zie note (3) bovenin deze file.
			0.5, 0.0, 0.0, 0.0,  // eerste kolom
			0.0, 0.5, 0.0, 0.0,  // tweede kolom
			0.0, 0.0, 0.5, 0.0,  // derde kolom
			0.5, 0.5, 0.5, 1.0); // vierde kolom
		mat4.multiply(biasMatrix, mtrx);
		let resultaat: VLib.AangemaaktShadowMapResultaatType = { shadowMapTexture: shadowMapTexture, diepteMatrix: biasMatrix };

		return resultaat;
	};

	//
	// Ruimt de eventuele bestaande shadow map van de lamp op.
	//
	private wisShadowMapVanLamp(lamp: Lamp): void {
		if (this.privates.glWrap) {
			if (lamp && lamp.shadowMapTexture) {
				this.privates.glWrap.gl.deleteTexture(lamp.shadowMapTexture);
				lamp.shadowMapTexture = undefined;
				lamp.diepteMatrix = undefined;
			}
		}
	};

	//
	// Ruimt de eventuele bestaande shadow map van de belichting op.
	//
	private wisShadowMapVanBelichting(belichting: Belichting): void {
		if (this.privates.glWrap) {
			if (belichting && belichting.shadowMapTextureWrap) {
				this.privates.glWrap.gl.deleteTexture(belichting.shadowMapTextureWrap.texture);
				belichting.shadowMapTextureWrap = undefined;
				belichting.diepteMatrix = undefined;
			}
		}
	};

	//
	// Ruimt alle achterhaalde shadow maps op.
	//
	private wisShadowMaps(): void {
		this.wisShadowMapVanBelichting(this.privates.belichting);
		if (this.privates.belichting.gestaldeShadowMapTextureWrap != null) {
			this.privates.glWrap.gl.deleteTexture(this.privates.belichting.gestaldeShadowMapTextureWrap.texture);
			this.privates.belichting.gestaldeShadowMapTextureWrap = undefined;
			this.privates.belichting.gestaldeDiepteMatrix = undefined;
		}

		if (this.privates.lampen != null) {
			for (let i: number = 0; i < this.privates.lampen.length; i++) {
				this.wisShadowMapVanLamp(this.privates.lampen[i]);
			}
		}
	};

	//
	// Bepaalt de afmeting de shadow map voor de lamp.
	// Return-waarde is een integer, de afmeting in pixels.
	//
	private bepaalShadowMapAfmetingVanLamp(lamp: Lamp): number {
		//
		// Parameter Lamp wordt (momenteel) niet gebruikt.
		// Een Lamp heeft namelijk geen property slagschaduwHint om rekening mee te houden.
		// Bij een Belichting speelt dat wel een rol, zie functie bepaalShadowMapAfmetingVanBelichting.
		//
		let afmeting: number = this.privates.standaardShadowMapAfmeting;
		return afmeting;
	};

	//
	// Bepaalt de afmeting de shadow map voor de belichting.
	// Return-waarde is een integer, de afmeting in pixels.
	//
	private bepaalShadowMapAfmetingVanBelichting(belichting: Belichting): number {
		let afmeting: number = this.privates.standaardShadowMapAfmeting;

		if (belichting) {
			switch (belichting.slagschaduwHint) {
				case -1:
					afmeting *= 0.5; // gehalveerd
					break;
				case 0:
				default:
					break;
				case 1:
					afmeting *= 2; // verdubbeld
					break;
				case 2:
					afmeting = this.privates.paramMaxTextureSize; // maximaal
					//
					// NB: De maximale afmeting is slechts een schatting van de user-agent. Het kan zijn dat gebruik van 
					// deze waarde problemen oplevert, waaronder het verlies van de rendering context.
					//
					break;
			}
			afmeting = Math.min(afmeting, this.privates.paramMaxTextureSize);
		}

		return afmeting;
	};

	//
	// Maakt een Z-buffer map van de huidige view.
	// Optionele parameters 'xLO', 'yLO', 'xRBO' en 'yRB' zijn integers, de viewport-coordinaten van een eventueel 
	// steekproefgebied.
	// Return-waarde is een Float32Array met de diepten van de view beginnend in de linkeronderhoek en oplopend naar 
	// rechts. Achtergrond wordt gerepresenteerd door waarde Number.NaN.
	//
	private maakZBufferMap(xLO?: number, yLO?: number, xRB?: number, yRB?: number): Float32Array {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let breedte: number = gl.drawingBufferWidth;
		let hoogte: number = gl.drawingBufferHeight;

		if (arguments.length > 0 &&
			(VLib.Lib.isGetal(xLO) == false || xLO < 0 || xLO >= breedte ||
			VLib.Lib.isGetal(yLO) == false || yLO < 0 || yLO >= hoogte ||
			VLib.Lib.isGetal(xRB) == false || xRB < 0 || xRB >= breedte ||
			VLib.Lib.isGetal(yRB) == false || yRB < 0 || yRB >= hoogte)) {
			//
			// De coordinaten zijn niet geldig:
			//
			return null;
		}

		try {
			//
			// Activeer het shader program voor Z-buffer mapping:
			//
			this.privates.shaderProgramWrap = this.privates.shaderProgramZBufferMappingWrap;
			gl.useProgram(this.privates.shaderProgramWrap.shaderProgram);

			//
			// Maak een framebufferobject om in te tekenen. Laat de fragment shader de diepte 
			// versleuteld wegschrijven in een kleur texture:
			//
			// NB: Sommige implementaties (in het bijzonder op tablets) blijken niet correct om te 
			// gaan met renderbuffers, hetzij bij het schrijven in de beschikbare color-renderable 
			// formats (RGBA4, RGB565, RGB5_A1), dan wel bij het uitlezen door readPixels. Om die 
			// reden wordt hier een texture in plaats van een renderbuffer gebruikt.
			//
			let zBufferMapFBO: VLib.WebGLFramebufferWrapType = this.maakFBO(breedte, hoogte, true, false, 'Z-buffer map');

			gl.uniformMatrix4fv(this.privates.shaderProgramWrap.projectieMatrixUniform, false,
				this.privates.projectieMatrix);
			gl.uniformMatrix4fv(this.privates.shaderProgramWrap.modelviewMatrixUniform, false,
				this.privates.modelviewMatrix);

			if (xLO == null) {
				xLO = 0;
				yLO = 0;

				//
				// Teken de afbeeldingen van het model:
				//
				this.tekenDepthMappingScene(this.privates.modelviewMatrix, false);
			}
			else {
				if (xRB < xLO) {
					let wissel: number = xRB;
					xRB = xLO;
					xLO = wissel;
				}
				if (yRB < yLO) {
					let wissel: number = yRB;
					yRB = yLO;
					yLO = wissel;
				}
				breedte = xRB - xLO + 1;
				hoogte = yRB - yLO + 1;

				gl.enable(gl.SCISSOR_TEST);
				gl.scissor(xLO, yLO, breedte, hoogte);
				//
				// Teken de afbeeldingen van het model:
				//
				this.tekenDepthMappingScene(this.privates.modelviewMatrix, false);
				gl.disable(gl.SCISSOR_TEST);
			}

			//
			// Vraag de z-buffer data op:
			//
			let aantalPixels: number = breedte * hoogte;
			let dieptenAlsRGBA: Uint8Array = new Uint8Array(aantalPixels * 4);
			gl.readPixels(xLO, yLO, breedte, hoogte, gl.RGBA, gl.UNSIGNED_BYTE, dieptenAlsRGBA);
			//
			// Ontsleutel de z-buffer data:
			//
			let resultaat: Float32Array = new Float32Array(aantalPixels);
			let Q1: number = 1.0 / 255.0;
			let Q2: number = 1.0 / 65025.0;
			let Q3: number = 1.0 / 16581375.0;
			let Q4: number = 1.0 / 4228250625.0;
			for (let i: number = 0, k = 0; i < aantalPixels; i++ , k += 4) {
				let diepte: number;
				let R: number = dieptenAlsRGBA[k];
				let G: number = dieptenAlsRGBA[k + 1];
				let B: number = dieptenAlsRGBA[k + 2];
				let A: number = dieptenAlsRGBA[k + 3];
				if (R == 255 && G == 255 && B == 255 && A == 255) {
					//
					// Dit is de kleur die overeenkomt met de grootst mogelijke diepte, oftewel 
					// de achtergrond:
					//
					diepte = Number.NaN;
				}
				else {
					//
					// Herstel het bereik van de waarde naar [0, 1] zoals het in de shader was met 
					// R = R / 255, G = G / 255, etc.
					// De float-waarde wordt ontsleuteld met R + (G / 255) + (B / 255*255) + 
					// (A / 255*255*255).
					//
					diepte = R * Q1 + G * Q2 + B * Q3 + A * Q4;
				}
				resultaat[i] = diepte;
			}

			//
			// Ruim de framebuffer op:
			//
			this.wisFBO(zBufferMapFBO);

			return resultaat;
		}
		catch (exc) {
			this.conditionalLog('Maken van z-buffer map kon niet uitgevoerd worden. Foutdetails: ' + exc);
			return null;
		}
		finally {
			//
			// Heractiveer het standaard shader program:
			//
			this.privates.shaderProgramWrap = this.privates.shaderProgramStdWrap;
			gl.useProgram(this.privates.shaderProgramWrap.shaderProgram);
		}
	};

	//
	// Tekent een scene van de view ten behoeve van depth mapping.
	// Parameter 'depthMapModelviewMatrix' is een mat4.
	// Parameter 'voorShadowMapping' is een boolean die aangeeft of de depth map gemaakt wordt ten behoeve van shadow 
	// mapping (true) of niet (false).
	//
	private tekenDepthMappingScene(depthMapModelviewMatrix: number[], voorShadowMapping: boolean): void {
		if (this.privates.fragmenten.length == 0) {
			return;
		}

		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		//
		// Laat de achtergrond vullen met de kleur die overeen komt met de grootst mogelijke diepte:
		//
		let vorigeClearColor: number[] = gl.getParameter(gl.COLOR_CLEAR_VALUE);
		gl.clearColor(1.0, 1.0, 1.0, 1.0);
		gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
		gl.clearColor(vorigeClearColor[0], vorigeClearColor[1], vorigeClearColor[2], vorigeClearColor[3]);

		//
		// Wissel de teken-cyclus vóór het tekenen van de depth-map scene:
		//
		this.privates.tekenCyclus = !this.privates.tekenCyclus;

		if (voorShadowMapping === true) {
			gl.cullFace(gl.FRONT);
		}
		gl.disable(gl.BLEND);

		let fragmenten: VLib.FragmentType[] = this.privates.fragmenten;
		for (let i: number = 0; i < fragmenten.length; i++) {
			let fragment: VLib.FragmentType = fragmenten[i];
			if (fragment.werptSlagschaduw) {
				this.tekenDepthMappingItems(fragment.itemsOpaak, fragment.itemsTransparant,
					fragment.transLijst, depthMapModelviewMatrix, voorShadowMapping);
			}
		}

		gl.enable(gl.BLEND);
		gl.cullFace(gl.BACK);
	};

	//
	// Tekent een aantal items van een modelfragment ten behoeve van depth mapping.
	// Parameter 'itemsOpaak' is een array met te tekenen opake items. Mag null zijn.
	// Parameter 'itemsTransparant' is nog een array met te tekenen transparante items. Mag null zijn.
	// Parameter 'transLijst' is de te gebruiken transformatielijst. Mag null zijn.
	// Parameter 'depthMapModelviewMatrix' is een mat4.
	// Parameter 'voorShadowMapping' is een boolean die aangeeft of de depth map gemaakt wordt ten behoeve van shadow 
	// mapping (true) of niet (false).
	//
	private tekenDepthMappingItems(itemsOpaak: VLib.FragmentItemType[], itemsTransparant: VLib.FragmentItemType[], transLijst: VLib.TransLijstType, depthMapModelviewMatrix: number[], voorShadowMapping: boolean): void {
		if (itemsOpaak || itemsTransparant) {
			if (transLijst) {
				let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
				let tekenActieFunctie: VLib.TekenActieFunctieType = function (transLijstIndex: number): void { // Parameter transLijstIndex wordt niet gebruikt.
					if (itemsOpaak) {
						for (let i: number = 0; i < itemsOpaak.length; i++) {
							_this.tekenDepthMappingItem(itemsOpaak[i], true, voorShadowMapping);
						}
					}
					if (itemsTransparant) {
						for (let i: number = 0; i < itemsTransparant.length; i++) {
							_this.tekenDepthMappingItem(itemsTransparant[i], false, voorShadowMapping);
						}
					}
				};
				this.tekenMetTransLijst(tekenActieFunctie, transLijst, depthMapModelviewMatrix, false, false);
			}
			else {
				if (itemsOpaak) {
					for (let i: number = 0; i < itemsOpaak.length; i++) {
						this.tekenDepthMappingItem(itemsOpaak[i], true, voorShadowMapping);
					}
				}
				if (itemsTransparant) {
					for (let i: number = 0; i < itemsTransparant.length; i++) {
						this.tekenDepthMappingItem(itemsTransparant[i], false, voorShadowMapping);
					}
				}
			}
		}
	};

	//
	// Tekent een item van een modelfragment ten behoeve van depth mapping.
	// Parameter 'item' is het te tekenen item.
	// Parameter 'isOpaak' is een boolean die aangeeft of het item opaak is (in tegenstelling tot transparant).
	// Parameter 'voorShadowMapping' is een boolean die aangeeft of de depth map gemaakt wordt ten behoeve van shadow 
	// mapping (true) of niet (false).
	//
	private tekenDepthMappingItem(item: VLib.FragmentItemType, isOpaak: boolean, voorShadowMapping: boolean): void {
		if (item.type !== this.privates.defTypeVolume && item.type !== this.privates.defTypeVlak) {
			return;
		}

		if (voorShadowMapping === true && isOpaak == false && item.kleur[3] < 64) // alpha-kanaal
		{
			//
			// Sterk transparante items werpen geen schaduw:
			//
			return;
		}

		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramWrap;

		gl.bindBuffer(gl.ARRAY_BUFFER, item.vertexDataBufferWrap.vertexDataBuffer);
		gl.vertexAttribPointer(shaderProgramWrap.vertexCoorAttribute, 3 /* x-, y-, z-coordinaten */, gl.FLOAT,
			false, item.vertexDataBufferWrap.stride, 0);

		let aangezetTextuurCoorAttribute: boolean = false;
		let uitgezetCullFace: boolean = false;

		if (item.tableau && item.tableau.heeftAlpha === true) {
			gl.uniform1i(shaderProgramWrap.tableauVormTexturingUniform, VLib.Lib._webGLTrue);

			gl.activeTexture(gl.TEXTURE0);
			gl.bindTexture(gl.TEXTURE_2D, item.tableau.tex);

			gl.vertexAttribPointer(shaderProgramWrap.textuurCoorAttribute, 2 /* s-, t-coordinaten */, gl.FLOAT,
				false, item.vertexDataBufferWrap.stride, item.vertexDataBufferWrap.textuurCoorOffset);
			gl.enableVertexAttribArray(shaderProgramWrap.textuurCoorAttribute);
			aangezetTextuurCoorAttribute = true;

			if (item.type === this.privates.defTypeVolume) {
				gl.disable(gl.CULL_FACE);
				uitgezetCullFace = true;
			}
		}

		if (item.type === this.privates.defTypeVlak) {
			gl.disable(gl.CULL_FACE);
			gl.drawArrays(gl.TRIANGLES, 0, item.vertexDataBufferWrap.aantal);
			gl.enable(gl.CULL_FACE);
		}
		else // if (item.type === this.privates.defTypeVolume)
		{
			gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, item.vertexIndicesBufferWrap.vertexIndicesBuffer);
			gl.drawElements(gl.TRIANGLES, item.vertexIndicesBufferWrap.aantal, gl.UNSIGNED_SHORT, 0);
		}

		if (aangezetTextuurCoorAttribute) {
			gl.disableVertexAttribArray(shaderProgramWrap.textuurCoorAttribute);
			gl.uniform1i(shaderProgramWrap.tableauVormTexturingUniform, VLib.Lib._webGLFalse);

			if (uitgezetCullFace) {
				gl.enable(gl.CULL_FACE);
			}
		}

	};

	//
	// Schakelt een bepaalde lamp of alle lampen uit. 
	// Optionele parameter 'nr' is een integer in het bereik [0, lampenMaxAantal]. Bij afwezigheid worden alle lampen 
	// uitgeschakeld.
	//
	private doofLampen(nr?: number): void {
		if (this.privates.glWrap && this.privates.lampenMaxAantal != null) {
			if (nr == null) {
				for (let i: number = 0; i < this.privates.lampenMaxAantal; i++) {
					let lampUniforms: VLib.LampUniformsType = this.privates.shaderProgramWrap.lampenUniforms[i];
					this.privates.glWrap.gl.uniform1f(lampUniforms.cosBuitenHoek, 2.0);
				}
			}
			else if (VLib.Lib.isGetal(nr) && nr >= 0 && nr < this.privates.lampenMaxAantal) {
				let lampUniforms: VLib.LampUniformsType = this.privates.shaderProgramWrap.lampenUniforms[nr];
				this.privates.glWrap.gl.uniform1f(lampUniforms.cosBuitenHoek, 2.0);
			}
		}
	};

	//
	// Tekent het assenstelsel.
	//
	private tekenAssen(): void {
		if (this.privates.assenItem == undefined) {
			this.maakAssen();
		}

		if (this.privates.assenItem) {
			let gl: WebGLRenderingContext = this.privates.glWrap.gl;
			let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramWrap;

			gl.uniform1i(shaderProgramWrap.elemTypeUniform, this.privates.defTypeLijn);

			gl.bindBuffer(gl.ARRAY_BUFFER, this.privates.assenItem.vertexDataBuffer);
			gl.vertexAttribPointer(shaderProgramWrap.vertexCoorAttribute, 3 /* x-, y-, z-coordinaten */, gl.FLOAT,
				false, 0, 0);

			gl.lineWidth(2.0);
			//
			// De X-as:
			//
			gl.vertexAttrib4f(shaderProgramWrap.kleurAttribute, 255, 0, 0, 255);
			gl.drawArrays(gl.LINES, 0, 2);
			//
			// De Y-as:
			//
			gl.vertexAttrib4f(shaderProgramWrap.kleurAttribute, 0, 255, 0, 255);
			gl.drawArrays(gl.LINES, 2, 2);
			//
			// De Z-as:
			//
			gl.vertexAttrib4f(shaderProgramWrap.kleurAttribute, 0, 0, 255, 255);
			gl.drawArrays(gl.LINES, 4, 2);
		}
	};

	//
	// Maakt een framebuffer.
	// Parameter 'breedte' is de breedte van de framebuffer in pixels.
	// Parameter 'hoogte' is de hoogte van de framebuffer in pixels.
	// Parameter 'kleurMetTexture' is een boolean die aangeeft of de color-attachment een texture (true) moet zijn of 
	// een renderbuffer (false).
	// Parameter 'diepteMetTexture' is een boolean die aangeeft of de depth-attachment een texture (true) moet zijn of
	// een renderbuffer (false).
	// Parameter 'doel' is een tekst die het doel van de framebuffer aangeeft.
	// Return-waarde is een framebuffer-object, met daaraan als properties toegevoegd de attachments onder de namen 
	// kleurTexture cq. kleurRBO en diepteTexture cq. diepteRBO. De framebuffer is na het aanmaken actief.
	//
	private maakFBO(breedte: number, hoogte: number, kleurMetTexture: boolean, diepteMetTexture: boolean, doel: string): VLib.WebGLFramebufferWrapType {
		if (this.privates.glWrap) {
			let gl: WebGLRenderingContext = this.privates.glWrap.gl;

			let nieuweFBO: VLib.WebGLFramebufferWrapType = { FBO: gl.createFramebuffer(), doel: doel };
			gl.bindFramebuffer(gl.FRAMEBUFFER, nieuweFBO.FBO);
			gl.activeTexture(gl.TEXTURE0);
			if (kleurMetTexture) {
				let kleurTexture: WebGLTexture = gl.createTexture();
				gl.bindTexture(gl.TEXTURE_2D, kleurTexture);
				gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, breedte, hoogte, 0,
					gl.RGBA, gl.UNSIGNED_BYTE, null);
				gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
				gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
				gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
				gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
				gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, kleurTexture, 0);

				nieuweFBO.kleurTexture = kleurTexture;
			}
			else {
				let kleurRBO: WebGLRenderbuffer = gl.createRenderbuffer();
				gl.bindRenderbuffer(gl.RENDERBUFFER, kleurRBO);
				gl.renderbufferStorage(gl.RENDERBUFFER, gl.RGBA4, breedte, hoogte);
				gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, kleurRBO);

				nieuweFBO.kleurRBO = kleurRBO;
			}
			if (diepteMetTexture) {
				//
				// NB: Deze optie wordt alleen ondersteund met de depth-texture extension.
				//
				let diepteTexture: WebGLTexture = gl.createTexture();
				gl.bindTexture(gl.TEXTURE_2D, diepteTexture);
				gl.texImage2D(gl.TEXTURE_2D, 0, gl.DEPTH_COMPONENT, breedte, hoogte, 0,
					gl.DEPTH_COMPONENT, gl.UNSIGNED_INT, null);
				gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
				gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
				gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
				gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
				gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, diepteTexture, 0);

				nieuweFBO.diepteTexture = diepteTexture;
			}
			else {
				let diepteRBO: WebGLRenderbuffer = gl.createRenderbuffer();
				gl.bindRenderbuffer(gl.RENDERBUFFER, diepteRBO);
				gl.renderbufferStorage(gl.RENDERBUFFER, gl.DEPTH_COMPONENT16, breedte, hoogte);
				gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, diepteRBO);

				nieuweFBO.diepteRBO = diepteRBO;
			}
			//gl.bindTexture(gl.TEXTURE_2D, null); //@@@QB Null-parameter is niet de bedoeling, geeft warning in onderstaande call naar gl.checkFramebufferStatus.
			this.bindDummyTextuur(gl.ACTIVE_TEXTURE); //@@@QB Bugfix, tegen warnings.

			let status: number = gl.checkFramebufferStatus(gl.FRAMEBUFFER);
			if (status != gl.FRAMEBUFFER_COMPLETE) {
				this.wisFBO(nieuweFBO);
				throw 'Framebuffer voor ' + doel + ' kon niet gemaakt worden: ' + status;
			}

			return nieuweFBO;
		}
		else {
			throw "Er is geen WebGLRenderingContext";
		}
	}

	//
	// Ruimt een framebuffer op, inclusief de gekoppelde attachments.
	// Parameter 'overbodigFBO' is het op te ruimen framebuffer-object. De framebuffer wordt verondersteld gemaakt te 
	// zijn met de functie maakFBO.
	//
	private wisFBO(overbodigFBO: VLib.WebGLFramebufferWrapType): void {
		if (this.privates.glWrap && overbodigFBO) {
			let gl: WebGLRenderingContext = this.privates.glWrap.gl;

			gl.bindFramebuffer(gl.FRAMEBUFFER, null);
			if (overbodigFBO.kleurTexture) {
				gl.deleteTexture(overbodigFBO.kleurTexture);
			}
			else if (overbodigFBO.kleurRBO) {
				gl.deleteRenderbuffer(overbodigFBO.kleurRBO);
			}
			if (overbodigFBO.diepteTexture) {
				gl.deleteTexture(overbodigFBO.diepteTexture);
			}
			else if (overbodigFBO.diepteRBO) {
				gl.deleteRenderbuffer(overbodigFBO.diepteRBO);
			}
			gl.deleteFramebuffer(overbodigFBO.FBO);
		}
	};

	//
	// Maakt het assenstelsel.
	//
	private maakAssen(): void {
		let modelDims: VLib.ModelDimensiesType = this.getModelDimensies();
		if (modelDims) {
			let gl: WebGLRenderingContext = this.privates.glWrap.gl;
			//
			// Maak buffers voor de data van de coordinaatassen:
			//
			let asLengte: number = 10.0 * modelDims.stelselStraal;
			let asPunten: number[] = [-asLengte, 0.0, 0.0, asLengte, 0.0, 0.0,
				0.0, -asLengte, 0.0, 0.0, asLengte, 0.0,
				0.0, 0.0, -asLengte, 0.0, 0.0, asLengte];
			let assenVertexDataBuffer: WebGLBuffer = gl.createBuffer();
			gl.bindBuffer(gl.ARRAY_BUFFER, assenVertexDataBuffer);
			gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(asPunten), gl.STATIC_DRAW);

			let assenItem: VLib.AssenItemType = { vertexDataBuffer: assenVertexDataBuffer };
			//assenItem.vertexDataBuffer = assenVertexDataBuffer; //@@@Q1 overbodig statement.
			this.privates.assenItem = assenItem;
		}
	};

	//
	// Ruimt het eventuele bestaande assenstelsel op.
	//
	private wisAssen(): void {
		if (this.privates.glWrap && this.privates.assenItem) {
			this.privates.glWrap.gl.deleteBuffer(this.privates.assenItem.vertexDataBuffer);
			this.privates.assenItem.vertexDataBuffer = undefined;

			this.privates.assenItem = undefined;
		}
	};

	//
	// Tekent de hemel.
	//
	private tekenHemel(): void {
		if (this.privates.hemelItem) {
			if (this.privates.cameraType === true) // geen terreincamera
			{
				return;
			}

			let gl: WebGLRenderingContext = this.privates.glWrap.gl;
			let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramWrap;

			this.maakSkyDomeProjectie();
			gl.uniformMatrix4fv(shaderProgramWrap.projectieMatrixUniform, false, this.privates.hemelProjectieMatrix);
			this.maakSkyDomeModelview();
			gl.uniformMatrix4fv(shaderProgramWrap.modelviewMatrixUniform, false, this.privates.hemelModelviewMatrix);
			//
			// Stel een gelijkmatige belichting in:
			//
			gl.uniform1i(shaderProgramWrap.vloedlichtUniform, VLib.Lib._webGLTrue);

			gl.depthMask(false);

			this.tekenItem(this.privates.hemelItem, false);

			gl.depthMask(true);

			gl.uniform1i(shaderProgramWrap.vloedlichtUniform, VLib.Lib._webGLFalse);
		}
	};

	//
	// Ruimt de eventuele bestaande hemel op.
	//
	private wisHemel(): void {
		if (this.privates.glWrap && this.privates.hemelItem) {
			this.privates.glWrap.gl.deleteBuffer(this.privates.hemelItem.vertexDataBufferWrap.vertexDataBuffer);
			this.privates.hemelItem.vertexDataBufferWrap = undefined;
			this.privates.glWrap.gl.deleteBuffer(this.privates.hemelItem.vertexIndicesBufferWrap.vertexIndicesBuffer);
			this.privates.hemelItem.vertexIndicesBufferWrap = undefined;
			this.privates.glWrap.gl.deleteTexture(this.privates.hemelItem.tableau.tex);
			this.privates.hemelItem.tableau.tex = undefined;

			this.privates.hemelItem = undefined;
		}
	};

	//
	// Maakt een sky dome.
	// Parameter 'bestandsnaam' is een string met een bestandsnaam van een bitmap. 
	// Optionele parameter 'bereik' is een float in het bereik [90, 180]. 
	// Optionele parameter 'mercatorProjectie' is een boolean.
	// Optionele parameter 'gereedCallback' is een callback die wordt aangeroepen zodra de sky dome gereed is. Een 
	// callback is nodig omdat het verwerken asynchroon verloopt. De callback heeft geen parameters.
	//
	private maakSkyDome(bestandsnaam: string, bereik: number, mercatorProjectie: boolean, gereedCallback: V3DSimpelCallbackType): void {
		if (this.privates.glWrap) {
			let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
			//
			// M.b.t. onderstaande functie-definitie:
			// Vooralsnog is 'parameters' niet in gebruik, en daarom is type nu 'any' (= LaadBitmapGereedCallbackParametersType).
			//
			let ladenUitgevoerd: VLib.LaadBitmapGereedCallbackType = function (bitmap: HTMLImageElement, parameters: VLib.LaadBitmapGereedCallbackParametersType): void { 
				if (bitmap) {
					let texture: WebGLTexture;
					try {
						texture = _this.maakTexture(bitmap);
					}
					catch (exc) {
						texture = undefined;
						_this.privates.texNamenBlacklist.push(bestandsnaam);
						_this.conditionalLog(bestandsnaam, 10);
					}

					if (texture) {
						_this.privates.hemelItem = _this.construeerSkyDome(bereik, mercatorProjectie);
						_this.privates.hemelItem.tableau = {
							tex: texture,
							heeftAlpha: false
						};
						_this.privates.hemelItem.kleur = [0, 0, 0, 255];
						_this.privates.hemelItem.schitteringMat = 0.0;
					}
				}

				if (gereedCallback) {
					gereedCallback();
				}
			};

			this.wisHemel();
			//
			// Laad de texture van de sky dome:
			//
			this.laadBitmap(bestandsnaam, ladenUitgevoerd, null);
		}
	};

	//
	// Maakt de vertices van een sky dome.
	// Optionele parameter 'bereik' is een float in het bereik [90, 180]. 
	// Optionele parameter 'mercatorProjectie' is een boolean.
	//
	private construeerSkyDome(bereik: number, mercatorProjectie: boolean): VLib.FragmentItemType {
		if (bereik == null) {
			bereik = 90.0;
		}
		if (mercatorProjectie == null) {
			mercatorProjectie = false;
		}
		let latRange: number = Math.max(bereik, 90.0);
		latRange = Math.min(latRange, 180.0);
		latRange = latRange * VLib.Lib._vanDegNaarRad;
		let longBands: number = 72;
		let latBands: number = Math.round(longBands * 0.5 * latRange / Math.PI);

		let phiNoord: number = this.privates.yAsTovNoord * VLib.Lib._vanDegNaarRad;

		let vertexData: number[] = [];
		for (let latIndex: number = 0; latIndex <= latBands; latIndex++) {
			let s: number = 0.0;
			let t: number = 0.0;
			//
			// Bepaal de zenith. Deze hoek gaat van de apex (positieve Z-as) naar de grond (Z = 0) in geval van een 
			// hemisfeer en naar de antapex (negatieve Z-as) in geval van een bol:
			//
			let thetaRatio: number = latIndex / latBands;
			let theta: number = thetaRatio * latRange;
			let sinTheta: number = Math.sin(theta);
			let cosTheta: number = Math.cos(theta);

			if (mercatorProjectie == true) {
				//
				// T neemt af van de apex (t = 1) naar de grond in geval van een hemisfeer cq. naar de antapex in geval 
				// van een bol (T = 0):
				//
				t = 1.0 - thetaRatio;
			}

			for (let longIndex: number = 0; longIndex <= longBands; longIndex++) {
				//
				// Bepaal de richting. Deze hoek gaat in het XY-vlak van de nul-meridiaan (positieve Y-as) met de klok mee.
				//
				let phiRatio: number = longIndex / longBands;
				let phi: number = 2.0 * Math.PI * phiRatio + phiNoord;
				let sinPhi: number = Math.sin(phi);
				let cosPhi: number = Math.cos(phi);

				let x: number = sinPhi * sinTheta;
				let y: number = cosPhi * sinTheta;
				let z: number = cosTheta;

				if (mercatorProjectie == true) {
					//
					// S neemt toe van de nul-meridiaan (S = 0) met de klok mee. Van de binnenkant van de bol bekeken 
					// neemt S dus toe van links naar rechts. De rechter- en linkerranden van de textuur (i.e. Noord) komen 
					// op de positieve Y-as te liggen; een kwart van rechts (i.e. Oost) op de positieve X-as.
					//
					s = phiRatio;
				}
				else {
					//
					// Het centrum van de textuur komt op de apex te liggen. De bovenzijde van de textuur (i.e. Noord) komt 
					// op de positieve Y-as te liggen; de rechterzijde (i.e. Oost) komt op de positieve X-as.
					//
					let afstandVanTextuurCentrum: number = 0.5 * thetaRatio;
					s = 0.5 + sinPhi * afstandVanTextuurCentrum;
					t = 0.5 + cosPhi * afstandVanTextuurCentrum;
				}

				vertexData.push(x); // vertexcoordinaten (bol heeft radius 1.0)
				vertexData.push(y);
				vertexData.push(z);
				vertexData.push(-x); // normaal (richting centrum)
				vertexData.push(-y);
				vertexData.push(-z);
				vertexData.push(s); // textuurcoordinaten
				vertexData.push(t);
			}
		}

		let vertexIndices: number[] = [];
		for (let latIndex: number = 0; latIndex < latBands; latIndex++) {
			for (let longIndex: number = 0; longIndex < longBands; longIndex++) {
				let eerste: number = (latIndex * (longBands + 1)) + longIndex;
				let tweede: number = eerste + (longBands + 1);

				vertexIndices.push(eerste);
				vertexIndices.push(tweede);
				vertexIndices.push(eerste + 1);
				vertexIndices.push(tweede);
				vertexIndices.push(tweede + 1);
				vertexIndices.push(eerste + 1);
			}
		}

		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let vertexDataBuffer: WebGLBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ARRAY_BUFFER, vertexDataBuffer);
		gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertexData), gl.STATIC_DRAW);
		let vertexDataStride: number = 8;
		let vertexDataBufferWrap: VLib.VertexDataBufferWrapType = {
			vertexDataBuffer: vertexDataBuffer,
			aantal: vertexData.length / vertexDataStride,
			stride: vertexDataStride * 4, /* sizeof(float) */
			normaalOffset: 3 * 4, /* 3 float * sizeof(float) */
			textuurCoorOffset: 6 * 4 /* 6 floats * sizeof(float) */
		};

		let vertexIndicesBuffer: WebGLBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, vertexIndicesBuffer);
		gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(vertexIndices), gl.STATIC_DRAW);
		let vertexIndicesBufferWrap: VLib.VertexIndicesBufferWrapType = {
			vertexIndicesBuffer: vertexIndicesBuffer,
			aantal: vertexIndices.length
		};

		let skyDomeElem: VLib.FragmentItemType = { // Velden kleur, schitteringMat en tableau worden later (echt) gevuld.
			type: this.privates.defTypeVolume, kleur: undefined,
			vertexDataBufferWrap: vertexDataBufferWrap, vertexIndicesBufferWrap: vertexIndicesBufferWrap
		};
		return skyDomeElem;
	};

	//
	// Maakt de projectie voor de sky dome.
	//
	private maakSkyDomeProjectie(): void {
		let aspectRatio: number = this.privates.glWrap.gl.drawingBufferWidth / this.privates.glWrap.gl.drawingBufferHeight;

		let factor: number = 1.0 + Math.log(this.privates._zoomSchaal) / Math.LN10;
		let fovy: number = 50.0 / factor;
		//
		// Bereken de kleinste afstand van de camera tot de dome. Het dichtstbijzijnde punt dat in beeld is, is te vinden 
		// in één van de hoekpunten van de viewport: 
		//
		let afst: number = Math.tan(0.5 * fovy * VLib.Lib._vanDegNaarRad);
		afst = 1.0 / (1.0 + afst * afst * (1.0 + aspectRatio * aspectRatio));
		afst = Math.min(afst, 0.99);
		//
		// NB: De dome is gesegmenteerd, vandaar dat ten behoeve van het (zeldzame) geval van een telephotocamerahoek een 
		// veiligheidsmarge van 1% wordt toegepast.
		//
		let far: number = 1.0;
		let near: number = afst * far;
		mat4.perspective(fovy, aspectRatio, near, far, this.privates.hemelProjectieMatrix);
	};

	//
	// Maakt de modelview (de positie van het oogpunt) voor de sky dome.
	//
	private maakSkyDomeModelview(): void {
		let modelviewMatrix: number[] = this.privates.hemelModelviewMatrix
		//
		// NB: Zie de functie stelModelviewMatrixIn_* voor meer informatie over de toegepaste rotaties.
		//
		mat4.identity(modelviewMatrix);
		mat4.rotateY(modelviewMatrix, 0.5 * Math.PI);
		mat4.rotateX(modelviewMatrix, -0.5 * Math.PI);
		mat4.rotateX(modelviewMatrix, -this.privates._rolhoek * VLib.Lib._vanDegNaarRad);
		mat4.rotateY(modelviewMatrix, -this.privates._helling * VLib.Lib._vanDegNaarRad);
		mat4.rotateZ(modelviewMatrix, -this.privates._richting * VLib.Lib._vanDegNaarRad);
		//
		// NB: De rotatiematrix wordt achterwege gelaten worden omdat de sky dome geen belichting benodigd.
		//
	};

	//
	// Bepaalt het dichtstbijzijnde modelfragment onder de aangeleverde viewport-coordinaten.
	// Parameters 'x' en 'y' zijn de viewport-coordinaten van het picking-punt.
	// Parameter 'fragmentOrigin': only fragments with this origin can be selected.
	// Parameter 'xRayVision': if true: fragments with other origin are considered transparent.
	// Return-waarde is een object met de ID-string en index van het gepickte modelfragment, of undefined resp. -1 
	// wanneer er geen fragment onder de coordinaten ligt; of null wanneer picking niet geslaagd is.
	//
	private bepaalPicking(x: number, y: number, fragmentOrigin: VLib.eFragmentOrigin, xRayVision: boolean): VLib.PickingType {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		if (VLib.Lib.isGetal(x) == false || x < 0 || x >= gl.drawingBufferWidth ||
			VLib.Lib.isGetal(y) == false || y < 0 || y >= gl.drawingBufferHeight) {
			return null;
		}

		if (this.privates.fragmenten.length == 0) {
			//
			// Het model is leeg:
			//
			return { fragmentIndex: -1, fragmentID: undefined, transLijstIndex: undefined };
		}

		let vorigeClearColor: number[];
		try {
			//
			// Activeer het shader program voor picking:
			//
			this.privates.shaderProgramWrap = this.privates.shaderProgramPickingWrap;
			gl.useProgram(this.privates.shaderProgramWrap.shaderProgram);
			//
			// Maak een framebuffer om in te tekenen:
			//
			// NB: Sommige implementaties (in het bijzonder op tablets) blijken niet correct om te gaan met renderbuffers, 
			// hetzij bij het schrijven in de beschikbare color-renderable formats (RGBA4, RGB565, RGB5_A1), dan wel bij 
			// het uitlezen door readPixels. Om die reden wordt hier een texture in plaats van een renderbuffer gebruikt.
			//
			let pickingFBO: VLib.WebGLFramebufferWrapType = this.maakFBO(gl.drawingBufferWidth, gl.drawingBufferHeight, true, false,
				'picking');

			//
			// Laat de achtergrond vullen met de kleur 0xFFFFFF die overeen komt met 'no-pick':
			//
			vorigeClearColor = gl.getParameter(gl.COLOR_CLEAR_VALUE);
			gl.clearColor(1.0, 1.0, 1.0, 1.0);
			gl.enable(gl.SCISSOR_TEST);
			gl.scissor(x, y, 1, 1);

			//
			// Teken de scene. Elk modelfragment wordt getekend met een kleur overeenkomstig met de (gecodeerde) index 
			// van het fragment in de fragmentenlijst:
			//
			this.tekenPickingScene(fragmentOrigin, xRayVision);

			//
			// Bepaal de picking:
			//
			let pickKleur: Uint8Array = new Uint8Array(4);
			gl.readPixels(x, y, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, pickKleur);
			let fragmentIndex: number = pickKleur[0] + (pickKleur[1] << 8) + (pickKleur[2] << 16);
			if (fragmentIndex == 0xFFFFFF) {
				fragmentIndex = -1;
			}
			let pick: VLib.PickingType = { fragmentIndex: fragmentIndex, fragmentID: undefined, transLijstIndex: undefined };

			if (fragmentIndex != -1) {
				let fragment: VLib.FragmentType = this.privates.fragmenten[fragmentIndex];
				pick.fragmentID = fragment.ID;

				if (fragment.transLijst) {
					//
					// Teken de het fragment. Elke transformatie ervan wordt getekend met een kleur overeenkomstig met de 
					// (gecodeerde) index van de transformatie in de transformatielijst:
					//
					this.tekenPickingFragmentMetTransLijst(fragment);

					//
					// Bepaal de picking:
					//
					gl.readPixels(x, y, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, pickKleur);
					let transLijstIndex: number = pickKleur[0] + (pickKleur[1] << 8) + (pickKleur[2] << 16);
					pick.transLijstIndex = transLijstIndex;
				}
			}

			//
			// Ruim de framebuffer op:
			//
			this.wisFBO(pickingFBO);

			return pick;
		}
		catch (exc) {
			this.conditionalLog('Picking kon niet uitgevoerd worden. Foutdetails: ' + exc);
			return null;
		}
		finally {
			gl.disable(gl.SCISSOR_TEST);
			gl.clearColor(vorigeClearColor[0], vorigeClearColor[1], vorigeClearColor[2], vorigeClearColor[3]);
			//
			// Heractiveer het standaard shader program:
			//
			this.privates.shaderProgramWrap = this.privates.shaderProgramStdWrap;
			gl.useProgram(this.privates.shaderProgramWrap.shaderProgram);
		}
	};

	//
	// Tekent een scene van de view ten behoeve van selectie.
	// Parameter 'fragmentOrigin': only fragments with this origin can be selected.
	// Parameter 'xRayVision': if true: fragments with other origin are considered transparent.
	//
	private tekenPickingScene(fragmentOrigin: VLib.eFragmentOrigin, xRayVision: boolean): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

		gl.uniformMatrix4fv(this.privates.shaderProgramWrap.projectieMatrixUniform, false, this.privates.projectieMatrix);
		gl.uniformMatrix4fv(this.privates.shaderProgramWrap.modelviewMatrixUniform, false, this.privates.modelviewMatrix);

		let fragmenten: VLib.FragmentType[] = this.privates.fragmenten;
		for (let i: number = 0; i < fragmenten.length; i++) {
			let fragment: VLib.FragmentType = fragmenten[i];
			if (fragment.moetGetekend && !fragment.hideWhenPicking) {
				if (fragmentOrigin == fragment.fragmentOrigin) {
					let pickingKleur: number[] = [(i & 0x0000FF), (i & 0x00FF00) >> 8, (i & 0xFF0000) >> 16, 255];
					this.tekenPickingItems(fragment.itemsOpaak, fragment.itemsTransparant, fragment.transLijst, pickingKleur);
				}
				else if (!xRayVision) {
					let pickingKleur: number[] = [255, 255, 255, 255]; // no-pick color
					this.tekenPickingItems(fragment.itemsOpaak, fragment.itemsTransparant, fragment.transLijst, pickingKleur);
				}
				//
				// ELSE: don't call this.tekenPickingItems. So this fragment is transparent (or invisible).
				// Now it's possible to select a (part of a) fragment with fragmentOrigin that is normally hidden behind the current fragment.
				//
			}
		}
	};

	//
	// Tekent een modelfragment met een transformatielijst ten behoeve van selectie.
	// Parameter 'fragment' is een fragment met een transformatielijst.
	//
	private tekenPickingFragmentMetTransLijst(fragment: VLib.FragmentType): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;

		gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

		let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
		let tekenActieFunctie: VLib.TekenActieFunctieType = function (transLijstIndexHuidig: number): void {
			let pickingKleur: number[] = [(transLijstIndexHuidig & 0x0000FF), (transLijstIndexHuidig & 0x00FF00) >> 8,
				(transLijstIndexHuidig & 0xFF0000) >> 16, 255];

			let items: VLib.FragmentItemType[] = fragment.itemsOpaak;
			if (items) {
				for (let i: number = 0; i < items.length; i++) {
					_this.tekenPickingItem(items[i], pickingKleur);
				}
			}
			items = fragment.itemsTransparant;
			if (items) {
				for (let i: number = 0; i < items.length; i++) {
					_this.tekenPickingItem(items[i], pickingKleur);
				}
			}
		};
		this.tekenMetTransLijst(tekenActieFunctie, fragment.transLijst, null, false, false);
	};

	//
	// Tekent een aantal items van een modelfragment ten behoeve van selectie.
	// Parameter 'items' is een array met te tekenen items. Mag null zijn.
	// Parameter 'meerItems' is nog een array met te tekenen items. Mag null zijn.
	// Parameter 'transLijst' is de te gebruiken transformatielijst. Mag null zijn.
	// Parameter 'pickingKleur' is de picking-kleur waarmee de items getekend moeten worden.
	//
	private tekenPickingItems(items: VLib.FragmentItemType[], meerItems: VLib.FragmentItemType[], transLijst: VLib.TransLijstType, pickingKleur: number[]): void {
		if (items || meerItems) {
			if (transLijst) {
				let _this: Viewer3D = this; // Tbv functiedefinitie binnen deze functie.
				let tekenActieFunctie: VLib.TekenActieFunctieType = function (transLijstIndex: number): void { // Parameter transLijstIndex wordt niet gebruikt.
					if (items) {
						for (let i: number = 0; i < items.length; i++) {
							_this.tekenPickingItem(items[i], pickingKleur);
						}
					}
					if (meerItems) {
						for (let i: number = 0; i < meerItems.length; i++) {
							_this.tekenPickingItem(meerItems[i], pickingKleur);
						}
					}
				};
				this.tekenMetTransLijst(tekenActieFunctie, transLijst, null, false, false);
			}
			else {
				if (items) {
					for (let i: number = 0; i < items.length; i++) {
						this.tekenPickingItem(items[i], pickingKleur);
					}
				}
				if (meerItems) {
					for (let i: number = 0; i < meerItems.length; i++) {
						this.tekenPickingItem(meerItems[i], pickingKleur);
					}
				}
			}
		}
	};

	//
	// Tekent een item van een modelfragment ten behoeve van selectie.
	// Parameter 'item' is het te tekenen item.
	// Parameter 'pickingKleur' is de picking-kleur waarmee het item getekend moet worden.
	//
	private tekenPickingItem(item: VLib.FragmentItemType, pickingKleur: number[]): void {
		let gl: WebGLRenderingContext = this.privates.glWrap.gl;
		let shaderProgramWrap: VLib.WebGLProgramWrapType = this.privates.shaderProgramWrap;

		gl.vertexAttrib4fv(shaderProgramWrap.kleurAttribute, pickingKleur);

		gl.bindBuffer(gl.ARRAY_BUFFER, item.vertexDataBufferWrap.vertexDataBuffer);
		gl.vertexAttribPointer(shaderProgramWrap.vertexCoorAttribute, 3 /* x-, y-, z-coordinaten */, gl.FLOAT,
			false, item.vertexDataBufferWrap.stride, 0);

		let aangezetTextuurCoorAttribute: boolean = false;
		let uitgezetCullFace: boolean = false;

		if (item.type === this.privates.defTypeVolume || item.type === this.privates.defTypeVlak) {
			if (item.tableau && item.tableau.heeftAlpha === true) {
				gl.uniform1i(shaderProgramWrap.tableauVormTexturingUniform, VLib.Lib._webGLTrue);

				gl.activeTexture(gl.TEXTURE0);
				gl.bindTexture(gl.TEXTURE_2D, item.tableau.tex);

				gl.vertexAttribPointer(shaderProgramWrap.textuurCoorAttribute, 2 /* s-, t-coordinaten */, gl.FLOAT,
					false, item.vertexDataBufferWrap.stride, item.vertexDataBufferWrap.textuurCoorOffset);
				gl.enableVertexAttribArray(shaderProgramWrap.textuurCoorAttribute);
				aangezetTextuurCoorAttribute = true;

				if (item.type === this.privates.defTypeVolume) {
					gl.disable(gl.CULL_FACE);
					uitgezetCullFace = true;
				}
			}

			if (item.type === this.privates.defTypeVlak) {
				gl.uniform1i(shaderProgramWrap.elemTypeUniform, this.privates.defTypeVlak);

				gl.disable(gl.CULL_FACE);
				gl.drawArrays(gl.TRIANGLES, 0, item.vertexDataBufferWrap.aantal);
				gl.enable(gl.CULL_FACE);
			}
			else // if (item.elemType === this.privates.defTypeVolume)
			{
				gl.uniform1i(shaderProgramWrap.elemTypeUniform, this.privates.defTypeVolume);

				gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, item.vertexIndicesBufferWrap.vertexIndicesBuffer);
				gl.drawElements(gl.TRIANGLES, item.vertexIndicesBufferWrap.aantal, gl.UNSIGNED_SHORT, 0);
			}

			if (aangezetTextuurCoorAttribute) {
				gl.disableVertexAttribArray(shaderProgramWrap.textuurCoorAttribute);
				gl.uniform1i(shaderProgramWrap.tableauVormTexturingUniform, VLib.Lib._webGLFalse);

				if (uitgezetCullFace) {
					gl.enable(gl.CULL_FACE);
				}
			}
		}
		else if (item.type === this.privates.defTypeLijn) {
			gl.uniform1i(shaderProgramWrap.elemTypeUniform, this.privates.defTypeLijn);

			gl.drawArrays(gl.LINES, 0, item.vertexDataBufferWrap.aantal);
		}
		else if (item.type === this.privates.defTypeMarkeerpunt) {
			gl.uniform1i(shaderProgramWrap.elemTypeUniform, this.privates.defTypeMarkeerpunt);

			gl.uniform3fv(shaderProgramWrap.elemPositieUniform, item.positie);
			gl.uniform1f(shaderProgramWrap.zoomSchaalUniform, this.privates._zoomSchaal);

			gl.drawArrays(gl.LINE_STRIP, 0, item.vertexDataBufferWrap.aantal);
		}

	};

	//
	// Ververst de huidige selectie en rapporteert de veranderingen.
	// Parameter 'selectieIDs' is een array van selectie-ID's.
	// Parameter 'meldVeranderend' is een boolean die aangeeft of de aanstaande verandering gerapporteerd moet worden 
	// (evenals de definitieve verandering).
	// Optionele parameter 'veroorzaaktDoorGebruiker' is een boolean die aangeeft of de selectieverandering door de 
	// gebruiker is veroorzaakt (in tegenstelling tot via de API).
	//
	private updateSelectieEnMeld(selectieIDs: V3DSelectieIDType[], meldVeranderend: boolean, veroorzaaktDoorGebruiker?: boolean): void {
		if (this.privates.selectieLock !== false) {
			//
			// De verwerking van een nieuwe selectie is in gang, of er is een operatie bezig die niet door selectie 
			// onderbroken mag worden:
			//
			return;
		}
		//
		// Verwerp elke nieuwe selectie:
		//
		this.privates.selectieLock = true;

		if (veroorzaaktDoorGebruiker !== true) {
			veroorzaaktDoorGebruiker = false;
		}
		if (meldVeranderend === true) {
			//
			// Meldt dat de selectie op het punt staat te veranderen:
			//
			if (this.privates.selectieVeranderendCallback) {
				try {
					if (this.privates.selectieVeranderendCallback(selectieIDs, veroorzaaktDoorGebruiker) === false) {
						//
						// De nieuwe selectie is geannuleerd:
						//
						this.privates.selectieLock = false;
						return;
					}
					//else
					//
					// De nieuwe selectie is geaccepteerd, maar kan aangepast zijn.
					//
				}
				catch (exc) {
					this.conditionalLog('Fout opgetreden in selectie-veranderend callback. ' +
						'De selectie is geannuleerd. Foutdetails: ' + exc);
					this.privates.selectieLock = false;
					return;
				}
			}
		}
		//
		// Leg de selectie vast:
		//
		this.updateSelectie(selectieIDs);
		//
		// Meldt dat de selectie veranderd is:
		//
		if (this.privates.selectieVeranderdCallback) {
			try {
				selectieIDs = this.selectie;
				this.privates.selectieVeranderdCallback(selectieIDs, veroorzaaktDoorGebruiker);
			}
			catch (exc) {
				this.conditionalLog('Fout opgetreden fout in selectie-veranderd callback. Foutdetails: ' + exc);
			}
		}

		//
		// Sta nieuwe selecties weer toe:
		//
		this.privates.selectieLock = false;
	};

	//
	// Valideert, corrigeert en ververst de huidige selectie.
	// Parameter 'selectieIDs' is een array van selectie-ID's en wordt mogelijk aangepast door ongeldige items te 
	// corrigeren of te verwijderen.
	//
	private updateSelectie(selectieIDs: V3DSelectieIDType[]): void {
		//
		// Geldig invoer voor een selectie-ID heeft een van de volgende vormen:
		//   1) object met de structuur { fragmentID : string };
		//   2) object met de structuur { fragmentID : string, transLijstIndices : [integers] }.
		// 
		// Voor intern gebruik kan afgeleide informatie worden toegevoegd. 
		//
		let selectieIDsVoorgesteld: V3DSelectieIDType[] = selectieIDs;
		let selectieIDsNieuw: VLib.SelectieIDInternType[] = [];
		for (let i: number = 0; i < selectieIDsVoorgesteld.length; i++) {
			let vsID: V3DSelectieIDType = selectieIDsVoorgesteld[i];
			let fragmentID: string = vsID.fragmentID;

			let nsID: VLib.SelectieIDInternType;
			if (typeof (fragmentID) == 'string') {
				//
				// Er kan eerder een aangeleverd selectie-ID met dezelfde fragment-ID verwerkt zijn:
				//
				nsID = this.findInSelectie(selectieIDsNieuw, fragmentID);
				if (!nsID) {
					for (let j: number = 0; j < this.privates.fragmenten.length; j++) {
						let fragment: VLib.FragmentType = this.privates.fragmenten[j];
						if (fragment.ID == fragmentID) {
							nsID = {
								fragmentID: fragmentID,
								fragmentIndex: j,
								transLijstAantal: (fragment.transLijst ? fragment.transLijst.aantal : 0)
							};
							//
							// NB: Door het ontbreken van de property 'transLijstIndices' wordt aangegeven dat alle 
							// transformaties in de transformatielijst, indien aanwezig, impliciet geselecteerd zijn. 
							// Verderop wordt deze aanname mogelijk gerectificeerd door middel van een array met de indices 
							// van de geselecteerde transformaties in de transformatielijst.
							//
							selectieIDsNieuw.push(nsID);
							break;
						}
					}
				}
			}
			if (!nsID) {
				//
				// Het is geen ID-string of het komt niet als fragment-ID in het model voor:
				//
				continue;
			}

			if (nsID.transLijstAantal > 0) {
				if (nsID.transLijstIndices && nsID.transLijstIndices.length == nsID.transLijstAantal) {
					//
					// Alle transformaties in de transformatielijst zijn al (expliciet) geselecteerd.
					//
				}
				else {
					let vsTransLijstIndices: number[] = vsID.transLijstIndices;
					if (vsTransLijstIndices) {
						if (VLib.Lib.isGetalLijst(vsTransLijstIndices, vsTransLijstIndices.length, 0, nsID.transLijstAantal - 1)) {
							if (nsID.transLijstIndices) {
								//
								// Er is eerder een aangeleverd selectie-ID met dezelfde fragment-ID verwerkt. Merge de array 
								// van indices met de bestaande array:
								//
								vsTransLijstIndices = vsTransLijstIndices.concat(nsID.transLijstIndices);
							}
							let sorteerIndicesFunctie: VLib.SorteerNumbersFunctieType = function (a: number, b: number): number {
								return a - b;
							};
							vsTransLijstIndices.sort(sorteerIndicesFunctie); // sorteer indices
							let filterDoubluresFunctie: VLib.FilterNumberDoubluresFunctieType = function (elem: number, pos: number, arr: number[]): boolean {
								return pos == 0 || elem != arr[pos - 1];
							}
							vsTransLijstIndices = vsTransLijstIndices.filter(filterDoubluresFunctie); // verwijder doublures
							nsID.transLijstIndices = vsTransLijstIndices; // een array met gesorteerde natuurlijke getallen
						}
					}
					else {
						//
						// Alle transformaties in de transformatielijst dienen te worden geselecteerd. Dit wordt voorlopig 
						// aangegeven met een array van alle indices (hoewel de daadwerkelijke waarden niet van belang zijn, 
						// alleen de juiste lengte is betekenisvol):
						//
						vsTransLijstIndices = [];
						vsTransLijstIndices.length = nsID.transLijstAantal
						nsID.transLijstIndices = vsTransLijstIndices;
					}
				}
			}
		}

		for (let i: number = 0; i < selectieIDsNieuw.length; i++) {
			let nsID: VLib.SelectieIDInternType = selectieIDsNieuw[i];
			if (nsID.transLijstIndices && nsID.transLijstIndices.length == nsID.transLijstAantal) {
				//
				// Alle transformaties in de transformatielijst dienen te worden geselecteerd. Dit wordt aangegeven door 
				// middel van de afwezigheid van een array van indices:
				//
				delete nsID.transLijstIndices;
			}
		}

		//
		// Leg de nieuwe selectie vast:
		//
		this.privates.selectieIDs = selectieIDsNieuw;
	};

	//
	// Zoekt het element met de gegeven fragment-ID in de aangeleverde selectie.
	// Parameter 'selectieIDs' is een array van selectie-ID objecten.
	// Parameter 'fragmentID' is de ID-string van het te zoeken modelfragment.
	// Return-waarde is het gevonden element, of null wanneer het gezochte element niet aanwezig is.
	//
	private findInSelectie(selectieIDs: VLib.SelectieIDInternType[], fragmentID: string): VLib.SelectieIDInternType {
		//
		// NB: Deze functie is in feite een polyfill voor de browsers die de functie Array.prototype.find nog niet 
		// ondersteunen.
		//
		for (let i: number = 0; i < selectieIDs.length; i++) {
			let selectieID: VLib.SelectieIDInternType = selectieIDs[i];
			if (selectieID.fragmentID === fragmentID) {
				return selectieID;
			}
		}
		return null;
	};

	//
	// Zoekt de index van het element met de gegeven fragment-ID in de aangeleverde selectie.
	// Parameter 'selectieIDs' is een array van selectie-ID objecten.
	// Parameter 'fragmentID' is de ID-string van het te zoeken modelfragment.
	// Return-waarde is de index van het gevonden element, of -1 wanneer het gezochte element niet aanwezig is.
	//
	private findIndexInSelectie(selectieIDs: VLib.SelectieIDInternType[], fragmentID: string): number {
		//
		// NB: Deze functie is in feite een polyfill voor de browsers die de functie Array.prototype.findIndex nog niet 
		// ondersteunen.
		//
		for (let i: number = 0; i < selectieIDs.length; i++) {
			if (selectieIDs[i].fragmentID === fragmentID) {
				return i;
			}
		}
		return -1;
	};

	//
	// Laadt tekst uit een bestand.
	// Parameter 'bestandURL' is de bestandsnaam met pad van het tekstbestand.
	// Parameter 'geladenCallback' is een callback die wordt aangeroepen zodra het laden van het tekstbestand gereed is. 
	// De callback heeft één parameter: de geladen tekst, of null wanneer het laden niet geslaagd is.
	//
	private loadTextFromFile(bestandURL: string, geladenCallback: VLib.GeladenTekstCallbackType): void {
		if (typeof (geladenCallback) == 'function') {
			if (typeof (bestandURL) != 'string') {
				geladenCallback(null);
				return;
			}

			let request: XMLHttpRequest = new XMLHttpRequest();
			let isGeladen: boolean = false;
			request.onreadystatechange = function () {
				if (request.readyState == 4 && isGeladen == false)
				// 4: request finished and response is ready; zie: http://www.w3schools.com/dom/dom_httprequest.asp
				{
					isGeladen = true;
					geladenCallback(request.responseText);
				}
			}
			try {
				request.open('GET', bestandURL, true);
				request.responseType = 'text';
				request.send();
			}
			catch (exc) {
				this.conditionalLog('Bestand "' + bestandURL + '". ' + exc, 2);
				geladenCallback(null);
			}
		}
	};

	//
	// Maakt een melding ten behoeve van debugging, maar alleen wanneer daarom expliciet is verzocht.
	// Parameter 'msgTekst' is de volledige of aanvullende tekst van de boodschap, afhankelijk van de waarde 
	// van 'msgType'.
	// Parameter 'msgType' is het meldingtype. Waarde null toont 'msgTekst' zonder meer.
	//
	private conditionalLog(msgTekst: string, msgType?: number): void {
		if (this.privates.canvasWrap && this.privates.logEnabled) {
			let msg: string = null;
			switch (msgType) {
				case null:
				default:
					msg = msgTekst;
					break;
				case 1:
					msg = 'JSON-tekst kon niet geparsed worden. Foutdetails: ' + msgTekst;
					break;
				case 2:
					msg = 'Tekst kon niet gelezen worden uit bestand. Foutdetails: ' + msgTekst;
					break;
				case 3:
					msg = 'Fragment kon niet geladen worden. Foutdetails: ' + msgTekst;
					break;
				case 4:
					msg = 'Fragment heeft geen ID.';
					break;
				case 5:
					msg = 'ID "' + msgTekst + '" heeft een ongeldig formaat.';
					break;
				case 6:
					msg = 'ID "' + msgTekst + '" bestaat al in het model.';
					break;
				case 7:
					msg = 'ID "' + msgTekst + '" is niet gevonden in het model.';
					break;
				case 8:
					msg = 'Bitmap "' + msgTekst + '" kon niet geladen worden.';
					break;
				case 9:
					msg = 'Bitmap "' + msgTekst + '" heeft afmetingen die geen macht van twee zijn.';
					break;
				case 10:
					msg = 'Bitmap "' + msgTekst + '" kon niet verwerkt worden tot textuur.';
					break;
				case 11:
					msg = 'Transformatielijst is niet geldig. Foutdetails: ' + msgTekst;
					break;
				case 12:
					msg = 'Fragment is niet geldig. Foutdetails: ' + msgTekst;
					break;
			}

			let afz: string = 'WebGLViewer ' +
				(this.privates.canvasWrap.canvas.id ? '\'' + this.privates.canvasWrap.canvas.id + '\'' : '(zonder id)') + ' --- ';
			VLib.Lib.log(afz + msg, 2);
		}
	};

	//
	// Event handlers ///////////////////////////////////////////////////////////////////////////////////////////////////
	//

	//
	// Event handler voor het mouse down-event.
	// Dit event moet gekoppeld worden aan het canvas-element.
	//
	private handleMouseDown: V3DMouseEventHandlerType = (evt: MouseEvent): void => {
		this.handleMouseDownIntern(evt);
	}

	private handleMouseDownIntern(evt: MouseEvent): void {
		VLib.Lib.cancelEvent(evt);

		if (this.privates.canvasWrap && this.navigeerbaar) {
			//this.printMouseEvent("down", evt); // T.b.v. probleem-analyses.
			VLib.Lib.registerEvent(document, 'mousemove', this.handleMouseMove, this.privates.eventListenerOpties);

			let navData: VLib.NavDataType = this.privates.navData;
			let onderbreekAnimatie: boolean = (this.animatie && this.privates.animatieOnderbreken);
			if (onderbreekAnimatie) {
				this.animatie = false;
			}
			navData.animatieOnderbroken = onderbreekAnimatie;
			navData.draaienOmPuntData = undefined;

			navData.muisNeerPositie = [evt.clientX, evt.clientY];
			navData.muisPositie = navData.muisNeerPositie;
			navData.muisIsKlik = true;

			//
			// Geef de focus aan het assisterende element, indien aanwezig, opdat deze toetsenbordinvoer kan ontvangen:
			//
			if (this.privates.canvasWrap.toetsAfvanger) {
				this.privates.canvasWrap.toetsAfvanger.focus();
			}

			switch (this.privates.workModus) {
				case eViewerWorkModus.normal:
				case eViewerWorkModus.createDrawing:
				case eViewerWorkModus.placeLibraryObject: {
					//
					// Ignore mouseDown.
					//
					break;
				}
				case eViewerWorkModus.createBridgeLine:
				case eViewerWorkModus.moveDrawingElements:
				case eViewerWorkModus.deleteShape: 
				case eViewerWorkModus.deleteBridgeLine:
				case eViewerWorkModus.placeOpening:
				case eViewerWorkModus.align: {
					let positie: VLib.PositionType = VLib.Lib.getPosition(this.privates.canvasWrap.canvas, evt.clientX, evt.clientY);
					let pick: VLib.PickingType = this.bepaalPicking(positie.x, (this.privates.canvasWrap.canvas.height - 1) - positie.y, VLib.eFragmentOrigin.editor, false);
					if ((pick != null) && (pick.fragmentIndex >= 0)) {
						if (this.privates.workModus == eViewerWorkModus.align) {
							this.privates.alignModusCallback(pick.fragmentID);
							this.requestNavVerversen();
						}
						else {
							this.privates.selectedEditorFragmentID = pick.fragmentID;
							if (this.privates.editorSelectionChangedCallback) {
								try {
									this.privates.editorSelectionChangedCallback(pick);
								}
								catch (exc) {
									this.conditionalLog('Fout opgetreden fout in editorselectie-veranderd callback. Foutdetails: ' + exc);
								}
							}
						}
					}
					else {
						this.privates.selectedEditorFragmentID = null;
					}
					this.privates.mouseHasMovedForSelectedEditorFragment = false;
					break;
				}
				default: {
					throw "Unknown viewerWorkModus: " + this.privates.workModus + "=" + eViewerWorkModusTags[this.privates.workModus];
					break;
				}
			} // end switch
		}
	};

	//
	// Event handler voor het mouse move-event.
	// Dit event moet gekoppeld worden aan het document.
	//
	private handleMouseMove: V3DMouseEventHandlerType = (evt: MouseEvent): void => {
		this.handleMouseMoveIntern(evt);
	}

	private handleMouseMoveIntern(evt: MouseEvent): void {
		VLib.Lib.cancelEvent(evt);

		let navData: VLib.NavDataType = this.privates.navData;
		if (this.privates.canvasWrap && navData.muisPositie != null) {
			//this.printMouseEvent("move", evt); // T.b.v. probleem-analyses.
			//
			// Bepaal de verandering van de muispositie. Bewegingen naar rechts en naar boven zijn positief:
			//
			let deltaMuisPositieRechts: number = evt.clientX - navData.muisPositie[0];
			let deltaMuisPositieOmhoog: number = navData.muisPositie[1] - evt.clientY;
			let vorigeMuisPositie: number[] = navData.muisPositie;
			navData.muisPositie = [evt.clientX, evt.clientY];

			if (navData.muisIsKlik) {
				navData.muisIsKlik =
					(Math.abs(evt.clientX - navData.muisNeerPositie[0]) < navData.muisDrempel &&
						Math.abs(evt.clientY - navData.muisNeerPositie[1]) < navData.muisDrempel);
				if (navData.muisIsKlik) {
					//
					// De drempel voor het starten van slepen (en daarmee het vervallen van een klik) is nog niet 
					// overschreden:
					//
					return;
				}
			}

			//
			// Het draaien van de camera om een punt kan tijdens het bewegen van de muis beëindigd worden door het 
			// loslaten van een vereiste modifier-toets of juist gestart worden door het indrukken ervan. Stel op voorhand 
			// dat deze navigatiewijze niet (meer) van kracht is, en laat de betreffende afwikkeling dit zonodig 
			// rectificeren:
			//
			let draaienOmPuntData: VLib.DraaienOmPuntDataType = navData.draaienOmPuntData;
			navData.draaienOmPuntData = null;

			let isFirmamentCamera: boolean = this.privates.isFirmamentCamera();

			switch (this.privates.workModus) {
				case eViewerWorkModus.normal: {
					if (evt.ctrlKey && isFirmamentCamera) // met Ctrl-toets ingedrukt
					{
						//
						// Slepen verschuift de view:
						//
						this.verschuif(deltaMuisPositieRechts, deltaMuisPositieOmhoog);
					}
					else {
						//
						// Slepen draait de view:
						//
						let draaiAmpl: number = isFirmamentCamera ? 100 : 25;
						if (evt.shiftKey && isFirmamentCamera) // met Shift-toets ingedrukt
						{
							if (draaienOmPuntData == null) {
								let positie: VLib.PositionType = VLib.Lib.getPosition(this.privates.canvasWrap.canvas, vorigeMuisPositie[0], vorigeMuisPositie[1]);
								draaienOmPuntData = this.initDraaiOm(positie.x, (this.privates.canvasWrap.canvas.height - 1) - positie.y);
							}
							if (draaienOmPuntData) {
								navData.draaienOmPuntData = draaienOmPuntData;
								this.draaiOm(deltaMuisPositieRechts, deltaMuisPositieOmhoog, draaiAmpl, draaienOmPuntData);
							}
						}
						else {
							this.draai(deltaMuisPositieRechts, deltaMuisPositieOmhoog, draaiAmpl);
						}
					}
					this.requestNavVerversen();
					break;
				}
				case eViewerWorkModus.createDrawing: {
					let spacePoint: number[] = this.getSpacePointFromMouseEvent(evt);
					this.privates.createDrawingModusCallback(eMouseEvent.mouseMove, spacePoint, evt.which);
					this.requestNavVerversen();
					break;
				}
				case eViewerWorkModus.createBridgeLine: // Moving the mouse resets the create bridgeLine action.
				case eViewerWorkModus.deleteShape:  // Moving the mouse resets the delete shape action.
				case eViewerWorkModus.deleteBridgeLine: { // Moving the mouse resets the delete bridgeLine action.
					this.privates.selectedEditorFragmentID = null;
					this.privates.mouseHasMovedForSelectedEditorFragment = false;
					break;
				}
				case eViewerWorkModus.moveDrawingElements: {
					if (this.privates.selectedEditorFragmentID != null) {
						let startSpacePoint: number[] = this.getSpacePointFromPoint(this.privates.navData.muisNeerPositie[0], this.privates.navData.muisNeerPositie[1]);

						let spacePoint: number[] = this.getSpacePointFromMouseEvent(evt);
						this.privates.moveDrawingElementsModusMouseMoveCallback(this.privates.selectedEditorFragmentID, spacePoint, startSpacePoint,
							evt.shiftKey, evt.ctrlKey, evt.altKey, this.privates.mouseHasMovedForSelectedEditorFragment);
						this.privates.mouseHasMovedForSelectedEditorFragment = true;
						this.requestNavVerversen();
					}
					break;
				}
				case eViewerWorkModus.align: {
					//
					// Ignore mouseDown.
					//
					break;
				}
				default: {
					throw "Unknown viewerWorkModus: " + this.privates.workModus + "=" + eViewerWorkModusTags[this.privates.workModus];
					break;
				}
			} // end switch
		}
	};

	//
	// Event handler voor het mouse up-event.
	// Dit event moet gekoppeld worden aan het document.
	//
	private handleMouseUp: V3DMouseEventHandlerType = (evt: MouseEvent): void => {
		this.handleMouseUpIntern(evt);
	}

	private handleMouseUpIntern(evt: MouseEvent): void {
		VLib.Lib.cancelEvent(evt);

		let navData: VLib.NavDataType = this.privates.navData;
		if (this.privates.canvasWrap && navData.muisPositie != null) {
			//this.printMouseEvent("up", evt); // T.b.v. probleem-analyses.
			//
			// Stop verschuiving cq. draaing:
			//
			VLib.Lib.unregisterEvent(document, 'mousemove', this.handleMouseMove, this.privates.eventListenerOpties);

			navData.muisPositie = null;

			let didMouseMove = this.privates.mouseHasMovedForSelectedEditorFragment;

			switch (this.privates.workModus) {
				case eViewerWorkModus.normal: {
					if (navData.muisIsKlik && this.privates.selectieToestaan) {
						let positie: VLib.PositionType = VLib.Lib.getPosition(this.privates.canvasWrap.canvas, evt.clientX, evt.clientY);
						this.selecteer(positie.x, (this.privates.canvasWrap.canvas.height - 1) - positie.y, evt.ctrlKey);
					}
					break;
				}
				case eViewerWorkModus.createDrawing: {
					let spacePoint: number[] = this.getSpacePointFromMouseEvent(evt);
					this.privates.createDrawingModusCallback(eMouseEvent.mouseUp, spacePoint, evt.which);
					this.requestNavVerversen();
					break;
				}
				case eViewerWorkModus.createBridgeLine: {
					let spacePoint: number[] = null;
					if (this.privates.selectedEditorFragmentID != null) {
						spacePoint = this.getSpacePointFromMouseEvent(evt);
					}
					this.privates.createBridgeLineModusCallback(this.privates.selectedEditorFragmentID, spacePoint); // Call callback even if there is no fragmentID.
					this.privates.selectedEditorFragmentID = null;
					this.privates.mouseHasMovedForSelectedEditorFragment = false;
					this.requestNavVerversen();
					break;
				}
				case eViewerWorkModus.moveDrawingElements: {
					if (this.privates.movedElementCallback) {
						this.privates.movedElementCallback(this.privates.selectedEditorFragmentID);
					}
					this.privates.selectedEditorFragmentID = null;
					this.privates.mouseHasMovedForSelectedEditorFragment = false;
					break;
				}
				case eViewerWorkModus.deleteShape: 
				case eViewerWorkModus.deleteBridgeLine: {
					if (this.privates.selectedEditorFragmentID != null) {
						if (this.privates.workModus == eViewerWorkModus.deleteShape) {
							this.privates.deleteShapeModusCallback(this.privates.selectedEditorFragmentID);
						}
						else { // eViewerWorkModus.deleteBridgeLine
							this.privates.deleteBridgeLineModusCallback(this.privates.selectedEditorFragmentID);
						}
						this.privates.selectedEditorFragmentID = null;
						this.privates.mouseHasMovedForSelectedEditorFragment = false;
						this.requestNavVerversen();
					}
					break;
				}
				case eViewerWorkModus.placeLibraryObject: {
					let spacePoint: number[] = this.getSpacePointFromMouseEvent(evt);
					this.privates.placeLibraryObjectCallback(spacePoint);
					this.requestNavVerversen();
					break;
				}
				case eViewerWorkModus.placeOpening: {
					if (this.privates.selectedEditorFragmentID != null) {
						let spacePoint: number[] = this.getSpacePointFromMouseEvent(evt);
						this.privates.placeOpeningCallback(this.privates.selectedEditorFragmentID, spacePoint);
						this.requestNavVerversen();
					}
					break;
				}
				case eViewerWorkModus.align: {
					//
					// Ignore mouseUp.
					//
					break;
				}
				default: {
					throw "Unknown viewerWorkModus: " + this.privates.workModus + "=" + eViewerWorkModusTags[this.privates.workModus];
					break;
				}
			} // end switch

			if (navData.animatieOnderbroken) {
				this.animatie = true;
			}

			if (this.privates.endEditorActionCallback) {
				let ignoreClick = this.privates.workModus == eViewerWorkModus.createDrawing ||
										(this.privates.workModus == eViewerWorkModus.moveDrawingElements && !didMouseMove);
				if (!ignoreClick) {
					this.privates.endEditorActionCallback(this.privates.workModus);
				}
			}
		}
	};

	//
	// Returns 3D vector.
	//
	private getSpacePointFromMouseEvent(evt: MouseEvent): number[] {
		return this.getSpacePointFromPoint(evt.clientX, evt.clientY);
	}

	//
	// Returns 3D vector.
	//
	private getSpacePointFromPoint(x: number, y: number): number[] {
		let positie: VLib.PositionType = VLib.Lib.getPosition(this.privates.canvasWrap.canvas, x, y);
		let schermCoor: number[] = [positie.x, (this.privates.canvasWrap.canvas.height - 1) - positie.y]; // Invert Y, stay in range 0..(height-1).
		let spacePoint: number[] = this.converteerVanSchermCoordinaten(schermCoor, true, true);
		return spacePoint;
	}

	//
	// Event handler voor het mouse wheel-event.
	// Dit event moet gekoppeld worden aan het canvas-element.
	//
	private handleMouseWheel: V3DWheelEventHandlerType = (evt: WheelEvent): void => {
		this.handleMouseWheelIntern(evt);
	}

	private handleMouseWheelIntern(evt: WheelEvent): void {
		VLib.Lib.cancelEvent(evt);

		if (this.privates.canvasWrap && this.navigeerbaar) {
			let wheelDelta: number = VLib.Lib.getWheel(evt);
			if (this.privates.cameraType === true) // firmamentcamera
			{
				let positie: VLib.PositionType = VLib.Lib.getPosition(this.privates.canvasWrap.canvas, evt.clientX, evt.clientY);
				this.zoomOp(wheelDelta / 90, positie.x, (this.privates.canvasWrap.canvas.height - 1) - positie.y);
			}
			else // terreincamera
			{
				this.zoom(wheelDelta / 45);
			}

			this.requestNavVerversen();

			//
			// Geef de focus aan het assisterende element, indien aanwezig, opdat deze toetsenbordinvoer kan ontvangen:
			//
			if (this.privates.canvasWrap.toetsAfvanger) {
				this.privates.canvasWrap.toetsAfvanger.focus();
			}
		}
	};

	//
	// Event handler voor het context menu-event.
	// Dit event moet gekoppeld worden aan het canvas-element.
	//
	private handleContextMenu: V3DPointerEventHandlerType = (evt: PointerEvent): void => {
		this.handleContextMenuIntern(evt);
	}

	private handleContextMenuIntern(evt: PointerEvent): void {
		//
		// Onderdruk de verschijning van het standaard context menu:
		//
		VLib.Lib.cancelEvent(evt);
	};

	//
	// Event handler voor het key down-event.
	// Dit event moet gekoppeld worden aan een element dat focus kan ontvangen en precies één canvas-element bevat.
	//
	private handleKeyDown: V3DKeyboardEventHandlerType = (evt: KeyboardEvent): void => {
		this.handleKeyDownIntern(evt);
	}

	private handleKeyDownIntern(evt: KeyboardEvent): void {
		let eventTarget: HTMLElement = <HTMLElement>evt.target; // Het event is in ons geval geregistreerd voor een HTMLElement.
		let targetCanvassen: HTMLCollectionOf<HTMLCanvasElement> = eventTarget.getElementsByTagName('canvas');
        //LOCALONLY: let targetCanvassen: NodeListOf<HTMLCanvasElement> = eventTarget.getElementsByTagName('canvas');
		if (targetCanvassen.length == 1) {
			let targetCanvas: HTMLCanvasElement = targetCanvassen[0];
			if (this.privates.canvasWrap && targetCanvas == this.privates.canvasWrap.canvas) {
				if (this.navigeerbaar == false) {
					return;
				}

				let pressedKey: number = VLib.Lib.getKey(evt);
				let metCtrlToets: boolean = (evt.ctrlKey === true);
				let metShiftToets: boolean = (evt.shiftKey === true);

				let verschuifStap: number = 20; // 20 pixels
				let draaiStap: number = 20; // equivalent van 20 pixels
				let zoomStap: number = 0.1; // 10 percent
				//@@@Q [dit blijkt niet zo te werken in TS] let wandelFunc: VLib.WandelFunctieType = (this.privates.wandelType === 0) ? this.wandel_Stap : this.wandel_Impuls;
				let amplificatie: number = metShiftToets ? 2.0 : 1.0;

				let toetsOnverwerkt: boolean = false;
				if (this.privates.cameraType === true) // firmamentcamera
				{
					let draaiAmpl: number = 100;
					switch (pressedKey) {
						case 37: // left
							{
								if (metCtrlToets == false)
									this.verschuif(verschuifStap, 0);
								else
									this.draai(draaiStap, 0, draaiAmpl);
								break;
							}
						case 39: // right
							{
								if (metCtrlToets == false)
									this.verschuif(-verschuifStap, 0);
								else
									this.draai(-draaiStap, 0, draaiAmpl);
								break;
							}
						case 38: // up
							{
								if (metCtrlToets == false)
									this.verschuif(0, -verschuifStap);
								else
									this.draai(0, -draaiStap, draaiAmpl);
								break;
							}
						case 40: // down
							{
								if (metCtrlToets == false)
									this.verschuif(0, verschuifStap);
								else
									this.draai(0, draaiStap, draaiAmpl);
								break;
							}
						case 100: // numpad-4
							{
								this.draai(draaiStap, 0, draaiAmpl);
								break;
							}
						case 102: // numpad-6
							{
								this.draai(-draaiStap, 0, draaiAmpl);
								break;
							}
						case 104: // numpad-8
							{
								this.draai(0, -draaiStap, draaiAmpl);
								break;
							}
						case 98: // numpad-2
							{
								this.draai(0, draaiStap, draaiAmpl);
								break;
							}
						default:
							{
								toetsOnverwerkt = true;
								break;
							}
					}
				}
				else // terreincamera
				{
					let doorgaan: boolean = true;
					let draaiAmpl: number = 25;
					switch (pressedKey) {
						case 37: // left
							{
								if (metCtrlToets == false)
									doorgaan = this.wandel(4, amplificatie);
								else
									this.draai(-draaiStap, 0, draaiAmpl);
								break;
							}
						case 39: // right
							{
								if (metCtrlToets == false)
									doorgaan = this.wandel(8, amplificatie);
								else
									this.draai(draaiStap, 0, draaiAmpl);
								break;
							}
						case 38: // up
							{
								if (metCtrlToets == false)
									doorgaan = this.wandel(1, amplificatie);
								else
									this.draai(0, draaiStap, draaiAmpl);
								break;
							}
						case 40: // down
							{
								if (metCtrlToets == false)
									doorgaan = this.wandel(2, amplificatie);
								else
									this.draai(0, -draaiStap, draaiAmpl);
								break;
							}
						case 100: // numpad-4
							{
								this.draai(-draaiStap, 0, draaiAmpl);
								break;
							}
						case 102: // numpad-6
							{
								this.draai(draaiStap, 0, draaiAmpl);
								break;
							}
						case 104: // numpad-8
							{
								this.draai(0, draaiStap, draaiAmpl);
								break;
							}
						case 98: // numpad-2
							{
								this.draai(0, -draaiStap, draaiAmpl);
								break;
							}
						case 87: // W
							{
								doorgaan = this.wandel(1, amplificatie);
								break;
							}
						case 65: // A
							{
								doorgaan = this.wandel(4, amplificatie);
								break;
							}
						case 83: // S
							{
								doorgaan = this.wandel(2, amplificatie);
								break;
							}
						case 68: // D
							{
								doorgaan = this.wandel(8, amplificatie);
								break;
							}
						case 81: // Q
							{
								doorgaan = this.wandel(32, amplificatie);
								break;
							}
						case 69: // E
							{
								doorgaan = this.wandel(16, amplificatie);
								break;
							}
						case 16: // Shift
							{
								if (this.privates.wandelType === 1) // voortbewegen met versnelling/vertraging
								{
									this.privates.navData.wandelAmplificatie = amplificatie;
								}
								break;
							}
						default:
							{
								toetsOnverwerkt = true;
								break;
							}
					}
					if (doorgaan === false) {
						VLib.Lib.cancelEvent(evt);
						return;
					}
				}

				switch (pressedKey) {
					case 33: // page-up
					case 107: // add
						{
							this.zoom(zoomStap);
							break;
						}
					case 34: // page-down
					case 109: // subtract
						{
							this.zoom(-zoomStap);
							break;
						}
					case 36: // home
						{
							this.zetZichtStandaard();
							break;
						}
					case 19: // pause/break
						{
							this.animatie = !this.animatie;
							break;
						}
					case 48: // 0
					case 49: // 1
					case 50: // 2
					case 51: // 3
					case 52: // 4
					case 53: // 5
					case 54: // 6
					case 55: // 7
					case 56: // 8
					case 57: // 9
					case 13: // enter
					case 46: // delete
					case 61: // equal
					case 187: // equal
					case 188: // comma (onder <)
					case 190: // period (onder >)
						{
							//
							// Equal-toets heeft code 61 of 187, afhankelijk van de browser. 
							// Zie 'https://github.com/ccampbell/mousetrap/pull/215'.
							//
							if (this.keyPressCallback) {
								this.keyPressCallback(pressedKey);
							}
							else if (toetsOnverwerkt == true) {
								return; // Zonder keyPressCallback verwerken als toetsOnverwerkt==true.
							}
							break;
						}
					default:
						{
							if (toetsOnverwerkt == true) {
								return;
							}
							break;
						}
				}

				VLib.Lib.cancelEvent(evt);

				this.requestNavVerversen();
			}
		}
	};

	//
	// Event handler voor het key up-event.
	// Dit event moet gekoppeld worden aan een element dat focus kan ontvangen en precies één canvas-element bevat.
	//
	private handleKeyUp: V3DKeyboardEventHandlerType = (evt: KeyboardEvent): void => {
		this.handleKeyUpIntern(evt);
	}

	private handleKeyUpIntern(evt: KeyboardEvent): void {
		let eventTarget: HTMLElement = <HTMLElement>evt.target; // Het event is in ons geval geregistreerd voor een HTMLElement.
		let targetCanvassen: HTMLCollectionOf<HTMLCanvasElement> = eventTarget.getElementsByTagName('canvas');
        //LOCALONLY: let targetCanvassen: NodeListOf<HTMLCanvasElement> = eventTarget.getElementsByTagName('canvas');
		if (targetCanvassen.length == 1) {
			let targetCanvas: HTMLCanvasElement = targetCanvassen[0];
			if (this.privates.canvasWrap && targetCanvas == this.privates.canvasWrap.canvas) {
				if (this.navigeerbaar == false) {
					return;
				}

				if (this.privates.cameraType === false) // terreincamera
				{
					let pressedKey: number = VLib.Lib.getKey(evt);

					switch (pressedKey) {
						case 37: // left
						case 65: // A
							{
								this.wandel_Impuls(4, null);
								break;
							}
						case 39: // right
						case 68: // D
							{
								this.wandel_Impuls(8, null);
								break;
							}
						case 38: // up
						case 87: // W
							{
								this.wandel_Impuls(1, null);
								break;
							}
						case 40: // down
						case 83: // S
							{
								this.wandel_Impuls(2, null);
								break;
							}
						case 81: // Q
							{
								this.wandel_Impuls(32, null);
								break;
							}
						case 69: // E
							{
								this.wandel_Impuls(16, null);
								break;
							}
						case 16: // Shift
							{
								this.privates.navData.wandelAmplificatie = 1.0;
								break;
							}
						default:
							{
								return;
							}
					}

					VLib.Lib.cancelEvent(evt);
				}
			}
		}
	};

	//
	// Event handler voor het blur-event.
	// Dit event moet gekoppeld worden aan een element dat focus kan ontvangen en precies één canvas-element bevat.
	//
	private handleBlur: V3DFocusEventHandlerType = (evt: FocusEvent): void => {
		this.handleBlurIntern(evt);
	}

	private handleBlurIntern(evt: FocusEvent): void {
		let eventTarget: HTMLElement = <HTMLElement>evt.target; // Het event is in ons geval geregistreerd voor een HTMLElement.
		let targetCanvassen: HTMLCollectionOf<HTMLCanvasElement> = eventTarget.getElementsByTagName('canvas');
        //LOCALONLY: let targetCanvassen: NodeListOf<HTMLCanvasElement> = eventTarget.getElementsByTagName('canvas');
		if (targetCanvassen.length == 1) {
			let targetCanvas: HTMLCanvasElement = targetCanvassen[0];
			if (this.privates.canvasWrap && targetCanvas == this.privates.canvasWrap.canvas) {
				if (this.navigeerbaar == false) {
					return;
				}

				if (this.privates.cameraType === false) // terreincamera
				{
					this.wandel_Impuls(1 + 2 + 4 + 8 + 16 + 32, null);
				}
			}
		}
	};

	//
	// Event handler voor het touch start-event.
	// Dit event moet gekoppeld worden aan het canvas-element.
	//
	private handleTouchStart: V3DTouchEventHandlerType = (evt: TouchEvent): void => {
		this.handleTouchStartIntern(evt);
	}

	private handleTouchStartIntern(evt: TouchEvent): void {
		VLib.Lib.cancelEvent(evt);

		if (this.privates.canvasWrap && this.navigeerbaar) {
			//this.printToucheEvent("start", evt); // T.b.v. probleem-analyses.
			let navData: VLib.NavDataType = this.privates.navData;
			let onderbreekAnimatie: boolean = (this.animatie && this.privates.animatieOnderbreken);
			if (onderbreekAnimatie) {
				this.animatie = false;
			}
			navData.animatieOnderbroken = onderbreekAnimatie;

			let touch2: Touch;
			if (navData.touch1Identifier == null) {
				//
				// Leg de primaire touch vast:
				//
				let touch1: Touch = evt.changedTouches[0];
				navData.touch1NeerPositie = [touch1.clientX, touch1.clientY];
				navData.touch1Positie = navData.touch1NeerPositie;
				navData.touch1Identifier = touch1.identifier;
				navData.touchIsTap = true;
				navData.touchIsKneep = null;

				if (evt.changedTouches.length > 1) {
					touch2 = evt.changedTouches[1];
				}
			}
			else if (navData.touch2Identifier == null) {
				touch2 = evt.changedTouches[0];
			}
			if (touch2) {
				//
				// Leg de secundaire touch vast:
				//
				navData.touch2NeerPositie = [touch2.clientX, touch2.clientY];
				navData.touch2Positie = navData.touch2NeerPositie;
				navData.touch2Identifier = touch2.identifier;
				navData.touchIsTap = false;
			}

			//
			// Geef de focus aan het assisterende element, indien aanwezig, opdat deze toetsenbordinvoer kan ontvangen:
			//
			if (this.privates.canvasWrap.toetsAfvanger) {
				this.privates.canvasWrap.toetsAfvanger.focus();
			}
		}
	};

	//
	// Event handler voor het touch move-event.
	// Dit event moet gekoppeld worden aan het canvas-element.
	//
	private handleTouchMove: V3DTouchEventHandlerType = (evt: TouchEvent): void => {
		this.handleTouchMoveIntern(evt);
	}

	private handleTouchMoveIntern(evt: TouchEvent): void {
		VLib.Lib.cancelEvent(evt);

		if (this.privates.canvasWrap && this.privates.navData.touch1Identifier != null) {
			//this.printToucheEvent("move", evt); // T.b.v. probleem-analyses.
			//
			// NB: In Firefox (versie 32) wordt het touch-move event niet afgevuurd voor de secundaire touch totdat de 
			// primaire touch tenminste eenmaal bewogen is.
			//
			let navData: VLib.NavDataType = this.privates.navData;
			let touch1: Touch = VLib.Lib.findTouch(navData.touch1Identifier, evt.changedTouches);
			let touch2: Touch;
			if (navData.touch2Identifier != null) {
				touch2 = VLib.Lib.findTouch(navData.touch2Identifier, evt.changedTouches);
			}

			//
			// Bepaal de verandering van de touch-posities. Bewegingen naar rechts en naar boven zijn positief:
			//
			let touch1DeltaRechts: number = 0;
			let touch1DeltaOmhoog: number = 0;
			if (touch1) {
				touch1DeltaRechts = touch1.clientX - navData.touch1Positie[0];
				touch1DeltaOmhoog = navData.touch1Positie[1] - touch1.clientY;
				navData.touch1Positie = [touch1.clientX, touch1.clientY];
			}
			let touch2DeltaRechts: number = 0;
			let touch2DeltaOmhoog: number = 0;
			if (touch2) {
				touch2DeltaRechts = touch2.clientX - navData.touch2Positie[0];
				touch2DeltaOmhoog = navData.touch2Positie[1] - touch2.clientY;
				navData.touch2Positie = [touch2.clientX, touch2.clientY];
			}

			if (touch1DeltaRechts == 0 && touch1DeltaOmhoog == 0 && touch2DeltaRechts == 0 && touch2DeltaOmhoog == 0) {
				//
				// Er heeft geen beweging plaatsgevonden. Touches vuren ook touch-move events af wanneer er geen beweging 
				// plaatsvindt, bijvoorbeeld om veranderingen in toegepaste druk en radius te melden. Voorkom onnodig 
				// hertekenen:
				//
				return;
			}

			if (navData.touchIsTap !== false) {
				if (Math.abs(navData.touch1Positie[0] - navData.touch1NeerPositie[0]) < navData.touchDrempel &&
					Math.abs(navData.touch1Positie[1] - navData.touch1NeerPositie[1]) < navData.touchDrempel) {
					//
					// De drempel voor het starten van slepen (en daarmee het vervallen van een tap) is nog niet 
					// overschreden:
					//
					return;
				}
				else {
					navData.touchIsTap = false;
				}
			}

			let isFirmamentCamera: boolean = this.privates.isFirmamentCamera();
			if (navData.touch2Identifier == null) {
				//
				// Slepen met één touch draait de view:
				//
				let draaiAmpl: number = isFirmamentCamera ? 100 : 25;
				this.draai(touch1DeltaRechts, touch1DeltaOmhoog, draaiAmpl);
			}
			else {
				if (navData.touchIsKneep == null &&
					((Math.abs(navData.touch1Positie[0] - navData.touch1NeerPositie[0]) < navData.touchDrempel &&
						Math.abs(navData.touch1Positie[1] - navData.touch1NeerPositie[1]) < navData.touchDrempel) ||
						(Math.abs(navData.touch2Positie[0] - navData.touch2NeerPositie[0]) < navData.touchDrempel &&
							Math.abs(navData.touch2Positie[1] - navData.touch2NeerPositie[1]) < navData.touchDrempel))) {
					//
					// Er is nog te weinig beweging geweest om de intentie van het slepen te kunnen bepalen:
					//
					return;
				}

				if (navData.touchIsKneep == null) {
					//
					// Bepaal de intentie van het slepen aan de hand van de evenwijdigheid van de bewegingen:
					//
					let touch1Offset: number[] = [navData.touch1Positie[0] - navData.touch1NeerPositie[0],
					navData.touch1Positie[1] - navData.touch1NeerPositie[1]];
					let lengte: number = Math.hypot(touch1Offset[0], touch1Offset[1]);
					if (lengte > VLib.Lib._epsilon) {
						touch1Offset[0] /= lengte;
						touch1Offset[1] /= lengte;
					}
					let touch2Offset: number[] = [navData.touch2Positie[0] - navData.touch2NeerPositie[0],
					navData.touch2Positie[1] - navData.touch2NeerPositie[1]];
					lengte = Math.hypot(touch2Offset[0], touch2Offset[1]);
					if (lengte > VLib.Lib._epsilon) {
						touch2Offset[0] /= lengte;
						touch2Offset[1] /= lengte;
					}
					let inproduct: number = touch1Offset[0] * touch2Offset[0] + touch1Offset[1] * touch2Offset[1];
					//
					// Er is sprake van voldoende evenwijdigheid wanneer de hoek tussen de richtingen van de touch-
					// bewegingen kleiner is dan 60 graden:
					// NB: Wanneer één touch stil zou staat, zou er sprake zijn van een kneep.
					//
					navData.touchIsKneep = (inproduct < 0.5);
				}

				if (navData.touchIsKneep) {
					//
					// Knijpen met twee touches zoomt de view:
					//
					let vorigeTouch2Afstand: number = navData.touch2Afstand;
					let verschilX: number = navData.touch2Positie[0] - navData.touch1Positie[0];
					let verschilY: number = navData.touch2Positie[1] - navData.touch1Positie[1];
					navData.touch2Afstand = Math.hypot(verschilX, verschilY);
					if (vorigeTouch2Afstand == null) {
						return;
					}

					let zoomDelta: number = (navData.touch2Afstand - vorigeTouch2Afstand);
					if (isFirmamentCamera) {
						let gemPositie: VLib.PositionType = VLib.Lib.getPosition(this.privates.canvasWrap.canvas,
							(navData.touch2Positie[0] + navData.touch1Positie[0]) / 2.0,
							(navData.touch2Positie[1] + navData.touch1Positie[1]) / 2.0);
						this.zoomOp(zoomDelta * 0.001, gemPositie.x, (this.privates.canvasWrap.canvas.height - 1) - gemPositie.y);
					}
					else // terreincamera
					{
						this.zoom(zoomDelta * 0.004);
					}
				}
				else {
					if (isFirmamentCamera) {
						//
						// Slepen met twee touches verschuift de view:
						//
						this.verschuif(touch1DeltaRechts, touch1DeltaOmhoog);
					}
					else // terreincamera
					{
						//
						// Slepen met twee touches verplaatst de camera:
						//
						let verplaatsAmpl: number = 0.025 * this.privates.wandelStap;
						let voorwaarts: number[] = VLib.Lib.vanHoekenNaarVector([this.privates.richting, 0]);
						let zijwaarts: number[] = [-voorwaarts[1], voorwaarts[0], 0];
						let nieuwePositie: number[] = this.privates.positie;
						nieuwePositie[0] += (voorwaarts[0] * touch1DeltaOmhoog + zijwaarts[0] * touch1DeltaRechts) *
							verplaatsAmpl;
						nieuwePositie[1] += (voorwaarts[1] * touch1DeltaOmhoog + zijwaarts[1] * touch1DeltaRechts) *
							verplaatsAmpl;
						if (this.setPositie(nieuwePositie, true) === false) {
							return;
						}
					}
				}
			}

			this.requestNavVerversen();
		}
	};

	//
	// Event handler voor het touch move-event.
	// Dit event moet gekoppeld worden aan het canvas-element.
	//
	private handleTouchEnd: V3DTouchEventHandlerType = (evt: TouchEvent): void => {
		this.handleTouchEndIntern(evt);
	}

	private handleTouchEndIntern(evt: TouchEvent): void {
		VLib.Lib.cancelEvent(evt);

		if (this.privates.canvasWrap && this.privates.navData.touch1Identifier != null) {
			//this.printToucheEvent("end", evt); // T.b.v. probleem-analyses.
			let navData: VLib.NavDataType = this.privates.navData;
			let touch1: Touch = VLib.Lib.findTouch(navData.touch1Identifier, evt.changedTouches);
			let touch2: Touch;
			if (navData.touch2Identifier != null) {
				touch2 = VLib.Lib.findTouch(navData.touch2Identifier, evt.changedTouches);
			}

			if (touch1) {
				if (navData.touchIsTap && this.privates.selectieToestaan) {
					let positie: VLib.PositionType = VLib.Lib.getPosition(this.privates.canvasWrap.canvas, navData.touch1NeerPositie[0],
						navData.touch1NeerPositie[1]);
					this.selecteer(positie.x, (this.privates.canvasWrap.canvas.height - 1) - positie.y, true);
					//
					// NB: De modus waarin items toegevoegd worden aan (of verwijderd uit) een multiselectie, welke 
					// gewoonlijk met de ctrl-toets opgeroepen wordt, is permanent geactiveerd.
					//
				}

				if (navData.touch2Identifier != null && touch2 == null) {
					//
					// De primaire touch is beëindigd, maar er is nog een secundaire touch.
					// NB: Deze situatie komt niet voor na selectie.
					// Laat de secundaire touch doorgaan als primaire:
					//
					navData.touch1NeerPositie = navData.touch2Positie;
					navData.touch1Positie = navData.touch1NeerPositie;
					navData.touch1Identifier = navData.touch2Identifier;
					navData.touchIsTap = null; // herstel de sleepdrempel (zonder mogelijkheid op een tap)
					navData.touchIsKneep = null;
				}
				else {
					//
					// De primaire touch is beëindigd (en mogelijk de secundaire ook).
					//
					navData.touch1NeerPositie = null;
					navData.touch1Positie = null;
					navData.touch1Identifier = null;

					if (navData.animatieOnderbroken) {
						this.animatie = true;
					}
				}
				navData.touch2NeerPositie = null;
				navData.touch2Positie = null;
				navData.touch2Afstand = null;
				navData.touch2Identifier = null;
			}
			else if (touch2) {
				//
				// De secundaire touch is beëindigd.
				//
				navData.touch1NeerPositie = navData.touch1Positie;
				navData.touchIsTap = null; // herstel de sleepdrempel (zonder mogelijkheid op een tap)
				navData.touch2NeerPositie = null;
				navData.touch2Positie = null;
				navData.touch2Afstand = null;
				navData.touch2Identifier = null;
			}
		}
	};

	//
	// Laat de view verversen na een navigatie-event.
	//
	public requestNavVerversen(): void {
		if (this.privates.navData.verversenAanhangig == false && this.privates.isGelust == false) {
			this.privates.navData.verversenAanhangig = true;
			//
			// Bij probleem-analyse kan het soms helpen om this.handleNavVerversen hier rechtstreeks (synchroon) aan te roepen.
			// Dus zonder window.requestAnimationFrame te gebruiken.
			//
			window.requestAnimationFrame(this.handleNavVerversen);
		}
	};

	//
	// Ververst de view na een navigatie-event.
	// Wordt asynchroon aangeroepen.
	//
	private handleNavVerversen: V3DSimpelCallbackType = (): void => {
		this.handleNavVerversenIntern();
	}

	private handleNavVerversenIntern(): void {
		if (this.privates.navData.verversenAanhangig) {
			this.toonModel();
			this.privates.navData.verversenAanhangig = false;
		}
	};

	//
	// Event handler voor het WebGL context lost-event.
	// Dit event moet gekoppeld worden aan het canvas-element.
	//
	private handleWebGLContextLost: V3DWebGLContextEventHandlerType = (evt: WebGLContextEvent): void => {
		this.handleWebGLContextLostIntern(evt);
	}

	private handleWebGLContextLostIntern(evt: WebGLContextEvent): void { // Parameter evt wordt niet gebruikt.
		this.conditionalLog('WebGL context is verloren. De viewer is gestaakt.');
		let canvas: HTMLCanvasElement = this.privates.canvasWrap.canvas;
		this.viewer3DManager.verwijder(canvas);
		this.privates.canvasWrap = undefined;
		this.privates.glWrap = undefined;

		//
		// Meld dat de viewer gestaakt is:
		//
		if (this.privates.berichtCallback) {
			this.privates.berichtCallback(canvas, 1, 'viewer gestaakt'); // Zie comment bij set-property: berichtCallback.
		}
	};

	//
	// Handler voor de afwikkeling van een verandering van de afmetingen van het canvas-element.
	//
	public onResize(): void { //@@@Q1 Naam gewijzigd, was 'handleResize'. Wegens oorspronkelijke JS-regel: "this.onResize = handleResize".
		if (this.privates.canvasWrap) {
			if (this.privates.glWrap.drawingBufferAutoSize === false) {
				this.zetSpecifiekeGLProperties(this.privates.glWrap.gl, this.privates.canvasWrap.canvas); // NB: Zie de opmerking in die functie.
			}

			this.requestNavVerversen();
		}
	};

	//
	// Test-functie, t.b.v. probleem-analyses.
	// Callers naar behoeven in/uit-commentarieren.
	// Parameter tag: "start"/"move"/"end".
	//
	private printToucheEvent(tag: string, evt: TouchEvent): void { // Normaliter niet in gebruik.
		let printStr: string = "touch " + tag + " |";
		for (let i: number = 0; i < evt.changedTouches.length; i++) {
			let touch: Touch = evt.changedTouches[i];
			printStr += "id=" + touch.identifier + ",x=" + touch.clientX + ",y=" + touch.clientY + "|";
		}
		console.log(printStr);
	}

	//
	// Test-functie, t.b.v. probleem-analyses.
	// Callers naar behoeven in/uit-commentarieren.
	// Parameter tag: "down"/"move"/"up".
	//
	private printMouseEvent(tag: string, evt: MouseEvent): void { // Normaliter niet in gebruik.
		let printStr: string = "mouse " + tag + " |x=" + evt.clientX + ",y=" + evt.clientY + ",ctrl=" + evt.ctrlKey + "|";
		console.log(printStr);
	}

	public showOrientation(tag: string) { // For debugging
		this.privates.showOrientation(tag);
	}

} // class Viewer3D

//
// Class voor een belichting-object.
//
class Belichting {

	constructor() {
		//
		// Bewust leeg.
		//
	}

	//
	// Openbaar API-accessors:
	//

	//
	// Set-property: lichtval.
	// Stelt de richting van het invallend licht van de lichtbron in. Parameter 'value' is een array van floats met
	// lengte 3 (primaire, secundaire en tertiaire componenten van het generieke lichtbronassenstelsel).
	//
	public set lichtval(value: number[]) {
		if (VLib.Lib.isVector(value, false)) {
			this._lichtval = value.slice();
			this.bepaalLichtvalF();
			this._isVuil = true;
		}
	}
	//
	// Get-property: lichtval.
	// Geeft de richting van het invallend licht van de lichtbron in. Return-waarde is een array van floats met
	// lengte 3 (primaire, secundaire en tertiaire componenten van het generieke lichtbronassenstelsel).
	//
	public get lichtval(): number[] {
		return this._lichtval.slice();
	}
	//
	// Set-property: isCameraLicht.
	// Stelt in of de lichtval gedefinieerd is in het coordinatenstelsel van het model (false) of ten opzichte van de
	// camera, oftewel ten opzichte van het scherm (true).
	// Parameter 'value' is een boolean.
	//
	public set isCameraLicht(value: boolean) {
		if (typeof (value) == 'boolean' && value != this._isCameraLicht) { // M.b.t. de typecheck: zie note (1) bovenin deze file.
			this._isCameraLicht = value;
			this.bepaalLichtvalF();
			this._isVuil = true;
		}
	}
	//
	// Get-property: isCameraLicht.
	// Geeft aan of of de lichtval gedefinieerd is in het coordinatenstelsel van het model (false) of ten opzichte van
	// de camera, oftewel ten opzichte van het scherm (true).
	// Return-waarde is een boolean.
	//
	public get isCameraLicht(): boolean {
		return this._isCameraLicht;
	}
	//
	// Stelt de relatieve sterkte van het omgevinglicht, een percentage van de nominale maximale lichtsterkte, in.
	// Parameter 'value' is een float in het bereik [0, 10].
	//
	public set omgevinglichtSterkte(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._omgevinglichtSterkte) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 10.0);
			this._omgevinglichtSterkte = value;
			this._omgevingLichtNiveau = value;
			this._isVuil = true;
		}
	}
	//
	// Get-property: omgevinglichtSterkte.
	// Geeft de relatieve sterkte van het omgevinglicht.
	// Return-waarde is een float.
	//
	public get omgevinglichtSterkte(): number {
		return this._omgevinglichtSterkte;
	}
	//
	// Set-property: lichtbronSterkte.
	// Stelt de relatieve sterkte van de lichtbron, een percentage van de nominale maximale lichtsterkte, in.
	// Parameter 'value' is een float in het bereik [0, 10].
	//
	public set lichtbronSterkte(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._lichtbronSterkte) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 10.0);
			this._lichtbronSterkte = value;
			this._diffuusLichtNiveau = value * (1.0 - this._lichtSchittering);
			this._schitteringLichtNiveau = value * this._lichtSchittering;
			this._isVuil = true;
		}
	}
	//
	// Get-property: lichtbronSterkte.
	// Geeft de relatieve sterkte van de lichtbron.
	// Return-waarde is een float.
	//
	public get lichtbronSterkte(): number {
		return this._lichtbronSterkte;
	}
	//
	// Set-property: lichtSchittering.
	// Stelt het percentage van het licht van de lichtbron dat schitterend weerkaatst wanneer het op een oppervlak valt
	// in. Deze waarde is het complement van het percentage van het licht van de lichtbron dat verstrooid weerkaatst.
	// Parameter 'value' is een float in het bereik [0, 1].
	//
	public set lichtSchittering(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._lichtSchittering) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 1.0);
			this._lichtSchittering = value;
			this._diffuusLichtNiveau = this._lichtbronSterkte * (1.0 - value);
			this._schitteringLichtNiveau = this._lichtbronSterkte * value;
			this._isVuil = true;
		}
	}
	//
	// Get-property: lichtSchittering.
	// Geeft het percentage van het licht van de lichtbron dat schitterend weerkaatst wanneer het op een oppervlak valt.
	// Return-waarde is een float.
	//
	public get lichtSchittering(): number {
		return this._lichtSchittering;
	}
	//
	// Set-property: toonSlagschaduw.
	// Stelt in of er slagschaduw (schaduw die een voorwerp werpt op andere voorwerpen, dit in tegenstelling tot eigen
	// schaduw) getekend moet worden of niet.
	// Parameter 'value' is een boolean.
	//
	public set toonSlagschaduw(value: boolean) {
		if (typeof (value) == 'boolean' && value != this._toonSlagschaduw) { // M.b.t. de typecheck: zie note (1) bovenin deze file.
			this._toonSlagschaduw = value;
			this._isVuil = true;
		}
	}
	//
	// Get-property: toonSlagschaduw.
	// Geeft aan of er slagschaduw (schaduw die een voorwerp werpt op andere voorwerpen, dit in tegenstelling tot eigen
	// schaduw) getekend wordt of niet.
	// Return-waarde is een boolean.
	//
	public get toonSlagschaduw(): boolean {
		return this._toonSlagschaduw;
	}
	//
	// Set-property: slagschaduwVerzadiging.
	// Stelt de verzadiging van de slagschaduw, oftewel het percentage van het licht van de lichtbron dat door de
	// slagschaduw wordt ontnomen, in.
	// Parameter 'value' is een float in het bereik [0, 1].
	//
	public set slagschaduwVerzadiging(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._slagschaduwVerzadiging) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 1.0);
			this._slagschaduwVerzadiging = value;
			this._isVuil = true;
		}
	}
	//
	// Get-property: slagschaduwVerzadiging.
	// Geeft de verzadiging van de slagschaduw.
	// Return-waarde is een float.
	//
	public get slagschaduwVerzadiging(): number {
		return this._slagschaduwVerzadiging;
	}
	//
	// Set-property: slagschaduwHint.
	// Stelt de hint voor de kwaliteit van de slagschaduw in.
	// Parameter 'value' is een enumeratiegetal. De mogelijke enumeratiewaarden zijn: -1 = Gehalveerde resolutie; 
	// 0 = Nominale resolutie; 1 = Dubbele resolutie; 2 = Maximale resolutie.
	//
	public set slagschaduwHint(value: number) {
		if (VLib.Lib.isGeheelGetal(value) && value >= -1 && value <= 2 && value != this._slagschaduwHint) {
			this._slagschaduwHint = value;
			this._isVuil = true;
			this._isShadowMapVuil = true;
		}
	}
	//
	// Get-property: slagschaduwHint.
	// Geeft de hint voor de kwaliteit van de slagschaduw.
	// Return-waarde is een enumeratiegetal.
	//
	public get slagschaduwHint(): number {
		return this._slagschaduwHint;
	}
	//
	// Set-property: toonSlagschaduwVerfijnd.
	// Stelt in of de slagschaduw verfijnd getekend moet worden op basis van de view (in tegenstelling tot het volledige
	// model).
	// Parameter 'value' is een boolean.
	//
	public set toonSlagschaduwVerfijnd(value: boolean) {
		if (typeof (value) == 'boolean' && value != this._toonSlagschaduwVerfijnd) { // M.b.t. de typecheck: zie note (1) bovenin deze file.
			this._toonSlagschaduwVerfijnd = value;
			this._isVuil = true;
			this._isShadowMapVuil = true;
		}
	}
	//
	// Get-property: toonSlagschaduwVerfijnd.
	// Geeft aan of de slagschaduw verfijnd getekend moet worden op basis van de view (in tegenstelling tot het
	// volledige model).
	// Return-waarde is een boolean.
	//
	public get toonSlagschaduwVerfijnd(): boolean {
		return this._toonSlagschaduwVerfijnd;
	}

	//
	// Enkele accessors bedoeld voor intern gebruik:
	//

	//
	// Get-property: lichtvalF.
	// Geeft de genormaliseerde richting van het invallend licht van de lichtbron. Een w-coordinaat met waarde 1 geeft
	// aan dat het een cameralichtbron betreft; bij waarde 0 is het een modellichtbron.
	// Return-waarde is een array van floats met lengte 4.
	//
	public get lichtvalF(): number[] {
		return this._lichtvalF;
	}
	//
	// Get-property: omgevingLichtNiveau.
	// Geeft het niveau van het omgevinglicht (i.e. ambient).
	// Return-waarde is een float in het bereik [0, 10].
	//
	public get omgevingLichtNiveau(): number {
		return this._omgevingLichtNiveau;
	}
	//
	// Get-property: diffuusLichtNiveau.
	// Geeft het niveau van het diffuus licht (i.e. diffuse).
	// Return-waarde is een float in het bereik [0, 10].
	//
	public get diffuusLichtNiveau(): number {
		return this._diffuusLichtNiveau;
	}
	//
	// Get-property: schitteringLichtNiveau.
	// Geeft het niveau van het schitteringlicht (i.e. specular).
	// Return-waarde is een float in het bereik [0, 10].
	//
	public get schitteringLichtNiveau(): number {
		return this._schitteringLichtNiveau;
	}
	//
	// Get-property: schitteringMateriaalMacht.
	// Geeft de macht van het schitteringlicht (i.e. specular).
	// Return-waarde is een float.
	//
	public get schitteringMateriaalMacht(): number {
		return this._schitteringMateriaalMacht;
	}
	//
	// Get/set-property: shadowMapTextureWrap.
	// De huidige shadow map.
	//
	public set shadowMapTextureWrap(value: VLib.ShadowMapTextureWrapType) {
		this._shadowMapTextureWrap = value;
	}
	public get shadowMapTextureWrap(): VLib.ShadowMapTextureWrapType {
		return this._shadowMapTextureWrap;
	}
	//
	// Get/set-property: diepteMatrix.
	// Dieptematrix behorende bij de huidige shadow map.
	//
	public set diepteMatrix(value: number[]) {
		this._diepteMatrix = value;
	}
	public get diepteMatrix(): number[] {
		return this._diepteMatrix;
	}
	//
	// Get/set-property: gestaldeShadowMapTextureWrap.
	// De gestalde shadow map.
	//
	public set gestaldeShadowMapTextureWrap(value: VLib.ShadowMapTextureWrapType) {
		this._gestaldeShadowMapTextureWrap = value;
	}
	public get gestaldeShadowMapTextureWrap(): VLib.ShadowMapTextureWrapType {
		return this._gestaldeShadowMapTextureWrap;
	}
	//
	// Get/set-property: gestaldeDiepteMatrix.
	// Dieptematrix behorende bij de gestalde shadow map.
	//
	public set gestaldeDiepteMatrix(value: number[]) {
		this._gestaldeDiepteMatrix = value;
	}
	public get gestaldeDiepteMatrix(): number[] {
		return this._gestaldeDiepteMatrix;
	}
	//
	// Get/set-property: isVuil.
	//
	public set isVuil(value: boolean) {
		this._isVuil = value;
	}
	public get isVuil(): boolean {
		return this._isVuil;
	}
	//
	// Get/set-property: isShadowMapVuil.
	//
	public set isShadowMapVuil(value: boolean) {
		this._isShadowMapVuil = value;
	}
	public get isShadowMapVuil(): boolean {
		return this._isShadowMapVuil;
	}

	//
	// Public functions.
	//

	//
	// Kopieert de belichting. Return-waarde is een belichting-object.
	//
	public copy(): Belichting {
		let kopie: Belichting = new Belichting();
		kopie.lichtval = this.lichtval;
		kopie.isCameraLicht = this.isCameraLicht;
		kopie.omgevinglichtSterkte = this.omgevinglichtSterkte;
		kopie.lichtbronSterkte = this.lichtbronSterkte;
		kopie.lichtSchittering = this.lichtSchittering;
		kopie.toonSlagschaduw = this.toonSlagschaduw;
		kopie.toonSlagschaduwVerfijnd = this.toonSlagschaduwVerfijnd;
		kopie.slagschaduwVerzadiging = this.slagschaduwVerzadiging;
		kopie.slagschaduwHint = this.slagschaduwHint;
		return kopie;
	};

	//
	// Leest de belichting in.
	// Parameter 'value' is een belichting-object of een object, eventueel geserialiseerd in de vorm van de JSON-tekst, 
	// met de volgende content: 
	//   property 'lichtval' is een array van floats met lengte 3 (x-, y- en z-componenten); 
	//   properties 'isCameraLicht', 'toonSlagschaduw' en 'toonSlagschaduwVerfijnd' zijn booleans; 
	//   properties 'omgevinglichtSterkte' en 'lichtbronSterkte' zijn floats in het bereik [0, 10]; 
	//   properties 'lichtSchittering' en 'slagschaduwVerzadiging' zijn floats in het bereik [0, 1]; 
	//   property 'slagschaduwHint' is een enumeratiegetal.
	// Als 'value' een belichting-object is, kan beter functie 'copy' gebruikt worden.
	//
	public read(value: V3DObjectOfStringType): void {
		if (value) {
			let valueObject: object;
			if (typeof (value) == 'string') {
				try {
					valueObject = JSON.parse(value as string);
				}
				catch (exc) {
					VLib.Lib.log('Belichting kon niet geladen worden. Foutdetails: ' + exc, 2);
					return;
				}
			}
			else {
				valueObject = value as object;
			}
			if (typeof (valueObject) != 'object') {
				VLib.Lib.log('Belichting kon niet geladen worden. Bron is niet een object', 2);
				return;
			};
			let propNaam: string;
			propNaam = 'lichtval';
			if (propNaam in valueObject) {
				this.lichtval = valueObject[propNaam] as number[]; // Typechecking zit binnen de set-property.
			};
			propNaam = 'isCameraLicht';
			if (propNaam in valueObject) {
				this.isCameraLicht = valueObject[propNaam] as boolean; // Typechecking zit binnen de set-property. // Zie echter note (2) bovenin deze file.
			};
			propNaam = 'omgevinglichtSterkte';
			if (propNaam in valueObject) {
				this.omgevinglichtSterkte = valueObject[propNaam] as number; // Typechecking zit binnen de set-property.
			};
			propNaam = 'lichtbronSterkte';
			if (propNaam in valueObject) {
				this.lichtbronSterkte = valueObject[propNaam] as number; // Typechecking zit binnen de set-property.
			};
			propNaam = 'lichtSchittering';
			if (propNaam in valueObject) {
				this.lichtSchittering = valueObject[propNaam] as number; // Typechecking zit binnen de set-property.
			};
			propNaam = 'toonSlagschaduw';
			if (propNaam in valueObject) {
				this.toonSlagschaduw = valueObject[propNaam] as boolean; // Typechecking zit binnen de set-property. // Zie echter note (2) bovenin deze file.
			};
			propNaam = 'slagschaduwVerzadiging';
			if (propNaam in valueObject) {
				this.slagschaduwVerzadiging = valueObject[propNaam] as number; // Typechecking zit binnen de set-property.
			};
			propNaam = 'slagschaduwHint';
			if (propNaam in valueObject) {
				this.slagschaduwHint = valueObject[propNaam] as number; // Typechecking zit binnen de set-property.
			};
			propNaam = 'toonSlagschaduwVerfijnd';
			if (propNaam in valueObject) {
				this.toonSlagschaduwVerfijnd = valueObject[propNaam] as boolean; // Typechecking zit binnen de set-property. // Zie echter note (2) bovenin deze file.
			};
		};
	}

	//
	// Private functions.
	//

	//
	// Bepaalt de richting van het invallend licht van de lichtbron.
	//
	private bepaalLichtvalF(): void {
		let vec: number[] = this._lichtval;
		if (this._isCameraLicht) {
			//
			// Converteer van het generieke lichtbronassenstelsel naar het WebGL-assenstelsel:
			//
			vec = [-vec[1], vec[2], -vec[0]];
		}
		let vecLengte: number = Math.hypot(vec[0], vec[1], vec[2]);
		this._lichtvalF = [-vec[0] / vecLengte, -vec[1] / vecLengte, -vec[2] / vecLengte,
			(this._isCameraLicht ? 1.0 : 0.0)];
		this._isShadowMapVuil = true;
	};

	//
	// Private variabelen:
	//
	private _lichtval: number[] = [1.0, -1.0, -1.0];
	private _isCameraLicht: boolean = true;
	private _omgevinglichtSterkte: number = 0.4;
	private _lichtbronSterkte: number = 0.6;
	private _lichtSchittering: number = 0.333;
	private _toonSlagschaduw: boolean = false;
	private _slagschaduwVerzadiging: number = 0.75;
	private _slagschaduwHint: number = 0;
	private _toonSlagschaduwVerfijnd: boolean = false;

	//
	// Private variabelen gekoppeld aan accessors bedoeld voor intern gebruik:
	//
	private _lichtvalF: number[] = [-0.57735, 0.57735, 0.57735, 1.0];
	private _omgevingLichtNiveau: number = 0.4; // Het niveau van het omgevinglicht (i.e. ambient), het diffuus licht (i.e. diffuse) en het schitteringlicht (i.e. specular).
	private _diffuusLichtNiveau: number = 0.4;
	private _schitteringLichtNiveau: number = 0.2;
	private _schitteringMateriaalMacht: number = 50.0;
	private _isVuil: boolean = true;
	private _isShadowMapVuil: boolean = true;
	private _shadowMapTextureWrap: VLib.ShadowMapTextureWrapType = undefined; // de huidige shadow map
	private _diepteMatrix: number[] = undefined; // dieptematrix behorende bij de huidige shadow map
	private _gestaldeShadowMapTextureWrap: VLib.ShadowMapTextureWrapType = undefined; // de gestalde shadow map
	private _gestaldeDiepteMatrix: number[] = undefined; // dieptematrix behorende bij de gestalde shadow map

}; // class Belichting

//
// Class voor een lamp-object.
//
export class Lamp {

	constructor() {
		//
		// Bewust leeg.
		//
	}

	//
	// Openbaar API-accessors:
	//

	//
	// Set-property: isAan.
	// Stelt in of de lamp aan is (true) of uit (false).
	// Parameter 'value' is een boolean.
	//
	public set isAan(value: boolean) {
		if (typeof (value) == 'boolean' && value != this._isAan) { // M.b.t. de typecheck: zie note (1) bovenin deze file.
			this._isAan = value;
			this._isVuil = true;
		}
	}
	//
	// Get-property: isAan.
	// Geeft aan of de lamp aan is (true) of uit (false).
	// Return-waarde is een boolean.
	//
	public get isAan(): boolean {
		return this._isAan;
	}
	//
	// Set-property: positie.
	// Stelt de positie van de lamp in.
	// Parameter 'value' is een array van floats met lengte 3 (x-, y-, z-componenten).
	//
	public set positie(value: number[]) {
		if (VLib.Lib.isVector(value, true)) {
			this._positie = value.slice();
			this._isVuil = true;
			this._isShadowMapVuil = true;
		}
	}
	//
	// Get-property: positie.
	// Geeft de positie van de lamp.
	// Return-waarde is een array van floats met lengte 3 (x-, y-, z-componenten).
	//
	public get positie(): number[] {
		return this._positie.slice();
	}
	//
	// Set-property: richting.
	// Stelt de richting van de lamp (i.e. de richting van de as van de lichtconus) in.
	// Parameter 'value' is een array van floats met lengte 3 (x-, y-, z-componenten).
	//
	public set richting(value: number[]) {
		if (VLib.Lib.isVector(value, false)) {
			let vecLengte: number = Math.hypot(value[0], value[1], value[2]);
			this._richting = [value[0] / vecLengte, value[1] / vecLengte, value[2] / vecLengte]; //@@@Q Bugfix: x, y en z kregen een minteken. Dat is fout. Getter heeft tenslotte ook geen minteken.
			this._isVuil = true;
			this._isShadowMapVuil = true;
		}
	}
	//
	// Get-property: richting.
	// Geeft de richting van de lamp.
	// Return-waarde is een array van floats met lengte 3 (x-, y-, z-componenten).
	//
	public get richting(): number[] {
		return this._richting.slice();
	}
	//
	// Set-property: radiusHoek.
	// Stelt de hoek tussen de richting van de lamp en de uiterste rand van de lichtconus in, in graden.
	// Parameter 'value' is een float in het bereik [1, 85].
	//
	public set radiusHoek(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._radiusHoek) {
			value = Math.max(value, 1.0);
			value = Math.min(value, 85.0);
			this._radiusHoek = value;
			this._cosBuitenHoek = Math.cos(this._radiusHoek * VLib.Lib._vanDegNaarRad);
			this._cosBinnenHoek = Math.cos((this._radiusHoek - this._afzwakHoek) * VLib.Lib._vanDegNaarRad);
			this._isVuil = true;
			this._isShadowMapVuil = true;
		}
	}
	//
	// Get-property: radiusHoek.
	// Geeft de hoek tussen de richting van de lamp en de uiterste rand van de lichtconus, in graden.
	// Return-waarde is een float.
	//
	public get radiusHoek(): number {
		return this._radiusHoek;
	}
	//
	// Set-property: afzwakHoek.
	// Stelt de hoek van de afzwakzone binnen de uiterste rand van de lichtconus, in graden.
	// Parameter 'value' is een float in het bereik [0, radiusHoek].
	//
	public set afzwakHoek(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._afzwakHoek) {
			value = Math.max(value, 0.0);
			value = Math.min(value, this._radiusHoek);
			this._afzwakHoek = value;
			this._cosBinnenHoek = Math.cos((this._radiusHoek - this._afzwakHoek) * VLib.Lib._vanDegNaarRad);
			this._isVuil = true;
		}
	}
	//
	// Get-property: afzwakHoek.
	// Geeft de hoek van de afzwakzone binnen de uiterste rand van de lichtconus, in graden.
	// Return-waarde is een float.
	//
	public get afzwakHoek(): number {
		return this._afzwakHoek;
	}
	//
	// Set-property: lichtbronSterkte.
	// Stelt de relatieve sterkte van de lichtbron, een percentage van de nominale maximale lichtsterkte, in.
	// Parameter 'value' is een float in het bereik [0, 10].
	//
	public set lichtbronSterkte(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._lichtbronSterkte) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 10.0);
			this._lichtbronSterkte = value;
			this._diffuusLichtNiveau = value * (1.0 - this._lichtSchittering);
			this._schitteringLichtNiveau = value * this._lichtSchittering;
			this._isVuil = true;
		}
	}
	//
	// Get-property: lichtbronSterkte.
	// Geeft de relatieve sterkte van de lichtbron.
	// Return-waarde is een float.
	//
	public get lichtbronSterkte(): number {
		return this._lichtbronSterkte;
	}
	//
	// Set-property: lichtSchittering.
	// Stelt het percentage van het licht van de lichtbron dat schitterend weerkaatst wanneer het op een oppervlak valt
	// in. Deze waarde is het complement van het percentage van het licht van de lichtbron dat verstrooid weerkaatst.
	// Parameter 'value' is een float in het bereik [0, 1].
	//
	public set lichtSchittering(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._lichtSchittering) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 1.0);
			this._lichtSchittering = value;
			this._diffuusLichtNiveau = this._lichtbronSterkte * (1.0 - value);
			this._schitteringLichtNiveau = this._lichtbronSterkte * value;
			this._isVuil = true;
		}
	}
	//
	// Get-property: lichtSchittering.
	// Geeft het percentage van het licht van de lichtbron dat schitterend weerkaatst wanneer het op een oppervlak valt.
	// Return-waarde is een float.
	//
	public get lichtSchittering(): number {
		return this._lichtSchittering;
	}
	public set kleur(RGB: number[]) {
		if (VLib.Lib.isGetalLijst(RGB, 3, 0, 255)) {
			this._kleur = RGB.slice();
			this._kleurF = [RGB[0] / 255.0, RGB[1] / 255.0, RGB[2] / 255.0];
			this._isVuil = true;
		}
	}
	public get kleur(): number[] {
		return this._kleur.slice();
	}

	//
	// Enkele accessors bedoeld voor intern gebruik:
	//

	//
	// Get-property: cosBuitenHoek.
	// Geeft de cosinussen van de hoeken van de radiushoek en de afzwakhoek.
	// Return-waarde is een array van floats met lengte 2.
	//
	public get cosBuitenHoek(): number {
		return this._cosBuitenHoek;
	}
	//
	// Get-property: cosBinnenHoek.
	// Geeft de cosinussen van de hoeken van de radiushoek en de afzwakhoek.
	// Return-waarde is een array van floats met lengte 2.
	//
	public get cosBinnenHoek(): number {
		return this._cosBinnenHoek;
	}
	//
	// Get-property: diffuusLichtNiveau.
	// Geeft het niveau van het diffuus licht (i.e. diffuse).
	// Return-waarde is een float in het bereik [0, 10].
	//
	public get diffuusLichtNiveau(): number {
		return this._diffuusLichtNiveau;
	}
	//
	// Get-property: schitteringLichtNiveau.
	// Geeft het niveau van het schitteringlicht (i.e. specular).
	// Return-waarde is een float in het bereik [0, 10].
	//
	public get schitteringLichtNiveau(): number {
		return this._schitteringLichtNiveau;
	}
	//
	// Get-property: kleurF.
	// Geeft de lichtkleur van de lamp.
	// Return-waarde is een array met lengte 3 met waarden (R-, G-, B-componenten) in het bereik [0, 1].
	//
	public get kleurF(): number[] {
		return this._kleurF;
	}
	//
	// Get/set-property: shadowMapTexture.
	// De huidige shadow map.
	//
	public set shadowMapTexture(value: WebGLTexture) {
		this._shadowMapTexture = value;
	}
	public get shadowMapTexture(): WebGLTexture {
		return this._shadowMapTexture;
	}
	//
	// Get/set-property: diepteMatrix.
	// Dieptematrix behorende bij de huidige shadow map.
	//
	public set diepteMatrix(value: number[]) {
		this._diepteMatrix = value;
	}
	public get diepteMatrix(): number[] {
		return this._diepteMatrix;
	}
	//
	// Get/set-property: isVuil.
	//
	public set isVuil(value: boolean) {
		this._isVuil = value;
	}
	public get isVuil(): boolean {
		return this._isVuil;
	}
	//
	// Get/set-property: isShadowMapVuil.
	//
	public set isShadowMapVuil(value: boolean) {
		this._isShadowMapVuil = value;
	}
	public get isShadowMapVuil(): boolean {
		return this._isShadowMapVuil;
	}

	//
	// Public functions.
	//

	//
	// Kopieert de lamp. Return-waarde is een lamp-object.
	//
	public copy(): Lamp {
		let kopie: Lamp = new Lamp();
		kopie.isAan = this.isAan;
		kopie.positie = this.positie;
		kopie.richting = this.richting;
		kopie.radiusHoek = this.radiusHoek;
		kopie.afzwakHoek = this.afzwakHoek;
		kopie.lichtbronSterkte = this.lichtbronSterkte;
		kopie.lichtSchittering = this.lichtSchittering;
		kopie.kleur = this.kleur;
		return kopie;
	};

	//
	// Leest de lamp in. 
	// Parameter 'value' is een lamp - object of een object, eventueel geserialiseerd in de vorm van de JSON-tekst, 
	// met de volgende content: 
	//   property 'isAan' is een boolean; 
	//   properties 'positie' en 'richting' zijn arrays van floats met lengte 3 (x-, y- en z-componenten); 
	//   property 'radiusHoek' is een float in het bereik [1, 85]; 
	//   property 'afzwakHoek' is een float in het bereik [0, radiusHoek]; 
	//   property 'lichtbronSterkte' is een float in het bereik [0, 10]; 
	//   property 'lichtSchittering' is een float in het bereik [0, 1]; 
	//   property 'kleur' is een array met lengte 3 (R-, G- en B-componenten) met waarden in het bereik [0, 255].
	// Als 'value' een lamp-object is, kan beter functie 'copy' gebruikt worden.
	//
	public read(value: V3DObjectOfStringType): void {
		if (value) {
			let valueObject: object;
			if (typeof (value) == 'string') {
				try {
					valueObject = JSON.parse(value as string);
				}
				catch (exc) {
					VLib.Lib.log('Lamp kon niet geladen worden. Foutdetails: ' + exc, 2);
					return;
				}
			}
			else {
				valueObject = value as object;
			}
			if (typeof (valueObject) != 'object') {
				VLib.Lib.log('Lamp kon niet geladen worden. Bron is niet een object', 2);
				return;
			};
			let propNaam: string;
			propNaam = 'isAan';
			if (propNaam in valueObject) {
				this.isAan = valueObject[propNaam] as boolean; // Typechecking zit binnen de set-property. // Zie echter note (2) bovenin deze file.
			};
			propNaam = 'positie';
			if (propNaam in valueObject) {
				this.positie = valueObject[propNaam] as number[]; // Typechecking zit binnen de set-property.
			};
			propNaam = 'richting';
			if (propNaam in valueObject) {
				this.richting = valueObject[propNaam] as number[]; // Typechecking zit binnen de set-property.
			};
			propNaam = 'radiusHoek';
			if (propNaam in valueObject) {
				this.radiusHoek = valueObject[propNaam] as number; // Typechecking zit binnen de set-property.
			};
			propNaam = 'afzwakHoek';
			if (propNaam in valueObject) {
				this.afzwakHoek = valueObject[propNaam] as number; // Typechecking zit binnen de set-property.
			};
			propNaam = 'lichtbronSterkte';
			if (propNaam in valueObject) {
				this.lichtbronSterkte = valueObject[propNaam] as number; // Typechecking zit binnen de set-property.
			};
			propNaam = 'lichtSchittering';
			if (propNaam in valueObject) {
				this.lichtSchittering = valueObject[propNaam] as number; // Typechecking zit binnen de set-property.
			};
			propNaam = 'kleur';
			if (propNaam in valueObject) {
				this.kleur = valueObject[propNaam] as number[]; // Typechecking zit binnen de set-property.
			};
		};
	}

	//
	// Private variabelen:
	//
	private _isAan: boolean = true;
	private _positie: number[] = [0.0, 0.0, 0.0];
	private _richting: number[] = [0.0, 0.0, -1.0];
	private _radiusHoek: number = 45.0;
	private _afzwakHoek: number = 5.0;
	private _lichtbronSterkte: number = 0.6;
	private _lichtSchittering: number = 0.333;
	private _kleur: number[] = [255, 255, 255]; // in ubyte[3], wit

	//
	// Private variabelen gekoppeld aan accessors bedoeld voor intern gebruik:
	//
	private _cosBuitenHoek: number = Math.cos(45.0 * VLib.Lib._vanDegNaarRad);
	private _cosBinnenHoek: number = Math.cos(40.0 * VLib.Lib._vanDegNaarRad);
	private _diffuusLichtNiveau: number = 0.4;
	private _schitteringLichtNiveau: number = 0.2;
	private _kleurF: number[] = [1.0, 1.0, 1.0]; // in ubyte[3], wit
	private _shadowMapTexture: WebGLTexture = undefined;
	private _diepteMatrix: number[] = undefined;
	private _isVuil: boolean = true;
	private _isShadowMapVuil: boolean = true;

}; // class Lamp

//
// Class voor een Nabewerkingen-object.
//
class Nabewerkingen {
	constructor() {
		//
		// Bewust leeg.
		//
	}
	//
	// Openbaar API-accessors:
	//
	// Get/set-property: idlePeriode.
	// Stelt de wachttijd (in msec) na de laatste tekenactiviteit in waarna bijzondere handelingen worden verricht.
	// Een negatieve waarde schakelt het verrichten van deze handelingen uit.
	// Parameter 'value' en return-waarde is een float.
	//
	public set idlePeriode(value: number) {
		this.staakIdlePeriode();
		if (value == null || value < 1.0) // wachttijd is minimaal 1 msec
		{
			value = -1.0;
		}
		this._idlePeriode = value;
	}
	public get idlePeriode(): number {
		return this._idlePeriode;
	}
	//
	// Get/set-property: toonSlagschaduwVerfijnd.
	// Geeft aan of de slagschaduw verfijnd getekend moet worden op basis van de view (in tegenstelling tot het
	// volledige model).
	// Return-waarde is een boolean.
	//
	public set toonSlagschaduwVerfijnd(value: boolean) {
		if (typeof (value) == 'boolean') { // M.b.t. de typecheck: zie note (1) bovenin deze file.
			this._toonSlagschaduwVerfijnd = value;
		}
	}
	public get toonSlagschaduwVerfijnd(): boolean {
		return this._toonSlagschaduwVerfijnd;
	}
	//
	// Get-property: fxaa.
	// Geeft de toegang tot de instellingen van de FXAA-nabewerking.
	// Return-value is een NabewerkingFXAA-object.
	//
	public get fxaa(): NabewerkingFXAA {
		return this._nabewerkingFXAA;
	}
	//
	// Get-property: bokeh.
	// Geeft de toegang tot de instellingen van de bokeh-nabewerking.
	// Return-value is een NabewerkingBokeh-object.
	//
	public get bokeh(): NabewerkingBokeh {
		return this._nabewerkingBokeh;
	}
	//
	// Get-property: vignette.
	// Geeft de toegang tot de instellingen van de vignette-nabewerking.
	// Return-value is een NabewerkingVignette-object.
	//
	public get vignette(): NabewerkingVignette {
		return this._nabewerkingVignette;
	}
	//
	// Get-property: ssao.
	// Geeft de toegang tot de instellingen van de SSAO-nabewerking.
	// Return-value is een NabewerkingSSAO-object.
	//
	public get ssao(): NabewerkingSSAO {
		return this._nabewerkingSSAO;
	}
	//
	// Stopt met aftellen van de lopende idle-periode.
	//
	public staakIdlePeriode(): void {
		if (this._idleTimeoutHandle != null) {
			window.clearTimeout(this._idleTimeoutHandle);
			this._idleTimeoutHandle = null;
		}
	}

	public wisIdleTimeoutHandle(): void { //@@@Q Nieuw.
		this._idleTimeoutHandle = null;
	}

	public zetTimeout(onIdle: V3DSimpelCallbackType): void { //@@@Q Nieuw.
		if (this._idlePeriode > 0) {
			this._idleTimeoutHandle = window.setTimeout(onIdle, this._idlePeriode);
		}
	}

	//
	// Enkele accessors bedoeld voor intern gebruik:
	//

	//
	// Get/set-property: idleTimeoutHandle.
	//
	private set idleTimeoutHandle(value: number) {
		this._idleTimeoutHandle = value;
	}
	private get idleTimeoutHandle(): number { // Niet in gebruik.
		return this._idleTimeoutHandle;
	}

	//
	// Private variabelen:
	//
	private _idlePeriode: number = -1.0; // in msec; negatief duidt op uitgeschakeld
	private _idleTimeoutHandle: number = null; //@@@Q Fix: moet initieel null zijn, i.p.v. undefined. Volgt uit het gebruik van deze property. Was weliswaar niet fout, maar is nu duidelijker.
	private _toonSlagschaduwVerfijnd: boolean = false;
	private _nabewerkingFXAA: NabewerkingFXAA = new NabewerkingFXAA();
	private _nabewerkingBokeh: NabewerkingBokeh = new NabewerkingBokeh();
	private _nabewerkingVignette: NabewerkingVignette = new NabewerkingVignette();
	private _nabewerkingSSAO: NabewerkingSSAO = new NabewerkingSSAO();

}; // class Nabewerkingen

//
// Class voor een NabewerkingFXAA-object.
//
class NabewerkingFXAA {

	constructor() {
		//
		// Bewust leeg.
		//
	}

	//
	// Openbaar API-accessors:
	//

	//
	// Get/set-property: toepassen.
	// Stelt in of de FXAA-nabewerking toegepast moet worden.
	// Parameter 'value' en return-value is een boolean.
	//
	public set toepassen(value: boolean) {
		if (typeof (value) == 'boolean') { // M.b.t. de typecheck: zie note (1) bovenin deze file.
			this._toepassen = value;
		}
	}
	public get toepassen(): boolean {
		return this._toepassen;
	}
	//
	// Private variabelen:
	//
	private _toepassen: boolean = false;

}; // class NabewerkingFXAA

//
// Class voor een NabewerkingSSAO-object.
//
class NabewerkingSSAO {

	constructor() {
		//
		// Bewust leeg.
		//
	}

	//
	// Openbaar API-accessors:
	//

	//
	// Get/set-property: toepassen.
	// Stelt in of de SSAO-nabewerking toegepast moet worden.
	// Parameter 'value' en return-value is een boolean.
	//
	public set toepassen(value: boolean) {
		if (typeof (value) == 'boolean') { // M.b.t. de typecheck: zie note (1) bovenin deze file.
			this._toepassen = value;
		}
	}
	public get toepassen(): boolean {
		return this._toepassen;
	}
	//
	// Get/set-property: straal.
	// Stelt de straal van de steekproefopname in, in mm.
	// Parameter 'value' en return-value is een float in bereik [25, 250].
	//
	public set straal(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._straal) {
			value = Math.max(value, 25.0);
			value = Math.min(value, 250.0);
			this._straal = value;
			this._isVuil = true;
		}
	}
	public get straal(): number {
		return this._straal;
	}
	//
	// Get/set-property: macht.
	// Stelt de machtsverheffing van de occlusie in.
	// Parameter 'value' en return-value is een float in bereik [1, 3].
	//
	public set macht(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._macht) {
			value = Math.max(value, 1.0);
			value = Math.min(value, 3.0);
			this._macht = value;
			this._isVuil = true;
		}
	}
	public get macht(): number {
		return this._macht;
	}

	//
	// Enkele accessors bedoeld voor intern gebruik:
	//

	//
	// Get/set-property: isVuil.
	//
	public set isVuil(value: boolean) {
		this._isVuil = value;
	}
	public get isVuil(): boolean {
		return this._isVuil;
	}

	//
	// Private variabelen:
	//
	private _isVuil: boolean = true;
	private _toepassen: boolean = false;
	private _straal: number = 125.0;
	private _macht: number = 2.0;

}; // class NabewerkingSSAO

//
// Class voor een NabewerkingBokeh-object.
//
class NabewerkingBokeh {

	constructor() {
		//
		// Bewust leeg.
		//
	}

	//
	// Openbaar API-accessors:
	//

	//
	// Get/set-property: toepassen.
	// Stelt in of de bokeh-nabewerking toegepast moet worden.
	// Parameter 'value' en return-value is een boolean.
	//
	public set toepassen(value: boolean) {
		if (typeof (value) == 'boolean') { // M.b.t. de typecheck: zie note (1) bovenin deze file.
			this._toepassen = value;
		}
	}
	public get toepassen(): boolean {
		return this._toepassen;
	}
	//
	// Get/set-property: autofocus.
	// Stelt in of auto-focus (i.e. focus op het centrum van de view) toegepast moet worden.
	// Parameter 'value' en return-value is een boolean.
	//
	public set autofocus(value: boolean) {
		if (typeof (value) == 'boolean' && value != this._autofocus) { // M.b.t. de typecheck: zie note (1) bovenin deze file.
			this._autofocus = value;
			this._isVuil = true;
		}
	}
	public get autofocus(): boolean {
		return this._autofocus;
	}
	//
	// Get/set-property: focusAfstand.
	// Stelt de focusafstand (i.e. het centrum van de scherptediepte) in, in mm.
	// Parameter 'value' en return-value is een float.
	//
	public set focusAfstand(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._focusAfstand) {
			value = Math.max(value, 0.0);
			this._focusAfstand = value;
			this._isVuil = true;
		}
	}
	public get focusAfstand(): number {
		return this._focusAfstand;
	}
	//
	// Get/set-property: scherptediepteLengte.
	// Stelt de lengte van de scherptediepte (ook wel depth-of-field, ook wel focus range) in, in mm.
	// Parameter 'value' en return-value is een float.
	//
	public set scherptediepteLengte(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._scherptediepteLengte) {
			value = Math.max(value, 0.0);
			this._scherptediepteLengte = value;
			this._isVuil = true;
		}
	}
	public get scherptediepteLengte(): number {
		return this._scherptediepteLengte;
	}
	//
	// Get/set-property: nabijeOnscherpteLengte.
	// Stelt de lengte van het nabije gebied met oplopende onscherpte (i.e. de nabije bokeh) in, in mm.
	// Parameter 'value' en return-value is een float.
	//
	public set nabijeOnscherpteLengte(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._nabijeOnscherpteLengte) {
			value = Math.max(value, 0.0);
			this._nabijeOnscherpteLengte = value;
			this._isVuil = true;
		}
	}
	public get nabijeOnscherpteLengte(): number {
		return this._nabijeOnscherpteLengte;
	}
	//
	// Get/set-property: verreOnscherpteLengte.
	// Stelt de lengte van het verre gebied met oplopende onscherpte (i.e. de verre bokeh) in, in mm.
	// Parameter 'value' en return-value is een float.
	//
	public set verreOnscherpteLengte(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._verreOnscherpteLengte) {
			value = Math.max(value, 0.0);
			this._verreOnscherpteLengte = value;
			this._isVuil = true;
		}
	}
	public get verreOnscherpteLengte(): number {
		return this._verreOnscherpteLengte;
	}
	//
	// Get/set-property: scherptediepteSterkte.
	// Stelt de relatieve mate van onscherpte van de scherptediepte in.
	// Parameter 'value' en return-value is een float in bereik [0, 1].
	//
	public set scherptediepteSterkte(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._scherptediepteSterkte) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 1.0);
			this._scherptediepteSterkte = value;
			this._isVuil = true;
		}
	}
	public get scherptediepteSterkte(): number {
		return this._scherptediepteSterkte;
	}
	//
	// Get/set-property: nabijeOnscherpteSterkte.
	// Stelt de relatieve mate van onscherpte van het nabije gebied met oplopende onscherpte in.
	// Parameter 'value' en return-value is een float in bereik [0, 1].
	//
	public set nabijeOnscherpteSterkte(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._nabijeOnscherpteSterkte) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 1.0);
			this._nabijeOnscherpteSterkte = value;
			this._isVuil = true;
		}
	}
	public get nabijeOnscherpteSterkte(): number {
		return this._nabijeOnscherpteSterkte;
	}
	//
	// Get/set-property: verreOnscherpteSterkte.
	// Stelt de relatieve mate van onscherpte van het verre gebied met oplopende onscherpte in.
	// Parameter 'value' en return-value is een float in bereik [0, 1].
	//
	public set verreOnscherpteSterkte(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._verreOnscherpteSterkte) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 1.0);
			this._verreOnscherpteSterkte = value;
			this._isVuil = true;
		}
	}
	public get verreOnscherpteSterkte(): number {
		return this._verreOnscherpteSterkte;
	}
	//
	// Get-property: cocCurveParams.
	//
	public get cocCurveParams(): number[] {
		return [this._nabijeOnscherpteLengte, this._scherptediepteLengte, this._verreOnscherpteLengte,
		this._nabijeOnscherpteSterkte, this._scherptediepteSterkte, this._verreOnscherpteSterkte];
	}
	//
	// Get/set-property: waasSterkte.
	// Stelt de algehele mate van onscherpte in.
	// Parameter 'value' en return-value is een float in bereik [0.5, 5].
	//
	public set waasSterkte(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._waasSterkte) {
			value = Math.max(value, 0.5);
			value = Math.min(value, 5.0);
			this._waasSterkte = value;
			this._isVuil = true;
		}
	}
	public get waasSterkte(): number {
		return this._waasSterkte;
	}
	//
	// Get/set-property: ruisSterkte.
	// Stelt de mate van ruis in.
	// Parameter 'value' en return-value is een float in bereik [0, 5].
	//
	public set ruisSterkte(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._ruisSterkte) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 5.0);
			this._ruisSterkte = value;
			this._isVuil = true;
		}
	}
	public get ruisSterkte(): number {
		return this._ruisSterkte;
	}

	//
	// Enkele accessors bedoeld voor intern gebruik:
	//

	//
	// Get/set-property: isVuil.
	//
	public set isVuil(value: boolean) {
		this._isVuil = value;
	}
	public get isVuil(): boolean {
		return this._isVuil;
	}
	//
	// Private variabelen:
	//
	private _toepassen: boolean = false;
	private _autofocus: boolean = false;
	private _focusAfstand: number = 5000.0;
	private _nabijeOnscherpteLengte: number = 5000.0
	private _scherptediepteLengte: number = 2000.0;
	private _verreOnscherpteLengte: number = 10000.0
	private _nabijeOnscherpteSterkte: number = 1.0
	private _scherptediepteSterkte: number = 0.0;
	private _verreOnscherpteSterkte: number = 0.5
	private _waasSterkte: number = 1.0;
	private _ruisSterkte: number = 0.0;
	private _isVuil: boolean = true;

}; // class NabewerkingBokeh

//
// Class voor een NabewerkingVignette-object.
//
class NabewerkingVignette {

	constructor() {
		//
		// Bewust leeg.
		//
	}

	//
	// Openbaar API-accessors:
	//

	//
	// Get/set-property: toepassen.
	// Stelt in of de vignette-nabewerking toegepast moet worden.
	// Parameter 'value' en return-value is een boolean.
	//
	public set toepassen(value: boolean) {
		if (typeof (value) == 'boolean') { // M.b.t. de typecheck: zie note (1) bovenin deze file.
			this._toepassen = value;
		}
	}
	public get toepassen(): boolean {
		return this._toepassen;
	}
	//
	// Get/set-property: straal.
	// Stelt de straal in, relatief ten opzichte van de schermafmetingen.
	// Parameter 'value' en return-value is een float in bereik [0, 1].
	//
	public set straal(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._straal) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 1.0);
			this._straal = value;
			this._isVuil = true;
		}
	}
	public get straal(): number {
		return this._straal;
	}
	//
	// Get/set-property: wijdte.
	// Stelt de wijdte in, gecentreerd om de radius, relatief ten opzichte van de schermafmetingen.
	// Parameter 'value' en return-value is een float in bereik [0, 1].
	//
	public set wijdte(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._wijdte) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 1.0);
			this._wijdte = value;
			this._isVuil = true;
		}
	}
	public get wijdte(): number {
		return this._wijdte;
	}
	//
	// Get/set-property: intensiteit.
	// Stelt de intensiteit van de vignette in.
	// Parameter 'value' en return-value is een float in bereik [0, 1].
	//
	public set intensiteit(value: number) {
		if (VLib.Lib.isGetal(value) && value != this._intensiteit) {
			value = Math.max(value, 0.0);
			value = Math.min(value, 1.0);
			this._intensiteit = value;
			this._isVuil = true;
		}
	}
	public get intensiteit(): number {
		return this._intensiteit;
	}

	//
	// Enkele accessors bedoeld voor intern gebruik:
	//

	//
	// Get/set-property: isVuil.
	//
	public set isVuil(value: boolean) {
		this._isVuil = value;
	}
	public get isVuil(): boolean {
		return this._isVuil;
	}

	//
	// Private variabelen:
	//
	private _toepassen: boolean = false;
	private _straal: number = 0.6;
	private _wijdte: number = 0.25;
	private _intensiteit: number = 0.5;
	private _isVuil: boolean = true;

}; // class NabewerkingVignette

//
// Type van callback-functie voor de trigger-events voor een Perimeter.
//
type PerimeterTriggerCallbackType = (perimeter: Perimeter, triggers: string[]) => number;

//
// Class voor een perimeter-object.
//
class Perimeter {

	//
	// Constructor.
	// Parameter 'value' is een perimeter geserialiseerd in de vorm van een JSON-tekst.
	//
	constructor(value: string) {
		if (typeof value != 'string') { throw "Perimeter.CTR: parameter value is geen string" };
		this.init(value);
	}

	//
	// Openbaar API-accessors:
	//

	//
	// Get-property: isGeldig.
	// Geeft aan of de perimeter succesvol geinitieerd is.
	// Return-waarde is een boolean. Bij waarde false is het object niet te gebruiken.
	//
	public get isGeldig(): boolean {
		return this._isGeldig;
	}
	//
	// Set-property: ooghoogte.
	// Stelt de ooghoogte (of camerahoogte) binnen de perimeter in.
	// Parameter 'value' is een float (z-component in mm).
	//
	public set ooghoogte(value: number) {
		if (VLib.Lib.isGetal(value)) {
			this._ooghoogte = value;
		}
	}
	//
	// Get-property: ooghoogte.
	// Geeft de ooghoogte (of camerahoogte) binnen de perimeter.
	// Return-waarde is een float (z-component in mm).
	//
	public get ooghoogte(): number {
		return this._ooghoogte;
	}
	//
	// Set-property: triggerCallback.
	// Stelt de callback-functie voor de trigger-events in.
	// Parameter 'callback' is een functie die wordt aangeroepen wanneer grenzen van perimeterdelen overschreden worden. 
	// De functie heeft twee argumenten: het perimeter-object, en het ID van het betreffende perimeterdeel en het type 
	// van het opgetreden trigger-event, paarsgewijs in een array voor de achtereenvolgende events.
	//
	// Waarde null ontkoppelt een eventueel bestaande callback-functie.
	//
	public set triggerCallback(callback: PerimeterTriggerCallbackType) {
		if (callback == null || typeof (callback) != 'function') {
			callback = undefined;
		}
		else {
			//
			// Hier zou ik graag de hele signatuur van de functie willen checken.
			// Helaas is runtime (vrijwel) alle informatie daarover niet beschikbaar.
			// Alleen de length-property is beschikbaar: het aantal gedefinieerde parameters van de functie.
			//
			if (callback.length != 2) { throw "Trigger-callback functie heeft fout aantal parameters" };
		}
		this._triggerCallback = callback;
	}
	//
	// Get-property: triggerCallback.
	// Geeft de callback-functie voor de trigger-events.
	// Return-waarde is een functie, of undefined.
	//
	public get triggerCallback(): PerimeterTriggerCallbackType {
		return this._triggerCallback;
	}

	//
	// Public functions.
	//

	//
	// Geeft de ID van het huidige perimeterdeel.
	// Return-waarde is een string, of undefined wanneer het huidige perimeterdeel geen ID heeft.
	//
	public geefID(): string {
		if (this._isGeldig) {
			return this._perimeterDeelHuidig.ID;
		}
		return null;
	}

	//
	// Past de positie aan.
	// Parameters 'huidigPunt' en 'nieuwPunt' zijn arrays van floats met lengte 3 (x-, y- en z-componenten).
	// Parameter 'nieuwPunt' is de voorgestelde nieuwe positie die mogelijk aangepast moet worden. Indien 'huidigPunt' 
	// waarde null heeft, is er geen sprake van een camerabeweging maar van een volledige herplaatsing.
	// Return-waarde:
	// Is een array van floats met lengte 3(x -, y - en z - componenten) die de uiteindelijke positie aangeven, 
	// OF de waarde null om de aanpassing van de positie te annuleren, 
	// OF de waarde false om alle aanhangige verwerking te annuleren.
	// Geeft dus nooit true terug.
	//
	public positieAanpassen(huidigPunt: number[], nieuwPunt: number[]): V3DPuntOfBoolType {
		if (!this.isGeldig) { throw "Perimeter is ongeldig" };
		if (!VLib.Lib.isVector(nieuwPunt, true)) { throw "nieuwPunt is geen vector" };
		let perimeterInfoObj: VLib.PerimeterInfoObjType;
		if (huidigPunt != null) {
			if (!VLib.Lib.isVector(huidigPunt, true)) { throw "huidigPunt is geen vector (en ongelijk null)" };
			perimeterInfoObj = this.bepaalPerimeterDeelCollisie(this._perimeterDeelHuidig, huidigPunt, nieuwPunt);
		}
		else {
			perimeterInfoObj = this.bepaalPerimeterDeelDichtstbijzijnde(nieuwPunt);
		}
		if (perimeterInfoObj != null) {
			if (this._triggerCallback && perimeterInfoObj.triggers) {
				let respons: number = this._triggerCallback(this, perimeterInfoObj.triggers);
				switch (respons) {
					default:
					case 0:
						break;
					case 1:
						//
						// Annuleer de aanpassing van de positie (maar niet de uiteindelijke verversing van de view):
						//
						return null;
						break;
					case 2:
						//
						// Annuleer alle aanhangige verwerking (inclusief de uiteindelijke verversing van de view):
						//
						return false;
						break;
				}
			}

			this._perimeterDeelHuidig = perimeterInfoObj.deel;
			nieuwPunt = perimeterInfoObj.positie;
			nieuwPunt[2] = nieuwPunt[2] + this._ooghoogte;

			return nieuwPunt;
		}

		return null;
	}

	//
	// Private functions.
	//

	//
	// Initieert de perimeter.
	// Parameter 'valueStr' is een perimeter geserialiseerd in de vorm van een JSON-tekst.
	// Return-waarde is true wanneer de initiatie geslaagd is; anders false.
	// Alleen als de initiatie slaagt wordt property isGeldig op true gezet.
	//
	private init(valueStr: string): void {
		let value: VLib.PerimeterJsonType;
		try {
			value = JSON.parse(valueStr);
		}
		catch (exc) {
			VLib.Lib.log('Perimeter kon niet geladen worden. Foutdetails: ' + exc, 2);
			return;
		}

		try {
			let delen: VLib.PerimeterDeelJsonType[] = value.delen;
			if (!Array.isArray(delen)) { throw "Perimeter bevat geen delen-array" };
			if (delen.length == 0) { throw "Perimeter bevat leeg delen-array" }; // Minimaal 1 nodig, anders is er geen this._perimeterDeelHuidig.
			for (let i: number = 0; i < delen.length; i++) {
				let deelJson: VLib.PerimeterDeelJsonType = delen[i];
				let deel: VLib.PerimeterDeel = new VLib.PerimeterDeel(deelJson);
				this._perimeterDelen.push(deel);
			}
			//
			// Nu nog velden buur en buurOuder in de segmenten zetten.
			//
			for (let i: number = 0; i < delen.length; i++) {
				let deelJson: VLib.PerimeterDeelJsonType = delen[i];
				let deel: VLib.PerimeterDeel = this._perimeterDelen[i];
				let segmentenJson: VLib.PerimeterSegmentJsonType[] = deelJson.sgmn;
				let segmenten: VLib.PerimeterSegment[] = deel.sgmn;
				for (let j: number = 0; j < segmenten.length; j++) {
					let segmentJson: VLib.PerimeterSegmentJsonType = segmentenJson[j];
					let segment: VLib.PerimeterSegment = segmenten[j];
					if (segmentJson.buur != null) {
						if (segmentJson.buurOuder >= this._perimeterDelen.length) { throw "buurOuder-index is te groot"; };
						segment.buurOuder = this._perimeterDelen[segmentJson.buurOuder];
						if (segmentJson.buur >= segment.buurOuder.sgmn.length) { throw "buur-index is te groot"; };
						segment.buur = segment.buurOuder.sgmn[segmentJson.buur];
					}
				}
			}
			this._perimeterDeelHuidig = this._perimeterDelen[0];
			//
			// Initiatie is geslaagd:
			//
			this._isGeldig = true;
		}
		catch (exc) {
			VLib.Lib.log('Perimeter kon niet ingesteld worden. Foutdetails: ' + exc, 2);
		}
	}

	//
	// Zoek kandidaten op basis van de hoogte:
	//
	private zoekPerimeterDelenOpBasisVanHoogte(zPunt: number, speling: number): VLib.PerimeterDeel[] {
		let delen: VLib.PerimeterDeel[] = this._perimeterDelen;
		let gevonden: VLib.PerimeterDeel[] = [];
		//
		// Zoek kandidaten op basis van de hoogte:
		//
		for (let i: number = 0; i < delen.length; i++) {
			let deel: VLib.PerimeterDeel = delen[i];
			let bb: number[] = deel.bb; // bounding box van deel = [ xMin, xMax, yMin, yMax, zMin, zMax ]

			if (deel.helling == null) {
				//
				// Een horizontaal perimeterdeel.
				//
				if (Math.abs(zPunt - bb[4]) < speling) {
					gevonden.push(deel);
				}
			}
			else {
				//
				// Een schuin perimeterdeel.
				//
				if (zPunt > bb[4] - speling && zPunt < bb[5] + speling) {
					gevonden.push(deel);
				}
			}
		}
		return gevonden;
	}

	//
	// Bepaalt het perimeterdeel waarin het aangeleverde punt ligt. Indien het punt niet in een perimeterdeel ligt, 
	// wordt de dichtstbijzijnde gezocht.
	// Parameter 'punt' is een array van floats met lengte 3 (x-, y- en z-componenten).
	// Return-waarde is een object met het perimeterdeel, de werkelijke positie (x-, y- en z-componenten) IN het 
	// perimeterdeel en eventueel een array van opgetreden triggers; of null wanneer er geen perimeterdeel gevonden is.
	//
	private bepaalPerimeterDeelDichtstbijzijnde(punt: number[]): VLib.PerimeterInfoObjType {
		let perimeterInfoObj: VLib.PerimeterInfoObjType = this.bepaalPerimeterDeel(punt);
		if (perimeterInfoObj == null) {
			//
			// Geef voorkeur aan perimeterdelen die op de hoogte liggen van het aangeleverde punt:
			//
			let kandidaatDelen: VLib.PerimeterDeel[] = this.zoekPerimeterDelenOpBasisVanHoogte(punt[2] - this._ooghoogte, 50.0);
			if (kandidaatDelen.length == 0) {
				kandidaatDelen = this.zoekPerimeterDelenOpBasisVanHoogte(punt[2], 50.0);
				if (kandidaatDelen.length == 0) {
					kandidaatDelen = this._perimeterDelen;
				}
			}

			let hitAfstand2: number = 1e10;
			let hitPunt: number[];
			let hitDeel: VLib.PerimeterDeel;
			for (let j: number = 0; j < kandidaatDelen.length; j++) {
				let deel: VLib.PerimeterDeel = kandidaatDelen[j];
				let segmenten: VLib.PerimeterSegment[] = deel.sgmn;
				for (let k: number = 0; k < segmenten.length; k++) {
					let punten: number[] = segmenten[k].ptn;

					let dichtst: VLib.DichtstbijzijndePuntType = this.bepaalDichtstbijzijndePunt(punten[0], punten[1], punten[2], punten[3],
						punt[0], punt[1], false);
					if (dichtst.afstand2 < hitAfstand2) {
						hitAfstand2 = dichtst.afstand2;
						hitPunt = dichtst.punt;
						hitDeel = deel;
					}
				}
			}
			let hoogte: number = this.bepaalPerimeterDeelHoogte(hitPunt, hitDeel);
			hitPunt.push(hoogte);
			perimeterInfoObj = { deel: hitDeel, positie: hitPunt, triggers: undefined };
		}

		//
		// Noteer de eventueel opgetreden trigger:
		//
		if (this._triggerCallback && perimeterInfoObj != null && perimeterInfoObj.deel.ID != null) {
			perimeterInfoObj.triggers = [perimeterInfoObj.deel.ID, 'enter'];
		}

		return perimeterInfoObj;
	}

	//
	// Bepaalt het perimeterdeel waarin het aangeleverde punt ligt.
	// Parameter 'punt' is een array van floats met lengte 3 (x-, y- en z-componenten).
	// Return-waarde is een object met het perimeterdeel en de werkelijke positie (x-, y- en z-componenten) IN het 
	// perimeterdeel; of null wanneer het punt niet in een perimeterdeel ligt.
	//
	private bepaalPerimeterDeel(punt: number[]): VLib.PerimeterInfoObjType {
		let kandidaten: VLib.PerimeterDeel[] = [];
		let delen: VLib.PerimeterDeel[] = this._perimeterDelen;
		for (let i: number = 0; i < delen.length; i++) {
			let deel: VLib.PerimeterDeel = delen[i];
			if (this.ligtInBoundingBox(punt[0], punt[1], deel.bb) == false) {
				continue;
			}

			let segmenten: VLib.PerimeterSegment[] = deel.sgmn;
			let ligtBuitenPolygoon: boolean = (this.bepaalWindingGetal(punt, segmenten) == 0);
			if (ligtBuitenPolygoon == false) {
				kandidaten.push(deel);
			}
		}

		if (kandidaten.length == 0) {
			return null;
		}
		else {
			let kleinsteVloerAfstand: number = 1e10;
			let besteDeel: VLib.PerimeterDeel;
			let besteDeelHoogte: number;
			for (let i: number = 0; i < kandidaten.length; i++) {
				let kandidaatDeel: VLib.PerimeterDeel = kandidaten[i];
				let deelHoogte: number = this.bepaalPerimeterDeelHoogte(punt, kandidaatDeel);
				let vloerAfstand: number = punt[2] - deelHoogte;
				//
				// Het punt moet boven het perimeterdeel liggen en het dichtstbijzijnde wint:
				//
				if (vloerAfstand >= 0 && vloerAfstand < kleinsteVloerAfstand) {
					kleinsteVloerAfstand = vloerAfstand;
					besteDeel = kandidaatDeel;
					besteDeelHoogte = deelHoogte;
				}
			}
			if (besteDeel) {
				punt = punt.slice();
				punt[2] = besteDeelHoogte;
				let perimeterInfoObj: VLib.PerimeterInfoObjType = { deel: besteDeel, positie: punt, triggers: undefined };
				return perimeterInfoObj;
			}
			else {
				return null;
			}
		}
	}

	//
	// Bepaalt de z-coordinaat van het perimeterdeel onder het aangeleverde punt. 
	// Het punt hoeft niet binnen het perimeterdeel te liggen.
	// Parameter 'punt' is een array van floats met (minimale) lengte 2 (x- en y-componenten).
	// Parameter 'deel' is een perimeterdeel.
	// Return-waarde de z-coordinaat van het perimeterdeel onder het aangeleverde punt.
	//
	private bepaalPerimeterDeelHoogte(punt: number[], deel: VLib.PerimeterDeel): number {
		if (deel.helling == null) {
			//
			// Het perimeterdeel is horizontaal:
			//
			return deel.bb[4]; // zMin
		}
		else {
			//
			// Het perimeterdeel is schuin:
			//
			let projLangsOpwaarts: number = (punt[0] - deel.basis[0]) * deel.opwaarts[0] +
				(punt[1] - deel.basis[1]) * deel.opwaarts[1];
			let z: number = deel.basis[2] + (projLangsOpwaarts * deel.helling);
			return z;
		}
	}

	//
	// Bepaalt het collisiepunt met het perimeterdeel voor de gegeven voorgenomen verplaatsing.
	// Parameter 'huidigDeel' is het perimeterdeel waarin de huidige positie zich bevindt.
	// Parameter 'huidigPunt' is een array van floats met (minimale) lengte 2 (x- en y-componenten) die de huidige 
	// positie aangeeft.
	// Parameter 'nieuwPunt' is een array van floats met (minimale) lengte 2 (x- en y-componenten) die de potentiele 
	// nieuwe positie aangeeft.
	// Optionele parameter 'triggers' is een array van tot dan toe opgetreden triggers.
	// Return-waarde is een object met het perimeterdeel, de werkelijke positie (x-, y- en z-componenten) IN het 
	// perimeterdeel en eventueel een array van opgetreden triggers; of null wanneer er geen resultaat gevonden is.
	//
	private bepaalPerimeterDeelCollisie(huidigDeel: VLib.PerimeterDeel, huidigPunt: number[], nieuwPunt: number[], triggers?: string[]): VLib.PerimeterInfoObjType {
		let xH: number = huidigPunt[0];
		let yH: number = huidigPunt[1];
		let xN: number = nieuwPunt[0];
		let yN: number = nieuwPunt[1];
		let xHNMin: number = Math.min(xH, xN);
		let xHNMax: number = Math.max(xH, xN);
		let yHNMin: number = Math.min(yH, yN);
		let yHNMax: number = Math.max(yH, yN);

		let segmenten: VLib.PerimeterSegment[] = huidigDeel.sgmn;
		for (let i: number = 0; i < segmenten.length; i++) {
			let segment: VLib.PerimeterSegment = segmenten[i];
			let bb: number[] = segment.bb; // bounding box van segment = [ xMin, xMax, yMin, yMax ]

			if (this.overlaptBoundingBox(xHNMin, xHNMax, yHNMin, yHNMax, bb) == false) {
				//
				// Er is geen overlap van bounding boxen, dus geen collisie met dit segment:
				//
				continue;
			}

			//
			// Een collisie met een segment treedt alleen op als de huidige positie links ervan is (of erop) en de nieuwe 
			// positie rechts ervan (of erop).
			// Namelijk, bij een segment van de buitenkant (dat CCW is) geldt dat de huidige positie per definitie links 
			// van het segment ligt (of erop) (anders zou het buiten het perimeterdeel vallen).
			// Bovendien, de huidige positie kan alleen rechts van een segment van een gat (dat CW is) liggen als het IN 
			// het gat ligt (maar dan zou het buiten het perimeterdeel vallen), of wanneer het aan de overkant van het gat 
			// ligt.
			//
			let xB: number = segment.ptn[0];
			let yB: number = segment.ptn[1];
			let xE: number = segment.ptn[2];
			let yE: number = segment.ptn[3];
			let pN: number = this.ligtLinks(xB, yB, xE, yE, xN, yN);
			if (pN > 0) {
				//
				// De nieuwe positie ligt links van het segment, dus geen collisie met dit segment:
				//
				continue;
			}
			let pH: number = this.ligtLinks(xB, yB, xE, yE, xH, yH);
			if (pH < 0) {
				//
				// De huidige positie ligt rechts van het segment, dus geen collisie met dit segment:
				//
				continue;
			}
			if (pN == 0) {
				//
				// De nieuwe positie ligt op de drager van het segment.
				//
				if (this.ligtInBoundingBox(xN, yN, bb) == false) {
					//
					// De nieuwe positie ligt niet op het lijnstuk van het segment zelf, dus geen collisie met dit segment:
					//
					continue;
				}
				else {
					nieuwPunt = nieuwPunt.slice(0, 2)
					let hoogte: number = this.bepaalPerimeterDeelHoogte(nieuwPunt, huidigDeel);
					nieuwPunt.push(hoogte);
					let perimeterInfoObj: VLib.PerimeterInfoObjType = { deel: huidigDeel, positie: nieuwPunt, triggers: undefined };
					if (triggers != null) {
						perimeterInfoObj.triggers = triggers;
					}
					return perimeterInfoObj;
				}
			}
			else {
				//
				// De nieuwe positie ligt rechts van het segment, dus mogelijk is er collisie. 
				// Bepaal het snijpunt met de drager van het segment:
				//
				let snijpunt: number[] = this.bepaalSnijpunt(xB, yB, xE, yE, xH, yH, xN, yN);
				if (snijpunt == null) {
					//
					// Geen snijpunt. Deze situatie zou niet voor mogen komen:
					//
					return null;
				}
				else if (this.ligtInBoundingBox(snijpunt[0], snijpunt[1], bb) == false) {
					//
					// Het snijpunt ligt niet op het lijnstuk van het segment, dus geen collisie met dit segment:
					//
					continue;
				}
				else {
					if (segment.buur != null) {
						//
						// Het segment is doorlatend, dus herhaal de procedure met het naburige perimeterdeel met het 
						// snijpunt als start:
						//
						let buurDeel: VLib.PerimeterDeel = segment.buurOuder;
						if (this._triggerCallback && (huidigDeel.ID != null || buurDeel.ID != null)) {
							if (triggers == null) {
								triggers = [];
							}
							if (huidigDeel.ID != null) {
								triggers.push(huidigDeel.ID, 'exit');
							}
							if (buurDeel.ID != null) {
								triggers.push(buurDeel.ID, 'enter');
							}
						}
						huidigDeel = buurDeel;
						huidigPunt = snijpunt;
						let perimeterInfoObj: VLib.PerimeterInfoObjType = this.bepaalPerimeterDeelCollisie(huidigDeel, huidigPunt, nieuwPunt, triggers);
						return perimeterInfoObj;
					}
					else {
						//
						// Het segment is niet doorlatend.
						//
						let hoek: number = this.bepaalHoek(xB, yB, xE, yE, xH, yH, xN, yN);
						if (hoek > 60.0 && hoek < 120.0) {
							//
							// Stilstand tegen het perimetersegment:
							//
							let hoogte: number = this.bepaalPerimeterDeelHoogte(snijpunt, huidigDeel);
							snijpunt.push(hoogte);
							let perimeterInfoObj: VLib.PerimeterInfoObjType = { deel: huidigDeel, positie: snijpunt, triggers: undefined };
							if (triggers != null) {
								perimeterInfoObj.triggers = triggers;
							}
							return perimeterInfoObj;
						}
						else {
							//
							// Schuiven langs het perimetersegment:
							//
							huidigPunt = snijpunt;
							let proj: VLib.DichtstbijzijndePuntType = this.bepaalDichtstbijzijndePunt(xB, yB, xE, yE, nieuwPunt[0], nieuwPunt[1], true);
							nieuwPunt = proj.punt;
							let perimeterInfoObj: VLib.PerimeterInfoObjType = this.bepaalPerimeterDeelCollisie(huidigDeel, huidigPunt, nieuwPunt, triggers);
							return perimeterInfoObj;
						}
					}
				}
			}
		}
		//
		// Er is geen collisie; het huidige perimeterdeel wordt niet verlaten:
		//
		nieuwPunt = nieuwPunt.slice(0, 2)
		let hoogte: number = this.bepaalPerimeterDeelHoogte(nieuwPunt, huidigDeel);
		nieuwPunt.push(hoogte);
		let perimeterInfoObj: VLib.PerimeterInfoObjType = { deel: huidigDeel, positie: nieuwPunt, triggers: undefined };
		if (triggers != null) {
			perimeterInfoObj.triggers = triggers;
		}
		return perimeterInfoObj;
	}

	//
	// Bepaalt het winding-getal, het getal dat bepaalt hoe vaak een polygoon om een punt draait in het horizontale 
	// vlak. Retourneert nul als het punt buiten de polygoon ligt.
	// Parameter 'punt' is een array van floats met (minimale) lengte 2 (x- en y-componenten).
	// Parameter 'segmenten' is een array van segmenten die de polygoon beschrijven.
	// Return-waarde is het winding-getal. Is nul wanneer het aangeleverde punt buiten de polygoon ligt.
	// 
	// Hoewel in bovenstaande beschrijving gesproken wordt over 'een polygoon', kan deze functie ook gebruikt worden 
	// voor een oppervlak bestaande uit een buitenrand met gaten. Omdat de buitenranden (tegen de klok in) en gatranden 
	// (met de klok mee) tegengestelde draairichtingen hebben, kunnen zij tegelijk verwerkt worden: een punt in een gat 
	// levert een winding-getal nul op omdat de winding-getallen van buitenrand en gatrand een tegengesteld teken 
	// hebben.
	//
	private bepaalWindingGetal(punt: number[], segmenten: VLib.PerimeterSegment[]): number {
		let windingen: number = 0;

		let puntX: number = punt[0];
		let puntY: number = punt[1];
		let segment: VLib.PerimeterSegment;
		let beginX: number;
		let beginY: number;
		let eindX: number;
		let eindY: number;

		for (let i: number = 0; i < segmenten.length; i++) {
			segment = segmenten[i];
			beginX = segment.ptn[0];
			beginY = segment.ptn[1];
			eindX = segment.ptn[2];
			eindY = segment.ptn[3];

			if (beginY <= puntY) {
				if (eindY > puntY) {
					//
					// Het segment kruist het punt opwaarts:
					//
					if (this.ligtLinks(beginX, beginY, eindX, eindY, puntX, puntY) > 0) {
						windingen++;
					}
				}
			}
			else {
				if (eindY <= puntY) {
					//
					// Het segment kruist het punt neerwaarts:
					//
					if (this.ligtLinks(beginX, beginY, eindX, eindY, puntX, puntY) < 0) {
						windingen--;
					}
				}
			}
		}

		return windingen;
	}

	//
	// Bepaalt of een punt links van, rechts van, of op een lijnstuk ligt.
	// Parameters 'beginX', 'beginY', eindX', eindY' zijn floats die het begin- en eindpunt van het lijnstuk aangeven.
	// Parameters 'puntX', 'puntY' zijn floats die het te testen punt aangeven.
	// Return-waarde is een positief getal als het punt links ligt, een negatief getal als het rechts ligt en nul als 
	// het op de lijn ligt.
	//
	private ligtLinks(beginX: number, beginY: number, eindX: number, eindY: number, puntX: number, puntY: number): number {
		//
		// Gebaseerd op de magnitude van het uitproduct A x B, waarbij A het lijnstuk is en B de vector van het 
		// beginpunt van het lijnstuk naar het te testen punt:
		//
		let uitproductMagnitude: number = (eindX - beginX) * (puntY - beginY) -
			(eindY - beginY) * (puntX - beginX);
		return uitproductMagnitude > VLib.Lib._epsilon ? 1 :
			(uitproductMagnitude < -VLib.Lib._epsilon ? -1 : 0);
	}

	//
	// Bepaalt of een punt in een bounding box ligt.
	// Parameters 'puntX', 'puntY' zijn floats die het te testen punt aangeven.
	// Parameter 'bb' is de bounding box.
	//
	private ligtInBoundingBox(puntX: number, puntY: number, bb: number[]): boolean {
		//
		// Een bounding box (x- en y-componenten) is als volgt gedefinieerd: 
		//   [ xMin, xMax, yMin, yMax ]
		//
		return (puntX > bb[0] - VLib.Lib._epsilon &&
			puntX < bb[1] + VLib.Lib._epsilon &&
			puntY > bb[2] - VLib.Lib._epsilon &&
			puntY < bb[3] + VLib.Lib._epsilon);
	}

	//
	// Bepaalt of een rechtboek overlapt met een bounding box ligt.
	// Parameters 'rectXMin', 'rectXMax', 'rectYMin' en 'rectYMax' zijn floats die de te testen rechthoek aangeven.
	// Parameter 'bb' is de bounding box.
	//
	private overlaptBoundingBox(rectXMin: number, rectXMax: number, rectYMin: number, rectYMax: number, bb: number[]): boolean {
		//
		// Een bounding box (x- en y-componenten) is als volgt gedefinieerd: 
		//   [ xMin, xMax, yMin, yMax ]
		//
		return (rectXMax > bb[0] - VLib.Lib._epsilon &&
			rectXMin < bb[1] + VLib.Lib._epsilon &&
			rectYMax > bb[2] - VLib.Lib._epsilon &&
			rectYMin < bb[3] + VLib.Lib._epsilon);
	}

	//
	// Bepaalt het snijpunt tussen de dragers van twee lijnstukken.
	// Parameters 'x1', 'y1', 'x2' en 'y2' zijn floats die het begin- en eindpunt van het lijnstuk A aangeven.
	// Parameters 'x3', 'y3', 'x4' en 'y4' zijn floats die het begin- en eindpunt van het lijnstuk B aangeven.
	// Return-waarde is een array van 2 floats (x- en y-componenten) met het snijpunt, of null wanneer er geen snijpunt 
	// bepaald kon worden.
	//
	private bepaalSnijpunt(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number): number[] {
		//
		// Zie http://en.wikipedia.org/wiki/Line-line_intersection .
		//
		let dx12: number = (x1 - x2);
		let dy12: number = (y1 - y2);
		let dx34: number = (x3 - x4);
		let dy34: number = (y3 - y4);
		let noemer: number = dx12 * dy34 - dy12 * dx34;
		if (Math.abs(noemer) < VLib.Lib._epsilon) {
			//
			// De lijnstukken zijn (pseudo-)parallel of vallen zelfs samen:
			//
			return null;
		}

		let pxy12: number = (x1 * y2 - y1 * x2);
		let pxy34: number = (x3 * y4 - y3 * x4);
		let xSnij: number = (pxy12 * dx34 - dx12 * pxy34) / noemer;
		let ySnij: number = (pxy12 * dy34 - dy12 * pxy34) / noemer;

		return [xSnij, ySnij];
	}

	//
	// Bepaalt de hoek (in graden) tussen de dragers van twee lijnstukken.
	// Parameters 'x1', 'y1', 'x2' en 'y2' zijn floats die het begin- en eindpunt van het lijnstuk A aangeven.
	// Parameters 'x3', 'y3', 'x4' en 'y4' zijn floats die het begin- en eindpunt van het lijnstuk B aangeven.
	// Return-waarde is een getal (in graden).
	//
	private bepaalHoek(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number): number {
		let dx12: number = (x2 - x1);
		let dy12: number = (y2 - y1);
		let dx34: number = (x4 - x3);
		let dy34: number = (y4 - y3);
		let inprod: number = dx12 * dx34 + dy12 * dy34;
		let noemer: number = Math.sqrt((dx12 * dx12 + dy12 * dy12) * (dx34 * dx34 + dy34 * dy34));
		let hoek: number = Math.acos(inprod / noemer) * VLib.Lib._vanRadNaarDeg;

		return hoek;
	}

	//
	// Bepaalt het dichtstbijzijnde punt op het lijnstuk of, desgewenst, op de drager van het lijnstuk.
	// Parameters 'beginX', 'beginY', 'eindX' en 'eindY' zijn floats die het begin- en eindpunt van het lijnstuk 
	// aangeven.
	// Parameter 'puntX' en 'puntY' zijn floats die het punt aangeven.
	// Optionele parameter 'opDrager' is een boolean die aangeeft of het dichtstbijzijnde punt deel moet uitmaken van 
	// het lijnstuk zelf (false) of het enkel op de drager van het lijnstuk hoeft te liggen (true)?
	// Return-waarde is een object met een array van 2 floats (x- en y-componenten) met het dichtstbijzijnde punt en de 
	// gekwadrateerde afstand.
	//
	private bepaalDichtstbijzijndePunt(beginX: number, beginY: number, eindX: number, eindY: number, puntX: number, puntY: number, opDrager: boolean): VLib.DichtstbijzijndePuntType {
		let afstand2: number;
		let punt: number[];
		//
		// Bepaal de projectie van het punt op de lijn:
		//
		let verschilX: number = eindX - beginX;
		let verschilY: number = eindY - beginY;
		let lengte2: number = verschilX * verschilX + verschilY * verschilY;
		let vectorX: number = puntX - beginX;
		let vectorY: number = puntY - beginY;
		let projectieFactor: number = (vectorX * verschilX + vectorY * verschilY) / lengte2;
		if (projectieFactor <= 0.0 && opDrager === false) {
			//
			// Het beginpunt van het lijnstuk is het dichtstbijzijnde punt, 
			// hoewel de drager van het lijnstuk dichterbij is:
			//
			afstand2 = vectorX * vectorX + vectorY * vectorY;
			punt = [beginX, beginY];
		}
		else if (projectieFactor >= 1.0 && opDrager === false) {
			//
			// Het eindpunt van het lijnstuk is het dichtstbijzijnde punt, 
			// hoewel de drager van het lijnstuk dichterbij is:
			//
			vectorX = puntX - eindX;
			vectorY = puntY - eindY;
			afstand2 = vectorX * vectorX + vectorY * vectorY;
			punt = [eindX, eindY];
		}
		else {
			let teller: number = vectorX * verschilY - verschilX * vectorY;
			afstand2 = (teller * teller) / lengte2;
			punt = [beginX + projectieFactor * verschilX, beginY + projectieFactor * verschilY];
		}
		return { punt: punt, afstand2: afstand2 };
	}

	//
	// Private variabelen:
	//
	private _isGeldig: boolean = false;
	private _ooghoogte: number = 1650.0;
	private _triggerCallback: PerimeterTriggerCallbackType = undefined;
	private _perimeterDelen: VLib.PerimeterDeel[] = []; // Niet toegankelijk via een property.
	private _perimeterDeelHuidig: VLib.PerimeterDeel = undefined; // Niet toegankelijk via een property.

}; // class Perimeter

type V3DIdOfHTMLCanvasElementType = string | HTMLCanvasElement;

type Viewer3DManagerGetFunctieType = (canvas: V3DIdOfHTMLCanvasElementType, opties?: Viewer3DOptiesType, nietCreeeren?: boolean) => Viewer3D;
type Viewer3DManagerDisposeFunctieType = (canvas: V3DIdOfHTMLCanvasElementType) => boolean;

type Viewer3DManagerType = { // Bevat een deel van de public functions in class Viewer3DManager.
	get: Viewer3DManagerGetFunctieType,
	dispose: Viewer3DManagerDisposeFunctieType
}

type ModuleViewer3DType = {
	viewer3DManager: Viewer3DManagerType,
	Belichting : typeof Belichting,
	Lamp: typeof Lamp,
	Perimeter: typeof Perimeter
}

new VLib.PolyFills(); // Eenmalig de polyfill functies definiëren.
let viewer3DManager: VLib.Viewer3DManager = new VLib.Viewer3DManager();

//
// ModuleViewer3D is de 'handle' die de buitenwereld gebruikt.
//
export let ModuleViewer3D: ModuleViewer3DType = {
	viewer3DManager: viewer3DManager, // Geeft toegang tot functies get en dispose.
	Belichting: Belichting,
	Lamp: Lamp,
	Perimeter: Perimeter
}
