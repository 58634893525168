/**
 * @ref WoonConnectViewModel/ImageAnnotation/SaveAnnotationModel.cs
 */

 import * as DataContracts from "@datacontracts/SaveAnnotationModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.SaveAnnotationRequestModel
	 */
	 

	export interface ISaveAnnotationRequestModel {
		BoundingBoxes: ImageAnnotationResultElement[];
		ProjectId: number;
		PhotoId: number;
		PersoonId: number;
		ToJsonContract(): DataContracts.ISaveAnnotationRequestModel;
	}

	export class SaveAnnotationRequestModel {
		// property-Properties
		public get BoundingBoxes(): ImageAnnotationResultElement[] {
			return this._BoundingBoxes;
		}
		public set BoundingBoxes(newBoundingBoxes: ImageAnnotationResultElement[]) {
			this._BoundingBoxes = newBoundingBoxes;
		}

		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		public get PhotoId(): number {
			return this._PhotoId;
		}
		public set PhotoId(newPhotoId: number) {
			this._PhotoId = newPhotoId;
		}

		public get PersoonId(): number {
			return this._PersoonId;
		}
		public set PersoonId(newPersoonId: number) {
			this._PersoonId = newPersoonId;
		}

		// field-Properties

		// fields
		protected _BoundingBoxes: ImageAnnotationResultElement[] = [];
		protected _ProjectId: number = 0;
		protected _PhotoId: number = 0;
		protected _PersoonId: number = 0;

		constructor(data?: DataContracts.ISaveAnnotationRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISaveAnnotationRequestModel): void {
			if(data) {
				// vul properties
				this._BoundingBoxes = data.BoundingBoxes ? data.BoundingBoxes.map(imageAnnotationResultElementItem => new ImageAnnotationResultElement(imageAnnotationResultElementItem)): [];
				this._ProjectId = data.ProjectId;
				this._PhotoId = data.PhotoId;
				this._PersoonId = data.PersoonId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISaveAnnotationRequestModel {
			return {
				BoundingBoxes: (this.BoundingBoxes ? this.BoundingBoxes.map(imageAnnotationResultElementItem => new ImageAnnotationResultElement(imageAnnotationResultElementItem).ToJsonContract()): []),
				ProjectId: this.ProjectId,
				PhotoId: this.PhotoId,
				PersoonId: this.PersoonId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.SaveAnnotationResponseModel
	 */
	 

	export interface ISaveAnnotationResponseModel {
		Success: boolean;
	}

	export class SaveAnnotationResponseModel {
		// property-Properties
		public get Success(): boolean {
			return this._Success;
		}
		public set Success(newSuccess: boolean) {
			this._Success = newSuccess;
		}

		// field-Properties

		// fields
		protected _Success: boolean = false;

		constructor(data?: DataContracts.ISaveAnnotationResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISaveAnnotationResponseModel): void {
			if(data) {
				// vul properties
				this._Success = data.Success;
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.ImageAnnotationResultElement
	 */
	 

	export interface IImageAnnotationResultElement {
		Vertices: BoundingBoxVertex[];
		Type: string;
		ToJsonContract(): DataContracts.IImageAnnotationResultElement;
	}

	export class ImageAnnotationResultElement {
		// property-Properties
		public get Vertices(): BoundingBoxVertex[] {
			return this._Vertices;
		}
		public set Vertices(newVertices: BoundingBoxVertex[]) {
			this._Vertices = newVertices;
		}

		public get Type(): string {
			return this._Type;
		}
		public set Type(newType: string) {
			this._Type = newType;
		}

		// field-Properties

		// fields
		protected _Vertices: BoundingBoxVertex[] = [];
		protected _Type: string = "";

		constructor(data?: DataContracts.IImageAnnotationResultElement) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IImageAnnotationResultElement): void {
			if(data) {
				// vul properties
				this._Vertices = data.Vertices ? data.Vertices.map(boundingBoxVertexItem => new BoundingBoxVertex(boundingBoxVertexItem)): [];
				this._Type = data.Type;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IImageAnnotationResultElement {
			return {
				Vertices: (this.Vertices ? this.Vertices.map(boundingBoxVertexItem => new BoundingBoxVertex(boundingBoxVertexItem).ToJsonContract()): []),
				Type: this.Type
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.BoundingBoxVertex
	 */
	 

	export interface IBoundingBoxVertex {
		x: number;
		y: number;
		ToJsonContract(): DataContracts.IBoundingBoxVertex;
	}

	export class BoundingBoxVertex {
		// property-Properties
		public get x(): number {
			return this._x;
		}
		public set x(newx: number) {
			this._x = newx;
		}

		public get y(): number {
			return this._y;
		}
		public set y(newy: number) {
			this._y = newy;
		}

		// field-Properties

		// fields
		protected _x: number = 0;
		protected _y: number = 0;

		constructor(data?: DataContracts.IBoundingBoxVertex) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBoundingBoxVertex): void {
			if(data) {
				// vul properties
				this._x = data.x;
				this._y = data.y;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBoundingBoxVertex {
			return {
				x: this.x,
				y: this.y
			}
		}
	}
