import * as ng from 'angular';
import { Injectable } from '@angular/core';

export interface IFatalErrorService {
	ReloadPage(contextNaarGebruiker: string, contextNaarOntwikkeling: string);

}
@Injectable({
	providedIn: 'root',
})
export class FatalErrorService implements IFatalErrorService {
	constructor(
	) {
		'ngInject';
	}

	ReloadPage(contextNaarGebruiker: string, contextNaarOntwikkeling: string) {
		var melding = "De sessie is verlopen. De pagina wordt nu herladen";

		console.log(contextNaarOntwikkeling);

		if (contextNaarGebruiker != null) {
			melding = contextNaarGebruiker;
		}

		alert(melding);
		window.location.assign(window.location.toString());
		//window.location.assign(window.location.toString());
		document.location.assign(document.location.toString());
	}
}
