/**
 * @ref WoonConnectViewModel/ContractorTradeAssociationViewModel.cs
 */

 import * as DataContracts from "@datacontracts/ContractorTradeAssociationViewModel";

 
import * as DocumentModel from "./DocumentModel";



	/**
	 * Originele namespace: WoonConnectViewModel.ContractorTradeAssociationViewModel
	 */
	 

	export interface IContractorTradeAssociationViewModel {
		Id: number;
		ContractorId: number;
		TradeAssociationId: number;
		Approved: boolean;
		ExplanationByAdministrator: string;
		ExplanationByContractor: string;
		Document: DocumentModel.IDocumentModel;
		ToJsonContract(): DataContracts.IContractorTradeAssociationViewModel;
	}

	export class ContractorTradeAssociationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ContractorId(): number {
			return this._ContractorId;
		}
		public set ContractorId(newContractorId: number) {
			this._ContractorId = newContractorId;
		}

		public get TradeAssociationId(): number {
			return this._TradeAssociationId;
		}
		public set TradeAssociationId(newTradeAssociationId: number) {
			this._TradeAssociationId = newTradeAssociationId;
		}

		public get Approved(): boolean {
			return this._Approved;
		}
		public set Approved(newApproved: boolean) {
			this._Approved = newApproved;
		}

		public get ExplanationByAdministrator(): string {
			return this._ExplanationByAdministrator;
		}
		public set ExplanationByAdministrator(newExplanationByAdministrator: string) {
			this._ExplanationByAdministrator = newExplanationByAdministrator;
		}

		public get ExplanationByContractor(): string {
			return this._ExplanationByContractor;
		}
		public set ExplanationByContractor(newExplanationByContractor: string) {
			this._ExplanationByContractor = newExplanationByContractor;
		}

		public get Document(): DocumentModel.IDocumentModel {
			return this._Document;
		}
		public set Document(newDocument: DocumentModel.IDocumentModel) {
			this._Document = newDocument;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ContractorId: number = 0;
		protected _TradeAssociationId: number = 0;
		protected _Approved: boolean = false;
		protected _ExplanationByAdministrator: string = "";
		protected _ExplanationByContractor: string = "";
		protected _Document: DocumentModel.IDocumentModel = new DocumentModel.DocumentModel(undefined);

		constructor(data?: DataContracts.IContractorTradeAssociationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IContractorTradeAssociationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ContractorId = data.ContractorId;
				this._TradeAssociationId = data.TradeAssociationId;
				this._Approved = data.Approved;
				this._ExplanationByAdministrator = data.ExplanationByAdministrator;
				this._ExplanationByContractor = data.ExplanationByContractor;
				this._Document = data.Document ? new DocumentModel.DocumentModel(data.Document): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IContractorTradeAssociationViewModel {
			return {
				Id: this.Id,
				ContractorId: this.ContractorId,
				TradeAssociationId: this.TradeAssociationId,
				Approved: this.Approved,
				ExplanationByAdministrator: this.ExplanationByAdministrator,
				ExplanationByContractor: this.ExplanationByContractor,
				Document: (this.Document ? new DocumentModel.DocumentModel(this.Document).ToJsonContract(): this.Document)
			}
		}
	}
