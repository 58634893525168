/**
 * @ref WoonConnectViewModel/Cost.cs
 */

 import * as DataContracts from "@datacontracts/Cost";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Cost
	 */
	 

	export interface ICost {
		ExcludingVat: number;
		IncludingVat: number;
		ToJsonContract(): DataContracts.ICost;
	}

	export class Cost {
		// property-Properties
		public get ExcludingVat(): number {
			return this._ExcludingVat;
		}
		public set ExcludingVat(newExcludingVat: number) {
			this._ExcludingVat = newExcludingVat;
		}

		public get IncludingVat(): number {
			return this._IncludingVat;
		}
		public set IncludingVat(newIncludingVat: number) {
			this._IncludingVat = newIncludingVat;
		}

		// field-Properties

		// fields
		protected _ExcludingVat: number = 0;
		protected _IncludingVat: number = 0;

		constructor(data?: DataContracts.ICost) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICost): void {
			if(data) {
				// vul properties
				this._ExcludingVat = data.ExcludingVat;
				this._IncludingVat = data.IncludingVat;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ICost {
			return {
				ExcludingVat: this.ExcludingVat,
				IncludingVat: this.IncludingVat
			}
		}
	}
