<!-- <div *ngIf="Laden">
    Laden...
</div> -->

<!-- <div *ngIf="!Laden"> -->
<div>
    
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>
            <mat-checkbox class="checklist-leaf-node"
                          [checked]="node.Active"
                          (change)="filterChange($event.checked, node )">
                {{node.Name}}
            </mat-checkbox>
      
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>

            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.Name">
                <mat-icon class="mat-icon-rtl-mirror">
                    <i class="{{treeControl.isExpanded(node) ? 'fal fa-chevron-down' : 'fal fa-chevron-right'}}"></i>
                </mat-icon>
            </button>
            <mat-checkbox [checked]="node.Active"
                          [indeterminate]="node.Indeterminate"
                          (change)="filterChange($event.checked, node)">
                               
                {{node.Name}}

            </mat-checkbox>
        </mat-tree-node>
    </mat-tree>



</div>