// [conv] import "(./WctActivatieFormulierPopup).less";
import * as ng from 'angular';
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AccountService } from '../../services/AccountService';
import { AccountActivationStatus } from '@models/AccountActivationStatus';
import { AppConfigService } from '../../infrastructure/app.configservice';

@Component({
    selector: 'wct-activatie-formulier-popup',
    templateUrl: './WctActivatieFormulierPopup.html',
    styleUrls: ['./WctActivatieFormulierPopup.scss']
})
export class WctActivatieFormulierPopupComponent {
    oorzaak: string;
    melding: string;
    invulformulier: boolean = false;

    disclaimerCheckboxIsApproved: boolean = false;
    width: number;
    activatie_emailverplicht: boolean = true;
    username: string = "";
    CheckboxHelptextIsShown: boolean = false;

    constructor(
        private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA)  private data,
        private accountService: AccountService,
        private config: AppConfigService,
        public dialogRef: MatDialogRef<WctActivatieFormulierPopupComponent>) {
'ngInject';

        this.oorzaak = data.oorzaak;
        this.melding = data.melding;
        this.width = data.width;
        this.activatie_emailverplicht = this.config.Software.MetMailBevestiging;
        var act: AccountActivationStatus = this.accountService.GeefActivatieAccount();
        this.username = act.UserName;
    }

    naarBeginscherm() {
        window.location.replace('logonzonderactiveren');
    }

    naarInlogscherm() {
        window.location.replace('');
    }

    closeDialog(success: boolean): void {
        this.dialogRef.close(success);
    }

    geefSupportEmailadres() {
        let emailadres = this.config.Software.SupportEmailAdres;

        return emailadres;
    }

    geefSupportEmailadresLinkToEmailProgram() {
        let emailadres = this.config.Software.SupportEmailAdres;
        let project = "WoonConnect";

        if (this.config.Software.TekstBijWoonconnectLogo !== null && this.config.Software.TekstBijWoonconnectLogo !== "") {
            project = this.config.Software.TekstBijWoonconnectLogo;
        }

        return "mailto: " + emailadres + "?Subject=Vraag%20over%20" + project;
    }

    ControleerDisclaimer() {
        if (this.disclaimerCheckboxIsApproved) {
          this.CheckboxHelptextIsShown = false;
          this.closeDialog(true);
        } else {
          this.CheckboxHelptextIsShown = true;
        }
    }
}
