/**
 * @ref WoonConnectViewModel/ImageAnnotation/GetPhotoSetsFromProjectModel.cs
 */

 import * as DataContracts from "@datacontracts/GetPhotoSetsFromProjectModel";

 
import * as GetPhotoSetsModel from "./GetPhotoSetsModel";



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetPhotoSetsFromProjectRequestModel
	 */
	 

	export interface IGetPhotoSetsFromProjectRequestModel {
		ProjectId: number;
		ToJsonContract(): DataContracts.IGetPhotoSetsFromProjectRequestModel;
	}

	export class GetPhotoSetsFromProjectRequestModel {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		// field-Properties

		// fields
		protected _ProjectId: number = 0;

		constructor(data?: DataContracts.IGetPhotoSetsFromProjectRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetPhotoSetsFromProjectRequestModel): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IGetPhotoSetsFromProjectRequestModel {
			return {
				ProjectId: this.ProjectId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetPhotoSetsFromProjectResponseModel
	 */
	 

	export interface IGetPhotoSetsFromProjectResponseModel {
		PhotoSets: GetPhotoSetsModel.IImageAnnotationPhotoSet[];
	}

	export class GetPhotoSetsFromProjectResponseModel {
		// property-Properties
		public get PhotoSets(): GetPhotoSetsModel.IImageAnnotationPhotoSet[] {
			return this._PhotoSets;
		}
		public set PhotoSets(newPhotoSets: GetPhotoSetsModel.IImageAnnotationPhotoSet[]) {
			this._PhotoSets = newPhotoSets;
		}

		// field-Properties

		// fields
		protected _PhotoSets: GetPhotoSetsModel.IImageAnnotationPhotoSet[] = [];

		constructor(data?: DataContracts.IGetPhotoSetsFromProjectResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetPhotoSetsFromProjectResponseModel): void {
			if(data) {
				// vul properties
				this._PhotoSets = data.PhotoSets ? data.PhotoSets.map(imageAnnotationPhotoSetItem => new GetPhotoSetsModel.ImageAnnotationPhotoSet(imageAnnotationPhotoSetItem)): [];
				// vul fields
			}
		}
	}
