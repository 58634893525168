<div *ngIf="Laden" class="mat-progress-spinner__cont">
  <div style="padding: 20px; margin-left: 220px;">
    <mat-progress-spinner mode="indeterminate" color="#0078be" strokeWidth="2" diameter="50"></mat-progress-spinner>
  </div>
   
   <p>Het productmodel wordt geladen</p>
</div>

<div *ngIf="!ErrorBijLaden" class="fab-product">
   <div class="fab-product__viewer" [ngClass]="{'fab-product__viewer--bestek': BCBProductModel.ViewSelected == 'Bestek', 'fab-product__viewer--suf': BCBProductModel.ViewSelected == 'SUF'}">
      <div class="fab-product__viewer-left-total">
         <div class="fab-product__viewer-left" [ngClass]="{'fab-product__viewer-left--full-width': BCBProductModel.HeeftCADAbstract == true}" #fabProductViewerLeft>
            <div *ngIf="BCBProductModel.ViewSelected == 'Bestek'" class="fab-product__bestek">
               <p [innerHTML]="BCBProductModel.Bestek" #fabProductBestek></p>
            </div>
            <div *ngFor="let BCBProductCADViewModel of BCBProductModel.CAD">
               <div *ngIf="BCBProductModel.ViewSelected == BCBProductCADViewModel.Naam">
                  {{ BCBProductCADViewModel.Naam }}<br />
                  <img src="{{ BCBProductCADViewModel.Waarde }}" />
               </div>
            </div>
         </div>

      </div>
      <div class="fab-product__viewer-right"> 
        <div class="fab-product__kopieer-bestekteksten">
          <table>
            <tr>
              <td>
                <wct-button *ngIf="BCBProductModel.ViewSelected == 'Bestek'" buttonText="Tekst naar klembord" buttonSize="small" buttonIconFa="fal fa-copy" (click)="CopyBestekToClipboard()"></wct-button>
              </td>
              <td>
                <wct-button *ngIf="BCBProductModel.ViewSelected == 'Bestek'" buttonText="Download" buttonSize="small" buttonIconFa="fal fa-download" (click)="DownloadBestek()"></wct-button>
              </td>
            </tr>
            <tr>
              <td>
                <wct-button *ngIf="BCBProductModel.ViewSelected == 'Bestek'" buttonText="STABU SUF naar klembord" buttonSize="small" buttonIconFa="fal fa-copy" (click)="CopySUFToClipboard()"></wct-button>
              </td>
              <td>
                <wct-button *ngIf="BCBProductModel.ViewSelected == 'Bestek'" buttonText="Download STABU SUF" buttonSize="small" buttonIconFa="fal fa-download" (click)="DownloadBestekStabu()"></wct-button>
              </td>
            </tr>
          </table>
          
          <div class="fab-product__viewer-notification">
            <p *ngIf="ShowNotificationCopiedToClipboard">
              Is gekopieerd naar clipboard
            </p>
          </div>
        </div>
      </div>
   </div>
</div>
<div *ngIf="ErrorBijLaden">
   Error bij laden van product...
</div>
