// [conv] import "(./FabBestekMain).less";
import * as _ from 'lodash';
import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from '../../../services/GoogleAnalyticsService';
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
//import { ThemeService } from '../../shared/theme';

@Component({
  selector: 'wct-main',
  templateUrl: './fab-sto-isoned-bestekservice.component.html'
})

export class FabStoIsonedBestekServiceMainComponent {
  Header: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fab: FabrikantenConsumer,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    let params: URLSearchParams = new URLSearchParams(window.location.search);
    let consent: boolean = (/true/i).test(params.get("consent"));
    this.googleAnalyticsService.setAnonymization(!consent);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.sendPageView(document.title, location.href, location.pathname);
      }
    });

    this.fab.GetProjectName_Observable().subscribe((data: any) => {
      var link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = '/Content/KlantSpecifiek/' + data.response + '/favicon.ico';
    });
  }
  public SetTheme() {
    this.fab.GetFabrikantName_Observable().subscribe((data) => {
      let jsonStr = JSON.stringify(data);
      let jsonObj = JSON.parse(jsonStr);
    });
  }
}
