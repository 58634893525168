// [conv] import "(./Users).less";
import { Component, Input, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { UserManagementConsumer } from '@consumers/UserManagementController';
import { UserViewModel, UsersViewModel, UsersCountViewModel } from '@models/UserViewModel';
import {
    UserRetrieveRequestModel, SortOrderViewModel} from '@models/UserRetrieveModel';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Sort } from '../Helpers/page';

interface UserTable{
    UserName : string;
    Id: number;
    Name: string;
    VerificatieCode: string;
    Verificatie: boolean;
    LastSeen: Date;
}



@Component({
    selector: 'users',
  templateUrl: './Users.html'
})
export class WoonUsersComponent {
    displayedColumns: string[] = ['Id', 'UserName', 'Name', 'VerificatieCode', 'LastSeen'];
    Laden: boolean = true;
    UserViewModel: UserViewModel;
    UserRetrieveRequestModel: UserRetrieveRequestModel;
    UsersViewModel: UsersViewModel;
    SortOrderViewModel: SortOrderViewModel = new SortOrderViewModel();
    UsersCountViewModel: UsersCountViewModel = new UsersCountViewModel();
    dataSource = new MatTableDataSource<UserTable>();
    pageOptions = [50, 100, 200, 500, 1000];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    
    constructor(private user: UserManagementConsumer) {
        this.UserRetrieveRequestModel = new UserRetrieveRequestModel();

        this.UserRetrieveRequestModel.Page = 0;
        this.UserRetrieveRequestModel.PageSize = this.pageOptions[0];

        this.Refresh();

    }
    ngOnInit() {
       
      
    }


    public Refresh(): void {
        this.Laden = false;
        if (this.UsersViewModel == undefined) {
            this.UsersViewModel = new UsersViewModel();
        }


        console.log(this.UserRetrieveRequestModel);
        //Get All Users
        this.user.SelectUsers_Observable(this.UserRetrieveRequestModel).subscribe((data) => {

            this.UsersViewModel = new UsersViewModel(data);
            let users_data = [];
            this.UsersViewModel.Users.forEach(elem     => {
               
                let users_node: any = {
                    Id: elem.PersoonId,
                    UserName: elem.UserName,
                    VerificatieCode: elem.VerificatieCode,
                    Verificatie: elem.Verificatie,
                    LastSeen: elem.LastSeen,
                    Name: elem.FirstName + ' ' + elem.LastName
                }
                users_data.push(users_node);
            })
            this.dataSource.data = users_data;
            console.log(this.dataSource);
            this.Laden = true;

        })

        this.user.CountUsers_Observable(this.UsersCountViewModel).subscribe((data) => {
            this.UsersCountViewModel = new UsersCountViewModel(data);
          
        })
    }

    pageChange(event) {
        console.log(event);
        this.UserRetrieveRequestModel.Page = event.pageIndex;
        this.UserRetrieveRequestModel.PageSize = event.pageSize;

        this.Refresh();
    }

    sortData(event) {
        console.log(event);

        this.UserRetrieveRequestModel.sortOrder = event.active;
        this.UserRetrieveRequestModel.sortDirection = event.direction;

        console.log()
        this.Refresh();
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

}
