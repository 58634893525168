/**
 * @ref WoonConnectViewModel/User/WoningViewModel.cs
 */

 import * as DataContracts from "@datacontracts/WoningViewModel";

 
import * as BuildingViewModels from "./BuildingViewModels";
import * as PersonViewModel from "./PersonViewModel";
import * as ProjectsViewModel from "./ProjectsViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.User.ResidencesViewModel
	 */
	 

	export interface IResidencesViewModel {
		Residences: ResidenceViewModel[];
		ToJsonContract(): DataContracts.IResidencesViewModel;
	}

	export class ResidencesViewModel {
		// property-Properties
		public get Residences(): ResidenceViewModel[] {
			return this._Residences;
		}
		public set Residences(newResidences: ResidenceViewModel[]) {
			this._Residences = newResidences;
		}

		// field-Properties

		// fields
		protected _Residences: ResidenceViewModel[] = [];

		constructor(data?: DataContracts.IResidencesViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IResidencesViewModel): void {
			if(data) {
				// vul properties
				this._Residences = data.Residences ? data.Residences.map(residenceViewModelItem => new ResidenceViewModel(residenceViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IResidencesViewModel {
			return {
				Residences: (this.Residences ? this.Residences.map(residenceViewModelItem => new ResidenceViewModel(residenceViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.WoningViewModel
	 */
	 

	export interface IWoningViewModel {
		Id: number;
		Project: string;
		Adres: BuildingViewModels.IAddressViewModel;
		Ingediend: Date;
		HoofdBewoner: string;
		HoofdBewonerNaam: string;
		ComplexNaam: string;
		Bewoners: string;
		BlokId: number;
		MaximaleHuurverhoging: number;
		ToJsonContract(): DataContracts.IWoningViewModel;
	}

	export class WoningViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Project(): string {
			return this._Project;
		}
		public set Project(newProject: string) {
			this._Project = newProject;
		}

		public get Adres(): BuildingViewModels.IAddressViewModel {
			return this._Adres;
		}
		public set Adres(newAdres: BuildingViewModels.IAddressViewModel) {
			this._Adres = newAdres;
		}

		public get Ingediend(): Date {
			return this._Ingediend;
		}
		public set Ingediend(newIngediend: Date) {
			this._Ingediend = newIngediend;
		}

		public get HoofdBewoner(): string {
			return this._HoofdBewoner;
		}
		public set HoofdBewoner(newHoofdBewoner: string) {
			this._HoofdBewoner = newHoofdBewoner;
		}

		public get HoofdBewonerNaam(): string {
			return this._HoofdBewonerNaam;
		}
		public set HoofdBewonerNaam(newHoofdBewonerNaam: string) {
			this._HoofdBewonerNaam = newHoofdBewonerNaam;
		}

		public get ComplexNaam(): string {
			return this._ComplexNaam;
		}
		public set ComplexNaam(newComplexNaam: string) {
			this._ComplexNaam = newComplexNaam;
		}

		public get Bewoners(): string {
			return this._Bewoners;
		}
		public set Bewoners(newBewoners: string) {
			this._Bewoners = newBewoners;
		}

		public get BlokId(): number {
			return this._BlokId;
		}
		public set BlokId(newBlokId: number) {
			this._BlokId = newBlokId;
		}

		public get MaximaleHuurverhoging(): number {
			return this._MaximaleHuurverhoging;
		}
		public set MaximaleHuurverhoging(newMaximaleHuurverhoging: number) {
			this._MaximaleHuurverhoging = newMaximaleHuurverhoging;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Project: string = "";
		protected _Adres: BuildingViewModels.IAddressViewModel = new BuildingViewModels.AddressViewModel(undefined);
		protected _Ingediend: Date = new Date();
		protected _HoofdBewoner: string = "";
		protected _HoofdBewonerNaam: string = "";
		protected _ComplexNaam: string = "";
		protected _Bewoners: string = "";
		protected _BlokId: number = 0;
		protected _MaximaleHuurverhoging: number = 0;

		constructor(data?: DataContracts.IWoningViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IWoningViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Project = data.Project;
				this._Adres = data.Adres ? new BuildingViewModels.AddressViewModel(data.Adres): undefined;
				this._Ingediend = data.Ingediend;
				this._HoofdBewoner = data.HoofdBewoner;
				this._HoofdBewonerNaam = data.HoofdBewonerNaam;
				this._ComplexNaam = data.ComplexNaam;
				this._Bewoners = data.Bewoners;
				this._BlokId = data.BlokId;
				this._MaximaleHuurverhoging = data.MaximaleHuurverhoging;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IWoningViewModel {
			return {
				Id: this.Id,
				Project: this.Project,
				Adres: (this.Adres ? new BuildingViewModels.AddressViewModel(this.Adres).ToJsonContract(): this.Adres),
				Ingediend: this.Ingediend,
				HoofdBewoner: this.HoofdBewoner,
				HoofdBewonerNaam: this.HoofdBewonerNaam,
				ComplexNaam: this.ComplexNaam,
				Bewoners: this.Bewoners,
				BlokId: this.BlokId,
				MaximaleHuurverhoging: this.MaximaleHuurverhoging
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.StreetViewModel
	 */
	 

	export interface IStreetViewModel {
		Id: number;
		Straat: string;
		AdresId: number;
		WijkId: number;
		EzieRegistratieId: number;
		Status: number;
		ToJsonContract(): DataContracts.IStreetViewModel;
	}

	export class StreetViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Straat(): string {
			return this._Straat;
		}
		public set Straat(newStraat: string) {
			this._Straat = newStraat;
		}

		public get AdresId(): number {
			return this._AdresId;
		}
		public set AdresId(newAdresId: number) {
			this._AdresId = newAdresId;
		}

		public get WijkId(): number {
			return this._WijkId;
		}
		public set WijkId(newWijkId: number) {
			this._WijkId = newWijkId;
		}

		public get EzieRegistratieId(): number {
			return this._EzieRegistratieId;
		}
		public set EzieRegistratieId(newEzieRegistratieId: number) {
			this._EzieRegistratieId = newEzieRegistratieId;
		}

		public get Status(): number {
			return this._Status;
		}
		public set Status(newStatus: number) {
			this._Status = newStatus;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Straat: string = "";
		protected _AdresId: number = 0;
		protected _WijkId: number = 0;
		protected _EzieRegistratieId: number = 0;
		protected _Status: number = 0;

		constructor(data?: DataContracts.IStreetViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IStreetViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Straat = data.Straat;
				this._AdresId = data.AdresId;
				this._WijkId = data.WijkId;
				this._EzieRegistratieId = data.EzieRegistratieId;
				this._Status = data.Status;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IStreetViewModel {
			return {
				Id: this.Id,
				Straat: this.Straat,
				AdresId: this.AdresId,
				WijkId: this.WijkId,
				EzieRegistratieId: this.EzieRegistratieId,
				Status: this.Status
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.ResidenceViewModel
	 */
	 

	export interface IResidenceViewModel {
		Id: number;
		WoningId: number;
		Resident: PersonViewModel.IPersonViewModel;
		Status: number;
		Relation: string;
		StartHoofdBewoner: Date;
		EindeHoofdBewoner: Date;
		ToJsonContract(): DataContracts.IResidenceViewModel;
	}

	export class ResidenceViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get WoningId(): number {
			return this._WoningId;
		}
		public set WoningId(newWoningId: number) {
			this._WoningId = newWoningId;
		}

		public get Resident(): PersonViewModel.IPersonViewModel {
			return this._Resident;
		}
		public set Resident(newResident: PersonViewModel.IPersonViewModel) {
			this._Resident = newResident;
		}

		public get Status(): number {
			return this._Status;
		}
		public set Status(newStatus: number) {
			this._Status = newStatus;
		}

		public get Relation(): string {
			return this._Relation;
		}
		public set Relation(newRelation: string) {
			this._Relation = newRelation;
		}

		public get StartHoofdBewoner(): Date {
			return this._StartHoofdBewoner;
		}
		public set StartHoofdBewoner(newStartHoofdBewoner: Date) {
			this._StartHoofdBewoner = newStartHoofdBewoner;
		}

		public get EindeHoofdBewoner(): Date {
			return this._EindeHoofdBewoner;
		}
		public set EindeHoofdBewoner(newEindeHoofdBewoner: Date) {
			this._EindeHoofdBewoner = newEindeHoofdBewoner;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _WoningId: number = 0;
		protected _Resident: PersonViewModel.IPersonViewModel = new PersonViewModel.PersonViewModel(undefined);
		protected _Status: number = 0;
		protected _Relation: string = "";
		protected _StartHoofdBewoner: Date = new Date();
		protected _EindeHoofdBewoner: Date = new Date();

		constructor(data?: DataContracts.IResidenceViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IResidenceViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._WoningId = data.WoningId;
				this._Resident = data.Resident ? new PersonViewModel.PersonViewModel(data.Resident): undefined;
				this._Status = data.Status;
				this._Relation = data.Relation;
				this._StartHoofdBewoner = data.StartHoofdBewoner;
				this._EindeHoofdBewoner = data.EindeHoofdBewoner;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IResidenceViewModel {
			return {
				Id: this.Id,
				WoningId: this.WoningId,
				Resident: (this.Resident ? new PersonViewModel.PersonViewModel(this.Resident).ToJsonContract(): this.Resident),
				Status: this.Status,
				Relation: this.Relation,
				StartHoofdBewoner: this.StartHoofdBewoner,
				EindeHoofdBewoner: this.EindeHoofdBewoner
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.DistrictViewModel
	 */
	 

	export interface IDistrictViewModel {
		Id: number;
		Naam: string;
		Project: ProjectsViewModel.IProjectsViewModel;
		SituatieTekening: string;
		SituatieDhg: string;
		SituatieTekeningWidth: number;
		SituatieTekeningHeight: number;
		DomeAfbeelding: string;
		DomePositie: string;
		UniekeGUID: string;
		OuderWijkId: number;
		WijkNiveau: number;
		SituatieDhgNieuw: string;
		ToJsonContract(): DataContracts.IDistrictViewModel;
	}

	export class DistrictViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Naam(): string {
			return this._Naam;
		}
		public set Naam(newNaam: string) {
			this._Naam = newNaam;
		}

		public get Project(): ProjectsViewModel.IProjectsViewModel {
			return this._Project;
		}
		public set Project(newProject: ProjectsViewModel.IProjectsViewModel) {
			this._Project = newProject;
		}

		public get SituatieTekening(): string {
			return this._SituatieTekening;
		}
		public set SituatieTekening(newSituatieTekening: string) {
			this._SituatieTekening = newSituatieTekening;
		}

		public get SituatieDhg(): string {
			return this._SituatieDhg;
		}
		public set SituatieDhg(newSituatieDhg: string) {
			this._SituatieDhg = newSituatieDhg;
		}

		public get SituatieTekeningWidth(): number {
			return this._SituatieTekeningWidth;
		}
		public set SituatieTekeningWidth(newSituatieTekeningWidth: number) {
			this._SituatieTekeningWidth = newSituatieTekeningWidth;
		}

		public get SituatieTekeningHeight(): number {
			return this._SituatieTekeningHeight;
		}
		public set SituatieTekeningHeight(newSituatieTekeningHeight: number) {
			this._SituatieTekeningHeight = newSituatieTekeningHeight;
		}

		public get DomeAfbeelding(): string {
			return this._DomeAfbeelding;
		}
		public set DomeAfbeelding(newDomeAfbeelding: string) {
			this._DomeAfbeelding = newDomeAfbeelding;
		}

		public get DomePositie(): string {
			return this._DomePositie;
		}
		public set DomePositie(newDomePositie: string) {
			this._DomePositie = newDomePositie;
		}

		public get UniekeGUID(): string {
			return this._UniekeGUID;
		}
		public set UniekeGUID(newUniekeGUID: string) {
			this._UniekeGUID = newUniekeGUID;
		}

		public get OuderWijkId(): number {
			return this._OuderWijkId;
		}
		public set OuderWijkId(newOuderWijkId: number) {
			this._OuderWijkId = newOuderWijkId;
		}

		public get WijkNiveau(): number {
			return this._WijkNiveau;
		}
		public set WijkNiveau(newWijkNiveau: number) {
			this._WijkNiveau = newWijkNiveau;
		}

		public get SituatieDhgNieuw(): string {
			return this._SituatieDhgNieuw;
		}
		public set SituatieDhgNieuw(newSituatieDhgNieuw: string) {
			this._SituatieDhgNieuw = newSituatieDhgNieuw;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Naam: string = "";
		protected _Project: ProjectsViewModel.IProjectsViewModel = new ProjectsViewModel.ProjectsViewModel(undefined);
		protected _SituatieTekening: string = "";
		protected _SituatieDhg: string = "";
		protected _SituatieTekeningWidth: number = 0;
		protected _SituatieTekeningHeight: number = 0;
		protected _DomeAfbeelding: string = "";
		protected _DomePositie: string = "";
		protected _UniekeGUID: string = "";
		protected _OuderWijkId: number = 0;
		protected _WijkNiveau: number = 0;
		protected _SituatieDhgNieuw: string = "";

		constructor(data?: DataContracts.IDistrictViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDistrictViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Naam = data.Naam;
				this._Project = data.Project ? new ProjectsViewModel.ProjectsViewModel(data.Project): undefined;
				this._SituatieTekening = data.SituatieTekening;
				this._SituatieDhg = data.SituatieDhg;
				this._SituatieTekeningWidth = data.SituatieTekeningWidth;
				this._SituatieTekeningHeight = data.SituatieTekeningHeight;
				this._DomeAfbeelding = data.DomeAfbeelding;
				this._DomePositie = data.DomePositie;
				this._UniekeGUID = data.UniekeGUID;
				this._OuderWijkId = data.OuderWijkId;
				this._WijkNiveau = data.WijkNiveau;
				this._SituatieDhgNieuw = data.SituatieDhgNieuw;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDistrictViewModel {
			return {
				Id: this.Id,
				Naam: this.Naam,
				Project: (this.Project ? new ProjectsViewModel.ProjectsViewModel(this.Project).ToJsonContract(): this.Project),
				SituatieTekening: this.SituatieTekening,
				SituatieDhg: this.SituatieDhg,
				SituatieTekeningWidth: this.SituatieTekeningWidth,
				SituatieTekeningHeight: this.SituatieTekeningHeight,
				DomeAfbeelding: this.DomeAfbeelding,
				DomePositie: this.DomePositie,
				UniekeGUID: this.UniekeGUID,
				OuderWijkId: this.OuderWijkId,
				WijkNiveau: this.WijkNiveau,
				SituatieDhgNieuw: this.SituatieDhgNieuw
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.DistrictsViewModel
	 */
	 

	export interface IDistrictsViewModel {
		Districts: DistrictViewModel[];
		ToJsonContract(): DataContracts.IDistrictsViewModel;
	}

	export class DistrictsViewModel {
		// property-Properties
		public get Districts(): DistrictViewModel[] {
			return this._Districts;
		}
		public set Districts(newDistricts: DistrictViewModel[]) {
			this._Districts = newDistricts;
		}

		// field-Properties

		// fields
		protected _Districts: DistrictViewModel[] = [];

		constructor(data?: DataContracts.IDistrictsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDistrictsViewModel): void {
			if(data) {
				// vul properties
				this._Districts = data.Districts ? data.Districts.map(districtViewModelItem => new DistrictViewModel(districtViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDistrictsViewModel {
			return {
				Districts: (this.Districts ? this.Districts.map(districtViewModelItem => new DistrictViewModel(districtViewModelItem).ToJsonContract()): [])
			}
		}
	}
