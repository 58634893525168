import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CategoryComponent {

  public ShowCallToAction: boolean = false;

  constructor(public router: Router) {

  }

  public ContactOpen(): void {
    window.open('https://www.reynaers.nl/contact', '_blank');
  }

}
