/// <reference path='../consumerinterfaces/DrawSceneController.ts' />
/**
 * @ref WoonConnectApiController/Scene/DrawSceneController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/DrawSceneController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as DrawSceneModel from "@datacontracts/DrawSceneModel";
import * as CreateSceneModel from "@datacontracts/CreateSceneModel";
import * as CalculateFragmentModel from "@datacontracts/CalculateFragmentModel";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class DrawSceneConsumer implements Interface.IDrawSceneConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public DrawScene_Observable(inputModel: DrawSceneModel.IDrawSceneRequestModel & {ToJsonContract(noContent?: boolean): DrawSceneModel.IDrawSceneRequestModel}): Observable<DrawSceneModel.IDrawSceneResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/DrawScene/DrawScene`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<DrawSceneModel.IDrawSceneResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DrawScene(inputModel: DrawSceneModel.IDrawSceneRequestModel & {ToJsonContract(noContent?: boolean): DrawSceneModel.IDrawSceneRequestModel}): ng.IPromise<DrawSceneModel.IDrawSceneResponseModel> {
				let obs = this.DrawScene_Observable(inputModel);
				let promise = new Promise<DrawSceneModel.IDrawSceneResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public CreateScene_Observable(inputModel: CreateSceneModel.ICreateSceneRequestModel & {ToJsonContract(noContent?: boolean): CreateSceneModel.ICreateSceneRequestModel}): Observable<CreateSceneModel.ICreateSceneResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/DrawScene/CreateScene`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<CreateSceneModel.ICreateSceneResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public CreateScene(inputModel: CreateSceneModel.ICreateSceneRequestModel & {ToJsonContract(noContent?: boolean): CreateSceneModel.ICreateSceneRequestModel}): ng.IPromise<CreateSceneModel.ICreateSceneResponseModel> {
				let obs = this.CreateScene_Observable(inputModel);
				let promise = new Promise<CreateSceneModel.ICreateSceneResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public CalculateFragment_Observable(inputModel: CalculateFragmentModel.ICalculateFragmentRequestModel & {ToJsonContract(noContent?: boolean): CalculateFragmentModel.ICalculateFragmentRequestModel}): Observable<CalculateFragmentModel.ICalculateFragmentResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/DrawScene/CalculateFragment`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<CalculateFragmentModel.ICalculateFragmentResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public CalculateFragment(inputModel: CalculateFragmentModel.ICalculateFragmentRequestModel & {ToJsonContract(noContent?: boolean): CalculateFragmentModel.ICalculateFragmentRequestModel}): ng.IPromise<CalculateFragmentModel.ICalculateFragmentResponseModel> {
				let obs = this.CalculateFragment_Observable(inputModel);
				let promise = new Promise<CalculateFragmentModel.ICalculateFragmentResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


