/**
 * @ref WoonConnectViewModel/ImageAnnotation/AddNewElementModel.cs
 */

 import * as DataContracts from "@datacontracts/AddNewElementModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.AddNewElementRequestModel
	 */
	 

	export interface IAddNewElementRequestModel {
		elementName: string;
		ToJsonContract(): DataContracts.IAddNewElementRequestModel;
	}

	export class AddNewElementRequestModel {
		// property-Properties
		public get elementName(): string {
			return this._elementName;
		}
		public set elementName(newelementName: string) {
			this._elementName = newelementName;
		}

		// field-Properties

		// fields
		protected _elementName: string = "";

		constructor(data?: DataContracts.IAddNewElementRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddNewElementRequestModel): void {
			if(data) {
				// vul properties
				this._elementName = data.elementName;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAddNewElementRequestModel {
			return {
				elementName: this.elementName
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.AddNewElementResponseModel
	 */
	 

	export interface IAddNewElementResponseModel {
		Succeeded: boolean;
	}

	export class AddNewElementResponseModel {
		// property-Properties
		public get Succeeded(): boolean {
			return this._Succeeded;
		}
		public set Succeeded(newSucceeded: boolean) {
			this._Succeeded = newSucceeded;
		}

		// field-Properties

		// fields
		protected _Succeeded: boolean = false;

		constructor(data?: DataContracts.IAddNewElementResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddNewElementResponseModel): void {
			if(data) {
				// vul properties
				this._Succeeded = data.Succeeded;
				// vul fields
			}
		}
	}
