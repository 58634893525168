import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './shared/services/user.service';
import { tap } from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userservice: UserService, private router: Router) {}
  //constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.userservice.getAuthorizationToken();

    if (authToken != null)
    {
        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`)
        });
        return next.handle(authReq).pipe( tap(() => {},
        (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
           return;
          }
          this.userservice.logout();
          this.router.navigate(['/accountlogin']);
        }
      }));

    }

    return next.handle(req);
  }
}