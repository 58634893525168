/**
 * @ref WoonConnectViewModel/Survey/GetSurveyModel.cs
 */

 import * as DataContracts from "@datacontracts/GetSurveyModel";

 
import * as WoningSelectie from "./WoningSelectie";
import * as DwellingSurvey from "./DwellingSurvey";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.GetSurveyRequestModel
	 */
	 

	export interface IGetSurveyRequestModel {
		Dwelling: WoningSelectie.IWoningSelectie;
		ToJsonContract(): DataContracts.IGetSurveyRequestModel;
	}

	export class GetSurveyRequestModel {
		// property-Properties
		public get Dwelling(): WoningSelectie.IWoningSelectie {
			return this._Dwelling;
		}
		public set Dwelling(newDwelling: WoningSelectie.IWoningSelectie) {
			this._Dwelling = newDwelling;
		}

		// field-Properties

		// fields
		protected _Dwelling: WoningSelectie.IWoningSelectie = new WoningSelectie.WoningSelectie(undefined);

		constructor(data?: DataContracts.IGetSurveyRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetSurveyRequestModel): void {
			if(data) {
				// vul properties
				this._Dwelling = data.Dwelling ? new WoningSelectie.WoningSelectie(data.Dwelling): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IGetSurveyRequestModel {
			return {
				Dwelling: (this.Dwelling ? new WoningSelectie.WoningSelectie(this.Dwelling).ToJsonContract(): this.Dwelling)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Survey.GetSurveyResponseModel
	 */
	 

	export interface IGetSurveyResponseModel {
		DwellingSurvey: DwellingSurvey.IDwellingSurvey;
	}

	export class GetSurveyResponseModel {
		// property-Properties
		public get DwellingSurvey(): DwellingSurvey.IDwellingSurvey {
			return this._DwellingSurvey;
		}
		public set DwellingSurvey(newDwellingSurvey: DwellingSurvey.IDwellingSurvey) {
			this._DwellingSurvey = newDwellingSurvey;
		}

		// field-Properties

		// fields
		protected _DwellingSurvey: DwellingSurvey.IDwellingSurvey = new DwellingSurvey.DwellingSurvey(undefined);

		constructor(data?: DataContracts.IGetSurveyResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetSurveyResponseModel): void {
			if(data) {
				// vul properties
				this._DwellingSurvey = data.DwellingSurvey ? new DwellingSurvey.DwellingSurvey(data.DwellingSurvey): undefined;
				// vul fields
			}
		}
	}
