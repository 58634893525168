/**
 * @ref WoonConnectViewModel/CostBandwidth.cs
 */

 import * as DataContracts from "@datacontracts/CostBandwidth";

 
import * as Cost from "./Cost";



	/**
	 * Originele namespace: WoonConnectViewModel.CostBandwidth
	 */
	 

	export interface ICostBandwidth {
		Minimum: Cost.ICost;
		Maximum: Cost.ICost;
		ToJsonContract(): DataContracts.ICostBandwidth;
	}

	export class CostBandwidth {
		// property-Properties
		public get Minimum(): Cost.ICost {
			return this._Minimum;
		}
		public set Minimum(newMinimum: Cost.ICost) {
			this._Minimum = newMinimum;
		}

		public get Maximum(): Cost.ICost {
			return this._Maximum;
		}
		public set Maximum(newMaximum: Cost.ICost) {
			this._Maximum = newMaximum;
		}

		// field-Properties

		// fields
		protected _Minimum: Cost.ICost = new Cost.Cost(undefined);
		protected _Maximum: Cost.ICost = new Cost.Cost(undefined);

		constructor(data?: DataContracts.ICostBandwidth) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICostBandwidth): void {
			if(data) {
				// vul properties
				this._Minimum = data.Minimum ? new Cost.Cost(data.Minimum): undefined;
				this._Maximum = data.Maximum ? new Cost.Cost(data.Maximum): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ICostBandwidth {
			return {
				Minimum: (this.Minimum ? new Cost.Cost(this.Minimum).ToJsonContract(): this.Minimum),
				Maximum: (this.Maximum ? new Cost.Cost(this.Maximum).ToJsonContract(): this.Maximum)
			}
		}
	}
