/**
 * @ref WoonConnectViewModel/User/ProjectsViewModel.cs
 */

 import * as DataContracts from "@datacontracts/ProjectsViewModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.User.ProjectsViewsModel
	 */
	 

	export interface IProjectsViewsModel {
		Projects: ProjectsViewModel[];
		ToJsonContract(): DataContracts.IProjectsViewsModel;
	}

	export class ProjectsViewsModel {
		// property-Properties
		public get Projects(): ProjectsViewModel[] {
			return this._Projects;
		}
		public set Projects(newProjects: ProjectsViewModel[]) {
			this._Projects = newProjects;
		}

		// field-Properties

		// fields
		protected _Projects: ProjectsViewModel[] = [];

		constructor(data?: DataContracts.IProjectsViewsModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProjectsViewsModel): void {
			if(data) {
				// vul properties
				this._Projects = data.Projects ? data.Projects.map(projectsViewModelItem => new ProjectsViewModel(projectsViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProjectsViewsModel {
			return {
				Projects: (this.Projects ? this.Projects.map(projectsViewModelItem => new ProjectsViewModel(projectsViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.ProjectsViewModel
	 */
	 

	export interface IProjectsViewModel {
		Id: number;
		Naam: string;
		Type: string;
		Website: string;
		ToJsonContract(): DataContracts.IProjectsViewModel;
	}

	export class ProjectsViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Naam(): string {
			return this._Naam;
		}
		public set Naam(newNaam: string) {
			this._Naam = newNaam;
		}

		public get Type(): string {
			return this._Type;
		}
		public set Type(newType: string) {
			this._Type = newType;
		}

		public get Website(): string {
			return this._Website;
		}
		public set Website(newWebsite: string) {
			this._Website = newWebsite;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Naam: string = "";
		protected _Type: string = "";
		protected _Website: string = "";

		constructor(data?: DataContracts.IProjectsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProjectsViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Naam = data.Naam;
				this._Type = data.Type;
				this._Website = data.Website;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProjectsViewModel {
			return {
				Id: this.Id,
				Naam: this.Naam,
				Type: this.Type,
				Website: this.Website
			}
		}
	}
