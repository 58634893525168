import { InjectionToken } from "@angular/core";

//export const Viewer3DService = new InjectionToken("Viewer3DService");
//export const DownloadService = new InjectionToken("DownloadService");
export const DocumentService = new InjectionToken("DocumentService");
//export const WoningEventService = new InjectionToken("WoningEventService");
//export const AccountService = new InjectionToken("AccountService");
export const EnergieService = new InjectionToken("EnergieService");
export const ConstSoftware = new InjectionToken("ConstSoftware");
export const AfbeeldingControlerenService = new InjectionToken("AfbeeldingControlerenService");
export const OptieService = new InjectionToken("OptieService");
export const OptieOverzichtService = new InjectionToken("OptieOverzichtService");
export const StateService = new InjectionToken("StateService");
export const WoningInformatieService = new InjectionToken("WoningInformatieService");
//export const WoningService = new InjectionToken("WoningService");
export const TranslateService = new InjectionToken("TranslateService");
export const TopMenuService = new InjectionToken("TopMenuService");
export const FormatService = new InjectionToken("FormatService");
export const EnergieAdviesComparisonTableService = new InjectionToken("EnergieAdviesComparisonTableService");
export const EnergieAdviesService = new InjectionToken("EnergieAdviesService");
export const BewonerKostenService = new InjectionToken("BewonerKostenService");
export const ModelService = new InjectionToken("ModelService");
export const MenuService = new InjectionToken("MenuService");
export const LogService = new InjectionToken("LogService");
export const ProjectService = new InjectionToken("ProjectService");

export function viewer3DServiceFactory(i: any) {
	return i.get('viewer3dservice');
}

// export function woningEventServiceFactory(i: any) {
// 	return i.get('woningeventservice');
// }

// export function downloadServiceFactory(i: any) {
// 	return i.get('downloadservice');
// }

export function documentServiceFactory(i: any) {
   return i.get('documentservice');
}


export function accountServiceFactory(i: any) {
	return i.get('accountservice');
}

export function energieServiceFactory(i: any) {
	return i.get('energieservice');
}

export function constSoftwareFactory(i: any) {
	return i.get('constsoftware');
}

export function afbeeldingControlerenServiceFactory (i: any) {
	return i.get('afbeeldingcontrolerenservice');
}

export function optieServiceFactory(i: any) {
	return i.get('optieservice');
}

export function optieOverzichtServiceFactory(i: any) {
	return i.get('optieoverzichtservice');
}

export function stateServiceFactory(i: any) {
	return i.get('stateservice');
}

export function woningInformatieServiceFactory(i: any) {
	return i.get('woninginformatieservice');
}

// export function woningServiceFactory(i: any) {
// 	return i.get('woningService');
// }

export function translateServiceFactory(i: any) {
	return i.get('translateservice');
}

export function topMenuServiceFactory(i: any) {
	return i.get('topmenuservice');
}

export function formatServiceFactory(i: any) {
	return i.get('formatservice');
}

export function energieAdviesComparisonTableServiceFactory(i: any) {
	return i.get('energieadviescomparisontableservice');
}

export function energieAdviesServiceFactory(i: any) {
	return i.get('energieadviesservice');
}

export function bewonerKostenServiceFactory(i: any) {
	return i.get('bewonerkostenservice');
}

export function modelServiceFactory(i: any) {
	return i.get('modelservice');
}

export function menuServiceFactory(i: any) {
	return i.get('menuservice');
}

export function logServiceFactory(i: any) {
	return i.get('logservice');
}

export function projectServiceFactory(i: any) {
	return i.get('projectservice');
}

export const energieServiceProvider = {
	provide: EnergieService,
	useFactory: energieServiceFactory,
	deps: ['$injector']
};

export const constSoftwareProvider = {
	provide: ConstSoftware,
	useFactory: constSoftwareFactory,
	deps: ['$injector']
};

export const afbeeldingControlerenServiceProvider = {
	provide: AfbeeldingControlerenService,
	useFactory: afbeeldingControlerenServiceFactory,
	deps: ['$injector']
};

export const optieServiceProvider = {
	provide: OptieService,
	useFactory: optieServiceFactory,
	deps: ['$injector']
};

export const optieOverzichtServiceProvider = {
	provide: OptieOverzichtService,
	useFactory: optieOverzichtServiceFactory,
	deps: ['$injector']
};

export const stateServiceProvider = {
	provide: StateService,
	useFactory: stateServiceFactory,
	deps: ['$injector']
};

export const woningInformatieServiceProvider = {
	provide: WoningInformatieService,
	useFactory: woningInformatieServiceFactory,
	deps: ['$injector']
};

export const translateServiceProvider = {
	provide: TranslateService,
	useFactory: translateServiceFactory,
	deps: ['$injector']
};

export const topMenuServiceProvider = {
	provide: TopMenuService,
	useFactory: topMenuServiceFactory,
	deps: ['$injector']
};

export const formatServiceProvider = {
	provide: FormatService,
	useFactory: formatServiceFactory,
	deps: ['$injector']
};

export const energieAdviesServiceProvider = {
	provide: EnergieAdviesService,
	useFactory: energieAdviesServiceFactory,
	deps: ['$injector']
};

export const energieAdviesComparisonTableServiceProvider = {
	provide: EnergieAdviesComparisonTableService,
	useFactory: energieAdviesComparisonTableServiceFactory,
	deps: ['$injector']
};

export const bewonerKostenServiceProvider = {
	provide: BewonerKostenService,
	useFactory: bewonerKostenServiceFactory,
	deps: ['$injector']
};

export const modelServiceProvider = {
	provide: ModelService,
	useFactory: modelServiceFactory,
	deps: ['$injector']
};

export const menuServiceProvider = {
	provide: MenuService,
	useFactory: menuServiceFactory,
	deps: ['$injector']
};

export const logServiceProvider = {
	provide: LogService,
	useFactory: logServiceFactory,
	deps: ['$injector']
};

export const projectServiceProvider = {
	provide: ProjectService,
	useFactory: projectServiceFactory,
	deps: ['$injector']
};


