/**
 * @ref WoonConnectViewModel/Documenten/DocumentMapModel.cs
 */

 import * as DataContracts from "@datacontracts/DocumentMapModel";

 
import * as RechtenModel from "./RechtenModel";
import * as DocumentModel from "./DocumentModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Documenten.DocumentMapModel
	 */
	 

	export interface IDocumentMapModel {
		Id: number;
		ProjectId: number;
		ParentId: number;
		MapType: number;
		Naam: string;
		DisplayNameForBewoner: string;
		IsRoot: boolean;
		Verbergen: boolean;
		Rechten: RechtenModel.IRechtenModel;
		Documenten: DocumentModel.IDocumentModel[];
		ChildMappen: DocumentMapModel[];
		ToJsonContract(): DataContracts.IDocumentMapModel;
	}

	export class DocumentMapModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		public get ParentId(): number {
			return this._ParentId;
		}
		public set ParentId(newParentId: number) {
			this._ParentId = newParentId;
		}

		public get MapType(): number {
			return this._MapType;
		}
		public set MapType(newMapType: number) {
			this._MapType = newMapType;
		}

		public get Naam(): string {
			return this._Naam;
		}
		public set Naam(newNaam: string) {
			this._Naam = newNaam;
		}

		public get DisplayNameForBewoner(): string {
			return this._DisplayNameForBewoner;
		}
		public set DisplayNameForBewoner(newDisplayNameForBewoner: string) {
			this._DisplayNameForBewoner = newDisplayNameForBewoner;
		}

		public get IsRoot(): boolean {
			return this._IsRoot;
		}
		public set IsRoot(newIsRoot: boolean) {
			this._IsRoot = newIsRoot;
		}

		public get Verbergen(): boolean {
			return this._Verbergen;
		}
		public set Verbergen(newVerbergen: boolean) {
			this._Verbergen = newVerbergen;
		}

		public get Rechten(): RechtenModel.IRechtenModel {
			return this._Rechten;
		}
		public set Rechten(newRechten: RechtenModel.IRechtenModel) {
			this._Rechten = newRechten;
		}

		public get Documenten(): DocumentModel.IDocumentModel[] {
			return this._Documenten;
		}
		public set Documenten(newDocumenten: DocumentModel.IDocumentModel[]) {
			this._Documenten = newDocumenten;
		}

		public get ChildMappen(): DocumentMapModel[] {
			return this._ChildMappen;
		}
		public set ChildMappen(newChildMappen: DocumentMapModel[]) {
			this._ChildMappen = newChildMappen;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ProjectId: number = 0;
		protected _ParentId: number = 0;
		protected _MapType: number = 0;
		protected _Naam: string = "";
		protected _DisplayNameForBewoner: string = "";
		protected _IsRoot: boolean = false;
		protected _Verbergen: boolean = false;
		protected _Rechten: RechtenModel.IRechtenModel = new RechtenModel.RechtenModel(undefined);
		protected _Documenten: DocumentModel.IDocumentModel[] = [];
		protected _ChildMappen: DocumentMapModel[] = [];

		constructor(data?: DataContracts.IDocumentMapModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDocumentMapModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ProjectId = data.ProjectId;
				this._ParentId = data.ParentId;
				this._MapType = data.MapType;
				this._Naam = data.Naam;
				this._DisplayNameForBewoner = data.DisplayNameForBewoner;
				this._IsRoot = data.IsRoot;
				this._Verbergen = data.Verbergen;
				this._Rechten = data.Rechten ? new RechtenModel.RechtenModel(data.Rechten): undefined;
				this._Documenten = data.Documenten ? data.Documenten.map(documentModelItem => new DocumentModel.DocumentModel(documentModelItem)): [];
				this._ChildMappen = data.ChildMappen ? data.ChildMappen.map(documentMapModelItem => new DocumentMapModel(documentMapModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDocumentMapModel {
			return {
				Id: this.Id,
				ProjectId: this.ProjectId,
				ParentId: this.ParentId,
				MapType: this.MapType,
				Naam: this.Naam,
				DisplayNameForBewoner: this.DisplayNameForBewoner,
				IsRoot: this.IsRoot,
				Verbergen: this.Verbergen,
				Rechten: (this.Rechten ? new RechtenModel.RechtenModel(this.Rechten).ToJsonContract(): this.Rechten),
				Documenten: (this.Documenten ? this.Documenten.map(documentModelItem => new DocumentModel.DocumentModel(documentModelItem).ToJsonContract()): []),
				ChildMappen: (this.ChildMappen ? this.ChildMappen.map(documentMapModelItem => new DocumentMapModel(documentMapModelItem).ToJsonContract()): [])
			}
		}
	}
