/**
 * @ref WoonConnectViewModel/Fabrikanten/ProductsViewModel.cs
 */

 import * as DataContracts from "@datacontracts/ProductsViewModel";

 
import * as FilterViewModel from "./FilterViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductsViewModel
	 */
	 

	export interface IProductsViewModel {
		Products: ProductViewModel[];
		ToJsonContract(): DataContracts.IProductsViewModel;
	}

	export class ProductsViewModel {
		// property-Properties
		public get Products(): ProductViewModel[] {
			return this._Products;
		}
		public set Products(newProducts: ProductViewModel[]) {
			this._Products = newProducts;
		}

		// field-Properties

		// fields
		protected _Products: ProductViewModel[] = [];

		constructor(data?: DataContracts.IProductsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductsViewModel): void {
			if(data) {
				// vul properties
				this._Products = data.Products ? data.Products.map(productViewModelItem => new ProductViewModel(productViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductsViewModel {
			return {
				Products: (this.Products ? this.Products.map(productViewModelItem => new ProductViewModel(productViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductViewModel
	 */
	 

	export interface IProductViewModel {
		Id: number;
		Name: string;
		Kenmerk: string;
		Position: number;
		Bot: string;
		DO1Type: string;
		DO1Naam: string;
		DO2Type: string;
		DO2Naam: string;
		DO3Type: string;
		DO3Naam: string;
		DO4Type: string;
		DO4Naam: string;
		Description: string;
		Import: string;
		Connected: boolean;
		ProductVariant: ProductVariantModel[];
		ProductProperty: ProductPropertyViewModel[];
		FilterRelations: FilterViewModel.IFilterViewModel[];
		Active: boolean;
		ToJsonContract(): DataContracts.IProductViewModel;
	}

	export class ProductViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Kenmerk(): string {
			return this._Kenmerk;
		}
		public set Kenmerk(newKenmerk: string) {
			this._Kenmerk = newKenmerk;
		}

		public get Position(): number {
			return this._Position;
		}
		public set Position(newPosition: number) {
			this._Position = newPosition;
		}

		public get Bot(): string {
			return this._Bot;
		}
		public set Bot(newBot: string) {
			this._Bot = newBot;
		}

		public get DO1Type(): string {
			return this._DO1Type;
		}
		public set DO1Type(newDO1Type: string) {
			this._DO1Type = newDO1Type;
		}

		public get DO1Naam(): string {
			return this._DO1Naam;
		}
		public set DO1Naam(newDO1Naam: string) {
			this._DO1Naam = newDO1Naam;
		}

		public get DO2Type(): string {
			return this._DO2Type;
		}
		public set DO2Type(newDO2Type: string) {
			this._DO2Type = newDO2Type;
		}

		public get DO2Naam(): string {
			return this._DO2Naam;
		}
		public set DO2Naam(newDO2Naam: string) {
			this._DO2Naam = newDO2Naam;
		}

		public get DO3Type(): string {
			return this._DO3Type;
		}
		public set DO3Type(newDO3Type: string) {
			this._DO3Type = newDO3Type;
		}

		public get DO3Naam(): string {
			return this._DO3Naam;
		}
		public set DO3Naam(newDO3Naam: string) {
			this._DO3Naam = newDO3Naam;
		}

		public get DO4Type(): string {
			return this._DO4Type;
		}
		public set DO4Type(newDO4Type: string) {
			this._DO4Type = newDO4Type;
		}

		public get DO4Naam(): string {
			return this._DO4Naam;
		}
		public set DO4Naam(newDO4Naam: string) {
			this._DO4Naam = newDO4Naam;
		}

		public get Description(): string {
			return this._Description;
		}
		public set Description(newDescription: string) {
			this._Description = newDescription;
		}

		public get Import(): string {
			return this._Import;
		}
		public set Import(newImport: string) {
			this._Import = newImport;
		}

		public get Connected(): boolean {
			return this._Connected;
		}
		public set Connected(newConnected: boolean) {
			this._Connected = newConnected;
		}

		public get ProductVariant(): ProductVariantModel[] {
			return this._ProductVariant;
		}
		public set ProductVariant(newProductVariant: ProductVariantModel[]) {
			this._ProductVariant = newProductVariant;
		}

		public get ProductProperty(): ProductPropertyViewModel[] {
			return this._ProductProperty;
		}
		public set ProductProperty(newProductProperty: ProductPropertyViewModel[]) {
			this._ProductProperty = newProductProperty;
		}

		public get FilterRelations(): FilterViewModel.IFilterViewModel[] {
			return this._FilterRelations;
		}
		public set FilterRelations(newFilterRelations: FilterViewModel.IFilterViewModel[]) {
			this._FilterRelations = newFilterRelations;
		}

		public get Active(): boolean {
			return this._Active;
		}
		public set Active(newActive: boolean) {
			this._Active = newActive;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _Kenmerk: string = "";
		protected _Position: number = 0;
		protected _Bot: string = "";
		protected _DO1Type: string = "";
		protected _DO1Naam: string = "";
		protected _DO2Type: string = "";
		protected _DO2Naam: string = "";
		protected _DO3Type: string = "";
		protected _DO3Naam: string = "";
		protected _DO4Type: string = "";
		protected _DO4Naam: string = "";
		protected _Description: string = "";
		protected _Import: string = "";
		protected _Connected: boolean = false;
		protected _ProductVariant: ProductVariantModel[] = [];
		protected _ProductProperty: ProductPropertyViewModel[] = [];
		protected _FilterRelations: FilterViewModel.IFilterViewModel[] = [];
		protected _Active: boolean = false;

		constructor(data?: DataContracts.IProductViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._Kenmerk = data.Kenmerk;
				this._Position = data.Position;
				this._Bot = data.Bot;
				this._DO1Type = data.DO1Type;
				this._DO1Naam = data.DO1Naam;
				this._DO2Type = data.DO2Type;
				this._DO2Naam = data.DO2Naam;
				this._DO3Type = data.DO3Type;
				this._DO3Naam = data.DO3Naam;
				this._DO4Type = data.DO4Type;
				this._DO4Naam = data.DO4Naam;
				this._Description = data.Description;
				this._Import = data.Import;
				this._Connected = data.Connected;
				this._ProductVariant = data.ProductVariant ? data.ProductVariant.map(productVariantModelItem => new ProductVariantModel(productVariantModelItem)): [];
				this._ProductProperty = data.ProductProperty ? data.ProductProperty.map(productPropertyViewModelItem => new ProductPropertyViewModel(productPropertyViewModelItem)): [];
				this._FilterRelations = data.FilterRelations ? data.FilterRelations.map(filterViewModelItem => new FilterViewModel.FilterViewModel(filterViewModelItem)): [];
				this._Active = data.Active;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductViewModel {
			return {
				Id: this.Id,
				Name: this.Name,
				Kenmerk: this.Kenmerk,
				Position: this.Position,
				Bot: this.Bot,
				DO1Type: this.DO1Type,
				DO1Naam: this.DO1Naam,
				DO2Type: this.DO2Type,
				DO2Naam: this.DO2Naam,
				DO3Type: this.DO3Type,
				DO3Naam: this.DO3Naam,
				DO4Type: this.DO4Type,
				DO4Naam: this.DO4Naam,
				Description: this.Description,
				Import: this.Import,
				Connected: this.Connected,
				ProductVariant: (this.ProductVariant ? this.ProductVariant.map(productVariantModelItem => new ProductVariantModel(productVariantModelItem).ToJsonContract()): []),
				ProductProperty: (this.ProductProperty ? this.ProductProperty.map(productPropertyViewModelItem => new ProductPropertyViewModel(productPropertyViewModelItem).ToJsonContract()): []),
				FilterRelations: (this.FilterRelations ? this.FilterRelations.map(filterViewModelItem => new FilterViewModel.FilterViewModel(filterViewModelItem).ToJsonContract()): []),
				Active: this.Active
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.AddEigenschapInputModel
	 */
	 

	export interface IAddEigenschapInputModel {
		Name: string;
		ParentPropertyOptionId: number;
		ProductVariantId: number;
		ToJsonContract(): DataContracts.IAddEigenschapInputModel;
	}

	export class AddEigenschapInputModel {
		// property-Properties
		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get ParentPropertyOptionId(): number {
			return this._ParentPropertyOptionId;
		}
		public set ParentPropertyOptionId(newParentPropertyOptionId: number) {
			this._ParentPropertyOptionId = newParentPropertyOptionId;
		}

		public get ProductVariantId(): number {
			return this._ProductVariantId;
		}
		public set ProductVariantId(newProductVariantId: number) {
			this._ProductVariantId = newProductVariantId;
		}

		// field-Properties

		// fields
		protected _Name: string = "";
		protected _ParentPropertyOptionId: number = 0;
		protected _ProductVariantId: number = 0;

		constructor(data?: DataContracts.IAddEigenschapInputModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddEigenschapInputModel): void {
			if(data) {
				// vul properties
				this._Name = data.Name;
				this._ParentPropertyOptionId = data.ParentPropertyOptionId;
				this._ProductVariantId = data.ProductVariantId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAddEigenschapInputModel {
			return {
				Name: this.Name,
				ParentPropertyOptionId: this.ParentPropertyOptionId,
				ProductVariantId: this.ProductVariantId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.AddOptionInputModel
	 */
	 

	export interface IAddOptionInputModel {
		Name: string;
		ParentProductPropertyId: number;
		ProductVariantId: number;
		ToJsonContract(): DataContracts.IAddOptionInputModel;
	}

	export class AddOptionInputModel {
		// property-Properties
		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get ParentProductPropertyId(): number {
			return this._ParentProductPropertyId;
		}
		public set ParentProductPropertyId(newParentProductPropertyId: number) {
			this._ParentProductPropertyId = newParentProductPropertyId;
		}

		public get ProductVariantId(): number {
			return this._ProductVariantId;
		}
		public set ProductVariantId(newProductVariantId: number) {
			this._ProductVariantId = newProductVariantId;
		}

		// field-Properties

		// fields
		protected _Name: string = "";
		protected _ParentProductPropertyId: number = 0;
		protected _ProductVariantId: number = 0;

		constructor(data?: DataContracts.IAddOptionInputModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddOptionInputModel): void {
			if(data) {
				// vul properties
				this._Name = data.Name;
				this._ParentProductPropertyId = data.ParentProductPropertyId;
				this._ProductVariantId = data.ProductVariantId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAddOptionInputModel {
			return {
				Name: this.Name,
				ParentProductPropertyId: this.ParentProductPropertyId,
				ProductVariantId: this.ProductVariantId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductVariantsViewModel
	 */
	 

	export interface IProductVariantsViewModel {
		ProductVariant: ProductVariantModel[];
		ToJsonContract(): DataContracts.IProductVariantsViewModel;
	}

	export class ProductVariantsViewModel {
		// property-Properties
		public get ProductVariant(): ProductVariantModel[] {
			return this._ProductVariant;
		}
		public set ProductVariant(newProductVariant: ProductVariantModel[]) {
			this._ProductVariant = newProductVariant;
		}

		// field-Properties

		// fields
		protected _ProductVariant: ProductVariantModel[] = [];

		constructor(data?: DataContracts.IProductVariantsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductVariantsViewModel): void {
			if(data) {
				// vul properties
				this._ProductVariant = data.ProductVariant ? data.ProductVariant.map(productVariantModelItem => new ProductVariantModel(productVariantModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductVariantsViewModel {
			return {
				ProductVariant: (this.ProductVariant ? this.ProductVariant.map(productVariantModelItem => new ProductVariantModel(productVariantModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductVariantModel
	 */
	 

	export interface IProductVariantModel {
		Id: number;
		ProductId: number;
		Bot: string;
		DO1Type: string;
		DO1Naam: string;
		DO2Type: string;
		DO2Naam: string;
		DO3Type: string;
		DO3Naam: string;
		DO4Type: string;
		DO4Naam: string;
		Kenmerk: string;
		Name: string;
		Description: string;
		Import: string;
		Connected: boolean;
		FilterOptionsRelations: FilterViewModel.IFilterOptionViewModel[];
		FilterRelations: FilterViewModel.IFilterViewModel[];
		ProductPropertiesEigenschappen: ProductPropertyViewModel[];
		ProductPropertiesOpbouw: ProductPropertyViewModel[];
		ToJsonContract(): DataContracts.IProductVariantModel;
	}

	export class ProductVariantModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ProductId(): number {
			return this._ProductId;
		}
		public set ProductId(newProductId: number) {
			this._ProductId = newProductId;
		}

		public get Bot(): string {
			return this._Bot;
		}
		public set Bot(newBot: string) {
			this._Bot = newBot;
		}

		public get DO1Type(): string {
			return this._DO1Type;
		}
		public set DO1Type(newDO1Type: string) {
			this._DO1Type = newDO1Type;
		}

		public get DO1Naam(): string {
			return this._DO1Naam;
		}
		public set DO1Naam(newDO1Naam: string) {
			this._DO1Naam = newDO1Naam;
		}

		public get DO2Type(): string {
			return this._DO2Type;
		}
		public set DO2Type(newDO2Type: string) {
			this._DO2Type = newDO2Type;
		}

		public get DO2Naam(): string {
			return this._DO2Naam;
		}
		public set DO2Naam(newDO2Naam: string) {
			this._DO2Naam = newDO2Naam;
		}

		public get DO3Type(): string {
			return this._DO3Type;
		}
		public set DO3Type(newDO3Type: string) {
			this._DO3Type = newDO3Type;
		}

		public get DO3Naam(): string {
			return this._DO3Naam;
		}
		public set DO3Naam(newDO3Naam: string) {
			this._DO3Naam = newDO3Naam;
		}

		public get DO4Type(): string {
			return this._DO4Type;
		}
		public set DO4Type(newDO4Type: string) {
			this._DO4Type = newDO4Type;
		}

		public get DO4Naam(): string {
			return this._DO4Naam;
		}
		public set DO4Naam(newDO4Naam: string) {
			this._DO4Naam = newDO4Naam;
		}

		public get Kenmerk(): string {
			return this._Kenmerk;
		}
		public set Kenmerk(newKenmerk: string) {
			this._Kenmerk = newKenmerk;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Description(): string {
			return this._Description;
		}
		public set Description(newDescription: string) {
			this._Description = newDescription;
		}

		public get Import(): string {
			return this._Import;
		}
		public set Import(newImport: string) {
			this._Import = newImport;
		}

		public get Connected(): boolean {
			return this._Connected;
		}
		public set Connected(newConnected: boolean) {
			this._Connected = newConnected;
		}

		public get FilterOptionsRelations(): FilterViewModel.IFilterOptionViewModel[] {
			return this._FilterOptionsRelations;
		}
		public set FilterOptionsRelations(newFilterOptionsRelations: FilterViewModel.IFilterOptionViewModel[]) {
			this._FilterOptionsRelations = newFilterOptionsRelations;
		}

		public get FilterRelations(): FilterViewModel.IFilterViewModel[] {
			return this._FilterRelations;
		}
		public set FilterRelations(newFilterRelations: FilterViewModel.IFilterViewModel[]) {
			this._FilterRelations = newFilterRelations;
		}

		public get ProductPropertiesEigenschappen(): ProductPropertyViewModel[] {
			return this._ProductPropertiesEigenschappen;
		}
		public set ProductPropertiesEigenschappen(newProductPropertiesEigenschappen: ProductPropertyViewModel[]) {
			this._ProductPropertiesEigenschappen = newProductPropertiesEigenschappen;
		}

		public get ProductPropertiesOpbouw(): ProductPropertyViewModel[] {
			return this._ProductPropertiesOpbouw;
		}
		public set ProductPropertiesOpbouw(newProductPropertiesOpbouw: ProductPropertyViewModel[]) {
			this._ProductPropertiesOpbouw = newProductPropertiesOpbouw;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ProductId: number = 0;
		protected _Bot: string = "";
		protected _DO1Type: string = "";
		protected _DO1Naam: string = "";
		protected _DO2Type: string = "";
		protected _DO2Naam: string = "";
		protected _DO3Type: string = "";
		protected _DO3Naam: string = "";
		protected _DO4Type: string = "";
		protected _DO4Naam: string = "";
		protected _Kenmerk: string = "";
		protected _Name: string = "";
		protected _Description: string = "";
		protected _Import: string = "";
		protected _Connected: boolean = false;
		protected _FilterOptionsRelations: FilterViewModel.IFilterOptionViewModel[] = [];
		protected _FilterRelations: FilterViewModel.IFilterViewModel[] = [];
		protected _ProductPropertiesEigenschappen: ProductPropertyViewModel[] = [];
		protected _ProductPropertiesOpbouw: ProductPropertyViewModel[] = [];

		constructor(data?: DataContracts.IProductVariantModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductVariantModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ProductId = data.ProductId;
				this._Bot = data.Bot;
				this._DO1Type = data.DO1Type;
				this._DO1Naam = data.DO1Naam;
				this._DO2Type = data.DO2Type;
				this._DO2Naam = data.DO2Naam;
				this._DO3Type = data.DO3Type;
				this._DO3Naam = data.DO3Naam;
				this._DO4Type = data.DO4Type;
				this._DO4Naam = data.DO4Naam;
				this._Kenmerk = data.Kenmerk;
				this._Name = data.Name;
				this._Description = data.Description;
				this._Import = data.Import;
				this._Connected = data.Connected;
				this._FilterOptionsRelations = data.FilterOptionsRelations ? data.FilterOptionsRelations.map(filterOptionViewModelItem => new FilterViewModel.FilterOptionViewModel(filterOptionViewModelItem)): [];
				this._FilterRelations = data.FilterRelations ? data.FilterRelations.map(filterViewModelItem => new FilterViewModel.FilterViewModel(filterViewModelItem)): [];
				this._ProductPropertiesEigenschappen = data.ProductPropertiesEigenschappen ? data.ProductPropertiesEigenschappen.map(productPropertyViewModelItem => new ProductPropertyViewModel(productPropertyViewModelItem)): [];
				this._ProductPropertiesOpbouw = data.ProductPropertiesOpbouw ? data.ProductPropertiesOpbouw.map(productPropertyViewModelItem => new ProductPropertyViewModel(productPropertyViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductVariantModel {
			return {
				Id: this.Id,
				ProductId: this.ProductId,
				Bot: this.Bot,
				DO1Type: this.DO1Type,
				DO1Naam: this.DO1Naam,
				DO2Type: this.DO2Type,
				DO2Naam: this.DO2Naam,
				DO3Type: this.DO3Type,
				DO3Naam: this.DO3Naam,
				DO4Type: this.DO4Type,
				DO4Naam: this.DO4Naam,
				Kenmerk: this.Kenmerk,
				Name: this.Name,
				Description: this.Description,
				Import: this.Import,
				Connected: this.Connected,
				FilterOptionsRelations: (this.FilterOptionsRelations ? this.FilterOptionsRelations.map(filterOptionViewModelItem => new FilterViewModel.FilterOptionViewModel(filterOptionViewModelItem).ToJsonContract()): []),
				FilterRelations: (this.FilterRelations ? this.FilterRelations.map(filterViewModelItem => new FilterViewModel.FilterViewModel(filterViewModelItem).ToJsonContract()): []),
				ProductPropertiesEigenschappen: (this.ProductPropertiesEigenschappen ? this.ProductPropertiesEigenschappen.map(productPropertyViewModelItem => new ProductPropertyViewModel(productPropertyViewModelItem).ToJsonContract()): []),
				ProductPropertiesOpbouw: (this.ProductPropertiesOpbouw ? this.ProductPropertiesOpbouw.map(productPropertyViewModelItem => new ProductPropertyViewModel(productPropertyViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertiesViewModel
	 */
	 

	export interface IProductPropertiesViewModel {
		Property: ProductPropertyViewModel[];
		ToJsonContract(): DataContracts.IProductPropertiesViewModel;
	}

	export class ProductPropertiesViewModel {
		// property-Properties
		public get Property(): ProductPropertyViewModel[] {
			return this._Property;
		}
		public set Property(newProperty: ProductPropertyViewModel[]) {
			this._Property = newProperty;
		}

		// field-Properties

		// fields
		protected _Property: ProductPropertyViewModel[] = [];

		constructor(data?: DataContracts.IProductPropertiesViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertiesViewModel): void {
			if(data) {
				// vul properties
				this._Property = data.Property ? data.Property.map(productPropertyViewModelItem => new ProductPropertyViewModel(productPropertyViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertiesViewModel {
			return {
				Property: (this.Property ? this.Property.map(productPropertyViewModelItem => new ProductPropertyViewModel(productPropertyViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.DeleteResponseModel
	 */
	 

	export interface IDeleteResponseModel {
		Id: number;
		Message: string;
		Succeed: boolean;
		ToJsonContract(): DataContracts.IDeleteResponseModel;
	}

	export class DeleteResponseModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Message(): string {
			return this._Message;
		}
		public set Message(newMessage: string) {
			this._Message = newMessage;
		}

		public get Succeed(): boolean {
			return this._Succeed;
		}
		public set Succeed(newSucceed: boolean) {
			this._Succeed = newSucceed;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Message: string = "";
		protected _Succeed: boolean = false;

		constructor(data?: DataContracts.IDeleteResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDeleteResponseModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Message = data.Message;
				this._Succeed = data.Succeed;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDeleteResponseModel {
			return {
				Id: this.Id,
				Message: this.Message,
				Succeed: this.Succeed
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.DeleteRequestModel
	 */
	 

	export interface IDeleteRequestModel {
		Id: number;
		LinkedId: number;
		ToJsonContract(): DataContracts.IDeleteRequestModel;
	}

	export class DeleteRequestModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get LinkedId(): number {
			return this._LinkedId;
		}
		public set LinkedId(newLinkedId: number) {
			this._LinkedId = newLinkedId;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _LinkedId: number = 0;

		constructor(data?: DataContracts.IDeleteRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDeleteRequestModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._LinkedId = data.LinkedId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDeleteRequestModel {
			return {
				Id: this.Id,
				LinkedId: this.LinkedId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductRequestModel
	 */
	 

	export interface IProductRequestModel {
		Id: number;
		ToJsonContract(): DataContracts.IProductRequestModel;
	}

	export class ProductRequestModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;

		constructor(data?: DataContracts.IProductRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductRequestModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductRequestModel {
			return {
				Id: this.Id
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterOptionVariantRelationLink
	 */
	 

	export interface IFilterOptionVariantRelationLink {
		FilterOption: number;
		ProductVariant: ProductVariantModel;
		Active: boolean;
		ToJsonContract(): DataContracts.IFilterOptionVariantRelationLink;
	}

	export class FilterOptionVariantRelationLink {
		// property-Properties
		public get FilterOption(): number {
			return this._FilterOption;
		}
		public set FilterOption(newFilterOption: number) {
			this._FilterOption = newFilterOption;
		}

		public get ProductVariant(): ProductVariantModel {
			return this._ProductVariant;
		}
		public set ProductVariant(newProductVariant: ProductVariantModel) {
			this._ProductVariant = newProductVariant;
		}

		public get Active(): boolean {
			return this._Active;
		}
		public set Active(newActive: boolean) {
			this._Active = newActive;
		}

		// field-Properties

		// fields
		protected _FilterOption: number = 0;
		protected _ProductVariant: ProductVariantModel = new ProductVariantModel(undefined);
		protected _Active: boolean = false;

		constructor(data?: DataContracts.IFilterOptionVariantRelationLink) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterOptionVariantRelationLink): void {
			if(data) {
				// vul properties
				this._FilterOption = data.FilterOption;
				this._ProductVariant = data.ProductVariant ? new ProductVariantModel(data.ProductVariant): undefined;
				this._Active = data.Active;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterOptionVariantRelationLink {
			return {
				FilterOption: this.FilterOption,
				ProductVariant: (this.ProductVariant ? new ProductVariantModel(this.ProductVariant).ToJsonContract(): this.ProductVariant),
				Active: this.Active
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterOptionProductVariantsViewModel
	 */
	 

	export interface IFilterOptionProductVariantsViewModel {
		FilterOptionVariant: FilterOptionProductVariantViewModel[];
		ToJsonContract(): DataContracts.IFilterOptionProductVariantsViewModel;
	}

	export class FilterOptionProductVariantsViewModel {
		// property-Properties
		public get FilterOptionVariant(): FilterOptionProductVariantViewModel[] {
			return this._FilterOptionVariant;
		}
		public set FilterOptionVariant(newFilterOptionVariant: FilterOptionProductVariantViewModel[]) {
			this._FilterOptionVariant = newFilterOptionVariant;
		}

		// field-Properties

		// fields
		protected _FilterOptionVariant: FilterOptionProductVariantViewModel[] = [];

		constructor(data?: DataContracts.IFilterOptionProductVariantsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterOptionProductVariantsViewModel): void {
			if(data) {
				// vul properties
				this._FilterOptionVariant = data.FilterOptionVariant ? data.FilterOptionVariant.map(filterOptionProductVariantViewModelItem => new FilterOptionProductVariantViewModel(filterOptionProductVariantViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterOptionProductVariantsViewModel {
			return {
				FilterOptionVariant: (this.FilterOptionVariant ? this.FilterOptionVariant.map(filterOptionProductVariantViewModelItem => new FilterOptionProductVariantViewModel(filterOptionProductVariantViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterOptionProductVariantViewModel
	 */
	 

	export interface IFilterOptionProductVariantViewModel {
		FilterOptionId: number;
		ProductVariant: ProductVariantModel;
		Link: boolean;
		ToJsonContract(): DataContracts.IFilterOptionProductVariantViewModel;
	}

	export class FilterOptionProductVariantViewModel {
		// property-Properties
		public get FilterOptionId(): number {
			return this._FilterOptionId;
		}
		public set FilterOptionId(newFilterOptionId: number) {
			this._FilterOptionId = newFilterOptionId;
		}

		public get ProductVariant(): ProductVariantModel {
			return this._ProductVariant;
		}
		public set ProductVariant(newProductVariant: ProductVariantModel) {
			this._ProductVariant = newProductVariant;
		}

		public get Link(): boolean {
			return this._Link;
		}
		public set Link(newLink: boolean) {
			this._Link = newLink;
		}

		// field-Properties

		// fields
		protected _FilterOptionId: number = 0;
		protected _ProductVariant: ProductVariantModel = new ProductVariantModel(undefined);
		protected _Link: boolean = false;

		constructor(data?: DataContracts.IFilterOptionProductVariantViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterOptionProductVariantViewModel): void {
			if(data) {
				// vul properties
				this._FilterOptionId = data.FilterOptionId;
				this._ProductVariant = data.ProductVariant ? new ProductVariantModel(data.ProductVariant): undefined;
				this._Link = data.Link;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterOptionProductVariantViewModel {
			return {
				FilterOptionId: this.FilterOptionId,
				ProductVariant: (this.ProductVariant ? new ProductVariantModel(this.ProductVariant).ToJsonContract(): this.ProductVariant),
				Link: this.Link
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductFilterRelationsViewModel
	 */
	 

	export interface IProductFilterRelationsViewModel {
		ProductFilterRelation: ProductFilterRelationViewModel[];
		ToJsonContract(): DataContracts.IProductFilterRelationsViewModel;
	}

	export class ProductFilterRelationsViewModel {
		// property-Properties
		public get ProductFilterRelation(): ProductFilterRelationViewModel[] {
			return this._ProductFilterRelation;
		}
		public set ProductFilterRelation(newProductFilterRelation: ProductFilterRelationViewModel[]) {
			this._ProductFilterRelation = newProductFilterRelation;
		}

		// field-Properties

		// fields
		protected _ProductFilterRelation: ProductFilterRelationViewModel[] = [];

		constructor(data?: DataContracts.IProductFilterRelationsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductFilterRelationsViewModel): void {
			if(data) {
				// vul properties
				this._ProductFilterRelation = data.ProductFilterRelation ? data.ProductFilterRelation.map(productFilterRelationViewModelItem => new ProductFilterRelationViewModel(productFilterRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductFilterRelationsViewModel {
			return {
				ProductFilterRelation: (this.ProductFilterRelation ? this.ProductFilterRelation.map(productFilterRelationViewModelItem => new ProductFilterRelationViewModel(productFilterRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductFilterRelationViewModel
	 */
	 

	export interface IProductFilterRelationViewModel {
		Id: number;
		Product: ProductViewModel;
		Filter: FilterViewModel.IFilterViewModel;
		Active: boolean;
		ProductFilterOption: FilterOptionRelationViewModel[];
		ToJsonContract(): DataContracts.IProductFilterRelationViewModel;
	}

	export class ProductFilterRelationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Product(): ProductViewModel {
			return this._Product;
		}
		public set Product(newProduct: ProductViewModel) {
			this._Product = newProduct;
		}

		public get Filter(): FilterViewModel.IFilterViewModel {
			return this._Filter;
		}
		public set Filter(newFilter: FilterViewModel.IFilterViewModel) {
			this._Filter = newFilter;
		}

		public get Active(): boolean {
			return this._Active;
		}
		public set Active(newActive: boolean) {
			this._Active = newActive;
		}

		public get ProductFilterOption(): FilterOptionRelationViewModel[] {
			return this._ProductFilterOption;
		}
		public set ProductFilterOption(newProductFilterOption: FilterOptionRelationViewModel[]) {
			this._ProductFilterOption = newProductFilterOption;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Product: ProductViewModel = new ProductViewModel(undefined);
		protected _Filter: FilterViewModel.IFilterViewModel = new FilterViewModel.FilterViewModel(undefined);
		protected _Active: boolean = false;
		protected _ProductFilterOption: FilterOptionRelationViewModel[] = [];

		constructor(data?: DataContracts.IProductFilterRelationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductFilterRelationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Product = data.Product ? new ProductViewModel(data.Product): undefined;
				this._Filter = data.Filter ? new FilterViewModel.FilterViewModel(data.Filter): undefined;
				this._Active = data.Active;
				this._ProductFilterOption = data.ProductFilterOption ? data.ProductFilterOption.map(filterOptionRelationViewModelItem => new FilterOptionRelationViewModel(filterOptionRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductFilterRelationViewModel {
			return {
				Id: this.Id,
				Product: (this.Product ? new ProductViewModel(this.Product).ToJsonContract(): this.Product),
				Filter: (this.Filter ? new FilterViewModel.FilterViewModel(this.Filter).ToJsonContract(): this.Filter),
				Active: this.Active,
				ProductFilterOption: (this.ProductFilterOption ? this.ProductFilterOption.map(filterOptionRelationViewModelItem => new FilterOptionRelationViewModel(filterOptionRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductVariantFilterOptionRelationViewModel
	 */
	 

	export interface IProductVariantFilterOptionRelationViewModel {
		Id: number;
		ProductVariant: ProductVariantModel;
		FilterOption: FilterViewModel.IFilterOptionViewModel;
		Active: boolean;
		ToJsonContract(): DataContracts.IProductVariantFilterOptionRelationViewModel;
	}

	export class ProductVariantFilterOptionRelationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ProductVariant(): ProductVariantModel {
			return this._ProductVariant;
		}
		public set ProductVariant(newProductVariant: ProductVariantModel) {
			this._ProductVariant = newProductVariant;
		}

		public get FilterOption(): FilterViewModel.IFilterOptionViewModel {
			return this._FilterOption;
		}
		public set FilterOption(newFilterOption: FilterViewModel.IFilterOptionViewModel) {
			this._FilterOption = newFilterOption;
		}

		public get Active(): boolean {
			return this._Active;
		}
		public set Active(newActive: boolean) {
			this._Active = newActive;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ProductVariant: ProductVariantModel = new ProductVariantModel(undefined);
		protected _FilterOption: FilterViewModel.IFilterOptionViewModel = new FilterViewModel.FilterOptionViewModel(undefined);
		protected _Active: boolean = false;

		constructor(data?: DataContracts.IProductVariantFilterOptionRelationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductVariantFilterOptionRelationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ProductVariant = data.ProductVariant ? new ProductVariantModel(data.ProductVariant): undefined;
				this._FilterOption = data.FilterOption ? new FilterViewModel.FilterOptionViewModel(data.FilterOption): undefined;
				this._Active = data.Active;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductVariantFilterOptionRelationViewModel {
			return {
				Id: this.Id,
				ProductVariant: (this.ProductVariant ? new ProductVariantModel(this.ProductVariant).ToJsonContract(): this.ProductVariant),
				FilterOption: (this.FilterOption ? new FilterViewModel.FilterOptionViewModel(this.FilterOption).ToJsonContract(): this.FilterOption),
				Active: this.Active
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductVariantFilterOptionsRelationsViewModel
	 */
	 

	export interface IProductVariantFilterOptionsRelationsViewModel {
		ProductVariantFilterOptionsRelation: ProductVariantFilterOptionRelationViewModel[];
		ToJsonContract(): DataContracts.IProductVariantFilterOptionsRelationsViewModel;
	}

	export class ProductVariantFilterOptionsRelationsViewModel {
		// property-Properties
		public get ProductVariantFilterOptionsRelation(): ProductVariantFilterOptionRelationViewModel[] {
			return this._ProductVariantFilterOptionsRelation;
		}
		public set ProductVariantFilterOptionsRelation(newProductVariantFilterOptionsRelation: ProductVariantFilterOptionRelationViewModel[]) {
			this._ProductVariantFilterOptionsRelation = newProductVariantFilterOptionsRelation;
		}

		// field-Properties

		// fields
		protected _ProductVariantFilterOptionsRelation: ProductVariantFilterOptionRelationViewModel[] = [];

		constructor(data?: DataContracts.IProductVariantFilterOptionsRelationsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductVariantFilterOptionsRelationsViewModel): void {
			if(data) {
				// vul properties
				this._ProductVariantFilterOptionsRelation = data.ProductVariantFilterOptionsRelation ? data.ProductVariantFilterOptionsRelation.map(productVariantFilterOptionRelationViewModelItem => new ProductVariantFilterOptionRelationViewModel(productVariantFilterOptionRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductVariantFilterOptionsRelationsViewModel {
			return {
				ProductVariantFilterOptionsRelation: (this.ProductVariantFilterOptionsRelation ? this.ProductVariantFilterOptionsRelation.map(productVariantFilterOptionRelationViewModelItem => new ProductVariantFilterOptionRelationViewModel(productVariantFilterOptionRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductFilterOptionRelationsViewModel
	 */
	 

	export interface IProductFilterOptionRelationsViewModel {
		ProductFilterOptionRelation: FilterOptionRelationViewModel[];
		ToJsonContract(): DataContracts.IProductFilterOptionRelationsViewModel;
	}

	export class ProductFilterOptionRelationsViewModel {
		// property-Properties
		public get ProductFilterOptionRelation(): FilterOptionRelationViewModel[] {
			return this._ProductFilterOptionRelation;
		}
		public set ProductFilterOptionRelation(newProductFilterOptionRelation: FilterOptionRelationViewModel[]) {
			this._ProductFilterOptionRelation = newProductFilterOptionRelation;
		}

		// field-Properties

		// fields
		protected _ProductFilterOptionRelation: FilterOptionRelationViewModel[] = [];

		constructor(data?: DataContracts.IProductFilterOptionRelationsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductFilterOptionRelationsViewModel): void {
			if(data) {
				// vul properties
				this._ProductFilterOptionRelation = data.ProductFilterOptionRelation ? data.ProductFilterOptionRelation.map(filterOptionRelationViewModelItem => new FilterOptionRelationViewModel(filterOptionRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductFilterOptionRelationsViewModel {
			return {
				ProductFilterOptionRelation: (this.ProductFilterOptionRelation ? this.ProductFilterOptionRelation.map(filterOptionRelationViewModelItem => new FilterOptionRelationViewModel(filterOptionRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductVariantFilterRelationViewModel
	 */
	 

	export interface IProductVariantFilterRelationViewModel {
		Id: number;
		ProductVariant: ProductVariantModel;
		Filter: FilterViewModel.IFilterViewModel;
		Active: boolean;
		ProductVariantFilterOption: ProductVariantFilterOptionRelationViewModel[];
		ToJsonContract(): DataContracts.IProductVariantFilterRelationViewModel;
	}

	export class ProductVariantFilterRelationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ProductVariant(): ProductVariantModel {
			return this._ProductVariant;
		}
		public set ProductVariant(newProductVariant: ProductVariantModel) {
			this._ProductVariant = newProductVariant;
		}

		public get Filter(): FilterViewModel.IFilterViewModel {
			return this._Filter;
		}
		public set Filter(newFilter: FilterViewModel.IFilterViewModel) {
			this._Filter = newFilter;
		}

		public get Active(): boolean {
			return this._Active;
		}
		public set Active(newActive: boolean) {
			this._Active = newActive;
		}

		public get ProductVariantFilterOption(): ProductVariantFilterOptionRelationViewModel[] {
			return this._ProductVariantFilterOption;
		}
		public set ProductVariantFilterOption(newProductVariantFilterOption: ProductVariantFilterOptionRelationViewModel[]) {
			this._ProductVariantFilterOption = newProductVariantFilterOption;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ProductVariant: ProductVariantModel = new ProductVariantModel(undefined);
		protected _Filter: FilterViewModel.IFilterViewModel = new FilterViewModel.FilterViewModel(undefined);
		protected _Active: boolean = false;
		protected _ProductVariantFilterOption: ProductVariantFilterOptionRelationViewModel[] = [];

		constructor(data?: DataContracts.IProductVariantFilterRelationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductVariantFilterRelationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ProductVariant = data.ProductVariant ? new ProductVariantModel(data.ProductVariant): undefined;
				this._Filter = data.Filter ? new FilterViewModel.FilterViewModel(data.Filter): undefined;
				this._Active = data.Active;
				this._ProductVariantFilterOption = data.ProductVariantFilterOption ? data.ProductVariantFilterOption.map(productVariantFilterOptionRelationViewModelItem => new ProductVariantFilterOptionRelationViewModel(productVariantFilterOptionRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductVariantFilterRelationViewModel {
			return {
				Id: this.Id,
				ProductVariant: (this.ProductVariant ? new ProductVariantModel(this.ProductVariant).ToJsonContract(): this.ProductVariant),
				Filter: (this.Filter ? new FilterViewModel.FilterViewModel(this.Filter).ToJsonContract(): this.Filter),
				Active: this.Active,
				ProductVariantFilterOption: (this.ProductVariantFilterOption ? this.ProductVariantFilterOption.map(productVariantFilterOptionRelationViewModelItem => new ProductVariantFilterOptionRelationViewModel(productVariantFilterOptionRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.PropertyOptionFilterViewModel
	 */
	 

	export interface IPropertyOptionFilterViewModel {
		Id: number;
		ProductPropertyOption: ProductPropertyOptionViewModel;
		Filter: FilterViewModel.IFilterViewModel;
		PropertyOptionFilterOption: PropertyOptionFilterOptionViewModel[];
		ToJsonContract(): DataContracts.IPropertyOptionFilterViewModel;
	}

	export class PropertyOptionFilterViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ProductPropertyOption(): ProductPropertyOptionViewModel {
			return this._ProductPropertyOption;
		}
		public set ProductPropertyOption(newProductPropertyOption: ProductPropertyOptionViewModel) {
			this._ProductPropertyOption = newProductPropertyOption;
		}

		public get Filter(): FilterViewModel.IFilterViewModel {
			return this._Filter;
		}
		public set Filter(newFilter: FilterViewModel.IFilterViewModel) {
			this._Filter = newFilter;
		}

		public get PropertyOptionFilterOption(): PropertyOptionFilterOptionViewModel[] {
			return this._PropertyOptionFilterOption;
		}
		public set PropertyOptionFilterOption(newPropertyOptionFilterOption: PropertyOptionFilterOptionViewModel[]) {
			this._PropertyOptionFilterOption = newPropertyOptionFilterOption;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ProductPropertyOption: ProductPropertyOptionViewModel = new ProductPropertyOptionViewModel(undefined);
		protected _Filter: FilterViewModel.IFilterViewModel = new FilterViewModel.FilterViewModel(undefined);
		protected _PropertyOptionFilterOption: PropertyOptionFilterOptionViewModel[] = [];

		constructor(data?: DataContracts.IPropertyOptionFilterViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPropertyOptionFilterViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ProductPropertyOption = data.ProductPropertyOption ? new ProductPropertyOptionViewModel(data.ProductPropertyOption): undefined;
				this._Filter = data.Filter ? new FilterViewModel.FilterViewModel(data.Filter): undefined;
				this._PropertyOptionFilterOption = data.PropertyOptionFilterOption ? data.PropertyOptionFilterOption.map(propertyOptionFilterOptionViewModelItem => new PropertyOptionFilterOptionViewModel(propertyOptionFilterOptionViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPropertyOptionFilterViewModel {
			return {
				Id: this.Id,
				ProductPropertyOption: (this.ProductPropertyOption ? new ProductPropertyOptionViewModel(this.ProductPropertyOption).ToJsonContract(): this.ProductPropertyOption),
				Filter: (this.Filter ? new FilterViewModel.FilterViewModel(this.Filter).ToJsonContract(): this.Filter),
				PropertyOptionFilterOption: (this.PropertyOptionFilterOption ? this.PropertyOptionFilterOption.map(propertyOptionFilterOptionViewModelItem => new PropertyOptionFilterOptionViewModel(propertyOptionFilterOptionViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.PropertyOptionFiltersViewModel
	 */
	 

	export interface IPropertyOptionFiltersViewModel {
		PropertyOptionFilter: PropertyOptionFilterViewModel[];
		ToJsonContract(): DataContracts.IPropertyOptionFiltersViewModel;
	}

	export class PropertyOptionFiltersViewModel {
		// property-Properties
		public get PropertyOptionFilter(): PropertyOptionFilterViewModel[] {
			return this._PropertyOptionFilter;
		}
		public set PropertyOptionFilter(newPropertyOptionFilter: PropertyOptionFilterViewModel[]) {
			this._PropertyOptionFilter = newPropertyOptionFilter;
		}

		// field-Properties

		// fields
		protected _PropertyOptionFilter: PropertyOptionFilterViewModel[] = [];

		constructor(data?: DataContracts.IPropertyOptionFiltersViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPropertyOptionFiltersViewModel): void {
			if(data) {
				// vul properties
				this._PropertyOptionFilter = data.PropertyOptionFilter ? data.PropertyOptionFilter.map(propertyOptionFilterViewModelItem => new PropertyOptionFilterViewModel(propertyOptionFilterViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPropertyOptionFiltersViewModel {
			return {
				PropertyOptionFilter: (this.PropertyOptionFilter ? this.PropertyOptionFilter.map(propertyOptionFilterViewModelItem => new PropertyOptionFilterViewModel(propertyOptionFilterViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.PropertyOptionFilterOptionViewModel
	 */
	 

	export interface IPropertyOptionFilterOptionViewModel {
		Id: number;
		ProductPropertyOption: ProductPropertyOptionViewModel;
		FilterOption: FilterViewModel.IFilterOptionViewModel;
		Active: boolean;
		ToJsonContract(): DataContracts.IPropertyOptionFilterOptionViewModel;
	}

	export class PropertyOptionFilterOptionViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ProductPropertyOption(): ProductPropertyOptionViewModel {
			return this._ProductPropertyOption;
		}
		public set ProductPropertyOption(newProductPropertyOption: ProductPropertyOptionViewModel) {
			this._ProductPropertyOption = newProductPropertyOption;
		}

		public get FilterOption(): FilterViewModel.IFilterOptionViewModel {
			return this._FilterOption;
		}
		public set FilterOption(newFilterOption: FilterViewModel.IFilterOptionViewModel) {
			this._FilterOption = newFilterOption;
		}

		public get Active(): boolean {
			return this._Active;
		}
		public set Active(newActive: boolean) {
			this._Active = newActive;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ProductPropertyOption: ProductPropertyOptionViewModel = new ProductPropertyOptionViewModel(undefined);
		protected _FilterOption: FilterViewModel.IFilterOptionViewModel = new FilterViewModel.FilterOptionViewModel(undefined);
		protected _Active: boolean = false;

		constructor(data?: DataContracts.IPropertyOptionFilterOptionViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPropertyOptionFilterOptionViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ProductPropertyOption = data.ProductPropertyOption ? new ProductPropertyOptionViewModel(data.ProductPropertyOption): undefined;
				this._FilterOption = data.FilterOption ? new FilterViewModel.FilterOptionViewModel(data.FilterOption): undefined;
				this._Active = data.Active;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPropertyOptionFilterOptionViewModel {
			return {
				Id: this.Id,
				ProductPropertyOption: (this.ProductPropertyOption ? new ProductPropertyOptionViewModel(this.ProductPropertyOption).ToJsonContract(): this.ProductPropertyOption),
				FilterOption: (this.FilterOption ? new FilterViewModel.FilterOptionViewModel(this.FilterOption).ToJsonContract(): this.FilterOption),
				Active: this.Active
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.PropertyOptionFilterOptionsViewModel
	 */
	 

	export interface IPropertyOptionFilterOptionsViewModel {
		PropertyOptionFilterOption: PropertyOptionFilterOptionViewModel[];
		ToJsonContract(): DataContracts.IPropertyOptionFilterOptionsViewModel;
	}

	export class PropertyOptionFilterOptionsViewModel {
		// property-Properties
		public get PropertyOptionFilterOption(): PropertyOptionFilterOptionViewModel[] {
			return this._PropertyOptionFilterOption;
		}
		public set PropertyOptionFilterOption(newPropertyOptionFilterOption: PropertyOptionFilterOptionViewModel[]) {
			this._PropertyOptionFilterOption = newPropertyOptionFilterOption;
		}

		// field-Properties

		// fields
		protected _PropertyOptionFilterOption: PropertyOptionFilterOptionViewModel[] = [];

		constructor(data?: DataContracts.IPropertyOptionFilterOptionsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPropertyOptionFilterOptionsViewModel): void {
			if(data) {
				// vul properties
				this._PropertyOptionFilterOption = data.PropertyOptionFilterOption ? data.PropertyOptionFilterOption.map(propertyOptionFilterOptionViewModelItem => new PropertyOptionFilterOptionViewModel(propertyOptionFilterOptionViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPropertyOptionFilterOptionsViewModel {
			return {
				PropertyOptionFilterOption: (this.PropertyOptionFilterOption ? this.PropertyOptionFilterOption.map(propertyOptionFilterOptionViewModelItem => new PropertyOptionFilterOptionViewModel(propertyOptionFilterOptionViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterOptionRelationViewModel
	 */
	 

	export interface IFilterOptionRelationViewModel {
		Id: number;
		Product: ProductViewModel;
		FilterOption: FilterViewModel.IFilterOptionViewModel;
		Active: boolean;
		ToJsonContract(): DataContracts.IFilterOptionRelationViewModel;
	}

	export class FilterOptionRelationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Product(): ProductViewModel {
			return this._Product;
		}
		public set Product(newProduct: ProductViewModel) {
			this._Product = newProduct;
		}

		public get FilterOption(): FilterViewModel.IFilterOptionViewModel {
			return this._FilterOption;
		}
		public set FilterOption(newFilterOption: FilterViewModel.IFilterOptionViewModel) {
			this._FilterOption = newFilterOption;
		}

		public get Active(): boolean {
			return this._Active;
		}
		public set Active(newActive: boolean) {
			this._Active = newActive;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Product: ProductViewModel = new ProductViewModel(undefined);
		protected _FilterOption: FilterViewModel.IFilterOptionViewModel = new FilterViewModel.FilterOptionViewModel(undefined);
		protected _Active: boolean = false;

		constructor(data?: DataContracts.IFilterOptionRelationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterOptionRelationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Product = data.Product ? new ProductViewModel(data.Product): undefined;
				this._FilterOption = data.FilterOption ? new FilterViewModel.FilterOptionViewModel(data.FilterOption): undefined;
				this._Active = data.Active;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterOptionRelationViewModel {
			return {
				Id: this.Id,
				Product: (this.Product ? new ProductViewModel(this.Product).ToJsonContract(): this.Product),
				FilterOption: (this.FilterOption ? new FilterViewModel.FilterOptionViewModel(this.FilterOption).ToJsonContract(): this.FilterOption),
				Active: this.Active
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterRelationViewModel
	 */
	 

	export interface IFilterRelationViewModel {
		Id: number;
		Product: ProductViewModel;
		Filter: FilterViewModel.IFilterViewModel;
		Active: boolean;
		FilterOptionsRelation: FilterOptionRelationViewModel[];
		ToJsonContract(): DataContracts.IFilterRelationViewModel;
	}

	export class FilterRelationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Product(): ProductViewModel {
			return this._Product;
		}
		public set Product(newProduct: ProductViewModel) {
			this._Product = newProduct;
		}

		public get Filter(): FilterViewModel.IFilterViewModel {
			return this._Filter;
		}
		public set Filter(newFilter: FilterViewModel.IFilterViewModel) {
			this._Filter = newFilter;
		}

		public get Active(): boolean {
			return this._Active;
		}
		public set Active(newActive: boolean) {
			this._Active = newActive;
		}

		public get FilterOptionsRelation(): FilterOptionRelationViewModel[] {
			return this._FilterOptionsRelation;
		}
		public set FilterOptionsRelation(newFilterOptionsRelation: FilterOptionRelationViewModel[]) {
			this._FilterOptionsRelation = newFilterOptionsRelation;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Product: ProductViewModel = new ProductViewModel(undefined);
		protected _Filter: FilterViewModel.IFilterViewModel = new FilterViewModel.FilterViewModel(undefined);
		protected _Active: boolean = false;
		protected _FilterOptionsRelation: FilterOptionRelationViewModel[] = [];

		constructor(data?: DataContracts.IFilterRelationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterRelationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Product = data.Product ? new ProductViewModel(data.Product): undefined;
				this._Filter = data.Filter ? new FilterViewModel.FilterViewModel(data.Filter): undefined;
				this._Active = data.Active;
				this._FilterOptionsRelation = data.FilterOptionsRelation ? data.FilterOptionsRelation.map(filterOptionRelationViewModelItem => new FilterOptionRelationViewModel(filterOptionRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterRelationViewModel {
			return {
				Id: this.Id,
				Product: (this.Product ? new ProductViewModel(this.Product).ToJsonContract(): this.Product),
				Filter: (this.Filter ? new FilterViewModel.FilterViewModel(this.Filter).ToJsonContract(): this.Filter),
				Active: this.Active,
				FilterOptionsRelation: (this.FilterOptionsRelation ? this.FilterOptionsRelation.map(filterOptionRelationViewModelItem => new FilterOptionRelationViewModel(filterOptionRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertyRelationsViewModel
	 */
	 

	export interface IProductPropertyRelationsViewModel {
		ProductPropertyRelation: ProductPropertyRelationViewModel[];
		ToJsonContract(): DataContracts.IProductPropertyRelationsViewModel;
	}

	export class ProductPropertyRelationsViewModel {
		// property-Properties
		public get ProductPropertyRelation(): ProductPropertyRelationViewModel[] {
			return this._ProductPropertyRelation;
		}
		public set ProductPropertyRelation(newProductPropertyRelation: ProductPropertyRelationViewModel[]) {
			this._ProductPropertyRelation = newProductPropertyRelation;
		}

		// field-Properties

		// fields
		protected _ProductPropertyRelation: ProductPropertyRelationViewModel[] = [];

		constructor(data?: DataContracts.IProductPropertyRelationsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertyRelationsViewModel): void {
			if(data) {
				// vul properties
				this._ProductPropertyRelation = data.ProductPropertyRelation ? data.ProductPropertyRelation.map(productPropertyRelationViewModelItem => new ProductPropertyRelationViewModel(productPropertyRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertyRelationsViewModel {
			return {
				ProductPropertyRelation: (this.ProductPropertyRelation ? this.ProductPropertyRelation.map(productPropertyRelationViewModelItem => new ProductPropertyRelationViewModel(productPropertyRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertyRelationViewModel
	 */
	 

	export interface IProductPropertyRelationViewModel {
		Id: number;
		Product: ProductViewModel;
		ProductProperty: ProductPropertyViewModel;
		ToJsonContract(): DataContracts.IProductPropertyRelationViewModel;
	}

	export class ProductPropertyRelationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Product(): ProductViewModel {
			return this._Product;
		}
		public set Product(newProduct: ProductViewModel) {
			this._Product = newProduct;
		}

		public get ProductProperty(): ProductPropertyViewModel {
			return this._ProductProperty;
		}
		public set ProductProperty(newProductProperty: ProductPropertyViewModel) {
			this._ProductProperty = newProductProperty;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Product: ProductViewModel = new ProductViewModel(undefined);
		protected _ProductProperty: ProductPropertyViewModel = new ProductPropertyViewModel(undefined);

		constructor(data?: DataContracts.IProductPropertyRelationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertyRelationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Product = data.Product ? new ProductViewModel(data.Product): undefined;
				this._ProductProperty = data.ProductProperty ? new ProductPropertyViewModel(data.ProductProperty): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertyRelationViewModel {
			return {
				Id: this.Id,
				Product: (this.Product ? new ProductViewModel(this.Product).ToJsonContract(): this.Product),
				ProductProperty: (this.ProductProperty ? new ProductPropertyViewModel(this.ProductProperty).ToJsonContract(): this.ProductProperty)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertyVariantRelationsViewModel
	 */
	 

	export interface IProductPropertyVariantRelationsViewModel {
		ProductPropertyVariantRelation: ProductPropertyVariantRelationViewModel[];
		ToJsonContract(): DataContracts.IProductPropertyVariantRelationsViewModel;
	}

	export class ProductPropertyVariantRelationsViewModel {
		// property-Properties
		public get ProductPropertyVariantRelation(): ProductPropertyVariantRelationViewModel[] {
			return this._ProductPropertyVariantRelation;
		}
		public set ProductPropertyVariantRelation(newProductPropertyVariantRelation: ProductPropertyVariantRelationViewModel[]) {
			this._ProductPropertyVariantRelation = newProductPropertyVariantRelation;
		}

		// field-Properties

		// fields
		protected _ProductPropertyVariantRelation: ProductPropertyVariantRelationViewModel[] = [];

		constructor(data?: DataContracts.IProductPropertyVariantRelationsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertyVariantRelationsViewModel): void {
			if(data) {
				// vul properties
				this._ProductPropertyVariantRelation = data.ProductPropertyVariantRelation ? data.ProductPropertyVariantRelation.map(productPropertyVariantRelationViewModelItem => new ProductPropertyVariantRelationViewModel(productPropertyVariantRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertyVariantRelationsViewModel {
			return {
				ProductPropertyVariantRelation: (this.ProductPropertyVariantRelation ? this.ProductPropertyVariantRelation.map(productPropertyVariantRelationViewModelItem => new ProductPropertyVariantRelationViewModel(productPropertyVariantRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertyVariantRelationViewModel
	 */
	 

	export interface IProductPropertyVariantRelationViewModel {
		Id: number;
		ProductVariant: ProductVariantModel;
		ProductProperty: ProductPropertyViewModel;
		ToJsonContract(): DataContracts.IProductPropertyVariantRelationViewModel;
	}

	export class ProductPropertyVariantRelationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ProductVariant(): ProductVariantModel {
			return this._ProductVariant;
		}
		public set ProductVariant(newProductVariant: ProductVariantModel) {
			this._ProductVariant = newProductVariant;
		}

		public get ProductProperty(): ProductPropertyViewModel {
			return this._ProductProperty;
		}
		public set ProductProperty(newProductProperty: ProductPropertyViewModel) {
			this._ProductProperty = newProductProperty;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ProductVariant: ProductVariantModel = new ProductVariantModel(undefined);
		protected _ProductProperty: ProductPropertyViewModel = new ProductPropertyViewModel(undefined);

		constructor(data?: DataContracts.IProductPropertyVariantRelationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertyVariantRelationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ProductVariant = data.ProductVariant ? new ProductVariantModel(data.ProductVariant): undefined;
				this._ProductProperty = data.ProductProperty ? new ProductPropertyViewModel(data.ProductProperty): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertyVariantRelationViewModel {
			return {
				Id: this.Id,
				ProductVariant: (this.ProductVariant ? new ProductVariantModel(this.ProductVariant).ToJsonContract(): this.ProductVariant),
				ProductProperty: (this.ProductProperty ? new ProductPropertyViewModel(this.ProductProperty).ToJsonContract(): this.ProductProperty)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertyViewModel
	 */
	 

	export interface IProductPropertyViewModel {
		Id: number;
		Name: string;
		Products: number;
		Filters: number;
		Variants: number;
		Display: boolean;
		ManualAdded: boolean;
		Min: number;
		Max: number;
		Connected: boolean;
		Filter: FilterViewModel.IFilterViewModel;
		IgnoreFilter: boolean;
		LiveUpdate: boolean;
		ShowFilters: boolean;
		ProductPropertyType: ProductPropertyTypeViewModel;
		Options: ProductPropertyOptionViewModel[];
		Parent: string;
		ParentParent: string;
		ToJsonContract(): DataContracts.IProductPropertyViewModel;
	}

	export class ProductPropertyViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Products(): number {
			return this._Products;
		}
		public set Products(newProducts: number) {
			this._Products = newProducts;
		}

		public get Filters(): number {
			return this._Filters;
		}
		public set Filters(newFilters: number) {
			this._Filters = newFilters;
		}

		public get Variants(): number {
			return this._Variants;
		}
		public set Variants(newVariants: number) {
			this._Variants = newVariants;
		}

		public get Display(): boolean {
			return this._Display;
		}
		public set Display(newDisplay: boolean) {
			this._Display = newDisplay;
		}

		public get ManualAdded(): boolean {
			return this._ManualAdded;
		}
		public set ManualAdded(newManualAdded: boolean) {
			this._ManualAdded = newManualAdded;
		}

		public get Min(): number {
			return this._Min;
		}
		public set Min(newMin: number) {
			this._Min = newMin;
		}

		public get Max(): number {
			return this._Max;
		}
		public set Max(newMax: number) {
			this._Max = newMax;
		}

		public get Connected(): boolean {
			return this._Connected;
		}
		public set Connected(newConnected: boolean) {
			this._Connected = newConnected;
		}

		public get Filter(): FilterViewModel.IFilterViewModel {
			return this._Filter;
		}
		public set Filter(newFilter: FilterViewModel.IFilterViewModel) {
			this._Filter = newFilter;
		}

		public get IgnoreFilter(): boolean {
			return this._IgnoreFilter;
		}
		public set IgnoreFilter(newIgnoreFilter: boolean) {
			this._IgnoreFilter = newIgnoreFilter;
		}

		public get LiveUpdate(): boolean {
			return this._LiveUpdate;
		}
		public set LiveUpdate(newLiveUpdate: boolean) {
			this._LiveUpdate = newLiveUpdate;
		}

		public get ShowFilters(): boolean {
			return this._ShowFilters;
		}
		public set ShowFilters(newShowFilters: boolean) {
			this._ShowFilters = newShowFilters;
		}

		public get ProductPropertyType(): ProductPropertyTypeViewModel {
			return this._ProductPropertyType;
		}
		public set ProductPropertyType(newProductPropertyType: ProductPropertyTypeViewModel) {
			this._ProductPropertyType = newProductPropertyType;
		}

		public get Options(): ProductPropertyOptionViewModel[] {
			return this._Options;
		}
		public set Options(newOptions: ProductPropertyOptionViewModel[]) {
			this._Options = newOptions;
		}

		public get Parent(): string {
			return this._Parent;
		}
		public set Parent(newParent: string) {
			this._Parent = newParent;
		}

		public get ParentParent(): string {
			return this._ParentParent;
		}
		public set ParentParent(newParentParent: string) {
			this._ParentParent = newParentParent;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _Products: number = 0;
		protected _Filters: number = 0;
		protected _Variants: number = 0;
		protected _Display: boolean = false;
		protected _ManualAdded: boolean = false;
		protected _Min: number = 0;
		protected _Max: number = 0;
		protected _Connected: boolean = false;
		protected _Filter: FilterViewModel.IFilterViewModel = new FilterViewModel.FilterViewModel(undefined);
		protected _IgnoreFilter: boolean = false;
		protected _LiveUpdate: boolean = false;
		protected _ShowFilters: boolean = false;
		protected _ProductPropertyType: ProductPropertyTypeViewModel = new ProductPropertyTypeViewModel(undefined);
		protected _Options: ProductPropertyOptionViewModel[] = [];
		protected _Parent: string = "";
		protected _ParentParent: string = "";

		constructor(data?: DataContracts.IProductPropertyViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertyViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._Products = data.Products;
				this._Filters = data.Filters;
				this._Variants = data.Variants;
				this._Display = data.Display;
				this._ManualAdded = data.ManualAdded;
				this._Min = data.Min;
				this._Max = data.Max;
				this._Connected = data.Connected;
				this._Filter = data.Filter ? new FilterViewModel.FilterViewModel(data.Filter): undefined;
				this._IgnoreFilter = data.IgnoreFilter;
				this._LiveUpdate = data.LiveUpdate;
				this._ShowFilters = data.ShowFilters;
				this._ProductPropertyType = data.ProductPropertyType ? new ProductPropertyTypeViewModel(data.ProductPropertyType): undefined;
				this._Options = data.Options ? data.Options.map(productPropertyOptionViewModelItem => new ProductPropertyOptionViewModel(productPropertyOptionViewModelItem)): [];
				this._Parent = data.Parent;
				this._ParentParent = data.ParentParent;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertyViewModel {
			return {
				Id: this.Id,
				Name: this.Name,
				Products: this.Products,
				Filters: this.Filters,
				Variants: this.Variants,
				Display: this.Display,
				ManualAdded: this.ManualAdded,
				Min: this.Min,
				Max: this.Max,
				Connected: this.Connected,
				Filter: (this.Filter ? new FilterViewModel.FilterViewModel(this.Filter).ToJsonContract(): this.Filter),
				IgnoreFilter: this.IgnoreFilter,
				LiveUpdate: this.LiveUpdate,
				ShowFilters: this.ShowFilters,
				ProductPropertyType: (this.ProductPropertyType ? new ProductPropertyTypeViewModel(this.ProductPropertyType).ToJsonContract(): this.ProductPropertyType),
				Options: (this.Options ? this.Options.map(productPropertyOptionViewModelItem => new ProductPropertyOptionViewModel(productPropertyOptionViewModelItem).ToJsonContract()): []),
				Parent: this.Parent,
				ParentParent: this.ParentParent
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertySaveModel
	 */
	 

	export interface IProductPropertySaveModel {
		Id: number;
		Name: string;
		ProductPropertyTypeId: number;
		ProductId: number;
		ToJsonContract(): DataContracts.IProductPropertySaveModel;
	}

	export class ProductPropertySaveModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get ProductPropertyTypeId(): number {
			return this._ProductPropertyTypeId;
		}
		public set ProductPropertyTypeId(newProductPropertyTypeId: number) {
			this._ProductPropertyTypeId = newProductPropertyTypeId;
		}

		public get ProductId(): number {
			return this._ProductId;
		}
		public set ProductId(newProductId: number) {
			this._ProductId = newProductId;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _ProductPropertyTypeId: number = 0;
		protected _ProductId: number = 0;

		constructor(data?: DataContracts.IProductPropertySaveModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertySaveModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._ProductPropertyTypeId = data.ProductPropertyTypeId;
				this._ProductId = data.ProductId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertySaveModel {
			return {
				Id: this.Id,
				Name: this.Name,
				ProductPropertyTypeId: this.ProductPropertyTypeId,
				ProductId: this.ProductId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertyTypeViewModel
	 */
	 

	export interface IProductPropertyTypeViewModel {
		Id: number;
		Name: string;
		ToJsonContract(): DataContracts.IProductPropertyTypeViewModel;
	}

	export class ProductPropertyTypeViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";

		constructor(data?: DataContracts.IProductPropertyTypeViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertyTypeViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertyTypeViewModel {
			return {
				Id: this.Id,
				Name: this.Name
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertyTypesViewModel
	 */
	 

	export interface IProductPropertyTypesViewModel {
		ProductPropertyType: ProductPropertyTypeViewModel[];
		ToJsonContract(): DataContracts.IProductPropertyTypesViewModel;
	}

	export class ProductPropertyTypesViewModel {
		// property-Properties
		public get ProductPropertyType(): ProductPropertyTypeViewModel[] {
			return this._ProductPropertyType;
		}
		public set ProductPropertyType(newProductPropertyType: ProductPropertyTypeViewModel[]) {
			this._ProductPropertyType = newProductPropertyType;
		}

		// field-Properties

		// fields
		protected _ProductPropertyType: ProductPropertyTypeViewModel[] = [];

		constructor(data?: DataContracts.IProductPropertyTypesViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertyTypesViewModel): void {
			if(data) {
				// vul properties
				this._ProductPropertyType = data.ProductPropertyType ? data.ProductPropertyType.map(productPropertyTypeViewModelItem => new ProductPropertyTypeViewModel(productPropertyTypeViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertyTypesViewModel {
			return {
				ProductPropertyType: (this.ProductPropertyType ? this.ProductPropertyType.map(productPropertyTypeViewModelItem => new ProductPropertyTypeViewModel(productPropertyTypeViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertyOptionViewModel
	 */
	 

	export interface IProductPropertyOptionViewModel {
		Id: number;
		ProductPropertyId: number;
		Bot: string;
		DO1Type: string;
		DO1Naam: string;
		DO2Type: string;
		DO2Naam: string;
		DO3Type: string;
		DO3Naam: string;
		DO4Type: string;
		DO4Naam: string;
		Kenmerk: string;
		Description: string;
		Connected: boolean;
		ShowFilters: boolean;
		FilterOptions: FilterViewModel.IFilterOptionViewModel[];
		IgnoreFilterOption: boolean;
		ProductPropertiesEigenschappen: ProductPropertyViewModel[];
		ProductPropertiesOpbouw: ProductPropertyViewModel[];
		ToJsonContract(): DataContracts.IProductPropertyOptionViewModel;
	}

	export class ProductPropertyOptionViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ProductPropertyId(): number {
			return this._ProductPropertyId;
		}
		public set ProductPropertyId(newProductPropertyId: number) {
			this._ProductPropertyId = newProductPropertyId;
		}

		public get Bot(): string {
			return this._Bot;
		}
		public set Bot(newBot: string) {
			this._Bot = newBot;
		}

		public get DO1Type(): string {
			return this._DO1Type;
		}
		public set DO1Type(newDO1Type: string) {
			this._DO1Type = newDO1Type;
		}

		public get DO1Naam(): string {
			return this._DO1Naam;
		}
		public set DO1Naam(newDO1Naam: string) {
			this._DO1Naam = newDO1Naam;
		}

		public get DO2Type(): string {
			return this._DO2Type;
		}
		public set DO2Type(newDO2Type: string) {
			this._DO2Type = newDO2Type;
		}

		public get DO2Naam(): string {
			return this._DO2Naam;
		}
		public set DO2Naam(newDO2Naam: string) {
			this._DO2Naam = newDO2Naam;
		}

		public get DO3Type(): string {
			return this._DO3Type;
		}
		public set DO3Type(newDO3Type: string) {
			this._DO3Type = newDO3Type;
		}

		public get DO3Naam(): string {
			return this._DO3Naam;
		}
		public set DO3Naam(newDO3Naam: string) {
			this._DO3Naam = newDO3Naam;
		}

		public get DO4Type(): string {
			return this._DO4Type;
		}
		public set DO4Type(newDO4Type: string) {
			this._DO4Type = newDO4Type;
		}

		public get DO4Naam(): string {
			return this._DO4Naam;
		}
		public set DO4Naam(newDO4Naam: string) {
			this._DO4Naam = newDO4Naam;
		}

		public get Kenmerk(): string {
			return this._Kenmerk;
		}
		public set Kenmerk(newKenmerk: string) {
			this._Kenmerk = newKenmerk;
		}

		public get Description(): string {
			return this._Description;
		}
		public set Description(newDescription: string) {
			this._Description = newDescription;
		}

		public get Connected(): boolean {
			return this._Connected;
		}
		public set Connected(newConnected: boolean) {
			this._Connected = newConnected;
		}

		public get ShowFilters(): boolean {
			return this._ShowFilters;
		}
		public set ShowFilters(newShowFilters: boolean) {
			this._ShowFilters = newShowFilters;
		}

		public get FilterOptions(): FilterViewModel.IFilterOptionViewModel[] {
			return this._FilterOptions;
		}
		public set FilterOptions(newFilterOptions: FilterViewModel.IFilterOptionViewModel[]) {
			this._FilterOptions = newFilterOptions;
		}

		public get IgnoreFilterOption(): boolean {
			return this._IgnoreFilterOption;
		}
		public set IgnoreFilterOption(newIgnoreFilterOption: boolean) {
			this._IgnoreFilterOption = newIgnoreFilterOption;
		}

		public get ProductPropertiesEigenschappen(): ProductPropertyViewModel[] {
			return this._ProductPropertiesEigenschappen;
		}
		public set ProductPropertiesEigenschappen(newProductPropertiesEigenschappen: ProductPropertyViewModel[]) {
			this._ProductPropertiesEigenschappen = newProductPropertiesEigenschappen;
		}

		public get ProductPropertiesOpbouw(): ProductPropertyViewModel[] {
			return this._ProductPropertiesOpbouw;
		}
		public set ProductPropertiesOpbouw(newProductPropertiesOpbouw: ProductPropertyViewModel[]) {
			this._ProductPropertiesOpbouw = newProductPropertiesOpbouw;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ProductPropertyId: number = 0;
		protected _Bot: string = "";
		protected _DO1Type: string = "";
		protected _DO1Naam: string = "";
		protected _DO2Type: string = "";
		protected _DO2Naam: string = "";
		protected _DO3Type: string = "";
		protected _DO3Naam: string = "";
		protected _DO4Type: string = "";
		protected _DO4Naam: string = "";
		protected _Kenmerk: string = "";
		protected _Description: string = "";
		protected _Connected: boolean = false;
		protected _ShowFilters: boolean = false;
		protected _FilterOptions: FilterViewModel.IFilterOptionViewModel[] = [];
		protected _IgnoreFilterOption: boolean = false;
		protected _ProductPropertiesEigenschappen: ProductPropertyViewModel[] = [];
		protected _ProductPropertiesOpbouw: ProductPropertyViewModel[] = [];

		constructor(data?: DataContracts.IProductPropertyOptionViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertyOptionViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ProductPropertyId = data.ProductPropertyId;
				this._Bot = data.Bot;
				this._DO1Type = data.DO1Type;
				this._DO1Naam = data.DO1Naam;
				this._DO2Type = data.DO2Type;
				this._DO2Naam = data.DO2Naam;
				this._DO3Type = data.DO3Type;
				this._DO3Naam = data.DO3Naam;
				this._DO4Type = data.DO4Type;
				this._DO4Naam = data.DO4Naam;
				this._Kenmerk = data.Kenmerk;
				this._Description = data.Description;
				this._Connected = data.Connected;
				this._ShowFilters = data.ShowFilters;
				this._FilterOptions = data.FilterOptions ? data.FilterOptions.map(filterOptionViewModelItem => new FilterViewModel.FilterOptionViewModel(filterOptionViewModelItem)): [];
				this._IgnoreFilterOption = data.IgnoreFilterOption;
				this._ProductPropertiesEigenschappen = data.ProductPropertiesEigenschappen ? data.ProductPropertiesEigenschappen.map(productPropertyViewModelItem => new ProductPropertyViewModel(productPropertyViewModelItem)): [];
				this._ProductPropertiesOpbouw = data.ProductPropertiesOpbouw ? data.ProductPropertiesOpbouw.map(productPropertyViewModelItem => new ProductPropertyViewModel(productPropertyViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertyOptionViewModel {
			return {
				Id: this.Id,
				ProductPropertyId: this.ProductPropertyId,
				Bot: this.Bot,
				DO1Type: this.DO1Type,
				DO1Naam: this.DO1Naam,
				DO2Type: this.DO2Type,
				DO2Naam: this.DO2Naam,
				DO3Type: this.DO3Type,
				DO3Naam: this.DO3Naam,
				DO4Type: this.DO4Type,
				DO4Naam: this.DO4Naam,
				Kenmerk: this.Kenmerk,
				Description: this.Description,
				Connected: this.Connected,
				ShowFilters: this.ShowFilters,
				FilterOptions: (this.FilterOptions ? this.FilterOptions.map(filterOptionViewModelItem => new FilterViewModel.FilterOptionViewModel(filterOptionViewModelItem).ToJsonContract()): []),
				IgnoreFilterOption: this.IgnoreFilterOption,
				ProductPropertiesEigenschappen: (this.ProductPropertiesEigenschappen ? this.ProductPropertiesEigenschappen.map(productPropertyViewModelItem => new ProductPropertyViewModel(productPropertyViewModelItem).ToJsonContract()): []),
				ProductPropertiesOpbouw: (this.ProductPropertiesOpbouw ? this.ProductPropertiesOpbouw.map(productPropertyViewModelItem => new ProductPropertyViewModel(productPropertyViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertyOptionsViewModel
	 */
	 

	export interface IProductPropertyOptionsViewModel {
		ProductPropertyOption: ProductPropertyOptionViewModel[];
		ToJsonContract(): DataContracts.IProductPropertyOptionsViewModel;
	}

	export class ProductPropertyOptionsViewModel {
		// property-Properties
		public get ProductPropertyOption(): ProductPropertyOptionViewModel[] {
			return this._ProductPropertyOption;
		}
		public set ProductPropertyOption(newProductPropertyOption: ProductPropertyOptionViewModel[]) {
			this._ProductPropertyOption = newProductPropertyOption;
		}

		// field-Properties

		// fields
		protected _ProductPropertyOption: ProductPropertyOptionViewModel[] = [];

		constructor(data?: DataContracts.IProductPropertyOptionsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertyOptionsViewModel): void {
			if(data) {
				// vul properties
				this._ProductPropertyOption = data.ProductPropertyOption ? data.ProductPropertyOption.map(productPropertyOptionViewModelItem => new ProductPropertyOptionViewModel(productPropertyOptionViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertyOptionsViewModel {
			return {
				ProductPropertyOption: (this.ProductPropertyOption ? this.ProductPropertyOption.map(productPropertyOptionViewModelItem => new ProductPropertyOptionViewModel(productPropertyOptionViewModelItem).ToJsonContract()): [])
			}
		}
	}
