/// <reference path='../consumerinterfaces/Geometry2DController.ts' />
/**
 * @ref WoonConnectApiController/Geometry/Geometry2DController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/Geometry2DController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as TekeningConfiguratie from "@datacontracts/TekeningConfiguratie";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class Geometry2DConsumer implements Interface.IGeometry2DConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public Massa2D_Observable(tekeningConfiguratieModel: TekeningConfiguratie.ITekeningConfiguratie & {ToJsonContract(noContent?: boolean): TekeningConfiguratie.ITekeningConfiguratie}): Observable<TekeningConfiguratie.ITekeningConfiguratieResult> {
				const tekeningConfiguratie = tekeningConfiguratieModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = tekeningConfiguratie;
				let apiURL = `api/Geometry2D/Massa2D/${tekeningConfiguratie}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<TekeningConfiguratie.ITekeningConfiguratieResult>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Massa2D(tekeningConfiguratieModel: TekeningConfiguratie.ITekeningConfiguratie & {ToJsonContract(noContent?: boolean): TekeningConfiguratie.ITekeningConfiguratie}): ng.IPromise<TekeningConfiguratie.ITekeningConfiguratieResult> {
				let obs = this.Massa2D_Observable(tekeningConfiguratieModel);
				let promise = new Promise<TekeningConfiguratie.ITekeningConfiguratieResult>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


