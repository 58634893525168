import * as _ from 'lodash';
import { WindowService } from '../../viewers/WindowService';
import { MenuService } from '../../services/MenuService2';
//import { WijzigDetectieService } from '../../services/WijzigDetectieService';
import { MenuItem } from '../../Models/MenuItem';
import { Component, ElementRef } from "@angular/core";
import { MenuSelectorService } from '../MenuSelectorService';


@Component({
    selector: 'wct-beheer-menu-header',
	templateUrl: './WctBeheerMenuHeader.html',
	styleUrls: ['./WctBeheerMenuHeader.scss']
})
export class WctBeheerMenuHeaderComponent {
	public menuItems: MenuItem[] = [];
	public showButton: boolean = true;
	public  dropdownIsOpen: boolean = false;
	public showOnlyFirstMenuItem: boolean = false;
	

	constructor(
		public menuService: MenuService,
		private windowService: WindowService,
		//private wijzigDetectieService: WijzigDetectieService,
		private menuSelectorService: MenuSelectorService
	) {
'ngInject';
		var self = this;
	
		this.menuService.GeefMenus().then(m => {
			self.menuItems = m;
			
			setTimeout(() => {
				setTimeout(() => {
					self.GeefMenuAfmetingen();

					setTimeout(() => {
						self.ControleerOfHetMenuMoetWordenAangepast();
					});
				})
			})

		});


		this.windowService.changeSubject.subscribe({
			next: () => {
				setTimeout(() => {
					self.ControleerOfHetMenuMoetWordenAangepast();
				});
			}
		 });
	}

	ngOnInit() {
		
	}
	public $onChanges(changes: any): void {
		// if (this.breakWidths.length !== this.$visibleLinks.children().length) {
		// 	this.GeefMenuAfmetingen();

		// 	var self = this;
		// 	setTimeout(() => {
		// 		self.ControleerOfHetMenuMoetWordenAangepast();
		// 	});
		// }
	}

	ngAfterViewInit(){
		// this.$visibleLinks = this.elementRef.nativeElement.querySelectorAll('nav.nav-menu-header .links');
		// console.log(this.$visibleLinks[0]);
		// this.$hlinks = this.elementRef.nativeElement.querySelectorAll('nav.nav-menu-header .hidden-links');
		
	}


	public GeefMenuAfmetingen() {
	

		// for (var i = 0; i < this.$visibleLinks.children().length; i++) {
		// 	let test: number = this.$visibleLinks.children()[i].offsetWidth;
		// 	this.totalSpace = this.totalSpace + test;
		// 	this.numOfItems += 1;
		// 	this.breakWidths.push(this.totalSpace);
		// }
	}

	public ControleerOfHetMenuMoetWordenAangepast() {
		this.showButton = true;
		// Get instant state
		// this.availableSpace = this.$visibleLinks.width() - 10;
		// this.numOfVisibleItems = this.$visibleLinks.children().length;
		// this.requiredSpace = this.breakWidths[this.numOfVisibleItems - 1];

		// // There is not enought space
		// if (this.requiredSpace > this.availableSpace) {
		// 	this.$visibleLinks.children().last().prependTo(this.$hlinks);
		// 	this.numOfVisibleItems -= 1;
		// 	this.ControleerOfHetMenuMoetWordenAangepast();
		// 	// There is more than enough space
		// } else if (this.availableSpace > this.breakWidths[this.numOfVisibleItems]) {
		// 	this.$hlinks.children().first().appendTo(this.$visibleLinks);
		// 	this.numOfVisibleItems += 1;
		// }
		// // Update the button accordingly
		// this.AantalMenuItemsDieNietZichtbaarZijn = this.numOfItems - this.numOfVisibleItems;
		// if (this.numOfVisibleItems === this.numOfItems) {
		// 	this.showButton = false;
		// } else {
		// 	this.showButton = true;
		// }

		// this.MenuIsGeladen = true;
	}

	public isActive(menuItem: MenuItem): boolean {
		var active: boolean = this.menuService.IsActief(menuItem);
		return active;
	}

	public showMenuItem(redirectTarget: string): boolean {
		let returnValue = false;
		if ((redirectTarget == "headeradresdiv") || ((redirectTarget !== "nieuweenquete") && this.showOnlyFirstMenuItem)) {
			returnValue = false;
		} else {
			returnValue = true;
		}
		return returnValue;
	}

	public clickMenu(menu: MenuItem): void {
		// this.wijzigDetectieService.ExecuteActionWithPermission().then(res => {
		// 	if (res) {
		// 		this.dropdownIsOpen = false;
		// 		this.menuService.ZetActiefMenuItem(menu);
		// 		this.menuService.ClickMenu(menu);
		// 	}
		// });
		this.menuSelectorService.setSubMenu(menu);
}

}

