<wct-stui-layout-two-panels>
  <div class="header">
    <wct-stui-main-header>
      <div class="content">
        <wct-dwelling-selector></wct-dwelling-selector>
      </div>
    </wct-stui-main-header>
  </div>
  <div class="left">
    <div class="menu">
      <wct-stui-tree-menu [tree]="SurveyTree$ | async" (nodeSelected)="selectCategory($event)"></wct-stui-tree-menu>
    </div>
    <div class="buttons">
      <wct-stui-button (action)="Save()">Sla op in het model</wct-stui-button>
      <wct-stui-button (action)="Test()">Run ontwikkeltest</wct-stui-button>
      <wct-stui-button (action)="showSignOut()">Afmelden</wct-stui-button>
    </div>
  </div>
  <div class="right" *ngIf="(FormState$ | async).controls !== undefined">
    <h2>{{ (ActiveNode$ | async).title }}</h2>
    <div *ngIf="!signOutIsActive && (FormState$ | async) as formState">
      <div [ngSwitch]="(ActiveNode$ | async).category">
        <!-- CalculationZone -->
        <div [ngSwitch]="(ActiveNode$ | async).category">
          <div *ngSwitchCase="surveyCategories.CalculationZone">
            <div *ngFor="let control of formState.controls.CalculationZones.controls;
            trackBy: trackByIndex;
            let i = index">
              <wct-survey-calculation-zone [system]="control"></wct-survey-calculation-zone>
            </div>
          </div>
        </div>
        <!-- Installations -->
        <div *ngSwitchCase="surveyCategories.Installations">
          <wct-survey-climate-systems [systems]="formState.controls.ClimateSystem"></wct-survey-climate-systems>
        </div>
        <div *ngSwitchCase="surveyCategories.ClimateSystem">
          <wct-survey-climate-system [system]='ActiveClimateSystem$ | async'></wct-survey-climate-system>
          <wct-survey-notes [Dwelling]="GetDwelling()" [ObjectId]="(ActiveClimateSystem$ | async).controls.ObjectId.value">
          </wct-survey-notes>
        </div>
        <!-- General data -->
        <div *ngSwitchCase="surveyCategories.GeneralData">
          <wct-survey-generic *ngIf="formState.controls.Generic" [generic]='formState.controls.Generic'>
          </wct-survey-generic>
        </div>
        <!-- Building data -->
        <div *ngSwitchCase="surveyCategories.BuildingData">
          <wct-survey-building [building]='formState.controls.Building'>
          </wct-survey-building>
        </div>
        <!-- Facades -->
        <div *ngSwitchCase="surveyCategories.Facades">
          <wct-survey-facades [facades]="formState.controls.Walls"></wct-survey-facades>
        </div>
      </div>
    </div>
    <wct-afmelden *ngIf="signOutIsActive"></wct-afmelden>
  </div>
</wct-stui-layout-two-panels>


<!-- <br />
<br />
 -->
