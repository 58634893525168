/**
 * @ref WoonConnectViewModel/Survey/SurveyWall.cs
 */

 import * as DataContracts from "@datacontracts/SurveyWall";

 
import * as SurveyProperty from "./SurveyProperty";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.SurveyWall
	 */
	 

	export interface ISurveyWall {
		ObjectId: string;
		Name: SurveyProperty.ISurveyProperty;
		Surface: SurveyProperty.ISurveyProperty;
		Inclination: SurveyProperty.ISurveyProperty;
		Orientation: SurveyProperty.ISurveyProperty;
		IsolationThickness: SurveyProperty.ISurveyProperty;
		ToJsonContract(): DataContracts.ISurveyWall;
	}

	export class SurveyWall {
		// property-Properties
		public get ObjectId(): string {
			return this._ObjectId;
		}
		public set ObjectId(newObjectId: string) {
			this._ObjectId = newObjectId;
		}

		public get Name(): SurveyProperty.ISurveyProperty {
			return this._Name;
		}
		public set Name(newName: SurveyProperty.ISurveyProperty) {
			this._Name = newName;
		}

		public get Surface(): SurveyProperty.ISurveyProperty {
			return this._Surface;
		}
		public set Surface(newSurface: SurveyProperty.ISurveyProperty) {
			this._Surface = newSurface;
		}

		public get Inclination(): SurveyProperty.ISurveyProperty {
			return this._Inclination;
		}
		public set Inclination(newInclination: SurveyProperty.ISurveyProperty) {
			this._Inclination = newInclination;
		}

		public get Orientation(): SurveyProperty.ISurveyProperty {
			return this._Orientation;
		}
		public set Orientation(newOrientation: SurveyProperty.ISurveyProperty) {
			this._Orientation = newOrientation;
		}

		public get IsolationThickness(): SurveyProperty.ISurveyProperty {
			return this._IsolationThickness;
		}
		public set IsolationThickness(newIsolationThickness: SurveyProperty.ISurveyProperty) {
			this._IsolationThickness = newIsolationThickness;
		}

		// field-Properties

		// fields
		protected _ObjectId: string = "";
		protected _Name: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _Surface: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _Inclination: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _Orientation: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _IsolationThickness: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);

		constructor(data?: DataContracts.ISurveyWall) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISurveyWall): void {
			if(data) {
				// vul properties
				this._ObjectId = data.ObjectId;
				this._Name = data.Name ? new SurveyProperty.SurveyProperty(data.Name): undefined;
				this._Surface = data.Surface ? new SurveyProperty.SurveyProperty(data.Surface): undefined;
				this._Inclination = data.Inclination ? new SurveyProperty.SurveyProperty(data.Inclination): undefined;
				this._Orientation = data.Orientation ? new SurveyProperty.SurveyProperty(data.Orientation): undefined;
				this._IsolationThickness = data.IsolationThickness ? new SurveyProperty.SurveyProperty(data.IsolationThickness): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISurveyWall {
			return {
				ObjectId: this.ObjectId,
				Name: (this.Name ? new SurveyProperty.SurveyProperty(this.Name).ToJsonContract(): this.Name),
				Surface: (this.Surface ? new SurveyProperty.SurveyProperty(this.Surface).ToJsonContract(): this.Surface),
				Inclination: (this.Inclination ? new SurveyProperty.SurveyProperty(this.Inclination).ToJsonContract(): this.Inclination),
				Orientation: (this.Orientation ? new SurveyProperty.SurveyProperty(this.Orientation).ToJsonContract(): this.Orientation),
				IsolationThickness: (this.IsolationThickness ? new SurveyProperty.SurveyProperty(this.IsolationThickness).ToJsonContract(): this.IsolationThickness)
			}
		}
	}
