<div class="content">
  <link href="https://cdn.materialdesignicons.com/2.1.19/css/materialdesignicons.min.css" rel="stylesheet" />

  <div *ngIf="Loading || LoadingInstructie" class="mat-progress-spinner__cont">
    <div style="padding-top: 300px; padding-bottom: 300px; text-align: center;">
      <div>
        <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
      </div>
      <div>
        <p>De producten worden geladen</p>
      </div>
    </div>
  </div>

  <div *ngIf="!Loading && !LoadingInstructie">
    <h1>Standaard condities</h1>

    <div>
      <div class="block">
        <h2>Voorwaarden</h2>
        <table style="width: 100%;">
          <tr>
            <td style="width: 100px;"><img src="/Content/KlantSpecifiek/BCB21111/PDF/VoorwaardenImage/{{ VoorwaardenViewModel.FileNameImage }}?a={{ GetTime() }}" style=" height: 60px;" /></td>
            <td><a href="/Content/KlantSpecifiek/BCB21111/PDF/Voorwaarden/{{ VoorwaardenViewModel.FileName }}?a={{ GetTime() }}" target="_blank">{{ VoorwaardenViewModel.FileName }}</a>  </td>
          </tr>
        </table>
      </div>
      <br />
      <div class="block">
        <h2>Nieuwe upload PDF voorwaarden</h2>

        <input #file type="file" (change)="uploadVoorwaarden(file.files)">
      </div>
      <div class="block">
        <h2>Nieuwe upload PDF image</h2>

        <input #fileimage type="file" (change)="uploadVoorwaardenImage(fileimage.files)">
      </div>
      <br />
      <br />
    </div>

    <div>
      <div class="block">
        <h2>Instructie</h2>
        <table style="width: 100%;">
          <tr>
            <td style="width: 100px;"><img src="/Content/KlantSpecifiek/BCB21111/PDF/InstructieImage/{{ InstructieViewModel.FileNameImage }}?a={{ GetTime() }}" style=" height: 60px;" /></td>
            <td><a href="/Content/KlantSpecifiek/BCB21111/PDF/Instructie/{{ InstructieViewModel.FileName }}?a={{ GetTime() }}" target="_blank">{{ InstructieViewModel.FileName }}</a>  </td>
          </tr>
        </table>
      </div>
      <br />
      <div class="block">
        <h2>Nieuwe upload PDF instructie</h2>

        <input #filein type="file" (change)="uploadInstructie(filein.files)">
      </div>
      <div class="block">
        <h2>Nieuwe upload PDF instructie image</h2>

        <input #fileinimage type="file" (change)="uploadInstructieImage(fileinimage.files)">
      </div>
      <br />
      <br />
    </div>
  </div>
</div>
