<div class="property-add">
    <!--Only add a product property if its needed. -->
    <p>
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Selected Product</mat-label>
            <mat-select matNativeControl [(ngModel)]="ProductId">
                <mat-option *ngFor="let product of Products.Products" [value]="product.Id">
                    {{product.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Select Property</mat-label>
            <mat-select matNativeControl [(ngModel)]="ProductProperty.Id">
                <mat-option *ngFor="let property of ProductProperties.Property" [value]="property.Id">
                    {{property.Name}}
                </mat-option>
                <mat-option value="-1">
                    Voeg nieuwe type toe
                </mat-option>
            </mat-select>
        </mat-form-field>
    </p>
    <p *ngIf="ProductProperty.Id == -1">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Product Property</mat-label>
            <input matInput name="propertyName" [(ngModel)]="ProductProperty.Name" ngModel required min-length="4" #productproperty="ngModel">
        </mat-form-field>
    </p>
    <div *ngIf="ProductProperty.Id == -1">
        <p>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Select Property Type</mat-label>
                <mat-select matNativeControl [(ngModel)]="ProductPropertyType.Id">
                    <mat-option *ngFor="let propertytype of ProductPropertyTypesViewModel.ProductPropertyType" [value]="propertytype.Id">
                        {{propertytype.Name}}
                    </mat-option>
                    <mat-option value="-1">
                        Voeg nieuwe type toe
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </p>
        <p *ngIf="ProductPropertyType.Id == -1">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Property Type</mat-label>
                <input matInput name="propertyType" [(ngModel)]="ProductPropertyType.Name" ngModel required #type="ngModel">
            </mat-form-field>
        </p>
    </div>

    <p>
        <button mat-raised-button color="primary" (click)="saveProductProperty()">Voeg Toe</button>
    </p>
</div>