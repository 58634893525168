import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, Input, Output, EventEmitter, OnChanges, OnInit, OnDestroy, SimpleChanges, Inject, ElementRef, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { SurveyConsumer } from '@consumers/SurveyController';
import { WoningEventService } from '@services/WoningEventService';
import { GetSurveyRequestModel } from '@models/GetSurveyModel';
import { IDwellingSurvey } from '@datacontracts/DwellingSurvey';
import { Store, select, createSelector, createFeatureSelector, ActionsSubject } from '@ngrx/store';
import { SurveyCategories, SurveyState } from '../store/survey.state';
import * as Actions from '../store/survey.actions';
import { Observable, Subscription } from 'rxjs';
import { ISurveyWall } from '@datacontracts/SurveyWall';
import { AddArrayControlAction, FormArrayState, FormGroupState } from 'ngrx-forms';
import { v4 as uuidv4 } from 'uuid';
import { treenode } from '../../smarttwin-ui/components/wct-stui-tree-menu/wct-stui-tree-menu.component';
import { ErrorService } from '../../../configurator/ErrorService';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WctErrorDialog } from '../../smarttwin-ui/components/wct-error-dialog/WctErrorDialog';
import { ISurveyClimateSystem } from '@datacontracts/SurveyClimateSystem';
import { map, take } from 'rxjs/operators';
import { SurveyClimateSystem } from '@models/SurveyClimateSystem';

@Component({
	selector: 'wct-survey',
	templateUrl: './WctSurvey.html',
	styleUrls: ['./WctSurvey.scss']
})
export class WctSurveyComponent implements OnDestroy {
	Walls$: Observable<FormGroupState<IDwellingSurvey>>;
	FormState$: Observable<FormGroupState<IDwellingSurvey>>;
  ActiveNode$: Observable<treenode>;
  ActiveClimateSystem$: Observable<FormGroupState<ISurveyClimateSystem>>;
  SurveyTree$: Observable<treenode[]>
  ActiveCategoryName: string;
	m_Subscription: Subscription;
  signOutIsActive: boolean = false;

  surveyCategories = SurveyCategories;
	constructor(
		private store: Store<{ survey: SurveyState }>,
		public dialog: MatDialog,
		private errorservice: ErrorService,
		private woningEventService: WoningEventService,
    private actionsSubject: ActionsSubject
	) {
		this.store.dispatch(Actions.requestLoadSurvey());

		this.FormState$ = store.pipe(select(s => s.survey.Survey));
    this.ActiveNode$ = store.select(s => s.survey.ActiveNode);
    this.SurveyTree$ = store.select(s => s.survey.SurveyTree);
    this.ActiveClimateSystem$ = store.select((s => s.survey)).pipe(map((s) => {
      let activeSystem: FormGroupState<ISurveyClimateSystem> = null;
      s.Survey.controls.ClimateSystem.controls.forEach((system) => {
        if(system.id == s.ActiveClimateSystemId) {
          activeSystem = system;
        }
      });
      return activeSystem;
    }))

		this.m_Subscription = this.errorservice.GetErrors().subscribe(err => {
			const dialogConfig = new MatDialogConfig();
			dialogConfig.data = err;
			dialogConfig.panelClass = "configurator__mat-dialog";
			this.dialog.open(WctErrorDialog, dialogConfig);
		});
	}

	ngOnDestroy(): void {
		this.m_Subscription.unsubscribe();
	}

	trackByIndex(index, item) {
		return index;
	}

	GetDwelling()
	{
		// todo
		return null;
	}

	Test()
	{
		this.store.dispatch(Actions.requestTest());
	}

	Save()
	{
		this.store.dispatch(Actions.requestSave());
	}

  selectCategory(node: treenode) {
    this.store.dispatch(Actions.setActiveTreeNode({ node: node }));
    // if(node.category !== undefined) {
    //   this.signOutIsActive = false;
    //   this.ActiveCategoryName = node.title;

    // }
  }

  showSignOut() {
    this.signOutIsActive = true;
  }

  getSystemControl(tag: any): FormGroupState<ISurveyClimateSystem> {
    let foundSystem: FormGroupState<ISurveyClimateSystem> = null;
    let sub: Subscription = this.FormState$.subscribe((formState) => {
      formState.controls.ClimateSystem.controls.forEach((system) => {
        if(system.id == tag) {
          return foundSystem = system;
        }
      });
    })
    sub.unsubscribe();
    return foundSystem;
  }
}
