/**
 * @ref WoonConnectViewModel/User/UserChangePasswordModel.cs
 */

 import * as DataContracts from "@datacontracts/UserChangePasswordModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.User.UserChangePasswordRequestModel
	 */
	 

	export interface IUserChangePasswordRequestModel {
		UserId: string;
		NewPassword: string;
		ToJsonContract(): DataContracts.IUserChangePasswordRequestModel;
	}

	export class UserChangePasswordRequestModel {
		// property-Properties
		public get UserId(): string {
			return this._UserId;
		}
		public set UserId(newUserId: string) {
			this._UserId = newUserId;
		}

		public get NewPassword(): string {
			return this._NewPassword;
		}
		public set NewPassword(newNewPassword: string) {
			this._NewPassword = newNewPassword;
		}

		// field-Properties

		// fields
		protected _UserId: string = "";
		protected _NewPassword: string = "";

		constructor(data?: DataContracts.IUserChangePasswordRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserChangePasswordRequestModel): void {
			if(data) {
				// vul properties
				this._UserId = data.UserId;
				this._NewPassword = data.NewPassword;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUserChangePasswordRequestModel {
			return {
				UserId: this.UserId,
				NewPassword: this.NewPassword
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.UserChangePasswordResponseModel
	 */
	 

	export interface IUserChangePasswordResponseModel {
		Succeeded: boolean;
		Message: string;
	}

	export class UserChangePasswordResponseModel {
		// property-Properties
		// field-Properties
		public get Succeeded(): boolean {
			return this._Succeeded;
		}
		public set Succeeded(newSucceeded: boolean) {
			this._Succeeded = newSucceeded;
		}
		
		public get Message(): string {
			return this._Message;
		}
		public set Message(newMessage: string) {
			this._Message = newMessage;
		}

		// fields
		protected _Succeeded: boolean = false;
		protected _Message: string = "";

		constructor(data?: DataContracts.IUserChangePasswordResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserChangePasswordResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Succeeded = data.Succeeded;
				this._Message = data.Message;
			}
		}
	}
