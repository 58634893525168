import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { ProjectSetting, ProjectSettingsService } from "../../services/ProjectSettingsService";
import { ProjectService } from "../../services/ProjectService";
import { KostenConsumer } from "@consumers//KostenController";
import { IProjectViewModel } from "@models/ProjectViewModel";
import { IBeschikbareLeningen } from "@models/BeschikbareLeningen";
import { IProjectSettingsAngular } from '@datacontracts/ProjectSettingsModel';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';


@Component({
    selector: 'wct-admin-project-settings',
    styleUrls: ['./WctAdminProjectSettings.scss'],
    templateUrl: './WctAdminProjectSettings.html'
})
export class WctAdminProjectSettingsComponent {
    ProjectSettings: Array<IProjectSettingsAngular> = [];
    BezigMetLaden: boolean = true;
    ProjectSettingsTonen: boolean = true;
    KostenImporteren: boolean = false;
    LeningenLijst: IBeschikbareLeningen;
    Projecten: IProjectViewModel[];
    geselecteerdProject: IProjectViewModel = null;
    public sortedProjectSettings: IProjectSettingsAngular[];

    Categorie: string;
    Naam: string;

    public Editor = ClassicEditor;

    public onReady( editor ) {
        editor.ui.getEditableElement().parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
        );
    }

    constructor(
        private projectsettingsservice: ProjectSettingsService,
        private projectservice: ProjectService,
        private kostenconsumer: KostenConsumer
    ) {
       this.projectservice.GeefProjecten().then(r => {
          this.Projecten = r;

          this.geselecteerdProject = _.find(r, (r) => r.ProjectId == this.projectservice.GeefProjectId());

          this.GetProjectSettings();
       });
    }

    private GetProjectSettings(): void {
       this.projectsettingsservice.GeefProjectSettingsHuidigeProject(this.geselecteerdProject.ProjectId).subscribe(response => {
          this.ProjectSettings = response;
          this.sortedProjectSettings = this.sortProjectSettings();
          this.BezigMetLaden = false;
          this.setAllFalse();
       });
    }

    public LaadProject(): void {
       this.BezigMetLaden = true;
       this.GetProjectSettings();
    }

      public GetProjectSettingsFiltered(): Array<IProjectSettingsAngular> {
          var array: Array<IProjectSettingsAngular> = new Array<IProjectSettingsAngular>();

          _.each(this.ProjectSettings, (proj: IProjectSettingsAngular) => {
          var add = true;

          if (this.Categorie != undefined && this.Categorie != "") {
             if (proj.Categorie.toLocaleLowerCase().indexOf(this.Categorie.toLocaleLowerCase()) == -1)
             {
                add = false;
             }
          }

          if (this.Naam != undefined && this.Naam != "") {
             if (proj.Naam.toLocaleLowerCase().indexOf(this.Naam.toLocaleLowerCase()) == -1) {
                add = false;
             }
          }

          if (add) {
             array.push(proj);
          }
       });

       return array;
    }

    public ImporteerLeningen(): void {
       this.KostenImporteren = true;
       this.ProjectSettingsTonen = false;
       this.kostenconsumer.ImporteerLeningen().then(r => {
          this.LeningenLijst = r;
       });
    }

    public UploadFiles(files, errFiles): void {
       this.KostenImporteren = false;
       this.ProjectSettingsTonen = true;
       this.BezigMetLaden = true;
       this.projectservice.uploadFiles(files).then((response) => {
            this.projectsettingsservice.GeefProjectSettingsHuidigeProject(this.geselecteerdProject.ProjectId).subscribe(response => {
                this.ProjectSettings = response;
                this.BezigMetLaden = false;
            });
        });
    }

    public Opslaan(): void {
       this.KostenImporteren = false;
       this.ProjectSettingsTonen = true;
       this.BezigMetLaden = true;

       this.projectsettingsservice.OpslaanProjectSettingsHuidigeProject(this.geselecteerdProject.ProjectId, this.ProjectSettings).subscribe(() => {
          this.projectsettingsservice.GeefProjectSettingsHuidigeProject(this.geselecteerdProject.ProjectId).subscribe(response => {
             this.ProjectSettings = response;
             this.BezigMetLaden = false;
          });

       });
    }

    public Importeer(): void {
       this.KostenImporteren = false;
       this.ProjectSettingsTonen = true;
       this.BezigMetLaden = true;
       this.projectsettingsservice.ImporteerProjectSettings(this.geselecteerdProject.ProjectId).subscribe(response => {
          alert(response);
          this.projectsettingsservice.GeefProjectSettingsHuidigeProject(this.geselecteerdProject.ProjectId).subscribe(response => {
             this.ProjectSettings = response;
             this.BezigMetLaden = false;
          });
       })
    }

    public Exporteer(): void {
       this.KostenImporteren = false;
       this.ProjectSettingsTonen = true;
       window.open('/Project/ExportProjectSettings', '_blank')
    }

      public Verwijderen(projectsetting: ProjectSetting): void {
       this.BezigMetLaden = true;
       this.projectsettingsservice.VerwijderProjectSettingInstantie(this.geselecteerdProject.ProjectId, projectsetting).subscribe(response => {
          this.projectsettingsservice.GeefProjectSettingsHuidigeProject(this.geselecteerdProject.ProjectId).subscribe(response => {
             this.ProjectSettings = response;
             this.GetProjectSettings();
             this.BezigMetLaden = false;
          });
       });

    }

    public LaadDefault(): void {

       this.BezigMetLaden = true;

       this.projectsettingsservice.LaadDefault().subscribe(response => {
          alert("succes: " + response);
          this.GetProjectSettings();
       });

       //window.open('/Project/ImporteerProjectSettings', '_blank')
    }

    public TerugNaarAdminOud(): void {
       window.location.href = '/Wijk/Overzicht';
    }

   public GetProjectSettingsName(projectSettings: IProjectSettingsAngular): string{
      return projectSettings.Categorie + '-' + projectSettings.Naam;
   }

   private sortProjectSettings(): IProjectSettingsAngular[] {
      return _.sortBy(this.ProjectSettings, (projectSetting => projectSetting.Categorie));
   }

   public checkChecked(projectSetting): boolean {
      if (typeof(projectSetting.Waarde) === 'string') {
         return projectSetting.Waarde.toLowerCase() === 'true';
      } else if(typeof(projectSetting.Waarde) === 'boolean') {
         return projectSetting.Waarde;
      }
      return false;
   }

   onChange(event) {
      event.source.name.Aangepast = !event.source.name.Aangepast;
      event.source.name.Waarde = event.checked;
   }

   setAllFalse() {
      this.ProjectSettings.forEach(setting => {
         setting.Aangepast = false;
      });
   }

   public SortProjectSettings() {
    if (this.Categorie != undefined && this.Categorie != "") {
      this.sortedProjectSettings = this.ProjectSettings.filter(projectsettings => projectsettings.Categorie.toLowerCase().includes(this.Categorie.toLowerCase()));
    }
    if (this.Naam != undefined && this.Naam != "") {
      this.sortedProjectSettings = this.ProjectSettings.filter(projectsettings => projectsettings.Naam.toLowerCase().includes(this.Naam.toLowerCase()));
    }
   }

   public onChangeTextEditor( { editor }: ChangeEvent, projectsettingedited: IProjectSettingsAngular ) {
    const data = editor.getData();

    this.ProjectSettings.forEach(function (projectsetting, index) {
      if (projectsetting.Naam == projectsettingedited.Naam) {
        projectsetting.Waarde = data;
      }
    });
    console.log(data);

    //projectsetting.Waarde = data;
  }
 }
