import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FabrikantenAdminKoppelFilterOptionRequestModel, FabrikantenAdminKoppelFilterOptionResponseModel } from '@models/FabrikantenAdminModels';
import { FabrikantenAdminConsumer } from "@consumers/FabrikantenAdminController";
import { FiltersConsumer } from "@consumers/FiltersController";

import {
  FiltersViewModel,
  FilterOptionViewModel
} from "@models/FilterViewModel";

@Component({
  selector: 'fab-admin-koppel-filteroption',
  templateUrl: './FabAdminKoppelFilterOption.html'
})

export class FabAdminKoppelFilterOption {

  public Loading: boolean = false;

  public Request: FabrikantenAdminKoppelFilterOptionRequestModel;
  public Response: FabrikantenAdminKoppelFilterOptionResponseModel;

  public FiltersViewModel: FiltersViewModel = new FiltersViewModel();
  public FilterOptions: FilterOptionViewModel[] = new Array<FilterOptionViewModel>();
  public SelectedFilterId: number;

  constructor(private route: ActivatedRoute, private fabadmin: FabrikantenAdminConsumer, private filters: FiltersConsumer) {
    this.Request = new FabrikantenAdminKoppelFilterOptionRequestModel();

    this.LoadFilters();
    this.LoadProductProperties();

  }

  public FilterChanged(): void {
    for (var i = 0; i < this.FiltersViewModel.Filters.length; i++) {
      if (this.FiltersViewModel.Filters[i].Id == this.SelectedFilterId) {
        this.FilterOptions = this.FiltersViewModel.Filters[i].Options;
      }
    }
  }

  public LoadFilters(): void {
    this.Loading = true;
    this.filters.GetFilters(this.FiltersViewModel).then((data) => {
      this.FiltersViewModel = new FiltersViewModel(data);
      this.Loading = false;
    });
  }

  public LoadProductProperties(): void {
    this.Loading = true;
    this.filters.GetFilters(this.FiltersViewModel).then((data) => {
      this.FiltersViewModel = new FiltersViewModel(data);
      this.Loading = false;
    });
  }

  public Koppel(): void {
    this.Loading = true;
    this.Request.ExecutePP = false;
    this.Request.ExecuteF = false;
    this.fabadmin.KoppelFilterOption(this.Request).then((data) => {
      this.Response = new FabrikantenAdminKoppelFilterOptionResponseModel(data);
      this.Loading = false;
    });
  }

  public KoppelEchtPP(): void {
    this.Loading = true;
    this.Request.ExecutePP = true;
    this.Request.ExecuteF = false;
    this.fabadmin.KoppelFilterOption(this.Request).then((data) => {
      this.Response = new FabrikantenAdminKoppelFilterOptionResponseModel(data);
      this.Loading = false;
    });
  }

  public KoppelEchtF(): void {
    this.Loading = true;
    this.Request.ExecutePP = false;
    this.Request.ExecuteF = true;
    this.fabadmin.KoppelFilterOption(this.Request).then((data) => {
      this.Response = new FabrikantenAdminKoppelFilterOptionResponseModel(data);
      this.Loading = false;
    });
  }
}
