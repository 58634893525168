import * as ViewModelsKostenPrijsregel from '.././Models/KostenPrijsregel';




	export class KostenDetails {
		Naam: string;
		Code: string;
		PrijsRegels: Array<ViewModelsKostenPrijsregel.KostenPrijsregel> = [];
		TotaalBedrag: number;
		TotaalBedragExBTW: number;
		TotaalBedragExBTWStr: string;
		dTotaalBedragExBTW: number;
		TotaalBedragIncBTW: number;
		TotaalBedragIncBTWStr: string;
		dTotaalBedragIncBTW: number;
		Tonen: boolean;
		m_IsUitGeklapt: boolean = false;
		Uitklapbaar: boolean = false;

		get IsUitGeklapt(): boolean {
			return this.m_IsUitGeklapt;
		}
		set IsUitGeklapt(b: boolean) {
			this.m_IsUitGeklapt = b;
		}
	}
