// Angular components and modules
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreUiModule } from '../../../coreui/coreui.module';
import { EffectsModule } from '@ngrx/effects';

// Site components
import { FabBestekMainComponent } from '../../generic/bestekservice/fab-bestekservice.component';
import { FabBestekNavComponent } from '../../generic/bestekservice/components/FabBestekNav';
import { FabBestekComponent } from '../../generic/bestekservice/components/FabBestek';
import { FabBestekDetailComponent } from '../../generic/bestekservice/components/FabBestekDetail';
import { FabFiltersComponent } from '../../generic/bestekservice/components/FabFilters';
import { FabFiltersDialogComponent } from '../../generic/bestekservice/components/FabFiltersDialog';
import { FabFilterComponent } from '../../generic/bestekservice/components/FabFilter';
import { FabProductComponent } from '../../generic/bestekservice/components/FabProduct';

// Themes and Material
//import { ThemeModule, DefaultTheme } from '../../shared/theme';
import { MaterialModule } from '../../shared/material';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// Other modules
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FabAdminModule } from '../../admin/fab-admin.module';
import { ViewerModule } from '../../../viewers/viewer.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';

// Consumers
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { FabrikantenAdminConsumer } from '@consumers/FabrikantenAdminController';

// Services
import { GoogleAnalyticsService } from '../../../services/GoogleAnalyticsService';

// Localization
import { registerLocaleData, DatePipe } from '@angular/common'
import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeNl, 'nl');

// Store
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { GetInfraStatus } from '../../../modules/webpack/sitestatus';


//import { MatPaginatorModule } from '@angular/material/paginator';
//import { MatSortModule } from '@angular/material/sort';


// Some logic
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function debugStore(reducer: ActionReducer<any>): ActionReducer<any> {
	return function (state, action) {
		console.log('ngrx store: pre', state);
		console.log('ngrx store action', action.type, action);
		let post = reducer(state, action);
		console.log('ngrx store: post', state);
		return post;
	};
}

export const metaReducers: MetaReducer<any>[] = [debugStore];

let production = GetInfraStatus().ClientsideProduction;

// Routes
const routes: Routes = [
  { path: 'fab-bestek', component: FabBestekComponent },
  { path: 'fab-bestek-nav', component: FabBestekNavComponent },
  {
    path: 'fab-filters', component: FabFiltersComponent, children: [
      { path: '**', component: FabFiltersComponent }
    ]
  },
  { path: 'fab-bestek-detail', component: FabBestekDetailComponent },
  { path: 'fab-bestek-detail/:productid', component: FabBestekDetailComponent },
  { path: '', redirectTo: '/fab-filters', pathMatch: 'full' },
];


// Module definition
@NgModule({
  imports: [
    ViewerModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CoreUiModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    StoreModule.forRoot({}, !production ? { metaReducers } : {}),
    EffectsModule.forRoot(),
    PerfectScrollbarModule,
    //ThemeModule.forRoot({
    //  themes: [DefaultTheme],
    //  active: 'default'
    //}),
    SlickCarouselModule,
    HttpClientModule,
    FabAdminModule,
  ],
  exports: [
    RouterModule,
    FabBestekMainComponent,
    FabBestekNavComponent,
    FabFiltersComponent,
    FabFiltersDialogComponent,
    FabBestekDetailComponent,
    FabBestekComponent,
    FabFilterComponent,
    FabProductComponent,
    //MatPaginatorModule,
    //MatSortModule
  ],
  declarations: [
    FabBestekMainComponent,
    FabBestekNavComponent,
    FabFiltersComponent,
    FabFiltersDialogComponent,
    FabBestekDetailComponent,
    FabBestekComponent,
    FabFilterComponent,
    FabProductComponent,
  ],
  entryComponents: [
    FabBestekMainComponent,
    FabFiltersComponent,
    FabFiltersDialogComponent
  ],
  bootstrap: [
    FabBestekMainComponent
  ],
  providers: [
    FabrikantenConsumer,
    FabrikantenAdminConsumer,
    
    DatePipe,
    GoogleAnalyticsService,
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: MAT_DATE_LOCALE, useValue: 'nl' },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ]
})

export class FabBestekserviceModule {
}
