<div class="content">
  <h1>Prijsschema's</h1>

  <div class="main-div">
    <div *ngIf="prijsmoduleservice.LoadingPrijsSchemas" class="mat-progress-spinner__cont">
      <div style="padding-top: 300px; padding-bottom: 300px; text-align: center;">
        <div>
          <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
        </div>
        <div>
          <p>De prijsschema's worden geladen</p>
        </div>
      </div>
    </div>

    <div *ngIf="!prijsmoduleservice.LoadingPrijsSchemas" class="block-container">
      <table style="width: 100%;">
        <tr>
          <td class="one">
            <div class="white-block">
              <a href="#" onclick="return false;" (click)="Nieuw()">nieuw</a>  
              <br /><br />
              <table class="prijsschema-table">
                <thead>
                  <tr>
                    <td style="width: 20px;">#</td>
                    <td>Naam</td>
                    <td></td>
                  </tr>
                </thead>
                <tr *ngFor="let prijsschema of prijsmoduleservice.PrijsSchemasViewModel.PrijsSchemas">
                  <td>{{ prijsschema.Id }}</td>
                  <td><a href="#" onclick="return false;" (click)="Open(prijsschema)">{{ prijsschema.Name }}</a></td>
                  <td>
                    <div *ngIf="prijsschema.UserId != ''"><a href="#" onclick="return false;" (click)="Delete(prijsschema)">verwijderen</a></div>
                  </td>
                </tr>
              </table>
            </div>
          </td>
          <td class="two">
            <div class="white-block two">
              <div *ngIf="ActivePrijsSchemaViewModel == null">
                Selecteer eerst een prijsschema
              </div>
              <div *ngIf="ActivePrijsSchemaViewModel != null">
                <table style="width: 400px;">
                  <tr>
                    <td>
                      Naam
                    </td>
                    <td colspan="5">
                      <input type="text" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.Name" />
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Aluminium</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td style="width: 113px;"><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.Aluminium" /></td>
                    <td>kg</td>
                  </tr>
                  <tr>
                    <td>Poedercoaten (1 kleur)</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.Poedercoaten" /></td>
                    <td>m<sup>2</sup></td>
                  </tr>
                  <tr>
                    <td>Poedercoaten binnen</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.PoedercoatenBinnen" /></td>
                    <td>m<sup>2</sup></td>
                  </tr>
                  <tr>
                    <td>Poedercoaten buiten</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.PoedercoatenBuiten" /></td>
                    <td>m<sup>2</sup></td>
                  </tr>
                  <tr>
                    <td>Anodiseren (1 kleur)</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.Anodiseren" /></td>
                    <td>m<sup>2</sup></td>
                  </tr>
                  <tr>
                    <td>Anodiseren binnen</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.AnodiserenBinnen" /></td>
                    <td>m<sup>2</sup></td>
                  </tr>
                  <tr>
                    <td>Anodiseren buiten</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.AnodiserenBuiten" /></td>
                    <td>m<sup>2</sup></td>
                  </tr>
                  <tr>
                    <td>ISO RT62/AA4110</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.ISORT62" /></td>
                    <td>m<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td>ISO RT72 Reflex</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.ISORT72Reflex" /></td>
                    <td>m<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td>ISO RT72 HI+</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.ISORT72HI" /></td>
                    <td>m<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td>ISO RT82 HI+</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.ISORT82HI" /></td>
                    <td>m<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td>ISO AA5110</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.ISOAA5110" /></td>
                    <td>m<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td>ISO AA5110 2x</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.ISOAA51102X" /></td>
                    <td>m<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td>ISO AA3572</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.ISOAA3572" /></td>
                    <td>m<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td>ISO AA3572 2X</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.ISOAA35722X" /></td>
                    <td>m<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td>ISO AA3572 3X</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.ISOAA35723X" /></td>
                    <td>m<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td>Boren</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.Boren" /></td>
                    <td>m<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td>Bi-color toeslag</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.BiColorToeslag" /></td>
                    <td>m<sup>1</sup></td>
                  </tr>
                  <tr>
                    <td>Tapen</td>
                    <td style="width: 20px; text-align: right;">&euro;</td>
                    <td><input type="number" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.Tapen" /></td>
                    <td>lgt</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>AA3110</td>
                    <td style="width: 20px; text-align: right;">Korting</td>
                    <td><input type="number" min="0" max="100" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.AA3110" /></td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Accessoires</td>
                    <td style="width: 20px; text-align: right;">Korting</td>
                    <td><input type="number" min="0" max="100" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.Accessoires" /></td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td>Raambeslag</td>
                    <td style="width: 20px; text-align: right;">Korting</td>
                    <td><input type="number" min="0" max="100" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.Raambeslag" /></td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td>Deurbeslag</td>
                    <td style="width: 20px; text-align: right;">Korting</td>
                    <td><input type="number" min="0" max="100" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.Deurbeslag" /></td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td>Schuifbeslag</td>
                    <td style="width: 20px; text-align: right;">Korting</td>
                    <td><input type="number" min="0" max="100" step="0.01" class="decimal-input" [readonly]="ActivePrijsSchemaViewModel.UserId == ''" [(ngModel)]="ActivePrijsSchemaViewModel.Schuifbeslag" /></td>
                    <td>%</td>
                  </tr>
                </table>
                <br /><br />
                <div *ngIf="ActivePrijsSchemaViewModel.UserId != ''">
                  <a href="#" onclick="return false;" (click)="Opslaan()">opslaan</a>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    </div>
  </div>
