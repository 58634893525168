<div>
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let item of ItemArray; let i = index" [attr.data-index]="i" cdkDrag>
            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
            <div class="fab-drag-text" onmouseover="" style="cursor: pointer;" (click)="select(i)">
                {{item[this.PropertyToShow]}}
            </div>
            <button *ngIf="Deletable" mat-icon-button color="warn" aria-label="Delete Filter Option" (click)="delete(i)">
                <i class="fal fa-trash-alt"></i>
            </button>
            <div class="example-handle" cdkDragHandle>
                <i class="fal fa-arrows"></i>
            </div>
        </div>
    </div>
</div>