// [conv] import "(./FabBestekNav).less";
import { Component } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'fab-bestek-nav',
  templateUrl: './FabBestekNav.html'
})
export class FabBestekNavComponent {
    Header: string;
    constructor() {
        this.Header = "Welkom fabrikant!";
        console.log("Geef me een header");

    }


}
