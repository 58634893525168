<div class="container">
    <div>
        <div class="search-field">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Zoeken</mat-label>
                <input matInput (keyup)="applyFilter($event)" #input />
            </mat-form-field>
        </div>
        

        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8">
            <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
                <td mat-cell *matCellDef="let data">
                    <div *ngIf="data.Verificatie">
                        <button mat-icon-button color="success-icon" aria-label="Example icon button with a heart icon">
                            <i class="fas fa-shield-alt"></i>
                        </button>
                        {{data.Id}}
                    </div>
                    <div *ngIf="!data.Verificatie">
                        <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon">
                            <i class="fas fa-shield-alt"></i>
                        </button>
                        {{data.Id}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="UserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Inlognaam</th>

                <td mat-cell *matCellDef="let data">
                    <a [routerLink]="['user-detail', data.Id]"> {{data.UserName}}</a>
                </td>
            </ng-container>
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Persoonsnaam</th>
                <td mat-cell *matCellDef="let data">
                    {{data.Name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="VerificatieCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>VerificatieCode</th>
                <td mat-cell *matCellDef="let data">
                    {{data.VerificatieCode}}
                </td>
            </ng-container>
            <ng-container matColumnDef="LastSeen">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Laatst Gezien</th>
                <td mat-cell *matCellDef="let data">
                    {{data.LastSeen | date:"medium"}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [routerLink]="['/wct-admin/user-detail/', row.Id]"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>

        </table>
        <mat-paginator [length]="UsersCountViewModel.TotalUsers" [pageSizeOptions]="pageOptions" (page)="pageChange($event)" showFirstLastButtons></mat-paginator>

    </div>
</div>