
<h2>Opmerkingen</h2>
<wct-survey-question-group groupname="">
  <div *ngFor="let note of Notes; index as i">
    <div class="note-title">
      <h3>Opmerking {{ i+1 }}</h3>
      <img src="/Content/Images/configurator/Icon-Verwijderen.svg" alt="upload file" class="delete-icon">
    </div>
    <p class="note-data">{{note.ServerNote.Author}} | 01-01-2020 12:00u (TODO)</p>
    <p class="note-message">{{note.ServerNote.Message}}</p>
    <!-- <div>{{note.ServerNote.ContainsFile}}</div> -->
    <div class="files-container">
      <input #file type="file" class="file-input" (change)="upload(file.files)">

      <div class="upload-btn"
        (click)="file.click()">
        <img src="/Content/Images/configurator/Icon-Upload.svg" alt="upload file" class="upload-icon">
      </div>
      <div class="img-container" *ngIf="note.ImageLoaded">
        <img class="note-image" [src]="sanitize('data:image/jpg;base64, ' + _arrayBufferToBase64(note.Contents))">
      </div>
    </div>

  </div>

  <div class="btn-container">
    <wct-stui-button class="secondary" (click)="AddNote()">Opmerking toevoegen</wct-stui-button>
  </div>
</wct-survey-question-group>
