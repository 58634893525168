/// <reference path='../consumerinterfaces/ProjectApiController.ts' />
/**
 * @ref WoonConnectApiController/Controllers/Projecten/ProjectApiController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/ProjectApiController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as ProjectenModel from "@datacontracts/ProjectenModel";
import * as ProjectSettingsModel from "@datacontracts/ProjectSettingsModel";
import * as SaveProjectSettingsModel from "@datacontracts/SaveProjectSettingsModel";
import * as ImportProjectSettingsModel from "@datacontracts/ImportProjectSettingsModel";
import * as DeleteProjectSettingInstantieModel from "@datacontracts/DeleteProjectSettingInstantieModel";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class ProjectAPIConsumer implements Interface.IProjectAPIConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public GeefProjecten_Observable(): Observable<ProjectenModel.IProjectenModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/ProjectAPI/GeefProjecten`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProjectenModel.IProjectenModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefProjecten(): ng.IPromise<ProjectenModel.IProjectenModel> {
				let obs = this.GeefProjecten_Observable();
				let promise = new Promise<ProjectenModel.IProjectenModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefProjectSettings_Observable(inputModel: ProjectSettingsModel.IProjectSettingsRequestModel & {ToJsonContract(noContent?: boolean): ProjectSettingsModel.IProjectSettingsRequestModel}): Observable<ProjectSettingsModel.IProjectSettingsResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ProjectAPI/GeefProjectSettings`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProjectSettingsModel.IProjectSettingsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefProjectSettings(inputModel: ProjectSettingsModel.IProjectSettingsRequestModel & {ToJsonContract(noContent?: boolean): ProjectSettingsModel.IProjectSettingsRequestModel}): ng.IPromise<ProjectSettingsModel.IProjectSettingsResponseModel> {
				let obs = this.GeefProjectSettings_Observable(inputModel);
				let promise = new Promise<ProjectSettingsModel.IProjectSettingsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public OpslaanProjectSettings_Observable(inputModel: SaveProjectSettingsModel.ISaveProjectSettingsRequestModel & {ToJsonContract(noContent?: boolean): SaveProjectSettingsModel.ISaveProjectSettingsRequestModel}): Observable<SaveProjectSettingsModel.ISaveProjectSettingsResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ProjectAPI/OpslaanProjectSettings`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<SaveProjectSettingsModel.ISaveProjectSettingsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public OpslaanProjectSettings(inputModel: SaveProjectSettingsModel.ISaveProjectSettingsRequestModel & {ToJsonContract(noContent?: boolean): SaveProjectSettingsModel.ISaveProjectSettingsRequestModel}): ng.IPromise<SaveProjectSettingsModel.ISaveProjectSettingsResponseModel> {
				let obs = this.OpslaanProjectSettings_Observable(inputModel);
				let promise = new Promise<SaveProjectSettingsModel.ISaveProjectSettingsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ImportProjectSettings_Observable(inputModel: ImportProjectSettingsModel.IImportProjectSettingsRequestModel & {ToJsonContract(noContent?: boolean): ImportProjectSettingsModel.IImportProjectSettingsRequestModel}): Observable<ImportProjectSettingsModel.IImportProjectSettingsResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = { input: input };
				let apiURL = `api/ProjectAPI/ImportProjectSettings`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ImportProjectSettingsModel.IImportProjectSettingsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ImportProjectSettings(inputModel: ImportProjectSettingsModel.IImportProjectSettingsRequestModel & {ToJsonContract(noContent?: boolean): ImportProjectSettingsModel.IImportProjectSettingsRequestModel}): ng.IPromise<ImportProjectSettingsModel.IImportProjectSettingsResponseModel> {
				let obs = this.ImportProjectSettings_Observable(inputModel);
				let promise = new Promise<ImportProjectSettingsModel.IImportProjectSettingsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public VerwijderProjectSettingInstantie_Observable(inputModel: DeleteProjectSettingInstantieModel.IDeleteProjectSettingInstantieRequestModel & {ToJsonContract(noContent?: boolean): DeleteProjectSettingInstantieModel.IDeleteProjectSettingInstantieRequestModel}): Observable<DeleteProjectSettingInstantieModel.IDeleteProjectSettingInstantieResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ProjectAPI/VerwijderProjectSettingInstantie`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<DeleteProjectSettingInstantieModel.IDeleteProjectSettingInstantieResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public VerwijderProjectSettingInstantie(inputModel: DeleteProjectSettingInstantieModel.IDeleteProjectSettingInstantieRequestModel & {ToJsonContract(noContent?: boolean): DeleteProjectSettingInstantieModel.IDeleteProjectSettingInstantieRequestModel}): ng.IPromise<DeleteProjectSettingInstantieModel.IDeleteProjectSettingInstantieResponseModel> {
				let obs = this.VerwijderProjectSettingInstantie_Observable(inputModel);
				let promise = new Promise<DeleteProjectSettingInstantieModel.IDeleteProjectSettingInstantieResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public LoadDefaultProjectSettings_Observable(): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/ProjectAPI/LoadDefaultProjectSettings`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public LoadDefaultProjectSettings(): ng.IPromise<string> {
				let obs = this.LoadDefaultProjectSettings_Observable();
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


