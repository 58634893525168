import {  Component, Input, Output, EventEmitter, OnChanges, OnInit, OnDestroy, SimpleChanges, Inject, ElementRef, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { fromEvent, EMPTY } from 'rxjs';
import { first, filter, debounceTime, distinctUntilChanged, tap, switchMap,  catchError, map, take } from 'rxjs/operators';
import { WoningService } from '../../services/WoningService';
import { WoningEventService } from '../../services/WoningEventService';
import { ProjectService } from '../../services/ProjectService';
import { IAngularWoning } from '@models/AngularWoningModel';
import { MassConfiguratorConsumer } from '@consumers/MassConfiguratorController';
import { SearchAddressRequestModel } from '@models/SearchAddressModel';
import { ISearchAddressResponseModel, ISearchDwelling } from '@datacontracts/SearchAddressModel';
import { AddDwellingRequestModel } from '@models/AddDwellingModel';
import { AddMultipleDwellingsRequestModel, Dwelling } from '@models/AddMultipleDwellingsModel';

@Component({
    selector: 'wct-woningen',
    templateUrl: './WctWoningen.html',
    styleUrls: ['./WctWoningen.scss']
})
export class WctWoningenComponent implements AfterViewInit, OnDestroy {
    @ViewChild('input') input: ElementRef;
    subscription: Subscription;
    dwellings: IAngularWoning[];
    showDwellings: boolean = false;
    showSearch: boolean = true;
    addresses = [];
    adding: boolean = false;
    warnings: string = "";
	error: string = "";
    bgt: boolean = true;

    @Output() import = new EventEmitter<number[]>();

    constructor(private woningService: WoningService, private projectService: ProjectService, private massConfiguratorConsumer: MassConfiguratorConsumer, private woningEventService: WoningEventService ) {
        this.woningService.getDwellingsByProjectId(this.projectService.GeefProjectId()).subscribe(dwellings => {
            this.dwellings = dwellings;
        })
    }

    ngAfterViewInit() {
		// server-side search
		this.subscription = fromEvent(this.input.nativeElement, 'keyup')
			.pipe(
				filter((x: any) => x.target != null && x.target.value != null),
				map((x: any) => x.target.value),
				debounceTime(300),
				distinctUntilChanged(),
				switchMap((x) => {
					let req = new SearchAddressRequestModel();
					req.Zipcode = x;
					return this.massConfiguratorConsumer.SearchAddress_Observable(req);
				}),
			)
			.subscribe((x: ISearchAddressResponseModel) => {
				if (!x.Succeeded)
				{
					alert(x.Message);
				}
				else
				{
                    this.addresses =[];
                    x.Dwellings.forEach(dwelling => {
                        this.addresses.push({dwelling: dwelling, isSelected: false, isDisabled: dwelling.InSystem})
                    })
				}
			});
	}

    ngOnDestroy() {
		if (this.subscription != null) {
			this.subscription.unsubscribe();
		}
	}

    selectAddress(address: ISearchDwelling) {
        let req = new AddDwellingRequestModel();
		req.Number = address.Number;
		req.Zipcode = address.Zipcode;
        req.UseBGT = this.bgt;
        req.Streetname = address.Streetname;
        req.Domicile = address.Domicile;

		this.adding = true;

		this.massConfiguratorConsumer.AddDwelling_Observable(req).pipe(first()).subscribe(r => {
			this.adding = false;
			this.error = r.Error;
			this.warnings = r.Warnings;

			if (r.Error != null)
			{
				alert(r.Error);
			}
		});
    }

    submitAddresses(){
        let req = new AddMultipleDwellingsRequestModel();
		
        let addressesToAdd = this.addresses.filter(dwelling => dwelling.isSelected);
        addressesToAdd.forEach(address => {
            let dwellingReq: Dwelling = new Dwelling();
            dwellingReq.Number = address.dwelling.Number;
            dwellingReq.Zipcode = address.dwelling.Zipcode;
            dwellingReq.UseBGT = this.bgt;
            dwellingReq.Streetname = address.dwelling.Streetname;
            dwellingReq.Domicile = address.dwelling.Domicile;
            req.Dwellings.push(dwellingReq);
        })
        this.massConfiguratorConsumer.AddMultipleDwellings_Observable(req).subscribe(r => {
            this.adding = false;
			this.error = r.Error;
			this.warnings = r.Warnings;

            this.import.next(r.DwellingIds);

            alert('Woningen toegevoegd: ' + r.DwellingIds.length)

			if (r.Error != null)
			{
				alert(r.Error);
			}
        });
    }

    checkAllBoxes() {
        let notDisabled = this.addresses.filter(checkbox => !checkbox.isDisabled);
        if (notDisabled.every(checkbox => checkbox.isSelected == true))
          this.addresses.forEach(checkbox => { checkbox.isSelected = false });
        else
            this.addresses.forEach(checkbox => { 
                if(!checkbox.isDisabled) {
                    checkbox.isSelected = true; 
                }
            });
      }
}