<div class="dialog__header">
  <p class="dialog__title">Nieuwe gebruiker toevoegen</p>
  <i (click)="Close()" class="fal fa-times"></i>
</div>
<div class="dialog__main">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Voornaam</mat-label>
      <input matInput name="voornaam" [(ngModel)]="userDetail.Persoon.FirstName" ngModel required min-length="4" #name="ngModel">
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Achternaam</mat-label>
        <input matInput name="Achternaam" [(ngModel)]="userDetail.Persoon.LastName" ngModel required min-length="4" #name="ngModel">
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Email</mat-label>
        <input matInput name="Email" [(ngModel)]="userDetail.Persoon.Email" ngModel required min-length="4" #name="ngModel">
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Telefoon</mat-label>
        <input matInput name="Telefoon" [(ngModel)]="userDetail.PersoonExtra.Telefoon" ngModel required min-length="4" #name="ngModel">
    </mat-form-field>
    <!-- <mat-form-field appearance="outline" floatLabel="alwayss">
      <mat-label>Land</mat-label>
      <input matInput name="Land" [(ngModel)]="userDetail.Adres.Land" ngModel required min-length="4" #name="ngModel">
    </mat-form-field> -->
</div>
<div class="dialog__btns">
  <button (click)="Close()" class="dialog__btn__secundary">Annuleren</button>
  <button (click)="SaveUser()" class="dialog__btn__primary">Ok</button>
</div>


