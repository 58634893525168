<div class="content">
  <div class="background">
    <a href="https://www.hoppe.com/nl-nl/" target="_blank" class="logo">
      <img src="/Content/KlantSpecifiek/BCB20343/HOPPE-WBM-NL.svg" height="60">
    </a>
    <div>
      <ul class="menu">
        <li><a routerLink="" [class.active]="router.url === '/'">Home</a></li>
        <!--<ng-container *ngIf="!Loading">
          <li *ngFor="let filteroption of GetCategories().Options"><a routerLink="/categorie/{{ filteroption.Id }}" [class.active]="IsUrlActive('/categorie/' + filteroption.Id)">{{ filteroption.DescriptionShort }}</a></li>
        </ng-container>-->
      </ul>
    </div>

    <!--<div class="callToActionMain">
      <div class="callToAction" (click)="ShowCallToAction=!ShowCallToAction">
        Vraag? Meer informatie nodig?
      </div>
      <div class="callToActionFold" *ngIf="ShowCallToAction">
        <table style="width: 100%;">
          <tr>
            <td> </td>
          </tr>
          <tr>
            <td> </td>
          </tr>
        </table>
      </div>
    </div>-->
  </div>
</div>
