/**
 * @ref WoonConnectViewModel/Survey/GetNotesModel.cs
 */

 import * as DataContracts from "@datacontracts/GetNotesModel";

 
import * as WoningSelectie from "./WoningSelectie";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.GetNotesRequestModel
	 */
	 

	export interface IGetNotesRequestModel {
		Dwelling: WoningSelectie.IWoningSelectie;
		ObjectId: string;
		NoteId: string;
		GetContents: boolean;
		ToJsonContract(): DataContracts.IGetNotesRequestModel;
	}

	export class GetNotesRequestModel {
		// property-Properties
		public get Dwelling(): WoningSelectie.IWoningSelectie {
			return this._Dwelling;
		}
		public set Dwelling(newDwelling: WoningSelectie.IWoningSelectie) {
			this._Dwelling = newDwelling;
		}

		public get ObjectId(): string {
			return this._ObjectId;
		}
		public set ObjectId(newObjectId: string) {
			this._ObjectId = newObjectId;
		}

		public get NoteId(): string {
			return this._NoteId;
		}
		public set NoteId(newNoteId: string) {
			this._NoteId = newNoteId;
		}

		public get GetContents(): boolean {
			return this._GetContents;
		}
		public set GetContents(newGetContents: boolean) {
			this._GetContents = newGetContents;
		}

		// field-Properties

		// fields
		protected _Dwelling: WoningSelectie.IWoningSelectie = new WoningSelectie.WoningSelectie(undefined);
		protected _ObjectId: string = "";
		protected _NoteId: string = "";
		protected _GetContents: boolean = false;

		constructor(data?: DataContracts.IGetNotesRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetNotesRequestModel): void {
			if(data) {
				// vul properties
				this._Dwelling = data.Dwelling ? new WoningSelectie.WoningSelectie(data.Dwelling): undefined;
				this._ObjectId = data.ObjectId;
				this._NoteId = data.NoteId;
				this._GetContents = data.GetContents;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IGetNotesRequestModel {
			return {
				Dwelling: (this.Dwelling ? new WoningSelectie.WoningSelectie(this.Dwelling).ToJsonContract(): this.Dwelling),
				ObjectId: this.ObjectId,
				NoteId: this.NoteId,
				GetContents: this.GetContents
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Survey.NoteModel
	 */
	 

	export interface INoteModel {
		NoteId: string;
		Author: string;
		Message: string;
		ContainsFile: boolean;
		ToJsonContract(): DataContracts.INoteModel;
	}

	export class NoteModel {
		// property-Properties
		public get NoteId(): string {
			return this._NoteId;
		}
		public set NoteId(newNoteId: string) {
			this._NoteId = newNoteId;
		}

		public get Author(): string {
			return this._Author;
		}
		public set Author(newAuthor: string) {
			this._Author = newAuthor;
		}

		public get Message(): string {
			return this._Message;
		}
		public set Message(newMessage: string) {
			this._Message = newMessage;
		}

		public get ContainsFile(): boolean {
			return this._ContainsFile;
		}
		public set ContainsFile(newContainsFile: boolean) {
			this._ContainsFile = newContainsFile;
		}

		// field-Properties

		// fields
		protected _NoteId: string = "";
		protected _Author: string = "";
		protected _Message: string = "";
		protected _ContainsFile: boolean = false;

		constructor(data?: DataContracts.INoteModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.INoteModel): void {
			if(data) {
				// vul properties
				this._NoteId = data.NoteId;
				this._Author = data.Author;
				this._Message = data.Message;
				this._ContainsFile = data.ContainsFile;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.INoteModel {
			return {
				NoteId: this.NoteId,
				Author: this.Author,
				Message: this.Message,
				ContainsFile: this.ContainsFile
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Survey.GetNotesResponseModel
	 */
	 

	export interface IGetNotesResponseModel {
		Notes: NoteModel[];
	}

	export class GetNotesResponseModel {
		// property-Properties
		public get Notes(): NoteModel[] {
			return this._Notes;
		}
		public set Notes(newNotes: NoteModel[]) {
			this._Notes = newNotes;
		}

		// field-Properties

		// fields
		protected _Notes: NoteModel[] = [];

		constructor(data?: DataContracts.IGetNotesResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetNotesResponseModel): void {
			if(data) {
				// vul properties
				this._Notes = data.Notes ? data.Notes.map(noteModelItem => new NoteModel(noteModelItem)): [];
				// vul fields
			}
		}
	}
