<div class="container">
  <div class="header">
    <ng-content select=".header"></ng-content>
  </div>
  <div class="panel-left">
    <ng-content select=".left"></ng-content>
  </div>
  <div class="panel-right">
    <ng-content select=".right"></ng-content>
  </div>
</div>
