// Angular
import { NgModule } from '@angular/core';
import { CdkTreeModule } from '@angular/cdk/tree';

// CDK
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';

// Material
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule} from '@angular/material/core';
import { MatInputModule}  from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatDialogModule} from '@angular/material/dialog';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatSelectModule} from '@angular/material/select';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatRadioModule} from '@angular/material/radio';
import { MatTableModule} from '@angular/material/table';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatTreeModule} from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule} from '@angular/material/tabs';
import { MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatButtonModule} from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';


@NgModule({
    exports: [
        CdkTreeModule,
        DragDropModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatTableModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatTreeModule,
        MatIconModule,
        MatCardModule,
        MatTabsModule,
        MatBottomSheetModule,
        MatGridListModule,
        MatListModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatButtonModule,
        MatProgressBarModule,
        MatMenuModule,
        MatSortModule,
        MatPaginatorModule,
        MatExpansionModule,
    ]
})
export class MaterialModule {}