import { createReducer, on, ActionReducerMap } from '@ngrx/store';
import * as Actions from './dwelling.actions';
import { DwellingState } from './dwelling.state';
import {  initialDwellingState } from './dwelling.state';
import * as _ from 'lodash';
import { WijzigScopeEnum }  from '@enums/WijzigScopeEnum';
import { WoningSelectie } from '../../Models/WoningSelectie';
import { IBreadcrumbSelectie } from '../woningservice';
import * as ViewModelsWoningGroepType from '../../Models/WoningGroepType';
import * as ViewModelsWoningGroep from '../../Models/WoningGroep';
import * as ViewModelsWoningSelectie from '../../Models/WoningSelectie';
import * as ViewModelsWoning from '../../Models/Woning';


function copystate(state: DwellingState): DwellingState
{
    return ({...state});
}

function GeefBreadCrumbVoorWoning(state: DwellingState, woningid: number, scope: WijzigScopeEnum): WoningSelectie {

    if (!state.WoningenGeladen)
    {
        return null;
    }

    const groepen: IBreadcrumbSelectie[] = [];
    const selectie: WoningSelectie = new WoningSelectie();
    selectie.WoningGroepBreadcrumb = groepen;
    selectie.Actievegroep = scope;

    const blokgroep = _.find(state.WoningGroepen, (wg) => {
        var woning = _.find(wg.Woningen, (w) => w.WoningID == woningid);
        return (woning != null);
    });

    if (blokgroep != undefined) {
        const huidigeWoning = _.find(blokgroep.Woningen, (w) => w.WoningID == woningid);
        groepen.unshift(huidigeWoning);
        groepen.unshift(blokgroep);

        let g = blokgroep;
        while (g != null) {
            g = g.SuperGroep;

            if (g != null) {
                groepen.unshift(g);
            }
        }
    }

    return selectie;
}

// function zetGlobaleScopeSelectie(state: DwellingState): void {
//     let nieuweGlobaleScopeSelectie = new ViewModelsWoningSelectie.GlobaleScopeSelectie(state.HuidigeWijk,
//         this.m_Data.m_HuidigBlok,
//         this.m_Data.m_HuidigeWoning,
//         this.HuidigScenarioNummer);
//     if (this.m_Data.HuidigeGlobaleScopeSelectie) {
//         if (this.m_Data.HuidigeGlobaleScopeSelectie.EqualTo(nieuweGlobaleScopeSelectie)) {
//             nieuweGlobaleScopeSelectie.IsVeranderd = false;
//         }
//     }
//     this.m_Data.HuidigeGlobaleScopeSelectie = nieuweGlobaleScopeSelectie;
// }

// function x(state: DwellingState)
// {
//     if (state.DwellingId > 0) {
//         if (state.GlobaleWoningSelectie != null)
//         {
//             //this.woningService.laadwoninggroepen(false).then(a => {
//             this.m_Data.m_InitielBroadcast = true;
//             //var selectie = a;
//             var selectie = this.woningService.GeefBreadCrumbVoorWoning(this.m_Data.m_HuidigeWoning, this.m_Data.m_HuidigeScope);

//             //this.GlobaleWoningSelectie = selectie;
//             this.zetGlobaleScopeSelectie();

//             /* Nieuwe woning selectie bekend */

//             var arg: ViewModelsWoningSelectie.KiesNieuweWoningEventArgs = new ViewModelsWoningSelectie.KiesNieuweWoningEventArgs();
//             arg.Event = null;
//             arg.GlobaleScopeSelectie = this.m_Data.HuidigeGlobaleScopeSelectie;

//             this.m_Data.kiesNieuweWoningEventEmitter.Emitter.BroadCast(arg);

//             var argCentrale = new ViewModelsWoningSelectie.CentraleToestandGewijzigdEventArgs();
//             argCentrale.Event = null;
//             argCentrale.GlobaleScopeSelectie = arg.GlobaleScopeSelectie;
//             argCentrale.WijzigBron = ViewModelsWoningSelectie.WijzigBron.Opstarten;

//             this.m_Data.m_InitielBroadcast = false;
//             //});
//         }
//     }
// }

function loadlist(oldstate: DwellingState, data: any): DwellingState
{
    let state  = copystate(oldstate);

    state.Woningen = {};
    var woningen = data.Woningen;
    var typen = data.Typen;
    var groepen = data.Groepen;
    _.each(woningen, (w) => {
        var woning = new ViewModelsWoning.Woning();
        Object.assign(woning, woning, w);
        woning.Clickable = woning.Tonen;
        state.Woningen[woning.WoningID] = woning;
    });

    state.WoningGroepTypen = _.map(typen, (t: any) => {
        var woninggroeptype = new ViewModelsWoningGroepType.WoningGroepType();

        woninggroeptype.Naam = t.Naam;
        woninggroeptype.DisplayNaam = t.DisplayNaam;
        woninggroeptype.Icoon = t.Icoon;
        woninggroeptype.GroepType = t.GroepTypeNummer;
        woninggroeptype.SuperGroepTypeEnum = t.SuperGroepType;

        return woninggroeptype;
    });

    _.each(state.WoningGroepTypen, (gt) => {
        gt.SuperGroepType = _.find(state.WoningGroepTypen, (gt2) => gt.SuperGroepTypeEnum == gt2.GroepType);
        gt.SubGroepType = _.find(state.WoningGroepTypen, (gt2) => gt2.SuperGroepTypeEnum == gt.GroepType && gt2.SuperGroepTypeEnum != gt2.GroepType);
    });


    state.WoningGroepen = _.map(groepen, (g: any) => {
        var woninggroep = new ViewModelsWoningGroep.WoningGroep();
        woninggroep.Naam = g.Naam;
        woninggroep.ID = g.WoningGroepID;
        woninggroep.Type = _.find(state.WoningGroepTypen, (t) => t.GroepType == g.WoningGroepTypeID);

        woninggroep.Plaats = g.Plaats;
        woninggroep.Postcode = g.Postcode;
        woninggroep.Straat = g.Straat;
        woninggroep.Nummer = g.Nummer;
        woninggroep.Longitude = g.Longitude;
        woninggroep.Latitude = g.Latitude;
        woninggroep.OppervlaktePolygoon = g.OppervlaktePolygoon;

        woninggroep.SubGroepIDs = g.SubGroepen;
        woninggroep.Tonen = false;
        woninggroep.Clickable = false;

        return woninggroep;
    });


    _.each(state.WoningGroepen, g => {
        if (g.Type.GroepType != WijzigScopeEnum.Blok) {
            g.Woningen = [];
            g.SubGroepen = _.map(g.SubGroepIDs, (gid) => {
                return _.find(state.WoningGroepen, (wg) => {
                    return wg.ID == gid && wg.Type == g.Type.SubGroepType
                });
            });
        }
        else {
            g.SubGroepen = [];
            g.Woningen = _.map(g.SubGroepIDs, (id) => {
                var woning = state.Woningen[id];
                return woning;
                //return _.find(this.Woningen, (w) => w.WoningID == id)
            });

            if (_.find(g.Woningen, (w) => { return w.Tonen === true }) !== undefined) {
                g.Tonen = true;
                g.Clickable = true;
            }
        }

        g.SuperGroep = _.find(state.WoningGroepen, (wg) => {
            if (wg.Type != g.Type.SuperGroepType) {
                return false;
            }
            return _.includes(wg.SubGroepIDs, g.ID);
        });
        if (g.Tonen) {
            if (g.SuperGroep != null) {
                g.SuperGroep.Tonen = true;
                if (_.filter(g.SuperGroep.SubGroepen, (s) => { return s.IsClickable() === true }).length > 1) {
                    g.SuperGroep.Clickable = true;
                }
            }

        }
    });

    return state;
}

export const dwellingReducer = createReducer(initialDwellingState,
    on(Actions.loadConfig, (oldstate, { software }) => {
        let state = copystate(oldstate);
        state.ScenarioId = software.InitieelScenario;
        state.DwellingId = software.InitieleWoning;
        state.DwellingId = software.InitieleWoning;

        state.BlockId = software.InitieelBlok;
        state.AreaId = software.InitieleWijk;
        state.ProjectId = software.InitieelProject;
        state.BewonerId = software.BewonerID;
        state.ScenarioIsReadOnly= software.InitieelScenarioReadOnly;

        if (software.IsVerkoopsite) {
            state.Scope = WijzigScopeEnum.Woning;
        }

        if (software.InitieelBreadcrumbniveauIsWijk) {
            state.Scope = WijzigScopeEnum.Complex;
        }



        return state;
    }),
    on(Actions.changeScope, (oldstate, { scope }) =>
    {
        let state = copystate(oldstate);

        if (state.Scope != scope) {
			if (state.GlobaleWoningSelectie != null) {
				state.GlobaleWoningSelectie.Actievegroep = <any>scope;
			}
			state.Scope = scope;
			//console.log("%cZetScope", "color:green");
			//state.scopeWijzigingEventEmitter.Emitter.BroadCast(undefined);
			//this.TriggerCentraleToestandGewijzigdSimpel(ViewModelsWoningSelectie.WijzigBron.ScopeWissel);
        }
        
        return state;
    }),
    on(Actions.ReloadDwellingListSuccess, (oldstate, { data }) => {
        let state = loadlist(oldstate, data);
        state.WoningenGeladen = true;
        state.DwellingListVersion++;
        state.GlobaleWoningSelectie = GeefBreadCrumbVoorWoning(state, state.DwellingId, state.Scope);

        return state;
    }),

    on(Actions.changeScenarioAndDwelling, (oldstate, { scenario, dwelling }) => {
        let state = copystate(oldstate);

        state.ScenarioId = scenario;
        state.DwellingId = dwelling;

        if (state.WoningenGeladen)
        {
            const bc = GeefBreadCrumbVoorWoning(state, dwelling, WijzigScopeEnum.Blok);
            let woningbreadcrumb = state.GlobaleWoningSelectie.WoningGroepBreadcrumb.slice();

            for (let i = 0; i < bc.WoningGroepBreadcrumb.length; i++) {
                woningbreadcrumb[i] = bc.WoningGroepBreadcrumb[i];
            }

            state.GlobaleWoningSelectie = state.GlobaleWoningSelectie.Copy();
            state.GlobaleWoningSelectie.WoningGroepBreadcrumb = woningbreadcrumb;

            state.DwellingId = state.GlobaleWoningSelectie.GeefType(WijzigScopeEnum.Woning).GeefID();
            state.AreaId = state.GlobaleWoningSelectie.GeefType(WijzigScopeEnum.Complex).GeefID();
            state.BlockId = state.GlobaleWoningSelectie.GeefType(WijzigScopeEnum.Blok).GeefID();
        }

        let nieuweGlobaleScopeSelectie = new ViewModelsWoningSelectie.GlobaleScopeSelectie(state.AreaId,
			state.BlockId,
			state.DwellingId,
            state.ScenarioId);
            
		if (state.GlobaleScopeSelectie) {
			if (state.GlobaleScopeSelectie.EqualTo(nieuweGlobaleScopeSelectie)) {
				nieuweGlobaleScopeSelectie.IsVeranderd = false;
			}
        }
        
        state.GlobaleScopeSelectie = nieuweGlobaleScopeSelectie;
        
        return state;
    })
);

export const dwellingFeatureKey = 'dwelling';