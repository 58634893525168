/// <reference path='../consumerinterfaces/CleanDataController.ts' />
/**
 * @ref WoonConnectApiController/Project/CleanDataController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/CleanDataController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as CleanUserDataModel from "@datacontracts/CleanUserDataModel";
import * as GeneratePasswordsModel from "@datacontracts/GeneratePasswordsModel";
import * as CleanDwellingsModel from "@datacontracts/CleanDwellingsModel";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class CleanDataConsumer implements Interface.ICleanDataConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public CleanUserData_Observable(inputModel: CleanUserDataModel.ICleanUserDataRequestModel & {ToJsonContract(noContent?: boolean): CleanUserDataModel.ICleanUserDataRequestModel}): Observable<CleanUserDataModel.ICleanUserDataResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/CleanData/CleanUserData`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<CleanUserDataModel.ICleanUserDataResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public CleanUserData(inputModel: CleanUserDataModel.ICleanUserDataRequestModel & {ToJsonContract(noContent?: boolean): CleanUserDataModel.ICleanUserDataRequestModel}): ng.IPromise<CleanUserDataModel.ICleanUserDataResponseModel> {
				let obs = this.CleanUserData_Observable(inputModel);
				let promise = new Promise<CleanUserDataModel.ICleanUserDataResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeneratePasswords_Observable(inputModel: GeneratePasswordsModel.IGeneratePasswordsRequestModel & {ToJsonContract(noContent?: boolean): GeneratePasswordsModel.IGeneratePasswordsRequestModel}): Observable<GeneratePasswordsModel.IGeneratePasswordsResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/CleanData/GeneratePasswords`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GeneratePasswordsModel.IGeneratePasswordsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeneratePasswords(inputModel: GeneratePasswordsModel.IGeneratePasswordsRequestModel & {ToJsonContract(noContent?: boolean): GeneratePasswordsModel.IGeneratePasswordsRequestModel}): ng.IPromise<GeneratePasswordsModel.IGeneratePasswordsResponseModel> {
				let obs = this.GeneratePasswords_Observable(inputModel);
				let promise = new Promise<GeneratePasswordsModel.IGeneratePasswordsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public CleanDwellings_Observable(inputModel: CleanDwellingsModel.ICleanDwellingsRequestModel & {ToJsonContract(noContent?: boolean): CleanDwellingsModel.ICleanDwellingsRequestModel}): Observable<CleanDwellingsModel.ICleanDwellingsResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/CleanData/CleanDwellings`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<CleanDwellingsModel.ICleanDwellingsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public CleanDwellings(inputModel: CleanDwellingsModel.ICleanDwellingsRequestModel & {ToJsonContract(noContent?: boolean): CleanDwellingsModel.ICleanDwellingsRequestModel}): ng.IPromise<CleanDwellingsModel.ICleanDwellingsResponseModel> {
				let obs = this.CleanDwellings_Observable(inputModel);
				let promise = new Promise<CleanDwellingsModel.ICleanDwellingsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


