import { Injectable } from '@angular/core';
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { GoogleAnalyticsModel } from '@models/FabrikantFilterModel';

declare let gtag: Function;

@Injectable({
    providedIn: 'root'
})

export class GoogleAnalyticsService {
    private loaded: boolean = false;
    private enabled: boolean = false;
    private crossDomain: boolean = false;
    private anonymize: boolean = true;
    private trackingId: string = "no-tracking-id";

    /**
     * Constructor for Google Analytics service.
     * @param fab {FabrikantenConsumer} FabrikantenConsumer model to fetch settings from fabrikanten.config.
     */
    constructor(private fab: FabrikantenConsumer) { }

    /**
     * Sets whether Google Analytics has to anonymize IP addresses (GPDR).
     * @param state {boolean} Set true to enable IP address anonymization.
     */
    public setAnonymization(state: boolean) {
        this.anonymize = state;

        // Console log
        console.log("[GA] anonymize set to " + this.anonymize);
    }

    /**
    * Send a page view event to Google Analytics.
    * @param url {string} The URL to register.
    */
    public sendPageView(
        title: string,
        location: string,
        path: string
    ) {
        let data: any = {};

        data["page_title"] = title;
        data["page_location"] = location;
        data["page_path"] = path;

        this.sendEventData('page_view', data);
    }

    /**
     * Send a custom event to Google Analyics.
     * @param category {string} The category of the event.
     * @param action {string} The value that will appear as the event action in Google Analytics Event reports.
     * @param label {string} The label of the event.
     * @param value {string} A non-negative integer that will appear as the vent value.
     */
    public sendCustomEvent(
        category: string,
        action: string,
        label: string,
        value: number

    ) {
        let data: any = {};

        data["event_category"] = category;
        data["event_label"] = label;
        data["value"] = value;

        this.sendEventData(action, data);
    }

    /**
     * Send configuration data to Google Analytics.
     */
    private sendConfigData() {
        if (!this.enabled) {
            return;
        }

        let config: any = {};

        config["send_page_view"] = false;

        if (this.anonymize) {
            config["anonymize_ip"] = true;
        }

        if (this.crossDomain) {
            config["linker"] = {};
            config.linker["accept_incoming"] = true;
        }

        gtag('config', this.trackingId, config);

        // Console log
        console.log("[GA] config data send (" + this.trackingId + "): " + JSON.stringify(config));
    }

    /**
     * Send event data to Google Analytics.
     * @param action {string} Action to be recorded on GA.
     * @param data {object} Data to be recorded on GA.
     */
    private sendEventData(
        action: string,
        data: object
    ) {
        if (this.loaded && this.enabled) {
            gtag('event', action, data);

            // Console log
            console.log("[GA] event data send (" + action + "): " + JSON.stringify(data));
        }
        else if (!this.loaded) {
            this.fab.GetGoogleAnalyticsSettings_Observable().toPromise().then(result => {
                if (!this.loaded) {
                  
                  var model: GoogleAnalyticsModel = new GoogleAnalyticsModel(result);

                  this.enabled = model.Enabled;
                  this.crossDomain = model.CrossDomain;
                  this.trackingId = model.TrackingId;

                    console.log("[GA] enabled set to " + this.enabled);
                    console.log("[GA] crossDomain set to " + this.crossDomain);
                    console.log("[GA] trackingId set to " + this.trackingId);

                    this.sendConfigData();

                    this.loaded = true;
                }
            })
            .then(result => {
                if (this.enabled) {
                    gtag('event', action, data);

                    // Console log
                    console.log("[GA] event data send (" + action + "): " + JSON.stringify(data));
                }
            })
            .catch(error => {
                this.enabled = false;
                this.loaded = true;

                // Console log
                console.log("[GA] disabled due to error: " + error);
            });
        }
    }
}
