import * as DataContractsAccountModels from '@datacontracts/AccountModels';
import * as ModelsAccountModels from '@models/AccountModels';




	export class AccountBeheer extends ModelsAccountModels.AccountViewModel {
		constructor(data: DataContractsAccountModels.IAccountViewModel) {
			super(data);			
		}
	}
	export class Account {
		PersoonId: number;
		LoginNaam: string;
		Password: string;
		Rollen: Array<string>;
		RegistratieCode: string;


		constructor() {
			this.PersoonId = null;
			this.LoginNaam = "";
			this.Password = "";
			this.Rollen = ([]);
			this.RegistratieCode = "";
		}
	}

	


	
