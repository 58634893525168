/**
 * @ref WoonConnectViewModel/Fabrikanten/FabrikantFilterModel.cs
 */

 import * as DataContracts from "@datacontracts/FabrikantFilterModel";

 
import * as FilterViewModel from "./FilterViewModel";
import * as BCBProductModel from "./BCBProductModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FabrikantenFilterRequestModel
	 */
	 

	export interface IFabrikantenFilterRequestModel {
		Input: string;
		ToJsonContract(): DataContracts.IFabrikantenFilterRequestModel;
	}

	export class FabrikantenFilterRequestModel {
		// property-Properties
		public get Input(): string {
			return this._Input;
		}
		public set Input(newInput: string) {
			this._Input = newInput;
		}

		// field-Properties

		// fields
		protected _Input: string = "";

		constructor(data?: DataContracts.IFabrikantenFilterRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabrikantenFilterRequestModel): void {
			if(data) {
				// vul properties
				this._Input = data.Input;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabrikantenFilterRequestModel {
			return {
				Input: this.Input
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FabrikantenFilterResponseModel
	 */
	 

	export interface IFabrikantenFilterResponseModel {
		ProductParameter: string;
		DeeplinkModus: boolean;
		ShowLeftOverProducts: boolean;
		FiltersParameter: string;
		Filters: FilterViewModel.IFilterViewModel[];
		Products: BCBProductModel.IBCBProductViewModel[];
		LeftOverProducts: BCBProductModel.IBCBProductViewModel[];
		SelectedProduct: BCBProductModel.IBCBProductViewModel;
		VerplichteWaardenIngevuld: boolean;
		ToJsonContract(): DataContracts.IFabrikantenFilterResponseModel;
	}

	export class FabrikantenFilterResponseModel {
		// property-Properties
		public get ProductParameter(): string {
			return this._ProductParameter;
		}
		public set ProductParameter(newProductParameter: string) {
			this._ProductParameter = newProductParameter;
		}

		public get DeeplinkModus(): boolean {
			return this._DeeplinkModus;
		}
		public set DeeplinkModus(newDeeplinkModus: boolean) {
			this._DeeplinkModus = newDeeplinkModus;
		}

		public get ShowLeftOverProducts(): boolean {
			return this._ShowLeftOverProducts;
		}
		public set ShowLeftOverProducts(newShowLeftOverProducts: boolean) {
			this._ShowLeftOverProducts = newShowLeftOverProducts;
		}

		public get FiltersParameter(): string {
			return this._FiltersParameter;
		}
		public set FiltersParameter(newFiltersParameter: string) {
			this._FiltersParameter = newFiltersParameter;
		}

		public get Filters(): FilterViewModel.IFilterViewModel[] {
			return this._Filters;
		}
		public set Filters(newFilters: FilterViewModel.IFilterViewModel[]) {
			this._Filters = newFilters;
		}

		public get Products(): BCBProductModel.IBCBProductViewModel[] {
			return this._Products;
		}
		public set Products(newProducts: BCBProductModel.IBCBProductViewModel[]) {
			this._Products = newProducts;
		}

		public get LeftOverProducts(): BCBProductModel.IBCBProductViewModel[] {
			return this._LeftOverProducts;
		}
		public set LeftOverProducts(newLeftOverProducts: BCBProductModel.IBCBProductViewModel[]) {
			this._LeftOverProducts = newLeftOverProducts;
		}

		public get SelectedProduct(): BCBProductModel.IBCBProductViewModel {
			return this._SelectedProduct;
		}
		public set SelectedProduct(newSelectedProduct: BCBProductModel.IBCBProductViewModel) {
			this._SelectedProduct = newSelectedProduct;
		}

		public get VerplichteWaardenIngevuld(): boolean {
			return this._VerplichteWaardenIngevuld;
		}
		public set VerplichteWaardenIngevuld(newVerplichteWaardenIngevuld: boolean) {
			this._VerplichteWaardenIngevuld = newVerplichteWaardenIngevuld;
		}

		// field-Properties

		// fields
		protected _ProductParameter: string = "";
		protected _DeeplinkModus: boolean = false;
		protected _ShowLeftOverProducts: boolean = false;
		protected _FiltersParameter: string = "";
		protected _Filters: FilterViewModel.IFilterViewModel[] = [];
		protected _Products: BCBProductModel.IBCBProductViewModel[] = [];
		protected _LeftOverProducts: BCBProductModel.IBCBProductViewModel[] = [];
		protected _SelectedProduct: BCBProductModel.IBCBProductViewModel = new BCBProductModel.BCBProductViewModel(undefined);
		protected _VerplichteWaardenIngevuld: boolean = false;

		constructor(data?: DataContracts.IFabrikantenFilterResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabrikantenFilterResponseModel): void {
			if(data) {
				// vul properties
				this._ProductParameter = data.ProductParameter;
				this._DeeplinkModus = data.DeeplinkModus;
				this._ShowLeftOverProducts = data.ShowLeftOverProducts;
				this._FiltersParameter = data.FiltersParameter;
				this._Filters = data.Filters ? data.Filters.map(filterViewModelItem => new FilterViewModel.FilterViewModel(filterViewModelItem)): [];
				this._Products = data.Products ? data.Products.map(bCBProductViewModelItem => new BCBProductModel.BCBProductViewModel(bCBProductViewModelItem)): [];
				this._LeftOverProducts = data.LeftOverProducts ? data.LeftOverProducts.map(bCBProductViewModelItem => new BCBProductModel.BCBProductViewModel(bCBProductViewModelItem)): [];
				this._SelectedProduct = data.SelectedProduct ? new BCBProductModel.BCBProductViewModel(data.SelectedProduct): undefined;
				this._VerplichteWaardenIngevuld = data.VerplichteWaardenIngevuld;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabrikantenFilterResponseModel {
			return {
				ProductParameter: this.ProductParameter,
				DeeplinkModus: this.DeeplinkModus,
				ShowLeftOverProducts: this.ShowLeftOverProducts,
				FiltersParameter: this.FiltersParameter,
				Filters: (this.Filters ? this.Filters.map(filterViewModelItem => new FilterViewModel.FilterViewModel(filterViewModelItem).ToJsonContract()): []),
				Products: (this.Products ? this.Products.map(bCBProductViewModelItem => new BCBProductModel.BCBProductViewModel(bCBProductViewModelItem).ToJsonContract()): []),
				LeftOverProducts: (this.LeftOverProducts ? this.LeftOverProducts.map(bCBProductViewModelItem => new BCBProductModel.BCBProductViewModel(bCBProductViewModelItem).ToJsonContract()): []),
				SelectedProduct: (this.SelectedProduct ? new BCBProductModel.BCBProductViewModel(this.SelectedProduct).ToJsonContract(): this.SelectedProduct),
				VerplichteWaardenIngevuld: this.VerplichteWaardenIngevuld
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FabrikantenCMSModel
	 */
	 

	export interface IFabrikantenCMSModel {
		Image1: string;
		Image2: string;
		Title1: string;
		Title2: string;
		Link1: string;
		Link2: string;
		ToJsonContract(): DataContracts.IFabrikantenCMSModel;
	}

	export class FabrikantenCMSModel {
		// property-Properties
		public get Image1(): string {
			return this._Image1;
		}
		public set Image1(newImage1: string) {
			this._Image1 = newImage1;
		}

		public get Image2(): string {
			return this._Image2;
		}
		public set Image2(newImage2: string) {
			this._Image2 = newImage2;
		}

		public get Title1(): string {
			return this._Title1;
		}
		public set Title1(newTitle1: string) {
			this._Title1 = newTitle1;
		}

		public get Title2(): string {
			return this._Title2;
		}
		public set Title2(newTitle2: string) {
			this._Title2 = newTitle2;
		}

		public get Link1(): string {
			return this._Link1;
		}
		public set Link1(newLink1: string) {
			this._Link1 = newLink1;
		}

		public get Link2(): string {
			return this._Link2;
		}
		public set Link2(newLink2: string) {
			this._Link2 = newLink2;
		}

		// field-Properties

		// fields
		protected _Image1: string = "";
		protected _Image2: string = "";
		protected _Title1: string = "";
		protected _Title2: string = "";
		protected _Link1: string = "";
		protected _Link2: string = "";

		constructor(data?: DataContracts.IFabrikantenCMSModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabrikantenCMSModel): void {
			if(data) {
				// vul properties
				this._Image1 = data.Image1;
				this._Image2 = data.Image2;
				this._Title1 = data.Title1;
				this._Title2 = data.Title2;
				this._Link1 = data.Link1;
				this._Link2 = data.Link2;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabrikantenCMSModel {
			return {
				Image1: this.Image1,
				Image2: this.Image2,
				Title1: this.Title1,
				Title2: this.Title2,
				Link1: this.Link1,
				Link2: this.Link2
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FabrikantenInlogRequestModel
	 */
	 

	export interface IFabrikantenInlogRequestModel {
		Name: string;
		Password: string;
		ToJsonContract(): DataContracts.IFabrikantenInlogRequestModel;
	}

	export class FabrikantenInlogRequestModel {
		// property-Properties
		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Password(): string {
			return this._Password;
		}
		public set Password(newPassword: string) {
			this._Password = newPassword;
		}

		// field-Properties

		// fields
		protected _Name: string = "";
		protected _Password: string = "";

		constructor(data?: DataContracts.IFabrikantenInlogRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabrikantenInlogRequestModel): void {
			if(data) {
				// vul properties
				this._Name = data.Name;
				this._Password = data.Password;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabrikantenInlogRequestModel {
			return {
				Name: this.Name,
				Password: this.Password
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FabrikantenInlogResponseModel
	 */
	 

	export interface IFabrikantenInlogResponseModel {
		Success: boolean;
		ToJsonContract(): DataContracts.IFabrikantenInlogResponseModel;
	}

	export class FabrikantenInlogResponseModel {
		// property-Properties
		public get Success(): boolean {
			return this._Success;
		}
		public set Success(newSuccess: boolean) {
			this._Success = newSuccess;
		}

		// field-Properties

		// fields
		protected _Success: boolean = false;

		constructor(data?: DataContracts.IFabrikantenInlogResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabrikantenInlogResponseModel): void {
			if(data) {
				// vul properties
				this._Success = data.Success;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabrikantenInlogResponseModel {
			return {
				Success: this.Success
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.GoogleAnalyticsModel
	 */
	 

	export interface IGoogleAnalyticsModel {
		Enabled: boolean;
		CrossDomain: boolean;
		TrackingId: string;
		ToJsonContract(): DataContracts.IGoogleAnalyticsModel;
	}

	export class GoogleAnalyticsModel {
		// property-Properties
		public get Enabled(): boolean {
			return this._Enabled;
		}
		public set Enabled(newEnabled: boolean) {
			this._Enabled = newEnabled;
		}

		public get CrossDomain(): boolean {
			return this._CrossDomain;
		}
		public set CrossDomain(newCrossDomain: boolean) {
			this._CrossDomain = newCrossDomain;
		}

		public get TrackingId(): string {
			return this._TrackingId;
		}
		public set TrackingId(newTrackingId: string) {
			this._TrackingId = newTrackingId;
		}

		// field-Properties

		// fields
		protected _Enabled: boolean = false;
		protected _CrossDomain: boolean = false;
		protected _TrackingId: string = "";

		constructor(data?: DataContracts.IGoogleAnalyticsModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGoogleAnalyticsModel): void {
			if(data) {
				// vul properties
				this._Enabled = data.Enabled;
				this._CrossDomain = data.CrossDomain;
				this._TrackingId = data.TrackingId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IGoogleAnalyticsModel {
			return {
				Enabled: this.Enabled,
				CrossDomain: this.CrossDomain,
				TrackingId: this.TrackingId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ConfiguratorUserInputModel
	 */
	 

	export interface IConfiguratorUserInputModel {
		Username: string;
		Password: string;
		AuthData: string;
		ToJsonContract(): DataContracts.IConfiguratorUserInputModel;
	}

	export class ConfiguratorUserInputModel {
		// property-Properties
		public get Username(): string {
			return this._Username;
		}
		public set Username(newUsername: string) {
			this._Username = newUsername;
		}

		public get Password(): string {
			return this._Password;
		}
		public set Password(newPassword: string) {
			this._Password = newPassword;
		}

		public get AuthData(): string {
			return this._AuthData;
		}
		public set AuthData(newAuthData: string) {
			this._AuthData = newAuthData;
		}

		// field-Properties

		// fields
		protected _Username: string = "";
		protected _Password: string = "";
		protected _AuthData: string = "";

		constructor(data?: DataContracts.IConfiguratorUserInputModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IConfiguratorUserInputModel): void {
			if(data) {
				// vul properties
				this._Username = data.Username;
				this._Password = data.Password;
				this._AuthData = data.AuthData;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IConfiguratorUserInputModel {
			return {
				Username: this.Username,
				Password: this.Password,
				AuthData: this.AuthData
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ConfiguratorUserViewModel
	 */
	 

	export interface IConfiguratorUserViewModel {
		Valid: boolean;
		Role: string;
		AuthData: string;
		ToJsonContract(): DataContracts.IConfiguratorUserViewModel;
	}

	export class ConfiguratorUserViewModel {
		// property-Properties
		public get Valid(): boolean {
			return this._Valid;
		}
		public set Valid(newValid: boolean) {
			this._Valid = newValid;
		}

		public get Role(): string {
			return this._Role;
		}
		public set Role(newRole: string) {
			this._Role = newRole;
		}

		public get AuthData(): string {
			return this._AuthData;
		}
		public set AuthData(newAuthData: string) {
			this._AuthData = newAuthData;
		}

		// field-Properties

		// fields
		protected _Valid: boolean = false;
		protected _Role: string = "";
		protected _AuthData: string = "";

		constructor(data?: DataContracts.IConfiguratorUserViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IConfiguratorUserViewModel): void {
			if(data) {
				// vul properties
				this._Valid = data.Valid;
				this._Role = data.Role;
				this._AuthData = data.AuthData;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IConfiguratorUserViewModel {
			return {
				Valid: this.Valid,
				Role: this.Role,
				AuthData: this.AuthData
			}
		}
	}
