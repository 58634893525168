/**
 * @ref WoonConnectViewModel/TradeAssociationViewModel.cs
 */

 import * as DataContracts from "@datacontracts/TradeAssociationViewModel";

 
import * as MeasureViewModel from "./MeasureViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.TradeAssociationViewModel
	 */
	 

	export interface ITradeAssociationViewModel {
		Id: number;
		Name: string;
		MeasureId: number;
		QualityMark: boolean;
		ToJsonContract(): DataContracts.ITradeAssociationViewModel;
	}

	export class TradeAssociationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get MeasureId(): number {
			return this._MeasureId;
		}
		public set MeasureId(newMeasureId: number) {
			this._MeasureId = newMeasureId;
		}

		public get QualityMark(): boolean {
			return this._QualityMark;
		}
		public set QualityMark(newQualityMark: boolean) {
			this._QualityMark = newQualityMark;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _MeasureId: number = 0;
		protected _QualityMark: boolean = false;

		constructor(data?: DataContracts.ITradeAssociationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITradeAssociationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._MeasureId = data.MeasureId;
				this._QualityMark = data.QualityMark;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITradeAssociationViewModel {
			return {
				Id: this.Id,
				Name: this.Name,
				MeasureId: this.MeasureId,
				QualityMark: this.QualityMark
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.TradeAssociationsViewModel
	 */
	 

	export interface ITradeAssociationsViewModel {
		TradeAssociations: TradeAssociationViewModel[];
		QualityMarks: TradeAssociationViewModel[];
		Measures: MeasureViewModel.IMeasureViewModel[];
		ToJsonContract(): DataContracts.ITradeAssociationsViewModel;
	}

	export class TradeAssociationsViewModel {
		// property-Properties
		public get TradeAssociations(): TradeAssociationViewModel[] {
			return this._TradeAssociations;
		}
		public set TradeAssociations(newTradeAssociations: TradeAssociationViewModel[]) {
			this._TradeAssociations = newTradeAssociations;
		}

		public get QualityMarks(): TradeAssociationViewModel[] {
			return this._QualityMarks;
		}
		public set QualityMarks(newQualityMarks: TradeAssociationViewModel[]) {
			this._QualityMarks = newQualityMarks;
		}

		public get Measures(): MeasureViewModel.IMeasureViewModel[] {
			return this._Measures;
		}
		public set Measures(newMeasures: MeasureViewModel.IMeasureViewModel[]) {
			this._Measures = newMeasures;
		}

		// field-Properties

		// fields
		protected _TradeAssociations: TradeAssociationViewModel[] = [];
		protected _QualityMarks: TradeAssociationViewModel[] = [];
		protected _Measures: MeasureViewModel.IMeasureViewModel[] = [];

		constructor(data?: DataContracts.ITradeAssociationsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITradeAssociationsViewModel): void {
			if(data) {
				// vul properties
				this._TradeAssociations = data.TradeAssociations ? data.TradeAssociations.map(tradeAssociationViewModelItem => new TradeAssociationViewModel(tradeAssociationViewModelItem)): [];
				this._QualityMarks = data.QualityMarks ? data.QualityMarks.map(tradeAssociationViewModelItem => new TradeAssociationViewModel(tradeAssociationViewModelItem)): [];
				this._Measures = data.Measures ? data.Measures.map(measureViewModelItem => new MeasureViewModel.MeasureViewModel(measureViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITradeAssociationsViewModel {
			return {
				TradeAssociations: (this.TradeAssociations ? this.TradeAssociations.map(tradeAssociationViewModelItem => new TradeAssociationViewModel(tradeAssociationViewModelItem).ToJsonContract()): []),
				QualityMarks: (this.QualityMarks ? this.QualityMarks.map(tradeAssociationViewModelItem => new TradeAssociationViewModel(tradeAssociationViewModelItem).ToJsonContract()): []),
				Measures: (this.Measures ? this.Measures.map(measureViewModelItem => new MeasureViewModel.MeasureViewModel(measureViewModelItem).ToJsonContract()): [])
			}
		}
	}
