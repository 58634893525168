/**
 * @ref WoonConnectViewModel/Survey/SurveyWindow.cs
 */

 import * as DataContracts from "@datacontracts/SurveyWindow";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.SurveyWindow
	 */
	 

	export interface ISurveyWindow {
		TekstUit: string;
		ToJsonContract(): DataContracts.ISurveyWindow;
	}

	export class SurveyWindow {
		// property-Properties
		// field-Properties
		public get TekstUit(): string {
			return this._TekstUit;
		}
		public set TekstUit(newTekstUit: string) {
			this._TekstUit = newTekstUit;
		}

		// fields
		protected _TekstUit: string = "";

		constructor(data?: DataContracts.ISurveyWindow) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISurveyWindow): void {
			if(data) {
				// vul properties
				// vul fields
				this._TekstUit = data.TekstUit;
			}
		}

		public ToJsonContract(): DataContracts.ISurveyWindow {
			return {
				TekstUit: this.TekstUit
			}
		}
	}
