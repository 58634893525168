import * as _ from 'lodash';
import { WijzigScopeEnum }  from '@enums/WijzigScopeEnum';
import { Observable, Subscriber } from 'rxjs';
import { DwellingSurvey } from '@models/DwellingSurvey';
import { IDwellingSurvey } from '@datacontracts/DwellingSurvey';
import { FormGroupState, createFormGroupState, formGroupReducer } from 'ngrx-forms';
import { SurveyGeneric } from '@models/SurveyGeneric';
import { SurveyBuilding } from '@models/SurveyBuilding';
import { treenode } from '../../smarttwin-ui/components/wct-stui-tree-menu/wct-stui-tree-menu.component';
import { v4 as uuidv4 } from 'uuid';
import { surveyFeatureKey } from './survey.reducer';
import { CdkTreeNode } from '@angular/cdk/tree';

export interface SurveyState {
	Survey: FormGroupState<IDwellingSurvey>;
  ActiveNode: treenode;
  ActiveClimateSystemId: string;
  SurveyTree: treenode[];
}

// example image, must be set for every treenode.
const exampleImg: string = "/Content/Images/configurator/surveytree/Opbouw_gevel.svg"


export enum SurveyCategories {
  GeneralData,
  BuildingData,
  Geometry,
  CalculationZone,
  FloorsGroundFloor,
  FloorsOthers,
  Roofs,
  Facades,
  FacadeOpenings,
  LinearThermalBridges,
  Installations,
  ClimateSystem
  // HeatingSystem,
  // Heater,
  // HeatingDistributionSystem,
  // HeatingDeliverySystem,
  // HeatingSolarPowerSystem,
  // HeatingStorageVessel,
  // CoolingSystem,
  // Cooler,
  // CoolngDistributionSystem,
  // CoolingDeliverySystem,
  // TapwaterSystem,
  // TapwaterDevice,
  // TapwaterDistributionSystem,
  // TapwaterDeliverySystem,
  // ShowerHeatRecovery,
  // TapwaterSolarPowerSystem,
  // TapwaterStorageVessel,
  // VentilationSystem,
  // Ventilators,
  // VentilationDistributionSystem,
  // PVPanels
}

export const FORM_ID = 'SurveyFormId';

// we can not use "new DwellingSurvey()" here, as the model has only the (for instance) _Walls member and the Walls get/set property, and it will not properly wrap it.
// As this is only needed for initial state, we do it by hand
const initialFormState = createFormGroupState<IDwellingSurvey>(FORM_ID, { Roofs: [], Walls: [], Floors: [], CalculationZones: [], ClimateSystem : [], Windows: [], Generic: new SurveyGeneric(), Building: new SurveyBuilding(), Facades: [] });
const initialSurveyTree = [
  {
    id: uuidv4(),
    image: exampleImg,
    title: "Algemene gegevens",
    category: SurveyCategories.GeneralData,
    active: true,
    children: []
  },
  {
    id: uuidv4(),
    image: exampleImg,
    title: "Woning gegevens",
    category: SurveyCategories.BuildingData,
    active: false,
    children: []
  },
  {
    id: uuidv4(),
    image: exampleImg,
    title: "Geometrie",
    category: SurveyCategories.Geometry,
    active: false,
    children: []
  },
  {
    id: uuidv4(),
    image: exampleImg,
    title: "Rekenzone",
    category: SurveyCategories.CalculationZone,
    active: false,
    children: []
  },
  {
    id: uuidv4(),
    image: exampleImg,
    title: "Bouwkundig",
    active: false,
    children: [
      {
        id: uuidv4(),
        image: exampleImg,
        title: "Vloeren beganegrond",
        category: SurveyCategories.FloorsGroundFloor,
        active: false,
        children: []
      },
      {
        id: uuidv4(),
        image: exampleImg,
        title: "Vloeren overige",
        category: SurveyCategories.FloorsOthers,
        active: false,
        children: []
      },
      {
        id: uuidv4(),
        image: exampleImg,
        title: "Daken",
        category: SurveyCategories.Roofs,
        active: false,
        children: []
      },
      {
        id: uuidv4(),
        image: exampleImg,
        title: "Gevels",
        category: SurveyCategories.Facades,
        active: false,
        children: []
      },
      {
        id: uuidv4(),
        image: exampleImg,
        title: "Kozijnen",
        category: SurveyCategories.FacadeOpenings,
        active: false,
        children: []
      },
      // not for basic survey
      // {
      //   id: uuidv4(),
      //   image: exampleImg,
      //   title: "Lineaire koudebruggen",
      //   category: SurveyCategories.LinearThermalBridges,
      //   active: false,
      //   children: []
      // },
    ]
  },
  {
    id: uuidv4(),
    image: exampleImg,
    title: "Installaties",
    active: false,
    category: SurveyCategories.Installations,
    children: [
      // {
      //   id: uuidv4(),
      //   image: exampleImg,
      //   title: "Verwarming",
      //   active: false,
      //   children: [
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Installatiesysteem",
      //       active: false,
      //       category: SurveyCategories.HeatingSystem,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Opwekkingstoestel",
      //       category: SurveyCategories.Heater,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Distributiesysteem",
      //       category: SurveyCategories.HeatingDistributionSystem,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Afgiftesysteem",
      //       category: SurveyCategories.HeatingDeliverySystem,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Zonne-energiesysteem",
      //       category: SurveyCategories.HeatingSolarPowerSystem,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Opslagvat",
      //       category: SurveyCategories.HeatingStorageVessel,
      //       active: false,
      //       children: []
      //     },
      //   ]
      // },
      // {
      //   id: uuidv4(),
      //   image: exampleImg,
      //   title: "Koeling",
      //   active: false,
      //   children: [
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Installatiesysteem",
      //       category: SurveyCategories.CoolingSystem,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Opwekkingstoestel",
      //       category: SurveyCategories.Cooler,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Distributiesysteem",
      //       category: SurveyCategories.CoolngDistributionSystem,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Afgiftesysteem",
      //       category: SurveyCategories.CoolingDeliverySystem,
      //       active: false,
      //       children: []
      //     }
      //   ]
      // },
      // {
      //   id: uuidv4(),
      //   image: exampleImg,
      //   title: "Tapwater",
      //   active: false,
      //   children: [
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Installatiesysteem",
      //       category: SurveyCategories.TapwaterSystem,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Opwekkingstoestel",
      //       category: SurveyCategories.TapwaterDevice,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Distributiesysteem",
      //       category: SurveyCategories.TapwaterDistributionSystem,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Afgiftesysteem",
      //       category: SurveyCategories.TapwaterDeliverySystem,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Douche-WTW",
      //       category: SurveyCategories.ShowerHeatRecovery,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Zonne-energiesysteem",
      //       category: SurveyCategories.TapwaterSolarPowerSystem,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Opslagvat",
      //       category: SurveyCategories.TapwaterStorageVessel,
      //       active: false,
      //       children: []
      //     }
      //   ]
      // },
      // {
      //   id: uuidv4(),
      //   image: exampleImg,
      //   title: "Ventilatie",
      //   active: false,
      //   children: [
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Installatiesysteem",
      //       category: SurveyCategories.VentilationSystem,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Ventilatoren",
      //       category: SurveyCategories.Ventilators,
      //       active: false,
      //       children: []
      //     },
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "Distributiesysteem",
      //       category: SurveyCategories.VentilationDistributionSystem,
      //       active: false,
      //       children: []
      //     }
      //   ]
      // },
      // {
      //   id: uuidv4(),
      //   image: exampleImg,
      //   title: "Gebouwgebonden energieopwekking",
      //   active: false,
      //   children: [
      //     {
      //       id: uuidv4(),
      //       image: exampleImg,
      //       title: "PV-panelen",
      //       category: SurveyCategories.PVPanels,
      //       active: false,
      //       children: []
      //     }
      //   ]
      // }
    ]
  }
]

export const initialSurveyState: SurveyState =
{
	Survey: initialFormState,
  SurveyTree: initialSurveyTree,
  ActiveNode: initialSurveyTree[0],
  ActiveClimateSystemId: ''
};

export interface IAppState {
	survey: SurveyState;
}
