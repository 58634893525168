import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { ProductsConsumer } from '@consumers/ProductsController';
import { PrijslijstConsumer } from '@consumers/PrijslijstController';
import { ProductsViewModel, ProductViewModel, ProductVariantModel } from '@models/ProductsViewModel';
import { InstructieViewModel, PrijslijstNewProductFilterViewModel, ExcelsViewModel, ExcelViewModel, UploadViewModel } from '@models/PrijslijstViewModel';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'instructie',
  templateUrl: './instructie.component.html',
  styleUrls: ['./instructie.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class InstructieComponent {


  public Loading = true;
  public InstructieViewModel: InstructieViewModel = null;

  constructor(
    private domSanitizer: DomSanitizer,
    private prijslijst: PrijslijstConsumer

  ) {
    this.LoadInstructie();

  }

  public GetUrl(url: string): any {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private LoadInstructie(): void {
    this.Loading = true;

    this.prijslijst.GetInstructieViewModel_Observable().subscribe((data) => {
      this.InstructieViewModel = new InstructieViewModel(data);
      this.Loading = false;
    });
  }

 public GetTime(): string {
  return (new Date()).getTime().toString();
}
}
