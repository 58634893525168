import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as ng from 'angular';
import { ProjectService } from '.././services/ProjectService';
import { ProjectAPIConsumer } from '@consumers/ProjectApiController';
import { ProjectSettingsRequestModel, IProjectSettingsAngular, ProjectSettingsAngular } from '@models/ProjectSettingsModel';
import { IProjectSettingsAngular as DataContractProjectSettings } from '@datacontracts/ProjectSettingsModel';

import { Observable } from 'rxjs'; 
import { SaveProjectSettingsRequestModel } from '@models/SaveProjectSettingsModel';
import { ImportProjectSettingsRequestModel } from '@models/ImportProjectSettingsModel';
import { DeleteProjectSettingInstantieRequestModel } from '@models/DeleteProjectSettingInstantieModel';

	@Injectable({
		providedIn: 'root'
	})
	export class ProjectSettingsService {
		public ProjectId: number;

		constructor(private projectservice: ProjectService, private projectAPIConsumer: ProjectAPIConsumer) {

			this.ProjectId = this.projectservice.GeefProjectId();
		}

		GeefProjectSettingsHuidigeProject(projectId: number): Observable<DataContractProjectSettings[]> {
			

			let input: ProjectSettingsRequestModel = new ProjectSettingsRequestModel();
			input.ProjectId = projectId;
			let obs = new Observable<DataContractProjectSettings[]>(observer => {
				this.projectAPIConsumer.GeefProjectSettings_Observable(input).subscribe(response => {
					observer.next(response.ProjectSettings);
					observer.complete();
				}); 

			});

			return obs;
		}

		OpslaanProjectSettingsHuidigeProject(projectId: number, projectSettings: Array<DataContractProjectSettings>): Observable<any> {
	
			let inputData: SaveProjectSettingsRequestModel = new SaveProjectSettingsRequestModel();
			inputData.ProjectId = projectId;
			projectSettings.forEach(projectSetting => {
				inputData.ProjectSettings.push(new ProjectSettingsAngular(projectSetting));
			});
			

			let obs = new Observable<any>(observer => {
				this.projectAPIConsumer.OpslaanProjectSettings_Observable(inputData).subscribe(response => {
					observer.next(response);
					observer.complete();
				});
			});
			return obs;
		}

		ImporteerProjectSettings(projectId: number): Observable<string>
		{
			var inputData: ImportProjectSettingsRequestModel = new ImportProjectSettingsRequestModel();
			inputData.ProjectId = projectId;

			let obs = new Observable<string>(observer => {
				this.projectAPIConsumer.ImportProjectSettings_Observable(inputData).subscribe(response => {
					observer.next(response.Message);
					observer.complete();
				});
			});

			return obs;
		}

		VerwijderProjectSettingInstantie(projectid: number, projectSetting: DataContractProjectSettings): Observable<boolean> {
			var inputData: DeleteProjectSettingInstantieRequestModel = new DeleteProjectSettingInstantieRequestModel();
			inputData.ProjectSettings = new ProjectSettingsAngular(projectSetting);
			inputData.ProjectId = projectid;

			let obs = new Observable<boolean>(observer => {
				this.projectAPIConsumer.VerwijderProjectSettingInstantie_Observable(inputData).subscribe(response => {
					observer.next(response.Success);
					observer.complete();
				});
			});

			return obs;
		}

		LaadDefault(): Observable<string> {

			let obs = new Observable<string>(observer => {
				this.projectAPIConsumer.LoadDefaultProjectSettings_Observable().subscribe(response =>{
					observer.next(response);
					observer.complete();
				});
			});

			 return obs;
		}
	}
	
	export class ProjectSetting {
		public Naam: string;
		public Type: string;
		public Categorie: string;
		public Waarde: string;
		public Aangepast: boolean;

		constructor(data) {
'ngInject';
			this.Naam = data.Naam;
			this.Type = data.Type;
			this.Categorie = data.Categorie;
			this.Waarde = data.Waarde;
			this.Aangepast = data.Aangepast;
		}

		public GetNaam(): string {
			return this.Categorie + "-" + this.Naam;
		}
	}
