/// <reference path='../consumerinterfaces/FabrikantenController.ts' />
/**
 * @ref WoonConnectApiController/Fabrikanten/FabrikantenController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/FabrikantenController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as FabrikantFilterModel from "@datacontracts/FabrikantFilterModel";
import * as FabrikantExcelModel from "@datacontracts/FabrikantExcelModel";
import * as PDFViewModel from "@datacontracts/PDFViewModel";
import * as BCBProductModel from "@datacontracts/BCBProductModel";
import * as request from "../../../helpers/IRequestHandler";





	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class FabrikantenConsumer implements Interface.IFabrikantenConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public FilterOptions_Observable(pStartModel: FabrikantFilterModel.IFabrikantenFilterResponseModel & {ToJsonContract(noContent?: boolean): FabrikantFilterModel.IFabrikantenFilterResponseModel}): Observable<FabrikantFilterModel.IFabrikantenFilterResponseModel> {
				const pStart = pStartModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pStart;
				let apiURL = `api/Fabrikanten/FilterOptions`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FabrikantFilterModel.IFabrikantenFilterResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public FilterOptions(pStartModel: FabrikantFilterModel.IFabrikantenFilterResponseModel & {ToJsonContract(noContent?: boolean): FabrikantFilterModel.IFabrikantenFilterResponseModel}): ng.IPromise<FabrikantFilterModel.IFabrikantenFilterResponseModel> {
				let obs = this.FilterOptions_Observable(pStartModel);
				let promise = new Promise<FabrikantFilterModel.IFabrikantenFilterResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public Login_Observable(pRequestModel: FabrikantFilterModel.IFabrikantenInlogRequestModel & {ToJsonContract(noContent?: boolean): FabrikantFilterModel.IFabrikantenInlogRequestModel}): Observable<FabrikantFilterModel.IFabrikantenInlogResponseModel> {
				const pRequest = pRequestModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pRequest;
				let apiURL = `api/Fabrikanten/Login`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FabrikantFilterModel.IFabrikantenInlogResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Login(pRequestModel: FabrikantFilterModel.IFabrikantenInlogRequestModel & {ToJsonContract(noContent?: boolean): FabrikantFilterModel.IFabrikantenInlogRequestModel}): ng.IPromise<FabrikantFilterModel.IFabrikantenInlogResponseModel> {
				let obs = this.Login_Observable(pRequestModel);
				let promise = new Promise<FabrikantFilterModel.IFabrikantenInlogResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SetCMS_Observable(pCMSModel: FabrikantFilterModel.IFabrikantenCMSModel & {ToJsonContract(noContent?: boolean): FabrikantFilterModel.IFabrikantenCMSModel}): Observable<void> {
				const pCMS = pCMSModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pCMS;
				let apiURL = `api/Fabrikanten/SetCMS`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SetCMS(pCMSModel: FabrikantFilterModel.IFabrikantenCMSModel & {ToJsonContract(noContent?: boolean): FabrikantFilterModel.IFabrikantenCMSModel}): ng.IPromise<void> {
				let obs = this.SetCMS_Observable(pCMSModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetCMS_Observable(): Observable<FabrikantFilterModel.IFabrikantenCMSModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetCMS`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FabrikantFilterModel.IFabrikantenCMSModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetCMS(): ng.IPromise<FabrikantFilterModel.IFabrikantenCMSModel> {
				let obs = this.GetCMS_Observable();
				let promise = new Promise<FabrikantFilterModel.IFabrikantenCMSModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetExcelModel_Observable(pStartModel: FabrikantFilterModel.IFabrikantenFilterResponseModel & {ToJsonContract(noContent?: boolean): FabrikantFilterModel.IFabrikantenFilterResponseModel}): Observable<FabrikantExcelModel.IFabrikantenExcelResponseModel> {
				const pStart = pStartModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pStart;
				let apiURL = `api/Fabrikanten/GetExcelModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FabrikantExcelModel.IFabrikantenExcelResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetExcelModel(pStartModel: FabrikantFilterModel.IFabrikantenFilterResponseModel & {ToJsonContract(noContent?: boolean): FabrikantFilterModel.IFabrikantenFilterResponseModel}): ng.IPromise<FabrikantExcelModel.IFabrikantenExcelResponseModel> {
				let obs = this.GetExcelModel_Observable(pStartModel);
				let promise = new Promise<FabrikantExcelModel.IFabrikantenExcelResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetUser_Observable(pInputModel: FabrikantFilterModel.IConfiguratorUserInputModel & {ToJsonContract(noContent?: boolean): FabrikantFilterModel.IConfiguratorUserInputModel}): Observable<FabrikantFilterModel.IConfiguratorUserViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetUser`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FabrikantFilterModel.IConfiguratorUserViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetUser(pInputModel: FabrikantFilterModel.IConfiguratorUserInputModel & {ToJsonContract(noContent?: boolean): FabrikantFilterModel.IConfiguratorUserInputModel}): ng.IPromise<FabrikantFilterModel.IConfiguratorUserViewModel> {
				let obs = this.GetUser_Observable(pInputModel);
				let promise = new Promise<FabrikantFilterModel.IConfiguratorUserViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetPDFModel_Observable(pRekenModuleViewModelModel: PDFViewModel.IRekenModuleInputViewModel & {ToJsonContract(noContent?: boolean): PDFViewModel.IRekenModuleInputViewModel}): Observable<PDFViewModel.IPDFViewModel> {
				const pRekenModuleViewModel = pRekenModuleViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pRekenModuleViewModel;
				let apiURL = `api/Fabrikanten/GetPDFModel/${pRekenModuleViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PDFViewModel.IPDFViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetPDFModel(pRekenModuleViewModelModel: PDFViewModel.IRekenModuleInputViewModel & {ToJsonContract(noContent?: boolean): PDFViewModel.IRekenModuleInputViewModel}): ng.IPromise<PDFViewModel.IPDFViewModel> {
				let obs = this.GetPDFModel_Observable(pRekenModuleViewModelModel);
				let promise = new Promise<PDFViewModel.IPDFViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SendOfferteAanvraag_Observable(pRekenModuleViewModelModel: PDFViewModel.IRekenModuleInputViewModel & {ToJsonContract(noContent?: boolean): PDFViewModel.IRekenModuleInputViewModel}): Observable<void> {
				const pRekenModuleViewModel = pRekenModuleViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pRekenModuleViewModel;
				let apiURL = `api/Fabrikanten/SendOfferteAanvraag/${pRekenModuleViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SendOfferteAanvraag(pRekenModuleViewModelModel: PDFViewModel.IRekenModuleInputViewModel & {ToJsonContract(noContent?: boolean): PDFViewModel.IRekenModuleInputViewModel}): ng.IPromise<void> {
				let obs = this.SendOfferteAanvraag_Observable(pRekenModuleViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetBCBProduct_Observable(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): Observable<BCBProductModel.IBCBProductViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetBCBProduct/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BCBProductModel.IBCBProductViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetBCBProduct(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): ng.IPromise<BCBProductModel.IBCBProductViewModel> {
				let obs = this.GetBCBProduct_Observable(pInputModel);
				let promise = new Promise<BCBProductModel.IBCBProductViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetBCBProductCAD_Observable(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): Observable<BCBProductModel.IBCBUrlViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetBCBProductCAD/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BCBProductModel.IBCBUrlViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetBCBProductCAD(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): ng.IPromise<BCBProductModel.IBCBUrlViewModel> {
				let obs = this.GetBCBProductCAD_Observable(pInputModel);
				let promise = new Promise<BCBProductModel.IBCBUrlViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetBCBProductCADIFC_Observable(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): Observable<BCBProductModel.IBCBUrlViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetBCBProductCADIFC/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BCBProductModel.IBCBUrlViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetBCBProductCADIFC(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): ng.IPromise<BCBProductModel.IBCBUrlViewModel> {
				let obs = this.GetBCBProductCADIFC_Observable(pInputModel);
				let promise = new Promise<BCBProductModel.IBCBUrlViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetBCBProductCADDWG3D_Observable(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): Observable<BCBProductModel.IBCBUrlViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetBCBProductCADDWG3D/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BCBProductModel.IBCBUrlViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetBCBProductCADDWG3D(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): ng.IPromise<BCBProductModel.IBCBUrlViewModel> {
				let obs = this.GetBCBProductCADDWG3D_Observable(pInputModel);
				let promise = new Promise<BCBProductModel.IBCBUrlViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetBCBProductCADDWG2D_Observable(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): Observable<BCBProductModel.IBCBUrlViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetBCBProductCADDWG2D/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BCBProductModel.IBCBUrlViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetBCBProductCADDWG2D(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): ng.IPromise<BCBProductModel.IBCBUrlViewModel> {
				let obs = this.GetBCBProductCADDWG2D_Observable(pInputModel);
				let promise = new Promise<BCBProductModel.IBCBUrlViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetBCBProductCADDXF2D_Observable(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): Observable<BCBProductModel.IBCBUrlViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetBCBProductCADDXF2D/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BCBProductModel.IBCBUrlViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetBCBProductCADDXF2D(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): ng.IPromise<BCBProductModel.IBCBUrlViewModel> {
				let obs = this.GetBCBProductCADDXF2D_Observable(pInputModel);
				let promise = new Promise<BCBProductModel.IBCBUrlViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetBCBProductCADDXF3D_Observable(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): Observable<BCBProductModel.IBCBUrlViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetBCBProductCADDXF3D/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BCBProductModel.IBCBUrlViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetBCBProductCADDXF3D(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): ng.IPromise<BCBProductModel.IBCBUrlViewModel> {
				let obs = this.GetBCBProductCADDXF3D_Observable(pInputModel);
				let promise = new Promise<BCBProductModel.IBCBUrlViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetBCBProductCADRFA_Observable(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): Observable<BCBProductModel.IBCBUrlViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetBCBProductCADRFA/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BCBProductModel.IBCBUrlViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetBCBProductCADRFA(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): ng.IPromise<BCBProductModel.IBCBUrlViewModel> {
				let obs = this.GetBCBProductCADRFA_Observable(pInputModel);
				let promise = new Promise<BCBProductModel.IBCBUrlViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetBCBProductCADHDHRevit_Observable(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): Observable<BCBProductModel.IBCBUrlViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Fabrikanten/GetBCBProductCADHDHRevit/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BCBProductModel.IBCBUrlViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetBCBProductCADHDHRevit(pInputModel: BCBProductModel.IBCBProductViewModel & {ToJsonContract(noContent?: boolean): BCBProductModel.IBCBProductViewModel}): ng.IPromise<BCBProductModel.IBCBUrlViewModel> {
				let obs = this.GetBCBProductCADHDHRevit_Observable(pInputModel);
				let promise = new Promise<BCBProductModel.IBCBUrlViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetFabrikantModule_Observable(): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetFabrikantModule`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetFabrikantModule(): ng.IPromise<string> {
				let obs = this.GetFabrikantModule_Observable();
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetFabrikantName_Observable(): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetFabrikantName`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetFabrikantName(): ng.IPromise<string> {
				let obs = this.GetFabrikantName_Observable();
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetProjectName_Observable(): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetProjectName`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetProjectName(): ng.IPromise<string> {
				let obs = this.GetProjectName_Observable();
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetGoogleAnalyticsSettings_Observable(): Observable<FabrikantFilterModel.IGoogleAnalyticsModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetGoogleAnalyticsSettings`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FabrikantFilterModel.IGoogleAnalyticsModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetGoogleAnalyticsSettings(): ng.IPromise<FabrikantFilterModel.IGoogleAnalyticsModel> {
				let obs = this.GetGoogleAnalyticsSettings_Observable();
				let promise = new Promise<FabrikantFilterModel.IGoogleAnalyticsModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}
