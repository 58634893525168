/**
 * @ref WoonConnectViewModel/Survey/SetSurveyModel.cs
 */

 import * as DataContracts from "@datacontracts/SetSurveyModel";

 
import * as DwellingSurvey from "./DwellingSurvey";
import * as WoningSelectie from "./WoningSelectie";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.SetSurveyRequestModel
	 */
	 

	export interface ISetSurveyRequestModel {
		DwellingSurvey: DwellingSurvey.IDwellingSurvey;
		Dwelling: WoningSelectie.IWoningSelectie;
		ToJsonContract(): DataContracts.ISetSurveyRequestModel;
	}

	export class SetSurveyRequestModel {
		// property-Properties
		public get DwellingSurvey(): DwellingSurvey.IDwellingSurvey {
			return this._DwellingSurvey;
		}
		public set DwellingSurvey(newDwellingSurvey: DwellingSurvey.IDwellingSurvey) {
			this._DwellingSurvey = newDwellingSurvey;
		}

		public get Dwelling(): WoningSelectie.IWoningSelectie {
			return this._Dwelling;
		}
		public set Dwelling(newDwelling: WoningSelectie.IWoningSelectie) {
			this._Dwelling = newDwelling;
		}

		// field-Properties

		// fields
		protected _DwellingSurvey: DwellingSurvey.IDwellingSurvey = new DwellingSurvey.DwellingSurvey(undefined);
		protected _Dwelling: WoningSelectie.IWoningSelectie = new WoningSelectie.WoningSelectie(undefined);

		constructor(data?: DataContracts.ISetSurveyRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISetSurveyRequestModel): void {
			if(data) {
				// vul properties
				this._DwellingSurvey = data.DwellingSurvey ? new DwellingSurvey.DwellingSurvey(data.DwellingSurvey): undefined;
				this._Dwelling = data.Dwelling ? new WoningSelectie.WoningSelectie(data.Dwelling): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISetSurveyRequestModel {
			return {
				DwellingSurvey: (this.DwellingSurvey ? new DwellingSurvey.DwellingSurvey(this.DwellingSurvey).ToJsonContract(): this.DwellingSurvey),
				Dwelling: (this.Dwelling ? new WoningSelectie.WoningSelectie(this.Dwelling).ToJsonContract(): this.Dwelling)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Survey.SetSurveyResponseModel
	 */
	 

	export interface ISetSurveyResponseModel {
		Succeeded: boolean;
	}

	export class SetSurveyResponseModel {
		// property-Properties
		// field-Properties
		public get Succeeded(): boolean {
			return this._Succeeded;
		}
		public set Succeeded(newSucceeded: boolean) {
			this._Succeeded = newSucceeded;
		}

		// fields
		protected _Succeeded: boolean = false;

		constructor(data?: DataContracts.ISetSurveyResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISetSurveyResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Succeeded = data.Succeeded;
			}
		}
	}
