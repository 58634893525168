import { Component, Input, OnInit } from '@angular/core';
import { ISurveyBuilding } from '@datacontracts/SurveyBuilding';
import { FormGroupState } from 'ngrx-forms';

@Component({
  selector: 'wct-survey-building',
  templateUrl: './wct-survey-building.component.html',
  styleUrls: ['./wct-survey-building.component.scss']
})
export class WctSurveyBuildingComponent implements OnInit {

  @Input() building : FormGroupState<ISurveyBuilding> = null;

  constructor() { }

  ngOnInit(): void {
  }

}
