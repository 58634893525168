/**
 * @ref WoonConnectViewModel/Survey/SurveyFloor.cs
 */

 import * as DataContracts from "@datacontracts/SurveyFloor";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.SurveyFloor
	 */
	 

	export interface ISurveyFloor {
		TekstUit: string;
		ToJsonContract(): DataContracts.ISurveyFloor;
	}

	export class SurveyFloor {
		// property-Properties
		// field-Properties
		public get TekstUit(): string {
			return this._TekstUit;
		}
		public set TekstUit(newTekstUit: string) {
			this._TekstUit = newTekstUit;
		}

		// fields
		protected _TekstUit: string = "";

		constructor(data?: DataContracts.ISurveyFloor) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISurveyFloor): void {
			if(data) {
				// vul properties
				// vul fields
				this._TekstUit = data.TekstUit;
			}
		}

		public ToJsonContract(): DataContracts.ISurveyFloor {
			return {
				TekstUit: this.TekstUit
			}
		}
	}
