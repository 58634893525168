/**
 * @ref WoonConnectViewModel/HourlyTarifKindViewModel.cs
 */

 import * as DataContracts from "@datacontracts/HourlyTarifKindViewModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.HourlyTarifKindViewModel
	 */
	 

	export interface IHourlyTarifKindViewModel {
		Id: number;
		DisplayName: string;
		ModelCode: string;
		ToJsonContract(): DataContracts.IHourlyTarifKindViewModel;
	}

	export class HourlyTarifKindViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get DisplayName(): string {
			return this._DisplayName;
		}
		public set DisplayName(newDisplayName: string) {
			this._DisplayName = newDisplayName;
		}

		public get ModelCode(): string {
			return this._ModelCode;
		}
		public set ModelCode(newModelCode: string) {
			this._ModelCode = newModelCode;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _DisplayName: string = "";
		protected _ModelCode: string = "";

		constructor(data?: DataContracts.IHourlyTarifKindViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IHourlyTarifKindViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._DisplayName = data.DisplayName;
				this._ModelCode = data.ModelCode;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IHourlyTarifKindViewModel {
			return {
				Id: this.Id,
				DisplayName: this.DisplayName,
				ModelCode: this.ModelCode
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.HourlyTarifKindRequestModel
	 */
	 

	export interface IHourlyTarifKindRequestModel {
		Temp: number;
		ToJsonContract(): DataContracts.IHourlyTarifKindRequestModel;
	}

	export class HourlyTarifKindRequestModel {
		// property-Properties
		public get Temp(): number {
			return this._Temp;
		}
		public set Temp(newTemp: number) {
			this._Temp = newTemp;
		}

		// field-Properties

		// fields
		protected _Temp: number = 0;

		constructor(data?: DataContracts.IHourlyTarifKindRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IHourlyTarifKindRequestModel): void {
			if(data) {
				// vul properties
				this._Temp = data.Temp;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IHourlyTarifKindRequestModel {
			return {
				Temp: this.Temp
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.HourlyTarifKindsViewModel
	 */
	 

	export interface IHourlyTarifKindsViewModel {
		HourlyTarifKinds: HourlyTarifKindViewModel[];
		ToJsonContract(): DataContracts.IHourlyTarifKindsViewModel;
	}

	export class HourlyTarifKindsViewModel {
		// property-Properties
		public get HourlyTarifKinds(): HourlyTarifKindViewModel[] {
			return this._HourlyTarifKinds;
		}
		public set HourlyTarifKinds(newHourlyTarifKinds: HourlyTarifKindViewModel[]) {
			this._HourlyTarifKinds = newHourlyTarifKinds;
		}

		// field-Properties

		// fields
		protected _HourlyTarifKinds: HourlyTarifKindViewModel[] = [];

		constructor(data?: DataContracts.IHourlyTarifKindsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IHourlyTarifKindsViewModel): void {
			if(data) {
				// vul properties
				this._HourlyTarifKinds = data.HourlyTarifKinds ? data.HourlyTarifKinds.map(hourlyTarifKindViewModelItem => new HourlyTarifKindViewModel(hourlyTarifKindViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IHourlyTarifKindsViewModel {
			return {
				HourlyTarifKinds: (this.HourlyTarifKinds ? this.HourlyTarifKinds.map(hourlyTarifKindViewModelItem => new HourlyTarifKindViewModel(hourlyTarifKindViewModelItem).ToJsonContract()): [])
			}
		}
	}
