/**
 * @ref WoonConnectViewModel/Fabrikanten/OverviewViewModel.cs
 */

 import * as DataContracts from "@datacontracts/OverviewViewModel";

 
import * as ProductsViewModel from "./ProductsViewModel";
import * as FilterViewModel from "./FilterViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.OverviewViewModel
	 */
	 

	export interface IOverviewViewModel {
		Products: ProductsViewModel.IProductViewModel[];
		Filters: FilterViewModel.IFilterViewModel[];
		ToJsonContract(): DataContracts.IOverviewViewModel;
	}

	export class OverviewViewModel {
		// property-Properties
		public get Products(): ProductsViewModel.IProductViewModel[] {
			return this._Products;
		}
		public set Products(newProducts: ProductsViewModel.IProductViewModel[]) {
			this._Products = newProducts;
		}

		public get Filters(): FilterViewModel.IFilterViewModel[] {
			return this._Filters;
		}
		public set Filters(newFilters: FilterViewModel.IFilterViewModel[]) {
			this._Filters = newFilters;
		}

		// field-Properties

		// fields
		protected _Products: ProductsViewModel.IProductViewModel[] = [];
		protected _Filters: FilterViewModel.IFilterViewModel[] = [];

		constructor(data?: DataContracts.IOverviewViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IOverviewViewModel): void {
			if(data) {
				// vul properties
				this._Products = data.Products ? data.Products.map(productViewModelItem => new ProductsViewModel.ProductViewModel(productViewModelItem)): [];
				this._Filters = data.Filters ? data.Filters.map(filterViewModelItem => new FilterViewModel.FilterViewModel(filterViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IOverviewViewModel {
			return {
				Products: (this.Products ? this.Products.map(productViewModelItem => new ProductsViewModel.ProductViewModel(productViewModelItem).ToJsonContract()): []),
				Filters: (this.Filters ? this.Filters.map(filterViewModelItem => new FilterViewModel.FilterViewModel(filterViewModelItem).ToJsonContract()): [])
			}
		}
	}
