<wct-bezig [bezigmodel]="bezigViewModel" *ngIf="cause == 'editFolder'">
	<div class="flex-cont cont-title">
		<p *ngIf="folder?.Naam == ''" class="txt-title">Nieuwe map toevoegen</p>
		<p *ngIf="folder?.Naam != ''" class="txt-title">Eigenschappen van {{ folder?.Naam }} wijzigen</p>
		<img (click)="cancel()" [src]="'/Content/Images/menu/menu-close-white.svg'" class="img-title" />
	</div>

	<div class="cont-main">
		<div *ngIf="parentFolder != undefined" class="cont-line">
			<div class="cont-field"><p>Bovenliggende map</p></div>
			<div class="cont-field"><p>{{ parentFolder.Naam }}</p></div>
		</div>

		<div class="cont-line cont-line-input-or-select">
			<div class="cont-field"><p>Naam van de map</p></div>
			<div class="cont-field"><input type="text" [ngModel]="folder?.Naam" (ngModelChange)="folder.Naam = $event" [ngClass]="{'input-error': showFolderNameNotEntered()}" /></div>
			<p *ngIf="showFolderNameNotEntered()" class="txt-mapnaam-is-niet-ingevuld">Vul eerst de naam van de map in</p>
		</div>
		<div class="cont-line cont-line-input-or-select">
			<div class="cont-field"><p>Display naam voor bewoners</p></div>
			<div class="cont-field"><input type="text" [ngModel]="folder?.DisplayNameForBewoner" (ngModelChange)="folder.DisplayNameForBewoner = $event" /></div>
		</div>
		<div class="cont-line cont-line-input-or-select cont-line-last" *ngIf="isFolderAdmin">
			<div class="cont-field"><p>Maptype</p></div>
			<div class="cont-field">
				<select [(ngModel)]="folderType">
					<option value="-1">Standaard</option>
               		<option value="1">Verbergen</option>
               		<option value="2">Ingediende Opties</option>
               		<option value="3">Offertes</option>
               		<option value="4">Algemene documenten</option>
               		<option value="5">Persoonlijke map</option>
					<option value="6">Woningmap</option>
					<option value="7">Privemap</option>
					<option value="8">Contractor</option>
				</select>
			</div>
		</div>
		<wct-rechten-control [rechten]="folder?.Rechten" *ngIf="isFolderAdmin"></wct-rechten-control>
	</div>
	<div class="col-xs-12 flex-cont cont-btns">
		<wct-button buttonText="Annuleren" buttonSize="small" buttonRole="secundary" (click)="cancel()"></wct-button>
		<wct-button buttonText="Opslaan" buttonSize="small" buttonRole="primary" (click)=save()></wct-button>
	</div>
</wct-bezig>

<wct-bezig [bezigmodel]="bezigViewModel" *ngIf="cause == 'deleteFolder'">
	<div class="flex-cont cont-title">
		<p class="txt-title">Map {{ folder?.Naam }} verwijderen</p>
		<img (click)="cancel()" [src]="'/Content/Images/menu/menu-close-white.svg'" class="img-title" />
	</div>

	<div class="cont-main">
		<p>Weet u zeker dat u de map {{folder?.Naam}} wilt verwijderen?</p>
	</div>

	<div class="col-xs-12 flex-cont cont-btns">
		<wct-button buttonText="{{ 'DOCUMENTMANAGEMENT_CANCEL' | translate }}" buttonSize="small" buttonRole="secundary" (click)="cancel()"></wct-button>
		<wct-button buttonText="{{ 'DOCUMENTMANAGEMENT_OK' | translate }}" buttonSize="small" buttonRole="primary" (click)="deleteFolder()"></wct-button>
	</div>
</wct-bezig>