import { Theme } from '../theme.ts';

export const ImsTheme: Theme = {
   name: 'ims',
   properties: {
      '--header-font-family': 'Open sans',
      '--header-font-weight': '600',
      '--header-color': '#666666',
      '--header-text-transform': 'none',
      '--body-font-family': 'Open sans',
      '--color-default-primary-background': '#f5d327',
      '--color-default-primary-background-default': '#f5d327',
      '--color-default-primary-background-hover': '#666666',
      '--color-default-primary-background-default-text': '#000000',
      '--color-default-primary-background-hover-text': '#ffffff',
      '--color-default-primary-background-opacity-30': '#fcf1b6',
      '--button-primary-border-radius': '0',
      '--button-secundary-border-radius': '0',
      '--button-primary-color--hover': 'black',
      '--fab-filters-margin': '0',
      '--fab-product__osf-display': 'none',
      '--fab-product__button-text-color': 'black',
      '--fab-product__button-text-color--hover': 'black',
      '--fab-product__button-text-color--active': 'black',
      '--fab-product__button-background-color--hover': '#f5de6b',
      '--fab-filters__button-border-color': 'rgba(0,0,0,0.12)',
      '--fab-filters__button-text-color': '#61686c',
      '--fab-filter__selected-option-color': 'black'
   }
};
