//// [conv] import "(./WctLichtDialoog).less";
import * as ng from 'angular';
import * as HelpersBackwards from '../../Helpers/Backwards';
import * as ServicesSceneTypescript from '../../services/SceneTypescript';
import * as ServicesOpslagService from '../../services/OpslagService';
import * as ServicesWebGLViewerService from '../../services/WebGLViewerService';
import * as ServicesSceneService from '../../viewers/SceneService';
import * as ComponentslichtWctLichtDialoog from '../../components/licht/WctLichtDialoog';
import * as ViewModelsWebGLSessie from '../../Models/WebGLSessie';


	
	export class StartLichtDialoogStartController {
		constructor($scope: any, viewersession: any) {
'ngInject';
			$scope.viewersession = viewersession;
		}
	}

	export function StartLichtDialoog($mdDialog: any, session: ViewModelsWebGLSessie.WebGLSessie) {
		$mdDialog.show({
			locals: { viewersession: session },
			clickOutsideToClose: true,
			//controllerAs: OptiePopupCtrl.controllerId,
			template: '<wct-licht-dialoog viewersession="viewersession"></wct-licht-dialoog>',
			controller: ComponentslichtWctLichtDialoog.StartLichtDialoogStartController,
		});
	}

	interface ILichtDialoogBindings {
		viewersession: ViewModelsWebGLSessie.WebGLSessie;
	}

	export interface ILichtDialoogController extends ILichtDialoogBindings {

	}


	class LichtDialoogController implements ILichtDialoogController {
		viewersession: ViewModelsWebGLSessie.WebGLSessie;

		constructor(private $window, private $state, private sceneservice: ServicesSceneService.ISceneService, private webglviewerservice: ServicesWebGLViewerService.IWebGLViewerService, private opslagservice: ServicesOpslagService.IOpslagService) {
'ngInject';
			this.ZetInitieleWaarden();

			this.OvernemenUitViewer();
		}

		Verbergen: boolean = (true);

		LichtinstellingenActief = (false);
		ViewerLichtvalX = ('');
		ViewerLichtvalY = ('');
		ViewerLichtvalZ = ('');

		ViewerCentrumX = ('');
		ViewerCentrumY = ('');
		ViewerCentrumZ = ('');

		ViewerPositieX = ('');
		ViewerPositieY = ('');
		ViewerPositieZ = ('');

		m_LaatsteViewer: string = "";

		ViewerAssen: boolean = (false);
		ViewerLichtbronSterkte: string = ('0');
		ViewerIsCameraLicht: boolean = (false);
		ViewerLichtSchittering: string = ('0');
		ViewerOmgevinglichtSterkte: string = ('0');
		ViewerSlagschaduwVerzadiging: string = ('0');
		ViewerToonSlagschaduw: boolean = (false);
		ViewerHint: number = (0);
		SpecialeHandelingWachttijd: number = (-1);
		TekenHints: boolean = (true);
		SlagschaduwHint: string = ('0');
		GebouwFilter: string = ('');



		Opgestart = false;

		Open: boolean = (false);


		Start() {
			this.Open = (true);
			//var view = BekijkViewModelInstance.View();
			//if (this.m_LaatsteViewer != view) {
			//this.OvernemenUitViewer();
			// }
		}


		numto2digit(d) {
			return ('0' + d).slice(-2);
		}

		ZetInitieleWaarden() {
		}

		GeefSessie(): ServicesSceneTypescript.SceneSession {
			return this.viewersession.SceneSession;
			//if (BekijkViewModelInstance.View() == 'Woning3D') {
			//    return BekijkPaginaInstance.Woning3DViewer.Sessie;
			//} else {
			//    return BekijkPaginaInstance.Wijk3DViewer.Sessie;
			//}
			//return null;
		}

		GeefViewer() {
			return this.GeefSessie().GeefViewer();
		}

		Lichtinstellingen() {
			this.LichtinstellingenActief = (!this.LichtinstellingenActief);
		}

		ToggleAssen() {
			var viewer = this.GeefViewer();
			var assen = viewer.getToonAssen();
			viewer.setToonAssen(!assen);
			viewer.toonModel();
		}

		ToggleFPS() {
			this.viewersession.ToonFPS();
		}

		double2string(d) {
			return (Math.round(d * 1000) / 1000).toString();
		}

		istrue(v) {
			if (typeof v == "boolean") {
				return v;
			}
			return (v.toString() == 'true');
		}

		OvernemenUitViewer() {
			var belichting = this.webglviewerservice.GeefBelichting(this.GeefViewer());
			var lichtval = this.webglviewerservice.GeefBelichtingLichtval(belichting);
			this.ViewerLichtvalX = (this.double2string(lichtval[0]));
			this.ViewerLichtvalY = (this.double2string(lichtval[1]));
			this.ViewerLichtvalZ = (this.double2string(lichtval[2]));
			var centrum = this.webglviewerservice.GeefCentrum(this.GeefViewer());
			if (centrum) {
				this.ViewerCentrumX = (this.double2string(centrum[0]));
				this.ViewerCentrumY = (this.double2string(centrum[1]));
				this.ViewerCentrumZ = (this.double2string(centrum[2]));
			}
			var positie = this.webglviewerservice.GeefPositie(this.GeefViewer());
			if (positie)
			{
				this.ViewerPositieX = (this.double2string(positie[0]));
				this.ViewerPositieY = (this.double2string(positie[1]));
				this.ViewerPositieZ = (this.double2string(positie[2]));
			}

			this.ViewerLichtbronSterkte = this.webglviewerservice.GeefBelichtingLichtbronSterkte(belichting);
			this.ViewerIsCameraLicht = this.webglviewerservice.GeefBelichtingIsCameraLicht(belichting);
			this.ViewerLichtSchittering = this.webglviewerservice.GeefBelichtingLichtschittering(belichting);
			this.ViewerOmgevinglichtSterkte = this.webglviewerservice.GeefBelichtingOmgevinglichtSterkte(belichting);
			this.ViewerSlagschaduwVerzadiging = this.webglviewerservice.GeefBelichtingSlagschaduwVerzadiging(belichting);

			var slagschaduw = this.webglviewerservice.GeefBelichtingSlagschaduw(belichting);
			this.ViewerToonSlagschaduw = (slagschaduw);

			var slagschaduwhint = this.webglviewerservice.GeefBelichtingSlagschaduwHint(belichting);
			this.SlagschaduwHint = (slagschaduwhint);
		}

		ResetLocalCache()
		{
			this.opslagservice.WisAlles();
		}

		InfoBelichting() {
			var belichting = this.GeefViewer().getBelichting();

			alert("OmgevinglichtSterkte: " + belichting.getOmgevinglichtSterkte() + " - LichtbronSterkte: " + belichting.getLichtbronSterkte() + " - LichtSchittering: " + belichting.getLichtSchittering() + " - SlagschaduwVerzadiging: " + belichting.getSlagschaduwVerzadiging() + " - Slagschaduw: " + belichting.getToonSlagschaduw());
		}

		ZetZoomGeheel() {
			this.GeefViewer().zetZoomGeheel();
			this.GeefViewer().toonModel();
		}

		ZetZichtStandaard() {
			this.GeefViewer().zetZichtStandaard();
			this.GeefViewer().toonModel();

		}
		//
		// Stelt een tekenkwaliteithint voor een 'online 3D-viewer' in. Deze hint mag beschouwd worden als 
		// het omgekeerde van een prestatiehint.
		// Parameter 'viewer3D' is een viewer-object.
		// Parameter 'hint' is een enumeratiegetal. De mogelijke enumeratiewaarden zijn: 
		// 2 = Hoogst; 1 = Hoger; 0 = Gemiddeld; -1 = Minder; -2 = Nog minder; -3 = Minst.
		//
		ZetHint() {
			var hint = +(this.ViewerHint);


			var viewer = this.GeefViewer();

			if (typeof (hint) == 'number' && isFinite(hint) && Math.floor(hint) == hint &&
				hint >= -3 && hint <= 2) {
				//
				// Kwaliteithint    Slagschaduwhint   Bumpmapping-hint
				//    2                2                 aan
				//    1                1                 aan
				//    0                0                 aan
				//   -1               -1                 aan
				//   -2               -1                 uit
				//   -3               uit                uit
				//
				var slagschaduwHint: number = Math.max(hint, -1);
				var toonSlagschaduw: boolean = (hint > -3);
				var bumpmappingHint: boolean = (hint > -2);

				this.SlagschaduwHint = (slagschaduwHint.toString());
				this.ViewerToonSlagschaduw = (toonSlagschaduw);
				this.TekenHints = (bumpmappingHint);
			}
		}


		OvernemenUitDialoog() {
			var belichting = this.webglviewerservice.GeefBelichting(this.GeefViewer());
			var lichtval = [parseFloat(this.ViewerLichtvalX), parseFloat(this.ViewerLichtvalY), parseFloat(this.ViewerLichtvalZ)];
			this.webglviewerservice.ZetBelichtingLichtval(belichting, lichtval);
			this.webglviewerservice.ZetBelichtingLichtbronSterkte(belichting, parseFloat(this.ViewerLichtbronSterkte));
			this.webglviewerservice.ZetBelichtingIsCameraLicht(belichting, this.istrue(this.ViewerIsCameraLicht));
			this.webglviewerservice.ZetBelichtingLichtSchittering(belichting, parseFloat(this.ViewerLichtSchittering));
			this.webglviewerservice.ZetBelichtingOmgevinglichtSterkte(belichting, parseFloat(this.ViewerOmgevinglichtSterkte));
			this.webglviewerservice.ZetBelichtingSlagschaduwVerzadiging(belichting, parseFloat(this.ViewerSlagschaduwVerzadiging));
			var tezettenSlagschaduw = this.istrue(this.ViewerToonSlagschaduw);
			this.webglviewerservice.ZetBelichtingLichtval(belichting, tezettenSlagschaduw);

			var centrum = [parseFloat(this.ViewerCentrumX), parseFloat(this.ViewerCentrumY), parseFloat(this.ViewerCentrumZ)];
			this.webglviewerservice.ZetCentrum(this.GeefViewer(), centrum);
			this.ViewerCentrumX = (this.double2string(centrum[0]));
			this.ViewerCentrumY = (this.double2string(centrum[1]));
			this.ViewerCentrumZ = (this.double2string(centrum[2]));

			var positie = [parseFloat(this.ViewerPositieX), parseFloat(this.ViewerPositieY), parseFloat(this.ViewerPositieZ)];
			this.webglviewerservice.ZetPositie(this.GeefViewer(), positie);
			this.ViewerPositieX = (this.double2string(positie[0]));
			this.ViewerPositieY = (this.double2string(positie[1]));
			this.ViewerPositieZ = (this.double2string(positie[2]));

			this.webglviewerservice.ZetBelichtingSlagschaduwHint(belichting, parseInt(this.SlagschaduwHint));
			this.webglviewerservice.ZetBelichting(this.GeefViewer(), belichting);

			var wachttijd = +(this.SpecialeHandelingWachttijd);

			this.webglviewerservice.ZetTekenHints(this.GeefViewer(), this.TekenHints);

			//if (HelpersBackwards.ConstSoftware.HDHBranch == 'experimenteel') {
			//	this.GeefViewer().zetTekenHints(this.TekenHints, wachttijd);
			//}
			//else {
			//	this.GeefViewer().zetTekenHints(this.TekenHints);
			//}

			this.GeefSessie().ZetFilter(this.GebouwFilter);

			this.GeefViewer().toonModel();
		}
	}


	export class WctLichtDialoog implements ng.IComponentOptions {
		public bindings: any;
		public controller: any;
		public templateUrl: string;

		public transclude: any;
		public $routeConfig: any;

		constructor() {
			this.bindings = {
				viewersession: '<'
			};
			this.controller = LichtDialoogController;
			this.templateUrl = '/app/components/Licht/wctLichtDialoog.html';
		}

		public static Factory() {
			return new WctLichtDialoog();
		}
	}

