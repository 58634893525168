/// <reference path='../consumerinterfaces/WoningAPIController.ts' />
/**
 * @ref WoonConnectApiController/Controllers/WoningAPI/WoningAPIController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/WoningAPIController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as WoningBatch from "@datacontracts/WoningBatch";
import * as WoningAPIResultaat from "@datacontracts/WoningAPIResultaat";
import * as WoningAPIViewModel from "@datacontracts/WoningAPIViewModel";
import * as WoningAPIStatistiekenViewModel from "@datacontracts/WoningAPIStatistiekenViewModel";
import * as WoningBatchAPIViewModel from "@datacontracts/WoningBatchAPIViewModel";
import * as WoningAPIReplaceMainResident from "@datacontracts/WoningAPIReplaceMainResident";
import * as WoningAPISetApproved from "@datacontracts/WoningAPISetApproved";
import * as WoningAPIHoofdBewoners from "@datacontracts/WoningAPIHoofdBewoners";
import * as AngularWoningGroepModel from "@datacontracts/AngularWoningGroepModel";
import * as request from "../../../helpers/IRequestHandler";





	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class WoningAPIConsumer implements Interface.IWoningAPIConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public MaakWoningBatch_Observable(batchModel: WoningBatch.IWoningBatch & {ToJsonContract(noContent?: boolean): WoningBatch.IWoningBatch}): Observable<WoningAPIResultaat.IWoningAPIResultaat> {
				const batch = batchModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = batch;
				let apiURL = `api/Woning/MaakWoningBatch/${batch}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<WoningAPIResultaat.IWoningAPIResultaat>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public MaakWoningBatch(batchModel: WoningBatch.IWoningBatch & {ToJsonContract(noContent?: boolean): WoningBatch.IWoningBatch}): ng.IPromise<WoningAPIResultaat.IWoningAPIResultaat> {
				let obs = this.MaakWoningBatch_Observable(batchModel);
				let promise = new Promise<WoningAPIResultaat.IWoningAPIResultaat>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefWoningen_Observable(): Observable<WoningAPIViewModel.IWoningAPIViewModel[]> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Woning/GeefWoningen/`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<WoningAPIViewModel.IWoningAPIViewModel[]>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefWoningen(): ng.IPromise<WoningAPIViewModel.IWoningAPIViewModel[]> {
				let obs = this.GeefWoningen_Observable();
				let promise = new Promise<WoningAPIViewModel.IWoningAPIViewModel[]>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefWoning_Observable(id: number): Observable<WoningAPIViewModel.IWoningAPIViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Woning/GeefWoning/${id}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<WoningAPIViewModel.IWoningAPIViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefWoning(id: number): ng.IPromise<WoningAPIViewModel.IWoningAPIViewModel> {
				let obs = this.GeefWoning_Observable(id);
				let promise = new Promise<WoningAPIViewModel.IWoningAPIViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ControleerBatchOmschrijving_Observable(omschrijving: string): Observable<boolean> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Woning/ControleerBatchOmschrijving/${omschrijving}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ControleerBatchOmschrijving(omschrijving: string): ng.IPromise<boolean> {
				let obs = this.ControleerBatchOmschrijving_Observable(omschrijving);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefWoningStatistieken_Observable(): Observable<WoningAPIStatistiekenViewModel.IWoningAPIStatistiekenViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Woning/GeefWoningStatistieken/`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<WoningAPIStatistiekenViewModel.IWoningAPIStatistiekenViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefWoningStatistieken(): ng.IPromise<WoningAPIStatistiekenViewModel.IWoningAPIStatistiekenViewModel> {
				let obs = this.GeefWoningStatistieken_Observable();
				let promise = new Promise<WoningAPIStatistiekenViewModel.IWoningAPIStatistiekenViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefWoningBatches_Observable(): Observable<WoningBatchAPIViewModel.IWoningBatchAPIViewModel[]> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Woning/GeefWoningBatches/`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<WoningBatchAPIViewModel.IWoningBatchAPIViewModel[]>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefWoningBatches(): ng.IPromise<WoningBatchAPIViewModel.IWoningBatchAPIViewModel[]> {
				let obs = this.GeefWoningBatches_Observable();
				let promise = new Promise<WoningBatchAPIViewModel.IWoningBatchAPIViewModel[]>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ReplaceMainResident_Observable(pInputModel: WoningAPIReplaceMainResident.IWoningAPIReplaceMainResidentInputModel & {ToJsonContract(noContent?: boolean): WoningAPIReplaceMainResident.IWoningAPIReplaceMainResidentInputModel}): Observable<WoningAPIReplaceMainResident.IWoningAPIReplaceMainResidentResultModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Woning/ReplaceMainResident`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<WoningAPIReplaceMainResident.IWoningAPIReplaceMainResidentResultModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ReplaceMainResident(pInputModel: WoningAPIReplaceMainResident.IWoningAPIReplaceMainResidentInputModel & {ToJsonContract(noContent?: boolean): WoningAPIReplaceMainResident.IWoningAPIReplaceMainResidentInputModel}): ng.IPromise<WoningAPIReplaceMainResident.IWoningAPIReplaceMainResidentResultModel> {
				let obs = this.ReplaceMainResident_Observable(pInputModel);
				let promise = new Promise<WoningAPIReplaceMainResident.IWoningAPIReplaceMainResidentResultModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SetMainResidentApproved_Observable(pWoningAPISetApprovedModel: WoningAPISetApproved.IWoningAPISetApproved & {ToJsonContract(noContent?: boolean): WoningAPISetApproved.IWoningAPISetApproved}): Observable<void> {
				const pWoningAPISetApproved = pWoningAPISetApprovedModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pWoningAPISetApproved;
				let apiURL = `api/Woning/SetMainResidentApproved/${pWoningAPISetApproved}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SetMainResidentApproved(pWoningAPISetApprovedModel: WoningAPISetApproved.IWoningAPISetApproved & {ToJsonContract(noContent?: boolean): WoningAPISetApproved.IWoningAPISetApproved}): ng.IPromise<void> {
				let obs = this.SetMainResidentApproved_Observable(pWoningAPISetApprovedModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetOudHoofdBewoners_Observable(pWoningAPIOudHoofdBewonersInputModelModel: WoningAPIHoofdBewoners.IWoningAPIOudHoofdBewonersInputModel & {ToJsonContract(noContent?: boolean): WoningAPIHoofdBewoners.IWoningAPIOudHoofdBewonersInputModel}): Observable<WoningAPIHoofdBewoners.IWoningAPIOudHoofdBewonersResultModel> {
				const pWoningAPIOudHoofdBewonersInputModel = pWoningAPIOudHoofdBewonersInputModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pWoningAPIOudHoofdBewonersInputModel;
				let apiURL = `api/Woning/GetOudHoofdBewoners/${pWoningAPIOudHoofdBewonersInputModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<WoningAPIHoofdBewoners.IWoningAPIOudHoofdBewonersResultModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetOudHoofdBewoners(pWoningAPIOudHoofdBewonersInputModelModel: WoningAPIHoofdBewoners.IWoningAPIOudHoofdBewonersInputModel & {ToJsonContract(noContent?: boolean): WoningAPIHoofdBewoners.IWoningAPIOudHoofdBewonersInputModel}): ng.IPromise<WoningAPIHoofdBewoners.IWoningAPIOudHoofdBewonersResultModel> {
				let obs = this.GetOudHoofdBewoners_Observable(pWoningAPIOudHoofdBewonersInputModelModel);
				let promise = new Promise<WoningAPIHoofdBewoners.IWoningAPIOudHoofdBewonersResultModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public WoningGroepen_Observable(projectid: number): Observable<AngularWoningGroepModel.IAngularWoningGroepModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Woning/WoningGroepen/${projectid}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AngularWoningGroepModel.IAngularWoningGroepModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public WoningGroepen(projectid: number): ng.IPromise<AngularWoningGroepModel.IAngularWoningGroepModel> {
				let obs = this.WoningGroepen_Observable(projectid);
				let promise = new Promise<AngularWoningGroepModel.IAngularWoningGroepModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}
