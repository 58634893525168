<div class="cms-block" style="display: flex;" *ngIf="!Loading">
  <div style="flex: auto;"></div>
  <div>
    <h1>Misschien ook interessant voor u</h1>

    <div style="display: flex;">
      <div class="cms-item-block" (click)="Open(FabrikantenCMSModel.Link1)">
        <div class="cms-image">
            <img class="object-fit-cover" [src]="FabrikantenCMSModel.Image1">
        </div>
        <div class="cms-title">
          {{ FabrikantenCMSModel.Title1 }}
        </div>
        <div class="cms-leesmeer">
          <br />
          <a href="{{ FabrikantenCMSModel.Link1 }}" target="_blank">Lees meer</a>
        </div>
      </div>
      <div class="cms-item-block" (click)="Open(FabrikantenCMSModel.Link2)">
        <div class="cms-image">
          <img class="object-fit-cover" [src]="FabrikantenCMSModel.Image2">
        </div>
        <div class="cms-title">
          {{ FabrikantenCMSModel.Title2 }}
        </div>
        <div class="cms-leesmeer">
          <br />
          <a href="{{ FabrikantenCMSModel.Link2 }}" target="_blank">Lees meer</a>
        </div>
      </div>
    </div>
  </div>
  <div style="flex: auto;"></div>
</div>
