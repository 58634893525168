// [conv] import "(./FabBestekMain).less";
import * as _ from 'lodash';
import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from '../../../services/GoogleAnalyticsService';
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
//import { ThemeService } from '../../shared/theme';

@Component({
  selector: 'wct-main',
  templateUrl: './fab-bestekservice.component.html'
})

export class FabBestekMainComponent {
  Header: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fab: FabrikantenConsumer,
    //private themeService: ThemeService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.Header = "Welkom fabrikant!";

    //this.SetTheme();

    // Google Analytics event
    let params: URLSearchParams = new URLSearchParams(window.location.search);
    let consent: boolean = (/true/i).test(params.get("consent"));
    this.googleAnalyticsService.setAnonymization(!consent);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.sendPageView(document.title, location.href, location.pathname);
      }
    });

    this.fab.GetProjectName_Observable().subscribe((data: any) => {
      var link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = '/Content/KlantSpecifiek/' + data.response + '/favicon.ico';

      // Get HTML head element
      var head = document.getElementsByTagName('HEAD')[0];

      // Create new link Element
      link = document.createElement('link');

      // set the attributes for link element 
      link.rel = 'stylesheet';

      link.type = 'text/css';

      link.href = '/Content/KlantSpecifiek/' + data.response + '/style.css';

      // Append link element to HTML head
      head.appendChild(link);
    });

    
  }
  public SetTheme() {
    this.fab.GetFabrikantName_Observable().subscribe((data) => {
      // Temp fix for typewriter not setting the body type to text (default: JSON)
      // Param data is now both a javascript object and a string.
      // Stringyfi and convert back to JSON. Ugly hack. 

      let jsonStr = JSON.stringify(data);
      let jsonObj = JSON.parse(jsonStr);

      //let theme: any = this.themeService.getTheme(jsonObj.response.toLowerCase());

      //this.themeService.setTheme(theme.name);
    });
  }
}
