/**
 * @ref WoonConnectViewModel/ContractorQualityViewModel.cs
 */

 import * as DataContracts from "@datacontracts/ContractorQualityViewModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ContractorQualityViewModel
	 */
	 

	export interface IContractorQualityViewModel {
		Id: number;
		ModelName: string;
		Description: string;
		Score: number;
		NoMeasures: boolean;
		ShowUpload: boolean;
		ToJsonContract(): DataContracts.IContractorQualityViewModel;
	}

	export class ContractorQualityViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ModelName(): string {
			return this._ModelName;
		}
		public set ModelName(newModelName: string) {
			this._ModelName = newModelName;
		}

		public get Description(): string {
			return this._Description;
		}
		public set Description(newDescription: string) {
			this._Description = newDescription;
		}

		public get Score(): number {
			return this._Score;
		}
		public set Score(newScore: number) {
			this._Score = newScore;
		}

		public get NoMeasures(): boolean {
			return this._NoMeasures;
		}
		public set NoMeasures(newNoMeasures: boolean) {
			this._NoMeasures = newNoMeasures;
		}

		public get ShowUpload(): boolean {
			return this._ShowUpload;
		}
		public set ShowUpload(newShowUpload: boolean) {
			this._ShowUpload = newShowUpload;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ModelName: string = "";
		protected _Description: string = "";
		protected _Score: number = 0;
		protected _NoMeasures: boolean = false;
		protected _ShowUpload: boolean = false;

		constructor(data?: DataContracts.IContractorQualityViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IContractorQualityViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ModelName = data.ModelName;
				this._Description = data.Description;
				this._Score = data.Score;
				this._NoMeasures = data.NoMeasures;
				this._ShowUpload = data.ShowUpload;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IContractorQualityViewModel {
			return {
				Id: this.Id,
				ModelName: this.ModelName,
				Description: this.Description,
				Score: this.Score,
				NoMeasures: this.NoMeasures,
				ShowUpload: this.ShowUpload
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ContractorQualitiesRequestModel
	 */
	 

	export interface IContractorQualitiesRequestModel {
		Temp: string;
		ToJsonContract(): DataContracts.IContractorQualitiesRequestModel;
	}

	export class ContractorQualitiesRequestModel {
		// property-Properties
		public get Temp(): string {
			return this._Temp;
		}
		public set Temp(newTemp: string) {
			this._Temp = newTemp;
		}

		// field-Properties

		// fields
		protected _Temp: string = "";

		constructor(data?: DataContracts.IContractorQualitiesRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IContractorQualitiesRequestModel): void {
			if(data) {
				// vul properties
				this._Temp = data.Temp;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IContractorQualitiesRequestModel {
			return {
				Temp: this.Temp
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ContractorQualitiesViewModel
	 */
	 

	export interface IContractorQualitiesViewModel {
		ContractorId: number;
		QualitiesNoMeasures: ContractorQualityViewModel[];
		ToJsonContract(): DataContracts.IContractorQualitiesViewModel;
	}

	export class ContractorQualitiesViewModel {
		// property-Properties
		public get ContractorId(): number {
			return this._ContractorId;
		}
		public set ContractorId(newContractorId: number) {
			this._ContractorId = newContractorId;
		}

		public get QualitiesNoMeasures(): ContractorQualityViewModel[] {
			return this._QualitiesNoMeasures;
		}
		public set QualitiesNoMeasures(newQualitiesNoMeasures: ContractorQualityViewModel[]) {
			this._QualitiesNoMeasures = newQualitiesNoMeasures;
		}

		// field-Properties

		// fields
		protected _ContractorId: number = 0;
		protected _QualitiesNoMeasures: ContractorQualityViewModel[] = [];

		constructor(data?: DataContracts.IContractorQualitiesViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IContractorQualitiesViewModel): void {
			if(data) {
				// vul properties
				this._ContractorId = data.ContractorId;
				this._QualitiesNoMeasures = data.QualitiesNoMeasures ? data.QualitiesNoMeasures.map(contractorQualityViewModelItem => new ContractorQualityViewModel(contractorQualityViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IContractorQualitiesViewModel {
			return {
				ContractorId: this.ContractorId,
				QualitiesNoMeasures: (this.QualitiesNoMeasures ? this.QualitiesNoMeasures.map(contractorQualityViewModelItem => new ContractorQualityViewModel(contractorQualityViewModelItem).ToJsonContract()): [])
			}
		}
	}
