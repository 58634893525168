    <div class="two-columns">
        <div class="first-column">
            <h3>Algemeeen</h3>
            <div class="two-columns">
                <div class="first-column">
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Project</mat-label>
                            <input matInput name="Project" [(ngModel)]="buildingDetails.Project.Naam" ngModel required min-length="4" #name="ngModel" disabled>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Id</mat-label>
                            <input matInput name="Id" [(ngModel)]="buildingDetails.Id" ngModel required min-length="4" #name="ngModel" disabled>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Recept</mat-label>
                            <input matInput name="Recept" [(ngModel)]="buildingDetails.Id" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Typelogie</mat-label>
                            <input matInput name="Typelogie" [(ngModel)]="buildingDetails.Typelogie" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Energiepakket</mat-label>
                            <input matInput name="Energiepakket" [(ngModel)]="buildingDetails.Id" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Status</mat-label>
                            <input matInput name="Status" [(ngModel)]="buildingDetails.Status" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>

                </div>
                <div class="second-column">
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Kavelbreedte</mat-label>
                            <input matInput name="Kavelbreedte" [(ngModel)]="buildingDetails.Kavelbreedte" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Kavel grondplaat</mat-label>
                            <input matInput name="KavelGrondPlaat" [(ngModel)]="buildingDetails.KavelGrondplaat" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Concepten</mat-label>
                            <input matInput name="Concepten" [(ngModel)]="buildingDetails.Id" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>
                    <!-- <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Kavelplaatje</mat-label>
                            <input matInput name="KavelPlaatje" [(ngModel)]="buildingDetails.KavelPlaatje" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p> -->
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>y-as tov Noord</mat-label>
                            <input matInput name="Concepten" [(ngModel)]="buildingDetails.LocatieY" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Kavel Locatie Top</mat-label>
                            <input matInput name="KavelPlaatjeLokatieTop" [(ngModel)]="buildingDetails.KavelPlaatjeLokatieTop" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Kavel locatie left</mat-label>
                            <input matInput name="KavelPlaatjeLokatieLeft" [(ngModel)]="buildingDetails.KavelPlaatjeLokatieLeft" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Kavel Locatie Bottom</mat-label>
                            <input matInput name="KavelLokatieBottom" [(ngModel)]="buildingDetails.KavelPlaatjeLokatieBottomLeft" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Kavel Locatie Right</mat-label>
                            <input matInput name="KavelLokatieRight" [(ngModel)]="buildingDetails.KavelPlaatjeLokatieBottomTop" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Kavel Locatie Path</mat-label>
                            <input matInput name="KavelLokatiePath" [(ngModel)]="buildingDetails.KavelPlaatjeLokatiePath" ngModel required min-length="4" #name="ngModel">
                        </mat-form-field>
                    </p>
                </div>
            </div>
         
          
        

        </div>
        <div class="second-column">
          
            <div class="two-columns">
                <div class="first-column">
                    <h3>Adres</h3>
                    <fieldset>
                        <p>
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>Straat</mat-label>
                                <input matInput name="Straat" [(ngModel)]="AddressModel.Straat" ngModel required min-length="4" #name="ngModel">
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>Nummer</mat-label>
                                <input matInput name="Nummer" [(ngModel)]="AddressModel.Nummer" ngModel required min-length="4" #name="ngModel">
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>Postcode</mat-label>
                                <input matInput name="Postcode" [(ngModel)]="AddressModel.Postcode" ngModel required min-length="4" #name="ngModel">
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>Plaats</mat-label>
                                <input matInput name="Plaats" [(ngModel)]="AddressModel.Plaats" ngModel required min-length="4" #name="ngModel">
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>Latitude</mat-label>
                                <input matInput name="Latitude" [(ngModel)]="AddressModel.Latitude" ngModel required min-length="4" #name="ngModel">
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>Longtitude</mat-label>
                                <input matInput name="Longtitude" [(ngModel)]="AddressModel.Longitude" ngModel required min-length="4" #name="ngModel">
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>Land</mat-label>
                                <input matInput name="Land" [(ngModel)]="AddressModel.Land" ngModel required min-length="4" #name="ngModel">
                            </mat-form-field>
                        </p>

                    </fieldset>
                    <div>
                        <button mat-raised-button color="success" type="submit" (click)="onAddressSave()">Adres wijzigen</button>
                    </div>

                </div>
                <div class="second-column">
                    <h3>Bewoners</h3>

                    <ul>
                        <li *ngFor="let resident of buildingDetails.Residents.Residences ">
                            <a [routerLink]="['/wct-admin/user-detail/', resident.Resident.Id]"> {{resident.Resident.AccountName}} - {{resident.Relation}} </a>
                        </li>
                    </ul>
                </div>
            </div>
          
            
           
        </div>
    </div>


