import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../utils/config.service';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { LogonConsumer } from '@consumers/LogonController';
import { LogonRequestModel } from '@models/LogonModel';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  getAuthorizationToken(): string {
    let token = localStorage.getItem('auth_token');
    if (token == null) {
      return null;
    }
    if (token == 'null') {
      return null;
    }
    try {
      let obj = JSON.parse(token);
      let authToken = obj.auth_token;
      return authToken;
    } catch (error) {
      console.error("Auth token parse error");
      return null;
    }
  }

  baseUrl: string = '';

  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();

  private loggedIn = false;

  constructor(private http: HttpClient, private configService: ConfigService, private logonconsumer: LogonConsumer,) {
    this.loggedIn = !!localStorage.getItem('auth_token');
    // ?? not sure if this the best way to broadcast the status but seems to resolve issue on page refresh where auth status is lost in
    // header component resulting in authed user nav links disappearing despite the fact user is still logged in
    this._authNavStatusSource.next(this.loggedIn);
    this.baseUrl = configService.getApiURI();
  }

  login(userName, password) {
    // let headers = new HttpHeaders();
    // headers.append('Content-Type', 'application/json');
    let req = new LogonRequestModel();
    req.Username = userName;
    req.Password = password;

    return this.logonconsumer.Logon_Observable(req).pipe(
      map((res) => {
        localStorage.setItem('auth_token', res.Token);
        this.loggedIn = true;
        this._authNavStatusSource.next(true);
        return res.LogonStatus;
      }))
    //.catch(this.handleError);
  }

  logout() {
    localStorage.removeItem('auth_token');
    this.loggedIn = false;
    this._authNavStatusSource.next(false);
  }

  isLoggedIn() {
    return this.loggedIn;
  }
}

