import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { MenuState, SubMenus } from './menu/menu.state';
import { selectMenu } from './menu/menu.actions';
import { MenuItem } from '../Models/MenuItem';

@Injectable({
    providedIn: 'root'
})
export class MenuSelectorService {
    showDocuments$: Observable<boolean>;
    showDwellings$: Observable<boolean>;
    showImageAnnotation$: Observable<boolean>;
    constructor (private menuStore: Store<{ menu: MenuState }>) {
        this.showDocuments$ = menuStore.select(s => s.menu.subMenu === SubMenus.Documenten);
        this.showDwellings$ = menuStore.select(s => s.menu.subMenu === SubMenus.BeheerWoningen);
        this.showImageAnnotation$ = menuStore.select(s => s.menu.subMenu === SubMenus.ImageAnnotationAdmin);
    }

    setSubMenu(menuItem: MenuItem) {
        let subMenu = SubMenus.Default;
        switch(menuItem.State) {
            case 'locatie': subMenu = SubMenus.Locatie;
                            break;
            case 'beheerwoningen': subMenu = SubMenus.BeheerWoningen;
                            break;
            case 'model': subMenu = SubMenus.Model;
                            break;
            case 'scenario': subMenu = SubMenus.Scenario;
                            break;
            case 'kosten': subMenu = SubMenus.Kosten;
                            break;
            case 'maatwerkadviesrapport': subMenu = SubMenus.MaatwerkAdviesRapport;
                            break;
            case 'documenten': subMenu = SubMenus.Documenten;
                            break;
            case 'optiebord': subMenu = SubMenus.Optiebord;
                            break;
            case 'districtdirectormanagement': subMenu = SubMenus.DistrictDirectorManagement;
                            break;
            case 'analytics': subMenu = SubMenus.Analytics;
                            break;
            case 'imageannotationbeheer': subMenu = SubMenus.ImageAnnotationAdmin;
                            break;
        };
        this.menuStore.dispatch(selectMenu({subMenu: subMenu}));
    }
}