import * as DataContractsWijzigEvent from '@datacontracts/WijzigEvent';
import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';
import * as ServicesWoningService from '.././services/WoningService';



	export class WoningSelectie {
		public WoningGroepBreadcrumb: ServicesWoningService.IBreadcrumbSelectie[];
		public Actievegroep: EnumsWijzigScopeEnum.WijzigScopeEnum;

		Copy(): WoningSelectie
		{
			let ret = new WoningSelectie();
			ret.WoningGroepBreadcrumb = this.WoningGroepBreadcrumb;
			ret.Actievegroep = this.Actievegroep;
			return ret;
		}

		public GeefType(t: EnumsWijzigScopeEnum.WijzigScopeEnum): ServicesWoningService.IBreadcrumbSelectie {
			for (let i = 0; i < this.WoningGroepBreadcrumb.length; i++) {
				if (this.WoningGroepBreadcrumb[i].GeefGroepTypeNum() === t) {
					return this.WoningGroepBreadcrumb[i];
				}
			}

			return null;
		}
	}

	export class GlobaleScopeSelectie {
		public IsVeranderd = true;

		constructor(
			public WijkId: number,
			public BlokId: number,
			public WoningId: number,
			public ScenarioId: number) {
		}

		public EqualTo(andere: GlobaleScopeSelectie): boolean {
			return this.WijkId === andere.WijkId &&
				this.BlokId === andere.BlokId &&
				this.ScenarioId === andere.ScenarioId &&
				this.WoningId === andere.WoningId;
		}
	}

	export class KiesNieuweWoningEventArgs {
		public GlobaleScopeSelectie: GlobaleScopeSelectie;
		public Event: DataContractsWijzigEvent.IWijzigEvent;
	}

	export enum WijzigBron
	{
		Opstarten,
		WoningWissel,
		ScopeWissel,
		ScenarioWissel,
		OptieAangepast
	}

	export class CentraleToestandGewijzigdEventArgs {
		public GlobaleScopeSelectie: GlobaleScopeSelectie;
		public Event: DataContractsWijzigEvent.IWijzigEvent;
		public WijzigBron: WijzigBron;
	}

