/// <reference path='../consumerinterfaces/DocumentenController.ts' />
/**
 * @ref WoonConnectApiController/Documenten/DocumentenController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/DocumentenController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as GeefDocumentenInputModel from "@datacontracts/GeefDocumentenInputModel";
import * as GeefDocumentenResultaatModel from "@datacontracts/GeefDocumentenResultaatModel";
import * as DocumentMapModel from "@datacontracts/DocumentMapModel";
import * as DocumentModel from "@datacontracts/DocumentModel";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class DocumentenConsumer implements Interface.IDocumentenConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public GeefDocumentenModel_Observable(inputModel: GeefDocumentenInputModel.IGeefDocumentenInputModel & {ToJsonContract(noContent?: boolean): GeefDocumentenInputModel.IGeefDocumentenInputModel}): Observable<GeefDocumentenResultaatModel.IGeefDocumentenResultaatModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/Documenten/GeefDocumentenModel/${input}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GeefDocumentenResultaatModel.IGeefDocumentenResultaatModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefDocumentenModel(inputModel: GeefDocumentenInputModel.IGeefDocumentenInputModel & {ToJsonContract(noContent?: boolean): GeefDocumentenInputModel.IGeefDocumentenInputModel}): ng.IPromise<GeefDocumentenResultaatModel.IGeefDocumentenResultaatModel> {
				let obs = this.GeefDocumentenModel_Observable(inputModel);
				let promise = new Promise<GeefDocumentenResultaatModel.IGeefDocumentenResultaatModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefDocumentMapModel_Observable(pMapId: number): Observable<DocumentMapModel.IDocumentMapModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Documenten/GeefDocumentMapModel/${pMapId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<DocumentMapModel.IDocumentMapModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefDocumentMapModel(pMapId: number): ng.IPromise<DocumentMapModel.IDocumentMapModel> {
				let obs = this.GeefDocumentMapModel_Observable(pMapId);
				let promise = new Promise<DocumentMapModel.IDocumentMapModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefOffertesDocumentMapModel_Observable(): Observable<DocumentMapModel.IDocumentMapModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Documenten/GeefOffertesDocumentMapModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<DocumentMapModel.IDocumentMapModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefOffertesDocumentMapModel(): ng.IPromise<DocumentMapModel.IDocumentMapModel> {
				let obs = this.GeefOffertesDocumentMapModel_Observable();
				let promise = new Promise<DocumentMapModel.IDocumentMapModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefContractorDocumentMapModel_Observable(): Observable<DocumentMapModel.IDocumentMapModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Documenten/GeefContractorDocumentMapModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<DocumentMapModel.IDocumentMapModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefContractorDocumentMapModel(): ng.IPromise<DocumentMapModel.IDocumentMapModel> {
				let obs = this.GeefContractorDocumentMapModel_Observable();
				let promise = new Promise<DocumentMapModel.IDocumentMapModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefDocumentModel_Observable(pId: number): Observable<DocumentModel.IDocumentModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Documenten/GeefDocumentModel/${pId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<DocumentModel.IDocumentModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefDocumentModel(pId: number): ng.IPromise<DocumentModel.IDocumentModel> {
				let obs = this.GeefDocumentModel_Observable(pId);
				let promise = new Promise<DocumentModel.IDocumentModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public OpslaanDocumentMapModel_Observable(pDocumentMapModelModel: DocumentMapModel.IDocumentMapModel & {ToJsonContract(noContent?: boolean): DocumentMapModel.IDocumentMapModel}): Observable<boolean> {
				const pDocumentMapModel = pDocumentMapModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pDocumentMapModel;
				let apiURL = `api/Documenten/OpslaanDocumentMapModel/${pDocumentMapModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public OpslaanDocumentMapModel(pDocumentMapModelModel: DocumentMapModel.IDocumentMapModel & {ToJsonContract(noContent?: boolean): DocumentMapModel.IDocumentMapModel}): ng.IPromise<boolean> {
				let obs = this.OpslaanDocumentMapModel_Observable(pDocumentMapModelModel);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public OpslaanDocumentModel_Observable(pDocumentModelModel: DocumentModel.IDocumentModel & {ToJsonContract(noContent?: boolean): DocumentModel.IDocumentModel}): Observable<boolean> {
				const pDocumentModel = pDocumentModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pDocumentModel;
				let apiURL = `api/Documenten/OpslaanDocumentModel/${pDocumentModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public OpslaanDocumentModel(pDocumentModelModel: DocumentModel.IDocumentModel & {ToJsonContract(noContent?: boolean): DocumentModel.IDocumentModel}): ng.IPromise<boolean> {
				let obs = this.OpslaanDocumentModel_Observable(pDocumentModelModel);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public VerwijderDocumentMapModel_Observable(pDocumentMapId: number): Observable<boolean> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Documenten/VerwijderDocumentMapModel/${pDocumentMapId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public VerwijderDocumentMapModel(pDocumentMapId: number): ng.IPromise<boolean> {
				let obs = this.VerwijderDocumentMapModel_Observable(pDocumentMapId);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public VerwijderDocument_Observable(pDocumentId: number): Observable<boolean> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Documenten/VerwijderDocument/${pDocumentId}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public VerwijderDocument(pDocumentId: number): ng.IPromise<boolean> {
				let obs = this.VerwijderDocument_Observable(pDocumentId);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GenereerWoningMappenStructuur_Observable(): Observable<boolean> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Documenten/GenereerWoningMappenStructuur`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GenereerWoningMappenStructuur(): ng.IPromise<boolean> {
				let obs = this.GenereerWoningMappenStructuur_Observable();
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GenereerPriveMappenStructuur_Observable(): Observable<boolean> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Documenten/GenereerPriveMappenStructuur`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GenereerPriveMappenStructuur(): ng.IPromise<boolean> {
				let obs = this.GenereerPriveMappenStructuur_Observable();
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GenereerOfferteMappenStructuur_Observable(): Observable<boolean> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Documenten/GenereerOfferteMappenStructuur`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GenereerOfferteMappenStructuur(): ng.IPromise<boolean> {
				let obs = this.GenereerOfferteMappenStructuur_Observable();
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GenereerIngediendeOptiesMappenStructuur_Observable(): Observable<boolean> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Documenten/GenereerIngediendeOptiesMappenStructuur`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GenereerIngediendeOptiesMappenStructuur(): ng.IPromise<boolean> {
				let obs = this.GenereerIngediendeOptiesMappenStructuur_Observable();
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GenereerAlgemeneDocumentenStructuur_Observable(): Observable<boolean> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Documenten/GenereerAlgemeneDocumentenStructuur`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GenereerAlgemeneDocumentenStructuur(): ng.IPromise<boolean> {
				let obs = this.GenereerAlgemeneDocumentenStructuur_Observable();
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GenereerPersoonsGebondenMappenStructuur_Observable(): Observable<boolean> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Documenten/GenereerPersoonsGebondenMappenStructuur`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GenereerPersoonsGebondenMappenStructuur(): ng.IPromise<boolean> {
				let obs = this.GenereerPersoonsGebondenMappenStructuur_Observable();
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UploadHttp_Observable(MapId: number, persoonId: number = null): Observable<boolean> {
				let operation = this.m_Operation++;
				let consumerdata = MapId;
				let apiURL = `api/Documenten/UploadDocumenten`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UploadHttp(MapId: number, persoonId: number = null): ng.IPromise<boolean> {
				let obs = this.UploadHttp_Observable(MapId, persoonId);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


