//import * as _ from 'lodash';
import { Component, OnInit, Inject, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
//import {  TranslateService } from '../../../infrastructure/ajs-upgraded-providers';
import { WoningService } from '../../../services/WoningService';
import { AccountService } from '../../../services/AccountService';
import { WoningEventService } from '../../../services/WoningEventService';
import { IAccountService } from '../../../services/AccountService';
import { IWoningService } from '../../../services/WoningService';
import { IWoningEventService } from '../../../services/WoningEventService';
import { TranslateService, ITranslateService } from '../../../services/TranslateService';
//import { ConstSoftware } from '../../../Helpers/Backwards';
import { AppConfigService } from '../../../infrastructure/app.configservice';
import { ResidentModelConsumer } from '@consumers/ResidentModelController';
import { SetResidentModelRequestModel } from '@models/SetResidentModel';
import { GetResidentModelRequestModel, ResidentModel } from '@models/GetResidentModel';

class ViewModel
{

}

@Component({
	selector: 'wct-account-settings',
	styleUrls: ['./WctAccountSettings.scss'],
	templateUrl: './WctAccountSettings.html'
})

export class WctAccountSettings {
	//public LoginName: string = "";
	//public ShowDropdown: boolean = false;
	@Input() public activeTab: string;

	@Output() public onCloseAccountSettings = new EventEmitter<any>();

	public wachtwoordLengte: number = this.appconfigservice.Software.MinimaleWachtwoordLengte;
	public Wachtwoord: string = "";
	public BevestigWachtwoord: string = "";
	public HuidigeWachtwoord: string = "";
	public WachtwoordBevestigingTekst: string = "";
	public ErrorTonen: boolean = false;

	//public melding: string;
	//public existingAccount: boolean = true;
	//public textHeader: string = "";
	public Geslacht: string = "Man";
	public Voorletters: string = "";
	public Voornaam: string = "";
	public Tussenvoegsel: string = "";
	public Achternaam: string = "";
	public Geboortedatum: string = "";
	public TelefoonNummer: string = "";
	public Emailadres: string = "";
	public BevestigEmailadres: string = "";
	//public Wachtwoord: string = "";
	//public BevestigWachtwoord: string = "";
	public Straat: string = "";
	public Nummer: string = "";
	public Postcode: string = "";
	public Plaats: string = "";
	public BezigMetLaden: boolean = true;
	public ToonActivatieFormulierHulp: boolean = false;
	public BevestigWachtwoordTekst: string = "";
	public EmailadresHulpTekst: string = "";
	//public NieuwAccountFoutmelding: string = "";
	public AdresReadOnly: boolean = false;
	public VoornaamReadOnly: boolean = false;
	public AchternaamReadOnly: boolean = false;
	public email_verplicht: boolean = true;
	public telefoon_verplicht: boolean = true;
	public TelefoonnummerFout: boolean = false;
	public Bedrijfsnaam: string = "";
	public isDC: boolean = false;

	constructor(
		private http: HttpClient,
		@Inject(AccountService) private accountservice: IAccountService,
		@Inject(TranslateService) private translateservice: ITranslateService,
		@Inject(WoningService) private woningservice: IWoningService,
		@Inject(WoningEventService) private woningeventservice: IWoningEventService,
		private residentmodelconsumer: ResidentModelConsumer,
		private appconfigservice: AppConfigService
	) {
		'ngInject';

		if (this.HasRole("Huurder") || this.HasRole("Eigenaar")) {
			this.LoadAccountManagementSettings();
		}
		if (this.appconfigservice.Software.SceneLayoutStategie == "DC") {
			this.isDC = true;
		}
	}

	public HasRole(role: string) {
		let ret: boolean = false;
		if (this.accountservice.HeeftRol(role)) {
			ret = true;
		}
		return ret;
	}

	public LoadAccountManagementSettings() {
		this.email_verplicht = this.appconfigservice.Software.ActivatieEmailVerplicht;
		this.telefoon_verplicht = this.appconfigservice.Software.ActivatieTelefoonnummerVerplicht;
		this.AdresReadOnly = !this.appconfigservice.Software.IsVerkoopsite && !(this.appconfigservice.Software.SceneLayoutStategie == "DC");
		this.VoornaamReadOnly = !this.appconfigservice.Software.ActivatieVoornaamAanpasbaar;
		this.AchternaamReadOnly = !this.appconfigservice.Software.ActivatieAchternaamAanpasbaar;

		//this.textHeader = this.translateservice.GetTranslation("SETTINGS", "ACCOUNT-MANAGEMENT");

		var input: any = {};
		input.pPersoonId = this.accountservice.GeefHuidigPersoonID();
		var req = new GetResidentModelRequestModel();
		this.residentmodelconsumer.GetResident_Observable(req).subscribe(resp  => {
			let data = resp.ResidentModel;

			if (data.Geslacht == "1") {
				this.Geslacht = "Vrouw";
			}
			else {
				this.Geslacht = "Man";
			}

			this.Voorletters = data.Voorletters;
			this.Voornaam = data.Voornaam;
			this.Tussenvoegsel = data.Tussenvoegsel;
			this.Achternaam = data.Achternaam;
			this.Geboortedatum = data.GeboorteDatum;
			this.TelefoonNummer = data.Telefoonnummer;
			this.Emailadres = data.Email;
			this.Straat = data.Straat;
			this.Nummer = data.Nummer;
			this.Postcode = data.Postcode;
			this.Plaats = data.Plaats;
			this.Emailadres = data.Email;
			this.Bedrijfsnaam = data.Bedrijfsnaam;

			//if ((this.AdresReadOnly) && ((this.Straat == "") && (this.Nummer == "") && (this.Postcode == "") && (this.Plaats == ""))) {
				// this.woningservice.GeefBeheerWoning(this.woningeventservice.GeefHuidigeWoningID()).then(response => {
				// 	this.Straat = response.AdresStraat;
				// 	this.Nummer = response.AdresNummer;
				// 	this.Postcode = response.Postcode;
				// 	this.Plaats = response.Plaats;
				// 	this.BezigMetLaden = false;
				// });
			// } else {
			// 	this.BezigMetLaden = false;
			// }
			this.BezigMetLaden = false;
		});
	}

	public CloseAccountSettings(): void {
		this.onCloseAccountSettings.emit();
	}

	public NavigateToTab(tab: string): void {
		this.ErrorTonen = false;
		this.activeTab = tab;
	}

	public SaveNewPassword(): void {
		if (!this.Controleer()) {
			this.accountservice.WachtwoordVeranderenMetOudWachtwoord(this.HuidigeWachtwoord, this.Wachtwoord).then(a => {
				if (a == "") {
					//this.$mdDialog.hide();
				}
				else {
					this.WachtwoordBevestigingTekst = a;
					this.ErrorTonen = true;
				}
			});
		}
	}

	public Controleer(): boolean {
		if (this.HuidigeWachtwoord == this.Wachtwoord) {
			this.WachtwoordBevestigingTekst = "Uw nieuwe wachtwoord is hetzelfde als uw huidige wachtwoord.";
			this.ErrorTonen = true;
			return true;
		}
		if (this.HuidigeWachtwoord.length < this.wachtwoordLengte) {
			this.WachtwoordBevestigingTekst = "Vul eerst uw wachtwoord nogmaals in (minimaal " + this.wachtwoordLengte + " karakters lang)";
			this.ErrorTonen = true;
			return true;
		} else if (this.BevestigWachtwoord.length < this.wachtwoordLengte) {
			this.WachtwoordBevestigingTekst = "Vul eerst uw wachtwoord nogmaals in (minimaal " + this.wachtwoordLengte + " karakters lang)";
			this.ErrorTonen = true;
			return true;
		} else if (this.Wachtwoord != this.BevestigWachtwoord) {
			this.WachtwoordBevestigingTekst = "De twee ingevoerde wachtwoorden komen niet overeen";
			this.ErrorTonen = true;
			return true;
		} else {
			this.ErrorTonen = false;
			return false;
		}
	}

	public SaveAccountSettings() {
		this.ToonActivatieFormulierHulp = true;

		//if (((this.Emailadres != undefined) && (this.EmailadresHulp() == false) && (this.BevestigEmailadresHulp() == false) && (this.WachtwoordHulp() == false) && (this.BevestigWachtwoordHulp() == false))) {
		if ((this.Emailadres != undefined) && (this.Wachtwoord != undefined)) {
			this.SaveAccountSettingsAfterCheck();
		} else {
			console.log("Error: nu zijn niet alle velden ingevuld of er is iets fout gegaan");
		}
	}

	public SaveAccountSettingsAfterCheck(): void {

		var input: ResidentModel = new ResidentModel();

		input.PersoonId = this.accountservice.GeefHuidigPersoonID();
		input.Geslacht = this.Geslacht;
		//input.Voornaam = this.Voornaam;
		//input.Voorletters = this.Voorletters;
		input.Tussenvoegsel = this.Tussenvoegsel;
		//input.Achternaam = this.Achternaam;
		input.GeboorteDatum = this.Geboortedatum;

		input.Telefoonnummer = this.TelefoonNummer;

		this.TelefoonnummerFout = false;
		if (this.telefoon_verplicht) {
			if ((this.TelefoonNummer == "") || (this.TelefoonNummer == undefined)) {
				this.TelefoonnummerFout = true;
				return;
			}
		}

		if (this.email_verplicht) {
			input.Email = this.Emailadres;
			//input.BevestigEmailadres = this.BevestigEmailadres;
		} else {
			input.Email = this.Emailadres;
			//input.BevestigEmailadres = this.Emailadres;
		}
		//input.Wachtwoord = this.Wachtwoord;
		//input.BevestigWachtwoord = this.BevestigWachtwoord;
		input.Straat = this.Straat;
		input.Nummer = this.Nummer;
		input.Postcode = this.Postcode;
		input.Bedrijfsnaam = this.Bedrijfsnaam;
		//input.Plaats = this.Plaats;

		//tijdelijke oplossing:
		if (this.Voorletters !== "") {
			input.Voorletters = this.Voorletters;
		} else {
			input.Voorletters = " ";
		}
		if (this.Voornaam !== "") {
			input.Voornaam = this.Voornaam;
		} else {
			input.Voornaam = " ";
		}
		if (this.Achternaam !== "") {
			input.Achternaam = this.Achternaam;
		} else {
			input.Achternaam = " ";
		}
		if (this.Plaats !== "") {
			input.Plaats = this.Plaats;
		} else {
			input.Plaats = " ";
		}

		var setBewoner = new SetResidentModelRequestModel();
		setBewoner.ResidentModel = input;

		this.residentmodelconsumer.SetResident_Observable(setBewoner).subscribe(x =>
			{

			});

		// this.http.post("/Configurator/OpslaanPersoon", input).subscribe(response => {
		// });
		//if (this.oorzaak != "nieuwaccount") {
		//	this.OpslaanGegevens(input, false);
		//} else {

		//this.accountservice.DemoUpgrade(input).then(b => {
		//	if (b == "Gelukt") {
		//		this.OpslaanGegevens(input, true);
		//	}
		//	else {
		//		this.NieuwAccountFoutmelding = b;

		//		this.$mdDialog.show({
		//			locals: { oorzaak: this.NieuwAccountFoutmelding },
		//			clickOutsideToClose: true,
		//			//fullscreen: this.customFullscreen,
		//			templateUrl: '/app/components/account/WctAccountVerkoopBevestigingPopup.html',
		//			controller: () => this, //AccountVerkoopPopupController,
		//			controllerAs: '$ctrl',
		//			onRemoving: () => {
		//			}
		//		});
		//	}
		//});
		//}
	}

	public EmailadresHulp(): boolean {
		let inputCheck = false;
		if (this.Emailadres !== undefined) {
			if ((this.Emailadres.length === 0) && (this.ToonActivatieFormulierHulp === true)) {
				this.EmailadresHulpTekst = "Vul eerst je e-mail adres in";
				return true;
			}
		}
		return inputCheck;
	}

	public BevestigEmailadresHulp(): boolean {
		let inputCheck = false;
		if (this.Emailadres !== undefined) {
			if ((this.Emailadres.length > 0) && (this.Emailadres != this.BevestigEmailadres)) {
				return true;
			}
		}
		return inputCheck;
	}

	public WachtwoordHulp(): boolean {
		let inputCheck = false;
		if (this.Wachtwoord !== undefined) {
			if ((this.Wachtwoord.length === 0) && (this.ToonActivatieFormulierHulp === true)) {
				return true;
			}
		}
		return inputCheck;
	}

	public BevestigWachtwoordHulp(): boolean {
		let inputCheck = false;
		if (this.Wachtwoord !== undefined) {
			if ((this.Wachtwoord.length > 0) && (this.Wachtwoord != this.BevestigWachtwoord)) {
				this.BevestigWachtwoordTekst = "De ingevoerde wachtwoorden komen niet overeen";
				return true;
			}
		}
		return inputCheck;
	}
}
