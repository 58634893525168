<div *ngIf="startscherm && ToegestaneBrowser()" class="cont-startscherm-basisversie" [ngClass]="startschermGereed? 'cont-startscherm-basisversie-gereed':''">
	<div class="cont-startscherm-login-total" [ngStyle]="{'background-image':'url(' + GetStartschermAchtergrond('startpagina-achtergrond') + ')'}" [ngClass]="{'cont-startscherm-login-total-met-grote-achtergrondafbeelding': ToonLoginPaginaMetGroteAchtergrondafbeelding}">
    <section class="cont-header">
      <img src="/Content/Images/configurator/Smart-Twin-logo.svg" />
    </section>

		<section class="start-cont-section-login">
			<div *ngIf="!ToonLoginPaginaMetGroteAchtergrondafbeelding" class="cont-intro-totaal">
				<div class="cont-intro-txt-totaal">
					<div class="cont-intro-txt" [innerHtml]="AlgemeneIntrotekst"></div>
				</div>
				<!-- <div class="cont-img" *ngIf="urlStartschermGrootLogoVanKlant"> -->
        <div class="cont-img-left-section">
					<img [src]="urlStartschermAfbeelding" />
				</div>
			</div>

			<div class="cont-activeren-en-inloggen" [ngClass]="{'cont-activeren-en-inloggen-volledige-breedte' : ToonLoginPaginaMetGroteAchtergrondafbeelding}">
				<div class="cont-inloggen" *ngIf="startschermLogin">
					<div [hidden]="urlStartschermGrootLogoVanKlant == ''" class="cont-img">
						<img id="startschermgrootlogovanklant" class="img-groot-logo-van-klant" [src]="urlStartschermGrootLogoVanKlant" />
					</div>
					<p class="txt-title">Inloggen</p>
					<wct-bezig [bezigmodel]="BezigViewModel">
						<wct-login [(parentMelding)]="melding" [(parentStartschermLogin)]="startschermLogin" [(parentStartschermActivatie)]="startschermActivatie" titelTonen="false" (toggleloaderChange)="ToggleLoader()"></wct-login>
						<div *ngIf="FormulierActivatieIsBeschikbaar" class="cont-of">
							<p>Moet uw account nog worden geactiveerd?</p>
						</div>
						<wct-button *ngIf="FormulierActivatieIsBeschikbaar" (click)="ToonStartschermActivatie()" class="btn-activeren" buttonText="Activeer" buttonSize="small" buttonAlign="center" buttonRole="primary" tabindex="5"></wct-button>
					</wct-bezig>
				</div>
				<div class="cont-activeren" *ngIf="startschermActivatie">
					<p class="txt-title">Activeren</p>
					<ul>
						<li>Vul hieronder uw code in en klik op ‘Activeer’.</li>
						<li>Vul uw gegevens in en maak een eigen wachtwoord.</li>
						<li>U krijgt een e-mail: klik op de link.</li>
						<li>Log in met uw eigen e-mailadres en wachtwoord.</li>
					</ul>
					<wct-activatie-sleutel (activationKeyChanged)="receiveActivationKey($event)"></wct-activatie-sleutel>
					<p class="txt-main" [innerHtml]="UitlegActiverentekst">
						<!--Deze website is speciaal voor woningeigenaren die een persoonlijke code hebben ontvangen van de gemeente.
						Het gebruik van de website is gratis.-->
					</p>
					<wct-button (click)="GebruikActivatieSleutel()" buttonText="Activeer" buttonSize="small" buttonAlign="center" buttonRole="primary" class="btn-volgende"></wct-button>
					<div class="cont-of">
						<p>Is uw account al geactiveerd?</p>
					</div>
					<wct-button (click)="ToonStartschermLogin()" class="btn-inloggen" buttonText="Log in" buttonSize="small" buttonAlign="center" buttonRole="primary"></wct-button>
				</div>
			</div>
		</section>

		<section class="cont-footer">
			<p>{{ 'HOMEPAGE_THIS-WEBSITE-IS-POWERED-BY-WOONCONNECT-ON-BEHALF-OF' | translate }} {{NaamVanOpdrachtgever}}</p>
			<a (click)="ToonDisclaimer()" class="link-disclaimer">{{ 'HOMEPAGE_DISCLAIMER' | translate }}</a>
		</section>
	</div>
</div>

<div *ngIf="!ToegestaneBrowser()">
	<div style="width: 400px; text-align: center;">
		Internet Explorer 10 wordt op dit moment niet ondersteund.

		Om gebruik te kunnen maken van WoonConnect verzoeken wij u vriendelijk om een van onderstaande browsers te gebruiken. Indien u nog niet in het bezit bent van een van deze browsers kunt u deze hier downloaden en naast uw bestaande browser installeren.
		<br /><br />
		<div style="text-align: center;">
			<table cellpadding="10" style="text-align: center;">
				<tr>
					<td style="padding: 10px;"><a href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank"><img src="/Content/Images/chrome.png" alt="Chrome" /></a></td>
					<td style="padding: 10px;"><a href="https://www.mozilla.org/nl/firefox/new/" target="_blank"><img src="/Content/Images/firefox.png" alt="Firefox" /></a></td>
				</tr>
				<tr>
					<td style="padding: 10px;"><a href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank">Google Chrome</a></td>
					<td style="padding: 10px;"><a href="https://www.mozilla.org/nl/firefox/new/" target="_blank">Mozilla Firefox</a></td>
				</tr>
			</table>
		</div>
	</div>
</div>
