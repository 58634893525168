// [conv] import "(./FabAdminProduct).less";
import { Component, Input, Output } from '@angular/core';
import * as _ from 'lodash';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductsConsumer } from '@consumers/ProductsController';
import { ProductViewModel, ProductRequestModel, ProductVariantModel, ProductPropertyRelationsViewModel, ProductPropertyTypesViewModel, ProductPropertyTypeViewModel, ProductPropertyViewModel, ProductPropertySaveModel, ProductPropertiesViewModel, DeleteRequestModel } from '@models/ProductsViewModel';
import { FiltersConsumer } from '@consumers/FiltersController';

export interface ProductDetailTable {
  Id: number;
  Position: number;
  Name: string;
  Kenmerk: string;
  Bot: string;
  DO1Type: string;
  DO1Name: string;
  DO2Type: string;
  DO2Name: string;
  DO3Type: string;
  DO3Name: string;
  DO4Type: string;
  DO4Name: string;
}

@Component({
  selector: 'product',
  templateUrl: './FabAdminProduct.html',
  styleUrls: ['./FabAdminProduct.scss']
})
export class FabAdminProductComponent {
  Laden: boolean = true;
  Importing: boolean = false;
  update: boolean = true;
  variantFilter: boolean = false;
  editRowNumber: number = 0;
  totalVariants: number = 0;
  //variant
  fVariant: ProductVariantModel = new ProductVariantModel();
  newVariant: ProductVariantModel = new ProductVariantModel();
  ProductViewModel: ProductViewModel;
  showMe: boolean = false;
  showProductPropertyRelation: boolean = true;
  DeleteId: number;
  @Output() ProductId: number;
  @Output() VariantId: number;
  updateProduct: ProductViewModel = new ProductViewModel();
  ProductRequestModel: ProductRequestModel;
  ProductPropertySaveModel: ProductPropertySaveModel;
  ProductPropertyRelationsViewModel: ProductPropertyRelationsViewModel = new ProductPropertyRelationsViewModel();
  ProductProperty: ProductPropertyViewModel = new ProductPropertyViewModel();
  ProductProperties: ProductPropertiesViewModel = new ProductPropertiesViewModel();
  ProductPropertyType: ProductPropertyTypeViewModel = new ProductPropertyTypeViewModel();
  ProductPropertyTypesViewModel: ProductPropertyTypesViewModel = new ProductPropertyTypesViewModel();
  DeleteRequestModel: DeleteRequestModel;
  ImportIndex: number;

  constructor(private route: ActivatedRoute, private prod: ProductsConsumer, private filter: FiltersConsumer, public router: Router) {
    this.ProductId = parseInt((<any>this.route.params).value.productid);

    this.Refresh();
  }

  public Refresh(): void {
    var input = new ProductRequestModel();
    input.Id = this.ProductId;

    this.prod.GetProduct_Observable(input).subscribe((data) => {

      this.ProductViewModel = new ProductViewModel(data);
      console.log(this.ProductViewModel);
      this.updateProduct = new ProductViewModel(data);

      this.totalVariants = this.ProductViewModel.ProductVariant.length;

      this.Laden = false;
    });

    this.filter.GetProductFilterRelation_Observable(input).subscribe((data) => {

    });

    //get all product porerties 
    this.ProductProperties = new ProductPropertiesViewModel();
    //this.prod.GetAllProperties_Observable(this.ProductProperties).subscribe((data) => {
    //    this.ProductProperties = new ProductPropertiesViewModel(data); 

    //});


    this.prod.GetProductPropertyRelation_Observable(input).subscribe((data) => {
      //check if the ProductPropertyRelationsViewModel waarde heeft. 

      this.ProductPropertyRelationsViewModel = new ProductPropertyRelationsViewModel(data);


    });

    this.ProductPropertyTypesViewModel = new ProductPropertyTypesViewModel();

    this.prod.GetAllProductPropertyTypes_Observable(this.ProductPropertyTypesViewModel).subscribe((data) => {

      this.ProductPropertyTypesViewModel = new ProductPropertyTypesViewModel(data)
    })
  }

  public ResetImportFlags() {
    this.Laden = true;

    this.prod.ResetImportFlags_Observable(this.ProductId, -1).subscribe((data) => {
      this.Refresh();
    });
  }

  public Update() {
    this.prod.AddProduct_Observable(this.updateProduct).subscribe((data) => {
      console.log(data);
    });
    this.Refresh;
    this.update = !this.update
  }

  public Herlaad() {
    this.Refresh();
  }

  public showVariant() {
    this.showMe = !this.showMe;
  }

  public Import() {
    this.ImportIndex = 0;
    this.Importing = true;

    this.DoImportIndex();
  }

  public DoImportIndex() {

    if (this.ImportIndex < this.ProductViewModel.ProductVariant.length) {
      this.ProductViewModel.ProductVariant[this.ImportIndex].Import = "Bezig";

      this.prod.ImportVariant_Observable(this.ProductViewModel.ProductVariant[this.ImportIndex]).subscribe((data) => {
        this.ProductViewModel.ProductVariant[this.ImportIndex].Import = "Klaar";
        this.ImportIndex++;
        this.DoImportIndex();
      }, error => {
        alert("mislukt variantid");
      });
    }
  }

  changeFilter(variantId) {
    this.variantFilter = true;
    this.VariantId = variantId;

    console.log(variantId);
  }


  public Delete() {
    console.log(this.ProductId);
    //create link to delete product.
    this.prod.DeleteProduct_Observable(this.ProductId).subscribe((data) => {

      console.log(data);
      this.router.navigate(['/fab-admin/products']);

    });

  }

  public deleteProperty(id) {
    this.DeleteRequestModel = new DeleteRequestModel();

    this.DeleteRequestModel.Id = id;
    this.DeleteRequestModel.LinkedId = this.ProductId;

    alert('todo');
    // this.prod.DeleteProductPropertyProduct_Observable(this.DeleteRequestModel).subscribe((data) => {
    //    console.log(data);
    //    this.Refresh();
    // })
  }
  editRow(variant) {
    this.editRowNumber = variant.Id;
    console.log(variant);
    this.fVariant = new ProductVariantModel(variant);


  }

  saveRow() {
    this.prod.SaveVariant_Observable(this.fVariant).subscribe((data) => {
      console.log(data);
      this.editRowNumber = 0;
      this.Refresh();
    });
  }

  addVariant() {
    this.newVariant.ProductId = this.ProductId;
    this.prod.SaveVariant_Observable(this.newVariant).subscribe((data) => {
      console.log(data);
      this.Refresh();
    });
  }

  deleteVariant(id) {
    console.log(id);
    //create link to delete product.
    this.prod.DeleteVariant_Observable(id).subscribe((data) => {
      this.editRowNumber = 0;
      this.Refresh();
    });
  }

  //PRODUCT PROPERTY
  public saveProductProperty() {

    if (this.ProductProperty.Id < 1) {
      //nieuwe product property aanmaken. 
      this.ProductPropertySaveModel = new ProductPropertySaveModel();
      this.ProductPropertySaveModel.ProductId = this.ProductId;
      this.ProductPropertySaveModel.Name = this.ProductProperty.Name;

      //save and create product property
      if (this.ProductPropertyType.Id < 1) {
        this.prod.SaveProductPropertyType_Observable(this.ProductPropertyType).subscribe((data) => {
          this.ProductPropertyType = new ProductPropertyTypeViewModel(data);

        });

      }
      this.ProductPropertySaveModel.ProductPropertyTypeId = this.ProductPropertyType.Id;

      //save product property nieuw en daarbij ook de relatie 
      console.log(this.ProductPropertySaveModel);
      //this.prod.SaveProductProperty_Observable(this.ProductPropertySaveModel).subscribe((data) => {
      //    this.ProductPropertySaveModel = new ProductPropertySaveModel();
      //    console.log(data);
      //})
    }

    if (this.ProductProperty.Id > 0) {
      this.ProductPropertySaveModel = new ProductPropertySaveModel();
      this.ProductPropertySaveModel.ProductId = this.ProductId;
      this.ProductPropertySaveModel.Id = this.ProductProperty.Id;
      //Product Property Bestaat al en er hoeft alleen een link gemaakt te worden. 
      this.prod.SaveProductPropertyRelation_Observable(this.ProductPropertySaveModel).subscribe((data) => {
        console.log(data);
      })
    }
    this.Refresh();
    this.showProductPropertyRelation = true;
  }
}
