import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';



	export class WoningGroepType {
		public Naam: string;
		public DisplayNaam: string;
		public Icoon: string;
		public GroepType: EnumsWijzigScopeEnum.WijzigScopeEnum;
		public SuperGroepTypeEnum: EnumsWijzigScopeEnum.WijzigScopeEnum;
		public SuperGroepType: WoningGroepType;

		// Voorlopig ondersteunen we maar 1 subtype (project-wijk-subwijk-blok-woning)
		public SubGroepType: WoningGroepType;

		public EqualTo(scopeItem: EnumsWijzigScopeEnum.WijzigScopeEnum): boolean {
			return this.GroepType === scopeItem;
		}
	}

