import * as _ from 'lodash';
import * as ng from 'angular';
import * as HelpersAngularEvent from '.././Helpers/AngularEvent';
import * as ServicesOptieService from '.././services/OptieService';
import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';
import * as ViewModelsWoningSelectie from '.././Models/WoningSelectie';
import * as ViewModelsWoning from '.././Models/Woning';
import { Injectable } from '@angular/core';
import { Observable, Subscriber, of, BehaviorSubject, Subject} from 'rxjs';
import { shareReplay, map,take , distinctUntilChanged} from 'rxjs/operators';
import * as  EnumsWebBericht from '@enums/WebBericht';
import { AppConfigService } from '../infrastructure/app.configservice';
import { DocumentModel } from '@models/DocumentModel';
import { DwellingState } from './dwellingstore/dwelling.state';
import { Store, select, createSelector, createFeatureSelector } from '@ngrx/store';
import * as DwellingActions from './dwellingstore/dwelling.actions';
import { ISoftware}  from '@datacontracts/Constanten';

export function  GlobalGetDwellingScope(state: DwellingState): ServicesOptieService.ScopeSelectie {
	const selectie = new ServicesOptieService.ScopeSelectie();
	selectie.Scope = EnumsWijzigScopeEnum.WijzigScopeEnum.Woning;
	selectie.ID = GlobalGeefHuidigeWoningID(state);
	selectie.Scenario = GlobalGetScenarioId(state);
	selectie.BewonerID = state.BewonerId;
	return selectie;
}

export function  GlobalGeefHuidigeWoning(state: DwellingState): ViewModelsWoning.Woning {
	if (state.GlobaleWoningSelectie == null) {
		return null;
	}
	var woning = <ViewModelsWoning.Woning>state.GlobaleWoningSelectie.GeefType(EnumsWijzigScopeEnum.WijzigScopeEnum.Woning);
	if (woning != null) {
		return woning;
	}
	return null;
}

export function GlobalGeefHuidigeWoningID(state: DwellingState): number{
	var w: ViewModelsWoning.Woning = GlobalGeefHuidigeWoning(state);
	if (w != null) {
		return w.GeefID();
	} else {
		return state.DwellingId;
	}
}

export function GlobalGetScenarioId(state: DwellingState): number
{
	return state.ScenarioId;
}

export interface IWoningEventService {
	//ReLoadWoningen_USESPARINGLY(cacheNietgebruiken: boolean): Observable<boolean>;
	TriggerCentraleToestandGewijzigd(args: ViewModelsWoningSelectie.CentraleToestandGewijzigdEventArgs);
	TriggerCentraleToestandGewijzigdSimpel(bron: ViewModelsWoningSelectie.WijzigBron);

	TriggerMenuKlik();
	zetScenarioReadOnlyWaarde(waarde: boolean);
	GeefGlobaleWoningSelectie(): ViewModelsWoningSelectie.WoningSelectie;
	//WisselActieveWoning(woningid: number, scenario: number);
	WisselWoning(woningid: number): ng.IPromise<boolean>;
	//WisselWoning_Obs(woningid: number): Observable<boolean>;
	WisselScenario(scenario: number);
	WisselWoningEnScenario(woningid: number, scenario: number);
	GeefHuidigeWoning(): ViewModelsWoning.Woning;
	GeefHuidigeWoningID(): number;
	//GeefHuidigeBewonerID(): number;
	GeefHuidigBlokID(): number;
	GeefHuidigeWijkID(): number;
	GeefHuidigScenario(): number;
	//LaadWoning(alleenScenarioNotificatie: boolean): ng.IPromise<boolean>;
	//ScopeWijzigingInGlobaleSelectie();
	GeefScope(): EnumsWijzigScopeEnum.WijzigScopeEnum;
	//GetDwellingScope(state: DwellingState): ServicesOptieService.ScopeSelectie;
	GeefScopeSelectieWoning(): ServicesOptieService.ScopeSelectie;
	GeefScopeSelectieWoonblok(): ServicesOptieService.ScopeSelectie;
	GeefScopeSelectie(): ServicesOptieService.ScopeSelectie;
	ZetScope(scope: EnumsWijzigScopeEnum.WijzigScopeEnum): void;
	WisHuidigeWoning(): void;

	KiesNieuweWoningEvent: HelpersAngularEvent.AngularEvent<ViewModelsWoningSelectie.KiesNieuweWoningEventArgs>;
	CentraleToestandWijzigEventEvent: HelpersAngularEvent.AngularEvent<ViewModelsWoningSelectie.CentraleToestandGewijzigdEventArgs>;
	SiteGestartEvent: HelpersAngularEvent.AngularEvent<void>;
	ScopeWijzigingEvent: HelpersAngularEvent.AngularEvent<void>;
	AangevraagdeNieuweWoningEvent: HelpersAngularEvent.AngularEvent<void>;
	MenuKlikEvent: HelpersAngularEvent.AngularEvent<void>;

	IsInitieleBroadcast(): boolean;

	IsHuidigeScenarioReadOnly(): boolean;


	/**
	 * Alleen gebruiken voor initialisatie doeleinden.
	 */
	WoningSelectieCache: ng.IPromise<ViewModelsWoningSelectie.WoningSelectie>;

	HuidigScenarioNummer: number;

	currentDocument: DocumentModel;

	WisselScenarioEvent: HelpersAngularEvent.AngularEvent<HelpersAngularEvent.AngularEventArg<number>>;

	Scenario$: Observable<number>;
	DwellingId$: Observable<number>;
	ScopeWijzigEvent$: Observable<EnumsWijzigScopeEnum.WijzigScopeEnum>;
}

@Injectable({
	providedIn: 'root',
})
export class WoningEventService implements IWoningEventService {
	//private m_Data: WoningEventServiceData;
	private _currentDocument: DocumentModel;
	Scenario$: Observable<number>;
	DwellingId$: Observable<number>;
	ScopeWijzigEvent$: Observable<EnumsWijzigScopeEnum.WijzigScopeEnum>;
	

	public LatestStore(): DwellingState {
		let inst: DwellingState = null;
		this.store.select(s => s.dwelling).pipe(take(1)).subscribe(a => inst = a);
		return inst;
	}

	public get WisselScenarioEvent(): HelpersAngularEvent.AngularEvent<HelpersAngularEvent.AngularEventArg<number>> {
		alert("WisselScenarioEvent uitgefaseerd, gebruik woningeventservice.Scenario$");
		return null;
		//return this.m_Data.wisselScenarioEventEmitter.Event;
	}

	public get HuidigScenarioNummer(): number {
		return this.LatestStore().ScenarioId;
	}

	public get currentDocument(): DocumentModel {
		return this._currentDocument;
	}

	public set currentDocument(doc: DocumentModel) {
		this._currentDocument = doc;
	}

	public set HuidigScenarioNummer(nieuwNummer: number) {
		alert("HuidigScenarioNummer uitgefaseerd");
		// if (nieuwNummer !== this.m_Data.huidigScenarioNummer) {
		// 	this.m_Data.huidigScenarioNummer = nieuwNummer;
		// 	this.m_Data.wisselScenarioEventEmitter.Emitter.BroadCast(
		// 		HelpersAngularEvent.AngularEventArg.WithData(nieuwNummer, false));
		// }
	}

	public get AangevraagdeNieuweWoningEvent(): HelpersAngularEvent.AngularEvent<void> {
		alert("AangevraagdeNieuweWoningEvent uitgefaseerd");
		return null;
		//return this.m_Data.aangevraagdeNieuweWoningEventEmitter.Event;
	}

	public get KiesNieuweWoningEvent(): HelpersAngularEvent.AngularEvent<ViewModelsWoningSelectie.KiesNieuweWoningEventArgs> {
		alert("KiesNieuweWoningEvent uitgefaseerd");
		return null;
	}

	public get SiteGestartEvent(): HelpersAngularEvent.AngularEvent<void> {
		alert("SiteGestartEvent uitgefaseerd");
		return null;
	}

	public get ScopeWijzigingEvent(): HelpersAngularEvent.AngularEvent<void> {
		alert("ScopeWijzigingEvent uitgefaseerd");
		return null;
	}

	public get MenuKlikEvent(): HelpersAngularEvent.AngularEvent<void> {
		alert("MenuKlikEvent uitgefaseerd");
		return null;
	}

	public get CentraleToestandWijzigEventEvent(): HelpersAngularEvent.AngularEvent<ViewModelsWoningSelectie.CentraleToestandGewijzigdEventArgs> {
		alert("CentraleToestandWijzigEventEvent uitgefaseerd");
		return null;
	}

	public IsHuidigeScenarioReadOnly(): boolean {
		return this.LatestStore().ScenarioIsReadOnly;
	}


	//public WoningScope: EnumsWijzigScopeEnum.WijzigScopeEnum = EnumsWijzigScopeEnum.WijzigScopeEnum.Blok;
	public IsInitieleBroadcast(): boolean {
		alert("IsInitieleBroadcast uitgefaseerd");
		return null;
	}

	/**
	 * Alleen gebruiken voor initialisatie doeleinden, anders via event.
	 */
	public get WoningSelectieCache(): ng.IPromise<ViewModelsWoningSelectie.WoningSelectie> {
		alert("WoningSelectieCache uitgefaseerd");
		return null;
	}

	constructor(
		//private $http: ng.IHttpService,
		private config: AppConfigService,
		//private woningService: ServicesWoningService.WoningService,
		//private webberichtconsumer: WebBerichtConsumer,
		//private accountservice: ServicesAccountService.AccountService,
		//private $timeout: ng.ITimeoutService,
		//private fatalerrorservice: ServicesFatalErrorService.FatalErrorService,
		private store: Store<{ dwelling: DwellingState }>,
	) {
		this.Scenario$ = this.store.select(s => s.dwelling.ScenarioId).pipe(distinctUntilChanged());
		this.DwellingId$ = this.store.select(s => s.dwelling.DwellingId).pipe(distinctUntilChanged());
		this.ScopeWijzigEvent$ = this.store.select(s => s.dwelling.Scope).pipe(distinctUntilChanged());

		this.Internal_UpdateConfig(config.Software);

		this.Load();
	}

	private Load()
	{
		//debugger;

		//this.registerEventEmitters();

		//this.ReLoadWoningen_USESPARINGLY(false);

		// because of ng1/ng2 not sharing this instance, we might have some problems with singleton truth / async 
		// timings.

		

		// HelpersCachedPromise.CachedPromise.Default<ViewModelsWoningSelectie.WoningSelectie>(this.$q,
		// 	() => {
		// 		return this.woningService.laadwoninggroepen(false)
		// 			.then(a => {
		// 				this.GlobaleWoningSelectie =
		// 					this.woningService.GeefBreadCrumbVoorWoning(this.m_HuidigeWoning, this.m_HuidigeScope);
		// 				return this.GlobaleWoningSelectie;
		// 			});
		// 	});


		//this.$timeout(() => {
		//if (accountservice.HeeftRol("eigenaar") || accountservice.HeeftRol("huurder")) {
		//	this.m_HuidigeScope = EnumsWijzigScopeEnum.WijzigScopeEnum.Woning;
		//}
		//this.AboneerOpDiensten();

		
	}

	ZetBreadcrumbOpBewonerNiveau() {
		//alert("ZetBreadcrumbOpBewonerNiveau uitgefaseerd");
		return null;
		//this.m_Data.m_HuidigeScope = EnumsWijzigScopeEnum.WijzigScopeEnum.Woning;
	}

	// public ReLoadWoningen_USESPARINGLY(cacheNietGebruiken: boolean): Observable<boolean> {
	// 	alert("ReLoadWoningen_USESPARINGLY uitgefaseerd");
	// 	// let loaded = new Subject<boolean>();
	// 	// this.m_Data.woningSelectieCache = this.woningService.GeefWoningenObversable(cacheNietGebruiken).pipe(
	// 	// 	map(a => {
	// 	// 		this.m_Data.GlobaleWoningSelectie = this.woningService.GeefBreadCrumbVoorWoning(this.m_Data.m_HuidigeWoning, this.m_Data.m_HuidigeScope);
	// 	// 		loaded.next(true);
	// 	// 		return this.m_Data.GlobaleWoningSelectie;
	// 	// 	}),
	// 	// 	shareReplay(1));

	// 	// 	this.m_Data.woningSelectieCache.pipe(take(1)).subscribe();

	// 	// 	return loaded.asObservable();
	// }

	
	// private registerEventEmitters(): void {
	// 	this.m_Data.aangevraagdeNieuweWoningEventEmitter =
	// 		HelpersAngularEvent.AngularEvent.Default<void>("AangevraagdeNieuweWoning-event", null);
	// 	this.m_Data.kiesNieuweWoningEventEmitter =
	// 		HelpersAngularEvent.AngularEvent.Default<ViewModelsWoningSelectie.KiesNieuweWoningEventArgs>("WisselWoning-event", null);
	// 	this.m_Data.siteGestartEventEmitter = HelpersAngularEvent.AngularEvent.Default<void>("SiteGestart-event", null);
	// 	this.m_Data.scopeWijzigingEventEmitter = HelpersAngularEvent.AngularEvent.Default<void>("ScopeWijziging-event", null);
	// 	this.m_Data.menuKlikEventEmitter = HelpersAngularEvent.AngularEvent.Default<void>("MenuKlik-event", null);
	// 	this.m_Data.m_CentraleToestandWijzigEventEmitter = HelpersAngularEvent.AngularEvent.Default<ViewModelsWoningSelectie.CentraleToestandGewijzigdEventArgs>("m_CentraleToestandWijzigEventEmitterevent", null);
	// 	this.m_Data.wisselScenarioEventEmitter =
	// 		HelpersAngularEvent.AngularEvent.Default<HelpersAngularEvent.AngularEventArg<number>>("WisselScenario-event", null);
	// }

	public GeefScope(): EnumsWijzigScopeEnum.WijzigScopeEnum {
		let store = this.LatestStore();
		return store.Scope;
	}

	// public GeefHuidigeBewonerID() {
	// 	return this.accountservice.GeefHuidigeBewonerID();
	// }

	public GeefScopeSelectie(): ServicesOptieService.ScopeSelectie {
		let store = this.LatestStore();
		const selectie = new ServicesOptieService.ScopeSelectie();
		selectie.Scope = this.GeefScope();
		if (selectie.Scope === EnumsWijzigScopeEnum.WijzigScopeEnum.Complex) {
			selectie.ID = store.AreaId;
		} else if (selectie.Scope === EnumsWijzigScopeEnum.WijzigScopeEnum.Blok) {
			selectie.ID = store.BlockId;
		} else if (selectie.Scope === EnumsWijzigScopeEnum.WijzigScopeEnum.Woning) {
			selectie.ID = store.DwellingId;
		}
		selectie.Scenario = this.HuidigScenarioNummer;
		return selectie;
	}

	

	public GeefScopeSelectieWoning(): ServicesOptieService.ScopeSelectie {
		const selectie = GlobalGetDwellingScope(this.LatestStore());
		console.log("GeefScopeSelectieWoning" + this.GeefHuidigeWoningID());
		return selectie;
	}

	public GeefScopeSelectieWoonblok(): ServicesOptieService.ScopeSelectie {
		var selectie: ServicesOptieService.ScopeSelectie = new ServicesOptieService.ScopeSelectie();
		selectie.Scope = EnumsWijzigScopeEnum.WijzigScopeEnum.Blok;
		selectie.ID = this.GeefHuidigBlokID();
		selectie.Scenario = this.HuidigScenarioNummer;
		return selectie;
	}



	public GeefHuidigeWoning(): ViewModelsWoning.Woning {
		let state = this.LatestStore();

		return GlobalGeefHuidigeWoning(state);
		// if (state.GlobaleWoningSelectie == null) {
		// 	return null;
		// }
		// var woning = <ViewModelsWoning.Woning>state.GlobaleWoningSelectie.GeefType(EnumsWijzigScopeEnum.WijzigScopeEnum.Woning);
		// if (woning != null) {
		// 	return woning;
		// }
		// return null;
	}

	
	public GeefHuidigeWoningID(): number {
		let state = this.LatestStore();
		return GlobalGeefHuidigeWoningID(state);
	}

	public WisHuidigeWoning(): void {
		alert("WoningSelectieCache uitgefaseerd");
		return null;
	}

	public GeefHuidigeWijkID(): number {
		let state = this.LatestStore();
		if (state.GlobaleWoningSelectie == null) {
			return state.AreaId;
		} else {
			return state.GlobaleWoningSelectie.GeefType(EnumsWijzigScopeEnum.WijzigScopeEnum.Complex).GeefID();
		}
	}

	public GeefHuidigBlokID(): number {
		let state = this.LatestStore();
		if (state.GlobaleWoningSelectie == null) {
			return state.BlockId;
		} else {
			return state.GlobaleWoningSelectie.GeefType(EnumsWijzigScopeEnum.WijzigScopeEnum.Blok).GeefID();
		}
	}

	public GeefHuidigScenario(): number {
		let state = this.LatestStore();
		if (state.GlobaleWoningSelectie == null) {
			return 0;
		} else {
			return this.HuidigScenarioNummer;
		}
	}

	public GeefGlobaleWoningSelectie(): ViewModelsWoningSelectie.WoningSelectie {
		let state = this.LatestStore();
		return state.GlobaleWoningSelectie;
	}

	

	public WisselScenario(scenario: number) {
		let state = this.LatestStore();
		this.WisselWoningEnScenario(state.DwellingId, scenario);
	}

	public WisselWoningEnScenario(woningid: number, scenario: number) {
		this.store.dispatch(DwellingActions.changeScenarioAndDwelling({ scenario: scenario, dwelling: woningid }));
	}

	public Internal_UpdateConfig(cfg: ISoftware) {
		this.store.dispatch(DwellingActions.loadConfig({ software: cfg}));
	}


	public WisselWoning(woningid: number): ng.IPromise<boolean> {
		let state = this.LatestStore();
		this.WisselWoningEnScenario(woningid, state.ScenarioId);
		//var obs = this.WisselWoning_Obs(woningid);
		//return obs.toPromise();
		return of(true).toPromise(); // todo: change
	}

	// public WisselWoning_Obs(woningid: number): Observable<boolean> {
	// 	var obs = new Observable<boolean>((observer) => {

	// 		if (this.m_Data.GlobaleWoningSelectie != null) {
	// 			var huidigeWoning = this.m_Data.GlobaleWoningSelectie.GeefType(EnumsWijzigScopeEnum.WijzigScopeEnum.Woning).GeefID();
	// 			if (woningid == huidigeWoning) {
	// 				/* Reeds gezet */
	// 				observer.next(true);
	// 				observer.complete();
	// 			}
	// 			this.woningService.WijzigSelectieWoning(this.m_Data.GlobaleWoningSelectie.WoningGroepBreadcrumb, woningid);
	// 		}

	// 		this.LaadWoning(false).then(response => {
	// 			observer.next(true);
	// 			observer.complete();
	// 		}).catch(r => observer.error(r));

	// 	});
	// 	return obs;
	// }

	// public ScopeWijzigingInGlobaleSelectie() {
	// 	let state = this.LatestStore();
	// 	var nieuwe = (state.GlobaleWoningSelectie.Actievegroep as any);
	// 	if (nieuwe != state.m_HuidigeScope) {
	// 		state.m_HuidigeScope = nieuwe;
	// 		state.scopeWijzigingEventEmitter.Emitter.BroadCast(undefined);

	// 		this.TriggerCentraleToestandGewijzigdSimpel(ViewModelsWoningSelectie.WijzigBron.ScopeWissel);
	// 	}
	// }

	public ZetScope(scope: EnumsWijzigScopeEnum.WijzigScopeEnum): void {
		this.store.dispatch(DwellingActions.changeScope({ scope: scope }));
	}

	// public LaadWoning(alleenScenarioWisselingEvent: boolean): ng.IPromise<boolean> {
	// 	var obs = new Observable<boolean>((observer) => {
	// 		console.log("%cLaad woning", "color:green");
	// 		if (this.m_Data.mWisselWoningAanvraagBezig) {
	// 			/* niet toegestaan, wellicht in de 'queue' leggen */
	// 			console.log("%cNieuwe wisselwoning aangevraagd", "color:darkgreen");
	// 			this.m_Data.mExtraWisselWoningAanvraag.push(observer);
	// 			if (!alleenScenarioWisselingEvent) {
	// 				this.m_Data.mExtraWisselWoningAanvraagAlleenScenario = false;
	// 			}
	// 		}

	// 		var wwmodel = new ModelWisselWoning.WisselWoningDataIn({ WoningId: woning, Scenario: scenario });

	// 		if (this.config.Software.ModelSystemActive) {
	// 			/* In het nieuwe "model" system wisselen we altijd DIRECT van woning op de client */
	// 			var response = new ModelWisselWoning.WisselWoningDataUit();
	// 			response.ReadOnly = false;
	// 			response.EventId = -1;
	// 			response.Fout = null;

	// 			// We voeren nog wel het commando uit, zodat de backend kan gekoppelde woning onthouden zodat deze terug komt bij opnieuw inloggen
	// 			this.webberichtconsumer.WisselWoning(wwmodel).then((model) => {
	// 			});

	// 			this.WisselWoningDone(response, observer, woning, scenario, alleenScenarioWisselingEvent);
	// 		}
	// 		else {
	// 			// let bericht = new WebBericht();

	// 			// bericht.Type = EnumsWebBericht.AktieBerichtType.WisselWoning;
	// 			// bericht.DataIn = JSON.stringify({ WoningId: woning, Scenario: scenario });
	// 			// bericht.Name = EnumsWebBericht.AktieBerichtType[EnumsWebBericht.AktieBerichtType.WisselWoning];

				

	// 			this.webberichtconsumer.WisselWoning(wwmodel).then((model) => {
	// 				//var jsonData = JSON.parse(bericht.DataUit);
	// 				var d: DataContractsWisselWoning.IWisselWoningDataUit = model;
	// 				this.WisselWoningDone(d, observer, woning, scenario, alleenScenarioWisselingEvent);
	// 			});
	// 		}
	// 		console.groupEnd();
	// 	});
	// 	return obs.toPromise();
	// }

	// WisselWoningDone(model: DataContractsWisselWoning.IWisselWoningDataUit, deferred: Subscriber<boolean>, woning: number, scenario: number, alleenScenarioWisselingEvent: boolean) {
	// 	//HelpersBackwards.EindWachten();

	// 	console.log("%cWisselwoning success", "color:blue");
	// 	if (model == null) {
	// 		this.fatalerrorservice.ReloadPage(null, "woningeventservice");
	// 		return;
	// 	}
	// 	else {
	// 		if (model.Fout != null) {
	// 			alert(model.Fout + " U wordt nu automatisch uitgelogd. Sluit indien van toepassing alle andere WoonConnect tabbladen");
	// 			window.location.assign("/Account/Logoff");
	// 			document.location.assign("/Account/Logoff");
	// 			return;
	// 		}
	// 	}
	// 	this.m_Data.mWisselWoningAanvraagBezig = false;


	// 	if (this.m_Data.mExtraWisselWoningAanvraag.length > 0) {
	// 		var tenotificeren = this.m_Data.mExtraWisselWoningAanvraag;
	// 		var alleenScenario = this.m_Data.mExtraWisselWoningAanvraagAlleenScenario;
	// 		this.m_Data.mExtraWisselWoningAanvraag = [];
	// 		this.m_Data.mExtraWisselWoningAanvraagAlleenScenario = true;
	// 		this.LaadWoning(alleenScenario).then(r => {
	// 			_.each(tenotificeren,
	// 				(n) => {
	// 					deferred.next(r);
	// 					deferred.complete();
	// 				});
	// 		});
	// 	} else {


	// 		var woningEventsUpdaten = () => {
	// 			console.log("%cWisselwoning success - geen nieuwe aanvraag", "color:darkblue");
				
	// 			this.zetGlobaleScopeSelectie();
	// 			this.zetScenarioReadOnlyWaarde(model.ReadOnly);

	// 			var arg: ViewModelsWoningSelectie.KiesNieuweWoningEventArgs = new ViewModelsWoningSelectie.KiesNieuweWoningEventArgs();
	// 			var ev: ModelsWijzigEvent.WijzigEvent = new ModelsWijzigEvent.WijzigEvent();
	// 			ev.Generatie = model.EventId;
	// 			ev.ID = woning;
	// 			ev.Scope = EnumsWijzigScopeEnum.WijzigScopeEnum.Woning;
	// 			ev.Variant = scenario;
	// 			arg.Event = ev;
	// 			arg.GlobaleScopeSelectie = this.m_Data.HuidigeGlobaleScopeSelectie;

	// 			this.m_Data.kiesNieuweWoningEventEmitter.Emitter.BroadCast(arg);

	// 			var argCentrale = new ViewModelsWoningSelectie.CentraleToestandGewijzigdEventArgs();
	// 			argCentrale.Event = ev;
	// 			argCentrale.GlobaleScopeSelectie = arg.GlobaleScopeSelectie;

	// 			if (alleenScenarioWisselingEvent) {
	// 				argCentrale.WijzigBron = ViewModelsWoningSelectie.WijzigBron.ScenarioWissel;

	// 			} else {
	// 				argCentrale.WijzigBron = ViewModelsWoningSelectie.WijzigBron.WoningWissel;
	// 			}

	// 			this.m_Data.m_CentraleToestandWijzigEventEmitter.Emitter.BroadCast(argCentrale);
	// 		}

	// 		var limiteerBreadcrumb = (this.m_Data.m_LimitatieVoorScenario == scenario
	// 			? this.DoNothing()
	// 			: this.woningService.WoningGroepenLimiterenVoorBreadCrumb());
	// 		limiteerBreadcrumb.then(_ => {
	// 			woningEventsUpdaten();
	// 			deferred.next(true);
	// 			deferred.complete();
	// 		});
	// 	}
	// }

	DoNothing(): ng.IPromise<boolean> {
		return of(true).toPromise();
	}

	public zetScenarioReadOnlyWaarde(waarde: boolean) {
		alert("AangevraagdeNieuweWoningEvent uitgefaseerd");
		return null;
	}

	public TriggerMenuKlik() {
		alert("AangevraagdeNieuweWoningEvent uitgefaseerd");
		return null;
	}

	public TriggerCentraleToestandGewijzigd(args: ViewModelsWoningSelectie.CentraleToestandGewijzigdEventArgs) {
		alert("AangevraagdeNieuweWoningEvent uitgefaseerd");
		return null;
	}

	public TriggerCentraleToestandGewijzigdSimpel(bron: ViewModelsWoningSelectie.WijzigBron) {
		alert("AangevraagdeNieuweWoningEvent uitgefaseerd");
		return null;
	}

}

