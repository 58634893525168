import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ImageAnnotationConsumer } from "../data/woonconnectkern/consumers/ImageAnnotationController";
import { AddElementsToProjectRequestModel } from "../data/woonconnectkern/models/AddElementsToProjectModel";
import { AddNewElementRequestModel } from "../data/woonconnectkern/models/AddNewElementModel";
import { AddPhotoSetsToProjectRequestModel } from "../data/woonconnectkern/models/AddPhotoSetsToProjectModel";
import { AddUsersToProjectRequestModel } from "../data/woonconnectkern/models/AddUsersToProjectModel";
import { CreateNewProjectRequestModel } from "../data/woonconnectkern/models/CreateNewProjectModel";
import { GetAllProjectsResponseModel, ImageAnnotationProject } from "../data/woonconnectkern/models/GetAllProjectsModel";
import { AnnotatorUser } from "../data/woonconnectkern/models/GetAnnotatorUsersModel";
import { GetElementsRequestModel, ImageAnnotationElement } from "../data/woonconnectkern/models/GetElementsModel";
import { GetPhotoDataRequestModel, ImageAnnotationPhotoData } from "../data/woonconnectkern/models/GetPhotoDataModel";
import { GetPhotoSetsFromProjectRequestModel } from "../data/woonconnectkern/models/GetPhotoSetsFromProjectModel";
import { ImageAnnotationPhotoSet } from "../data/woonconnectkern/models/GetPhotoSetsModel";
import { GetPhotosRequestModel, GetPhotosResponseModel, ImageAnnotationPhoto } from "../data/woonconnectkern/models/GetPhotosModel";
import { GetProjectsFromUserRequestModel } from "../data/woonconnectkern/models/GetProjectsFromUserModel";
import { SaveAnnotationRequestModel, SaveAnnotationResponseModel, ImageAnnotationResultElement } from "../data/woonconnectkern/models/SaveAnnotationModel";
import { SkipPhotoRequestModel } from "../data/woonconnectkern/models/SkipPhotoModel";
import { ImageAnnotationSelection } from "../models/imageannotation/ImageAnnotationSelection";

@Injectable({
    providedIn: 'root'
})
export class ImageAnnotationService {

    private defaultImageAnnotationSelection: ImageAnnotationSelection[];
    constructor(private imageAnnotationConsumer: ImageAnnotationConsumer) {
        this.defaultImageAnnotationSelection = [{type: 'window', color: 'red'}, {type: 'door', color: 'green'}];
    }

    public getDefaultImageAnnotationSelection(): ImageAnnotationSelection[] {
        return this.defaultImageAnnotationSelection;
    }

    public getImageAnnotationElements(projectId: number): Observable<ImageAnnotationElement[]> {
        let request = new GetElementsRequestModel();
        request.ProjectId = projectId;

        return this.imageAnnotationConsumer.GetElements_Observable(request).pipe(map(response => {
            var data: any = response;
            let elements: ImageAnnotationElement[] = [];
                data.elements.forEach(element => {
                    elements.push(new ImageAnnotationElement(element));
                });
            return elements;
        }));
    }

    public getAllImageAnnotationElements(): Observable<ImageAnnotationElement[]> {
        return this.imageAnnotationConsumer.GetAllElements_Observable().pipe(map(response => {
            var data: any = response;
            let elements: ImageAnnotationElement[] = [];
            data.elements.forEach(element => {
                elements.push(new ImageAnnotationElement(element));
            });
            return elements;
        }));
    }

    public saveResults(resultElements: ImageAnnotationResultElement[], photoId: number, userId: number, projectId: number ): Observable<boolean> {
        let request = new SaveAnnotationRequestModel();
        request.BoundingBoxes = resultElements;
        request.PhotoId = photoId;
        request.ProjectId = projectId;
        request.PersoonId = userId;
        
        return this.imageAnnotationConsumer.SaveAnnotation_Observable(request).pipe(map(response => {
            var data: any = response;
            return data.Success;
        }));
    }

    public getPhotos(photoSetId: number, userId: number): Observable<ImageAnnotationPhoto[]> {
        let request = new GetPhotosRequestModel();
        request.PhotoSetId = photoSetId;
        request.PersoonId = userId;
        
        return this.imageAnnotationConsumer.GetPhotos_Observable(request).pipe(map(response => {
            var data: any = response;
            let photos: ImageAnnotationPhoto[] = [];
            data.Photos.forEach(photo => {
                photos.push(new ImageAnnotationPhoto(photo));
            });
            return photos;
        }));
    }

    public getPhotoData(photoPath: string): Observable<ImageAnnotationPhotoData> {
        let request = new GetPhotoDataRequestModel();
        request.PhotoPath = photoPath;
        
        return this.imageAnnotationConsumer.GetPhotoData_Observable(request).pipe(map(response => {
            var data: any = response;
            let photoData = new ImageAnnotationPhotoData(data.PhotoData);
            return photoData;
        }));
    }
    
    public getAllProjects(): Observable<ImageAnnotationProject[]> {
        return this.imageAnnotationConsumer.GetAllProjects_Observable().pipe(map(response => {
            var data: any = response;
            let projects: ImageAnnotationProject[] = [];
            data.Projects.forEach(project => {
                projects.push(new ImageAnnotationProject(project))
            });
            return projects;
        }));
    }

    public getAnnotatorUsers(): Observable<AnnotatorUser[]> {
        return this.imageAnnotationConsumer.GetAnnotatorUsers_Observable().pipe(map(response => {
            var data: any = response;
            let users: AnnotatorUser[] = [];
            data.Users.forEach(user => {
                users.push(new AnnotatorUser(user))
            });
            return users;
        }));
    }

    public getAllPhotoSets(): Observable<ImageAnnotationPhotoSet[]> {
        return this.imageAnnotationConsumer.GetAllPhotoSets_Observable().pipe(map(response => {
            var data: any = response;
            let photoSets: ImageAnnotationPhotoSet[] = [];
            data.PhotoSets.forEach(photoSet => {
                photoSets.push(new ImageAnnotationPhotoSet(photoSet));
            });
            return photoSets;
        }));
    }

    public addUsersToProject(userIds: number[], projectId: number): Observable<boolean> {
        let request = new AddUsersToProjectRequestModel();
        request.PersoonIds = userIds;
        request.ProjectId = projectId;
        return this.imageAnnotationConsumer.AddUsersToProject_Observable(request).pipe(map(response => {
            var data: any = response;
            return data.Success;
        }));
    }

    public getProjectsFromUser(personId: number): Observable<ImageAnnotationProject[]> {
        let request = new GetProjectsFromUserRequestModel();
        request.PersonId = personId;
        return this.imageAnnotationConsumer.GetProjectsFromUser_Observable(request).pipe(map(response => {
            var data: any = response;
            let projects: ImageAnnotationProject[] = [];
            data.Projects.forEach(project => {
                projects.push(new ImageAnnotationProject(project))
            });
            return projects;
        }));
    }

    public createNewProject(projectName: string): Observable<boolean> {
        let request = new CreateNewProjectRequestModel();
        request.ProjectName = projectName;
        return this.imageAnnotationConsumer.CreateNewProject_Observable(request).pipe(map(response => {
            var data: any = response;
            return data.Success;
        }));
    }

    public addPhotoSetsToProject(photoSetIds: number[], projectId: number): Observable<boolean> {
        let request = new AddPhotoSetsToProjectRequestModel();
        request.PhotoSetIds = photoSetIds;
        request.ProjectId = projectId;
        return this.imageAnnotationConsumer.AddPhotoSetsToProject_Observable(request).pipe(map(response => {
            var data: any = response;
            return data.Success;
        }));
    }

    public getPhotoSetsFromProject(projectId: number): Observable<ImageAnnotationPhotoSet[]> {
        let request = new GetPhotoSetsFromProjectRequestModel();
        request.ProjectId = projectId;
        let obs = new Observable<ImageAnnotationPhotoSet[]>(observer => {
            this.imageAnnotationConsumer.GetPhotoSetsFromProject_Observable(request).subscribe(response => {
                let data: ImageAnnotationPhotoSet[] = [];
                response.PhotoSets.forEach(photoSet => {
                    data.push(new ImageAnnotationPhotoSet(photoSet));
                });
                observer.next(data);
                observer.complete();
            });
        });
        return this.imageAnnotationConsumer.GetPhotoSetsFromProject_Observable(request).pipe(map(response => {
            var data: any = response;
            let photoSets: ImageAnnotationPhotoSet[] = [];
            data.PhotoSets.forEach(photoSet => {
                photoSets.push(new ImageAnnotationPhotoSet(photoSet));
            });
            return photoSets
        }));
    }

    public skipPhoto(photoId: number, projectId: number, photoSetId: number): Observable<boolean> {
        let request = new SkipPhotoRequestModel();
        request.PhotoID = photoId;
        request.ProjectID = projectId;
        request.PhotoSetID = photoSetId;
        return this.imageAnnotationConsumer.SkipPhoto_Observable(request).pipe(map(response => {
            var data: any = response;
            return data.Succeeded;
        }));
    }

    public addElementsToProject(elementIds: number[], projectId: number): Observable<boolean> {
        let request = new AddElementsToProjectRequestModel();
        request.ElementIds = elementIds;
        request.ProjectId = projectId;
        return this.imageAnnotationConsumer.AddElementsToProject_Observable(request).pipe(map(response => {
            var data: any = response;
            return data.Succeeded;
        }));
    }

    public addNewElement(elementName: string): Observable<boolean> {
        let request = new AddNewElementRequestModel();
        request.elementName = elementName;
        return this.imageAnnotationConsumer.AddNewElement_Observable(request).pipe(map(response => {
            var data: any = response;
            return data.Succeeded; 
        }));
    }
}