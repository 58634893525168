import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {  Component, Input, Output, EventEmitter, OnChanges, OnInit, OnDestroy, SimpleChanges, Inject, ElementRef, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { SurveyConsumer } from '@consumers/SurveyController';
import { WoningEventService } from '@services/WoningEventService';
import { GetSurveyRequestModel } from '@models/GetSurveyModel';
import { IDwellingSurvey } from '@datacontracts/DwellingSurvey';
import { Store, select, createSelector, createFeatureSelector } from '@ngrx/store';
import { SurveyState } from '../store/survey.state';
import * as Actions from '../store/survey.actions';
import { Observable } from 'rxjs';
import { ISurveyWall } from '@models/SurveyWall';
import { ISurveyProperty, SurveyProperty } from '@models/SurveyProperty';
import { FormGroupState } from 'ngrx-forms';

@Component({
	selector: 'wct-survey-string',
	templateUrl: './WctSurveyString.html',
	styleUrls: ['./WctSurveyString.scss']
})
export class WctSurveyStringComponent  implements OnChanges  {

	@Input() prop : FormGroupState<ISurveyProperty> = null;
  @Input() placeholder: string = "";
  @Input() type: string = "text";

	constructor(
		private store: Store<{ survey: SurveyState }>,
	) {
	}


	ngOnChanges(changes: SimpleChanges): void {
	}
}
