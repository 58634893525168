/**
 * @ref WoonConnectViewModel/Survey/UpdateNoteModel.cs
 */

 import * as DataContracts from "@datacontracts/UpdateNoteModel";

 
import * as WoningSelectie from "./WoningSelectie";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.UpdateNoteRequestModel
	 */
	 

	export interface IUpdateNoteRequestModel {
		Dwelling: WoningSelectie.IWoningSelectie;
		Adding: boolean;
		NoteId: string;
		ObjectId: string;
		ToJsonContract(): DataContracts.IUpdateNoteRequestModel;
	}

	export class UpdateNoteRequestModel {
		// property-Properties
		public get Dwelling(): WoningSelectie.IWoningSelectie {
			return this._Dwelling;
		}
		public set Dwelling(newDwelling: WoningSelectie.IWoningSelectie) {
			this._Dwelling = newDwelling;
		}

		public get Adding(): boolean {
			return this._Adding;
		}
		public set Adding(newAdding: boolean) {
			this._Adding = newAdding;
		}

		public get NoteId(): string {
			return this._NoteId;
		}
		public set NoteId(newNoteId: string) {
			this._NoteId = newNoteId;
		}

		public get ObjectId(): string {
			return this._ObjectId;
		}
		public set ObjectId(newObjectId: string) {
			this._ObjectId = newObjectId;
		}

		// field-Properties

		// fields
		protected _Dwelling: WoningSelectie.IWoningSelectie = new WoningSelectie.WoningSelectie(undefined);
		protected _Adding: boolean = false;
		protected _NoteId: string = "";
		protected _ObjectId: string = "";

		constructor(data?: DataContracts.IUpdateNoteRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUpdateNoteRequestModel): void {
			if(data) {
				// vul properties
				this._Dwelling = data.Dwelling ? new WoningSelectie.WoningSelectie(data.Dwelling): undefined;
				this._Adding = data.Adding;
				this._NoteId = data.NoteId;
				this._ObjectId = data.ObjectId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUpdateNoteRequestModel {
			return {
				Dwelling: (this.Dwelling ? new WoningSelectie.WoningSelectie(this.Dwelling).ToJsonContract(): this.Dwelling),
				Adding: this.Adding,
				NoteId: this.NoteId,
				ObjectId: this.ObjectId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Survey.UpdateNoteResponseModel
	 */
	 

	export interface IUpdateNoteResponseModel {
		TekstUit: string;
	}

	export class UpdateNoteResponseModel {
		// property-Properties
		// field-Properties
		public get TekstUit(): string {
			return this._TekstUit;
		}
		public set TekstUit(newTekstUit: string) {
			this._TekstUit = newTekstUit;
		}

		// fields
		protected _TekstUit: string = "";

		constructor(data?: DataContracts.IUpdateNoteResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUpdateNoteResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._TekstUit = data.TekstUit;
			}
		}
	}
