// [conv] import "(./FabAdminOverview).less";
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ProductsConsumer } from '@consumers/ProductsController';
import { OverviewViewModel } from '@models/OverviewViewModel';

@Component({
   selector: 'fab-admin-overview',
  templateUrl: './FabAdminOverview.html',
  styleUrls: ['./FabAdminOverview.scss']
})
export class FabAdminOverviewComponent {

   public Laden: boolean = true;
   public Model: OverviewViewModel;

   constructor(private prod: ProductsConsumer) {
      this.prod.GetOverview_Observable().subscribe((data) => {

         this.Model = new OverviewViewModel(data);
         this.Laden = false;
      });
   }
}
