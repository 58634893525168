
<style>
  .rood {
    background-color: #F00;
  }
</style>

<div (click)="ProductProperty.Display = !ProductProperty.Display" style="line-height: 36px;" [ngClass]="{ 'rood': !ProductProperty.Connected, 'groen': ProductProperty.Connected }">
  <span *ngIf="!ProductProperty.Display">+</span><span *ngIf="ProductProperty.Display">-</span>{{ parentproductname }} <span *ngIf="parentproductname != ''"> / </span> {{ ProductProperty.Name }} ({{ ProductProperty.Id }})
  &nbsp;<button mat-raised-button color="primary" style="line-height: initial; padding: 2px;" *ngIf="ProductProperty.ManualAdded" (click)="RemoveEigenschap()">Remove</button>
  &nbsp;<button mat-raised-button color="primary" style="line-height: initial; padding: 2px;" (click)="Block()">Block</button>
  &nbsp;<button mat-raised-button color="primary" style="line-height: initial; padding: 2px;" (click)="BlockAlways()">BlockAlways</button>
  &nbsp;<button mat-raised-button color="primary" style="line-height: initial; padding: 2px;" *ngIf="HasParent()" (click)="BlockAlwaysSameParent()">BlockAlways({{ GetParent() }})</button>
  &nbsp;<button mat-raised-button color="primary" style="line-height: initial; padding: 2px;" *ngIf="HasParentParent()" (click)="BlockAlwaysSameParentParent()">BlockAlways({{ GetParentParent() }})</button>
  &nbsp;<span *ngIf="ProductProperty.ProductPropertyType.Name == 'DhInvoer'"><input type="checkbox" [(ngModel)]="ProductProperty.LiveUpdate" (change)="UpdateLiveUpdate()" /> Live update</span>
</div>

<div *ngIf="ProductProperty.Display">
  <div *ngIf="ProductProperty.Min != -1">
    Min: {{ ProductProperty.Min }}
  </div>
  <div *ngIf="ProductProperty.Max != -1">
    Max: {{ ProductProperty.Max }}
  </div>
  <div *ngIf="ProductProperty.Options.length == 0">
    <div *ngIf="ProductProperty.IgnoreFilter">
      Ignore <button mat-raised-button color="primary" (click)="Disconnect()">X</button>
    </div>
    <div *ngIf="!ProductProperty.IgnoreFilter">
      <div *ngIf="ProductProperty.Filter != undefined">
        Filter: {{ ProductProperty.Filter.Name }} <button mat-raised-button color="primary" (click)="Disconnect()">X</button>
      </div>
      <div *ngIf="ProductProperty.Filter == undefined && !ProductProperty.ShowFilters && (ShowFiltersSelectedId !== ProductProperty.Id)">
        <button mat-raised-button color="primary" (click)="ShowFilters(ProductProperty.Id, false)">Connect</button>
        <button mat-raised-button color="primary" (click)="Ignore()">Ignore</button>
        <button mat-raised-button color="primary" (click)="ConvertToOption()">ConvertToOption</button>
      </div>
      <div *ngIf="ProductProperty.Filter == undefined && ProductProperty.ShowFilters && (ShowFiltersSelectedId == ProductProperty.Id)">
        <select [(ngModel)]="SelectedFilterId">
          <option *ngFor="let filter of FiltersViewModel.Filters" [ngValue]="filter.Id">{{ filter.Name }}</option>
        </select>
        <button mat-raised-button color="primary" (click)="Connect()">Connect</button>
      </div>
    </div>
  </div>
  <div *ngFor="let propertyoption of ProductProperty.Options" style="border: 1px solid #000;" [ngStyle]="{'background-color':propertyoption.Connected || propertyoption.IgnoreFilterOption || propertyoption.FilterOptions?.length > 0 ? 'white' : '#f1b4b4' }">
    <div *ngIf="propertyoption.Bot != ''">Bot: {{ propertyoption.Bot }}</div>
    <div *ngIf="propertyoption.DO1Type != ''">{{ propertyoption.DO1Type }}: {{ propertyoption.DO1Naam }}</div>
    <div *ngIf="propertyoption.DO2Type != ''">{{ propertyoption.DO2Type }}: {{ propertyoption.DO2Naam }}</div>
    <div *ngIf="propertyoption.DO3Type != ''">{{ propertyoption.DO3Type }}: {{ propertyoption.DO3Naam }}</div>
    <div *ngIf="propertyoption.DO4Type != ''">{{ propertyoption.DO4Type }}: {{ propertyoption.DO4Naam }}</div>
    <div *ngIf="propertyoption.Kenmerk != ''">Kenmerk: {{ propertyoption.Kenmerk }}</div>
    <div>
      <div *ngIf="propertyoption.IgnoreFilterOption">
        Ignore <button mat-raised-button color="primary" (click)="DisconnectOption(null, propertyoption)">X</button>
      </div>
      <div *ngIf="ProductProperty.ManualAdded">
        <button mat-raised-button color="primary" (click)="RemoveOption(propertyoption.Id)">Delete</button>
      </div>
      <div *ngIf="!propertyoption.IgnoreFilterOption">
        <div *ngFor="let filteroption of propertyoption.FilterOptions">
          Filter: {{ filteroption.FilterName }} / {{ filteroption.Name }} <button mat-raised-button color="primary" (click)="DisconnectOption(filteroption, propertyoption)">X</button>
        </div>
        <div *ngIf="propertyoption.FilterOptions.length == 0">
          <button mat-raised-button color="primary" (click)="IgnoreOption(propertyoption)">Ignore</button>
          <button mat-raised-button color="primary" (click)="IgnoreOptionAlways(propertyoption)">Ignore Always</button>
        </div>
        <div *ngIf="!propertyoption.ShowFilters">
          <button mat-raised-button color="primary" (click)="ShowFilters(propertyoption.Id, true)">Connect</button>
        </div>
        <div *ngIf="propertyoption.ShowFilters">
          <div *ngIf="ShowFiltersSelectedId == propertyoption.Id">
            <select [(ngModel)]="SelectedFilterId" (change)="FilterChanged()">
              <option *ngFor="let filter of FiltersViewModel.Filters" [ngValue]="filter.Id">{{ filter.Name }}</option>
            </select>
            <select [(ngModel)]="SelectedFilterOption" (change)="FilterChanged()">
              <option *ngFor="let filteroption of FilterOptions" [ngValue]="filteroption.Id">{{ filteroption.Name }}</option>
            </select>
          </div>
          <button mat-raised-button color="primary" (click)="ConnectOption(propertyoption)">Connect</button>
          <button mat-raised-button color="primary" (click)="ConnectOptionAlways(propertyoption)">ConnectAlways</button>
          <button mat-raised-button color="primary" *ngIf="HasParent()" (click)="ConnectOptionAlwaysParent(propertyoption)">ConnectAlways({{ GetParent() }})</button>
          <button mat-raised-button color="primary" *ngIf="HasParentParent()" (click)="ConnectOptionAlwaysParentParent(propertyoption)">ConnectAlways({{ GetParentParent() }})</button>
          <button mat-raised-button color="primary" (click)="SuperConnectBot(propertyoption)">SuperConnectBot</button>
          <button mat-raised-button color="primary" (click)="SuperConnectKenmerk(propertyoption)">SuperConnectKenmerk</button>
        </div>
      </div>
    </div>

    <div *ngIf="propertyoption.ProductPropertiesOpbouw.length > 0">
      <b style="margin: 15px;font-weight: bold;font-size: 1.6rem;">Opbouw</b>
      <div *ngFor="let property of propertyoption.ProductPropertiesOpbouw" style="padding: 15px;">
        <display-product-property [ProductProperty]="property" [ProductVariantId]="ProductVariantId" [parentproductname]="ProductProperty.Name" (onRefresh)="Refresh()"></display-product-property>
      </div>
    </div>
    <div>
      <b style="margin: 15px;font-weight: bold;font-size: 1.6rem;">Eigenschappen</b>
      <div *ngFor="let property of propertyoption.ProductPropertiesEigenschappen" style="padding: 15px;">
        <display-product-property [ProductProperty]="property" [ProductVariantId]="ProductVariantId" [parentproductname]="ProductProperty.Name" (onRefresh)="Refresh()"></display-product-property>
      </div>
      <div>
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Name</mat-label>
          <input matInput [(ngModel)]="NewEigenschapName" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="AddEigenschap(propertyoption.Id)">Add</button>
      </div>
    </div>
  </div>
  <div *ngIf="ProductProperty.ManualAdded">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="NewOptionName" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="AddOption()">Add</button>
  </div>
</div>
