/**
 * @ref WoonConnectViewModel/User/UserRetrieveModel.cs
 */

 import * as DataContracts from "@datacontracts/UserRetrieveModel";

 
import * as UserRetrieveModelEnum from "@enums/UserRetrieveModel";
import * as UserViewModel from "./UserViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.User.UserRetrieveCriterea
	 */
	 

	export interface IUserRetrieveCriterea {
		UserRetrieveCritereaType: UserRetrieveModelEnum.UserRetrieveCritereaType;
		Critera: string;
		ToJsonContract(): DataContracts.IUserRetrieveCriterea;
	}

	export class UserRetrieveCriterea {
		// property-Properties
		public get UserRetrieveCritereaType(): UserRetrieveModelEnum.UserRetrieveCritereaType {
			return this._UserRetrieveCritereaType;
		}
		public set UserRetrieveCritereaType(newUserRetrieveCritereaType: UserRetrieveModelEnum.UserRetrieveCritereaType) {
			this._UserRetrieveCritereaType = newUserRetrieveCritereaType;
		}

		public get Critera(): string {
			return this._Critera;
		}
		public set Critera(newCritera: string) {
			this._Critera = newCritera;
		}

		// field-Properties

		// fields
		protected _UserRetrieveCritereaType: UserRetrieveModelEnum.UserRetrieveCritereaType = undefined;
		protected _Critera: string = "";

		constructor(data?: DataContracts.IUserRetrieveCriterea) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserRetrieveCriterea): void {
			if(data) {
				// vul properties
				this._UserRetrieveCritereaType = data.UserRetrieveCritereaType;
				this._Critera = data.Critera;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUserRetrieveCriterea {
			return {
				UserRetrieveCritereaType: this.UserRetrieveCritereaType,
				Critera: this.Critera
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.UserRetrieveRequestModel
	 */
	 

	export interface IUserRetrieveRequestModel {
		UserId: number;
		SearchCriterea: UserRetrieveCriterea[];
		PageSize: number;
		Page: number;
		sortOrder: string;
		sortDirection: string;
		ToJsonContract(): DataContracts.IUserRetrieveRequestModel;
	}

	export class UserRetrieveRequestModel {
		// property-Properties
		public get UserId(): number {
			return this._UserId;
		}
		public set UserId(newUserId: number) {
			this._UserId = newUserId;
		}

		public get SearchCriterea(): UserRetrieveCriterea[] {
			return this._SearchCriterea;
		}
		public set SearchCriterea(newSearchCriterea: UserRetrieveCriterea[]) {
			this._SearchCriterea = newSearchCriterea;
		}

		public get PageSize(): number {
			return this._PageSize;
		}
		public set PageSize(newPageSize: number) {
			this._PageSize = newPageSize;
		}

		public get Page(): number {
			return this._Page;
		}
		public set Page(newPage: number) {
			this._Page = newPage;
		}

		public get sortOrder(): string {
			return this._sortOrder;
		}
		public set sortOrder(newsortOrder: string) {
			this._sortOrder = newsortOrder;
		}

		public get sortDirection(): string {
			return this._sortDirection;
		}
		public set sortDirection(newsortDirection: string) {
			this._sortDirection = newsortDirection;
		}

		// field-Properties

		// fields
		protected _UserId: number = 0;
		protected _SearchCriterea: UserRetrieveCriterea[] = [];
		protected _PageSize: number = 0;
		protected _Page: number = 0;
		protected _sortOrder: string = "";
		protected _sortDirection: string = "";

		constructor(data?: DataContracts.IUserRetrieveRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserRetrieveRequestModel): void {
			if(data) {
				// vul properties
				this._UserId = data.UserId;
				this._SearchCriterea = data.SearchCriterea ? data.SearchCriterea.map(userRetrieveCritereaItem => new UserRetrieveCriterea(userRetrieveCritereaItem)): [];
				this._PageSize = data.PageSize;
				this._Page = data.Page;
				this._sortOrder = data.sortOrder;
				this._sortDirection = data.sortDirection;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUserRetrieveRequestModel {
			return {
				UserId: this.UserId,
				SearchCriterea: (this.SearchCriterea ? this.SearchCriterea.map(userRetrieveCritereaItem => new UserRetrieveCriterea(userRetrieveCritereaItem).ToJsonContract()): []),
				PageSize: this.PageSize,
				Page: this.Page,
				sortOrder: this.sortOrder,
				sortDirection: this.sortDirection
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.UserDetailRequestModel
	 */
	 

	export interface IUserDetailRequestModel {
		Id: number;
		Guid: string;
		ToJsonContract(): DataContracts.IUserDetailRequestModel;
	}

	export class UserDetailRequestModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Guid(): string {
			return this._Guid;
		}
		public set Guid(newGuid: string) {
			this._Guid = newGuid;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Guid: string = "";

		constructor(data?: DataContracts.IUserDetailRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserDetailRequestModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Guid = data.Guid;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUserDetailRequestModel {
			return {
				Id: this.Id,
				Guid: this.Guid
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.SortOrderViewModel
	 */
	 

	export interface ISortOrderViewModel {
		Array: string;
		Direction: number;
	}

	export class SortOrderViewModel {
		// property-Properties
		public get Array(): string {
			return this._Array;
		}
		public set Array(newArray: string) {
			this._Array = newArray;
		}

		public get Direction(): number {
			return this._Direction;
		}
		public set Direction(newDirection: number) {
			this._Direction = newDirection;
		}

		// field-Properties

		// fields
		protected _Array: string = "";
		protected _Direction: number = 0;

		constructor(data?: DataContracts.ISortOrderViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISortOrderViewModel): void {
			if(data) {
				// vul properties
				this._Array = data.Array;
				this._Direction = data.Direction;
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.UserRetrieveResponseModel
	 */
	 

	export interface IUserRetrieveResponseModel {
		Users: UserViewModel.IUserViewModel[];
	}

	export class UserRetrieveResponseModel {
		// property-Properties
		public get Users(): UserViewModel.IUserViewModel[] {
			return this._Users;
		}
		public set Users(newUsers: UserViewModel.IUserViewModel[]) {
			this._Users = newUsers;
		}

		// field-Properties

		// fields
		protected _Users: UserViewModel.IUserViewModel[] = [];

		constructor(data?: DataContracts.IUserRetrieveResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserRetrieveResponseModel): void {
			if(data) {
				// vul properties
				this._Users = data.Users ? data.Users.map(userViewModelItem => new UserViewModel.UserViewModel(userViewModelItem)): [];
				// vul fields
			}
		}
	}
