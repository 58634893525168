// [conv] import "(./FabAdminFilters).less";
import { Component, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FiltersConsumer } from "@consumers/FiltersController";
import {
    FiltersViewModel,
    FilterOptionsViewModel,
    FilterOptionsVariantRelationsViewModel,
   } from "@models/FilterViewModel";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ProductsConsumer } from '@consumers/ProductsController';
import { ProductVariantModel, ProductVariantsViewModel, FilterOptionVariantRelationLink } from '@models/ProductsViewModel';



@Component({
    selector: 'filteroption-variant',
  templateUrl: './FabAdminFilterOptionVariant.html'
})
export class FabAdminFilterOptionVariantComponent {
    Laden: boolean = true;
    Filters: FiltersViewModel;
    ProductVariants: ProductVariantsViewModel = new ProductVariantsViewModel();
    SearchInput: string;
    InputField: string;
    Results: number = 0;
    deleteOpposite: boolean = false;
    addRelation: boolean = true;
    SelectedOption: number = 0;
    SelectedOptionName: string = "Niks geselecteerd";
    searchVariant: ProductVariantModel = new ProductVariantModel();
    FilterOptionVariantLink: FilterOptionVariantRelationLink;


    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


    constructor(private route: ActivatedRoute, private filter: FiltersConsumer, private prod: ProductsConsumer) {
        this.Refresh();
    }

    public Refresh(): void {
        //alle filters en filteroptions ophalen 
        this.Filters = new FiltersViewModel();
        this.filter.GetFilters_Observable(this.Filters).subscribe((data) => {
            console.log(data);
            this.Filters = new FiltersViewModel(data);
        })


        //alle product varianten ophalen. 
        //Op basis van zoekveld

        //wanneer een filter optie geselecteerd is alle producten die een relatie hebben ophalen, en allee producten die geen relatie hebben ook ophalen. 
    }
    Herlaad() {
        this.Refresh();
    }

    searchVariants() {
        console.log("Gezocht");
        console.log(this.searchVariant);

        alert("todo")
        // this.filter.GetAllVariantsWithLink_Observable(this.searchVariant).subscribe((data) => {
        //     console.log(data);
        //     this.ProductVariants = new ProductVariantsViewModel(data);
        //     this.Results = this.ProductVariants.ProductVariant.length;
        // })

    }
    searchOpposite() {
        console.log("Tegenovergestelde gezocht");

        this.deleteOpposite = true;

        alert("todo");
        // this.filter.FilterOptionVariantRelationOpposite_Observable(this.searchVariant).subscribe((data) => {
        //     console.log(data);
        //     this.ProductVariants = new ProductVariantsViewModel(data);
        //     this.Results = this.ProductVariants.ProductVariant.length;
        // })
    }

    public selectOption(id, name) {
        this.SelectedOption = id;
        this.SelectedOptionName = name;
    }

    addFilterOptionRelation() {
        
        if (this.SelectedOption == 0) {
            alert("Selecteer een Filter Option");
        } else {
            this.FilterOptionVariantLink = new FilterOptionVariantRelationLink();

            this.FilterOptionVariantLink.FilterOption = this.SelectedOption;
            this.FilterOptionVariantLink.ProductVariant = this.searchVariant;

            this.FilterOptionVariantLink.Active = this.addRelation;

            if (confirm("Weet je zeker dat je deze varianten" + this.Results + " de Filter Optie:" + this.SelectedOption + "wilt mee geven?")) {
                if (this.deleteOpposite) {
                    alert("todo");
                    // this.filter.DeleteFilterOptionsVariantRelationOpposite_Observable(this.FilterOptionVariantLink).subscribe((data) => {
                    //     console.log(data);
                    // })
                } else {
                    alert("todo");
                    // this.filter.FilterOptionVariantRelationLink_Observable(this.FilterOptionVariantLink).subscribe((data) => {
                    //     console.log(data);
                    // })
                }
            
            } else {
                console.log("Gestopt!");
            }
           
        }

    }

   
}
