<div class="container">
    <div>
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Id
                </th>
                <td mat-cell *matCellDef="let data">
                    <div *ngIf="data.Id">
                        {{data.Id}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Name
                </th>
                <td mat-cell *matCellDef="let data">
                    <div *ngIf="data.Name">
                        {{data.Name}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Type
                </th>
                <td mat-cell *matCellDef="let data">
                    <div *ngIf="data.Type">
                        {{data.Type}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Products">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Relations
                </th>
                <td mat-cell *matCellDef="let data">
                    <div *ngIf="data.Type">
                        {{data.Products}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Filters">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Filters
                </th>
                <td mat-cell *matCellDef="let data">
                    <div *ngIf="data.Type">
                        {{data.Filters}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Variants">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Varianten
                </th>
                <td mat-cell *matCellDef="let data">
                    <div *ngIf="data.Type">
                        {{data.Variants}}
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [routerLink]="['/fab-admin/product-property/', row.Id]"></tr>

            <!-- Row shown when there is no matching data. -->
            <!-- <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr> -->

        </table>
    </div>
    <div>
       <button mat-raised-button color="primary" (click)="DeleteAll()">Delete All</button>
    </div>
</div>