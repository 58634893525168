/// <reference path='../consumerinterfaces/InfraController.ts' />
/**
 * @ref WoonConnectApiController/Configurator/InfraController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/InfraController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as InfraStatus from "@datacontracts/InfraStatus";
import * as ConstRequestModel from "@datacontracts/ConstRequestModel";
import * as request from "../../../helpers/IRequestHandler";





	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class InfraConsumer implements Interface.IInfraConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public Status_Observable(): Observable<InfraStatus.IInfraStatus> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Infra/Status`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<InfraStatus.IInfraStatus>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Status(): ng.IPromise<InfraStatus.IInfraStatus> {
				let obs = this.Status_Observable();
				let promise = new Promise<InfraStatus.IInfraStatus>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public Const_Observable(inputModel: ConstRequestModel.IConstRequestModel & {ToJsonContract(noContent?: boolean): ConstRequestModel.IConstRequestModel}): Observable<ConstRequestModel.IConstResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `/api/Infra/Const`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ConstRequestModel.IConstResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Const(inputModel: ConstRequestModel.IConstRequestModel & {ToJsonContract(noContent?: boolean): ConstRequestModel.IConstRequestModel}): ng.IPromise<ConstRequestModel.IConstResponseModel> {
				let obs = this.Const_Observable(inputModel);
				let promise = new Promise<ConstRequestModel.IConstResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}
