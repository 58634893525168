import { Component } from '@angular/core';
import * as _ from 'lodash';

import { UserManagementConsumer } from '@consumers/UserManagementController';
import { RolesConsumer } from '@consumers/RolesController';


import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailRequestModel } from '@models/UserRetrieveModel';
import { UserDetailViewModel, UserSecurityViewModel, VerificationViewModel } from '@models/UserViewModel';
import { UserChangePasswordRequestModel } from '@models/UserChangePasswordModel';
import { AddUserToRoleRequest, RemoveUserFromRoleRequest, RolesViewModel, RoleViewModel } from '@models/RolesViewModel';
import { BuildingsViewModel, BuildingUserLinkRequest } from '@models/BuildingViewModels';
import { BuildingManagementConsumer } from '@consumers/BuildingManagementController';
import { ProjectSettingsService } from '../../services/ProjectSettingsService';
import { ProjectService } from '../../services/ProjectService';
import { IProjectSettingsAngular } from '@datacontracts/ProjectSettingsModel';
import { UserDeleteRequestModel } from 'src/app/data/woonconnectkern/models/UserDeleteModel';

@Component({
    selector: 'wct-admin-user-detail',
    templateUrl: './WctAdminUserDetail.html',
    styleUrls: ['./WctAdminUserDetail.scss']
})
export class WctAdminUserDetailComponent {
    UserId: string;
    projectSettings: Array<IProjectSettingsAngular> = []
    UserDetailRequestModel: UserDetailRequestModel = new UserDetailRequestModel();
    userDetail: UserDetailViewModel = new UserDetailViewModel();
    newPassword: string;
    verification: VerificationViewModel = new VerificationViewModel();
    UserChangePasswordRequestModel: UserChangePasswordRequestModel = new UserChangePasswordRequestModel();
    AddRole: AddUserToRoleRequest = new AddUserToRoleRequest();
    Roles: RolesViewModel;
    UserRoles: RolesViewModel;
    BuildingsViewModel: BuildingsViewModel = new BuildingsViewModel();
    BuildingUserLinkRequest: BuildingUserLinkRequest = new BuildingUserLinkRequest();
    Security: UserSecurityViewModel = new UserSecurityViewModel();

    constructor(private user: UserManagementConsumer, private route: ActivatedRoute, private router: Router, private roles: RolesConsumer, private building: BuildingManagementConsumer, private projectService: ProjectService, private projectSettingsService: ProjectSettingsService) {
        this.UserId = (<any>this.route.params).value.userid;

        this.UserDetailRequestModel.Id = parseInt(this.UserId);
        this.AddRole.UserId = parseInt(this.UserId);

        this.projectSettingsService.GeefProjectSettingsHuidigeProject(this.projectService.GeefProjectId()).subscribe(response => {
            this.projectSettings = response;
        });

        this.Refresh();
    }

    public openMainDwelling()
    {
        this.router.navigate([ '/admin/building-detail/', this.userDetail.Persoon.MainResidentDwelling.Id]);

        //[routerLink]="['/admin/building-detail/', row.Id]"></tr>
    }

    public Refresh(): void {

        //get all information from one user. 
        this.user.GetUser_Observable(this.UserDetailRequestModel).subscribe((data) => {
            this.userDetail = new UserDetailViewModel(data);

            this.verification.Verificatie = this.userDetail.Persoon.Verificatie;
            this.verification.VerificatieCode = this.userDetail.Persoon.VerificatieCode;
            this.verification.Id = this.userDetail.Persoon.PersoonId;

            this.Security = this.userDetail.UserSecurity;
        })

        this.roles.RetrieveAllRoles_Observable().subscribe((data) => {

            this.Roles = new RolesViewModel(data);
        })

        this.roles.RetrieveUserRoles_Observable(this.UserDetailRequestModel).subscribe(data => {
            this.UserRoles = new RolesViewModel(data);
        })

        this.building.GetAllBuildings_Observable(this.BuildingsViewModel).subscribe((data) => {
            this.BuildingsViewModel = new BuildingsViewModel(data);
        })

    }

    public updateFields()
    {
        this.user.SaveUser_Observable(this.userDetail).subscribe(x =>
            {
                this.Refresh();
            });
    }

    public setPassword() {
        //userId
        //password string;

        if (confirm("Weet je zeker dat je het wachtwoord wilt wijzigen?")) {
            this.UserChangePasswordRequestModel.UserId = this.UserId;
            this.UserChangePasswordRequestModel.NewPassword = this.newPassword;

            this.user.ResetPassword_Observable(this.UserChangePasswordRequestModel).subscribe((data) => {
                if (data.Succeeded) {
                    alert('Wachtwoord gewijzigd');
                }
                else {
                    alert('Wachtwoord wijziging mislukt' + data.Message);
                }
                console.log(data);
            })

        } else {
            console.log("Wachtwoord wijzigen geannuleerd!");
        }
    }

    public saveRoles() {
        this.roles.AddUserToRoles_Observable(this.AddRole).subscribe(response => {
            if (response.Succeed) {
                alert(response.Message);
                this.Refresh();
            } else {
                alert('Er ging iets mis. Rol toevoegen mislukt');
            }
        });
    }

    VerwijderRol(rol: RoleViewModel)
    {
        let removeRole = new RemoveUserFromRoleRequest();
        removeRole.UserId = parseInt(this.UserId);
        removeRole.Rol = rol.Rol;
        this.roles.RemoveUsersFromRoles_Observable(removeRole).subscribe(response => {
            this.Refresh();
        });
    }

    //create save for user 

    public onChange(event) {

    }

    private checkChecked(projectSetting): boolean {
        if (typeof (projectSetting.Waarde) === 'string') {
            return projectSetting.Waarde.toLowerCase() === 'true';
        } else if (typeof (projectSetting.Waarde) === 'boolean') {
            return projectSetting.Waarde;
        }
        return false;
    }

    public onLockedoutChange() {
        let req = new UserDetailRequestModel();
        req.Guid = this.userDetail.PersoonExtra.UserId;

        if (!this.Security.IsLockedOut) {
            if (confirm('Weet je zeker dat je de user wil deblokkeren?')) {
                this.user.UnlockUser_Observable(req).subscribe((data) => {
                    this.Refresh();
                })
            }
            else
            {
                this.Refresh();
            }
        }
        else {
            if (confirm('Weet je zeker dat je de user wil blokkeren?')) {
                this.user.LockUser_Observable(req).subscribe((data) => {
                    this.Refresh();
                })
            }
            else
            {
                this.Refresh();
            }
        }
    }

    public onVerificationChange() {
        let verificationCodeLength = parseInt(this.projectSettings.find(setting => setting.Naam === 'VerificatieCodeLengte').Waarde);
        let verificationCodeUsableChars = this.projectSettings.find(setting => setting.Naam === 'VerificatieCodeUseableChars').Waarde;
        if (this.verification) {
            let result = '';
            let numberOfCharacters = verificationCodeUsableChars.length;
            for (var i = 0; i < verificationCodeLength; i++) {
                result += verificationCodeUsableChars.charAt(Math.floor(Math.random() * numberOfCharacters));
            }

            this.verification.VerificatieCode = result;
        }

        if (confirm('Weet je zeker dat je de verificatie wilt wijzigen??')) {
            // Save it!
            this.user.SetVerification_Observable(this.verification).subscribe((data) => {
                this.Refresh();
            })
        } else {
            // Do nothing!
            this.Refresh();
        }
    }

    remove()
    {
        if (confirm("Gebruiker verwijderen?"))
        {
            let req = new UserDeleteRequestModel();
            req.Persoonid = parseInt(this.UserId);
            this.user.Delete_Observable(req).subscribe(x =>
                {
                    alert('done');
                    this.router.navigate([ '/admin/users' ]);
                });

        }

    }

    public deblockUser() {
        this.UserDetailRequestModel.Guid = this.UserId;
        this.UserDetailRequestModel.Id = parseInt(this.UserId);

        if (confirm("Weet je zeker dat je deze user wilt deblokkeren?")) {
            this.user.UnlockUser_Observable(this.UserDetailRequestModel).subscribe((data) => {
                console.log(data);
            })
        } else {
            console.log("Deblokkeren geannulleerd!");
        }
    }


}
