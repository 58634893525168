import { Component, Input, OnInit } from '@angular/core';
import { ISurveyCalculationZone } from '@models/SurveyCalculationZone';
import { FormGroupState } from 'ngrx-forms';
import { ConstructionMass, ConduitType } from '@enums/SurveyCalculationZone';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'wct-survey-calculation-zone',
  templateUrl: './wct-survey-calculation-zone.component.html',
  styleUrls: ['./wct-survey-calculation-zone.component.scss']
})
export class WctSurveyCalculationZoneComponent implements OnInit {

  @Input() system : FormGroupState<ISurveyCalculationZone> = null;

  get control() {
    return this.system.controls;
  }

  // make enum accessibly in the HTML
  public ConstructionMass = ConstructionMass;
  public ConduitType = ConduitType;

  constructor()
  {
  }

  ngOnInit(): void {
    //console.log(this.system);
  }
}
