import * as HelpersBackwards from '../Helpers/Backwards';
import * as DataContractsTekeningConfiguratie from '@datacontracts/TekeningConfiguratie';
import * as ServicesProjectService from '../services/ProjectService';
import { TekeningConfiguratie } from '@models/TekeningConfiguratie';
import * as ServicesWctLogService from '../services/WctLogService';
import * as ServicesWeergaveService from '../services/WeergaveService';
import * as ServicesWoningEventService from '../services/WoningEventService';
import * as ServicesOptieService from '../services/OptieService';
import * as HelpersOverlay from '../Helpers/Overlay';
import { Component, Input, OnDestroy, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewChecked, HostBinding, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { HostListener } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { OptieEventService } from '../services/optieeventservice';
import { TekeningConfiguratieMode } from '@enums/TekeningConfiguratie';
import { Geometry2DConsumer } from '@consumers/Geometry2DController';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
// interface IViewer2DBindings {
// 	cameraindex: string;
// 	invalshoek: string;
// 	tekeningnaam: string;
// 	woningid: number;
// 	scope: number;
// 	meldingid: number;
// 	scenarioid: number;
// 	endpoint: string;
// 	endpointevent: number;
// }

// interface IViewer2DController extends IViewer2DBindings {
// 	BezigViewModel: HelpersOverlay.IBezigState;
// }


@Component({
	selector: 'wct-viewer-2d',
  templateUrl: './WctViewer2d.html',
  styleUrls: ['./Wctviewer2d.scss']
})
export class Viewer2DComponent implements OnDestroy, OnChanges, AfterViewChecked {
	@Input() endpoint: string;
	@Input() endpointevent: number;
	@Input() tekeningnaam: string;
	@Input() invalshoek: string;

	@Input() cameraindex: string;
	@Input() woningid: number;
	@Input() scenarioid: number;
	@Input() scope: number;
	@Input() meldingid: number;
	@Input() mode: TekeningConfiguratieMode;

	public model: string;

	public Variant: string;// SafeUrl = this.sanitizer.bypassSecurityTrustUrl("");

	public BezigViewModel: HelpersOverlay.IBezigState;

	public mHuidige_cameraindex: number;
	public mHuidige_invalshoek: string;
	public mHuidige_tekeningnaam: string;
	public mHuidige_meldingid: number;
	public mHuidige_woningid: number;
	public mHuidige_scenarioid: number;
	public mHuidige_scope: number;
	public mHuidige_endpointevent: number;
	public mHuidige_endpoint: string;

	private SVGWidth = 630;
	private SVGHeight = 700;
	private eerstekeer = true;
	private bezigMetLaden = false;
	private requestUitgesteld = false;
	Subscription: Subscription;
	mHuidige_width: number = -1;
	mHuidige_height: number = -1;

	constructor(
		private readonly $http: HttpClient,
		private readonly optieeventservice: OptieEventService,
		private readonly woningeventservice: ServicesWoningEventService.WoningEventService,
		private readonly $log: ServicesWctLogService.WctLogService,
		private readonly projectservice: ServicesProjectService.ProjectService,
		private readonly geometryconsumer: Geometry2DConsumer,
		private readonly sanitizer: DomSanitizer,
		private renderer: Renderer2,
		//private $timeout: ng.ITimeoutService

	) {
		'ngInject';

		this.BezigViewModel = HelpersOverlay.BezigState.Default();
		this.BezigViewModel.Diameter = 100;

		this.Subscription = this.optieeventservice.OptieAangepastEvent.RegisterHandler(null, () => {
			this.UpdateViewer();
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		this.UpdateViewer();
	}

	DataUrl(): string {
		return '/Toon2D/SVG?Variant=' + this.Variant;
	}

	@ViewChild('viewer2dcontainerdiv') viewerContainerDiv: ElementRef;

	ngAfterViewChecked() {
		this.UpdateViewer();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.UpdateViewer();
	}

	// @HostBinding('style.height.px')
	// elHeight:number;

	ngOnDestroy(): void {
		this.Subscription.unsubscribe();
	}

	public $onInit(): void {

	}

	public $onChanges(changes: any): void {
		//this.$timeout(100, true).then(r => {
		this.UpdateViewer();
		//});
	}

	public m_WoningEvent = -1;
	public m_HuidigeWoningScope: ServicesOptieService.ScopeSelectie;

	private KlaarMetLaden() {
		this.$log.debug('Klaar met laden!');

		this.BezigViewModel.ToonOverlay = false;
		this.bezigMetLaden = false;

		if (this.requestUitgesteld) {
			this.$log.debug('Request uigesteld, opnieuw updaten');
			this.requestUitgesteld = false;
			this.UpdateViewer();
		}
	}

	private StartMetLaden() {
		this.$log.debug('Start met laden loader!');

		this.bezigMetLaden = true;
		this.BezigViewModel.ToonOverlay = true;
	}

	public UpdateViewer(): void {
		//this.$log.debug('UpdateViewer aangeroepen');

		if (this.woningid <= 0) {
			return;
		}

		if (this.invalshoek == null) {
			return;
		}

		if (this.tekeningnaam == null || this.tekeningnaam == "") {
			return;

		}

		var scope = this.woningeventservice.GeefScopeSelectie();
		var ev = this.optieeventservice.GeefEventID(scope);

		let lokaleCameraIndex = -1;
		if (this.cameraindex != null && this.cameraindex.length > 0) {
			lokaleCameraIndex = parseInt(this.cameraindex);
		}

		let lokaleMeldingId = this.meldingid;

		let lokaleInvalshoek = this.invalshoek;
		let lokaleScope = this.scope;
		let lokaleTekeningnaam = this.tekeningnaam;
		let lokaleWoningId = this.woningid;
		let lokaleScenarioId = this.scenarioid;
		let lokaleEndpoint = this.endpoint;
		let lokaleEndpointEvent = this.endpointevent;

		var redrawNodig = this.m_WoningEvent != ev || ev === undefined || lokaleWoningId != this.mHuidige_woningid;// || this.m_HuidigeWoningScope == null || scope == null || !scope.Gelijk(this.m_HuidigeWoningScope);

		var tekeningAangepast = (this.mHuidige_cameraindex != lokaleCameraIndex ||
			this.mHuidige_invalshoek != lokaleInvalshoek ||
			this.mHuidige_tekeningnaam != lokaleTekeningnaam ||
			this.mHuidige_meldingid != lokaleMeldingId ||
			this.mHuidige_woningid != lokaleWoningId ||
			this.mHuidige_scenarioid != lokaleScenarioId ||
			this.mHuidige_scope != lokaleScope ||
			this.mHuidige_endpoint != lokaleEndpoint ||
			this.mHuidige_endpointevent != lokaleEndpointEvent);

		if (this.viewerContainerDiv == null)
		{
			this.$log.debug('viewerContainerDiv not available yet');
			return;
		}

		let rect = this.viewerContainerDiv.nativeElement.getBoundingClientRect();

		//var width = this.viewerContainerDiv.nativeElement.clientWidth;
		//var height = this.viewerContainerDiv.nativeElement.clientHeight;
		let width = rect.width;
		let height = rect.height;

		if (width < 10 || height < 10)
		{
			// wait for correct draw
			this.$log.debug('2D with|height < 10');
			return;
		}

		if (width != this.mHuidige_width || height != this.mHuidige_height)
		{
			tekeningAangepast = true;
		}

		if (tekeningAangepast) {
			//this.$log.debug('Viewer: tekeningAangepast oud-nieuw camera ' + this.mHuidige_cameraindex + '-' + lokaleCameraIndex + ', invalshoek ' + this.mHuidige_invalshoek + '-' + lokaleInvalshoek + ', tekening ' + this.mHuidige_tekeningnaam + '-' + lokaleTekeningnaam);
		}

		if (redrawNodig) {
			var huidigeScope = "(null)";
			if (this.m_HuidigeWoningScope != null) {
				huidigeScope = this.m_HuidigeWoningScope.Sleutel;
			}

			//this.$log.debug('Viewer: redrawNodig oud-nieuw event ' + this.m_WoningEvent + '-' + ev + ", scope " + huidigeScope + "-" + scope.Sleutel);
		}

		if (this.bezigMetLaden) {
			this.requestUitgesteld = true;
			this.$log.debug('Request wordt uitgesteld, exit.');
			return;
		}

		if (tekeningAangepast || redrawNodig) {
			var div = $("#wct-viewer-2D");
			var div2 = $("wct-viewer-2D");

			if (div.width() != undefined) {
				this.SVGWidth = div.width();
			} else if (div2.width() != undefined) {
				this.SVGWidth = div2.width();
			}

			if (div.height() != undefined) {
				this.SVGHeight = div.height();
			} else if (div2.height() != undefined) {
				this.SVGHeight = div2.height();
			}

			this.m_WoningEvent = ev;
			this.m_HuidigeWoningScope = scope;

			this.StartMetLaden();

			const newObj: TekeningConfiguratie = new TekeningConfiguratie({
				WoningId: lokaleWoningId,
				Scenario: lokaleScenarioId,
				CameraIndex: lokaleCameraIndex,
				MeldingId: lokaleMeldingId,
				AchtergrondKleur: undefined,
				GebruikEditView: undefined,
				Invalshoek: lokaleInvalshoek,
				Mode: this.mode == null ? TekeningConfiguratieMode.Normaal : this.mode,
				Navigeerbaar: true,
				OverruleBreedte: Math.round(width),
				OverruleHoogte: Math.round(height),
				TekeningNaam: lokaleTekeningnaam,
				ProjectId: this.projectservice.GeefProjectId(),
				Scope: lokaleScope
			});

			var endpointToCall = '/Configurator/Toon2DNew';
			if (lokaleEndpoint != null) {
				endpointToCall = lokaleEndpoint;
			}

			this.geometryconsumer.Massa2D(newObj).then(d => {

				//this.$log.debug('Toon2DNew aangevraagd voor woning ' + lokaleWoningId + ', invalshoek ' + lokaleInvalshoek + ', tekening ' + lokaleTekeningnaam + ', camera ' + lokaleCameraIndex);
				//this.$http.post(endpointToCall, { data: JSON.stringify(newObj) })
				//.toPromise().then((d: ng.IHttpPromiseCallbackArg<string>) => {

				this.$log.debug('Toon2DNew terug');

				//this.Variant = this.sanitizer.bypassSecurityTrustUrl(d.Id);
				this.Variant = d.Id;
				this.mHuidige_cameraindex = lokaleCameraIndex;
				this.mHuidige_invalshoek = lokaleInvalshoek;
				this.mHuidige_tekeningnaam = lokaleTekeningnaam;
				this.mHuidige_woningid = lokaleWoningId;
				this.mHuidige_meldingid = lokaleMeldingId;
				this.mHuidige_woningid = lokaleWoningId;
				this.mHuidige_scenarioid = lokaleScenarioId;
				this.mHuidige_scope = lokaleScope;
				this.mHuidige_endpoint = lokaleEndpoint;
				this.mHuidige_endpointevent = lokaleEndpointEvent;
				this.mHuidige_width = width;
				this.mHuidige_height  = height;
				//var element = $("#wct-viewer2d-div");

				if (HelpersBackwards.wct_detect_ie()) {
					this.$http.get(`/Toon2D/SVGasFile?Variant=${this.Variant}`)
						.toPromise().then((svg: ng.IHttpPromiseCallbackArg<string>) => {
							var html = `<object id="ObjectViewer2D" style="overflow:hidden;" data="${svg.data}"></object>`;
							div.html(html);

							this.KlaarMetLaden();
						});
				}
				else {
					this.KlaarMetLaden();
				}
			}).catch((err) => {
				alert('Fout opgetreden bij het ophalen van de 2D tekening');
				this.KlaarMetLaden();
			});
		}
	}
}
