// [conv] import "(./Buildings).less";
import { Component, Input, ViewChild } from '@angular/core';
import * as _ from 'lodash';


import { BuildingManagementConsumer } from '@consumers/BuildingManagementController';
import { BuildingViewModel } from '@models/BuildingViewModels';





@Component({
    selector: 'add-building',
  templateUrl: './AddBuilding.html'
})
export class WoonAddBuildingComponent {
    newBuilding: BuildingViewModel = new BuildingViewModel();

    constructor(private building: BuildingManagementConsumer) {
       

    }


    onAddBuilding() {
       
        console.log(this.newBuilding)
    }



}
