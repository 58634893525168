/**
 * @ref WoonConnectViewModel/Scene/CalculateFragmentModel.cs
 */

 import * as DataContracts from "@datacontracts/CalculateFragmentModel";

 
import * as ISceneServiceEnum from "@enums/ISceneService";



	/**
	 * Originele namespace: WoonConnectViewModel.Scene.CalculateFragmentRequestModel
	 */
	 

	export interface ICalculateFragmentRequestModel {
		SceneSessionID: string;
		fragmentid: string;
		fragmentwaarde: string;
		snapshot: string;
		projectid: number;
		fragmentprojectid: number;
		ToJsonContract(): DataContracts.ICalculateFragmentRequestModel;
	}

	export class CalculateFragmentRequestModel {
		// property-Properties
		// field-Properties
		public get SceneSessionID(): string {
			return this._SceneSessionID;
		}
		public set SceneSessionID(newSceneSessionID: string) {
			this._SceneSessionID = newSceneSessionID;
		}
		
		public get fragmentid(): string {
			return this._fragmentid;
		}
		public set fragmentid(newfragmentid: string) {
			this._fragmentid = newfragmentid;
		}
		
		public get fragmentwaarde(): string {
			return this._fragmentwaarde;
		}
		public set fragmentwaarde(newfragmentwaarde: string) {
			this._fragmentwaarde = newfragmentwaarde;
		}
		
		public get snapshot(): string {
			return this._snapshot;
		}
		public set snapshot(newsnapshot: string) {
			this._snapshot = newsnapshot;
		}
		
		public get projectid(): number {
			return this._projectid;
		}
		public set projectid(newprojectid: number) {
			this._projectid = newprojectid;
		}
		
		public get fragmentprojectid(): number {
			return this._fragmentprojectid;
		}
		public set fragmentprojectid(newfragmentprojectid: number) {
			this._fragmentprojectid = newfragmentprojectid;
		}

		// fields
		protected _SceneSessionID: string = "";
		protected _fragmentid: string = "";
		protected _fragmentwaarde: string = "";
		protected _snapshot: string = "";
		protected _projectid: number = 0;
		protected _fragmentprojectid: number = 0;

		constructor(data?: DataContracts.ICalculateFragmentRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICalculateFragmentRequestModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._SceneSessionID = data.SceneSessionID;
				this._fragmentid = data.fragmentid;
				this._fragmentwaarde = data.fragmentwaarde;
				this._snapshot = data.snapshot;
				this._projectid = data.projectid;
				this._fragmentprojectid = data.fragmentprojectid;
			}
		}

		public ToJsonContract(): DataContracts.ICalculateFragmentRequestModel {
			return {
				SceneSessionID: this.SceneSessionID,
				fragmentid: this.fragmentid,
				fragmentwaarde: this.fragmentwaarde,
				snapshot: this.snapshot,
				projectid: this.projectid,
				fragmentprojectid: this.fragmentprojectid
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Scene.CalculateFragmentResponseModel
	 */
	 

	export interface ICalculateFragmentResponseModel {
		Resultaat: ISceneServiceEnum.CalculateFragmentResult;
	}

	export class CalculateFragmentResponseModel {
		// property-Properties
		public get Resultaat(): ISceneServiceEnum.CalculateFragmentResult {
			return this._Resultaat;
		}
		public set Resultaat(newResultaat: ISceneServiceEnum.CalculateFragmentResult) {
			this._Resultaat = newResultaat;
		}

		// field-Properties

		// fields
		protected _Resultaat: ISceneServiceEnum.CalculateFragmentResult = undefined;

		constructor(data?: DataContracts.ICalculateFragmentResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICalculateFragmentResponseModel): void {
			if(data) {
				// vul properties
				this._Resultaat = data.Resultaat;
				// vul fields
			}
		}
	}
