<div class="content">
  <h1>Categorieën</h1>

  <div *ngIf="Loading" class="mat-progress-spinner__cont">
    <div style="padding-top: 300px; padding-bottom: 300px; text-align: center;">
      <div>
        <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
      </div>
      <div>
        <p>De categorieën worden geladen</p>
      </div>
    </div>
  </div>

  <div *ngIf="!Loading">
    <div *ngFor="let categorie of ProductsViewModel.Products">
      <h2>{{ categorie.Name }}</h2>
      Eigenschappen: {{ GetEigenschappen(categorie) }}
    </div>
  </div>

</div>

