/**
 * @ref WoonConnectViewModel/Documenten/GeefDocumentenInputModel.cs
 */

 import * as DataContracts from "@datacontracts/GeefDocumentenInputModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Documenten.GeefDocumentenInputModel
	 */
	 

	export interface IGeefDocumentenInputModel {
		PersoonId: number;
		Archief: boolean;
		MapTypes: number[];
		ToJsonContract(): DataContracts.IGeefDocumentenInputModel;
	}

	export class GeefDocumentenInputModel {
		// property-Properties
		// field-Properties
		public get PersoonId(): number {
			return this._PersoonId;
		}
		public set PersoonId(newPersoonId: number) {
			this._PersoonId = newPersoonId;
		}
		
		public get Archief(): boolean {
			return this._Archief;
		}
		public set Archief(newArchief: boolean) {
			this._Archief = newArchief;
		}
		
		public get MapTypes(): number[] {
			return this._MapTypes;
		}
		public set MapTypes(newMapTypes: number[]) {
			this._MapTypes = newMapTypes;
		}

		// fields
		protected _PersoonId: number = 0;
		protected _Archief: boolean = false;
		protected _MapTypes: number[] = [];

		constructor(data?: DataContracts.IGeefDocumentenInputModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGeefDocumentenInputModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._PersoonId = data.PersoonId;
				this._Archief = data.Archief;
				this._MapTypes = data.MapTypes;
			}
		}

		public ToJsonContract(): DataContracts.IGeefDocumentenInputModel {
			return {
				PersoonId: this.PersoonId,
				Archief: this.Archief,
				MapTypes: this.MapTypes
			}
		}
	}
