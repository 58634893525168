import * as ComponentsmodelWctModelPopup from './WctModelPopup';
import * as ServicesWindowService from './WindowService';
import * as ServicesCapabilitiesService from './CapabilitiesService';
import * as ServicesSceneService from './SceneService';
import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';
import * as ViewModelsWebGLSessie from '../Models/WebGLSessie';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { HostListener } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ModelPopupComponent } from './WctModelPopup';

@Component({
	selector: 'wct-model-viewer3d',
  templateUrl: './WctModelViewer3d.html',
  styleUrls: ['./WctModelViewer3d.scss']
})
export class ModelViewer3dComponent {

	@Input() sessie: ViewModelsWebGLSessie.WebGLSessie;
	@Input() scope: EnumsWijzigScopeEnum.WijzigScopeEnum;
	@Output() onselect = new EventEmitter<string[]>();
	@Input() drawscene: boolean;
	@Input() suppress: boolean;

	nrErrors: number = 0;
	public windowWidth: number = 0;

	constructor(
		private sceneservice: ServicesSceneService.SceneService,
		private dialog: MatDialog,
		private capabilitiesservice: ServicesCapabilitiesService.CapabilitiesService,
	) {
		'ngInject';
		if (this.sceneservice.GebruikWebGL() == false) {
			if (!this.sceneservice.WebGLOndersteuningDialoogGetoond) {
				this.sceneservice.WebGLOndersteuningDialoogGetoond = true;
				this.toonPopup("webGlError");
			}
		}

		//this.windowWidth = $window.innerWidth || document.documentElement.clientWidth;

		// if (this.windowservice !== undefined) {
		// 	this.windowservice.WindowGrootteAangepastEvent.RegisterHandler($scope, () => {
		// 		this.windowWidth = this.windowservice.GeefWindowWidth();
		// 	});
		// }
	}

	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.windowWidth = window.innerWidth;
	}

	public downloadChrome(): string {
		if (this.windowWidth < 768) {
			if (this.capabilitiesservice.IsiOS) {
				return "https://itunes.apple.com/us/app/apple-store/id535886823?pt=9008&ct=help-center-mg&mt=8";
			} else {
				// er van uitgegaan dat het dan een Android tablet is:
				return "https://play.google.com/store/apps/details?id=com.android.chrome&referrer=utm_source%3Dhelp-center%26utm_medium%3Dhc-answer%26utm_campaign%3Dhelp-center-mg";
			}
		} else {
			return "https://www.google.com/chrome/browser/desktop/index.html";
		}
	}

	ToonWeergave: boolean = true;
	ToonHulp: boolean = false;
	ToggleHulp() {
		this.ToonHulp = !this.ToonHulp;
	}

	LadenBezig(): boolean {
		return false;
	}
	LadenVoortgang(): number {
		return 0;
	}
	ToonFPS(): boolean {
		if (this.sessie != null) {
			return this.sessie.FPSActief();
		}
		return false;
	}

	toonPopup(oorzaak: any) {
		let ref = this.dialog.open(ModelPopupComponent);
		ref.componentInstance.oorzaak = oorzaak;
		ref.afterClosed().subscribe(result => {
			//this.dialogRef = null;
		});


		// .show({
		// 	locals: { oorzaak: oorzaak },
		// 	clickOutsideToClose: true,
		// 	template: '<wct-model-popup oorzaak="oorzaak">',
		// 	controller: ComponentsmodelWctModelPopup.WctModelPopupStartController, // LET OP: START CONTROLLER
		// 	onRemoving: () => {
		// 		// code uit te voeren wanneer dialoog gesloten is
		// 	}
		// });
	}

	GebruikWebGL(): boolean {
		var error = this.WebGLError();
		if (error) {
			return false;
		}

		return this.sceneservice.GebruikWebGL();
	}

	WebGLError(): boolean {
		if (this.sessie != null) {
			if (this.sessie.SceneSession != null) {
				var error = this.sessie.SceneSession.HeeftWebGLError();
				if (error) {
					return true;
				}
			}
		}

		return this.sceneservice.WebGLError;
	}

	OnSelect(key: string[]) {
		this.onselect.next(key);
	}
}

