import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserAddRequestModel } from '@models/UserAddModel';
import { UserDetailViewModel, VerificationViewModel } from '@models/UserViewModel';
import { UserManagementConsumer } from '@consumers/UserManagementController';



@Component({
	selector: 'wct-admin-users-dialog',
  styleUrls: ['./WctAdminUsersDialog.scss'],
	templateUrl: './WctAdminUsersDialog.html'
})

export class WctAdminUsersDialog {
  userDetail: UserDetailViewModel = new UserDetailViewModel(undefined);

	constructor(
		public dialogRef: MatDialogRef<WctAdminUsersDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
    private user: UserManagementConsumer
	) {
		'ngInject';
	}

	Close() {
		this.dialogRef.close();
	}

  SaveUser() {
    let addRequest = new UserAddRequestModel();
    addRequest.Voornaam = this.userDetail.Persoon.FirstName;
    addRequest.Achternaam = this.userDetail.Persoon.LastName;
    addRequest.Prive_Email_Adres = this.userDetail.Persoon.Email;
    addRequest.Telefoon_Prive_Nummer = this.userDetail.PersoonExtra.Telefoon;

    this.user.Add_Observable(addRequest).subscribe((data) => {
        if (!data.Succeeded) {
          this.Close();
        } else {
          alert('To do: goede foutmelding maken voor huidige status (als is iets niet goed gegaan bij het opslaan)');
        }
    });
  }
}
