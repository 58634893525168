/// <reference path='../consumerinterfaces/ImageAnnotationController.ts' />
/**
 * @ref WoonConnectApiController/ImageAnnotation/ImageAnnotationController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/ImageAnnotationController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as SaveAnnotationModel from "@datacontracts/SaveAnnotationModel";
import * as GetElementsModel from "@datacontracts/GetElementsModel";
import * as GetPhotosModel from "@datacontracts/GetPhotosModel";
import * as GetAllProjectsModel from "@datacontracts/GetAllProjectsModel";
import * as GetAnnotatorUsersModel from "@datacontracts/GetAnnotatorUsersModel";
import * as AddUsersToProjectModel from "@datacontracts/AddUsersToProjectModel";
import * as GetProjectsFromUserModel from "@datacontracts/GetProjectsFromUserModel";
import * as CreateNewProjectModel from "@datacontracts/CreateNewProjectModel";
import * as GetPhotoSetsModel from "@datacontracts/GetPhotoSetsModel";
import * as AddPhotoSetsToProjectModel from "@datacontracts/AddPhotoSetsToProjectModel";
import * as GetPhotoSetsFromProjectModel from "@datacontracts/GetPhotoSetsFromProjectModel";
import * as SkipPhotoModel from "@datacontracts/SkipPhotoModel";
import * as GetPhotoDataModel from "@datacontracts/GetPhotoDataModel";
import * as AddElementsToProjectModel from "@datacontracts/AddElementsToProjectModel";
import * as AddNewElementModel from "@datacontracts/AddNewElementModel";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class ImageAnnotationConsumer implements Interface.IImageAnnotationConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public SaveAnnotation_Observable(inputModel: SaveAnnotationModel.ISaveAnnotationRequestModel & {ToJsonContract(noContent?: boolean): SaveAnnotationModel.ISaveAnnotationRequestModel}): Observable<SaveAnnotationModel.ISaveAnnotationResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ImageAnnotationController/SaveAnnotation`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<SaveAnnotationModel.ISaveAnnotationResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveAnnotation(inputModel: SaveAnnotationModel.ISaveAnnotationRequestModel & {ToJsonContract(noContent?: boolean): SaveAnnotationModel.ISaveAnnotationRequestModel}): ng.IPromise<SaveAnnotationModel.ISaveAnnotationResponseModel> {
				let obs = this.SaveAnnotation_Observable(inputModel);
				let promise = new Promise<SaveAnnotationModel.ISaveAnnotationResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetElements_Observable(inputModel: GetElementsModel.IGetElementsRequestModel & {ToJsonContract(noContent?: boolean): GetElementsModel.IGetElementsRequestModel}): Observable<GetElementsModel.IGetElementsResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ImageAnnotationController/GetElements`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GetElementsModel.IGetElementsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetElements(inputModel: GetElementsModel.IGetElementsRequestModel & {ToJsonContract(noContent?: boolean): GetElementsModel.IGetElementsRequestModel}): ng.IPromise<GetElementsModel.IGetElementsResponseModel> {
				let obs = this.GetElements_Observable(inputModel);
				let promise = new Promise<GetElementsModel.IGetElementsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetAllElements_Observable(): Observable<GetElementsModel.IGetElementsResponseModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/ImageAnnotationController/GetAllElements`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GetElementsModel.IGetElementsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetAllElements(): ng.IPromise<GetElementsModel.IGetElementsResponseModel> {
				let obs = this.GetAllElements_Observable();
				let promise = new Promise<GetElementsModel.IGetElementsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetPhotos_Observable(inputModel: GetPhotosModel.IGetPhotosRequestModel & {ToJsonContract(noContent?: boolean): GetPhotosModel.IGetPhotosRequestModel}): Observable<GetPhotosModel.IGetPhotosResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ImageAnnotationController/GetPhotos`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GetPhotosModel.IGetPhotosResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetPhotos(inputModel: GetPhotosModel.IGetPhotosRequestModel & {ToJsonContract(noContent?: boolean): GetPhotosModel.IGetPhotosRequestModel}): ng.IPromise<GetPhotosModel.IGetPhotosResponseModel> {
				let obs = this.GetPhotos_Observable(inputModel);
				let promise = new Promise<GetPhotosModel.IGetPhotosResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetAllProjects_Observable(): Observable<GetAllProjectsModel.IGetAllProjectsResponseModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/ImageAnnotationController/GetAllProjects`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GetAllProjectsModel.IGetAllProjectsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetAllProjects(): ng.IPromise<GetAllProjectsModel.IGetAllProjectsResponseModel> {
				let obs = this.GetAllProjects_Observable();
				let promise = new Promise<GetAllProjectsModel.IGetAllProjectsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetAnnotatorUsers_Observable(): Observable<GetAnnotatorUsersModel.IGetAnnotatorUsersResponseModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/ImageAnnotationController/GetAnnotatorUsers`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GetAnnotatorUsersModel.IGetAnnotatorUsersResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetAnnotatorUsers(): ng.IPromise<GetAnnotatorUsersModel.IGetAnnotatorUsersResponseModel> {
				let obs = this.GetAnnotatorUsers_Observable();
				let promise = new Promise<GetAnnotatorUsersModel.IGetAnnotatorUsersResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AddUsersToProject_Observable(inputModel: AddUsersToProjectModel.IAddUsersToProjectRequestModel & {ToJsonContract(noContent?: boolean): AddUsersToProjectModel.IAddUsersToProjectRequestModel}): Observable<AddUsersToProjectModel.IAddUsersToProjectResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ImageAnnotationController/AddUsersToProject`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AddUsersToProjectModel.IAddUsersToProjectResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AddUsersToProject(inputModel: AddUsersToProjectModel.IAddUsersToProjectRequestModel & {ToJsonContract(noContent?: boolean): AddUsersToProjectModel.IAddUsersToProjectRequestModel}): ng.IPromise<AddUsersToProjectModel.IAddUsersToProjectResponseModel> {
				let obs = this.AddUsersToProject_Observable(inputModel);
				let promise = new Promise<AddUsersToProjectModel.IAddUsersToProjectResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetProjectsFromUser_Observable(inputModel: GetProjectsFromUserModel.IGetProjectsFromUserRequestModel & {ToJsonContract(noContent?: boolean): GetProjectsFromUserModel.IGetProjectsFromUserRequestModel}): Observable<GetProjectsFromUserModel.IGetProjectsFromUserResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ImageAnnotationController/GetProjectsFromUser`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GetProjectsFromUserModel.IGetProjectsFromUserResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetProjectsFromUser(inputModel: GetProjectsFromUserModel.IGetProjectsFromUserRequestModel & {ToJsonContract(noContent?: boolean): GetProjectsFromUserModel.IGetProjectsFromUserRequestModel}): ng.IPromise<GetProjectsFromUserModel.IGetProjectsFromUserResponseModel> {
				let obs = this.GetProjectsFromUser_Observable(inputModel);
				let promise = new Promise<GetProjectsFromUserModel.IGetProjectsFromUserResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public CreateNewProject_Observable(inputModel: CreateNewProjectModel.ICreateNewProjectRequestModel & {ToJsonContract(noContent?: boolean): CreateNewProjectModel.ICreateNewProjectRequestModel}): Observable<CreateNewProjectModel.ICreateNewProjectResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ImageAnnotationController/CreateNewProject`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<CreateNewProjectModel.ICreateNewProjectResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public CreateNewProject(inputModel: CreateNewProjectModel.ICreateNewProjectRequestModel & {ToJsonContract(noContent?: boolean): CreateNewProjectModel.ICreateNewProjectRequestModel}): ng.IPromise<CreateNewProjectModel.ICreateNewProjectResponseModel> {
				let obs = this.CreateNewProject_Observable(inputModel);
				let promise = new Promise<CreateNewProjectModel.ICreateNewProjectResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetAllPhotoSets_Observable(): Observable<GetPhotoSetsModel.IGetPhotoSetsResponseModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/ImageAnnotationController/GetAllPhotoSets`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GetPhotoSetsModel.IGetPhotoSetsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetAllPhotoSets(): ng.IPromise<GetPhotoSetsModel.IGetPhotoSetsResponseModel> {
				let obs = this.GetAllPhotoSets_Observable();
				let promise = new Promise<GetPhotoSetsModel.IGetPhotoSetsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AddPhotoSetsToProject_Observable(inputModel: AddPhotoSetsToProjectModel.IAddPhotoSetsToProjectRequestModel & {ToJsonContract(noContent?: boolean): AddPhotoSetsToProjectModel.IAddPhotoSetsToProjectRequestModel}): Observable<AddPhotoSetsToProjectModel.IAddPhotoSetsToProjectResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ImageAnnotationController/AddPhotoSetsToProject`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AddPhotoSetsToProjectModel.IAddPhotoSetsToProjectResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AddPhotoSetsToProject(inputModel: AddPhotoSetsToProjectModel.IAddPhotoSetsToProjectRequestModel & {ToJsonContract(noContent?: boolean): AddPhotoSetsToProjectModel.IAddPhotoSetsToProjectRequestModel}): ng.IPromise<AddPhotoSetsToProjectModel.IAddPhotoSetsToProjectResponseModel> {
				let obs = this.AddPhotoSetsToProject_Observable(inputModel);
				let promise = new Promise<AddPhotoSetsToProjectModel.IAddPhotoSetsToProjectResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetPhotoSetsFromProject_Observable(inputModel: GetPhotoSetsFromProjectModel.IGetPhotoSetsFromProjectRequestModel & {ToJsonContract(noContent?: boolean): GetPhotoSetsFromProjectModel.IGetPhotoSetsFromProjectRequestModel}): Observable<GetPhotoSetsFromProjectModel.IGetPhotoSetsFromProjectResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ImageAnnotationController/GetPhotoSetsFromProject`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GetPhotoSetsFromProjectModel.IGetPhotoSetsFromProjectResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetPhotoSetsFromProject(inputModel: GetPhotoSetsFromProjectModel.IGetPhotoSetsFromProjectRequestModel & {ToJsonContract(noContent?: boolean): GetPhotoSetsFromProjectModel.IGetPhotoSetsFromProjectRequestModel}): ng.IPromise<GetPhotoSetsFromProjectModel.IGetPhotoSetsFromProjectResponseModel> {
				let obs = this.GetPhotoSetsFromProject_Observable(inputModel);
				let promise = new Promise<GetPhotoSetsFromProjectModel.IGetPhotoSetsFromProjectResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SkipPhoto_Observable(inputModel: SkipPhotoModel.ISkipPhotoRequestModel & {ToJsonContract(noContent?: boolean): SkipPhotoModel.ISkipPhotoRequestModel}): Observable<SkipPhotoModel.ISkipPhotoResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ImageAnnotationController/SkipPhoto`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<SkipPhotoModel.ISkipPhotoResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SkipPhoto(inputModel: SkipPhotoModel.ISkipPhotoRequestModel & {ToJsonContract(noContent?: boolean): SkipPhotoModel.ISkipPhotoRequestModel}): ng.IPromise<SkipPhotoModel.ISkipPhotoResponseModel> {
				let obs = this.SkipPhoto_Observable(inputModel);
				let promise = new Promise<SkipPhotoModel.ISkipPhotoResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetPhotoData_Observable(inputModel: GetPhotoDataModel.IGetPhotoDataRequestModel & {ToJsonContract(noContent?: boolean): GetPhotoDataModel.IGetPhotoDataRequestModel}): Observable<GetPhotoDataModel.IGetPhotoDataResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ImageAnnotationController/GetPhotoData`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GetPhotoDataModel.IGetPhotoDataResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetPhotoData(inputModel: GetPhotoDataModel.IGetPhotoDataRequestModel & {ToJsonContract(noContent?: boolean): GetPhotoDataModel.IGetPhotoDataRequestModel}): ng.IPromise<GetPhotoDataModel.IGetPhotoDataResponseModel> {
				let obs = this.GetPhotoData_Observable(inputModel);
				let promise = new Promise<GetPhotoDataModel.IGetPhotoDataResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AddElementsToProject_Observable(inputModel: AddElementsToProjectModel.IAddElementsToProjectRequestModel & {ToJsonContract(noContent?: boolean): AddElementsToProjectModel.IAddElementsToProjectRequestModel}): Observable<AddElementsToProjectModel.IAddElementsToProjectResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ImageAnnotationController/AddElementsToProject`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AddElementsToProjectModel.IAddElementsToProjectResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AddElementsToProject(inputModel: AddElementsToProjectModel.IAddElementsToProjectRequestModel & {ToJsonContract(noContent?: boolean): AddElementsToProjectModel.IAddElementsToProjectRequestModel}): ng.IPromise<AddElementsToProjectModel.IAddElementsToProjectResponseModel> {
				let obs = this.AddElementsToProject_Observable(inputModel);
				let promise = new Promise<AddElementsToProjectModel.IAddElementsToProjectResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AddNewElement_Observable(inputModel: AddNewElementModel.IAddNewElementRequestModel & {ToJsonContract(noContent?: boolean): AddNewElementModel.IAddNewElementRequestModel}): Observable<AddNewElementModel.IAddNewElementResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ImageAnnotationController/AddNewElement`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AddNewElementModel.IAddNewElementResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AddNewElement(inputModel: AddNewElementModel.IAddNewElementRequestModel & {ToJsonContract(noContent?: boolean): AddNewElementModel.IAddNewElementRequestModel}): ng.IPromise<AddNewElementModel.IAddNewElementResponseModel> {
				let obs = this.AddNewElement_Observable(inputModel);
				let promise = new Promise<AddNewElementModel.IAddNewElementResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


