<div class="container-fluid">
    <h3>Overzicht Projecten</h3>
    <h5>Organisatie: [.Wat moet hier komen?]</h5>

    <div>
        <button mat-raised-button color="primary">Nieuw Project</button>
    </div>

    <table>
        <tr>
            <td>Hier Moet nog een table worden uitgelzen met alle projecten</td>
        </tr>
    </table>

    <div>
        <mat-checkbox [(ngModel)]="CleanRequest.MetNaw">MetNaw</mat-checkbox>
        <mat-checkbox [(ngModel)]="CleanRequest.MetBestaandeOpties">MetBestaandeOpties</mat-checkbox>
        <mat-checkbox [(ngModel)]="CleanRequest.MetNieuweOpties">MetNieuweOpties</mat-checkbox>
        <mat-checkbox [(ngModel)]="CleanRequest.alleenGebruikers">alleenGebruikers</mat-checkbox>
        MetRol: <input [(ngModel)]="CleanRequest.MetRol"/>
        MetNaam: <input [(ngModel)]="CleanRequest.MetNaam"/>
        <mat-checkbox [(ngModel)]="CleanRequest.MetSocialeEnquete">MetSocialeEnquete</mat-checkbox>
        <mat-checkbox [(ngModel)]="CleanRequest.MetWarmeOpname">MetWarmeOpname</mat-checkbox>
        <mat-checkbox [(ngModel)]="CleanRequest.MetEPAOpname">MetEPAOpname</mat-checkbox>
        <mat-checkbox [(ngModel)]="CleanRequest.MetRasterNotities">MetRasterNotities</mat-checkbox>

        <wct-button (click)="Clean()">Voer clean users uit </wct-button>

        <wct-button (click)="CleanDwellings()">Voer clean woningen uit </wct-button>

        <wct-button (click)="ResetWachtwoorden()">Reset wachtwoorden (van bewoners)</wct-button>
        <wct-button (click)="ResetWachtwoordenDoorvoeren()">Reset wachtwoorden (bewoners, doorvoeren)</wct-button>

    </div>

    <div>
        Resulataten:
        <div>
            <button mat-raised-button color="primary">Importeer woningen + aanmaak hoofdbewoners voor nieuwe woningen</button>
        </div>
        <hr />
        <div>
            <button mat-raised-button color="primary">
                Importeer woningen + aanmaak hoofdbewoners voor neiwue en reeds geimporteerde wonignen

            </button>
        </div>

        <p>
            Project Settings
            <wct-admin-project-settings></wct-admin-project-settings>
        </p>
    </div>
</div>