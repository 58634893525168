import { Component, Input, OnInit } from '@angular/core';
import { ISurveyClimateSystem } from '@datacontracts/SurveyClimateSystem';
import { AddArrayControlAction, FormGroupState } from 'ngrx-forms';
import * as SurveyClimateSystemEnum from "@enums/SurveyClimateSystem";
import { Observable } from 'rxjs';
import { IDwellingSurvey } from '@datacontracts/DwellingSurvey';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { SurveyState } from '../../store/survey.state';
import { take } from 'rxjs/operators';
import * as Actions from '../../store/survey.actions';

@Component({
  selector: 'wct-survey-climate-systems',
  templateUrl: './wct-survey-climate-systems.component.html',
  styleUrls: ['./wct-survey-climate-systems.component.scss']
})
export class WctSurveyClimateSystemsComponent implements OnInit {
  @Input() systems: FormGroupState<ISurveyClimateSystem>;

  FormState$: Observable<FormGroupState<IDwellingSurvey>>;

  get control() {
    return this.systems.controls;
  }

  constructor(
    private store: Store<{ survey: SurveyState }>,
    private actionsSubject: ActionsSubject
  )
  {
    this.FormState$ = store.pipe(select(s => s.survey.Survey));
  }

  ngOnInit(): void {
  }

  trackByIndex(index, item) {
		return index;
	}

  AddSystem()
  {
    let newSystem: ISurveyClimateSystem = {
      ObjectId: '',
      Name: {
        Name: 'Test combiketel',
        ObjectID: '',
        StringValue: '',
        NumericValue: 1,
        SetByUser: true
      },
      Functions: SurveyClimateSystemEnum.Functions.HeatingAndtap,
      HeatingType: SurveyClimateSystemEnum.HeatingType.Individual,
      HeatingDevice: SurveyClimateSystemEnum.HeatingDevice.Combiboiler,
      HeatingDeviceEnergyType: SurveyClimateSystemEnum.HeatingDeviceEnergyType.Invalid,
      HeatingDistribution: SurveyClimateSystemEnum.HeatingDistribution.Invalid,
      HeatingPumpSource: SurveyClimateSystemEnum.HeatingPumpSource.Invalid,
      AllElectric: SurveyClimateSystemEnum.AllElectric.Invalid,
      OtherFunctions: SurveyClimateSystemEnum.OtherFunctions.Invalid,
      BoilerType: SurveyClimateSystemEnum.BoilerType.Invalid,
      VentilationSupply: SurveyClimateSystemEnum.VentilationSupply.Invalid,
      LocalHeatingType: SurveyClimateSystemEnum.LocalHeatingType.Invalid,
      Drain: SurveyClimateSystemEnum.Drain.Invalid,
      BiomassAccumulate: SurveyClimateSystemEnum.BiomassAccumulate.Invalid,
      DistributionSystemType: SurveyClimateSystemEnum.DistributionSystemType.Invalid,
      DistributionInsulationPipes: SurveyClimateSystemEnum.DistributionInsulationPipes.Invalid,
      DistributionPipesThroughNonInsulatedRoom: SurveyClimateSystemEnum.DistributionPipesThroughNonInsulatedRoom.Invalid,
      CWClass: SurveyClimateSystemEnum.CWClass.Invalid,
      GasHrLabel: SurveyClimateSystemEnum.GasHrLabel.Invalid,
      GeneratorPowerHreWKK: SurveyClimateSystemEnum.GeneratorPowerHreWKK.Invalid,
      OnlineWuid: ''
    }

    this.store.dispatch(Actions.addClimateSystem({ system: newSystem }));

    // this.FormState$.pipe(take(1)).subscribe((x) =>
    // {
    //   this.actionsSubject.next(
    //     new AddArrayControlAction<ISurveyClimateSystem>(
    //       x.controls.ClimateSystem.id,
    //       newSystem,
    //     )
    //   );
    // });
  }
}
