import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { PrijslijstConsumer } from '@consumers/PrijslijstController';
import { PrijsSchemasViewModel, PrijsSchemaViewModel, CategoriesViewModel, CategorieViewModel, RequestCategorieViewModel } from '@models/PrijslijstViewModel';
import { PrijsModuleService } from '@services/PrijsModuleService';

@Component({
  selector: 'prijsschema',
  templateUrl: './prijsschema.component.html',
  styleUrls: ['./prijsschema.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PrijsSchemaComponent {

  public ActivePrijsSchemaViewModel: PrijsSchemaViewModel;

  constructor(
    private fab: FabrikantenConsumer,
    private prijslijst: PrijslijstConsumer,
    private route: ActivatedRoute,
    public prijsmoduleservice: PrijsModuleService,
    private router: Router
  ) {
  }

  public Nieuw(): void {
    this.ActivePrijsSchemaViewModel = new PrijsSchemaViewModel(this.prijsmoduleservice.PrijsSchemaSelected);
    this.ActivePrijsSchemaViewModel.Id = -1;
    this.ActivePrijsSchemaViewModel.Name = "Nieuw";
    this.ActivePrijsSchemaViewModel.UserId = "Nieuw";
  }

  public Open(prijsschema: PrijsSchemaViewModel): void {
    this.ActivePrijsSchemaViewModel = prijsschema;
  }

  public Delete(prijsschema: PrijsSchemaViewModel): void {
    this.ActivePrijsSchemaViewModel = null;
    this.prijsmoduleservice.LoadingPrijsSchemas = true;

    this.prijslijst.DeletePrijsSchema_Observable(prijsschema).subscribe(() => {
      this.prijsmoduleservice.LoadPrijsSchemas();
    });
  }

  public Opslaan() {
    if (this.ValidatePrijsSchema()) {
      this.prijsmoduleservice.LoadingPrijsSchemas = true;

      if (this.ActivePrijsSchemaViewModel.Id != null && this.ActivePrijsSchemaViewModel.Id > 0) {
        this.prijsmoduleservice.CategorieViewModels[this.ActivePrijsSchemaViewModel.Id] = null;
      }

      this.prijslijst.SavePrijsSchema_Observable(this.ActivePrijsSchemaViewModel).subscribe(() => {
        this.ActivePrijsSchemaViewModel = null;
        this.prijsmoduleservice.LoadPrijsSchemas();
      });
    }
  }

  public ValidatePrijsSchema(): boolean {
    if (this.ActivePrijsSchemaViewModel.Aluminium <= 0) {
      alert("Vul een 'Aluminium' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.Poedercoaten <= 0) {
      alert("Vul een 'Poedercoaten (1 kleur)' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.PoedercoatenBinnen <= 0) {
      alert("Vul een 'Poedercoaten binnen' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.PoedercoatenBuiten <= 0) {
      alert("Vul een 'Poedercoaten buiten' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.Anodiseren <= 0) {
      alert("Vul een 'Anodiseren' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.AnodiserenBinnen <= 0) {
      alert("Vul een 'Anodiseren binnen' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.AnodiserenBuiten <= 0) {
      alert("Vul een 'Anodiseren buiten' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.ISORT62 <= 0) {
      alert("Vul een 'ISO RT62' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.ISORT72Reflex <= 0) {
      alert("Vul een 'ISO RT72 Reflex' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.ISORT72HI <= 0) {
      alert("Vul een 'RT72 HI+' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.ISORT82HI <= 0) {
      alert("Vul een 'RT82 HI+' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.ISOAA5110 <= 0) {
      alert("Vul een 'ISO AA5110' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.ISOAA51102X <= 0) {
      alert("Vul een 'ISO AA5110 2x' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.ISOAA3572 <= 0) {
      alert("Vul een 'ISO AA3572' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.ISOAA35722X <= 0) {
      alert("Vul een 'ISO AA3572 2X' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.ISOAA35723X <= 0) {
      alert("Vul een 'ISO AA3572 3X' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.Boren <= 0) {
      alert("Vul een 'Boren' prijs in hoger dan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.BiColorToeslag == null) {
      alert("Vul een 'Bi-color toeslag’ prijs in hoger of gelijk aan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.BiColorToeslag < 0) {
      alert("Vul een 'Bi-color toeslag’ prijs in hoger of gelijk aan 0");
      return false;
    }

    if (this.ActivePrijsSchemaViewModel.Tapen < 0) {
      alert("Vul een 'Tapen’ prijs in hoger of gelijk aan 0");
      return false;
    }

    return true;
  }
}
