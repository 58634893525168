/**
 * @ref WoonConnectKern/Models/WebBericht/WisselWoning.cs
 */

 import * as DataContracts from "@datacontracts/WisselWoning";

 



	/**
	 * Originele namespace: WoonConnectKern.Models.WebBericht.WisselWoningDataIn
	 */
	 

	export interface IWisselWoningDataIn {
		WoningId: number;
		Scenario: number;
		ToJsonContract(): DataContracts.IWisselWoningDataIn;
	}

	export class WisselWoningDataIn {
		// property-Properties
		// field-Properties
		public get WoningId(): number {
			return this._WoningId;
		}
		public set WoningId(newWoningId: number) {
			this._WoningId = newWoningId;
		}
		
		public get Scenario(): number {
			return this._Scenario;
		}
		public set Scenario(newScenario: number) {
			this._Scenario = newScenario;
		}

		// fields
		protected _WoningId: number = 0;
		protected _Scenario: number = 0;

		constructor(data?: DataContracts.IWisselWoningDataIn) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IWisselWoningDataIn): void {
			if(data) {
				// vul properties
				// vul fields
				this._WoningId = data.WoningId;
				this._Scenario = data.Scenario;
			}
		}

		public ToJsonContract(): DataContracts.IWisselWoningDataIn {
			return {
				WoningId: this.WoningId,
				Scenario: this.Scenario
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectKern.Models.WebBericht.WisselWoningDataUit
	 */
	 

	export interface IWisselWoningDataUit {
		BlokID: number;
		ReadOnly: boolean;
		Minimum: boolean;
		WijkID: number;
		CameraIndex: number;
		EventId: number;
		Fout: string;
	}

	export class WisselWoningDataUit {
		// property-Properties
		public get BlokID(): number {
			return this._BlokID;
		}
		public set BlokID(newBlokID: number) {
			this._BlokID = newBlokID;
		}

		public get ReadOnly(): boolean {
			return this._ReadOnly;
		}
		public set ReadOnly(newReadOnly: boolean) {
			this._ReadOnly = newReadOnly;
		}

		public get Minimum(): boolean {
			return this._Minimum;
		}
		public set Minimum(newMinimum: boolean) {
			this._Minimum = newMinimum;
		}

		public get WijkID(): number {
			return this._WijkID;
		}
		public set WijkID(newWijkID: number) {
			this._WijkID = newWijkID;
		}

		public get CameraIndex(): number {
			return this._CameraIndex;
		}
		public set CameraIndex(newCameraIndex: number) {
			this._CameraIndex = newCameraIndex;
		}

		public get EventId(): number {
			return this._EventId;
		}
		public set EventId(newEventId: number) {
			this._EventId = newEventId;
		}

		public get Fout(): string {
			return this._Fout;
		}
		public set Fout(newFout: string) {
			this._Fout = newFout;
		}

		// field-Properties

		// fields
		protected _BlokID: number = 0;
		protected _ReadOnly: boolean = false;
		protected _Minimum: boolean = false;
		protected _WijkID: number = 0;
		protected _CameraIndex: number = 0;
		protected _EventId: number = 0;
		protected _Fout: string = "";

		constructor(data?: DataContracts.IWisselWoningDataUit) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IWisselWoningDataUit): void {
			if(data) {
				// vul properties
				this._BlokID = data.BlokID;
				this._ReadOnly = data.ReadOnly;
				this._Minimum = data.Minimum;
				this._WijkID = data.WijkID;
				this._CameraIndex = data.CameraIndex;
				this._EventId = data.EventId;
				this._Fout = data.Fout;
				// vul fields
			}
		}
	}
