// [conv] import "(../FabProduct).less";
import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { ProductsConsumer } from '@consumers/ProductsController';
import { ProductViewModel, ProductRequestModel } from '@models/ProductsViewModel';



@Component({
    selector: 'product-details',
  templateUrl: './ProductDetails.html'
})
export class ProductDetailsComponent {
    @Input() ProductId;

    //Producten laden.
    Laden: boolean = true;
    ProductViewModel: ProductViewModel;
    ProductRequestModel: ProductRequestModel;


    constructor(private prod: ProductsConsumer) {
        if (this.ProductId>0)
        {
            this.Refresh();
        }
    }

    public Herlaad() {
        this.Refresh();
    }

    public Refresh(): void {
        this.Laden = true;

        //get here the product View
        this.ProductRequestModel = new ProductRequestModel();
        this.ProductRequestModel.Id = this.ProductId;
        this.prod.GetProduct_Observable(this.ProductRequestModel).subscribe((data) => {
            console.log(data);
        })
    }

}
