import * as _ from 'lodash';
import * as ViewModelsBlok from '.././Models/Blok';
import * as ViewModelsWoningInstantie from '.././Models/WoningInstantie';
import * as ViewModelsKostenElement from '.././Models/KostenElement';
import * as DataContractsMJOPJaar from '@datacontracts/MJOPJaar';
import { IMJOPJaar } from '../interfaces/imjopjaar';


	export class KostenHoofdstuk {
		Naam: string;
		Code: string;
		Elementen: Array<ViewModelsKostenElement.KostenElement> = [];
		//PrijsRegels: Array<KostenPrijsregel> = [];
		TotaalBedrag: number;
		TotaalBedragExBTW: number;
		TotaalBedragExBTWStr: string;
		dTotaalBedragExBTW: number;
		TotaalBedragIncBTW: number;
		TotaalBedragIncBTWStr: string;
		dTotaalBedragIncBTW: number;
		MJOPjaren: Array<IMJOPJaar>;

		Tonen: boolean;
		m_IsUitGeklapt: boolean;
		constructor(public Instantie: ViewModelsWoningInstantie.WoningInstantie | ViewModelsBlok.Blok, data: any
		) {
			Object.assign(this, data)
			//ng.extend(this, data);
			this.TotaalBedragExBTW = 0;//(data.dTotaalBedragExBTW == null ? 0 : data.dTotaalBedragExBTW).toFixed(2);
			this.TotaalBedragExBTWStr = this.TotaalBedragExBTW.toLocaleString();
			this.TotaalBedragIncBTW = 0;//(data.dTotaalBedragIncBTW == null ? 0 : data.dTotaalBedragIncBTW).toFixed(2);
			this.TotaalBedragIncBTWStr = this.TotaalBedragIncBTW.toLocaleString();
			var startjaar = new Date().getFullYear();
			//var startjaar = 2016
			this.Tonen = false;
			this.MJOPjaren = [];
			for (var i = startjaar; i < startjaar + 30; i++) {
				var mjopjaar: IMJOPJaar = { Jaar: i, Bedrag: 0, Type: 100 };
				this.MJOPjaren.push(mjopjaar);
			}

			this.Elementen = _.map(data.Elementen, (p) => { return new ViewModelsKostenElement.KostenElement(this, p); });


			//this.PrijsRegels = _.map(data.PrijsRegels, (p) => { return new KostenPrijsregel(this, p); });
		}

		MaakLeeg() {
			this.TotaalBedrag = 0;
			this.TotaalBedragExBTW = 0;
			this.TotaalBedragIncBTW = 0;
			this.dTotaalBedragExBTW = 0;
			this.dTotaalBedragIncBTW = 0;
			_.each(this.MJOPjaren, (j) => {
				j.Bedrag = 0;

			});
			_.each(this.Elementen, (e) => {
				e.MaakLeeg();
			});
		}

		get IsUitGeklapt(): boolean {
			return this.m_IsUitGeklapt;
		}
		set IsUitGeklapt(b: boolean) {
			this.m_IsUitGeklapt = b;
		}

	}
