/// <reference path='../consumerinterfaces/UserManagementController.ts' />
/**
 * @ref WoonConnectApiController/User/UserManagementController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/UserManagementController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as UserChangePasswordModel from "@datacontracts/UserChangePasswordModel";
import * as UserRetrieveModel from "@datacontracts/UserRetrieveModel";
import * as UserViewModel from "@datacontracts/UserViewModel";
import * as UserChangeModel from "@datacontracts/UserChangeModel";
import * as UserAddModel from "@datacontracts/UserAddModel";
import * as UserDeleteModel from "@datacontracts/UserDeleteModel";
import * as ActionResponseModel from "@datacontracts/ActionResponseModel";
import * as RolesViewModel from "@datacontracts/RolesViewModel";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class UserManagementConsumer implements Interface.IUserManagementConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public ChangePassword_Observable(inputModel: UserChangePasswordModel.IUserChangePasswordRequestModel & {ToJsonContract(noContent?: boolean): UserChangePasswordModel.IUserChangePasswordRequestModel}): Observable<UserChangePasswordModel.IUserChangePasswordResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/ChangePassword`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserChangePasswordModel.IUserChangePasswordResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ChangePassword(inputModel: UserChangePasswordModel.IUserChangePasswordRequestModel & {ToJsonContract(noContent?: boolean): UserChangePasswordModel.IUserChangePasswordRequestModel}): ng.IPromise<UserChangePasswordModel.IUserChangePasswordResponseModel> {
				let obs = this.ChangePassword_Observable(inputModel);
				let promise = new Promise<UserChangePasswordModel.IUserChangePasswordResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public Retrieve_Observable(inputModel: UserRetrieveModel.IUserRetrieveRequestModel & {ToJsonContract(noContent?: boolean): UserRetrieveModel.IUserRetrieveRequestModel}): Observable<UserRetrieveModel.IUserRetrieveResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/Retrieve`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserRetrieveModel.IUserRetrieveResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Retrieve(inputModel: UserRetrieveModel.IUserRetrieveRequestModel & {ToJsonContract(noContent?: boolean): UserRetrieveModel.IUserRetrieveRequestModel}): ng.IPromise<UserRetrieveModel.IUserRetrieveResponseModel> {
				let obs = this.Retrieve_Observable(inputModel);
				let promise = new Promise<UserRetrieveModel.IUserRetrieveResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetUsers_Observable(): Observable<UserViewModel.IUsersViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/UserManagement/GetUsers`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserViewModel.IUsersViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetUsers(): ng.IPromise<UserViewModel.IUsersViewModel> {
				let obs = this.GetUsers_Observable();
				let promise = new Promise<UserViewModel.IUsersViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public CountUsers_Observable(inputModel: UserViewModel.IUsersCountViewModel & {ToJsonContract(noContent?: boolean): UserViewModel.IUsersCountViewModel}): Observable<UserViewModel.IUsersCountViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/CountUsers`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserViewModel.IUsersCountViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public CountUsers(inputModel: UserViewModel.IUsersCountViewModel & {ToJsonContract(noContent?: boolean): UserViewModel.IUsersCountViewModel}): ng.IPromise<UserViewModel.IUsersCountViewModel> {
				let obs = this.CountUsers_Observable(inputModel);
				let promise = new Promise<UserViewModel.IUsersCountViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SelectUsers_Observable(inputModel: UserRetrieveModel.IUserRetrieveRequestModel & {ToJsonContract(noContent?: boolean): UserRetrieveModel.IUserRetrieveRequestModel}): Observable<UserViewModel.IUsersViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/SelectUsers`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserViewModel.IUsersViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SelectUsers(inputModel: UserRetrieveModel.IUserRetrieveRequestModel & {ToJsonContract(noContent?: boolean): UserRetrieveModel.IUserRetrieveRequestModel}): ng.IPromise<UserViewModel.IUsersViewModel> {
				let obs = this.SelectUsers_Observable(inputModel);
				let promise = new Promise<UserViewModel.IUsersViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetUser_Observable(inputModel: UserRetrieveModel.IUserDetailRequestModel & {ToJsonContract(noContent?: boolean): UserRetrieveModel.IUserDetailRequestModel}): Observable<UserViewModel.IUserDetailViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/GetUser`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserViewModel.IUserDetailViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetUser(inputModel: UserRetrieveModel.IUserDetailRequestModel & {ToJsonContract(noContent?: boolean): UserRetrieveModel.IUserDetailRequestModel}): ng.IPromise<UserViewModel.IUserDetailViewModel> {
				let obs = this.GetUser_Observable(inputModel);
				let promise = new Promise<UserViewModel.IUserDetailViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveUser_Observable(inputModel: UserViewModel.IUserDetailViewModel & {ToJsonContract(noContent?: boolean): UserViewModel.IUserDetailViewModel}): Observable<UserViewModel.IUserDetailViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/SaveUser`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserViewModel.IUserDetailViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveUser(inputModel: UserViewModel.IUserDetailViewModel & {ToJsonContract(noContent?: boolean): UserViewModel.IUserDetailViewModel}): ng.IPromise<UserViewModel.IUserDetailViewModel> {
				let obs = this.SaveUser_Observable(inputModel);
				let promise = new Promise<UserViewModel.IUserDetailViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SetVerification_Observable(inputModel: UserViewModel.IVerificationViewModel & {ToJsonContract(noContent?: boolean): UserViewModel.IVerificationViewModel}): Observable<UserViewModel.IVerificationViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/SetVerification`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserViewModel.IVerificationViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SetVerification(inputModel: UserViewModel.IVerificationViewModel & {ToJsonContract(noContent?: boolean): UserViewModel.IVerificationViewModel}): ng.IPromise<UserViewModel.IVerificationViewModel> {
				let obs = this.SetVerification_Observable(inputModel);
				let promise = new Promise<UserViewModel.IVerificationViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SetMultipleVerifications_Observable(inputModel: UserViewModel.IMultipleVerificationsViewModel & {ToJsonContract(noContent?: boolean): UserViewModel.IMultipleVerificationsViewModel}): Observable<UserViewModel.IMultipleVerificationsViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/SetMultipleVerifications`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserViewModel.IMultipleVerificationsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SetMultipleVerifications(inputModel: UserViewModel.IMultipleVerificationsViewModel & {ToJsonContract(noContent?: boolean): UserViewModel.IMultipleVerificationsViewModel}): ng.IPromise<UserViewModel.IMultipleVerificationsViewModel> {
				let obs = this.SetMultipleVerifications_Observable(inputModel);
				let promise = new Promise<UserViewModel.IMultipleVerificationsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ResetPassword_Observable(inputModel: UserChangePasswordModel.IUserChangePasswordRequestModel & {ToJsonContract(noContent?: boolean): UserChangePasswordModel.IUserChangePasswordRequestModel}): Observable<UserChangePasswordModel.IUserChangePasswordResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/ResetPassword`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserChangePasswordModel.IUserChangePasswordResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ResetPassword(inputModel: UserChangePasswordModel.IUserChangePasswordRequestModel & {ToJsonContract(noContent?: boolean): UserChangePasswordModel.IUserChangePasswordRequestModel}): ng.IPromise<UserChangePasswordModel.IUserChangePasswordResponseModel> {
				let obs = this.ResetPassword_Observable(inputModel);
				let promise = new Promise<UserChangePasswordModel.IUserChangePasswordResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public Change_Observable(inputModel: UserChangeModel.IUserChangeRequestModel & {ToJsonContract(noContent?: boolean): UserChangeModel.IUserChangeRequestModel}): Observable<UserChangeModel.IUserChangeResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/Change`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserChangeModel.IUserChangeResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Change(inputModel: UserChangeModel.IUserChangeRequestModel & {ToJsonContract(noContent?: boolean): UserChangeModel.IUserChangeRequestModel}): ng.IPromise<UserChangeModel.IUserChangeResponseModel> {
				let obs = this.Change_Observable(inputModel);
				let promise = new Promise<UserChangeModel.IUserChangeResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public Add_Observable(inputModel: UserAddModel.IUserAddRequestModel & {ToJsonContract(noContent?: boolean): UserAddModel.IUserAddRequestModel}): Observable<UserAddModel.IUserAddResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/Add`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserAddModel.IUserAddResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Add(inputModel: UserAddModel.IUserAddRequestModel & {ToJsonContract(noContent?: boolean): UserAddModel.IUserAddRequestModel}): ng.IPromise<UserAddModel.IUserAddResponseModel> {
				let obs = this.Add_Observable(inputModel);
				let promise = new Promise<UserAddModel.IUserAddResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public Delete_Observable(inputModel: UserDeleteModel.IUserDeleteRequestModel & {ToJsonContract(noContent?: boolean): UserDeleteModel.IUserDeleteRequestModel}): Observable<UserDeleteModel.IUserDeleteResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/Delete`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UserDeleteModel.IUserDeleteResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Delete(inputModel: UserDeleteModel.IUserDeleteRequestModel & {ToJsonContract(noContent?: boolean): UserDeleteModel.IUserDeleteRequestModel}): ng.IPromise<UserDeleteModel.IUserDeleteResponseModel> {
				let obs = this.Delete_Observable(inputModel);
				let promise = new Promise<UserDeleteModel.IUserDeleteResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UnlockUser_Observable(inputModel: UserRetrieveModel.IUserDetailRequestModel & {ToJsonContract(noContent?: boolean): UserRetrieveModel.IUserDetailRequestModel}): Observable<ActionResponseModel.IActionResponseViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/UnlockUser`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ActionResponseModel.IActionResponseViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UnlockUser(inputModel: UserRetrieveModel.IUserDetailRequestModel & {ToJsonContract(noContent?: boolean): UserRetrieveModel.IUserDetailRequestModel}): ng.IPromise<ActionResponseModel.IActionResponseViewModel> {
				let obs = this.UnlockUser_Observable(inputModel);
				let promise = new Promise<ActionResponseModel.IActionResponseViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public LockUser_Observable(inputModel: UserRetrieveModel.IUserDetailRequestModel & {ToJsonContract(noContent?: boolean): UserRetrieveModel.IUserDetailRequestModel}): Observable<ActionResponseModel.IActionResponseViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/LockUser`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ActionResponseModel.IActionResponseViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public LockUser(inputModel: UserRetrieveModel.IUserDetailRequestModel & {ToJsonContract(noContent?: boolean): UserRetrieveModel.IUserDetailRequestModel}): ng.IPromise<ActionResponseModel.IActionResponseViewModel> {
				let obs = this.LockUser_Observable(inputModel);
				let promise = new Promise<ActionResponseModel.IActionResponseViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AddUserToRole_Observable(inputModel: RolesViewModel.IAddUserToRoleRequest & {ToJsonContract(noContent?: boolean): RolesViewModel.IAddUserToRoleRequest}): Observable<ActionResponseModel.IActionResponseViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/UserManagement/AddRoleToUser`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ActionResponseModel.IActionResponseViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AddUserToRole(inputModel: RolesViewModel.IAddUserToRoleRequest & {ToJsonContract(noContent?: boolean): RolesViewModel.IAddUserToRoleRequest}): ng.IPromise<ActionResponseModel.IActionResponseViewModel> {
				let obs = this.AddUserToRole_Observable(inputModel);
				let promise = new Promise<ActionResponseModel.IActionResponseViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


