/**
 * @ref WoonConnectViewModel/ImageAnnotation/AddElementsToProjectModel.cs
 */

 import * as DataContracts from "@datacontracts/AddElementsToProjectModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.AddElementsToProjectRequestModel
	 */
	 

	export interface IAddElementsToProjectRequestModel {
		ProjectId: number;
		ElementIds: number[];
		ToJsonContract(): DataContracts.IAddElementsToProjectRequestModel;
	}

	export class AddElementsToProjectRequestModel {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		public get ElementIds(): number[] {
			return this._ElementIds;
		}
		public set ElementIds(newElementIds: number[]) {
			this._ElementIds = newElementIds;
		}

		// field-Properties

		// fields
		protected _ProjectId: number = 0;
		protected _ElementIds: number[] = [];

		constructor(data?: DataContracts.IAddElementsToProjectRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddElementsToProjectRequestModel): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				this._ElementIds = data.ElementIds;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAddElementsToProjectRequestModel {
			return {
				ProjectId: this.ProjectId,
				ElementIds: this.ElementIds
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.AddElementsToProjectResponseModel
	 */
	 

	export interface IAddElementsToProjectResponseModel {
		Succeeded: boolean;
	}

	export class AddElementsToProjectResponseModel {
		// property-Properties
		public get Succeeded(): boolean {
			return this._Succeeded;
		}
		public set Succeeded(newSucceeded: boolean) {
			this._Succeeded = newSucceeded;
		}

		// field-Properties

		// fields
		protected _Succeeded: boolean = false;

		constructor(data?: DataContracts.IAddElementsToProjectResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddElementsToProjectResponseModel): void {
			if(data) {
				// vul properties
				this._Succeeded = data.Succeeded;
				// vul fields
			}
		}
	}
