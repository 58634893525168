/**
 * @ref WoonConnectViewModel/Resident/SetResidentModel.cs
 */

 import * as DataContracts from "@datacontracts/SetResidentModel";

 
import * as GetResidentModel from "./GetResidentModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Resident.SetResidentModelRequestModel
	 */
	 

	export interface ISetResidentModelRequestModel {
		ResidentModel: GetResidentModel.IResidentModel;
		ToJsonContract(): DataContracts.ISetResidentModelRequestModel;
	}

	export class SetResidentModelRequestModel {
		// property-Properties
		public get ResidentModel(): GetResidentModel.IResidentModel {
			return this._ResidentModel;
		}
		public set ResidentModel(newResidentModel: GetResidentModel.IResidentModel) {
			this._ResidentModel = newResidentModel;
		}

		// field-Properties

		// fields
		protected _ResidentModel: GetResidentModel.IResidentModel = new GetResidentModel.ResidentModel(undefined);

		constructor(data?: DataContracts.ISetResidentModelRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISetResidentModelRequestModel): void {
			if(data) {
				// vul properties
				this._ResidentModel = data.ResidentModel ? new GetResidentModel.ResidentModel(data.ResidentModel): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISetResidentModelRequestModel {
			return {
				ResidentModel: (this.ResidentModel ? new GetResidentModel.ResidentModel(this.ResidentModel).ToJsonContract(): this.ResidentModel)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Resident.SetResidentModelResponseModel
	 */
	 

	export interface ISetResidentModelResponseModel {
		Errors: string;
		Succeeded: boolean;
	}

	export class SetResidentModelResponseModel {
		// property-Properties
		// field-Properties
		public get Errors(): string {
			return this._Errors;
		}
		public set Errors(newErrors: string) {
			this._Errors = newErrors;
		}
		
		public get Succeeded(): boolean {
			return this._Succeeded;
		}
		public set Succeeded(newSucceeded: boolean) {
			this._Succeeded = newSucceeded;
		}

		// fields
		protected _Errors: string = "";
		protected _Succeeded: boolean = false;

		constructor(data?: DataContracts.ISetResidentModelResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISetResidentModelResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Errors = data.Errors;
				this._Succeeded = data.Succeeded;
			}
		}
	}
