import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WctLoginModuleComponent } from './WctLoginModule';
import { WctStartschermComponent } from './components/WctStartscherm';
import { WctLoginComponent } from './components/WctLogin';
import { WctForgotPasswordComponent } from './components/WctForgotPassword';
import { WctConfirmEmailComponent } from './components/WctConfirmEmail';

import { WctActivatieSleutelComponent } from '../components/account/WctActivatieSleutel';
import { WctActivatiePopupComponent } from './components/WctActivatiePopup';
import { WctLoginPopupComponent } from './components/WctLoginPopup';
import { WctActivatieFormulierComponent } from './components/WctActivatieFormulier';
import { WctActivatieFormulierPopupComponent } from './components/WctActivatieFormulierPopup';


import { TranslateModule } from '../infrastructure/translate.module';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button'
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreUiModule } from '../coreui/coreui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountModule } from '../account/account.module';


const AngularMaterialModules = [
	MatInputModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatFormFieldModule,
	MatDialogModule,
	MatSelectModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatTableModule,
	MatCheckboxModule,
	MatTooltipModule,
	MatTreeModule,
	MatIconModule,
	MatTabsModule,
	MatBottomSheetModule,
	MatGridListModule,
	MatSidenavModule,
	MatSlideToggleModule,
	MatButtonToggleModule,
	MatButtonModule,
	MatProgressBarModule
];

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		AngularMaterialModules,
		CoreUiModule,
		ReactiveFormsModule,
		FormsModule,
		AccountModule
	],
	exports: [
		WctLoginComponent,
		WctForgotPasswordComponent,
		WctConfirmEmailComponent,
		WctActivatieSleutelComponent,
		WctActivatiePopupComponent,
		WctActivatieFormulierComponent
	],
	declarations: [
		WctStartschermComponent,
		WctLoginComponent,
		WctActivatieSleutelComponent,
		WctActivatiePopupComponent,
		WctLoginPopupComponent,
		WctLoginModuleComponent,
		WctForgotPasswordComponent,
		WctConfirmEmailComponent,
		WctActivatieFormulierComponent,
		WctActivatieFormulierPopupComponent
	],
	entryComponents: [
		WctStartschermComponent,
		WctLoginComponent,
		WctForgotPasswordComponent,
		WctConfirmEmailComponent,
		WctLoginModuleComponent,
		WctActivatieFormulierComponent
	],
	bootstrap: [
	],
	providers: [
	]
})

export class LoginModule {
}

