/**
 * @ref WoonConnectViewModel/MeasureViewModel.cs
 */

 import * as DataContracts from "@datacontracts/MeasureViewModel";

 
import * as BuildingComponentViewModel from "./BuildingComponentViewModel";
import * as EfficacyViewModel from "./EfficacyViewModel";
import * as TradeAssociationViewModel from "./TradeAssociationViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.MeasuresRequestModel
	 */
	 

	export interface IMeasuresRequestModel {
		Temp: string;
		ToJsonContract(): DataContracts.IMeasuresRequestModel;
	}

	export class MeasuresRequestModel {
		// property-Properties
		// field-Properties
		public get Temp(): string {
			return this._Temp;
		}
		public set Temp(newTemp: string) {
			this._Temp = newTemp;
		}

		// fields
		protected _Temp: string = "";

		constructor(data?: DataContracts.IMeasuresRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IMeasuresRequestModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Temp = data.Temp;
			}
		}

		public ToJsonContract(): DataContracts.IMeasuresRequestModel {
			return {
				Temp: this.Temp
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.MeasuresViewModel
	 */
	 

	export interface IMeasuresViewModel {
		Measures: MeasureViewModel[];
		ToJsonContract(): DataContracts.IMeasuresViewModel;
	}

	export class MeasuresViewModel {
		// property-Properties
		public get Measures(): MeasureViewModel[] {
			return this._Measures;
		}
		public set Measures(newMeasures: MeasureViewModel[]) {
			this._Measures = newMeasures;
		}

		// field-Properties

		// fields
		protected _Measures: MeasureViewModel[] = [];

		constructor(data?: DataContracts.IMeasuresViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IMeasuresViewModel): void {
			if(data) {
				// vul properties
				this._Measures = data.Measures ? data.Measures.map(measureViewModelItem => new MeasureViewModel(measureViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IMeasuresViewModel {
			return {
				Measures: (this.Measures ? this.Measures.map(measureViewModelItem => new MeasureViewModel(measureViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.MeasureRequestModel
	 */
	 

	export interface IMeasureRequestModel {
		MeasureId: number;
		ToJsonContract(): DataContracts.IMeasureRequestModel;
	}

	export class MeasureRequestModel {
		// property-Properties
		// field-Properties
		public get MeasureId(): number {
			return this._MeasureId;
		}
		public set MeasureId(newMeasureId: number) {
			this._MeasureId = newMeasureId;
		}

		// fields
		protected _MeasureId: number = 0;

		constructor(data?: DataContracts.IMeasureRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IMeasureRequestModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._MeasureId = data.MeasureId;
			}
		}

		public ToJsonContract(): DataContracts.IMeasureRequestModel {
			return {
				MeasureId: this.MeasureId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.MeasureViewModel
	 */
	 

	export interface IMeasureViewModel {
		Id: number;
		DisplayName: string;
		IsExpanded: boolean;
		ModelCode: string;
		BuildingComponent: BuildingComponentViewModel.IBuildingComponentViewModel;
		BuildingComponentImprovementId: number;
		Efficacies: EfficacyViewModel.IEfficacyViewModel[];
		TradeAssociations: TradeAssociationViewModel.ITradeAssociationViewModel[];
		QualityMarks: TradeAssociationViewModel.ITradeAssociationViewModel[];
		ToJsonContract(): DataContracts.IMeasureViewModel;
	}

	export class MeasureViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get DisplayName(): string {
			return this._DisplayName;
		}
		public set DisplayName(newDisplayName: string) {
			this._DisplayName = newDisplayName;
		}

		public get IsExpanded(): boolean {
			return this._IsExpanded;
		}
		public set IsExpanded(newIsExpanded: boolean) {
			this._IsExpanded = newIsExpanded;
		}

		public get ModelCode(): string {
			return this._ModelCode;
		}
		public set ModelCode(newModelCode: string) {
			this._ModelCode = newModelCode;
		}

		public get BuildingComponent(): BuildingComponentViewModel.IBuildingComponentViewModel {
			return this._BuildingComponent;
		}
		public set BuildingComponent(newBuildingComponent: BuildingComponentViewModel.IBuildingComponentViewModel) {
			this._BuildingComponent = newBuildingComponent;
		}

		public get BuildingComponentImprovementId(): number {
			return this._BuildingComponentImprovementId;
		}
		public set BuildingComponentImprovementId(newBuildingComponentImprovementId: number) {
			this._BuildingComponentImprovementId = newBuildingComponentImprovementId;
		}

		public get Efficacies(): EfficacyViewModel.IEfficacyViewModel[] {
			return this._Efficacies;
		}
		public set Efficacies(newEfficacies: EfficacyViewModel.IEfficacyViewModel[]) {
			this._Efficacies = newEfficacies;
		}

		public get TradeAssociations(): TradeAssociationViewModel.ITradeAssociationViewModel[] {
			return this._TradeAssociations;
		}
		public set TradeAssociations(newTradeAssociations: TradeAssociationViewModel.ITradeAssociationViewModel[]) {
			this._TradeAssociations = newTradeAssociations;
		}

		public get QualityMarks(): TradeAssociationViewModel.ITradeAssociationViewModel[] {
			return this._QualityMarks;
		}
		public set QualityMarks(newQualityMarks: TradeAssociationViewModel.ITradeAssociationViewModel[]) {
			this._QualityMarks = newQualityMarks;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _DisplayName: string = "";
		protected _IsExpanded: boolean = false;
		protected _ModelCode: string = "";
		protected _BuildingComponent: BuildingComponentViewModel.IBuildingComponentViewModel = new BuildingComponentViewModel.BuildingComponentViewModel(undefined);
		protected _BuildingComponentImprovementId: number = 0;
		protected _Efficacies: EfficacyViewModel.IEfficacyViewModel[] = [];
		protected _TradeAssociations: TradeAssociationViewModel.ITradeAssociationViewModel[] = [];
		protected _QualityMarks: TradeAssociationViewModel.ITradeAssociationViewModel[] = [];

		constructor(data?: DataContracts.IMeasureViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IMeasureViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._DisplayName = data.DisplayName;
				this._IsExpanded = data.IsExpanded;
				this._ModelCode = data.ModelCode;
				this._BuildingComponent = data.BuildingComponent ? new BuildingComponentViewModel.BuildingComponentViewModel(data.BuildingComponent): undefined;
				this._BuildingComponentImprovementId = data.BuildingComponentImprovementId;
				this._Efficacies = data.Efficacies ? data.Efficacies.map(efficacyViewModelItem => new EfficacyViewModel.EfficacyViewModel(efficacyViewModelItem)): [];
				this._TradeAssociations = data.TradeAssociations ? data.TradeAssociations.map(tradeAssociationViewModelItem => new TradeAssociationViewModel.TradeAssociationViewModel(tradeAssociationViewModelItem)): [];
				this._QualityMarks = data.QualityMarks ? data.QualityMarks.map(tradeAssociationViewModelItem => new TradeAssociationViewModel.TradeAssociationViewModel(tradeAssociationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IMeasureViewModel {
			return {
				Id: this.Id,
				DisplayName: this.DisplayName,
				IsExpanded: this.IsExpanded,
				ModelCode: this.ModelCode,
				BuildingComponent: (this.BuildingComponent ? new BuildingComponentViewModel.BuildingComponentViewModel(this.BuildingComponent).ToJsonContract(): this.BuildingComponent),
				BuildingComponentImprovementId: this.BuildingComponentImprovementId,
				Efficacies: (this.Efficacies ? this.Efficacies.map(efficacyViewModelItem => new EfficacyViewModel.EfficacyViewModel(efficacyViewModelItem).ToJsonContract()): []),
				TradeAssociations: (this.TradeAssociations ? this.TradeAssociations.map(tradeAssociationViewModelItem => new TradeAssociationViewModel.TradeAssociationViewModel(tradeAssociationViewModelItem).ToJsonContract()): []),
				QualityMarks: (this.QualityMarks ? this.QualityMarks.map(tradeAssociationViewModelItem => new TradeAssociationViewModel.TradeAssociationViewModel(tradeAssociationViewModelItem).ToJsonContract()): [])
			}
		}
	}
