/**
 * @ref WoonConnectViewModel/Login/CapabilitiesModel.cs
 */

 import * as DataContracts from "@datacontracts/CapabilitiesModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Login.CapabilitiesRequestModel
	 */
	 

	export interface ICapabilitiesRequestModel {
		Browser: string;
		BrowserVersion: string;
		Device: string;
		DeviceType: string;
		OS: string;
		OSVersion: string;
		Orientation: string;
		UserAgent: string;
		OuterWidth: number;
		OuterHeight: number;
		InnerWidth: number;
		InnerHeight: number;
		DevicePixelRatio: number;
		LocalTime: Date;
		Languages: string;
		TimeZone: string;
		WebGL: string;
		ToJsonContract(): DataContracts.ICapabilitiesRequestModel;
	}

	export class CapabilitiesRequestModel {
		// property-Properties
		public get Browser(): string {
			return this._Browser;
		}
		public set Browser(newBrowser: string) {
			this._Browser = newBrowser;
		}

		public get BrowserVersion(): string {
			return this._BrowserVersion;
		}
		public set BrowserVersion(newBrowserVersion: string) {
			this._BrowserVersion = newBrowserVersion;
		}

		public get Device(): string {
			return this._Device;
		}
		public set Device(newDevice: string) {
			this._Device = newDevice;
		}

		public get DeviceType(): string {
			return this._DeviceType;
		}
		public set DeviceType(newDeviceType: string) {
			this._DeviceType = newDeviceType;
		}

		public get OS(): string {
			return this._OS;
		}
		public set OS(newOS: string) {
			this._OS = newOS;
		}

		public get OSVersion(): string {
			return this._OSVersion;
		}
		public set OSVersion(newOSVersion: string) {
			this._OSVersion = newOSVersion;
		}

		public get Orientation(): string {
			return this._Orientation;
		}
		public set Orientation(newOrientation: string) {
			this._Orientation = newOrientation;
		}

		public get UserAgent(): string {
			return this._UserAgent;
		}
		public set UserAgent(newUserAgent: string) {
			this._UserAgent = newUserAgent;
		}

		public get OuterWidth(): number {
			return this._OuterWidth;
		}
		public set OuterWidth(newOuterWidth: number) {
			this._OuterWidth = newOuterWidth;
		}

		public get OuterHeight(): number {
			return this._OuterHeight;
		}
		public set OuterHeight(newOuterHeight: number) {
			this._OuterHeight = newOuterHeight;
		}

		public get InnerWidth(): number {
			return this._InnerWidth;
		}
		public set InnerWidth(newInnerWidth: number) {
			this._InnerWidth = newInnerWidth;
		}

		public get InnerHeight(): number {
			return this._InnerHeight;
		}
		public set InnerHeight(newInnerHeight: number) {
			this._InnerHeight = newInnerHeight;
		}

		public get DevicePixelRatio(): number {
			return this._DevicePixelRatio;
		}
		public set DevicePixelRatio(newDevicePixelRatio: number) {
			this._DevicePixelRatio = newDevicePixelRatio;
		}

		public get LocalTime(): Date {
			return this._LocalTime;
		}
		public set LocalTime(newLocalTime: Date) {
			this._LocalTime = newLocalTime;
		}

		public get Languages(): string {
			return this._Languages;
		}
		public set Languages(newLanguages: string) {
			this._Languages = newLanguages;
		}

		public get TimeZone(): string {
			return this._TimeZone;
		}
		public set TimeZone(newTimeZone: string) {
			this._TimeZone = newTimeZone;
		}

		public get WebGL(): string {
			return this._WebGL;
		}
		public set WebGL(newWebGL: string) {
			this._WebGL = newWebGL;
		}

		// field-Properties

		// fields
		protected _Browser: string = "";
		protected _BrowserVersion: string = "";
		protected _Device: string = "";
		protected _DeviceType: string = "";
		protected _OS: string = "";
		protected _OSVersion: string = "";
		protected _Orientation: string = "";
		protected _UserAgent: string = "";
		protected _OuterWidth: number = 0;
		protected _OuterHeight: number = 0;
		protected _InnerWidth: number = 0;
		protected _InnerHeight: number = 0;
		protected _DevicePixelRatio: number = 0;
		protected _LocalTime: Date = new Date();
		protected _Languages: string = "";
		protected _TimeZone: string = "";
		protected _WebGL: string = "";

		constructor(data?: DataContracts.ICapabilitiesRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICapabilitiesRequestModel): void {
			if(data) {
				// vul properties
				this._Browser = data.Browser;
				this._BrowserVersion = data.BrowserVersion;
				this._Device = data.Device;
				this._DeviceType = data.DeviceType;
				this._OS = data.OS;
				this._OSVersion = data.OSVersion;
				this._Orientation = data.Orientation;
				this._UserAgent = data.UserAgent;
				this._OuterWidth = data.OuterWidth;
				this._OuterHeight = data.OuterHeight;
				this._InnerWidth = data.InnerWidth;
				this._InnerHeight = data.InnerHeight;
				this._DevicePixelRatio = data.DevicePixelRatio;
				this._LocalTime = data.LocalTime;
				this._Languages = data.Languages;
				this._TimeZone = data.TimeZone;
				this._WebGL = data.WebGL;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ICapabilitiesRequestModel {
			return {
				Browser: this.Browser,
				BrowserVersion: this.BrowserVersion,
				Device: this.Device,
				DeviceType: this.DeviceType,
				OS: this.OS,
				OSVersion: this.OSVersion,
				Orientation: this.Orientation,
				UserAgent: this.UserAgent,
				OuterWidth: this.OuterWidth,
				OuterHeight: this.OuterHeight,
				InnerWidth: this.InnerWidth,
				InnerHeight: this.InnerHeight,
				DevicePixelRatio: this.DevicePixelRatio,
				LocalTime: this.LocalTime,
				Languages: this.Languages,
				TimeZone: this.TimeZone,
				WebGL: this.WebGL
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Login.CapabilitiesResponseModel
	 */
	 

	export interface ICapabilitiesResponseModel {
		Succeeded: boolean;
	}

	export class CapabilitiesResponseModel {
		// property-Properties
		// field-Properties
		public get Succeeded(): boolean {
			return this._Succeeded;
		}
		public set Succeeded(newSucceeded: boolean) {
			this._Succeeded = newSucceeded;
		}

		// fields
		protected _Succeeded: boolean = false;

		constructor(data?: DataContracts.ICapabilitiesResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICapabilitiesResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Succeeded = data.Succeeded;
			}
		}
	}
