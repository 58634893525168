import { createAction, props } from '@ngrx/store';

import { IDwellingSurvey } from '@datacontracts/DwellingSurvey';
import { ISurveyProperty } from '@datacontracts/SurveyProperty';
import { treenode } from '../../smarttwin-ui/components/wct-stui-tree-menu/wct-stui-tree-menu.component';
import { ISurveyClimateSystem } from '@datacontracts/SurveyClimateSystem';

export const surveyLoaded = createAction('[Survey] surveyLoaded', props<{survey: IDwellingSurvey}>());
export const requestLoadSurvey = createAction('[Survey] requestLoadSurvey');
export const surveySaved = createAction('[Survey] surveySaved');
export const updateStringProperty = createAction('[Survey] updateStringProperty', props<{prop: ISurveyProperty}>());
export const setActiveTreeNode = createAction('[Survey] setActiveTreeNode', props<{node: treenode}>());
export const addClimateSystem = createAction('[Survey] addClimateSystem', props<{system: ISurveyClimateSystem}>());

export const requestSave = createAction('[Survey] requestSave');
export const requestTest = createAction('[Survey] requestTest');
export const surveyTested = createAction('[Survey] surveyTested');

