import { IEnergieAdviesComparisonTableBodyController } from './../../../../../components/energieadvies/comparisontable/WctEnergieAdviesComparisonTableBody';
// [conv] import "(./FabProduct).less";
import { Component, DoCheck, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import * as _ from 'lodash';

import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { BCBProductViewModel, IBCBProductViewModel, BCBUrlViewModel } from '@models/BCBProductModel';
import { SceneSession } from '../../../../../services/SceneTypescript';
import { WebGLSessie } from '../../../../../Models/WebGLSessie';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Clipboard } from '@angular/cdk/clipboard';
import { GoogleAnalyticsService } from '../../../../../services/GoogleAnalyticsService';
import { ConstSoftware } from '../../../../../Helpers/Backwards';

@Component({
  selector: 'bestek',
  templateUrl: './bestek.component.html',
  styleUrls: ['./bestek.component.scss'],
})

export class BestekComponent implements DoCheck {
  ErrorBijLaden: boolean = false;
  Laden: boolean = true;
  CadLaden: boolean = false;
  Dwg2DLaden: boolean = false;
  Dwg3DLaden: boolean = false;
  Dxf2DLaden: boolean = false;
  Dxf3DLaden: boolean = false;
  IfcLaden: boolean = false;
  RfaLaden: boolean = false;
  HdhRevitLaden: boolean = true;
  BCBProductModel: BCBProductViewModel;

  @Input()
  set product(pProduct: BCBProductViewModel) {
    if (this.BCBProductModel != pProduct) {
      this.BCBProductModel = pProduct;
      this.Refresh();
    }
  }

  @Input()
  set lastselectedview(lastselectedview: string) {
    this.BCBProductModel.ViewSelected = lastselectedview;
  }

  @Output() public setlastselectedview = new EventEmitter<any>();
  @Output() public setlastselectedproduct = new EventEmitter<any>();

  @ViewChild('fabProductBestek') fabProductBestek: ElementRef;
  @ViewChild('fabProductViewerLeft') fabProductViewerLeft: ElementRef;

  TrustedSVGUrl: any;
  m_Session: SceneSession;
  ViewerSessie: WebGLSessie = new WebGLSessie("local_BestekViewer");
  m_ViewerInitialised: boolean = false;
  m_ViewerAdd: string = null;

  public ViewLoaded: boolean = false;
  public LastViewSelected: string;
  public ShowNotificationCopiedToClipboard: boolean = false;
  public CADLocation: string = "";
  public IFCLocation: string = "";
  public RFALocation: string = "";
  public DwgLocation: string = "";
  public DxfLocation: string = "";
  public HDHRevitLocation: string = "";

  public LastWidth: number = 0;
  public LastHeight: number = 0;

  constructor(
    private sanitizer: DomSanitizer,
    private fab: FabrikantenConsumer,
    private clipboard: Clipboard,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
  }

  public Refresh(): void {
    this.Laden = true;
    this.ErrorBijLaden = false;

    if (this.BCBProductModel != undefined) {
      if (this.LastWidth != Math.round($(".fab-product__viewer-left").width())) {
        this.LastWidth = Math.round($(".fab-product__viewer-left").width());
      }
      if (this.LastHeight != Math.round($(".fab-product__viewer-left").height())) {
        this.LastHeight = Math.round($(".fab-product__viewer-left").height());
      }

      this.BCBProductModel.SVGWidth = 0;
      this.BCBProductModel.SVGHeight = 0;
      this.BCBProductModel.ViewSelected = "Bestek";

      this.fab.GetBCBProduct_Observable(this.BCBProductModel).subscribe((data) => {
        this.BCBProductModel = new BCBProductViewModel(data);

        if (this.BCBProductModel.SVG != undefined) {
          this.TrustedSVGUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.BCBProductModel.SVG);
        }
        else {
          this.TrustedSVGUrl = undefined;
        }

        if (this.BCBProductModel.WebGLJSON != undefined) {
          this.m_ViewerAdd = this.BCBProductModel.WebGLJSON;

          setTimeout(this.ngDoCheck, 100);
        }



        this.Laden = false;

        this.setlastselectedproduct.emit({ selectedproduct: this.BCBProductModel });

        // in case 2D or 3D was selected and the product has an abstract generated CAD that shouldn't be shown
        if (this.BCBProductModel.HeeftCADAbstract && (this.BCBProductModel.ViewSelected == '3D' || this.BCBProductModel.ViewSelected == '2D')) {
          this.SetViewSelected('Bestek');
        }
      }, error => {
        this.ErrorBijLaden = true;
        this.Laden = false;
      });


      // Google Analytics event
      this.googleAnalyticsService.sendCustomEvent("Bestekservice", "ProductView" + this.BCBProductModel.ViewSelected, this.BCBProductModel.Naam, this.BCBProductModel.Id);
    }
  }

  ngDoCheck() {
    if (this.ViewerSessie !== undefined) {
      if (!this.m_ViewerInitialised) {
        if (this.ViewerSessie.SceneSession != null && this.ViewerSessie.SceneSession.GeefViewer() != null) {
          this.m_ViewerInitialised = true;

          console.log('viewer initialised');
        }
      }

      if (this.m_ViewerInitialised && this.m_ViewerAdd && (this.ViewerSessie.SceneSession !== undefined && this.ViewerSessie.SceneSession.GeefViewer() != null)) {
        this.LoadViewer();
      }
    }
  }

  public LoadViewer() {
    var viewer = this.ViewerSessie.SceneSession.GeefViewer();

    if (viewer !== null) {
      viewer.clear();

      viewer.add(this.BCBProductModel.WebGLJSON, () => {
        viewer.belichting.isCameraLicht = false;
        viewer.belichting._isCameraLicht = false;
        viewer.belichting.toonSlagschaduw = true;
        viewer.belichting._toonSlagschaduw = true;
        viewer.kijkrichting = [90, 90, -60];
        viewer.toonModel();
      });
    }

    this.m_ViewerAdd = null;
  }

  SessieGeregistreerd(session: SceneSession) {
    var viewer = session.GeefViewer();

    this.m_Session = session;
  }

  public CopyActiveViewToClipboard() {
    // Google Analytics event
    this.googleAnalyticsService.sendCustomEvent("Bestekservice", "ProductClipboardActiveView", this.BCBProductModel.Naam, this.BCBProductModel.Id);

    if (this.BCBProductModel.ViewSelected == 'OSF') {
      this.CopyToClipboardHtml(this.BCBProductModel.OSFHTML);
      //} else if (this.ViewSelected == 'SUF') {
      // this.clipboard.copy(this.BCBProductModel.SUF);
    } else if (this.BCBProductModel.ViewSelected == 'Bestek') {
      this.CopyToClipboardHtml(this.BCBProductModel.Bestek);
    }

    this.ShowNotificationCopiedToClipboard = true;
  }

  public CopyOSFToClipboard() {
    // Google Analytics event
    this.googleAnalyticsService.sendCustomEvent("Bestekservice", "ProductClipboardOSF", this.BCBProductModel.Naam, this.BCBProductModel.Id);

    this.CopyToClipboardHtml(this.BCBProductModel.OSFHTML);
  }

  public CopyBestekToClipboard() {
    // Google Analytics event
    this.googleAnalyticsService.sendCustomEvent("Bestekservice", "ProductClipboardBestek", this.BCBProductModel.Naam, this.BCBProductModel.Id);

    this.clipboard.copy(this.fabProductBestek.nativeElement.innerText);
    this.ShowNotificationCopiedToClipboard = true;
  }

  public GetMaximimumHeightForViewerLeft() {
    let ret: string = "none";
    if (this.fabProductViewerLeft !== undefined) {
      if (this.fabProductViewerLeft.nativeElement.offsetWidth < this.fabProductViewerLeft.nativeElement.offsetHeight) {
        ret = this.fabProductViewerLeft.nativeElement.offsetWidth + "px";
      }
    }
    return ret;
  }

  public CopySUFToClipboard() {
    // Google Analytics event
    this.googleAnalyticsService.sendCustomEvent("Bestekservice", "ProductClipboardSUF", this.BCBProductModel.Naam, this.BCBProductModel.Id);

    this.clipboard.copy(this.BCBProductModel.SUF);
    this.ShowNotificationCopiedToClipboard = true;
  }

  public getInnerHTML(val) {
    val = val.replace(/(<([^>]+)>)/ig, '<br>');
    val = val.split("<br><br>").join("<br>");
    val = val.split("<br><br>").join("<br>");
    val = val.split("<br><br>").join("<br>");
    val = val.split("<br> <br>").join("<br>");
    val = val.split("<br> <br>").join("<br>");
    val = val.split("<br> <br>").join("<br>");
    return val;
  }

  public HideNotificationCopiedToClipboard(ev:any) {
    this.ShowNotificationCopiedToClipboard = false;
  }

  public CopyToClipboardHtml(str: any) {
    const el = document.createElement('p');
    //str = str.replace(/(<([^>]+)>)/ig,'<br>');  
    //str = this.RemoveAllDoubleBreaks(str); 
    var brRegex = /<br\s*[\/]?>/gi;
    str = str.replace(brRegex, "\r\n");
    el.innerHTML = str;
    this.clipboard.copy(el.innerHTML);
  };

  public RemoveAllDoubleBreaks(str: string) {
    str = str.split("<br><br>").join("<br>");
    str = str.split("<br><br>").join("<br>");
    str = str.split("<br><br>").join("<br>");
    str = str.split("<br> <br>").join("<br>");
    str = str.split("<br> <br>").join("<br>");
    str = str.split("<br> <br>").join("<br>");
    if (str.startsWith("<br>")) {
      str = str.replace("<br>", "");
    }
    return str;
  }

  public GetMaximimumHeight() {
    let ret: number = 0;
    let FabFiltersMainElement: any = document.getElementsByClassName("fab-filters__main")[0];
    //let FabFiltersMainTitleElement: any = document.getElementsByClassName("fab-filters__title-for-products")[0];
    // let FabFiltersButtonsForProductsElement: any = document.getElementsByClassName("fab-filters__buttons-for-products")[0];
    let FabFiltersBreadcrumbElement: any = document.getElementsByClassName("fab-filters__breadcrumb")[0];
    let FabProductTitleElement: any = document.getElementsByClassName("fab-product__title")[0];
    let FabProductDescriptionElement: any = document.getElementsByClassName("fab-product__description")[0];
    let FabFiltersButtonFilterMenuElement: any = document.getElementsByClassName(" fab-filters__button-filter-menu")[0];

    if (
      (FabFiltersMainElement !== undefined) && (FabFiltersBreadcrumbElement !== undefined) &&
      (FabProductTitleElement !== undefined) && (FabProductDescriptionElement !== undefined) &&
      (FabFiltersButtonFilterMenuElement !== undefined)
    ) {
      ret = FabFiltersMainElement.offsetHeight - FabFiltersBreadcrumbElement.offsetHeight -
        FabProductTitleElement.offsetHeight - FabProductDescriptionElement.offsetHeight -
        FabFiltersButtonFilterMenuElement.offsetHeight;
    }
    return ret;
  }

  public SetViewSelected(viewSelected: string) {
    let OldViewSelected = this.BCBProductModel.ViewSelected;
    this.BCBProductModel.ViewSelected = viewSelected;

    this.setlastselectedview.emit({ selectedview: this.BCBProductModel.ViewSelected });

    if (OldViewSelected !== '3D' && this.BCBProductModel.ViewSelected == '3D') {
      let t: any = this;
      setTimeout(function () {
        t.LoadViewer();
      }, 1000);
    }

    if (OldViewSelected != this.BCBProductModel.ViewSelected) {
      this.Refresh();
    }

    // Google Analytics event
    this.googleAnalyticsService.sendCustomEvent("Bestekservice", "ProductView" + this.BCBProductModel.ViewSelected, this.BCBProductModel.Naam, this.BCBProductModel.Id);
  }

  PanOmhoog() {
    this.ViewerSessie.SceneSession.Pan(0, 500);
  }

  PanRechts() {
    this.ViewerSessie.SceneSession.Pan(500, 0);
  }

  PanBeneden() {
    this.ViewerSessie.SceneSession.Pan(0, -500);
  }

  PanLinks() {
    this.ViewerSessie.SceneSession.Pan(-500, 0);
  }

  public ParameterIsShown(parameter: string) {
    let ret: boolean = false;
    if (parameter !== null && parameter !== "") {
      ret = true;
    }
    return ret;
  }

  public LoadCAD(): void {
    this.CadLaden = true;
    this.fab.GetBCBProductCAD_Observable(this.BCBProductModel).subscribe((data) => {
      this.CADLocation = (new BCBUrlViewModel(data)).URL;
      this.CadLaden = false;

      this.googleAnalyticsService.sendCustomEvent("Bestekservice", "DownloadDHG", this.BCBProductModel.Naam, this.BCBProductModel.Id);
      this.OpenInSameWindow(this.CADLocation);
    }, error => {
      alert('error');
      this.CadLaden = false;
    });
  }

  public LoadCADIFC(): void {
    this.IfcLaden = true;
    this.fab.GetBCBProductCADIFC_Observable(this.BCBProductModel).subscribe((data) => {
      this.IFCLocation = (new BCBUrlViewModel(data)).URL;
      this.IfcLaden = false;

      this.googleAnalyticsService.sendCustomEvent("Bestekservice", "DownloadIFC", this.BCBProductModel.Naam, this.BCBProductModel.Id);
      this.OpenInSameWindow(this.IFCLocation);
    }, error => {
      alert('error');
      this.IfcLaden = false;
    });
  }

  public OpenInSameWindow(url: string): void {
    window.location.href = url;
  }

  public LoadCADDwg3D(): void {
    this.Dwg3DLaden = true;
    this.fab.GetBCBProductCADDWG3D_Observable(this.BCBProductModel).subscribe((data) => {
      if ((new BCBUrlViewModel(data)).URL.indexOf("Exception") == -1) {
        this.DwgLocation = (new BCBUrlViewModel(data)).URL;

        this.Dwg3DLaden = false;

        this.googleAnalyticsService.sendCustomEvent("Bestekservice", "DownloadDWG3D", this.BCBProductModel.Naam, this.BCBProductModel.Id);
        this.OpenInSameWindow(this.DwgLocation);
      }
    }, error => {
      alert('error');
      this.Dwg3DLaden = false;
    });
  }

  public LoadCADDwg2D(): void {
    this.Dwg2DLaden = true;
    this.fab.GetBCBProductCADDWG2D_Observable(this.BCBProductModel).subscribe((data) => {
      if ((new BCBUrlViewModel(data)).URL.indexOf("Exception") == -1) {
        this.DwgLocation = (new BCBUrlViewModel(data)).URL;

        this.Dwg2DLaden = false;

        this.googleAnalyticsService.sendCustomEvent("Bestekservice", "DownloadDWG2D", this.BCBProductModel.Naam, this.BCBProductModel.Id);
        this.OpenInSameWindow(this.DwgLocation);
      }
    }, error => {
      alert('error');
      this.Dwg2DLaden = false;
    });
  }

  public LoadCADDxf2D(): void {
    this.Dxf2DLaden = true;
    this.fab.GetBCBProductCADDXF2D_Observable(this.BCBProductModel).subscribe((data) => {
      if ((new BCBUrlViewModel(data)).URL.indexOf("Exception") == -1) {
        this.DxfLocation = (new BCBUrlViewModel(data)).URL;

        this.Dxf2DLaden = false;

        this.googleAnalyticsService.sendCustomEvent("Bestekservice", "DownloadDXF2D", this.BCBProductModel.Naam, this.BCBProductModel.Id);
        this.OpenInSameWindow(this.DxfLocation);
      }
    }, error => {
      alert('error');
      this.Dxf2DLaden = false;
    });
  }

  public LoadCADDxf3D(): void {
    this.Dxf3DLaden = true;
    this.fab.GetBCBProductCADDXF3D_Observable(this.BCBProductModel).subscribe((data) => {
      if ((new BCBUrlViewModel(data)).URL.indexOf("Exception") == -1) {
        this.DxfLocation = (new BCBUrlViewModel(data)).URL;

        this.Dxf3DLaden = false;

        this.googleAnalyticsService.sendCustomEvent("Bestekservice", "DownloadDXF3D", this.BCBProductModel.Naam, this.BCBProductModel.Id);
        this.OpenInSameWindow(this.DxfLocation);
      }
    }, error => {
      alert('error');
      this.Dxf3DLaden = false;
    });
  }

  public DownloadProductInformatie(url: string) {
    this.googleAnalyticsService.sendCustomEvent("Bestekservice", "DownloadProductInformatie", this.BCBProductModel.Naam, this.BCBProductModel.Id);
    this.OpenInSameWindow(url);
  }

  public OpenBCB(url: string) {
    this.googleAnalyticsService.sendCustomEvent("Bestekservice", "OpenBCB", this.BCBProductModel.Naam, this.BCBProductModel.Id);
    window.open(url, '_blank');
  }

  public DownloadBestek() {
    if (this.BCBProductModel != null) {
      var data = this.fabProductBestek.nativeElement.innerText;
      var c = document.createElement("a");
      c.download = this.BCBProductModel.Naam + ".txt";

      var t = new Blob([data], {
        type: "text/plain"
      });
      c.href = window.URL.createObjectURL(t);
      c.click();
    }
  }

  public DownloadBestekStabu() {

    if (this.BCBProductModel != null) {
      var data = this.BCBProductModel.SUF;
      var c = document.createElement("a");
      c.download = this.BCBProductModel.Naam + ".S01";

      var t = new Blob([data], {
        type: "text/plain"
      });
      c.href = window.URL.createObjectURL(t);
      c.click();
    }
  }
}
