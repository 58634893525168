<div class="photo-popup" *ngIf="PhotoName != '' && PhotoName != null">
  <div class="photo-popup-inner">
    <img style="max-width: 100%; max-height: 700px;" src="/Content/KlantSpecifiek/BCB21111/product/{{ PhotoName }}" />
    <a href="#" onclick="return false;" (click)="PhotoName = '';">sluiten</a>
  </div>
</div>

<style>
  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
  }

    /* Tooltip text */
    .tooltip .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: white;
      color: #000;
      text-align: center;
      padding: 5px;
      border-radius: 3px;
      border: 1px solid #000;
      /* Position the tooltip text - see examples below! */
      position: absolute;
      z-index: 1;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
      visibility: visible;
    }
</style>



<div style="margin-top: 10px;">
  <div *ngIf="IsLoading()" class="mat-progress-spinner__cont content">
    <div style="padding-top: 100px; text-align: center;">
      <div>
        <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
      </div>
      <div>
        <p>De categorieën worden geladen</p>
      </div>
    </div>
  </div>

  <div *ngIf="!IsLoading()">
    <div class="content">
      <ul class="menu">
        <li *ngFor="let categorie of prijsmoduleservice.CategoriesViewModel.CategorieViewModels"><a routerLink="/overview/{{ categorie.Id }}" [class.active]="categorie.Id == prijsmoduleservice.CategorieId">{{ categorie.Name }}</a></li>
      </ul>
    </div>

    <div class="main-div">
      <table cellpadding="5" cellspacing="5">
        <tr style="font-size: 14px;">
          <td>
            <div>
              <input type="text" [(ngModel)]="prijsmoduleservice.SearchString" (change)="RefreshPageIndex()" (keyup)="RefreshPageIndex()" style="height: 18px;" />
              <img src="/Content/Images/woningselectie/Zoek.png" style="vertical-align: bottom;" />
            </div>
          </td>
          <td style="width: 80px;">
            <input type="number" min="1" style="width: 40px; height: 18px;" [(ngModel)]="prijsmoduleservice.PageIndex" (change)="RefreshPageIndex()" [attr.max]="GetPagesAmount()" /> / {{ GetPagesAmount() }}
          </td>
          <td>
            Products per pagina: <input type="number" style="width: 45px; height: 18px;" min="25" step="25" [(ngModel)]="prijsmoduleservice.ProductsPerPage" (change)="RefreshPageIndex()" />
          </td>
          <td>
            Toon schaal <input type="checkbox" [(ngModel)]="prijsmoduleservice.ToonSchaal" />
          </td>
          <td>
            <div *ngIf="!prijsmoduleservice.LoadingPrijsSchemas">
              Prijsschema
              <select [(ngModel)]="prijsmoduleservice.PrijsSchemaSelectedId" (change)="UpdatePrijsSchemaSelected()" style="height: 24px;">
                <option *ngFor="let prijsschema of prijsmoduleservice.PrijsSchemasViewModel.PrijsSchemas" [value]="prijsschema.Id">{{ prijsschema.Name }}</option>
              </select>
            </div>
          </td>
          <td>
            <button class="btn btn-default btn-tab-nav btn-tab-nav-right" style="height:24px" (click)="Download()">
              <img src="/Content/Images/ExcelClean.png" style=" height: 18px;" />
              <span style="position: relative; top: -3px;">
                download
              </span>
            </button>
          </td>
          <td>
            Versie: <span>{{ prijsmoduleservice.CategoriesViewModel.ExcelName }}</span>
          </td>
        </tr>
      </table>

      <div class="table-div">
        <div *ngIf="!prijsmoduleservice.LoadingProducts">

          <table class="overview-table" [class.overview-table-force]="GetForceSecondCol()" cellpadding="10" cellspacing="10">
            <thead>
              <tr>
                <td class="small"></td>
                <ng-container *ngFor="let filter of prijsmoduleservice.GetActiveFilters(); index as i">
                  <td class="normal" *ngIf="GetRotateCells()" [class.bottombar]="!prijsmoduleservice.HasSubCategorie2() && !prijsmoduleservice.HasSubCategorie1()" [class.first-cell]="i == 0" [class.first-cell-even]="i == 0" [class.first-cell-first-row]="i == 0" [class.header_cell_height]="GetRotateCells()">
                    <div class="header_cell_rotated">
                      <div [class.header_cell_rotated_width]="GetRotateCells() && filter.Name.trim().length > 35" [innerHTML]="GetFilterName(filter.Name)"></div>
                    </div>
                  </td>
                  <td class="normal" style="position:relative;" *ngIf="!GetRotateCells()" [class.bottombar]="!prijsmoduleservice.HasSubCategorie2() && !prijsmoduleservice.HasSubCategorie1()" [class.first-cell]="i == 0" [class.first-cell-even]="i == 0" [class.first-cell-first-row]="i == 0" [class.header_cell_height]="GetRotateCells()" [innerHTML]="GetFilterName(filter.Name)">
                  </td>
                </ng-container>

              </tr>
              <tr *ngIf="prijsmoduleservice.HasSubCategorie1()">
                <td class="small"></td>
                <ng-container *ngFor="let cat of prijsmoduleservice.GetSubCategories1(prijsmoduleservice.ToonSchaal); index as i">
                  <td [attr.colspan]="cat.Length" [class.first-cell-sub]="i == 0" [class.bottombar]="!prijsmoduleservice.HasSubCategorie2()" style="text-align: center; min-width: auto;" [innerHTML]="cat.Name"></td>
                </ng-container>
              </tr>
              <tr *ngIf="prijsmoduleservice.HasSubCategorie2()">
                <td class="small"></td>
                <ng-container *ngFor="let cat of prijsmoduleservice.GetSubCategories2(); index as i">
                  <td [attr.colspan]="cat.Length" [class.first-cell-sub]="i == 0" class="bottombar" style="text-align: center; min-width: auto;" [innerHTML]="cat.Name"></td>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let productvariant of prijsmoduleservice.Products; index as row">
                <tr>
                  <td>
                    <img *ngIf="productvariant['image'] != '' && productvariant['image'] != null" (click)="OpenPhotoDialog(productvariant['image'])" style="cursor: pointer;" src="/Content/Images/foto.png" />
                  </td>

                  <ng-container *ngFor="let filter of prijsmoduleservice.GetActiveFilters(); index as i">
                    <td [class.first-cell]="i == 0" [class.center]="ShouldCenter(filter.Name)" [class.first-cell-even]="i == 0 && (row % 2 == 1)" [class.first-cell-odd]="i == 0 && (row % 2 == 0)" [class.border-right]="ShouldDisplayBorderRight(i)" [innerHTML]="productvariant[filter.Id.toString()]"></td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>

        <div *ngIf="prijsmoduleservice.LoadingProducts" class="mat-progress-spinner__cont content">
          <div style="padding-top: 100px; text-align: center;">
            <div>
              <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
            </div>
            <div>
              <p>De producten worden geladen</p>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
