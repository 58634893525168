import { Component } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserService } from '../../auth/shared/services/user.service';
import { AccountService } from '../../services/AccountService';

@Component({
	template: 'start',
})
export class WctStartComponent  {
	constructor(private user: UserService, private router: Router, private acc: AccountService) { 
		let url = this.getUrl();
		this.router.navigate([url]);
	}

	getUrl(): string{
		// if (!this.user.isLoggedIn)
		// {
		// 	this.router.navigate(['/accountlogin']);
		// 	return;
		// }

		if(this.acc.HeeftRol('image annotator'))
		{
			return '/imageannotation/start';
		}

		if (this.acc.HeeftRol('MassaConfigureerder') )
		{
			return '/cfg/address';
		}

		if ( this.acc.HeeftRol('Eigenaar'))
		{
			return '/cfg/home';
		}

		if (this.acc.HeeftRol('admin'))
		{
			return '/admin';
		}

		if (this.acc.HeeftRol('beheerder'))
		{
			return '/beheer';
		}

		return '/accountlogin';
	}

}