/**
 * @ref WoonConnectViewModel/ImageAnnotation/GetPhotosModel.cs
 */

 import * as DataContracts from "@datacontracts/GetPhotosModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetPhotosRequestModel
	 */
	 

	export interface IGetPhotosRequestModel {
		PhotoSetId: number;
		PersoonId: number;
		ToJsonContract(): DataContracts.IGetPhotosRequestModel;
	}

	export class GetPhotosRequestModel {
		// property-Properties
		public get PhotoSetId(): number {
			return this._PhotoSetId;
		}
		public set PhotoSetId(newPhotoSetId: number) {
			this._PhotoSetId = newPhotoSetId;
		}

		public get PersoonId(): number {
			return this._PersoonId;
		}
		public set PersoonId(newPersoonId: number) {
			this._PersoonId = newPersoonId;
		}

		// field-Properties

		// fields
		protected _PhotoSetId: number = 0;
		protected _PersoonId: number = 0;

		constructor(data?: DataContracts.IGetPhotosRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetPhotosRequestModel): void {
			if(data) {
				// vul properties
				this._PhotoSetId = data.PhotoSetId;
				this._PersoonId = data.PersoonId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IGetPhotosRequestModel {
			return {
				PhotoSetId: this.PhotoSetId,
				PersoonId: this.PersoonId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetPhotosResponseModel
	 */
	 

	export interface IGetPhotosResponseModel {
		Photos: ImageAnnotationPhoto[];
	}

	export class GetPhotosResponseModel {
		// property-Properties
		public get Photos(): ImageAnnotationPhoto[] {
			return this._Photos;
		}
		public set Photos(newPhotos: ImageAnnotationPhoto[]) {
			this._Photos = newPhotos;
		}

		// field-Properties

		// fields
		protected _Photos: ImageAnnotationPhoto[] = [];

		constructor(data?: DataContracts.IGetPhotosResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetPhotosResponseModel): void {
			if(data) {
				// vul properties
				this._Photos = data.Photos ? data.Photos.map(imageAnnotationPhotoItem => new ImageAnnotationPhoto(imageAnnotationPhotoItem)): [];
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.ImageAnnotationPhoto
	 */
	 

	export interface IImageAnnotationPhoto {
		PhotoPath: string;
		PhotoId: number;
		ToJsonContract(): DataContracts.IImageAnnotationPhoto;
	}

	export class ImageAnnotationPhoto {
		// property-Properties
		public get PhotoPath(): string {
			return this._PhotoPath;
		}
		public set PhotoPath(newPhotoPath: string) {
			this._PhotoPath = newPhotoPath;
		}

		public get PhotoId(): number {
			return this._PhotoId;
		}
		public set PhotoId(newPhotoId: number) {
			this._PhotoId = newPhotoId;
		}

		// field-Properties

		// fields
		protected _PhotoPath: string = "";
		protected _PhotoId: number = 0;

		constructor(data?: DataContracts.IImageAnnotationPhoto) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IImageAnnotationPhoto): void {
			if(data) {
				// vul properties
				this._PhotoPath = data.PhotoPath;
				this._PhotoId = data.PhotoId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IImageAnnotationPhoto {
			return {
				PhotoPath: this.PhotoPath,
				PhotoId: this.PhotoId
			}
		}
	}
