import * as ng from 'angular';
import * as ConsumersProjectApiController from '@consumers/ProjectApiController';
import * as ConsumersProjectController from '@consumers/ProjectController';
import * as DataContractsProjectViewModel from '@datacontracts/ProjectViewModel';
import * as DataContractsProjectenModel from '@datacontracts/ProjectenModel';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AppConfigService } from '../infrastructure/app.configservice';
import { LogonConsumer } from '@consumers/LogonController';
import { Observable } from 'rxjs';
import { CapabilitiesService } from '../viewers/CapabilitiesService';

export interface IProjectService {
	GeefProjectenKriest(): ng.IPromise<DataContractsProjectenModel.IProjectenModel>;
	GeefProjecten(): ng.IPromise<DataContractsProjectViewModel.IProjectViewModel[]>;
	ZetProjectId(projectid: number): void;
	GeefProjectId(): number;
	GeefDisclaimer(): ng.IPromise<string>;
	GeefSubsidieDisclaimer(): ng.IPromise<string>;
	GeefSpecificatieTonenDisclaimer(): ng.IPromise<string>;
	GeefVoorwaarden(): ng.IPromise<string>;
	GeefAdviesAanvragenInfo(): ng.IPromise<string>;
	ZetSluitingsDatum(datum: string): ng.IPromise<string>;
	GeefSluitingsDatum(): ng.IPromise<string>;
}


@Injectable({
	providedIn: 'root',
})
export class ProjectService implements IProjectService {
	ServiceEventName: string = 'ProjectService-event';
	m_HuidigeProjectID: number = -1;
	m_Disclaimer: string = null;
  m_Opdrachtgever: string = null;
	m_SubisidieDisclaimer: string = null;
	m_SpecificatieTonenDisclaimer: string = null;
	m_Voorwaarden: string = null;
	m_AdviesAanvragenInfo: string = null;

	constructor(private config: AppConfigService, 
		private http: HttpClient, 
		private projectconsumer: ConsumersProjectController.ProjectConsumer, 
		private projectapiconsumer: ConsumersProjectApiController.ProjectAPIConsumer, 
		private capabilities: CapabilitiesService,
		private logonconsumer: LogonConsumer) {
		'ngInject';

		this.m_HuidigeProjectID = config.Software.InitieelProject;
	}

	public GeefProjectenKriest(): ng.IPromise<DataContractsProjectenModel.IProjectenModel> {
		return this.projectapiconsumer.GeefProjecten()
	}

	GeefProjecten(): ng.IPromise<DataContractsProjectViewModel.IProjectViewModel[]> {
		return this.projectconsumer.GeefProjecten();
	}

	GeefProjectId(): number {
		return this.m_HuidigeProjectID;
	}

	public ZetProjectId(projectid: number): void {
		this.m_HuidigeProjectID = (projectid);
	}

	GeefDisclaimer(): ng.IPromise<string> {
		let subj: Subject<string> = new Subject();

		//var deferred = this.$q.defer<string>();

		var opties = {responseType: 'text'};

		if (this.m_Disclaimer == null) {

			this.http.get('api/Logon/GeefDisclaimer', { responseType: 'text' }).subscribe(response => {
				subj.next(response);
				subj.complete();
			})

			// this.http.get('/Project/GeefDisclaimerTekst', { responseType: 'text' }).toPromise().then(d => {
			// 	var data: any = d;
			// 	this.m_Disclaimer = data;
			// 	//deferred.resolve(data);
			// 	subj.next(this.m_Disclaimer);;
			// 	subj.complete();

			// });
		} else {
			subj.next(this.m_Disclaimer);;
			subj.complete();
			//deferred.resolve(this.m_Disclaimer);
		}

		return subj.toPromise();

		//		return deferred.promise;
	}

  GeefOpdrachtgever(): ng.IPromise<string> {
		let subj: Subject<string> = new Subject();
		if (this.m_Opdrachtgever == null) {
			this.http.get('api/Logon/GeefOpdrachtgever', { responseType: 'text' }).subscribe(response => {
				subj.next(response);
				subj.complete();
			})
		} else {
			subj.next(this.m_Opdrachtgever);;
			subj.complete();
		}
		return subj.toPromise()
	}

	GeefSubsidieDisclaimer(): ng.IPromise<string> {
		let subj: Subject<string> = new Subject();
		//var deferred = this.$q.defer<string>();

		var opties = {};

		if (this.m_SubisidieDisclaimer == null) {
			this.http.post('/Project/GeefSubsidieDisclaimerTekst', opties).toPromise().then(d => {
				var data: any = d;
				this.m_SubisidieDisclaimer = data;
				//deferred.resolve(data);
				subj.next(this.m_SubisidieDisclaimer);;
				subj.complete();
			});
		} else {
			subj.next(this.m_SubisidieDisclaimer);;
			subj.complete();
		}

		return subj.toPromise();
	}

	GeefSpecificatieTonenDisclaimer(): ng.IPromise<string> {
		let subj: Subject<string> = new Subject();

		var opties = {};

		if (this.m_SpecificatieTonenDisclaimer == null) {
			this.http.post('/Project/GeefSpecificatieTonenDisclaimerTekst', opties).toPromise().then(d => {
				var data: any = d;
				this.m_SpecificatieTonenDisclaimer = data;
				subj.next(this.m_SpecificatieTonenDisclaimer);;
				subj.complete();
			});
		} else {
			subj.next(this.m_SpecificatieTonenDisclaimer);;
			subj.complete();
		}

		return subj.toPromise();
	}

	GeefAdviesAanvragenInfo(): ng.IPromise<string> {
		let subj: Subject<string> = new Subject();

		var opties = {};

		if (this.m_AdviesAanvragenInfo == null) {

			this.http.post('/Project/GeefAdviesAanvragenInfo', opties).toPromise().then(d => {
				var data: any = d;
				this.m_AdviesAanvragenInfo = data;
				subj.next(this.m_SpecificatieTonenDisclaimer);;
				subj.complete();
			});
		} else {
			subj.next(this.m_SpecificatieTonenDisclaimer);;
			subj.complete();
		}

		return subj.toPromise();
	}

	GeefVoorwaarden(): ng.IPromise<string> {
		let subj: Subject<string> = new Subject();

		var opties = {};

		if (this.m_Voorwaarden == null) {

			this.http.post('/Project/GeefVoorwaardenTekst', opties).toPromise().then(d => {
				var data: any = d;
				this.m_Voorwaarden = data;
				subj.next(this.m_Voorwaarden);;
				subj.complete();
			});
		} else {
			subj.next(this.m_Voorwaarden);;
			subj.complete();
		}

		return subj.toPromise();
	}

	ZetSluitingsDatum(datum: string): ng.IPromise<string> {
		let subj: Subject<string> = new Subject();

		var opties: any = {};
		opties.SluitingsDatum = datum;

		if (this.m_Voorwaarden == null) {

			this.http.post('/Project/ZetSluitingsDatum', opties).toPromise().then(d => {
				var data: any = d;

				subj.next(data);;
				subj.complete();
			});
		} else {
			subj.next(this.m_Voorwaarden);;
			subj.complete();
		}

		return subj.toPromise();
	}

	GeefSluitingsDatum(): ng.IPromise<string> {
		let subj: Subject<string> = new Subject();

		var opties: any = {};

		if (this.m_Voorwaarden == null) {

			this.http.post('/Project/GeefSluitingsDatum', opties).toPromise().then(d => {
				var data: any = d;

				subj.next(data);;
				subj.complete();
			});
		} else {
			subj.next(this.m_Voorwaarden);;
			subj.complete();
		}

		return subj.toPromise();
	}

	uploadFiles(files: any): ng.IPromise<any>{
		if (files.length === 0) {
			return;
		 }
		 let obs = new Observable(observer => {
			let url = '/project/UploadHttp';
			const formData: FormData = new FormData();
			for (let file of files) {
			   formData.append('uploadFiles', file, file.name);
			}
			const request = new HttpRequest('POST', url, formData);
			this.http.request(request).subscribe(r => {
			   if (r.type == HttpEventType.Response) {
				  observer.next(r.body[0]);
				  observer.complete();
			   }
			});
		 });
		 return obs.toPromise();
	}
}

