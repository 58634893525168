import * as ng from 'angular';
import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';
import * as ServicesProjectService from '.././services/ProjectService';
//import * as ServicesScenarioService from '.././services/ScenarioService';
import * as ServicesOptieService from '.././services/OptieService';
import * as ServicesWoningEventService from '.././services/WoningEventService';
import * as DataContractsWeergaveResultaatModel from '@datacontracts/WeergaveResultaatModel';
import * as ViewModelsWoningCamera from '.././Models/WoningCamera';
import * as ViewModelsMenuGroep from '.././Models/MenuGroep';
import * as ViewModelsGebouwBrok from '.././Models/GebouwBrok';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IModelCameraService {
	GebouwdeelGekozen(deel: ViewModelsGebouwBrok.GebouwBrok);
	MenuGroepGekozen(menu: ViewModelsMenuGroep.MenuGroep): void;
	KiesCamera(): ng.IPromise<any>;
	WijzigWeergave(menu: DataContractsWeergaveResultaatModel.IWoningWeergave, initieel: boolean);
	GeefWijkLopen(): boolean;
	GeefWijkMode(): boolean;
	HeeftCameraIndexGezet(): boolean;
	ResetCameraIndex3D();
	GeefExploded(): boolean;
	GeefWijkCameraIndex(): number;

	GeefCameraIndex(): number;
	GeefInvalshoek(): string;
	GeefTekeningNaam(): string;
	GeefTekeningNaamBlok3D(): string;
	GeefCameraIndexBlok(): number;
	GeefInvalshoekBlok(): string;
	GeefTekeningNaamBlok(): string;
}
@Injectable({
	providedIn: 'root',
})
export class ModelCameraService implements IModelCameraService {
	constructor(private $http: HttpClient, private woningeventservice: ServicesWoningEventService.WoningEventService, 
		private readonly projectservice: ServicesProjectService.ProjectService) {
'ngInject';

		this.UpdateScope();

		this.woningeventservice.ScopeWijzigEvent$.subscribe(x => {
			this.UpdateScope();
		});
	}

	private UpdateScope() {
		if (this.woningeventservice.GeefScope() == EnumsWijzigScopeEnum.WijzigScopeEnum.Complex) {
			this.m_WijkMode = true;
		}
		else {
			this.m_WijkMode = false;
		}
	}

	private mInvalshoek: string = "Definitief Ontwerp";
	private mInvalshoekBlok: string = "Definitief Ontwerp";
	private mTekeningNaam: string = null;
	private mTekeningNaamBlok: string = null;
	private mTekeningNaamBlok3D: string = null;
	private mCameraIndex: number = 1; /* Volledig */
	private mCameraIndexBlok: number = 1; /* Volledig */
	private mCameraIndexGezet: boolean = false;
	private mCameraIndexBlokGezet: boolean = false;
	private mExplodedView: boolean = false;

	m_LaatsteWoningTypeID: string = null;

	m_WijkLopen: boolean = false;
	m_WijkMode: boolean = false;
	m_WijkCameraIndex: number = -1;

	GeefCameraIndex(): number {
		return this.mCameraIndex;
	}

	GeefExploded(): boolean {
		return this.mExplodedView;
	}

	HeeftCameraIndexGezet(): boolean {
		return this.mCameraIndexGezet;
	}

	ResetCameraIndex3D() {
		this.mCameraIndexGezet = false;
	}

	GeefInvalshoek(): string {
		return this.mInvalshoek;
	}

	GeefTekeningNaam(): string {
		return this.mTekeningNaam;
	}

	GeefWijkLopen(): boolean {
		return this.m_WijkLopen;
	}

	GeefWijkMode(): boolean {
		return this.m_WijkMode;
	}

	GeefWijkCameraIndex(): number {
		return this.m_WijkCameraIndex;
	}

	GeefCameraIndexBlok(): number {
		return this.mCameraIndexBlok;

	}
	GeefInvalshoekBlok(): string {
		return this.mInvalshoekBlok;
	}

	GeefTekeningNaamBlok(): string {
		return this.mTekeningNaamBlok;
	}

	GeefTekeningNaamBlok3D(): string {
		return this.mTekeningNaamBlok3D;
	}

	WijzigWeergave(menu: DataContractsWeergaveResultaatModel.IWoningWeergave, initieel: boolean) {
		if (menu != undefined) {
			if (menu.WoningWeergaveCollectie != undefined) {
				if (menu.WoningWeergaveCollectie.Scope == EnumsWijzigScopeEnum.WijzigScopeEnum.Woning) {
					this.mCameraIndex = menu.CameraIndex;
					this.mExplodedView = menu.ExplodedView;

					var woningid = this.woningeventservice.GeefHuidigeWoningID();
					var scenario = this.woningeventservice.HuidigScenarioNummer;

					if (menu.CameraIndex >= 100) {
						this.mCameraIndexGezet = true;
						this.mCameraIndex = menu.CameraIndex;
					}
					this.mInvalshoek = menu.Invalshoek;
					this.mTekeningNaam = menu.TekeningNaam;
				}
				else if (menu.WoningWeergaveCollectie.Scope == EnumsWijzigScopeEnum.WijzigScopeEnum.Blok) {
					this.m_WijkMode = false;
					if (menu.JSFunctie == "BekijkLopen") {
						this.m_WijkLopen = true;
						this.mTekeningNaamBlok = "";
						this.mTekeningNaamBlok3D = "";
					} else if (menu.JSFunctie == "BekijkVogelvlucht") {
						this.m_WijkLopen = false;
						this.m_WijkMode = false;
						this.mTekeningNaamBlok = "";
						this.mTekeningNaamBlok3D = "";
					} else if (menu.JSFunctie == 'BekijkVogelvluchtDitComplex') {
						this.m_WijkLopen = false;
						this.m_WijkMode = true;
						this.mTekeningNaamBlok = "";
						this.mTekeningNaamBlok3D = "";
					} else {
						//if (menu.CameraIndex < 100) {
						this.mCameraIndexBlok = menu.CameraIndex;
						this.mInvalshoekBlok = menu.Invalshoek;
						this.mTekeningNaamBlok = menu.TekeningNaam;
						if (!initieel) {
							if (menu.TekeningNaam != null) {
								this.mTekeningNaamBlok3D = menu.TekeningNaam;
								this.m_WijkCameraIndex = -1;
							}
							else {
								this.m_WijkCameraIndex = menu.CameraIndex;
								this.mTekeningNaamBlok3D = null;
							}
						}
						//if (initieel) {
						//	this.mTekeningNaamBlok = "";
						//}
						//}
					}
				} else if (menu.WoningWeergaveCollectie.Scope == EnumsWijzigScopeEnum.WijzigScopeEnum.Complex) {
					if (menu.JSFunctie == 'BekijkVogelvluchtDitComplex') {
						this.m_WijkLopen = false;
						this.m_WijkMode = true;
						this.mTekeningNaamBlok = "";
					} else if (menu.JSFunctie == 'BekijkVogelvluchtDitComplexConcreet') {
						this.m_WijkLopen = false;
						this.m_WijkMode = true;
						this.mTekeningNaamBlok = "";
					} else if (menu.JSFunctie == 'BekijkLopenWijk') {
						this.m_WijkLopen = true;
						this.m_WijkMode = true;
						this.mTekeningNaamBlok = "";
					}
					else {
						this.mCameraIndexBlok = menu.CameraIndex;
						this.mInvalshoekBlok = menu.Invalshoek;
						this.mTekeningNaamBlok = menu.TekeningNaam;
						//if (initieel) {
						//	this.mTekeningNaamBlok = "";
						//}
					}
				}
			}
			else {
				console.log("WijzigWeergave(menu: WoningWeergave, initieel: boolean) aangeroepen met menu.collectie == null");
			}
		} else {
			console.log("WijzigWeergave(menu: WoningWeergave, initieel: boolean) aangeroepen met menu == null");
		}
	}

	KiesCamera(): ng.IPromise<any> {
		if (this.mExplodedView) {
			return this.$http.get("/Configurator/KiesCameraIndex?ProjectId=" + this.projectservice.GeefProjectId() + "&CameraID=" + this.GeefCameraIndex() + "&forceer=true&explode=true").toPromise();
		}
		else {
			return this.$http.get("/Configurator/KiesCameraIndex?ProjectId=" + this.projectservice.GeefProjectId() + "&CameraID=" + this.GeefCameraIndex()).toPromise();
		}
	}

	GebouwdeelGekozen(deel: ViewModelsGebouwBrok.GebouwBrok) {
		var woningType = deel.MenuGroep.WoningType;
		this.m_LaatsteWoningTypeID = woningType.RandomID;

		var camera = deel.MenuGroep.WoningType.Cameras[deel.CameraIndex];
		if (camera != null) {
			this.ZetCamera(camera);
			//MasterControllerInstance.TriggerCameraWijziging(camera.Nummer, camera.Invalshoek, camera.Tekening);
		}
		//this.modelcameraservice.GebouwdeelGekozen(deel);
		//	//camera.Nummer, camera.Invalshoek, camera.Tekening);
		//	//modelca
		//	//var camera = this.HuidigeWoning.Typologie.WoningType.Cameras[deel.CameraIndex];
		//	//if (camera != null) {
		//	MasterControllerInstance.TriggerCameraWijziging(camera.Nummer, camera.Invalshoek, camera.Tekening);
		//	//}
		//}
	}

	ZetCamera(camera: ViewModelsWoningCamera.WoningCamera) {
		this.mCameraIndex = camera.Nummer;
		this.mInvalshoek = camera.Invalshoek;
		this.mTekeningNaam = camera.Tekening;
		this.mCameraIndexGezet = true;
	}

	MenuGroepGekozen(menu: ViewModelsMenuGroep.MenuGroep): void {
		const woningType = menu.WoningType;
		this.m_LaatsteWoningTypeID = woningType.RandomID;
		var camera = woningType.Cameras[menu.CameraIndex]
		if (camera != null) {
			this.ZetCamera(camera);
		}
	}

	GeefHuidigeCamera(): number {
		if (this.mCameraIndex == null) {
			return -1;
		}
		return this.mCameraIndex;
	}
}

