import { Component } from '@angular/core';

@Component({
	selector: 'wct-main',
	templateUrl: './WctMain.html',
	styleUrls: ['./WctMain.scss']
})

export class WctMainComponent  {
	constructor() {
	}

}