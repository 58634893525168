import * as _ from 'lodash';
//import * as ng from 'angular';
import * as ViewModelsKostenPrijsregel from '.././Models/KostenPrijsregel';
import * as DataContractsKostenViewModel_KostenPrijsRegelMJOPJaarViewModel from '@datacontracts/KostenViewModel.KostenPrijsRegelMJOPJaarViewModel';
import { IMJOPJaar } from '../interfaces/imjopjaar';



	export class KostenMJOP {
		Naam: string;
		Code: string;
		Bedrag: number;
		Tekst: string;
		Kosten: number;
		Jaren: Array<number> = [];
		MJOPjaren: Array<IMJOPJaar>;
		JaarKosten: Array<DataContractsKostenViewModel_KostenPrijsRegelMJOPJaarViewModel.IKostenPrijsRegelMJOPJaarViewModel>
		MJOPJaarKosten: Array<DataContractsKostenViewModel_KostenPrijsRegelMJOPJaarViewModel.IKostenPrijsRegelMJOPJaarViewModel>
		constructor(public Prijsregel: ViewModelsKostenPrijsregel.KostenPrijsregel, data: any
		) {
			this.MJOPjaren = [];
			this.MJOPJaarKosten = [];
			var startjaar = new Date().getFullYear();
			//var startjaar = 2016;
			if (data.length > 0) {
				Object.assign(this, data[0]);
				for (var i = startjaar; i < startjaar + 30; i++) {
					var mjopjaar: IMJOPJaar = { Jaar: i, Bedrag: 0, Type: Prijsregel.BTWpercentage };
					var jaarkosten: DataContractsKostenViewModel_KostenPrijsRegelMJOPJaarViewModel.IKostenPrijsRegelMJOPJaarViewModel = _.find(this.JaarKosten, function (item) { return (item.Jaar == i) });
					if (jaarkosten == null) {
						jaarkosten = { Jaar: i, Kosten: 0, Naam: this.Naam };
					}
					this.MJOPJaarKosten.push(jaarkosten);
					this.MJOPjaren.push(mjopjaar);
				}
				this.Bedrag = 0;
			}
			else {
				// dit is geen mjop
				var mjopjaar: IMJOPJaar = { Jaar: startjaar, Bedrag: 0, Type: Prijsregel.BTWpercentage };
				this.MJOPjaren.push(mjopjaar);
				this.Jaren.push(startjaar);
			}
		}

		MaakLeeg() {
			_.each(this.MJOPjaren, (j) => {
				j.Bedrag = 0;
			});
		}
	}
