
<!-- <script src="/Scripts/tiny_mce/tiny_mce.js" type="text/javascript"></script>
<script type="text/javascript">
	function StartTiny() {
		tinyMCE.init({
			// General options
			mode: "textareas",
			theme: "advanced",
			plugins: "autolink,lists,pagebreak,style,layer,table,save,advhr,advimage,advlink,emotions,iespell,inlinepopups,insertdatetime,preview,media,searchreplace,print,contextmenu,paste,directionality,fullscreen,noneditable,visualchars,nonbreaking,xhtmlxtras,template,wordcount,advlist,autosave,visualblocks",

			// Theme options
			theme_advanced_buttons1: "bold,italic,underline,strikethrough,|,justifyleft,justifycenter,justifyright,justifyfull,|,cut,copy,paste,pastetext,pasteword,|,search,replace,|,bullist,numlist,blockquote,|,undo,redo,|,link,unlink,image,html,|,forecolor,backcolor,|,sub,sup,",
			theme_advanced_buttons2: "",
			theme_advanced_buttons3: "",
			theme_advanced_buttons4: "",
			theme_advanced_toolbar_location: "top",
			theme_advanced_toolbar_align: "left",
			theme_advanced_statusbar_location: "bottom",
			theme_advanced_resizing: true,

			// Example content CSS (should be your site CSS)
			content_css: "css/content.css",

			// Drop lists for link/image/media/template dialogs
			template_external_list_url: "lists/template_list.js",
			external_link_list_url: "lists/link_list.js",
			external_image_list_url: "lists/image_list.js",
			media_external_list_url: "lists/media_list.js",

			// Style formats
			style_formats: [
				{ title: 'Bold text', inline: 'b' },
				{ title: 'Red text', inline: 'span', styles: { color: '#ff0000' } },
				{ title: 'Red header', block: 'h1', styles: { color: '#ff0000' } },
				{ title: 'Example 1', inline: 'span', classes: 'example1' },
				{ title: 'Example 2', inline: 'span', classes: 'example2' },
				{ title: 'Table styles' },
				{ title: 'Table row 1', selector: 'tr', classes: 'tablerow1' }
			],

			// Replace values for the template plugin
			template_replace_values: {
				username: "Some User",
				staffid: "991234"
			}
		});
	}

	function GetTinyContent(id) {
		return tinyMCE.editors[$(id).attr('id')].getContent();
	}
</script> -->

<div class="main">
  <div [hidden]="!BezigMetLaden" class="mat-progress-spinner__cont">
    <mat-progress-spinner mode="indeterminate" color="#0078be" strokeWidth="2" diameter="50"></mat-progress-spinner>
  </div>
	<div *ngIf="!BezigMetLaden">
		<mat-select [(ngModel)]="geselecteerdProject" (selectionChange)="LaadProject()">
        <mat-option *ngFor="let project of Projecten" [value]="project.Naam">
            {{project.Naam}}
          </mat-option>
    </mat-select>
    <div class="buttons-top">
      <wct-button buttonText="Opslaan" buttonSize="small" buttonAlign="center" class="btn-no-account" (click)="Opslaan()" class="button"></wct-button>
      <wct-button buttonText="Exporteer" buttonSize="small" buttonAlign="center" class="btn-no-account" (click)="Exporteer()" class="button"></wct-button>
      <!-- <button class="button button--import" (change)="UploadFiles($files, $invalidFiles)" multiple ngf-multiple="true" ngf-capture="camera" ngf-accept="" accept="">Importeer</button> -->
      <wct-button buttonText="Laad Settings" buttonSize="small" buttonAlign="center" class="btn-no-account" (click)="LaadDefault()" class="button"></wct-button>
      <wct-button buttonText="Importeer Leningen" buttonSize="small" buttonAlign="center" class="btn-no-account" (click)="ImporteerLeningen()" class="button"></wct-button>
      <wct-button buttonText="Terug naar Admin oud" buttonSize="small" buttonAlign="center" class="btn-no-account" (click)="TerugNaarAdminOud()" class="button"></wct-button>
    </div>
		<!-- <table class="main-table" *ngIf="ProjectSettingsTonen">
			<tr>
				<td><input [(ngModel)]="Categorie" placeholder="Categorie" style="width:100%;" /></td>
				<td><input [(ngModel)]="Naam" placeholder="Naam" style="width:100%;" /></td>
				<td>Waarde</td>
				<td><img [src]="'/Content/Images/fail.png'" /></td>
			</tr>
			<tr *ngFor="let projectsetting of sortedProjectSettings" [ngClass]="{nietaangepast: !projectsetting.Aangepast}">
				<td>{{ projectsetting.Categorie }}</td>
				<td>{{ projectsetting.Naam }}</td>
				<td>
					<div [ngSwitch]="projectsetting.Type">
						<div *ngSwitchCase="'ConfigSettingBool'">
							<mat-checkbox [name]="projectsetting" [checked]="checkChecked(projectsetting)" (change)="onChange($event)"></mat-checkbox>
						</div>
						<div *ngSwitchCase="'ConfigSettingHTML'">
							<textarea cols="20" class="tinymce" id="{{ GetProjectSettingsName(projectsetting) }}" name="{{ GetProjectSettingsName(projectsetting) }}" [(ngModel)]="projectsetting.Waarde" rows="2" style="width:100%; height: 200px;"></textarea>
						</div>
						<div *ngSwitchDefault>
							<input type="text" class="input-text" [(ngModel)]="projectsetting.Waarde" (change)="projectsetting.Aangepast = true;" />
						</div>
					</div>
				</td>
				<td *ngIf="projectsetting.Aangepast">
					<a href="javascript: void(0)" (click)="Verwijderen(projectsetting)"><img [src]="'/Content/Images/fail.png'" /></a>
				</td>
			</tr>
		</table> -->
    <div *ngIf="ProjectSettingsTonen">
      <div class="cont-line cont-line-header">
        <p>Categorie</p>
        <p>Naam</p>
        <p>Waarde</p>
        <p>Acties</p>
      </div>
      <div class="cont-line cont-line-filter">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Zoek op categorie</mat-label>
            <input matInput type="text" [(ngModel)]="Categorie" (change)="SortProjectSettings()" (ngModelChange)="SortProjectSettings()">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Zoek op naam</mat-label>
            <input matInput type="text" [(ngModel)]="Naam" (change)="SortProjectSettings()" (ngModelChange)="SortProjectSettings()">
          </mat-form-field>
        </div>
        <div></div>
        <div></div>
      </div>
      <div *ngFor="let projectsetting of sortedProjectSettings; let index = index" class="cont-line" [ngClass]="{nietaangepast: !projectsetting.Aangepast}">
        <p>{{ projectsetting.Categorie }}</p>
        <p>{{ projectsetting.Naam }}</p>
        <div [ngSwitch]="projectsetting.Type">
          <mat-checkbox *ngSwitchCase="'ConfigSettingBool'" [name]="projectsetting" [checked]="checkChecked(projectsetting)" (change)="onChange($event)"></mat-checkbox>
          <!-- <mat-form-field *ngSwitchCase="'ConfigSettingHTML'" appearance="outline">
            <textarea matInput matTextareaAutosize  matAutosizeMinRows=5 class="tinymce" id="{{ GetProjectSettingsName(projectsetting) }}" [(ngModel)]="projectsetting.Waarde" rows="2"></textarea>
          </mat-form-field> -->
          <div *ngSwitchCase="'ConfigSettingHTML'">
            <ckeditor [editor]="Editor" [data]="projectsetting.Waarde" (change)="onChangeTextEditor($event, projectsetting)"></ckeditor>
          </div>

          <mat-form-field appearance="outline" *ngSwitchDefault>
            <!-- <mat-label></mat-label> -->
            <input matInput type="text" [(ngModel)]="projectsetting.Waarde" (change)="projectsetting.Aangepast = true;">
          </mat-form-field>
        </div>
        <i *ngIf="projectsetting.Aangepast" class="fal fa-times" (click)="Verwijderen(projectsetting)"></i>
      </div>
    </div>
		<div *ngIf="KostenImporteren">
			<p>De volgende leningen zijn geimporteerd:</p>
			<table>
				<thead>
					<tr>
						<td>Naam</td>
						<td>Groep</td>
						<td>Organisatie</td>
						<td>Looptijd</td>
						<td>RentePercentage</td>
						<td>MinimumBedrag</td>
						<td>MaximumBedrag</td>
						<td>GebruikerType</td>
						<td>MinimumAantalWoningen</td>
						<td>MaxLeningenInGroep</td>
					</tr>
				</thead>
				<tr *ngFor="let lening of LeningenLijst.Leningen ">
					<td>{{lening.Naam}}</td>
					<td>{{lening.Groep}}</td>
					<td>{{lening.Organisatie}}</td>
					<td>{{lening.Looptijd}}</td>
					<td>{{lening.RentePercentage}}</td>
					<td>{{lening.MinimumBedrag}}</td>
					<td>{{lening.MaximumBedrag}}</td>
					<td>{{lening.GebruikerType}}</td>
					<td>{{lening.MinimumAantalWoningen}}</td>
					<td>{{lening.MaxLeningenInGroep}}</td>
				</tr>
			</table>
		</div>
	</div>
</div>
