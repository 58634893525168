/**
 * @ref WoonConnectViewModel/HourlyTarifViewModel.cs
 */

 import * as DataContracts from "@datacontracts/HourlyTarifViewModel";

 
import * as Cost from "./Cost";
import * as HourlyTarifKindViewModel from "./HourlyTarifKindViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.HourlyTarifViewModel
	 */
	 

	export interface IHourlyTarifViewModel {
		Cost: Cost.ICost;
		HourlyTarifSetId: number;
		HourlyTarifKind: HourlyTarifKindViewModel.IHourlyTarifKindViewModel;
		ToJsonContract(): DataContracts.IHourlyTarifViewModel;
	}

	export class HourlyTarifViewModel {
		// property-Properties
		public get Cost(): Cost.ICost {
			return this._Cost;
		}
		public set Cost(newCost: Cost.ICost) {
			this._Cost = newCost;
		}

		public get HourlyTarifSetId(): number {
			return this._HourlyTarifSetId;
		}
		public set HourlyTarifSetId(newHourlyTarifSetId: number) {
			this._HourlyTarifSetId = newHourlyTarifSetId;
		}

		public get HourlyTarifKind(): HourlyTarifKindViewModel.IHourlyTarifKindViewModel {
			return this._HourlyTarifKind;
		}
		public set HourlyTarifKind(newHourlyTarifKind: HourlyTarifKindViewModel.IHourlyTarifKindViewModel) {
			this._HourlyTarifKind = newHourlyTarifKind;
		}

		// field-Properties

		// fields
		protected _Cost: Cost.ICost = new Cost.Cost(undefined);
		protected _HourlyTarifSetId: number = 0;
		protected _HourlyTarifKind: HourlyTarifKindViewModel.IHourlyTarifKindViewModel = new HourlyTarifKindViewModel.HourlyTarifKindViewModel(undefined);

		constructor(data?: DataContracts.IHourlyTarifViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IHourlyTarifViewModel): void {
			if(data) {
				// vul properties
				this._Cost = data.Cost ? new Cost.Cost(data.Cost): undefined;
				this._HourlyTarifSetId = data.HourlyTarifSetId;
				this._HourlyTarifKind = data.HourlyTarifKind ? new HourlyTarifKindViewModel.HourlyTarifKindViewModel(data.HourlyTarifKind): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IHourlyTarifViewModel {
			return {
				Cost: (this.Cost ? new Cost.Cost(this.Cost).ToJsonContract(): this.Cost),
				HourlyTarifSetId: this.HourlyTarifSetId,
				HourlyTarifKind: (this.HourlyTarifKind ? new HourlyTarifKindViewModel.HourlyTarifKindViewModel(this.HourlyTarifKind).ToJsonContract(): this.HourlyTarifKind)
			}
		}
	}
