/**
 * @ref WoonConnectViewModel/Survey/DownloadNoteModel.cs
 */

 import * as DataContracts from "@datacontracts/DownloadNoteModel";

 
import * as WoningSelectie from "./WoningSelectie";
import * as FileDownload from "./FileDownload";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.DownloadNoteRequestModel
	 */
	 

	export interface IDownloadNoteRequestModel {
		Dwelling: WoningSelectie.IWoningSelectie;
		NoteId: string;
		ToJsonContract(): DataContracts.IDownloadNoteRequestModel;
	}

	export class DownloadNoteRequestModel {
		// property-Properties
		public get Dwelling(): WoningSelectie.IWoningSelectie {
			return this._Dwelling;
		}
		public set Dwelling(newDwelling: WoningSelectie.IWoningSelectie) {
			this._Dwelling = newDwelling;
		}

		public get NoteId(): string {
			return this._NoteId;
		}
		public set NoteId(newNoteId: string) {
			this._NoteId = newNoteId;
		}

		// field-Properties

		// fields
		protected _Dwelling: WoningSelectie.IWoningSelectie = new WoningSelectie.WoningSelectie(undefined);
		protected _NoteId: string = "";

		constructor(data?: DataContracts.IDownloadNoteRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDownloadNoteRequestModel): void {
			if(data) {
				// vul properties
				this._Dwelling = data.Dwelling ? new WoningSelectie.WoningSelectie(data.Dwelling): undefined;
				this._NoteId = data.NoteId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDownloadNoteRequestModel {
			return {
				Dwelling: (this.Dwelling ? new WoningSelectie.WoningSelectie(this.Dwelling).ToJsonContract(): this.Dwelling),
				NoteId: this.NoteId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Survey.DownloadNoteResponseModel
	 */
	 

	export interface IDownloadNoteResponseModel {
		Contents: FileDownload.IFileDownload;
	}

	export class DownloadNoteResponseModel {
		// property-Properties
		public get Contents(): FileDownload.IFileDownload {
			return this._Contents;
		}
		public set Contents(newContents: FileDownload.IFileDownload) {
			this._Contents = newContents;
		}

		// field-Properties

		// fields
		protected _Contents: FileDownload.IFileDownload = new FileDownload.FileDownload(undefined);

		constructor(data?: DataContracts.IDownloadNoteResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDownloadNoteResponseModel): void {
			if(data) {
				// vul properties
				this._Contents = data.Contents ? new FileDownload.FileDownload(data.Contents): undefined;
				// vul fields
			}
		}
	}
