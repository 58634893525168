/**
 * @ref WoonConnectViewModel/TenderCostViewModel.cs
 */

 import * as DataContracts from "@datacontracts/TenderCostViewModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.TenderCostRequestModel
	 */
	 

	export interface ITenderCostRequestModel {
		TenderId: number;
		ToJsonContract(): DataContracts.ITenderCostRequestModel;
	}

	export class TenderCostRequestModel {
		// property-Properties
		public get TenderId(): number {
			return this._TenderId;
		}
		public set TenderId(newTenderId: number) {
			this._TenderId = newTenderId;
		}

		// field-Properties

		// fields
		protected _TenderId: number = 0;

		constructor(data?: DataContracts.ITenderCostRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITenderCostRequestModel): void {
			if(data) {
				// vul properties
				this._TenderId = data.TenderId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITenderCostRequestModel {
			return {
				TenderId: this.TenderId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.TenderCostViewModel
	 */
	 

	export interface ITenderCostViewModel {
		Total: number;
		ToJsonContract(): DataContracts.ITenderCostViewModel;
	}

	export class TenderCostViewModel {
		// property-Properties
		public get Total(): number {
			return this._Total;
		}
		public set Total(newTotal: number) {
			this._Total = newTotal;
		}

		// field-Properties

		// fields
		protected _Total: number = 0;

		constructor(data?: DataContracts.ITenderCostViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITenderCostViewModel): void {
			if(data) {
				// vul properties
				this._Total = data.Total;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITenderCostViewModel {
			return {
				Total: this.Total
			}
		}
	}
