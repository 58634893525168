<div class="content">
  <div class="background">
    <a href="https://derbigum.nl/" target="_blank" class="logo">
      <img src="/Content/KlantSpecifiek/BCB21075/31-DERBIGUM_logo-Color-inv-fond-noir-220x66.jpg" height="60">
    </a>
    <div>
      <ul class="menu">
        <li><a routerLink="" [class.active]="router.url === '/'">Home</a></li>
      </ul>
    </div>
  </div>
</div>
