import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, AfterViewInit,ElementRef,  OnChanges,DoCheck,HostBinding, AfterViewChecked  } from '@angular/core';

import * as _ from 'lodash';
import * as ng from 'angular';
import * as ServicesSceneTypescript from '../services/SceneTypescript';
import * as ServicesViewer3DService from '../services/Viewer3DService';
import * as ViewModelsWebGLSessie from '../Models/WebGLSessie';
import * as $ from 'jquery';

import { __exportStar } from 'tslib';

export interface IWctWebGLCanvasScope extends ng.IScope {
	sessiegeregistreerd(params: any);
	sessie: ViewModelsWebGLSessie.WebGLSessie;
	selectie: boolean;
}

@Component({
	selector: 'wct-webgl-canvas',
  templateUrl: './WctWebGLCanvas.html',
  styleUrls: ['./WctWebGLCanvas.scss']
})
export class WctWebGLCanvasComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges, DoCheck, AfterViewChecked {

	@Input() sessie: ViewModelsWebGLSessie.WebGLSessie;
	@Input() selectie: boolean;
	@Input() suppress: boolean;
	@Input() drawscene: boolean;

	@Output () sessiegeregistreerd = new EventEmitter<ServicesSceneTypescript.SceneSession>();

	onResize() {
		this.resizeWorks();
	}

	@HostBinding('style.height.px') elHeight:number;

	private resizeWorks(): void {
		this.elHeight = this.el.nativeElement.width;
	}

	@HostBinding('style.width.px') elWidth:number;

	constructor(private viewer3dservice: ServicesViewer3DService.Viewer3DService, private el: ElementRef) {
	}

	ngOnInit(): void {

	}

	ngOnDestroy(): void {
			//element.append(canvas);
			// move canvas away from template so it's not destroyed
			if (this.created) {
				this.tempHolder.appendChild(this.canvas);
			}
			//scope.sessie.SceneSession.Dispose();
		//});
	}

	m_Initialised = false;


	updateElement(element: any, canvas: any, sessie: ViewModelsWebGLSessie.WebGLSessie) {

		if (!this.m_Initialised)
		{
			return;
		}

		//var isZichtbaar: boolean = (element.is(':visible'));
		var isZichtbaar = true;
		if (isZichtbaar) {

			var widthelement = Math.round(element.offsetWidth);
			var heightelement = Math.round(element.offsetHeight);
			var width = parseInt(widthelement.toString(), 10);
			var height = parseInt(heightelement.toString(), 10);

			var canvasheight = $(canvas).prop("height");
			var canvaswidth = $(canvas).prop("width");


			var update: boolean = false;

			var gewensteHoogte = height;
			var gewensteBreedte = width;

			if (canvaswidth != gewensteBreedte) {
				sessie.Resizes++;
				if (sessie.MaxResizes != -1) {
					if (sessie.Resizes > sessie.MaxResizes) {
						return;
					}
				}

				update = true;
				console.log("Sample width voor sessie " + sessie.SceneSession.scenetype + " met " + gewensteBreedte);
			}


			if (canvasheight != gewensteHoogte) {
				sessie.Resizes++;
				if (sessie.MaxResizes != -1) {
					if (sessie.Resizes > sessie.MaxResizes) {
						return;
					}
				}

				update = true;
				console.log("Sample width voor sessie " + sessie.SceneSession.scenetype + " met " + gewensteHoogte);
			}

			if (update) {
				$(canvas).prop("height", gewensteHoogte);
				$(canvas).prop("width", gewensteBreedte);
				console.log("Canvas=" + canvaswidth + "x" + canvasheight + ", DIV=" + gewensteBreedte + "x" + gewensteHoogte + " -- onResize");

				let drawscene = this.drawscene;
				if (drawscene == null)
				{
					drawscene = true;
				}

				sessie.SceneSession.OnResize(drawscene);
			}
		}
	}

	isSelectieActief(): boolean {
		var selectieActief: boolean = false;
		if (this.selectie != null) {
			selectieActief = this.selectie;
		}
		return selectieActief;
	}

	isSuppressActief(): boolean {
		var suppressActief: boolean = false;
		if (this.suppress != null) {
			suppressActief = this.suppress;
		}
		return suppressActief;
	}

	tempHolder: any;
	created: boolean;
	canvas: any;

	public ngAfterViewChecked()
	{
		//this.Init();
	}

	public ngAfterViewInit()
	{
		this.Init();
	}

	Init()
	{
		//link = (scope: IWctWebGLCanvasScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) => {
		// create a temp holder to save the canvas outside of canvas

		var resizes = 0;

		this.tempHolder = document.createElement('div');
    this.tempHolder.style.display = 'none';
		window.document.body.appendChild(this.tempHolder);

		var wrapper_div = document.createElement('div');
		$(wrapper_div).prop("tabindex", "0");
		$(wrapper_div).css("height", "100%");
		$(wrapper_div).css("outline", "none");

		this.created = false;
		this.canvas = this.sessie.Canvas;
		if (this.canvas == null) {
			this.canvas = document.createElement('canvas');
			this.created = true;
			this.canvas.id = 'canvas_' + _.sampleSize('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 10).join('');
			$(this.canvas).addClass('mijnViewer');
		}

		wrapper_div.appendChild(this.canvas);
		document.body.appendChild(wrapper_div);

		let element = this.el.nativeElement;

		element.append(wrapper_div);

		if (this.created) {
			var sessionobj: ServicesSceneTypescript.SceneSession = this.viewer3dservice.RegistreerViewer(this.canvas.id, this.sessie, this.isSelectieActief(), this.isSuppressActief());
			this.sessie.ZetSessie(this.canvas, sessionobj);
			this.sessiegeregistreerd.emit(sessionobj);
		}

		this.m_Initialised = true;
	}

	ngDoCheck(): void
	{
		this.updateElement(this.el.nativeElement, this.canvas, this.sessie);
	}

	ngOnChanges(changes) {
  }

}
