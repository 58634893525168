<div class="content">
  <div class="nav">
    <b>IsoBouw Systems BV</b><br /><br />

    Kanaalstraat 107,<br />
    5711 EG Someren<br /><br />

    Postbus 1,<br />
    5710 AA Someren<br />
    Nederland<br /><br />

    t: 0493-49 81 11<br />
    f: 0493-49 64 00<br />
    e: <a href="mailto: info@isobouw.nl">info@isobouw.nl</a><br />

  </div>
  <div class="links">
    <ul>
      <li><a href="http://www.isobouw.nl/nl/algemene-voorwaarden/" target="_blank">Algemene voorwaarden</a></li>
      <li><a href="http://www.isobouw.nl/nl/privacybeleid/" target="_blank">Privacybeleid</a></li>
      <li><a href="http://www.isobouw.nl/nl/disclaimer/" target="_blank">Disclaimer</a></li>
    </ul>
  </div>
  <div class="info">
    <div class="social">
      <p>Volg ons ook op:</p>
      <ul>
        <li class="clearfloat"><a class="twitter" href="https://twitter.com/IsoBouw">twitter</a></li>
        <li><a class="linkedin" href="https://www.linkedin.com/company/isobouw-systems-b-v-">Linkedin</a></li>
        <li><a class="facebook" href="https://www.facebook.com/pages/IsoBouw-systems-BV/338661149506697">Facebook</a></li>
        <li><a class="youtube" href="http://www.youtube.com/user/isobouw">youtube</a></li>
      </ul>
    </div>
    <p class="copyright">
      Alle rechten voorbehouden<br>
      &copy; Copyrights 2021
    </p>
  </div>
</div>

<!--<div class="container centerOnPage">
  <section class="row vierluik">
    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 col equal margin-sm" style="line-height: 22px;">
      <div class="inner">
        <h4>IsoBouw Systems BV</h4>
        <p>Kanaalstraat 107,<br />5711 EG Someren<br /><br />Postbus 1,<br />5710 AA Someren<br />Nederland<br /><br />t: 0493-49 81 11<br />f: 0493-49 64 00<br />e: <a href="mailto:info@isobouw.nl">info@isobouw.nl</a></p>
      </div>
    </div>
  </section>
  <section id="footerNav">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12 visible-md visible-lg  margin-sm small-gutter">
        <ul>
          <li><a href="http://www.isobouw.nl/nl/algemene-voorwaarden/" target="_blank">Algemene voorwaarden</a></li>
          <li><a href="http://www.isobouw.nl/nl/privacybeleid/" target="_blank">Privacybeleid</a></li>
          <li><a href="http://www.isobouw.nl/nl/disclaimer/" target="_blank">Disclaimer</a></li>
        </ul>
      </div>
      <div id="socialmedia" class="col-lg-4 col-md-4 col-sm-6 col-xs-12 reset-md text-right-md">
        <p>Volg ons ook op:</p>
        <ul>
          <li class="clearfloat"><a class="twitter" href="https://twitter.com/IsoBouw">twitter</a></li>
          <li><a class="linkedin" href="https://www.linkedin.com/company/isobouw-systems-b-v-">Linkedin</a></li>
          <li><a class="facebook" href="https://www.facebook.com/pages/IsoBouw-systems-BV/338661149506697">Facebook</a></li>
          <li><a class="youtube" href="http://www.youtube.com/user/isobouw">youtube</a></li>
        </ul>
      </div>
    </div>
  </section>
</div>-->
