/**
 * @ref WoonConnectViewModel/ImageAnnotation/SkipPhotoModel.cs
 */

 import * as DataContracts from "@datacontracts/SkipPhotoModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.SkipPhotoRequestModel
	 */
	 

	export interface ISkipPhotoRequestModel {
		PhotoID: number;
		ProjectID: number;
		PhotoSetID: number;
		ToJsonContract(): DataContracts.ISkipPhotoRequestModel;
	}

	export class SkipPhotoRequestModel {
		// property-Properties
		public get PhotoID(): number {
			return this._PhotoID;
		}
		public set PhotoID(newPhotoID: number) {
			this._PhotoID = newPhotoID;
		}

		public get ProjectID(): number {
			return this._ProjectID;
		}
		public set ProjectID(newProjectID: number) {
			this._ProjectID = newProjectID;
		}

		public get PhotoSetID(): number {
			return this._PhotoSetID;
		}
		public set PhotoSetID(newPhotoSetID: number) {
			this._PhotoSetID = newPhotoSetID;
		}

		// field-Properties

		// fields
		protected _PhotoID: number = 0;
		protected _ProjectID: number = 0;
		protected _PhotoSetID: number = 0;

		constructor(data?: DataContracts.ISkipPhotoRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISkipPhotoRequestModel): void {
			if(data) {
				// vul properties
				this._PhotoID = data.PhotoID;
				this._ProjectID = data.ProjectID;
				this._PhotoSetID = data.PhotoSetID;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISkipPhotoRequestModel {
			return {
				PhotoID: this.PhotoID,
				ProjectID: this.ProjectID,
				PhotoSetID: this.PhotoSetID
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.SkipPhotoResponseModel
	 */
	 

	export interface ISkipPhotoResponseModel {
		Succeeded: boolean;
	}

	export class SkipPhotoResponseModel {
		// property-Properties
		// field-Properties
		public get Succeeded(): boolean {
			return this._Succeeded;
		}
		public set Succeeded(newSucceeded: boolean) {
			this._Succeeded = newSucceeded;
		}

		// fields
		protected _Succeeded: boolean = false;

		constructor(data?: DataContracts.ISkipPhotoResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISkipPhotoResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Succeeded = data.Succeeded;
			}
		}
	}
