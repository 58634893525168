import * as ng from 'angular';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Dictionary } from "lodash";
import { PrijslijstConsumer } from '@consumers/PrijslijstController';
import { PrijsSchemasViewModel, PrijsSchemaViewModel, CategoriesViewModel, DownloadExcelViewModel, ExcelsViewModel, CategorieViewModel, RequestCategorieViewModel, FabUserViewModel } from '@models/PrijslijstViewModel';
import { ProductsViewModel, ProductVariantModel, ProductViewModel, IProductViewModel } from '@models/ProductsViewModel';
import { FilterOptionViewModel, IFilterOptionViewModel, FilterViewModel, IFilterViewModel } from '@models/FilterViewModel';
import { IProductVariantModel } from '../data/WoonConnectKern/DataContracts/ProductsViewModel';

export interface IPrijsModuleService {

}

class PrijsmoduleProduct {

}

class Categorie {
  public Name: string;
  public Length: number;
  public Start: number;
  public End: number;
}

@Injectable({
  providedIn: 'root',
})
export class PrijsModuleService implements IPrijsModuleService {

  public Loading: boolean = true;
  public LoadingProducts: boolean = true;
  public LoadingPrijsSchemas: boolean = true;

  public CategoriesViewModel: CategoriesViewModel = null;
  public CategorieId: number = -1;

  public PrijsSchemasViewModel: PrijsSchemasViewModel = null;
  public PrijsSchemaSelected: PrijsSchemaViewModel = null;
  public PrijsSchemaSelectedId: string;

  public SearchString: string = "";
  public PageIndex: number = 1;
  public ProductsPerPage: number = 100;

  public ToonSchaal: boolean = false;

  public AllProducts: Array<IProductVariantModel> = new Array<IProductVariantModel>();
  public Products: Array<PrijsmoduleProduct> = new Array<PrijsmoduleProduct>();

  public CategorieViewModels: Dictionary<CategoriesViewModel> = {};

  constructor(private prijslijst: PrijslijstConsumer) {
    'ngInject';
    this.LoadPrijsSchemas();
  }

  public LoadCategorieen(): void {
    this.Loading = true;

    if (this.PrijsSchemaSelected != null) {
      if (this.CategorieViewModels[this.PrijsSchemaSelected.Id] == null) {
        this.prijslijst.GetCategories_Observable(this.PrijsSchemaSelected.Id).subscribe((data) => {
          this.CategorieViewModels[this.PrijsSchemaSelected.Id] = new CategoriesViewModel(data);

          this.CategoriesViewModel = this.CategorieViewModels[this.PrijsSchemaSelected.Id];

          if (this.CategorieId == -1) {
            this.CategorieId = this.CategoriesViewModel.CategorieViewModels[0].Id;
          }

          this.Loading = false;
          this.LoadingProducts = true;

          this.LoadProducts();
        });
      }
      else {
        this.CategoriesViewModel = this.CategorieViewModels[this.PrijsSchemaSelected.Id];

        if (this.CategorieId == -1) {
          this.CategorieId = this.CategoriesViewModel.CategorieViewModels[0].Id;
        }

        this.Loading = false;
        this.LoadingProducts = true;

        this.LoadProducts();
      }
    }
  }

  public GetActiveFilters(): Array<IFilterViewModel> {
    var Filters = new Array<IFilterViewModel>();

    if (this.GetActiveCategorie() != null) {
      _.forEach(this.GetActiveCategorie().FilterRelations, filter => {
        if ((this.ToonSchaal || !this.IsSchaal(filter.Id)) && (filter.Name.toLowerCase() != "isolator type"))
          Filters.push(filter);
      });
    }

    return Filters;
  }

  public IsSchaal(index: number): boolean {
    if (this.GetActiveCategorie() != null) {
      if (this.GetActiveCategorie().Id == 1) {
        var subcats = this.GetSubCategories1(true);
        for (var i = 0; i < subcats.length; i++) {
          if (subcats[i].Start < (index - 1) && subcats[i].End >= (index - 1)) {
            if (subcats[i].Name.toLowerCase().indexOf("schaal") != -1) {
              return true;
            }
            else {
              return false;
            }
          }
        }
      }
    }
    return false;
  }

  public HasSubCategorie1(): boolean {

    if (this.GetActiveCategorie() != null) {
      if (this.GetActiveCategorie().Id == 1) {
        return true;
      }

      if (this.GetActiveCategorie().Id == 2) {
        return true;
      }
    }

    return false;
  }

  public GetSubCategories1(includeschaal: boolean): Array<Categorie> {
    var cats = new Array<Categorie>();
    if (this.GetActiveCategorie() != null) {
      if (this.GetActiveCategorie().Id == 1) {
        var position = 0;
        this.AddCat(cats, "Artikelinformatie", 13, position);
        position += 13;
        if (includeschaal) {
          this.AddCat(cats, "Schaal 1 (A)", 3, position);
          position += 3;
          this.AddCat(cats, "Schaal 2 (B)", 3, position);
          position += 3;
          this.AddCat(cats, "Schaal 3 (C)", 3, position);
          position += 3;
          this.AddCat(cats, "Schaal 4 (D)", 3, position);
          position += 3;
        }
        this.AddCat(cats, "Prijs &euro; /m<sup>2</sup>", 7, position);
        position += 7;
        this.AddCat(cats, "", 1, position);
      }

      if (this.GetActiveCategorie().Id == 2) {
        var position = 0;
        this.AddCat(cats, "", 2, position);
        position += 2;
        this.AddCat(cats, "", 1, position);
        position += 1;
        this.AddCat(cats, "Anodiseren", 7, position);
        position += 7;
        this.AddCat(cats, "Basis Conversie 1 laags 60 µm", 7, position);
        position += 7;
        this.AddCat(cats, "PREANO+ 1-laags 60 µm", 7, position);
        position += 7;
        this.AddCat(cats, "Basis Conversie 1 laags 60 µm", 7, position);
        position += 7;
        this.AddCat(cats, "PREANO+ 1-laags 60 µm", 7, position);
        position += 7;
        this.AddCat(cats, "Basis Conversie 1 laags 60 µm Structuur", 6, position);
        position += 6;
        this.AddCat(cats, "PREANO+ 1-laags  60 µm Structuur", 6, position);
        position += 6;
        this.AddCat(cats, "", 1, position);

      }
    }

    return cats;
  }

  private AddCat(list: Array<Categorie>, name: string, length: number, start: number) {
    var cat2 = new Categorie();
    cat2.Name = name;
    cat2.Length = length;
    cat2.Start = start;
    cat2.End = start + length;
    list.push(cat2);
  }

  public HasSubCategorie2(): boolean {
    if (this.GetActiveCategorie() != null) {
      if (this.GetActiveCategorie().Id == 2) {
        return true;
      }
    }

    return false;
  }

  public GetSubCategories2(): Array<Categorie> {
    var cats = new Array<Categorie>();
    if (this.GetActiveCategorie() != null) {
      if (this.GetActiveCategorie().Id == 2) {
        var position = 0;
        this.AddCat(cats, "", 2, position);
        position += 2;
        this.AddCat(cats, "", 1, position);
        position += 1;
        this.AddCat(cats, "", 7, position);
        position += 7;
        this.AddCat(cats, "70% glansgraad", 7, position);
        position += 7;
        this.AddCat(cats, "70% glansgraad", 7, position);
        position += 7;
        this.AddCat(cats, "30% glansgraad", 7, position);
        position += 7;
        this.AddCat(cats, "30% glansgraad", 7, position);
        position += 7;
        this.AddCat(cats, "", 6, position);
        position += 6;
        this.AddCat(cats, "", 6, position);
        position += 6;
        this.AddCat(cats, "", 1, position);
      }
    }

    return cats;
  }

  public GetActiveCategorie(): IProductViewModel {

    if (this.CategoriesViewModel != null) {
      for (var i = 0; i < this.CategoriesViewModel.CategorieViewModels.length; i++) {
        if (this.CategoriesViewModel.CategorieViewModels[i].Id == this.CategorieId) {
          return this.CategoriesViewModel.CategorieViewModels[i].Products;
        }
      }
    }

    return null;
  }

  public LoadProductsAsync(): void {
    setTimeout(() => {
      this.LoadProducts();
    }, 1);
  }

  public LoadProducts(): void {
    if (this.CategoriesViewModel != null) {
      this.LoadingProducts = true;

      var cat: CategorieViewModel = null;

      for (var i = 0; i < this.CategoriesViewModel.CategorieViewModels.length; i++) {
        if (this.CategoriesViewModel.CategorieViewModels[i].Id == this.CategorieId) {
          cat = this.CategoriesViewModel.CategorieViewModels[i];
        }
      }

      if (this.SearchString != "") {
        this.AllProducts = new Array<IProductVariantModel>();

        _.forEach(cat.Products.ProductVariant, (productvariant) => {
          if (this.ProductVariantTonen(productvariant)) {
            this.AllProducts.push(productvariant);
          }
        });
      }
      else {
        this.AllProducts = cat.Products.ProductVariant;
      }

      this.Products = new Array<PrijsmoduleProduct>();

      var end = this.PageIndex * this.ProductsPerPage;
      var filters = this.GetActiveFilters();

      for (var i = end - this.ProductsPerPage; i < end && i < this.AllProducts.length; i++) {
       
          var product = new PrijsmoduleProduct();

        product["image"] = this.AllProducts[i].Description;

          for (var j = 0; j < filters.length; j++) {
            product[filters[j].Id.toString()] = this.GetValue(cat.Products, filters[j], this.AllProducts[i]);
          }

          this.Products.push(product);
      }

      this.LoadingProducts = false;
    }
  }

  public GetValue(productviewmodel: IProductViewModel, filter: IFilterViewModel, productvariant: IProductVariantModel): string {

    var value = "";
    var filternametrim = filter.Name.trim().toLowerCase();

    _.forEach(productvariant.FilterOptionsRelations, filteroption => {
      if (filteroption.FilterId == filter.Id) {
        var filteroptionnametrim = filteroption.Name.trim().toLowerCase();

        if (filternametrim.indexOf('€') != -1 && filternametrim.indexOf("tapen") == -1) {
          if (filteroptionnametrim != "") {
            var temp = parseFloat(filteroption.Name.replace(",", "."));
            value = "€ " + temp.toFixed(2).replace(".", ",");
          }
        }
        else if (productviewmodel.Id == 1 && filter.Id > 25 && filter.Id < 34) 
        {
          if (filteroptionnametrim != "") {
            var temp = parseFloat(filteroption.Name.replace(",", "."));
            if (temp == 0) {
              value = "€ -";
            }
            else {
              value = "€ " + temp.toFixed(2).replace(".", ",");
            }
          }
        }
        else if (productviewmodel.Id == 1 && filter.Id > 33) {
          value = filteroption.Name;
        }
        else if (filternametrim.indexOf('verkoopprijs') != -1 || filternametrim.indexOf('prijs per m1\n(bruto)') != -1 || filternametrim.indexOf('vulkanisatietoeslag \n(netto / st.)') != -1) {
          if (filteroptionnametrim != "") {
            if (filteroptionnametrim == "op aanvraag") {
              value = "op aanvraag";
            }
            else {
              var temp = parseFloat(filteroption.Name.replace(",", "."));
              if (temp == 0) {
                value = "€ -";
              }
              else {
                value = "€ " + temp.toFixed(2).replace(".", ",");
              }
            }
          }
        }
        else if (filter.Name.indexOf('m1') != -1 || filter.Name.indexOf('kg') != -1) {

          if (filteroption.Name.trim() != "") {
            if (filter.Name.toLowerCase().trim() == "min. best fix lengte m1") {
              value = filteroption.Name;
            }
            else {
              value = parseFloat(filteroption.Name.replace(",", ".")).toFixed(3).replace(".", ",");
            }
          }
        }
        else {
          value = filteroption.Name;
        }
      }
    });

    return value;
  }

  public LoadPrijsSchemas(): void {
    this.LoadingPrijsSchemas = true;

    this.prijslijst.GetPrijsSchemas_Observable().subscribe((data) => {
      this.PrijsSchemasViewModel = new PrijsSchemasViewModel(data);

      if (this.PrijsSchemasViewModel.PrijsSchemas.length > 0) {
        this.PrijsSchemaSelected = this.PrijsSchemasViewModel.PrijsSchemas[0];
        this.PrijsSchemaSelectedId = this.PrijsSchemaSelected.Id.toString();
      }

      this.LoadingPrijsSchemas = false;

      this.LoadCategorieen();
    });
  }

  private ProductVariantTonen(productvariant: ProductVariantModel) {

    var Tonen = true;

    if (this.SearchString != "") {
      Tonen = false;

      _.forEach(productvariant.FilterOptionsRelations, filteroption => {
        if (filteroption.Name.toLowerCase().indexOf(this.SearchString.toLowerCase()) != -1) {
          Tonen = true;
        }
      });
    }

    return Tonen;
  }
}

