<div class="dialog__header">
  <p class="dialog__title">Er is iets fout gegaan</p>
  <i (click)="closeDialog()" class="fal fa-times"></i>
</div>
<div class="dialog__main">
  <p *ngIf="oorzaak === 'FoutGeenCode'">De code is nog niet (volledig) ingevuld.</p>
  <p *ngIf="oorzaak === 'FoutWoningCode'">Er is geen woning gevonden bij deze code.</p>
  <p *ngIf="oorzaak === 'FoutVerkeerdeCode'">De sleutelcode is niet gevonden of al in gebruik.<br />
    Heeft u de code al eerder ingevoerd, klik dan op de activatielink in uw e-mail.
    U kunt voortaan hier inloggen met uw e-mailadres en zelfgemaakte wachtwoord: <a (click)="reloadWebsite()">{{WebsiteBasisUrlZonderProtocol}}</a>.
  </p>
</div>
<div class="dialog__btns">
  <button (click)="closeDialog()" class="dialog__btn__primary">Ok</button>
</div>
