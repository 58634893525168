import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { WctLogService } from '../services/WctLogService';

export class ErrorContext
{
    Context: string;
    Error: string;
    HttpResponse : HttpErrorResponse
}

@Injectable({
    providedIn: 'root',
})
export class ErrorService
{
    m_Error: Subject<ErrorContext> = new Subject<ErrorContext>();

    GetErrors(): Observable<ErrorContext>
    {
        return this.m_Error.asObservable();
    }

    BackendError(context: string, httpErrorResponse: any, error: string = "")
    {
        let ctx = new ErrorContext();    
        ctx.Context = context;
        ctx.Error = error;

        if (httpErrorResponse instanceof HttpErrorResponse)
        {
            ctx.HttpResponse = httpErrorResponse;
        }
        else
        {
            ctx.Error = error + httpErrorResponse;
        }

        this.log.error("ERRORSERVICE: " + ctx.Error);
        
        this.m_Error.next(ctx);
    }
    
    constructor(private log: WctLogService)
    {
    }

}