<div class="container">
    <div>
        <div class="two-columns">
            <div class="first-column">
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Organisatie</mat-label>
                        <input matInput name="productNaam" [(ngModel)]="userDetail.Organisation.Name" ngModel required
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Project (nog niet werkend)</mat-label>
                        <input matInput name="project" [(ngModel)]="userDetail.Organisation.Id" ngModel required
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>GUID</mat-label>
                        <input matInput name="user-guid" [(ngModel)]="userDetail.PersoonExtra.UserId" ngModel disabled>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Inlognaam</mat-label>
                        <input matInput name="Inlognaam" [(ngModel)]="userDetail.Persoon.UserName" ngModel required
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Woningen</mat-label>
                        <mat-select [(ngModel)]="BuildingUserLinkRequest.BuilidingId"
                            [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let building of BuildingsViewModel.Buildings" [value]="building.Id">
                                {{building.Adres.Straat}} {{building.Adres.Nummer}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </p>
                
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Rol gebruiker</mat-label>
                        <mat-select [(ngModel)]="AddRole.Rol" [ngModelOptions]="{standalone: true}" >
                            <mat-option *ngFor="let role of Roles?.Roles" [value]="role.Rol">
                                {{role.Rol}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                    <button (click)="saveRoles()">Update</button>
                </p>
                <div>
                    Huidige rollen:
                    <div *ngFor="let role of UserRoles?.Roles">
                        {{role.Rol}}<a (click)="VerwijderRol(role)">Verwijderen</a>
                    </div>
                </div>
            </div>
            <div class="second-column">
                <p>
                    <mat-slide-toggle [(ngModel)]="verification.Verificatie" (change)="onVerificationChange()">
                        Verificatie</mat-slide-toggle>

                </p>
                <p>
                    <mat-slide-toggle [(ngModel)]="Security.IsLockedOut" (change)="onLockedoutChange()">Login geblokkeerd</mat-slide-toggle>

                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Verificatiecode</mat-label>
                        <input matInput name="verificatiecode" [(ngModel)]="verification.VerificatieCode" ngModel
                            required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>

                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Wachtwoord resetten</mat-label>
                        <input matInput name="password" [(ngModel)]="newPassword" ngModel min-length="4">
                    </mat-form-field>

                    <button (click)="setPassword()">Update</button>

                    <!-- <wct-button mat-icon-button color="success" aria-label="Example icon button with a heart icon"
                        (click)="setPassword()">
                        <mat-icon>
                            <i class="fas fa-check"></i>
                        </mat-icon>
                    </wct-button> -->

                </p>
            </div>
        </div>
    </div>


    <div>
        <div class="two-columns">
            <div class="first-column">
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Id</mat-label>
                        <input matInput name="PersoonId" [(ngModel)]="userDetail.Persoon.PersoonId" ngModel required disabled
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Voornaam</mat-label>
                        <input matInput name="voornaam" [(ngModel)]="userDetail.Persoon.FirstName" ngModel required
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Voorletters</mat-label>
                        <input matInput name="project" [(ngModel)]="userDetail.PersoonExtra.Voorletters" ngModel
                            required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Tussenvoegsel</mat-label>
                        <input matInput name="tussenvoegsel" [(ngModel)]="userDetail.PersoonExtra.Tussenvoegsel" ngModel
                            required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Achternaam</mat-label>
                        <input matInput name="Achternaam" [(ngModel)]="userDetail.Persoon.LastName" ngModel required
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Hoofdwoning Straat</mat-label>
                        <input matInput name="Straat" [(ngModel)]="userDetail.Persoon.MainResidentDwelling.Straat" ngModel  disabled
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Hoofdwoning Nummer</mat-label>
                        <input matInput name="Nummer" [(ngModel)]="userDetail.Persoon.MainResidentDwelling.Nummer" ngModel  disabled
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Hoofdwoning Land</mat-label>
                        <input matInput name="Land" [(ngModel)]="userDetail.Persoon.MainResidentDwelling.Land" ngModel disabled min-length="4"
                            #name="ngModel">
                    </mat-form-field>
                </p>
            </div>

            <div class="second-column">
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Geslacht</mat-label>
                        <input matInput name="Geslacht" [(ngModel)]="userDetail.PersoonExtra.Geslacht" ngModel required
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Email</mat-label>
                        <input matInput name="Email" [(ngModel)]="userDetail.Persoon.Email" ngModel required
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Telefoon</mat-label>
                        <input matInput name="Telefoon" [(ngModel)]="userDetail.PersoonExtra.Telefoon" ngModel required disabled
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>(gereserveerd)</mat-label>
                        <input matInput disabled>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>(gereserveerd)</mat-label>
                        <input matInput name="Telefoon" disabled>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Hoofdwoning Postcode</mat-label>
                        <input matInput name="Postcode" [(ngModel)]="userDetail.Persoon.MainResidentDwelling.Postcode" ngModel required disabled
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Hoofdwoning Plaats</mat-label>
                        <input matInput name="Plaats" [(ngModel)]="userDetail.Persoon.MainResidentDwelling.Plaats" ngModel required disabled
                            min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>

            </div>
        </div>

        <button (click)="updateFields()">Update velden</button>

        <button (click)="remove()">Verwijder gebruiker</button>

        <br>

        <a *ngIf=" this.userDetail.Persoon.MainResidentDwelling.Id > 0" [routerLink]="['/admin/building-detail/', this.userDetail.Persoon.MainResidentDwelling.Id]"> Open hoofdwoning </a>
    </div>
</div>