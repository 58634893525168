// [conv] import "(./FabProduct).less";
import { Component, Input, Output } from '@angular/core';
import * as _ from 'lodash';

import { ProductsConsumer } from '@consumers/ProductsController';
import { ProductsViewModel } from '@models/ProductsViewModel';
import { ProductViewModel } from '@models/ProductsViewModel';

@Component({
  selector: 'fab-admin-products',
  templateUrl: './FabAdminProducts.html',
  styleUrls: ['./FabAdminProducts.scss']
})
export class FabAdminProductsComponent {
  @Output() ProductId = 0;
  addProduct: boolean = false;

  //Producten laden.
  ProductsViewModel: ProductsViewModel;
  ProductsToShow: ProductViewModel[];
  ImportIndex: number;
  ImportVariantIndex: number;
  Importing: boolean = false;
  Temp: number = 0;
  Loading: boolean = true;
  ShowInactive: boolean = false;


  constructor(private prod: ProductsConsumer) {
    this.Refresh();
  }

  public ResetImportFlags() {
    this.Loading = true;

    this.prod.ResetImportFlags_Observable(-1, -1).subscribe((data) => {
      this.Refresh();
    });
  }

  public Import() {
    this.ImportIndex = 0;
    this.ImportVariantIndex = 0;
    this.Importing = true;

    this.DoImportIndex();
  }

  public DoImportIndex() {
    if (this.ImportIndex < this.ProductsToShow.length) {
      if (this.ImportVariantIndex < this.ProductsToShow[this.ImportIndex].ProductVariant.length) {
        this.ProductsToShow[this.ImportIndex].Import = this.ImportVariantIndex + "/" + this.ProductsToShow[this.ImportIndex].ProductVariant.length;

        this.prod.ImportVariant_Observable(this.ProductsToShow[this.ImportIndex].ProductVariant[this.ImportVariantIndex]).subscribe((data) => {
          this.ImportVariantIndex++;
          this.Temp++;
          this.DoImportIndex();
        }, error => {
          alert("mislukt variantid: " + this.ProductsToShow[this.ImportIndex].ProductVariant[this.ImportVariantIndex].Id);
        });
      }
      else {
        this.ProductsToShow[this.ImportIndex].Import = 'Klaar';
        this.ImportIndex++;
        this.ImportVariantIndex = 0;
        this.DoImportIndex();
      }
    }

    this.setProductsToShow();
  }

  public Herlaad() {
    this.Refresh();
  }

  public Refresh(): void {
    this.Loading = true;

    if (this.ProductsViewModel == undefined) {
      this.ProductsViewModel = new ProductsViewModel();
    }

    this.prod.GetProducts_Observable(this.ProductsViewModel).subscribe((data) => {
      console.log(data);
      this.ProductsViewModel = new ProductsViewModel(data);
      this.setProductsToShow();
      this.Loading = false;
    });
  }

  setProductId(id) {
    this.ProductId = id;
  }

  setProductsToShow() {
    //
    // Gets the products to show from the ViewModel and puts them in another, temporary array.
    // This is needed because all the items in array used by the drag-drop need to be visible,
    // otherwise the drag-drop will have unexpected behaviour.
    // This method first filtes the products based on wether ShowInactivce is true or not and
    // then sorts them on position.
    //

    console.log("Setting products, ShowInactive = " + this.ShowInactive);
    this.ProductsToShow = this.ProductsViewModel.Products
      .filter((product, index, array) => product.Active || this.ShowInactive)
      .sort((a, b) => a.Position - b.Position);
  }

  onArrayChange() {
    for (var i = 0; i < this.ProductsToShow.length; i++) {
      this.ProductsToShow[i].Position = i;
    }

    console.log("Syncing Positions...");
    this.Loading = true;
    this.prod.SaveProducts_Observable(this.ProductsViewModel).subscribe((data) => {
      this.ProductsViewModel = new ProductsViewModel(data);
      console.log("Positions synced!");
      this.Loading = false;
    }, error => {
      console.error("Something went wrong while saving products... " + error);
    });
  }

  onSelectedAtIndex(index: number) {
    window.location.href = "/fab-admin/product/" + this.ProductsToShow[index].Id;
  }

  showInactiveChange(event: any) {
    this.ShowInactive = event.currentTarget.checked;
    this.setProductsToShow();
  }
}
