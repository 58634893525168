/**
 * @ref WoonConnectViewModel/Project/DeleteProjectSettingInstantieModel.cs
 */

 import * as DataContracts from "@datacontracts/DeleteProjectSettingInstantieModel";

 
import * as ProjectSettingsModel from "./ProjectSettingsModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Project.DeleteProjectSettingInstantieRequestModel
	 */
	 

	export interface IDeleteProjectSettingInstantieRequestModel {
		ProjectId: number;
		ProjectSettings: ProjectSettingsModel.IProjectSettingsAngular;
		ToJsonContract(): DataContracts.IDeleteProjectSettingInstantieRequestModel;
	}

	export class DeleteProjectSettingInstantieRequestModel {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		public get ProjectSettings(): ProjectSettingsModel.IProjectSettingsAngular {
			return this._ProjectSettings;
		}
		public set ProjectSettings(newProjectSettings: ProjectSettingsModel.IProjectSettingsAngular) {
			this._ProjectSettings = newProjectSettings;
		}

		// field-Properties

		// fields
		protected _ProjectId: number = 0;
		protected _ProjectSettings: ProjectSettingsModel.IProjectSettingsAngular = new ProjectSettingsModel.ProjectSettingsAngular(undefined);

		constructor(data?: DataContracts.IDeleteProjectSettingInstantieRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDeleteProjectSettingInstantieRequestModel): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				this._ProjectSettings = data.ProjectSettings ? new ProjectSettingsModel.ProjectSettingsAngular(data.ProjectSettings): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDeleteProjectSettingInstantieRequestModel {
			return {
				ProjectId: this.ProjectId,
				ProjectSettings: (this.ProjectSettings ? new ProjectSettingsModel.ProjectSettingsAngular(this.ProjectSettings).ToJsonContract(): this.ProjectSettings)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Project.DeleteProjectSettingInstantieResponseModel
	 */
	 

	export interface IDeleteProjectSettingInstantieResponseModel {
		Success: boolean;
	}

	export class DeleteProjectSettingInstantieResponseModel {
		// property-Properties
		public get Success(): boolean {
			return this._Success;
		}
		public set Success(newSuccess: boolean) {
			this._Success = newSuccess;
		}

		// field-Properties

		// fields
		protected _Success: boolean = false;

		constructor(data?: DataContracts.IDeleteProjectSettingInstantieResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDeleteProjectSettingInstantieResponseModel): void {
			if(data) {
				// vul properties
				this._Success = data.Success;
				// vul fields
			}
		}
	}
