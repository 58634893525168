/**
 * @ref WoonConnectViewModel/Survey/TestSurveyModel.cs
 */

 import * as DataContracts from "@datacontracts/TestSurveyModel";

 
import * as WoningSelectie from "./WoningSelectie";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.TestSurveyRequestModel
	 */
	 

	export interface ITestSurveyRequestModel {
		Dwelling: WoningSelectie.IWoningSelectie;
		ToJsonContract(): DataContracts.ITestSurveyRequestModel;
	}

	export class TestSurveyRequestModel {
		// property-Properties
		public get Dwelling(): WoningSelectie.IWoningSelectie {
			return this._Dwelling;
		}
		public set Dwelling(newDwelling: WoningSelectie.IWoningSelectie) {
			this._Dwelling = newDwelling;
		}

		// field-Properties

		// fields
		protected _Dwelling: WoningSelectie.IWoningSelectie = new WoningSelectie.WoningSelectie(undefined);

		constructor(data?: DataContracts.ITestSurveyRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITestSurveyRequestModel): void {
			if(data) {
				// vul properties
				this._Dwelling = data.Dwelling ? new WoningSelectie.WoningSelectie(data.Dwelling): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITestSurveyRequestModel {
			return {
				Dwelling: (this.Dwelling ? new WoningSelectie.WoningSelectie(this.Dwelling).ToJsonContract(): this.Dwelling)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Survey.TestSurveyResponseModel
	 */
	 

	export interface ITestSurveyResponseModel {
		Problems: string[];
	}

	export class TestSurveyResponseModel {
		// property-Properties
		public get Problems(): string[] {
			return this._Problems;
		}
		public set Problems(newProblems: string[]) {
			this._Problems = newProblems;
		}

		// field-Properties

		// fields
		protected _Problems: string[] = [];

		constructor(data?: DataContracts.ITestSurveyResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITestSurveyResponseModel): void {
			if(data) {
				// vul properties
				this._Problems = data.Problems;
				// vul fields
			}
		}
	}
