<div class="content">

  <h1>Producten</h1>

  <div *ngIf="Loading || LoadingPlaatjes" class="mat-progress-spinner__cont">
    <div style="padding-top: 300px; padding-bottom: 300px; text-align: center;">
      <div>
        <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
      </div>
      <div>
        <p>De producten worden geladen</p>
      </div>
    </div>
  </div>

  <div *ngIf="!Loading">
    <div class="table-div">
      <table style="width: 100%">
        <tr>
          <td style="vertical-align: top;">
            <div class="block">
              <h2>Nieuwe upload Excel</h2>

              <input #file type="file" (change)="upload(file.files)">
            </div>
            <br />
            <div class="block">
              <h2>Actief</h2>
              <table style="width: 100%;">
                <tr>
                  <td><img src="/Content/Images/excel.png" style=" height: 30px;" /></td>
                  <td>{{ GetActiveExcel().DateTime }}</td>
                  <td>{{ GetActiveExcel().Name }}</td>
                  <td><a href="/Content/KlantSpecifiek/BCB21111/Excels/{{ GetActiveExcel().RelativePath }}">download</a>  </td>
                </tr>
              </table>
            </div>
            <br />
            <div class="block">
              <h2>Oud</h2>
              <table style="width: 100%;">
                <tr *ngFor="let excel of GetOldExcels()">
                  <td><img src="/Content/Images/excel.png" style=" height: 30px;" /></td>
                  <td>{{ excel.DateTime }}</td>
                  <td>{{ excel.Name }}</td>
                  <td><a href="/Content/KlantSpecifiek/BCB21111/Excels/{{ excel.RelativePath }}">download</a>  </td>
                </tr>
              </table>
            </div>
          </td>
          <td style="vertical-align: top;">
            <div class="block">
              <h2>Nieuwe upload Plaatje</h2>

              <input #fileplaatje type="file" (change)="uploadPlaatje(fileplaatje.files)">
            </div>
            <br />
            <div class="block" *ngIf="PlaatjesViewModel != undefined">
              <h2>Plaatjes</h2>

              <input type="checkbox" [(ngModel)]="InUse" /> Alleen ongebruikte plaatjes tonen
              <ng-container *ngFor="let plaatje of PlaatjesViewModel.Plaatjes">
                <div *ngIf="!InUse || !plaatje.InUse" class="plaatje-row">
                  <div class="plaatje-text">
                    {{ plaatje.Name }}
                  </div>
                  <div (click)="Delete(plaatje.Name)" style="cursor: pointer;">
                    <img src="/Content/Images/verwijderen.png" />
                  </div>
                </div>
              </ng-container>
            </div>
          </td>
        </tr>
      </table>
      
    </div>
    </div>
</div>
