/**
 * @ref WoonConnectViewModel/Survey/SurveyFacade.cs
 */

 import * as DataContracts from "@datacontracts/SurveyFacade";

 
import * as SurveyProperty from "./SurveyProperty";
import * as SurveyFacadeEnum from "@enums/SurveyFacade";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.SurveyFacade
	 */
	 

	export interface ISurveyFacade {
		ObjectId: string;
		Name: SurveyProperty.ISurveyProperty;
		WallType: SurveyFacadeEnum.WallTypes;
		InsulationPresentSingleWall: SurveyFacadeEnum.InsulationPresentSingleWall;
		OutsideInsulationWith: SurveyFacadeEnum.OutsideInsulationWith;
		InsulationThickness: number;
		RCValue: number;
		RCReportingCode: string;
		Insulated: boolean;
		ToJsonContract(): DataContracts.ISurveyFacade;
	}

	export class SurveyFacade {
		// property-Properties
		public get ObjectId(): string {
			return this._ObjectId;
		}
		public set ObjectId(newObjectId: string) {
			this._ObjectId = newObjectId;
		}

		public get Name(): SurveyProperty.ISurveyProperty {
			return this._Name;
		}
		public set Name(newName: SurveyProperty.ISurveyProperty) {
			this._Name = newName;
		}

		public get WallType(): SurveyFacadeEnum.WallTypes {
			return this._WallType;
		}
		public set WallType(newWallType: SurveyFacadeEnum.WallTypes) {
			this._WallType = newWallType;
		}

		public get InsulationPresentSingleWall(): SurveyFacadeEnum.InsulationPresentSingleWall {
			return this._InsulationPresentSingleWall;
		}
		public set InsulationPresentSingleWall(newInsulationPresentSingleWall: SurveyFacadeEnum.InsulationPresentSingleWall) {
			this._InsulationPresentSingleWall = newInsulationPresentSingleWall;
		}

		public get OutsideInsulationWith(): SurveyFacadeEnum.OutsideInsulationWith {
			return this._OutsideInsulationWith;
		}
		public set OutsideInsulationWith(newOutsideInsulationWith: SurveyFacadeEnum.OutsideInsulationWith) {
			this._OutsideInsulationWith = newOutsideInsulationWith;
		}

		public get InsulationThickness(): number {
			return this._InsulationThickness;
		}
		public set InsulationThickness(newInsulationThickness: number) {
			this._InsulationThickness = newInsulationThickness;
		}

		public get RCValue(): number {
			return this._RCValue;
		}
		public set RCValue(newRCValue: number) {
			this._RCValue = newRCValue;
		}

		public get RCReportingCode(): string {
			return this._RCReportingCode;
		}
		public set RCReportingCode(newRCReportingCode: string) {
			this._RCReportingCode = newRCReportingCode;
		}

		public get Insulated(): boolean {
			return this._Insulated;
		}
		public set Insulated(newInsulated: boolean) {
			this._Insulated = newInsulated;
		}

		// field-Properties

		// fields
		protected _ObjectId: string = "";
		protected _Name: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _WallType: SurveyFacadeEnum.WallTypes = undefined;
		protected _InsulationPresentSingleWall: SurveyFacadeEnum.InsulationPresentSingleWall = undefined;
		protected _OutsideInsulationWith: SurveyFacadeEnum.OutsideInsulationWith = undefined;
		protected _InsulationThickness: number = 0;
		protected _RCValue: number = 0;
		protected _RCReportingCode: string = "";
		protected _Insulated: boolean = false;

		constructor(data?: DataContracts.ISurveyFacade) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISurveyFacade): void {
			if(data) {
				// vul properties
				this._ObjectId = data.ObjectId;
				this._Name = data.Name ? new SurveyProperty.SurveyProperty(data.Name): undefined;
				this._WallType = data.WallType;
				this._InsulationPresentSingleWall = data.InsulationPresentSingleWall;
				this._OutsideInsulationWith = data.OutsideInsulationWith;
				this._InsulationThickness = data.InsulationThickness;
				this._RCValue = data.RCValue;
				this._RCReportingCode = data.RCReportingCode;
				this._Insulated = data.Insulated;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISurveyFacade {
			return {
				ObjectId: this.ObjectId,
				Name: (this.Name ? new SurveyProperty.SurveyProperty(this.Name).ToJsonContract(): this.Name),
				WallType: this.WallType,
				InsulationPresentSingleWall: this.InsulationPresentSingleWall,
				OutsideInsulationWith: this.OutsideInsulationWith,
				InsulationThickness: this.InsulationThickness,
				RCValue: this.RCValue,
				RCReportingCode: this.RCReportingCode,
				Insulated: this.Insulated
			}
		}
	}
