// [conv] import "(./FabAdminProductProperty).less";
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { ProductPropertyViewModel, PropertyOptionFiltersViewModel, ProductRequestModel, ProductPropertyTypesViewModel, ProductPropertyTypeViewModel, ProductPropertyOptionViewModel, ProductPropertyOptionsViewModel } from '@models/ProductsViewModel';
import { ProductPropertyFilterRelationsViewModel, RelationViewModel } from '@models/FilterViewModel';
import { ProductsConsumer } from '@consumers/ProductsController';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FiltersConsumer } from '@consumers/FiltersController';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';


interface FilterNode {
    Name: string;
    Id: number;
    FilterId: number;
    expandable: boolean;
    level: number;
    FilterParent: number;
    Active: boolean;
    Indeterminate: boolean;
    children?: FilterNode[];
}

interface ExampleFlatNode {
    expandable: boolean;
    Name: string;
    Id: number;
    FilterId: number;
    FilterParent: number;
    Indeterminate: boolean;
    Active: boolean;
    level: number;
}



@Component({
    selector: 'product-property-option',
  templateUrl: './FabAdminProductPropertyOption.html'
})
export class FabAdminProductPropertyOptionComponent {
    OptionId: number;
    Laden: boolean = true;
    update: boolean = true;
    addOption: boolean = false;
    ProductRequestModel: ProductRequestModel = new ProductRequestModel()
    ProductPropertyViewModel: ProductPropertyViewModel = new ProductPropertyViewModel();
    ProductPropertyTypesViewModel: ProductPropertyTypesViewModel = new ProductPropertyTypesViewModel();
    ProductPropertyType: ProductPropertyTypeViewModel = new ProductPropertyTypeViewModel();
    ProductPropertyOption: ProductPropertyOptionViewModel = new ProductPropertyOptionViewModel();
    PropertyOptionFiltersViewModel: PropertyOptionFiltersViewModel = new PropertyOptionFiltersViewModel();
    RelationViewModel: RelationViewModel = new RelationViewModel();

    //tree for filter options. 

    flatNodeMap = new Map<ExampleFlatNode, FilterNode>();
    nestedNodeMap = new Map<FilterNode, ExampleFlatNode>();


    checklistSelection = new SelectionModel<ExampleFlatNode>(true /* multiple */);
    //Selection
    selectedParent: ExampleFlatNode | null = null;
    getLevel = (node: ExampleFlatNode) => node.level;
    isExpandable = (node: ExampleFlatNode) => node.expandable;
    getChildren = (node: FilterNode): FilterNode[] => node.children;
    hasChild = (_: number, _nodeData: ExampleFlatNode) => _nodeData.expandable;
    hasNoContent = (_: number, _nodeData: ExampleFlatNode) => _nodeData.Name;


    //make nested tree a flat tree 
    private _transformer = (node: FilterNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            Name: node.Name,
            level: level,
            Id: node.Id,
            FilterId: node.FilterId,
            FilterParent: node.FilterParent,
            Active: node.Active
        }
    }


    treeControl = new FlatTreeControl<ExampleFlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  

    constructor(private route: ActivatedRoute, private prod: ProductsConsumer, private filter: FiltersConsumer, private router: Router) {
        //gets the product propertyid


    }
    ngOnInit() {
        this.OptionId = parseInt((<any>this.route.params).value.propertyoptionid);
        this.Refresh();
    }


    Update() {
        //update product property option settings. 
        console.log(this.ProductPropertyOption);

        //this.prod.SaveProductPropertyOption_Observable(this.ProductPropertyOption).subscribe((data) => {
        //    this.update = false;
        //    console.log(data);
        //    this.Refresh();
        //})
    }

    Delete() {
        console.log("Delete het volgende product property" + this.ProductPropertyOption.Id);


    }



    public Refresh(): void {
        this.ProductRequestModel.Id = this.OptionId;
        //this.prod.GetProductPropertyOption_Observable(this.ProductRequestModel).subscribe((data) => {
        //    this.ProductPropertyOption = new ProductPropertyOptionViewModel(data);
        //})

        //get all the filteroptions product proeprty options relation. 
        this.filter.GetPropertyOptionFilterOption_Observable(this.ProductRequestModel).subscribe((data) => {
            console.log(this.ProductRequestModel);
            console.log(data);
            let tree_data: any = [];

            this.PropertyOptionFiltersViewModel = new PropertyOptionFiltersViewModel(data);
            this.PropertyOptionFiltersViewModel.PropertyOptionFilter.forEach(elem => {
                let filtersoption: any = [];
                elem.PropertyOptionFilterOption.forEach(filteroption => {
                    let filteroption_node: any = {
                        Id: filteroption.Id,
                        FilterParent: filteroption.FilterOption.FilterId,
                        FilterId: filteroption.FilterOption.Id,
                        Name: filteroption.FilterOption.Name,
                        Active: filteroption.Active
                    }
                    filtersoption.push(filteroption_node);
                })
                let filters_node: any = {
                    Id: elem.Id,
                    FilterId: elem.Filter.Id,
                    FilterParent: 0,
                    Name: elem.Filter.Name,
                    Active: false,
                    children: filtersoption,
                }
                tree_data.push(filters_node);
            });

            this.dataSource.data = tree_data;
            console.log(this.dataSource.data);
        })
        this.Laden = false;
    }

    filterChange(checkedValue: boolean, node) {
        console.log(checkedValue, node);
        this.RelationViewModel = new RelationViewModel();
        if (node.level > 0) {
            this.RelationViewModel.Type = "propertyoptionfilteroption";
            this.RelationViewModel.Id = node.Id;
            this.RelationViewModel.FirstLinkId = this.OptionId;
            this.RelationViewModel.SecondLinkId = node.FilterId;
            this.RelationViewModel.Active = checkedValue;

            console.log(this.RelationViewModel);

            this.filter.SaveRelation_Observable(this.RelationViewModel).subscribe((data) => {
                console.log(data);
                this.Refresh();
            });
        }
        
    }

     

}
