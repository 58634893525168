export enum SubMenus {
    Default,
	Locatie,
	BeheerWoningen,
	Model,
	Scenario,
	Kosten,
    MaatwerkAdviesRapport,
    Documenten,
    Optiebord,
    DistrictDirectorManagement,
    Analytics,
    ImageAnnotationAdmin
}

export const initialMenuState: MenuState = {
    subMenu: SubMenus.Default
}

export interface MenuState {
    subMenu: SubMenus
}

