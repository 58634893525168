// [conv] import "(./WctEnergieTrace).less";
import * as _ from 'lodash';
import * as ng from 'angular';
import * as EnumsEnergieTraceHelper from '@enums/EnergieTraceHelper';
import * as ServicesEnergieTraceService from '../../services/EnergieTraceService';
import * as DataContractsEnergieTraceHelper from '@datacontracts/EnergieTraceHelper';




	interface IEnergieTraceBindings {
	}

	export interface IEnergieTraceController extends IEnergieTraceBindings {
		EnergieTracers: DataContractsEnergieTraceHelper.IEnergieTraceHelper[];
		Details: DataContractsEnergieTraceHelper.IEnergieTraceHelper[];

		CacheAan(b: boolean);
		PDF(b: boolean);
		LeegCaches();

		ToonGebouwmodel: boolean;
		ToonEigenschappen: boolean;
		ToonWijzigingen: boolean;
	
	}

	export class WctEnergieTraceStartController {
		constructor($scope: any) {
'ngInject';
			//$scope.isgeklikt = isgeklikt;
		}
	}

	class EnergieTraceController implements IEnergieTraceController {
		EnergieTracers: DataContractsEnergieTraceHelper.IEnergieTraceHelper[] = [];
		Details: DataContractsEnergieTraceHelper.IEnergieTraceHelper[] = [];

		ToonGebouwmodel: boolean = true;
		ToonEigenschappen: boolean = true;
		ToonWijzigingen: boolean = true;

		constructor(private energietraceservice: ServicesEnergieTraceService.IEnergieTraceService) {
'ngInject';
			this.HaalTracesOp();
		}

		private HaalTracesOp() {
			this.energietraceservice.GeefTraces().then(r => {
				this.Details = [];
				this.EnergieTracers = r.reverse();
			});
		}

		CacheAan(b: boolean) {
			this.energietraceservice.WijzigParameters(b, null, null);
		}

		PDF(b: boolean) {
			this.energietraceservice.WijzigParameters(null, b, null);
		}

		LeegCaches() {
			this.energietraceservice.WijzigParameters(null, null, true).then(r => {
				this.HaalTracesOp();
			});


		}

		ZoekProfiel(trace: DataContractsEnergieTraceHelper.IEnergieTraceHelper): DataContractsEnergieTraceHelper.IEnergieTraceBerekening {
			var profiel = _.find(trace.Berekeningen,
				(b) => b.BerekeningsType == EnumsEnergieTraceHelper.EnergieTraceBerekeningType.EnergieProfiel);
			return profiel;
		}

		Elektra(trace: DataContractsEnergieTraceHelper.IEnergieTraceHelper): number {
			var profiel = this.ZoekProfiel(trace);
			if (profiel != null) {
				return profiel.Elektriciteit;
			}
			return -1;
		}

		Gas(trace: DataContractsEnergieTraceHelper.IEnergieTraceHelper): number {
			var profiel = this.ZoekProfiel(trace);
			if (profiel != null) {
				return profiel.Gas;
			}
			return -1;
		}

		pad(n, width, z) {
			z = z || '0';
			n = n + '';
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
		}

		Tijdstip(trace: DataContractsEnergieTraceHelper.IEnergieTraceHelper): string {
			var d2: any = trace.StartTijd;
			var d = <string>d2;
			var date = new Date(d);
			var padded = this.pad(date.getHours(), 2, '0') + ':' + this.pad(date.getMinutes(), 2, '0') + ':' + this.pad(date.getSeconds(), 2, '0');
			return padded;
		}

		CheckboxAangepast(trace: DataContractsEnergieTraceHelper.IEnergieTraceHelper) {
			var t: any = trace;
			if (t.DetailsAan) {
				this.Details.push(trace);
			} else {
				var index = this.Details.indexOf(trace);
				if (index >= 0) {
					this.Details.splice(index, 1);
				}
			}
		}
	}

	export class WctEnergieTrace implements ng.IComponentOptions {
		public bindings: any;
		public controller: any;
		public templateUrl: string;

		public transclude: any;
		public $routeConfig: any;

		constructor() {
			this.bindings = {
			};
			this.controller = EnergieTraceController;
			this.templateUrl = '/app/components/EnergieTrace/WctEnergieTrace.html';
		}

		public static Factory() {
			return new WctEnergieTrace();
		}
	}

