/**
 * @ref WoonConnectViewModel/Infrastructure/ConstRequestModel.cs
 */

 import * as DataContracts from "@datacontracts/ConstRequestModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Infrastructure.ConstRequestModel
	 */
	 

	export interface IConstRequestModel {
		ProjectId: number;
		ToJsonContract(): DataContracts.IConstRequestModel;
	}

	export class ConstRequestModel {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		// field-Properties

		// fields
		protected _ProjectId: number = 0;

		constructor(data?: DataContracts.IConstRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IConstRequestModel): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IConstRequestModel {
			return {
				ProjectId: this.ProjectId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Infrastructure.ConstResponseModel
	 */
	 

	export interface IConstResponseModel {
		ProjectId: number;
		Contents: any;
	}

	export class ConstResponseModel {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		// field-Properties
		public get Contents(): any {
			return this._Contents;
		}
		public set Contents(newContents: any) {
			this._Contents = newContents;
		}

		// fields
		protected _ProjectId: number = 0;
		protected _Contents: any = undefined;

		constructor(data?: DataContracts.IConstResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IConstResponseModel): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				// vul fields
				this._Contents = data.Contents;
			}
		}
	}
