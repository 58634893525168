import * as ServicesPersoonService from '.././services/PersoonService';
import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';
import * as ServicesWoningService from '.././services/WoningService';



	export class Woning implements ServicesWoningService.IBreadcrumbSelectie {

		public GeefSubGroepen(): ServicesWoningService.IBreadcrumbSelectie[] {
			return [];
		}

		public GeefLabel(): string {
			return `${this.AdresStraat} ${this.AdresNummer}`;
		}

		public GeefGroepTypeNum(): EnumsWijzigScopeEnum.WijzigScopeEnum {
			return EnumsWijzigScopeEnum.WijzigScopeEnum.Woning;
		}

		public GeefID(): number {
			return this.WoningID;
		}

		public IsClickable(): boolean {
			//return true;
			return this.Clickable;
		}

		public IsToonbaar(): boolean {
			//return true;
			return this.Tonen;
		}

		public MaximaleHuurverhoging: number;

		public WoningType: string;
		public WoningTypeNaam: string;
		public Typologie: string;
		public WoningID: number;
		public BlokID: number;
		public AdresStraat: string;
		public AdresNummer: string;
		public ComplexNaam: string;
		public WoningEigenschappenWoningType: string;

		public AdresTekst(): string { return this.AdresStraat + " " + this.AdresNummer }

		public HoofdBewonerId: number;
		public HoofdBewonerIdString: string;
		public HoofdBewonerNaam: string;
		public HoofdBewonerAccountNaam: string;
		public HoofdBewonerAccountGuid: string;
		public HoofdBewonerVerificatieCode: string;
		public HoofdBewonerVerificatieCompleted: boolean;
		public HoofdBewonerAccountLocked: boolean;
		public HoofdBewonerAccountApproved: boolean;
		public HoofdBewonerRol: string;

		//public StartDatum: string;
		//public EindDatum: string;
		//public Ingediend: string;
		//public IsIngediend: boolean;
		//public HeeftAfspraak: boolean;

		public Postcode: string;
		public Plaats: string;
		public Status: string;
		public KavelStatus: string;

		public Index: number;
		public Laag: number;
		//public autozoom: number = 1;

		public Personen: Array<ServicesPersoonService.PersoonInfo>;

		public Latitude: string;
		public Longitude: string;
		public RD_x: number;
		public RD_y: number;
		public BAGID: string;
		public OppervlaktePolygoon: string;
		public WOZWaarde: number;

		public WijkNaam: string;

		public Kavelstatussen: Array<string>;

		// interface props
		public Tonen: boolean = false;
		public Clickable: boolean = false;
		public BezigMetLaden: boolean = false;
		public isOnderdeelVanVVE: boolean = false;


		constructor() {

		}

		public LaadData(data: any) {
			//this.HeeftAfspraak = data.HeeftAfspraak;
			//this.Ingediend = data.Ingediend;
			//this.IsIngediend = data.Ingediend != null;
			this.WoningID = data.Id;
			this.AdresStraat = data.Straat;
			this.AdresNummer = data.Nummer;
			this.Postcode = data.Postcode;
			this.Plaats = data.Plaats;
			this.Status = data.Status;
			this.HoofdBewonerId = data.HoofdBewonerId;
			this.HoofdBewonerIdString = data.HoofdBewonerId + "";
			this.HoofdBewonerNaam = data.HoofdBewonerNaam;
			this.HoofdBewonerAccountNaam = data.HoofdBewonerAccountNaam;
			this.HoofdBewonerAccountGuid = data.HoofdBewonerAccountGuid;
			this.HoofdBewonerVerificatieCode = data.HoofdBewonerVerificatieCode;
			this.HoofdBewonerVerificatieCompleted = data.HoofdBewonerVerificatieCompleted;
			this.HoofdBewonerAccountLocked = data.HoofdBewonerAccountLocked;
			this.HoofdBewonerAccountApproved = data.HoofdBewonerAccountApproved;
			this.ComplexNaam = data.ComplexNaam;
			//this.EindDatum = data.EindDatum;
			//this.StartDatum = data.StartDatum;
			this.BlokID = data.BlokId;
			this.WoningEigenschappenWoningType = data.WoningEigenschappenWoningType;
			this.MaximaleHuurverhoging = data.MaximaleHuurverhoging;
			this.isOnderdeelVanVVE = data.isOnderdeelVanVVE;

			this.Personen = new Array();

			this.Kavelstatussen = $.map((<{ Kavelstatussen: Array<string> }>data).Kavelstatussen, function (item) { return item; });
			this.KavelStatus = data.KavelStatus;

			this.Latitude = data.Lat;
			this.Longitude = data.Lng;
			this.RD_x = data.RD_x;
			this.RD_y = data.RD_y;
			this.BAGID = data.BAGID;
			this.OppervlaktePolygoon = data.OppervlaktePolygoon;
			this.WOZWaarde = data.WOZWaarde;
		}

		public ToonWoning() {
			alert(this.WoningID);
		}
	}

	export class PersoonModel {
		public Id: number;
		public Voornaam: string;
		public Tussenvoegsel: string;
		public Achternaam: string;
		public Telefoon: string;
		public Email: string;

		constructor(data) {
			if (data != null) {
				this.Id = data.Id;
				this.Voornaam = data.Voornaam;
				this.Tussenvoegsel = data.Tussenvoegsel;
				this.Achternaam = data.Achternaam;
				this.Telefoon = data.Telefoon;
				this.Email = data.Email;
			}

			if (this.Voornaam == null) {
				this.Voornaam = "";
			}

			if (this.Tussenvoegsel == null) {
				this.Tussenvoegsel = "";
			}

			if (this.Achternaam == null) {
				this.Achternaam = "";
			}
		}

		public GeefVolledigeNaam() {
			var naam = "";


			if (this.Tussenvoegsel.trim().length > 0) {
				naam = this.Voornaam.trim() + " " + this.Tussenvoegsel.trim() + " " + this.Achternaam;
			}
			else {
				naam = this.Voornaam.trim() + " " + this.Achternaam;
			}

			return naam;
		}
	}

