import * as _ from 'lodash';
import * as ng from 'angular';
import { DCConsumer } from '@consumers/DCController';
import {ProjectService}  from '.././services/ProjectService';
import * as ServicesWoningService from '.././services/WoningService';
import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';
import * as ViewModelsWoningSelectie from '.././Models/WoningSelectie';
import * as ViewModelsWoningGroepType from '.././Models/WoningGroepType';
import * as ViewModelsWoningGroep from '.././Models/WoningGroep';
import * as ViewModelsWoning from '.././Models/Woning';
import { of, Observable } from 'rxjs';
import { filter,map,take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store, select, createSelector, createFeatureSelector } from '@ngrx/store';

import { HttpClient } from '@angular/common/http';

import { WoningServiceData } from './WoningServicedata';
import { DwellingState } from './dwellingstore/dwelling.state';
import * as DwellingActions from './dwellingstore/dwelling.actions';
import { WoningAPIConsumer } from '@consumers/WoningAPIController';
import { IAngularWoning } from '@datacontracts/AngularWoningModel';


export interface IBreadcrumbSelectie {
	GeefLabel(): string;
	GeefID(): number;
	GeefGroepTypeNum(): EnumsWijzigScopeEnum.WijzigScopeEnum;
	GeefSubGroepen(): ServicesWoningService.IBreadcrumbSelectie[];
	IsClickable(): boolean;
	IsToonbaar(): boolean;
}

export interface IWoningService {
	GeefWoningGroepenMember(): ViewModelsWoningGroep.WoningGroep[];
	GeefWoningGroepVoorBlok(blokid: number): ViewModelsWoningGroep.WoningGroep;
	//GeefWoningGroepVoorWoning(woningid: number): ViewModelsWoningGroep.WoningGroep;
	GeefWoningen(cacheNietgebruiken): ng.IPromise<{ [id: number]: ViewModelsWoning.Woning; }>;
	GeefWoning(woning: number): Observable<ViewModelsWoning.Woning>;
	GeefBeheerWoningen(): ng.IPromise<ViewModelsWoning.Woning[]>;
	GeefBeheerWoning(woningid: number): ng.IPromise<ViewModelsWoning.Woning>;
	GeefWoningenVanPersoon(persoonid): ng.IPromise<ViewModelsWoning.Woning[]>;
	//GeefWoningenPromise(cacheNietGebruiken): ng.IPromise<ViewModelsWoningGroepType.WoningGroepType[]>;
	geefwoninggroepen(): ng.IPromise<ViewModelsWoningGroep.WoningGroep[]>;
	//GeefBreadCrumbVoorWoning(woningid: number, scope: EnumsWijzigScopeEnum.WijzigScopeEnum): ViewModelsWoningSelectie.WoningSelectie;
	GeefType(b: EnumsWijzigScopeEnum.WijzigScopeEnum): ViewModelsWoningGroepType.WoningGroepType;
	GeefBroerGroepen(selectie: ServicesWoningService.IBreadcrumbSelectie[], actief: ServicesWoningService.IBreadcrumbSelectie): ServicesWoningService.IBreadcrumbSelectie[];
	WijzigSelectieGroep(selectie: ServicesWoningService.IBreadcrumbSelectie[], actief: ServicesWoningService.IBreadcrumbSelectie): void;
	//WijzigSelectieWoning(selectie: ServicesWoningService.IBreadcrumbSelectie[], woning: number): void;
	GeefWoningGroepenVanType(t: EnumsWijzigScopeEnum.WijzigScopeEnum): ViewModelsWoningGroep.WoningGroep[];
	GeefWoningGroepWoning(woningid: number): ViewModelsWoning.Woning;
	//WoningGroepenLimiterenVoorBreadCrumb(): ng.IPromise<boolean>;
	KlaarMetLaden(): boolean;
}
@Injectable({
	providedIn: 'root',
})
export class WoningService implements ServicesWoningService.IWoningService {
	//m_Data: WoningServiceData;
	DwellingListVersion$: Observable<number>;

	//Woningen: ViewModelsWoning.Woning[] = null;


	public GeefWoningGroepenMember(): ViewModelsWoningGroep.WoningGroep[]
	{
		return this.LatestStore().WoningGroepen;
	}

	constructor(private $http: HttpClient, private projectservice: ProjectService, private dcconsumer: DCConsumer, private store: Store<{ dwelling: DwellingState }>, private woningApiConsumer: WoningAPIConsumer) {
		this.DwellingListVersion$ = this.store.select(s => s.dwelling.DwellingListVersion);
	}

	public LatestStore(): DwellingState {
		let inst: DwellingState = null;
		this.store.select(s => s.dwelling).pipe(take(1)).subscribe(a => inst = a);
		return inst;
	}

	public reload(): number
	{
		let store = this.LatestStore();
		let number = store.DwellingListVersion;
		this.store.dispatch(DwellingActions.ReloadDwellingList());
		return number;
	}

	public GeefBeheerWoning(woningid): ng.IPromise<ViewModelsWoning.Woning> {
		let obs = new Observable<ViewModelsWoning.Woning>((observable) => {
			this.$http.get('/Woning/GeefWoningBeheerInformatie?pWoningId=' + woningid).toPromise().then(response => {
				console.log("Woning-GeefWoningBeheerInformatie: AJAX success");

				var tWoningen = $.map((<{ Woningen: Array<Object> }>response).Woningen, function (item) {
					const woning = new ViewModelsWoning.Woning();
					woning.LaadData(item);
					return woning;
				});

				observable.next(tWoningen[0]);
				observable.complete();
			});
		});
		return obs.toPromise();
	}

	// public CheckWoningGroepenLimiterenVoorBreadCrumb(): ng.IPromise<boolean> {
	// 	var obs = new Observable<boolean>((observer) => {
	// 		var inputdata: any = {};
	// 		if (this.m_Data.m_WoningGroepenLimiterenVoorBreadCrumb == null) {
	// 			this.$http.post('/Woning/WoningGroepenLimiterenVoorBreadCrumb', inputdata).toPromise().then(response => {
	// 				var d: any = response;
	// 				observer.next(d);
	// 				observer.complete();
	// 			});
	// 		}
	// 		else {
	// 			observer.next(this.m_Data.m_WoningGroepenLimiterenVoorBreadCrumb);
	// 			observer.complete();
	// 		}
	// 	});
	// 	return obs.toPromise();
	// }

	// public WoningGroepenLimiterenVoorBreadCrumb(): ng.IPromise<boolean> {
	// 	var obs = new Observable<boolean>((observer) => {
	// 		if (this.m_Data.m_WoningGroepenLimiterenVoorBreadCrumb) {
	// 			this.dcconsumer.GeefParameters().then(r => {
	// 				var teTonenBlokken = _.filter(r.Compartimenten, (c) => { return c.Tonen }).map((t) => { return t.ID });
	// 				_.forEach(this.m_Data.WoningGroepen, (w) => {
	// 					if (w.Type.Naam == "Blok") {
	// 						if (_.find(teTonenBlokken, (b) => {
	// 							return b == w.ID;
	// 						}) == undefined) {
	// 							w.Tonen = false;
	// 						}
	// 						else {
	// 							w.Tonen = true;
	// 							var matchingCompartiment = _.find(r.Compartimenten, (c) => { return c.ID == w.ID });
	// 							if (matchingCompartiment != null) {
	// 								_.forEach(w.Woningen, (w) => {
	// 									if (w.Index < matchingCompartiment.Breedte && w.Laag < matchingCompartiment.Diepte) {
	// 										w.Tonen = true;
	// 									}
	// 									else {
	// 										w.Tonen = false;
	// 									}
	// 								});
	// 							}
	// 						}
	// 					}
	// 				});
	// 				observer.next(true);
	// 				observer.complete();
	// 			});
	// 		}
	// 		else {
	// 			observer.next(false);
	// 			observer.complete();
	// 		}
	// 	});
	// 	return obs.toPromise();
	// }

	public GeefBeheerWoningen(): ng.IPromise<ViewModelsWoning.Woning[]> {
		var inputdata: any = {};
		inputdata.ProjectId = this.projectservice.GeefProjectId();
		var req = this.$http.post('/Woning/GeefWoningenBeheerInformatie', inputdata).pipe(
			map(response => {
				console.log("Woning-GeefWoningenBeheerInformatie: AJAX success");
				var tWoningen = $.map((<{ Woningen: Array<Object> }>response).Woningen, function (item) {
					const woning = new ViewModelsWoning.Woning();
					woning.LaadData(item);
					return woning;
				});

				return tWoningen;
			}));
		return req.toPromise();
	}

	public GeefWoningenVanPersoon(persoonid): ng.IPromise<ViewModelsWoning.Woning[]> {
		var req = this.$http.get('/Woning/GeefWoningenBeheerInformatieVanPersoon?pPersoonId=' + persoonid).pipe(map(response => {
			console.log("Woning-GeefWoningenBeheerInformatieVanPersoon: AJAX success");

			var tWoningen = $.map((<{ Woningen: Array<Object> }>response).Woningen, function (item) {
				const woning = new ViewModelsWoning.Woning();
				woning.LaadData(item);
				return woning;
			});

			return tWoningen
		}));
		return req.toPromise();
	}

	// GeefWoningenPromise(cacheNietGebruiken): ng.IPromise<ViewModelsWoningGroepType.WoningGroepType[]> {
	// 	return
	// 	let obs = this.GeefWoningenObversable(cacheNietGebruiken);
	// 	return obs.toPromise();
	// }

	// GeefWoningenObversable(cacheNietGebruiken): Observable<ViewModelsWoningGroepType.WoningGroepType[]> {
	// 	let obs = new Observable<ViewModelsWoningGroepType.WoningGroepType[]>((observer) => {

	// 		if (cacheNietGebruiken === undefined) {
	// 			cacheNietGebruiken = false;
	// 		}

	// 		if ((this.m_Data.m_Woningen != null) && !cacheNietGebruiken && this.m_Data.m_KlaarMetLaden) {
	// 			observer.next(this.m_Data.WoningGroepTypen);
	// 			observer.complete();
	// 			//deferred.resolve(this.WoningGroepTypen);
	// 		}
	// 		else {
	// 			//var inputdata: any = {};
	// 			//inputdata.projectid = this.projectservice.GeefProjectId();
	// 			this.$http.post('/api/woning/WoningGroepen/' + this.projectservice.GeefProjectId(), {}).toPromise().then(response => {
	// 				).catch(r => observer.error(r));
	// 		}

	// 	});


	// 	return obs;
	// }

	public KlaarMetLaden(): boolean {
		return true;
		//return this.m_Data.m_KlaarMetLaden;
	}

	public GeefType(b: EnumsWijzigScopeEnum.WijzigScopeEnum): ViewModelsWoningGroepType.WoningGroepType {
		var store = this.LatestStore();
		const c = _.find(store.WoningGroepTypen, (bc) => b == bc.GroepType);
		return c;
	}

	public GeefBroerGroepen(selectie: ServicesWoningService.IBreadcrumbSelectie[], actief: ServicesWoningService.IBreadcrumbSelectie): ServicesWoningService.IBreadcrumbSelectie[] {
		var superenum = this.GeefType(actief.GeefGroepTypeNum()).SuperGroepTypeEnum;
		const b = _.find(selectie, (s) => s.GeefGroepTypeNum() == superenum);
		return b.GeefSubGroepen();
	}

	public GeefWoningGroepVoorBlok(blokid: number): ViewModelsWoningGroep.WoningGroep {
		var store = this.LatestStore();
		var blok = _.find(store.WoningGroepen, (g) => g.Type.SuperGroepTypeEnum == EnumsWijzigScopeEnum.WijzigScopeEnum.Complex && g.ID == blokid);
		return blok;
	}

	public GeefWoningGroepWoning(woningid: number): ViewModelsWoning.Woning {
		var store = this.LatestStore();
		var woning = store.Woningen[woningid];
		return woning;
	}

	public GeefWoningGroepenVanType(t: EnumsWijzigScopeEnum.WijzigScopeEnum): ViewModelsWoningGroep.WoningGroep[] {
		var store = this.LatestStore();
		var res = _.filter(store.WoningGroepen, (g) => g.Tonen == true && g.Type.GroepType == t);
		return res;
	}



	// Wijzigt in de opgegeven selectie een woninggroep
	public WijzigSelectieGroep(selectie: ServicesWoningService.IBreadcrumbSelectie[], actief?: ServicesWoningService.IBreadcrumbSelectie) {
		const huidige = _.find(selectie, (s) => s.GeefGroepTypeNum() === actief.GeefGroepTypeNum());
		let index = _.indexOf(selectie, huidige);
		selectie[index] = actief;

		while (selectie.length > index + 1) {
			index++;
			const nieuwActief = actief.GeefSubGroepen()[0];
			actief = nieuwActief;
			selectie[index] = nieuwActief;
		}
	}

	// Wijzigt in de opgegeven selectie een woning
	// public WijzigSelectieWoning(selectie: ServicesWoningService.IBreadcrumbSelectie[], woning: number) {
	// 	const bc = this.GeefBreadCrumbVoorWoning(woning, EnumsWijzigScopeEnum.WijzigScopeEnum.Blok);

	// 	for (let i = 0; i < bc.WoningGroepBreadcrumb.length; i++) {
	// 		selectie[i] = bc.WoningGroepBreadcrumb[i];
	// 	}
	// }

	GeefWoning(woning: number): Observable<ViewModelsWoning.Woning>
	{
		return this.store.select(x => x.dwelling).pipe(map(x => {
      return x.Woningen[woning]
    }));
		// var store = this.LatestStore();
		// return store.Woningen[woning];
	}


	GeefWoningen(cacheNietGebruiken): ng.IPromise<{ [id: number]: ViewModelsWoning.Woning; }> {

		var obs = this.store.select(x => x.dwelling).pipe(filter(x => x.WoningenGeladen), map(x => x.Woningen), take(1));
		return obs.toPromise();

		// var obs = new Observable<{ [id: number]: ViewModelsWoning.Woning; }>((observer) => {

		// 	this.GeefWoningenPromise(cacheNietGebruiken).then(gp => {
		// 		observer.next(this.m_Data.m_Woningen);
		// 		observer.complete();
		// 	});

		// });
		// return obs.toPromise();
	}

	public geefwoninggroepen(): ng.IPromise<ViewModelsWoningGroep.WoningGroep[]> {
		var obs = this.store.select(x => x.dwelling.WoningGroepen).pipe(filter(x => x != null));
		return obs.toPromise();

	}

	public getDwellingsByProjectId(projectId: number): Observable<IAngularWoning[]> {

		let obs = new Observable<IAngularWoning[]>(observer => {
			this.woningApiConsumer.WoningGroepen_Observable(projectId).subscribe(response => {
			   observer.next(response.Woningen);
			   observer.complete();
			});
		 });
		return obs;
	}
}

