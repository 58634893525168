<div class="content">
  <div *ngIf="!Loading">
    <a routerLink="/" class="logo">
      <img src="/Content/KlantSpecifiek/BCB21111/Kawneer_Logo_NoTag_websmart.png" height="60">
    </a>
    <ul class="menu" *ngIf="UserViewModel != null">
      <li><a routerLink="/overview/1" [class.active]="router.url === '/' || router.url.indexOf('/overview') != -1">Overview</a></li>
      <li><a routerLink="/prijsschema" [class.active]="router.url.indexOf('/prijsschema') != -1">Prijsschema's</a></li>
      <li><a routerLink="/voorwaarden" [class.active]="router.url.indexOf('/voorwaarden') != -1 && router.url.indexOf('beheer') == -1">Informatie Standaard condities</a></li>
      <li><a routerLink="/instructie" [class.active]="router.url.indexOf('/instructie') != -1 && router.url.indexOf('beheer') == -1">Instructie</a></li>
      <li *ngIf="UserViewModel.Rol == 'Beheerder'"><a routerLink="/voorwaardenbeheer" [class.active]="router.url.indexOf('/voorwaardenbeheer') != -1">Teksten</a></li>
      <!--<li *ngIf="UserViewModel.Rol == 'Beheerder'"><a routerLink="/instructiebeheer" [class.active]="router.url.indexOf('/instructiebeheer') != -1">Instructie beheer</a></li>-->
      <li *ngIf="UserViewModel.Rol == 'Beheerder'"><a routerLink="/product" [class.active]="router.url.indexOf('/product') != -1">Producten</a></li>
      <li *ngIf="UserViewModel.Rol == 'Beheerder'"><a routerLink="/gebruiker" [class.active]="router.url.indexOf('/gebruiker') != -1">Gebruikers</a></li>
      <li ></li>
    </ul>

    <div *ngIf="UserViewModel != null" class="user">
      {{ UserViewModel.UserName }}
      <a href="#" onclick="return false;" (click)="LogOut()">uitloggen</a>
    </div>
  </div>
</div>
