/**
 * @ref WoonConnectViewModel/WoningType/RetrieveDwellingTypeUseCaseModel.cs
 */

 import * as DataContracts from "@datacontracts/RetrieveDwellingTypeUseCaseModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.WoningType.RetrieveDwellingTypeUseCaseRequestModel
	 */
	 

	export interface IRetrieveDwellingTypeUseCaseRequestModel {
		DwellingId: number;
		TypeName: string;
		ProjectId: number;
		ToJsonContract(): DataContracts.IRetrieveDwellingTypeUseCaseRequestModel;
	}

	export class RetrieveDwellingTypeUseCaseRequestModel {
		// property-Properties
		public get DwellingId(): number {
			return this._DwellingId;
		}
		public set DwellingId(newDwellingId: number) {
			this._DwellingId = newDwellingId;
		}

		public get TypeName(): string {
			return this._TypeName;
		}
		public set TypeName(newTypeName: string) {
			this._TypeName = newTypeName;
		}

		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		// field-Properties

		// fields
		protected _DwellingId: number = 0;
		protected _TypeName: string = "";
		protected _ProjectId: number = 0;

		constructor(data?: DataContracts.IRetrieveDwellingTypeUseCaseRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IRetrieveDwellingTypeUseCaseRequestModel): void {
			if(data) {
				// vul properties
				this._DwellingId = data.DwellingId;
				this._TypeName = data.TypeName;
				this._ProjectId = data.ProjectId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IRetrieveDwellingTypeUseCaseRequestModel {
			return {
				DwellingId: this.DwellingId,
				TypeName: this.TypeName,
				ProjectId: this.ProjectId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.WoningType.RetrieveDwellingTypeUseCaseResponseModel
	 */
	 

	export interface IRetrieveDwellingTypeUseCaseResponseModel {
		Model: any;
		TekstUit: string;
	}

	export class RetrieveDwellingTypeUseCaseResponseModel {
		// property-Properties
		public get Model(): any {
			return this._Model;
		}
		public set Model(newModel: any) {
			this._Model = newModel;
		}

		// field-Properties
		public get TekstUit(): string {
			return this._TekstUit;
		}
		public set TekstUit(newTekstUit: string) {
			this._TekstUit = newTekstUit;
		}

		// fields
		protected _Model: any = undefined;
		protected _TekstUit: string = "";

		constructor(data?: DataContracts.IRetrieveDwellingTypeUseCaseResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IRetrieveDwellingTypeUseCaseResponseModel): void {
			if(data) {
				// vul properties
				this._Model = data.Model;
				// vul fields
				this._TekstUit = data.TekstUit;
			}
		}
	}
