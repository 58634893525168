import { createAction, props } from '@ngrx/store';
import { ISoftware}  from '@datacontracts/Constanten';
import { WijzigScopeEnum }  from '@enums/WijzigScopeEnum';

export const loadConfig = createAction('[Dwelling] loadConfig', props<{software: ISoftware}>());
//export const changeScenario = createAction('[Dwelling] changeScenario', props<{scenario: number}>());
export const changeScenarioAndDwelling = createAction('[Dwelling] changeScenarioAndDwelling', props<{scenario: number, dwelling: number}>());
export const changeScenarioAndDwellingSuccessServerSide = createAction('[Dwelling] changeScenarioAndDwellingSuccessServerSide');
export const ReloadDwellingListSuccess = createAction('[Dwelling] ReloadDwellingListSuccess', props<{data: any}>());

export const changeScope = createAction('[Dwelling] changeScope', props<{scope: WijzigScopeEnum}>());
export const ReloadDwellingList = createAction('[Dwelling] ReloadDwellingList');

export const changeScenarioAndDwellingSuccess = createAction('[Dwelling] changeScenarioAndDwellingSuccess');


