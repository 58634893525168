import * as ng from 'angular';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../infrastructure/app.configservice';

   export interface ITranslateService {
      GetTranslation(Part: string, Word: string): string;
      GetDateFormat(Type: string): string;
   }

   @Injectable({
		providedIn: 'root',
	})
   export class TranslateService implements ITranslateService {

      constructor(private config: AppConfigService) {
'ngInject';

      }

      public GetTranslation(Part: string, Word: string): string {
         for (var i = 0; i < this.config.Software.Talen.length; i++) {
            if (this.config.Software.StartTaal.toUpperCase() == this.config.Software.Talen[i].Afkorting.toUpperCase()) {
               for (var k = 0; k < this.config.Software.Talen[i].Onderdelen.length; k++) {
                  if (this.config.Software.Talen[i].Onderdelen[k].Onderdeel.toUpperCase() == Part.toUpperCase()) {
                     for (var j = 0; j < this.config.Software.Talen[i].Onderdelen[k].Items.length; j++) {
                        if (this.config.Software.Talen[i].Onderdelen[k].Items[j].Keyword.toUpperCase() == Word.toUpperCase()) {
                           return this.config.Software.Talen[i].Onderdelen[k].Items[j].Vertaling;
                        }
                     }
                  }
               }
            }
         }

         return "";
      }

      public GetDateFormat(Type: string): string {

         for (var i = 0; i < this.config.Software.Talen.length; i++) {
            if (this.config.Software.StartTaal.toUpperCase() == this.config.Software.Talen[i].Afkorting.toUpperCase()) {

               if (Type.toUpperCase() == "DEFAULT") {
                  return this.config.Software.Talen[i].DateFormatDefault;
               }

               if (Type.toUpperCase() == "LONG") {
                  return this.config.Software.Talen[i].DateFormatLong;
               }
            }
         }

         return "dd-MM-yyyy";
      }
   }

