/// <reference path='../consumerinterfaces/ResidentModelController.ts' />
/**
 * @ref WoonConnectApiController/Resident/ResidentModelController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/ResidentModelController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as GetResidentModel from "@datacontracts/GetResidentModel";
import * as SetResidentModel from "@datacontracts/SetResidentModel";
import * as ResidentChangePasswordModel from "@datacontracts/ResidentChangePasswordModel";
import * as SetResidentContactformModel from "@datacontracts/SetResidentContactformModel";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class ResidentModelConsumer implements Interface.IResidentModelConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public GetResident_Observable(inputModel: GetResidentModel.IGetResidentModelRequestModel & {ToJsonContract(noContent?: boolean): GetResidentModel.IGetResidentModelRequestModel}): Observable<GetResidentModel.IGetResidentModelResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ResidentModelController/GetResidentModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GetResidentModel.IGetResidentModelResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetResident(inputModel: GetResidentModel.IGetResidentModelRequestModel & {ToJsonContract(noContent?: boolean): GetResidentModel.IGetResidentModelRequestModel}): ng.IPromise<GetResidentModel.IGetResidentModelResponseModel> {
				let obs = this.GetResident_Observable(inputModel);
				let promise = new Promise<GetResidentModel.IGetResidentModelResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SetResident_Observable(inputModel: SetResidentModel.ISetResidentModelRequestModel & {ToJsonContract(noContent?: boolean): SetResidentModel.ISetResidentModelRequestModel}): Observable<SetResidentModel.ISetResidentModelResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ResidentModelController/SetResidentModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<SetResidentModel.ISetResidentModelResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SetResident(inputModel: SetResidentModel.ISetResidentModelRequestModel & {ToJsonContract(noContent?: boolean): SetResidentModel.ISetResidentModelRequestModel}): ng.IPromise<SetResidentModel.ISetResidentModelResponseModel> {
				let obs = this.SetResident_Observable(inputModel);
				let promise = new Promise<SetResidentModel.ISetResidentModelResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ChangePassword_Observable(inputModel: ResidentChangePasswordModel.IResidentChangePasswordRequestModel & {ToJsonContract(noContent?: boolean): ResidentChangePasswordModel.IResidentChangePasswordRequestModel}): Observable<ResidentChangePasswordModel.IResidentChangePasswordResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ResidentModelController/ChangePassword`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ResidentChangePasswordModel.IResidentChangePasswordResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ChangePassword(inputModel: ResidentChangePasswordModel.IResidentChangePasswordRequestModel & {ToJsonContract(noContent?: boolean): ResidentChangePasswordModel.IResidentChangePasswordRequestModel}): ng.IPromise<ResidentChangePasswordModel.IResidentChangePasswordResponseModel> {
				let obs = this.ChangePassword_Observable(inputModel);
				let promise = new Promise<ResidentChangePasswordModel.IResidentChangePasswordResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SetResidentContactForm_Observable(inputModel: SetResidentContactformModel.ISetResidentContactformRequestModel & {ToJsonContract(noContent?: boolean): SetResidentContactformModel.ISetResidentContactformRequestModel}): Observable<SetResidentContactformModel.ISetResidentContactformResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/ResidentModelController/SetResidentContactform`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<SetResidentContactformModel.ISetResidentContactformResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SetResidentContactForm(inputModel: SetResidentContactformModel.ISetResidentContactformRequestModel & {ToJsonContract(noContent?: boolean): SetResidentContactformModel.ISetResidentContactformRequestModel}): ng.IPromise<SetResidentContactformModel.ISetResidentContactformResponseModel> {
				let obs = this.SetResidentContactForm_Observable(inputModel);
				let promise = new Promise<SetResidentContactformModel.ISetResidentContactformResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


