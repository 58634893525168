<div class="accountmenu__main-link" (click)="ToggleDropdown()">
	<p *ngIf="!ShowAddress()">{{LoginName}}</p>
	<p *ngIf="ShowAddress()">{{HuidigeWoning?.AdresStraat}} {{HuidigeWoning?.AdresNummer}}</p>
	<i class="fal fa-user-circle"></i>
</div>
<div *ngIf="ShowDropdown" class="accountmenu__dropdown">
	<ul>
		<li *ngIf="HasRole('Eigenaar') || HasRole('Huurder')" (click)="OpenAccountSettings('accountmanagement')"><p>{{ "SETTINGS_ACCOUNT-MANAGEMENT" | translate }}</p></li>
		<li *ngIf="HasRole('Contractor')" (click)="OpenAccountSettings('companyprofile')"><p>{{ "SETTINGS_COMPANY-PROFILE" | translate }}</p></li>
    <!-- <li *ngIf="HasRole('MassaConfigureerder')" routerLink="../contactforms"><p>Advies / offerte aanvragen</p></li> -->
		<li (click)="OpenAccountSettings('changepassword')"><p>{{ "SETTINGS_CHANGE-PASSWORD" | translate }}</p></li>
    <li *ngIf="HasRole('Contractor') || HasRole('MassaConfigureerder')" (click)="OpenAccountSettings('disclaimer')"><p>{{ "MENUHEADER_DISCLAIMER" | translate }}</p></li>
		<li (click)="Logout()"><p>{{ "MENUHEADER_SIGNOUT" | translate }}</p></li>

		<svg ngNonBindable viewBox="0 0 14.1 7.8" style="enable-background:new 0 0 14.1 7.8;" xml:space="preserve">
			<polygon class="st0" points="0.7,7.1 7.1,0.7 13.4,7.1 " />
		</svg>
	</ul>
</div>
<div *ngIf="AccountSettingsAreShown" @ngIfAnimation class="accountmenu__settings-background" (click)="CloseAccountSettings()">
  <div class="accountmenu__settings" (click)="$event.stopPropagation()" @easeInOut>
	<!-- <div [perfectScrollbar]="config" class="ps"> -->
    <div class="ps">
      <wct-account-settings [activeTab]="chosenTab" (onCloseAccountSettings)="CloseAccountSettings()"></wct-account-settings>
    </div>
  </div>
</div>
