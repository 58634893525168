/**
 * @ref WoonConnectViewModel/Scene/DrawSceneModel.cs
 */

 import * as DataContracts from "@datacontracts/DrawSceneModel";

 
import * as FragmentScene from "./FragmentScene";
import * as WoningSelectie from "./WoningSelectie";



	/**
	 * Originele namespace: WoonConnectViewModel.Scene.FragmentModelData
	 */
	 

	export interface IFragmentModelData {
		FragmentId: string;
		FragmentUrl: string;
		FragmentUrlId: string;
		FragmentConfigKey: string;
		AktieNodig: boolean;
		TransformatieURL: string;
		TransformatieText: string;
		IsMeubilair: string;
		AbsoluutTransformeren: boolean;
	}

	export class FragmentModelData {
		// property-Properties
		public get FragmentId(): string {
			return this._FragmentId;
		}
		public set FragmentId(newFragmentId: string) {
			this._FragmentId = newFragmentId;
		}

		public get FragmentUrl(): string {
			return this._FragmentUrl;
		}
		public set FragmentUrl(newFragmentUrl: string) {
			this._FragmentUrl = newFragmentUrl;
		}

		public get FragmentUrlId(): string {
			return this._FragmentUrlId;
		}
		public set FragmentUrlId(newFragmentUrlId: string) {
			this._FragmentUrlId = newFragmentUrlId;
		}

		public get FragmentConfigKey(): string {
			return this._FragmentConfigKey;
		}
		public set FragmentConfigKey(newFragmentConfigKey: string) {
			this._FragmentConfigKey = newFragmentConfigKey;
		}

		public get AktieNodig(): boolean {
			return this._AktieNodig;
		}
		public set AktieNodig(newAktieNodig: boolean) {
			this._AktieNodig = newAktieNodig;
		}

		public get TransformatieURL(): string {
			return this._TransformatieURL;
		}
		public set TransformatieURL(newTransformatieURL: string) {
			this._TransformatieURL = newTransformatieURL;
		}

		public get TransformatieText(): string {
			return this._TransformatieText;
		}
		public set TransformatieText(newTransformatieText: string) {
			this._TransformatieText = newTransformatieText;
		}

		public get IsMeubilair(): string {
			return this._IsMeubilair;
		}
		public set IsMeubilair(newIsMeubilair: string) {
			this._IsMeubilair = newIsMeubilair;
		}

		public get AbsoluutTransformeren(): boolean {
			return this._AbsoluutTransformeren;
		}
		public set AbsoluutTransformeren(newAbsoluutTransformeren: boolean) {
			this._AbsoluutTransformeren = newAbsoluutTransformeren;
		}

		// field-Properties

		// fields
		protected _FragmentId: string = "";
		protected _FragmentUrl: string = "";
		protected _FragmentUrlId: string = "";
		protected _FragmentConfigKey: string = "";
		protected _AktieNodig: boolean = false;
		protected _TransformatieURL: string = "";
		protected _TransformatieText: string = "";
		protected _IsMeubilair: string = "";
		protected _AbsoluutTransformeren: boolean = false;

		constructor(data?: DataContracts.IFragmentModelData) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFragmentModelData): void {
			if(data) {
				// vul properties
				this._FragmentId = data.FragmentId;
				this._FragmentUrl = data.FragmentUrl;
				this._FragmentUrlId = data.FragmentUrlId;
				this._FragmentConfigKey = data.FragmentConfigKey;
				this._AktieNodig = data.AktieNodig;
				this._TransformatieURL = data.TransformatieURL;
				this._TransformatieText = data.TransformatieText;
				this._IsMeubilair = data.IsMeubilair;
				this._AbsoluutTransformeren = data.AbsoluutTransformeren;
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Scene.FragmentAction
	 */
	 

	export interface IFragmentAction {
		action: string;
		modeldata: FragmentScene.IFragmentModelData[];
		actionExtraData: any;
		/**
		 * Geeft de display mode aan die gezet moet worden, indien het een camera
		 */
		DisplayMode: string;
		CameraNaam: string;
		CameraZoomSchaal: number;
		CameraRichting: number;
		CameraHelling: number;
		CameraCentrum: number[];
		CameraRichting3D: number[];
		CameraPositie: number[];
		Zoom: number[][];
		CameraDomeAfbeelding: string;
		CameraRichtingIs3D: boolean;
		Lichtval: number[];
		LichtSchittering: number;
		LichtbronSterkte: number;
		IsCameraLicht: boolean;
		OmgevinglichtSterkte: number;
		SlagschaduwVerzadiging: number;
		ToonSlagschaduw: boolean;
		AktieNodig: boolean;
	}

	export class FragmentAction {
		// property-Properties
		public get action(): string {
			return this._action;
		}
		public set action(newaction: string) {
			this._action = newaction;
		}

		public get modeldata(): FragmentScene.IFragmentModelData[] {
			return this._modeldata;
		}
		public set modeldata(newmodeldata: FragmentScene.IFragmentModelData[]) {
			this._modeldata = newmodeldata;
		}

		public get actionExtraData(): any {
			return this._actionExtraData;
		}
		public set actionExtraData(newactionExtraData: any) {
			this._actionExtraData = newactionExtraData;
		}

		public get DisplayMode(): string {
			return this._DisplayMode;
		}
		public set DisplayMode(newDisplayMode: string) {
			this._DisplayMode = newDisplayMode;
		}

		public get CameraNaam(): string {
			return this._CameraNaam;
		}
		public set CameraNaam(newCameraNaam: string) {
			this._CameraNaam = newCameraNaam;
		}

		public get CameraZoomSchaal(): number {
			return this._CameraZoomSchaal;
		}
		public set CameraZoomSchaal(newCameraZoomSchaal: number) {
			this._CameraZoomSchaal = newCameraZoomSchaal;
		}

		public get CameraRichting(): number {
			return this._CameraRichting;
		}
		public set CameraRichting(newCameraRichting: number) {
			this._CameraRichting = newCameraRichting;
		}

		public get CameraHelling(): number {
			return this._CameraHelling;
		}
		public set CameraHelling(newCameraHelling: number) {
			this._CameraHelling = newCameraHelling;
		}

		public get CameraCentrum(): number[] {
			return this._CameraCentrum;
		}
		public set CameraCentrum(newCameraCentrum: number[]) {
			this._CameraCentrum = newCameraCentrum;
		}

		public get CameraRichting3D(): number[] {
			return this._CameraRichting3D;
		}
		public set CameraRichting3D(newCameraRichting3D: number[]) {
			this._CameraRichting3D = newCameraRichting3D;
		}

		public get CameraPositie(): number[] {
			return this._CameraPositie;
		}
		public set CameraPositie(newCameraPositie: number[]) {
			this._CameraPositie = newCameraPositie;
		}

		public get Zoom(): number[][] {
			return this._Zoom;
		}
		public set Zoom(newZoom: number[][]) {
			this._Zoom = newZoom;
		}

		public get CameraDomeAfbeelding(): string {
			return this._CameraDomeAfbeelding;
		}
		public set CameraDomeAfbeelding(newCameraDomeAfbeelding: string) {
			this._CameraDomeAfbeelding = newCameraDomeAfbeelding;
		}

		public get CameraRichtingIs3D(): boolean {
			return this._CameraRichtingIs3D;
		}
		public set CameraRichtingIs3D(newCameraRichtingIs3D: boolean) {
			this._CameraRichtingIs3D = newCameraRichtingIs3D;
		}

		public get Lichtval(): number[] {
			return this._Lichtval;
		}
		public set Lichtval(newLichtval: number[]) {
			this._Lichtval = newLichtval;
		}

		public get LichtSchittering(): number {
			return this._LichtSchittering;
		}
		public set LichtSchittering(newLichtSchittering: number) {
			this._LichtSchittering = newLichtSchittering;
		}

		public get LichtbronSterkte(): number {
			return this._LichtbronSterkte;
		}
		public set LichtbronSterkte(newLichtbronSterkte: number) {
			this._LichtbronSterkte = newLichtbronSterkte;
		}

		public get IsCameraLicht(): boolean {
			return this._IsCameraLicht;
		}
		public set IsCameraLicht(newIsCameraLicht: boolean) {
			this._IsCameraLicht = newIsCameraLicht;
		}

		public get OmgevinglichtSterkte(): number {
			return this._OmgevinglichtSterkte;
		}
		public set OmgevinglichtSterkte(newOmgevinglichtSterkte: number) {
			this._OmgevinglichtSterkte = newOmgevinglichtSterkte;
		}

		public get SlagschaduwVerzadiging(): number {
			return this._SlagschaduwVerzadiging;
		}
		public set SlagschaduwVerzadiging(newSlagschaduwVerzadiging: number) {
			this._SlagschaduwVerzadiging = newSlagschaduwVerzadiging;
		}

		public get ToonSlagschaduw(): boolean {
			return this._ToonSlagschaduw;
		}
		public set ToonSlagschaduw(newToonSlagschaduw: boolean) {
			this._ToonSlagschaduw = newToonSlagschaduw;
		}

		public get AktieNodig(): boolean {
			return this._AktieNodig;
		}
		public set AktieNodig(newAktieNodig: boolean) {
			this._AktieNodig = newAktieNodig;
		}

		// field-Properties

		// fields
		protected _action: string = "";
		protected _modeldata: FragmentScene.IFragmentModelData[] = [];
		protected _actionExtraData: any = undefined;
		protected _DisplayMode: string = "";
		protected _CameraNaam: string = "";
		protected _CameraZoomSchaal: number = 0;
		protected _CameraRichting: number = 0;
		protected _CameraHelling: number = 0;
		protected _CameraCentrum: number[] = [];
		protected _CameraRichting3D: number[] = [];
		protected _CameraPositie: number[] = [];
		protected _Zoom: number[][] = [];
		protected _CameraDomeAfbeelding: string = "";
		protected _CameraRichtingIs3D: boolean = false;
		protected _Lichtval: number[] = [];
		protected _LichtSchittering: number = 0;
		protected _LichtbronSterkte: number = 0;
		protected _IsCameraLicht: boolean = false;
		protected _OmgevinglichtSterkte: number = 0;
		protected _SlagschaduwVerzadiging: number = 0;
		protected _ToonSlagschaduw: boolean = false;
		protected _AktieNodig: boolean = false;

		constructor(data?: DataContracts.IFragmentAction) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFragmentAction): void {
			if(data) {
				// vul properties
				this._action = data.action;
				this._modeldata = data.modeldata ? data.modeldata.map(fragmentModelDataItem => new FragmentScene.FragmentModelData(fragmentModelDataItem)): [];
				this._actionExtraData = data.actionExtraData;
				this._DisplayMode = data.DisplayMode;
				this._CameraNaam = data.CameraNaam;
				this._CameraZoomSchaal = data.CameraZoomSchaal;
				this._CameraRichting = data.CameraRichting;
				this._CameraHelling = data.CameraHelling;
				this._CameraCentrum = data.CameraCentrum;
				this._CameraRichting3D = data.CameraRichting3D;
				this._CameraPositie = data.CameraPositie;
				this._Zoom = data.Zoom;
				this._CameraDomeAfbeelding = data.CameraDomeAfbeelding;
				this._CameraRichtingIs3D = data.CameraRichtingIs3D;
				this._Lichtval = data.Lichtval;
				this._LichtSchittering = data.LichtSchittering;
				this._LichtbronSterkte = data.LichtbronSterkte;
				this._IsCameraLicht = data.IsCameraLicht;
				this._OmgevinglichtSterkte = data.OmgevinglichtSterkte;
				this._SlagschaduwVerzadiging = data.SlagschaduwVerzadiging;
				this._ToonSlagschaduw = data.ToonSlagschaduw;
				this._AktieNodig = data.AktieNodig;
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Scene.DrawSceneOptions
	 */
	 

	export interface IDrawSceneOptions {
		Hoogte: number;
		SnijDoor: boolean;
		ToJsonContract(): DataContracts.IDrawSceneOptions;
	}

	export class DrawSceneOptions {
		// property-Properties
		public get Hoogte(): number {
			return this._Hoogte;
		}
		public set Hoogte(newHoogte: number) {
			this._Hoogte = newHoogte;
		}

		public get SnijDoor(): boolean {
			return this._SnijDoor;
		}
		public set SnijDoor(newSnijDoor: boolean) {
			this._SnijDoor = newSnijDoor;
		}

		// field-Properties

		// fields
		protected _Hoogte: number = 0;
		protected _SnijDoor: boolean = false;

		constructor(data?: DataContracts.IDrawSceneOptions) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDrawSceneOptions): void {
			if(data) {
				// vul properties
				this._Hoogte = data.Hoogte;
				this._SnijDoor = data.SnijDoor;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDrawSceneOptions {
			return {
				Hoogte: this.Hoogte,
				SnijDoor: this.SnijDoor
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Scene.DrawSceneRequestModel
	 */
	 

	export interface IDrawSceneRequestModel {
		SceneSessionID: string;
		WoningSelectie: WoningSelectie.IWoningSelectie;
		Width: number;
		Height: number;
		Commando: string;
		Options: DrawSceneOptions;
		ToJsonContract(): DataContracts.IDrawSceneRequestModel;
	}

	export class DrawSceneRequestModel {
		// property-Properties
		public get SceneSessionID(): string {
			return this._SceneSessionID;
		}
		public set SceneSessionID(newSceneSessionID: string) {
			this._SceneSessionID = newSceneSessionID;
		}

		public get WoningSelectie(): WoningSelectie.IWoningSelectie {
			return this._WoningSelectie;
		}
		public set WoningSelectie(newWoningSelectie: WoningSelectie.IWoningSelectie) {
			this._WoningSelectie = newWoningSelectie;
		}

		public get Width(): number {
			return this._Width;
		}
		public set Width(newWidth: number) {
			this._Width = newWidth;
		}

		public get Height(): number {
			return this._Height;
		}
		public set Height(newHeight: number) {
			this._Height = newHeight;
		}

		public get Commando(): string {
			return this._Commando;
		}
		public set Commando(newCommando: string) {
			this._Commando = newCommando;
		}

		public get Options(): DrawSceneOptions {
			return this._Options;
		}
		public set Options(newOptions: DrawSceneOptions) {
			this._Options = newOptions;
		}

		// field-Properties

		// fields
		protected _SceneSessionID: string = "";
		protected _WoningSelectie: WoningSelectie.IWoningSelectie = new WoningSelectie.WoningSelectie(undefined);
		protected _Width: number = 0;
		protected _Height: number = 0;
		protected _Commando: string = "";
		protected _Options: DrawSceneOptions = new DrawSceneOptions(undefined);

		constructor(data?: DataContracts.IDrawSceneRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDrawSceneRequestModel): void {
			if(data) {
				// vul properties
				this._SceneSessionID = data.SceneSessionID;
				this._WoningSelectie = data.WoningSelectie ? new WoningSelectie.WoningSelectie(data.WoningSelectie): undefined;
				this._Width = data.Width;
				this._Height = data.Height;
				this._Commando = data.Commando;
				this._Options = data.Options ? new DrawSceneOptions(data.Options): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDrawSceneRequestModel {
			return {
				SceneSessionID: this.SceneSessionID,
				WoningSelectie: (this.WoningSelectie ? new WoningSelectie.WoningSelectie(this.WoningSelectie).ToJsonContract(): this.WoningSelectie),
				Width: this.Width,
				Height: this.Height,
				Commando: this.Commando,
				Options: (this.Options ? new DrawSceneOptions(this.Options).ToJsonContract(): this.Options)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Scene.DrawSceneResponseModel
	 */
	 

	export interface IDrawSceneResponseModel {
		Fragmenten: FragmentScene.IFragmentAction[];
		Snapshot: string;
	}

	export class DrawSceneResponseModel {
		// property-Properties
		public get Fragmenten(): FragmentScene.IFragmentAction[] {
			return this._Fragmenten;
		}
		public set Fragmenten(newFragmenten: FragmentScene.IFragmentAction[]) {
			this._Fragmenten = newFragmenten;
		}

		public get Snapshot(): string {
			return this._Snapshot;
		}
		public set Snapshot(newSnapshot: string) {
			this._Snapshot = newSnapshot;
		}

		// field-Properties

		// fields
		protected _Fragmenten: FragmentScene.IFragmentAction[] = [];
		protected _Snapshot: string = "";

		constructor(data?: DataContracts.IDrawSceneResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDrawSceneResponseModel): void {
			if(data) {
				// vul properties
				this._Fragmenten = data.Fragmenten ? data.Fragmenten.map(fragmentActionItem => new FragmentScene.FragmentAction(fragmentActionItem)): [];
				this._Snapshot = data.Snapshot;
				// vul fields
			}
		}
	}
