// [conv] import "(./FabAdminFilter).less";
import { Component, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FiltersConsumer } from "@consumers/FiltersController";
import { FilterViewModel, FilterRequestModel, FilterTypesViewModel, FilterTypeViewModel } from '@models/FilterViewModel';
import { DeleteRequestModel } from '@models/ProductsViewModel';
import { FilterDisplayLevelEnum, FilterDisplayTypeEnum } from '@enums/FilterViewModel';
import { FormControl, FormGroup, FormBuilder, ValidationErrors, AbstractControl } from '@angular/forms';

@Component({
	selector: 'filter',
  templateUrl: './FabAdminFilter.html',
  styleUrls: ['./FabAdminFilter.scss']
})

export class FabAdminFilterComponent {
	@Input() FilterId: number;
	@Input() TotalFilters: number;
	@Output() public FiltersRefresh = new EventEmitter<any>();
	selectedFilter: FilterViewModel = new FilterViewModel();
	FilterTypesViewModel: FilterTypesViewModel;

	Loading: boolean = true;
	addNew: boolean = true;
	initialized: boolean = false;
	FilterViewModel: FilterViewModel;
	showMe: boolean = false;
	DeleteId: number;

	filterForm: FormGroup;

	constructor(private filter: FiltersConsumer, private fb: FormBuilder) {

		//get FilterTypes from server
		this.filter.GetFilterTypes_Observable().subscribe((data) => {
			this.FilterTypesViewModel = new FilterTypesViewModel(data);
			this.Refresh();
			this.initialized = true;
		})

		this.filterForm = this.fb.group({
			filterName: [''],
			minValue: ['0'],
			maxValue: ['0'],
			defaultValue: ['0'],
			hasEffect: [false],
			mandatory: [false],
			important: [false],
			filterDisplayType: [0],
			filterDisplayLevel: [0],
			filterTypeId: [0]
		},
			{ validator: this.checkValueRanges });
	}

	ngOnChanges(changes: SimpleChanges) {
		console.log("filter change called")
		if (!this.initialized) {
			return; //do nothing, not initialized yet. (probably still getting FilterTypesViewModel)
		}

		if (changes.FilterId.currentValue != changes.FilterId.previousValue) {
			this.FilterId = changes.FilterId.currentValue;
			this.Refresh();
		}
	}

	public Refresh(): void {
		this.Loading = true;

		if (this.FilterId > 0) {
			this.addNew = false;

			var input = new FilterRequestModel();
			input.Id = this.FilterId;

			this.filter.GetFilter_Observable(input).subscribe((data) => {
				console.log(data);
				this.FilterViewModel = new FilterViewModel(data);
				this.selectedFilter = new FilterViewModel(data);
				this.ResetFields(this.selectedFilter);
				this.Loading = false;
			});
		}
		if (this.FilterId < 1) {
			this.addNew = true;
			this.ResetFields();
			this.Loading = false;
		}
	}

	ResetFields(filter?: FilterViewModel) {
		if (filter) {
			this.filterForm.setValue({
				filterName: filter.Name,
				minValue: filter.MinValue,
				maxValue: filter.MaxValue,
				defaultValue: filter.DefaultValue,
				hasEffect: filter.HasEffect,
				mandatory: filter.Mandatory,
				important: filter.Important,
				filterDisplayType: filter.DisplayTypeEnum,
				filterDisplayLevel: filter.DisplayLevelEnum,
				filterTypeId: filter.FilterType.Id
			});
		}
		else {
			this.filterForm.setValue({
				filterName: '',
				minValue: '0',
				maxValue: '0',
				defaultValue: '0',
				hasEffect: false,
				mandatory: false,
				important: false,
				filterDisplayType: 0,
				filterDisplayLevel: 0,
				filterTypeId: this.FilterTypesViewModel.FilterTypes[0].Id
			});
		}
	}

	OnSubmit() {
		console.log("submit clicked");
		var filter = new FilterViewModel();

		filter.Id = this.FilterId;
		filter.Name = this.filterForm.get("filterName").value;
		filter.MinValue = this.filterForm.get("minValue").value;
		filter.MaxValue = this.filterForm.get("maxValue").value;
		filter.DefaultValue = this.filterForm.get("defaultValue").value;
		filter.HasEffect = this.filterForm.get("hasEffect").value;
		filter.Mandatory = this.filterForm.get("mandatory").value;
		filter.Important = this.filterForm.get("important").value;

		var newFilterDisplayLevel: FilterDisplayLevelEnum = FilterDisplayLevelEnum[String(this.filterForm.get("filterDisplayLevel").value)];
		var newFilterDisplayType: FilterDisplayTypeEnum = FilterDisplayTypeEnum[String(this.filterForm.get("filterDisplayType").value)];
		var newFilterType: FilterTypeViewModel = this.FilterTypesViewModel.FilterTypes.find(f => { return f.Id == Number(this.filterForm.get("filterTypeId").value) });

		filter.DisplayLevelEnum = newFilterDisplayLevel;
		filter.DisplayTypeEnum = newFilterDisplayType;
		filter.FilterType = newFilterType;

		console.log("saving filter... ");
		this.filter.SaveFilter_Observable(filter).subscribe((data) => {
			console.log("Saved!");

			this.FilterId = data.Id;
			this.Refresh;
			this.FiltersRefresh.emit();
		}, error => {
			console.log("something went wrong... " + error);
		})
	}

	public Herlaad() {
		this.Refresh();
		this.FiltersRefresh.emit();
	}

	public Delete() {
		if (!confirm("Weet je zeker dat je deze filter wil verwijderen?")) {
			return;
		}

		console.log(this.FilterId);
		var deleteRequestModel = new DeleteRequestModel();
		deleteRequestModel.Id = this.FilterId;

		this.filter.DeleteFilter_Observable(this.FilterId).subscribe((data) => {

			console.log(data);
			this.FilterId = 0;

			this.FiltersRefresh.emit();

		});
	}

	//form controller getters
	get filterName() { return this.filterForm.get("filterName"); }

	//form validator
	checkValueRanges(control: AbstractControl): ValidationErrors | null {
		const maxValue: number = control.get("maxValue").value;
		const minValue: number = control.get("minValue").value;
		const defaultValue: number = control.get("defaultValue").value;

		if (maxValue < minValue) {
			return { 'boundaryError': true };
		}

		if (defaultValue > maxValue || defaultValue < minValue) {
			return { 'defaultValueError': true };
		}

		return null;
	}
}
