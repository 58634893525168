import * as _ from 'lodash';
import * as ng from 'angular';
import * as HelpersBackwards from '.././Helpers/Backwards';
import * as HelpersOverlay from '.././Helpers/Overlay';
import * as rxjs from 'rxjs';
import { Injectable, VERSION } from '@angular/core';


export interface IAngularEventEmitter<T> {
	Event: AngularEvent<T>;
	Emitter: AngularEmitter<T>;
}

export class AngularEventArg<T> {
	/**
	 * Een kopie van een object met daarin de status van de update.
	 */
	public get BezigState(): HelpersOverlay.BezigState {
		return _.cloneDeep(this.bezigState);
	}

	/**
	 * Een kopie van een object met daarin de data van de update.
	 */
	public get Data(): T {
		return _.cloneDeep(this.data);
	}

	private constructor(
		private readonly data: T,
		private readonly bezigState: HelpersOverlay.BezigState) {
	}

	/**
	 * Return an empty angular event argument.
	 * @param {boolean} isBusy Whether the action is still underway.
	 * @param {number} progress A number which will be clipped to 0 and 100 indicating the progress of the action.
	 */
	public static Void(isBusy: boolean, progress?: number): AngularEventArg<void> {
		const bezigState = HelpersOverlay.BezigState.Default();
		bezigState.ToonOverlay = isBusy;
		return new AngularEventArg<undefined>(undefined, bezigState);
	}

	/**
	 * Return an angular event argument.
	 * @param {T} data Data of the angular event's generic type.
	 * @param {boolean} isBusy Whether the action is still underway.
	 * @param {number} progress A number which will be clipped to 0 and 100 indicating the progress of the action.
	 */
	public static WithData<T>(data: T, isBusy: boolean, progress?: number): AngularEventArg<T> {
		const bezigState = HelpersOverlay.BezigState.Default();
		if (!(progress === undefined || progress === null)) {
			progress = Math.max(Math.min(progress, 100), 0);
			bezigState.Value = progress;
			bezigState.Mode = "determinate";
		}
		bezigState.ToonOverlay = isBusy;
		return new AngularEventArg<T>(data, bezigState);
	}
}

export class EventInfo
{
	constructor(public name: string)
	{

	}
}

export class AngularEvent<T> {
	private m_Registraties: number = 0;
	private static allEvents: { [eventName: string]: string } = {};
	private m_ChangeObservable: rxjs.Subject<number>;
	public get Name(): string {
		return this.name;
	}
	public get AantalLuisteraars(): rxjs.Observable<number> {
		return this.m_ChangeObservable;
	}

	private constructor(
		private readonly name: string,
		private readonly broadcast: rxjs.Observable<SubjectWrapper<T>>
	) {
		this.m_ChangeObservable = new rxjs.Subject<number>();
	}

	/**
	 * Creëer een nieuw angular event object met als voordeel dat:
		1. gecontroleerd wordt of je event uniek is
		2. het registreren van het event op een veilige wijze gebeurd.
		3. je als ontvanger weet wat voor type object er mee wordt verstuurd bij het broadcasten.
	 * @param {string} eventName The name of the event you are registering.
	 * @param {angular.IRootScopeService} rootScopeService Rootscope context waar vanuit het event wordt verzonden.
	 */
	public static Default<T>(
		eventName: string,
		rootScopeService: ng.IRootScopeService
	): IAngularEventEmitter<T> {
		let registerEventEmitterStack: string;
		if (!HelpersBackwards.wct_detect_ie()) {
			registerEventEmitterStack = new Error().stack.replace(/^Error/, "Going to register the event");
		} else {
			try {
				throw new Error();
			}
			catch (e) {
				registerEventEmitterStack = e.stack.replace(/^Error/, "Going to register the event");
			}
		}
		// Forceer uniekheid van event.
		if (eventName in AngularEvent.allEvents) {
			console.log(`%cPas op!\n%cEvent emitter met event naam "${eventName}" is al eens eerder geregistreerd, de call stack was destijds:\n\n ${AngularEvent.allEvents[eventName]}\n\nDe callstack is nu:\n\n${registerEventEmitterStack}\n\n%cVerwijder of hernoem één van de event emitters!`, "text-decoration: overline; color: red; font-size: 18pt", "color: black", "text-decoration: underline");
		} else {
			AngularEvent.allEvents[eventName] = registerEventEmitterStack;
		}

		var _broadcasts: rxjs.Subject<SubjectWrapper<T>> = <rxjs.Subject<SubjectWrapper<T>>>new rxjs.Subject<SubjectWrapper<T>>();
		var broadcasts: rxjs.Observable<SubjectWrapper<T>> = _broadcasts.asObservable();
		

		return {
			Event: new AngularEvent<T>(eventName, broadcasts),		
			Emitter: new AngularEmitter<T>(eventName, _broadcasts)
		};
	}

	public HeeftLuisteraars(): boolean {
		return this.m_Registraties > 0;
	}
	/**
	 * Handle registration of an event handler in a safe manner.
	 * @param {angular.IScope} receiverScope De scope waarop de handler zich bevindt.
	 * @param {Function} callbackFn Een methode die een event en data accepteerd als argumenten en die de door de event gecreëerde data handled.
	 */
	public RegisterHandler(
		receiverScope: ng.IScope,
		callbackFn: (event: EventInfo, data: T) => void): rxjs.Subscription {
		this.m_Registraties++;

		this.m_ChangeObservable.next(this.m_Registraties);

		var sub = this.broadcast.subscribe(res => {
			if (res != null) {
				let ev = new EventInfo('ThisEvent');
				callbackFn(ev, res.value);
			}
		});	

		if (receiverScope != null) {
			receiverScope.$on("$destroy", () => {
				this.m_Registraties--;
				this.m_ChangeObservable.next(this.m_Registraties);
				sub.unsubscribe();
			});
		}

		return sub;
	}
}

export class SubjectWrapper<T>
{
	constructor(public value: T) {
	}
}

export class AngularEmitter<T>{

	constructor(
		private readonly name: string,
		private readonly broadcasts: rxjs.Subject<SubjectWrapper<T>>
	) {
	}

	/**
	 * Broadcast an event with the given data.
	 * @param {T} data An object of type T.
	 */
	public BroadCast(data: T): void {
		this.broadcasts.next(new SubjectWrapper<T>(data));
	}
}
