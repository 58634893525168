/**
 * @ref WoonConnectViewModel/Fabrikanten/PrijslijstViewModel.cs
 */

 import * as DataContracts from "@datacontracts/PrijslijstViewModel";

 
import * as ProductsViewModel from "./ProductsViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.PrijslijstNewProductViewModel
	 */
	 

	export interface IPrijslijstNewProductViewModel {
		CategorieId: number;
		ProductVariantId: number;
		Filters: PrijslijstNewProductFilterViewModel[];
		ToJsonContract(): DataContracts.IPrijslijstNewProductViewModel;
	}

	export class PrijslijstNewProductViewModel {
		// property-Properties
		public get CategorieId(): number {
			return this._CategorieId;
		}
		public set CategorieId(newCategorieId: number) {
			this._CategorieId = newCategorieId;
		}

		public get ProductVariantId(): number {
			return this._ProductVariantId;
		}
		public set ProductVariantId(newProductVariantId: number) {
			this._ProductVariantId = newProductVariantId;
		}

		public get Filters(): PrijslijstNewProductFilterViewModel[] {
			return this._Filters;
		}
		public set Filters(newFilters: PrijslijstNewProductFilterViewModel[]) {
			this._Filters = newFilters;
		}

		// field-Properties

		// fields
		protected _CategorieId: number = 0;
		protected _ProductVariantId: number = 0;
		protected _Filters: PrijslijstNewProductFilterViewModel[] = [];

		constructor(data?: DataContracts.IPrijslijstNewProductViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPrijslijstNewProductViewModel): void {
			if(data) {
				// vul properties
				this._CategorieId = data.CategorieId;
				this._ProductVariantId = data.ProductVariantId;
				this._Filters = data.Filters ? data.Filters.map(prijslijstNewProductFilterViewModelItem => new PrijslijstNewProductFilterViewModel(prijslijstNewProductFilterViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPrijslijstNewProductViewModel {
			return {
				CategorieId: this.CategorieId,
				ProductVariantId: this.ProductVariantId,
				Filters: (this.Filters ? this.Filters.map(prijslijstNewProductFilterViewModelItem => new PrijslijstNewProductFilterViewModel(prijslijstNewProductFilterViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.PrijslijstNewProductFilterViewModel
	 */
	 

	export interface IPrijslijstNewProductFilterViewModel {
		FilterId: number;
		Value: string;
		ToJsonContract(): DataContracts.IPrijslijstNewProductFilterViewModel;
	}

	export class PrijslijstNewProductFilterViewModel {
		// property-Properties
		public get FilterId(): number {
			return this._FilterId;
		}
		public set FilterId(newFilterId: number) {
			this._FilterId = newFilterId;
		}

		public get Value(): string {
			return this._Value;
		}
		public set Value(newValue: string) {
			this._Value = newValue;
		}

		// field-Properties

		// fields
		protected _FilterId: number = 0;
		protected _Value: string = "";

		constructor(data?: DataContracts.IPrijslijstNewProductFilterViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPrijslijstNewProductFilterViewModel): void {
			if(data) {
				// vul properties
				this._FilterId = data.FilterId;
				this._Value = data.Value;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPrijslijstNewProductFilterViewModel {
			return {
				FilterId: this.FilterId,
				Value: this.Value
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.PrijsSchemasViewModel
	 */
	 

	export interface IPrijsSchemasViewModel {
		PrijsSchemas: PrijsSchemaViewModel[];
		ToJsonContract(): DataContracts.IPrijsSchemasViewModel;
	}

	export class PrijsSchemasViewModel {
		// property-Properties
		public get PrijsSchemas(): PrijsSchemaViewModel[] {
			return this._PrijsSchemas;
		}
		public set PrijsSchemas(newPrijsSchemas: PrijsSchemaViewModel[]) {
			this._PrijsSchemas = newPrijsSchemas;
		}

		// field-Properties

		// fields
		protected _PrijsSchemas: PrijsSchemaViewModel[] = [];

		constructor(data?: DataContracts.IPrijsSchemasViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPrijsSchemasViewModel): void {
			if(data) {
				// vul properties
				this._PrijsSchemas = data.PrijsSchemas ? data.PrijsSchemas.map(prijsSchemaViewModelItem => new PrijsSchemaViewModel(prijsSchemaViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPrijsSchemasViewModel {
			return {
				PrijsSchemas: (this.PrijsSchemas ? this.PrijsSchemas.map(prijsSchemaViewModelItem => new PrijsSchemaViewModel(prijsSchemaViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.PrijsSchemaViewModel
	 */
	 

	export interface IPrijsSchemaViewModel {
		Id: number;
		Name: string;
		UserId: string;
		Aluminium: number;
		Poedercoaten: number;
		PoedercoatenBinnen: number;
		PoedercoatenBuiten: number;
		Anodiseren: number;
		AnodiserenBinnen: number;
		AnodiserenBuiten: number;
		ISORT62: number;
		ISORT72Reflex: number;
		ISORT72HI: number;
		ISORT82HI: number;
		ISOAA5110: number;
		ISOAA51102X: number;
		ISOAA3572: number;
		ISOAA35722X: number;
		ISOAA35723X: number;
		Boren: number;
		BiColorToeslag: number;
		Tapen: number;
		AA3110: number;
		Accessoires: number;
		Raambeslag: number;
		Deurbeslag: number;
		Schuifbeslag: number;
		ToJsonContract(): DataContracts.IPrijsSchemaViewModel;
	}

	export class PrijsSchemaViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get UserId(): string {
			return this._UserId;
		}
		public set UserId(newUserId: string) {
			this._UserId = newUserId;
		}

		public get Aluminium(): number {
			return this._Aluminium;
		}
		public set Aluminium(newAluminium: number) {
			this._Aluminium = newAluminium;
		}

		public get Poedercoaten(): number {
			return this._Poedercoaten;
		}
		public set Poedercoaten(newPoedercoaten: number) {
			this._Poedercoaten = newPoedercoaten;
		}

		public get PoedercoatenBinnen(): number {
			return this._PoedercoatenBinnen;
		}
		public set PoedercoatenBinnen(newPoedercoatenBinnen: number) {
			this._PoedercoatenBinnen = newPoedercoatenBinnen;
		}

		public get PoedercoatenBuiten(): number {
			return this._PoedercoatenBuiten;
		}
		public set PoedercoatenBuiten(newPoedercoatenBuiten: number) {
			this._PoedercoatenBuiten = newPoedercoatenBuiten;
		}

		public get Anodiseren(): number {
			return this._Anodiseren;
		}
		public set Anodiseren(newAnodiseren: number) {
			this._Anodiseren = newAnodiseren;
		}

		public get AnodiserenBinnen(): number {
			return this._AnodiserenBinnen;
		}
		public set AnodiserenBinnen(newAnodiserenBinnen: number) {
			this._AnodiserenBinnen = newAnodiserenBinnen;
		}

		public get AnodiserenBuiten(): number {
			return this._AnodiserenBuiten;
		}
		public set AnodiserenBuiten(newAnodiserenBuiten: number) {
			this._AnodiserenBuiten = newAnodiserenBuiten;
		}

		public get ISORT62(): number {
			return this._ISORT62;
		}
		public set ISORT62(newISORT62: number) {
			this._ISORT62 = newISORT62;
		}

		public get ISORT72Reflex(): number {
			return this._ISORT72Reflex;
		}
		public set ISORT72Reflex(newISORT72Reflex: number) {
			this._ISORT72Reflex = newISORT72Reflex;
		}

		public get ISORT72HI(): number {
			return this._ISORT72HI;
		}
		public set ISORT72HI(newISORT72HI: number) {
			this._ISORT72HI = newISORT72HI;
		}

		public get ISORT82HI(): number {
			return this._ISORT82HI;
		}
		public set ISORT82HI(newISORT82HI: number) {
			this._ISORT82HI = newISORT82HI;
		}

		public get ISOAA5110(): number {
			return this._ISOAA5110;
		}
		public set ISOAA5110(newISOAA5110: number) {
			this._ISOAA5110 = newISOAA5110;
		}

		public get ISOAA51102X(): number {
			return this._ISOAA51102X;
		}
		public set ISOAA51102X(newISOAA51102X: number) {
			this._ISOAA51102X = newISOAA51102X;
		}

		public get ISOAA3572(): number {
			return this._ISOAA3572;
		}
		public set ISOAA3572(newISOAA3572: number) {
			this._ISOAA3572 = newISOAA3572;
		}

		public get ISOAA35722X(): number {
			return this._ISOAA35722X;
		}
		public set ISOAA35722X(newISOAA35722X: number) {
			this._ISOAA35722X = newISOAA35722X;
		}

		public get ISOAA35723X(): number {
			return this._ISOAA35723X;
		}
		public set ISOAA35723X(newISOAA35723X: number) {
			this._ISOAA35723X = newISOAA35723X;
		}

		public get Boren(): number {
			return this._Boren;
		}
		public set Boren(newBoren: number) {
			this._Boren = newBoren;
    }

    public get Tapen(): number {
      return this._Tapen;
    }
    public set Tapen(newTapen: number) {
      this._Tapen = newTapen;
    }

		public get BiColorToeslag(): number {
			return this._BiColorToeslag;
		}
		public set BiColorToeslag(newBiColorToeslag: number) {
			this._BiColorToeslag = newBiColorToeslag;
		}

		public get AA3110(): number {
			return this._AA3110;
		}
		public set AA3110(newAA3110: number) {
			this._AA3110 = newAA3110;
		}

		public get Accessoires(): number {
			return this._Accessoires;
		}
		public set Accessoires(newAccessoires: number) {
			this._Accessoires = newAccessoires;
		}

		public get Raambeslag(): number {
			return this._Raambeslag;
		}
		public set Raambeslag(newRaambeslag: number) {
			this._Raambeslag = newRaambeslag;
		}

		public get Deurbeslag(): number {
			return this._Deurbeslag;
		}
		public set Deurbeslag(newDeurbeslag: number) {
			this._Deurbeslag = newDeurbeslag;
		}

		public get Schuifbeslag(): number {
			return this._Schuifbeslag;
		}
		public set Schuifbeslag(newSchuifbeslag: number) {
			this._Schuifbeslag = newSchuifbeslag;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _UserId: string = "";
		protected _Aluminium: number = 0;
		protected _Poedercoaten: number = 0;
		protected _PoedercoatenBinnen: number = 0;
		protected _PoedercoatenBuiten: number = 0;
		protected _Anodiseren: number = 0;
		protected _AnodiserenBinnen: number = 0;
		protected _AnodiserenBuiten: number = 0;
		protected _ISORT62: number = 0;
		protected _ISORT72Reflex: number = 0;
		protected _ISORT72HI: number = 0;
		protected _ISORT82HI: number = 0;
		protected _ISOAA5110: number = 0;
		protected _ISOAA51102X: number = 0;
		protected _ISOAA3572: number = 0;
		protected _ISOAA35722X: number = 0;
		protected _ISOAA35723X: number = 0;
		protected _Boren: number = 0;
		protected _Tapen: number = 0;
		protected _BiColorToeslag: number = 0;
		protected _AA3110: number = 0;
		protected _Accessoires: number = 0;
		protected _Raambeslag: number = 0;
		protected _Deurbeslag: number = 0;
		protected _Schuifbeslag: number = 0;

		constructor(data?: DataContracts.IPrijsSchemaViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPrijsSchemaViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._UserId = data.UserId;
				this._Aluminium = data.Aluminium;
				this._Poedercoaten = data.Poedercoaten;
				this._PoedercoatenBinnen = data.PoedercoatenBinnen;
				this._PoedercoatenBuiten = data.PoedercoatenBuiten;
				this._Anodiseren = data.Anodiseren;
				this._AnodiserenBinnen = data.AnodiserenBinnen;
				this._AnodiserenBuiten = data.AnodiserenBuiten;
				this._ISORT62 = data.ISORT62;
				this._ISORT72Reflex = data.ISORT72Reflex;
				this._ISORT72HI = data.ISORT72HI;
				this._ISORT82HI = data.ISORT82HI;
				this._ISOAA5110 = data.ISOAA5110;
				this._ISOAA51102X = data.ISOAA51102X;
				this._ISOAA3572 = data.ISOAA3572;
				this._ISOAA35722X = data.ISOAA35722X;
				this._ISOAA35723X = data.ISOAA35723X;
        this._Boren = data.Boren;
        this._Tapen = data.Tapen;
				this._BiColorToeslag = data.BiColorToeslag;
				this._AA3110 = data.AA3110;
				this._Accessoires = data.Accessoires;
				this._Raambeslag = data.Raambeslag;
				this._Deurbeslag = data.Deurbeslag;
				this._Schuifbeslag = data.Schuifbeslag;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPrijsSchemaViewModel {
			return {
				Id: this.Id,
				Name: this.Name,
				UserId: this.UserId,
				Aluminium: this.Aluminium,
				Poedercoaten: this.Poedercoaten,
				PoedercoatenBinnen: this.PoedercoatenBinnen,
				PoedercoatenBuiten: this.PoedercoatenBuiten,
				Anodiseren: this.Anodiseren,
				AnodiserenBinnen: this.AnodiserenBinnen,
				AnodiserenBuiten: this.AnodiserenBuiten,
				ISORT62: this.ISORT62,
				ISORT72Reflex: this.ISORT72Reflex,
				ISORT72HI: this.ISORT72HI,
				ISORT82HI: this.ISORT82HI,
				ISOAA5110: this.ISOAA5110,
				ISOAA51102X: this.ISOAA51102X,
				ISOAA3572: this.ISOAA3572,
				ISOAA35722X: this.ISOAA35722X,
				ISOAA35723X: this.ISOAA35723X,
				Boren: this.Boren,
				BiColorToeslag: this.BiColorToeslag,
				Tapen: this.Tapen,
				AA3110: this.AA3110,
				Accessoires: this.Accessoires,
				Raambeslag: this.Raambeslag,
				Deurbeslag: this.Deurbeslag,
				Schuifbeslag: this.Schuifbeslag
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.CategoriesViewModel
	 */
	 

	export interface ICategoriesViewModel {
		CategorieViewModels: CategorieViewModel[];
		ExcelName: string;
		ToJsonContract(): DataContracts.ICategoriesViewModel;
	}

	export class CategoriesViewModel {
		// property-Properties
		public get CategorieViewModels(): CategorieViewModel[] {
			return this._CategorieViewModels;
		}
		public set CategorieViewModels(newCategorieViewModels: CategorieViewModel[]) {
			this._CategorieViewModels = newCategorieViewModels;
		}

		public get ExcelName(): string {
			return this._ExcelName;
		}
		public set ExcelName(newExcelName: string) {
			this._ExcelName = newExcelName;
		}

		// field-Properties

		// fields
		protected _CategorieViewModels: CategorieViewModel[] = [];
		protected _ExcelName: string = "";

		constructor(data?: DataContracts.ICategoriesViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICategoriesViewModel): void {
			if(data) {
				// vul properties
				this._CategorieViewModels = data.CategorieViewModels ? data.CategorieViewModels.map(categorieViewModelItem => new CategorieViewModel(categorieViewModelItem)): [];
				this._ExcelName = data.ExcelName;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ICategoriesViewModel {
			return {
				CategorieViewModels: (this.CategorieViewModels ? this.CategorieViewModels.map(categorieViewModelItem => new CategorieViewModel(categorieViewModelItem).ToJsonContract()): []),
				ExcelName: this.ExcelName
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.CategorieViewModel
	 */
	 

	export interface ICategorieViewModel {
		Id: number;
		Name: string;
		Products: ProductsViewModel.IProductViewModel;
		ToJsonContract(): DataContracts.ICategorieViewModel;
	}

	export class CategorieViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Products(): ProductsViewModel.IProductViewModel {
			return this._Products;
		}
		public set Products(newProducts: ProductsViewModel.IProductViewModel) {
			this._Products = newProducts;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _Products: ProductsViewModel.IProductViewModel = new ProductsViewModel.ProductViewModel(undefined);

		constructor(data?: DataContracts.ICategorieViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICategorieViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._Products = data.Products ? new ProductsViewModel.ProductViewModel(data.Products): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ICategorieViewModel {
			return {
				Id: this.Id,
				Name: this.Name,
				Products: (this.Products ? new ProductsViewModel.ProductViewModel(this.Products).ToJsonContract(): this.Products)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.RequestCategorieViewModel
	 */
	 

	export interface IRequestCategorieViewModel {
		ProductId: number;
		PrijsSchemaId: number;
		ToJsonContract(): DataContracts.IRequestCategorieViewModel;
	}

	export class RequestCategorieViewModel {
		// property-Properties
		public get ProductId(): number {
			return this._ProductId;
		}
		public set ProductId(newProductId: number) {
			this._ProductId = newProductId;
		}

		public get PrijsSchemaId(): number {
			return this._PrijsSchemaId;
		}
		public set PrijsSchemaId(newPrijsSchemaId: number) {
			this._PrijsSchemaId = newPrijsSchemaId;
		}

		// field-Properties

		// fields
		protected _ProductId: number = 0;
		protected _PrijsSchemaId: number = 0;

		constructor(data?: DataContracts.IRequestCategorieViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IRequestCategorieViewModel): void {
			if(data) {
				// vul properties
				this._ProductId = data.ProductId;
				this._PrijsSchemaId = data.PrijsSchemaId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IRequestCategorieViewModel {
			return {
				ProductId: this.ProductId,
				PrijsSchemaId: this.PrijsSchemaId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ExcelsViewModel
	 */
	 

	export interface IExcelsViewModel {
		ActiveExcel: ExcelViewModel;
		OldExcels: ExcelViewModel[];
		BlankAnod: number;
		ZwartAnod: number;
		ToJsonContract(): DataContracts.IExcelsViewModel;
	}

	export class ExcelsViewModel {
		// property-Properties
		public get ActiveExcel(): ExcelViewModel {
			return this._ActiveExcel;
		}
		public set ActiveExcel(newActiveExcel: ExcelViewModel) {
			this._ActiveExcel = newActiveExcel;
		}

		public get OldExcels(): ExcelViewModel[] {
			return this._OldExcels;
		}
		public set OldExcels(newOldExcels: ExcelViewModel[]) {
			this._OldExcels = newOldExcels;
		}

		public get BlankAnod(): number {
			return this._BlankAnod;
		}
		public set BlankAnod(newBlankAnod: number) {
			this._BlankAnod = newBlankAnod;
		}

		public get ZwartAnod(): number {
			return this._ZwartAnod;
		}
		public set ZwartAnod(newZwartAnod: number) {
			this._ZwartAnod = newZwartAnod;
		}

		// field-Properties

		// fields
		protected _ActiveExcel: ExcelViewModel = new ExcelViewModel(undefined);
		protected _OldExcels: ExcelViewModel[] = [];
		protected _BlankAnod: number = 0;
		protected _ZwartAnod: number = 0;

		constructor(data?: DataContracts.IExcelsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IExcelsViewModel): void {
			if(data) {
				// vul properties
				this._ActiveExcel = data.ActiveExcel ? new ExcelViewModel(data.ActiveExcel): undefined;
				this._OldExcels = data.OldExcels ? data.OldExcels.map(excelViewModelItem => new ExcelViewModel(excelViewModelItem)): [];
				this._BlankAnod = data.BlankAnod;
				this._ZwartAnod = data.ZwartAnod;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IExcelsViewModel {
			return {
				ActiveExcel: (this.ActiveExcel ? new ExcelViewModel(this.ActiveExcel).ToJsonContract(): this.ActiveExcel),
				OldExcels: (this.OldExcels ? this.OldExcels.map(excelViewModelItem => new ExcelViewModel(excelViewModelItem).ToJsonContract()): []),
				BlankAnod: this.BlankAnod,
				ZwartAnod: this.ZwartAnod
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.PlaatjesViewModel
	 */
	 

	export interface IPlaatjesViewModel {
		Plaatjes: PlaatjeViewModel[];
		ToJsonContract(): DataContracts.IPlaatjesViewModel;
	}

	export class PlaatjesViewModel {
		// property-Properties
		public get Plaatjes(): PlaatjeViewModel[] {
			return this._Plaatjes;
		}
		public set Plaatjes(newPlaatjes: PlaatjeViewModel[]) {
			this._Plaatjes = newPlaatjes;
		}

		// field-Properties

		// fields
		protected _Plaatjes: PlaatjeViewModel[] = [];

		constructor(data?: DataContracts.IPlaatjesViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPlaatjesViewModel): void {
			if(data) {
				// vul properties
				this._Plaatjes = data.Plaatjes ? data.Plaatjes.map(plaatjeViewModelItem => new PlaatjeViewModel(plaatjeViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPlaatjesViewModel {
			return {
				Plaatjes: (this.Plaatjes ? this.Plaatjes.map(plaatjeViewModelItem => new PlaatjeViewModel(plaatjeViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.PlaatjeViewModel
	 */
	 

	export interface IPlaatjeViewModel {
		Name: string;
		NameNoExtension: string;
		InUse: boolean;
		ToJsonContract(): DataContracts.IPlaatjeViewModel;
	}

	export class PlaatjeViewModel {
		// property-Properties
		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get NameNoExtension(): string {
			return this._NameNoExtension;
		}
		public set NameNoExtension(newNameNoExtension: string) {
			this._NameNoExtension = newNameNoExtension;
		}

		public get InUse(): boolean {
			return this._InUse;
		}
		public set InUse(newInUse: boolean) {
			this._InUse = newInUse;
		}

		// field-Properties

		// fields
		protected _Name: string = "";
		protected _NameNoExtension: string = "";
		protected _InUse: boolean = false;

		constructor(data?: DataContracts.IPlaatjeViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPlaatjeViewModel): void {
			if(data) {
				// vul properties
				this._Name = data.Name;
				this._NameNoExtension = data.NameNoExtension;
				this._InUse = data.InUse;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPlaatjeViewModel {
			return {
				Name: this.Name,
				NameNoExtension: this.NameNoExtension,
				InUse: this.InUse
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ExcelViewModel
	 */
	 

	export interface IExcelViewModel {
		Name: string;
		NameClean: string;
		RelativePath: string;
		DateTime: string;
		Active: boolean;
		ToJsonContract(): DataContracts.IExcelViewModel;
	}

	export class ExcelViewModel {
		// property-Properties
		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get NameClean(): string {
			return this._NameClean;
		}
		public set NameClean(newNameClean: string) {
			this._NameClean = newNameClean;
		}

		public get RelativePath(): string {
			return this._RelativePath;
		}
		public set RelativePath(newRelativePath: string) {
			this._RelativePath = newRelativePath;
		}

		public get DateTime(): string {
			return this._DateTime;
		}
		public set DateTime(newDateTime: string) {
			this._DateTime = newDateTime;
		}

		public get Active(): boolean {
			return this._Active;
		}
		public set Active(newActive: boolean) {
			this._Active = newActive;
		}

		// field-Properties

		// fields
		protected _Name: string = "";
		protected _NameClean: string = "";
		protected _RelativePath: string = "";
		protected _DateTime: string = "";
		protected _Active: boolean = false;

		constructor(data?: DataContracts.IExcelViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IExcelViewModel): void {
			if(data) {
				// vul properties
				this._Name = data.Name;
				this._NameClean = data.NameClean;
				this._RelativePath = data.RelativePath;
				this._DateTime = data.DateTime;
				this._Active = data.Active;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IExcelViewModel {
			return {
				Name: this.Name,
				NameClean: this.NameClean,
				RelativePath: this.RelativePath,
				DateTime: this.DateTime,
				Active: this.Active
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.UploadViewModel
	 */
	 

	export interface IUploadViewModel {
		Name: string;
		File: string;
		ToJsonContract(): DataContracts.IUploadViewModel;
	}

	export class UploadViewModel {
		// property-Properties
		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get File(): string {
			return this._File;
		}
		public set File(newFile: string) {
			this._File = newFile;
		}

		// field-Properties

		// fields
		protected _Name: string = "";
		protected _File: string = "";

		constructor(data?: DataContracts.IUploadViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUploadViewModel): void {
			if(data) {
				// vul properties
				this._Name = data.Name;
				this._File = data.File;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUploadViewModel {
			return {
				Name: this.Name,
				File: this.File
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.VoorwaardenViewModel
	 */
	 

	export interface IVoorwaardenViewModel {
		HTML: string;
		FileName: string;
		FileNameImage: string;
		ToJsonContract(): DataContracts.IVoorwaardenViewModel;
	}

	export class VoorwaardenViewModel {
		// property-Properties
		public get HTML(): string {
			return this._HTML;
		}
		public set HTML(newHTML: string) {
			this._HTML = newHTML;
		}

		public get FileName(): string {
			return this._FileName;
		}
		public set FileName(newFileName: string) {
			this._FileName = newFileName;
		}

		public get FileNameImage(): string {
			return this._FileNameImage;
		}
		public set FileNameImage(newFileNameImage: string) {
			this._FileNameImage = newFileNameImage;
		}

		// field-Properties

		// fields
		protected _HTML: string = "";
		protected _FileName: string = "";
		protected _FileNameImage: string = "";

		constructor(data?: DataContracts.IVoorwaardenViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IVoorwaardenViewModel): void {
			if(data) {
				// vul properties
				this._HTML = data.HTML;
				this._FileName = data.FileName;
				this._FileNameImage = data.FileNameImage;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IVoorwaardenViewModel {
			return {
				HTML: this.HTML,
				FileName: this.FileName,
				FileNameImage: this.FileNameImage
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.InstructieViewModel
	 */
	 

	export interface IInstructieViewModel {
		HTML: string;
		FileName: string;
		FileNameImage: string;
		ToJsonContract(): DataContracts.IInstructieViewModel;
	}

	export class InstructieViewModel {
		// property-Properties
		public get HTML(): string {
			return this._HTML;
		}
		public set HTML(newHTML: string) {
			this._HTML = newHTML;
		}

		public get FileName(): string {
			return this._FileName;
		}
		public set FileName(newFileName: string) {
			this._FileName = newFileName;
		}

		public get FileNameImage(): string {
			return this._FileNameImage;
		}
		public set FileNameImage(newFileNameImage: string) {
			this._FileNameImage = newFileNameImage;
		}

		// field-Properties

		// fields
		protected _HTML: string = "";
		protected _FileName: string = "";
		protected _FileNameImage: string = "";

		constructor(data?: DataContracts.IInstructieViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IInstructieViewModel): void {
			if(data) {
				// vul properties
				this._HTML = data.HTML;
				this._FileName = data.FileName;
				this._FileNameImage = data.FileNameImage;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IInstructieViewModel {
			return {
				HTML: this.HTML,
				FileName: this.FileName,
				FileNameImage: this.FileNameImage
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.DownloadExcelViewModel
	 */
	 

	export interface IDownloadExcelViewModel {
		Path: string;
		ToJsonContract(): DataContracts.IDownloadExcelViewModel;
	}

	export class DownloadExcelViewModel {
		// property-Properties
		public get Path(): string {
			return this._Path;
		}
		public set Path(newPath: string) {
			this._Path = newPath;
		}

		// field-Properties

		// fields
		protected _Path: string = "";

		constructor(data?: DataContracts.IDownloadExcelViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDownloadExcelViewModel): void {
			if(data) {
				// vul properties
				this._Path = data.Path;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDownloadExcelViewModel {
			return {
				Path: this.Path
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FabUserViewModel
	 */
	 

	export interface IFabUserViewModel {
		UserName: string;
		Password: string;
		TokenValue: string;
		VerifyValue: string;
		Rol: string;
		ToJsonContract(): DataContracts.IFabUserViewModel;
	}

	export class FabUserViewModel {
		// property-Properties
		public get UserName(): string {
			return this._UserName;
		}
		public set UserName(newUserName: string) {
			this._UserName = newUserName;
		}

		public get Password(): string {
			return this._Password;
		}
		public set Password(newPassword: string) {
			this._Password = newPassword;
		}

		public get TokenValue(): string {
			return this._TokenValue;
		}
		public set TokenValue(newTokenValue: string) {
			this._TokenValue = newTokenValue;
		}

		public get VerifyValue(): string {
			return this._VerifyValue;
		}
		public set VerifyValue(newVerifyValue: string) {
			this._VerifyValue = newVerifyValue;
		}

		public get Rol(): string {
			return this._Rol;
		}
		public set Rol(newRol: string) {
			this._Rol = newRol;
		}

		// field-Properties

		// fields
		protected _UserName: string = "";
		protected _Password: string = "";
		protected _TokenValue: string = "";
		protected _VerifyValue: string = "";
		protected _Rol: string = "";

		constructor(data?: DataContracts.IFabUserViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabUserViewModel): void {
			if(data) {
				// vul properties
				this._UserName = data.UserName;
				this._Password = data.Password;
				this._TokenValue = data.TokenValue;
				this._VerifyValue = data.VerifyValue;
				this._Rol = data.Rol;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabUserViewModel {
			return {
				UserName: this.UserName,
				Password: this.Password,
				TokenValue: this.TokenValue,
				VerifyValue: this.VerifyValue,
				Rol: this.Rol
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.AttachRequestModel
	 */
	 

	export interface IAttachRequestModel {
		UserName: string;
		Password: string;
		ToJsonContract(): DataContracts.IAttachRequestModel;
	}

	export class AttachRequestModel {
		// property-Properties
		public get UserName(): string {
			return this._UserName;
		}
		public set UserName(newUserName: string) {
			this._UserName = newUserName;
		}

		public get Password(): string {
			return this._Password;
		}
		public set Password(newPassword: string) {
			this._Password = newPassword;
		}

		// field-Properties

		// fields
		protected _UserName: string = "";
		protected _Password: string = "";

		constructor(data?: DataContracts.IAttachRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAttachRequestModel): void {
			if(data) {
				// vul properties
				this._UserName = data.UserName;
				this._Password = data.Password;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAttachRequestModel {
			return {
				UserName: this.UserName,
				Password: this.Password
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.AttachResponseModel
	 */
	 

	export interface IAttachResponseModel {
		URL: string;
		AlreadyAttached: boolean;
		ToJsonContract(): DataContracts.IAttachResponseModel;
	}

	export class AttachResponseModel {
		// property-Properties
		public get URL(): string {
			return this._URL;
		}
		public set URL(newURL: string) {
			this._URL = newURL;
		}

		public get AlreadyAttached(): boolean {
			return this._AlreadyAttached;
		}
		public set AlreadyAttached(newAlreadyAttached: boolean) {
			this._AlreadyAttached = newAlreadyAttached;
		}

		// field-Properties

		// fields
		protected _URL: string = "";
		protected _AlreadyAttached: boolean = false;

		constructor(data?: DataContracts.IAttachResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAttachResponseModel): void {
			if(data) {
				// vul properties
				this._URL = data.URL;
				this._AlreadyAttached = data.AlreadyAttached;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAttachResponseModel {
			return {
				URL: this.URL,
				AlreadyAttached: this.AlreadyAttached
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.CookieViewModel
	 */
	 

	export interface ICookieViewModel {
		TokenName: string;
		TokenValue: string;
		VerifyName: string;
		VerifyValue: string;
		ToJsonContract(): DataContracts.ICookieViewModel;
	}

	export class CookieViewModel {
		// property-Properties
		public get TokenName(): string {
			return this._TokenName;
		}
		public set TokenName(newTokenName: string) {
			this._TokenName = newTokenName;
		}

		public get TokenValue(): string {
			return this._TokenValue;
		}
		public set TokenValue(newTokenValue: string) {
			this._TokenValue = newTokenValue;
		}

		public get VerifyName(): string {
			return this._VerifyName;
		}
		public set VerifyName(newVerifyName: string) {
			this._VerifyName = newVerifyName;
		}

		public get VerifyValue(): string {
			return this._VerifyValue;
		}
		public set VerifyValue(newVerifyValue: string) {
			this._VerifyValue = newVerifyValue;
		}

		// field-Properties

		// fields
		protected _TokenName: string = "";
		protected _TokenValue: string = "";
		protected _VerifyName: string = "";
		protected _VerifyValue: string = "";

		constructor(data?: DataContracts.ICookieViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICookieViewModel): void {
			if(data) {
				// vul properties
				this._TokenName = data.TokenName;
				this._TokenValue = data.TokenValue;
				this._VerifyName = data.VerifyName;
				this._VerifyValue = data.VerifyValue;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ICookieViewModel {
			return {
				TokenName: this.TokenName,
				TokenValue: this.TokenValue,
				VerifyName: this.VerifyName,
				VerifyValue: this.VerifyValue
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.InfoViewModel
	 */
	 

	export interface IInfoViewModel {
		Message: string;
		Email: string;
		ToJsonContract(): DataContracts.IInfoViewModel;
	}

	export class InfoViewModel {
		// property-Properties
		public get Message(): string {
			return this._Message;
		}
		public set Message(newMessage: string) {
			this._Message = newMessage;
		}

		public get Email(): string {
			return this._Email;
		}
		public set Email(newEmail: string) {
			this._Email = newEmail;
		}

		// field-Properties

		// fields
		protected _Message: string = "";
		protected _Email: string = "";

		constructor(data?: DataContracts.IInfoViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IInfoViewModel): void {
			if(data) {
				// vul properties
				this._Message = data.Message;
				this._Email = data.Email;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IInfoViewModel {
			return {
				Message: this.Message,
				Email: this.Email
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FabUserLoginViewModel
	 */
	 

	export interface IFabUserLoginViewModel {
		Response: string;
		Success: boolean;
		ToJsonContract(): DataContracts.IFabUserLoginViewModel;
	}

	export class FabUserLoginViewModel {
		// property-Properties
		public get Response(): string {
			return this._Response;
		}
		public set Response(newResponse: string) {
			this._Response = newResponse;
		}

		public get Success(): boolean {
			return this._Success;
		}
		public set Success(newSuccess: boolean) {
			this._Success = newSuccess;
		}

		// field-Properties

		// fields
		protected _Response: string = "";
		protected _Success: boolean = false;

		constructor(data?: DataContracts.IFabUserLoginViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabUserLoginViewModel): void {
			if(data) {
				// vul properties
				this._Response = data.Response;
				this._Success = data.Success;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabUserLoginViewModel {
			return {
				Response: this.Response,
				Success: this.Success
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FabUsersViewModel
	 */
	 

	export interface IFabUsersViewModel {
		Users: FabUserViewModel[];
		ToJsonContract(): DataContracts.IFabUsersViewModel;
	}

	export class FabUsersViewModel {
		// property-Properties
		public get Users(): FabUserViewModel[] {
			return this._Users;
		}
		public set Users(newUsers: FabUserViewModel[]) {
			this._Users = newUsers;
		}

		// field-Properties

		// fields
		protected _Users: FabUserViewModel[] = [];

		constructor(data?: DataContracts.IFabUsersViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabUsersViewModel): void {
			if(data) {
				// vul properties
				this._Users = data.Users ? data.Users.map(fabUserViewModelItem => new FabUserViewModel(fabUserViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabUsersViewModel {
			return {
				Users: (this.Users ? this.Users.map(fabUserViewModelItem => new FabUserViewModel(fabUserViewModelItem).ToJsonContract()): [])
			}
		}
	}
