import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: 'wct-activatie-popup',
	templateUrl: './WctActivatiePopup.html',
	styleUrls: ['./WctActivatiePopup.scss']
})
export class WctActivatiePopupComponent {
	public WebsiteBasisUrlZonderProtocol: string = "";
	public oorzaak: string;

	constructor(
		private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) private data: any
	) {
		'ngInject';
		this.WebsiteBasisUrlZonderProtocol = window.location.host;
		this.oorzaak = data.oorzaak;
	}

	public reloadWebsite(): void {
		window.location.reload();
	}

	closeDialog(): void {
		this.dialog.closeAll();
	}
}
