/// <reference path='../consumerinterfaces/PrijslijstController.ts' />
/**
 * @ref WoonConnectApiController/Fabrikanten/PrijslijstController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/PrijslijstController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as PrijslijstViewModel from "@datacontracts/PrijslijstViewModel";
import * as request from "../../../helpers/IRequestHandler";





	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class PrijslijstConsumer implements Interface.IPrijslijstConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public GetPrijsSchemas_Observable(): Observable<PrijslijstViewModel.IPrijsSchemasViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetPrijsSchemas`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.IPrijsSchemasViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetPrijsSchemas(): ng.IPromise<PrijslijstViewModel.IPrijsSchemasViewModel> {
				let obs = this.GetPrijsSchemas_Observable();
				let promise = new Promise<PrijslijstViewModel.IPrijsSchemasViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SavePrijsSchema_Observable(pPrijsSchemaViewModelModel: PrijslijstViewModel.IPrijsSchemaViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IPrijsSchemaViewModel}): Observable<void> {
				const pPrijsSchemaViewModel = pPrijsSchemaViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pPrijsSchemaViewModel;
				let apiURL = `api/Fabrikanten/SavePrijsSchema/${pPrijsSchemaViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SavePrijsSchema(pPrijsSchemaViewModelModel: PrijslijstViewModel.IPrijsSchemaViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IPrijsSchemaViewModel}): ng.IPromise<void> {
				let obs = this.SavePrijsSchema_Observable(pPrijsSchemaViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DeletePrijsSchema_Observable(pPrijsSchemaViewModelModel: PrijslijstViewModel.IPrijsSchemaViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IPrijsSchemaViewModel}): Observable<void> {
				const pPrijsSchemaViewModel = pPrijsSchemaViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pPrijsSchemaViewModel;
				let apiURL = `api/Fabrikanten/DeletePrijsSchema/${pPrijsSchemaViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DeletePrijsSchema(pPrijsSchemaViewModelModel: PrijslijstViewModel.IPrijsSchemaViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IPrijsSchemaViewModel}): ng.IPromise<void> {
				let obs = this.DeletePrijsSchema_Observable(pPrijsSchemaViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetCategories_Observable(pPrijsSchemaId: number): Observable<PrijslijstViewModel.ICategoriesViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = pPrijsSchemaId;
				let apiURL = `api/Fabrikanten/GetCategories`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.ICategoriesViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetCategories(pPrijsSchemaId: number): ng.IPromise<PrijslijstViewModel.ICategoriesViewModel> {
				let obs = this.GetCategories_Observable(pPrijsSchemaId);
				let promise = new Promise<PrijslijstViewModel.ICategoriesViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetExcelDownload_Observable(pRequestCategorieViewModelModel: PrijslijstViewModel.IRequestCategorieViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IRequestCategorieViewModel}): Observable<PrijslijstViewModel.IDownloadExcelViewModel> {
				const pRequestCategorieViewModel = pRequestCategorieViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pRequestCategorieViewModel;
				let apiURL = `api/Fabrikanten/GetExcelDownload/${pRequestCategorieViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.IDownloadExcelViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetExcelDownload(pRequestCategorieViewModelModel: PrijslijstViewModel.IRequestCategorieViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IRequestCategorieViewModel}): ng.IPromise<PrijslijstViewModel.IDownloadExcelViewModel> {
				let obs = this.GetExcelDownload_Observable(pRequestCategorieViewModelModel);
				let promise = new Promise<PrijslijstViewModel.IDownloadExcelViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetExcelsViewModel_Observable(): Observable<PrijslijstViewModel.IExcelsViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetExcelsViewModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.IExcelsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetExcelsViewModel(): ng.IPromise<PrijslijstViewModel.IExcelsViewModel> {
				let obs = this.GetExcelsViewModel_Observable();
				let promise = new Promise<PrijslijstViewModel.IExcelsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetPlaatjesViewModel_Observable(): Observable<PrijslijstViewModel.IPlaatjesViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetPlaatjesViewModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.IPlaatjesViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetPlaatjesViewModel(): ng.IPromise<PrijslijstViewModel.IPlaatjesViewModel> {
				let obs = this.GetPlaatjesViewModel_Observable();
				let promise = new Promise<PrijslijstViewModel.IPlaatjesViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetVoorwaardenViewModel_Observable(): Observable<PrijslijstViewModel.IVoorwaardenViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetVoorwaardenViewModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.IVoorwaardenViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetVoorwaardenViewModel(): ng.IPromise<PrijslijstViewModel.IVoorwaardenViewModel> {
				let obs = this.GetVoorwaardenViewModel_Observable();
				let promise = new Promise<PrijslijstViewModel.IVoorwaardenViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetInstructieViewModel_Observable(): Observable<PrijslijstViewModel.IInstructieViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetInstructieViewModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.IInstructieViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetInstructieViewModel(): ng.IPromise<PrijslijstViewModel.IInstructieViewModel> {
				let obs = this.GetInstructieViewModel_Observable();
				let promise = new Promise<PrijslijstViewModel.IInstructieViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveVoorwaardenViewModel_Observable(pVoorwaardenViewModelModel: PrijslijstViewModel.IVoorwaardenViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IVoorwaardenViewModel}): Observable<void> {
				const pVoorwaardenViewModel = pVoorwaardenViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pVoorwaardenViewModel;
				let apiURL = `api/Fabrikanten/SaveVoorwaardenViewModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveVoorwaardenViewModel(pVoorwaardenViewModelModel: PrijslijstViewModel.IVoorwaardenViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IVoorwaardenViewModel}): ng.IPromise<void> {
				let obs = this.SaveVoorwaardenViewModel_Observable(pVoorwaardenViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveInstructieViewModel_Observable(pInstructieViewModelModel: PrijslijstViewModel.IInstructieViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IInstructieViewModel}): Observable<void> {
				const pInstructieViewModel = pInstructieViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInstructieViewModel;
				let apiURL = `api/Fabrikanten/SaveInstructieViewModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveInstructieViewModel(pInstructieViewModelModel: PrijslijstViewModel.IInstructieViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IInstructieViewModel}): ng.IPromise<void> {
				let obs = this.SaveInstructieViewModel_Observable(pInstructieViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UploadHttp_Observable(): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/UploadHttp`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UploadHttp(): ng.IPromise<void> {
				let obs = this.UploadHttp_Observable();
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UploadHttpPlaatje_Observable(): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/UploadHttpPlaatje`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UploadHttpPlaatje(): ng.IPromise<void> {
				let obs = this.UploadHttpPlaatje_Observable();
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UploadHttpVoorwaarden_Observable(): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/UploadHttpVoorwaarden`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UploadHttpVoorwaarden(): ng.IPromise<void> {
				let obs = this.UploadHttpVoorwaarden_Observable();
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UploadHttpInstructie_Observable(): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/UploadHttpInstructie`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UploadHttpInstructie(): ng.IPromise<void> {
				let obs = this.UploadHttpInstructie_Observable();
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UploadHttpVoorwaardenImage_Observable(): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/UploadHttpVoorwaardenImage`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UploadHttpVoorwaardenImage(): ng.IPromise<void> {
				let obs = this.UploadHttpVoorwaardenImage_Observable();
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UploadHttpInstructieImage_Observable(): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/UploadHttpInstructieImage`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UploadHttpInstructieImage(): ng.IPromise<void> {
				let obs = this.UploadHttpInstructieImage_Observable();
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetUsers_Observable(): Observable<PrijslijstViewModel.IFabUsersViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetUsers`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.IFabUsersViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetUsers(): ng.IPromise<PrijslijstViewModel.IFabUsersViewModel> {
				let obs = this.GetUsers_Observable();
				let promise = new Promise<PrijslijstViewModel.IFabUsersViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetLoggedInUser_Observable(): Observable<PrijslijstViewModel.IFabUserViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetLoggedInUser`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.IFabUserViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetLoggedInUser(): ng.IPromise<PrijslijstViewModel.IFabUserViewModel> {
				let obs = this.GetLoggedInUser_Observable();
				let promise = new Promise<PrijslijstViewModel.IFabUserViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public Login_Observable(pUserViewModelModel: PrijslijstViewModel.IFabUserViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IFabUserViewModel}): Observable<PrijslijstViewModel.IFabUserLoginViewModel> {
				const pUserViewModel = pUserViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pUserViewModel;
				let apiURL = `api/Fabrikanten/Login/${pUserViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.IFabUserLoginViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Login(pUserViewModelModel: PrijslijstViewModel.IFabUserViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IFabUserViewModel}): ng.IPromise<PrijslijstViewModel.IFabUserLoginViewModel> {
				let obs = this.Login_Observable(pUserViewModelModel);
				let promise = new Promise<PrijslijstViewModel.IFabUserLoginViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public Attach_Observable(pCookieViewModelModel: PrijslijstViewModel.ICookieViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.ICookieViewModel}): Observable<PrijslijstViewModel.IAttachResponseModel> {
				const pCookieViewModel = pCookieViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pCookieViewModel;
				let apiURL = `api/Fabrikanten/Attach/${pCookieViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.IAttachResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Attach(pCookieViewModelModel: PrijslijstViewModel.ICookieViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.ICookieViewModel}): ng.IPromise<PrijslijstViewModel.IAttachResponseModel> {
				let obs = this.Attach_Observable(pCookieViewModelModel);
				let promise = new Promise<PrijslijstViewModel.IAttachResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetCookies_Observable(): Observable<PrijslijstViewModel.ICookieViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/GetCookies`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.ICookieViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetCookies(): ng.IPromise<PrijslijstViewModel.ICookieViewModel> {
				let obs = this.GetCookies_Observable();
				let promise = new Promise<PrijslijstViewModel.ICookieViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public Info_Observable(pCookieViewModelModel: PrijslijstViewModel.ICookieViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.ICookieViewModel}): Observable<PrijslijstViewModel.IInfoViewModel> {
				const pCookieViewModel = pCookieViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pCookieViewModel;
				let apiURL = `api/Fabrikanten/Info/${pCookieViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PrijslijstViewModel.IInfoViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Info(pCookieViewModelModel: PrijslijstViewModel.ICookieViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.ICookieViewModel}): ng.IPromise<PrijslijstViewModel.IInfoViewModel> {
				let obs = this.Info_Observable(pCookieViewModelModel);
				let promise = new Promise<PrijslijstViewModel.IInfoViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public LogOut_Observable(pCookieViewModelModel: PrijslijstViewModel.ICookieViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.ICookieViewModel}): Observable<void> {
				const pCookieViewModel = pCookieViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pCookieViewModel;
				let apiURL = `api/Fabrikanten/LogOut/${pCookieViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public LogOut(pCookieViewModelModel: PrijslijstViewModel.ICookieViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.ICookieViewModel}): ng.IPromise<void> {
				let obs = this.LogOut_Observable(pCookieViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public Delete_Observable(pUserViewModelModel: PrijslijstViewModel.IFabUserViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IFabUserViewModel}): Observable<void> {
				const pUserViewModel = pUserViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pUserViewModel;
				let apiURL = `api/Fabrikanten/Delete/${pUserViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Delete(pUserViewModelModel: PrijslijstViewModel.IFabUserViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IFabUserViewModel}): ng.IPromise<void> {
				let obs = this.Delete_Observable(pUserViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DeletePlaatje_Observable(pNaam: string): Observable<void> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Fabrikanten/DeletePlaatje/${pNaam}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DeletePlaatje(pNaam: string): ng.IPromise<void> {
				let obs = this.DeletePlaatje_Observable(pNaam);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveUser_Observable(pUserViewModelModel: PrijslijstViewModel.IFabUserViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IFabUserViewModel}): Observable<void> {
				const pUserViewModel = pUserViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pUserViewModel;
				let apiURL = `api/Fabrikanten/SaveUser/${pUserViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveUser(pUserViewModelModel: PrijslijstViewModel.IFabUserViewModel & {ToJsonContract(noContent?: boolean): PrijslijstViewModel.IFabUserViewModel}): ng.IPromise<void> {
				let obs = this.SaveUser_Observable(pUserViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}
