<div class="cont-activatie-formulier">
  <div class="dialog__header">
    <p class="dialog__title">Uw account activeren</p>
    <i (click)="naarBeginscherm()" class="fal fa-times"></i>
  </div>
  <div class="dialog__main">
    <div *ngIf="controlevraag" class="cont-address">
      <div class="address">{{activatie.Straat}}&nbsp;{{activatie.Huisnr}}</div>
    </div>
    <div *ngIf="invulformulier">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="activatieformulier__text-intro">
                <p>U bent bijna binnen...</p>
                <p>Maak uw activatie definitief door uw persoonlijke inloggegevens in te stellen.</p>
            </div>
            <mat-form-field *ngIf="ToonInlognaam" class="account__form-field__short" appearance="outline" floatLabel="always">
                <mat-label>Inlognaam</mat-label>
                <input matInput placeholder="" formControlName="username" [readonly]="true">
            </mat-form-field>
            <div class="activatieformulier__line">
                <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Voorletters</mat-label>
                <input matInput placeholder="" formControlName="initials" >
                </mat-form-field>
                <div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Voornaam</mat-label>
                    <input matInput placeholder="" formControlName="firstName" >
                    <mat-error>Vul eerst uw voornaam in</mat-error>
                </mat-form-field>
                
                </div>
            </div>
            <div class="activatieformulier__line">
                <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Tussenvoegsel</mat-label>
                <input matInput placeholder="" formControlName="surnamePrefix">
                </mat-form-field>
                <div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Achternaam</mat-label>
                    <input matInput placeholder="" formControlName="surname">
                    <mat-error>Vul eerst uw achternaam in</mat-error>
                </mat-form-field>
                
                </div>
            </div>
            <div class="activatieformulier__line">
                <div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Telefoonnummer</mat-label>
                    <input matInput placeholder="" formControlName="phoneNumber">
                    <mat-error>Vul eerst uw telefoonnummer in</mat-error>
                </mat-form-field>
                
                </div>
                <div>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>E-mailadres</mat-label>
                        <input matInput type="email" placeholder="" formControlName="emailAddress">
                        <mat-error *ngIf="form.controls['emailAddress'].errors?.required">Vul eerst uw email adres in</mat-error>
                        <mat-error *ngIf="form.controls['emailAddress'].errors?.email">Het email adres is ongeldig. Voorbeeld: test@test.com</mat-error>
                    </mat-form-field>
                    
                </div>
                </div>
                <div class="activatieformulier__line">
                <div>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Kies een wachtwoord</mat-label>
                        <input matInput type="password" placeholder="Minimaal 6 karakters" formControlName="password">
                        <mat-error *ngIf="form.controls['password'].errors?.required">Vul eerst uw wachtwoord in</mat-error>
                        <mat-error *ngIf="form.controls['password'].errors?.minlength">Het wachtwoord moet uit minstens 6 karakters bestaan</mat-error>
                    </mat-form-field>
                </div>
                <div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Typ uw wachtwoord nogmaals</mat-label>
                    <input matInput type="password" placeholder="" formControlName="confirmPassword">
                    <mat-error>De twee ingevoerde wachtwoorden komen niet overeen</mat-error>
                </mat-form-field>
                
                </div>
            </div>
            <div *ngIf="invulformulier" class="dialog__btns">
                <button (click)="naarBeginscherm()" class="dialog__btn__secundary">Annuleren</button>
                <button class="dialog__btn__primary" type="submit">Aanmelden</button>
              </div>
        </form>
  </div>
  <div *ngIf="controlevraag" class="dialog__btns">
    <button (click)="toonPopup('nietMijnWoning','')" class="dialog__btn__secundary">Dit is niet mijn woning</button>
    <button (click)="ToonInvulformulier()" class="dialog__btn__primary">Ik woon hier!</button>
  </div>
  
</div>
