
import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'fab-drag-and-drop',
	templateUrl: './drag-and-drop.component.html',
	styleUrls: ['./drag-and-drop.component.scss']
})

export class FabDragAndDrop implements OnInit{
	@Input() ItemArray: object[];
	@Input() PropertyToShow: string;
	@Input() Deletable: boolean = false;
	@Input() Selectable: boolean = true;

	@Output() DeleteAtIndex = new EventEmitter<number>();
	@Output() SelectedAtIndex = new EventEmitter<number>();
	@Output() ArrayUpdated = new EventEmitter();

	ngOnInit(): void {
		console.log("Drag&Drop was initialized with an array of " + this.ItemArray.length + " length");

		//doing a quick check if the objects in the array have the given property name.
		if (this.ItemArray.length > 0) {
			var item = this.ItemArray[0];
			if (!(this.PropertyToShow in item)) {
				throw new Error("Array objects do not have property '" + this.PropertyToShow + "', please check the objects");
			}
		}
	}

	drop(event: CdkDragDrop<string[]>) {
		if (event.previousIndex == event.currentIndex) {
			//new index is same as previous, don't do anything.
			return
		}

		moveItemInArray(this.ItemArray, event.previousIndex, event.currentIndex);

		this.ArrayUpdated.emit();
	}

	select(index: number) {
		this.SelectedAtIndex.emit(index);
	}

	delete(index: number) {
		if (!confirm("Weet je zeker dat je \"" + this.ItemArray[index][this.PropertyToShow] + "\" wil verwijderen?")) {
			//user presses no.
			return
		}
		console.log("delete at " + index)
		this.DeleteAtIndex.emit(index);
	}
}