/**
 * @ref WoonConnectViewModel/TenderViewModel.cs
 */

 import * as DataContracts from "@datacontracts/TenderViewModel";

 
import * as TenderViewModelEnum from "@enums/TenderViewModel";
import * as CostBandwidth from "./CostBandwidth";
import * as TenderCostViewModel from "./TenderCostViewModel";
import * as TenderRequestViewModel from "./TenderRequestViewModel";
import * as BuildingComponentImprovementViewModel from "./BuildingComponentImprovementViewModel";
import * as TenderDwellingViewModel from "./TenderDwellingViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.TenderRequestSingleModel
	 */
	 

	export interface ITenderRequestSingleModel {
		TenderId: number;
		ToJsonContract(): DataContracts.ITenderRequestSingleModel;
	}

	export class TenderRequestSingleModel {
		// property-Properties
		public get TenderId(): number {
			return this._TenderId;
		}
		public set TenderId(newTenderId: number) {
			this._TenderId = newTenderId;
		}

		// field-Properties

		// fields
		protected _TenderId: number = 0;

		constructor(data?: DataContracts.ITenderRequestSingleModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITenderRequestSingleModel): void {
			if(data) {
				// vul properties
				this._TenderId = data.TenderId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITenderRequestSingleModel {
			return {
				TenderId: this.TenderId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.TenderViewModel
	 */
	 

	export interface ITenderViewModel {
		Id: number;
		DistrictDirectorId: number;
		Name: string;
		Description: string;
		Status: TenderViewModelEnum.TenderStatus;
		Fase: string;
		StartDateResidentParticipation: Date;
		EndDateResidentParticipation: Date;
		CostBandwidth: CostBandwidth.ICostBandwidth;
		TenderCost: TenderCostViewModel.ITenderCostViewModel;
		TenderRequests: TenderRequestViewModel.ITenderRequestViewModel[];
		BuildingComponentImprovements: BuildingComponentImprovementViewModel.IBuildingComponentImprovementViewModel[];
		TenderDwellings: TenderDwellingViewModel.ITenderDwellingViewModel[];
		IsExpanded: boolean;
		IsDeleteAllowed: boolean;
		AllowTenderRequestToStart: boolean;
		ToJsonContract(): DataContracts.ITenderViewModel;
	}

	export class TenderViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get DistrictDirectorId(): number {
			return this._DistrictDirectorId;
		}
		public set DistrictDirectorId(newDistrictDirectorId: number) {
			this._DistrictDirectorId = newDistrictDirectorId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Description(): string {
			return this._Description;
		}
		public set Description(newDescription: string) {
			this._Description = newDescription;
		}

		public get Status(): TenderViewModelEnum.TenderStatus {
			return this._Status;
		}
		public set Status(newStatus: TenderViewModelEnum.TenderStatus) {
			this._Status = newStatus;
		}

		public get Fase(): string {
			return this._Fase;
		}
		public set Fase(newFase: string) {
			this._Fase = newFase;
		}

		public get StartDateResidentParticipation(): Date {
			return this._StartDateResidentParticipation;
		}
		public set StartDateResidentParticipation(newStartDateResidentParticipation: Date) {
			this._StartDateResidentParticipation = newStartDateResidentParticipation;
		}

		public get EndDateResidentParticipation(): Date {
			return this._EndDateResidentParticipation;
		}
		public set EndDateResidentParticipation(newEndDateResidentParticipation: Date) {
			this._EndDateResidentParticipation = newEndDateResidentParticipation;
		}

		public get CostBandwidth(): CostBandwidth.ICostBandwidth {
			return this._CostBandwidth;
		}
		public set CostBandwidth(newCostBandwidth: CostBandwidth.ICostBandwidth) {
			this._CostBandwidth = newCostBandwidth;
		}

		public get TenderCost(): TenderCostViewModel.ITenderCostViewModel {
			return this._TenderCost;
		}
		public set TenderCost(newTenderCost: TenderCostViewModel.ITenderCostViewModel) {
			this._TenderCost = newTenderCost;
		}

		public get TenderRequests(): TenderRequestViewModel.ITenderRequestViewModel[] {
			return this._TenderRequests;
		}
		public set TenderRequests(newTenderRequests: TenderRequestViewModel.ITenderRequestViewModel[]) {
			this._TenderRequests = newTenderRequests;
		}

		public get BuildingComponentImprovements(): BuildingComponentImprovementViewModel.IBuildingComponentImprovementViewModel[] {
			return this._BuildingComponentImprovements;
		}
		public set BuildingComponentImprovements(newBuildingComponentImprovements: BuildingComponentImprovementViewModel.IBuildingComponentImprovementViewModel[]) {
			this._BuildingComponentImprovements = newBuildingComponentImprovements;
		}

		public get TenderDwellings(): TenderDwellingViewModel.ITenderDwellingViewModel[] {
			return this._TenderDwellings;
		}
		public set TenderDwellings(newTenderDwellings: TenderDwellingViewModel.ITenderDwellingViewModel[]) {
			this._TenderDwellings = newTenderDwellings;
		}

		public get IsExpanded(): boolean {
			return this._IsExpanded;
		}
		public set IsExpanded(newIsExpanded: boolean) {
			this._IsExpanded = newIsExpanded;
		}

		public get IsDeleteAllowed(): boolean {
			return this._IsDeleteAllowed;
		}
		public set IsDeleteAllowed(newIsDeleteAllowed: boolean) {
			this._IsDeleteAllowed = newIsDeleteAllowed;
		}

		public get AllowTenderRequestToStart(): boolean {
			return this._AllowTenderRequestToStart;
		}
		public set AllowTenderRequestToStart(newAllowTenderRequestToStart: boolean) {
			this._AllowTenderRequestToStart = newAllowTenderRequestToStart;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _DistrictDirectorId: number = 0;
		protected _Name: string = "";
		protected _Description: string = "";
		protected _Status: TenderViewModelEnum.TenderStatus = undefined;
		protected _Fase: string = "";
		protected _StartDateResidentParticipation: Date = new Date();
		protected _EndDateResidentParticipation: Date = new Date();
		protected _CostBandwidth: CostBandwidth.ICostBandwidth = new CostBandwidth.CostBandwidth(undefined);
		protected _TenderCost: TenderCostViewModel.ITenderCostViewModel = new TenderCostViewModel.TenderCostViewModel(undefined);
		protected _TenderRequests: TenderRequestViewModel.ITenderRequestViewModel[] = [];
		protected _BuildingComponentImprovements: BuildingComponentImprovementViewModel.IBuildingComponentImprovementViewModel[] = [];
		protected _TenderDwellings: TenderDwellingViewModel.ITenderDwellingViewModel[] = [];
		protected _IsExpanded: boolean = false;
		protected _IsDeleteAllowed: boolean = false;
		protected _AllowTenderRequestToStart: boolean = false;

		constructor(data?: DataContracts.ITenderViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITenderViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._DistrictDirectorId = data.DistrictDirectorId;
				this._Name = data.Name;
				this._Description = data.Description;
				this._Status = data.Status;
				this._Fase = data.Fase;
				this._StartDateResidentParticipation = data.StartDateResidentParticipation;
				this._EndDateResidentParticipation = data.EndDateResidentParticipation;
				this._CostBandwidth = data.CostBandwidth ? new CostBandwidth.CostBandwidth(data.CostBandwidth): undefined;
				this._TenderCost = data.TenderCost ? new TenderCostViewModel.TenderCostViewModel(data.TenderCost): undefined;
				this._TenderRequests = data.TenderRequests ? data.TenderRequests.map(tenderRequestViewModelItem => new TenderRequestViewModel.TenderRequestViewModel(tenderRequestViewModelItem)): [];
				this._BuildingComponentImprovements = data.BuildingComponentImprovements ? data.BuildingComponentImprovements.map(buildingComponentImprovementViewModelItem => new BuildingComponentImprovementViewModel.BuildingComponentImprovementViewModel(buildingComponentImprovementViewModelItem)): [];
				this._TenderDwellings = data.TenderDwellings ? data.TenderDwellings.map(tenderDwellingViewModelItem => new TenderDwellingViewModel.TenderDwellingViewModel(tenderDwellingViewModelItem)): [];
				this._IsExpanded = data.IsExpanded;
				this._IsDeleteAllowed = data.IsDeleteAllowed;
				this._AllowTenderRequestToStart = data.AllowTenderRequestToStart;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITenderViewModel {
			return {
				Id: this.Id,
				DistrictDirectorId: this.DistrictDirectorId,
				Name: this.Name,
				Description: this.Description,
				Status: this.Status,
				Fase: this.Fase,
				StartDateResidentParticipation: this.StartDateResidentParticipation,
				EndDateResidentParticipation: this.EndDateResidentParticipation,
				CostBandwidth: (this.CostBandwidth ? new CostBandwidth.CostBandwidth(this.CostBandwidth).ToJsonContract(): this.CostBandwidth),
				TenderCost: (this.TenderCost ? new TenderCostViewModel.TenderCostViewModel(this.TenderCost).ToJsonContract(): this.TenderCost),
				TenderRequests: (this.TenderRequests ? this.TenderRequests.map(tenderRequestViewModelItem => new TenderRequestViewModel.TenderRequestViewModel(tenderRequestViewModelItem).ToJsonContract()): []),
				BuildingComponentImprovements: (this.BuildingComponentImprovements ? this.BuildingComponentImprovements.map(buildingComponentImprovementViewModelItem => new BuildingComponentImprovementViewModel.BuildingComponentImprovementViewModel(buildingComponentImprovementViewModelItem).ToJsonContract()): []),
				TenderDwellings: (this.TenderDwellings ? this.TenderDwellings.map(tenderDwellingViewModelItem => new TenderDwellingViewModel.TenderDwellingViewModel(tenderDwellingViewModelItem).ToJsonContract()): []),
				IsExpanded: this.IsExpanded,
				IsDeleteAllowed: this.IsDeleteAllowed,
				AllowTenderRequestToStart: this.AllowTenderRequestToStart
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.TenderRequestAllModel
	 */
	 

	export interface ITenderRequestAllModel {
		Temp: string;
		ToJsonContract(): DataContracts.ITenderRequestAllModel;
	}

	export class TenderRequestAllModel {
		// property-Properties
		public get Temp(): string {
			return this._Temp;
		}
		public set Temp(newTemp: string) {
			this._Temp = newTemp;
		}

		// field-Properties

		// fields
		protected _Temp: string = "";

		constructor(data?: DataContracts.ITenderRequestAllModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITenderRequestAllModel): void {
			if(data) {
				// vul properties
				this._Temp = data.Temp;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITenderRequestAllModel {
			return {
				Temp: this.Temp
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.TenderResultAllModel
	 */
	 

	export interface ITenderResultAllModel {
		Tenders: TenderViewModel[];
		ToJsonContract(): DataContracts.ITenderResultAllModel;
	}

	export class TenderResultAllModel {
		// property-Properties
		public get Tenders(): TenderViewModel[] {
			return this._Tenders;
		}
		public set Tenders(newTenders: TenderViewModel[]) {
			this._Tenders = newTenders;
		}

		// field-Properties

		// fields
		protected _Tenders: TenderViewModel[] = [];

		constructor(data?: DataContracts.ITenderResultAllModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITenderResultAllModel): void {
			if(data) {
				// vul properties
				this._Tenders = data.Tenders ? data.Tenders.map(tenderViewModelItem => new TenderViewModel(tenderViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ITenderResultAllModel {
			return {
				Tenders: (this.Tenders ? this.Tenders.map(tenderViewModelItem => new TenderViewModel(tenderViewModelItem).ToJsonContract()): [])
			}
		}
	}
