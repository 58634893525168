/**
 * @ref WoonConnectViewModel/Documenten/DocumentModel.cs
 */

 import * as DataContracts from "@datacontracts/DocumentModel";

 
import * as RechtenModel from "./RechtenModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Documenten.DocumentModel
	 */
	 

	export interface IDocumentModel {
		Id: number;
		Naam: string;
		Bestand: string;
		ContentType: string;
		Grootte: number;
		UploadDate: string;
		ProjectId: number;
		Rechten: RechtenModel.IRechtenModel;
		ToJsonContract(): DataContracts.IDocumentModel;
	}

	export class DocumentModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Naam(): string {
			return this._Naam;
		}
		public set Naam(newNaam: string) {
			this._Naam = newNaam;
		}

		public get Bestand(): string {
			return this._Bestand;
		}
		public set Bestand(newBestand: string) {
			this._Bestand = newBestand;
		}

		public get ContentType(): string {
			return this._ContentType;
		}
		public set ContentType(newContentType: string) {
			this._ContentType = newContentType;
		}

		public get Grootte(): number {
			return this._Grootte;
		}
		public set Grootte(newGrootte: number) {
			this._Grootte = newGrootte;
		}

		public get UploadDate(): string {
			return this._UploadDate;
		}
		public set UploadDate(newUploadDate: string) {
			this._UploadDate = newUploadDate;
		}

		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		public get Rechten(): RechtenModel.IRechtenModel {
			return this._Rechten;
		}
		public set Rechten(newRechten: RechtenModel.IRechtenModel) {
			this._Rechten = newRechten;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Naam: string = "";
		protected _Bestand: string = "";
		protected _ContentType: string = "";
		protected _Grootte: number = 0;
		protected _UploadDate: string = "";
		protected _ProjectId: number = 0;
		protected _Rechten: RechtenModel.IRechtenModel = new RechtenModel.RechtenModel(undefined);

		constructor(data?: DataContracts.IDocumentModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDocumentModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Naam = data.Naam;
				this._Bestand = data.Bestand;
				this._ContentType = data.ContentType;
				this._Grootte = data.Grootte;
				this._UploadDate = data.UploadDate;
				this._ProjectId = data.ProjectId;
				this._Rechten = data.Rechten ? new RechtenModel.RechtenModel(data.Rechten): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDocumentModel {
			return {
				Id: this.Id,
				Naam: this.Naam,
				Bestand: this.Bestand,
				ContentType: this.ContentType,
				Grootte: this.Grootte,
				UploadDate: this.UploadDate,
				ProjectId: this.ProjectId,
				Rechten: (this.Rechten ? new RechtenModel.RechtenModel(this.Rechten).ToJsonContract(): this.Rechten)
			}
		}
	}
