/**
 * @ref WoonConnectDomein/Scene/ISceneService.cs
 */
	/**
	* Originele namespace: WoonConnectDomein.Scene.CalculateFragmentResult
	*/
	export enum CalculateFragmentResult {
		SessionLost = 0,
		
		Calculated = 1,
		
		Empty = 2
	}

