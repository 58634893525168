// Core Angular imports
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { SetAngularProd  } from './app/modules/webpack/sitestatus';
import { environment } from './environments/environment';

// Available modules
import { MainModule } from './app/infrastructure/main.module';
import { FabBestekserviceModule } from './app/fabrikanten/generic/bestekservice/fab-bestekservice.module';
import { ReynaersBestekserviceModule } from './app/fabrikanten/custom/reynaers-bestekservice/bestekservice.module';
import { HoppeBestekserviceModule } from './app/fabrikanten/custom/hoppe-bestekservice/bestekservice.module';
import { DerbigumBestekserviceModule } from './app/fabrikanten/custom/derbigum-bestekservice/bestekservice.module';
import { PrijslijstModule } from './app/fabrikanten/custom/prijslijst-module/prijslijst.module';
import { FabStoIsonedBestekServiceModule } from './app/fabrikanten/custom/sto-isoned-bestekservice/fab-sto-isoned-bestekservice.module';
import { RekenModule } from './app/fabrikanten/custom/reken-module/rekenmodule.module';


// Set production or development mode
if (environment.production) {
  enableProdMode();
}

SetAngularProd();

// Async function to bootstrap the correct module
async function bootstrapModule() {
  const response = await fetch('/api/Fabrikanten/GetFabrikantModule', {
    method: "POST",
    body: null,
  });

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  else {
    const data = await response.text();
    let module = null;

    switch (data) {
      // Woonconnect
      case "WoonConnect":
        module = MainModule;
        break;
      // Generic manafacturers
      case "Bestekservice":
        module = FabBestekserviceModule;
        break;
      case "RekenModule":
        module = RekenModule;
        break;
      // Custom manufacturers
      case "ReynaersBestekservice":
        module = ReynaersBestekserviceModule;
        break;
      case "HoppeBestekserviceModule":
        module = HoppeBestekserviceModule;
        break;
      case "DerbigumBestekserviceModule":
        module = DerbigumBestekserviceModule;
        break;
      case "FabStoIsonedBestekServiceModule":
        module = FabStoIsonedBestekServiceModule;
        break;
      case "PrijslijstModule":
        module = PrijslijstModule;
        break;
      default:
        break;
    }

    platformBrowserDynamic().bootstrapModule(module, [])
    .then(msg => console.log("Angular 2 loaded: " + msg))
    .then(msg => console.log("Module loaded: " + data))
    .catch(err => {throw new Error(err)});
  }
}

// Bootstrap module
bootstrapModule().catch(err => {
  console.log("Problem bootstrapping: " + err)
});

