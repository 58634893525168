// [conv] import "(./UserDetail).less";
import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { UserManagementConsumer } from '@consumers/UserManagementController';
import { RolesConsumer } from '@consumers/RolesController';


import { ActivatedRoute, Router } from '@angular/router';
import { UserRetrieveCriterea, UserDetailRequestModel } from '@models/UserRetrieveModel';
import { UserDetailViewModel, VerificationViewModel } from '@models/UserViewModel';
import { UserChangePasswordRequestModel } from '@models/UserChangePasswordModel';
import { AddUserToRoleRequest, RolesViewModel } from '@models/RolesViewModel';
import { BuildingsViewModel, BuildingUserLinkRequest } from '@models/BuildingViewModels';
import { BuildingManagementConsumer } from '@consumers/BuildingManagementController';





@Component({
    selector: 'user-detail',
    templateUrl: './UserDetail.html'
})
export class WoonUserDetailComponent {
    UserId: string;
    UserDetailRequestModel: UserDetailRequestModel = new UserDetailRequestModel();
    userDetail: UserDetailViewModel = new UserDetailViewModel();
    newPassword: string;
    verification: VerificationViewModel = new VerificationViewModel();
    UserChangePasswordRequestModel: UserChangePasswordRequestModel = new UserChangePasswordRequestModel();
    AddRole: AddUserToRoleRequest = new AddUserToRoleRequest();
    Roles: RolesViewModel;
    BuildingsViewModel: BuildingsViewModel = new BuildingsViewModel();
    BuildingUserLinkRequest: BuildingUserLinkRequest = new BuildingUserLinkRequest();

    constructor(private user: UserManagementConsumer, private route: ActivatedRoute, private router: Router, private roles: RolesConsumer, private building: BuildingManagementConsumer) {
        this.UserId = (<any>this.route.params).value.userid; 
        
        this.UserDetailRequestModel.Id = parseInt(this.UserId);

        console.log(this.UserId);
      
        this.Refresh();
    }

    public Refresh(): void {
        
       
        //get all information from one user. 
        this.user.GetUser_Observable(this.UserDetailRequestModel).subscribe((data) => {
            console.log(data);
            this.userDetail = new UserDetailViewModel(data);

            this.verification.Verificatie = this.userDetail.Persoon.Verificatie;
            this.verification.VerificatieCode = this.userDetail.Persoon.VerificatieCode;
            this.verification.Id = this.userDetail.Persoon.PersoonId;
        })

        this.UserDetailRequestModel.Id = 0;
        this.roles.RetrieveAllRoles_Observable().subscribe((data) => {
          
            this.Roles = new RolesViewModel(data);
            //TODO - delete
            console.log(this.Roles);
        })

        this.building.GetAllBuildings_Observable(this.BuildingsViewModel).subscribe((data) => {
            this.BuildingsViewModel = new BuildingsViewModel(data);
        })
           
    }

    public setPassword() {
        //userId
        //password string;

        console.log(this.UserId, this.newPassword);
        if (confirm("Weet je zeker dat je het wachtwoord wilt wijzigen?")) {
            this.UserChangePasswordRequestModel.UserId = this.UserId;
            this.UserChangePasswordRequestModel.NewPassword = this.newPassword;

            this.user.ResetPassword_Observable(this.UserChangePasswordRequestModel).subscribe((data) => {
                console.log(data);
            })

        } else {
            console.log("Wachtwoord wijzigen geannulleerd!");
        }
    }

    //create save for user 

    public onSaveUser() {
        console.log("user opslaan");
    }

    public onVerificationChange() {
        if (this.verification) {
            this.verification.VerificatieCode = Math.random().toString(36).toUpperCase().substr(5)
        }

        if (confirm('Weet je zeker dat je de verificatie wilt wijzigen??')) {
            // Save it!
            console.log("someting");
            this.user.SetVerification_Observable(this.verification).subscribe((data) => {
                console.log("Gelukt");
                this.Refresh();
            })
        } else {
            // Do nothing!
            this.Refresh();
        }
    }

    public deblockUser() {
        this.UserDetailRequestModel.Guid = this.UserId;
        this.UserDetailRequestModel.Id = parseInt(this.UserId);

        if (confirm("Weet je zeker dat je deze user wilt deblokkeren?")) {
            this.user.UnlockUser_Observable(this.UserDetailRequestModel).subscribe((data) => {
                console.log(data);
            })
        } else {
            console.log("Deblokkeren geannulleerd!");
        }
    }
    

}
