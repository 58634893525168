import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import * as ng from 'angular';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as HelpersMasterTypescript from '.././Helpers/MasterTypescript';
import * as HelpersMenuTypescript from '.././Helpers/MenuTypescript';
import * as HelpersBackwards from '.././Helpers/Backwards';
import { ProjectService } from '../services/ProjectService';
import * as HelpersAngularEvent from '.././Helpers/AngularEvent';
import { MenuItem } from '../Models/MenuItem';
import { IMenuDetails } from '@datacontracts/GetMenusModel'; 
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { AppConfigService } from '../infrastructure/app.configservice';
import { MenuConsumer } from '@consumers/MenuController';
import { MenuState } from '../beheer/menu/menu.state';
import { GetMenusRequestModel } from '@models/GetMenusModel';
//import { AppConfigService } from '@consumers/men'';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

   ServiceEventName: string = "MenuService-event";
   m_IsGeenMenutemGeselecteerd: boolean = true;

   public ProjectId: number;
   public ToonAdresVanDezeWoning: boolean;

   public m_Menus: MenuItem[] = [];
   private m_OphalenBezig = false;
   private m_MenusOpgehaald = false;
   private m_Wachtenden: Subject<Array<MenuItem>>[] = [];
   private m_ActieveStatusbalkDetails = "";
   private m_ActiefMenuItem: MenuItem;
   private menuOpenEventEmitter: HelpersAngularEvent.IAngularEventEmitter<string>;


   constructor(private http: HttpClient,  private projectservice: ProjectService, private router: Router, 
      private route: ActivatedRoute, private config: AppConfigService, private menuConsumer: MenuConsumer,
      private store: Store<{ menu: MenuState }>,) {//}, private menuconsumer: MenuConsumer) {
      'ngInject';
      this.menuOpenEventEmitter = HelpersAngularEvent.AngularEvent.Default<string>("menuopen-event", undefined);
   }

   public BroadCastMenuOpen(naam: string): void {
      this.menuOpenEventEmitter.Emitter.BroadCast(naam);
   }

   public ZetActiefMenuItem(menuitem: MenuItem): void {
		this.m_ActiefMenuItem = menuitem;
      this.BroadCastMenuOpen(menuitem.State);
   }

   
  

   private VerwerkMenu(data: any): MenuItem[] {
      let GereedDoorBewoner: boolean = false;
      if (!HelpersMasterTypescript.MasterControllerInstance.HeeftRol("huurder")) {
         GereedDoorBewoner = true;
      }
      let mappedMenuItems: MenuItem[] = [];

      if (data != undefined) {
         data.forEach((item: any) => { mappedMenuItems.push(new MenuItem(item, GereedDoorBewoner, null)); });
      }

      //HelpersMasterTypescript.MasterControllerInstance.AngularBroadcast("MenuVerwerkt", "", true);

      return mappedMenuItems;
   }

   public GeefMenus(): ng.IPromise<Array<MenuItem>> {
      let subj: Subject<Array<MenuItem>> = new Subject();
      var inputData = new GetMenusRequestModel();
      inputData.ProjectId = this.projectservice.GeefProjectId();
      this.menuConsumer.GeefMenus_Observable(inputData).subscribe(menus => {
         let responseMenu: MenuItem[] = [];
         menus.Menus.forEach(menuDetails => {
            responseMenu.push(this.fromMenuDetails(menuDetails));
         })
         subj.next(responseMenu)
         subj.complete();
         return subj.toPromise();
      })
      //   if (this.m_MenusOpgehaald) {
      //       subj.next(this.m_Menus);
      //       subj.complete();
      //       return subj.toPromise();
      //   }

      //   if (!this.m_OphalenBezig) {
      //       this.m_OphalenBezig = true;
      //       var inputdata: any = {};
      //       inputdata.ProjectId = this.projectservice.GeefProjectId();
      //       this.http.post('/api/Menu/GeefMenu', inputdata, {responseType: 'text'}).toPromise().then(d => {
               
      //           var data: any = d;
      //           console.log('data: ');
      //           console.log(typeof(data));
   
      //           var menus: MenuDetails[] = this.VerwerkMenu(data.Menus);
   
      //           HelpersMenuTypescript.MenuViewModelInstance.DisplayDirection = data.DisplayDirection;
   
      //           this.m_Menus = menus;
      //           this.verwerkmenuitemszichtbaarzettenbuffer(); // ivm niet aanwezig zijn van data omdat deze nog niet is geladen
   
      //           subj.next(menus);
      //           subj.complete();
   
      //          //  this.m_OphalenBezig = false;
      //          //  this.m_Wachtenden.forEach((w) => {
      //          //      w.next(menus);
      //          //      w.complete();
      //          //  });
   
      //          // this.m_MenusOpgehaald = true;
      //       });
      //    } else {
      //       // this.m_Wachtenden.push(subj);
      //    }

         return subj.toPromise();
		
	}  

   private fromMenuDetails(menuDetails: IMenuDetails): MenuItem {
      return new MenuItem(menuDetails, false, false);
   }

  

   public IsActief(menuitem: MenuItem): boolean {
      var act: boolean = false;
      if (menuitem.Type !== undefined) {
         switch (menuitem.Type) {
            case "Link":
               act = this.IsLinkActief(menuitem) || menuitem.ActiveOverride;
               break;
            case "Anchor":
               act = menuitem.ActiveOverride;
               break;
            case "Parent":
               // check children
               menuitem.SubMenus.forEach((item: MenuItem) => {
                  if (this.IsActief(item)) { act = true };
               });
               if (menuitem.m_ToonDropDown) {
                  act = true;
               }
               break;
            case "OptieMenuGebouw":
               if (menuitem.m_ToonDropDown) { act = true; }
               break;
            case "OptieMenu":
               if (menuitem.m_ToonDropDown) { act = true; }
               break;
            case "Enquete":
               if (menuitem.m_ToonDropDown) { act = true; }
               break;
            case "Container":
               act = false;
               break;
            case "Zorg":
               if (menuitem.m_ToonDropDown) { act = true; }
               break;
            default:
               console.log(`%cmenuitem.TypeInterne fout: Menu heeft geen status IsActief "${menuitem.Titel}" (${menuitem.Id})`, "color:red");
               break;
         }
      }
      return act;
   }

   private IsLinkActief(menuitem: MenuItem): boolean {
      var menuItemState: string = "beheer." + (menuitem.RedirectTarget !== null ? menuitem.RedirectTarget.toLowerCase() : '');
      var menuItemStateVerkoop: string = "verkoop." + (menuitem.RedirectTarget !== null ? menuitem.RedirectTarget.toLowerCase() : '');
      var menuItemStateEnquete: string = "enquete." + (menuitem.RedirectTarget !== null ? menuitem.RedirectTarget.toLowerCase() : '');
      var menuItemStateDistrictDirector: string = "districtdirector." + (menuitem.RedirectTarget !== null ? menuitem.RedirectTarget.toLowerCase() : '');
		menuItemStateDistrictDirector = menuItemStateDistrictDirector.split("?")[0];
		var menuItemStateContractor: string = "contractor." + (menuitem.RedirectTarget !== null ? menuitem.RedirectTarget.toLowerCase() : '');
		menuItemStateContractor = menuItemStateContractor.split("?")[0];
		var currentState: string = "";//this.$state.current.name;

      var ok: boolean = currentState.toLowerCase().indexOf(menuItemState.toLowerCase()) == 0;
      ok = (currentState.toLowerCase().indexOf(menuItemStateVerkoop.toLowerCase()) == 0) || ok;
      ok = (currentState.toLowerCase().indexOf(menuItemStateEnquete.toLowerCase()) == 0) || ok;
      ok = (currentState.toLowerCase().indexOf(menuItemStateDistrictDirector.toLowerCase()) == 0) || ok;
      ok = (currentState.toLowerCase().indexOf(menuItemStateContractor.toLowerCase()) == 0) || ok;

      return ok;
   }

   private menuitemszichtbaarzettenbuffer: { menus: string[], zichtbaar: boolean }[] = [];
   private verwerkmenuitemszichtbaarzettenbuffer(): void {
      this.menuitemszichtbaarzettenbuffer.forEach((v: { menus: string[], zichtbaar: boolean }) =>
         (this.ZetMenuItemsZichtbaar(v.menus, v.zichtbaar))
      );
      this.menuitemszichtbaarzettenbuffer = [];
   }

   public ZetMenuItemsZichtbaar(betreftMenus: string[], zichtbaar: boolean): number {
      if (this.m_Menus.length > 0) {
         var aantalWijzigingen: number = 0;
         this.m_Menus.forEach((menu: MenuItem): void => {
            if (betreftMenus.some((menuRedirect: string) => menuRedirect === menu.RedirectTarget)) {
               if (menu.Zichtbaar !== zichtbaar) {
                  ++aantalWijzigingen;
               }
               menu.Zichtbaar = zichtbaar;
            }
         });

         return aantalWijzigingen;
      } else { // Opslaan om later toch te kunnen zetten
         var item: { menus: string[], zichtbaar: boolean } = { menus: betreftMenus, zichtbaar: zichtbaar };
         this.menuitemszichtbaarzettenbuffer.push(item);
      }
   }

   public ClickMenu(menu: MenuItem): void {
      var rootElem: string = this.config.Software.UImode;
      if (rootElem == "beheerverkoop") { /* hack: moet gladgestreken worden .. */
         rootElem = "beheer";
      }

      if (menu.RedirectTarget != null && menu.RedirectTarget.length > 0) {
         var target: string[] = menu.RedirectTarget.split("?");
			var state: string = target[0];

			let basisUrl = "/" + rootElem + "/" + state;

         if (target.length > 1) {
            var params: any = {};
            var p: any = target[1].split("&");
            _.each(p, (item: any) => {
               var t: any = item.split("=");
               if (t.length > 1) {
						params[t[0]] = t[1];
						basisUrl = basisUrl + '/' + t[1];
               }
            });

				this.router.navigate([basisUrl], 
				{
					relativeTo: this.route,
					queryParamsHandling: 'merge', // remove to replace all query params by provided
				}
			).then(r =>
				{
					// okay
				}).catch(err =>
				{
					// angular 2 navigation failed; fallback on angular 1 navigation
					// this.$state.go(rootElem + "." + state, params);
				});



         } else {
            this.CreateLogInConsole(rootElem, state);
            if (rootElem == 'huurdereigenaar') {
               var nieuweState = rootElem + '.' + state;
            //    this.$state.transitionTo(nieuweState);//, { "uniqueRequestCacheBuster": null });
            } else {

					this.router.navigate(["/" + rootElem + "/" + state], 
						{
							relativeTo: this.route,
							queryParamsHandling: 'merge', // remove to replace all query params by provided
						}
					).then(r =>
						{
							// okay
						}).catch(err =>
						{
							// anulgar 2 navigation failed, fallback on angular 1 navigation
							// this.$state.go(rootElem + "." + state);
						});
            }
         }
      } else {
        //  this.$state.go("Home");
      }
   }

   public CreateLogInConsole(rootElem: string, state: string) {
      var href = ""//this.$state.href(rootElem + "." + state);
      console.assert(href !== null, `Interne fout: '${rootElem}'.'${state}' is niet bekend in de routing.`);
   }
}
