import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wct-stui-layout-two-panels',
  templateUrl: './wct-stui-layout-two-panels.component.html',
  styleUrls: ['./wct-stui-layout-two-panels.component.scss']
})
export class WctStuiLayoutTwoPanelsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
