import { Component, Input, OnInit } from '@angular/core';
import { ISurveyWall } from '@datacontracts/SurveyWall';
import { FormGroupState } from 'ngrx-forms';

@Component({
  selector: 'wct-survey-facades',
  templateUrl: './wct-survey-facades.component.html',
  styleUrls: ['./wct-survey-facades.component.scss']
})
export class WctSurveyFacadesComponent implements OnInit {
  @Input() facades : FormGroupState<ISurveyWall> = null;

  constructor() { }

  ngOnInit(): void {
    console.log("walls!");
    console.log(this.facades);
  }

  trackByIndex(index, item) {
		return index;
	}
}
