import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WctButton } from './components/coreuicomponents';
import { WctBreadcrumb } from './components/coreuicomponents';
import { WctBezigComponent } from '../components/overlay/wctbezig';

import {
	 MatProgressBarModule
} from '@angular/material/progress-bar';

import {
  MatProgressSpinnerModule
} from '@angular/material/progress-spinner';


@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ],
  exports: [
    WctButton,
    WctBreadcrumb,
    WctBezigComponent
  ],
  declarations: [
    WctButton,
    WctBreadcrumb,
    WctBezigComponent,
  ],
  entryComponents: [
    WctButton,
    WctBreadcrumb,
    WctBezigComponent,
  ],
  providers: []
})
export class CoreUiModule { }

