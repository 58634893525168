import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import * as _ from 'lodash';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorContext  } from '../../../../configurator/ErrorService';
import { AccountService } from '../../../../services/AccountService';


interface HttpError
{
	ExceptionMessage: string;//"De objectverwijzing is niet op een exemplaar van een object ingesteld."
	ExceptionType: string;//"System.NullReferenceException"
	Message: string;//"An error has occurred."
	StackTrace: string;


}
@Component({
	selector: 'wct-error-dialog',
  templateUrl: './WctErrorDialog.html',
  styleUrls: ['./WctErrorDialog.scss']
})

export class WctErrorDialog  {
	Context: string;
	Error: string;
	ErrMessage: string;
	HttpError: HttpError;
	IsIntern: boolean;
	constructor(
    public accountservice: AccountService,
    public dialogRef: MatDialogRef<WctErrorDialog>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ErrorContext
	) {
    console.log("data: ");
    console.log(data);
		this.Context = data.Context;
		this.Error = data.Error;
		this.IsIntern = this.accountservice.IsIntern;

		if (data.HttpResponse != null)
		{
			let err = data.HttpResponse.error as HttpError;
			if (err != null)
			{
				this.HttpError = err;
			}
			else
			{
				this.Error = this.Error + " " + err;
			}
		}
	}

	ngOnInit(): void {

	}

	ngOnDestroy(): void {
	}

  closeDialog(): void {
		this.dialog.closeAll();
	}

  ReloadPage() {
    window.location.reload();
  }
}
