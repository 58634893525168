// [conv] import "(../FabAdminAddProduct).less";
import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductsConsumer } from '@consumers/ProductsController';
import { ProductViewModel, ProductPropertyViewModel, ProductRequestModel, ProductPropertiesViewModel, ProductPropertyTypesViewModel, ProductPropertySaveModel, ProductPropertyTypeViewModel, ProductsViewModel } from '@models/ProductsViewModel';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';



@Component({
    selector: 'product-property-add',
  templateUrl: './FabAdminProductPropertyAdd.html'
})
export class FabAdminProductPropertyAddComponent {

    @Input() ProductId: number;
    ProductRelation: ProductRequestModel;
    ProductProperty: ProductPropertyViewModel = new ProductPropertyViewModel();
    ProductProperties: ProductPropertiesViewModel;
    ProductPropertySaveModel: ProductPropertySaveModel = new ProductPropertySaveModel();
    ProductPropertyType: ProductPropertyTypeViewModel = new ProductPropertyTypeViewModel();
    ProductPropertyTypesViewModel: ProductPropertyTypesViewModel;
    Products: ProductsViewModel;




    constructor(private prod: ProductsConsumer, private route: ActivatedRoute, private router: Router) {
        this.Refresh();
    }


    public Refresh(): void {
        if (this.ProductId == undefined) {
            this.ProductId = 0;
        }
        //

        this.ProductRelation = new ProductRequestModel();
        this.ProductRelation.Id = this.ProductId;

        this.ProductProperties = new ProductPropertiesViewModel();
        //this.prod.GetAllProperties_Observable(this.ProductProperties).subscribe((data) => {
        //    this.ProductProperties = new ProductPropertiesViewModel(data);
        //})

        this.ProductPropertyTypesViewModel = new ProductPropertyTypesViewModel();

        this.prod.GetAllProductPropertyTypes_Observable(this.ProductPropertyTypesViewModel).subscribe((data) => {
            this.ProductPropertyTypesViewModel = new ProductPropertyTypesViewModel(data);
        })
        this.Products = new ProductsViewModel();
        this.prod.GetProducts_Observable(this.Products).subscribe((data) => {
            this.Products = new ProductsViewModel(data);
            console.log(this.Products);
        })
    }


    public saveProductProperty() {
        if (this.ProductProperty.Id < 1) {
            if (this.ProductId == undefined) {
                this.ProductId = 0;
            }
            //nieuwe product property aanmaken. 
            this.ProductPropertySaveModel = new ProductPropertySaveModel();
            this.ProductPropertySaveModel.ProductId = this.ProductId;
            this.ProductPropertySaveModel.Name = this.ProductProperty.Name;

            //save and create product property
            if (this.ProductPropertyType.Id < 1) {
                this.prod.SaveProductPropertyType_Observable(this.ProductPropertyType).subscribe((data) => {
                    this.ProductPropertyType = new ProductPropertyTypeViewModel(data);

                });

            }
            this.ProductPropertySaveModel.ProductPropertyTypeId = this.ProductPropertyType.Id;

            //save product property nieuw en daarbij ook de relatie 
            console.log(this.ProductPropertySaveModel);
            //this.prod.SaveProductProperty_Observable(this.ProductPropertySaveModel).subscribe((data) => {
            //    this.ProductPropertySaveModel = new ProductPropertySaveModel();
            //    console.log(data);
            //})
        }

        if (this.ProductProperty.Id > 0) {
            this.ProductPropertySaveModel = new ProductPropertySaveModel();
            this.ProductPropertySaveModel.ProductId = this.ProductId;
            this.ProductPropertySaveModel.Id = this.ProductProperty.Id;
            //Product Property Bestaat al en er hoeft alleen een link gemaakt te worden. 
            this.prod.SaveProductPropertyRelation_Observable(this.ProductPropertySaveModel).subscribe((data) => {
                console.log(data);
            })
        }
        this.Refresh();
    }
}
