/**
 * @ref WoonConnectViewModel/User/UserAddModel.cs
 */

 import * as DataContracts from "@datacontracts/UserAddModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.User.UserAddRequestModel
	 */
	 

	export interface IUserAddRequestModel {
		AccountNaam: string;
		Voornaam: string;
		Achternaam: string;
		Werk_Email_Adres: string;
		Prive_Email_Adres: string;
		Telefoon_Werk_Nummer: string;
		Telefoon_Prive_Nummer: string;
		Geboorteland: string;
		ToJsonContract(): DataContracts.IUserAddRequestModel;
	}

	export class UserAddRequestModel {
		// property-Properties
		public get AccountNaam(): string {
			return this._AccountNaam;
		}
		public set AccountNaam(newAccountNaam: string) {
			this._AccountNaam = newAccountNaam;
		}

		public get Voornaam(): string {
			return this._Voornaam;
		}
		public set Voornaam(newVoornaam: string) {
			this._Voornaam = newVoornaam;
		}

		public get Achternaam(): string {
			return this._Achternaam;
		}
		public set Achternaam(newAchternaam: string) {
			this._Achternaam = newAchternaam;
		}

		public get Werk_Email_Adres(): string {
			return this._Werk_Email_Adres;
		}
		public set Werk_Email_Adres(newWerk_Email_Adres: string) {
			this._Werk_Email_Adres = newWerk_Email_Adres;
		}

		public get Prive_Email_Adres(): string {
			return this._Prive_Email_Adres;
		}
		public set Prive_Email_Adres(newPrive_Email_Adres: string) {
			this._Prive_Email_Adres = newPrive_Email_Adres;
		}

		public get Telefoon_Werk_Nummer(): string {
			return this._Telefoon_Werk_Nummer;
		}
		public set Telefoon_Werk_Nummer(newTelefoon_Werk_Nummer: string) {
			this._Telefoon_Werk_Nummer = newTelefoon_Werk_Nummer;
		}

		public get Telefoon_Prive_Nummer(): string {
			return this._Telefoon_Prive_Nummer;
		}
		public set Telefoon_Prive_Nummer(newTelefoon_Prive_Nummer: string) {
			this._Telefoon_Prive_Nummer = newTelefoon_Prive_Nummer;
		}

		public get Geboorteland(): string {
			return this._Geboorteland;
		}
		public set Geboorteland(newGeboorteland: string) {
			this._Geboorteland = newGeboorteland;
		}

		// field-Properties

		// fields
		protected _AccountNaam: string = "";
		protected _Voornaam: string = "";
		protected _Achternaam: string = "";
		protected _Werk_Email_Adres: string = "";
		protected _Prive_Email_Adres: string = "";
		protected _Telefoon_Werk_Nummer: string = "";
		protected _Telefoon_Prive_Nummer: string = "";
		protected _Geboorteland: string = "";

		constructor(data?: DataContracts.IUserAddRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserAddRequestModel): void {
			if(data) {
				// vul properties
				this._AccountNaam = data.AccountNaam;
				this._Voornaam = data.Voornaam;
				this._Achternaam = data.Achternaam;
				this._Werk_Email_Adres = data.Werk_Email_Adres;
				this._Prive_Email_Adres = data.Prive_Email_Adres;
				this._Telefoon_Werk_Nummer = data.Telefoon_Werk_Nummer;
				this._Telefoon_Prive_Nummer = data.Telefoon_Prive_Nummer;
				this._Geboorteland = data.Geboorteland;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUserAddRequestModel {
			return {
				AccountNaam: this.AccountNaam,
				Voornaam: this.Voornaam,
				Achternaam: this.Achternaam,
				Werk_Email_Adres: this.Werk_Email_Adres,
				Prive_Email_Adres: this.Prive_Email_Adres,
				Telefoon_Werk_Nummer: this.Telefoon_Werk_Nummer,
				Telefoon_Prive_Nummer: this.Telefoon_Prive_Nummer,
				Geboorteland: this.Geboorteland
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.UserAddResponseModel
	 */
	 

	export interface IUserAddResponseModel {
		Succeeded: boolean;
		Message: string;
	}

	export class UserAddResponseModel {
		// property-Properties
		// field-Properties
		public get Succeeded(): boolean {
			return this._Succeeded;
		}
		public set Succeeded(newSucceeded: boolean) {
			this._Succeeded = newSucceeded;
		}
		
		public get Message(): string {
			return this._Message;
		}
		public set Message(newMessage: string) {
			this._Message = newMessage;
		}

		// fields
		protected _Succeeded: boolean = false;
		protected _Message: string = "";

		constructor(data?: DataContracts.IUserAddResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserAddResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Succeeded = data.Succeeded;
				this._Message = data.Message;
			}
		}
	}
