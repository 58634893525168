// [conv] import "(./ProjectOverview).less";
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Component({
    selector: 'projects-overview',
  templateUrl: './ProjectOverview.html'
})
export class WoonAdminProjectsComponent {

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private _location: Location) {
        
    }






}
