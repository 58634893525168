<!-- <div class="header">
    <p [routerLink]="'/admin/users'">Gebruikers</p>
    <p [routerLink]="'/admin/buildings'">Woningen</p>
    <p [routerLink]="'/admin/projects'">Projecten</p>
    <p [routerLink]="'/admin/streets'">Straten</p>
    <p [routerLink]="'/admin/districts'">Wijken</p>
    <p [routerLink]="'/admin/project-settings'">Admin(nieuw)</p>
</div>

<router-outlet></router-outlet> -->

<!-- <wct-energieflow-bewoners></wct-energieflow-bewoners> -->
<wct-startscherm></wct-startscherm>
