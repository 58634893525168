/**
 * @ref WoonConnectViewModel/Survey/SurveyCalculationZone.cs
 */

 import * as DataContracts from "@datacontracts/SurveyCalculationZone";

 
import * as SurveyProperty from "./SurveyProperty";
import * as SurveyCalculationZoneEnum from "@enums/SurveyCalculationZone";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.SurveyCalculationZone
	 */
	 

	export interface ISurveyCalculationZone {
		ObjectId: string;
		Name: SurveyProperty.ISurveyProperty;
		UsageArea: SurveyProperty.ISurveyProperty;
		ConstructionMass: SurveyCalculationZoneEnum.ConstructionMass;
		LoweredCeiling: boolean;
		SpecificInfiltration: SurveyProperty.ISurveyProperty;
		NumberOfFloors: SurveyProperty.ISurveyProperty;
		NumberOfLivingUnits: SurveyProperty.ISurveyProperty;
		NumberOfConduits: SurveyProperty.ISurveyProperty;
		ConduitType: SurveyCalculationZoneEnum.ConduitType;
		ToJsonContract(): DataContracts.ISurveyCalculationZone;
	}

	export class SurveyCalculationZone {
		// property-Properties
		public get ObjectId(): string {
			return this._ObjectId;
		}
		public set ObjectId(newObjectId: string) {
			this._ObjectId = newObjectId;
		}

		public get Name(): SurveyProperty.ISurveyProperty {
			return this._Name;
		}
		public set Name(newName: SurveyProperty.ISurveyProperty) {
			this._Name = newName;
		}

		public get UsageArea(): SurveyProperty.ISurveyProperty {
			return this._UsageArea;
		}
		public set UsageArea(newUsageArea: SurveyProperty.ISurveyProperty) {
			this._UsageArea = newUsageArea;
		}

		public get ConstructionMass(): SurveyCalculationZoneEnum.ConstructionMass {
			return this._ConstructionMass;
		}
		public set ConstructionMass(newConstructionMass: SurveyCalculationZoneEnum.ConstructionMass) {
			this._ConstructionMass = newConstructionMass;
		}

		public get LoweredCeiling(): boolean {
			return this._LoweredCeiling;
		}
		public set LoweredCeiling(newLoweredCeiling: boolean) {
			this._LoweredCeiling = newLoweredCeiling;
		}

		public get SpecificInfiltration(): SurveyProperty.ISurveyProperty {
			return this._SpecificInfiltration;
		}
		public set SpecificInfiltration(newSpecificInfiltration: SurveyProperty.ISurveyProperty) {
			this._SpecificInfiltration = newSpecificInfiltration;
		}

		public get NumberOfFloors(): SurveyProperty.ISurveyProperty {
			return this._NumberOfFloors;
		}
		public set NumberOfFloors(newNumberOfFloors: SurveyProperty.ISurveyProperty) {
			this._NumberOfFloors = newNumberOfFloors;
		}

		public get NumberOfLivingUnits(): SurveyProperty.ISurveyProperty {
			return this._NumberOfLivingUnits;
		}
		public set NumberOfLivingUnits(newNumberOfLivingUnits: SurveyProperty.ISurveyProperty) {
			this._NumberOfLivingUnits = newNumberOfLivingUnits;
		}

		public get NumberOfConduits(): SurveyProperty.ISurveyProperty {
			return this._NumberOfConduits;
		}
		public set NumberOfConduits(newNumberOfConduits: SurveyProperty.ISurveyProperty) {
			this._NumberOfConduits = newNumberOfConduits;
		}

		public get ConduitType(): SurveyCalculationZoneEnum.ConduitType {
			return this._ConduitType;
		}
		public set ConduitType(newConduitType: SurveyCalculationZoneEnum.ConduitType) {
			this._ConduitType = newConduitType;
		}

		// field-Properties

		// fields
		protected _ObjectId: string = "";
		protected _Name: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _UsageArea: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _ConstructionMass: SurveyCalculationZoneEnum.ConstructionMass = undefined;
		protected _LoweredCeiling: boolean = false;
		protected _SpecificInfiltration: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _NumberOfFloors: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _NumberOfLivingUnits: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _NumberOfConduits: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _ConduitType: SurveyCalculationZoneEnum.ConduitType = undefined;

		constructor(data?: DataContracts.ISurveyCalculationZone) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISurveyCalculationZone): void {
			if(data) {
				// vul properties
				this._ObjectId = data.ObjectId;
				this._Name = data.Name ? new SurveyProperty.SurveyProperty(data.Name): undefined;
				this._UsageArea = data.UsageArea ? new SurveyProperty.SurveyProperty(data.UsageArea): undefined;
				this._ConstructionMass = data.ConstructionMass;
				this._LoweredCeiling = data.LoweredCeiling;
				this._SpecificInfiltration = data.SpecificInfiltration ? new SurveyProperty.SurveyProperty(data.SpecificInfiltration): undefined;
				this._NumberOfFloors = data.NumberOfFloors ? new SurveyProperty.SurveyProperty(data.NumberOfFloors): undefined;
				this._NumberOfLivingUnits = data.NumberOfLivingUnits ? new SurveyProperty.SurveyProperty(data.NumberOfLivingUnits): undefined;
				this._NumberOfConduits = data.NumberOfConduits ? new SurveyProperty.SurveyProperty(data.NumberOfConduits): undefined;
				this._ConduitType = data.ConduitType;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISurveyCalculationZone {
			return {
				ObjectId: this.ObjectId,
				Name: (this.Name ? new SurveyProperty.SurveyProperty(this.Name).ToJsonContract(): this.Name),
				UsageArea: (this.UsageArea ? new SurveyProperty.SurveyProperty(this.UsageArea).ToJsonContract(): this.UsageArea),
				ConstructionMass: this.ConstructionMass,
				LoweredCeiling: this.LoweredCeiling,
				SpecificInfiltration: (this.SpecificInfiltration ? new SurveyProperty.SurveyProperty(this.SpecificInfiltration).ToJsonContract(): this.SpecificInfiltration),
				NumberOfFloors: (this.NumberOfFloors ? new SurveyProperty.SurveyProperty(this.NumberOfFloors).ToJsonContract(): this.NumberOfFloors),
				NumberOfLivingUnits: (this.NumberOfLivingUnits ? new SurveyProperty.SurveyProperty(this.NumberOfLivingUnits).ToJsonContract(): this.NumberOfLivingUnits),
				NumberOfConduits: (this.NumberOfConduits ? new SurveyProperty.SurveyProperty(this.NumberOfConduits).ToJsonContract(): this.NumberOfConduits),
				ConduitType: this.ConduitType
			}
		}
	}
