import { Component, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: 'wct-activatie-sleutel',
  templateUrl: './WctActivatieSleutel.html',
  styleUrls: ['./WctActivatieSleutel.scss']
})

export class WctActivatieSleutelComponent {
  @Output() private activationKeyChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;
  @ViewChild('input5') input5: ElementRef;
  @ViewChild('input6') input6: ElementRef;
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;
  sub4: Subscription;
  sub5: Subscription;
  sub6: Subscription;

  textPasted: boolean;
  private activationKey: string;
  public karakter1: string = "";
  public karakter2: string = "";
  public karakter3: string = "";
  public karakter4: string = "";
  public karakter5: string = "";
  public karakter6: string = "";

  constructor() {}

  ngAfterViewInit() {
    this.sub1 = fromEvent(this.input1.nativeElement, 'keydown').subscribe((event) => {
      this.onDigitEntered(event);
    })
    this.sub2 = fromEvent(this.input2.nativeElement, 'keydown').subscribe((event) => {
      this.onDigitEntered(event);
    })
    this.sub3 = fromEvent(this.input3.nativeElement, 'keydown').subscribe((event) => {
      this.onDigitEntered(event);
    })
    this.sub4 = fromEvent(this.input4.nativeElement, 'keydown').subscribe((event) => {
      this.onDigitEntered(event);
    })
    this.sub5 = fromEvent(this.input5.nativeElement, 'keydown').subscribe((event) => {
      this.onDigitEntered(event);
    })
    this.sub6 = fromEvent(this.input6.nativeElement, 'keydown').subscribe((event) => {
      this.onDigitEntered(event);
    })
  }

  public ProcessPaste(event): void {
    var clipboard_tekst = event.clipboardData.getData('Text');

    if (clipboard_tekst != undefined) {
      if (clipboard_tekst.length > 0) {
        this.karakter1 = clipboard_tekst[0];
      }

      if (clipboard_tekst.length > 1) {
        this.karakter2 = clipboard_tekst[1];
      }

      if (clipboard_tekst.length > 2) {
        this.karakter3 = clipboard_tekst[2];
      }

      if (clipboard_tekst.length > 3) {
        this.karakter4 = clipboard_tekst[3];
      }

      if (clipboard_tekst.length > 4) {
        this.karakter5 = clipboard_tekst[4];
      }

      if (clipboard_tekst.length > 5) {
        this.karakter6 = clipboard_tekst[5];
      }
    }
    this.input6.nativeElement.focus();
    this.textPasted = true;
  }

  onDigitEntered(event){
    if(event && !this.textPasted) {
      let element;
      if (event.code !== 'Backspace' && event.code !== 'ShiftLeft' && event.code !== 'ShiftRight') {
        if(event.target.value !== "") {
          element = event.target.nextElementSibling;
          if(element) {
            this.makeInputEmpty(element);
          }
        }
      }

      if (event.code === 'Backspace') {
        this.makeInputEmpty(event.target);
        element = event.target.previousElementSibling;
      }

      if(element == null) {
        return;
      }
      else {
        element.focus();
      }
    }
    this.textPasted = false;
  }

  onClick(event) {
    this.makeInputEmpty(event.target);
  }

  makeInputEmpty(element) {
    if(element) {
      switch (element.id) {
        case "character-1":
          this.karakter1 = "";
          break;
        case "character-2":
          this.karakter2 = "";
          break;
        case "character-3":
          this.karakter3 = "";
          break;
        case "character-4":
          this.karakter4 = "";
          break;
        case "character-5":
          this.karakter5 = "";
          break;
        case "character-6":
          this.karakter6 = "";
          break;
        default:
          break;
      }
    }
  }

  constructActivationKey() {
    this.activationKey = this.karakter1 + this.karakter2 + this.karakter3 + this.karakter4 + this.karakter5 + this.karakter6;
    this.activationKeyChanged.emit(this.activationKey);
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
    this.sub2.unsubscribe();
    this.sub3.unsubscribe();
    this.sub4.unsubscribe();
    this.sub5.unsubscribe();
    this.sub6.unsubscribe();
  }
}

