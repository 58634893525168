<div class="account-settings__header-top">
	<h1>Instellingen</h1>
	<i class="fal fa-times" (click)="CloseAccountSettings()"></i>
</div>

<div class="cont-header">
	<div class="cont-header-links">
		<h4 *ngIf="HasRole('Huurder') || HasRole('Eigenaar')" (click)="NavigateToTab('accountmanagement')" [ngClass]="{'link-is-actief': activeTab == 'accountmanagement'}">{{ "SETTINGS_ACCOUNT-MANAGEMENT" | translate }}</h4>
		<h4 *ngIf="HasRole('Contractor')" (click)="NavigateToTab('companyprofile')" [ngClass]="{'link-is-actief': activeTab == 'companyprofile'}">{{ "SETTINGS_COMPANY-PROFILE" | translate }}</h4>
		<h4 (click)="NavigateToTab('changepassword')" [ngClass]="{'link-is-actief': activeTab == 'changepassword'}">{{ "SETTINGS_CHANGE-PASSWORD" | translate }}</h4>
		<h4 *ngIf="HasRole('Huurder') || HasRole('Eigenaar') || HasRole('Contractor') || HasRole('MassaConfigureerder')" (click)="NavigateToTab('disclaimer')" [ngClass]="{'link-is-actief': activeTab == 'disclaimer'}">{{ "MENUHEADER_DISCLAIMER" | translate }}</h4>
	</div>
	<div class="cont-header-rechts"></div>
</div>

<div *ngIf="activeTab == 'accountmanagement'">
	<div [hidden]="!BezigMetLaden" class="mat-progress-spinner__cont">
		<mat-progress-spinner mode="indeterminate" color="#0078be" strokeWidth="2" diameter="50"></mat-progress-spinner>
	</div>

	<div [hidden]="BezigMetLaden" class="account-settings__main account-settings__main__accountmanagement">
		<p>{{ "SETTINGS_YOUR-ACCOUNT-DETAILS-SUPPLEMENT-OR-MODIFY" | translate }}</p>

		<p class="account-settings__subtitle">{{ "SETTINGS_PERSONAL-DETAILS" | translate }}</p>
		<div class="account-line">
			<label>{{ "SETTINGS_GENDER" | translate }}:</label>
			<mat-radio-group [(ngModel)]="Geslacht" aria-label="Wat is uw geslacht?">
				<mat-radio-button value="Man">{{ "SETTINGS_MALE" | translate }}</mat-radio-button>
				<mat-radio-button value="Vrouw">{{ "SETTINGS_FEMALE" | translate }}</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="account-line">
			<mat-form-field class="account__form-field__short" appearance="outline" floatLabel="always">
				<mat-label>{{ "SETTINGS_INITIALS" | translate }}</mat-label>
				<input matInput placeholder="" [(ngModel)]="Voorletters" [readonly]="AchternaamReadOnly">
			</mat-form-field>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>{{ "SETTINGS_FIRST-NAME" | translate }}</mat-label>
				<input matInput placeholder="" [(ngModel)]="Voornaam" [readonly]="VoornaamReadOnly">
			</mat-form-field>
			<mat-form-field class="account__form-field__short" appearance="outline" floatLabel="always">
				<mat-label>{{ "SETTINGS_MIDDLE-NAME" | translate }}</mat-label>
				<input matInput placeholder="" [(ngModel)]="Tussenvoegsel" [readonly]="AchternaamReadOnly">
			</mat-form-field>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>{{ "SETTINGS_LAST-NAME" | translate }}</mat-label>
				<input matInput placeholder="" [(ngModel)]="Achternaam" [readonly]="AchternaamReadOnly">
			</mat-form-field>
		</div>
		<div *ngIf="isDC" class="account-line">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Bedrijfsnaam</mat-label>
					<input matInput placeholder="" [(ngModel)]="Bedrijfsnaam">
				</mat-form-field>
		</div>
		<div class="account-line">
			<div>
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>{{ "SETTINGS_PHONE-NUMBER" | translate }}</mat-label>
					<input matInput placeholder="" [(ngModel)]="TelefoonNummer">
				</mat-form-field>
				<p *ngIf="TelefoonnummerFout" class="account__error-text">Vul eerst je telefoonnummer in</p>
			</div>
			<mat-form-field *ngIf="!email_verplicht" appearance="outline" floatLabel="always">
				<mat-label>{{ "SETTINGS_EMAIL" | translate }}</mat-label>
				<input matInput placeholder="" [(ngModel)]="Emailadres">
			</mat-form-field>
		</div>

		<p class="account-settings__subtitle">{{ "SETTINGS_ADDRESS" | translate }}</p>
		<div class="account-line">
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>{{ "SETTINGS_STREET-NAME" | translate }}</mat-label>
				<input matInput placeholder="" [(ngModel)]="Straat" [readonly]="AdresReadOnly">
			</mat-form-field>
			<mat-form-field class="account__form-field__short" appearance="outline" floatLabel="always">
				<mat-label>{{ "SETTINGS_HOUSE-NUMBER" | translate }}</mat-label>
				<input matInput placeholder="" [(ngModel)]="Nummer" [readonly]="AdresReadOnly">
			</mat-form-field>
		</div>
		<div class="account-line">
			<mat-form-field class="account__form-field__short" appearance="outline" floatLabel="always">
				<mat-label>{{ "SETTINGS_POSTAL-CODE" | translate }}</mat-label>
				<input matInput placeholder="" [(ngModel)]="Postcode" [readonly]="AdresReadOnly">
			</mat-form-field>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>{{ "SETTINGS_CITY" | translate }}</mat-label>
				<input matInput placeholder="" [(ngModel)]="Plaats" [readonly]="AdresReadOnly">
			</mat-form-field>
		</div>
		<wct-button buttonText="{{ 'SETTINGS_SAVE' | translate }}" buttonSize="small" buttonRole="primary" (click)="SaveAccountSettings()"></wct-button>
	</div>
</div>

<div *ngIf="activeTab == 'changepassword'" class="account-settings__main">
	<p>{{ "CHANGEPASSWORD_CHOOSE-NEW-PASSWORD-OF-AT-LEAST" | translate }}</p>

	<p *ngIf="ErrorTonen" class="account-settings__error-text">{{WachtwoordBevestigingTekst}}</p>

	<div class="account-settings__form-fields">
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>{{'CHANGEPASSWORD_YOUR-CURRENT-PASSWORD' | translate }}</mat-label>
			<input matInput placeholder="{{'CHANGEPASSWORD_YOUR-CURRENT-PASSWORD' | translate }}" [(ngModel)]="HuidigeWachtwoord" type="password">
		</mat-form-field>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>{{'CHANGEPASSWORD_YOUR-NEW-PASSWORD' | translate }}</mat-label>
			<input matInput placeholder="{{'CHANGEPASSWORD_AT-LEAST-X-CHARACTERS' | translate }}" [(ngModel)]="Wachtwoord" type="password">
		</mat-form-field>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>{{'CHANGEPASSWORD_CONFIRM-YOUR-NEW-PASSWORD' | translate }}</mat-label>
			<input matInput placeholder="{{'CHANGEPASSWORD_IN-CASE-YOU-MADE-TYPING-ERROR' | translate }}" [(ngModel)]="BevestigWachtwoord" type="password">
		</mat-form-field>
	</div>

	<wct-button buttonText="{{ 'CHANGEPASSWORD_SAVE' | translate }}" buttonSize="small" buttonRole="primary" (click)="SaveNewPassword()"></wct-button>
</div>

<div *ngIf="activeTab == 'companyprofile'" class="account-settings__main">
	<wct-account-settings-company-profile></wct-account-settings-company-profile>
</div>

<div *ngIf="activeTab == 'disclaimer'" class="account-settings__main">
	<wct-disclaimer></wct-disclaimer>
</div>
