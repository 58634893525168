<div *ngIf="filterViewModel.FilterType.Name == 'Dropdown'">
    <div>
       <div>
          <mat-form-field appearance="outline" floatLabel="always" class="control-font-size" style="width: 100%">          
             <mat-select matNativeControl [(ngModel)]="filterViewModel.SelectedOptionId" (selectionChange)="OptionChanged()">
             <mat-option *ngFor="let option of filterViewModel.Options" [value]="option.Id.toString()" class="mat-option--fab-filter">
                   {{RemoveEmptyFirstCharacter(option.Name)}}
                </mat-option>
             </mat-select>
          </mat-form-field>
       </div>
    </div>
 </div>
 
<div *ngIf="filterViewModel.FilterType.Name == 'Range'" class="fab-filter__input">
  <div class="fab-filter__form-field-and-button-and-label">
    <div class="fab-filter__form-field-and-button">
      <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size" [ngClass]="{'fab-filter__form-field--error' : RangeExplanationTextIsShown == true}">
        <input matInput type="number" [(ngModel)]="filterViewModel.RangeValue" (focus)="tooltip.show()" (blur)="EmitValueChanged(filterViewModel.RangeValue); OptionChanged(); tooltip.hide();" (keyup.enter)="EmitValueChanged(filterViewModel.RangeValue); OptionChanged(); tooltip.hide();">
        <div class="fab-filter__tooltip"
             matTooltipPosition="below"
             matTooltip='{{GetMatTooltipText()}}'
             #tooltip="matTooltip">
        </div>
      </mat-form-field>
    </div>
  </div>
  <p *ngIf="RangeExplanationTextIsShown" class="fab-filter__explanation-text">Deze maat valt niet binnen het bereik. Neem contact op als deze maat gewenst is.</p>
</div>
