/**
 * @ref WoonConnectViewModel/Resident/ResidentChangePasswordModel.cs
 */

 import * as DataContracts from "@datacontracts/ResidentChangePasswordModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Resident.ResidentChangePasswordRequestModel
	 */
	 

	export interface IResidentChangePasswordRequestModel {
		OudWachtwoord: string;
		NieuwWachtwoord: string;
		ToJsonContract(): DataContracts.IResidentChangePasswordRequestModel;
	}

	export class ResidentChangePasswordRequestModel {
		// property-Properties
		// field-Properties
		public get OudWachtwoord(): string {
			return this._OudWachtwoord;
		}
		public set OudWachtwoord(newOudWachtwoord: string) {
			this._OudWachtwoord = newOudWachtwoord;
		}
		
		public get NieuwWachtwoord(): string {
			return this._NieuwWachtwoord;
		}
		public set NieuwWachtwoord(newNieuwWachtwoord: string) {
			this._NieuwWachtwoord = newNieuwWachtwoord;
		}

		// fields
		protected _OudWachtwoord: string = "";
		protected _NieuwWachtwoord: string = "";

		constructor(data?: DataContracts.IResidentChangePasswordRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IResidentChangePasswordRequestModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._OudWachtwoord = data.OudWachtwoord;
				this._NieuwWachtwoord = data.NieuwWachtwoord;
			}
		}

		public ToJsonContract(): DataContracts.IResidentChangePasswordRequestModel {
			return {
				OudWachtwoord: this.OudWachtwoord,
				NieuwWachtwoord: this.NieuwWachtwoord
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Resident.ResidentChangePasswordResponseModel
	 */
	 

	export interface IResidentChangePasswordResponseModel {
		Success: boolean;
		Bericht: string;
	}

	export class ResidentChangePasswordResponseModel {
		// property-Properties
		// field-Properties
		public get Success(): boolean {
			return this._Success;
		}
		public set Success(newSuccess: boolean) {
			this._Success = newSuccess;
		}
		
		public get Bericht(): string {
			return this._Bericht;
		}
		public set Bericht(newBericht: string) {
			this._Bericht = newBericht;
		}

		// fields
		protected _Success: boolean = false;
		protected _Bericht: string = "";

		constructor(data?: DataContracts.IResidentChangePasswordResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IResidentChangePasswordResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Success = data.Success;
				this._Bericht = data.Bericht;
			}
		}
	}
