import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ImageAnnotationProject } from '../data/woonconnectkern/models/GetAllProjectsModel';
import { AnnotatorUser } from '../data/woonconnectkern/models/GetAnnotatorUsersModel';
import { ImageAnnotationElement } from '../data/woonconnectkern/models/GetElementsModel';
import { ImageAnnotationPhotoSet } from '../data/woonconnectkern/models/GetPhotoSetsModel';
import { ImageAnnotationService } from '../services/ImageAnnotationService';

@Component({
    selector: 'wct-image-annotation-beheer',
    templateUrl: './WctImageAnnotationBeheer.html',
    styleUrls: ['./WctImageAnnotationBeheer.scss']
})
export class WctImageAnnotationBeheerComponent {
    localUrl: any[];
    allProjects: ImageAnnotationProject[] = [];
    selectedProject: ImageAnnotationProject;
    annotatorUsers: AnnotatorUser[];
    photoSets: ImageAnnotationPhotoSet[];
    selectedUserIds: number[];
    photoSetIds: number[];
    addUsersActive: boolean = false;
    addProjectActive: boolean = false;
    addPhotoSetActive: boolean = false;
    addAnnotationElementActive: boolean = false;
    addAnnotationElementToProjectActive: boolean = false;
    addNewElementActive: boolean = false;
    projectName: string;
    elementName: string;
    selectedAnnotationElementIds: number[];
    allAnnotationElements: ImageAnnotationElement[];

    constructor(private imageAnnotationService: ImageAnnotationService) {
        
    }

    ngOnInit() {
        this.loadData();

        this.imageAnnotationService.getAnnotatorUsers().subscribe(response => {
            this.annotatorUsers = response;
        });

        this.imageAnnotationService.getAllPhotoSets().subscribe(response => {
            this.photoSets = response;
        });

        
    }

    loadData() {
        this.imageAnnotationService.getAllProjects().subscribe(response => {
            this.allProjects = response;
            this.selectedProject = this.allProjects[0];
        });

        this.imageAnnotationService.getAllImageAnnotationElements().subscribe(response => {
            this.allAnnotationElements = response;
        });
    }

    addUsers(){
        this.imageAnnotationService.addUsersToProject(this.selectedUserIds, this.selectedProject.Id).subscribe(succeeded => {
            if(succeeded) {
                alert('Gebruikers toevoegen gelukt!');
            } else {
                alert('Er is iets mis gegaan. Probeer het later opnieuw.');
            }
        });
    }

    addProject(){
        this.imageAnnotationService.createNewProject(this.projectName).subscribe(succeeded => {
            if(succeeded) {
                alert('Project toegevoegd!');
                this.loadData();
            } else {
                alert('Er is iets mis gegaan. Probeer het later opnieuw.');
            }
        }); 
    }

    addPhotoSet() {
        this.imageAnnotationService.addPhotoSetsToProject(this.photoSetIds, this.selectedProject.Id).subscribe(succeeded => {
            if(succeeded) {
                alert('Fotoset(s) succesvol toegevoegd!');
            } else {
                alert('Er is iets mis gegaan. Probeer het later opnieuw.');
            }
        });
    }

    addElementsToProject() {
        this.imageAnnotationService.addElementsToProject(this.selectedAnnotationElementIds, this.selectedProject.Id).subscribe(succeeded => {
            if(succeeded) {
                alert('Element(en) succesvol toegevoegd!');
            } else {
                alert('Er is iets mis gegaan. Probeer het later opnieuw.');
            }
        });
    }

    addElement() {
        this.imageAnnotationService.addNewElement(this.elementName).subscribe(succeeded => {
            if(succeeded) {
                alert('Element toegevoegd!');
                this.loadData();
            } else {
                alert('Er is iets mis gegaan. Probeer het later opnieuw.');
            }
        }); 
    }
    
}