
<div *ngIf="Loading">
  Laden...
</div>
<div *ngIf="!Loading">
  <div class="container">
    <table style="width: 100%;">

      <tr>
        <td>
          <div class="box-1">
            <h3>
              Producten
              <button mat-icon-button color="primary" aria-label="Add new product" (click)="addProduct = !addProduct">
                <i class="{{ !addProduct ? 'fal fa-plus' : 'fal fa-times'}}"></i>
              </button>
            </h3>
            <div>
              <button mat-raised-button color="warn" (click)="Import()">Import</button>
              <button mat-raised-button color="warn" (click)="ResetImportFlags()">ResetImportFlags</button>
            </div>


            <div>
              <input type="checkbox" value="angular" [checked]="ShowInactive"
                     (change)="showInactiveChange($event)" />
              inactieve producten weergeven
            </div>

            <fab-drag-and-drop [ItemArray]="ProductsToShow" PropertyToShow="Name" (ArrayUpdated)="onArrayChange()" (SelectedAtIndex)="onSelectedAtIndex($event)"> </fab-drag-and-drop>
          </div>
        </td>
        <td>
          <div class="box-1">
            <style type="text/css">
              .rood {
                background-color: lightcoral !important;
              }
            </style>
            <div class="example-box" *ngFor="let product of ProductsToShow" [ngClass]="{ 'rood': !product.Connected }">
              {{product.Name}} <a href="/fab-admin/product/{{ product.Id }}">go to</a>

              <div *ngIf="Importing">
                <span style="color: red;" *ngIf="product.Import == ''">X</span>
                <span *ngIf="product.Import != '' && product.Import != 'Klaar'">{{ product.Import }}</span>
                <span style="color: green;" *ngIf="product.Import == 'Klaar'">V</span>
              </div>
            </div>
          </div>
        </td>
      </tr>




      
    </table>
    <div *ngIf="addProduct" class="box-1">
      <add-product (ProductSaved)="Herlaad()"></add-product>
    </div>
  </div>
</div>



