<div class="accountmenu__main-link" (click)="ToggleDropdown()">
	{{LoginName}}
	<i class="fal fa-user-circle"></i>
</div>
<div *ngIf="ShowDropdown" class="accountmenu__dropdown">
	<ul>
		<li (click)="OpenAccountSettings('changepassword')"><p>Wachtwoord veranderen</p></li>
		<li (click)="Logout()"><p>Uitloggen</p></li>

		<svg ngNonBindable viewBox="0 0 14.1 7.8" style="enable-background:new 0 0 14.1 7.8;" xml:space="preserve">
			<polygon class="st0" points="0.7,7.1 7.1,0.7 13.4,7.1 " />
		</svg>
	</ul>
</div>
<div *ngIf="AccountSettingsAreShown" class="accountmenu__settings">
	<!-- <div [perfectScrollbar]="config" class="ps"> -->
		<!-- <wct-account-settings [activeTab]="chosenTab" (onCloseAccountSettings)="CloseAccountSettings()"></wct-account-settings> -->
	<!-- </div> -->
</div>