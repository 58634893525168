/**
 * @ref WoonConnectKern/Services/Energie/EnergieTraceHelper.cs
 */
	/**
	* Originele namespace: WoonConnectKern.Services.Energie.EnergieTraceBerekeningType
	*/
	export enum EnergieTraceBerekeningType {
		Onbekend = 0,
		
		EPC = 1,
		
		EPC_NormVersie2 = 2,
		
		EPAEnergieIndex = 3,
		
		VoorlopigEnergieLabel = 4,
		
		DefinitiefEnergieLabel = 5,
		
		EnergieProfiel = 6,
		
		Cache = 7,
		
		EPGEnergieIndex = 8,
		
		EPGEnergieIndexZonderPV = 9
	}

