/**
 * @ref WoonConnectViewModel/Fabrikanten/FilterViewModel.cs
 */

 import * as DataContracts from "@datacontracts/FilterViewModel";

 
import * as ProductsViewModel from "./ProductsViewModel";
import * as FilterViewModelEnum from "@enums/FilterViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FiltersViewModel
	 */
	 

	export interface IFiltersViewModel {
		Filters: FilterViewModel[];
		ToJsonContract(): DataContracts.IFiltersViewModel;
	}

	export class FiltersViewModel {
		// property-Properties
		public get Filters(): FilterViewModel[] {
			return this._Filters;
		}
		public set Filters(newFilters: FilterViewModel[]) {
			this._Filters = newFilters;
		}

		// field-Properties

		// fields
		protected _Filters: FilterViewModel[] = [];

		constructor(data?: DataContracts.IFiltersViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFiltersViewModel): void {
			if(data) {
				// vul properties
				this._Filters = data.Filters ? data.Filters.map(filterViewModelItem => new FilterViewModel(filterViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFiltersViewModel {
			return {
				Filters: (this.Filters ? this.Filters.map(filterViewModelItem => new FilterViewModel(filterViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterOptionsViewModel
	 */
	 

	export interface IFilterOptionsViewModel {
		FilterOptions: FilterOptionViewModel[];
		ToJsonContract(): DataContracts.IFilterOptionsViewModel;
	}

	export class FilterOptionsViewModel {
		// property-Properties
		public get FilterOptions(): FilterOptionViewModel[] {
			return this._FilterOptions;
		}
		public set FilterOptions(newFilterOptions: FilterOptionViewModel[]) {
			this._FilterOptions = newFilterOptions;
		}

		// field-Properties

		// fields
		protected _FilterOptions: FilterOptionViewModel[] = [];

		constructor(data?: DataContracts.IFilterOptionsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterOptionsViewModel): void {
			if(data) {
				// vul properties
				this._FilterOptions = data.FilterOptions ? data.FilterOptions.map(filterOptionViewModelItem => new FilterOptionViewModel(filterOptionViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterOptionsViewModel {
			return {
				FilterOptions: (this.FilterOptions ? this.FilterOptions.map(filterOptionViewModelItem => new FilterOptionViewModel(filterOptionViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterOptionRelationsViewModel
	 */
	 

	export interface IFilterOptionRelationsViewModel {
		FilterOptionsRelation: ProductsViewModel.IFilterOptionRelationViewModel[];
		ToJsonContract(): DataContracts.IFilterOptionRelationsViewModel;
	}

	export class FilterOptionRelationsViewModel {
		// property-Properties
		public get FilterOptionsRelation(): ProductsViewModel.IFilterOptionRelationViewModel[] {
			return this._FilterOptionsRelation;
		}
		public set FilterOptionsRelation(newFilterOptionsRelation: ProductsViewModel.IFilterOptionRelationViewModel[]) {
			this._FilterOptionsRelation = newFilterOptionsRelation;
		}

		// field-Properties

		// fields
		protected _FilterOptionsRelation: ProductsViewModel.IFilterOptionRelationViewModel[] = [];

		constructor(data?: DataContracts.IFilterOptionRelationsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterOptionRelationsViewModel): void {
			if(data) {
				// vul properties
				this._FilterOptionsRelation = data.FilterOptionsRelation ? data.FilterOptionsRelation.map(filterOptionRelationViewModelItem => new ProductsViewModel.FilterOptionRelationViewModel(filterOptionRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterOptionRelationsViewModel {
			return {
				FilterOptionsRelation: (this.FilterOptionsRelation ? this.FilterOptionsRelation.map(filterOptionRelationViewModelItem => new ProductsViewModel.FilterOptionRelationViewModel(filterOptionRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterOptionsVariantRelationsViewModel
	 */
	 

	export interface IFilterOptionsVariantRelationsViewModel {
		FilterOptionVariantRelation: FilterOptionVariantRelationViewModel[];
		ToJsonContract(): DataContracts.IFilterOptionsVariantRelationsViewModel;
	}

	export class FilterOptionsVariantRelationsViewModel {
		// property-Properties
		public get FilterOptionVariantRelation(): FilterOptionVariantRelationViewModel[] {
			return this._FilterOptionVariantRelation;
		}
		public set FilterOptionVariantRelation(newFilterOptionVariantRelation: FilterOptionVariantRelationViewModel[]) {
			this._FilterOptionVariantRelation = newFilterOptionVariantRelation;
		}

		// field-Properties

		// fields
		protected _FilterOptionVariantRelation: FilterOptionVariantRelationViewModel[] = [];

		constructor(data?: DataContracts.IFilterOptionsVariantRelationsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterOptionsVariantRelationsViewModel): void {
			if(data) {
				// vul properties
				this._FilterOptionVariantRelation = data.FilterOptionVariantRelation ? data.FilterOptionVariantRelation.map(filterOptionVariantRelationViewModelItem => new FilterOptionVariantRelationViewModel(filterOptionVariantRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterOptionsVariantRelationsViewModel {
			return {
				FilterOptionVariantRelation: (this.FilterOptionVariantRelation ? this.FilterOptionVariantRelation.map(filterOptionVariantRelationViewModelItem => new FilterOptionVariantRelationViewModel(filterOptionVariantRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterRelationsViewModel
	 */
	 

	export interface IFilterRelationsViewModel {
		FilterRelations: ProductsViewModel.IFilterRelationViewModel[];
		ToJsonContract(): DataContracts.IFilterRelationsViewModel;
	}

	export class FilterRelationsViewModel {
		// property-Properties
		public get FilterRelations(): ProductsViewModel.IFilterRelationViewModel[] {
			return this._FilterRelations;
		}
		public set FilterRelations(newFilterRelations: ProductsViewModel.IFilterRelationViewModel[]) {
			this._FilterRelations = newFilterRelations;
		}

		// field-Properties

		// fields
		protected _FilterRelations: ProductsViewModel.IFilterRelationViewModel[] = [];

		constructor(data?: DataContracts.IFilterRelationsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterRelationsViewModel): void {
			if(data) {
				// vul properties
				this._FilterRelations = data.FilterRelations ? data.FilterRelations.map(filterRelationViewModelItem => new ProductsViewModel.FilterRelationViewModel(filterRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterRelationsViewModel {
			return {
				FilterRelations: (this.FilterRelations ? this.FilterRelations.map(filterRelationViewModelItem => new ProductsViewModel.FilterRelationViewModel(filterRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.VariantFilterRelationsViewModel
	 */
	 

	export interface IVariantFilterRelationsViewModel {
		VariantFilterRelation: ProductsViewModel.IProductVariantFilterRelationViewModel[];
		ToJsonContract(): DataContracts.IVariantFilterRelationsViewModel;
	}

	export class VariantFilterRelationsViewModel {
		// property-Properties
		public get VariantFilterRelation(): ProductsViewModel.IProductVariantFilterRelationViewModel[] {
			return this._VariantFilterRelation;
		}
		public set VariantFilterRelation(newVariantFilterRelation: ProductsViewModel.IProductVariantFilterRelationViewModel[]) {
			this._VariantFilterRelation = newVariantFilterRelation;
		}

		// field-Properties

		// fields
		protected _VariantFilterRelation: ProductsViewModel.IProductVariantFilterRelationViewModel[] = [];

		constructor(data?: DataContracts.IVariantFilterRelationsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IVariantFilterRelationsViewModel): void {
			if(data) {
				// vul properties
				this._VariantFilterRelation = data.VariantFilterRelation ? data.VariantFilterRelation.map(productVariantFilterRelationViewModelItem => new ProductsViewModel.ProductVariantFilterRelationViewModel(productVariantFilterRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IVariantFilterRelationsViewModel {
			return {
				VariantFilterRelation: (this.VariantFilterRelation ? this.VariantFilterRelation.map(productVariantFilterRelationViewModelItem => new ProductsViewModel.ProductVariantFilterRelationViewModel(productVariantFilterRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertyFilterRelationsViewModel
	 */
	 

	export interface IProductPropertyFilterRelationsViewModel {
		ProductPropertyFilterRelation: ProductPropertyFilterRelationViewModel[];
		ToJsonContract(): DataContracts.IProductPropertyFilterRelationsViewModel;
	}

	export class ProductPropertyFilterRelationsViewModel {
		// property-Properties
		public get ProductPropertyFilterRelation(): ProductPropertyFilterRelationViewModel[] {
			return this._ProductPropertyFilterRelation;
		}
		public set ProductPropertyFilterRelation(newProductPropertyFilterRelation: ProductPropertyFilterRelationViewModel[]) {
			this._ProductPropertyFilterRelation = newProductPropertyFilterRelation;
		}

		// field-Properties

		// fields
		protected _ProductPropertyFilterRelation: ProductPropertyFilterRelationViewModel[] = [];

		constructor(data?: DataContracts.IProductPropertyFilterRelationsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertyFilterRelationsViewModel): void {
			if(data) {
				// vul properties
				this._ProductPropertyFilterRelation = data.ProductPropertyFilterRelation ? data.ProductPropertyFilterRelation.map(productPropertyFilterRelationViewModelItem => new ProductPropertyFilterRelationViewModel(productPropertyFilterRelationViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertyFilterRelationsViewModel {
			return {
				ProductPropertyFilterRelation: (this.ProductPropertyFilterRelation ? this.ProductPropertyFilterRelation.map(productPropertyFilterRelationViewModelItem => new ProductPropertyFilterRelationViewModel(productPropertyFilterRelationViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterTypesViewModel
	 */
	 

	export interface IFilterTypesViewModel {
		FilterTypes: FilterTypeViewModel[];
		ToJsonContract(): DataContracts.IFilterTypesViewModel;
	}

	export class FilterTypesViewModel {
		// property-Properties
		public get FilterTypes(): FilterTypeViewModel[] {
			return this._FilterTypes;
		}
		public set FilterTypes(newFilterTypes: FilterTypeViewModel[]) {
			this._FilterTypes = newFilterTypes;
		}

		// field-Properties

		// fields
		protected _FilterTypes: FilterTypeViewModel[] = [];

		constructor(data?: DataContracts.IFilterTypesViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterTypesViewModel): void {
			if(data) {
				// vul properties
				this._FilterTypes = data.FilterTypes ? data.FilterTypes.map(filterTypeViewModelItem => new FilterTypeViewModel(filterTypeViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterTypesViewModel {
			return {
				FilterTypes: (this.FilterTypes ? this.FilterTypes.map(filterTypeViewModelItem => new FilterTypeViewModel(filterTypeViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.ProductPropertyFilterRelationViewModel
	 */
	 

	export interface IProductPropertyFilterRelationViewModel {
		Id: number;
		Filter: FilterViewModel;
		ProductProperty: ProductsViewModel.IProductPropertyViewModel;
		Active: boolean;
		ToJsonContract(): DataContracts.IProductPropertyFilterRelationViewModel;
	}

	export class ProductPropertyFilterRelationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Filter(): FilterViewModel {
			return this._Filter;
		}
		public set Filter(newFilter: FilterViewModel) {
			this._Filter = newFilter;
		}

		public get ProductProperty(): ProductsViewModel.IProductPropertyViewModel {
			return this._ProductProperty;
		}
		public set ProductProperty(newProductProperty: ProductsViewModel.IProductPropertyViewModel) {
			this._ProductProperty = newProductProperty;
		}

		public get Active(): boolean {
			return this._Active;
		}
		public set Active(newActive: boolean) {
			this._Active = newActive;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Filter: FilterViewModel = new FilterViewModel(undefined);
		protected _ProductProperty: ProductsViewModel.IProductPropertyViewModel = new ProductsViewModel.ProductPropertyViewModel(undefined);
		protected _Active: boolean = false;

		constructor(data?: DataContracts.IProductPropertyFilterRelationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProductPropertyFilterRelationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Filter = data.Filter ? new FilterViewModel(data.Filter): undefined;
				this._ProductProperty = data.ProductProperty ? new ProductsViewModel.ProductPropertyViewModel(data.ProductProperty): undefined;
				this._Active = data.Active;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProductPropertyFilterRelationViewModel {
			return {
				Id: this.Id,
				Filter: (this.Filter ? new FilterViewModel(this.Filter).ToJsonContract(): this.Filter),
				ProductProperty: (this.ProductProperty ? new ProductsViewModel.ProductPropertyViewModel(this.ProductProperty).ToJsonContract(): this.ProductProperty),
				Active: this.Active
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.RelationViewModel
	 */
	 

	export interface IRelationViewModel {
		Type: string;
		Id: number;
		FirstLinkId: number;
		SecondLinkId: number;
		Active: boolean;
		ToJsonContract(): DataContracts.IRelationViewModel;
	}

	export class RelationViewModel {
		// property-Properties
		public get Type(): string {
			return this._Type;
		}
		public set Type(newType: string) {
			this._Type = newType;
		}

		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get FirstLinkId(): number {
			return this._FirstLinkId;
		}
		public set FirstLinkId(newFirstLinkId: number) {
			this._FirstLinkId = newFirstLinkId;
		}

		public get SecondLinkId(): number {
			return this._SecondLinkId;
		}
		public set SecondLinkId(newSecondLinkId: number) {
			this._SecondLinkId = newSecondLinkId;
		}

		public get Active(): boolean {
			return this._Active;
		}
		public set Active(newActive: boolean) {
			this._Active = newActive;
		}

		// field-Properties

		// fields
		protected _Type: string = "";
		protected _Id: number = 0;
		protected _FirstLinkId: number = 0;
		protected _SecondLinkId: number = 0;
		protected _Active: boolean = false;

		constructor(data?: DataContracts.IRelationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IRelationViewModel): void {
			if(data) {
				// vul properties
				this._Type = data.Type;
				this._Id = data.Id;
				this._FirstLinkId = data.FirstLinkId;
				this._SecondLinkId = data.SecondLinkId;
				this._Active = data.Active;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IRelationViewModel {
			return {
				Type: this.Type,
				Id: this.Id,
				FirstLinkId: this.FirstLinkId,
				SecondLinkId: this.SecondLinkId,
				Active: this.Active
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterViewModel
	 */
	 

	export interface IFilterViewModel {
		Id: number;
		Name: string;
		Position: number;
		HasEffect: boolean;
		Hidden: boolean;
		MinValue: number;
		MaxValue: number;
		DefaultValue: number;
		RangeValue: number;
		TextField: string;
		DisplayTypeEnum: FilterViewModelEnum.FilterDisplayTypeEnum;
		DisplayLevelEnum: FilterViewModelEnum.FilterDisplayLevelEnum;
		FilterType: FilterTypeViewModel;
		Options: FilterOptionViewModel[];
		SelectedOptions: FilterOptionViewModel[];
		SelectedOption: FilterOptionViewModel;
		SelectedOptionId: string;
		DefaultOptionId: number;
		VisibleInModel: boolean;
		Mandatory: boolean;
		Important: boolean;
		ToJsonContract(): DataContracts.IFilterViewModel;
	}

	export class FilterViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Position(): number {
			return this._Position;
		}
		public set Position(newPosition: number) {
			this._Position = newPosition;
		}

		public get HasEffect(): boolean {
			return this._HasEffect;
		}
		public set HasEffect(newHasEffect: boolean) {
			this._HasEffect = newHasEffect;
		}

		public get Hidden(): boolean {
			return this._Hidden;
		}
		public set Hidden(newHidden: boolean) {
			this._Hidden = newHidden;
		}

		public get MinValue(): number {
			return this._MinValue;
		}
		public set MinValue(newMinValue: number) {
			this._MinValue = newMinValue;
		}

		public get MaxValue(): number {
			return this._MaxValue;
		}
		public set MaxValue(newMaxValue: number) {
			this._MaxValue = newMaxValue;
		}

		public get DefaultValue(): number {
			return this._DefaultValue;
		}
		public set DefaultValue(newDefaultValue: number) {
			this._DefaultValue = newDefaultValue;
		}

		public get RangeValue(): number {
			return this._RangeValue;
		}
		public set RangeValue(newRangeValue: number) {
			this._RangeValue = newRangeValue;
		}

		public get TextField(): string {
			return this._TextField;
		}
		public set TextField(newTextField: string) {
			this._TextField = newTextField;
		}

		public get DisplayTypeEnum(): FilterViewModelEnum.FilterDisplayTypeEnum {
			return this._DisplayTypeEnum;
		}
		public set DisplayTypeEnum(newDisplayTypeEnum: FilterViewModelEnum.FilterDisplayTypeEnum) {
			this._DisplayTypeEnum = newDisplayTypeEnum;
		}

		public get DisplayLevelEnum(): FilterViewModelEnum.FilterDisplayLevelEnum {
			return this._DisplayLevelEnum;
		}
		public set DisplayLevelEnum(newDisplayLevelEnum: FilterViewModelEnum.FilterDisplayLevelEnum) {
			this._DisplayLevelEnum = newDisplayLevelEnum;
		}

		public get FilterType(): FilterTypeViewModel {
			return this._FilterType;
		}
		public set FilterType(newFilterType: FilterTypeViewModel) {
			this._FilterType = newFilterType;
		}

		public get Options(): FilterOptionViewModel[] {
			return this._Options;
		}
		public set Options(newOptions: FilterOptionViewModel[]) {
			this._Options = newOptions;
		}

		public get SelectedOptions(): FilterOptionViewModel[] {
			return this._SelectedOptions;
		}
		public set SelectedOptions(newSelectedOptions: FilterOptionViewModel[]) {
			this._SelectedOptions = newSelectedOptions;
		}

		public get SelectedOption(): FilterOptionViewModel {
			return this._SelectedOption;
		}
		public set SelectedOption(newSelectedOption: FilterOptionViewModel) {
			this._SelectedOption = newSelectedOption;
		}

		public get SelectedOptionId(): string {
			return this._SelectedOptionId;
		}
		public set SelectedOptionId(newSelectedOptionId: string) {
			this._SelectedOptionId = newSelectedOptionId;
		}

		public get DefaultOptionId(): number {
			return this._DefaultOptionId;
		}
		public set DefaultOptionId(newDefaultOptionId: number) {
			this._DefaultOptionId = newDefaultOptionId;
		}

		public get VisibleInModel(): boolean {
			return this._VisibleInModel;
		}
		public set VisibleInModel(newVisibleInModel: boolean) {
			this._VisibleInModel = newVisibleInModel;
		}

		public get Mandatory(): boolean {
			return this._Mandatory;
		}
		public set Mandatory(newMandatory: boolean) {
			this._Mandatory = newMandatory;
		}

		public get Important(): boolean {
			return this._Important;
		}
		public set Important(newImportant: boolean) {
			this._Important = newImportant;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _Position: number = 0;
		protected _HasEffect: boolean = false;
		protected _Hidden: boolean = false;
		protected _MinValue: number = 0;
		protected _MaxValue: number = 0;
		protected _DefaultValue: number = 0;
		protected _RangeValue: number = 0;
		protected _TextField: string = "";
		protected _DisplayTypeEnum: FilterViewModelEnum.FilterDisplayTypeEnum = undefined;
		protected _DisplayLevelEnum: FilterViewModelEnum.FilterDisplayLevelEnum = undefined;
		protected _FilterType: FilterTypeViewModel = new FilterTypeViewModel(undefined);
		protected _Options: FilterOptionViewModel[] = [];
		protected _SelectedOptions: FilterOptionViewModel[] = [];
		protected _SelectedOption: FilterOptionViewModel = new FilterOptionViewModel(undefined);
		protected _SelectedOptionId: string = "";
		protected _DefaultOptionId: number = 0;
		protected _VisibleInModel: boolean = false;
		protected _Mandatory: boolean = false;
		protected _Important: boolean = false;

		constructor(data?: DataContracts.IFilterViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._Position = data.Position;
				this._HasEffect = data.HasEffect;
				this._Hidden = data.Hidden;
				this._MinValue = data.MinValue;
				this._MaxValue = data.MaxValue;
				this._DefaultValue = data.DefaultValue;
				this._RangeValue = data.RangeValue;
				this._TextField = data.TextField;
				this._DisplayTypeEnum = data.DisplayTypeEnum;
				this._DisplayLevelEnum = data.DisplayLevelEnum;
				this._FilterType = data.FilterType ? new FilterTypeViewModel(data.FilterType): undefined;
				this._Options = data.Options ? data.Options.map(filterOptionViewModelItem => new FilterOptionViewModel(filterOptionViewModelItem)): [];
				this._SelectedOptions = data.SelectedOptions ? data.SelectedOptions.map(filterOptionViewModelItem => new FilterOptionViewModel(filterOptionViewModelItem)): [];
				this._SelectedOption = data.SelectedOption ? new FilterOptionViewModel(data.SelectedOption): undefined;
				this._SelectedOptionId = data.SelectedOptionId;
				this._DefaultOptionId = data.DefaultOptionId;
				this._VisibleInModel = data.VisibleInModel;
				this._Mandatory = data.Mandatory;
				this._Important = data.Important;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterViewModel {
			return {
				Id: this.Id,
				Name: this.Name,
				Position: this.Position,
				HasEffect: this.HasEffect,
				Hidden: this.Hidden,
				MinValue: this.MinValue,
				MaxValue: this.MaxValue,
				DefaultValue: this.DefaultValue,
				RangeValue: this.RangeValue,
				TextField: this.TextField,
				DisplayTypeEnum: this.DisplayTypeEnum,
				DisplayLevelEnum: this.DisplayLevelEnum,
				FilterType: (this.FilterType ? new FilterTypeViewModel(this.FilterType).ToJsonContract(): this.FilterType),
				Options: (this.Options ? this.Options.map(filterOptionViewModelItem => new FilterOptionViewModel(filterOptionViewModelItem).ToJsonContract()): []),
				SelectedOptions: (this.SelectedOptions ? this.SelectedOptions.map(filterOptionViewModelItem => new FilterOptionViewModel(filterOptionViewModelItem).ToJsonContract()): []),
				SelectedOption: (this.SelectedOption ? new FilterOptionViewModel(this.SelectedOption).ToJsonContract(): this.SelectedOption),
				SelectedOptionId: this.SelectedOptionId,
				DefaultOptionId: this.DefaultOptionId,
				VisibleInModel: this.VisibleInModel,
				Mandatory: this.Mandatory,
				Important: this.Important
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterOptionViewModel
	 */
	 

	export interface IFilterOptionViewModel {
		Id: number;
		Name: string;
		FilterName: string;
		Position: number;
		FilterId: number;
		Description: string;
		DescriptionShort: string;
		Image: string;
		Selected: boolean;
		ToJsonContract(): DataContracts.IFilterOptionViewModel;
	}

	export class FilterOptionViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get FilterName(): string {
			return this._FilterName;
		}
		public set FilterName(newFilterName: string) {
			this._FilterName = newFilterName;
		}

		public get Position(): number {
			return this._Position;
		}
		public set Position(newPosition: number) {
			this._Position = newPosition;
		}

		public get FilterId(): number {
			return this._FilterId;
		}
		public set FilterId(newFilterId: number) {
			this._FilterId = newFilterId;
		}

		public get Description(): string {
			return this._Description;
		}
		public set Description(newDescription: string) {
			this._Description = newDescription;
		}

		public get DescriptionShort(): string {
			return this._DescriptionShort;
		}
		public set DescriptionShort(newDescriptionShort: string) {
			this._DescriptionShort = newDescriptionShort;
		}

		public get Image(): string {
			return this._Image;
		}
		public set Image(newImage: string) {
			this._Image = newImage;
		}

		public get Selected(): boolean {
			return this._Selected;
		}
		public set Selected(newSelected: boolean) {
			this._Selected = newSelected;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _FilterName: string = "";
		protected _Position: number = 0;
		protected _FilterId: number = 0;
		protected _Description: string = "";
		protected _DescriptionShort: string = "";
		protected _Image: string = "";
		protected _Selected: boolean = false;

		constructor(data?: DataContracts.IFilterOptionViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterOptionViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._FilterName = data.FilterName;
				this._Position = data.Position;
				this._FilterId = data.FilterId;
				this._Description = data.Description;
				this._DescriptionShort = data.DescriptionShort;
				this._Image = data.Image;
				this._Selected = data.Selected;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterOptionViewModel {
			return {
				Id: this.Id,
				Name: this.Name,
				FilterName: this.FilterName,
				Position: this.Position,
				FilterId: this.FilterId,
				Description: this.Description,
				DescriptionShort: this.DescriptionShort,
				Image: this.Image,
				Selected: this.Selected
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterTypeViewModel
	 */
	 

	export interface IFilterTypeViewModel {
		Id: number;
		Name: string;
		ToJsonContract(): DataContracts.IFilterTypeViewModel;
	}

	export class FilterTypeViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";

		constructor(data?: DataContracts.IFilterTypeViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterTypeViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterTypeViewModel {
			return {
				Id: this.Id,
				Name: this.Name
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterRequestModel
	 */
	 

	export interface IFilterRequestModel {
		Id: number;
		ToJsonContract(): DataContracts.IFilterRequestModel;
	}

	export class FilterRequestModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;

		constructor(data?: DataContracts.IFilterRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterRequestModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterRequestModel {
			return {
				Id: this.Id
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FilterOptionVariantRelationViewModel
	 */
	 

	export interface IFilterOptionVariantRelationViewModel {
		Id: number;
		ProductVariant: ProductsViewModel.IProductVariantModel;
		FilterOption: FilterOptionViewModel;
		Active: boolean;
		ToJsonContract(): DataContracts.IFilterOptionVariantRelationViewModel;
	}

	export class FilterOptionVariantRelationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ProductVariant(): ProductsViewModel.IProductVariantModel {
			return this._ProductVariant;
		}
		public set ProductVariant(newProductVariant: ProductsViewModel.IProductVariantModel) {
			this._ProductVariant = newProductVariant;
		}

		public get FilterOption(): FilterOptionViewModel {
			return this._FilterOption;
		}
		public set FilterOption(newFilterOption: FilterOptionViewModel) {
			this._FilterOption = newFilterOption;
		}

		public get Active(): boolean {
			return this._Active;
		}
		public set Active(newActive: boolean) {
			this._Active = newActive;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ProductVariant: ProductsViewModel.IProductVariantModel = new ProductsViewModel.ProductVariantModel(undefined);
		protected _FilterOption: FilterOptionViewModel = new FilterOptionViewModel(undefined);
		protected _Active: boolean = false;

		constructor(data?: DataContracts.IFilterOptionVariantRelationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFilterOptionVariantRelationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ProductVariant = data.ProductVariant ? new ProductsViewModel.ProductVariantModel(data.ProductVariant): undefined;
				this._FilterOption = data.FilterOption ? new FilterOptionViewModel(data.FilterOption): undefined;
				this._Active = data.Active;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFilterOptionVariantRelationViewModel {
			return {
				Id: this.Id,
				ProductVariant: (this.ProductVariant ? new ProductsViewModel.ProductVariantModel(this.ProductVariant).ToJsonContract(): this.ProductVariant),
				FilterOption: (this.FilterOption ? new FilterOptionViewModel(this.FilterOption).ToJsonContract(): this.FilterOption),
				Active: this.Active
			}
		}
	}
