// [conv] import "(./FabFilter).less";
import { Component, Input, Output, EventEmitter, Pipe } from '@angular/core';
import * as _ from 'lodash';

import { FilterViewModel } from '@models/FilterViewModel';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { MatTooltip } from '@angular/material/tooltip';
import { GoogleAnalyticsService } from '../../../../../services/GoogleAnalyticsService'

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent {
  public rateControl: FormControl;
  public filterViewModel: FilterViewModel;
  public RangeExplanationTextIsShown: boolean;

  @Input()
  set FilterViewModel(pFilterViewModel: FilterViewModel) {
    if (pFilterViewModel.MinValue !== null) {
      //this.BCBProductModel = pProduct;
      //this.Refresh();
      this.filterViewModel = pFilterViewModel;

      this.ToggleRangeExplanationText();

      this.rateControl = new FormControl("", [Validators.max(pFilterViewModel.MaxValue), Validators.min(pFilterViewModel.MinValue)]);
    }
  }

  @Input() public IsFilterFirstLevel: boolean;
  @Input() public HeeftCADAbstract: boolean;

  @Output() public FilterChanged = new EventEmitter<any>();
  @Output() public ValueChanged = new EventEmitter<any>();

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {
  }


  public OptionChanged(): void {

    this.filterViewModel.SelectedOptions.splice(0, this.filterViewModel.SelectedOptions.length);

    for (var i = 0; i < this.filterViewModel.Options.length; i++) {
      if (this.filterViewModel.FilterType.Name == "Multiselect") {
        if (this.filterViewModel.Options[i].Selected) {
          this.filterViewModel.SelectedOptions.push(this.filterViewModel.Options[i]);
        }
      }
      else {
        if (this.filterViewModel.Options[i].Id.toString() == this.filterViewModel.SelectedOptionId.toString()) {
          this.filterViewModel.SelectedOption = this.filterViewModel.Options[i];

          // Google Analytics event
          this.googleAnalyticsService.sendCustomEvent(
            "Bestekservice",
            "FilterPropertyChanged",
            this.filterViewModel.Name + " / " + this.filterViewModel.SelectedOption.Name,
            Number(this.filterViewModel.SelectedOptionId));
        }
      }
    }


    this.FilterChanged.emit();
  }

  public ToggleRangeExplanationText() {
    if (this.filterViewModel.FilterType.Name == 'Range') {
      if (((this.filterViewModel.RangeValue < this.filterViewModel.MinValue) || (this.filterViewModel.RangeValue > this.filterViewModel.MaxValue)) && this.filterViewModel.RangeValue != undefined) {
        this.RangeExplanationTextIsShown = true;
      } else {
        this.RangeExplanationTextIsShown = false;
      }
    }
  }

  public EmitValueChanged(inputValue) {
    this.ValueChanged.emit({ filterviewmodelid: this.filterViewModel.Id, savedvalue: inputValue });
  }

  public Reset(): void {
    this.filterViewModel.SelectedOption = null;
    this.filterViewModel.SelectedOptionId = "";
    this.OptionChanged();
  }

  public RemoveEmptyFirstCharacter(str: string) {
    if (str.charAt(0) == " ") {
      str = str.slice(1)
    }
    str = str.charAt(0).toUpperCase() + str.slice(1)
    return str;
  }

  public ShouldDisplayTooltip(name): boolean {
    if (name == "Brandwerendheid") {
      return true;
    }

    if (name == "Uf-waarde") {
      return true;
    }

    if (name == "Waterdichtheid") {
      return true;
    }

    if (name == "Luchtdoorlatendheid") {
      return true;
    }

    if (name == "Geluidwerendheid") {
      return true;
    }

    if (name == "Kleur buiten") {
      return true;
    }

    if (name == "Kleur binnen") {
      return true;
    }

    if (name == "Kleur draaiende delen buiten") {
      return true;
    }

    if (name == "Kleur draaiende delen binnen") {
      return true;
    }

    if (name == "Hang en sluitwerk") {
      return true;
    }

    if (name == "Hang en sluitwerk kleur") {
      return true;
    }

    return false;
  }

  public GetToolTipText(name): string {
    if (name == "Brandwerendheid") {
      return "Maximale afmetingen zijn van toepassing!";
    }

    if (name == "Uf-waarde") {
      return "Afhankelijk van profielkeuze";
    }

    if (name == "Waterdichtheid") {
      return "Afhankelijk van onderliggende testresultaten en maximale afmetingen";
    }

    if (name == "Luchtdoorlatendheid") {
      return "Getest volgens NEN EN 12207 tot 600Pa";
    }

    if (name == "Geluidwerendheid") {
      return "Afhankelijk van de gekozen beglazing";
    }

    if (name == "Kleur buiten") {
      return "beschrijf hier bijvoorbeeld de RAL of anodisatie kleur";
    }

    if (name == "Kleur binnen") {
      return "beschrijf hier bijvoorbeeld de RAL of anodisatie kleur";
    }

    if (name == "Kleur draaiende delen buiten") {
      return "beschrijf hier bijvoorbeeld de RAL of anodisatie kleur";
    }

    if (name == "Kleur draaiende delen binnen") {
      return "beschrijf hier bijvoorbeeld de RAL of anodisatie kleur";
    }

    if (name == "Hang en sluitwerk") {
      return "Meer informatie vindt u op https://beslag.reynaers.nl/";
    }

    if (name == "Hang en sluitwerk kleur") {
      return "beschrijf hier bijvoorbeeld de RAL of anodisatie kleur";
    }
    
    return "";
  }

  public GetMatTooltipText() {
    let ret = "";
    if (this.filterViewModel.MaxValue == 1000000) {
      ret = "Vul een waarde in groter dan " + this.filterViewModel.MinValue;
    } else {
      ret = "Vul een waarde in tussen " + this.filterViewModel.MinValue + " en " + this.filterViewModel.MaxValue;
    }
    return ret;
  }
}
