/**
 * @ref WoonConnectViewModel/Survey/SurveyRoof.cs
 */

 import * as DataContracts from "@datacontracts/SurveyRoof";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.SurveyRoof
	 */
	 

	export interface ISurveyRoof {
		TekstUit: string;
		ToJsonContract(): DataContracts.ISurveyRoof;
	}

	export class SurveyRoof {
		// property-Properties
		// field-Properties
		public get TekstUit(): string {
			return this._TekstUit;
		}
		public set TekstUit(newTekstUit: string) {
			this._TekstUit = newTekstUit;
		}

		// fields
		protected _TekstUit: string = "";

		constructor(data?: DataContracts.ISurveyRoof) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISurveyRoof): void {
			if(data) {
				// vul properties
				// vul fields
				this._TekstUit = data.TekstUit;
			}
		}

		public ToJsonContract(): DataContracts.ISurveyRoof {
			return {
				TekstUit: this.TekstUit
			}
		}
	}
