<div class="content">
  <div class="breadcrumb">
    <a routerLink="" [class.active]="router.url === '/'">Home</a>

    <div class="callToActionMain">
      <div class="callToAction" (click)="ContactOpen()">
        Vraag?<br />
        <div class="callToActionSubText">Meer informatie nodig?</div>
      </div>
      <div class="callToActionFold">
        <table style="width: 100%;">
          <tr>
            <td> <a href="tel:+0310492561020" style="color: #0078ff;">+31 (0)492 - 56 10 20</a></td>
          </tr>
          <tr>
            <td> <a href="mailto:info@reynaers.nl" style="color: #0078ff;">info@reynaers.nl</a></td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div style="display: flex; padding: 40px;">
    <div style="flex: auto;"></div>
    <div>
      <h1>Reynaers Bestekservice</h1>
      <p class="text">
        Stel met deze bestekservice snel en eenvoudig uw eigen bestek samen.  Geef uw wensen en eisen aan. Het resultaat is het product dat het beste past bij uw criteria.
      </p>
      <p class="text">
        Kies één van onderstaande categorieën en start met het samenstellen van uw bestek.
      </p>
      <br />
      <br />
      <br />
      <div class="category-grid">
        <div style="display: flex;">
          <div style="flex: auto;"></div>
          <a routerLink="/categorie/1">
            <div class="cat-block">
              <div class="windows cat-img">
                <p class="compose">
                  <img src="/Content/KlantSpecifiek/BCB21016/img/category-compose.png" width="16" height="14"> Bestek samenstellen
                </p>
              </div>
            </div>
            <div class="title">
              Ramen
            </div>
          </a>
          <a routerLink="/categorie/2">
            <div class="cat-block">
              <div class="doors cat-img">
                <p class="compose">
                  <img src="/Content/KlantSpecifiek/BCB21016/img/category-compose.png" width="16" height="14"> Bestek samenstellen
                </p>
              </div>
            </div>
            <div class="title">
              Deuren
            </div>
          </a>
          <div style="flex: auto;"></div>
        </div>
        <div style="display: flex;">
          <div style="flex: auto;"></div>
          <a routerLink="/categorie/3">
            <div class="cat-block">
              <div class="slidings cat-img">
                <p class="compose">
                  <img src="/Content/KlantSpecifiek/BCB21016/img/category-compose.png" width="16" height="14"> Bestek samenstellen
                </p>
              </div>
            </div>
            <div class="title">
              Schuif- en vouwsystemen
            </div>
          </a>
          <a routerLink="/categorie/4">
            <div class="cat-block">
              <div class="curtains cat-img">
                <p class="compose">
                  <img src="/Content/KlantSpecifiek/BCB21016/img/category-compose.png" width="16" height="14"> Bestek samenstellen
                </p>
              </div>
            </div>
            <div class="title">
              Vliesgevels
            </div>
          </a>
          <div style="flex: auto;"></div>
        </div>
      </div>
    </div>
    <div style="flex: auto;"></div>
  </div>
</div>

<app-meer></app-meer>
<br />
<br />
<br />
