/**
 * @ref WoonConnectViewModel/Fabrikanten/FabrikantExcelModel.cs
 */

 import * as DataContracts from "@datacontracts/FabrikantExcelModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FabrikantenExcelResponseModel
	 */
	 

	export interface IFabrikantenExcelResponseModel {
		Values: FabrikantenExcelValueModel[];
		ToJsonContract(): DataContracts.IFabrikantenExcelResponseModel;
	}

	export class FabrikantenExcelResponseModel {
		// property-Properties
		public get Values(): FabrikantenExcelValueModel[] {
			return this._Values;
		}
		public set Values(newValues: FabrikantenExcelValueModel[]) {
			this._Values = newValues;
		}

		// field-Properties

		// fields
		protected _Values: FabrikantenExcelValueModel[] = [];

		constructor(data?: DataContracts.IFabrikantenExcelResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabrikantenExcelResponseModel): void {
			if(data) {
				// vul properties
				this._Values = data.Values ? data.Values.map(fabrikantenExcelValueModelItem => new FabrikantenExcelValueModel(fabrikantenExcelValueModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabrikantenExcelResponseModel {
			return {
				Values: (this.Values ? this.Values.map(fabrikantenExcelValueModelItem => new FabrikantenExcelValueModel(fabrikantenExcelValueModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Fabrikanten.FabrikantenExcelValueModel
	 */
	 

	export interface IFabrikantenExcelValueModel {
		Name: string;
		Value: string;
		Unit: string;
		Tussenuitkomst: boolean;
		ToJsonContract(): DataContracts.IFabrikantenExcelValueModel;
	}

	export class FabrikantenExcelValueModel {
		// property-Properties
		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Value(): string {
			return this._Value;
		}
		public set Value(newValue: string) {
			this._Value = newValue;
		}

		public get Unit(): string {
			return this._Unit;
		}
		public set Unit(newUnit: string) {
			this._Unit = newUnit;
		}

		public get Tussenuitkomst(): boolean {
			return this._Tussenuitkomst;
		}
		public set Tussenuitkomst(newTussenuitkomst: boolean) {
			this._Tussenuitkomst = newTussenuitkomst;
		}

		// field-Properties

		// fields
		protected _Name: string = "";
		protected _Value: string = "";
		protected _Unit: string = "";
		protected _Tussenuitkomst: boolean = false;

		constructor(data?: DataContracts.IFabrikantenExcelValueModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IFabrikantenExcelValueModel): void {
			if(data) {
				// vul properties
				this._Name = data.Name;
				this._Value = data.Value;
				this._Unit = data.Unit;
				this._Tussenuitkomst = data.Tussenuitkomst;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IFabrikantenExcelValueModel {
			return {
				Name: this.Name,
				Value: this.Value,
				Unit: this.Unit,
				Tussenuitkomst: this.Tussenuitkomst
			}
		}
	}
