/**
 * @ref WoonConnectViewModel/Infrastructure/InfraStatus.cs
 */

 import * as DataContracts from "@datacontracts/InfraStatus";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Infrastructure.InfraStatus
	 */
	 

	export interface IInfraStatus {
		ClientsideProduction: boolean;
	}

	export class InfraStatus {
		// property-Properties
		// field-Properties
		public get ClientsideProduction(): boolean {
			return this._ClientsideProduction;
		}
		public set ClientsideProduction(newClientsideProduction: boolean) {
			this._ClientsideProduction = newClientsideProduction;
		}

		// fields
		protected _ClientsideProduction: boolean = false;

		constructor(data?: DataContracts.IInfraStatus) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IInfraStatus): void {
			if(data) {
				// vul properties
				// vul fields
				this._ClientsideProduction = data.ClientsideProduction;
			}
		}
	}
