import * as _ from 'lodash';
import * as ServicesSceneTypescript from '.././services/SceneTypescript';




	export class WebGLSessie {
		SceneSession: ServicesSceneTypescript.SceneSession;
		Canvas: any;
		private m_KleurTerrein: number[];
		private m_KleurFirmament: number[];
		public MaxResizes = 100;
		public Resizes = -1;
		public Pauze: boolean = false;

		private m_ToonFPS: boolean;
		private FPSFrames: number = 0;

		private FPSVerstreken: number = (0);
		private FPS: number = (0);
		private FPSUpdateInterval: number = 500;

		private m_VolgCameraType: boolean = false;

		ZetVolgCameraType(b: boolean)
		{
			this.m_VolgCameraType = b;
		}

		GeefVolgCameraType()
		{
			return this.m_VolgCameraType;
		}

		GeefAchtergrondKleurTerrein() {
			return this.m_KleurTerrein;
		}
		GeefAchtergrondKleurFirmament() {
			return this.m_KleurFirmament;
		}

		ZetAchtergrondKleurTerrein(kleur: number[]) {
			this.m_KleurTerrein = kleur;
		}
		ZetAchtergrondKleurFirmament(kleur: number[]) {
			this.m_KleurFirmament = kleur;
		}

		constructor(public SceneType: string) {
		}

		ZetSessie(canvas, s: ServicesSceneTypescript.SceneSession) {
			this.SceneSession = s;
			this.Canvas = canvas;
			//s.ZetAchtergrondKleur(this.m_KleurTerrein, this.m_KleurFirmament);
		}

		FPSActief(): boolean {
			return this.m_ToonFPS;
		}

		Destroyed()
		{
			if (this.SceneSession != null)
			{
				this.SceneSession.Destroyed();
			}
		}

		ToonFPS() {
			var viewer = this.SceneSession.GeefViewer();

			this.m_ToonFPS = (!this.m_ToonFPS);

			if (this.ToonFPS) {
				viewer.zetAnimatieCallback((animatieCanvas, deltaTijd, totaleTijd) => {
					this.FPSFrames = (this.FPSFrames + 1);
					this.FPSVerstreken = (this.FPSVerstreken + deltaTijd);
					if (this.FPSVerstreken > this.FPSUpdateInterval) {
						this.FPS = (this.FPSFrames * 1000 / this.FPSVerstreken);
						this.FPSFrames = (0);
						this.FPSVerstreken = (0);
						$('.wct-fps-counter').text((Math.round(this.FPS * 100) / 100) + " FPS");

						var fragmenten = viewer.list().length;
						var infos = _.map(viewer.list(), (id) => viewer.getInfo(id));

						//Aangezien de WebGL-viewer momenteel niet genoeg informatie publiek maakt om het effect van culling te kunnen tonen kan dit tijdens het debuggen
						//op de volgende manier aangezet worden:
						//- Draai zonder minified WebGL-scripts door in in woonconnect.config de volgende regel toe te voegen: <add key="MinifiedWebGL" value="False"/>
						//- Voeg aan de constructor in WebGLViewer.js de volgende regels toe net voor de regel "init(canvas, gl, opties);"
						//    this.privates = privates;
						//    this.bepaalMoetGetekend = bepaalMoetGetekend;
						if (viewer.privates && viewer.bepaalMoetGetekend) {
							var visible = _.filter(viewer.privates.fragmenten, (fragment) => viewer.bepaalMoetGetekend(fragment, viewer.privates.projectieMatrix.clippingFrustum));
							fragmenten = visible.length + " / " + fragmenten;
							infos = _.map(visible, (fragment) => viewer.getInfo((<any>fragment).ID));
						}

						var items = _.map(infos, (info) => {
							var opaakMatch: [string] = info.match(/itOpk=(\d+)\|(\d+)\|(\d+)\|(\d+)/);
							var itemsOpaak = _.reduce(_.map(opaakMatch.slice(1), (i) => parseInt(i)), (a: number, b: number) => a + b);
							var transpMatch: [string] = info.match(/itTrp=(\d+)\|(\d+)\|(\d+)\|(\d+)/);
							var itemsTransp = _.reduce(_.map(transpMatch.slice(1), (i) => parseInt(i)), (a: number, b: number) => a + b);
							var translaties = Math.max(1, parseInt(info.match(/trnLs=\((\d+)\)/)[1]));
							return (itemsOpaak + itemsTransp) * translaties;
						});
						var itemsTotaal = _.reduce(items, (a: number, b: number) => a + b, 0);

						//this.FpsFragmenten = fragmenten;

						//angular.$apply

						$('.wct-fps-details').html("Fragmenten: " + fragmenten + "<br>Items: " + itemsTotaal);
					}
				});
				viewer.setAnimatie(true);
			}
			else {
				viewer.zetAnimatieCallback(null);
				viewer.setAnimatie(false);
			}
		}

	}
