import { Component } from '@angular/core';
import * as _ from 'lodash';

import { FabrikantenConsumer } from '@consumers/FabrikantenController';

import { BCBProductsOverviewModel } from '@models/BCBProductsOverviewModel';

@Component({
  selector: 'fab-bestek',
  templateUrl: './FabBestek.html',
  styleUrls: ['./FabBestek.scss']
})

export class FabBestekComponent {
  Header: string;
  BCBProductsOverviewModel: BCBProductsOverviewModel;

  constructor(private fab: FabrikantenConsumer) {
    this.Header = "Laden...";

    //let input = new BCBProductInputModel();
    //this.fab.GetAllBCBProducts_Observable().subscribe((data) => {
    //   this.BCBProductsOverviewModel = new BCBProductsOverviewModel(data);
    //   this.Header = "";
    //});
  }
}
