import * as _ from 'lodash';
import { Component, OnInit, Inject, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { MatRadioChange, MatRadioButton } from '@angular/material/radio';
import { DocumentService } from '../../../infrastructure/ajs-upgraded-providers';
import { ContractorService } from '../../../tenderportal/services/ContractorService';
import { ContractorAllViewModel, ContractorRequestAllModel, ContractorViewModel, IContractorAllViewModel, IContractorRequestAllModel, IContractorViewModel } from '@models/ContractorViewModel';
import { LegalForm } from '@enums/ContractorViewModel';
import { IMeasureConsumer } from '@consumerinterfaces/MeasureController';
import { MeasureConsumer } from '@consumers/MeasureController';
import { ITradeAssociationConsumer } from '@consumerinterfaces/TradeAssociationController';
import { TradeAssociationConsumer } from '@consumers/TradeAssociationController';
import { TradeAssociationViewModel, ITradeAssociationViewModel, TradeAssociationsViewModel, ITradeAssociationsViewModel } from '@models/TradeAssociationViewModel';
import { ContractorTradeAssociationViewModel, IContractorTradeAssociationViewModel } from '@models/ContractorTradeAssociationViewModel';
import * as MeasureViewModel from '@models/MeasureViewModel';
import * as SubContractorViewModels from '@models/SubContractorViewModel';
import * as ConsumerInterfacesFotoController from '@consumerinterfaces/FotoController';
import * as ServicesDocumentService from '../../../services/DocumentService';
import * as DataContractsDocumentModel from '@datacontracts/DocumentModel';

@Component({
   selector: 'wct-account-settings-company-profile',
   styleUrls: ['./WctAccountSettingsCompanyProfile.scss'],
   templateUrl: './WctAccountSettingsCompanyProfile.html'
})

export class WctAccountSettingsCompanyProfile {
   public ActiveSubpage: string = "Bedrijfsgegevens";
   public LegalForms: Array<WctAccountSettingsCompanyProfileLegalForm> = new Array<WctAccountSettingsCompanyProfileLegalForm>();
   public Measures: MeasureViewModel.MeasuresViewModel;
   public TradeAssociations: TradeAssociationsViewModel = null;

   private LoadingMeasures: boolean = true;
   private LoadingTrade: boolean = true;

   constructor(
      public contractorservice: ContractorService,
      @Inject(MeasureConsumer) private measureconsumer: IMeasureConsumer,
      @Inject(DocumentService) private documentservice: ServicesDocumentService.IDocumentService,
      @Inject(TradeAssociationConsumer) private tradeassociationconsumer: ITradeAssociationConsumer
   ) {
      'ngInject';
      this.LoadMeasures();
      this.GetTradeAssociations();
   }

   ngOnInit() {
      this.SetLegalForms();
   }

   public GetCurrentContractor(): ContractorViewModel {
      return this.contractorservice.CurrentContractor;
   }

   public IsLoading(): boolean {
      return this.contractorservice.IsLoading() || this.LoadingMeasures || this.LoadingTrade;
   }

   public GetTradeAssociations(): void {
      this.LoadingTrade = true;

      this.tradeassociationconsumer.GetTradeAssociations().then((tempmodel: ITradeAssociationsViewModel) => {
         this.TradeAssociations = new TradeAssociationsViewModel(tempmodel);
         this.LoadingTrade = false;
      });
   }

   public IsAllowedToAddSubContractors(): boolean {
      return this.contractorservice.SubContractorsInvite == "Ja";
   }

   public SaveCurrentContractor(): void {
      this.contractorservice.SaveCurrentContractor();
   }

   public AddSubContractor(): void {

      var model = new SubContractorViewModels.SubContractorViewModel();
      model.ContractorId = this.contractorservice.CurrentContractor.Id;

      this.contractorservice.CurrentContractor.SubContractors.push(model);
   }

   public GetMeasuresTradeAssociations(): MeasureViewModel.IMeasureViewModel[] {
      var measures = new Array<MeasureViewModel.IMeasureViewModel>();

      for (var i = 0; i < this.TradeAssociations.Measures.length; i++) {
         for (var j = 0; j < this.GetCurrentContractor().Measures.length; j++) {
            if (this.TradeAssociations.Measures[i].Id == this.GetCurrentContractor().Measures[j].Id) {
               measures.push(this.TradeAssociations.Measures[i]);
            }
         }
      }

      return measures;
   }

   public RemoveSubContractor(subcontractor: SubContractorViewModels.SubContractorViewModel): void {
      var index = -1;

      for (var i = 0; i < this.contractorservice.CurrentContractor.SubContractors.length; i++) {
         if (this.contractorservice.CurrentContractor.SubContractors[i].Id == subcontractor.Id) {
            index = i;
         }
      }

      if (index != -1) {
         this.contractorservice.CurrentContractor.SubContractors.splice(index, 1);
         this.contractorservice.SaveCurrentContractor();
      }
   }

   public SetMenuItem(menuitem: string): void {
      this.ActiveSubpage = menuitem;
   }

   public SetCurrentContractor(): void {

   }

   public GetYearsForFoundation(): Array<number> {
      let ret: Array<number> = new Array<number>();
      let currentYear = new Date().getFullYear();

      for (let i: number = 1900; i < currentYear; i++) {
         //text += cars[i] + "<br>";
         ret.push(i);
      }
      return ret;
   }

   public SetLegalForms(): void {
      let ret: Array<any> = new Array<any>();
      let legalforms = Object.keys(LegalForm).filter(k => isNaN(<any>k));

      _.forEach(legalforms, (legalFormValue, legalFormIndex) => {
         let legalform: WctAccountSettingsCompanyProfileLegalForm = new WctAccountSettingsCompanyProfileLegalForm();
         legalform.Id = legalFormIndex;
         legalform.Name = legalFormValue;
         ret.push(legalform);
      });
      this.LegalForms = ret;
   }

   public HandleFileInput(pTA: TradeAssociationViewModel, files: FileList): void {
      if (files.length > 0) {
         var file: File = files.item(0);
         this.contractorservice.mIsLoadingCurrentContractor = true;

         this.documentservice.GeefContractorMap().then((map) => {
            var errfiles;
            this.documentservice.UploadFiles(map, files, errfiles).then((document) => {
               var CTA = new ContractorTradeAssociationViewModel();

               CTA.Approved = false;
               CTA.ContractorId = this.contractorservice.CurrentContractor.Id;
               CTA.Document = document.data[0];
               CTA.ExplanationByAdministrator = "";
               CTA.ExplanationByContractor = "";
               CTA.TradeAssociationId = pTA.Id;

               this.contractorservice.CurrentContractor.ContractorTradeAssociations.push(CTA);
               this.contractorservice.SaveCurrentContractor();
            });
         });
      }
   }

   public DocumentMimePreviewUrl(doc: DataContractsDocumentModel.IDocumentModel): string {
      if (doc != undefined) {
         return this.documentservice.GeefPreviewUrl(doc, true);
      }
      else {
         return "";
      }
   }

   public RemoveEvidence(CTA: ContractorTradeAssociationViewModel) {
      var index = -1;

      for (var i = 0; i < this.contractorservice.CurrentContractor.ContractorTradeAssociations.length; i++) {
         if (this.contractorservice.CurrentContractor.ContractorTradeAssociations[i].Id == CTA.Id) {
            index = i;
         }
      }

      if (index != -1) {
         this.contractorservice.CurrentContractor.ContractorTradeAssociations.splice(index, 1);
         this.contractorservice.SaveCurrentContractor();
      }
   }

   public GetCheckedState(measure: MeasureViewModel.MeasureViewModel): boolean {
      if (this.contractorservice.CurrentContractor != null) {
         var match = _.find(this.contractorservice.CurrentContractor.Measures, (b) => b.Id == measure.Id);
         if (match != null) {
            return true;
         }
      }

      return false;
   }

   public GetDisabledState(measure: MeasureViewModel.MeasureViewModel): boolean {
      return false;
   }

   public ToggleMeasure(measure: MeasureViewModel.MeasureViewModel): void {
      if (this.contractorservice.CurrentContractor != null) {
         var tFound = -1;

         for (var i = 0; i < this.contractorservice.CurrentContractor.Measures.length; i++) {
            if (this.contractorservice.CurrentContractor.Measures[i].Id == measure.Id) {
               tFound = i;
               break;
            }
         }

         if (tFound == -1) {
            this.contractorservice.CurrentContractor.Measures.push(measure);
         }
         else {
            this.contractorservice.CurrentContractor.Measures.splice(tFound, 1);
         }

         this.contractorservice.SaveCurrentContractor();
      }
   }

   public IsTAChecked(tradeassociation: TradeAssociationViewModel): boolean {
      if (this.GetCurrentContractor() != null) {
         for (var i = 0; i < this.GetCurrentContractor().ContractorTradeAssociations.length; i++) {
            if (tradeassociation.Id == this.GetCurrentContractor().ContractorTradeAssociations[i].TradeAssociationId) {
               return true;
            }
         }
      }

      return false;
   }

   public ChangeTA(mrChange: MatRadioChange, tradeassociation: TradeAssociationViewModel) {

      let mrButton: MatRadioButton = mrChange.source;

      if (mrButton.value) {
         if (this.GetContractorTradeAssociations(tradeassociation).length == 0) {
            var CTA = new ContractorTradeAssociationViewModel();

            CTA.Approved = false;
            CTA.ContractorId = this.contractorservice.CurrentContractor.Id;
            CTA.Document = null;
            CTA.ExplanationByAdministrator = "";
            CTA.ExplanationByContractor = "";
            CTA.TradeAssociationId = tradeassociation.Id;

            this.contractorservice.CurrentContractor.ContractorTradeAssociations.push(CTA);
         }
      }
      else {
         this.RemoveContractorTradeAssociations(tradeassociation);
      }

      this.contractorservice.SaveCurrentContractor();
   }

   public RemoveContractorTradeAssociations(pTA: TradeAssociationViewModel): void {
      if (this.GetCurrentContractor() != undefined) {
         for (var i = this.GetCurrentContractor().ContractorTradeAssociations.length - 1; i >= 0; i--) {
            if (this.GetCurrentContractor().ContractorTradeAssociations[i].TradeAssociationId == pTA.Id) {
               this.GetCurrentContractor().ContractorTradeAssociations.splice(i, 1);
            }
         }
      }
   }

   public GetContractorTradeAssociations(pTA: TradeAssociationViewModel): Array<IContractorTradeAssociationViewModel> {
      var array = new Array<IContractorTradeAssociationViewModel>();

      if (this.GetCurrentContractor() != undefined) {
         for (var i = 0; i < this.GetCurrentContractor().ContractorTradeAssociations.length; i++) {
            if (this.GetCurrentContractor().ContractorTradeAssociations[i].TradeAssociationId == pTA.Id) {
               array.push(this.GetCurrentContractor().ContractorTradeAssociations[i]);
            }
         }
      }

      return array;
   }

   private LoadMeasures(): void {
      this.LoadingMeasures = true;

      this.measureconsumer.GetMeasures().then((measuresmodel: MeasureViewModel.IMeasuresViewModel) => {
         this.Measures = new MeasureViewModel.MeasuresViewModel(measuresmodel);
         this.LoadingMeasures = false;
      });

   }
}

class WctAccountSettingsCompanyProfileLegalForm {
   public Id: number;
   public Name: string;

   constructor(
   ) {
   }
}
