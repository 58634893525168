import { Component } from "@angular/core";
import { ImageAnnotationProject } from "../data/woonconnectkern/models/GetAllProjectsModel";
import { ImageAnnotationPhotoSet } from "../data/woonconnectkern/models/GetPhotoSetsModel";
import { AccountService } from "../services/AccountService";
import { ImageAnnotationService } from "../services/ImageAnnotationService";

@Component({
    selector: 'image-annotation-start',
    templateUrl: './WctImageAnnotationStart.html'
})
export class WctImageAnnotationStartComponent {

    userId: number;
    username: string;
    projects: ImageAnnotationProject[] = [];
    photoSets: ImageAnnotationPhotoSet[] = [];
    selectedProject: ImageAnnotationProject = new ImageAnnotationProject();
    selectedPhotoSet: ImageAnnotationPhotoSet = new ImageAnnotationPhotoSet();
    dataIsLoaded: boolean = false;
    projectSelected: boolean = false;

    constructor(private imageAnnotationService: ImageAnnotationService, private accountService: AccountService) {
        this.userId = accountService.GeefHuidigPersoonID();
        this.username = accountService.GeefLoginNaam();
        this.imageAnnotationService.getProjectsFromUser(this.userId).subscribe(response => {
            this.projects = response;
            this.selectedProject = this.projects[0];
            this.imageAnnotationService.getPhotoSetsFromProject(this.selectedProject.Id).subscribe(response => {
                this.photoSets = response;
                this.selectedPhotoSet = this.photoSets[0];
                this.projectSelected = true;
            });
            this.dataIsLoaded = true;
        });
    }

    getPhotoSets(event) {
        this.imageAnnotationService.getPhotoSetsFromProject(event.value.Id).subscribe(response => {
            this.photoSets = response;
            this.selectedPhotoSet = this.photoSets[0];
            this.projectSelected = true;
        });
    }
}