import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wct-stui-loader',
  templateUrl: './wct-stui-loader.component.html',
  styleUrls: ['./wct-stui-loader.component.scss']
})
export class WctStuiLoaderComponent implements OnInit {
  @Input() NoMessage: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
