import * as _ from 'lodash';
import * as DataContractsConstanten from '@datacontracts/Constanten';
import * as HelpersBackwards from '.././Helpers/Backwards';
//import from './formatnumber';
//import * as dtc from '../../bower_components/Detect.js/detect';
// import * as dtc from 'detect.js';



export class BezigContext {
	Bezig: boolean;
	//Gewijzigd: () => void;
	Parent: BezigCollectie;

	constructor(coll: BezigCollectie) {
		this.Parent = coll;
	}

	public Waarde(b: boolean): boolean {
		if (b != null) {
			this.Bezig = b;
			this.Parent.Herbereken();
		}
		return this.Bezig;
	}
}



export class BezigCollectie {
	Contexten: Array<BezigContext> = [];
	Observable: boolean;

	constructor() {
		this.Observable = null;
	}

	public Herbereken() {
		var ok = _.some(this.Contexten, (c) => c.Bezig);
		this.Observable = (ok);
	}

	public MaakContext(): BezigContext {
		var ctx: BezigContext = new BezigContext(this);
		this.Contexten.push(ctx);
		return ctx;
	}

}

export interface SVGDocument {
}

export interface MouseEvent {
	new (name: string, args: any);
}

export interface Window {
	Event: Event;
}



//interface NavigatorUserMedia {
//	webkitGetUserMedia?(constraints: MediaStreamConstraints, successCallback: NavigatorUserMediaSuccessCallback, errorCallback: NavigatorUserMediaErrorCallback): void;
//	mozGetUserMedia?(constraints: MediaStreamConstraints, successCallback: NavigatorUserMediaSuccessCallback, errorCallback: NavigatorUserMediaErrorCallback): void;
//	msGetUserMedia?(constraints: MediaStreamConstraints, successCallback: NavigatorUserMediaSuccessCallback, errorCallback: NavigatorUserMediaErrorCallback): void;
//	oGetUserMedia?(constraints: MediaStreamConstraints, successCallback: NavigatorUserMediaSuccessCallback, errorCallback: NavigatorUserMediaErrorCallback): void;
//}

interface MediaDevices {
	getUserMedia(constraints: MediaStreamConstraints): Promise<MediaStream>;
	enumerateDevices(): Promise<MediaDeviceInfo[]>;
}

export function queryWebGLSupport(): any {
	var w: any = window;
	return w.queryWebGLSupport();
}

export function VoldoetScherm() { }
export function getViewer3D(canvas, opties): any { return null; }
export var UnitTestMode: boolean;
export var ModuleViewer3D: any;

//declare function Profile(s);
export var OptiesTonenBijHuurder: boolean;

export var Gebruik_Angular_Routing: boolean = true;
export var Gebruik_Server_logging: boolean = true;

export var uimode: string;

export function wct_detect_ie(): boolean {
	var ua = window.navigator.userAgent;

	var msie = ua.indexOf('MSIE ');
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) != 0;
	}

	var trident = ua.indexOf('Trident/');
	if (trident > 0) {
		// IE 11 => return version number
		var rv = ua.indexOf('rv:');
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) != 0;
	}

	var edge = ua.indexOf('Edge/');
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10) != 0;
	}

	// other browser
	return false;
}

export var detect: any;
export var JL: any;
export function getCookie(s: string): string { return null; }

export function Install_BeforeUnloadDebuggerEvent() { }

// HelpersBackwards.EindWachten(s: string);
// HelpersBackwards.BeginWachten(s: string) { }
// HelpersBackwards.BeginWachten(s: string, s2: string);

export function BekijkViewModel_InstalleerOptieAangepast() { }
export function AlsBedrag(waarde: number): string {
	//waarde.formatMoney(waarde, 2, ',', '.');
	if (isNaN(waarde)) {
		return "NaN";
	}
	return formatMoney(waarde, 2, ',', '.');
}

export function disposeViewer3D(canvas: string) {
	this.ModuleViewer3D.disposeViewer3D(canvas);
}

export function RedirectNaarFunctieInPagina(Functienaam: string, text: string, url: string) { }

//declare function VerbergMenuAccordionOverlay(b);

//declare var BekijkViewModelInstance: Bekijk.BekijkViewModel;

//declare function getViewer3D(canvas, opties);
//function  HelpersBackwards.EindWachten();
//declare function Profile(s);
export var Belichting: any;
//declare var ScenarioIndexViewModelInstance: any;
export var OptiebordViewModelInstance: any;
//declare var KostenViewModelInstance: any;
export var SemantiekViewModelInstance: any;
export var LocatieKeuzePaginaViewModelInstance: any;
//declare var LocatieKeuzeViewModelInstance: BreadCrumb.LocatieKeuzeViewModel;
export var HuurpuntenViewModelInstance: any;
//declare var NotitieViewModelInstance: Notitie.RasterLocatiesViewModel;
//declare var NotitieViewModelInstance: WoningOpname.WoningOpnameViewModel;
//declare var WoningOpnameViewModelInstance: WoningOpname.WoningOpnameViewModel;
export var InmeetViewModelInstance: any;

import * as JSNLog from 'jsnlog';

//declare function Profile

//declare var OptiesViewModelInstance: OptieMenu.ViewModel;

//alert('backward aangeroepen');

export var SVGWidth: number;
export var SVGHeight: number;

export var SVGDocument: any;

export var ConstSoftware: DataContractsConstanten.ISoftware;

export var HuurderWeergave: boolean; // Om 3d weergaven te sturen voor huurders

export var jsBeginInVogelvlucht: boolean;

export var zonderBindings: boolean; // om in oudere knockout ts/js te gebruiken om de bindings niet te laten maken.

export var StartState: string = "";
export var StartUrl: string = "";
export var ServerMelding: string = "";

export function InitInitial() {
	var w: any = window;

	ConstSoftware = w.ConstSoftware;
	ModuleViewer3D = w.ModuleViewer3D;
	JL = JSNLog.JL;
	//detect = dtc;

	StartState = w.StartState;
	StartUrl = w.StartUrl;
	ServerMelding = w.ServerMelding;

	if (ConstSoftware.BuildMode === 'DEBUG') {
		Gebruik_Server_logging = false;
	}
	//this.detect = dtc;
}

export function formatMoney(n: number, numberOfDecimalPlaces?: number, decimalSeparator?: string, thousandsSeparator?: string) {
	numberOfDecimalPlaces = isNaN(numberOfDecimalPlaces = Math.abs(numberOfDecimalPlaces)) ? 2 : numberOfDecimalPlaces;
	decimalSeparator = decimalSeparator == undefined ? "," : decimalSeparator;
	thousandsSeparator = thousandsSeparator == undefined ? "." : thousandsSeparator;
	let sign = n < 0 ? "-" : "";
	let nFixed = Math.abs(n).toFixed(numberOfDecimalPlaces);
	let nFloor = parseInt(nFixed);
	let i = nFloor.toString();
	let numberOfDigits = i.length;
	let numberOfDigitsBeforeFirstThousandsGroup = numberOfDigits > 3 ? numberOfDigits % 3 : 0;

	let decimals = Math.abs(n - nFloor);

	return sign + (numberOfDigitsBeforeFirstThousandsGroup ? i.substr(0, numberOfDigitsBeforeFirstThousandsGroup) + thousandsSeparator : "") + i.substr(numberOfDigitsBeforeFirstThousandsGroup).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (numberOfDecimalPlaces ? decimalSeparator + decimals.toFixed(numberOfDecimalPlaces).slice(2) : "");
};

// interface Number {
// 	formatMoney(c: number, d?: string, t?: string): string;
// }


// Number.prototype.formatMoney = function (numberOfDecimalPlaces?: number, decimalSeparator?: string, thousandsSeparator?: string) {
// 	let n: number = +this || 0;
// 	return formatMoney(n, numberOfDecimalPlaces, decimalSeparator, thousandsSeparator);
// };

let BeginWachtenCalls = 0;
let WachtenStack = [];

function GeefCallerName(f: Function) {
	try {
		if (f != null) {
			return f.name;
		}
	} catch (e) {
		// In 'use strict' mag je "caller" niet raadplegen!
	}
	return "root call";
}

//, stackMsg: number
export function BeginWachten(bericht: string) {
	//BeginWachtenCalls++;
	//var benErAl = $(".wachten").is(":visible");

	//var wachten = $(".wachten");
	//var wachtenIcoon = $(".wachten-icoon");
	//var wachtTekst = $(".wachten-info");
	//if ((bericht != null && bericht.length > 0) || benErAl == false) {
	//	$(wachtTekst).html(bericht);
	//}
	//var wachtImg = $(wachtenIcoon).find(".img");
	//if (wachtImg != null) {
	//	$(wachtImg).attr("title", bericht);
	//	$(wachtImg).attr("alt", bericht);
	//}
	//$(".wachten").show();

	////if (nocenter != undefined) {
	//$('.wachten-inner').css({ top: '40%', left: '50%', margin: '-' + ($('.wachten-inner').height() / 2) + 'px 0 0 -' + ($('.wachten-inner').width() / 2) + 'px' });
	//$(".wachten-inner").center();
	//}
}

export function EindWachtenAlles() {
	//BeginWachtenCalls = 0;
	//HelpersBackwards.EindWachten();
}

//stackMsg
export function EindWachten() {
	//BeginWachtenCalls--;
	//if (BeginWachtenCalls <= 0) {
	//	BeginWachtenCalls = 0;
	//	while (WachtenStack.length > 0) {
	//		WachtenStack.pop(); // empty the array
	//	}
	//	$(".wachten").hide();
	//}
}












