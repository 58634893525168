import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule, Routes } from '@angular/router';
import { PopoverModule } from "ngx-smart-popover";

import { CoreUiModule } from '../coreui/coreui.module';
import { TranslateModule } from './translate.module';
import { ViewerModule } from '../viewers/viewer.module';
import { WctDocumentMapComponent } from '../components/documenten/WctDocumentMap';
import { WctDocumentComponent } from '../components/documenten/WctDocument';
import { WctDocumentenComponent } from '../components/documenten/WctDocumenten';
import { WctDocumentPopupComponent } from '../components/documenten/WctDocumentPopup';
import { WctDocumentenPopupComponent } from '../components/documenten/WctDocumentenPopup';
import { WctDocumentMapPopupComponent } from '../components/documenten/WctDocumentMapPopup';
import { WctRechtenControlComponent } from '../components/rechtencontrol/WctRechtenControl';
import { DocumentService } from '../services/DocumentService';
import { CapabilitiesService } from '../viewers/CapabilitiesService';
import { AccountService } from '../services/AccountService';
import { OrderByService } from '../services/OrderByService';
import { ProjectService } from '../services/ProjectService';
import { PersoonService } from '../services/PersoonService';
import { DocumentenConsumer } from '@consumers/DocumentenController';
 
const routes: Routes = [
    { path: 'documenten', component: WctDocumentenComponent }
]

@NgModule({
    imports: [   
        RouterModule,
        CommonModule,
        CoreUiModule,
        PopoverModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        FormsModule,
        TranslateModule,
        HttpClientModule,
        ViewerModule
    ],
    exports: [
        CoreUiModule,
        WctDocumentMapComponent,
        WctDocumentComponent,
        WctDocumentenComponent
    ],
    declarations: [
        WctDocumentMapComponent,
        WctDocumentComponent,
        WctDocumentenComponent,
        WctDocumentPopupComponent,
        WctDocumentenPopupComponent,
        WctDocumentMapPopupComponent,
        OrderByService,
        WctRechtenControlComponent
    ],
    entryComponents: [
        WctDocumentMapComponent,
        WctDocumentComponent,
        WctDocumentenComponent,
        WctDocumentPopupComponent,
        WctDocumentenPopupComponent,
        WctDocumentMapPopupComponent,
        WctRechtenControlComponent
    ],
    bootstrap: [
    ],
    providers: [
        // DocumentService,
        // CapabilitiesService,
        // AccountService,
        // DocumentenConsumer,
        // ProjectService,
        // PersoonService,
        {provide: 'UPLOAD_PLACEHOLDER', useValue: 'placeholder for use in the documentService'}
    ]
})

export class DocumentModule {

}
