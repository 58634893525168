/**
 * @ref WoonConnectViewModel/Project/SaveProjectSettingsModel.cs
 */

 import * as DataContracts from "@datacontracts/SaveProjectSettingsModel";

 
import * as ProjectSettingsModel from "./ProjectSettingsModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Project.SaveProjectSettingsRequestModel
	 */
	 

	export interface ISaveProjectSettingsRequestModel {
		ProjectId: number;
		ProjectSettings: ProjectSettingsModel.IProjectSettingsAngular[];
		ToJsonContract(): DataContracts.ISaveProjectSettingsRequestModel;
	}

	export class SaveProjectSettingsRequestModel {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		public get ProjectSettings(): ProjectSettingsModel.IProjectSettingsAngular[] {
			return this._ProjectSettings;
		}
		public set ProjectSettings(newProjectSettings: ProjectSettingsModel.IProjectSettingsAngular[]) {
			this._ProjectSettings = newProjectSettings;
		}

		// field-Properties

		// fields
		protected _ProjectId: number = 0;
		protected _ProjectSettings: ProjectSettingsModel.IProjectSettingsAngular[] = [];

		constructor(data?: DataContracts.ISaveProjectSettingsRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISaveProjectSettingsRequestModel): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				this._ProjectSettings = data.ProjectSettings ? data.ProjectSettings.map(projectSettingsAngularItem => new ProjectSettingsModel.ProjectSettingsAngular(projectSettingsAngularItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISaveProjectSettingsRequestModel {
			return {
				ProjectId: this.ProjectId,
				ProjectSettings: (this.ProjectSettings ? this.ProjectSettings.map(projectSettingsAngularItem => new ProjectSettingsModel.ProjectSettingsAngular(projectSettingsAngularItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Project.SaveProjectSettingsResponseModel
	 */
	 

	export interface ISaveProjectSettingsResponseModel {
		Success: boolean;
		Message: string;
	}

	export class SaveProjectSettingsResponseModel {
		// property-Properties
		public get Success(): boolean {
			return this._Success;
		}
		public set Success(newSuccess: boolean) {
			this._Success = newSuccess;
		}

		public get Message(): string {
			return this._Message;
		}
		public set Message(newMessage: string) {
			this._Message = newMessage;
		}

		// field-Properties

		// fields
		protected _Success: boolean = false;
		protected _Message: string = "";

		constructor(data?: DataContracts.ISaveProjectSettingsResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISaveProjectSettingsResponseModel): void {
			if(data) {
				// vul properties
				this._Success = data.Success;
				this._Message = data.Message;
				// vul fields
			}
		}
	}
