
<div class="maindiv">
  Afmelden

  <div *ngIf="Loading()">
    Laden...
  </div>

  <div *ngIf="!Loading()">

    <div *ngIf="Token == ''">
      <div>
        <span>{{ SafeUrl }}</span>
      </div>

      <a [href]="SafeUrl">Inloggen</a>

      <!--<embed src="https://ide.geeksforgeeks.org"
       width="400"
       height="400" />-->
    </div>

    <div *ngIf="Token != ''">
      <div>
        <a href="javascript: void()" (click)="StartAfmelden()">Start afmelden</a>
      </div>

      <div *ngIf="Output != undefined">
        <div>
          Statuscode: {{ Output.StatusCode }}
        </div>

        <div>
          {{ Output.Content }}
        </div>
      </div>
      
    </div>
  </div>
</div>

