/**
 * @ref WoonConnectViewModel/User/UserDeleteModel.cs
 */

 import * as DataContracts from "@datacontracts/UserDeleteModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.User.UserDeleteRequestModel
	 */
	 

	export interface IUserDeleteRequestModel {
		Persoonid: number;
		ToJsonContract(): DataContracts.IUserDeleteRequestModel;
	}

	export class UserDeleteRequestModel {
		// property-Properties
		public get Persoonid(): number {
			return this._Persoonid;
		}
		public set Persoonid(newPersoonid: number) {
			this._Persoonid = newPersoonid;
		}

		// field-Properties

		// fields
		protected _Persoonid: number = 0;

		constructor(data?: DataContracts.IUserDeleteRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserDeleteRequestModel): void {
			if(data) {
				// vul properties
				this._Persoonid = data.Persoonid;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUserDeleteRequestModel {
			return {
				Persoonid: this.Persoonid
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.UserDeleteResponseModel
	 */
	 

	export interface IUserDeleteResponseModel {
		TekstUit: string;
	}

	export class UserDeleteResponseModel {
		// property-Properties
		// field-Properties
		public get TekstUit(): string {
			return this._TekstUit;
		}
		public set TekstUit(newTekstUit: string) {
			this._TekstUit = newTekstUit;
		}

		// fields
		protected _TekstUit: string = "";

		constructor(data?: DataContracts.IUserDeleteResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserDeleteResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._TekstUit = data.TekstUit;
			}
		}
	}
