<div class="content">
    <a routerLink="/" class="logo">
        <img src="/Content/KlantSpecifiek/BCB21097/logo_nl.png">
    </a>
    <!--<ul class="menu">
      <li><a routerLink="/" [class.active]="router.url === '/'">Home</a></li>-->
      <!--<li><a routerLink="/categorie/1" [class.active]="router.url === '/categorie/1'">Gevel</a></li>
      <li><a routerLink="/categorie/2" [class.active]="router.url === '/categorie/2'">Deuren</a></li>
      <li><a routerLink="/categorie/3" [class.active]="router.url === '/categorie/3'">Schuifsystemen</a></li>
      <li><a routerLink="/categorie/4" [class.active]="router.url === '/categorie/4'">Vliesgevels</a></li>-->
    <!--</ul>-->
</div>
