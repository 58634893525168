import * as _ from 'lodash';
import * as ViewModelsGebouwBrok from '.././Models/GebouwBrok';
import * as ViewModelsGebouwBrokOptie from '.././Models/GebouwBrokOptie';
import * as ViewModelsWoningInstantie from '.././Models/WoningInstantie';
import * as ViewModelsInstantieDeel from '.././Models/InstantieDeel';
import * as ViewModelsIGebouwBrokKeuze from '.././Models/IGebouwBrokKeuze';




	export class InstantieOptieKosten {
		public KostenNumeriek: number;
		public Werkomschrijving: string;
		public Werktoelichting: string;
		public OptieId: number;
	}

	export class InstantieDeel {
		DeelID: string;
		ActieveOptie: string;
		ToegestaneOpties: Array<string> = [];
		ToegestaneKeuzes: Array<ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze> = [];
		OptieKosten: Array<ViewModelsInstantieDeel.InstantieOptieKosten> = [];
		constructor(public WoningInstantie: ViewModelsWoningInstantie.WoningInstantie, data: any
		) {
			Object.assign(this, data)
			//ng.extend(this, data);
			//this.ToegestaneKeuzes = deel.BepaalToegestaneKeuzes(this.WoningInstantie);

			//if (deel.IsMateriaalBrok) {
			//    var toegestaan = _.filter(deel.Opties, (o) => o.Tonen(deel, WoningInstantie));
			//    var materialen = _.map(toegestaan, (o) => WoningInstantie.Typologie.WoningType.MateriaalSets[o.OptieId].GeefMaterialen());
			//    var allematerialen = [];
			//    allematerialen.concat.apply(allematerialen, materialen);
			//    this.Materialen = _.uniq(allematerialen);
			//}
		}

		GeefToegestaneNamen(): Array<string> {
			var definitie = this.GeefDefinitie();

			var ret = _.map(this.ToegestaneOpties, (o) => {
				var optiedef = _.find(definitie.Opties, (o2) => o2.OptieId == o.toString());
				return optiedef.OptieNaam;
			});

			return ret;
		}

		GeefActieveOptie(): ViewModelsGebouwBrokOptie.GebouwBrokOptie {
			var definitie = this.GeefDefinitie();
			if (definitie == null) {
				return null;
			}
			var optiedefinitie = _.find(definitie.Opties, (o) => o.OptieId == this.ActieveOptie);
			if (optiedefinitie == null) {
				return null;
			}

			return optiedefinitie;
		}

		GeefDefinitie(): ViewModelsGebouwBrok.GebouwBrok {
			return this.WoningInstantie.Typologie.WoningType.GeefGebouwDeel(this.DeelID);
		}

		GeefInstantieKosten(optie: ViewModelsGebouwBrokOptie.GebouwBrokOptie): InstantieOptieKosten {
			var kosten = _.find(this.OptieKosten, (k) => {
				return (k.OptieId.toString() == optie.OptieId);
			});
			return kosten;
		}

		BepaalKeuzes() {
			if (this.WoningInstantie.Typologie == null) {
				console.log("WoningInstantie met onbekende typologie aangetroffen");
				this.ToegestaneKeuzes = [];
			}
			else {
				var deel = this.WoningInstantie.Typologie.WoningType.GeefGebouwDeel(this.DeelID);
				this.ToegestaneKeuzes = deel.BepaalToegestaneKeuzes(this.WoningInstantie);
			}
		}
	}

