import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {  selectMenu } from './menu.actions';
import { SubMenus, MenuState, initialMenuState } from './menu.state';


export const menuFeatureKey = 'menu';

const _menuReducer = createReducer(
  initialMenuState,
//   on(loadMenu, (state, { menus }) => adapter.addMany(menus, state)),
  on(selectMenu, (state, { subMenu }) => {
      let newState = {...state, subMenu: subMenu };
      return newState;
  })
);

export function menuReducer(state, action) {
  return _menuReducer(state, action);
}