/**
 * @ref WoonConnectViewModel/Login/LoginScreenSettingsModel.cs
 */

 import * as DataContracts from "@datacontracts/LoginScreenSettingsModel";

 
import * as LogonStatus from "./LogonStatus";



	/**
	 * Originele namespace: WoonConnectViewModel.Login.LoginScreenSettingsRequestModel
	 */
	 

	export interface ILoginScreenSettingsRequestModel {
		ToJsonContract(): DataContracts.ILoginScreenSettingsRequestModel;
	}

	export class LoginScreenSettingsRequestModel {
		// property-Properties
		// field-Properties

		// fields

		constructor(data?: DataContracts.ILoginScreenSettingsRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ILoginScreenSettingsRequestModel): void {
			if(data) {
				// vul properties
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ILoginScreenSettingsRequestModel {
			return {
				
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Login.LoginScreenSettingsResponseModel
	 */
	 

	export interface ILoginScreenSettingsResponseModel {
		LogonStatus: LogonStatus.ILogonStatus;
	}

	export class LoginScreenSettingsResponseModel {
		// property-Properties
		// field-Properties
		public get LogonStatus(): LogonStatus.ILogonStatus {
			return this._LogonStatus;
		}
		public set LogonStatus(newLogonStatus: LogonStatus.ILogonStatus) {
			this._LogonStatus = newLogonStatus;
		}

		// fields
		protected _LogonStatus: LogonStatus.ILogonStatus = new LogonStatus.LogonStatus(undefined);

		constructor(data?: DataContracts.ILoginScreenSettingsResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ILoginScreenSettingsResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._LogonStatus = data.LogonStatus ? new LogonStatus.LogonStatus(data.LogonStatus): undefined;
			}
		}
	}
