<div class="content">

  <div class="popup-window" *ngIf="PopupTonen()">
    <div class="popup-window-inner">
      <div *ngIf="OffertePopupTonen">
        <div class="header">
          Offerte aanvragen
        </div>
        <div class="offerte-inner">
          <table style="width: 100%;">
            <tr>
              <td>
                Bedrijfsnaam aanvrager *
              </td>
              <td>
                <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size" [ngClass]="{ 'field-invalid' : !IsBedrijfsnaamValid() }">
                  <input matInput [(ngModel)]="OfferteAanvraagViewModel.BedrijfsnaamAanvrager" type="text" class="text-veld">
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                Plaats bedrijf *
              </td>
              <td>
                <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size" [ngClass]="{ 'field-invalid' : !IsPlaatsBedrijfValid() }">
                  <input matInput [(ngModel)]="OfferteAanvraagViewModel.PlaatsBedrijf" type="text" class="text-veld">
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                Bedrijfsactiviteit
              </td>
              <td>
                <select [(ngModel)]="OfferteAanvraagViewModel.Bedrijfsactiviteit" style="width: 100%">
                  <option value="Aannemer/Gevelbouwer">Aannemer/Gevelbouwer</option>
                  <option value="Architect/Bouwadviesbureau">Architect/Bouwadviesbureau</option>
                  <option value="Bouwmaterialenhandelaar">Bouwmaterialenhandelaar</option>
                  <option value="Woningbouwvereniging/opdrachtgever">Woningbouwvereniging/opdrachtgever</option>
                  <option value="Particulier">Particulier</option>
                  <option value="Overige">Overige</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>
                Naam contactpersoon *
              </td>
              <td>
                <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size" [ngClass]="{ 'field-invalid' : !IsNaamContactPersoonValid() }">
                  <input matInput [(ngModel)]="OfferteAanvraagViewModel.NaamContactPersoon" type="text" class="text-veld">
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                Tel. contactpersoon *
              </td>
              <td>
                <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size" [ngClass]="{ 'field-invalid' : !IsTelContactPersoonValid() }">
                  <input matInput [(ngModel)]="OfferteAanvraagViewModel.TelContactPersoon" type="text" class="text-veld">
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                E-mail contactpersoon *
              </td>
              <td>
                <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size" [ngClass]="{ 'field-invalid' : !IsEmailContactPersoonValid() }">
                  <input matInput [(ngModel)]="OfferteAanvraagViewModel.EmailContactPersoon" type="text" class="text-veld">
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                Uitvoeringdatum project
              </td>
              <td>
                <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size">
                  <input matInput [(ngModel)]="OfferteAanvraagViewModel.UitvoeringsDatumProject" type="text" class="text-veld">
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                Aantal m<sup>2</sup> project
              </td>
              <td>
                <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size">
                  <input matInput [(ngModel)]="OfferteAanvraagViewModel.AantalM2Project" type="text" class="text-veld">
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                Heeft u een voorkeur voor een handelaar?
              </td>
              <td style="padding-top: 10px; padding-bottom: 10px;">
                <mat-radio-group [(ngModel)]="OfferteAanvraagViewModel.VoorkeurHandelaar">
                  <mat-radio-button [value]="'Ja'">
                    <span class="wrap-mat-radio-label">
                      Ja
                    </span>
                  </mat-radio-button>
                  <mat-radio-button [value]="'Nee'" style="margin-left: 10px;">
                    <span class="wrap-mat-radio-label">
                      Nee
                    </span>
                  </mat-radio-button>
                </mat-radio-group>
              </td>
            </tr>
            <tr *ngIf="OfferteAanvraagViewModel.VoorkeurHandelaar == 'Ja'">
              <td>
                Naam handelaar
              </td>
              <td>
                <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size">
                  <input matInput [(ngModel)]="OfferteAanvraagViewModel.HandelaarNaam" type="text" class="text-veld">
                </mat-form-field>
              </td>
            </tr>
            <tr *ngIf="OfferteAanvraagViewModel.VoorkeurHandelaar == 'Ja'">
              <td>
                Plaats handelaar
              </td>
              <td>
                <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size">
                  <input matInput [(ngModel)]="OfferteAanvraagViewModel.HandelaarPlaats" type="text" class="text-veld">
                </mat-form-field>
              </td>
            </tr>
          </table>
          <br />
          <div>
            <span>
              Velden gemarkeerd met een * zijn verplicht
            </span>
            <button style="float:right;" (click)="VerstuurAanvraag()" class="btn btn-default">Verstuur aanvraag</button>
            <button style="float:right; margin-right: 5px;" (click)="Annuleren()" class="btn btn-default">Annuleren</button>
          </div>
          <br />
          <br />
        </div>
      </div>
      <div *ngIf="OfferteVerzondenPopupTonen">
        <div class="offerte-inner">
          Aanvraag verzonden
          <br />
          <br />
          <a style="float:right;" href="#" (click)="PopupSluiten()" class="btn btn-default">Sluiten</a>
          <br />
          <br />
        </div>
      </div>
      <div *ngIf="OfferteSlimfortTonen">
        <div class="offerte-inner">
          U wordt nu doorgestuurd naar <a href="https://slimfort-tool.isobouw.nl/" target="_blank">https://slimfort-tool.isobouw.nl/</a>
          <br />
          <br />
          <a style="float:right;" href="https://slimfort-tool.isobouw.nl/" target="_blank" (click)="PopupSluiten()" class="btn btn-default">Doorgaan</a>
          <a style="float:right; margin-right: 10px;" href="#" (click)="PopupSluiten()" class="btn btn-default">Annuleren</a>
          <br />
          <br />
        </div>
      </div>
      <div *ngIf="IsolatieToelichtingTonen">
        <div class="offerte-inner">
          <table style="width: 100%;">
            <tr>
              <td><b>Advies m.b.t. productkeuze</b></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td><b>Minimale druksterkte</b></td>
            </tr>
            <tr>
              <td>Dak met PV installatie o.g.:</td>
              <td>(PowerTop/OmniTop)</td>
              <td>150 kPa</td>
            </tr>
            <tr>
              <td>Dakterras o.g.:</td>
              <td>(SuperTop)</td>
              <td>200 kPa</td>
            </tr>
          </table>
          <br />
          <br />
          <a href="#" (click)="PopupSluiten()" class="btn btn-default">Sluiten</a>
        </div>
      </div>
      <div *ngIf="AfschotImage1Tonen">
        <div class="offerte-inner">
          <img style="border: 1px solid #000;" src="/Content/KlantSpecifiek/BCB21097/afshot1.png" />
          <br />
          <br />
          <button (click)="PopupSluiten()" class="btn btn-default">Sluiten</button>
        </div>
      </div>
      <div *ngIf="AfschotImage2Tonen">
        <div class="offerte-inner">
          <img style="border: 1px solid #000;" src="/Content/KlantSpecifiek/BCB21097/afshot2.png" />
          <br />
          <br />
          <button (click)="PopupSluiten()" class="btn btn-default">Sluiten</button>
        </div>
      </div>
      <div *ngIf="AfschotImage3Tonen">
        <div class="offerte-inner">
          <img style="border: 1px solid #000;" src="/Content/KlantSpecifiek/BCB21097/afshot3.png" />
          <br />
          <br />
          <button (click)="PopupSluiten()" class="btn btn-default">Sluiten</button>
        </div>
      </div>
      <div *ngIf="AfschotImage4Tonen">
        <div class="offerte-inner">
          <img style="border: 1px solid #000;" src="/Content/KlantSpecifiek/BCB21097/afshot4.png" />
          <br />
          <br />
          <button (click)="PopupSluiten()" class="btn btn-default">Sluiten</button>
        </div>
      </div>
    </div>

  </div>



  <div class="header" [innerHTML]="GetTitle()">

  </div>

  <div *ngIf="SubTitleTonen()" style="margin: 20px; margin-left: 80px; margin-right: 80px; background-color: #FFF; padding: 10px; font-weight: bold;">
    Let op: de energetische dikte kan afwijken van de gemiddelde dikte. Gelieve hier rekening mee te houden in uw calculatie.
  </div>

  <div *ngIf="IsLoading()" class="mat-progress-spinner__cont">
    <div style="padding-top: 300px; padding-bottom: 300px; text-align: center;">
      <div>
        <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
      </div>
      <div>
        <p>De producten worden geladen</p>
      </div>
    </div>
  </div>

  <div *ngIf="FabrikantenExcelResponseModel != undefined">
    <div class="panel" *ngIf="GetFirstImportantFilter() != null">
      <div *ngIf="GetFirstImportantFilter().Name != 'Categorie'">
        <a href="#" (click)="Terug(); NavigateToURL()" class="btn btn-default">Terug</a>
      </div>
      <div class="grid-container">
        <div *ngFor="let option of GetOrderOptions(GetFirstImportantFilter())" class="grid-item" (click)="SelectOption(GetFirstImportantFilter().Id, option.Id); NavigateToURL()">
          <div style="height: 262px;">
            <img src="{{ option.Image }}" style="width: 100%; height: 260px; object-fit: cover;" />
          </div>
          <div class="grid-item-name">
            {{ option.Name }}
          </div>
        </div>
      </div>
      <br /> <br />
      <div class="sub-text">
        <b>Isolatiewaarde R<sub>D</sub> ≠ R<sub>C</sub></b><br />
        Pas op bij uw keuze naar een geschikte isolatie. Vaak wordt nog alleen gekeken naar de isolatiewaarde van het isolatiemateriaal, de R<sub>D</sub>-waarde. Vanuit het bestek en het Bouwbesluit zijn de eisen echter gebaseerd op de R<sub>C</sub>-waarde. Dit is het isolerende vermogen van de totale constructie, dus inclusief ander bouwdelen en koudebruggen van bijvoorbeeld bevestigingsmaterialen.
      </div>
    </div>

    <div class="panel" *ngIf="GetFirstImportantFilter() == null">
      <table style="width: 100%;" cellpadding="10" cellspacing="0">
        <tr class="table-head">
          <td style="text-align: left;">
            <a href="#" (click)="Terug()" class="btn btn-default">Terug</a>&nbsp;
            <a href="#" (click)="RemoveNonImportantFilters()" class="btn btn-default">Reset alles</a>
          </td>
          <td style="width: 250px;">Materiaal</td>
          <td style="width: 135px;">Dikte (mm) / aantal (st./m<sup>2</sup>)</td>
          <td style="width: 100px;">
            Lambda (W/m∙K)
          </td>
          <td style="width: 100px;">
            R-waarde (m<sup>2</sup>∙K/W)
          </td>
          <td class="last-col"></td>
        </tr>
        <tr *ngFor="let cat of GetCategories(); index as i" class="table-row">
          <td class="table-cell-first">
            <span innerHTML="{{cat}}"></span>
            <span *ngIf="ShouldDisplayInfo(cat)">&nbsp; <a href="#" (click)="IsolatieToelichtingTonen = true;" class="btn btn-default">Info</a></span>
          </td>
          <td>
            <div *ngIf="GetFilter(cat, 'Materiaal') != null && GetFilter(cat, 'Materiaal').FilterType.Name != 'DisplayText'">
              <div *ngIf="GetFilter(cat, 'Materiaal').Options.length != 1">
                <filter [FilterViewModel]="GetFilter(cat, 'Materiaal')" (FilterChanged)="Refresh()"></filter>
              </div>
              <div class="first_letter" *ngIf="GetFilter(cat, 'Materiaal').Options.length == 1">
                {{ GetFilter(cat, 'Materiaal').Options[0].Name }}
              </div>
            </div>
            <div *ngIf="GetFilter(cat, 'Materiaal') != null && GetFilter(cat, 'Materiaal').FilterType.Name == 'DisplayText'">
              {{ GetExcelValue(GetFilter(cat, 'Materiaal').Name, undefined) }}
            </div>
          </td>
          <td>
            <div *ngIf="!ShouldDisplayNoDikte(GetFilter(cat, 'Materiaal'))">
              <div *ngIf="GetFilter(cat, 'Dikte') != null && GetFilter(cat, 'Dikte').FilterType.Name != 'DisplayText'">
                <div *ngIf="cat == 'Tule (lengte)'" style="text-align: left;">
                  Lengte:
                </div>
                <div *ngIf="GetFilter(cat, 'Dikte') != null && GetFilter(cat, 'Aantal') != null" style="text-align: left;">
                  Diameter:
                </div>
                <filter [FilterViewModel]="GetFilter(cat, 'Dikte')" (FilterChanged)="Refresh()"></filter>
              </div>
              <div *ngIf="GetFilter(cat, 'Dikte') != null && GetFilter(cat, 'Dikte').FilterType.Name == 'DisplayText'">
                {{ GetExcelValue(GetFilter(cat, 'Dikte').Name, 0) }}
              </div>
            </div>
            <div *ngIf="ShouldDisplayNoDikte(GetFilter(cat, 'Materiaal'))">
              0
            </div>
            <div *ngIf="GetFilter(cat, 'Dikte') != null && GetFilter(cat, 'Aantal') != null">
              &nbsp;
            </div>
            <div *ngIf="GetFilter(cat, 'Aantal') != null && GetFilter(cat, 'Aantal').FilterType.Name != 'DisplayText'">
              <div *ngIf="GetFilter(cat, 'Dikte') != null && GetFilter(cat, 'Aantal') != null" style="text-align: left;">
                Aantal:
              </div>
              <filter [FilterViewModel]="GetFilter(cat, 'Aantal')" (FilterChanged)="Refresh()"></filter>
            </div>
            <div *ngIf="GetFilter(cat, 'Aantal') != null && GetFilter(cat, 'Aantal').FilterType.Name == 'DisplayText'">
              {{ GetExcelValue(GetFilter(cat, 'Aantal').Name, 0) }}
            </div>
          </td>
          <td>
            <div *ngIf="GetFilter(cat, 'Lambda') != null && GetFilter(cat, 'Lambda').FilterType.Name != 'DisplayText'">
              <filter [FilterViewModel]="GetFilter(cat, 'Lambda')" (FilterChanged)="Refresh()"></filter>
            </div>
            <div *ngIf="GetFilter(cat, 'Lambda') != null && GetFilter(cat, 'Lambda').FilterType.Name == 'DisplayText'">
              <div *ngIf="GetFilter(cat, 'Lambda') != null">
                <div *ngIf="!ShouldDisplayFreeLambda(GetFilter(cat, 'Materiaal'))">
                  {{ GetExcelValue(GetFilter(cat, 'Lambda').Name, undefined) }}
                </div>
                <div *ngIf="ShouldDisplayFreeLambda(GetFilter(cat, 'Materiaal'))">
                  <mat-form-field style="width: 135px; position: relative; top: 3px;" appearance="outline" floatLabel="always" class="control-font-size">
                    <input matInput type="number" step="0.01" [(ngModel)]="VrijeInvoerLambda" (blur)="Refresh()" (keyup.enter)="Refresh()">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div *ngIf="GetFilter(cat, 'RWaarde') != null">
            {{ GetExcelValue(GetFilter(cat, 'RWaarde').Name, undefined) }}
            </div>
          </td>
          <td [attr.rowspan]="GetCategories().length + 1" *ngIf="i == 0">
            <img *ngIf="GetProductImage() != ''" style="border: 1px solid #000; max-width: 248px;" src="{{ GetProductImage() }}" />
            <a *ngIf="GetProductLink() != ''" href="{{ GetProductLink() }}" target="_blank" style="width:90%;" class="btn btn-default">Meer productinformatie</a><br /><br />
            <a *ngIf="GetBestekLink() != ''" href="{{ GetBestekLink() }}" target="_blank" style="width:90%;" class="btn btn-default">Bestekteksten</a>
          </td>
        </tr>
        <tr style="background-color: #F2F2F2;">
          <td colspan="5"></td>
        </tr>
      </table>

      <div *ngIf="AfschotSysteemTonen()">
        <table style="width: 100%;" cellpadding="10" cellspacing="0">
          <tr>
            <td colspan="9" style="text-align: center;">
              <!--<img style="border: 1px solid #000;" src="/Content/KlantSpecifiek/BCB21097/afshot-overzicht.png" height="300" />-->
            </td>
            <td style="vertical-align:bottom;">
              <div class="last-col" style="height: 40px; text-align:center; font-size: 20px; padding-top: 13px;">
                Type afschotvlak
              </div>
            </td>
          </tr>
          <tr>
            <td class="table-head" style="text-align: left;">
              Type 1
            </td>
            <td class="table-head">Lengte [m]</td>
            <td class="table-head">Breedte [m]</td>
            <td class="table-head">
              Dikte (mm) (hoge zijde)
            </td>
            <td class="table-head">
              Afschot %
            </td>
            <td class="table-head">
              R<sub>0</sub>=(R<sub>T1</sub>+R<sub>02</sub>)
            </td>
            <td class="table-head">
              R<sub>2</sub>
            </td>
            <td class="table-head">
              Ut
            </td>
            <td class="table-head">
              Oppervlakte
            </td>
            <td style="width: 250px;"></td>
          </tr>
          <tr class="table-row" *ngFor="let vlak of this.Afschotvlakken; index as i">
            <td class="table-cell-first">
              {{ vlak.Naam }}
            </td>
            <td style="width: 80px;">
              <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Lengte')" (FilterChanged)="Refresh()"></filter>
            </td>
            <td style="width: 80px;">
              <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Breedte')" (FilterChanged)="Refresh()"></filter>
            </td>
            <td style="width: 80px;">
              <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Dikte')" (FilterChanged)="Refresh()"></filter>
            </td>
            <td>
              <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Afschot')" (FilterChanged)="Refresh()"></filter>
            </td>
            <td>
              {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst1').Name, 3) }}
            </td>
            <td>
              {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst2').Name, 3) }}
            </td>
            <td>
              {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst3').Name, 3) }}
            </td>
            <td>
              {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst4').Name, 3) }}
            </td>
            <td rowspan="6" *ngIf="i == 0">
              <img style="border: 1px solid #000; width: 240px; cursor:pointer;" (click)="AfschotImage1Tonen = true;" src="/Content/KlantSpecifiek/BCB21097/afshot1.png" />
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <ng-container *ngFor="let vlak of this.Graatvlakken; index as i">
            <tr class="table-row">
              <td class="table-cell-first">
                {{ vlak.Naam }}
              </td>
              <td style="width: 80px;">
                <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Lengte')" (FilterChanged)="Refresh()"></filter>
              </td>
              <td style="width: 80px;">
                <span *ngIf="GetExcelValue(GetFilter(vlak.NaamIntern, 'Breedte').Name, 3) != '0.000'" style="line-height: 24px;">
                  {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Breedte').Name, 3) }}
                </span>
              </td>
              <td style="width: 80px;">
                <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Dikte')" (FilterChanged)="Refresh()"></filter>
              </td>
              <td>
                <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Afschot')" (FilterChanged)="Refresh()"></filter>
              </td>
              <td>
                {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst1').Name, 3) }}
              </td>
              <td>
                {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst2').Name, 3) }}
              </td>
              <td>
                {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst3').Name, 3) }}
              </td>
              <td>
                {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst4').Name, 3) }}
              </td>
              <td rowspan="6" *ngIf="i == 0">
                <img style="border: 1px solid #000; width: 240px;cursor:pointer;" (click)="AfschotImage2Tonen = true;" src="/Content/KlantSpecifiek/BCB21097/afshot2.png" />
              </td>
            </tr>
            <tr class="table-row" *ngIf="GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst3').Name, 3).toString() == 'NaN'">
              <td colspan="9" style="text-align: left;">
                Huidige combinatie van waarden is niet geldig
              </td>
            </tr>
          </ng-container>
          <tr>
            <td></td>
          </tr>
          <ng-container *ngFor="let vlak of this.Kilvlakken; index as i">
            <tr class="table-row">
              <td class="table-cell-first">
                {{ vlak.Naam }}
              </td>
              <td style="width: 80px;">
                <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Lengte')" (FilterChanged)="Refresh()"></filter>
              </td>
              <td style="width: 80px;">
                <span *ngIf="GetExcelValue(GetFilter(vlak.NaamIntern, 'Breedte').Name, 3) != '0.000'">
                  {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Breedte').Name, 3) }}
                </span>
              </td>
              <td style="width: 80px;">
                <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Dikte')" (FilterChanged)="Refresh()"></filter>
              </td>
              <td>
                <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Afschot')" (FilterChanged)="Refresh()"></filter>
              </td>
              <td>
                {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst1').Name, 3) }}
              </td>
              <td>
                {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst2').Name, 3) }}
              </td>
              <td>
                {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst3').Name, 3) }}
              </td>
              <td>
                {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst4').Name, 3) }}
              </td>
              <td rowspan="6" *ngIf="i == 0">
                <img style="border: 1px solid #000; width: 240px;cursor:pointer;" (click)="AfschotImage3Tonen = true;" src="/Content/KlantSpecifiek/BCB21097/afshot3.png" />
              </td>
            </tr>
            <tr class="table-row" *ngIf="GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst3').Name, 3).toString() == 'NaN'">
              <td colspan="9" style="text-align: left;">
                Huidige combinatie van waarden is niet geldig
              </td>
            </tr>
          </ng-container>
         
          <tr>
            <td></td>
          </tr>
          <tr class="table-row" *ngFor="let vlak of this.Vlakken; index as i">
            <td class="table-cell-first">
              {{ vlak.Naam }}
            </td>
            <td style="width: 80px;">
              <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Lengte')" (FilterChanged)="Refresh()"></filter>
            </td>
            <td style="width: 80px;">
              <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Breedte')" (FilterChanged)="Refresh()"></filter>
            </td>
            <td style="width: 80px;">
              <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Dikte')" (FilterChanged)="Refresh()"></filter>
            </td>
            <td>
              <filter [FilterViewModel]="GetFilter(vlak.NaamIntern, 'Afschot')" (FilterChanged)="Refresh()"></filter>
            </td>
            <td>
              {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst1').Name, 3) }}
            </td>
            <td>
              {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst2').Name, 3) }}
            </td>
            <td>
              {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst3').Name, 3) }}
            </td>
            <td>
              {{ GetExcelValue(GetFilter(vlak.NaamIntern, 'Uitkomst4').Name, 3) }}
            </td>
            <td rowspan="6" *ngIf="i == 0">
              <img style="border: 1px solid #000; width: 240px;cursor:pointer;" (click)="AfschotImage4Tonen = true;" src="/Content/KlantSpecifiek/BCB21097/afshot4.png" />
            </td>
          </tr>
        </table>
        <br />
        <br />
        <table style="width: 100%;" cellpadding="10" cellspacing="0">
          <tr class="table-row">
            <td class="table-cell-first">
            </td>
            <td>
              <b>U<sub>T</sub>=(1/R<sub>T</sub>)</b> [m<sup>2</sup>K/W]
            </td>
            <td>
              <div>
                {{ GetExcelValue(GetFilter(InclAfschotUT, 'RWaarde').Name, undefined) }}
              </div>
            </td>
            <td style="width: 250px;">
            </td>
          </tr>
          <tr class="table-row">
            <td class="table-cell-first">
            </td>
            <td>
              <b>R</b><sub>T</sub>
            </td>
            <td>
              <div>
                {{ GetExcelValue(GetFilter(InclAfschotRT, 'RWaarde').Name, undefined) }}
              </div>
            </td>
            <td>
            </td>
          </tr>
        </table>
      </div>

      <table style="width: 100%;" cellpadding="10" cellspacing="0">
        <tr class="table-head">
          <td style="text-align: left;">
          </td>
          <td style="width: 250px;"></td>
          <td style="width: 135px;"></td>
          <td style="width: 100px;"></td>
          <td style="width: 100px;"></td>
          <td class="last-col"></td>
        </tr>
        <tr>
          <td></td>
        </tr>
        <tr class="table-row-end" *ngIf='GetExcelValue(TussenUitkomst1, 4) != TussenUitkomst1'>
          <td colspan="3" style="text-align: right;" [innerHTML]="TussenUitkomst1">
          </td>
          <td style="text-align: right;">{{ GetExcelValue(TussenUitkomst1, 4) }}</td>
          <td>
            W/m<sup>2</sup>K
          </td>
        </tr>
        <tr class="table-row-end" *ngIf='GetExcelValue(TussenUitkomst2, 4) != TussenUitkomst2'>
          <td colspan="3" style="text-align: right;" [innerHTML]="TussenUitkomst2">
          </td>
          <td style="text-align: right;">{{ GetExcelValue(TussenUitkomst2, 4) }}</td>
          <td>
            W/m<sup>2</sup>K
          </td>
        </tr>
        <tr class="table-row-end" *ngIf='GetExcelValue(TussenUitkomst3, 4) != TussenUitkomst3'>
          <td colspan="3" style="text-align: right;" [innerHTML]="TussenUitkomst3">
          </td>
          <td style="text-align: right;">{{ GetExcelValue(TussenUitkomst3, 4) }}</td>
          <td>
            W/m<sup>2</sup>K
          </td>
        </tr>
        <tr class="table-row-end" *ngIf='GetExcelValue(TussenUitkomst4, 4) != TussenUitkomst4'>
          <td colspan="3" style="text-align: right;" [innerHTML]="TussenUitkomst4">
          </td>
          <td style="text-align: right;">{{ GetExcelValue(TussenUitkomst4, 2) }}</td>
          <td>
            %
          </td>
        </tr>
        <tr class="table-row-end" *ngIf='GetExcelValue(TussenUitkomst5, 4) != TussenUitkomst5'>
          <td colspan="3" style="text-align: right;" [innerHTML]="TussenUitkomst5">
          </td>
          <td style="text-align: right;">{{ GetExcelValue(TussenUitkomst5, 4) }}</td>
          <td>
            W/m<sup>2</sup>K
          </td>
        </tr>
        <tr class="table-row-end" *ngIf='GetExcelValue(TussenUitkomst6, 4) != TussenUitkomst6'>
          <td colspan="3" style="text-align: right;" [innerHTML]="TussenUitkomst6">
          </td>
          <td style="text-align: right;">{{ GetExcelValue(TussenUitkomst6, 4) }}</td>
          <td>m<sup>2</sup>∙K/W</td>
        </tr>
        <tr class="table-row-end" *ngIf='this.FabrikantenFilterResponseModel?.SelectedProduct?.Id == 1'>
          <td colspan="3" style="text-align: right;">
            R<sub>C</sub> = (R<sub>T</sub>) / (1+&szlig;) - R<sub>si</sub> - R<sub>se</sub>
          </td>
          <td style="text-align: right;"></td>
          <td></td>
        </tr>
        <tr class="table-row-end" style="background-color: #595959; color: #FFF;">
          <td colspan="3" style="text-align: right;">R<sub>C</sub> -waarde *</td>
          <td style="text-align: right;">{{ GetRCValue() }}</td>
          <td>m<sup>2</sup>∙K/W</td>
        </tr>
        <tr *ngIf="GetMinimum() != 0">
          <td colspan="5" style="text-align: right;">Vereiste R<sub>C</sub> -Waarde voor nieuwbouw is minimaal {{ GetMinimum() }} m<sup>2</sup>K/W</td>
        </tr>
        <tr>
          <td></td>
        </tr>
      </table>

      <table style="width: 100%;" cellpadding="10" cellspacing="0">
        <tr>
          <td colspan="5" class="table-head">
            Projectgegevens (optioneel)
          </td>
          <td rowspan="3" style="width: 250px;">
            <button (click)="PDFDownloaden()" style="width:100%;" class="btn btn-default">Download PDF</button><br /><br />
            <button (click)="OfferteAanvragen()" style="width:100%;" class="btn btn-default">Offerte aanvragen</button>
          </td>
        </tr>
        <tr class="table-row">
          <td class="table-cell-first">Naam project</td>
          <td colspan="4">
            <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size">
              <input matInput [(ngModel)]="PDFRequestViewModel.NaamProject" type="text" class="text-veld">
            </mat-form-field>
          </td>
        </tr>
        <tr class="table-row">
          <td class="table-cell-first">Plaats project</td>
          <td colspan="4">
            <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size">
              <input matInput [(ngModel)]="PDFRequestViewModel.PlaatsProject" type="text" class="text-veld">
            </mat-form-field>
          </td>
        </tr>
        <tr class="table-row" *ngIf="GetAfschotTBVAfschot()">
          <td class="table-cell-first">Gewenst afschotpercentage</td>
          <td colspan="4" style="text-align: left;">
            <select [(ngModel)]="PDFRequestViewModel.GewenstAfschotpercentage">
              <option>?</option>
              <option>1%</option>
              <option>1,5%</option>
              <option>2%</option>
            </select>
          </td>
        </tr>
        <tr class="table-row" *ngIf="GetAfschotTBVAfschot()">
          <td class="table-cell-first">Toepassen van kil- en graatplaten?</td>
          <td colspan="4" style="text-align: left;">
            <select [(ngModel)]="PDFRequestViewModel.ToepassingKilGraatPlaten">
              <option>?</option>
              <option>Ja</option>
              <option>Nee</option>
            </select>
          </td>
        </tr>
        <tr class="table-row">
          <td class="table-cell-first">Extra opmerkingen</td>
          <td colspan="4">
            <mat-form-field style="width: 100%" appearance="outline" floatLabel="always" class="control-font-size">
              <input matInput [(ngModel)]="PDFRequestViewModel.ExtraOpmerkingen" type="text" class="text-veld">
            </mat-form-field>
          </td>
        </tr>
        <tr class="table-row" *ngIf="GetAfschotTBVAfschot()">
          <td class="table-cell-first" colspan="5">Liefst tekeningen in dwg aanleveren met daarop duidelijk aagegeven hemelwaterafvoeren en/of afschotrichtingen)</td>
        </tr>
        <tr>
          <td></td>
        </tr>
        <tr>
          <td colspan="5">
            * Aan de uitkomsten van dit R<sub>C</sub>-rekenprogramma kunnen geen rechten worden ontleend.
          </td>
        </tr>
      </table>
    </div>

  </div>



</div>

