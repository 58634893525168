<div *ngIf="oorzaak == 'nietMijnWoning'" class="cont-activatie-formulier">
  <div class="dialog__header">
    <p class="dialog__title">Dit is niet mijn woning</p>
    <i (click)="naarBeginscherm()" class="fal fa-times"></i>
  </div>
  <div class="dialog__main">
    <p>Is dit niet uw adres? Dan is er iets niet goed gegaan.
      Weet u zeker dat u uw inlogcode goed hebt ingevoerd? Stuur dan een e-mail naar <a href="{{geefSupportEmailadresLinkToEmailProgram()}}">{{geefSupportEmailadres()}}</a></p>
  </div>
  <div class="dialog__btns">
    <button (click)="naarBeginscherm()" class="dialog__btn__primary">Ga terug naar beginscherm</button>
  </div>
</div>

<div *ngIf="oorzaak == 'disclaimerGoedkeuren'" class="cont-activatie-formulier">
  <div class="dialog__header">
    <p class="dialog__title">Disclaimer</p>
    <i (click)="naarBeginscherm()" class="fal fa-times"></i>
  </div>
  <div class="dialog__main">
    <wct-disclaimer></wct-disclaimer>
    <mat-checkbox [(ngModel)]="disclaimerCheckboxIsApproved">Ik ga akkoord met bovenstaande voorwaarden.</mat-checkbox>
    <p *ngIf="CheckboxHelptextIsShown && !disclaimerCheckboxIsApproved" class="activatie-formulier-popup__checkbox-help-text">U dient eerst nog akkoord te gaan met de algemene voorwaarden.</p>
  </div>
  <div class="dialog__btns">
    <button (click)="ControleerDisclaimer()" class="dialog__btn__primary">Ok</button>
  </div>
</div>

<div *ngIf="oorzaak == 'aanmeldingGeslaagd'" class="cont-activatie-formulier">
  <div class="dialog__header">
    <p *ngIf="activatie_emailverplicht" class="dialog__title">Bijna binnen! Klik eerst op de link in onze e-mail.</p>
    <p *ngIf="!activatie_emailverplicht" class="dialog__title">Account aanmaken gelukt!</p>
    <i (click)="naarBeginscherm()" class="fal fa-times"></i>
  </div>
  <div class="dialog__main">
    <p *ngIf="activatie_emailverplicht">Bedankt voor uw aanmelding. U ontvangt van ons een e-mail met uw inloggegevens.<br />
    Klik op de bevestigingslink in de e-mail, om uw account te activeren.<br />
    Vervolgens kunt u inloggen in de digitale wereld van Smart Twin.</p>

    <p *ngIf="!activatie_emailverplicht">Bedankt voor uw aanmelding. Login in met de loginnaam: '{{username}}' en het door u gekozen wachtwoord.<br /></p>
  </div>
  <div *ngIf="invulformulier" class="dialog__btns">
    <button (click)="naarBeginscherm()" class="dialog__btn__primary">Ga terug naar beginscherm</button>
  </div>
</div>

<div *ngIf="oorzaak == 'aanmeldingNietGeslaagd'" class="cont-activatie-formulier">
  <div class="dialog__header">
    <p class="dialog__title">Aanmelding nog niet geslaagd</p>
    <i (click)="naarBeginscherm()" class="fal fa-times"></i>
  </div>
  <div class="dialog__main">
    <p>Er is iets fout gegaan. </p>
    <p *ngIf="melding != ''">{{melding}}</p>
    <p>Sluit dit venster en klik nogmaals op aanmelden.</p>
  </div>
  <div *ngIf="invulformulier" class="dialog__btns">
    <button (click)="naarBeginscherm()" class="dialog__btn__primary">Ga terug naar beginscherm</button>
  </div>
</div>
