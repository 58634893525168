<!--<img class="img-achtergrond" src="/Content/Images/activatie/standaardafbeelding.jpg"  />-->
<img class="img-achtergrond" [src]="ToonImageWachtwoordVergeten()" />

<div class="cont-wachtwoord-bevestigd">
    <h2>Email bevestigd</h2>
    <p class="txt-main">
        Uw e-mailadres is bevestigd. U kunt nu inloggen met het e-mailadres en wachtwoord dat u tijdens activatie hebt
        ingevoerd.
    </p>

    <wct-button button-size="small" button-align="center" (click)="TerugNaarLogin()">Naar login</wct-button>
</div>