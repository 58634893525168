import { CapabilitiesService }  from '../viewers/CapabilitiesService';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WINDOW } from '../infrastructure/windowprovider';
import { DOCUMENT } from '@angular/common';
import { Observable, pipe } from 'rxjs';
import { tap, map } from 'rxjs/operators';

export interface IDownloadService {
   DownloadFile(url: string, datain: any, filename: string, mimetype?: string): ng.IPromise<boolean>;
   DownloadFileGet(url: string, filename: string, mimetype?: string): ng.IPromise<boolean>;
   DownloadFileNaam(url: string, datain: any, mimetype?: string): ng.IPromise<boolean>;
   DownloadFileGetIframe(url: string, filename: string): ng.IPromise<boolean>;
   DownloadFileGetBuffer(url: string, filename: string, mimetype?: string): Observable<ArrayBuffer>;
}

@Injectable({
	providedIn: 'root',
})
export class DownloadService implements IDownloadService {
   private bestandstypen = [".pdf", ".zip", ".xlsx"];

   constructor(private $http: HttpClient, private capabilitiesservice: CapabilitiesService, @Inject(WINDOW) private window: Window, @Inject(DOCUMENT) private document: Document) {
      'ngInject';
   }

   private IsUrlNaarBestand(url: string): boolean {
      let isUrlNaarBestand: boolean = false;
      let url_lower: string = url.toLowerCase();

      for (let i = 0; i < this.bestandstypen.length; i++) {
         if (url_lower.indexOf(this.bestandstypen[i], url_lower.length - this.bestandstypen[i].length) !== -1) {
            return true;
         }
      }

      return isUrlNaarBestand;
   }

   private VerwerkDownload(data: ArrayBuffer, filename: string, mimetype?: string) {
     if (false) {//window.navigator.msSaveOrOpenBlob) {
         var fileData: any[] = [data];
         var blobObject: Blob = new Blob(fileData);
         //this.window.navigator.msSaveOrOpenBlob(blobObject, filename);
      } else {
         // TODO when WS success
         var d: any[] = [data];
         var file: any = new Blob(d, { type: mimetype ? mimetype : 'application/pdf' });
         //trick to download store a file having its URL
         //var fileURL = URL.createObjectURL(file, <ObjectURLOptions>{ oneTimeOnly: false });
         var fileURL = URL.createObjectURL(file);
         var a = document.createElement('a');
         a.href = fileURL;
         a.target = '_blank';
         if (mimetype == 'application/pdf') {
            a.setAttribute("download", filename.replace('.', '').replace(/[^a-z0-9]/gi, '_'));
         }
         else
         {
            a.setAttribute("download", filename);
         }
         //a.setAttribute("id", fileURL);
         //var doc: any = this.document[0];
         //doc.body.appendChild(a);
         this.document.body.appendChild(a);

         a.click();
      }
   }

   private VerwerkDownloadNaam(filename: string, mimetype?: string) {
      var a = document.createElement('a');
      a.href = filename;
      a.target = '_blank';
      a.setAttribute("download", filename.substring(filename.lastIndexOf("\\") + 1));
      var doc: any = this.document[0];
      doc.body.appendChild(a);
      //this.$document.[0].body.appendChild(a);
      a.click();
   }

   DownloadFileGetIframe(url: string, filename: string): ng.IPromise<boolean> {

      let obs = new Observable<boolean>( (x) =>
      {
         var url = '/Print/GeefGeneratedOctetStream?id=' + url + '&vriendelijk=' + filename;
         url = url.replace(/ /g, "");
         if (this.capabilitiesservice.IsiPadTablet) {
            window.open(url);
         } else {
            $("body").append("<iframe src=" + url + " style='display: none; height:0px;' ></iframe>");
         }
         x.next(true);
         x.complete();
      });

      return obs.toPromise();
   }

   DownloadFileGet(url: string, filename: string, mimetype?: string): ng.IPromise<boolean> {
      let obs = this.DownloadFileGetBuffer(url, filename, mimetype).pipe(tap((x) =>
      {
         this.VerwerkDownload(x, filename, mimetype);
      }), map((x) => true));
      return obs.toPromise();
      
      // .toPromise().then((data) => {
      //    this.VerwerkDownload(data, filename, mimetype);
      //    deferred.resolve(true);
      // }, err => {
      //    deferred.reject("Downloaden mislukt");
      //    console.log(err);
      // });

      // return deferred.promise;
   }

   DownloadFileGetBuffer(url: string, filename: string, mimetype?: string): Observable<ArrayBuffer> {
      return this.$http.get(url, { responseType: 'arraybuffer' });
   }

   DownloadFile(url: string, datain: any, filename: string, mimetype?: string): ng.IPromise<boolean> {
      //var deferred = this.$q.defer<boolean>();

      let obs = this.$http.post(url, datain, { responseType: 'arraybuffer' }).pipe(tap((x) =>
      {
         this.VerwerkDownload(x, filename, mimetype);
      }), map((x) => true));
      return obs.toPromise();
      //    deferred.resolve(true);
      // }, err => {
      //    deferred.reject("Downloaden mislukt");
      //    console.log(err);
      // });
      // return deferred.promise;
   }

   DownloadFileNaam(url: string, datain: any, mimetype?: string): ng.IPromise<boolean> {
      //var deferred = this.$q.defer<boolean>();

      let obs = this.$http.post(url, datain).pipe(tap((x:any) =>
      {
         this.VerwerkDownloadNaam(x + "", mimetype);
      }), map((x) => true));
      return obs.toPromise();
      //    deferred.resolve(true);
      // }, err => {
      //    deferred.reject("Downloaden mislukt");
      //    console.log(err);
      // });
      // return deferred.promise;
   }
}
