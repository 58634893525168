import { Theme } from '../theme.ts';

export const AssaabloyTheme: Theme = {
  name: 'assaabloy',
  properties: {
    '--header-font-family': 'Open Sans,Arial,sans-serif',
    '--header-font-weight': '400',
    '--header-color': 'black',   
    '--header-text-transform': 'none',
    '--body-font-family': 'Open Sans,Arial,sans-serif',
    '--color-default-primary-background': '#00A0D0',
    '--color-default-primary-background-opacity-30': '#b4d6ec',
    '--button-primary-border-radius': '20px',      
    '--button-secundary-border-radius': '5px',
    '--button-primary-color--hover': 'white', 
    '--fab-filters-margin': '15px',
    '--fab-product__osf-display': 'flex',
    '--fab-product__button-text-color': 'white',
    '--fab-product__button-text-color--hover': 'white',   
    '--fab-product__button-text-color--active': 'white',  
    '--fab-product__button-background-color--hover': '#0090e4',   
    '--fab-filters__button-border-color': '#0078be',
    '--fab-filters__button-text-color': '#0078be',
    '--fab-filter__selected-option-color': 'rgba(0, 120, 190, 0.3)'          
  }
};
