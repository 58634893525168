/**
 * @ref WoonConnectViewModel/Project/ImportProjectSettingsModel.cs
 */

 import * as DataContracts from "@datacontracts/ImportProjectSettingsModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Project.ImportProjectSettingsRequestModel
	 */
	 

	export interface IImportProjectSettingsRequestModel {
		ProjectId: number;
		ToJsonContract(): DataContracts.IImportProjectSettingsRequestModel;
	}

	export class ImportProjectSettingsRequestModel {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		// field-Properties

		// fields
		protected _ProjectId: number = 0;

		constructor(data?: DataContracts.IImportProjectSettingsRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IImportProjectSettingsRequestModel): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IImportProjectSettingsRequestModel {
			return {
				ProjectId: this.ProjectId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Project.ImportProjectSettingsResponseModel
	 */
	 

	export interface IImportProjectSettingsResponseModel {
		Message: string;
	}

	export class ImportProjectSettingsResponseModel {
		// property-Properties
		// field-Properties
		public get Message(): string {
			return this._Message;
		}
		public set Message(newMessage: string) {
			this._Message = newMessage;
		}

		// fields
		protected _Message: string = "";

		constructor(data?: DataContracts.IImportProjectSettingsResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IImportProjectSettingsResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Message = data.Message;
			}
		}
	}
