/// <reference path='../consumerinterfaces/FiltersController.ts' />
/**
 * @ref WoonConnectApiController/Fabrikanten/FiltersController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/FiltersController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as FilterViewModel from "@datacontracts/FilterViewModel";
import * as ProductsViewModel from "@datacontracts/ProductsViewModel";
import * as request from "../../../helpers/IRequestHandler";





	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class FiltersConsumer implements Interface.IFiltersConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public GetFilters_Observable(pInputModel: FilterViewModel.IFiltersViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFiltersViewModel}): Observable<FilterViewModel.IFiltersViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/GetFilters`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IFiltersViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetFilters(pInputModel: FilterViewModel.IFiltersViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFiltersViewModel}): ng.IPromise<FilterViewModel.IFiltersViewModel> {
				let obs = this.GetFilters_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IFiltersViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetFilter_Observable(pInputModel: FilterViewModel.IFilterRequestModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterRequestModel}): Observable<FilterViewModel.IFilterViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/GetFilter/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IFilterViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetFilter(pInputModel: FilterViewModel.IFilterRequestModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterRequestModel}): ng.IPromise<FilterViewModel.IFilterViewModel> {
				let obs = this.GetFilter_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IFilterViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveFilter_Observable(pInputModel: FilterViewModel.IFilterViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterViewModel}): Observable<FilterViewModel.IFilterViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/SaveFilter/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IFilterViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveFilter(pInputModel: FilterViewModel.IFilterViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterViewModel}): ng.IPromise<FilterViewModel.IFilterViewModel> {
				let obs = this.SaveFilter_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IFilterViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveFilters_Observable(pInputModel: FilterViewModel.IFiltersViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFiltersViewModel}): Observable<FilterViewModel.IFiltersViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/SaveFilters/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IFiltersViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveFilters(pInputModel: FilterViewModel.IFiltersViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFiltersViewModel}): ng.IPromise<FilterViewModel.IFiltersViewModel> {
				let obs = this.SaveFilters_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IFiltersViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DeleteFilter_Observable(pInput: number): Observable<boolean> {
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/DeleteFilter/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DeleteFilter(pInput: number): ng.IPromise<boolean> {
				let obs = this.DeleteFilter_Observable(pInput);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public CheckFilter_Observable(pInput: number): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/CheckFilter/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public CheckFilter(pInput: number): ng.IPromise<string> {
				let obs = this.CheckFilter_Observable(pInput);
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveFilterType_Observable(pInputModel: FilterViewModel.IFilterTypeViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterTypeViewModel}): Observable<FilterViewModel.IFilterTypeViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/AddFilterType/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IFilterTypeViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveFilterType(pInputModel: FilterViewModel.IFilterTypeViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterTypeViewModel}): ng.IPromise<FilterViewModel.IFilterTypeViewModel> {
				let obs = this.SaveFilterType_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IFilterTypeViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DeleteFilterType_Observable(pInput: number): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/DeleteFilterType/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DeleteFilterType(pInput: number): ng.IPromise<string> {
				let obs = this.DeleteFilterType_Observable(pInput);
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetFilterTypes_Observable(): Observable<FilterViewModel.IFilterTypesViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Filters/GetFilterTypes`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IFilterTypesViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetFilterTypes(): ng.IPromise<FilterViewModel.IFilterTypesViewModel> {
				let obs = this.GetFilterTypes_Observable();
				let promise = new Promise<FilterViewModel.IFilterTypesViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetFilterOptions_Observable(pInputModel: FilterViewModel.IFilterRequestModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterRequestModel}): Observable<FilterViewModel.IFilterOptionsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/GetFilterOptions/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IFilterOptionsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetFilterOptions(pInputModel: FilterViewModel.IFilterRequestModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterRequestModel}): ng.IPromise<FilterViewModel.IFilterOptionsViewModel> {
				let obs = this.GetFilterOptions_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IFilterOptionsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveFilterOption_Observable(pInputModel: FilterViewModel.IFilterOptionViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterOptionViewModel}): Observable<FilterViewModel.IFilterOptionViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/SaveFilterOption/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IFilterOptionViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveFilterOption(pInputModel: FilterViewModel.IFilterOptionViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterOptionViewModel}): ng.IPromise<FilterViewModel.IFilterOptionViewModel> {
				let obs = this.SaveFilterOption_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IFilterOptionViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveFilterOptions_Observable(pInputModel: FilterViewModel.IFilterOptionsViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterOptionsViewModel}): Observable<FilterViewModel.IFilterOptionsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/SaveFilterOptions/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IFilterOptionsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveFilterOptions(pInputModel: FilterViewModel.IFilterOptionsViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterOptionsViewModel}): ng.IPromise<FilterViewModel.IFilterOptionsViewModel> {
				let obs = this.SaveFilterOptions_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IFilterOptionsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DeleteFilterOption_Observable(pInputModel: FilterViewModel.IFilterRequestModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterRequestModel}): Observable<boolean> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/DeleteFilterOption/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DeleteFilterOption(pInputModel: FilterViewModel.IFilterRequestModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterRequestModel}): ng.IPromise<boolean> {
				let obs = this.DeleteFilterOption_Observable(pInputModel);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetFilterOptionRelation_Observable(pInputModel: FilterViewModel.IFilterOptionRelationsViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterOptionRelationsViewModel}): Observable<FilterViewModel.IFilterOptionRelationsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/GetFilterOptionRelation/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IFilterOptionRelationsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetFilterOptionRelation(pInputModel: FilterViewModel.IFilterOptionRelationsViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterOptionRelationsViewModel}): ng.IPromise<FilterViewModel.IFilterOptionRelationsViewModel> {
				let obs = this.GetFilterOptionRelation_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IFilterOptionRelationsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetFilterOptionsVariantRelation_Observable(pInputModel: FilterViewModel.IFilterOptionsVariantRelationsViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterOptionsVariantRelationsViewModel}): Observable<FilterViewModel.IFilterOptionsVariantRelationsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/GetFilterOptionsVariantRelation/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IFilterOptionsVariantRelationsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetFilterOptionsVariantRelation(pInputModel: FilterViewModel.IFilterOptionsVariantRelationsViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterOptionsVariantRelationsViewModel}): ng.IPromise<FilterViewModel.IFilterOptionsVariantRelationsViewModel> {
				let obs = this.GetFilterOptionsVariantRelation_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IFilterOptionsVariantRelationsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetFilterRelation_Observable(pInputModel: FilterViewModel.IFilterRelationsViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterRelationsViewModel}): Observable<FilterViewModel.IFilterRelationsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/GetFilterRelation/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IFilterRelationsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetFilterRelation(pInputModel: FilterViewModel.IFilterRelationsViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IFilterRelationsViewModel}): ng.IPromise<FilterViewModel.IFilterRelationsViewModel> {
				let obs = this.GetFilterRelation_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IFilterRelationsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveRelation_Observable(pInputModel: FilterViewModel.IRelationViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IRelationViewModel}): Observable<FilterViewModel.IRelationViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/SaveRelation/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IRelationViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveRelation(pInputModel: FilterViewModel.IRelationViewModel & {ToJsonContract(noContent?: boolean): FilterViewModel.IRelationViewModel}): ng.IPromise<FilterViewModel.IRelationViewModel> {
				let obs = this.SaveRelation_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IRelationViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetProductFilterRelation_Observable(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): Observable<ProductsViewModel.IProductFilterRelationsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/GetProductsFilterRelations/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IProductFilterRelationsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetProductFilterRelation(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): ng.IPromise<ProductsViewModel.IProductFilterRelationsViewModel> {
				let obs = this.GetProductFilterRelation_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IProductFilterRelationsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetProductVariantFilterRelation_Observable(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): Observable<FilterViewModel.IVariantFilterRelationsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/GetProductsVariantFilterRelations/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IVariantFilterRelationsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetProductVariantFilterRelation(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): ng.IPromise<FilterViewModel.IVariantFilterRelationsViewModel> {
				let obs = this.GetProductVariantFilterRelation_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IVariantFilterRelationsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetProductPropertyFilterRelation_Observable(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): Observable<FilterViewModel.IProductPropertyFilterRelationsViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/GetProductPropertyFilterRelation/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FilterViewModel.IProductPropertyFilterRelationsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetProductPropertyFilterRelation(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): ng.IPromise<FilterViewModel.IProductPropertyFilterRelationsViewModel> {
				let obs = this.GetProductPropertyFilterRelation_Observable(pInputModel);
				let promise = new Promise<FilterViewModel.IProductPropertyFilterRelationsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetPropertyOptionFilterOption_Observable(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): Observable<ProductsViewModel.IPropertyOptionFiltersViewModel> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/Filters/GetPropertyOptionFilterOption/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ProductsViewModel.IPropertyOptionFiltersViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetPropertyOptionFilterOption(pInputModel: ProductsViewModel.IProductRequestModel & {ToJsonContract(noContent?: boolean): ProductsViewModel.IProductRequestModel}): ng.IPromise<ProductsViewModel.IPropertyOptionFiltersViewModel> {
				let obs = this.GetPropertyOptionFilterOption_Observable(pInputModel);
				let promise = new Promise<ProductsViewModel.IPropertyOptionFiltersViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}
