

	export class ServerTracker {
		private m_ServerTokens : any[] = [];

		MaakToken(naam: string): Object {
			var token: any = new Object();
			token.Naam = naam;
			token.Tijdstip = new Date();

			this.m_ServerTokens.push(token);
			return token;
		}

		VerwijderToken(token: Object) {
			var tokenindex = this.m_ServerTokens.indexOf(token);
			this.m_ServerTokens.splice(tokenindex, 1);
		}

		HeeftTokens() {
			if (this.m_ServerTokens.length > 0) {
				return true;
			}
			return false;
		}
	}
