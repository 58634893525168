import * as _ from 'lodash';
import * as ng from 'angular';
import * as HelpersBackwards from '.././Helpers/Backwards';
import * as ModelsAccountVeranderWachtwoord from '@models/AccountVeranderWachtwoord';
import * as DataContractsProject from '@datacontracts/Project';
import * as ViewModelsActivatie from '.././Models/Activatie';
import * as ViewModelsAccount from '.././Models/Account';
import {  LogonConsumer } from '@consumers/LogonController';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { AppConfigService } from '../infrastructure/app.configservice';
import { UserService } from '../auth/shared/services/user.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LoginScreenSettingsRequestModel }  from "@models/LoginScreenSettingsModel";
import { WoningEventService } from '../services/WoningEventService';
import { AccountActivationRequestModel } from '@models/AccountActivationModel';
import { IAccountActivationResponseModel } from '@datacontracts/AccountActivationModel';
import { IAccountActivationStatus as DataContractActivationStatus } from '@datacontracts/AccountActivationStatus';
import { AccountActivationStatus } from '@models/AccountActivationStatus';
import { AccountConsumer } from '@consumers/AccountController';
import { ResidentModelConsumer } from '@consumers/ResidentModelController';
import { ResidentChangePasswordRequestModel } from '@models/ResidentChangePasswordModel';

import {ForgotPasswordRequestModel } from '@models/ForgotPasswordModel';
import { IChangePasswordWithMailValidationResponseModel } from '@datacontracts/ChangePasswordWithMailValidationModel';
import { ChangePasswordWithMailValidationRequestModel } from '@models/ChangePasswordWithMailValidationModel';
export interface IAccountService {
	m_ToonHeeftUHulpNodig: boolean;
	m_ToonDisclaimerLink: boolean;
	HeeftRol(rolnaam: string): boolean;
	Login(account: ViewModelsAccount.Account, rememberme: boolean): ng.IPromise<boolean>;
	GeefAngularStartUrl(): string;
	FindAngularStartState(projectid: number): ng.IPromise<string>;
	GiveProjectAvailability(): string;
	GiveAllProjects(): ng.IPromise<DataContractsProject.IProjectInfo[]>;
	FindAngularStartUrl(): ng.IPromise<string>;
	GeefHuidigeBewonerID(): number;
	GeefHuidigPersoonID(): number;
	IsIngelogd(): boolean;
	DemoLogin(): ng.IPromise<boolean>;
	DemoUpgrade(pOpties: any): ng.IPromise<string>;
	GebruikActivatieSleutel(key: string): Observable<DataContractActivationStatus>;
	GeefActivatieAccount(): AccountActivationStatus;
	ActivatieGegevensInvullen(): ng.IPromise<IAccountActivationResponseModel>;
	GetOorzaak(): string;
	GetMelding(): string;
	Logout(): ng.IPromise<boolean>;
	Refresh(): ng.IPromise<boolean>;
	EersteInlogUitleg(): ng.IPromise<boolean>;
	IsEersteInlog(): boolean;
	IsEersteLogin(override: boolean): ng.IPromise<boolean>;
	GeefLoginNaam(): string;
	WachtwoordVergeten(emailadres: string): ng.IPromise<string>;
	GeefNaamVanOpdrachtgever(): ng.IPromise<string>;
	GeefWebsiteVanOpdrachtgever(): ng.IPromise<string>;
	//LoginSchermSettings(): void;
	LoginSchermSettings(): ng.IPromise<boolean>;
	ToonWachtwoordVergeten(): boolean;
	ToonActivatieMogelijkheid(): boolean;
	//GeefVeranderdWachtwoordModel(code: string): ng.IPromise<ModelsAccountVeranderWachtwoord.AccountVeranderWachtwoord>;
	GeefVeranderdWachtwoordModel(code: string): Observable<IChangePasswordWithMailValidationResponseModel>
	WachtwoordVeranderen(code: string, nieuwwachtwoord: string): ng.IPromise<string>;
	WachtwoordVeranderenMetOudWachtwoord(oudwachtwoord: string, nieuwwachtwoord: string): ng.IPromise<string>;	
}
@Injectable({
	providedIn: 'root',
})
export class AccountService implements IAccountService {
	public ServiceEventName: string = "AccountService-event";
	public m_Rollen: Array<string> = null;
	public m_HuidigeBewonerID: number = -1;
	public m_HuidigPersoonID: number = -1;
	public m_ActivatieAccount: AccountActivationStatus;
	public m_Oorzaak: string;
	public m_Melding: string;
	public m_EersteInlog: boolean;
	public m_LoginNaam: string;
	public m_AngularStartUrl: string;
	public m_Availability: string;

	public m_DemoActief: boolean;
	public m_WachtwoordVergetenActief: boolean;
	public m_metActivatieMogelijkheid: boolean;
	public m_LaptopVerkoop: boolean;
	public m_ToonHeeftUHulpNodig: boolean;
	public m_ToonDisclaimerLink: boolean;
	IsIntern: boolean;

	constructor(
		private $http: HttpClient, private logonconsumer: LogonConsumer, private config: AppConfigService, private userservice: UserService, private router: Router, private woningeventservice: WoningEventService, private accountConsumer: AccountConsumer, private residentConsumer: ResidentModelConsumer) {
		'ngInject';
			if (this.config.Software.Rollen !== null) {
				this.m_Rollen = this.config.Software.Rollen.split(",");
			}

			this.m_LaptopVerkoop = this.config.Software.LaptopVerkoop;
			this.m_HuidigeBewonerID = this.config.Software.BewonerID;
			this.m_HuidigPersoonID = this.config.Software.PersoonID;
			this.m_LoginNaam = this.config.Software.LoginNaam;

			this.IsIntern = !this.config.Software.ExternalNetwork;
			
			this.PropageerNaarOverigeServices();
		//this.m_EersteInlog = false;
	}

	public HeeftRol(rolnaam: string): boolean {
		return _.some(this.m_Rollen, (r) => {
			return r.toLowerCase() == rolnaam.toLowerCase();
		});
	}

	public GeefAngularStartUrl(): string {
		return this.m_AngularStartUrl;
	}

	public GiveProjectAvailability(): string {
		return this.m_Availability;
	}

	public GiveAllProjects(): ng.IPromise<DataContractsProject.IProjectInfo[]> {
		var opties = {};
		let p = this.$http.post<DataContractsProject.IProjectInfo[]>('/Account/GiveAllProjects', opties);
		return p.toPromise();
	}

	public FindAngularStartUrl(): ng.IPromise<string> {
		var opties = { username: this.m_LoginNaam };

		return this.$http.post<string>('/Account/FindAngularStartUrl', opties).toPromise();
	}

	public FindAngularStartState(projectid: number): ng.IPromise<string> {
		var opties = { projectid: projectid };

		return this.$http.post<string>('/Account/FindAngularStartState', opties).toPromise();
	}

	public IsEersteInlog(): boolean {
		return this.m_EersteInlog;
	}

	public GeefHuidigeBewonerID(): number {
		return this.m_HuidigeBewonerID;
	}

	public GeefHuidigPersoonID(): number {
		return this.m_HuidigPersoonID;
	}

	public IsIngelogd(): boolean {
		return this.m_HuidigPersoonID > 0;
	}

	public GebruikActivatieSleutel(key: string): Observable<DataContractActivationStatus> {
		let request = new AccountActivationRequestModel();
		request.ActivationCode = key;
		let obs = new Observable<DataContractActivationStatus>(observer => {
			this.accountConsumer.UseActivationKey_Observable(request).subscribe(response => {
				this.m_ActivatieAccount = new AccountActivationStatus(response.ActivationStatus);
				observer.next(response.ActivationStatus);
				observer.complete();
			})
		});
		
		return obs;
	}

	public GeefActivatieAccount(): AccountActivationStatus {
		return this.m_ActivatieAccount;
	}

	public GeefNaamVanOpdrachtgever(): ng.IPromise<string> {
		var opties = {};
		return this.$http.post<string>("/Account/GeefNaamVanOpdrachtgever", opties).toPromise();
	}

	public GeefWebsiteVanOpdrachtgever(): ng.IPromise<string> {
		var opties = {};

		return this.$http.post<string>("/Account/GeefWebsiteVanOpdrachtgever", opties).toPromise();
	}

	public ActivatieGegevensInvullen(): ng.IPromise<IAccountActivationResponseModel> {
		var model = this.GeefActivatieAccount();
		let request = new AccountActivationRequestModel();
		request.ActivationModel = model;
		return this.accountConsumer.ActivatieGegevensInvullen(request);
	}

	public GetOorzaak(): string {
		return this.m_Oorzaak;
	}

	public GetMelding(): string {
		return this.m_Melding;
	}

	public DemoLogin(): ng.IPromise<boolean> {
		var opties = {};

		return this.$http.post("/Account/DemoLogin", opties).pipe(map((d) => {
			let data: any = d;
			this.m_Rollen = data.Rollen;
			this.m_HuidigeBewonerID = data.BewonerID;
			this.m_HuidigPersoonID = data.PersoonID;
			this.m_EersteInlog = false;
			let gelukt: boolean = data.Gelukt;
			return gelukt;
		})).toPromise();
	}

	public DemoUpgrade(pOpties: any): ng.IPromise<string> {
		return this.$http.post<string>("/Account/DemoUpgrade", pOpties).toPromise();
	}

	public Logout(): ng.IPromise<boolean> {
		this.userservice.logout();
		this.router.navigateByUrl("/");
		return of(true).toPromise();
		// return this.$http.get("/Account/Logoff").pipe(map(x => {
		// 	this.m_LoginNaam = "";
		// 	return true;
		// })).toPromise();
	}

	private PropageerNaarOverigeServices()
	{
		if (this.config.Software.RollenBreadcrumbWoning.length > 0) {
			
			var entries = this.config.Software.RollenBreadcrumbWoning.split(',');
			var rolgevonden = _.some(entries, (e) => this.HeeftRol(e));
			if (rolgevonden) {
				this.woningeventservice.ZetBreadcrumbOpBewonerNiveau();
			}
		}

		if (this.woningeventservice.GeefHuidigeWoningID() != this.config.Software.InitieleWoning || 
		this.woningeventservice.GeefHuidigScenario() != this.config.Software.InitieelScenario)
		{
			this.woningeventservice.Internal_UpdateConfig(this.config.Software);
			//this.woningeventservice.WisselWoningEnScenario(this.config.Software.InitieleWoning , this.config.Software.InitieelScenario);
		}
	}

	public Login(account: ViewModelsAccount.Account, rememberme: boolean): ng.IPromise<boolean> {
		var opties = { UserName: account.LoginNaam, Password: account.Password, rememberme: rememberme };

		let obs = this.userservice.login(account.LoginNaam, account.Password).pipe(tap(d => {
			//var data: any = d;
			
		}), switchMap((x) =>
		{
			//ipe(map((x) => { return { ret: x, wuid: wuid } }))
			return this.config.loadobs().pipe(map((r) => { return x; } ))
		}), map((d) =>
		{
			this.m_Rollen = d.Rollen;
			this.m_HuidigeBewonerID = d.BewonerID;
			this.m_HuidigPersoonID = d.PersoonID;
			this.m_Oorzaak = d.Oorzaak;
			this.m_Melding = d.Melding;
			this.m_LoginNaam = account.LoginNaam;
			this.m_AngularStartUrl = d.AngularStartUrl;
			this.m_Availability = d.Availability;
			this.m_EersteInlog = d.EersteInlog;
			
			if (d.RedirectTarget != null) {
				this.router.navigateByUrl("/");
			}
			else
			{
				this.router.navigateByUrl("/");
			}

			this.PropageerNaarOverigeServices();

			let gelukt: boolean = d.Gelukt;
			return gelukt;
		}));
		return obs.toPromise();
		//this.$http.get('/Account/Login?UserName=' + account.LoginNaam + "&Password=" + account.Password);//.then(d => {
		//	var data: any = d.data;

		//	var succes = true;
		//	deferred.resolve(succes);
		//});

		//return deferred.promise;
	}

	public Refresh(): ng.IPromise<boolean> {
		var opties = {};

		return this.$http.post("/Account/Refresh", opties).pipe(map(d => {
			var data: any = d;
			this.m_Rollen = data.Rollen;
			this.m_HuidigeBewonerID = data.BewonerID;
			this.m_HuidigPersoonID = data.PersoonID;
			this.m_Oorzaak = data.Oorzaak;
			this.m_Melding = data.Melding;
			this.m_AngularStartUrl = data.AngularStartUrl;
			this.m_Availability = data.Availability;

			if (data.AngularStartUrl != null) {
				window.location.assign(data.AngularStartUrl);
				return;
			}

			this.m_EersteInlog = data.EersteInlog;
			let gelukt: boolean = data.Gelukt;
			return gelukt;
		})).toPromise();
	}

	public GeefLoginNaam(): string {
		return this.m_LoginNaam;
	}

	public IsEersteLogin(override: boolean): ng.IPromise<boolean> {

		var opties = { UserID: this.m_HuidigPersoonID };

		if (!override) {
			let obs = this.$http.post<boolean>("/Account/IsDitDeEersteLogin", opties).pipe(map(d => {
				var data: any = d;
				this.m_EersteInlog = data;
				return this.m_EersteInlog;
			}));
			return obs.toPromise();
		}
		else {
			return of(true).toPromise();
		}
	}

	public EersteInlogUitleg(): ng.IPromise<boolean> {
		var opties = { UserID: this.m_HuidigPersoonID };

		return this.$http.post("/Account/EersteLogin", opties).pipe(map(d => {
			var data: any = d;
			let gelukt: boolean = data.Gelukt;
			return gelukt;
		})).toPromise();
		//this.$http.get('/Account/Login?UserName=' + account.LoginNaam + "&Password=" + account.Password);//.then(d => {
		//	var data: any = d.data;

		//	var succes = true;
		//	deferred.resolve(succes);
		//});

		//return deferred.promise;
	}

	public WachtwoordVeranderen(code: string, nieuwwachtwoord: string): ng.IPromise<string> {
		var opties = { pCode: code, pNieuwWachtwoord: nieuwwachtwoord };

		let req = new ChangePasswordWithMailValidationRequestModel();
		req.Code = code;
		req.NewPassword = nieuwwachtwoord;
		return this.accountConsumer.ChangePasswordWithMailValidation_Observable(req).pipe(map(d => d.Bericht)).toPromise();
	}

	public WachtwoordVeranderenMetOudWachtwoord(oudwachtwoord: string, nieuwwachtwoord: string): ng.IPromise<string> {
		let req = new ResidentChangePasswordRequestModel();
		req.OudWachtwoord = oudwachtwoord;
		req.NieuwWachtwoord = nieuwwachtwoord;

		return this.residentConsumer.ChangePassword_Observable(req).pipe(map(x => x.Bericht)).toPromise();

		//var opties = { pUsername: this.m_LoginNaam, pOudWachtwoord: oudwachtwoord, pNieuwWachtwoord: nieuwwachtwoord };

		//return this.$http.post<string>('/Account/WachtwoordVeranderenMetOudWachtwoord', opties).toPromise();
	}

	public WachtwoordVergeten(emailadres: string): ng.IPromise<string> {
		let req = new ForgotPasswordRequestModel();
		req.EmailAdres = emailadres;
		return this.accountConsumer.ForgotPassword_Observable(req).pipe(map(x => x.TekstUit)).toPromise();
	}

	public LoginSchermSettings(): ng.IPromise<boolean> {

		let req = new LoginScreenSettingsRequestModel();

		return this.logonconsumer.LoginScreenSettings_Observable(req).pipe(map(d => {
			console.log('login screen:');
			console.log(d);
			this.m_metActivatieMogelijkheid = d.LogonStatus.metActivatieMogelijkheid;
			this.m_WachtwoordVergetenActief = d.LogonStatus.WachtwoordVergetenActief;
			this.m_ToonHeeftUHulpNodig = d.LogonStatus.toonHeeftUHulpNodig;
			this.m_ToonDisclaimerLink = d.LogonStatus.toonDisclaimer;
			this.m_DemoActief = d.LogonStatus.DemoActief;
			return true;
		})).toPromise();
	}

	public ToonWachtwoordVergeten(): boolean {
		return this.m_WachtwoordVergetenActief;
	}

	public ToonActivatieMogelijkheid(): boolean {
		return this.m_metActivatieMogelijkheid;
	}

	public GeefVeranderdWachtwoordModel(code: string): Observable<IChangePasswordWithMailValidationResponseModel>
	{
		let req = new ChangePasswordWithMailValidationRequestModel();
		req.Code = code;
		return this.accountConsumer.ChangePasswordWithMailValidation_Observable(req);
	}
}

