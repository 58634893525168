import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { ProductsConsumer } from '@consumers/ProductsController';
import { PrijslijstConsumer } from '@consumers/PrijslijstController';
import { FabUserViewModel, FabUserLoginViewModel, InfoViewModel, AttachRequestModel, AttachResponseModel, CookieViewModel } from '@models/PrijslijstViewModel';


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class LoginComponent {

  public Loading = true;
  public NoAccess = false;
  public Error: string = "";

  public UserName: string = "";
  public Password: string = "";

  private TokenName: string;
  private VerifyName: string;

  constructor(
    private prijslijst: PrijslijstConsumer,
    public router: Router
  ) {
    this.Attach();
  }

  public Attach(): void {

    this.prijslijst.GetCookies_Observable().subscribe((data_cookies: CookieViewModel) => {

      var request = new CookieViewModel();

      this.TokenName = data_cookies.TokenName;
      this.VerifyName = data_cookies.VerifyName;

      request.TokenValue = this.getCookie(data_cookies.TokenName);
      request.VerifyValue = this.getCookie(data_cookies.VerifyName);

      if (request.TokenValue == "") {
        this.setCookie(data_cookies.TokenName, data_cookies.TokenValue, 100);
        request.TokenValue = data_cookies.TokenValue;
      }

      if (request.VerifyValue == "") {
        this.prijslijst.Attach_Observable(request).subscribe((data: AttachResponseModel) => {
          window.location.href = data.URL;
          //console.log(data.URL);
        });
      }
      else {
        this.Info(request);
      }
    });
  }

  public getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  public setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  public Login(): void {
    this.Loading = true;

    var UserViewModel = new FabUserViewModel();

    UserViewModel.UserName = this.UserName;
    UserViewModel.Password = this.Password;

    var request = new CookieViewModel();

    request.TokenValue = this.getCookie(this.TokenName);
    request.VerifyValue = this.getCookie(this.VerifyName);

    UserViewModel.TokenValue = request.TokenValue;
    UserViewModel.VerifyValue = request.VerifyValue;

    this.prijslijst.Login_Observable(UserViewModel).subscribe((data) => {

      var model = new FabUserLoginViewModel(data);

      if (model.Response == "") {
        window.location.href = "overview";
      }
      else {
        this.Error = model.Response;
        this.Loading = false;
      }
    });
  }

  public Info(request: CookieViewModel): void {
    this.NoAccess = false;
    this.prijslijst.Info_Observable(request).subscribe((data: InfoViewModel) => {
      if (data.Message == "success") {
        window.location.href = "overview";
      }
      else if (data.Message == "expired") {
        //alert("expired");

        this.setCookie(this.TokenName, "", 100);
        this.setCookie(this.VerifyName, "", 100);

        this.Attach();
      }
      else if (data.Message == "no-access") {
        this.NoAccess = true;
        this.Loading = false;
      }
      else {
        this.Loading = false;
      }
    });
  }

  public handleKeyUp(e) {
    if (e.keyCode === 13) {
      this.Login();
    }
  }

  /**
     * Attach session.
     * Will redirect to SSO server.
     */
  //public attach(): void {
  //  const req = $.ajax({
  //    url: 'attach.php',
  //    crossDomain: true,
  //    dataType: 'jsonp'
  //  });

  //  req.done((data: any) => {
  //    //if (code && code >= 400) { // jsonp failure
  //    //  this.showError(data);
  //    //  return;
  //    //}

  //    $.ajax({ method: 'POST', url: 'verify.php', data: data }).done(function () {
  //      this.doApiRequest('info', null);
  //    });
  //  });

  //  req.fail(function (jqxhr) {
  //    this.showError(jqxhr)
  //  });
  //}

  ///**
  //  * Do an AJAX request to the API
  //  *
  //  * @param command   API command
  //  * @param params    POST data
  //  * @param callback  Callback function
  //  */
  //public doApiRequest(command, params) {
  //  const req = $.ajax({
  //    url: 'api.php?command=' + command,
  //    method: params ? 'POST' : 'GET',
  //    data: params,
  //    dataType: 'json'
  //  });

  //  req.done(() => {
  //    alert('done');
  //  });

  //  req.fail(function (jqxhr) {
  //    this.showError(jqxhr);
  //  });
  //}

  ///**
  // * Display the error message
  // *
  // * @param data
  // */
  //public showError(data) {
  //  const message = typeof data === 'object' && data.error ? data.error : 'Unexpected error';
  //  alert(message);
  //}

  ///**
  // * Display user info
  // *
  // * @param info
  // */
  //public showUserInfo(info) {
  //  const body = $('body');
  //  const userInfo = $('#user-info');

  //  body.removeClass('anonymous authenticated');
  //  userInfo.html('');

  //  if (info) {
  //    for (const key in info) {
  //      userInfo.append($('<dt>').text(key));
  //      userInfo.append($('<dd>').text(info[key]));
  //    }
  //  }

  //  body.addClass(info ? 'authenticated' : 'anonymous');
  //}

}
