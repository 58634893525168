import { Component, Input, OnInit } from '@angular/core';
import { FadeInOut } from '../../../smarttwin-ui/animations/WctConfiguratorAnimations';

@Component({
  selector: 'wct-survey-question',
  templateUrl: './wct-survey-question.component.html',
  styleUrls: ['./wct-survey-question.component.scss'],
  animations: FadeInOut
})
export class WctSurveyQuestionComponent implements OnInit {
  @Input() question;

  constructor() { }

  ngOnInit(): void {
  }

}
