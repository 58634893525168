import * as ng from 'angular';
import * as ServicesFatalErrorService from '.././services/FatalErrorService';
import * as ServicesWebGLViewerService from '.././services/WebGLViewerService';
import * as ServicesSceneService from '../viewers/SceneService';
import * as ServicesProjectService from '.././services/ProjectService';
import * as ServicesSceneTypescript from '.././services/SceneTypescript';
import * as ViewModelsWebGLSessie from '.././Models/WebGLSessie';
import * as drawsceneconsumer from '@consumers/DrawSceneController';
import { WoningEventService } from './WoningEventService';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../infrastructure/app.configservice';
	
	export interface IViewer3DService {
		RegistreerViewer(canvasid: string, sessie: ViewModelsWebGLSessie.WebGLSessie, selectie: boolean, suppressRedraw: boolean): ServicesSceneTypescript.SceneSession;
		DeregistreerViewer(sessie: ServicesSceneTypescript.SceneSession): void;
		GeefSessies(sessietype: string): Array<ServicesSceneTypescript.SceneSession>;
	}

	@Injectable({
		providedIn: 'root',
	})
	export class Viewer3DService implements IViewer3DService {
		ServiceEventName: string = 'Viewer3DService-event';
		private m_Sessies: { [sessietype: string]: Array<ServicesSceneTypescript.SceneSession>; } = {};

		constructor(private projectservice: ServicesProjectService.ProjectService, private $http: HttpClient, 
			
			private sceneservice: ServicesSceneService.SceneService, private webglviewerservice: ServicesWebGLViewerService.WebGLViewerServiceTypescript, 
			private fatalerrorservice: ServicesFatalErrorService.FatalErrorService, 
			private drawsceneconsumer: drawsceneconsumer.DrawSceneConsumer,
			private config: AppConfigService,
			private woningeventservice: WoningEventService) {
'ngInject';
		}

		GeefSessies(sessietype: string): Array<ServicesSceneTypescript.SceneSession> {
			if (this.m_Sessies[sessietype] == null) {
				this.m_Sessies[sessietype] = [];
			}
			return this.m_Sessies[sessietype];
		}

		RegistreerViewer(canvasid: string, sessie: ViewModelsWebGLSessie.WebGLSessie, selectie: boolean, suppressRedraw: boolean): ServicesSceneTypescript.SceneSession {
			console.log("REGISTREER VIEWER -- CANVAS ID " + canvasid);
			var sess: ServicesSceneTypescript.SceneSession
			sess = new ServicesSceneTypescript.SceneSession(this.config, this.projectservice, this.$http, this.sceneservice, this.webglviewerservice, canvasid, sessie.SceneType, false, false, selectie, null, null, sessie.GeefAchtergrondKleurFirmament(), sessie.GeefAchtergrondKleurTerrein(), this.fatalerrorservice,  suppressRedraw, this.drawsceneconsumer, this.woningeventservice);

			sess.VolgCameraType(sessie.GeefVolgCameraType());

			this.GeefSessies(sessie.SceneType).push(sess);

			return sess;
		}

		DeregistreerViewer(sessie: ServicesSceneTypescript.SceneSession): void {
			var sessies = this.GeefSessies(sessie.scenetype);
			var index = sessies.indexOf(sessie);
			if (index >= 0) {
				sessies.splice(index, 1);
			}
			else {
				console.log('Sessie niet gevonden bij scenetype ' + sessie.scenetype);
			}
		}
	}
