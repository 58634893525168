/**
 * @ref WoonConnectDomein/Documenten/RechtenRegel.cs
 */
	/**
	* Originele namespace: WoonConnectDomein.Documenten.TypeRecht
	*/
	export enum TypeRecht {
		Toegang = 0,
		
		Lezen = 1,
		
		Toevoegen = 2,
		
		Vervangen = 3,
		
		Verwijderen = 4,
		
		MapBeheer = 5
	}
	/**
	* Originele namespace: WoonConnectDomein.Documenten.TypeRechtToegestaan
	*/
	export enum TypeRechtToegestaan {
		WelToegestaan = 1,
		
		NietToegestaan = 2
	}

