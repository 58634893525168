<div class="content">
  <!--<div class="splash">
  </div>-->
  <div class="panel" style="padding-top: 10px; padding-bottom: 10px;">
    <h1>Welkom bij de Derbigum Bestekservice.</h1>

    <p>
      Stel met deze bestekservice eenvoudig uw eigen bestek samen.<br />
      Het resultaat is het product dat het beste past bij uw criteria.
    </p>
    <p>
      Kies één van onderstaande categorieën en start met het samenstellen van uw bestek.
    </p>
  </div>
  <div *ngIf="Loading" class="loading-block">
    <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
  </div>
  <div class="category-grid" *ngIf="!Loading">
    <div *ngFor="let filteroption of GetCategories().Options">
      <a routerLink="/categorie/{{ filteroption.Id }}" [ngStyle]="{backgroundImage:'url(\'/Content/KlantSpecifiek/BCB20343/category-shadow.png\'), url(\''+  filteroption.Image + '\')'}">
        <p class="title">
          {{ filteroption.Name }}
        </p>
      </a>
    </div>
  </div>
  <div class="panel" style="padding-top: 10px; padding-bottom: 10px;">
    <p>
      <b>Is uw project erg complex? Heeft u hulp nodig of heeft u vragen?</b><br />
      Bel of email ons voor technische ondersteuning:<br />
      <b>Email</b>: <a style="text-decoration: underline;" href="mailto:atze.walsweer@derbigum.com">atze.walsweer@derbigum.com</a><br />
      <b>Mobiel</b>: +31 6 511 554 78<br />

    </p>
  </div>
</div>
