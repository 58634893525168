/**
 * @ref WoonConnectViewModel/BuildingComponentViewModel.cs
 */

 import * as DataContracts from "@datacontracts/BuildingComponentViewModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.BuildingComponentViewModel
	 */
	 

	export interface IBuildingComponentViewModel {
		Id: number;
		DisplayName: string;
		DefaultContactPersoonId: number;
		ToJsonContract(): DataContracts.IBuildingComponentViewModel;
	}

	export class BuildingComponentViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get DisplayName(): string {
			return this._DisplayName;
		}
		public set DisplayName(newDisplayName: string) {
			this._DisplayName = newDisplayName;
		}

		public get DefaultContactPersoonId(): number {
			return this._DefaultContactPersoonId;
		}
		public set DefaultContactPersoonId(newDefaultContactPersoonId: number) {
			this._DefaultContactPersoonId = newDefaultContactPersoonId;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _DisplayName: string = "";
		protected _DefaultContactPersoonId: number = 0;

		constructor(data?: DataContracts.IBuildingComponentViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._DisplayName = data.DisplayName;
				this._DefaultContactPersoonId = data.DefaultContactPersoonId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentViewModel {
			return {
				Id: this.Id,
				DisplayName: this.DisplayName,
				DefaultContactPersoonId: this.DefaultContactPersoonId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.BuildingComponentRequestAllModel
	 */
	 

	export interface IBuildingComponentRequestAllModel {
		Temp: string;
		ToJsonContract(): DataContracts.IBuildingComponentRequestAllModel;
	}

	export class BuildingComponentRequestAllModel {
		// property-Properties
		public get Temp(): string {
			return this._Temp;
		}
		public set Temp(newTemp: string) {
			this._Temp = newTemp;
		}

		// field-Properties

		// fields
		protected _Temp: string = "";

		constructor(data?: DataContracts.IBuildingComponentRequestAllModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentRequestAllModel): void {
			if(data) {
				// vul properties
				this._Temp = data.Temp;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentRequestAllModel {
			return {
				Temp: this.Temp
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.BuildingComponentResultAllModel
	 */
	 

	export interface IBuildingComponentResultAllModel {
		BuildingComponents: BuildingComponentViewModel[];
		ToJsonContract(): DataContracts.IBuildingComponentResultAllModel;
	}

	export class BuildingComponentResultAllModel {
		// property-Properties
		public get BuildingComponents(): BuildingComponentViewModel[] {
			return this._BuildingComponents;
		}
		public set BuildingComponents(newBuildingComponents: BuildingComponentViewModel[]) {
			this._BuildingComponents = newBuildingComponents;
		}

		// field-Properties

		// fields
		protected _BuildingComponents: BuildingComponentViewModel[] = [];

		constructor(data?: DataContracts.IBuildingComponentResultAllModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentResultAllModel): void {
			if(data) {
				// vul properties
				this._BuildingComponents = data.BuildingComponents ? data.BuildingComponents.map(buildingComponentViewModelItem => new BuildingComponentViewModel(buildingComponentViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentResultAllModel {
			return {
				BuildingComponents: (this.BuildingComponents ? this.BuildingComponents.map(buildingComponentViewModelItem => new BuildingComponentViewModel(buildingComponentViewModelItem).ToJsonContract()): [])
			}
		}
	}
