<!-- Product toevoegen! -->
    <mat-card>
        <mat-card-title>Nieuw Product Toevoegen.</mat-card-title>
        <mat-card-content>
            <form #form="ngForm">

                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Name</mat-label>
                        <input matInput name="productNaam" [(ngModel)]="newProduct.Name" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Kenmerk</mat-label>
                        <input matInput name="productKenmerk" [(ngModel)]="newProduct.Kenmerk" ngModel #kenmerk="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Bot</mat-label>
                        <input matInput name="productBot" [(ngModel)]="newProduct.Bot" ngModel required #bot="ngModel">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Position</mat-label>
                        <input matInput type="number" name="productPosition" [(ngModel)]="newProduct.Position">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO1 Type</mat-label>
                        <input matInput name="productD01Type" [(ngModel)]="newProduct.DO1Type">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Do1 Naam</mat-label>
                        <input matInput name="productD01Naam" [(ngModel)]="newProduct.DO1Naam">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO2 Type</mat-label>
                        <input matInput name="productD02Type" [(ngModel)]="newProduct.DO2Type">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO2 Naam</mat-label>
                        <input matInput name="productD02Naam" [(ngModel)]="newProduct.DO2Naam">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO3 Type</mat-label>
                        <input matInput name="productD02Type" [(ngModel)]="newProduct.DO3Type">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO3 Naam</mat-label>
                        <input matInput name="productD03Naam" [(ngModel)]="newProduct.DO3Naam">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO4 Type</mat-label>
                        <input matInput name="productD04Type" [(ngModel)]="newProduct.DO4Type">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>DO4 Naam</mat-label>
                        <input matInput name="productD04Naam" [(ngModel)]="newProduct.DO4Naam">
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Omschrijving</mat-label>
                        <input matInput name="productDescription" [(ngModel)]="newProduct.Description">
                    </mat-form-field>
                </p>

                <div>
                    <button mat-raised-button color="primary" type="submit" (click)="productToevoegen()" [disabled]="! form.valid">Product toevoegen</button>
                </div>

            </form>

        </mat-card-content>
        
    </mat-card>