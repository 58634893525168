import { NgModule } from '@angular/core';
import { Translate } from '../services/TranslateServicePipe';

@NgModule({
    imports: [   
               
    ],
    exports: [
        Translate
    ],
    declarations: [
        Translate
    ],
    entryComponents: [
        
    ],
    bootstrap: [
    
    ],
    providers: [
        
    ]
})

export class TranslateModule {

}