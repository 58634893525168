// Angular components and modules
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';

// Admin components
import { FabAdminProductComponent } from './components/FabAdminProduct';
import { FabAdminDisplayProductPropertyComponent } from './components/AdminProduct/FabAdminDisplayProductProperty';
import { FabAdminComponent } from './components/FabAdmin';
import { FabAdminProductsComponent } from './components/FabAdminProducts';
import { FabAdminAddProductComponent } from './components/FabAdminAddProduct';
import { FabAdminSearchProductComponent } from './components/AdminProduct/FabAdminSearchProduct';
import { FabAdminKoppelFilterOption } from './components/AdminProduct/FabAdminKoppelFilterOption';
import { FabAdminOverviewComponent } from './components/FabAdminOverview';
import { FabAdminProductVariantComponent } from './components/FabAdminProductVariant';
import { FabAdminAddProductVariantComponent } from './components/FabAdminAddProductVariant';
import { FabAdminFiltersComponent } from './components/AdminFilters/FabAdminFilters';
import { FabAdminFilterComponent } from './components/AdminFilters/FabAdminFilter';
import { FabAdminFilterTypeComponent } from './components/AdminFilters/FabAdminFilterType';
import { FabAdminFilterOptionsComponent } from './components/AdminFilters/FabAdminFilterOptions';
import { FabAdminFilterRelationComponent } from './components/AdminFilters/FabAdminFilterRelation';
import { FabAdminFilterVariantRelationComponent } from './components/AdminFilters/FabAdminFilterVariantRelation';
import { FabAdminProductPropertyComponent } from './components/AdminProduct/FabAdminProductProperty';
import { FabAdminProductPropertyOptionComponent } from './components/AdminProduct/FabAdminProductPropertyOption';
import { ProductDetailsComponent } from './components/AdminProduct/ProductDetails';
import { FabAdminDashboardComponent } from './components/FabAdminDashboard';
import { FabAdminProductPropertyAddComponent } from './components/AdminProduct/FabAdminProductPropertyAdd';
import { FabAdminProductPropertiesComponent } from './components/AdminProduct/FabAdminProductProperties';
import { FabAdminFilterOptionVariantComponent } from './components/AdminFilters/FabAdminFilterOptionVariant';
import { FabDragAndDrop } from './components/drag-and-drop/drag-and-drop.component';
//import { FabAdminRelationsComponent } from '../fabrikanten/bestekken/AdminFilters/FabAdminRelations';

// WoonConnect components
import { WoonAdminComponent } from '../woonconnect/WoonAdmin';
import { WoonUsersComponent } from '../woonconnect/Users/Users';
import { WoonBuildingsComponent } from '../woonconnect/Buildings/Buildings';
import { WoonAddBuildingComponent } from '../woonconnect/Buildings/AddBuilding';
import { WoonBuildingDetailComponent } from '../woonconnect/Buildings/BuildingDetail';
import { WoonAdminProjectsComponent } from '../woonconnect/Projects/ProjectOverview';
import { WoonUserDetailComponent } from '../woonconnect/Users/UserDetail';

// Themes and Material
//import { ThemeModule, DefaultTheme } from '../shared/theme';
import { MaterialModule } from '../shared/material';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// Other modules
import { ViewerModule } from '../../viewers/viewer.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CoreUiModule } from '../../coreui/coreui.module';

// Consumers
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { FabrikantenAdminConsumer } from '@consumers/FabrikantenAdminController';

// Services
import { GoogleAnalyticsService } from '../../services/GoogleAnalyticsService';

// Localization
import { registerLocaleData, DatePipe } from '@angular/common'
import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeNl, 'nl');

// Store
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { GetInfraStatus } from '../../modules/webpack/sitestatus';

// Some logic
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function debugStore(reducer: ActionReducer<any>): ActionReducer<any> {
	return function (state, action) {
		console.log('ngrx store: pre', state);
		console.log('ngrx store action', action.type, action);
		let post = reducer(state, action);
		console.log('ngrx store: post', state);
		return post;
	};
}

export const metaReducers: MetaReducer<any>[] = [debugStore];

let production = GetInfraStatus().ClientsideProduction;


// Routes
const routes: Routes = [
  {
    path: 'fab-admin', component: FabAdminComponent, children: [
      { path: '', component: FabAdminDashboardComponent },
      { path: 'products', component: FabAdminProductsComponent },
      { path: 'add-product', component: FabAdminAddProductComponent },
      { path: 'search-product', component: FabAdminSearchProductComponent },
      { path: 'koppel-filteroption', component: FabAdminKoppelFilterOption },
      { path: 'overview', component: FabAdminOverviewComponent },
      { path: 'product/:productid', component: FabAdminProductComponent },
      { path: 'product-variant/:variantid', component: FabAdminProductVariantComponent },
      { path: 'filters', component: FabAdminFiltersComponent },
      { path: 'filter/:filterid', component: FabAdminFilterComponent },
      { path: 'filter-options', component: FabAdminFilterOptionsComponent },
      { path: 'filter-type', component: FabAdminFilterTypeComponent },
      { path: 'filter-relation', component: FabAdminFilterRelationComponent },
      { path: 'product-property/:propertyid', component: FabAdminProductPropertyComponent },
      { path: 'product-property-option/:propertyoptionid', component: FabAdminProductPropertyOptionComponent },
      { path: 'add-product-variant', component: FabAdminAddProductVariantComponent },
      { path: 'product-details', component: ProductDetailsComponent },
      { path: 'filter-variant-relation', component: FabAdminFilterVariantRelationComponent },
      { path: 'product-property-add', component: FabAdminProductPropertyAddComponent },
      { path: 'product-properties', component: FabAdminProductPropertiesComponent },
      { path: 'filteroption-variant', component: FabAdminFilterOptionVariantComponent },
      //{ path: 'relations', component: FabAdminRelationsComponent },
    ]
  },
  {
    path: 'wct-admin', component: WoonAdminComponent, children: [
      { path: 'users', component: WoonUsersComponent },
      { path: 'user-detail/:userid', component: WoonUserDetailComponent },
      { path: 'buildings', component: WoonBuildingsComponent },
      { path: 'building-detail/:adresid', component: WoonBuildingDetailComponent },
      { path: 'add-building', component: WoonAddBuildingComponent },
      { path: 'projects-overview', component: WoonAdminProjectsComponent },

    ]
  }
];

// Module definition
@NgModule({
  imports: [
    ViewerModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CoreUiModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    StoreModule.forRoot({}, !production ? { metaReducers } : {}),
    EffectsModule.forRoot(),
    PerfectScrollbarModule,
    //ThemeModule.forRoot({
    //  themes: [DefaultTheme],
    //  active: 'default'
    //}),
    SlickCarouselModule,
    HttpClientModule
  ],
  exports: [
    RouterModule,
    //MatTableModule,
    //MatPaginatorModule,
    //MatSortModule
  ],
  declarations: [
    FabDragAndDrop,
    FabAdminComponent,
    FabAdminProductComponent,
    FabAdminDisplayProductPropertyComponent,
    FabAdminAddProductComponent,
    FabAdminSearchProductComponent,
    FabAdminKoppelFilterOption,
    FabAdminOverviewComponent,
    FabAdminProductsComponent,
    FabAdminProductVariantComponent,
    FabAdminFiltersComponent,
    FabAdminFilterComponent,
    //FabAdminRelationsComponent,
    WoonAdminComponent,
    FabAdminFilterOptionsComponent,
    FabAdminFilterTypeComponent,
    FabAdminProductPropertyComponent,
    FabAdminFilterRelationComponent,
    FabAdminAddProductVariantComponent,
    FabAdminFilterVariantRelationComponent,
    FabAdminProductPropertyOptionComponent,
    ProductDetailsComponent,
    FabAdminDashboardComponent,
    FabAdminProductPropertyAddComponent,
    FabAdminProductPropertiesComponent,
    FabAdminFilterOptionVariantComponent,
    //WoonConnect
    WoonAdminComponent,
    WoonUsersComponent,
    WoonUserDetailComponent,
    WoonBuildingsComponent,
    WoonAddBuildingComponent,
    WoonBuildingDetailComponent,
    WoonAdminProjectsComponent,
  ],
  entryComponents: [
    FabAdminProductsComponent,
  ],
  providers: [
    FabrikantenConsumer,
    FabrikantenAdminConsumer,
    
    DatePipe,
    GoogleAnalyticsService,
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: MAT_DATE_LOCALE, useValue: 'nl' },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ]
})

export class FabAdminModule {
}
