import { Injectable } from '@angular/core';
import * as ServicesWebGLViewerService from '.././services/WebGLViewerService';
import * as HelpersBackwards from '.././Helpers/Backwards';
import * as ViewerTypescript from '../modules/webgl/WebGLViewer';
import { AppConfigService } from '../infrastructure/app.configservice';
// import { basename } from 'path';


	export interface WebGLViewer {
	}

	export interface WebGLFragment {

	}

	export interface WebGLBelichting {

	}

	export interface IWebGLViewerService {
		MaakViewer(canvas: any, opties: any): WebGLViewer;
		add(viewer: WebGLViewer, fragment: WebGLFragment, gereedCallback: () => void);
		dispose(canvas: any);
		MaakBelichting(): WebGLBelichting;
		GeefBelichting(viewer: WebGLViewer): WebGLBelichting;
		ZetBelichtingLichtval(belichting: WebGLBelichting, lichtval: any);
		ZetBelichtingLichtbronSterkte(belichting: WebGLBelichting, sterkte: any);
		ZetBelichtingIsCameraLicht(belichting: WebGLBelichting, cameralicht: any);
		ZetBelichtingLichtSchittering(belichting: WebGLBelichting, schittering: any);
		ZetBelichtingOmgevinglichtSterkte(belichting: WebGLBelichting, lichtsterkte: any);
		ZetBelichtingSlagschaduwVerzadiging(belichting: WebGLBelichting, verzadiging: any);
		ZetBelichtingSlagschaduwHint(belichting: WebGLBelichting, hint: any);
		ZetBelichtingSlagschaduw(belichting: WebGLBelichting, slagschaduw: any);
		ZetBelichting(viewer: WebGLViewer, belichting: WebGLBelichting);

		GeefBelichtingOmgevinglichtSterkte(belichting: WebGLBelichting): any;
		GeefBelichtingLichtbronSterkte(belichting: WebGLBelichting): any;
		GeefBelichtingLichtschittering(belichting: WebGLBelichting): any;
		GeefBelichtingSlagschaduwVerzadiging(belichting: WebGLBelichting): any;

		GeefBelichtingLichtval(belichting: WebGLBelichting): any;
		GeefBelichtingIsCameraLicht(belichting: WebGLBelichting): any;
		GeefBelichtingSlagschaduw(belichting: WebGLBelichting): any;

		GeefBelichtingSlagschaduwHint(belichting: WebGLBelichting): any;

		KopieerBelichting(belichting: WebGLBelichting): WebGLBelichting;

		GeefZoomSchaal(viewer: WebGLViewer): any;
		GeefCentrum(viewer: WebGLViewer): any;
		GeefKijkhoeken(viewer: WebGLViewer): any;
		GeefPositie(viewer: WebGLViewer): any;
		GeefKijkrichting(viewer: WebGLViewer): any;
		IsNavigeerbaar(viewer: WebGLViewer): any;
		IsTerreinCamera(viewer: WebGLViewer): boolean;

		ZetZoomSchaal(viewer: WebGLViewer, zoomschaal: any);
		ZetCentrum(viewer: WebGLViewer, centrum: any);
		ZetKijkhoeken(viewer: WebGLViewer, kijkhoeken: any);
		ZetPositie(viewer: WebGLViewer, positie: any);
		ZetKijkrichting(viewer: WebGLViewer, richting: any);
		ZetNavigeerbaar(viewer: WebGLViewer, navigeerbaar: any);
		ZetTerreinCamera(viewer: WebGLViewer, aan: any);

		ZetZichtStandaard(viewer: WebGLViewer);
		ZetZoomGeheel(viewer: WebGLViewer);

		ZetAchtergrondkleur(viewer: WebGLViewer, kleur: any);
		ZetOrthografischeProjectie(viewer: WebGLViewer, aan: any);
		ZetLogEnable(viewer: WebGLViewer, aan: any);

		ZetAnimatieCallback(viewer: WebGLViewer, callback: any);

		ZetTekenHints(viewer: WebGLViewer, aan: boolean);

		ZetNabewerkingenDefaults(viewer: WebGLViewer, idle: any);

		ZetBerichtCallback(viewer: WebGLViewer, callback: any);

		ZetSelectieVeranderd(viewer: WebGLViewer, callback: any, callback2: any);

		ZetSelectieKleur(viewer: WebGLViewer, kleur: number[]);

		ToonModel(viewer: WebGLViewer);

		ZetSelectieToegestaan(viewer: WebGLViewer, aan: boolean);
	}

	export class WebGLViewerServiceCommon {
		add(viewer: WebGLViewer, fragment: WebGLFragment, gereedCallback: () => void) {
			var v: any = viewer;
			v.add(fragment, gereedCallback);
		}

	}

	export class WebGLViewerService71Common extends WebGLViewerServiceCommon
	{
		constructor(private config: AppConfigService) 
		{
			super()
		}

		protected Log(viewer: WebGLViewer, s)
		{
			if (this.config.Software.BuildMode == 'DEBUG') {
				//viewer.
				console.log('%c' + s, 'background: white; color: green');
			}
		}

		private GeefViewerIntern(viewer: WebGLViewer): any
		{
			return viewer;
		}

		private GeefBelichtingIntern(belichting: WebGLBelichting): any {
			return belichting;
		}

		

		GeefBelichtingOmgevinglichtSterkte(belichting: WebGLBelichting): any
		{
			var b: any = belichting;
			return b.omgevinglichtSterkte;
		}

		GeefBelichtingLichtbronSterkte(belichting: WebGLBelichting): any
		{
			var b: any = belichting;
			return b.lichtbronSterkte;
		}

		GeefBelichtingLichtschittering(belichting: WebGLBelichting): any
		{
			var b: any = belichting;
			return b.lichtSchittering;
		}

		GeefBelichtingSlagschaduwVerzadiging(belichting: WebGLBelichting): any
		{
			var b: any = belichting;
			return b.slagschaduwVerzadiging;
		}

		GeefBelichtingLichtval(belichting: WebGLBelichting): any
		{
			var b: any = belichting;
			return b.lichtval;
		}
	
		GeefBelichtingIsCameraLicht(belichting: WebGLBelichting): any
		{
			var b: any = belichting;
			return b.isCameraLicht;
		}

		GeefBelichtingSlagschaduw(belichting: WebGLBelichting): any
		{
			var b: any = belichting;
			return b.toonSlagschaduw;
		}

		GeefBelichtingSlagschaduwHint(belichting: WebGLBelichting): any {
			var b: any = belichting;
			return b.slagschaduwHint;
		}

		ZetBelichtingLichtval(belichting: WebGLBelichting, lichtval: any) {
			var b: any = belichting;
			b.lichtval = lichtval;
		}

		ZetBelichtingLichtbronSterkte(belichting: WebGLBelichting, sterkte: any) {
			var b: any = belichting;
			b.lichtbronSterkte = sterkte;
		}

		ZetBelichtingIsCameraLicht(belichting: WebGLBelichting, cameralicht: any) {
			var b: any = belichting;
			b.isCameraLicht = cameralicht;
		}

		ZetBelichtingLichtSchittering(belichting: WebGLBelichting, schittering: any) {
			var b: any = belichting;
			b.lichtSchittering = schittering;
		}

		ZetBelichtingOmgevinglichtSterkte(belichting: WebGLBelichting, lichtsterkte: any) {
			var b: any = belichting;
			b.omgevinglichtSterkte = lichtsterkte;
		}

		ZetBelichtingSlagschaduwVerzadiging(belichting: WebGLBelichting, verzadiging: any) {
			var b: any = belichting;
			b.slagschaduwVerzadiging = verzadiging;
		}

		ZetBelichtingSlagschaduwHint(belichting: WebGLBelichting, hint: any) {
			var b: any = belichting;
			b.slagschaduwHint = hint;
		}

		ZetBelichtingSlagschaduw(belichting: WebGLBelichting, slagschaduw: any) {
			var b: any = belichting;
			b.toonSlagschaduw = slagschaduw;
		}

		ZetBelichting(viewer: WebGLViewer, belichting: WebGLBelichting) {
			var v = this.GeefViewerIntern(viewer);
			v.belichting = belichting;
		}

		GeefBelichting(viewer: WebGLViewer): WebGLBelichting {
			var v = this.GeefViewerIntern(viewer);
			return v.belichting;
		}

		KopieerBelichting(belichting: WebGLBelichting): WebGLBelichting {
			var nieuwe : any = this.MaakBelichting();
			var b: any = belichting;
			nieuwe.lichtval = b.lichtval;
			nieuwe.lichtbronSterkte = b.lichtbronSterkte;
			nieuwe.isCameraLicht = b.isCameraLicht;
			nieuwe.lichtSchittering = b.lichtSchittering;
			nieuwe.omgevinglichtSterkte = b.omgevinglichtSterkte;
			nieuwe.slagschaduwVerzadiging = b.slagschaduwVerzadiging; 
			nieuwe.toonSlagschaduw = b.toonSlagschaduw;

			return nieuwe;

		}

		GeefZoomSchaal(viewer: WebGLViewer): any
		{
			var v = this.GeefViewerIntern(viewer);
			return v.zoomSchaal;
		}
		GeefCentrum(viewer: WebGLViewer): any
		{
			var v = this.GeefViewerIntern(viewer);
			return v.centrum;
		}

		GeefKijkhoeken(viewer: WebGLViewer): any
		{
			var v = this.GeefViewerIntern(viewer);
			return v.kijkhoeken;
		}

		GeefPositie(viewer: WebGLViewer): any
		{
			var v = this.GeefViewerIntern(viewer);
			return v.positie;
		}

		IsNavigeerbaar(viewer: WebGLViewer): any
		{
			var v = this.GeefViewerIntern(viewer);
			return v.navigeerbaar;
		}

		IsTerreinCamera(viewer: WebGLViewer): boolean
		{
			var v = this.GeefViewerIntern(viewer);
			return v.camera;
		}


		ZetZoomSchaal(viewer: WebGLViewer, zoomschaal: any)
		{
			this.Log(viewer, 'ZetZoomSchaal'  + zoomschaal);
			var v = this.GeefViewerIntern(viewer);
			v.zoomSchaal = zoomschaal;
		}

		ZetCentrum(viewer: WebGLViewer, centrum: any)
		{
			this.Log(viewer, 'ZetCentrum' + centrum);
			var v = this.GeefViewerIntern(viewer);
			v.centrum = centrum;
		}

		ZetKijkhoeken(viewer: WebGLViewer, kijkhoeken: any)
		{
			this.Log(viewer, 'ZetKijkhoeken' + kijkhoeken);
			var v = this.GeefViewerIntern(viewer);
			v.kijkhoeken = kijkhoeken;
		}

		ZetPositie(viewer: WebGLViewer, positie: any)
		{
			this.Log(viewer, 'ZetPositie' + positie);
			var v = this.GeefViewerIntern(viewer);
			v.positie = positie;
		}

		ZetNavigeerbaar(viewer: WebGLViewer, navigeerbaar: any)
		{
			var v = this.GeefViewerIntern(viewer);
			v.navigeerbaar = navigeerbaar;
		}

		ZetTerreinCamera(viewer: WebGLViewer, aan: any)
		{
			this.Log(viewer, 'ZetTerreinCamera' + aan);
			var v = this.GeefViewerIntern(viewer);
			v.camera = aan;
		}


		ZetAchtergrondkleur(viewer: WebGLViewer, kleur: any)
		{
			var v = this.GeefViewerIntern(viewer);
			v.achtergrondKleur = kleur;
		}

		ZetOrthografischeProjectie(viewer: WebGLViewer, aan: any)
		{
			this.Log(viewer, 'ZetOrthografischeProjectie ' + aan);
			var v = this.GeefViewerIntern(viewer);
			v.orthografischeProjectie = aan;
		}

		ZetLogEnable(viewer: WebGLViewer, aan: any)
		{
			var v = this.GeefViewerIntern(viewer);
			v.logEnabled = true;
		}


		ZetAnimatieCallback(viewer: WebGLViewer, callback: any)
		{
			var v = this.GeefViewerIntern(viewer);
			v.animatieCallback = callback;
		}

		GeefKijkrichting(viewer: WebGLViewer): any
		{
			var v = this.GeefViewerIntern(viewer);
			return v.kijkrichting;
		}

		ZetKijkrichting(viewer: WebGLViewer, richting: any)
		{
			this.Log(viewer, 'ZetKijkrichting' + richting);
			var v = this.GeefViewerIntern(viewer);
			v.kijkrichting = richting;
		}

		ZetTekenHints(viewer: WebGLViewer, aan: boolean) {
			var v = this.GeefViewerIntern(viewer);
			return v.zetTekenHints(aan);
		}

		ZetNabewerkingenDefaults(viewer: WebGLViewer, idleperiod: any) {
			var v = this.GeefViewerIntern(viewer);
			v.nabewerkingen.idlePeriode = idleperiod;
			v.nabewerkingen.toonSlagschaduwVerfijnd = true;
			v.nabewerkingen.fxaa.toepassen = true;
			v.nabewerkingen.ssao.toepassen = true;
			v.nabewerkingen.ssao.straal =  50;
			v.nabewerkingen.ssao.macht = 3;
			v.nabewerkingen.vignette.toepassen = true;
			v.nabewerkingen.vignette.intensiteit = 0.1;
		}

		ZetBerichtCallback(viewer: WebGLViewer, callback: any) {
			var v = this.GeefViewerIntern(viewer);
			v.berichtCallback = callback;
		}

		ZetSelectieVeranderd(viewer: WebGLViewer, callback: any, callback2: any) {
			var v = this.GeefViewerIntern(viewer);
			v.selectieVeranderendCallback = callback;
			v.selectieVeranderendCallback = callback;
			v.selectieVeranderdCallback = callback2;
		}

		ZetZichtStandaard(viewer: WebGLViewer) {
			this.Log(viewer, 'ZetZichtStandaard');
			var v = this.GeefViewerIntern(viewer);
			v.zetZichtStandaard();
		}

		ZetZoomGeheel(viewer: WebGLViewer) {
			this.Log(viewer, 'ZetZoomGeheel');
			var v = this.GeefViewerIntern(viewer);
			v.zetZoomGeheel();
		}

		ToonModel(viewer: WebGLViewer) {
			this.Log(viewer, 'ToonModel');
			var v = this.GeefViewerIntern(viewer);
			v.toonModel();
		}

		MaakBelichting(): WebGLBelichting {
			return null;
		}

		ZetSelectieToegestaan(viewer: WebGLViewer, aan: boolean)
		{
			var v = this.GeefViewerIntern(viewer);
			v.selectieToestaan = aan;
		}

		ZetSelectieKleur(viewer: WebGLViewer, kleur: number[])
		{
			var v = this.GeefViewerIntern(viewer);
			v.selectieKleur = kleur;
		}
	}

	// export class WebGLViewerService71 extends WebGLViewerService71Common implements ServicesWebGLViewerService.IWebGLViewerService
	// {
	// 	MaakViewer(canvas: any, opties: any): WebGLViewer {
	// 		var viewer = HelpersBackwards.ModuleViewer3D.get(canvas, opties);
	// 		return viewer;
	// 	}

	// 	dispose(canvas: any) {
	// 		this.Log(null, 'dispose');
	// 		HelpersBackwards.ModuleViewer3D.dispose(canvas);
	// 	}

	// 	MaakBelichting(): WebGLBelichting {
	// 		var belichting = new HelpersBackwards.ModuleViewer3D.Belichting();
	// 		return belichting;
	// 	}
	// }

	@Injectable({
		providedIn: 'root',
	})
	export class WebGLViewerServiceTypescript extends WebGLViewerService71Common implements ServicesWebGLViewerService.IWebGLViewerService{
		constructor(config: AppConfigService) 
		{
			super(config)

		}
		MaakViewer(canvas: any, opties: any): WebGLViewer {
			var viewer = ViewerTypescript.ModuleViewer3D.viewer3DManager.get(canvas, opties);
			return viewer;
		}

		dispose(canvas: any) {
			this.Log(null, 'dispose');
			ViewerTypescript.ModuleViewer3D.viewer3DManager.dispose(canvas);
			//HelpersBackwards.ModuleViewer3D.dispose(canvas);
		}

		MaakBelichting(): WebGLBelichting {
			var belichting = new ViewerTypescript.ModuleViewer3D.Belichting();
			return belichting;
		}
	}
