/**
 * @ref WoonConnectViewModel/User/UserRetrieveModel.cs
 */
	/**
	* Originele namespace: WoonConnectViewModel.User.UserRetrieveCritereaType
	*/
	export enum UserRetrieveCritereaType {
		Any = 0,
		
		UserIdType = 1,
		
		LastName = 2,
		
		FirstName = 3,
		
		Address = 4
	}

