/**
 * @ref WoonConnectViewModel/BuildingComponentQuantityViewModel.cs
 */

 import * as DataContracts from "@datacontracts/BuildingComponentQuantityViewModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.BuildingComponentQuantityViewModel
	 */
	 

	export interface IBuildingComponentQuantityViewModel {
		Id: number;
		DisplayName: string;
		ModelCode: string;
		ToJsonContract(): DataContracts.IBuildingComponentQuantityViewModel;
	}

	export class BuildingComponentQuantityViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get DisplayName(): string {
			return this._DisplayName;
		}
		public set DisplayName(newDisplayName: string) {
			this._DisplayName = newDisplayName;
		}

		public get ModelCode(): string {
			return this._ModelCode;
		}
		public set ModelCode(newModelCode: string) {
			this._ModelCode = newModelCode;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _DisplayName: string = "";
		protected _ModelCode: string = "";

		constructor(data?: DataContracts.IBuildingComponentQuantityViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentQuantityViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._DisplayName = data.DisplayName;
				this._ModelCode = data.ModelCode;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentQuantityViewModel {
			return {
				Id: this.Id,
				DisplayName: this.DisplayName,
				ModelCode: this.ModelCode
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.BuildingComponentQuantityRequestAllModel
	 */
	 

	export interface IBuildingComponentQuantityRequestAllModel {
		Temp: string;
		ToJsonContract(): DataContracts.IBuildingComponentQuantityRequestAllModel;
	}

	export class BuildingComponentQuantityRequestAllModel {
		// property-Properties
		public get Temp(): string {
			return this._Temp;
		}
		public set Temp(newTemp: string) {
			this._Temp = newTemp;
		}

		// field-Properties

		// fields
		protected _Temp: string = "";

		constructor(data?: DataContracts.IBuildingComponentQuantityRequestAllModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentQuantityRequestAllModel): void {
			if(data) {
				// vul properties
				this._Temp = data.Temp;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentQuantityRequestAllModel {
			return {
				Temp: this.Temp
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.BuildingComponentQuantityResultAllModel
	 */
	 

	export interface IBuildingComponentQuantityResultAllModel {
		BuildingComponentQuantities: BuildingComponentQuantityViewModel[];
		ToJsonContract(): DataContracts.IBuildingComponentQuantityResultAllModel;
	}

	export class BuildingComponentQuantityResultAllModel {
		// property-Properties
		public get BuildingComponentQuantities(): BuildingComponentQuantityViewModel[] {
			return this._BuildingComponentQuantities;
		}
		public set BuildingComponentQuantities(newBuildingComponentQuantities: BuildingComponentQuantityViewModel[]) {
			this._BuildingComponentQuantities = newBuildingComponentQuantities;
		}

		// field-Properties

		// fields
		protected _BuildingComponentQuantities: BuildingComponentQuantityViewModel[] = [];

		constructor(data?: DataContracts.IBuildingComponentQuantityResultAllModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentQuantityResultAllModel): void {
			if(data) {
				// vul properties
				this._BuildingComponentQuantities = data.BuildingComponentQuantities ? data.BuildingComponentQuantities.map(buildingComponentQuantityViewModelItem => new BuildingComponentQuantityViewModel(buildingComponentQuantityViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentQuantityResultAllModel {
			return {
				BuildingComponentQuantities: (this.BuildingComponentQuantities ? this.BuildingComponentQuantities.map(buildingComponentQuantityViewModelItem => new BuildingComponentQuantityViewModel(buildingComponentQuantityViewModelItem).ToJsonContract()): [])
			}
		}
	}
