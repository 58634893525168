// [conv] import "(./FabAdminProductProperty).less";
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import {
  ProductPropertyViewModel, ProductPropertiesViewModel, ProductPropertyRelationsViewModel, ProductRequestModel,
  ProductPropertyTypesViewModel, ProductPropertyTypeViewModel
  , ProductPropertyOptionViewModel, ProductPropertyOptionsViewModel, ProductPropertySaveModel, ProductsViewModel, DeleteRequestModel
} from '@models/ProductsViewModel';
import { ProductPropertyFilterRelationsViewModel, RelationViewModel } from '@models/FilterViewModel';
import { ProductsConsumer } from '@consumers/ProductsController';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FiltersConsumer } from '@consumers/FiltersController';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';



interface FilterNode {
  Name: string;
  Id: number;
  FilterId: number;
  expandable: boolean;
  level: number;
  Active: boolean;

  children?: FilterNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  Name: string;
  Id: number;
  FilterId: number;
  FilterParent: number;
  Indeterminate: boolean;
  Active: boolean;
  level: number;
}



@Component({
  selector: 'product-property',
  templateUrl: './FabAdminProductProperty.html',
  styleUrls: ['./FabAdminProductProperty.scss']
})
export class FabAdminProductPropertyComponent {
  PropertyId: number;
  Laden: boolean = true;
  update: boolean = true;
  addOption: boolean = false;
  ProductRequestModel: ProductRequestModel = new ProductRequestModel()
  ProductPropertyViewModel: ProductPropertyViewModel = new ProductPropertyViewModel();
  ProductPropertyTypesViewModel: ProductPropertyTypesViewModel = new ProductPropertyTypesViewModel();
  ProductPropertyType: ProductPropertyTypeViewModel = new ProductPropertyTypeViewModel();
  newProductPropertyOption: ProductPropertyOptionViewModel = new ProductPropertyOptionViewModel();
  ProductPropertyOptionsViewModel: ProductPropertyOptionsViewModel = new ProductPropertyOptionsViewModel();
  ProductPropertyFilterRelationsViewModel: ProductPropertyFilterRelationsViewModel = new ProductPropertyFilterRelationsViewModel();
  RelationViewModel: RelationViewModel = new RelationViewModel();
  ProductPropertySaveModel: ProductPropertySaveModel = new ProductPropertySaveModel();
  Products: ProductsViewModel = new ProductsViewModel();

  //filter list

  DeleteVariantRequest: DeleteRequestModel;
  //tree for filter options. 

  flatNodeMap = new Map<ExampleFlatNode, FilterNode>();
  nestedNodeMap = new Map<FilterNode, ExampleFlatNode>();


  checklistSelection = new SelectionModel<ExampleFlatNode>(true /* multiple */);
  //Selection
  selectedParent: ExampleFlatNode | null = null;
  getLevel = (node: ExampleFlatNode) => node.level;
  isExpandable = (node: ExampleFlatNode) => node.expandable;
  getChildren = (node: FilterNode): FilterNode[] => node.children;
  hasChild = (_: number, _nodeData: ExampleFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: ExampleFlatNode) => _nodeData.Name;


  //make nested tree a flat tree 
  private _transformer = (node: FilterNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      Name: node.Name,
      level: level,
      Id: node.Id,
      FilterId: node.FilterId,
      Active: node.Active
    }
  }


  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private route: ActivatedRoute, private prod: ProductsConsumer, private filter: FiltersConsumer, private router: Router) {
    //gets the product propertyid


  }
  ngOnInit() {
    this.PropertyId = parseInt((<any>this.route.params).value.propertyid);
    this.Refresh();
  }


  public changeToggle(propertyId) {

    console.log(propertyId);
  }

  public Refresh(): void {
    this.ProductRequestModel.Id = this.PropertyId;
    //this.prod.GetProductProperty_Observable(this.ProductRequestModel).subscribe((data) => {
    //    console.log(data);
    //    this.ProductPropertyViewModel = new ProductPropertyViewModel(data);
    //})

    this.prod.GetAllProductPropertyTypes_Observable(this.ProductPropertyTypesViewModel).subscribe((data) => {
      this.ProductPropertyTypesViewModel = new ProductPropertyTypesViewModel(data);
    })


    //this.prod.GetAllProductPropertyOption_Observable(this.ProductRequestModel).subscribe((data) => {
    //    console.log(data);
    //    this.ProductPropertyOptionsViewModel = new ProductPropertyOptionsViewModel(data);
    //})



    //get all the filteroptions product proeprty options relation. 
    this.filter.GetProductPropertyFilterRelation_Observable(this.ProductRequestModel).subscribe((data) => {
      let tree_data: any = [];

      this.ProductPropertyFilterRelationsViewModel = new ProductPropertyFilterRelationsViewModel(data);
      this.ProductPropertyFilterRelationsViewModel.ProductPropertyFilterRelation.forEach(elem => {

        let filters_node: any = {
          Id: elem.Id,
          FilterId: elem.Filter.Id,
          FilterParent: 0,
          Name: elem.Filter.Name,
          Active: elem.Active,

        }
        tree_data.push(filters_node);
      });

      this.dataSource.data = tree_data;

      console.log(this.dataSource.data);
    })
    this.Laden = false;
  }

  public saveOption() {
    console.log(this.newProductPropertyOption);
    this.newProductPropertyOption.ProductPropertyId = this.PropertyId;

    //this.prod.SaveProductPropertyOption_Observable(this.newProductPropertyOption).subscribe((data) => {
    //    console.log(data);
    //    this.Refresh();
    //    this.addOption = !this.addOption;
    //})
  }

  filterChange(checkedValue: boolean, node) {
    console.log(checkedValue, node);
    this.RelationViewModel = new RelationViewModel();

    this.RelationViewModel.Type = "propertyfilter";
    this.RelationViewModel.Id = node.Id;
    this.RelationViewModel.FirstLinkId = this.PropertyId;
    this.RelationViewModel.SecondLinkId = node.FilterId;
    this.RelationViewModel.Active = checkedValue;

    console.log(this.RelationViewModel);

    this.filter.SaveRelation_Observable(this.RelationViewModel).subscribe((data) => {
      console.log(data);
      this.Refresh();
    });
  }

  public deletePropertyOption(id) {
    if (confirm("Weet je zeker dat je dit variant wilt verwijderen?")) {
      this.DeleteVariantRequest = new DeleteRequestModel();
      this.DeleteVariantRequest.Id = id;
      this.DeleteVariantRequest.LinkedId = this.PropertyId;
      alert("todo");
      // this.prod.DeleteProductPropertyOption_Observable(this.DeleteVariantRequest).subscribe((data) => {
      //     console.log(data);
      // })
    } else {
      console.log("verwijderen geannuleeerd");
    }

  }

  Update() {
    console.log("Update Product");
    this.ProductPropertySaveModel.Id = this.ProductPropertyViewModel.Id;
    this.ProductPropertySaveModel.Name = this.ProductPropertyViewModel.Name;
    this.ProductPropertySaveModel.ProductPropertyTypeId = this.ProductPropertyViewModel.ProductPropertyType.Id;

    //this.prod.SaveProductProperty_Observable(this.ProductPropertySaveModel).subscribe((data) => {
    //    console.log(data);
    //})

    this.update = !this.update;
  }




}
