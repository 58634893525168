/**
 * @ref WoonConnectViewModel/Scene/CreateSceneModel.cs
 */

 import * as DataContracts from "@datacontracts/CreateSceneModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Scene.CreateSceneRequestModel
	 */
	 

	export interface ICreateSceneRequestModel {
		sceneType: string;
		projectid: number;
		webgl: boolean;
		ToJsonContract(): DataContracts.ICreateSceneRequestModel;
	}

	export class CreateSceneRequestModel {
		// property-Properties
		public get sceneType(): string {
			return this._sceneType;
		}
		public set sceneType(newsceneType: string) {
			this._sceneType = newsceneType;
		}

		public get projectid(): number {
			return this._projectid;
		}
		public set projectid(newprojectid: number) {
			this._projectid = newprojectid;
		}

		public get webgl(): boolean {
			return this._webgl;
		}
		public set webgl(newwebgl: boolean) {
			this._webgl = newwebgl;
		}

		// field-Properties

		// fields
		protected _sceneType: string = "";
		protected _projectid: number = 0;
		protected _webgl: boolean = false;

		constructor(data?: DataContracts.ICreateSceneRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICreateSceneRequestModel): void {
			if(data) {
				// vul properties
				this._sceneType = data.sceneType;
				this._projectid = data.projectid;
				this._webgl = data.webgl;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ICreateSceneRequestModel {
			return {
				sceneType: this.sceneType,
				projectid: this.projectid,
				webgl: this.webgl
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Scene.CreateSceneResponseModel
	 */
	 

	export interface ICreateSceneResponseModel {
		SessionKey: string;
	}

	export class CreateSceneResponseModel {
		// property-Properties
		// field-Properties
		public get SessionKey(): string {
			return this._SessionKey;
		}
		public set SessionKey(newSessionKey: string) {
			this._SessionKey = newSessionKey;
		}

		// fields
		protected _SessionKey: string = "";

		constructor(data?: DataContracts.ICreateSceneResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICreateSceneResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._SessionKey = data.SessionKey;
			}
		}
	}
