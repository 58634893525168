/**
 * @ref WoonConnectViewModel/Project/CleanUserDataModel.cs
 */

 import * as DataContracts from "@datacontracts/CleanUserDataModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Project.CleanUserDataRequestModel
	 */
	 

	export interface ICleanUserDataRequestModel {
		MetNaw: boolean;
		MetBestaandeOpties: boolean;
		MetNieuweOpties: boolean;
		alleenGebruikers: boolean;
		MetRol: string;
		MetNaam: string;
		MetSocialeEnquete: boolean;
		MetWarmeOpname: boolean;
		MetEPAOpname: boolean;
		MetRasterNotities: boolean;
		ToJsonContract(): DataContracts.ICleanUserDataRequestModel;
	}

	export class CleanUserDataRequestModel {
		// property-Properties
		public get MetNaw(): boolean {
			return this._MetNaw;
		}
		public set MetNaw(newMetNaw: boolean) {
			this._MetNaw = newMetNaw;
		}

		public get MetBestaandeOpties(): boolean {
			return this._MetBestaandeOpties;
		}
		public set MetBestaandeOpties(newMetBestaandeOpties: boolean) {
			this._MetBestaandeOpties = newMetBestaandeOpties;
		}

		public get MetNieuweOpties(): boolean {
			return this._MetNieuweOpties;
		}
		public set MetNieuweOpties(newMetNieuweOpties: boolean) {
			this._MetNieuweOpties = newMetNieuweOpties;
		}

		public get alleenGebruikers(): boolean {
			return this._alleenGebruikers;
		}
		public set alleenGebruikers(newalleenGebruikers: boolean) {
			this._alleenGebruikers = newalleenGebruikers;
		}

		public get MetRol(): string {
			return this._MetRol;
		}
		public set MetRol(newMetRol: string) {
			this._MetRol = newMetRol;
		}

		public get MetNaam(): string {
			return this._MetNaam;
		}
		public set MetNaam(newMetNaam: string) {
			this._MetNaam = newMetNaam;
		}

		public get MetSocialeEnquete(): boolean {
			return this._MetSocialeEnquete;
		}
		public set MetSocialeEnquete(newMetSocialeEnquete: boolean) {
			this._MetSocialeEnquete = newMetSocialeEnquete;
		}

		public get MetWarmeOpname(): boolean {
			return this._MetWarmeOpname;
		}
		public set MetWarmeOpname(newMetWarmeOpname: boolean) {
			this._MetWarmeOpname = newMetWarmeOpname;
		}

		public get MetEPAOpname(): boolean {
			return this._MetEPAOpname;
		}
		public set MetEPAOpname(newMetEPAOpname: boolean) {
			this._MetEPAOpname = newMetEPAOpname;
		}

		public get MetRasterNotities(): boolean {
			return this._MetRasterNotities;
		}
		public set MetRasterNotities(newMetRasterNotities: boolean) {
			this._MetRasterNotities = newMetRasterNotities;
		}

		// field-Properties

		// fields
		protected _MetNaw: boolean = false;
		protected _MetBestaandeOpties: boolean = false;
		protected _MetNieuweOpties: boolean = false;
		protected _alleenGebruikers: boolean = false;
		protected _MetRol: string = "";
		protected _MetNaam: string = "";
		protected _MetSocialeEnquete: boolean = false;
		protected _MetWarmeOpname: boolean = false;
		protected _MetEPAOpname: boolean = false;
		protected _MetRasterNotities: boolean = false;

		constructor(data?: DataContracts.ICleanUserDataRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICleanUserDataRequestModel): void {
			if(data) {
				// vul properties
				this._MetNaw = data.MetNaw;
				this._MetBestaandeOpties = data.MetBestaandeOpties;
				this._MetNieuweOpties = data.MetNieuweOpties;
				this._alleenGebruikers = data.alleenGebruikers;
				this._MetRol = data.MetRol;
				this._MetNaam = data.MetNaam;
				this._MetSocialeEnquete = data.MetSocialeEnquete;
				this._MetWarmeOpname = data.MetWarmeOpname;
				this._MetEPAOpname = data.MetEPAOpname;
				this._MetRasterNotities = data.MetRasterNotities;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ICleanUserDataRequestModel {
			return {
				MetNaw: this.MetNaw,
				MetBestaandeOpties: this.MetBestaandeOpties,
				MetNieuweOpties: this.MetNieuweOpties,
				alleenGebruikers: this.alleenGebruikers,
				MetRol: this.MetRol,
				MetNaam: this.MetNaam,
				MetSocialeEnquete: this.MetSocialeEnquete,
				MetWarmeOpname: this.MetWarmeOpname,
				MetEPAOpname: this.MetEPAOpname,
				MetRasterNotities: this.MetRasterNotities
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Project.CleanUserDataResponseModel
	 */
	 

	export interface ICleanUserDataResponseModel {
		AantalWijzigingen: number;
		TekstUit: string;
	}

	export class CleanUserDataResponseModel {
		// property-Properties
		public get AantalWijzigingen(): number {
			return this._AantalWijzigingen;
		}
		public set AantalWijzigingen(newAantalWijzigingen: number) {
			this._AantalWijzigingen = newAantalWijzigingen;
		}

		// field-Properties
		public get TekstUit(): string {
			return this._TekstUit;
		}
		public set TekstUit(newTekstUit: string) {
			this._TekstUit = newTekstUit;
		}

		// fields
		protected _AantalWijzigingen: number = 0;
		protected _TekstUit: string = "";

		constructor(data?: DataContracts.ICleanUserDataResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICleanUserDataResponseModel): void {
			if(data) {
				// vul properties
				this._AantalWijzigingen = data.AantalWijzigingen;
				// vul fields
				this._TekstUit = data.TekstUit;
			}
		}
	}
