<wct-survey-question-group groupname="">
  <wct-survey-question
  question = "Datum afmelding"
  >
    <wct-survey-string [prop]='generic.controls.SurveyDateRegistration' placeholder="Datum afmelding" type="date">
    </wct-survey-string>
  </wct-survey-question>

  <wct-survey-question
  question = "Datum opname"
  >
    <wct-survey-string [prop]='generic.controls.SurveyDateVisit' placeholder="Datum opname" type="date">
    </wct-survey-string>
  </wct-survey-question>

  <wct-survey-question
  question = "ActivitySourceBuildingData"
  >
    <wct-survey-string [prop]='generic.controls.ActivitySourceBuildingData' placeholder="ActivitySourceBuildingData">
    </wct-survey-string>
  </wct-survey-question>

  <wct-survey-question
  question = "ActivityLevelSurvey"
  >
    <wct-survey-string [prop]='generic.controls.ActivityLevelSurvey' placeholder="ActivityLevelSurvey">
    </wct-survey-string>
  </wct-survey-question>

  <wct-survey-question
  question = "ActivityReasonSubmit"
  >
    <wct-survey-string [prop]='generic.controls.ActivityReasonSubmit' placeholder="ActivityReasonSubmit">
    </wct-survey-string>
  </wct-survey-question>
</wct-survey-question-group>


