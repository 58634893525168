import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmarttwinUiExampleComponent } from './components/smarttwin-ui-example/smarttwin-ui-example.component';
import { CoreUiModule } from '../../coreui/coreui.module';
import { WctStuiLayoutTwoPanelsComponent } from './components/layouts/wct-stui-layout-two-panels/wct-stui-layout-two-panels.component';
import { WctStuiTreeMenuComponent } from './components/wct-stui-tree-menu/wct-stui-tree-menu.component';
import { WctErrorDialog } from './components/wct-error-dialog/WctErrorDialog';
import { WctStuiTreeNodeComponent } from './components/wct-stui-tree-node/wct-stui-tree-node.component';
import { WctStuiLoaderComponent } from './components/wct-stui-loader/wct-stui-loader.component';
import { WctStuiMainHeaderComponent } from './components/wct-stui-main-header/wct-stui-main-header.component';
import { WctStuiButtonComponent } from './components/wct-stui-button/wct-stui-button.component';



@NgModule({
  declarations: [
    SmarttwinUiExampleComponent,
    WctStuiLayoutTwoPanelsComponent,
    WctStuiTreeMenuComponent,
    WctErrorDialog,
    WctStuiTreeNodeComponent,
    WctStuiLoaderComponent,
    WctStuiMainHeaderComponent,
    WctStuiButtonComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
  ],
  exports: [
    SmarttwinUiExampleComponent,
    WctStuiLayoutTwoPanelsComponent,
    WctStuiTreeMenuComponent,
    WctErrorDialog,
    WctStuiLoaderComponent,
    WctStuiMainHeaderComponent,
    WctStuiButtonComponent
  ]
})
export class SmarttwinUiModule { }
