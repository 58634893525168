import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { PrijslijstConsumer } from '@consumers/PrijslijstController';
import { FabUsersViewModel, FabUserViewModel } from '@models/PrijslijstViewModel';

@Component({
  selector: 'gebruiker',
  templateUrl: './gebruiker.component.html',
  styleUrls: ['./gebruiker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class GebruikerComponent {

  public UsersViewModel: FabUsersViewModel;
  public UserViewModel: FabUserViewModel;
  public Loading: boolean = true;
  public LoadingUser: boolean = true;
  public EditUser: boolean = false;

  public Error: string = "";

  public UserName: string = "";
  public Password: string = "";
  public Rol: string = "";
  private WijzigUserName: string = "";

  constructor(
    private fab: FabrikantenConsumer,
    private route: ActivatedRoute,
    private prijslijst: PrijslijstConsumer,
    private router: Router
  ) {
    this.GetLoggedInUser();
    this.LoadUsers();
  }

  public Nieuw(): void {
    this.EditUser = true;

    this.Error = "";
    this.UserName = "";
    this.Password = "";
    this.Rol = "Gebruiker";
    this.WijzigUserName = "";
  }

  public Opslaan(): void {
    if (this.UserName != this.WijzigUserName) {
      for (var i = 0; i < this.UsersViewModel.Users.length; i++) {
        if (this.UsersViewModel.Users[i].UserName.trim().toLowerCase() == this.UserName.trim().toLowerCase()) {
          this.Error = "Gebruikersnaam al ingebruik";
          return;
        }
      }
    }

    if (this.UserName.length < 6) {
      this.Error = "Username te kort (minimaal 6 tekens)";
      return;
    }

    if (this.Password.length < 6) {
      this.Error = "Password te kort (minimaal 6 tekens)";
      return;
    }

    this.Loading = true;
    this.EditUser = false;

    var model = new FabUserViewModel();

    model.UserName = this.UserName;
    model.Password = this.Password;
    model.Rol = this.Rol;

    this.prijslijst.SaveUser_Observable(model).subscribe(() => {
      this.LoadUsers();
    });
  }

  public Verwijder(user: FabUserViewModel) {
    if (confirm("Weet je zeker dat je gebruiker '" + user.UserName + "' wilt verwijderen?")) {
      this.Loading = true;

      var model = new FabUserViewModel();

      model.UserName = user.UserName;

      this.prijslijst.Delete_Observable(model).subscribe(() => {
        this.LoadUsers();
      });
    }
  }

  public Wijzigen(user: FabUserViewModel) {
    this.EditUser = true;
    this.Error = "";

    this.WijzigUserName = user.UserName;
    this.UserName = user.UserName;
    this.Password = user.Password;
    this.Rol = user.Rol;
  }

  private LoadUsers(): void {
    this.Loading = true;

    this.prijslijst.GetUsers_Observable().subscribe((data) => {
      this.UsersViewModel = new FabUsersViewModel(data);

      this.Loading = false;
    });
  }

  private GetLoggedInUser(): void {
    this.LoadingUser = true;

    this.prijslijst.GetLoggedInUser_Observable().subscribe((data) => {
      if (data == null) {
        this.UserViewModel = null;
      }
      else {
        this.UserViewModel = new FabUserViewModel(data);
      }

      this.LoadingUser = false;
    });
  }
}
