/**
 * @ref WoonConnectViewModel/Project/ProjectSettingsModel.cs
 */

 import * as DataContracts from "@datacontracts/ProjectSettingsModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Project.ProjectSettingsRequestModel
	 */
	 

	export interface IProjectSettingsRequestModel {
		ProjectId: number;
		ToJsonContract(): DataContracts.IProjectSettingsRequestModel;
	}

	export class ProjectSettingsRequestModel {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		// field-Properties

		// fields
		protected _ProjectId: number = 0;

		constructor(data?: DataContracts.IProjectSettingsRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProjectSettingsRequestModel): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProjectSettingsRequestModel {
			return {
				ProjectId: this.ProjectId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Project.ProjectSettingsResponseModel
	 */
	 

	export interface IProjectSettingsResponseModel {
		ProjectSettings: ProjectSettingsAngular[];
	}

	export class ProjectSettingsResponseModel {
		// property-Properties
		public get ProjectSettings(): ProjectSettingsAngular[] {
			return this._ProjectSettings;
		}
		public set ProjectSettings(newProjectSettings: ProjectSettingsAngular[]) {
			this._ProjectSettings = newProjectSettings;
		}

		// field-Properties

		// fields
		protected _ProjectSettings: ProjectSettingsAngular[] = [];

		constructor(data?: DataContracts.IProjectSettingsResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProjectSettingsResponseModel): void {
			if(data) {
				// vul properties
				this._ProjectSettings = data.ProjectSettings ? data.ProjectSettings.map(projectSettingsAngularItem => new ProjectSettingsAngular(projectSettingsAngularItem)): [];
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Project.ProjectSettingsAngular
	 */
	 

	export interface IProjectSettingsAngular {
		Naam: string;
		Categorie: string;
		Type: string;
		Waarde: string;
		Aangepast: boolean;
		ToJsonContract(): DataContracts.IProjectSettingsAngular;
	}

	export class ProjectSettingsAngular {
		// property-Properties
		public get Naam(): string {
			return this._Naam;
		}
		public set Naam(newNaam: string) {
			this._Naam = newNaam;
		}

		public get Categorie(): string {
			return this._Categorie;
		}
		public set Categorie(newCategorie: string) {
			this._Categorie = newCategorie;
		}

		public get Type(): string {
			return this._Type;
		}
		public set Type(newType: string) {
			this._Type = newType;
		}

		public get Waarde(): string {
			return this._Waarde;
		}
		public set Waarde(newWaarde: string) {
			this._Waarde = newWaarde;
		}

		public get Aangepast(): boolean {
			return this._Aangepast;
		}
		public set Aangepast(newAangepast: boolean) {
			this._Aangepast = newAangepast;
		}

		// field-Properties

		// fields
		protected _Naam: string = "";
		protected _Categorie: string = "";
		protected _Type: string = "";
		protected _Waarde: string = "";
		protected _Aangepast: boolean = false;

		constructor(data?: DataContracts.IProjectSettingsAngular) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IProjectSettingsAngular): void {
			if(data) {
				// vul properties
				this._Naam = data.Naam;
				this._Categorie = data.Categorie;
				this._Type = data.Type;
				this._Waarde = data.Waarde;
				this._Aangepast = data.Aangepast;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IProjectSettingsAngular {
			return {
				Naam: this.Naam,
				Categorie: this.Categorie,
				Type: this.Type,
				Waarde: this.Waarde,
				Aangepast: this.Aangepast
			}
		}
	}
