// [conv] import "(../components/model/WctModel).less";
import * as ng from 'angular';
import * as HelpersBackwards from '../Helpers/Backwards';
import * as ServicesWindowService from './WindowService';
import * as ServicesCapabilitiesService from './CapabilitiesService';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { HostListener } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';

interface IModelPopupBindings {
	oorzaak: string;
}

export interface IModelPopupController extends IModelPopupBindings {
	windowWidth: number;
	downloadChrome();
	downloadFirefox();
}

// tijdelijk doorgeefluik om componenten samen te laten werken met dialogen
// export class WctModelPopupStartController {
// 	constructor($scope: any, oorzaak: any) {
// 		'ngInject';
// 		$scope.oorzaak = oorzaak;
// 	}
// }
@Component({
	selector: 'wct-model-popup',
	templateUrl: './WctModelPopup.html'
})
export class ModelPopupComponent {
	@Input() oorzaak: string;
	windowWidth: number = 0;
	WebGLError: boolean = false;

	constructor(
		private capabilitiesservice: ServicesCapabilitiesService.CapabilitiesService,
		public dialogRef: MatDialogRef<any>
	) {
		this.windowWidth = document.documentElement.clientWidth;

		// if (this.oorzaak !== "webGlError" && this.windowservice !== undefined) {
		// 	this.windowservice.WindowGrootteAangepastEvent.RegisterHandler($scope, () => {
		// 		this.windowWidth = this.windowservice.GeefWindowWidth();
		// 	});
		// }
	}

	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.windowWidth = window.innerWidth;
	}

	public  geefSupportEmailadres() {
		let emailadres = HelpersBackwards.ConstSoftware.SupportEmailAdres;

		return emailadres;
	}

	public geefSupportEmailadresLinkToEmailProgram(melding: string) {
		let emailadres = HelpersBackwards.ConstSoftware.SupportEmailAdres;
		let project = "WoonConnect";

		if (HelpersBackwards.ConstSoftware.TekstBijWoonconnectLogo !== null && HelpersBackwards.ConstSoftware.TekstBijWoonconnectLogo !== "") {
			project = HelpersBackwards.ConstSoftware.TekstBijWoonconnectLogo;
		}

		return "mailto: " + emailadres + "?Subject=Vraag%20over%20" + project;
	}

	downloadChrome() {
		if (this.windowWidth < 768) {
			if (this.capabilitiesservice.IsiOS) {
				return "https://itunes.apple.com/us/app/apple-store/id535886823?pt=9008&ct=help-center-mg&mt=8";
			} else {
				// er van uitgegaan dat het dan een Android tablet is:
				return "https://play.google.com/store/apps/details?id=com.android.chrome&referrer=utm_source%3Dhelp-center%26utm_medium%3Dhc-answer%26utm_campaign%3Dhelp-center-mg";
			}
		} else {
			return "https://www.google.com/chrome/browser/desktop/index.html";
		}
	}

	downloadFirefox() {
		if (this.windowWidth < 768) {
			if (this.capabilitiesservice.IsiOS) {
				return "https://itunes.apple.com/nl/app/apple-store/id989804926?pt=373246&mt=8&ct=mozorg-ios_page-appstore-button";
			} else {
				// er van uitgegaan dat het dan een Android tablet is:
				return "https://play.google.com/store/apps/details?id=org.mozilla.firefox&referrer=utm_source%3Dmozilla%26utm_medium%3DReferral%26utm_campaign%3Dmozilla-org";
			}
		} else {
			return "https://www.mozilla.org/nl/firefox/desktop/";
		}
	}

	public clickAnnuleren() {
		this.dialogRef.close();
		//this.$mdDialog.hide();
	}
}

