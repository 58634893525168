import * as _ from 'lodash';
import * as ViewModelsDocument from '.././Models/Document';
import * as ViewModelsDocumentMap from '.././Models/DocumentMap';
import * as EnumsIDocumentRepository from '@enums/IDocumentRepository';




	export enum RechtenEnum {
		Geen,
		AlleenLezen,
		LezenSchrijven
	}

	export class DocumentMap {
		Id: number;
		DocumentMapId: any;
		ProjectId: number;
		AdresId: any;
		ReceptId: any;
		MapType: EnumsIDocumentRepository.MapType;

		Naam: string;
		Opengeklapt: boolean;

		ParentMap: DocumentMap;

		Mappen: Array<ViewModelsDocumentMap.DocumentMap> = [];
		AantalMappen: number;
		Documenten: Array<ViewModelsDocument.Document> = [];

		IsRoot: boolean
		MapNiveau: number
		KanKlappen: boolean;

		MapPad: string;

		RechtenMap: RechtenEnum;
		RechtenDocument: RechtenEnum;

		AlleenLezenBestanden: boolean;
		AlleenLezenMappen: boolean;

		NewName: string;

		constructor(private Ouder: DocumentMap) {
			if (Ouder == null) {
				this.MapNiveau = 0;
			}
			else {
				this.MapNiveau = Ouder.MapNiveau + 1;
			}
		}

		HeeftSubmappen(): boolean {
			return this.Mappen.length > 0;
		}

		VulModel(data) {
			this.RechtenMap = (data.RechtenMap);
			var alleenLezen: boolean = ((this.RechtenMap == RechtenEnum.AlleenLezen) || (this.RechtenMap == RechtenEnum.Geen))
			this.AlleenLezenMappen = (alleenLezen);

			this.RechtenDocument = (data.RechtenDocument);
			var alleenLezen: boolean = ((this.RechtenDocument == RechtenEnum.AlleenLezen) || (this.RechtenDocument == RechtenEnum.Geen))
			this.AlleenLezenBestanden = (alleenLezen);
			this.MapType = data.DocumentType;

			this.Mappen = _.map(data.Mappen, (item) => {
				var newItem = new DocumentMap(this);
				newItem.VulModel(item);
				return newItem;
			});
			this.AantalMappen = (this.Mappen.length);
			this.Documenten = (_.map(data.Documenten, (item) => {
				var newItem = new ViewModelsDocument.Document();
				newItem.VulModel(item);
				newItem.Map = (this);
				return newItem;
			}));
			this.Id = (data.Id);
			this.Opengeklapt = (false);
			this.Naam = (data.Naam);
			this.ProjectId = (data.ProjectId);
			this.IsRoot = (data.isRoot);		
			//if (this.IsRoot()) { this.Naam("."); }
			this.NewName = ("");

			this.DocumentMapId = (data.DocumentMapId);

			this.KanKlappen = (data.kanKlappen);
			this.MapPad = (".");
			this.ParentMap = (null);

			this.AdresId = (data.AdresId);
			this.ReceptId = (data.ReceptId);
		}
	}

