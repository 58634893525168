<div *ngIf="filterViewModel.FilterType.Name == 'Dropdown'">
   <div *ngIf="filterViewModel.SelectedOption == undefined">
      <div *ngIf="((filterViewModel.DisplayTypeEnum == 1) && (filterViewModel.Options.length > 1)) || ((filterViewModel.DisplayTypeEnum == 0) && (filterViewModel.Options.length > 1) && (filterViewModel.Options.length <= 3))" class="fab-filter__radio-group">
         <div class="fab-filter__option-name-and-icon fab-filter__option-name-and-icon--required">
            <p>{{ filterViewModel.Name }} <sup *ngIf="filterViewModel.Mandatory && (filterViewModel.Options.length > 1)">*</sup> <sup *ngIf="!IsFilterFirstLevel" [hidden]="filterViewModel.HasEffect" class="fab-filter__option-icon-number">1</sup></p>          
         </div>
         <mat-radio-group [(ngModel)]="filterViewModel.SelectedOptionId" (change)="OptionChanged()">
            <mat-radio-button *ngFor="let option of filterViewModel.Options" [value]="option.Id">
               <span class="wrap-mat-radio-label">
                  {{RemoveEmptyFirstCharacter(option.Name)}}
                  <!-- <div *ngIf="filterViewModel.Options[0].Id == option.Id" class="fab-filter__icon-standard">
                     <i class="fal fa-circle"></i>
                     <p>s</p>
                  </div> -->
               </span>
            </mat-radio-button>
         </mat-radio-group>
      </div>
      <div *ngIf="((filterViewModel.DisplayTypeEnum == 2) && (filterViewModel.Options.length > 1)) || ((filterViewModel.DisplayTypeEnum == 0) && (filterViewModel.Options.length > 3))">
         <div class="fab-filter__option-name-and-icon fab-filter__option-name-and-icon--required">
            <p>{{ filterViewModel.Name }} <sup *ngIf="filterViewModel.Mandatory && (filterViewModel.Options.length > 1)">*</sup> <sup *ngIf="!IsFilterFirstLevel" [hidden]="filterViewModel.HasEffect || HeeftCADAbstract" class="fab-filter__option-icon-number">1</sup></p>      
         </div>
         <mat-form-field  appearance="outline" floatLabel="always">          
            <mat-select matNativeControl [(ngModel)]="filterViewModel.SelectedOptionId" (selectionChange)="OptionChanged()">
            <mat-option *ngFor="let option of filterViewModel.Options" [value]="option.Id" class="mat-option--fab-filter">
                  {{RemoveEmptyFirstCharacter(option.Name)}}
                  <!-- <div *ngIf="filterViewModel.Options[0].Id == option.Id" class="fab-filter__icon-standard">
                     <i class="fal fa-circle"></i>
                     <p>s</p>
                  </div> -->
               </mat-option>
            </mat-select>
         </mat-form-field>
      </div>
      <div *ngIf="filterViewModel.Options.length == 1">
         <div class="fab-filter__option-name-and-icon fab-filter__option-name-and-icon--required">
            <p>{{ filterViewModel.Name }} <sup *ngIf="filterViewModel.Mandatory">*</sup> <sup *ngIf="!IsFilterFirstLevel" [hidden]="filterViewModel.HasEffect || HeeftCADAbstract"  class="fab-filter__option-icon-number">1</sup></p>      
         </div>
         <mat-form-field appearance="outline" floatLabel="always">          
            <mat-select matNativeControl [(ngModel)]="filterViewModel.Options[0].Id" [disabled]="true" (selectionChange)="OptionChanged()">
            <mat-option [value]="filterViewModel.Options[0].Id" class="mat-option--fab-filter">
                  {{RemoveEmptyFirstCharacter(filterViewModel.Options[0].Name)}}
                  <!-- <div *ngIf="filterViewModel.Options[0].Id == option.Id" class="fab-filter__icon-standard">
                     <i class="fal fa-circle"></i>
                     <p>s</p>
                  </div> -->
               </mat-option>
            </mat-select>
         </mat-form-field>
      </div>
   </div>


   <div *ngIf="filterViewModel.SelectedOption != undefined" class="fab-filter__selected-option">
      <p>{{ filterViewModel.Name }} <sup *ngIf="filterViewModel.Mandatory">*</sup> <sup *ngIf="!IsFilterFirstLevel" [hidden]="filterViewModel.HasEffect || HeeftCADAbstract"  class="fab-filter__option-icon-number">1</sup></p>
      <div class="fab-filter__selected-option-name" (click)="Reset()">
         <i class="fal fa-times"></i>
         <p>{{ filterViewModel.SelectedOption.Name }}</p>
      </div>
   </div>
</div>

<div *ngIf="filterViewModel.FilterType.Name == 'Range'" class="fab-filter__input">
   <div class="fab-filter__form-field-and-button-and-label">
      <div class="fab-filter__option-name-and-icon fab-filter__option-name-and-icon--required">
         <p>{{ filterViewModel.Name }} <sup>*</sup> <sup *ngIf="!IsFilterFirstLevel" [hidden]="filterViewModel.HasEffect" class="fab-filter__option-icon-number">1</sup></p>
      </div>
      <div class="fab-filter__form-field-and-button">
         <mat-form-field appearance="outline" floatLabel="always" [ngClass]="{'fab-filter__form-field--error' : RangeExplanationTextIsShown == true}">
            <input matInput required type="number" [(ngModel)]="filterViewModel.RangeValue" (focus)="tooltip.show()" (blur)="EmitValueChanged(filterViewModel.RangeValue); OptionChanged(); tooltip.hide();" [formControl]="rateControl" (keyup.enter)="EmitValueChanged(filterViewModel.RangeValue); OptionChanged(); tooltip.hide();">
            <div class="fab-filter__tooltip"
               matTooltipPosition="below"
               matTooltip='{{GetMatTooltipText()}}'
               #tooltip="matTooltip">
            </div>        
         </mat-form-field>
         <div (click)="OptionChanged()" class="fab-filter__input-button">
            <i class="fal fa-chevron-right"></i>
         </div>
      </div>
   </div>
   <p *ngIf="RangeExplanationTextIsShown" class="fab-filter__explanation-text">Deze maat valt niet binnen het bereik. Neem contact op als deze maat gewenst is.</p>
</div>

<div *ngIf="filterViewModel.FilterType.Name == 'Multiselect'" class="fab-filter__multiselect">
   <label>{{ filterViewModel.Name }} <sup *ngIf="!IsFilterFirstLevel" [hidden]="filterViewModel.HasEffect || HeeftCADAbstract">*</sup></label>
   <div *ngFor="let option of filterViewModel.Options" class="measure">
		<mat-checkbox aria-label="multiselectoption" [(ngModel)]="option.Selected" (change)="OptionChanged()">
			<p>{{option.Name}}</p>
		</mat-checkbox>
	</div>
</div>
