import * as _ from 'lodash';
//import * as HelpersBackwards from '.././Helpers/Backwards';
import { Inject, Injectable, Output, EventEmitter } from '@angular/core';
import { AppConfigService } from '../infrastructure/app.configservice';


@Injectable({
	providedIn: 'root',
})


	// export interface IAfbeeldingControlerenService {
	// 	geefAfbeelding(afbeelding: string): string;
	// 	geefAfbeeldingVanuitSpecifiekeUrl(afbeelding: string, url: string): string;
	// }

	export class AfbeeldingControlerenService {
		urlAfbeelding: string = "";

    constructor(
      private appconfigservice: AppConfigService
    ) {
      'ngInject';
    }

		geefAfbeelding(afbeelding: string): string {
			if (this.AfbeeldingBestaat(afbeelding)) {
				return this.urlAfbeelding;
			} else if (this.AfbeeldingBestaat('startpagina-achtergrond')) {
				return this.urlAfbeelding;
			} else {
				return "/Content/Images/startpagina/startpagina-achtergrond-basis.jpg"
			}
		}

        geefAfbeeldingVanuitSpecifiekeUrl(afbeelding: string, url: string): string {
            let UrlEnAfbeelding = "";
            let AfbeeldingBestaatUitUrlBestandsnaamBestandstype = false;

            if (afbeelding.indexOf('/') > -1) {
                AfbeeldingBestaatUitUrlBestandsnaamBestandstype = true;
            }

            if (AfbeeldingBestaatUitUrlBestandsnaamBestandstype) {
                let afbeeldingAlleenBestandsnaam = afbeelding.substring(afbeelding.lastIndexOf('/') + 1);
                afbeeldingAlleenBestandsnaam = afbeeldingAlleenBestandsnaam.substr(0, afbeeldingAlleenBestandsnaam.indexOf('.'));

                if (this.AfbeeldingBestaat(afbeeldingAlleenBestandsnaam)) {
                    UrlEnAfbeelding = this.urlAfbeelding;
                } else {
                    UrlEnAfbeelding = afbeelding;
                }
            } else {
                if (this.AfbeeldingBestaat(afbeelding)) {
                    UrlEnAfbeelding = this.urlAfbeelding;
                } else {
                    UrlEnAfbeelding = url + afbeelding + ".jpg";
                }
            }

            return UrlEnAfbeelding;
        }

		AfbeeldingBestaat(afbeelding: string): boolean {
			let svg_url_submap = null;
			let jpg_url_submap = null;
			let png_url_submap = null;

			if (this.appconfigservice.Software.CSSSubMap != undefined) {

				let cssmap = this.appconfigservice.Software.CSSSubMap.replace("\\", "/");

				svg_url_submap = '/Content/KlantSpecifiek/' + cssmap + '/images/' + afbeelding + '.svg';
				jpg_url_submap = '/Content/KlantSpecifiek/' + cssmap + '/images/' + afbeelding + '.jpg';
				png_url_submap = '/Content/KlantSpecifiek/' + cssmap + '/images/' + afbeelding + '.png';
			}

			let svg_url = '/Content/KlantSpecifiek/' + this.appconfigservice.Software.CSSMap + '/images/' + afbeelding + '.svg';
			let jpg_url = '/Content/KlantSpecifiek/' + this.appconfigservice.Software.CSSMap + '/images/' + afbeelding + '.jpg';
			let png_url = '/Content/KlantSpecifiek/' + this.appconfigservice.Software.CSSMap + '/images/' + afbeelding + '.png';

			if (svg_url_submap != null && this.UrlExists(svg_url_submap)) {
				this.urlAfbeelding = svg_url_submap;
				return true;
			} else if (jpg_url_submap != undefined && this.UrlExists(jpg_url_submap)) {
				this.urlAfbeelding = jpg_url_submap;
				return true;
			} else if (png_url_submap != undefined && this.UrlExists(png_url_submap)) {
				this.urlAfbeelding = png_url_submap;
				return true;
			} else if (this.UrlExists(svg_url)) {
				this.urlAfbeelding = svg_url;
				return true;
			} else if (this.UrlExists(jpg_url)) {
				this.urlAfbeelding = jpg_url;
				return true;
			} else if (this.UrlExists(png_url)) {
				this.urlAfbeelding = png_url;
				return true;
			} else {
				return false;
			}
		}

		UrlExists(url: any): boolean {
			let index = _.findIndex(this.appconfigservice.Software.BestandenInCssMap, function (o) { return o.indexOf(url) != -1; });

			if (index != -1) {
				console.log("'" + url + "' gevonden...");
				return true;
			}
			else
			{
				//console.log("'" + url + "' NIET gevonden...");
				return false;
			}
		}
	}
