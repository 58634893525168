/**
 * @ref WoonConnectViewModel/User/UserViewModel.cs
 */

 import * as DataContracts from "@datacontracts/UserViewModel";

 
import * as BuildingViewModels from "./BuildingViewModels";
import * as WoningViewModel from "./WoningViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.User.UsersCountViewModel
	 */
	 

	export interface IUsersCountViewModel {
		TotalUsers: number;
		ToJsonContract(): DataContracts.IUsersCountViewModel;
	}

	export class UsersCountViewModel {
		// property-Properties
		// field-Properties
		public get TotalUsers(): number {
			return this._TotalUsers;
		}
		public set TotalUsers(newTotalUsers: number) {
			this._TotalUsers = newTotalUsers;
		}

		// fields
		protected _TotalUsers: number = 0;

		constructor(data?: DataContracts.IUsersCountViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUsersCountViewModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._TotalUsers = data.TotalUsers;
			}
		}

		public ToJsonContract(): DataContracts.IUsersCountViewModel {
			return {
				TotalUsers: this.TotalUsers
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.UsersViewModel
	 */
	 

	export interface IUsersViewModel {
		Users: UserViewModel[];
		ToJsonContract(): DataContracts.IUsersViewModel;
	}

	export class UsersViewModel {
		// property-Properties
		public get Users(): UserViewModel[] {
			return this._Users;
		}
		public set Users(newUsers: UserViewModel[]) {
			this._Users = newUsers;
		}

		// field-Properties

		// fields
		protected _Users: UserViewModel[] = [];

		constructor(data?: DataContracts.IUsersViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUsersViewModel): void {
			if(data) {
				// vul properties
				this._Users = data.Users ? data.Users.map(userViewModelItem => new UserViewModel(userViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUsersViewModel {
			return {
				Users: (this.Users ? this.Users.map(userViewModelItem => new UserViewModel(userViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.UserViewModel
	 */
	 

	export interface IUserViewModel {
		PersoonId: number;
		UserName: string;
		Email: string;
		VerificatieCode: string;
		Verificatie: boolean;
		BewonerId: number;
		LastSeen: Date;
		FirstName: string;
		LastName: string;
		MainResidentDwelling: BuildingViewModels.IAddressViewModel;
		ToJsonContract(): DataContracts.IUserViewModel;
	}

	export class UserViewModel {
		// property-Properties
		public get PersoonId(): number {
			return this._PersoonId;
		}
		public set PersoonId(newPersoonId: number) {
			this._PersoonId = newPersoonId;
		}

		public get UserName(): string {
			return this._UserName;
		}
		public set UserName(newUserName: string) {
			this._UserName = newUserName;
		}

		public get Email(): string {
			return this._Email;
		}
		public set Email(newEmail: string) {
			this._Email = newEmail;
		}

		public get VerificatieCode(): string {
			return this._VerificatieCode;
		}
		public set VerificatieCode(newVerificatieCode: string) {
			this._VerificatieCode = newVerificatieCode;
		}

		public get Verificatie(): boolean {
			return this._Verificatie;
		}
		public set Verificatie(newVerificatie: boolean) {
			this._Verificatie = newVerificatie;
		}

		public get BewonerId(): number {
			return this._BewonerId;
		}
		public set BewonerId(newBewonerId: number) {
			this._BewonerId = newBewonerId;
		}

		public get LastSeen(): Date {
			return this._LastSeen;
		}
		public set LastSeen(newLastSeen: Date) {
			this._LastSeen = newLastSeen;
		}

		public get FirstName(): string {
			return this._FirstName;
		}
		public set FirstName(newFirstName: string) {
			this._FirstName = newFirstName;
		}

		public get LastName(): string {
			return this._LastName;
		}
		public set LastName(newLastName: string) {
			this._LastName = newLastName;
		}

		public get MainResidentDwelling(): BuildingViewModels.IAddressViewModel {
			return this._MainResidentDwelling;
		}
		public set MainResidentDwelling(newMainResidentDwelling: BuildingViewModels.IAddressViewModel) {
			this._MainResidentDwelling = newMainResidentDwelling;
		}

		// field-Properties

		// fields
		protected _PersoonId: number = 0;
		protected _UserName: string = "";
		protected _Email: string = "";
		protected _VerificatieCode: string = "";
		protected _Verificatie: boolean = false;
		protected _BewonerId: number = 0;
		protected _LastSeen: Date = new Date();
		protected _FirstName: string = "";
		protected _LastName: string = "";
		protected _MainResidentDwelling: BuildingViewModels.IAddressViewModel = new BuildingViewModels.AddressViewModel(undefined);

		constructor(data?: DataContracts.IUserViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserViewModel): void {
			if(data) {
				// vul properties
				this._PersoonId = data.PersoonId;
				this._UserName = data.UserName;
				this._Email = data.Email;
				this._VerificatieCode = data.VerificatieCode;
				this._Verificatie = data.Verificatie;
				this._BewonerId = data.BewonerId;
				this._LastSeen = data.LastSeen;
				this._FirstName = data.FirstName;
				this._LastName = data.LastName;
				this._MainResidentDwelling = data.MainResidentDwelling ? new BuildingViewModels.AddressViewModel(data.MainResidentDwelling): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUserViewModel {
			return {
				PersoonId: this.PersoonId,
				UserName: this.UserName,
				Email: this.Email,
				VerificatieCode: this.VerificatieCode,
				Verificatie: this.Verificatie,
				BewonerId: this.BewonerId,
				LastSeen: this.LastSeen,
				FirstName: this.FirstName,
				LastName: this.LastName,
				MainResidentDwelling: (this.MainResidentDwelling ? new BuildingViewModels.AddressViewModel(this.MainResidentDwelling).ToJsonContract(): this.MainResidentDwelling)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.UserSecurityViewModel
	 */
	 

	export interface IUserSecurityViewModel {
		IsLockedOut: boolean;
		CanBeLockedOut: boolean;
		LockedOutEndDate: Date;
		LockedOutCount: number;
		ToJsonContract(): DataContracts.IUserSecurityViewModel;
	}

	export class UserSecurityViewModel {
		// property-Properties
		public get IsLockedOut(): boolean {
			return this._IsLockedOut;
		}
		public set IsLockedOut(newIsLockedOut: boolean) {
			this._IsLockedOut = newIsLockedOut;
		}

		public get CanBeLockedOut(): boolean {
			return this._CanBeLockedOut;
		}
		public set CanBeLockedOut(newCanBeLockedOut: boolean) {
			this._CanBeLockedOut = newCanBeLockedOut;
		}

		public get LockedOutEndDate(): Date {
			return this._LockedOutEndDate;
		}
		public set LockedOutEndDate(newLockedOutEndDate: Date) {
			this._LockedOutEndDate = newLockedOutEndDate;
		}

		public get LockedOutCount(): number {
			return this._LockedOutCount;
		}
		public set LockedOutCount(newLockedOutCount: number) {
			this._LockedOutCount = newLockedOutCount;
		}

		// field-Properties

		// fields
		protected _IsLockedOut: boolean = false;
		protected _CanBeLockedOut: boolean = false;
		protected _LockedOutEndDate: Date = new Date();
		protected _LockedOutCount: number = 0;

		constructor(data?: DataContracts.IUserSecurityViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserSecurityViewModel): void {
			if(data) {
				// vul properties
				this._IsLockedOut = data.IsLockedOut;
				this._CanBeLockedOut = data.CanBeLockedOut;
				this._LockedOutEndDate = data.LockedOutEndDate;
				this._LockedOutCount = data.LockedOutCount;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUserSecurityViewModel {
			return {
				IsLockedOut: this.IsLockedOut,
				CanBeLockedOut: this.CanBeLockedOut,
				LockedOutEndDate: this.LockedOutEndDate,
				LockedOutCount: this.LockedOutCount
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.UserMoreDetailViewModel
	 */
	 

	export interface IUserMoreDetailViewModel {
		Geslacht: number;
		Voorletters: string;
		Tussenvoegsel: string;
		UserId: string;
		Telefoon: string;
		ToJsonContract(): DataContracts.IUserMoreDetailViewModel;
	}

	export class UserMoreDetailViewModel {
		// property-Properties
		public get Geslacht(): number {
			return this._Geslacht;
		}
		public set Geslacht(newGeslacht: number) {
			this._Geslacht = newGeslacht;
		}

		public get Voorletters(): string {
			return this._Voorletters;
		}
		public set Voorletters(newVoorletters: string) {
			this._Voorletters = newVoorletters;
		}

		public get Tussenvoegsel(): string {
			return this._Tussenvoegsel;
		}
		public set Tussenvoegsel(newTussenvoegsel: string) {
			this._Tussenvoegsel = newTussenvoegsel;
		}

		public get UserId(): string {
			return this._UserId;
		}
		public set UserId(newUserId: string) {
			this._UserId = newUserId;
		}

		public get Telefoon(): string {
			return this._Telefoon;
		}
		public set Telefoon(newTelefoon: string) {
			this._Telefoon = newTelefoon;
		}

		// field-Properties

		// fields
		protected _Geslacht: number = 0;
		protected _Voorletters: string = "";
		protected _Tussenvoegsel: string = "";
		protected _UserId: string = "";
		protected _Telefoon: string = "";

		constructor(data?: DataContracts.IUserMoreDetailViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserMoreDetailViewModel): void {
			if(data) {
				// vul properties
				this._Geslacht = data.Geslacht;
				this._Voorletters = data.Voorletters;
				this._Tussenvoegsel = data.Tussenvoegsel;
				this._UserId = data.UserId;
				this._Telefoon = data.Telefoon;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUserMoreDetailViewModel {
			return {
				Geslacht: this.Geslacht,
				Voorletters: this.Voorletters,
				Tussenvoegsel: this.Tussenvoegsel,
				UserId: this.UserId,
				Telefoon: this.Telefoon
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.AnalyticsViewModel
	 */
	 

	export interface IAnalyticsViewModel {
		Id: number;
		ProjectId: number;
		Tijdstip: Date;
		ToJsonContract(): DataContracts.IAnalyticsViewModel;
	}

	export class AnalyticsViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		public get Tijdstip(): Date {
			return this._Tijdstip;
		}
		public set Tijdstip(newTijdstip: Date) {
			this._Tijdstip = newTijdstip;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _ProjectId: number = 0;
		protected _Tijdstip: Date = new Date();

		constructor(data?: DataContracts.IAnalyticsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAnalyticsViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._ProjectId = data.ProjectId;
				this._Tijdstip = data.Tijdstip;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAnalyticsViewModel {
			return {
				Id: this.Id,
				ProjectId: this.ProjectId,
				Tijdstip: this.Tijdstip
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.OrganisationViewModel
	 */
	 

	export interface IOrganisationViewModel {
		Id: number;
		Name: string;
		ToJsonContract(): DataContracts.IOrganisationViewModel;
	}

	export class OrganisationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";

		constructor(data?: DataContracts.IOrganisationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IOrganisationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IOrganisationViewModel {
			return {
				Id: this.Id,
				Name: this.Name
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.UserSettingViewModel
	 */
	 

	export interface IUserSettingViewModel {
		Id: number;
		Naam: string;
		Categorie: string;
		Type: string;
		Rol: string;
		ToJsonContract(): DataContracts.IUserSettingViewModel;
	}

	export class UserSettingViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Naam(): string {
			return this._Naam;
		}
		public set Naam(newNaam: string) {
			this._Naam = newNaam;
		}

		public get Categorie(): string {
			return this._Categorie;
		}
		public set Categorie(newCategorie: string) {
			this._Categorie = newCategorie;
		}

		public get Type(): string {
			return this._Type;
		}
		public set Type(newType: string) {
			this._Type = newType;
		}

		public get Rol(): string {
			return this._Rol;
		}
		public set Rol(newRol: string) {
			this._Rol = newRol;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Naam: string = "";
		protected _Categorie: string = "";
		protected _Type: string = "";
		protected _Rol: string = "";

		constructor(data?: DataContracts.IUserSettingViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserSettingViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Naam = data.Naam;
				this._Categorie = data.Categorie;
				this._Type = data.Type;
				this._Rol = data.Rol;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUserSettingViewModel {
			return {
				Id: this.Id,
				Naam: this.Naam,
				Categorie: this.Categorie,
				Type: this.Type,
				Rol: this.Rol
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.UserDetailViewModel
	 */
	 

	export interface IUserDetailViewModel {
		Id: number;
		Woning: WoningViewModel.IWoningViewModel;
		Persoon: UserViewModel;
		UserSecurity: UserSecurityViewModel;
		Adres: BuildingViewModels.IAddressViewModel;
		PersoonExtra: UserMoreDetailViewModel;
		Organisation: OrganisationViewModel;
		ToJsonContract(): DataContracts.IUserDetailViewModel;
	}

	export class UserDetailViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Woning(): WoningViewModel.IWoningViewModel {
			return this._Woning;
		}
		public set Woning(newWoning: WoningViewModel.IWoningViewModel) {
			this._Woning = newWoning;
		}

		public get Persoon(): UserViewModel {
			return this._Persoon;
		}
		public set Persoon(newPersoon: UserViewModel) {
			this._Persoon = newPersoon;
		}

		public get UserSecurity(): UserSecurityViewModel {
			return this._UserSecurity;
		}
		public set UserSecurity(newUserSecurity: UserSecurityViewModel) {
			this._UserSecurity = newUserSecurity;
		}

		public get Adres(): BuildingViewModels.IAddressViewModel {
			return this._Adres;
		}
		public set Adres(newAdres: BuildingViewModels.IAddressViewModel) {
			this._Adres = newAdres;
		}

		public get PersoonExtra(): UserMoreDetailViewModel {
			return this._PersoonExtra;
		}
		public set PersoonExtra(newPersoonExtra: UserMoreDetailViewModel) {
			this._PersoonExtra = newPersoonExtra;
		}

		public get Organisation(): OrganisationViewModel {
			return this._Organisation;
		}
		public set Organisation(newOrganisation: OrganisationViewModel) {
			this._Organisation = newOrganisation;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Woning: WoningViewModel.IWoningViewModel = new WoningViewModel.WoningViewModel(undefined);
		protected _Persoon: UserViewModel = new UserViewModel(undefined);
		protected _UserSecurity: UserSecurityViewModel = new UserSecurityViewModel(undefined);
		protected _Adres: BuildingViewModels.IAddressViewModel = new BuildingViewModels.AddressViewModel(undefined);
		protected _PersoonExtra: UserMoreDetailViewModel = new UserMoreDetailViewModel(undefined);
		protected _Organisation: OrganisationViewModel = new OrganisationViewModel(undefined);

		constructor(data?: DataContracts.IUserDetailViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUserDetailViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Woning = data.Woning ? new WoningViewModel.WoningViewModel(data.Woning): undefined;
				this._Persoon = data.Persoon ? new UserViewModel(data.Persoon): undefined;
				this._UserSecurity = data.UserSecurity ? new UserSecurityViewModel(data.UserSecurity): undefined;
				this._Adres = data.Adres ? new BuildingViewModels.AddressViewModel(data.Adres): undefined;
				this._PersoonExtra = data.PersoonExtra ? new UserMoreDetailViewModel(data.PersoonExtra): undefined;
				this._Organisation = data.Organisation ? new OrganisationViewModel(data.Organisation): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUserDetailViewModel {
			return {
				Id: this.Id,
				Woning: (this.Woning ? new WoningViewModel.WoningViewModel(this.Woning).ToJsonContract(): this.Woning),
				Persoon: (this.Persoon ? new UserViewModel(this.Persoon).ToJsonContract(): this.Persoon),
				UserSecurity: (this.UserSecurity ? new UserSecurityViewModel(this.UserSecurity).ToJsonContract(): this.UserSecurity),
				Adres: (this.Adres ? new BuildingViewModels.AddressViewModel(this.Adres).ToJsonContract(): this.Adres),
				PersoonExtra: (this.PersoonExtra ? new UserMoreDetailViewModel(this.PersoonExtra).ToJsonContract(): this.PersoonExtra),
				Organisation: (this.Organisation ? new OrganisationViewModel(this.Organisation).ToJsonContract(): this.Organisation)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.VerificationViewModel
	 */
	 

	export interface IVerificationViewModel {
		Id: number;
		Verificatie: boolean;
		VerificatieCode: string;
		ToJsonContract(): DataContracts.IVerificationViewModel;
	}

	export class VerificationViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Verificatie(): boolean {
			return this._Verificatie;
		}
		public set Verificatie(newVerificatie: boolean) {
			this._Verificatie = newVerificatie;
		}

		public get VerificatieCode(): string {
			return this._VerificatieCode;
		}
		public set VerificatieCode(newVerificatieCode: string) {
			this._VerificatieCode = newVerificatieCode;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Verificatie: boolean = false;
		protected _VerificatieCode: string = "";

		constructor(data?: DataContracts.IVerificationViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IVerificationViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Verificatie = data.Verificatie;
				this._VerificatieCode = data.VerificatieCode;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IVerificationViewModel {
			return {
				Id: this.Id,
				Verificatie: this.Verificatie,
				VerificatieCode: this.VerificatieCode
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.User.MultipleVerificationsViewModel
	 */
	 

	export interface IMultipleVerificationsViewModel {
		Ids: number[];
		Verificatie: boolean;
		VerificatieCodes: string[];
		ToJsonContract(): DataContracts.IMultipleVerificationsViewModel;
	}

	export class MultipleVerificationsViewModel {
		// property-Properties
		public get Ids(): number[] {
			return this._Ids;
		}
		public set Ids(newIds: number[]) {
			this._Ids = newIds;
		}

		public get Verificatie(): boolean {
			return this._Verificatie;
		}
		public set Verificatie(newVerificatie: boolean) {
			this._Verificatie = newVerificatie;
		}

		public get VerificatieCodes(): string[] {
			return this._VerificatieCodes;
		}
		public set VerificatieCodes(newVerificatieCodes: string[]) {
			this._VerificatieCodes = newVerificatieCodes;
		}

		// field-Properties

		// fields
		protected _Ids: number[] = [];
		protected _Verificatie: boolean = false;
		protected _VerificatieCodes: string[] = [];

		constructor(data?: DataContracts.IMultipleVerificationsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IMultipleVerificationsViewModel): void {
			if(data) {
				// vul properties
				this._Ids = data.Ids;
				this._Verificatie = data.Verificatie;
				this._VerificatieCodes = data.VerificatieCodes;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IMultipleVerificationsViewModel {
			return {
				Ids: this.Ids,
				Verificatie: this.Verificatie,
				VerificatieCodes: this.VerificatieCodes
			}
		}
	}
