import { InfraStatus } from '@models/InfraStatus';
import { enableProdMode } from '@angular/core';

export function GetInfraStatus(): InfraStatus
{
    let obj : InfraStatus = new InfraStatus();
    obj.ClientsideProduction = true;
    try
    {
        let xhttp = new XMLHttpRequest();
        xhttp.open("POST", "/api/Infra/Status", false);
        xhttp.send();
        obj = JSON.parse(xhttp.responseText);
    } catch (error)
    {
        console.log("Infra status: " + error);
    }
    
    return obj;
}

export function SetAngularProd(): InfraStatus
{
    let status = GetInfraStatus();
    if (status.ClientsideProduction)
    {
        enableProdMode();
    }
    return status;
}