/**
 * @ref WoonConnectViewModel/Survey/SurveyClimateSystem.cs
 */
	/**
	* Originele namespace: WoonConnectViewModel.Survey.Functions
	*/
	export enum Functions {
		Invalid = 0,
		
		None = 1,
		
		Heating = 2,
		
		Tap = 3,
		
		Ventilation = 4,
		
		Cooling = 5,
		
		HeatingAndtap = 6,
		
		HeatingAndCooling = 7,
		
		HeatingAndVentilation = 8,
		
		HeatingAndVentilationAndCooling = 9,
		
		HeatingAndTapAndVentilation = 10,
		
		TapAndVentilation = 11,
		
		VentilationAndCooling = 12,
		
		VentilationAndHeating = 13,
		
		VentilationAndHeatingAndCooling = 14,
		
		BuildingEnergyGenerator = 15
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.HeatingType
	*/
	export enum HeatingType {
		Invalid = 0,
		
		Individual = 1,
		
		Common = 2
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.HeatingDevice
	*/
	export enum HeatingDevice {
		Invalid = 0,
		
		Local = 1,
		
		Boiler = 2,
		
		Combiboiler = 3,
		
		CombiHeatpump = 4,
		
		Heatpump = 5,
		
		BoilerWithHeatPowerCoupling = 6,
		
		CombiboilerHeatPowerCoupling = 7,
		
		BiomassBoiler = 8,
		
		BiomassCombiboiler = 9,
		
		SupplyByThirdParty = 10,
		
		Unknown = 11
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.HeatingDeviceEnergyType
	*/
	export enum HeatingDeviceEnergyType {
		Invalid = 0,
		
		Gas = 1,
		
		Electricity = 2
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.HeatingDistribution
	*/
	export enum HeatingDistribution {
		Invalid = 0,
		
		Water = 1,
		
		Air = 2
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.HeatingPumpSource
	*/
	export enum HeatingPumpSource {
		Invalid = 0,
		
		Bottom = 1,
		
		OutsideAir = 2,
		
		GroundWater = 3,
		
		SurfaceWater = 4
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.AllElectric
	*/
	export enum AllElectric {
		Invalid = 0,
		
		AllElectric = 1,
		
		Hybrid = 2
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.OtherFunctions
	*/
	export enum OtherFunctions {
		Invalid = 0,
		
		None = 1,
		
		Ventilation = 2,
		
		Cooling = 3,
		
		VentilatingAndCooling = 4
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.BoilerType
	*/
	export enum BoilerType {
		Invalid = 0,
		
		CR = 1,
		
		VR = 2,
		
		HR100 = 3,
		
		HR104 = 4,
		
		HR107 = 5
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.VentilationSupply
	*/
	export enum VentilationSupply {
		Invalid = 0,
		
		Mechanical = 1,
		
		Natural = 2
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.LocalHeatingType
	*/
	export enum LocalHeatingType {
		Invalid = 0,
		
		LocalGasStove = 1,
		
		LocalOilStove = 2,
		
		ElectricalHeatingBuiltin = 3,
		
		ElectricalHeatingFreestanding = 4,
		
		BiomassStoveFreestanding = 5,
		
		BiomassStoveBuiltIn = 6,
		
		BiomassStovePellet = 7
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.Drain
	*/
	export enum Drain {
		Invalid = 0,
		
		DrainAvailable = 1,
		
		DrainNotAvailable = 2
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.BiomassAccumulate
	*/
	export enum BiomassAccumulate {
		Invalid = 0,
		
		Yes = 1,
		
		No = 2
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.DistributionSystemType
	*/
	export enum DistributionSystemType {
		Invalid = 0,
		
		OnePipe = 1,
		
		TwoPipe = 2,
		
		RenovatedOnePipe = 3
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.DistributionInsulationPipes
	*/
	export enum DistributionInsulationPipes {
		Invalid = 0,
		
		Yes = 1,
		
		No = 2,
		
		Unknown = 3
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.DistributionPipesThroughNonInsulatedRoom
	*/
	export enum DistributionPipesThroughNonInsulatedRoom {
		Invalid = 0,
		
		Yes = 1,
		
		No = 2
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.CWClass
	*/
	export enum CWClass {
		Invalid = 0,
		
		None = 1,
		
		CW3 = 2,
		
		CW4 = 3,
		
		CW5 = 4,
		
		CW6 = 5
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.GasHrLabel
	*/
	export enum GasHrLabel {
		Invalid = 0,
		
		Yes = 1,
		
		No = 2
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.GeneratorPowerHreWKK
	*/
	export enum GeneratorPowerHreWKK {
		Invalid = 0,
		
		SmallerOrEqual2kW = 1,
		
		Between2KwAnd20Kw = 2,
		
		Between20KwAnd200Kw = 3,
		
		Between200KwAnd500Kw = 4,
		
		Between500KwAnd1000Kw = 5,
		
		MoreThan1000Kw = 6
	}

