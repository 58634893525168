// [conv] import "(./WctDocumentMapPopup).less";
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as ModelsDocumentMapModel from '@models/DocumentMapModel';
import { DocumentService } from '../../services/DocumentService';
import { IBezigState, BezigState } from '../../Helpers/Overlay';
import * as DataContractsDocumentMapModel from '@datacontracts/DocumentMapModel';
import { ConstSoftware } from '../../Helpers/Backwards';

@Component({
   selector: 'wct-document-map-popup',
   templateUrl: './WctDocumentMapPopup.html'
})
export class WctDocumentMapPopupComponent {
   public parentFolderId: number;
   public folderId: number;
   public cause: string;
   public isFolderAdmin: boolean = ConstSoftware.Rollen.toLowerCase().indexOf("documentenbeheerder") != -1;

   public folderType: string = "-1";

   public folder: DataContractsDocumentMapModel.IDocumentMapModel;
   public parentFolder: DataContractsDocumentMapModel.IDocumentMapModel;
   public bezigViewModel: IBezigState;
   public folderNameNotEntered: boolean = false;

   private loadingParentFolder: boolean = true;
   private loadingFolder: boolean = true;

   constructor(
      private documentService: DocumentService,
      private dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) private data: any) {
      'ngInject'; 
      this.parentFolderId = data.parentFolderId;
      this.folderId = data.folderId;
      this.cause = data.cause;
      this.bezigViewModel = BezigState.Default();
      this.loadFolders();
   }

   public loadFolders(): void {
      this.loadingParentFolder = true;
      this.loadingFolder = true;

      if (this.parentFolderId > 0) {
         this.documentService.GeefMap(this.parentFolderId).then(d => {
            this.parentFolder = d;
            this.loadingParentFolder = false;
            this.updateLoading();
         }).catch(err => {
            this.loadingParentFolder = false;
            this.updateLoading();
            console.log(err);
         });
      }
      else {
         this.loadingParentFolder = false;
         this.updateLoading();
      }

      if (this.folderId > 0) {
         this.documentService.GeefMap(this.folderId).then(d => {
            this.folder = d;
            this.folderType = d.MapType.toString();
            this.loadingFolder = false;
            this.updateLoading();
         }).catch(err => {
            this.loadingFolder = false;
            this.updateLoading();
            console.log(err);
         });
      }
      else {
         this.loadingFolder = false;
         this.updateLoading();
         this.folder = new ModelsDocumentMapModel.DocumentMapModel();
         this.folder.ParentId = this.parentFolderId;
      }
   }

   public save(): void {
      this.bezigViewModel.ToonOverlay = true;
      this.folder.MapType = parseInt(this.folderType);

      if (this.folder.Naam == "") {
         this.folderNameNotEntered = true;
         this.bezigViewModel.ToonOverlay = false;
      } else {
         this.documentService.OpslaanMap(this.folder).then(d => {
            this.bezigViewModel.ToonOverlay = false;
            this.dialog.closeAll();
         }).catch(err => {
            this.bezigViewModel.ToonOverlay = false;
            console.log(err);
            this.dialog.closeAll();
         });
      }
   }

   public showFolderNameNotEntered(): boolean {
      let ret: boolean = false;
      if (this.folderNameNotEntered) {
         ret = (this.folder.Naam == "");
      }
      return ret;
   }

   public cancel(): void {
      this.dialog.closeAll();
   }

   public deleteFolder(): void {
      this.documentService.VerwijderMap(this.folder.Id);
      this.dialog.closeAll();
   }

   private updateLoading(): void {
      this.bezigViewModel.ToonOverlay = this.loadingFolder || this.loadingParentFolder;
   }
}