// [conv] import "(./WctActivatieFormulier).less";
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WindowService } from '../../viewers/WindowService';
import { AccountService } from '../../services/AccountService';
import * as ViewModelsActivatie from '../../Models/Activatie';
import { Component } from "@angular/core";
import { FormControl, FormBuilder, Validators, FormGroup, ValidationErrors, AbstractControl } from '@angular/forms';
import { AppConfigService } from "src/app/infrastructure/app.configservice";
import { WctActivatieFormulierPopupComponent } from "./WctActivatieFormulierPopup";

   @Component({
      selector: 'wct-activatie-formulier',
      templateUrl: './WctActivatieFormulier.html',
      styleUrls: ['./WctActivatieFormulier.scss']
   })
	export class WctActivatieFormulierComponent {
		readonly EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
		activatie: ViewModelsActivatie.Activatie;
		ToonActivatieFormulierHulp: boolean = false;
		WachtwoordBevestigingTekst: string = "";
		wachtwoordTekst: string = "";
		emailAdresTekst: string = "";
		customFullscreen: boolean = false;
		windowWidth: number;
		metControleVraag: boolean = true;
		controlevraag: boolean = true;
		invulformulier: boolean = false;
		public ToonInlognaam: boolean = true;

		password = new FormControl('');
		form = this.formBuilder.group({
			username: '',
			initials: '',
			firstName: '',
			surnamePrefix: '',
			surname: '',
			phoneNumber: '',
			emailAddress: ['', [Validators.email]],
			password: ['', [Validators.required, Validators.minLength(6)]],
			confirmPassword: ['', [WctActivatieFormulierComponent.matchValues('password')]]
		  });

		constructor(
         private accountService: AccountService,
			private windowService: WindowService,
         private config: AppConfigService,
         private dialog: MatDialog,
         private router: Router,
		 private formBuilder: FormBuilder,
      ) {
'ngInject';
			if (this.config.Software.ActivatieVoornaamVerplicht) {
				this.form.get('firstName').setValidators([Validators.required]);
			}

			if (!this.config.Software.ActivatieVoornaamAanpasbaar) {
				this.form.get('firstName').disable();
			}

			if (this.config.Software.ActivatieAchternaamVerplicht) {
				this.form.get('surname').setValidators([Validators.required]);
			}
         	if (!this.config.Software.ActivatieAchternaamAanpasbaar) {
				this.form.get('surname').disable();
			}

         	if (!this.config.Software.ActivatieAchternaamAanpasbaar) {
				this.form.get('initials').disable();
			}

			if (this.config.Software.ActivatieTelefoonnummerVerplicht) {
				this.form.get('phoneNumber').setValidators([Validators.required]);
			}

			if (this.config.Software.ActivatieEmailVerplicht) {
				this.form.get('emailAddress').setValidators([Validators.required, this.form.get('emailAddress').validator]);
			}
			this.ToonInlognaam = this.config.Software.ToonInlognaam;


			this.form.controls.password.valueChanges.subscribe(() => {
				this.form.controls.confirmPassword.updateValueAndValidity();
			  });

         this.activatie = this.accountService.GeefActivatieAccount();
         if (this.activatie == null) {
            // this.$state.go('root.activatie');
            this.router.navigate(['']);
         }

         this.windowWidth = window.innerWidth || document.documentElement.clientWidth;
			this.windowService.WindowGrootteAangepastEvent.RegisterHandler(undefined, () => {
				this.windowWidth = this.windowService.GeefWindowWidth();
			});

			if (!this.metControleVraag) {
				this.ToonInvulformulier();
			}
      }

      ToonInvulformulier() {
         this.controlevraag = false;
         this.invulformulier = true;
      }

      naarBeginscherm() {
         window.location.replace('');
      }

      toonDisclaimerPopup(oorzaak: any, melding: any) {
         // pop-up openen met het document er in, full screen size bij tablets
         if (this.windowWidth <= 1024) {
            this.customFullscreen = true;
         } else {
            this.customFullscreen = false;
         }

         const dialogConfig = new MatDialogConfig();
         dialogConfig.data = { oorzaak: 'disclaimerGoedkeuren', melding: 'melding', width: this.windowWidth };
         dialogConfig.panelClass = "activatieformulier__mat-dialog";
         const dialogRef = this.dialog.open(WctActivatieFormulierPopupComponent, dialogConfig);

         dialogRef.afterClosed().subscribe(result => {
            if (result) {
               this.accountService.ActivatieGegevensInvullen().then(b => {
                  if (b.ActivationStatus.Melding == 'aanmeldingGeslaagd') {
                     this.toonPopup('aanmeldingGeslaagd', b.ActivationStatus.Melding);
                  } else {
                     this.toonPopup('aanmeldingNietGeslaagd', b.ActivationStatus.Melding);
                  }
               });
            }
         });
      }

      toonPopup(oorzaak: any, melding: any) {
         // pop-up openen met het document er in, full screen size bij tablets
         if (this.windowWidth <= 1024) {
            this.customFullscreen = true;
         } else {
            this.customFullscreen = false;
         }

         if (oorzaak == '' && melding != '') {
            oorzaak = "aanmeldingNietGeslaagd";
         }

         const dialogConfig = new MatDialogConfig();
         dialogConfig.data = { oorzaak: oorzaak, melding: melding, width: this.windowWidth };
         dialogConfig.panelClass = "activatieformulier__mat-dialog";
         const dialogRef = this.dialog.open(WctActivatieFormulierPopupComponent, dialogConfig);
         dialogRef.afterClosed().subscribe(() => {
            this.GaNaarBeginscherm();
         });
		}

        public GaNaarBeginscherm() {
            window.location.replace('logonzonderactiveren');
        }

	  public static matchValues( matchTo: string ): (AbstractControl) => ValidationErrors | null {
		return (control: AbstractControl): ValidationErrors | null => {
		  return !!control.parent &&
			!!control.parent.value &&
			control.value === control.parent.controls[matchTo].value
			? null
			: { isMatching: false };
		};
	}

	onSubmit() {
		if (this.form.valid) {
			this.activatie.Voorletters = this.form.controls['initials'].value;
			this.activatie.Voornaam = this.form.controls['firstName'].value;
			this.activatie.Tussenvoegsel = this.form.controls['surnamePrefix'].value;
			this.activatie.Achternaam = this.form.controls['surname'].value;
			this.activatie.Telefoonnummer = this.form.controls['phoneNumber'].value;
			this.activatie.EmailAdres = this.form.controls['emailAddress'].value;
			this.activatie.Wachtwoord = this.form.controls['password'].value;
			this.activatie.BevestigWachtwoord = this.form.controls['confirmPassword'].value;
			this.toonDisclaimerPopup('aanmeldingGeslaagd', null);
		}
		
	}
   }



