/// <reference path='../consumerinterfaces/PriceSetController.ts' />
/**
 * @ref WoonConnectApiController/Controllers/PriceSetController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/PriceSetController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as PriceSetViewModel from "@datacontracts/PriceSetViewModel";
import * as HourlyTarifKindViewModel from "@datacontracts/HourlyTarifKindViewModel";
import * as HourlyTarifSetViewModel from "@datacontracts/HourlyTarifSetViewModel";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class PriceSetConsumer implements Interface.IPriceSetConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public GetPriceSets_Observable(pPriceSetRequestModelModel: PriceSetViewModel.IPriceSetRequestModel & {ToJsonContract(noContent?: boolean): PriceSetViewModel.IPriceSetRequestModel}): Observable<PriceSetViewModel.IPriceSetsViewModel> {
				const pPriceSetRequestModel = pPriceSetRequestModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pPriceSetRequestModel;
				let apiURL = `api/PriceSet/GetPriceSets/${pPriceSetRequestModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PriceSetViewModel.IPriceSetsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetPriceSets(pPriceSetRequestModelModel: PriceSetViewModel.IPriceSetRequestModel & {ToJsonContract(noContent?: boolean): PriceSetViewModel.IPriceSetRequestModel}): ng.IPromise<PriceSetViewModel.IPriceSetsViewModel> {
				let obs = this.GetPriceSets_Observable(pPriceSetRequestModelModel);
				let promise = new Promise<PriceSetViewModel.IPriceSetsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SavePriceSet_Observable(pPriceSetViewModelModel: PriceSetViewModel.IPriceSetViewModel & {ToJsonContract(noContent?: boolean): PriceSetViewModel.IPriceSetViewModel}): Observable<void> {
				const pPriceSetViewModel = pPriceSetViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pPriceSetViewModel;
				let apiURL = `api/PriceSet/SavePriceSet/${pPriceSetViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SavePriceSet(pPriceSetViewModelModel: PriceSetViewModel.IPriceSetViewModel & {ToJsonContract(noContent?: boolean): PriceSetViewModel.IPriceSetViewModel}): ng.IPromise<void> {
				let obs = this.SavePriceSet_Observable(pPriceSetViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public RemovePriceSet_Observable(pPriceSetViewModelModel: PriceSetViewModel.IPriceSetViewModel & {ToJsonContract(noContent?: boolean): PriceSetViewModel.IPriceSetViewModel}): Observable<void> {
				const pPriceSetViewModel = pPriceSetViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pPriceSetViewModel;
				let apiURL = `api/PriceSet/RemovePriceSet/${pPriceSetViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public RemovePriceSet(pPriceSetViewModelModel: PriceSetViewModel.IPriceSetViewModel & {ToJsonContract(noContent?: boolean): PriceSetViewModel.IPriceSetViewModel}): ng.IPromise<void> {
				let obs = this.RemovePriceSet_Observable(pPriceSetViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetHourlyTarifKinds_Observable(): Observable<HourlyTarifKindViewModel.IHourlyTarifKindsViewModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/PriceSet/GetHourlyTarifKinds`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<HourlyTarifKindViewModel.IHourlyTarifKindsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetHourlyTarifKinds(): ng.IPromise<HourlyTarifKindViewModel.IHourlyTarifKindsViewModel> {
				let obs = this.GetHourlyTarifKinds_Observable();
				let promise = new Promise<HourlyTarifKindViewModel.IHourlyTarifKindsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetHourlyTarifSets_Observable(pHourlyTarifSetRequestModelModel: HourlyTarifSetViewModel.IHourlyTarifSetRequestModel & {ToJsonContract(noContent?: boolean): HourlyTarifSetViewModel.IHourlyTarifSetRequestModel}): Observable<HourlyTarifSetViewModel.IHourlyTarifSetsViewModel> {
				const pHourlyTarifSetRequestModel = pHourlyTarifSetRequestModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pHourlyTarifSetRequestModel;
				let apiURL = `api/PriceSet/GetHourlyTarifSets/${pHourlyTarifSetRequestModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<HourlyTarifSetViewModel.IHourlyTarifSetsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetHourlyTarifSets(pHourlyTarifSetRequestModelModel: HourlyTarifSetViewModel.IHourlyTarifSetRequestModel & {ToJsonContract(noContent?: boolean): HourlyTarifSetViewModel.IHourlyTarifSetRequestModel}): ng.IPromise<HourlyTarifSetViewModel.IHourlyTarifSetsViewModel> {
				let obs = this.GetHourlyTarifSets_Observable(pHourlyTarifSetRequestModelModel);
				let promise = new Promise<HourlyTarifSetViewModel.IHourlyTarifSetsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveHourlyTarifSet_Observable(pHourlyTarifSetViewModelModel: HourlyTarifSetViewModel.IHourlyTarifSetViewModel & {ToJsonContract(noContent?: boolean): HourlyTarifSetViewModel.IHourlyTarifSetViewModel}): Observable<void> {
				const pHourlyTarifSetViewModel = pHourlyTarifSetViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pHourlyTarifSetViewModel;
				let apiURL = `api/PriceSet/SaveHourlyTarifSet/${pHourlyTarifSetViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveHourlyTarifSet(pHourlyTarifSetViewModelModel: HourlyTarifSetViewModel.IHourlyTarifSetViewModel & {ToJsonContract(noContent?: boolean): HourlyTarifSetViewModel.IHourlyTarifSetViewModel}): ng.IPromise<void> {
				let obs = this.SaveHourlyTarifSet_Observable(pHourlyTarifSetViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public RemoveHourlyTarifSet_Observable(pHourlyTarifSetViewModelModel: HourlyTarifSetViewModel.IHourlyTarifSetViewModel & {ToJsonContract(noContent?: boolean): HourlyTarifSetViewModel.IHourlyTarifSetViewModel}): Observable<void> {
				const pHourlyTarifSetViewModel = pHourlyTarifSetViewModelModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pHourlyTarifSetViewModel;
				let apiURL = `api/PriceSet/RemoveHourlyTarifSet/${pHourlyTarifSetViewModel}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public RemoveHourlyTarifSet(pHourlyTarifSetViewModelModel: HourlyTarifSetViewModel.IHourlyTarifSetViewModel & {ToJsonContract(noContent?: boolean): HourlyTarifSetViewModel.IHourlyTarifSetViewModel}): ng.IPromise<void> {
				let obs = this.RemoveHourlyTarifSet_Observable(pHourlyTarifSetViewModelModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


