import * as HelpersAssert from '.././Helpers/Assert';


	/**
	 * Select from one of four modes: determinate, indeterminate, buffer and query.
	 * Note: if the mode value is set as undefined or specified as 1 of the four (4) valid modes, then indeterminate will be auto-applied as the mode.
	 * Note: if not configured, the mode="indeterminate" will be auto injected as an attribute.
	 * If value="" is also specified, however, then mode="determinate" would be auto-injected instead.
	 */
	export type LinearProgressBarMode = CircularProgressBarMode | "buffer" | "query";

	/**
	* Select from one of two modes: determinate, indeterminate.
	* Note: if the mode value is set as undefined or specified as 1 of the two (2) valid modes, then indeterminate will be auto-applied as the mode.
	* Note: if not configured, the mode="indeterminate" will be auto injected as an attribute.
	* If value="" is also specified, however, then mode="determinate" would be auto-injected instead.
	*/
	export type CircularProgressBarMode = "determinate" | "indeterminate";
	export type ProgressBarType = "linear" | "circular";

	export enum BezigActionEnum {
		/**
		 * Close button event.
		 */
		Close = 0,
		/**
		 * Nep event, zodat de enum 2 waardes heeft, wat er voor
		 * zorgt dat  HelpersAssert.Assert.Unreachable juist wordt uitgevoerd.
		 */
		Niks
	}

	export interface IBezigState {
		/**
		 * Geeft aan of de overlay met animatie moet worden getoond.
		 */
		ToonOverlay: boolean;

		/**
		 * Het type progress bar.
		 */
		Type: ProgressBarType;

		/**
		 * In determinate and buffer modes, this number represents the percentage of the primary progress bar.
		 * Default: 0
		 */
		Value?: number;

		/**
		 * In the buffer mode, this number represents the percentage of the secondary progress bar.
		 * Default: 0.
		 */
		BufferValue?: number;

		ToonLadenMelding?: boolean;

		LadenMelding?: string;

		/**
		 * De modus waarin de animatie zich bevindt.
		 */
		Mode?: CircularProgressBarMode | LinearProgressBarMode;

		/**
		 * De diameter van de cirkel in pixels.
		 */
		Diameter?: number;

		/**
		 * Geeft aan of de close button moet worden getoond.
		 */
		ToonCloseButton: boolean;

		/**
		 * Of het mogelijk is om een interactie aan te gaan met de onderliggende HTML elementen.
		 */
		ClickThrough: boolean;

		/**
		 * Tekstgrootte
		 */
		ExplanationSize: string;
	}

	export class BezigState implements IBezigState {
		private constructor(
			public ToonOverlay: boolean,
			public Type: ProgressBarType,
			public Value?: number,
			public BufferValue?: number,
			public ToonLadenMelding?: boolean,
			public LadenMelding?: string,
			public Mode: CircularProgressBarMode | LinearProgressBarMode = "indeterminate",
			public Diameter: number = 50,
			public ToonCloseButton: boolean = false,
			public ClickThrough: boolean = false,
			public ExplanationSize: string = "small"
		) {
		}

		public static Default(): BezigState {
			return new BezigState(false, "circular");
		}

		public static LoaderMetTekst(): BezigState {
			return new BezigState(true, "circular", 0, 0, true, "", "indeterminate", 100, false, false, "medium");
		}
	}
