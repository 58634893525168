/// <reference path='../consumerinterfaces/BuildingManagementController.ts' />
/**
 * @ref WoonConnectApiController/User/BuildingManagementController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/BuildingManagementController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as BuildingViewModels from "@datacontracts/BuildingViewModels";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class BuildingManagementConsumer implements Interface.IBuildingManagementConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public CountBuildings_Observable(inputModel: BuildingViewModels.IRowCountViewModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IRowCountViewModel}): Observable<BuildingViewModels.IRowCountViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/BuildingManagement/CountAddresses`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BuildingViewModels.IRowCountViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public CountBuildings(inputModel: BuildingViewModels.IRowCountViewModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IRowCountViewModel}): ng.IPromise<BuildingViewModels.IRowCountViewModel> {
				let obs = this.CountBuildings_Observable(inputModel);
				let promise = new Promise<BuildingViewModels.IRowCountViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetUserBuilding_Observable(inputModel: BuildingViewModels.IGetRequestByIdModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IGetRequestByIdModel}): Observable<BuildingViewModels.IBuildingDetailViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/BuildingManagement/GetUserBuilding`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BuildingViewModels.IBuildingDetailViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetUserBuilding(inputModel: BuildingViewModels.IGetRequestByIdModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IGetRequestByIdModel}): ng.IPromise<BuildingViewModels.IBuildingDetailViewModel> {
				let obs = this.GetUserBuilding_Observable(inputModel);
				let promise = new Promise<BuildingViewModels.IBuildingDetailViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public DeleteBuilding_Observable(inputModel: BuildingViewModels.IAddressViewModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IAddressViewModel}): Observable<BuildingViewModels.IAddressViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/BuildingManagement/DeleteAddress`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BuildingViewModels.IAddressViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DeleteBuilding(inputModel: BuildingViewModels.IAddressViewModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IAddressViewModel}): ng.IPromise<BuildingViewModels.IAddressViewModel> {
				let obs = this.DeleteBuilding_Observable(inputModel);
				let promise = new Promise<BuildingViewModels.IAddressViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetAddresses_Observable(inputModel: BuildingViewModels.IBuildingRetrieveRequestModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IBuildingRetrieveRequestModel}): Observable<BuildingViewModels.IAddressesViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/BuildingManagement/GetAddresses`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BuildingViewModels.IAddressesViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetAddresses(inputModel: BuildingViewModels.IBuildingRetrieveRequestModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IBuildingRetrieveRequestModel}): ng.IPromise<BuildingViewModels.IAddressesViewModel> {
				let obs = this.GetAddresses_Observable(inputModel);
				let promise = new Promise<BuildingViewModels.IAddressesViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveAddress_Observable(inputModel: BuildingViewModels.IAddressViewModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IAddressViewModel}): Observable<BuildingViewModels.IAddressViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/BuildingManagement/SaveAddress`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BuildingViewModels.IAddressViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveAddress(inputModel: BuildingViewModels.IAddressViewModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IAddressViewModel}): ng.IPromise<BuildingViewModels.IAddressViewModel> {
				let obs = this.SaveAddress_Observable(inputModel);
				let promise = new Promise<BuildingViewModels.IAddressViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveBuilding_Observable(inputModel: BuildingViewModels.IBuildingDetailViewModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IBuildingDetailViewModel}): Observable<BuildingViewModels.IBuildingDetailViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/BuildingManagement/SaveBuilding`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BuildingViewModels.IBuildingDetailViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveBuilding(inputModel: BuildingViewModels.IBuildingDetailViewModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IBuildingDetailViewModel}): ng.IPromise<BuildingViewModels.IBuildingDetailViewModel> {
				let obs = this.SaveBuilding_Observable(inputModel);
				let promise = new Promise<BuildingViewModels.IBuildingDetailViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetAllBuildings_Observable(inputModel: BuildingViewModels.IBuildingsViewModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IBuildingsViewModel}): Observable<BuildingViewModels.IBuildingsViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/BuildingManagement/GetAllBuilding`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BuildingViewModels.IBuildingsViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetAllBuildings(inputModel: BuildingViewModels.IBuildingsViewModel & {ToJsonContract(noContent?: boolean): BuildingViewModels.IBuildingsViewModel}): ng.IPromise<BuildingViewModels.IBuildingsViewModel> {
				let obs = this.GetAllBuildings_Observable(inputModel);
				let promise = new Promise<BuildingViewModels.IBuildingsViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


