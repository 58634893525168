// [conv] import "(./FabAdminProductProperty).less";
import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FabrikantenAdminSearchProductRequestModel, FabrikantenAdminSearchProductResponseModel } from '@models/FabrikantenAdminModels';
import { FabrikantenAdminConsumer } from "@consumers/FabrikantenAdminController";

@Component({
  selector: 'fab-admin-search-product',
  templateUrl: './FabAdminSearchProduct.html'
})

export class FabAdminSearchProductComponent {

  public Loading: boolean = false;

  public ToggleAllValue: boolean = false;

  public Request: FabrikantenAdminSearchProductRequestModel;
  public Response: FabrikantenAdminSearchProductResponseModel;

  constructor(private route: ActivatedRoute, private fabadmin: FabrikantenAdminConsumer) {
    this.Request = new FabrikantenAdminSearchProductRequestModel();
    this.Request.Input = "";

    this.Search();
  }

  public Search(): void {
    this.Loading = true;

    this.fabadmin.SearchProduct(this.Request).then((data) => {
      this.Response = new FabrikantenAdminSearchProductResponseModel(data);
      this.Loading = false;
    });
  }

  public AddProducts(): void {
    this.Loading = true;

    this.fabadmin.AddProducts(this.Response).then(() => {
      this.Loading = false;
    });
  }

  public ToggleAll(): void {
    this.ToggleAllValue = !this.ToggleAllValue;

    for (var i = 0; i < this.Response.Products.length; i++) {
      this.Response.Products[i].Connected = this.ToggleAllValue;
    }
  }
}
