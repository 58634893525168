import { Component, Input, OnInit } from '@angular/core';
import { ISurveyGeneric } from '@datacontracts/SurveyGeneric';
import { FormGroupState } from 'ngrx-forms';

@Component({
  selector: 'wct-survey-generic',
  templateUrl: './wct-survey-generic.component.html',
  styleUrls: ['./wct-survey-generic.component.scss']
})
export class WctSurveyGenericComponent implements OnInit {

  @Input() generic : FormGroupState<ISurveyGeneric> = null;
  
  constructor() { }

  ngOnInit(): void {
  }

}
