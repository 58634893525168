// [conv] import "(./FabAdminProductVariant).less";
import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductsConsumer } from '@consumers/ProductsController';
import { AddEigenschapInputModel, ProductVariantModel, ProductRequestModel, ProductViewModel, ProductPropertySaveModel, ProductPropertyVariantRelationsViewModel, ProductPropertyViewModel, ProductPropertiesViewModel, ProductPropertyTypesViewModel, ProductPropertyTypeViewModel } from '@models/ProductsViewModel';


@Component({
  selector: 'fab-admin-product-variant',
  templateUrl: './FabAdminProductVariant.html',
  styleUrls: ['./FabAdminProductVariant.scss']
})
export class FabAdminProductVariantComponent {
  @Output() public ProductVariantId: number;
  Laden: boolean = true;
  update: boolean = true;
  fVariant: ProductVariantModel = new ProductVariantModel();
  ProductViewModel: ProductViewModel = new ProductViewModel();
  @Output() public Saved = new EventEmitter<any>();
  @Output() showMe: boolean = true;

  public NewEigenschapName: string;
  showProductPropertyRelation: boolean = true;
  ProductRequestModel: ProductRequestModel;
  ProductPropertySaveModel: ProductPropertySaveModel = new ProductPropertySaveModel();
  ProductPropertyVariantRelationsViewModel: ProductPropertyVariantRelationsViewModel = new ProductPropertyVariantRelationsViewModel();
  ProductProperty: ProductPropertyViewModel = new ProductPropertyViewModel();
  ProductProperties: ProductPropertiesViewModel = new ProductPropertiesViewModel();
  ProductPropertyType: ProductPropertyTypeViewModel = new ProductPropertyTypeViewModel();
  ProductPropertyTypesViewModel: ProductPropertyTypesViewModel = new ProductPropertyTypesViewModel();


  constructor(private route: ActivatedRoute, private prod: ProductsConsumer, private router: Router) {
    this.ProductVariantId = parseInt((<any>this.route.params).value.variantid);

    if (this.ProductVariantId != undefined) {
      //variant niet gevonden
    }

    this.Refresh();
  }

  public Refresh(): void {
    var input = new ProductRequestModel();
    input.Id = this.ProductVariantId;

    //create product variant model. 
    this.prod.GetVariant_Observable(input).subscribe((data) => {

      var temp = new ProductVariantModel(data);

      try {
        if (this.fVariant != undefined) {
          for (var i = 0; i < this.fVariant.ProductPropertiesEigenschappen.length; i++) {
            this.Sync(this.fVariant.ProductPropertiesEigenschappen[i], temp.ProductPropertiesEigenschappen[i]);
          }

          for (var i = 0; i < this.fVariant.ProductPropertiesOpbouw.length; i++) {
            this.Sync(this.fVariant.ProductPropertiesOpbouw[i], temp.ProductPropertiesOpbouw[i]);
          }
        }
      }
      catch (ex) {

      }

      this.fVariant = temp;

      console.log(data);

      var input = new ProductRequestModel();
      input.Id = data.ProductId;

      console.log(input);

      this.prod.GetProduct_Observable(input).subscribe((data) => {
        console.log(data);
        this.ProductViewModel = new ProductViewModel(data);
      });
    })

    //ophalen voor product properties
    //this.ProductProperties = new ProductPropertiesViewModel();
    //this.prod.GetAllProperties_Observable(this.ProductProperties).subscribe((data) => {
    //   this.ProductProperties = new ProductPropertiesViewModel(data);

    //});

    //this.ProductPropertyTypesViewModel = new ProductPropertyTypesViewModel();

    //this.prod.GetAllProductPropertyTypes_Observable(this.ProductPropertyTypesViewModel).subscribe((data) => {

    //   this.ProductPropertyTypesViewModel = new ProductPropertyTypesViewModel(data)
    //});
    //this.prod.GetProductPropertyVariantRelation_Observable(input).subscribe((data) => {
    //   //check if the ProductPropertyRelationsViewModel waarde heeft. 

    //   this.ProductPropertyVariantRelationsViewModel = new ProductPropertyVariantRelationsViewModel(data);


    //});

    this.Laden = false;

  }

  public ResetImportFlags() {
    this.Laden = true;

    this.prod.ResetImportFlags_Observable(-1, this.ProductVariantId).subscribe((data) => {
      this.Refresh();
    });
  }

  Sync(first: ProductPropertyViewModel, second: ProductPropertyViewModel) {
    second.Display = first.Display;

    for (var i = 0; i < first.Options.length; i++) {
      for (var j = 0; j < first.Options[i].ProductPropertiesEigenschappen.length; j++) {
        this.Sync(first.Options[i].ProductPropertiesEigenschappen[j], second.Options[i].ProductPropertiesEigenschappen[j]);
      }

      for (var j = 0; j < first.Options[i].ProductPropertiesOpbouw.length; j++) {
        this.Sync(first.Options[i].ProductPropertiesOpbouw[j], second.Options[i].ProductPropertiesOpbouw[j]);
      }
    }
  }

  addVariant() {
    this.prod.SaveVariant_Observable(this.fVariant).subscribe((data) => {
      console.log(data);
      this.update = true;
      this.Refresh();
    });

  }

  Import() {
    this.Laden = true;
    this.prod.ImportVariant_Observable(this.fVariant).subscribe((data) => {
      console.log(data);
      this.Laden = false;
      this.update = true;
      this.Refresh();
    });

  }

  public saveProductProperty() {

    if (this.ProductProperty.Id < 1) {
      //nieuwe product property aanmaken. 
      this.ProductPropertySaveModel = new ProductPropertySaveModel();
      this.ProductPropertySaveModel.ProductId = this.ProductVariantId;
      this.ProductPropertySaveModel.Name = this.ProductProperty.Name;

      //save and create product property
      if (this.ProductPropertyType.Id < 1) {
        this.prod.SaveProductPropertyType_Observable(this.ProductPropertyType).subscribe((data) => {
          this.ProductPropertyType = new ProductPropertyTypeViewModel(data);

        });

      }
      this.ProductPropertySaveModel.ProductPropertyTypeId = this.ProductPropertyType.Id;

      //save product property nieuw en daarbij ook de relatie 
      console.log(this.ProductPropertySaveModel);
      //this.prod.SaveProductProperty_Observable(this.ProductPropertySaveModel).subscribe((data) => {
      //   this.ProductPropertySaveModel = new ProductPropertySaveModel();
      //   console.log(data);
      //})
    }

    if (this.ProductProperty.Id > 0) {
      this.ProductPropertySaveModel = new ProductPropertySaveModel();
      this.ProductPropertySaveModel.ProductId = this.ProductVariantId;
      this.ProductPropertySaveModel.Id = this.ProductProperty.Id;
      //Product Property Bestaat al en er hoeft alleen een link gemaakt te worden. 
      this.prod.SaveProductPropertyVariantRelation_Observable(this.ProductPropertySaveModel).subscribe((data) => {
        console.log(data);
      })
    }
    this.Refresh();
    this.showProductPropertyRelation = true;
  }

  public AddEigenschap(): void {
    var input: AddEigenschapInputModel = new AddEigenschapInputModel();
    input.Name = this.NewEigenschapName;
    input.ParentPropertyOptionId = -1;
    input.ProductVariantId = this.ProductVariantId;

    this.prod.AddEigenschap_Observable(input).subscribe(() => {
      this.Refresh();
    });
  }

}
