/**
 * @ref WoonConnect/DataTransfer/Configurator/TekeningConfiguratie.cs
 */

 import * as DataContracts from "@datacontracts/TekeningConfiguratie";

 
import * as TekeningConfiguratieEnum from "@enums/TekeningConfiguratie";



	/**
	 * Originele namespace: WoonConnect.DataTransfer.Configurator.TekeningConfiguratie
	 */
	 

	export interface ITekeningConfiguratie {
		Mode: TekeningConfiguratieEnum.TekeningConfiguratieMode;
		WoningId: number;
		Scenario: number;
		CameraIndex: number;
		Invalshoek: string;
		TekeningNaam: string;
		GebruikEditView: boolean;
		AchtergrondKleur: string;
		OverruleHoogte: number;
		OverruleBreedte: number;
		Navigeerbaar: boolean;
		ProjectId: number;
		MeldingId: number;
		Scope: number;
		ToJsonContract(): DataContracts.ITekeningConfiguratie;
	}

	export class TekeningConfiguratie {
		// property-Properties
		// field-Properties
		public get Mode(): TekeningConfiguratieEnum.TekeningConfiguratieMode {
			return this._Mode;
		}
		public set Mode(newMode: TekeningConfiguratieEnum.TekeningConfiguratieMode) {
			this._Mode = newMode;
		}
		
		public get WoningId(): number {
			return this._WoningId;
		}
		public set WoningId(newWoningId: number) {
			this._WoningId = newWoningId;
		}
		
		public get Scenario(): number {
			return this._Scenario;
		}
		public set Scenario(newScenario: number) {
			this._Scenario = newScenario;
		}
		
		public get CameraIndex(): number {
			return this._CameraIndex;
		}
		public set CameraIndex(newCameraIndex: number) {
			this._CameraIndex = newCameraIndex;
		}
		
		public get Invalshoek(): string {
			return this._Invalshoek;
		}
		public set Invalshoek(newInvalshoek: string) {
			this._Invalshoek = newInvalshoek;
		}
		
		public get TekeningNaam(): string {
			return this._TekeningNaam;
		}
		public set TekeningNaam(newTekeningNaam: string) {
			this._TekeningNaam = newTekeningNaam;
		}
		
		public get GebruikEditView(): boolean {
			return this._GebruikEditView;
		}
		public set GebruikEditView(newGebruikEditView: boolean) {
			this._GebruikEditView = newGebruikEditView;
		}
		
		public get AchtergrondKleur(): string {
			return this._AchtergrondKleur;
		}
		public set AchtergrondKleur(newAchtergrondKleur: string) {
			this._AchtergrondKleur = newAchtergrondKleur;
		}
		
		public get OverruleHoogte(): number {
			return this._OverruleHoogte;
		}
		public set OverruleHoogte(newOverruleHoogte: number) {
			this._OverruleHoogte = newOverruleHoogte;
		}
		
		public get OverruleBreedte(): number {
			return this._OverruleBreedte;
		}
		public set OverruleBreedte(newOverruleBreedte: number) {
			this._OverruleBreedte = newOverruleBreedte;
		}
		
		public get Navigeerbaar(): boolean {
			return this._Navigeerbaar;
		}
		public set Navigeerbaar(newNavigeerbaar: boolean) {
			this._Navigeerbaar = newNavigeerbaar;
		}
		
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}
		
		public get MeldingId(): number {
			return this._MeldingId;
		}
		public set MeldingId(newMeldingId: number) {
			this._MeldingId = newMeldingId;
		}
		
		public get Scope(): number {
			return this._Scope;
		}
		public set Scope(newScope: number) {
			this._Scope = newScope;
		}

		// fields
		protected _Mode: TekeningConfiguratieEnum.TekeningConfiguratieMode = undefined;
		protected _WoningId: number = 0;
		protected _Scenario: number = 0;
		protected _CameraIndex: number = 0;
		protected _Invalshoek: string = "";
		protected _TekeningNaam: string = "";
		protected _GebruikEditView: boolean = false;
		protected _AchtergrondKleur: string = "";
		protected _OverruleHoogte: number = 0;
		protected _OverruleBreedte: number = 0;
		protected _Navigeerbaar: boolean = false;
		protected _ProjectId: number = 0;
		protected _MeldingId: number = 0;
		protected _Scope: number = 0;

		constructor(data?: DataContracts.ITekeningConfiguratie) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITekeningConfiguratie): void {
			if(data) {
				// vul properties
				// vul fields
				this._Mode = data.Mode;
				this._WoningId = data.WoningId;
				this._Scenario = data.Scenario;
				this._CameraIndex = data.CameraIndex;
				this._Invalshoek = data.Invalshoek;
				this._TekeningNaam = data.TekeningNaam;
				this._GebruikEditView = data.GebruikEditView;
				this._AchtergrondKleur = data.AchtergrondKleur;
				this._OverruleHoogte = data.OverruleHoogte;
				this._OverruleBreedte = data.OverruleBreedte;
				this._Navigeerbaar = data.Navigeerbaar;
				this._ProjectId = data.ProjectId;
				this._MeldingId = data.MeldingId;
				this._Scope = data.Scope;
			}
		}

		public ToJsonContract(): DataContracts.ITekeningConfiguratie {
			return {
				Mode: this.Mode,
				WoningId: this.WoningId,
				Scenario: this.Scenario,
				CameraIndex: this.CameraIndex,
				Invalshoek: this.Invalshoek,
				TekeningNaam: this.TekeningNaam,
				GebruikEditView: this.GebruikEditView,
				AchtergrondKleur: this.AchtergrondKleur,
				OverruleHoogte: this.OverruleHoogte,
				OverruleBreedte: this.OverruleBreedte,
				Navigeerbaar: this.Navigeerbaar,
				ProjectId: this.ProjectId,
				MeldingId: this.MeldingId,
				Scope: this.Scope
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnect.DataTransfer.Configurator.TekeningConfiguratieResult
	 */
	 

	export interface ITekeningConfiguratieResult {
		Id: string;
		Gelukt: boolean;
	}

	export class TekeningConfiguratieResult {
		// property-Properties
		public get Id(): string {
			return this._Id;
		}
		public set Id(newId: string) {
			this._Id = newId;
		}

		public get Gelukt(): boolean {
			return this._Gelukt;
		}
		public set Gelukt(newGelukt: boolean) {
			this._Gelukt = newGelukt;
		}

		// field-Properties

		// fields
		protected _Id: string = "";
		protected _Gelukt: boolean = false;

		constructor(data?: DataContracts.ITekeningConfiguratieResult) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ITekeningConfiguratieResult): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Gelukt = data.Gelukt;
				// vul fields
			}
		}
	}
