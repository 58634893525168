/// <reference path='../consumerinterfaces/LogonController.ts' />
/**
 * @ref WoonConnectApiController/Account/LogonController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/LogonController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as LoginScreenSettingsModel from "@datacontracts/LoginScreenSettingsModel";
import * as CapabilitiesModel from "@datacontracts/CapabilitiesModel";
import * as LogonModel from "@datacontracts/LogonModel";
import * as LogonStartInformationModel from "@datacontracts/LogonStartInformationModel";
import * as request from "../../../helpers/IRequestHandler";





	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class LogonConsumer implements Interface.ILogonConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public LoginScreenSettings_Observable(inputModel: LoginScreenSettingsModel.ILoginScreenSettingsRequestModel & {ToJsonContract(noContent?: boolean): LoginScreenSettingsModel.ILoginScreenSettingsRequestModel}): Observable<LoginScreenSettingsModel.ILoginScreenSettingsResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/Logon/LoginScreenSettings`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<LoginScreenSettingsModel.ILoginScreenSettingsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public LoginScreenSettings(inputModel: LoginScreenSettingsModel.ILoginScreenSettingsRequestModel & {ToJsonContract(noContent?: boolean): LoginScreenSettingsModel.ILoginScreenSettingsRequestModel}): ng.IPromise<LoginScreenSettingsModel.ILoginScreenSettingsResponseModel> {
				let obs = this.LoginScreenSettings_Observable(inputModel);
				let promise = new Promise<LoginScreenSettingsModel.ILoginScreenSettingsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public Capabilities_Observable(inputModel: CapabilitiesModel.ICapabilitiesRequestModel & {ToJsonContract(noContent?: boolean): CapabilitiesModel.ICapabilitiesRequestModel}): Observable<CapabilitiesModel.ICapabilitiesResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/Logon/Capabilities`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<CapabilitiesModel.ICapabilitiesResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Capabilities(inputModel: CapabilitiesModel.ICapabilitiesRequestModel & {ToJsonContract(noContent?: boolean): CapabilitiesModel.ICapabilitiesRequestModel}): ng.IPromise<CapabilitiesModel.ICapabilitiesResponseModel> {
				let obs = this.Capabilities_Observable(inputModel);
				let promise = new Promise<CapabilitiesModel.ICapabilitiesResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public Logon_Observable(input2Model: LogonModel.ILogonRequestModel & {ToJsonContract(noContent?: boolean): LogonModel.ILogonRequestModel}): Observable<LogonModel.ILogonResponseModel> {
				const input2 = input2Model.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input2;
				let apiURL = `api/Logon/Logon`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<LogonModel.ILogonResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public Logon(input2Model: LogonModel.ILogonRequestModel & {ToJsonContract(noContent?: boolean): LogonModel.ILogonRequestModel}): ng.IPromise<LogonModel.ILogonResponseModel> {
				let obs = this.Logon_Observable(input2Model);
				let promise = new Promise<LogonModel.ILogonResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public LoginStartInformation_Observable(input2Model: LogonStartInformationModel.ILogonStartInformationRequestModel & {ToJsonContract(noContent?: boolean): LogonStartInformationModel.ILogonStartInformationRequestModel}): Observable<LogonStartInformationModel.ILogonStartInformationResponseModel> {
				const input2 = input2Model.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input2;
				let apiURL = `api/Logon/LoginStartInformation`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<LogonStartInformationModel.ILogonStartInformationResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public LoginStartInformation(input2Model: LogonStartInformationModel.ILogonStartInformationRequestModel & {ToJsonContract(noContent?: boolean): LogonStartInformationModel.ILogonStartInformationRequestModel}): ng.IPromise<LogonStartInformationModel.ILogonStartInformationResponseModel> {
				let obs = this.LoginStartInformation_Observable(input2Model);
				let promise = new Promise<LogonStartInformationModel.ILogonStartInformationResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefDisclaimerTekst_Observable(): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Logon/GeefDisclaimer`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefDisclaimerTekst(): ng.IPromise<string> {
				let obs = this.GeefDisclaimerTekst_Observable();
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefOpdrachtgever_Observable(): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Logon/GeefOpdrachtgever`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefOpdrachtgever(): ng.IPromise<string> {
				let obs = this.GeefOpdrachtgever_Observable();
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}
