/**
 * @ref WoonConnectViewModel/Survey/SurveyBuilding.cs
 */

 import * as DataContracts from "@datacontracts/SurveyBuilding";

 
import * as SurveyProperty from "./SurveyProperty";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.SurveyBuilding
	 */
	 

	export interface ISurveyBuilding {
		BAGResidenceId: SurveyProperty.ISurveyProperty;
		BAGBuildingId: SurveyProperty.ISurveyProperty;
		BuildingZipCode: SurveyProperty.ISurveyProperty;
		BuildingNumber: SurveyProperty.ISurveyProperty;
		BuildingConstructionYear: SurveyProperty.ISurveyProperty;
		BuildingRenovationYear: SurveyProperty.ISurveyProperty;
		ToJsonContract(): DataContracts.ISurveyBuilding;
	}

	export class SurveyBuilding {
		// property-Properties
		public get BAGResidenceId(): SurveyProperty.ISurveyProperty {
			return this._BAGResidenceId;
		}
		public set BAGResidenceId(newBAGResidenceId: SurveyProperty.ISurveyProperty) {
			this._BAGResidenceId = newBAGResidenceId;
		}

		public get BAGBuildingId(): SurveyProperty.ISurveyProperty {
			return this._BAGBuildingId;
		}
		public set BAGBuildingId(newBAGBuildingId: SurveyProperty.ISurveyProperty) {
			this._BAGBuildingId = newBAGBuildingId;
		}

		public get BuildingZipCode(): SurveyProperty.ISurveyProperty {
			return this._BuildingZipCode;
		}
		public set BuildingZipCode(newBuildingZipCode: SurveyProperty.ISurveyProperty) {
			this._BuildingZipCode = newBuildingZipCode;
		}

		public get BuildingNumber(): SurveyProperty.ISurveyProperty {
			return this._BuildingNumber;
		}
		public set BuildingNumber(newBuildingNumber: SurveyProperty.ISurveyProperty) {
			this._BuildingNumber = newBuildingNumber;
		}

		public get BuildingConstructionYear(): SurveyProperty.ISurveyProperty {
			return this._BuildingConstructionYear;
		}
		public set BuildingConstructionYear(newBuildingConstructionYear: SurveyProperty.ISurveyProperty) {
			this._BuildingConstructionYear = newBuildingConstructionYear;
		}

		public get BuildingRenovationYear(): SurveyProperty.ISurveyProperty {
			return this._BuildingRenovationYear;
		}
		public set BuildingRenovationYear(newBuildingRenovationYear: SurveyProperty.ISurveyProperty) {
			this._BuildingRenovationYear = newBuildingRenovationYear;
		}

		// field-Properties

		// fields
		protected _BAGResidenceId: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _BAGBuildingId: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _BuildingZipCode: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _BuildingNumber: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _BuildingConstructionYear: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);
		protected _BuildingRenovationYear: SurveyProperty.ISurveyProperty = new SurveyProperty.SurveyProperty(undefined);

		constructor(data?: DataContracts.ISurveyBuilding) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISurveyBuilding): void {
			if(data) {
				// vul properties
				this._BAGResidenceId = data.BAGResidenceId ? new SurveyProperty.SurveyProperty(data.BAGResidenceId): undefined;
				this._BAGBuildingId = data.BAGBuildingId ? new SurveyProperty.SurveyProperty(data.BAGBuildingId): undefined;
				this._BuildingZipCode = data.BuildingZipCode ? new SurveyProperty.SurveyProperty(data.BuildingZipCode): undefined;
				this._BuildingNumber = data.BuildingNumber ? new SurveyProperty.SurveyProperty(data.BuildingNumber): undefined;
				this._BuildingConstructionYear = data.BuildingConstructionYear ? new SurveyProperty.SurveyProperty(data.BuildingConstructionYear): undefined;
				this._BuildingRenovationYear = data.BuildingRenovationYear ? new SurveyProperty.SurveyProperty(data.BuildingRenovationYear): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISurveyBuilding {
			return {
				BAGResidenceId: (this.BAGResidenceId ? new SurveyProperty.SurveyProperty(this.BAGResidenceId).ToJsonContract(): this.BAGResidenceId),
				BAGBuildingId: (this.BAGBuildingId ? new SurveyProperty.SurveyProperty(this.BAGBuildingId).ToJsonContract(): this.BAGBuildingId),
				BuildingZipCode: (this.BuildingZipCode ? new SurveyProperty.SurveyProperty(this.BuildingZipCode).ToJsonContract(): this.BuildingZipCode),
				BuildingNumber: (this.BuildingNumber ? new SurveyProperty.SurveyProperty(this.BuildingNumber).ToJsonContract(): this.BuildingNumber),
				BuildingConstructionYear: (this.BuildingConstructionYear ? new SurveyProperty.SurveyProperty(this.BuildingConstructionYear).ToJsonContract(): this.BuildingConstructionYear),
				BuildingRenovationYear: (this.BuildingRenovationYear ? new SurveyProperty.SurveyProperty(this.BuildingRenovationYear).ToJsonContract(): this.BuildingRenovationYear)
			}
		}
	}
