import { Component, OnInit } from '@angular/core';
import { FabrikantenConsumer } from '../../../../../data/WoonConnectKern/Consumers/FabrikantenController';
import { FabrikantenCMSModel, FabrikantenInlogRequestModel } from '../../../../../data/WoonConnectKern/Models/FabrikantFilterModel';

@Component({
  selector: 'app-beheer',
  templateUrl: './beheer.component.html',
  styleUrls: ['./beheer.component.scss']
})
export class BeheerComponent implements OnInit {

  public LoggedIn: boolean = false;
  public Loading: boolean = false;

  public UserName: string = "";
  public Password: string = "";

  public Foto1: any;
  public Foto2: any;

  public FabrikantenCMSModel: FabrikantenCMSModel;

  constructor(private fab: FabrikantenConsumer) { }

  ngOnInit(): void {
    //this.LoadCMS();
  }

  public Login(): void {

    this.Loading = true;

    var model = new FabrikantenInlogRequestModel();

    model.Name = this.UserName;
    model.Password = this.Password;

    this.fab.Login_Observable(model).subscribe((data) => {
      if (data.Success) {
        this.LoggedIn = true;
        this.LoadCMS();
      }
      else {
        this.Loading = false;
        alert("Username/password incorrect");
      }
    });
  }

  public LoadCMS(): void {

    this.Loading = true;

    this.fab.GetCMS_Observable().subscribe((data) => {
      this.FabrikantenCMSModel = new FabrikantenCMSModel(data);

      if (this.FabrikantenCMSModel.Image1 != "" && this.FabrikantenCMSModel.Image1 != undefined) {
        this.Foto1 = this.FabrikantenCMSModel.Image1;
      }

      if (this.FabrikantenCMSModel.Image2 != "" && this.FabrikantenCMSModel.Image2 != undefined) {
        this.Foto2 = this.FabrikantenCMSModel.Image2;
      }

      this.Loading = false;
    });
  }

  public SaveCMS(): void {

    this.Loading = true;

    if (this.Foto1) {
      this.FabrikantenCMSModel.Image1 = this.Foto1;
    }

    if (this.Foto2) {
      this.FabrikantenCMSModel.Image2 = this.Foto2;
    }

    this.fab.SetCMS_Observable(this.FabrikantenCMSModel).subscribe(() => {
      this.Loading = false;
    });
  }

  public onFileSelected1(event) {

    const file: File = event.target.files[0];

    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (_event) => {
        this.Foto1 = reader.result;
      }
    }
  }

  public onFileSelected2(event) {

    const file: File = event.target.files[0];

    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (_event) => {
        this.Foto2 = reader.result;
      }
    }
  }
}
