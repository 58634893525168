/**
 * @ref WoonConnectViewModel/Login/LogonModel.cs
 */

 import * as DataContracts from "@datacontracts/LogonModel";

 
import * as LogonStatus from "./LogonStatus";



	/**
	 * Originele namespace: WoonConnectViewModel.Login.LogonRequestModel
	 */
	 

	export interface ILogonRequestModel {
		Username: string;
		Password: string;
		RememberMe: boolean;
		ToJsonContract(): DataContracts.ILogonRequestModel;
	}

	export class LogonRequestModel {
		// property-Properties
		public get Username(): string {
			return this._Username;
		}
		public set Username(newUsername: string) {
			this._Username = newUsername;
		}

		public get Password(): string {
			return this._Password;
		}
		public set Password(newPassword: string) {
			this._Password = newPassword;
		}

		public get RememberMe(): boolean {
			return this._RememberMe;
		}
		public set RememberMe(newRememberMe: boolean) {
			this._RememberMe = newRememberMe;
		}

		// field-Properties

		// fields
		protected _Username: string = "";
		protected _Password: string = "";
		protected _RememberMe: boolean = false;

		constructor(data?: DataContracts.ILogonRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ILogonRequestModel): void {
			if(data) {
				// vul properties
				this._Username = data.Username;
				this._Password = data.Password;
				this._RememberMe = data.RememberMe;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ILogonRequestModel {
			return {
				Username: this.Username,
				Password: this.Password,
				RememberMe: this.RememberMe
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Login.LogonResponseModel
	 */
	 

	export interface ILogonResponseModel {
		LogonStatus: LogonStatus.ILogonStatus;
		Token: string;
	}

	export class LogonResponseModel {
		// property-Properties
		// field-Properties
		public get LogonStatus(): LogonStatus.ILogonStatus {
			return this._LogonStatus;
		}
		public set LogonStatus(newLogonStatus: LogonStatus.ILogonStatus) {
			this._LogonStatus = newLogonStatus;
		}
		
		public get Token(): string {
			return this._Token;
		}
		public set Token(newToken: string) {
			this._Token = newToken;
		}

		// fields
		protected _LogonStatus: LogonStatus.ILogonStatus = new LogonStatus.LogonStatus(undefined);
		protected _Token: string = "";

		constructor(data?: DataContracts.ILogonResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ILogonResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._LogonStatus = data.LogonStatus ? new LogonStatus.LogonStatus(data.LogonStatus): undefined;
				this._Token = data.Token;
			}
		}
	}
