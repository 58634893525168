// [conv] import "(./WoonAdmin).less";
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Component({
    selector: 'wtc-admin',
  templateUrl: './WoonAdmin.html'
})
export class WoonAdminComponent {
    Header: string;
    previousUrl: string;
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private _location: Location) {
        router.events
            .pipe(filter(
                (evt: any) => evt instanceof RoutesRecognized), pairwise())
            .subscribe((events: RoutesRecognized[]) => {
                console.log('prev', events[0].urlAfterRedirects);
                this.previousUrl = events[0].urlAfterRedirects;
            })
        console.log("Admin Components ");

    }

    backClicked() {
        this._location.back();
    }



}
