import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: 'wct-disclaimer-dialog',
  styleUrls: ['./WctDisclaimerDialog.scss'],
	templateUrl: './WctDisclaimerDialog.html'
})

export class WctDisclaimerDialog {
	constructor(
		public dialogRef: MatDialogRef<WctDisclaimerDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		'ngInject';
	}

	Close() {
		this.dialogRef.close();
	}
}
