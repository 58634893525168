/**
 * @ref WoonConnectViewModel/PriceSetViewModel.cs
 */

 import * as DataContracts from "@datacontracts/PriceSetViewModel";

 
import * as HourlyTarifSetViewModel from "./HourlyTarifSetViewModel";
import * as MeasureViewModel from "./MeasureViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.PriceSetRequestModel
	 */
	 

	export interface IPriceSetRequestModel {
		Temp: number;
		ToJsonContract(): DataContracts.IPriceSetRequestModel;
	}

	export class PriceSetRequestModel {
		// property-Properties
		public get Temp(): number {
			return this._Temp;
		}
		public set Temp(newTemp: number) {
			this._Temp = newTemp;
		}

		// field-Properties

		// fields
		protected _Temp: number = 0;

		constructor(data?: DataContracts.IPriceSetRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPriceSetRequestModel): void {
			if(data) {
				// vul properties
				this._Temp = data.Temp;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPriceSetRequestModel {
			return {
				Temp: this.Temp
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.PriceSetsViewModel
	 */
	 

	export interface IPriceSetsViewModel {
		PriceSets: PriceSetViewModel[];
		ToJsonContract(): DataContracts.IPriceSetsViewModel;
	}

	export class PriceSetsViewModel {
		// property-Properties
		public get PriceSets(): PriceSetViewModel[] {
			return this._PriceSets;
		}
		public set PriceSets(newPriceSets: PriceSetViewModel[]) {
			this._PriceSets = newPriceSets;
		}

		// field-Properties

		// fields
		protected _PriceSets: PriceSetViewModel[] = [];

		constructor(data?: DataContracts.IPriceSetsViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPriceSetsViewModel): void {
			if(data) {
				// vul properties
				this._PriceSets = data.PriceSets ? data.PriceSets.map(priceSetViewModelItem => new PriceSetViewModel(priceSetViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPriceSetsViewModel {
			return {
				PriceSets: (this.PriceSets ? this.PriceSets.map(priceSetViewModelItem => new PriceSetViewModel(priceSetViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.PriceSetViewModel
	 */
	 

	export interface IPriceSetViewModel {
		Id: number;
		Name: string;
		Version: number;
		ReadOnly: boolean;
		ContractorId: number;
		HourlyTarifSetViewModel: HourlyTarifSetViewModel.IHourlyTarifSetViewModel;
		Measures: MeasureViewModel.IMeasureViewModel[];
		ToJsonContract(): DataContracts.IPriceSetViewModel;
	}

	export class PriceSetViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Version(): number {
			return this._Version;
		}
		public set Version(newVersion: number) {
			this._Version = newVersion;
		}

		public get ReadOnly(): boolean {
			return this._ReadOnly;
		}
		public set ReadOnly(newReadOnly: boolean) {
			this._ReadOnly = newReadOnly;
		}

		public get ContractorId(): number {
			return this._ContractorId;
		}
		public set ContractorId(newContractorId: number) {
			this._ContractorId = newContractorId;
		}

		public get HourlyTarifSetViewModel(): HourlyTarifSetViewModel.IHourlyTarifSetViewModel {
			return this._HourlyTarifSetViewModel;
		}
		public set HourlyTarifSetViewModel(newHourlyTarifSetViewModel: HourlyTarifSetViewModel.IHourlyTarifSetViewModel) {
			this._HourlyTarifSetViewModel = newHourlyTarifSetViewModel;
		}

		public get Measures(): MeasureViewModel.IMeasureViewModel[] {
			return this._Measures;
		}
		public set Measures(newMeasures: MeasureViewModel.IMeasureViewModel[]) {
			this._Measures = newMeasures;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _Version: number = 0;
		protected _ReadOnly: boolean = false;
		protected _ContractorId: number = 0;
		protected _HourlyTarifSetViewModel: HourlyTarifSetViewModel.IHourlyTarifSetViewModel = new HourlyTarifSetViewModel.HourlyTarifSetViewModel(undefined);
		protected _Measures: MeasureViewModel.IMeasureViewModel[] = [];

		constructor(data?: DataContracts.IPriceSetViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IPriceSetViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._Version = data.Version;
				this._ReadOnly = data.ReadOnly;
				this._ContractorId = data.ContractorId;
				this._HourlyTarifSetViewModel = data.HourlyTarifSetViewModel ? new HourlyTarifSetViewModel.HourlyTarifSetViewModel(data.HourlyTarifSetViewModel): undefined;
				this._Measures = data.Measures ? data.Measures.map(measureViewModelItem => new MeasureViewModel.MeasureViewModel(measureViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IPriceSetViewModel {
			return {
				Id: this.Id,
				Name: this.Name,
				Version: this.Version,
				ReadOnly: this.ReadOnly,
				ContractorId: this.ContractorId,
				HourlyTarifSetViewModel: (this.HourlyTarifSetViewModel ? new HourlyTarifSetViewModel.HourlyTarifSetViewModel(this.HourlyTarifSetViewModel).ToJsonContract(): this.HourlyTarifSetViewModel),
				Measures: (this.Measures ? this.Measures.map(measureViewModelItem => new MeasureViewModel.MeasureViewModel(measureViewModelItem).ToJsonContract()): [])
			}
		}
	}
