import { DataLoadMonitorService } from '../configurator/data-load-monitor.service';
import { NgModule,ModuleWithProviders  } from '@angular/core';

@NgModule({
	imports: [
	],
	exports: [
	],
	declarations: [
	],
	entryComponents: [
	],
	bootstrap: [
	],
	providers: [	
	]
})

export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
		  ngModule: SharedModule,
		  providers: [ DataLoadMonitorService ]
		}
	  }
}

