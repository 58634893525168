/**
 * @ref WoonConnectViewModel/Survey/DwellingSurvey.cs
 */

 import * as DataContracts from "@datacontracts/DwellingSurvey";

 
import * as SurveyGeneric from "./SurveyGeneric";
import * as SurveyBuilding from "./SurveyBuilding";
import * as SurveyCalculationZone from "./SurveyCalculationZone";
import * as SurveyRoof from "./SurveyRoof";
import * as SurveyWall from "./SurveyWall";
import * as SurveyWindow from "./SurveyWindow";
import * as SurveyFloor from "./SurveyFloor";
import * as SurveyClimateSystem from "./SurveyClimateSystem";
import * as SurveyFacade from "./SurveyFacade";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.DwellingSurvey
	 */
	 

	export interface IDwellingSurvey {
		Generic: SurveyGeneric.ISurveyGeneric;
		Building: SurveyBuilding.ISurveyBuilding;
		CalculationZones: SurveyCalculationZone.ISurveyCalculationZone[];
		Roofs: SurveyRoof.ISurveyRoof[];
		Walls: SurveyWall.ISurveyWall[];
		Windows: SurveyWindow.ISurveyWindow[];
		Floors: SurveyFloor.ISurveyFloor[];
		ClimateSystem: SurveyClimateSystem.ISurveyClimateSystem[];
		Facades: SurveyFacade.ISurveyFacade[];
		ToJsonContract(): DataContracts.IDwellingSurvey;
	}

	export class DwellingSurvey {
		// property-Properties
		public get Generic(): SurveyGeneric.ISurveyGeneric {
			return this._Generic;
		}
		public set Generic(newGeneric: SurveyGeneric.ISurveyGeneric) {
			this._Generic = newGeneric;
		}

		public get Building(): SurveyBuilding.ISurveyBuilding {
			return this._Building;
		}
		public set Building(newBuilding: SurveyBuilding.ISurveyBuilding) {
			this._Building = newBuilding;
		}

		public get CalculationZones(): SurveyCalculationZone.ISurveyCalculationZone[] {
			return this._CalculationZones;
		}
		public set CalculationZones(newCalculationZones: SurveyCalculationZone.ISurveyCalculationZone[]) {
			this._CalculationZones = newCalculationZones;
		}

		public get Roofs(): SurveyRoof.ISurveyRoof[] {
			return this._Roofs;
		}
		public set Roofs(newRoofs: SurveyRoof.ISurveyRoof[]) {
			this._Roofs = newRoofs;
		}

		public get Walls(): SurveyWall.ISurveyWall[] {
			return this._Walls;
		}
		public set Walls(newWalls: SurveyWall.ISurveyWall[]) {
			this._Walls = newWalls;
		}

		public get Windows(): SurveyWindow.ISurveyWindow[] {
			return this._Windows;
		}
		public set Windows(newWindows: SurveyWindow.ISurveyWindow[]) {
			this._Windows = newWindows;
		}

		public get Floors(): SurveyFloor.ISurveyFloor[] {
			return this._Floors;
		}
		public set Floors(newFloors: SurveyFloor.ISurveyFloor[]) {
			this._Floors = newFloors;
		}

		public get ClimateSystem(): SurveyClimateSystem.ISurveyClimateSystem[] {
			return this._ClimateSystem;
		}
		public set ClimateSystem(newClimateSystem: SurveyClimateSystem.ISurveyClimateSystem[]) {
			this._ClimateSystem = newClimateSystem;
		}

		public get Facades(): SurveyFacade.ISurveyFacade[] {
			return this._Facades;
		}
		public set Facades(newFacades: SurveyFacade.ISurveyFacade[]) {
			this._Facades = newFacades;
		}

		// field-Properties

		// fields
		protected _Generic: SurveyGeneric.ISurveyGeneric = new SurveyGeneric.SurveyGeneric(undefined);
		protected _Building: SurveyBuilding.ISurveyBuilding = new SurveyBuilding.SurveyBuilding(undefined);
		protected _CalculationZones: SurveyCalculationZone.ISurveyCalculationZone[] = [];
		protected _Roofs: SurveyRoof.ISurveyRoof[] = [];
		protected _Walls: SurveyWall.ISurveyWall[] = [];
		protected _Windows: SurveyWindow.ISurveyWindow[] = [];
		protected _Floors: SurveyFloor.ISurveyFloor[] = [];
		protected _ClimateSystem: SurveyClimateSystem.ISurveyClimateSystem[] = [];
		protected _Facades: SurveyFacade.ISurveyFacade[] = [];

		constructor(data?: DataContracts.IDwellingSurvey) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDwellingSurvey): void {
			if(data) {
				// vul properties
				this._Generic = data.Generic ? new SurveyGeneric.SurveyGeneric(data.Generic): undefined;
				this._Building = data.Building ? new SurveyBuilding.SurveyBuilding(data.Building): undefined;
				this._CalculationZones = data.CalculationZones ? data.CalculationZones.map(surveyCalculationZoneItem => new SurveyCalculationZone.SurveyCalculationZone(surveyCalculationZoneItem)): [];
				this._Roofs = data.Roofs ? data.Roofs.map(surveyRoofItem => new SurveyRoof.SurveyRoof(surveyRoofItem)): [];
				this._Walls = data.Walls ? data.Walls.map(surveyWallItem => new SurveyWall.SurveyWall(surveyWallItem)): [];
				this._Windows = data.Windows ? data.Windows.map(surveyWindowItem => new SurveyWindow.SurveyWindow(surveyWindowItem)): [];
				this._Floors = data.Floors ? data.Floors.map(surveyFloorItem => new SurveyFloor.SurveyFloor(surveyFloorItem)): [];
				this._ClimateSystem = data.ClimateSystem ? data.ClimateSystem.map(surveyClimateSystemItem => new SurveyClimateSystem.SurveyClimateSystem(surveyClimateSystemItem)): [];
				this._Facades = data.Facades ? data.Facades.map(surveyFacadeItem => new SurveyFacade.SurveyFacade(surveyFacadeItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDwellingSurvey {
			return {
				Generic: (this.Generic ? new SurveyGeneric.SurveyGeneric(this.Generic).ToJsonContract(): this.Generic),
				Building: (this.Building ? new SurveyBuilding.SurveyBuilding(this.Building).ToJsonContract(): this.Building),
				CalculationZones: (this.CalculationZones ? this.CalculationZones.map(surveyCalculationZoneItem => new SurveyCalculationZone.SurveyCalculationZone(surveyCalculationZoneItem).ToJsonContract()): []),
				Roofs: (this.Roofs ? this.Roofs.map(surveyRoofItem => new SurveyRoof.SurveyRoof(surveyRoofItem).ToJsonContract()): []),
				Walls: (this.Walls ? this.Walls.map(surveyWallItem => new SurveyWall.SurveyWall(surveyWallItem).ToJsonContract()): []),
				Windows: (this.Windows ? this.Windows.map(surveyWindowItem => new SurveyWindow.SurveyWindow(surveyWindowItem).ToJsonContract()): []),
				Floors: (this.Floors ? this.Floors.map(surveyFloorItem => new SurveyFloor.SurveyFloor(surveyFloorItem).ToJsonContract()): []),
				ClimateSystem: (this.ClimateSystem ? this.ClimateSystem.map(surveyClimateSystemItem => new SurveyClimateSystem.SurveyClimateSystem(surveyClimateSystemItem).ToJsonContract()): []),
				Facades: (this.Facades ? this.Facades.map(surveyFacadeItem => new SurveyFacade.SurveyFacade(surveyFacadeItem).ToJsonContract()): [])
			}
		}
	}
