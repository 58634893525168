import * as _ from 'lodash';
import * as TenderViewModels from '@models/TenderViewModel';
import * as TenderDwellingViewModels from '@models/TenderDwellingViewModel';
import * as ContractorOfferViewModels from '@models/ContractorOfferViewModel';
import * as ContractorQualityViewModels from '@models/ContractorQualityViewModel';
import * as ContractorQualityEvidenceViewModels from '@models/ContractorQualityEvidenceViewModel';
import * as MeasureViewModel from "@models/MeasureViewModel";
import * as EfficacyViewModel from "@models/EfficacyViewModel";
import * as HourlyTarifSetViewModels from '@models/HourlyTarifSetViewModel';
import * as HourlyTarifViewModels from '@models/HourlyTarifViewModel';
import * as HourlyTarifKindViewModels from '@models/HourlyTarifKindViewModel';
import * as PriceSetViewModels from '@models/PriceSetViewModel';
import * as HelpersAngularEvent from '../../Helpers/AngularEvent';
import { Inject, Injectable, Output, EventEmitter } from '@angular/core';
import { TenderRequestViewModel, ITenderRequestViewModel } from '@models/TenderRequestViewModel';
import { DwellingCostViewModel } from '@models/DwellingCostViewModel';
import { TenderCostViewModel } from '@models/TenderCostViewModel';
import { ContractorAllViewModel, ContractorRequestAllModel, ContractorViewModel, IContractorAllViewModel, IContractorRequestAllModel, IContractorViewModel } from '@models/ContractorViewModel';
import { ITenderAPIConsumer } from '@consumerinterfaces/TenderController';
import { TenderAPIConsumer } from '@consumers/TenderController';
import { IContractorConsumer } from '@consumerinterfaces/ContractorController';
import { ContractorConsumer } from '@consumers/ContractorController';
import { IPriceSetConsumer } from '@consumerinterfaces/PriceSetController';
import { PriceSetConsumer } from '@consumers/PriceSetController';
import { AccountService } from '../../services/AccountService';
import { Router, ActivatedRoute } from '@angular/router';
import { IAccountService } from '../../services/AccountService';

@Injectable({
   providedIn: 'root',
})

export class ContractorService {
   public ContractorOffersRefreshedEvent: HelpersAngularEvent.AngularEvent<HelpersAngularEvent.AngularEventArg<void>>;
   private ContractorOffersRefreshedEventEmitter: HelpersAngularEvent.IAngularEventEmitter<HelpersAngularEvent.AngularEventArg<void>>;

   private mContractorOffers: Array<ContractorOfferViewModels.ContractorOfferViewModel> = new Array<ContractorOfferViewModels.ContractorOfferViewModel>();
   private mIsLoadingContractorOffers: boolean = true;

   public SelectedContractorOffer: ContractorOfferViewModels.ContractorOfferViewModel = null;
   public SelectedContractorOfferId: number = -1;
   public SelectedPriceSetId: number = -1;

   public SubContractorsInvite: string = "Nee";
   public CurrentContractor: ContractorViewModel = null;
   public mIsLoadingCurrentContractor: boolean = true;

   public PriceSetLoadingActive: boolean = true;
   public PriceSetsViewModel: PriceSetViewModels.PriceSetsViewModel;
   public PriceSetsViewModelBackup: PriceSetViewModels.PriceSetsViewModel;

   public CurrentContractorQualities: ContractorQualityViewModels.ContractorQualitiesViewModel = null;
   private mIsLoadingCurrentContractorQualities: boolean = true;

   public ActiveTab: string = "prijsopbouw";
   public SelectedPriceSetToRemoveOrRestore: PriceSetViewModels.PriceSetViewModel = null;

   constructor(
      @Inject(TenderAPIConsumer) private tenderapiconsumer: ITenderAPIConsumer,
      @Inject(ContractorConsumer) private contractorconsumer: IContractorConsumer,
      @Inject(AccountService) private accountservice: IAccountService,
      @Inject(PriceSetConsumer) private pricesetconsumer: IPriceSetConsumer,
      private router: Router,
      private route: ActivatedRoute
   ) {
      'ngInject';

      this.ContractorOffersRefreshedEventEmitter = HelpersAngularEvent.AngularEvent.Default<HelpersAngularEvent.AngularEventArg<void>>("ContractorService-offers-refreshed-event", null);
      this.LoadContractorOffers();
      this.LoadCurrentContractor();
      this.LoadPriceSets();
   }

   public get GetContractorOffersRefreshedEvent(): HelpersAngularEvent.AngularEvent<HelpersAngularEvent.AngularEventArg<void>> {
      return this.ContractorOffersRefreshedEventEmitter.Event;
   }

   public GetPriceSets(): PriceSetViewModels.PriceSetViewModel[] {
      if (this.PriceSetsViewModel != null) {
         return this.PriceSetsViewModel.PriceSets;
      }
      else {
         return new Array<PriceSetViewModels.PriceSetViewModel>();
      }
   }

   public GetContractorOffers(): Array<ContractorOfferViewModels.ContractorOfferViewModel> {
      return this.mContractorOffers;
   }

   public SetSelectedContractorOffer(model: ContractorOfferViewModels.ContractorOfferViewModel): void {
      this.SelectedContractorOffer = model;

      this.UpdateSelectedContractorOffer();
   }

   public SetSelectedContractorOfferById(): void {
      var found = false;

      _.forEach(this.mContractorOffers, (contractoroffer: ContractorOfferViewModels.ContractorOfferViewModel) => {
         if (contractoroffer.Id == this.SelectedContractorOfferId) {
            this.SelectedContractorOffer = contractoroffer;
            found = true;
         }
      });

      if (!found) {
         this.SelectedContractorOffer = null;
      }

      this.UpdateSelectedContractorOffer();
   }

   public AddPriceSet(): void {
      this.PriceSetLoadingActive = true;

      this.pricesetconsumer.GetHourlyTarifKinds().then(d => {
         var priceset = new PriceSetViewModels.PriceSetViewModel();
         priceset.Name = "Prijsset " + (this.PriceSetsViewModel.PriceSets.length + 1);
         priceset.HourlyTarifSetViewModel = new HourlyTarifSetViewModels.HourlyTarifSetViewModel();

         for (var i = 0; i < d.HourlyTarifKinds.length; i++) {
            var hourlytarif = new HourlyTarifViewModels.HourlyTarifViewModel();

            hourlytarif.Cost.ExcludingVat = 0;
            hourlytarif.Cost.IncludingVat = 0;

            hourlytarif.HourlyTarifKind = new HourlyTarifKindViewModels.HourlyTarifKindViewModel(d.HourlyTarifKinds[i]);

            priceset.HourlyTarifSetViewModel.Tarifs.push(hourlytarif);
         }

         this.SavePriceSet(priceset);
      });
   }

   public SaveSelectedContractorOffer(): void {
      if (this.SelectedContractorOffer != null) {
         this.SaveContractorOffer(this.SelectedContractorOffer);
      }
   }

   public SaveContractorOffer(contractoroffer: ContractorOfferViewModels.ContractorOfferViewModel): void {
      this.mIsLoadingContractorOffers = true;
      this.tenderapiconsumer.ChangeContractorOfferStatus(contractoroffer).then(d => {
         this.LoadContractorOffers();
      }).catch((error) => {
         alert(error);
      });
   }

   public SaveCurrentContractor(): void {
      if (this.CurrentContractor != null) {
         this.SaveContractor(this.CurrentContractor);
      }
   }

   public SaveContractor(contractor: ContractorViewModel): void {
      this.mIsLoadingCurrentContractor = true;
      this.contractorconsumer.SaveCurrentContractor(contractor).then(d => {
         this.LoadCurrentContractor();
      }).catch((error) => {
         alert(error);
      });
   }

   //public GetCurrentContractorEvidence(qnm: ContractorQualityViewModels.ContractorQualityViewModel): Array<ContractorQualityEvidenceViewModels.IContractorQualityEvidenceViewModel> {
   //   var evidences = new Array<ContractorQualityEvidenceViewModels.IContractorQualityEvidenceViewModel>();

   //   if (this.CurrentContractor != null) {
   //      for (var i = 0; i < this.CurrentContractor.QualityEvidences.length; i++) {
   //         if (this.CurrentContractor.QualityEvidences[i].Quality.Id == qnm.Id) {
   //            evidences.push(this.CurrentContractor.QualityEvidences[i]);
   //         }
   //      }
   //   }

   //   return evidences;
   //}

   public IsLoading(): boolean {
      return this.PriceSetLoadingActive || this.mIsLoadingContractorOffers || this.mIsLoadingCurrentContractor || this.mIsLoadingCurrentContractorQualities;
   }

   public NavigateToTab(tab: string) {
      let urlWithoutParams: string = '/contractor/offer/';
      if (tab !== null) {
         this.router.navigate(
            [urlWithoutParams + this.SelectedContractorOfferId + '/' + tab],
            {
               relativeTo: this.route,
               queryParamsHandling: 'merge', // remove to replace all query params by provided
            }
         );
      }
   }

   public UpdatePriceSet(): void {
      if (this.SelectedContractorOffer != undefined) {
         if (this.SelectedPriceSetId != undefined) {
            var tPriceSet;

            for (var i = 0; i < this.PriceSetsViewModel.PriceSets.length; i++) {
               if (this.PriceSetsViewModel.PriceSets[i].Id == this.SelectedPriceSetId) {
                  tPriceSet = this.PriceSetsViewModel.PriceSets[i];
               }
            }

            if (this.SelectedContractorOffer.PriceSet != tPriceSet) {
               this.SelectedContractorOffer.PriceSet = tPriceSet;

               this.SaveSelectedContractorOffer();
            }
         }
      }
   }

   public RemovePriceSet(priceset: PriceSetViewModels.PriceSetViewModel): void {
      this.PriceSetLoadingActive = true;

      this.pricesetconsumer.RemovePriceSet(priceset).then(() => {
         this.LoadPriceSets();
      });
   }

   public SavePriceSet(priceset: PriceSetViewModels.PriceSetViewModel): void {
      this.PriceSetLoadingActive = true;

      this.pricesetconsumer.SavePriceSet(priceset).then(() => {
         this.LoadPriceSets();
         this.LoadContractorOffers();
      });
   }

   public SetSelectedPriceSetToRemoveOrRestore(model: PriceSetViewModels.PriceSetViewModel): void {
      this.SelectedPriceSetToRemoveOrRestore = model;
   } 

   public RestorePriceSet(model: PriceSetViewModels.PriceSetViewModel): void {
      var t = "test";
      _.forEach(this.PriceSetsViewModelBackup.PriceSets, priceset => {
         if (priceset.Id == model.Id) {
            this.SavePriceSet(priceset);
            this.LoadPriceSets();
         }
      });
   }

   private LoadPriceSets(): void {
      this.PriceSetLoadingActive = true;
      var request: PriceSetViewModels.PriceSetRequestModel = new PriceSetViewModels.PriceSetRequestModel();

      this.pricesetconsumer.GetPriceSets(request).then(d => {
         var pricesetsviewmodel = new PriceSetViewModels.PriceSetsViewModel(d);

         if (this.PriceSetsViewModel != undefined) {
            _.each(pricesetsviewmodel.PriceSets, (priceset: PriceSetViewModels.PriceSetViewModel) => {
               var pricesetfound = _.find(this.PriceSetsViewModel.PriceSets, (p: PriceSetViewModels.PriceSetViewModel) => p.Id === priceset.Id);

               if (pricesetfound != undefined) {
                  _.each(priceset.Measures, (measure: MeasureViewModel.IMeasureViewModel) => {
                     var measurefound = _.find(pricesetfound.Measures, (m: MeasureViewModel.IMeasureViewModel) => m.Id === measure.Id);

                     if (measurefound != undefined) {
                        measure.IsExpanded = measurefound.IsExpanded;

                        _.each(measure.Efficacies, (efficacy: EfficacyViewModel.IEfficacyViewModel) => {
                           var efficacyfound = _.find(measurefound.Efficacies, (e: EfficacyViewModel.IEfficacyViewModel) => e.Id === efficacy.Id);

                           if (efficacyfound != undefined) {
                              efficacy.IsExpanded = efficacyfound.IsExpanded;
                           }
                        });
                     }
                  });
               }
            });
         }

         this.PriceSetsViewModel = pricesetsviewmodel;

         if (this.PriceSetsViewModelBackup == undefined) {
            this.PriceSetsViewModelBackup = new PriceSetViewModels.PriceSetsViewModel(d);
         }     
         this.PriceSetLoadingActive = false;
      });
   }

   public ResetPriceSet(): void {
      this.PriceSetsViewModelBackup = undefined;
      this.LoadPriceSets();
   }

   private LoadContractorOffers(): void {
      var request = new ContractorOfferViewModels.ContractorOffersRequestModel();
      this.mIsLoadingContractorOffers = true;

      this.contractorconsumer.GetContractorOffers(request).then(d => {
         var contractoroffersviewmodel = new ContractorOfferViewModels.ContractorOffersViewModel(d);

         this.mContractorOffers = contractoroffersviewmodel.Offers;

         this.UpdateSelectedContractorOffer();

         this.mIsLoadingContractorOffers = false;
      });
   }

   private UpdateSelectedContractorOffer(): void {
      if (this.mContractorOffers.length > 0) {
         let tFound = false;

         if (this.SelectedContractorOffer != null) {
            for (var i = 0; i < this.mContractorOffers.length; i++) {
               if (this.SelectedContractorOffer.Id == this.mContractorOffers[i].Id) {
                  this.SelectedContractorOffer = this.mContractorOffers[i];
                  tFound = true;
               }
            }
         }

         if (!tFound) {
            this.SelectedContractorOffer = this.mContractorOffers[0];
         }
      }
      else {
         this.SelectedContractorOffer = null;
      }

      if (this.SelectedContractorOffer == null) {
         this.SelectedContractorOfferId = -1;
      }
      else {
         this.SelectedContractorOfferId = this.SelectedContractorOffer.Id;
      }

      if (this.SelectedContractorOffer != undefined && this.SelectedContractorOffer.PriceSet != undefined) {
         this.SelectedPriceSetId = this.SelectedContractorOffer.PriceSet.Id;
      }
      else {
         this.SelectedPriceSetId = -1;
      }

      this.ContractorOffersRefreshedEventEmitter.Emitter.BroadCast(HelpersAngularEvent.AngularEventArg.WithData(null, true, 0));
   }

   private LoadCurrentContractor(): void {
      var request = new ContractorRequestAllModel();
      this.mIsLoadingCurrentContractor = true;

      this.contractorconsumer.GetCurrentContractor(request).then(d => {
         var contractorrequestviewmodel = new ContractorViewModel(d);

         this.CurrentContractor = contractorrequestviewmodel;

         if (this.CurrentContractor.SubContractors.length > 0) {
            this.SubContractorsInvite = "Ja";
         }
         else {
            this.SubContractorsInvite = "Nee";
         }

         this.LoadCurrentContractorQualities();

         this.mIsLoadingCurrentContractor = false;
      });
   }

   private LoadCurrentContractorQualities(): void {
      var request = new ContractorQualityViewModels.ContractorQualitiesRequestModel();
      this.mIsLoadingCurrentContractorQualities = true;

      this.contractorconsumer.GetContractorQualities(request).then(d => {
         this.CurrentContractorQualities = new ContractorQualityViewModels.ContractorQualitiesViewModel(d);

         this.mIsLoadingCurrentContractorQualities = false;
      });
   }
}
