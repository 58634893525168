<div class="content">
  <!--<div class="splash">
  </div>-->
  <div class="panel" style="padding-top: 10px; padding-bottom: 10px;">
    <h1>Welkom bij de HOPPE Bestekservice.</h1>

    <p>
      Stel met deze bestekservice snel en eenvoudig uw eigen bestek samen.  Geef uw wensen en eisen aan. Het resultaat is het product dat het beste past bij uw criteria.
    </p>
    <p>
      Kies één van onderstaande categorieën en start met het samenstellen van uw bestek.
    </p>
  </div>
  <div *ngIf="Loading" class="loading-block">
    <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
  </div>
  <div class="category-grid" *ngIf="!Loading">
    <div *ngFor="let filteroption of GetCategories().Options">
      <a routerLink="/categorie/{{ filteroption.Id }}" [ngStyle]="{backgroundImage:'url(\'/Content/KlantSpecifiek/BCB20343/category-shadow.png\'), url(\''+  filteroption.Image + '\')'}" >
        <p class="title" [innerHTML]="filteroption.Name">
        </p>
        <p class="description">
          {{ filteroption.Description }}
        </p>
        <p class="compose">
          Bestek samenstellen
        </p>
      </a>
    </div>
  </div>
</div>
