/**
 * @ref WoonConnect/DataTransfer/Configurator/TekeningConfiguratie.cs
 */
	/**
	* Originele namespace: WoonConnect.DataTransfer.Configurator.TekeningConfiguratieMode
	*/
	export enum TekeningConfiguratieMode {
		Normaal = 0,
		
		Massa = 1,
		
		MassaMetRuimten = 2
	}

