import * as _ from 'lodash';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { FabrikantenFilterResponseModel } from '@models/FabrikantFilterModel';
import { FilterViewModel } from '@models/FilterViewModel';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent {
  public ShowCallToAction: boolean = false;
  public Loading: boolean = true;
  public FabrikantenFilterResponseModel: FabrikantenFilterResponseModel;

  constructor(private fab: FabrikantenConsumer, public router: Router) {
    this.FabrikantenFilterResponseModel = new FabrikantenFilterResponseModel();
    this.LoadFilters();
  }

  public GetCategories(): FilterViewModel {
    var filter = null;

    _.forEach(this.FabrikantenFilterResponseModel.Filters, filterTemp => {
      if (filterTemp.Name == "Categorie") {
        filter = filterTemp;
      }
    });

    return filter;
  }

  public IsUrlActive(url: string): boolean {
    return this.router.url.indexOf(url) != -1;
  }

  private LoadFilters(): void {
    this.Loading = true;

    this.fab.FilterOptions_Observable(this.FabrikantenFilterResponseModel).subscribe((data) => {
      this.FabrikantenFilterResponseModel = new FabrikantenFilterResponseModel(data);

      this.Loading = false;
    });
  }

}
