/**
 * @ref WoonConnectViewModel/ImageAnnotation/GetProjectsFromUserModel.cs
 */

 import * as DataContracts from "@datacontracts/GetProjectsFromUserModel";

 
import * as GetAllProjectsModel from "./GetAllProjectsModel";



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetProjectsFromUserRequestModel
	 */
	 

	export interface IGetProjectsFromUserRequestModel {
		PersonId: number;
		ToJsonContract(): DataContracts.IGetProjectsFromUserRequestModel;
	}

	export class GetProjectsFromUserRequestModel {
		// property-Properties
		public get PersonId(): number {
			return this._PersonId;
		}
		public set PersonId(newPersonId: number) {
			this._PersonId = newPersonId;
		}

		// field-Properties

		// fields
		protected _PersonId: number = 0;

		constructor(data?: DataContracts.IGetProjectsFromUserRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetProjectsFromUserRequestModel): void {
			if(data) {
				// vul properties
				this._PersonId = data.PersonId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IGetProjectsFromUserRequestModel {
			return {
				PersonId: this.PersonId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetProjectsFromUserResponseModel
	 */
	 

	export interface IGetProjectsFromUserResponseModel {
		Projects: GetAllProjectsModel.IImageAnnotationProject[];
	}

	export class GetProjectsFromUserResponseModel {
		// property-Properties
		public get Projects(): GetAllProjectsModel.IImageAnnotationProject[] {
			return this._Projects;
		}
		public set Projects(newProjects: GetAllProjectsModel.IImageAnnotationProject[]) {
			this._Projects = newProjects;
		}

		// field-Properties

		// fields
		protected _Projects: GetAllProjectsModel.IImageAnnotationProject[] = [];

		constructor(data?: DataContracts.IGetProjectsFromUserResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetProjectsFromUserResponseModel): void {
			if(data) {
				// vul properties
				this._Projects = data.Projects ? data.Projects.map(imageAnnotationProjectItem => new GetAllProjectsModel.ImageAnnotationProject(imageAnnotationProjectItem)): [];
				// vul fields
			}
		}
	}
