<!--<img class="img-achtergrond" src="/Content/Images/activatie/standaardafbeelding.jpg"  />-->
<img class="img-achtergrond" [src]="ToonImageWachtwoordVergeten()" />
<div *ngIf="bezigMetLaden">
    <div class="flex-cont cont-progress-circular">
        <mat-progress-spinner diameter="100px" mode="indeterminate"></mat-progress-spinner>
    </div>
</div>

<div *ngIf="!bezigMetLaden" class="cont-wachtwoord-veranderen">
    <div *ngIf="klaarMetWijzigen">
      <h2>Wachtwoord vergeten</h2>
      <p class="txt-main">
          Wachtwoord aanpassen met succes voltooid!
      </p>

      <wct-button button-size="small" button-align="center"
          (click)="TerugNaarLogin()">Terug naar login</wct-button>
    </div>

    <div *ngIf="!codeGeldig">
        <h2>Wachtwoord vergeten</h2>
        <p class="txt-main">
            De gebruikte link is niet meer geldig. Klik opnieuw op “Wachtwoord vergeten” als u uw wachtwoord wilt
            veranderen.
        </p>

        <wct-button  button-size="small" button-align="center"
        (click)="TerugNaarLogin()">Terug naar login</wct-button>
    </div>

    <div *ngIf="!klaarMetWijzigen && codeGeldig" class="content-wachtwoord-vergeten">
      <div>
        <h2>Wachtwoord vergeten</h2>
        <p class="txt-main">
            Stel een nieuw wachtwoord in wat uit minimaal {{ wachtwoordLengte }} karakters bestaat.
        </p>
        <div class="form-group">
            <label class="col-sm-6 control-label txt-readonly"
                for="gebruikersnaamWachtwoordVergeten">Gebruikersnaam</label>
            <div class="col-sm-6">
                <input *ngIf="Emailadres.length > 0" [(ngModel)]="Emailadres" type="text" class="form-control"
                    id="gebruikersnaamWachtwoordVergeten" readonly />
                <input *ngIf="Emailadres.length == 0" [(ngModel)]="userName" type="text" class="form-control"
                    id="gebruikersnaamWachtwoordVergeten" readonly />
            </div>
        </div>
        <div class="form-group">
            <label class="col-sm-6 control-label" for="registrerenWachtwoord">Wachtwoord</label>
            <div class="col-sm-6">
                <input [(ngModel)]="wachtwoord" type="password" class="form-control"
                    [ngClass]="{ formControlProblem: WachtwoordBevestigHulp() }" id="registrerenWachtwoord" />
            </div>
        </div>
        <div class="form-group">
            <label class="col-sm-6 control-label" for="registrerenEmailadres">Wachtwoord nogmaals ter
                bevestiging</label>
            <div class="col-sm-6">
                <input [(ngModel)]="wachtwoordBevestig" type="password" class="form-control"
                    [ngClass]="{ formControlProblem: WachtwoordBevestigHulp() }" id="registrerenEmailadres" />
            </div>
        </div>
      </div>
      <div class="footer">
        <p *ngIf="error != ''" class="col-sm-12 txt-nog-invullen error-message">{{ error }}</p>
        <wct-button style="display: block;" button-size="small" button-align="center" (click)="Aanpassen()" [buttonDisabled]="error == '' ? 'false' : 'true'">Aanpassen</wct-button>
      </div>

  </div>
</div>
