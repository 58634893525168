import * as _ from 'lodash';
import * as ng from 'angular';
import * as ModelsRetrieveDwellingTypeUseCaseModel from '@models/RetrieveDwellingTypeUseCaseModel';
import * as DataContractsKiesOptieDataUit from '@datacontracts/KiesOptieDataUit';
import * as HelpersMasterTypescript from '.././Helpers/MasterTypescript';
import * as ModelsWijzigEvent from '@models/WijzigEvent';
import * as DataContractsWijzigEvent from '@datacontracts/WijzigEvent';
import * as ConsumerInterfacesDwellingTypeController from '@consumerinterfaces/DwellingTypeController';
import * as ServicesEnergieAdviesComparisonTableService from '.././services/EnergieAdviesComparisonTableService';
import * as ServicesFatalErrorService from '.././services/FatalErrorService';
import * as ServicesProjectService from '.././services/ProjectService';
import * as ServicesKostenService from '.././services/KostenService';
import * as ServicesWoningEventService from '.././services/WoningEventService';
import * as ServicesWebBerichtService from '.././services/WebBerichtService';
import * as ServicesWoningService from '.././services/WoningService';
import * as HelpersBackwards from '.././Helpers/Backwards';
import * as EnumsWijzigScopeEnum from '@enums/WijzigScopeEnum';
import * as ServicesOptieService from '.././services/OptieService';
import * as HelpersAngularEvent from '.././Helpers/AngularEvent';
import * as DataContractsKiesOptieDataIn from '@datacontracts/KiesOptieDataIn';
import * as ViewModelsWoningTypologie from '.././Models/WoningTypologie';
import * as ViewModelsWoningType from '.././Models/WoningType'; 
import * as ViewModelsWoningSelectie from '.././Models/WoningSelectie';
import * as ViewModelsWoningInstantie from '.././Models/WoningInstantie';
import * as ViewModelsWoning from '.././Models/Woning';
import * as ViewModelsIGebouwBrokKeuze from '.././Models/IGebouwBrokKeuze';
import * as ViewModelsGebouwBrokOptieKeuze from '.././Models/GebouwBrokOptieKeuze';
import * as ViewModelsGebouwBrok from '.././Models/GebouwBrok';
import * as ViewModelsBlok from '.././Models/Blok';
import * as ModelsWoningSelectie from '@models/WoningSelectie';
import { OptieEventService } from './optieeventservice';
import { DwellingSelectionType } from '@enums/WoningSelectie';

export class KostenEnergieToken {
	metKosten: boolean;
	metEnergie: boolean;
}


export interface IOptieScope extends ng.IScope {

}
export interface IOptieService {
	//getwoningtype(woningtype: string): ng.IPromise<ViewModelsWoningType.WoningType>;
	//getwoningtypologie(woningtype: string, woningtypologie: string, voorgespiegeld: boolean): ng.IPromise<ViewModelsWoningTypologie.WoningTypologie>;
	getwoninginstantie(woningid: number, scenario: number, forceer: boolean): ng.IPromise<ViewModelsWoningInstantie.WoningInstantie>;
	getwoninginstanties(woningids: Array<number>, scenario: number, broktype?: ViewModelsGebouwBrok.GebouwType): ng.IPromise<Array<ViewModelsWoningInstantie.WoningInstantie>>;
	getblokken(cacheNietGebruiken): ng.IPromise<Array<ViewModelsBlok.Blok>>;
	getblok(blokid: number): ng.IPromise<ViewModelsBlok.Blok>;
	getwoningen(): ng.IPromise<Array<ViewModelsWoning.Woning>>;
	TriggerOptieAangepast(events: any, kiesOptieArgument: DataContractsKiesOptieDataIn.IKiesOptieDataIn): void;
	OptieAangepastEvent: HelpersAngularEvent.AngularEvent<HelpersAngularEvent.AngularEventArg<DataContractsKiesOptieDataIn.IKiesOptieDataIn>>;
	OptieWordtAangepastEvent: HelpersAngularEvent.AngularEvent<HelpersAngularEvent.AngularEventArg<void>>;
	OptieWordtBekekenEvent: HelpersAngularEvent.AngularEvent<OptieWordtBekekenEventArgs>;
	LaadOptieUitsluitingen(voorbeeld_woningid: number, woningtype: string): ng.IPromise<any>;
	//OptiesIndienen(): ng.IPromise<any>;
	//zijnOptiesIngediend(): ng.IPromise<string>;
	ZetInitieelWoningEventNummer(woningid: number, variant: number, eventid: number, bewonerid: number);

	ResetOptiesBestaand();
	ResetOptiesNieuw(alleenEnergie: boolean);
	BekijkOptie(deel: number, optie: number, ingangsroutePakketkeuze: boolean);
	ZetInterfacePakketflowIsGeladen(waarde: boolean): void;
	GetInterfacePakketflowIsGeladen(): boolean;

	IsBezig();

	ResetOptiesBlok(blokid: number, scenario: number);
	ResetOptiesWoning(woningid: number, scenario: number);

	GeefEventID(scope: ServicesOptieService.ScopeSelectie): number;
	//GeefBrokEventID(scope: ServicesOptieService.ScopeSelectie, broktype: ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze): number;
	KiesOptie(woningen: Array<number>, scenario: number, keuzes: Array<ViewModelsGebouwBrokOptieKeuze.GebouwBrokOptieKeuze>): ng.IPromise<any>;
	//KiesOpties(woningen: Array<number>, scenario: number, Array<Keuzes>: ViewModelsGebouwBrok.GebouwBrok, optie: ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze): ng.IPromise<any>;

	GeefVervolgVragen(deel: ViewModelsGebouwBrok.GebouwBrok, optie: ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze): ng.IPromise<Array<ViewModelsGebouwBrok.GebouwBrok>>;

	ZetOptieUitsluiting(woningids: number[], brok: ViewModelsGebouwBrok.GebouwBrok, optie: ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze, toestaan: boolean);
	AantalOptiesToegestaan(woningids: number[], brok: ViewModelsGebouwBrok.GebouwBrok, optie: ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze): number;

	haalwoninginstantiesmetkostenenenergie(kosten: boolean, energie: boolean): KostenEnergieToken;
	stophaalwoninginstantiesmetkostenenenergie(token: KostenEnergieToken);
	//m_ExplanationAboutNewOptionsIsShown: boolean;
	ShowExplanationAboutNewOptionsEvent: HelpersAngularEvent.AngularEvent<HelpersAngularEvent.AngularEventArg<void>>;
	TriggerShowExplanationAboutNewOptions(): void;
}

export class ScopeSelectie {
	public DwellingSelectionType: DwellingSelectionType;
	public Scope: EnumsWijzigScopeEnum.WijzigScopeEnum;
	public ID: number;
	public BewonerID: number;
	public Scenario: number;
	public get Sleutel(): string {
		return this.Scope + "_" + this.ID + "_" + this.Scenario + "_" + this.BewonerID;
	}

	public get Model(): ModelsWoningSelectie.WoningSelectie
	{
		let model = new ModelsWoningSelectie.WoningSelectie(this);
		return model;
	}

	public Kopie(): ServicesOptieService.ScopeSelectie {
		let selectie: ServicesOptieService.ScopeSelectie = new ServicesOptieService.ScopeSelectie();
		selectie.Scope = this.Scope;
		selectie.ID = this.ID;
		selectie.Scenario = this.Scenario;
		selectie.BewonerID = this.BewonerID;
		return selectie;
	}

	public Gelijk(selectie: ServicesOptieService.ScopeSelectie): boolean {
		if (this.Scope !== selectie.Scope) {
			return false;
		}
		if (this.ID !== selectie.ID) {
			return false;
		}
		if (this.Scenario !== selectie.Scenario) {
			return false;
		}
		if (this.BewonerID !== selectie.BewonerID) {
			return false;
		}
		return true;
	}

	public static Initial(): ServicesOptieService.ScopeSelectie {
		const selectie = new ServicesOptieService.ScopeSelectie();
		selectie.Scope = HelpersBackwards.ConstSoftware.InitieleScope;
		switch (selectie.Scope) {
			case EnumsWijzigScopeEnum.WijzigScopeEnum.Complex:
				selectie.ID = HelpersBackwards.ConstSoftware.InitieleWijk;
				break;
			case EnumsWijzigScopeEnum.WijzigScopeEnum.Blok:
				selectie.ID = HelpersBackwards.ConstSoftware.InitieelBlok;
				break;
			case EnumsWijzigScopeEnum.WijzigScopeEnum.Woning:
				selectie.ID = HelpersBackwards.ConstSoftware.InitieleWoning;
				break;
			default:
				throw new Error(`Initiële scope "${EnumsWijzigScopeEnum.WijzigScopeEnum[selectie.Scope]}" wordt nog niet ondersteund!`);
		}
		selectie.Scenario = HelpersBackwards.ConstSoftware.InitieelScenario;
		return selectie;
	}
}

/**
 * Services that persists and retrieves TODOs from localStorage.
 */
export class OptieService implements IOptieService {
	GeefEventID(scope: ServicesOptieService.ScopeSelectie): number
	{
		return this.optieeventservice.GeefEventID(scope);
	}
	public get OptieAangepastEvent(): HelpersAngularEvent.AngularEvent<HelpersAngularEvent.AngularEventArg<DataContractsKiesOptieDataIn.IKiesOptieDataIn>> {
		return this.optieeventservice.OptieAangepastEvent;
	}

	public get OptieWordtAangepastEvent(): HelpersAngularEvent.AngularEvent<HelpersAngularEvent.AngularEventArg<void>> {
		return this.optieWordtAangepastEventEmitter.Event;
	}

	public get OptieWordtBekekenEvent(): HelpersAngularEvent.AngularEvent<OptieWordtBekekenEventArgs> {
		return this.optieWordtBekekenEventEmitter.Event;
	}

	public get ShowExplanationAboutNewOptionsEvent(): HelpersAngularEvent.AngularEvent<HelpersAngularEvent.AngularEventArg<void>> {
		return this.showExplanationAboutNewOptionsEventEmitter.Event;
	}

	//private optieAangepastEventEmitter: HelpersAngularEvent.IAngularEventEmitter<HelpersAngularEvent.AngularEventArg<DataContractsKiesOptieDataIn.IKiesOptieDataIn>>;
	private optieWordtAangepastEventEmitter: HelpersAngularEvent.IAngularEventEmitter<HelpersAngularEvent.AngularEventArg<void>>;
	private optieWordtBekekenEventEmitter: HelpersAngularEvent.IAngularEventEmitter<OptieWordtBekekenEventArgs>;
	private showExplanationAboutNewOptionsEventEmitter: HelpersAngularEvent.IAngularEventEmitter<HelpersAngularEvent.AngularEventArg<void>>;

	public STORAGE_ID = "site-angularjs-typescript";
	private m_AjaxQuestTokens: any[] = [];

	private m_WoningTypen: { [woningtype: string]: ViewModelsWoningType.WoningType; } = {};
	private m_WoningInstanties: { [id: string]: ViewModelsWoningInstantie.WoningInstantie; } = {};
	private m_Blokken: _.Dictionary<ViewModelsBlok.Blok> = null;

	
	private m_OptieUitsluitingen: { [id: string]: number[] } = {};
	private m_OptiesZijnIngediend: string = null;

	private m_OphaalTokens: KostenEnergieToken[] = [];

	private m_InterfacePakketflowIsGeladen: boolean = false;
	//private optieWordtBekekenEventArgsPakketflow: OptieWordtBekekenEventArgs;
	private m_ExplanationAboutNewOptionsIsAlreadyShown: boolean = false;

	constructor(
		private $http: ng.IHttpService,
		private $q: ng.IQService,
		private $rootScope: ng.IScope,
		private $log: ng.ILogService,
		private woningService: ServicesWoningService.IWoningService,
		private webberichtservice: ServicesWebBerichtService.IWebBerichtService,
		private woningeventservice: ServicesWoningEventService.IWoningEventService,
		private kostenservice: ServicesKostenService.IKostenService,
		private projectservice: ServicesProjectService.IProjectService,
		private fatalerrorservice: ServicesFatalErrorService.IFatalErrorService,
		private energieadviescomparisontableservice: ServicesEnergieAdviesComparisonTableService.IEnergieAdviesComparisonTableService,
		private dwellingtypeconsumer: ConsumerInterfacesDwellingTypeController.IDwellingTypeConsumer,
		private optieeventservice: OptieEventService,
	) {
'ngInject';

		this.ZetInitieelWoningEventNummer(HelpersBackwards.ConstSoftware.InitieleWoning, HelpersBackwards.ConstSoftware.InitieelScenario,
			HelpersBackwards.ConstSoftware.InitieleWoningEventId, HelpersBackwards.ConstSoftware.BewonerID);

		this.registerEventEmitters();

		this.woningeventservice.KiesNieuweWoningEvent.RegisterHandler($rootScope, (r, d) => {
			if (d.Event != null) {
				this.ZetInitieelWoningEventNummer(d.Event.ID, d.Event.Variant, d.Event.Generatie, HelpersBackwards.ConstSoftware.BewonerID);
			}
		})
	}

	public ZetInterfacePakketflowIsGeladen(waarde: boolean): void {
		this.m_InterfacePakketflowIsGeladen = waarde;
	}

	public GetInterfacePakketflowIsGeladen(): boolean {
		return this.m_InterfacePakketflowIsGeladen;
	}

	public ZetInitieelWoningEventNummer(woningid: number, variant: number, eventid: number, bewonerid: number) {
		var scope: ServicesOptieService.ScopeSelectie = new ServicesOptieService.ScopeSelectie();
		scope.ID = woningid;
		scope.Scenario = variant;
		scope.Scope = EnumsWijzigScopeEnum.WijzigScopeEnum.Woning;
		scope.BewonerID = bewonerid;
		//scope.BewonerID = this.accountservice.
		var huidigeventid = this.optieeventservice.GeefEventID(scope);
		if (huidigeventid == null) {
			var event: DataContractsWijzigEvent.IWijzigEvent = new ModelsWijzigEvent.WijzigEvent();
			event.Generatie = eventid;
			event.ID = woningid;
			event.Variant = variant;
			event.Scope = EnumsWijzigScopeEnum.WijzigScopeEnum.Woning;

			this.optieeventservice.ZetEventNummer(event);
		}
	}

	IsBezig() {
		if (this.m_AjaxQuestTokens.length > 0) {
			return true;
		}
		return false;
	}

	MaakToken(naam: string): Object {
		var token: any = new Object();
		token.Naam = naam;
		token.Tijdstip = new Date();

		this.m_AjaxQuestTokens.push(token);
		return token;
	}

	VerwijderToken(token: Object) {
		var tokenindex = this.m_AjaxQuestTokens.indexOf(token);
		this.m_AjaxQuestTokens.splice(tokenindex, 1);
	}


	private registerEventEmitters(): void {
		this.optieWordtAangepastEventEmitter = HelpersAngularEvent.AngularEvent.Default<HelpersAngularEvent.AngularEventArg<void>>("OptieService-optie-wordt-aangepast-event", this.$rootScope);
		this.optieWordtBekekenEventEmitter = HelpersAngularEvent.AngularEvent.Default<OptieWordtBekekenEventArgs>("OptieService-optie-wordt-bekeken-event", this.$rootScope);
		this.showExplanationAboutNewOptionsEventEmitter = HelpersAngularEvent.AngularEvent.Default<HelpersAngularEvent.AngularEventArg<void>>("OptieService-show-explanation-about-new-options-event", this.$rootScope);
	}

	//public zijnOptiesIngediend(): ng.IPromise<string> {
	//	var deferred = this.$q.defer();
	//	if (this.m_OptiesZijnIngediend != null) {
	//		deferred.resolve(this.m_OptiesZijnIngediend);
	//	}
	//	this.$http.post("/opties/ZijnOptiesInGediend", {}).then(r => {
	//		var data: any = r.data;
	//		if (data == "") data = null;
	//		this.m_OptiesZijnIngediend = data;
	//		deferred.resolve(data);
	//	});
	//	return deferred.promise;
	//}

	//public OptiesIndienen(): ng.IPromise<any> {
	//	var deferred = this.$q.defer();
	//	this.$http.post("/opties/OptiesIndienen", {}).then(r => {
	//		var data: any = r.data;
	//		if (data) {
	//			this.zijnOptiesIngediend();
	//		}
	//		deferred.resolve(data);
	//	});
	//	return deferred.promise;
	//}

	public BekijkOptie(deel: number, optie: number, ingangsroutePakketkeuze: boolean): void {
		var arg: OptieWordtBekekenEventArgs = new OptieWordtBekekenEventArgs();
		arg.Deel = deel;
		arg.Optie = optie;
		arg.ingangsroutePakketkeuze = (ingangsroutePakketkeuze == true);

		//this.optieWordtBekekenEventArgsPakketflow = arg;
		this.optieWordtBekekenEventEmitter.Emitter.BroadCast(arg);
	}

	public KiesOptie(woningen: Array<number>, scenario: number, keuzes: Array<ViewModelsGebouwBrokOptieKeuze.GebouwBrokOptieKeuze>): ng.IPromise<any> {
		var deferred = this.$q.defer();

		const clientid = HelpersMasterTypescript.MasterControllerInstance.GeefNieuwClientId();
		const kiesOptieArgument: DataContractsKiesOptieDataIn.IKiesOptieDataIn = {
			Woningen: woningen,
			Scenario: scenario,
			Keuzes: keuzes,
			CameraNietAanpassen: false,
			ViaOptieId: true,
			clientid: clientid
		}

		var token = this.MaakToken('kiesoptie');

		this.optieWordtAangepastEventEmitter.Emitter.BroadCast(HelpersAngularEvent.AngularEventArg.WithData(null, true, 0));

		this.webberichtservice.KiesOptie(kiesOptieArgument, (model: DataContractsKiesOptieDataUit.IKiesOptieDataUit) => {
			this.VerwijderToken(token);
			if (model == null) {
				this.fatalerrorservice.ReloadPage(null, "KiesOptie");
			}
			else {
				this.TriggerOptieAangepast(model.Events, kiesOptieArgument);
			}

			deferred.resolve(null);
		});
		return deferred.promise;
	}


	public TriggerOptieAangepast(events: DataContractsWijzigEvent.IWijzigEvent[], kiesOptieArgument: DataContractsKiesOptieDataIn.IKiesOptieDataIn) {
		if (events != null) {
			for (let i = 0; i < events.length; i++) {
				const event = events[i];
				this.optieeventservice.ZetEventNummer(event);
			}
		}

		this.optieeventservice.Broadcast(kiesOptieArgument);

		

		this.woningeventservice.TriggerCentraleToestandGewijzigdSimpel(ViewModelsWoningSelectie.WijzigBron.OptieAangepast);
		//this.woningeventservice.TriggerOptieAangepast();
	}

	

	//GeefBrokEventID(scope: ServicesOptieService.ScopeSelectie, broktype: ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze): number;

	public ResetOptiesBlok(blokid: number, scenario: number) {
		const clientId = -1;
		const kiesOptieArgument: DataContractsKiesOptieDataIn.IKiesOptieDataIn = {
			Woningen: undefined,
			Scenario: scenario,
			Keuzes: undefined,
			CameraNietAanpassen: undefined,
			ViaOptieId: undefined,
			clientid: clientId
		}
		var token = this.MaakToken('ResetOptiesBlok');
		this.webberichtservice.ResetOpties(clientId, true, scenario, blokid, -1).then(r => {
			this.VerwijderToken(token);
			this.TriggerOptieAangepast(r.Events, kiesOptieArgument);
		});
	}

	public ResetOptiesWoning(woningid: number, scenario: number) {
		const clientId = -1;
		const kiesOptieArgument: DataContractsKiesOptieDataIn.IKiesOptieDataIn = {
			Woningen: [woningid],
			Scenario: scenario,
			Keuzes: undefined,
			CameraNietAanpassen: undefined,
			ViaOptieId: undefined,
			clientid: clientId
		}
		var token = this.MaakToken('ResetOptiesWoning');
		this.webberichtservice.ResetOpties(clientId, false, scenario, -1, woningid).then(r => {
			this.VerwijderToken(token);
			this.TriggerOptieAangepast(r.Events, kiesOptieArgument);
		});
	}


	public ResetOptiesBestaand() {
		var token = this.MaakToken('ResetOptiesBestaand');
		this.$http.post("/opties/resetoptieshuidigewoning?alles=true", {}).then(r => {
			this.VerwijderToken(token);
			var data: any = r.data;
			const kiesOptieArgument: DataContractsKiesOptieDataIn.IKiesOptieDataIn = {
				Woningen: undefined,
				Scenario: 0, // bestaande toestand
				Keuzes: undefined,
				CameraNietAanpassen: undefined,
				ViaOptieId: undefined,
				clientid: undefined
			}
			this.TriggerOptieAangepast(data.Events, kiesOptieArgument);
		});
	}

	public ResetOptiesNieuw(alleenEnergie: boolean) {
		this.$http.post("/opties/resetoptieshuidigewoning?alles=false&alleenEnergie=" + alleenEnergie, {}).then(r => {
			var data: any = r.data;
			const kiesOptieArgument: DataContractsKiesOptieDataIn.IKiesOptieDataIn = {
				Woningen: undefined,
				Scenario: undefined, // bestaande toestand
				Keuzes: undefined,
				CameraNietAanpassen: undefined,
				ViaOptieId: undefined,
				clientid: undefined
			}
			this.TriggerOptieAangepast(data.Events, kiesOptieArgument);
		});
	}


	// prefetchen van een hoop woningtypen
	private geefwoningtypen(optehalen: Array<number>): ng.IPromise<any> {
		var deferred = this.$q.defer();

		this.woningService.GeefWoningen(false).then(woningen => {
			var allewoningtypen = _.map(optehalen, (w) => {
				return woningen[w].WoningType;
			});
			allewoningtypen = _.uniq(allewoningtypen);
			var queries: Array<ng.IPromise<ViewModelsWoningType.WoningType>> = [];

			_.each(allewoningtypen, wt => {
				var id = _.find(optehalen, (w) => woningen[w].WoningType == wt);
				var q = this.getwoningtype(id, wt);
				queries.push(q);
			});

			this.$q.all(queries).then(data => {
				/* allemaal gevonden */
				deferred.resolve();
			});
		});

		return deferred.promise;
	}

	public getwoningtypologiekey(woningtype: string, typologie: string, gespiegeld: boolean): string {
		return woningtype + "_" + typologie + "_" + gespiegeld.toString();
	}

	public getwoningtypetypologiekey(typologie: string, gespiegeld: boolean): string {
		return typologie + "_" + gespiegeld.toString();
	}


	public getwoningtypologien(voorwoningid: Array<number>, spiegellijst: Array<number>): ng.IPromise<any> {
		var deferred = this.$q.defer();

		this.woningService.GeefWoningen(false).then(woningen => {
			this.geefwoningtypen(voorwoningid).then(dummy => {
				var benodigdeWoningen = _.filter(voorwoningid, i => {
					var isgespiegeld = _.includes(spiegellijst, i);
					var woning = woningen[i];
					return this.m_WoningTypen[woning.WoningType].GeefTypologie(woning.Typologie, isgespiegeld) == null;
				});

				var sleutels = _.map(benodigdeWoningen, wid => this.getwoningtypologiekey(woningen[wid].WoningType, woningen[wid].Typologie, _.includes(spiegellijst, wid)));
				var sleutelsuniq = _.uniq(sleutels);

				var queries: Array<ng.IPromise<ViewModelsWoningTypologie.WoningTypologie>> = [];
				_.each(sleutelsuniq, s => {
					var index = sleutels.indexOf(s);
					var wid = benodigdeWoningen[index];
					var woning = woningen[wid];
					var query = this.getwoningtypologie(wid, woning.WoningType, woning.Typologie, _.includes(spiegellijst, wid));
					queries.push(query);
				});

				this.$q.all(queries).then(data => {
					/* allemaal gevonden */
					deferred.resolve();
				});
			});
		});

		return deferred.promise;
	}


	public getwoningtype(voorwoningid: number, woningtypenaam: string): ng.IPromise<ViewModelsWoningType.WoningType> {
		var deferred = this.$q.defer<ViewModelsWoningType.WoningType>();

		const woningtype: ViewModelsWoningType.WoningType = this.m_WoningTypen[woningtypenaam];
		if (woningtype != null) {
			deferred.resolve(woningtype);
		}
		else {
			var token = this.MaakToken('getwoningtype ' + voorwoningid + ' ' + woningtypenaam);

			var inputModel = new ModelsRetrieveDwellingTypeUseCaseModel.RetrieveDwellingTypeUseCaseRequestModel();
			inputModel.DwellingId = voorwoningid;
			inputModel.TypeName = woningtypenaam;
			inputModel.ProjectId = this.projectservice.GeefProjectId();

			//this.$http.get("/Opties/GeefWoningType?voorWoning=" + voorwoningid + "&woningType=" + woningtypenaam + "&projectid=" + this.projectservice.GeefProjectId()).then(response => {

			// this.dwellingtypeconsumer.GetDwellingType(inputModel).then(response => {
			// 	this.VerwijderToken(token);
			// 	//var data: any = response.data;

			// 	// misschien heeft in de tussentijd iemand anders deze opgevraagd
			// 	var woningtype: ViewModelsWoningType.WoningType = this.m_WoningTypen[woningtypenaam];
			// 	if (woningtype == null) {
			// 		woningtype = new ViewModelsWoningType.WoningType(woningtypenaam, response.Model);
			// 		console.log("Woningtype " + woningtypenaam + " aangemaakt met id " + woningtype.RandomID);
			// 		this.m_WoningTypen[woningtypenaam] = woningtype;
			// 	}
			// 	deferred.resolve(woningtype);
			// }).catch(reason => {
			// 	deferred.reject(reason);
			// });
		}
		return deferred.promise;
	}

	public getwoningtypologie(voorwoningid: number, woningtype: string, woningtypologie: string, voorgespiegeld: boolean): ng.IPromise<ViewModelsWoningTypologie.WoningTypologie> {
		var deferred = this.$q.defer<ViewModelsWoningTypologie.WoningTypologie>();

		var sleutel = this.getwoningtypetypologiekey(woningtypologie, voorgespiegeld);
		this.getwoningtype(voorwoningid, woningtype).then(t => {
			var typologie = t.Typologien[sleutel];
			if (typologie != null) {
				deferred.resolve(typologie);
			}
			else {
				var token = this.MaakToken('getwoningtypologie ' + voorwoningid + ' ' + woningtype + ' ' + woningtypologie + ' ' + voorgespiegeld);
				this.$http.get("/Opties/GeefWoningTypologie?voorWoning=" + voorwoningid + "&woningType=" + woningtype + "&woningTypologie=" + woningtypologie + "&voorgespiegeld=" + voorgespiegeld.toString() + "&projectid=" + this.projectservice.GeefProjectId()).then(response => {
					this.VerwijderToken(token);
					console.log("Typologie " + woningtypologie + " voor type " + woningtype + " verwerkt; type heeft id " + t.RandomID);
					var data: any = response.data;
					var typologie: ViewModelsWoningTypologie.WoningTypologie = new ViewModelsWoningTypologie.WoningTypologie(data, woningtypologie, voorgespiegeld, t);
					typologie.WoningType = t;
					t.Typologien[sleutel] = typologie;
					deferred.resolve(typologie);
				}).finally(() => {
					this.VerwijderToken(token);
				}).catch(r => deferred.reject(r));
			}
		});
		return deferred.promise;
	}

	public getwoningen(): ng.IPromise<Array<ViewModelsWoning.Woning>> {
		var deferred = this.$q.defer<Array<ViewModelsWoning.Woning>>();
		this.woningService.GeefWoningen(false).then(dict => {
			var list: Array<ViewModelsWoning.Woning> = _.values(dict);
			//list = _.map(dict, (d) => d);
			deferred.resolve(list);
		});
		return deferred.promise;
	}

	//geefwoningen(): ng.IPromise<_.Dictionary<ViewModelsWoning.Woning>> {
	//    var deferred = this.$q.defer();
	//    if (this.m_Woningen != null) {
	//        deferred.resolve(this.m_Woningen);
	//    }
	//    else {
	//        this.$http.get('/Opties/GeefAlleWoningen').then(response => {
	//            this.m_Woningen = {};
	//            var data: any = response.data;
	//            _.each(data, (w) => {
	//                var woning = new ViewModelsWoning.Woning();
	//                ng.extend(woning, w);
	//                this.m_Woningen[woning.WoningID] = woning;
	//            });

	//            deferred.resolve(this.m_Woningen);
	//        })
	//            .catch(r => deferred.reject(r));
	//    }
	//    return deferred.promise;
	//}

	//getwoning(woningid: number): ng.IPromise<ViewModelsWoning.Woning> {
	//    var deferred = this.$q.defer();

	//    this.geefwoningen().then(woningen => {
	//        var woning = woningen[woningid];
	//        deferred.resolve(woning);
	//    }).catch(r => deferred.reject(r));
	//    return deferred.promise;
	//}

	public geefbloklijst(): Array<ViewModelsBlok.Blok> {
		const blokken = _.map(this.m_Blokken, (v, k) => v);
		return blokken;
	}

	public getblokken(cacheNietGebruiken): ng.IPromise<Array<ViewModelsBlok.Blok>> {

		if (cacheNietGebruiken === undefined) {
			cacheNietGebruiken = false;
		}

		var deferred = this.$q.defer<Array<ViewModelsBlok.Blok>>();
		if ((this.m_Blokken != null) && !cacheNietGebruiken) {
			deferred.resolve(this.geefbloklijst());
		}
		else {
			var inputdata: any = {};

			var token = this.MaakToken('getblokken');
			inputdata.ProjectId = this.projectservice.GeefProjectId();
			this.$http.post('/Opties/GeefAlleBlokken', inputdata).then(response => {
				this.VerwijderToken(token);
				this.woningService.GeefWoningen(cacheNietGebruiken).then(woningen => {
					this.m_Blokken = {};
					var data: any = response.data;
					_.each(data, (b) => {
						var blok = new ViewModelsBlok.Blok();
						blok._SetData(b);
						var blokwoningen: Array<ViewModelsWoning.Woning> = _.map(blok.WoningIDs, (w) => woningen[w]);
						blok._SetWoningen(blokwoningen);
						this.m_Blokken[blok.BlokID] = blok;
					});
					deferred.resolve(this.geefbloklijst());
				}).catch(r => deferred.reject(r));
			}).catch(r => deferred.reject(r));
		}
		return deferred.promise;
	}

	public getblok(blokid: number): ng.IPromise<ViewModelsBlok.Blok> {
		var deferred = this.$q.defer<ViewModelsBlok.Blok>();
		this.getblokken(false).then(blokken => {
			var gevonden = _.find(blokken, (b) => b.BlokID == blokid);
			deferred.resolve(gevonden);
		}).catch(r => deferred.reject(r));
		return deferred.promise;
	}

	public haalwoninginstantiesmetkostenenenergie(kosten: boolean, energie: boolean): KostenEnergieToken {
		var token = new KostenEnergieToken();
		token.metEnergie = energie;
		token.metKosten = kosten;
		this.m_OphaalTokens.push(token);
		return token;
	}

	public stophaalwoninginstantiesmetkostenenenergie(token: KostenEnergieToken) {
		var index = this.m_OphaalTokens.indexOf(token);
		if (index >= 0) {
			this.m_OphaalTokens.splice(index, 1);
		} else {
			console.log('Token niet gevonden');
		}
	}


	public getwoninginstanties(woningids: Array<number>, scenario: number, broktype?: ViewModelsGebouwBrok.GebouwType): ng.IPromise<Array<ViewModelsWoningInstantie.WoningInstantie>> {
		var deferred = this.$q.defer<Array<ViewModelsWoningInstantie.WoningInstantie>>();
		var metEnergie = false;
		var metKosten = false;

		_.each(this.m_OphaalTokens,
			(o) => {
				if (o.metEnergie) {
					metEnergie = true;
				}
				if (o.metKosten) {
					metKosten = true;
				}
			});


		var ret: Array<ViewModelsWoningInstantie.WoningInstantie> = _.map(woningids, (woningid) => {

			if (typeof (woningid) != "number") {
				this.$log.warn("WoningID is geen nummer");
			}

			var instantie = this.m_WoningInstanties[woningid + "_" + scenario];

			if (instantie != null) {
				if (!instantie.woningisuptodate(this, broktype, metKosten, metEnergie)) {
					return null;
				}
			}
			return instantie;
		});

		var optehalen = [];

		_.each(woningids, (w, i) => {
			if (ret[i] == null) {
				optehalen.push(w);
			}
		});

		if (optehalen.length == 0) {
			deferred.resolve(ret);
		}
		else {
			if (metKosten == null) {
				metKosten = false;
			}
			var token = this.MaakToken('getwoninginstanties');
			console.log('GeefWoningInstanties metEnergie: ' + metEnergie + ' metKosten: ' + metKosten);
			this.webberichtservice.GeefWoningInstanties(optehalen, scenario, broktype, metKosten, metEnergie).then(response => {
				this.VerwijderToken(token);
				var data: any = response;
				this.woningService.GeefWoningen(false).then(woningen => {
					this.geefwoningtypen(optehalen).then(() => {
						var gespiegeld = _.filter(data.Instanties, (i: any) => i.Gespiegeld);
						var gespiegeldeIDs = _.map(gespiegeld, (g: any) => g.WoningID);

						this.getwoningtypologien(optehalen, gespiegeldeIDs).then(() => {
							_.each(data.Instanties, (i: any) => {
								var instantie = this.m_WoningInstanties[i.WoningID + "_" + scenario];
								if (instantie == null) {
									instantie = new ViewModelsWoningInstantie.WoningInstantie(i);
									const isgespiegeld = i.Gespiegeld;
									const woning = woningen[i.WoningID];
									instantie.Typologie = this.m_WoningTypen[woning.WoningType].GeefTypologie(woning.Typologie, isgespiegeld);
									if (instantie.Typologie == null) {
										console.log("onbekende typologie");
									}
									this.m_WoningInstanties[i.WoningID + "_" + scenario] = instantie;
								}

								instantie._VulGebouwdelen(i, broktype);

								if (metKosten) {
									instantie._VulKosten(i.Kosten);
									instantie._VulKostenEigenaar(i.KostenEigenaar);
									instantie._VulKostenBestaand(i.KostenBestaand);
									instantie._VulKostenEigenaarBestaand(i.KostenEigenaarBestaand);
								} else {
									instantie._LeegKosten();
									instantie.KostenEigenaar = null;
									instantie.KostenEigenaarBestaand = null;
								}

								if (metEnergie) {
									instantie.EnergieBerekend = true;
								} else {
									instantie.EnergieBerekend = false;
								}
								if (instantie.EnergieBerekend != i.EnergieBerekend) {
									console.log("Energie mismatch");
								}

								var index = woningids.indexOf(i.WoningID);
								ret[index] = instantie;
							});

							//this.kostenservice.EnergieBesparingOokUitvoeren(false); // als enregie kosten aanstonden, weer uitzetten. Worden weer aangezet als nodig is door opvragende component.
							deferred.resolve(ret);
						});
					});
				});
			});
		}

		return deferred.promise;
	}

	public getwoninginstantie(woningid: number, scenario: number, forceer: boolean): ng.IPromise<ViewModelsWoningInstantie.WoningInstantie> {
		var deferred = this.$q.defer<ViewModelsWoningInstantie.WoningInstantie>();

		this.getwoninginstanties([woningid], scenario, null).then(instanties => {
			deferred.resolve(instanties[0]);
		}).catch(r => deferred.reject(r));

		return deferred.promise;
	}

	public GeefVervolgVragen(deel: ViewModelsGebouwBrok.GebouwBrok, optie: ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze): ng.IPromise<Array<ViewModelsGebouwBrok.GebouwBrok>> {
		var deferred = this.$q.defer<Array<ViewModelsGebouwBrok.GebouwBrok>>();

		const input: any = {};
		input.deelid = deel.DeelId;
		input.optieid = optie.KeuzeId();

		this.$http.post("/Opties/GeefVervolgVragen", input).then(d => {
			var data: any = d.data;

			var woningtype = deel.MenuGroep.WoningType;

			var vragen = data.VervolgVragen;
			var vervolgbrokken = _.map(vragen, (v: any) => {
				var brok = woningtype.GeefGebouwDeel(v.DeelId);
				return brok;
			});

			deferred.resolve(vervolgbrokken);

		}).catch(r => deferred.reject(r));

		return deferred.promise;
	}

	AantalOptiesToegestaan(woningids: number[], brok: ViewModelsGebouwBrok.GebouwBrok, optie: ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze): number {
		var huidigeWoningen = this.GeefOptiesUitgesloten(brok, optie);
		var newlist = _.difference(woningids, huidigeWoningen);
		return newlist.length;
	}

	ZetOptieUitsluiting(nieuweSelectie: number[], brok: ViewModelsGebouwBrok.GebouwBrok, optie: ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze, toestaan: boolean) {
		var huidigeWoningen = this.GeefOptiesUitgesloten(brok, optie);

		if (toestaan) {
			huidigeWoningen = _.difference(huidigeWoningen, nieuweSelectie);
		}
		else {
			huidigeWoningen = _.union(huidigeWoningen, nieuweSelectie);
		}

		this.ZetOptieUitgesloten(brok, optie, huidigeWoningen);

		var datain: any = {};
		//List < int > woningen, int receptid, string deel, string optie, bool aanuit)
		datain.woningen = nieuweSelectie;
		datain.receptid = brok.MenuGroep.WoningType.ReceptId;
		datain.deel = brok.DeelId;
		datain.optie = optie.KeuzeId();
		datain.toestaan = toestaan;

		this.$http.post('/opties/ZetUitsluitingen', datain).then(r => {
			/* done */
		}).catch(e => {
			alert('Opslaan optiebord uitsluitingen mislukt');
		});
	}

	public LaadOptieUitsluitingen(voorbeeld_woningid: number, woningtype: string): ng.IPromise<any> {
		var deferred = this.$q.defer();
		this.geefwoningtypen([voorbeeld_woningid]).then(dummy => {
			var wt = this.m_WoningTypen[woningtype];
			var datain: any = {};
			datain.receptid = wt.ReceptId;
			this.$http.post('/opties/GeefAlleUitsluitingen', datain).then(d => {
				var datauit: any = d.data;

				var opties = datauit.OptieUitsluitingen;
				_.each(opties, (o: any) => {
					this.m_OptieUitsluitingen[wt.ReceptId + "_" + o.OptieID] = o.WoningIDs;
				});

				deferred.resolve();
			});
		});
		return deferred.promise;
	}

	private GeefUitgeslotenId(brok: ViewModelsGebouwBrok.GebouwBrok, optie: ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze): string {
		var receptid = brok.MenuGroep.WoningType.ReceptId
		var id = receptid + "_" + brok.DeelId + "_" + optie.KeuzeId();
		return id;

	}

	private GeefOptiesUitgesloten(brok: ViewModelsGebouwBrok.GebouwBrok, optie: ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze): number[] {
		var id = this.GeefUitgeslotenId(brok, optie);
		var huidigeWoningen = this.m_OptieUitsluitingen[id];
		if (huidigeWoningen == null) {
			huidigeWoningen = [];
		}
		this.m_OptieUitsluitingen[id] = huidigeWoningen;
		return huidigeWoningen;
	}

	private ZetOptieUitgesloten(brok: ViewModelsGebouwBrok.GebouwBrok, optie: ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze, woningen: number[]) {
		var id = this.GeefUitgeslotenId(brok, optie);
		var receptid = brok.MenuGroep.WoningType.ReceptId
		this.m_OptieUitsluitingen[id] = woningen;
	}

	public TriggerShowExplanationAboutNewOptions(): void {
		if (!this.m_ExplanationAboutNewOptionsIsAlreadyShown) {
			this.m_ExplanationAboutNewOptionsIsAlreadyShown = true;
			this.showExplanationAboutNewOptionsEventEmitter.Emitter.BroadCast(undefined);
		}
	}
}
export class OptieWordtBekekenEventArgs {
	public Deel: number;
	public Optie: number;
	public ingangsroutePakketkeuze: boolean;

	constructor() {
'ngInject';
		this.Deel = 0;
		this.Optie = 0;
		this.ingangsroutePakketkeuze = false;
	}
}

