/**
 * @ref WoonConnectViewModel/ContractorViewModel.cs
 */

 import * as DataContracts from "@datacontracts/ContractorViewModel";

 
import * as ContractorViewModelEnum from "@enums/ContractorViewModel";
import * as TradeAssociationViewModel from "./TradeAssociationViewModel";
import * as AdresViewModel from "./AdresViewModel";
import * as PersonViewModel from "./PersonViewModel";
import * as ContractorTradeAssociationViewModel from "./ContractorTradeAssociationViewModel";
import * as SubContractorViewModel from "./SubContractorViewModel";
import * as MeasureViewModel from "./MeasureViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.ContractorRequestAllModel
	 */
	 

	export interface IContractorRequestAllModel {
		Test: string;
		ToJsonContract(): DataContracts.IContractorRequestAllModel;
	}

	export class ContractorRequestAllModel {
		// property-Properties
		public get Test(): string {
			return this._Test;
		}
		public set Test(newTest: string) {
			this._Test = newTest;
		}

		// field-Properties

		// fields
		protected _Test: string = "";

		constructor(data?: DataContracts.IContractorRequestAllModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IContractorRequestAllModel): void {
			if(data) {
				// vul properties
				this._Test = data.Test;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IContractorRequestAllModel {
			return {
				Test: this.Test
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ContractorViewModel
	 */
	 

	export interface IContractorViewModel {
		Id: number;
		Name: string;
		Website: string;
		LegalForm: ContractorViewModelEnum.LegalForm;
		FoundationYear: number;
		KVKNumber: string;
		DisputeSettlement: boolean;
		DisputeSettlementName: string;
		CRMSystemName: string;
		TradeAssociations: TradeAssociationViewModel.ITradeAssociationViewModel[];
		Adres: AdresViewModel.IAdresViewModel;
		DefaultContactPerson: PersonViewModel.IPersonViewModel;
		ContractorTradeAssociations: ContractorTradeAssociationViewModel.IContractorTradeAssociationViewModel[];
		SubContractors: SubContractorViewModel.ISubContractorViewModel[];
		Measures: MeasureViewModel.IMeasureViewModel[];
		ToJsonContract(): DataContracts.IContractorViewModel;
	}

	export class ContractorViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get Website(): string {
			return this._Website;
		}
		public set Website(newWebsite: string) {
			this._Website = newWebsite;
		}

		public get LegalForm(): ContractorViewModelEnum.LegalForm {
			return this._LegalForm;
		}
		public set LegalForm(newLegalForm: ContractorViewModelEnum.LegalForm) {
			this._LegalForm = newLegalForm;
		}

		public get FoundationYear(): number {
			return this._FoundationYear;
		}
		public set FoundationYear(newFoundationYear: number) {
			this._FoundationYear = newFoundationYear;
		}

		public get KVKNumber(): string {
			return this._KVKNumber;
		}
		public set KVKNumber(newKVKNumber: string) {
			this._KVKNumber = newKVKNumber;
		}

		public get DisputeSettlement(): boolean {
			return this._DisputeSettlement;
		}
		public set DisputeSettlement(newDisputeSettlement: boolean) {
			this._DisputeSettlement = newDisputeSettlement;
		}

		public get DisputeSettlementName(): string {
			return this._DisputeSettlementName;
		}
		public set DisputeSettlementName(newDisputeSettlementName: string) {
			this._DisputeSettlementName = newDisputeSettlementName;
		}

		public get CRMSystemName(): string {
			return this._CRMSystemName;
		}
		public set CRMSystemName(newCRMSystemName: string) {
			this._CRMSystemName = newCRMSystemName;
		}

		public get TradeAssociations(): TradeAssociationViewModel.ITradeAssociationViewModel[] {
			return this._TradeAssociations;
		}
		public set TradeAssociations(newTradeAssociations: TradeAssociationViewModel.ITradeAssociationViewModel[]) {
			this._TradeAssociations = newTradeAssociations;
		}

		public get Adres(): AdresViewModel.IAdresViewModel {
			return this._Adres;
		}
		public set Adres(newAdres: AdresViewModel.IAdresViewModel) {
			this._Adres = newAdres;
		}

		public get DefaultContactPerson(): PersonViewModel.IPersonViewModel {
			return this._DefaultContactPerson;
		}
		public set DefaultContactPerson(newDefaultContactPerson: PersonViewModel.IPersonViewModel) {
			this._DefaultContactPerson = newDefaultContactPerson;
		}

		public get ContractorTradeAssociations(): ContractorTradeAssociationViewModel.IContractorTradeAssociationViewModel[] {
			return this._ContractorTradeAssociations;
		}
		public set ContractorTradeAssociations(newContractorTradeAssociations: ContractorTradeAssociationViewModel.IContractorTradeAssociationViewModel[]) {
			this._ContractorTradeAssociations = newContractorTradeAssociations;
		}

		public get SubContractors(): SubContractorViewModel.ISubContractorViewModel[] {
			return this._SubContractors;
		}
		public set SubContractors(newSubContractors: SubContractorViewModel.ISubContractorViewModel[]) {
			this._SubContractors = newSubContractors;
		}

		public get Measures(): MeasureViewModel.IMeasureViewModel[] {
			return this._Measures;
		}
		public set Measures(newMeasures: MeasureViewModel.IMeasureViewModel[]) {
			this._Measures = newMeasures;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _Website: string = "";
		protected _LegalForm: ContractorViewModelEnum.LegalForm = undefined;
		protected _FoundationYear: number = 0;
		protected _KVKNumber: string = "";
		protected _DisputeSettlement: boolean = false;
		protected _DisputeSettlementName: string = "";
		protected _CRMSystemName: string = "";
		protected _TradeAssociations: TradeAssociationViewModel.ITradeAssociationViewModel[] = [];
		protected _Adres: AdresViewModel.IAdresViewModel = new AdresViewModel.AdresViewModel(undefined);
		protected _DefaultContactPerson: PersonViewModel.IPersonViewModel = new PersonViewModel.PersonViewModel(undefined);
		protected _ContractorTradeAssociations: ContractorTradeAssociationViewModel.IContractorTradeAssociationViewModel[] = [];
		protected _SubContractors: SubContractorViewModel.ISubContractorViewModel[] = [];
		protected _Measures: MeasureViewModel.IMeasureViewModel[] = [];

		constructor(data?: DataContracts.IContractorViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IContractorViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._Website = data.Website;
				this._LegalForm = data.LegalForm;
				this._FoundationYear = data.FoundationYear;
				this._KVKNumber = data.KVKNumber;
				this._DisputeSettlement = data.DisputeSettlement;
				this._DisputeSettlementName = data.DisputeSettlementName;
				this._CRMSystemName = data.CRMSystemName;
				this._TradeAssociations = data.TradeAssociations ? data.TradeAssociations.map(tradeAssociationViewModelItem => new TradeAssociationViewModel.TradeAssociationViewModel(tradeAssociationViewModelItem)): [];
				this._Adres = data.Adres ? new AdresViewModel.AdresViewModel(data.Adres): undefined;
				this._DefaultContactPerson = data.DefaultContactPerson ? new PersonViewModel.PersonViewModel(data.DefaultContactPerson): undefined;
				this._ContractorTradeAssociations = data.ContractorTradeAssociations ? data.ContractorTradeAssociations.map(contractorTradeAssociationViewModelItem => new ContractorTradeAssociationViewModel.ContractorTradeAssociationViewModel(contractorTradeAssociationViewModelItem)): [];
				this._SubContractors = data.SubContractors ? data.SubContractors.map(subContractorViewModelItem => new SubContractorViewModel.SubContractorViewModel(subContractorViewModelItem)): [];
				this._Measures = data.Measures ? data.Measures.map(measureViewModelItem => new MeasureViewModel.MeasureViewModel(measureViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IContractorViewModel {
			return {
				Id: this.Id,
				Name: this.Name,
				Website: this.Website,
				LegalForm: this.LegalForm,
				FoundationYear: this.FoundationYear,
				KVKNumber: this.KVKNumber,
				DisputeSettlement: this.DisputeSettlement,
				DisputeSettlementName: this.DisputeSettlementName,
				CRMSystemName: this.CRMSystemName,
				TradeAssociations: (this.TradeAssociations ? this.TradeAssociations.map(tradeAssociationViewModelItem => new TradeAssociationViewModel.TradeAssociationViewModel(tradeAssociationViewModelItem).ToJsonContract()): []),
				Adres: (this.Adres ? new AdresViewModel.AdresViewModel(this.Adres).ToJsonContract(): this.Adres),
				DefaultContactPerson: (this.DefaultContactPerson ? new PersonViewModel.PersonViewModel(this.DefaultContactPerson).ToJsonContract(): this.DefaultContactPerson),
				ContractorTradeAssociations: (this.ContractorTradeAssociations ? this.ContractorTradeAssociations.map(contractorTradeAssociationViewModelItem => new ContractorTradeAssociationViewModel.ContractorTradeAssociationViewModel(contractorTradeAssociationViewModelItem).ToJsonContract()): []),
				SubContractors: (this.SubContractors ? this.SubContractors.map(subContractorViewModelItem => new SubContractorViewModel.SubContractorViewModel(subContractorViewModelItem).ToJsonContract()): []),
				Measures: (this.Measures ? this.Measures.map(measureViewModelItem => new MeasureViewModel.MeasureViewModel(measureViewModelItem).ToJsonContract()): [])
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ContractorAllViewModel
	 */
	 

	export interface IContractorAllViewModel {
		Contractors: ContractorViewModel[];
		ToJsonContract(): DataContracts.IContractorAllViewModel;
	}

	export class ContractorAllViewModel {
		// property-Properties
		public get Contractors(): ContractorViewModel[] {
			return this._Contractors;
		}
		public set Contractors(newContractors: ContractorViewModel[]) {
			this._Contractors = newContractors;
		}

		// field-Properties

		// fields
		protected _Contractors: ContractorViewModel[] = [];

		constructor(data?: DataContracts.IContractorAllViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IContractorAllViewModel): void {
			if(data) {
				// vul properties
				this._Contractors = data.Contractors ? data.Contractors.map(contractorViewModelItem => new ContractorViewModel(contractorViewModelItem)): [];
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IContractorAllViewModel {
			return {
				Contractors: (this.Contractors ? this.Contractors.map(contractorViewModelItem => new ContractorViewModel(contractorViewModelItem).ToJsonContract()): [])
			}
		}
	}
