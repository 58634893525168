/// <reference path='../consumerinterfaces/FabrikantenAdminController.ts' />
/**
 * @ref WoonConnectApiController/FabrikantenAdmin/FabrikantenAdminController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/FabrikantenAdminController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as FabrikantenAdminModels from "@datacontracts/FabrikantenAdminModels";
import * as request from "../../../helpers/IRequestHandler";





	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class FabrikantenAdminConsumer implements Interface.IFabrikantenAdminConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public SearchProduct_Observable(pRequestModel: FabrikantenAdminModels.IFabrikantenAdminSearchProductRequestModel & {ToJsonContract(noContent?: boolean): FabrikantenAdminModels.IFabrikantenAdminSearchProductRequestModel}): Observable<FabrikantenAdminModels.IFabrikantenAdminSearchProductResponseModel> {
				const pRequest = pRequestModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pRequest;
				let apiURL = `api/FabrikantenAdmin/SearchProduct/${pRequest}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FabrikantenAdminModels.IFabrikantenAdminSearchProductResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SearchProduct(pRequestModel: FabrikantenAdminModels.IFabrikantenAdminSearchProductRequestModel & {ToJsonContract(noContent?: boolean): FabrikantenAdminModels.IFabrikantenAdminSearchProductRequestModel}): ng.IPromise<FabrikantenAdminModels.IFabrikantenAdminSearchProductResponseModel> {
				let obs = this.SearchProduct_Observable(pRequestModel);
				let promise = new Promise<FabrikantenAdminModels.IFabrikantenAdminSearchProductResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public KoppelFilterOption_Observable(pRequestModel: FabrikantenAdminModels.IFabrikantenAdminKoppelFilterOptionRequestModel & {ToJsonContract(noContent?: boolean): FabrikantenAdminModels.IFabrikantenAdminKoppelFilterOptionRequestModel}): Observable<FabrikantenAdminModels.IFabrikantenAdminKoppelFilterOptionResponseModel> {
				const pRequest = pRequestModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pRequest;
				let apiURL = `api/FabrikantenAdmin/KoppelFilterOption/${pRequest}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<FabrikantenAdminModels.IFabrikantenAdminKoppelFilterOptionResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public KoppelFilterOption(pRequestModel: FabrikantenAdminModels.IFabrikantenAdminKoppelFilterOptionRequestModel & {ToJsonContract(noContent?: boolean): FabrikantenAdminModels.IFabrikantenAdminKoppelFilterOptionRequestModel}): ng.IPromise<FabrikantenAdminModels.IFabrikantenAdminKoppelFilterOptionResponseModel> {
				let obs = this.KoppelFilterOption_Observable(pRequestModel);
				let promise = new Promise<FabrikantenAdminModels.IFabrikantenAdminKoppelFilterOptionResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AddProducts_Observable(pInputModel: FabrikantenAdminModels.IFabrikantenAdminSearchProductResponseModel & {ToJsonContract(noContent?: boolean): FabrikantenAdminModels.IFabrikantenAdminSearchProductResponseModel}): Observable<void> {
				const pInput = pInputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = pInput;
				let apiURL = `api/FabrikantenAdmin/AddProducts/${pInput}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<void>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AddProducts(pInputModel: FabrikantenAdminModels.IFabrikantenAdminSearchProductResponseModel & {ToJsonContract(noContent?: boolean): FabrikantenAdminModels.IFabrikantenAdminSearchProductResponseModel}): ng.IPromise<void> {
				let obs = this.AddProducts_Observable(pInputModel);
				let promise = new Promise<void>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}
