/**
 * @ref /IDocumentRepository.cs
 */
	/**
	* Originele namespace: WoonConnectDatabase.DatabaseModel.Repositories.MapType
	*/
	export enum MapType {
		Standaard = -1,
		
		Verbergen = 1,
		
		IngediendeOpties = 2,
		
		Offertes = 3,
		
		AlgemeneDocumenten = 4,
		
		PersoonlijkeMap = 5,
		
		WoningMap = 6,
		
		PriveMap = 7,
		
		Contractor = 8
	}

