<!-- Algemeen -->
<wct-survey-question-group groupname="Algemeen">
  <wct-survey-question
    question = "Type verwarming"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.HeatingType">
      <mat-radio-button class="mat-body-1" [value]="HeatingType.Common">Gemeenschappelijk</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="HeatingType.Individual">Individueel</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>
</wct-survey-question-group>

<!-- Verwarmingsapparaat -->
<wct-survey-question-group groupname="Verwarmingsapparaat">
  <wct-survey-question
    question = "Type verwarmingsapparaat"
  >
    <mat-form-field>
      <mat-select [ngrxFormControlState]="system.controls.HeatingDevice">
        <mat-option [value]="HeatingDevice.Local" *ngIf="HeatingAllowed(HeatingDevice.Local)">Lokaal</mat-option>
        <mat-option [value]="HeatingDevice.Boiler" *ngIf="HeatingAllowed(HeatingDevice.Boiler)">Ketel</mat-option>
        <mat-option [value]="HeatingDevice.Combiboiler" *ngIf="HeatingAllowed(HeatingDevice.Combiboiler)">Combiboiler</mat-option>
        <mat-option [value]="HeatingDevice.CombiHeatpump">Combiwarmtepomp</mat-option>
        <mat-option [value]="HeatingDevice.Heatpump">Warmtepomp</mat-option>
        <mat-option [value]="HeatingDevice.BoilerWithHeatPowerCoupling">Ketel met WKK</mat-option>
        <mat-option [value]="HeatingDevice.CombiboilerHeatPowerCoupling">Combiketel met WKK</mat-option>
        <mat-option [value]="HeatingDevice.BiomassBoiler">Biomassa ketel</mat-option>
        <mat-option [value]="HeatingDevice.BiomassCombiboiler">Biomassa combiketel</mat-option>
        <mat-option [value]="HeatingDevice.SupplyByThirdParty">Warmtelevering derden</mat-option>
        <mat-option [value]="HeatingDevice.Unknown" *ngIf="HeatingAllowed(HeatingDevice.Unknown)">Onbekend</mat-option>
      </mat-select>
    </mat-form-field>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="HeatingDeviceEnergyTypeAllowedAny()"
    question = "Gasgestookt of elektrisch?"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.HeatingDeviceEnergyType">
      <mat-radio-button class="mat-body-1" [value]="HeatingDeviceEnergyType.Gas">Gas</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="HeatingDeviceEnergyType.Electricity">Elektriciteit</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="BoilerTypeAny()"
    question = "Keteltype"
  >
    <mat-form-field >
      <mat-select [ngrxFormControlState]="system.controls.BoilerType">
        <mat-option [value]="BoilerType.CR">CR ketel</mat-option>
        <mat-option [value]="BoilerType.VR">VR ketel</mat-option>
        <mat-option [value]="BoilerType.HR100">HR100 ketel</mat-option>
        <mat-option [value]="BoilerType.HR104">HR104 ketel</mat-option>
        <mat-option [value]="BoilerType.HR107">HR107 ketel</mat-option>
      </mat-select>
    </mat-form-field>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="ShowCWClassQuestion()"
    question = "CW klasse?"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.CWClass">
      <mat-radio-button class="mat-body-1" [value]="CWClass.CW3">CW3</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="CWClass.CW4">CW4</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="CWClass.CW5">CW5</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="CWClass.CW6">CW6</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="ShowGasHrLabelQuestion()"
    question = "Gaskeur HRww label?"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.GasHrLabel">
      <mat-radio-button class="mat-body-1" [value]="GasHrLabel.Yes">Ja</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="GasHrLabel.No">Nee</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="OtherFunctionsAny()"
    question = "Overige functies?"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.OtherFunctions">
      <mat-radio-button class="mat-body-1" [value]="OtherFunctions.Ventilation">Ventilatie</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="OtherFunctions.Cooling">Koeling</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="OtherFunctions.VentilatingAndCooling">Ventilatie en koeling</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="HeatingPumpSourceAny()"
    question = "Bron"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.HeatingPumpSource">
      <mat-radio-button class="mat-body-1" [value]="HeatingPumpSource.Bottom">Bodem</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="HeatingPumpSource.OutsideAir">Buitenlucht</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="HeatingPumpSource.GroundWater">Grondwater</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="HeatingPumpSource.SurfaceWater">Oppervlaktewater</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="AllElectricAny()"
    question = "All electric of hybride?"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.AllElectric">
      <mat-radio-button class="mat-body-1" [value]="AllElectric.AllElectric">All electric</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="AllElectric.Hybrid">Hybride</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="ShowLocalHeatingTypeQuestion()"
    question = "Type lokale verwarming"
  >
    <mat-form-field >
      <mat-select [ngrxFormControlState]="system.controls.LocalHeatingType">
        <mat-option [value]="LocalHeatingType.LocalGasStove">Lokale gaskachel</mat-option>
        <mat-option [value]="LocalHeatingType.LocalOilStove">Lokale oliekachel</mat-option>
        <mat-option [value]="LocalHeatingType.ElectricalHeatingBuiltin">Elektrische verwarming inbouw</mat-option>
        <mat-option [value]="LocalHeatingType.ElectricalHeatingFreestanding">Elektrische verwarming vrijstaand</mat-option>
        <mat-option [value]="LocalHeatingType.BiomassStoveFreestanding">Biomassa kachel \ vrijstaande houtkachel</mat-option>
        <mat-option [value]="LocalHeatingType.BiomassStoveBuiltIn">Biomassa kachel \ inbouwinzetkachel</mat-option>
        <mat-option [value]="LocalHeatingType.BiomassStovePellet">Biomassa kachel \ pellet</mat-option>
      </mat-select>
    </mat-form-field>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="ShowDrainQuestion()"
    question = "Afvoer aanwezig?"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.Drain">
      <mat-radio-button class="mat-body-1" [value]="Drain.DrainAvailable">Ja</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="Drain.DrainNotAvailable">Nee</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="ShowBiomassAccumulateQuestion()"
    question = "Accumelerend?"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.BiomassAccumulate">
      <mat-radio-button class="mat-body-1" [value]="BiomassAccumulate.No">Nee</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="BiomassAccumulate.Yes">Ja</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="ShowGeneratorPowerHreWKKQuestion()"
    question = "Vermogen van de Hre WKK ketel?"
  >
    <mat-form-field >
      <mat-select [ngrxFormControlState]="system.controls.GeneratorPowerHreWKK">
        <mat-option [value]="GeneratorPowerHreWKK.SmallerOrEqual2kW">kleiner of gelijk aan 2kW</mat-option>
        <mat-option [value]="GeneratorPowerHreWKK.Between2KwAnd20Kw">van 2kW tot en met 20kW</mat-option>
        <mat-option [value]="GeneratorPowerHreWKK.Between20KwAnd200Kw">van 20kW tot en met 200kW</mat-option>
        <mat-option [value]="GeneratorPowerHreWKK.Between200KwAnd500Kw">van 200kW tot en met 500kW</mat-option>
        <mat-option [value]="GeneratorPowerHreWKK.Between500KwAnd1000Kw">van 500kW tot en met 1000kW</mat-option>
        <mat-option [value]="GeneratorPowerHreWKK.MoreThan1000Kw">meer dan 1000kW</mat-option>
      </mat-select>
    </mat-form-field>
  </wct-survey-question>
</wct-survey-question-group>

<!-- Distributiesysteem verwarming -->
<wct-survey-question-group groupname="Distributiesysteem verwarming" *ngIf="!ShowLocalHeatingTypeQuestion()">
  <wct-survey-question
    *ngIf="HeatingDistributionAny()"
    question = "Distributiemedium voor warmtetransport"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.HeatingDistribution">
      <mat-radio-button class="mat-body-1" [value]="HeatingDistribution.Water">Water</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="HeatingDistribution.Air">Lucht</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="ShowDistributionSystemTypeQuestion()"
    question = "Type distributiesysteem"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.DistributionSystemType">
      <mat-radio-button class="mat-body-1" [value]="DistributionSystemType.OnePipe">1 pijpssysteem</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="DistributionSystemType.TwoPipe">2 pijpssyteem</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="DistributionSystemType.RenovatedOnePipe">gerenoveerd 1 pijpssysteem</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="ShowDistributionInsulationPipesQuestion()"
    question = "Zijn de leidingen geisoleerd?"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.DistributionInsulationPipes">
      <mat-radio-button class="mat-body-1" [value]="DistributionInsulationPipes.Yes">Ja</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="DistributionInsulationPipes.No">Nee</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="DistributionInsulationPipes.Unknown">Onbekend</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>

  <wct-survey-question
    *ngIf="ShowDistributionPipesThroughNonInsulatedRoomQuestion()"
    question = "Leidingen door onverwarmde ruimte?"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.DistributionPipesThroughNonInsulatedRoom">
      <mat-radio-button class="mat-body-1" [value]="DistributionPipesThroughNonInsulatedRoom.Yes">Ja</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="DistributionPipesThroughNonInsulatedRoom.No">Nee</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>
</wct-survey-question-group>

<!-- Ventilatiesysteem -->
<wct-survey-question-group groupname="Ventilatiesysteem" *ngIf="VentilationSupplyAny()">
  <wct-survey-question
    *ngIf="VentilationSupplyAny()"
    question = "Ventilatietoevoer?"
  >
    <mat-radio-group [ngrxFormControlState]="system.controls.VentilationSupply">
      <mat-radio-button class="mat-body-1" [value]="VentilationSupply.Mechanical">Mechanisch</mat-radio-button>
      <mat-radio-button class="mat-body-1" [value]="VentilationSupply.Natural">Natuurlijk</mat-radio-button>
    </mat-radio-group>
  </wct-survey-question>
</wct-survey-question-group>
