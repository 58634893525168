import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { UpgradeModule } from '@angular/upgrade/static';
// import { RouterModule, Routes } from '@angular/router';
//declare var angular: angular.IAngularStatic;

// import { HttpClientModule } from '@angular/common/http';

import { CoreUiModule } from '../coreui/coreui.module';
import { TranslateModule } from '../infrastructure/translate.module';


// import { ThemeModule, defaultTheme, assaabloyTheme, merfordTheme, imsTheme } from '../fabrikanten/theme';

// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
// 	suppressScrollX: true
// };

//WoonConnect Admin
// import { WctAdminNg8Component } from './WctAdminNg8';
// import { WctAdminProjectSettingsComponent } from './ProjectSettings/WctAdminProjectSettings';
// import { WctAdminProjectsOverviewComponent } from './Projects/WctAdminProjectsOverview';
// import { WctAdminUsersComponent } from './Users/WctAdminUsers';
// import { WctAdminUserDetailComponent } from './Users/WctAdminUserDetail';
// import { WctAdminBuildingsComponent } from './Buildings/WctAdminBuildings';
// import { WctAdminAddBuildingComponent } from './Buildings/WctAdminAddBuilding';
// import { WctAdminBuildingDetailComponent } from './Buildings/WctAdminBuildingDetail';
// import { WctAdminStreetsComponent } from './Streets/WctAdminStreets';
// import { WctAdminDistrictsComponent } from './Districts/WctAdminDistricts';

// import { WctEnergybuddyComponent } from './WctAccount';
// import { WctEnergieflowBewonersComponent } from './components/WctEnergieflowBewoners';
// import { WctAanwezigheidComponent } from './components/WctAanwezigheid';
// import { WctHuishoudenComponent } from './components/WctHuishouden';
// import { WctHuishoudenPersoonComponent } from './components/WctHuishoudenPersoon';
// import { WctEnergieBewustComponent } from './components/WctEnergieBewust';
// import { WctEnergieBewustEenvoudigComponent } from './components/WctEnergieBewustEenvoudig';
// import { WctEnergieBewustUitgebreidComponent } from './components/WctEnergieBewustUitgebreid';

// import { EnergybuddyService } from './services/EnergybuddyService';
import { WctAccountMenu } from './components/account/WctAccountMenu';
import { WctAccountSettings } from './components/account/WctAccountSettings';
import { WctAccountSettingsCompanyProfile } from './components/account/WctAccountSettingsCompanyProfile';
import { WctDisclaimer } from './components/disclaimer/WctDisclaimer';
import { WctDisclaimerDialog } from './components/disclaimer/WctDisclaimerDialog';
import { WctAccountModuleComponent } from './WctAccountModule';


// import { ViewerModule }  from '../viewers/viewer.module';

// import { OrderByService } from '../services/OrderByService';
// import { GoogleAnalyticsService } from '../services/GoogleAnalyticsService';

// import { SlickCarouselModule } from 'ngx-slick-carousel';

// import { CdkTreeModule } from '@angular/cdk/tree';
// import { DragDropModule } from '@angular/cdk/drag-drop';

// import { MatMenuModule } from '@angular/material/menu';
// import { DatePipe } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { MatDatepickerModule} from '@angular/material/datepicker';
// import { MatNativeDateModule} from '@angular/material/core';
import { MatInputModule}  from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatDialogModule} from '@angular/material/dialog';
// import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatSelectModule} from '@angular/material/select';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatRadioModule} from '@angular/material/radio';
// import { MatTableModule} from '@angular/material/table';
import { MatCheckboxModule} from '@angular/material/checkbox';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { MatExpansionModule } from '@angular/material/expansion';
// import { MatTooltipModule} from '@angular/material/tooltip';
// import { MatTreeModule} from '@angular/material/tree';
// import { MatIconModule } from '@angular/material/icon';
// import { MatCardModule } from '@angular/material/card';
// import { MatTabsModule} from '@angular/material/tabs';
// import { MatBottomSheetModule} from '@angular/material/bottom-sheet';
// import { MatGridListModule} from '@angular/material/grid-list';
// import { MatSidenavModule} from '@angular/material/sidenav';
// import { MatButtonModule} from '@angular/material/button';
// import { MatListModule } from '@angular/material/list';
// import { MatProgressBarModule } from '@angular/material/progress-bar';

// import { CommonModule } from '@angular/common';

// import { AdminGuard } from '../auth/auth.guard';

// import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from '../helpers/FormatDates';

// import { registerLocaleData } from '@angular/common';
// import localeNl from '@angular/common/locales/nl';
// import { MatPaginatorModule } from '@angular/material/paginator';
// import { MatSortModule } from '@angular/material/sort';
// registerLocaleData(localeNl, 'nl');

const AngularMaterialModules = [
// 	CdkTreeModule,
// 	DragDropModule,
	MatInputModule,
// 	MatDatepickerModule,
// 	MatNativeDateModule,
	MatFormFieldModule,
	MatDialogModule,
	MatSelectModule,
	MatProgressSpinnerModule,
	MatRadioModule,
// 	MatTableModule,
	MatCheckboxModule,
// 	MatTooltipModule,
// 	MatTreeModule,
// 	MatIconModule,
// 	MatCardModule,
// 	MatTabsModule,
// 	MatBottomSheetModule,
// 	MatGridListModule,
// 	MatListModule,
// 	MatSidenavModule,
// 	MatSlideToggleModule,
// 	MatButtonToggleModule,
// 	MatButtonModule,
// 	MatProgressBarModule,
// 	MatMenuModule,
// 	MatSortModule,
// 	MatPaginatorModule,
// 	MatExpansionModule
];

// const routes: Routes = [
// 		// { path: 'users', component: WctAdminUsersComponent, canActivate: [AdminGuard] },
// 		// { path: 'user-detail/:userid', component: WctAdminUserDetailComponent, canActivate: [AdminGuard] },
// 		// { path: 'buildings', component: WctAdminBuildingsComponent, canActivate: [AdminGuard] },
// 		// { path: 'building-detail/:adresid', component: WctAdminBuildingDetailComponent , canActivate: [AdminGuard]},
// 		// { path: 'add-building', component: WctAdminAddBuildingComponent, canActivate: [AdminGuard] },
// 		// { path: 'projects', component: WctAdminProjectsOverviewComponent, canActivate: [AdminGuard] },
// 		// { path: 'project-settings', component: WctAdminProjectSettingsComponent, canActivate: [AdminGuard] },
// 		// { path: 'streets', component: WctAdminStreetsComponent, canActivate: [AdminGuard] },
// 		// { path: 'districts', component: WctAdminDistrictsComponent, canActivate: [AdminGuard] },
// ];

@NgModule({
	imports: [
		// ViewerModule,
		CommonModule,
		// BrowserAnimationsModule,
		MatInputModule,
    MatFormFieldModule,
    FormsModule,
		ReactiveFormsModule,
		AngularMaterialModules,
		// RouterModule.forChild(routes),
		// RouterModule.forRoot(
		// 	routes
		// ),
		CoreUiModule,
    TranslateModule
		// PerfectScrollbarModule
		// ThemeModule.forRoot({
		// 	themes: [defaultTheme, assaabloyTheme, merfordTheme, imsTheme],
		// 	active: 'default'
		// }),
		// SlickCarouselModule
	],
	exports: [
    // WctEnergybuddyComponent,
    // WctAanwezigheidComponent,
    // WctHuishoudenComponent,
    // WctHuishoudenPersoonComponent
    // RouterModule,
		// MatTableModule,
		// MatPaginatorModule,
		// MatSortModule
    WctAccountModuleComponent,
    WctAccountMenu,
	  WctDisclaimer,
    WctDisclaimerDialog
	],
	declarations: [
    WctAccountMenu,
    WctAccountSettings,
    WctAccountSettingsCompanyProfile,
    WctDisclaimer,
    WctDisclaimerDialog,
    WctAccountModuleComponent
	],
	entryComponents: [
		// WctEnergybuddyComponent
    WctAccountModuleComponent,
    WctAccountMenu,
	  WctDisclaimer,
    WctDisclaimerDialog
	],
	bootstrap: [
		 ],
	providers: [
    // EnergybuddyService
		// HttpClientModule,
		// DatePipe,
		// GoogleAnalyticsService,
		// { provide: LOCALE_ID, useValue: 'nl' },
		// { provide: MAT_DATE_LOCALE, useValue: 'nl' },
		// { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
	]
})

export class AccountModule {
}

