/**
 * @ref WoonConnectViewModel/Survey/UploadNoteModel.cs
 */

 import * as DataContracts from "@datacontracts/UploadNoteModel";

 
import * as WoningSelectie from "./WoningSelectie";



	/**
	 * Originele namespace: WoonConnectViewModel.Survey.UploadNoteRequestModel
	 */
	 

	export interface IUploadNoteRequestModel {
		Dwelling: WoningSelectie.IWoningSelectie;
		NoteId: string;
		ModelBytes: number[];
		ToJsonContract(): DataContracts.IUploadNoteRequestModel;
	}

	export class UploadNoteRequestModel {
		// property-Properties
		public get Dwelling(): WoningSelectie.IWoningSelectie {
			return this._Dwelling;
		}
		public set Dwelling(newDwelling: WoningSelectie.IWoningSelectie) {
			this._Dwelling = newDwelling;
		}

		public get NoteId(): string {
			return this._NoteId;
		}
		public set NoteId(newNoteId: string) {
			this._NoteId = newNoteId;
		}

		public get ModelBytes(): number[] {
			return this._ModelBytes;
		}
		public set ModelBytes(newModelBytes: number[]) {
			this._ModelBytes = newModelBytes;
		}

		// field-Properties

		// fields
		protected _Dwelling: WoningSelectie.IWoningSelectie = new WoningSelectie.WoningSelectie(undefined);
		protected _NoteId: string = "";
		protected _ModelBytes: number[] = [];

		constructor(data?: DataContracts.IUploadNoteRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUploadNoteRequestModel): void {
			if(data) {
				// vul properties
				this._Dwelling = data.Dwelling ? new WoningSelectie.WoningSelectie(data.Dwelling): undefined;
				this._NoteId = data.NoteId;
				this._ModelBytes = data.ModelBytes;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IUploadNoteRequestModel {
			return {
				Dwelling: (this.Dwelling ? new WoningSelectie.WoningSelectie(this.Dwelling).ToJsonContract(): this.Dwelling),
				NoteId: this.NoteId,
				ModelBytes: this.ModelBytes
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Survey.UploadNoteResponseModel
	 */
	 

	export interface IUploadNoteResponseModel {
		Succeeded: boolean;
	}

	export class UploadNoteResponseModel {
		// property-Properties
		// field-Properties
		public get Succeeded(): boolean {
			return this._Succeeded;
		}
		public set Succeeded(newSucceeded: boolean) {
			this._Succeeded = newSucceeded;
		}

		// fields
		protected _Succeeded: boolean = false;

		constructor(data?: DataContracts.IUploadNoteResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IUploadNoteResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Succeeded = data.Succeeded;
			}
		}
	}
