import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DwellingSelectorComponent } from './components/dwelling-selector/dwelling-selector.component';
import {CoreProvidersModule} from '../../viewers/coreeproviders.module';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  declarations: [
    DwellingSelectorComponent
  ],
  imports: [
    CommonModule,
    CoreProvidersModule,
    MatSelectModule,
  ],
  exports: [
    DwellingSelectorComponent,
  ]
})
export class DwellingmodelModule { }
