<b>product search</b>
<br />
<br />

<div *ngIf="Loading">
   Laden...
</div>

<div *ngIf="!Loading">
   <table>
      <tr>
         <td></td>
         <td>Naam</td>
         <td>Bot</td>
         <td>DO1Naam</td>
         <td>DO2Naam</td>
         <td>DO3Naam</td>
         <td>DO4Naam</td>
         <td>Kenmerk</td>
      </tr>
      <tr>
         <td><b>Contains</b></td>
         <td><input type="text" [(ngModel)]="Request.Naam" /> </td>
         <td><input type="text" [(ngModel)]="Request.BOT" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO1Naam" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO2Naam" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO3Naam" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO4Naam" /> </td>
         <td><input type="text" [(ngModel)]="Request.Kenmerk" /> </td>
      </tr>
      <tr>
         <td><b>Ignores</b></td>
         <td><input type="text" [(ngModel)]="Request.NaamIgnore1" /> </td>
         <td><input type="text" [(ngModel)]="Request.BOTIgnore1" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO1NaamIgnore1" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO2NaamIgnore1" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO3NaamIgnore1" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO4NaamIgnore1" /> </td>
         <td><input type="text" [(ngModel)]="Request.KenmerkIgnore1" /> </td>
      </tr>
      <tr>
         <td><b>Ignores</b></td>
         <td><input type="text" [(ngModel)]="Request.NaamIgnore2" /> </td>
         <td><input type="text" [(ngModel)]="Request.BOTIgnore2" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO1NaamIgnore2" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO2NaamIgnore2" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO3NaamIgnore2" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO4NaamIgnore2" /> </td>
         <td><input type="text" [(ngModel)]="Request.KenmerkIgnore2" /> </td>
      </tr>
      <tr>
         <td><b>Ignores</b></td>
         <td><input type="text" [(ngModel)]="Request.NaamIgnore3" /> </td>
         <td><input type="text" [(ngModel)]="Request.BOTIgnore3" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO1NaamIgnore3" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO2NaamIgnore3" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO3NaamIgnore3" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO4NaamIgnore3" /> </td>
         <td><input type="text" [(ngModel)]="Request.KenmerkIgnore3" /> </td>
      </tr>
      <tr>
         <td><b>Ignores</b></td>
         <td><input type="text" [(ngModel)]="Request.NaamIgnore4" /> </td>
         <td><input type="text" [(ngModel)]="Request.BOTIgnore4" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO1NaamIgnore4" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO2NaamIgnore4" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO3NaamIgnore4" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO4NaamIgnore4" /> </td>
         <td><input type="text" [(ngModel)]="Request.KenmerkIgnore4" /> </td>
      </tr>
      <tr>
         <td><b>Ignores</b></td>
         <td><input type="text" [(ngModel)]="Request.NaamIgnore5" /> </td>
         <td><input type="text" [(ngModel)]="Request.BOTIgnore5" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO1NaamIgnore5" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO2NaamIgnore5" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO3NaamIgnore5" /> </td>
         <td><input type="text" [(ngModel)]="Request.DO4NaamIgnore5" /> </td>
         <td><input type="text" [(ngModel)]="Request.KenmerkIgnore5" /> </td>
      </tr>
   </table>

   <button mat-raised-button color="primary" type="submit" (click)="Koppel()">Zoek</button>

   <div *ngIf="Response != undefined">
     {{ Response.Message }}

     <table style="width: 100%;">
       <tr>
         <td>Naam</td>
         <td>Bot</td>
         <td>DO1Naam</td>
         <td>DO2Naam</td>
         <td>DO3Naam</td>
         <td>DO4Naam</td>
         <td>Kenmerk</td>
       </tr>
       <tr *ngFor="let productvariantmodel of Response.ProductVariantModels">
         <td>{{ productvariantmodel.Name }}</td>
         <td>{{ productvariantmodel.Bot }}</td>
         <td>{{ productvariantmodel.DO1Naam }}</td>
         <td>{{ productvariantmodel.DO2Naam }}</td>
         <td>{{ productvariantmodel.DO3Naam }}</td>
         <td>{{ productvariantmodel.DO4Naam }}</td>
         <td>{{ productvariantmodel.Kenmerk }}</td>
       </tr>
     </table>

     <div>
       Filter option
       <select [(ngModel)]="SelectedFilterId" (change)="FilterChanged()">
         <option *ngFor="let filter of FiltersViewModel.Filters" [ngValue]="filter.Id">{{ filter.Name }}</option>
       </select>
       <select [(ngModel)]="Request.FilterOptionId" (change)="FilterChanged()">
         <option *ngFor="let filteroption of FilterOptions" [ngValue]="filteroption.Id">{{ filteroption.Name }}</option>
       </select>

       <button mat-raised-button color="primary" type="submit" (click)="KoppelEchtF()">Koppel</button>
     </div>

     <div>
       Product property

       <input type="text" [(ngModel)]="Request.ProductProperty" />
       <button mat-raised-button color="primary" type="submit" (click)="KoppelEchtPP()">Koppel</button>
     </div>

     <div>
       Product property option Id

       <input type="text" [(ngModel)]="Request.ProductPropertyOptionId" />
       <button mat-raised-button color="primary" type="submit" (click)="KoppelEchtPP()">Koppel</button>
     </div>

   </div>
   <br />
</div>
