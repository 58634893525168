/**
 * @ref WoonConnectViewModel/ImageAnnotation/GetAnnotatorUsersModel.cs
 */

 import * as DataContracts from "@datacontracts/GetAnnotatorUsersModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.GetAnnotatorUsersResponseModel
	 */
	 

	export interface IGetAnnotatorUsersResponseModel {
		Users: AnnotatorUser[];
	}

	export class GetAnnotatorUsersResponseModel {
		// property-Properties
		public get Users(): AnnotatorUser[] {
			return this._Users;
		}
		public set Users(newUsers: AnnotatorUser[]) {
			this._Users = newUsers;
		}

		// field-Properties

		// fields
		protected _Users: AnnotatorUser[] = [];

		constructor(data?: DataContracts.IGetAnnotatorUsersResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IGetAnnotatorUsersResponseModel): void {
			if(data) {
				// vul properties
				this._Users = data.Users ? data.Users.map(annotatorUserItem => new AnnotatorUser(annotatorUserItem)): [];
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.AnnotatorUser
	 */
	 

	export interface IAnnotatorUser {
		PersoonID: number;
		Username: string;
		ToJsonContract(): DataContracts.IAnnotatorUser;
	}

	export class AnnotatorUser {
		// property-Properties
		public get PersoonID(): number {
			return this._PersoonID;
		}
		public set PersoonID(newPersoonID: number) {
			this._PersoonID = newPersoonID;
		}

		public get Username(): string {
			return this._Username;
		}
		public set Username(newUsername: string) {
			this._Username = newUsername;
		}

		// field-Properties

		// fields
		protected _PersoonID: number = 0;
		protected _Username: string = "";

		constructor(data?: DataContracts.IAnnotatorUser) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAnnotatorUser): void {
			if(data) {
				// vul properties
				this._PersoonID = data.PersoonID;
				this._Username = data.Username;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAnnotatorUser {
			return {
				PersoonID: this.PersoonID,
				Username: this.Username
			}
		}
	}
