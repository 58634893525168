<div>

    <!-- Add FormControl for add builidng-->
    <mat-card>
        <mat-card-title>Nieuwe woning toevoegen.</mat-card-title>
        <mat-card-content>
            <form #form="ngForm">

                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Straat</mat-label>
                        <input matInput name="streetName" [(ngModel)]="newBuilding.Adres.Straat" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Huisnummer</mat-label>
                        <input matInput name="Housenumber" [(ngModel)]="newBuilding.Adres.Nummer" ngModel required #kenmerk="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Postcode</mat-label>
                        <input matInput name="postcode" [(ngModel)]="newBuilding.Adres.Postcode" ngModel required #bot="ngModel">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Plaats</mat-label>
                        <input matInput type="number" name="plaats" [(ngModel)]="newBuilding.Adres.Plaats">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Land</mat-label>
                        <input matInput type="number" name="land" [(ngModel)]="newBuilding.Adres.Land">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Longtitude</mat-label>
                        <input matInput name="longtitude" [(ngModel)]="newBuilding.Adres.Longitude">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Latitude</mat-label>
                        <input matInput name="latitude" [(ngModel)]="newBuilding.Adres.Latitude">
                    </mat-form-field>
                </p>
                <div>
                    <button mat-raised-button color="primary" (click)="onAddBuilding()">Adres Toevoegen</button>
                </div>

            </form>

        </mat-card-content>

    </mat-card>

   
</div>