<div *ngIf="Laden" class="mat-progress-spinner__cont" style="display: grid; width: 100%;">

  <table style="height: 100%; width: 100%;">
    <tr>
      <td style="text-align: center;">
        <table style="display: inline-block;">
          <tr>
            <td style="text-align: center;"><mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner></td>
          </tr>
          <tr>
            <td><p>Het productmodel wordt geladen</p></td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

</div>

<div *ngIf="!ErrorBijLaden && !Laden" class="fab-product">
  <div class="fab-product__viewer" [ngClass]="{'fab-product__viewer--bestek': BCBProductModel.ViewSelected == 'Bestek', 'fab-product__viewer--suf': BCBProductModel.ViewSelected == 'SUF'}">
    <div class="fab-product__viewer-left-total">
      <div class="fab-product__viewer-buttons">
        <table style="width: 100%">
          <tr>
            <td style="width: 25%;">
              <div (click)="SetViewSelected('Info')" class="bestek-button" [ngClass]="{'fab-product__viewer-button--is-active': BCBProductModel.ViewSelected == 'Info'}">
                Info
              </div>
            </td>
            <td style="width: 25%;">
              <div (click)="SetViewSelected('3D')" class="bestek-button" [ngClass]="{'fab-product__viewer-button--is-active': BCBProductModel.ViewSelected == '3D'}">
                3D
              </div>
            </td>
            <td style="width: 25%;">
              <div (click)="SetViewSelected('2D')" class="bestek-button" [ngClass]="{'fab-product__viewer-button--is-active': BCBProductModel.ViewSelected == '2D'}">
                2D
              </div>
            </td>
            <td style="width: 25%;">
              <div (click)="SetViewSelected('Bestek')" class="bestek-button" [ngClass]="{'fab-product__viewer-button--is-active': BCBProductModel.ViewSelected == 'Bestek'}">
                Bestek
              </div>
            </td>
          </tr>
        </table>

      </div>
      <div class="fab-product__viewer-left" [ngClass]="{'fab-product__viewer-left--full-width': BCBProductModel.HeeftCADAbstract == true}" #fabProductViewerLeft>
        <div *ngIf="BCBProductModel.ViewSelected == 'Info'" style="border: 1px solid #000; background-color: #FFF; padding: 12px;">
          <table>
            <tr>
              <td style="vertical-align: top;">
                <div *ngIf="GetProductDescription() != ''" [innerHTML]="GetProductDescription()">

                </div>
              </td>
              <td>
                <img src="{{ GetLijnTekening() }}" />
              </td>
            </tr>

          </table>
        </div>
        <div *ngIf="BCBProductModel.ViewSelected == '3D' && !BCBProductModel.HeeftCADAbstract" class="fab-product__viewer-3d" style="border: 1px solid #000;">
          <div class="webgl-viewer" style="background-color: #FFF;">

            <table style="width: 100%;">
              <tr>
                <td style="width: 25%;">
                  <div (click)="LoadCAD()" class="bestek-button" matTooltipPosition="below" matTooltip='ARKEY/Adomi ASD downloaden' #tooltip="matTooltip">
                    <div *ngIf="CadLaden">
                      ...
                    </div>
                    <div *ngIf="!CadLaden">
                      <i class="fal fa-download ng-star-inserted"></i>
                      ARKEY/Adomi ASD
                    </div>
                  </div>
                </td>
                <td style="width: 25%;">
                  <div (click)="LoadCADIFC()" class="bestek-button" matTooltipPosition="below" matTooltip='IFC downloaden' #tooltip="matTooltip">
                    <div *ngIf="IfcLaden">
                      ...
                    </div>
                    <div *ngIf="!IfcLaden">
                      <i class="fal fa-download ng-star-inserted"></i>
                      IFC
                    </div>
                  </div>
                </td>
                <td style="width: 25%;">
                  <div (click)="LoadCADDwg3D()" class="bestek-button" matTooltipPosition="below" matTooltip='3D AutoCAD Dwg downloaden' #tooltip="matTooltip">
                    <div *ngIf="Dwg3DLaden">
                      ...
                    </div>
                    <div *ngIf="!Dwg3DLaden">
                      <i class="fal fa-download ng-star-inserted"></i>
                      3D AutoCAD Dwg
                    </div>
                  </div>
                </td>
                <td style="width: 25%;">
                  <div (click)="LoadCADDxf3D()" class="bestek-button" matTooltipPosition="below" matTooltip='3D Dxf downloaden' #tooltip="matTooltip">
                    <div *ngIf="Dxf3DLaden">
                      ...
                    </div>
                    <div *ngIf="!Dxf3DLaden">
                      <i class="fal fa-download ng-star-inserted"></i>
                      3D Dxf
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <wct-webgl-canvas (sessiegeregistreerd)="SessieGeregistreerd($event)" [sessie]="ViewerSessie" style="height: 550px;">
            </wct-webgl-canvas>

            <div class="cont-model-viewable-area" style="background-color: #FFF;">
              <table style="margin: auto;">
                <tr>
                  <td>
                    <div class="link-model-pan-left bestek-button" style="width: 25px;" (click)="PanLinks()">
                      <i class="fal fa-chevron-left"></i>
                    </div>
                  </td>
                  <td>
                    <div class="link-model-pan-top bestek-button" style="width: 25px;" (click)="PanOmhoog()">
                      <i class="fal fa-chevron-up"></i>
                    </div>
                  </td>
                  <td>
                    <div class="link-model-pan-bottom bestek-button" style="width: 25px;" (click)="PanBeneden()">
                      <i class="fal fa-chevron-down"></i>
                    </div>
                  </td>
                  <td>
                    <div class="link-model-pan-right bestek-button" style="width: 25px;" (click)="PanRechts()">
                      <i class="fal fa-chevron-right"></i>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="BCBProductModel.ViewSelected == '2D' && !BCBProductModel.HeeftCADAbstract" style="background-color: #F1EEEB; border: 1px solid #000;">
          <table style="width: 100%;">
            <tr>
              <td style="width: 25%;">
                <div (click)="LoadCAD()" class="bestek-button" matTooltipPosition="below" matTooltip='ARKEY/Adomi ASD downloaden' #tooltip="matTooltip">
                  <div *ngIf="CadLaden">
                    ...
                  </div>
                  <div *ngIf="!CadLaden">
                    <i class="fal fa-download ng-star-inserted"></i>
                    ARKEY/Adomi ASD
                  </div>
                </div>
              </td>
              <td style="width: 25%;">
                <div (click)="LoadCADIFC()" class="bestek-button" matTooltipPosition="below" matTooltip='IFC downloaden' #tooltip="matTooltip">
                  <div *ngIf="IfcLaden">
                    ...
                  </div>
                  <div *ngIf="!IfcLaden">
                    <i class="fal fa-download ng-star-inserted"></i>
                    IFC
                  </div>
                </div>
              </td>
              <td style="width: 25%;">
                <div (click)="LoadCADDwg2D()" class="bestek-button" matTooltipPosition="below" matTooltip='2D AutoCAD Dwg downloaden' #tooltip="matTooltip">
                  <div *ngIf="Dwg2DLaden">
                    ...
                  </div>
                  <div *ngIf="!Dwg2DLaden">
                    <i class="fal fa-download ng-star-inserted"></i>
                    2D AutoCAD Dwg
                  </div>
                </div>
              </td>
              <td style="width: 25%;">
                <div (click)="LoadCADDxf2D()" class="bestek-button" matTooltipPosition="below" matTooltip='2D Dxf downloaden' #tooltip="matTooltip">
                  <div *ngIf="Dxf2DLaden">
                    ...
                  </div>
                  <div *ngIf="!Dxf2DLaden">
                    <i class="fal fa-download ng-star-inserted"></i>
                    2D Dxf
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <object [data]="TrustedSVGUrl" type="image/svg+xml" style="width: 100%;"></object>
        </div>
        <div *ngIf="BCBProductModel.ViewSelected == 'Bestek'" class="fab-product__bestek">
          <table style="width: 100%;">
            <tr>
              <td style="width: 25%;">
                <div class="bestek-button" (click)="CopySUFToClipboard()" matTooltipPosition="below" matTooltip='STABU SUF naar klembord kopiëren' #tooltip="matTooltip">
                  <i class="fal fa-copy ng-star-inserted"></i>
                  STABU SUF
                </div>
              </td>
              <td style="width: 25%;">
                <div class="bestek-button" (click)="CopyBestekToClipboard()" matTooltipPosition="below" matTooltip='Tekst naar klembord kopiëren' #tooltip="matTooltip">
                  <i class="fal fa-copy ng-star-inserted"></i>
                  Tekst
                </div>
              </td>
              <td style="width: 25%;">
                <div class="bestek-button" (click)="DownloadBestekStabu()" matTooltipPosition="below" matTooltip='STABU SUF download' #tooltip="matTooltip">
                  <i class="fal fa-download ng-star-inserted"></i>
                  STABU SUF
                </div>
              </td>
              <td style="width: 25%;">
                <div class="bestek-button" (click)="DownloadBestek()" matTooltipPosition="below" matTooltip='Tekst download' #tooltip="matTooltip">
                  <i class="fal fa-download ng-star-inserted"></i>
                  Tekst
                </div>
              </td>

            </tr>
          </table>

          <div class="fab-product__viewer-notification">
            <p *ngIf="ShowNotificationCopiedToClipboard" [@inOutAnimation] (@inOutAnimation.done)="HideNotificationCopiedToClipboard($event)">
              Is gekopieerd naar clipboard
            </p>
          </div>
          <p [innerHTML]="BCBProductModel.Bestek" #fabProductBestek></p>
        </div>

        <div *ngFor="let BCBProductCADViewModel of BCBProductModel.CAD">
          <div *ngIf="BCBProductModel.ViewSelected == BCBProductCADViewModel.Naam">
            {{ BCBProductCADViewModel.Naam }}<br />
            <img src="{{ BCBProductCADViewModel.Waarde }}" />
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<div *ngIf="ErrorBijLaden">
  Error bij laden van product...
</div>
