// [conv] import "(./WctAdminProjectsOverview).less";
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

import { CleanUserDataRequestModel } from '@models/CleanUserDataModel';
import { CleanDwellingsRequestModel } from '@models/CleanDwellingsModel';
import { CleanDataConsumer } from '@consumers/CleanDataController';
import { ConfiguratorOptionConsumer } from "@consumers//ConfiguratorOptionController";
import { DownloadService } from '../../services/DownloadService';

import { GeneratePasswordsRequestModel } from '@models/GeneratePasswordsModel';

@Component({
    selector: 'wct-admin-projects-overview',
    templateUrl: './WctAdminProjectsOverview.html'
})
export class WctAdminProjectsOverviewComponent {

    CleanRequest: CleanUserDataRequestModel = new CleanUserDataRequestModel();
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private _location: Location, private cleanconsumer: CleanDataConsumer, private downloadservice: DownloadService) {
        this.CleanRequest.MetBestaandeOpties = true;
        this.CleanRequest.MetNieuweOpties = true;
        //this.CleanRequest.alleenGebruikers = true;
    }

    ResetWachtwoorden()
    {
        let req = new GeneratePasswordsRequestModel();
        this.cleanconsumer.GeneratePasswords_Observable(req).subscribe(r => {
            alert("resultaat aantal: " + r.Adressen.length);
        });
    }

    ResetWachtwoordenDoorvoeren()
    {
        if (confirm("Alle woningen worden verwijderd. Weet u het zeker?")) {
            let req = new GeneratePasswordsRequestModel();
            req.Doorvoeren = true;
            req.Aantal = 6;
            req.ToegestaneChars = 'qwertyuiopasdfghjklzxcvbnm1234567890';
            this.cleanconsumer.GeneratePasswords_Observable(req).subscribe(r => {
                this.downloadservice.DownloadFileGet(r.ModelFile.Url, r.ModelFile.Filename, r.ModelFile.MimeType);
                alert("resultaat aantal: " + r.Adressen.length);
            });
        }
    }

    Clean() {
        this.cleanconsumer.CleanUserData(this.CleanRequest).then(r => {
            alert("Wijzigingen: " + r.AantalWijzigingen);
        });
    }

    CleanDwellings() {
        if (confirm("Alle woningen worden verwijderd. Weet u het zeker?")) {
            let req = new CleanDwellingsRequestModel();
            this.cleanconsumer.CleanDwellings(req).then(r => {
                alert("resultaat: " + r.TekstUit);
            });
        }
    }
}