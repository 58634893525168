<div class="content">

  <h1>Login</h1>

  <div *ngIf="Loading" class="mat-progress-spinner__cont">
    <div style="padding-top: 300px; padding-bottom: 300px; text-align: center;">
      <div>
        <mat-progress-spinner mode="indeterminate" color="#0078be" style="display: inline-block;" strokeWidth="2" diameter="50"></mat-progress-spinner>
      </div>
      <div>
        <p>De producten worden geladen</p>
      </div>
    </div>
  </div>

  <div *ngIf="!Loading">
    <table cellpadding="5" cellspacing="5">
      <tr>
        <td>Username</td>
        <td><input type="text" [(ngModel)]="UserName" (keyup)="handleKeyUp($event)" /></td>
      </tr>
      <tr>
        <td>Password</td>
        <td><input type="password" [(ngModel)]="Password" (keyup)="handleKeyUp($event)" /></td>
      </tr>
    </table>

    <div *ngIf="Error != ''" style="color: darkred;">
      {{ Error }}
    </div>

    <br />
    <button type="submit" (click)="Login()">Login</button>
    <br />
    <br />
    <br />
  </div>

  <div *ngIf="NoAccess">
    Geen toegang tot deze tool met dit account
  </div>
</div>
