/**
 * @ref WoonConnectViewModel/Account/ForgotPasswordModel.cs
 */

 import * as DataContracts from "@datacontracts/ForgotPasswordModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Account.ForgotPasswordRequestModel
	 */
	 

	export interface IForgotPasswordRequestModel {
		EmailAdres: string;
		ToJsonContract(): DataContracts.IForgotPasswordRequestModel;
	}

	export class ForgotPasswordRequestModel {
		// property-Properties
		public get EmailAdres(): string {
			return this._EmailAdres;
		}
		public set EmailAdres(newEmailAdres: string) {
			this._EmailAdres = newEmailAdres;
		}

		// field-Properties

		// fields
		protected _EmailAdres: string = "";

		constructor(data?: DataContracts.IForgotPasswordRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IForgotPasswordRequestModel): void {
			if(data) {
				// vul properties
				this._EmailAdres = data.EmailAdres;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IForgotPasswordRequestModel {
			return {
				EmailAdres: this.EmailAdres
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Account.ForgotPasswordResponseModel
	 */
	 

	export interface IForgotPasswordResponseModel {
		Gelukt: boolean;
		TekstUit: string;
	}

	export class ForgotPasswordResponseModel {
		// property-Properties
		public get Gelukt(): boolean {
			return this._Gelukt;
		}
		public set Gelukt(newGelukt: boolean) {
			this._Gelukt = newGelukt;
		}

		// field-Properties
		public get TekstUit(): string {
			return this._TekstUit;
		}
		public set TekstUit(newTekstUit: string) {
			this._TekstUit = newTekstUit;
		}

		// fields
		protected _Gelukt: boolean = false;
		protected _TekstUit: string = "";

		constructor(data?: DataContracts.IForgotPasswordResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IForgotPasswordResponseModel): void {
			if(data) {
				// vul properties
				this._Gelukt = data.Gelukt;
				// vul fields
				this._TekstUit = data.TekstUit;
			}
		}
	}
