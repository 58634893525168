 // [conv] import "(./FabAdminRelations).less";
import { Component, Input, Output, EventEmitter, OnInit, Injectable, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { ProductPropertyViewModel, ProductRequestModel, ProductFilterRelationsViewModel, FilterRelationViewModel } from '@models/ProductsViewModel';
import { FiltersViewModel, FilterViewModel, FilterOptionVariantRelationViewModel, RelationViewModel, VariantFilterRelationsViewModel } from '@models/FilterViewModel';
import { ProductsConsumer } from '@consumers/ProductsController';
import { NestedTreeControl, FlatTreeControl } from '@angular/cdk/tree';
import { FiltersConsumer } from '@consumers/FiltersController';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatTreeNestedDataSource, MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { expand } from 'rxjs/operators';

interface FilterNode {
    Name: string;
    Id: number;
    FilterId: number;
    expandable: boolean;
    level: number;
    FilterParent: number;
    Active: boolean;
    Indeterminate: boolean;
    children?: FilterNode[];
}

interface ExampleFlatNode {
    expandable: boolean;
    Name: string;
    Id: number;
    FilterId: number;
    FilterParent: number;
    Indeterminate: boolean;
    Active: boolean;
    level: number;
}


@Component({
    selector: 'filter-variant-relation',
  templateUrl: './FabAdminFilterVariantRelation.html'
})

export class FabAdminFilterVariantRelationComponent implements OnChanges{
    @Input() ProductVariantId;
    // treeControl = new NestedTreeControl<FilterNode>(node => node.children);
    // dataSource = new MatTreeNestedDataSource<FilterNode>();
    VariantFilterRelationsViewModel: VariantFilterRelationsViewModel = new VariantFilterRelationsViewModel();
    ProductRequestModel: ProductRequestModel = new ProductRequestModel();
    FilterViewModel: FilterViewModel = new FilterViewModel();
    FilterOptionVariantRelationViewModel: FilterOptionVariantRelationViewModel = new FilterOptionVariantRelationViewModel();
    RelationViewModel: RelationViewModel = new RelationViewModel();
    activeOption: boolean;

    flatNodeMap = new Map<ExampleFlatNode, FilterNode>();
    nestedNodeMap = new Map<FilterNode, ExampleFlatNode>();


    checklistSelection = new SelectionModel<ExampleFlatNode>(true /* multiple */);
    //Selection
    selectedParent: ExampleFlatNode | null = null;
    getLevel = (node: ExampleFlatNode) => node.level;
    isExpandable = (node: ExampleFlatNode) => node.expandable;
    getChildren = (node: FilterNode): FilterNode[] => node.children;
    hasChild = (_: number, _nodeData: ExampleFlatNode) => _nodeData.expandable;
    hasNoContent = (_: number, _nodeData: ExampleFlatNode) => _nodeData.Name;

    //make nested tree a flat tree 
    private _transformer = (node: FilterNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            Name: node.Name,
            level: level,
            Id: node.Id,
            FilterId: node.FilterId,
            Indeterminate: node.Indeterminate,
            FilterParent: node.FilterParent,
            Active: node.Active
        }
    }

    treeControl = new FlatTreeControl<ExampleFlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    constructor(private route: ActivatedRoute, private filter: FiltersConsumer, private router: Router) {

    }
    ngOnInit() {
        this.Refresh();
    }
    ngOnChanges() {
        this.Refresh();
    }
    public Refresh(): void {
        this.ProductRequestModel.Id = this.ProductVariantId;
        this.filter.GetProductVariantFilterRelation_Observable(this.ProductRequestModel).subscribe((data) => {
            let tree_data: any = [];

            this.VariantFilterRelationsViewModel = new VariantFilterRelationsViewModel(data);
            this.VariantFilterRelationsViewModel.VariantFilterRelation.forEach(elem => {
                let filtersoption: any = [];
                let IndeterminateView: boolean;
                elem.ProductVariantFilterOption.forEach(filteroption => {

                    if (elem.Active === true) {
                        this.activeOption = true;
                        IndeterminateView = true;
                    } else {
                        this.activeOption = filteroption.Active;
                        if (filteroption.Active === true) {
                            IndeterminateView = true;
                        }
                    }
                    let filteroption_node: any = {
                        Id: filteroption.Id,
                        FilterParent: filteroption.FilterOption.FilterId,
                        FilterId: filteroption.FilterOption.Id,
                        Name: filteroption.FilterOption.Name,
                        Active: this.activeOption
                    }
                    filtersoption.push(filteroption_node);
                })
                if (elem.Active === true) {
                    IndeterminateView = false;
                }
           
                let filters_node: any = {
                    Id: elem.Id,
                    FilterId: elem.Filter.Id,
                    FilterParent: 0,
                    Name: elem.Filter.Name,
                    Active: elem.Active,
                    Indeterminate: IndeterminateView,
                    children: filtersoption,
                }
                tree_data.push(filters_node);
            });

            this.dataSource.data = tree_data;
        })
    }

    //  hasChild = (_: number, node: FilterNode) => !!node.children && node.children.length > 0;


    filterChange(checkedValue: boolean, node) {
        console.log(checkedValue, node);
        if (node.children != null && node.FilterParent == 0) {
            node.children.forEach(t => t.Active = checkedValue);
        }

        //check if the filter is loaded.
        if (node.FilterParent > 0) {
            let parentNode = this.dataSource.data.find(elem => elem.FilterId == node.FilterParent);

            if (parentNode.Active === true) {
                console.log(1);
                if (checkedValue === false) {
                    console.log(parentNode);
                    //change the ParentNode to false 
                    this.RelationViewModel = new RelationViewModel();

                    this.RelationViewModel.Type = "variantfilterrelation";
                    this.RelationViewModel.Id = parentNode.Id;
                    this.RelationViewModel.SecondLinkId = node.FilterParent;
                    this.RelationViewModel.FirstLinkId = this.ProductVariantId;
                    this.RelationViewModel.Active = false;

                    console.log(this.RelationViewModel);

                    this.filter.SaveRelation_Observable(this.RelationViewModel).subscribe((data) => {
                        console.log(data);

                    });

                    //Change the value of the 
                    //parentNode bestaat dus filteroptionrelation. 
                    this.RelationViewModel = new RelationViewModel();

                    this.RelationViewModel.Type = "variantfilteroptionrelation";
                    this.RelationViewModel.Id = node.Id;
                    this.RelationViewModel.SecondLinkId = node.FilterId;
                    this.RelationViewModel.FirstLinkId = this.ProductVariantId;
                    this.RelationViewModel.Active = checkedValue;

                    console.log(this.RelationViewModel);

                    this.filter.SaveRelation_Observable(this.RelationViewModel).subscribe((data) => {
                        console.log(data);

                    });
                    this.Refresh();

                }
            } else {

                //parentNode bestaat dus filteroptionrelation. 
                this.RelationViewModel = new RelationViewModel();

                this.RelationViewModel.Type = "variantfilteroptionrelation";
                this.RelationViewModel.Id = node.Id;
                this.RelationViewModel.SecondLinkId = node.FilterId;
                this.RelationViewModel.FirstLinkId = this.ProductVariantId;
                this.RelationViewModel.Active = checkedValue;

                console.log(this.RelationViewModel);

                this.filter.SaveRelation_Observable(this.RelationViewModel).subscribe((data) => {
                    console.log("Parent is False, update moet het doen ");
                    console.log(data);
                    this.Refresh();
                })
            }

            //schrijf iets om te controlleren, of alles geselecteerd is. 
            if (checkedValue == true) {
                if (parentNode.Active != checkedValue) {
                    parentNode.Active = checkedValue
                }
            } else {
                let totalChild = parentNode.children.findIndex(elem => elem.Active == checkedValue)
                console.log(totalChild);
                if (totalChild < 1) {
                    parentNode.Active = checkedValue;
                }
            }


        } else {
            //update filterrelation
            this.RelationViewModel = new RelationViewModel();

            this.RelationViewModel.Type = "variantfilterrelation";
            this.RelationViewModel.Id = node.Id;
            this.RelationViewModel.SecondLinkId = node.FilterId;
            this.RelationViewModel.FirstLinkId = this.ProductVariantId;
            this.RelationViewModel.Active = checkedValue;

            console.log(this.RelationViewModel);

            this.filter.SaveRelation_Observable(this.RelationViewModel).subscribe((data) => {
                console.log(data);
                this.Refresh();
            })

        }







    }

  
}
