// [conv] import "(./WctDocument).less";
import { Component, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { TypeRecht } from '@enums/RechtenRegel';
import { DocumentService } from '../../services/DocumentService';
import { IDocumentModel } from '@datacontracts/DocumentModel';
import { FileTypes } from '../../Models/Document';
import { WctDocumentPopupComponent } from './WctDocumentPopup';
import { WctDocumentenPopupComponent } from './WctDocumentenPopup';


@Component({
   selector: 'wct-document',
   templateUrl: './WctDocument.html'
})
export class WctDocumentComponent {
   @Input() document: IDocumentModel;
   @Input() documentIsSelected: string;
   @Input() readOnly: boolean;
   documentIsSelectedBoolean: boolean;
   types: FileTypes = new FileTypes();

   constructor (
      private documentService: DocumentService,
      private dialog: MatDialog
   ) {
      'ngInject';
   }

   ngOnChanges() {
      this.documentIsSelectedBoolean = this.documentIsSelected === 'true';
   }

   isSelected() {
      return this.document.Id == this.documentService.activeDocumentId;
   }

   select(): void {
      this.documentService.ZetActiefDocument(this.document);
   }

   filterDocumentName(documentName) {
      if (documentName !== null && documentName !== undefined) {
         documentName = documentName.replace(".html", "");
      }
      return documentName;
   }

   openPopup(): void {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = { id: this.document.Id };
      this.dialog.open(WctDocumentPopupComponent, dialogConfig);
   }

   getIconName(): string {
      return this.types.GeefImg(this.document.Bestand);
   }

   openRemovePopup() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
         documentId: this.document.Id,
         documentName: this.document.Naam
      };
      this.dialog.open(WctDocumentenPopupComponent, dialogConfig);
   }

   canDelete() {
      if (this.document != undefined && !this.readOnly) {
         for (var i = 0; i < this.document.Rechten.RechtenVoorGebruiker.length; i++) {
            if (this.document.Rechten.RechtenVoorGebruiker[i] == TypeRecht.Verwijderen) {
               return true;
            }
         }
      }

      return false;
   }

   isFolderAdmin(): boolean {
      if (this.document != undefined && !this.readOnly) {
         for (var i = 0; i < this.document.Rechten.RechtenVoorGebruiker.length; i++) {
            if (this.document.Rechten.RechtenVoorGebruiker[i] == TypeRecht.MapBeheer) {
               return true;
            }
         }
      }

      return false;
   }

   public downloadDocument(): void {
      $("body").append("<iframe src='/DocumentenDownload/DownloadDocument?id=" + this.document.Id + "' style='display: none;' ></iframe>");
   }
   
   public getIconUrl(): string {
      return "/content/images/filetypes/" + this.getIconName();
   }
}