import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FabrikantenConsumer } from '@consumers/FabrikantenController';
import { ProductsConsumer } from '@consumers/ProductsController';
import { PrijslijstConsumer } from '@consumers/PrijslijstController';
import { ProductsViewModel, ProductViewModel, ProductVariantModel } from '@models/ProductsViewModel';
import { PrijslijstNewProductViewModel, PrijslijstNewProductFilterViewModel, ExcelsViewModel, VoorwaardenViewModel, InstructieViewModel, UploadViewModel } from '@models/PrijslijstViewModel';
import { HttpClient, HttpRequest, HttpEventType, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'voorwaardenbeheer',
  templateUrl: './voorwaarden.beheer.component.html',
  styleUrls: ['./voorwaarden.beheer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class VoorwaardenBeheerComponent {


  public Loading = true;
  public LoadingInstructie = true;
  public VoorwaardenViewModel: VoorwaardenViewModel = null;
  public InstructieViewModel: InstructieViewModel = null;

  public Categorie: string = "test1";

  constructor(
    private prijslijst: PrijslijstConsumer,
    private http: HttpClient) {
    this.LoadVoorwaarden();
    this.LoadInstructie();
  }

  private LoadVoorwaarden(): void {
    this.Loading = true;

    this.prijslijst.GetVoorwaardenViewModel_Observable().subscribe((data) => {
      this.VoorwaardenViewModel = new VoorwaardenViewModel(data);
      this.Loading = false;
    });
  }

  public SaveVoorwaarden(): void {
    this.Loading = true;

    this.prijslijst.SaveVoorwaardenViewModel_Observable(this.VoorwaardenViewModel).subscribe((data) => {
      this.Loading = false;
    });
  }

  private LoadInstructie(): void {
    this.LoadingInstructie = true;

    this.prijslijst.GetInstructieViewModel_Observable().subscribe((data) => {
      this.InstructieViewModel = new InstructieViewModel(data);
      this.LoadingInstructie = false;
    });
  }

  public SaveInstructie(): void {
    this.LoadingInstructie = true;

    this.prijslijst.SaveInstructieViewModel_Observable(this.InstructieViewModel).subscribe((data) => {
      this.LoadingInstructie = false;
      this.LoadInstructie();
    });
  }

  public GetTime(): string {
  return (new Date()).getTime().toString();
}

  public uploadVoorwaarden(files) {
    if (files.length === 0)
      return;

    this.Loading = true;

    const formData = new FormData();

    for (let file of files) {
      formData.append(file.name, file);
    }

    const request = new HttpRequest('POST', `api/Fabrikanten/UploadHttpVoorwaarden`, formData);
    const authToken = "";// this.userservice.getAuthorizationToken();
    let headers = request.headers.set('Authorization', `Bearer ${authToken}`)
    const authReq = request.clone({
      headers: headers
    });

    this.http.request<void>(authReq).subscribe(r => {
      if (r.type == HttpEventType.Response) {
        this.Loading = false;
        this.LoadVoorwaarden();
      }
    });
  }

  public uploadVoorwaardenImage(files) {
    if (files.length === 0)
      return;

    this.Loading = true;

    const formData = new FormData();

    for (let file of files) {
      formData.append(file.name, file);
    }

    const request = new HttpRequest('POST', `api/Fabrikanten/UploadHttpVoorwaardenImage`, formData);
    const authToken = "";// this.userservice.getAuthorizationToken();
    let headers = request.headers.set('Authorization', `Bearer ${authToken}`)
    const authReq = request.clone({
      headers: headers
    });

    this.http.request<void>(authReq).subscribe(r => {
      if (r.type == HttpEventType.Response) {
        this.Loading = false;
        this.LoadVoorwaarden();
      }
    });
  }

  public uploadInstructie(files) {
    if (files.length === 0)
      return;

    this.Loading = true;

    const formData = new FormData();

    for (let file of files) {
      formData.append(file.name, file);
    }

    const request = new HttpRequest('POST', `api/Fabrikanten/UploadHttpInstructie`, formData);
    const authToken = "";// this.userservice.getAuthorizationToken();
    let headers = request.headers.set('Authorization', `Bearer ${authToken}`)
    const authReq = request.clone({
      headers: headers
    });

    this.http.request<void>(authReq).subscribe(r => {
      if (r.type == HttpEventType.Response) {
        this.Loading = false;
        this.LoadInstructie();
      }
    });
  }

  public uploadInstructieImage(files) {
    if (files.length === 0)
      return;

    this.Loading = true;

    const formData = new FormData();

    for (let file of files) {
      formData.append(file.name, file);
    }

    const request = new HttpRequest('POST', `api/Fabrikanten/UploadHttpInstructieImage`, formData);
    const authToken = "";// this.userservice.getAuthorizationToken();
    let headers = request.headers.set('Authorization', `Bearer ${authToken}`)
    const authReq = request.clone({
      headers: headers
    });

    this.http.request<void>(authReq).subscribe(r => {
      if (r.type == HttpEventType.Response) {
        this.Loading = false;
        this.LoadInstructie();
      }
    });
  }
}
