<div style="width: 240px; border-right: 1px solid #EEF0F3; height: 100vH; position:fixed;">
  <a href="https://www.reynaers.nl/nl-NL/architecten/home" target="_blank" class="logo">
    <img src="/Content/KlantSpecifiek/BCB21016/img/logo-ra-color.svg" height="40">
  </a>
  <div class="link">
    <a routerLink="" [class.active]="router.url === '/'">Home</a>
  </div>
  <div class="link">
    <a routerLink="/categorie/1" [class.active]="router.url === '/categorie/1'">Ramen</a>
  </div>
  <div class="link">
    <a routerLink="/categorie/2" [class.active]="router.url === '/categorie/2'">Deuren</a>
  </div>
  <div class="link">
    <a routerLink="/categorie/3" [class.active]="router.url === '/categorie/3'">Schuif- en vouwsystemen</a>
  </div>
  <div class="link">
    <a routerLink="/categorie/4" [class.active]="router.url === '/categorie/4'">Vliesgevels</a>
  </div>
  <br />
  <br />
  

</div>
