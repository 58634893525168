/**
 * @ref WoonConnect/Services/Sessie/WijzigEvent.cs
 */

 import * as DataContracts from "@datacontracts/WijzigEvent";

 
import * as WijzigScopeEnumEnum from "@enums/WijzigScopeEnum";



	/**
	 * Originele namespace: WoonConnect.Services.Sessie.WijzigEvent
	 */
	 

	export interface IWijzigEvent {
		Scope: WijzigScopeEnumEnum.WijzigScopeEnum;
		ID: number;
		Variant: number;
		BewonerID: number;
		Generatie: number;
	}

	export class WijzigEvent {
		// property-Properties
		public get Scope(): WijzigScopeEnumEnum.WijzigScopeEnum {
			return this._Scope;
		}
		public set Scope(newScope: WijzigScopeEnumEnum.WijzigScopeEnum) {
			this._Scope = newScope;
		}

		public get ID(): number {
			return this._ID;
		}
		public set ID(newID: number) {
			this._ID = newID;
		}

		public get Variant(): number {
			return this._Variant;
		}
		public set Variant(newVariant: number) {
			this._Variant = newVariant;
		}

		public get BewonerID(): number {
			return this._BewonerID;
		}
		public set BewonerID(newBewonerID: number) {
			this._BewonerID = newBewonerID;
		}

		public get Generatie(): number {
			return this._Generatie;
		}
		public set Generatie(newGeneratie: number) {
			this._Generatie = newGeneratie;
		}

		// field-Properties

		// fields
		protected _Scope: WijzigScopeEnumEnum.WijzigScopeEnum = undefined;
		protected _ID: number = 0;
		protected _Variant: number = 0;
		protected _BewonerID: number = 0;
		protected _Generatie: number = 0;

		constructor(data?: DataContracts.IWijzigEvent) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IWijzigEvent): void {
			if(data) {
				// vul properties
				this._Scope = data.Scope;
				this._ID = data.ID;
				this._Variant = data.Variant;
				this._BewonerID = data.BewonerID;
				this._Generatie = data.Generatie;
				// vul fields
			}
		}
	}
