/**
 * @ref WoonConnectViewModel/ImageAnnotation/AddUsersToProjectModel.cs
 */

 import * as DataContracts from "@datacontracts/AddUsersToProjectModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.AddUsersToProjectRequestModel
	 */
	 

	export interface IAddUsersToProjectRequestModel {
		ProjectId: number;
		PersoonIds: number[];
		ToJsonContract(): DataContracts.IAddUsersToProjectRequestModel;
	}

	export class AddUsersToProjectRequestModel {
		// property-Properties
		public get ProjectId(): number {
			return this._ProjectId;
		}
		public set ProjectId(newProjectId: number) {
			this._ProjectId = newProjectId;
		}

		public get PersoonIds(): number[] {
			return this._PersoonIds;
		}
		public set PersoonIds(newPersoonIds: number[]) {
			this._PersoonIds = newPersoonIds;
		}

		// field-Properties

		// fields
		protected _ProjectId: number = 0;
		protected _PersoonIds: number[] = [];

		constructor(data?: DataContracts.IAddUsersToProjectRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddUsersToProjectRequestModel): void {
			if(data) {
				// vul properties
				this._ProjectId = data.ProjectId;
				this._PersoonIds = data.PersoonIds;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IAddUsersToProjectRequestModel {
			return {
				ProjectId: this.ProjectId,
				PersoonIds: this.PersoonIds
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.ImageAnnotation.AddUsersToProjectResponseModel
	 */
	 

	export interface IAddUsersToProjectResponseModel {
		Success: boolean;
	}

	export class AddUsersToProjectResponseModel {
		// property-Properties
		// field-Properties
		public get Success(): boolean {
			return this._Success;
		}
		public set Success(newSuccess: boolean) {
			this._Success = newSuccess;
		}

		// fields
		protected _Success: boolean = false;

		constructor(data?: DataContracts.IAddUsersToProjectResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IAddUsersToProjectResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._Success = data.Success;
			}
		}
	}
