/**
 * @ref WoonConnectViewModel/Project/CleanDwellingsModel.cs
 */

 import * as DataContracts from "@datacontracts/CleanDwellingsModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Project.CleanDwellingsRequestModel
	 */
	 

	export interface ICleanDwellingsRequestModel {
		CleanAll: boolean;
		ToJsonContract(): DataContracts.ICleanDwellingsRequestModel;
	}

	export class CleanDwellingsRequestModel {
		// property-Properties
		public get CleanAll(): boolean {
			return this._CleanAll;
		}
		public set CleanAll(newCleanAll: boolean) {
			this._CleanAll = newCleanAll;
		}

		// field-Properties

		// fields
		protected _CleanAll: boolean = false;

		constructor(data?: DataContracts.ICleanDwellingsRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICleanDwellingsRequestModel): void {
			if(data) {
				// vul properties
				this._CleanAll = data.CleanAll;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ICleanDwellingsRequestModel {
			return {
				CleanAll: this.CleanAll
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Project.CleanDwellingsResponseModel
	 */
	 

	export interface ICleanDwellingsResponseModel {
		TekstUit: string;
	}

	export class CleanDwellingsResponseModel {
		// property-Properties
		// field-Properties
		public get TekstUit(): string {
			return this._TekstUit;
		}
		public set TekstUit(newTekstUit: string) {
			this._TekstUit = newTekstUit;
		}

		// fields
		protected _TekstUit: string = "";

		constructor(data?: DataContracts.ICleanDwellingsResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ICleanDwellingsResponseModel): void {
			if(data) {
				// vul properties
				// vul fields
				this._TekstUit = data.TekstUit;
			}
		}
	}
