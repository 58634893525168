
    <div class="container">
        <div>
            <div class="search-field">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Zoeken</mat-label>
                    <input matInput (keyup)="applyFilter($event)" #input />
                </mat-form-field>
            </div>


            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8">
                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
                    <td mat-cell *matCellDef="let data">
                        {{data.Id}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Straat">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Straatnaam</th>
                    <td mat-cell *matCellDef="let data">{{data.Straat}}</td>
                </ng-container>
                <ng-container matColumnDef="Nummer">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Huisnummer</th>
                    <td mat-cell *matCellDef="let data">{{data.Nummer}}</td>
                </ng-container>

                <ng-container matColumnDef="Postcode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Postcode</th>
                    <td mat-cell *matCellDef="let data">
                        {{data.Postcode}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Plaats">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Plaats</th>
                    <td mat-cell *matCellDef="let data">
                        {{data.Plaats}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Land">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Land</th>
                    <td mat-cell *matCellDef="let data">
                        {{data.Land}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Options">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Land</th>
                    <td mat-cell *matCellDef="let data">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>
                                <i class="fal fa-ellipsis-v"></i>
                            </mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item>
                                <mat-icon>dialpad</mat-icon>
                                <span>Bewerken</span>
                            </button>
                            <button mat-menu-item disabled>
                                <mat-icon>voicemail</mat-icon>
                                <span>Verwijderen</span>
                            </button>
                            <button mat-menu-item>
                                <mat-icon>notifications_off</mat-icon>
                                <span>Activeren</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                   
                    [routerLink]="['/wct-admin/building-detail/', row.Id]"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>

            </table>
            <mat-paginator [pageSizeOptions]="pageOptions" [length]="RowCountViewModel.Rows" (page)="pageChange($event)" showFirstLastButtons></mat-paginator>

        </div>
    </div>