<!--1. zijkant maken
2. subpagina 1 maken, bedrijfsgegevens: maak  een subkop en plaats simpelweg ��n flexibel mat-form-field:-->
<!--<mat-form-field appearance="outline" floatLabel="always">
   <mat-label>{{'CHANGEPASSWORD_YOUR-NEW-PASSWORD' | translate }}</mat-label>
   <input matInput placeholder="{{'CHANGEPASSWORD_AT-LEAST-X-CHARACTERS' | translate }}" [(ngModel)]="Wachtwoord" type="password">
</mat-form-field>-->
<!--3. vul dit form-field met back-end data zoals Kriest dit meegaf
4. copy-pasten in de rest van de pagina
5. pagina zo veel mogelijk copy-pasten naar andere pagina's: dit onderdeel moet het doen, maar dit gaat toch nooit veel aandacht krijgen!!!-->


<div class="company-profile__menu">
   <div>
      <div class="company-profile__menu__logo"></div>
      <p class="company-profile__menu__subtitle">Organisatie</p>
      <p class="company-profile__menu__item" [ngClass]="{'company-profile__menu__menu-item--is-active' : ActiveSubpage == 'Bedrijfsgegevens'}" (click)="SetMenuItem('Bedrijfsgegevens')">Bedrijfsgegevens</p>
      <p class="company-profile__menu__item" [ngClass]="{'company-profile__menu__menu-item--is-active' : ActiveSubpage == 'Brancheorganisaties'}" (click)="SetMenuItem('Brancheorganisaties')">Brancheorganisaties</p>
      <p class="company-profile__menu__item company-profile__menu__menu-item--max-width-160" [ngClass]="{'company-profile__menu__menu-item--is-active' : ActiveSubpage == 'Kwaliteitskeurmerken en certificaten'}" (click)="SetMenuItem('Kwaliteitskeurmerken en certificaten')">Kwaliteitskeurmerken en certificaten</p>
      <p class="company-profile__menu__item" [ngClass]="{'company-profile__menu__menu-item--is-active' : ActiveSubpage == 'Onderaannemers'}" (click)="SetMenuItem('Onderaannemers')">Onderaannemers</p>

      <p class="company-profile__menu__subtitle company-profile__menu__subtitle--margin-top">Assortiment</p>
      <p class="company-profile__menu__item" [ngClass]="{'company-profile__menu__menu-item--is-active' : ActiveSubpage == 'Aan te bieden maatregelen'}" (click)="SetMenuItem('Aan te bieden maatregelen')">Aan te bieden maatregelen</p>
      <p class="company-profile__menu__item company-profile__menu__menu-item--max-width-160" [ngClass]="{'company-profile__menu__menu-item--is-active' : ActiveSubpage == 'Brancheorganisaties (maatregel gerelateerd)'}" (click)="SetMenuItem('Brancheorganisaties (maatregel gerelateerd)')">Brancheorganisaties (maatregel gerelateerd)</p>
   </div>
</div>

<div [hidden]="!IsLoading()" class="mat-progress-spinner__cont">
   <mat-progress-spinner mode="indeterminate" color="#0078be" strokeWidth="2" diameter="50"></mat-progress-spinner>
   <p>{{"TENDERPORTALDISTRICTDIRECTOR_THE-TENDERS-ARE-LOADING" | translate }}</p>
</div>
<div class="company-profile__main" [hidden]="IsLoading()">
   <h2>{{ActiveSubpage}}</h2>

   <div *ngIf="ActiveSubpage == 'Bedrijfsgegevens'">
      <h3>Algemene gegevens</h3>
      <div class="company-profile__main__line">
         <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Bedrijfsnaam</mat-label>
            <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().Name">
         </mat-form-field>
         <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Website</mat-label>
            <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().Website">
         </mat-form-field>
      </div>
      <div class="company-profile__main__line">
         <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Rechtsvorm</mat-label>
            <mat-select matNativeControl [(ngModel)]="GetCurrentContractor().LegalForm" (selectionChange)="SetCurrentContractor()">
               <mat-option *ngFor="let legalform of LegalForms" [value]="legalform?.Id">
                  {{legalform?.Name}}
               </mat-option>
            </mat-select>
         </mat-form-field>
         <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Oprichtingsjaar</mat-label>
            <mat-select matNativeControl [(ngModel)]="GetCurrentContractor().FoundationYear" (selectionChange)="SetCurrentContractor()">
               <mat-option *ngFor="let year of GetYearsForFoundation()" [value]="year">
                  {{year}}
               </mat-option>
            </mat-select>
         </mat-form-field>
         <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>KvK nummer</mat-label>
            <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().KVKNumber">
         </mat-form-field>
      </div>

      <h3>Vestigingsadres</h3>
      <div class="company-profile__main__line">
         <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Straat</mat-label>
            <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().Adres.Straat">
         </mat-form-field>
         <mat-form-field class="company-profile__form-field__short" appearance="outline" floatLabel="always">
            <mat-label>Huisnummer</mat-label>
            <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().Adres.Nummer">
         </mat-form-field>
      </div>
      <div class="company-profile__main__line">
         <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Postcode</mat-label>
            <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().Adres.Postcode">
         </mat-form-field>
         <mat-form-field class="company-profile__form-field__short" appearance="outline" floatLabel="always">
            <mat-label>Plaatsnaam</mat-label>
            <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().Adres.Plaats">
         </mat-form-field>
      </div>

      <h3>Contactpersoon</h3>
      <div class="company-profile__main__line">
         <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Voornaam</mat-label>
            <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().DefaultContactPerson.FirstName">
         </mat-form-field>
         <mat-form-field class="company-profile__form-field__short" appearance="outline" floatLabel="always">
            <mat-label>Tussenvoegsel</mat-label>
            <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().DefaultContactPerson.MiddleName">
         </mat-form-field>
         <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Achternaam</mat-label>
            <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().DefaultContactPerson.LastName">
         </mat-form-field>
      </div>
      <div class="company-profile__main__line">
         <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>E-mailadres</mat-label>
            <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().DefaultContactPerson.EmailAddress">
         </mat-form-field>
         <mat-form-field class="company-profile__form-field__short" appearance="outline" floatLabel="always">
            <mat-label>Telefoonnummer</mat-label>
            <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().DefaultContactPerson.PhoneNumber">
         </mat-form-field>
      </div>

      <h3>Overige informatie</h3>
      <label>Is uw organisatie aangesloten bij een geschillenregeling?</label>
      <mat-radio-group [(ngModel)]="GetCurrentContractor().DisputeSettlement" aria-label="Is uw organisatie aangesloten bij een geschillen regeling?">
         <mat-radio-button [value]="true">Ja</mat-radio-button>
         <mat-radio-button [value]="false">Nee</mat-radio-button>
      </mat-radio-group>
      <mat-form-field *ngIf="GetCurrentContractor().DisputeSettlement" class="company-profile__form-field__long" appearance="outline" floatLabel="always">
         <mat-label>Naam van de geschillenregeling</mat-label>
         <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().DisputeSettlementName">
      </mat-form-field>

      <label>Met welk CRM systeem werkt uw organisatie?</label>
      <mat-form-field appearance="outline" floatLabel="always">
         <mat-label>Naam van het CRM systeem</mat-label>
         <input matInput placeholder="" [(ngModel)]="GetCurrentContractor().CRMSystemName">
      </mat-form-field>
   </div>

   <div *ngIf="ActiveSubpage == 'Brancheorganisaties'">
      <div *ngFor="let tradeass of TradeAssociations.TradeAssociations">
         <label>Is uw bedrijf lid van <strong>{{ tradeass.Name }}</strong>?</label>
         <mat-radio-group (change)="ChangeTA($event, tradeass)">
            <mat-radio-button [checked]="IsTAChecked(tradeass)" [value]="true">Ja</mat-radio-button>
            <mat-radio-button [checked]="!IsTAChecked(tradeass)" [value]="false">Nee</mat-radio-button>
         </mat-radio-group>

         <div *ngIf="IsTAChecked(tradeass)" class="company-profile__contractor-evidences">
            <div *ngFor="let instance of GetContractorTradeAssociations(tradeass)" class="company-profile__contractor-evidence">
               <div *ngIf="instance.Document != undefined">
                  <img src="{{ DocumentMimePreviewUrl(instance.Document) }}" name="{{ instance.Document.Naam }}" typeof="image/jpeg" />
                  <div class="company-profile__contractor-evidence__remove">
                     <i class="fal fa-times" (click)="RemoveEvidence(instance)"></i>
                  </div>
               </div>
            </div>

            <div class="company_profile__add-contractor-evidence">
               <label for="{{ 'file-' + tradeass.Id }}">Voeg afbeelding toe</label>
               <input type="file" id="{{ 'file-' + tradeass.Id }}" (change)="HandleFileInput(tradeass, $event.target.files)" class="hidden" />
            </div>
         </div>
      </div>
   </div>

   <div *ngIf="ActiveSubpage == 'Kwaliteitskeurmerken en certificaten'">
      <div *ngFor="let qualitymark of TradeAssociations.QualityMarks" class="company-profile__main__question">
         <label>Is uw bedrijf <strong>{{ qualitymark.Name }}</strong> gecertificeerd?</label>
         <mat-radio-group (change)="ChangeTA($event, qualitymark)">
            <mat-radio-button [checked]="IsTAChecked(qualitymark)" [value]="true">Ja</mat-radio-button>
            <mat-radio-button [checked]="!IsTAChecked(qualitymark)" [value]="false">Nee</mat-radio-button>
         </mat-radio-group>

         <div *ngIf="IsTAChecked(qualitymark)" class="company-profile__contractor-evidences">
            <div *ngFor="let instance of GetContractorTradeAssociations(qualitymark)" class="company-profile__contractor-evidence">
               <div *ngIf="instance.Document != undefined">
                  <img src="{{ DocumentMimePreviewUrl(instance.Document) }}" name="{{ instance.Document.Naam }}" typeof="image/jpeg" />
                  <div class="company-profile__contractor-evidence__remove">
                     <i class="fal fa-times" (click)="RemoveEvidence(instance)"></i>
                  </div>
               </div>
            </div>

            <div class="company_profile__add-contractor-evidence">
               <label for="{{ 'file-' + qualitymark.Id }}">Voeg afbeelding toe</label>
               <input type="file" id="{{ 'file-' + qualitymark.Id }}" (change)="HandleFileInput(qualitymark, $event.target.files)" class="hidden" />
            </div>
         </div>
      </div>
   </div>

   <div *ngIf="ActiveSubpage == 'Onderaannemers'">
      <label>Wilt u inschrijven voor aanbestedingen met onderaannemers?</label>
      <mat-radio-group [(ngModel)]="contractorservice.SubContractorsInvite" aria-label="Wilt u inschrijven voor aanbestedingen met onderaannemers?">
         <mat-radio-button value="Ja">Ja</mat-radio-button>
         <mat-radio-button value="Nee">Nee</mat-radio-button>
      </mat-radio-group>

      <div *ngIf="IsAllowedToAddSubContractors()" class="company-profile__add-subcontractors">
         <div *ngFor="let subcontractor of contractorservice.CurrentContractor.SubContractors; let index = index" class="company-profile__add-subcontractor">
            <div class="company-profile__add-subcontractor__title">
               <p>Onderaannemer {{index + 1}}</p>
               <i class="fal fa-times" (click)="RemoveSubContractor(subcontractor)"></i>
            </div>
            <mat-form-field appearance="outline" floatLabel="always">
               <mat-label>Naam bedrijf</mat-label>
               <input matInput placeholder="Naam bedrijf" [(ngModel)]="subcontractor.Name" (blur)="SaveCurrentContractor()">
            </mat-form-field>

            <label>Is het bedrijf VCA gecertificeerd?</label>
            <mat-radio-group [(ngModel)]="subcontractor.VCA" (change)="SaveCurrentContractor()" aria-label="Is het bedrijf VCA gecertificeerd?">
               <mat-radio-button [value]="true">Ja</mat-radio-button>
               <mat-radio-button [value]="false">Nee</mat-radio-button>
            </mat-radio-group>
         </div>
         <wct-button buttonText="Voeg onderaannemer toe" buttonRole="secundary" buttonSize="small" (click)="AddSubContractor()"></wct-button>
      </div>
   </div>

   <div *ngIf="ActiveSubpage == 'Aan te bieden maatregelen'">
      <p class="measures-subtitle__text-explanation">Selecteer de maatregelen die u kunt leveren:</p>
      <div *ngFor="let measure of Measures.Measures" class="measure">
         <mat-checkbox [disabled]="GetDisabledState(measure)" [checked]="GetCheckedState(measure)" (change)="ToggleMeasure(measure)" aria-label="filtercategorie">
            {{measure.DisplayName}}
         </mat-checkbox>
      </div>
   </div>

   <div *ngIf="ActiveSubpage == 'Brancheorganisaties (maatregel gerelateerd)'">
      <div *ngFor="let measure of GetMeasuresTradeAssociations()" class="company-profile__main__question">
         <h4>{{ measure.DisplayName }}</h4>
         <div *ngFor="let tradeass of measure.TradeAssociations">
            <label>Is uw bedrijf lid van <strong>{{ tradeass.Name }}</strong>?</label>
            <mat-radio-group (change)="ChangeTA($event, tradeass)">
               <mat-radio-button [checked]="IsTAChecked(tradeass)" [value]="true">Ja</mat-radio-button>
               <mat-radio-button [checked]="!IsTAChecked(tradeass)" [value]="false">Nee</mat-radio-button>
            </mat-radio-group>

            <div *ngIf="IsTAChecked(tradeass)" class="company-profile__contractor-evidences">
               <div *ngFor="let instance of GetContractorTradeAssociations(tradeass)" class="company-profile__contractor-evidence">
                  <div *ngIf="instance.Document != undefined">
                     <img src="{{ DocumentMimePreviewUrl(instance.Document) }}" name="{{ instance.Document.Naam }}" typeof="image/jpeg" />
                     <div class="company-profile__contractor-evidence__remove">
                        <i class="fal fa-times" (click)="RemoveEvidence(instance)"></i>
                     </div>
                  </div>
               </div>

               <div class="company_profile__add-contractor-evidence">
                  <label for="{{ 'file-' + tradeass.Id }}">Voeg afbeelding toe</label>
                  <input type="file" id="{{ 'file-' + tradeass.Id }}" (change)="HandleFileInput(tradeass, $event.target.files)" class="hidden" />
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
