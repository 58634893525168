import * as _ from 'lodash';
import * as ViewModelsInstantieDeel from '.././Models/InstantieDeel';
import * as ViewModelsWoningInstantie from '.././Models/WoningInstantie';
import * as ViewModelsMenuGroep from '.././Models/MenuGroep';
import * as ViewModelsGebouwBrokOptie from '.././Models/GebouwBrokOptie';
import * as ViewModelsIGebouwBrokKeuze from '.././Models/IGebouwBrokKeuze';

export enum GebouwType {
	NieuweWoning = 0,
	BestaandeWoning = 1,
	BestaandGebouw = 2,
	NieuwGebouw = 3,
	BestaandeWijk = 4,
	NieuweWijk = 5,
	Onbekend = 6,
}

export abstract class GebouwBrok {
	Weergave: number;
	OptieWidth: string;
	OptieHeight: string;
	OptieImageWidth: string;
	OptieImageHeight: string;
	Kolommen: number;
	DeelId: string;
	Naam: string;
	OptieLijstModule: string;
	CameraIndex: number;
	GebouwType: GebouwType;
	IsMateriaalBrok: boolean;
	DefaultOptieId: string;
	Volgorde: number;
	ReadOnly: boolean;

	Opties: Array<ViewModelsGebouwBrokOptie.GebouwBrokOptie> = [];

	//Materialen: Array<Materiaal> = [];
	ViewModels
	constructor(public MenuGroep: ViewModelsMenuGroep.MenuGroep, data: any) {
		this.Weergave = data.Weergave;
		//this.Width = 400;
		this.Kolommen = data.Kolommen;
		this.DeelId = data.DeelId;
		this.GebouwType = data.GebouwType;
		this.CameraIndex = data.CameraIndex;
		this.IsMateriaalBrok = data.IsMateriaalBrok;
		this.Naam = data.Naam;
		this.OptieLijstModule = data.OptieLijstModule;
		this.DefaultOptieId = data.DefaultOptieId;
		this.Volgorde = data.Volgorde;
		this.ReadOnly = data.ReadOnly;
		this.OptieHeight = this.OptieImageHeight = this.OptieImageWidth = this.OptieWidth = (100 / this.Kolommen).toString() + '%';
	}

	BepaalKeuzes() {
	}

	GeefOptieMetNaam(naam: string): ViewModelsGebouwBrokOptie.GebouwBrokOptie {
		var optie = _.find(this.Opties, (o) => o.OptieNaam == naam);
		return optie;
	}

	AfbeeldNaam(Instantie: ViewModelsWoningInstantie.WoningInstantie): string {
		return Instantie.Typologie.Delen[this.DeelId].AfbeeldNaam;
	}

	GeefActieveAfbeeldKeuze(Instantie: ViewModelsWoningInstantie.WoningInstantie): ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze {
		var instantiedeel = Instantie.Typologie.Delen[this.DeelId];
		var keuzes = this.GeefKeuzes();
		var eerste = _.find(keuzes, (k) => {
			return k.Actief(this, Instantie)
		}
		);
		return eerste;
	}

	ActieveAfbeelding(Instantie: ViewModelsWoningInstantie.WoningInstantie, projectid: number): string {
		var eerste = this.GeefActieveAfbeeldKeuze(Instantie);
		if (eerste == null) {
			return null;
		}
		return eerste.Afbeelding(Instantie, projectid);
	}

	ActieveAfbeeldNaam(Instantie: ViewModelsWoningInstantie.WoningInstantie): string {
		var eerste = this.GeefActieveAfbeeldKeuze(Instantie);
		if (eerste == null) {
			return null;
		}

		return eerste.AfbeeldNaam(Instantie);
	}

	abstract GeefKeuzes(): Array<ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze>;

	abstract BepaalToegestaneKeuzes(Instantie: ViewModelsWoningInstantie.WoningInstantie): Array<ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze>;
	abstract BepaalToegestaneKeuzes(Instantie: ViewModelsWoningInstantie.WoningInstantie): Array<ViewModelsIGebouwBrokKeuze.IGebouwBrokKeuze>;

	AantalDelenTonen(Instantie: ViewModelsWoningInstantie.WoningInstantie): number {
		if (this.Weergave == 0) {
			return 0;
		}

		// indien het hele brok niet voorkomt, dan hoeven we ook niet af te dalen
		var deel: ViewModelsInstantieDeel.InstantieDeel = Instantie.Brokken[this.DeelId];
		if (deel == null) {
			return 0;
		}

		var keuzes = this.GeefKeuzes();
		var aantal = _.filter(keuzes, (k) => {
			return k.Tonen(this, Instantie);
		}).length;

		return aantal;
	}

	Tonen(Instantie: ViewModelsWoningInstantie.WoningInstantie): boolean {
		if (this.Weergave == 0) {
			return false;
		}

		// indien het hele brok niet voorkomt, dan hoeven we ook niet af te dalen
		var deel: ViewModelsInstantieDeel.InstantieDeel = Instantie.Brokken[this.DeelId];
		if (deel == null) {
			return false;
		}

		var keuzes = this.GeefKeuzes();
		var aantal = 0;
		var teweinig = _.every(keuzes, (k) => {
			if (k.Tonen(this, Instantie)) {
				aantal++;
			}
			// indien we meer dan 2 keuzes hebben, dan valt er iets te kiezen en kunnen we dus false teruggeven
			return aantal < 2;
		});

		return !teweinig;
	}
}
