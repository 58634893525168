
<div *ngIf="Loading">
    Laden...
</div>

<div *ngIf="!Loading">

    <h3 *ngIf="!addNew">Filter Details ({{selectedFilter.Id}})</h3>
    <h3 *ngIf="addNew">Nieuwe filter toevoegen</h3>

    <div class="row">
      <table style="width: 100%;">
        <tr>
          <td>
            <div class="box-1">
              <form #form="ngForm" id="filterUpdateForm" class="fab-admin-filter-form" [formGroup]="filterForm" (ngSubmit)="OnSubmit()">
                <fieldset style="border:none">
                  <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>Naam</mat-label>
                      <input matInput id="filterName" name="filterName" formControlName="filterName" required min-length="4">
                      <div *ngIf="filterName.invalid && (filterName.dirty || filterName.touched)" class="alert">
                        <div *ngIf="filterName.errors.required">Filter heeft een naam nodg</div>
                      </div>
                    </mat-form-field>
                  </p>
                  <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>FilterType</mat-label>
                      <mat-select formControlName="filterTypeId">
                        <mat-option *ngFor="let types of FilterTypesViewModel.FilterTypes" [value]="types.Id">{{types.Name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </p>
                  <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>Weergave</mat-label>
                      <mat-select formControlName="filterDisplayType">
                        <mat-option [value]="0">Automatisch</mat-option>
                        <mat-option [value]="1">Radio buttons</mat-option>
                        <mat-option [value]="2">DropDown</mat-option>
                        <mat-option [value]="3">Multiselect</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </p>
                  <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>Niveau / stap</mat-label>
                      <mat-select formControlName="filterDisplayLevel">
                        <mat-option [value]="0">Niveau 1</mat-option>
                        <mat-option [value]="1">Niveau 2</mat-option>
                        <mat-option [value]="2">Niveau 1-2</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </p>
                  <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>Min Value</mat-label>
                      <input matInput type="number" name="minValue" formControlName="minValue" required min-length="4">
                    </mat-form-field>
                  </p>
                  <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>Max Value</mat-label>
                      <input matInput type="number" name="maxValue" formControlName="maxValue" required min-length="4">
                    </mat-form-field>
                  </p>
                  <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>Default Value</mat-label>
                      <input matInput type="number" name="defaultValue" formControlName="defaultValue" required min-length="4">
                    </mat-form-field>
                  </p>
                  <div *ngIf="filterForm.errors && (filterForm.dirty || filterForm.touched)" class="form-warning">
                    <div *ngIf="filterForm.errors.boundaryError">Min is groter dan max!</div>
                    <div *ngIf="filterForm.errors.defaultValueError">Default value moet binnen Min en Max vallen</div>
                  </div>
                  <p>
                    <mat-checkbox formControlName="hasEffect" name="hasEffect">Heeft effect</mat-checkbox>
                  </p>
                  <p>
                    <mat-checkbox formControlName="mandatory" name="mandatory">Verplicht</mat-checkbox>
                  </p>
                  <p>
                    <mat-checkbox formControlName="important" name="important">Belangrijk</mat-checkbox>
                  </p>
                </fieldset>
              </form>
            </div>
          </td>
          <td>
            <div class="box-1">
              <p>
                <filter-options [FilterId]="FilterId"></filter-options>
              </p>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="row">
        <div class="box-1">
            <div *ngIf="addNew; else updateBlock">
                <button mat-raised-button color="blue" [disabled]="!filterForm.dirty || !filterForm.valid" type="submit" form="filterUpdateForm">Filter Toevoegen</button>
            </div>
            <ng-template #updateBlock>
                <div>
                    <button mat-raised-button [disabled]="!filterForm.dirty || !filterForm.valid" type="submit" form="filterUpdateForm" color="accent">Wijzigingen opslaan</button>

                    <button mat-raised-button color="primary" (click)="Delete()">Verwijder</button>
                </div>
            </ng-template>
        </div>
    </div>
</div>

