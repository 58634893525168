
<div class="container">
    <div>
        <div class="two-columns">
            <div class="first-column">
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Organisatie</mat-label>
                        <input matInput name="productNaam" [(ngModel)]="userDetail.Organisation.Name" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Project (nog niet werkend)</mat-label>
                        <input matInput name="project" [(ngModel)]="userDetail.Organisation.Id" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>GUID</mat-label>
                        <input matInput name="user-guid" [(ngModel)]="userDetail.PersoonExtra.UserId" ngModel disabled>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Inlognaam</mat-label>
                        <input matInput name="Inlognaam" [(ngModel)]="userDetail.Persoon.UserName" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Woningen</mat-label>
                        <mat-select [(ngModel)]="BuildingUserLinkRequest.BuilidingId" [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let building of BuildingsViewModel.Buildings" [value]="building.Id">
                                {{building.Adres.Straat}} {{building.Adres.Nummer}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Rol gebruiker</mat-label>
                        <mat-select [(ngModel)]="AddRole.Rol" [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let role of Roles.Roles" [value]="role.Rol">
                                {{role.Rol}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </p>
                <p>
                    Beheer
                </p>
            </div>
            <div class="second-column">
                <p>
                    <mat-slide-toggle [(ngModel)]="verification.Verificatie" (change)="onVerificationChange()">Verificatie</mat-slide-toggle>

                </p>
                <p>
                    <mat-slide-toggle [(ngModel)]="verification.Verificatie">Login geblokkeerds</mat-slide-toggle>

                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Verficiatiecode</mat-label>
                        <input matInput name="verificatiecode" [(ngModel)]="verification.VerificatieCode" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>

                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Wachtwoord resetten</mat-label>
                        <input matInput name="password" [(ngModel)]="newPassword" ngModel min-length="4" >
                    </mat-form-field>

                    <button mat-icon-button color="success" aria-label="Example icon button with a heart icon" (click)="setPassword()">
                        <mat-icon>
                            <i class="fas fa-check"></i>
                        </mat-icon>
                    </button>

                </p>
            </div>
        </div>
    </div>


    <div>
        <div class="two-columns">
            <div class="first-column">
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Id</mat-label>
                        <input matInput name="PersoonId" [(ngModel)]="userDetail.Persoon.PersoonId" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Voornaam</mat-label>
                        <input matInput name="voornaam" [(ngModel)]="userDetail.Persoon.FirstName" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Voorletters</mat-label>
                        <input matInput name="project" [(ngModel)]="userDetail.PersoonExtra.Voorletters" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Tussenvoegsel</mat-label>
                        <input matInput name="tussenvoegsel" [(ngModel)]="userDetail.PersoonExtra.Tussenvoegsel" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Achternaam</mat-label>
                        <input matInput name="Achternaam" [(ngModel)]="userDetail.Persoon.LastName" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Straat</mat-label>
                        <input matInput name="Straat" [(ngModel)]="userDetail.Adres.Straat" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Nummer</mat-label>
                        <input matInput name="Nummer" [(ngModel)]="userDetail.Adres.Nummer" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="alwayss">
                        <mat-label>Land</mat-label>
                        <input matInput name="Land" [(ngModel)]="userDetail.Adres.Land" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
            </div>
            <div class="second-column">
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Geslacht</mat-label>
                        <input matInput name="Geslacht" [(ngModel)]="userDetail.PersoonExtra.Geslacht" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Email</mat-label>
                        <input matInput name="Email" [(ngModel)]="userDetail.Persoon.Email" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Telefoon</mat-label>
                        <input matInput name="Telefoon" [(ngModel)]="userDetail.PersoonExtra.Telefoon" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Postcode</mat-label>
                        <input matInput name="Postcode" [(ngModel)]="userDetail.Adres.Postcode" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Plaats</mat-label>
                        <input matInput name="Plaats" [(ngModel)]="userDetail.Adres.Plaats" ngModel required min-length="4" #name="ngModel">
                    </mat-form-field>
                </p>

            </div>
        </div>
    </div>
</div>