import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PrijslijstConsumer } from '@consumers/PrijslijstController';
import { CookieViewModel, FabUserViewModel } from '@models/PrijslijstViewModel';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent {

  public UserViewModel: FabUserViewModel;
  public Loading: boolean = true;


  constructor(private prijslijst: PrijslijstConsumer, public router: Router) {
    this.GetLoggedInUser();
  }

  public GetLoggedInUser(): void {
    this.Loading = true;

    this.prijslijst.GetLoggedInUser_Observable().subscribe((data) => {
      if (data == null) {
        this.UserViewModel = null;
      }
      else {
        this.UserViewModel = new FabUserViewModel(data);
      }

      this.Loading = false;
    });
  }

  public LogOut(): void {
    this.Loading = true;

    this.prijslijst.GetCookies_Observable().subscribe((data_cookies: CookieViewModel) => {

      var request = new CookieViewModel();

      request.TokenValue = this.getCookie(data_cookies.TokenName);
      request.VerifyValue = this.getCookie(data_cookies.VerifyName);

      this.prijslijst.LogOut_Observable(request).subscribe(() => {
        window.location.href = "";
        this.Loading = false;
      });
    });


  }

  public getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
