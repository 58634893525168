<div *ngIf="filterViewModel.FilterType.Name == 'Dropdown'">
  <div *ngIf="filterViewModel.SelectedOption == undefined">
    <div class="fab-filter__option-name-and-icon fab-filter__option-name-and-icon--required">
      <p>
        {{ filterViewModel.Name }}
      <i *ngIf="ShouldDisplayTooltip(filterViewModel.Name)" class="fal fa-info-circle" matTooltip="{{ GetToolTipText(filterViewModel.Name) }}" matTooltipPosition="below" style="margin-left: 5px;"></i>
        <span *ngIf="filterViewModel.Mandatory">*</span>
      </p>
    </div>
    <div *ngIf="filterViewModel.Options.length > 1">

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-select matNativeControl [(ngModel)]="filterViewModel.SelectedOptionId" (selectionChange)="OptionChanged()">
          <mat-option *ngFor="let option of filterViewModel.Options" [value]="option.Id" matTooltip=" {{RemoveEmptyFirstCharacter(option.Name)}}" class="mat-option--fab-filter">
            {{RemoveEmptyFirstCharacter(option.Name)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="filterViewModel.Options.length == 1">
      
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-select matNativeControl [(ngModel)]="filterViewModel.Options[0].Id" [disabled]="true" (selectionChange)="OptionChanged()">
          <mat-option [value]="filterViewModel.Options[0].Id" class="mat-option--fab-filter">
            {{RemoveEmptyFirstCharacter(filterViewModel.Options[0].Name)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>


  <div *ngIf="filterViewModel.SelectedOption != undefined" class="fab-filter__selected-option">
    <p>
      {{ filterViewModel.Name }}
      <i *ngIf="ShouldDisplayTooltip(filterViewModel.Name)" class="fal fa-info-circle" matTooltip="{{ GetToolTipText(filterViewModel.Name) }}" matTooltipPosition="below" style="margin-left: 5px;"></i>
      <span *ngIf="filterViewModel.Mandatory">*</span>
    </p>
    <div class="fab-filter__selected-option-name" (click)="Reset()">
      <table style="width: 100%;">
        <tr>
          <td>{{ filterViewModel.SelectedOption.Name }}</td>
          <td style="width: 10px;"><i class="fal fa-times"></i></td>
        </tr>
      </table>
    </div>
  </div>
</div>
