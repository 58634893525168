

<wct-survey-question question="BAG Pand ID">
  <wct-survey-string [prop]='building.controls.BAGBuildingId'>
  </wct-survey-string>
</wct-survey-question>

<wct-survey-question question="BAG Verblijfsobject ID">
  <wct-survey-string [prop]='building.controls.BAGResidenceId'>
  </wct-survey-string>
</wct-survey-question>

<wct-survey-question question="Postcode">
  <wct-survey-string [prop]='building.controls.BuildingZipCode'>
  </wct-survey-string>
</wct-survey-question>

<wct-survey-question question="Huisnummer">
  <wct-survey-string [prop]='building.controls.BuildingNumber'>
  </wct-survey-string>
</wct-survey-question>

<wct-survey-question question="Bouwjaar">
  <wct-survey-string [prop]='building.controls.BuildingConstructionYear'>
  </wct-survey-string>
</wct-survey-question>

<wct-survey-question question="Renovatiejaar">
  <wct-survey-string [prop]='building.controls.BuildingRenovationYear'>
  </wct-survey-string>
</wct-survey-question>
