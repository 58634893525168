/**
 * @ref WoonConnectViewModel/Survey/SurveyCalculationZone.cs
 */
	/**
	* Originele namespace: WoonConnectViewModel.Survey.ConstructionMass
	*/
	export enum ConstructionMass {
		Invalid = 0,
		
		Unknown = 1,
		
		LessThan250 = 2,
		
		Between250And500 = 3,
		
		Between500And750 = 4,
		
		MoreThan750 = 5
	}
	/**
	* Originele namespace: WoonConnectViewModel.Survey.ConduitType
	*/
	export enum ConduitType {
		Invalid = 0,
		
		Unknown = 1,
		
		NoConduit = 2,
		
		Uninsulated = 3,
		
		Insulated = 4
	}

