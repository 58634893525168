<!-- <button (click)="fileInput.click()" class="wct-icon wct-icon-upload-file">
    <input #fileInput type="file" (change)="uploadFiles($event)" style="display:none;" />
 </button> -->

<wct-button class="header" buttonRole="tertiary" buttonSize="medium" buttonText="Maak een nieuw project aan" (click)="addProjectActive = !addProjectActive"></wct-button>
<div *ngIf="addProjectActive">
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label> Projectnaam: </mat-label>
        <input matInput id="project" [(ngModel)]="projectName">
    </mat-form-field>
    <wct-button buttonRole="primary" buttonSize="medium" buttonText="Voeg project toe" (click)="addProject()"></wct-button>
</div>

<wct-button class="header" buttonRole="tertiary" buttonSize="medium" buttonText="Maak een nieuw element aan" (click)="addNewElementActive = !addNewElementActive"></wct-button>
<div *ngIf="addNewElementActive">
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label> Elementnaam: </mat-label>
        <input matInput id="element" [(ngModel)]="elementName">
    </mat-form-field>
    <wct-button buttonRole="primary" buttonSize="medium" buttonText="Voeg element toe" (click)="addElement()"></wct-button>
</div>

<wct-button class="header" buttonRole="tertiary" buttonSize="medium" buttonText="Voeg element toe aan project" (click)="addAnnotationElementToProjectActive = !addAnnotationElementToProjectActive"></wct-button>
<div *ngIf="addAnnotationElementToProjectActive">
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Project:</mat-label>
        <mat-select [(ngModel)]="selectedProject.Id"
            [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let project of allProjects" [value]="project.Id">
                {{project.Naam}}
            </mat-option>

        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Elementen:</mat-label>
        <mat-select [(ngModel)]="selectedAnnotationElementIds"
            [ngModelOptions]="{standalone: true}" multiple>
            <mat-option *ngFor="let element of allAnnotationElements" [value]="element.Id">
                {{element.Type}}
            </mat-option>

        </mat-select>
    </mat-form-field>

    <wct-button buttonRole="primary" buttonSize="medium" buttonText="Voeg toe" (click)="addElementsToProject()"></wct-button>
</div>

<wct-button class="header" buttonRole="tertiary" buttonSize="medium" buttonText="Voeg fotoset toe aan project" (click)="addPhotoSetActive = !addPhotoSetActive"></wct-button>

 <div *ngIf="addPhotoSetActive">
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Project:</mat-label>
        <mat-select [(ngModel)]="selectedProject.Id"
            [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let project of allProjects" [value]="project.Id">
                {{project.Naam}}
            </mat-option>

        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Fotosets:</mat-label>
        <mat-select [(ngModel)]="photoSetIds"
            [ngModelOptions]="{standalone: true}" multiple>
            <mat-option *ngFor="let photoSet of photoSets" [value]="photoSet.ID">
                {{photoSet.Name}}
            </mat-option>

        </mat-select>
    </mat-form-field>

    <wct-button buttonRole="primary" buttonSize="medium" buttonText="Voeg toe" (click)="addPhotoSet()"></wct-button>
</div>

<wct-button class="header" buttonRole="tertiary" buttonSize="medium" buttonText="Voeg gebruikers toe aan project" (click)="addUsersActive = !addUsersActive"></wct-button>

 <div *ngIf="addUsersActive">
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Project:</mat-label>
        <mat-select [(ngModel)]="selectedProject.Id"
            [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let project of allProjects" [value]="project.Id">
                {{project.Naam}}
            </mat-option>

        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Gebruikers:</mat-label>
        <mat-select [(ngModel)]="selectedUserIds"
            [ngModelOptions]="{standalone: true}" multiple>
            <mat-option *ngFor="let user of annotatorUsers" [value]="user.PersoonID">
                {{user.Username}}
            </mat-option>

        </mat-select>
    </mat-form-field>

    <wct-button buttonRole="primary" buttonSize="medium" buttonText="Voeg toe" (click)="addUsers()"></wct-button>
</div>





