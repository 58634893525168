/**
 * @ref WoonConnectViewModel/Documenten/DocumentGebruikerModel.cs
 */

 import * as DataContracts from "@datacontracts/DocumentGebruikerModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Documenten.DocumentGebruikerModel
	 */
	 

	export interface IDocumentGebruikerModel {
		Type: string;
		PersoonId: string;
		WoningId: string;
		WijkId: string;
		Rol: string;
		ToJsonContract(): DataContracts.IDocumentGebruikerModel;
	}

	export class DocumentGebruikerModel {
		// property-Properties
		public get Type(): string {
			return this._Type;
		}
		public set Type(newType: string) {
			this._Type = newType;
		}

		public get PersoonId(): string {
			return this._PersoonId;
		}
		public set PersoonId(newPersoonId: string) {
			this._PersoonId = newPersoonId;
		}

		public get WoningId(): string {
			return this._WoningId;
		}
		public set WoningId(newWoningId: string) {
			this._WoningId = newWoningId;
		}

		public get WijkId(): string {
			return this._WijkId;
		}
		public set WijkId(newWijkId: string) {
			this._WijkId = newWijkId;
		}

		public get Rol(): string {
			return this._Rol;
		}
		public set Rol(newRol: string) {
			this._Rol = newRol;
		}

		// field-Properties

		// fields
		protected _Type: string = "";
		protected _PersoonId: string = "";
		protected _WoningId: string = "";
		protected _WijkId: string = "";
		protected _Rol: string = "";

		constructor(data?: DataContracts.IDocumentGebruikerModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDocumentGebruikerModel): void {
			if(data) {
				// vul properties
				this._Type = data.Type;
				this._PersoonId = data.PersoonId;
				this._WoningId = data.WoningId;
				this._WijkId = data.WijkId;
				this._Rol = data.Rol;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDocumentGebruikerModel {
			return {
				Type: this.Type,
				PersoonId: this.PersoonId,
				WoningId: this.WoningId,
				WijkId: this.WijkId,
				Rol: this.Rol
			}
		}
	}
