<div class="content">
  <table style="width: 100%;">
    <tr>
      <td>
        <a routerLink="/" class="logo">
          <img src="/Content/KlantSpecifiek/BCB21111/Kawneer_Logo_2017_200px.png">
        </a>
      </td>
      <td>
        <a routerLink="/voorwaarden">Algemene voorwaarden</a>
      </td>
      <td>
        <a href="" target="_blank">Privacybeleid</a>
      </td>
      <td>
        <a href="" target="_blank">Disclaimer</a>
      </td>
      <td>
        <p class="copyright">
          Alle rechten voorbehouden<br>
          &copy; Copyrights 2022
        </p>
      </td>
    </tr>
  </table>
</div>
