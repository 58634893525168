/**
 * @ref WoonConnectViewModel/Configurator/SearchAddressModel.cs
 */

 import * as DataContracts from "@datacontracts/SearchAddressModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.Configurator.SearchAddressRequestModel
	 */
	 

	export interface ISearchAddressRequestModel {
		Freeform: string;
		Zipcode: string;
		Number: string;
		Street: string;
		Municipality: string;
		ToJsonContract(): DataContracts.ISearchAddressRequestModel;
	}

	export class SearchAddressRequestModel {
		// property-Properties
		public get Freeform(): string {
			return this._Freeform;
		}
		public set Freeform(newFreeform: string) {
			this._Freeform = newFreeform;
		}

		public get Zipcode(): string {
			return this._Zipcode;
		}
		public set Zipcode(newZipcode: string) {
			this._Zipcode = newZipcode;
		}

		public get Number(): string {
			return this._Number;
		}
		public set Number(newNumber: string) {
			this._Number = newNumber;
		}

		public get Street(): string {
			return this._Street;
		}
		public set Street(newStreet: string) {
			this._Street = newStreet;
		}

		public get Municipality(): string {
			return this._Municipality;
		}
		public set Municipality(newMunicipality: string) {
			this._Municipality = newMunicipality;
		}

		// field-Properties

		// fields
		protected _Freeform: string = "";
		protected _Zipcode: string = "";
		protected _Number: string = "";
		protected _Street: string = "";
		protected _Municipality: string = "";

		constructor(data?: DataContracts.ISearchAddressRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISearchAddressRequestModel): void {
			if(data) {
				// vul properties
				this._Freeform = data.Freeform;
				this._Zipcode = data.Zipcode;
				this._Number = data.Number;
				this._Street = data.Street;
				this._Municipality = data.Municipality;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.ISearchAddressRequestModel {
			return {
				Freeform: this.Freeform,
				Zipcode: this.Zipcode,
				Number: this.Number,
				Street: this.Street,
				Municipality: this.Municipality
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Configurator.SearchDwelling
	 */
	 

	export interface ISearchDwelling {
		Domicile: string;
		Streetname: string;
		Number: number;
		Zipcode: string;
		NumberExtension: string;
		InSystem: boolean;
		ModelInSystem: boolean;
	}

	export class SearchDwelling {
		// property-Properties
		public get Domicile(): string {
			return this._Domicile;
		}
		public set Domicile(newDomicile: string) {
			this._Domicile = newDomicile;
		}

		public get Streetname(): string {
			return this._Streetname;
		}
		public set Streetname(newStreetname: string) {
			this._Streetname = newStreetname;
		}

		public get Number(): number {
			return this._Number;
		}
		public set Number(newNumber: number) {
			this._Number = newNumber;
		}

		public get Zipcode(): string {
			return this._Zipcode;
		}
		public set Zipcode(newZipcode: string) {
			this._Zipcode = newZipcode;
		}

		public get NumberExtension(): string {
			return this._NumberExtension;
		}
		public set NumberExtension(newNumberExtension: string) {
			this._NumberExtension = newNumberExtension;
		}

		public get InSystem(): boolean {
			return this._InSystem;
		}
		public set InSystem(newInSystem: boolean) {
			this._InSystem = newInSystem;
		}

		public get ModelInSystem(): boolean {
			return this._ModelInSystem;
		}
		public set ModelInSystem(newModelInSystem: boolean) {
			this._ModelInSystem = newModelInSystem;
		}

		// field-Properties

		// fields
		protected _Domicile: string = "";
		protected _Streetname: string = "";
		protected _Number: number = 0;
		protected _Zipcode: string = "";
		protected _NumberExtension: string = "";
		protected _InSystem: boolean = false;
		protected _ModelInSystem: boolean = false;

		constructor(data?: DataContracts.ISearchDwelling) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISearchDwelling): void {
			if(data) {
				// vul properties
				this._Domicile = data.Domicile;
				this._Streetname = data.Streetname;
				this._Number = data.Number;
				this._Zipcode = data.Zipcode;
				this._NumberExtension = data.NumberExtension;
				this._InSystem = data.InSystem;
				this._ModelInSystem = data.ModelInSystem;
				// vul fields
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Configurator.SearchAddressResponseModel
	 */
	 

	export interface ISearchAddressResponseModel {
		Dwellings: SearchDwelling[];
		Message: string;
		Succeeded: boolean;
	}

	export class SearchAddressResponseModel {
		// property-Properties
		public get Dwellings(): SearchDwelling[] {
			return this._Dwellings;
		}
		public set Dwellings(newDwellings: SearchDwelling[]) {
			this._Dwellings = newDwellings;
		}

		public get Message(): string {
			return this._Message;
		}
		public set Message(newMessage: string) {
			this._Message = newMessage;
		}

		public get Succeeded(): boolean {
			return this._Succeeded;
		}
		public set Succeeded(newSucceeded: boolean) {
			this._Succeeded = newSucceeded;
		}

		// field-Properties

		// fields
		protected _Dwellings: SearchDwelling[] = [];
		protected _Message: string = "";
		protected _Succeeded: boolean = false;

		constructor(data?: DataContracts.ISearchAddressResponseModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.ISearchAddressResponseModel): void {
			if(data) {
				// vul properties
				this._Dwellings = data.Dwellings ? data.Dwellings.map(searchDwellingItem => new SearchDwelling(searchDwellingItem)): [];
				this._Message = data.Message;
				this._Succeeded = data.Succeeded;
				// vul fields
			}
		}
	}
