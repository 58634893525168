import * as ViewModelsWoning from '.././Models/Woning';

export class Blok {

	WoningIDs: Array<number> = [];
	Woningen: Array<ViewModelsWoning.Woning> = [];
	BlokID: number;
	AdresStraat: string;
	AdresNummer: string;

	// interface props
	public Tonen: boolean = false;
	public BezigMetLaden: boolean = false;

	constructor() { }

	_SetData(b: any) {
		Object.assign(this, b)
		//ng.extend(this, b);
	}

	_SetWoningen(w: Array<ViewModelsWoning.Woning>) {
		this.Woningen = w;
	}

}
