/**
 * @ref WoonConnectViewModel/BuildingComponentInstanceViewModel.cs
 */

 import * as DataContracts from "@datacontracts/BuildingComponentInstanceViewModel";

 



	/**
	 * Originele namespace: WoonConnectViewModel.BuildingComponentInstanceViewModel
	 */
	 

	export interface IBuildingComponentInstanceViewModel {
		Id: number;
		Name: string;
		ModelCode: string;
		ToJsonContract(): DataContracts.IBuildingComponentInstanceViewModel;
	}

	export class BuildingComponentInstanceViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get Name(): string {
			return this._Name;
		}
		public set Name(newName: string) {
			this._Name = newName;
		}

		public get ModelCode(): string {
			return this._ModelCode;
		}
		public set ModelCode(newModelCode: string) {
			this._ModelCode = newModelCode;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _Name: string = "";
		protected _ModelCode: string = "";

		constructor(data?: DataContracts.IBuildingComponentInstanceViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentInstanceViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._Name = data.Name;
				this._ModelCode = data.ModelCode;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentInstanceViewModel {
			return {
				Id: this.Id,
				Name: this.Name,
				ModelCode: this.ModelCode
			}
		}
	}
