/// <reference path='../consumerinterfaces/FrontendController.ts' />
/**
 * @ref WoonConnectApiController/Frontend/FrontendController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/FrontendController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class FrontendConsumer implements Interface.IFrontendConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public GeefAfbeelding_Observable(naam: string, submap: string): Observable<string> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/Frontend/GeefAfbeelding/${naam}/${submap}`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<string>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefAfbeelding(naam: string, submap: string): ng.IPromise<string> {
				let obs = this.GeefAfbeelding_Observable(naam, submap);
				let promise = new Promise<string>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


