import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpRequest} from '@angular/common/http';

function makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

@Injectable({
  providedIn: 'root',
})
export class DataLoadMonitorService {
  loading$ = new Subject<boolean>();
  loadingConsumer$ = new Subject<string[]>();

  id = makeid(10);

  constructor()
  {
   // console.log("QUEUE DATA LOAD ID:" + this.id)

  }

 

  private queue = [] as HttpRequest<any>[];
  private user;

  private notify() {
    //console.log("QUEUE ID " + this.id + " LENGTH: " + this.queue.length);
    this.loading$.next(this.hasRequests);
    this.loadingConsumer$.next(this.GetLoadingConsumers());
  }
  GetLoadingConsumers(): string[] {
    return this.queue.map(q => q.url);
  }

  startRequestHandling(req: HttpRequest<any>) {
    //console.log("QUEUE ID " + this.id + " REQ: " + req.url);
    this.queue.push(req);
    this.notify();
  }

  stopRequestHandling(req: HttpRequest<any>) {
    this.queue = this.queue.filter(queuedReq => queuedReq !== req);
    this.notify();
  }

  private get hasRequests() {
    return this.queue.length > 0;
  }
}