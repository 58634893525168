<div *ngIf="Loading">
    Laden...
</div>
<!--
    <div *ngIf="!Laden">
   
        <table>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>FilterId</th>
                </tr>

            </thead>
            <tbody>
                <tr *ngFor="let filteroptions of FilterOptionsViewModel.FilterOptions">
                    <td>{{filteroptions.Id}}</td>
                    <td>{{filteroptions.Name}}</td>
                    <td>{{filteroptions.FilterId}}</td>
                </tr>
            </tbody>

        </table>
    </div>
    <div id="right">
        // hier de details van een filter optie de optie om een nieuwe toe te voegen
        <form>
            <label>Name</label>
            <input type="text" [(ngModel)]="newFilterOption.Name" [ngModelOptions]="{standalone: true}" required minlength="4" />
            <br />
            <label>Welke Filter </label>
            <select [(ngModel)]="newFilterOption.FilterId" [ngModelOptions]="{standalone: true}">
                <option *ngFor="let filters of alleFilters.Filters" value="{{filters.Id}}">{{filters.Name}}</option>
            </select>
            <br />
            <button mat-stroked-button color="primary" (click)="addFilterOption()">Filter Option Toevoegen</button>

        </form>
</div>
-->

<div *ngIf="!Loading">
  

    <div (click)='ExpandedFilter = !ExpandedFilter'>
        <h3>
            Filter Opties   
            <button mat-icon-button color="success-icon" > 
            <i class="{{ExpandedFilter ? 'fal fa-times' : 'fal fa-plus'}}"></i> 
            </button>
        </h3>
    </div>
 
    <!-- (cdkDropListDropped)="drop($event)" -->
    <div cdkDropList class="options-list" >
        <div *ngIf="ExpandedFilter" class="options-box">
            <div>

            </div>
            <form #form="ngForm">

                <mat-form-field floatLabel="never">
                    <input matInput color="blue" name="filterName" [(ngModel)]="NewFilterOptionName" ngModel #name="ngModel">

                </mat-form-field>

            </form>
            <button mat-button mat-icon-button matSuffix color="success-icon" (click)="addFilterOption()">
                <mat-icon><i class="fal fa-plus"></i></mat-icon>
            </button>
        </div>

        <fab-drag-and-drop [ItemArray]="FilterOptionsViewModel.FilterOptions" PropertyToShow="Name" [Deletable]="true" (ArrayUpdated)="onArrayChange()" (DeleteAtIndex)="onDeleteAtIndex($event)"> </fab-drag-and-drop> 

        <!--<div class="options-box" *ngFor="let options of FilterOptionsViewModel.FilterOptions" cdkDrag [cdkDragData]="options">
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
        <div class="example-handle" cdkDragHandle>
            <i class="fal fa-arrows"></i>
        </div>
        <span *ngIf="options.Id != updateFilter" (dblclick)="setUpdateFilter(options)">{{options.Name}}</span>
        <div *ngIf="updateFilter === options.Id">
            <form #form="ngForm">
                <p>
                    <mat-form-field>
                        <input matInput name="filterName" [(ngModel)]="updateFilterOption.Name" ng-value="options.Name" ngModel min-length="4" #name="ngModel">
                    </mat-form-field>
                    <button mat-button mat-icon-button matSuffix color="success-icon" (click)="saveFilterOption(options.Id)">
                        <mat-icon><i class="fal fa-plus"></i></mat-icon>
                    </button>

                </p>
            </form>
        </div>

        <button mat-icon-button color="warn" aria-label="Delete Filter Option" (click)="deleteFilterOption(options.Id)">
            <i class="fal fa-trash-alt"></i>
        </button>
    </div>-->
    </div>

        




</div>