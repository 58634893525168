<!--<span ng-show="!GebruikWebGL()" class="wct-viewer-niet-beschikbaar">
	 <span ng-show="WebGLError">
		  Geen 3D viewer beschikbaar! Gebruik Chrome of Firefox en/of update de drivers van de videokaart.
	 </span>
	 <span>
		  Er is een fout opgetreden bij het aansturen van de 3D viewer!
	 </span>
</span>-->

<div class="flex-cont cont-3d-viewer-niet-beschikbaar ng-hide" [hidden]="GebruikWebGL()">
	<h3>3D viewer is niet beschikbaar</h3>
	<wct-button button-text="Meer informatie" button-size="small" button-align="center" button-role="primary" (click)="toonPopup('3dViewerNietBeschikbaar')"></wct-button>
</div>

<!--<div ng-if="FoutmeldingFirefoxOpMacTonen" class="cont-foutmelding-firefox-op-mac-total">
	<div class="cont-foutmelding-firefox-op-mac">
		<p class="txt-subtitle">3D viewer is niet beschikbaar</p>
		<p>Als u de 3D viewer wel wilt gebruiken, maak dan gebruik van Safari of Chrome en/of update de drivers van de videokaart:</p>
		<div class="cont-logos-browsers">
			<a href="" target="_blank">
				<img class="img-logo" src="../../../Content/Images/safari.svg" />
				<p class="txt-logo">Safari</p>
			</a>
			<a href="" ng-href="{{downloadChrome()}}" target="_blank">
				<img class="img-logo" src="../../../Content/Images/Chrome.svg" />
				<p class="txt-logo">Download Chrome</p>
			</a>
		</div>
		<p>De onderdelen van de interface die geen gebruik maken van de 3D viewer zijn wel beschikbaar (zoals Uw verbruik en Woninginformatie).</p>
	</div>
</div>-->

<div id='BekijkPauseResumeContainer' class='BekijkPauseResumeContainer' title="Hier kunt u de aanpassingen in het model pauzeren en ondertussen verschillende opties selecteren. Door nogmaals te klikken worden wijzigingen doorgevoerd.">



	<!--<img id='BekijkPauseResume' src="/Content/Images/EXTERN_pause.png" width="48" height="48"
		  class='BekijkPauseResume'>
	<div id='BekijkPauseResumeMelding' class='BekijkPauseResumeMeldingInactief BekijkPauseResumeMelding'>
		 Let op: De 3D-weergave is momenteel gepauseerd.
	</div>-->
</div>

<wct-viewer-3d [sessie]="sessie" [suppress]="suppress" *ngIf="GebruikWebGL()" (onselect)="OnSelect($event)" [drawscene]="drawscene">
	<!-- [selectie]="selectie"  -->
</wct-viewer-3d>

<!--<div class="progressdialogcontainer" ng-show="WoningLadenBezig()">
	 <div class="progressdialog">
		  <div>Bezig met verversen 3D</div>
		  <div class="progressbarcontainer">
				<div class="progressbar" ng-style="{ width:WoningLadenVoortgang() + '%' }"></div>
		  </div>
	 </div>
</div>-->

<div class="wct-fps-info" ng-show="ToonFPS()">
	<div class="wct-fps-counter"></div>
	<div class="wct-fps-details"></div>
</div>