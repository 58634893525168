import { NgModule,ErrorHandler, APP_INITIALIZER   } from '@angular/core';
import { CommonModule } from '@angular/common';  

import { CoreUiModule } from '../coreui/coreui.module';

import { wct3DViewerComponent } from './WctViewer3d';
import { ModelViewer3dComponent } from './WctModelViewer3d';
import { WctWebGLCanvasComponent } from './WctWebGlCanvas';
import { Viewer2DComponent } from './Wctviewer2d';

import { CoreProvidersModule} from './coreeproviders.module';

import { CapabilitiesService } from './CapabilitiesService';
import { SceneService } from './SceneService';
import { ProjectAPIConsumer } from '@consumers/ProjectApiController';
import { ProjectConsumer } from '@consumers/ProjectController';
import { WctLogService } from '../services/WctLogService';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import MyErrorHandler from './errorhandler';
import { ModelPopupComponent} from './WctModelPopup';

import {  SafePipe } from './safepipe';

import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule} from '@angular/material/core';
import { MatInputModule}  from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatDialogModule} from '@angular/material/dialog';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatSelectModule} from '@angular/material/select';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatRadioModule} from '@angular/material/radio';
import { MatTableModule} from '@angular/material/table';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatTreeModule} from '@angular/material/tree';
import { MatIconModule} from '@angular/material/icon';
import { MatTabsModule} from '@angular/material/tabs';
import { MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatButtonModule} from '@angular/material/button'
import { MatProgressBarModule} from '@angular/material/progress-bar';

const AngularMaterialModules = [
	MatInputModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatFormFieldModule,
	MatDialogModule,
	MatSelectModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatTableModule,
	MatCheckboxModule,
	MatTooltipModule,
	MatTreeModule,
	MatIconModule,
	MatTabsModule,
	MatBottomSheetModule,
	MatGridListModule,
	MatSidenavModule,
	MatSlideToggleModule,
	MatButtonToggleModule,
	MatButtonModule,
	MatProgressBarModule
];

@NgModule({
    imports: [
		CoreProvidersModule,
		//HttpClientModule,
		CommonModule,
		//BrowserAnimationsModule,
        AngularMaterialModules,
        CoreUiModule,
    ],
    declarations: [
        ModelViewer3dComponent,
        wct3DViewerComponent,
        WctWebGLCanvasComponent,
		Viewer2DComponent,
		ModelPopupComponent,
		SafePipe,
	],
	exports:
	[
		ModelViewer3dComponent,
		wct3DViewerComponent,
		WctWebGLCanvasComponent,
		Viewer2DComponent,
		SafePipe,
	],
    entryComponents: [
        ModelViewer3dComponent,
        wct3DViewerComponent,
        WctWebGLCanvasComponent,
		Viewer2DComponent,
		ModelPopupComponent,
    ]
})
export class ViewerModule {
	constructor() { }
}