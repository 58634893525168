import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConstSoftware } from '../../Helpers/Backwards';
import { WindowService } from '../../viewers/WindowService';
import { ProjectService } from '../../services/ProjectService';
import { AccountService } from '../../services/AccountService';
import { IProjectInfo } from '@datacontracts/Project';
import { Account } from '../../Models/Account';
import { WctLoginPopupComponent } from './WctLoginPopup';
import { AppConfigService } from '../../infrastructure/app.configservice';


	@Component({
		selector: 'wct-login',
		templateUrl: './WctLogin.html',
		styleUrls: ['./WctLogin.scss'],
	 })
	export class WctLoginComponent {
		@Input() public parent: any;
		@Input() public kleinFormaat: boolean;
		@Input() public titelTonen: boolean = false;

		@Input() public parentMelding: string;
		@Input() public parentStartschermLogin: boolean;
		@Input() public parentStartschermActivatie: boolean;
		@Output() parentMeldingChange = new EventEmitter<string>();
		@Output() parentStartschermLoginChange = new EventEmitter<boolean>();
		@Output() parentStartschermActivatieChange = new EventEmitter<boolean>();
		@Output() public toggleloaderChange = new EventEmitter<string>();
		public account: Account;
		public username: string = "";
		public password: string = "";
		public ToonUserNamePasswordHulp: boolean = false;

		public Rememberme: boolean = true;
		public windowWidth: number = 0;
		public customFullscreen: boolean = false;
		public wachtwoordVergetenZichtbaar: boolean = false;
		public schermIsTeKleinZichtbaar: boolean = false;
		public InloggenNaarInterfaceMetModelActief: boolean = false;
		public InloggenNaarInterfaceZonderModelActief: boolean = false;
		public InloggenIsOpgeroepen: boolean = false;
		public username_placeholder: string = "Uw e-mailadres";

		public AvailableProjects: IProjectInfo[];
		public SelectedProject: number;
		public AngularStartUrl: string;


		constructor(
			private accountservice: AccountService,
			private projectservice: ProjectService,
			private windowservice: WindowService,
			private config: AppConfigService,

			private dialog: MatDialog
		) {
'ngInject';
			this.username_placeholder = config.Software.UsernamePlaceholder;
			this.account = new Account();
			if (this.parent != null) {
				if (this.parent.melding != "" && this.parent.melding != null) {
					this.toonPopup("melding", this.parent.melding);
					this.parent.melding = "";
				}
			} else {
				if (this.parentMelding != "" && this.parentMelding != null) {
					this.toonPopup("melding", this.parentMelding);
					this.parentMeldingChange.emit("");
				}
			}


			this.windowWidth = window.innerWidth || document.documentElement.clientWidth;
			this.windowservice.WindowGrootteAangepastEvent.RegisterHandler(undefined, () => {
				this.windowWidth = this.windowservice.GeefWindowWidth();
			});

			this.accountservice.LoginSchermSettings();

			if (this.kleinFormaat != true) {
				this.kleinFormaat = false;
			}
		}

		public LoginHeeftKleinFormaat(): boolean {
			return (this.kleinFormaat == true);
		}

		public  geefSupportEmailadres() {
			let emailadres = ConstSoftware.SupportEmailAdres;

			return emailadres;
		}

		public geefSupportEmailadresLinkToEmailProgram(oorzaak: string) {
			let emailadres = ConstSoftware.SupportEmailAdres;
			let project = "WoonConnect";

			if (ConstSoftware.TekstBijWoonconnectLogo !== null && ConstSoftware.TekstBijWoonconnectLogo !== "") {
				project = ConstSoftware.TekstBijWoonconnectLogo;
			}

			if (oorzaak == null) {
				return "mailto: " + emailadres + "?Subject=Vraag%20over%20" + project;
			} else {
				return "mailto: " + emailadres + "?Subject=Vraag%20over%20" + project + "%20:%20" + oorzaak;
			}
		}

		public Inloggen() {
			console.log('Inloggen opgeroepen');
			this.ToonUserNamePasswordHulp = true;

			if (this.usernameHulp() == false && this.passwordHulp() == false && !this.InloggenIsOpgeroepen) {
				this.toggleloader();
				this.InloggenIsOpgeroepen = true;
				if (this.windowWidth < 768) {
					this.toggleloader();
					this.InloggenIsOpgeroepen = false;
					this.toonSchermIsTeKlein();
				} else {
					this.accountservice.Login(this.account, this.Rememberme).then(loginIsSuccessful => {
						this.toggleloader();
						this.ToggleInloggenIsActief();

						this.InloggenIsOpgeroepen = false;
						if (loginIsSuccessful) {
							// if (this.account.LoginNaam.toLocaleLowerCase() === "admin") {
							// 	window.location.replace("/app/bootstrap");
							// }
							// else {
							// 	if (this.accountservice.HeeftRol('modelopnemer'))
							// 	{
							// 		window.location.replace("/");
							// 	}
							// 	else
							// 	{
							// 		//this.router.
							// 		this.CheckProjectAvailabilityPage();
							// 	}
							// }
						}
						else {
							this.ToggleInloggenIsActief();
							let oorzaak: string = this.accountservice.GetOorzaak();
							let melding: string = this.accountservice.GetMelding();
							this.toonPopup(oorzaak, "Er is bij ons geen account bekend met deze inlognaam of u heeft een verkeerd wachtwoord ingevoerd.");
						}
					}, (reasonForRejection) => {
						this.ToggleInloggenIsActief();
					});
				}
			}
		}

		public ToggleInloggenIsActief(): void {
			if (this.accountservice.HeeftRol('Contractor') || this.accountservice.HeeftRol('DistrictDirector')) {
				this.InloggenNaarInterfaceZonderModelActief = !this.InloggenNaarInterfaceZonderModelActief;
			} else {
				this.InloggenNaarInterfaceMetModelActief = !this.InloggenNaarInterfaceMetModelActief;
			}
		}

		public ControleerInlognaamEnWachtwoord(event: any): void {
			if (event.keyCode == 13) {
				console.log('ControleerInlognaamEnWachtwoord opgeroepen');
				this.Inloggen();
			}
		}

		private CheckProjectAvailabilityPage() {
			var project_availability = this.accountservice.GiveProjectAvailability();
			this.accountservice.GiveAllProjects().then(response => {
				this.AvailableProjects = response;

					this.SelectedProject = this.AvailableProjects[0].PrimaryProjectId;
					this.projectservice.ZetProjectId(this.SelectedProject);

					window.location.replace(this.accountservice.GeefAngularStartUrl());

			});
		}

		public Uitloggen() {
			this.accountservice.Logout();
		}

		public usernameHulp(): boolean {
			let ret = false;
			if ((this.account.LoginNaam.length === 0) && (this.ToonUserNamePasswordHulp === true)) {
				ret = true;
			}
			return ret;
		}

		public passwordHulp(): boolean {
			let ret = false;
			if ((this.account.Password.length === 0) && (this.ToonUserNamePasswordHulp === true)) {
				ret = true;
			}
			return ret;
		}

		public ToonStartschermActivatie() {
			if (this.parent != null) {
				this.parent.startschermLogin = false;
				this.parent.startschermActivatie = true;
			} else {
				this.parentStartschermLoginChange.emit(false);
				this.parentStartschermActivatieChange.emit(true);
			}
		}

		public ToonRegistrerenScherm() {
		}

		public ToonActivatieMogelijkheid() {
			return this.accountservice.ToonActivatieMogelijkheid();
		}

		public toonPopup(oorzaak: any, melding: any) {
			const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        oorzaak: oorzaak,
        melding: melding,
        width: this.windowWidth
      };
      dialogConfig.panelClass = "wct-login-popup__mat-dialog";
      this.dialog.open(WctLoginPopupComponent, dialogConfig);
		}

		public ToonWachtwoordVergeten(): boolean {
			return this.accountservice.ToonWachtwoordVergeten();
		}

		public toonWachtwoordVergetenTekst() {
			if (this.windowWidth <= 768) {
				this.customFullscreen = true;
				this.toonPopup("wachtwoordVergeten", "");
			} else {
				this.customFullscreen = false;
				this.toonPopup("wachtwoordVergeten", "");
			}
		}

		public toonSchermIsTeKlein() {
			this.wachtwoordVergetenZichtbaar = false;
			this.schermIsTeKleinZichtbaar = !this.schermIsTeKleinZichtbaar;
		}

		public Demo() {
			this.accountservice.DemoLogin().then(b => {
				window.location.replace('/');
			});
		}

		private toggleloader() {
			this.toggleloaderChange.emit("");
		}
	}

