// [conv] import "(./FabAdminFilter).less";
import { Component, Input, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FiltersConsumer } from "@consumers/FiltersController";
import { FilterOptionsViewModel, FilterOptionViewModel, FiltersViewModel, FilterRequestModel } from '@models/FilterViewModel';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeleteRequestModel } from '@models/ProductsViewModel';


@Component({
	selector: 'filter-options',
	templateUrl: './FabAdminFilterOptions.html',
	styleUrls: ['./FabAdminFilterOptions.scss']
})


export class FabAdminFilterOptionsComponent {
	Loading: boolean = true;
	ExpandedFilter: boolean = true;
	Update: boolean = true;
	@Input() FilterId: number;
	FilterRequestModel: FilterRequestModel = new FilterRequestModel();
	FilterOptionsViewModel: FilterOptionsViewModel = new FilterOptionsViewModel();
	DeleteFilterOptionModel: DeleteRequestModel;
	NewFilterOptionName: string;

	constructor(private route: ActivatedRoute, private prod: FiltersConsumer, private router: Router) {
		//this.Refresh();

	}

	ngOnChanges(changes: SimpleChanges) {
		this.FilterId = changes.FilterId.currentValue;

		if (this.FilterId > 0) {
			console.log(this.FilterId);

			this.Refresh();
		}
	}

	public Refresh(): void {
		this.Loading = true;
		this.FilterRequestModel.Id = this.FilterId;

		console.log("getting filteroptions for ID " + this.FilterId);

		this.prod.GetFilterOptions_Observable(this.FilterRequestModel).subscribe((data) => {
			console.log(data);
			this.FilterOptionsViewModel = new FilterOptionsViewModel(data);
			this.Loading = false;
		});
	}

	public deleteFilterOption(optionid) {
		this.DeleteFilterOptionModel = new DeleteRequestModel();

		this.DeleteFilterOptionModel.Id = <number>optionid;
		this.DeleteFilterOptionModel.LinkedId = this.FilterId;

		this.prod.DeleteFilterOption_Observable(this.DeleteFilterOptionModel).subscribe((data) => {
			console.log(data);

			this.Refresh();
		})
		console.log(optionid);
	}

	addFilterOption() {
		//create a new filter to send to the server
		var newFilterOption = new FilterOptionViewModel();
		newFilterOption.Name = this.NewFilterOptionName;
		newFilterOption.FilterId = this.FilterId;

		//send it to the server.
		console.log("Saving new filterOption...");
		this.Loading = true;
		this.prod.SaveFilterOption_Observable(newFilterOption).subscribe((data) => {
			console.log("Saved!");
			this.Refresh();
		}, error => {
			console.error("Something went wrong while saving new FilterOption... " + error);
		})
	}

	onArrayChange() {
		//update the position values in the array
		for (var i = 0; i < this.FilterOptionsViewModel.FilterOptions.length; i++) {
			this.FilterOptionsViewModel.FilterOptions[i].Position = i;
		}

		//set loading to true while we send the changes to the server.
		console.log("Saving filterOptions positions...");
		this.Loading = true;
		this.prod.SaveFilterOptions_Observable(this.FilterOptionsViewModel).subscribe((data) => {
			this.FilterOptionsViewModel = new FilterOptionsViewModel(data);
			this.Loading = false;
			console.log("Saved!");
		}, error => {
			console.error("Something went wrong while saving filterOptions... " + error);
		});
	}

	onDeleteAtIndex(index: number) {
		var toDelete = this.FilterOptionsViewModel.FilterOptions[index];

		console.log("Deleting filterOption...");
		this.prod.DeleteFilterOption_Observable(toDelete).subscribe((data) => {
			console.log("Deleted!");
			this.Refresh();
		}, error => {
			console.error("Something went wrong while deleting filterOption... " + error);
		});
	}
}
