import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/internal/operators/timeout';
import * as HelpersAngularEvent from '../Helpers/AngularEvent';
import { BehaviorSubject } from 'rxjs';



	export interface IWindowService {
		BroadCastWindowGrootteAangepast(): void;
		WindowGrootteAangepastEvent: HelpersAngularEvent.AngularEvent<boolean>;
		GeefWindowWidth(): number;
	}
	@Injectable({
		providedIn: 'root',
	})
	export class WindowService implements IWindowService {
		private WindowGrootteAangepastEventEmitter: HelpersAngularEvent.IAngularEventEmitter<boolean>;
		private innerWidth: number;
		changeSubject: BehaviorSubject<any>;
		constructor(
		) {
'ngInject';
			this.changeSubject = new BehaviorSubject(0);
			this.WindowGrootteAangepastEventEmitter = HelpersAngularEvent.AngularEvent.Default<boolean>("WindowGrootteAangepast-event", undefined);
			this.innerWidth = window.innerWidth || document.documentElement.clientWidth;

			let self = this;
			let w = window;
			window.onresize = function (event) {
				self.innerWidth = w.innerWidth || document.documentElement.clientWidth;
				self.BroadCastWindowGrootteAangepast();
				self.BroadCastSubjectWindowGrootteAangepast();
			};
		}

		public GeefWindowWidth(): number {
			return this.innerWidth;
		}

		public get WindowGrootteAangepastEvent(): HelpersAngularEvent.AngularEvent<boolean> {
			return this.WindowGrootteAangepastEventEmitter.Event;
		}

		public BroadCastWindowGrootteAangepast(): void {
			this.WindowGrootteAangepastEventEmitter.Emitter.BroadCast(undefined);
		}

		public BroadCastSubjectWindowGrootteAangepast(): void {
			this.changeSubject.next(0);
		 } 
	}
