import { Theme } from '../theme.ts';

export const MerfordTheme: Theme = {
  name: 'merford',
  properties: {
    '--header-font-family': 'Graphik,Arial,sans-serif',
    '--header-font-weight': '300',
    '--header-color': 'rgb(0, 31, 96)',    
    '--header-text-transform': 'none',
    '--body-font-family': 'Barlow,Arial,sans-serif',
    '--color-default-primary-background': '#009cde',
    '--color-default-primary-background-opacity-30': 'rgba(0, 120, 190, 0.3)',
    '--button-primary-border-radius': '0',  
    '--button-secundary-border-radius': '0',
    '--button-primary-color--hover': 'white', 
    '--fab-filters-margin': '0',
    '--fab-product__osf-display': 'none',
    '--fab-product__button-text-color': 'white',
    '--fab-product__button-text-color--hover': 'white',   
    '--fab-product__button-text-color--active': 'white',  
    '--fab-product__button-background-color--hover': 'rgba(0, 120, 190, 0.3)',   
    '--fab-filters__button-border-color': '#009cde',
    '--fab-filters__button-text-color': '#009cde',
    '--fab-filter__selected-option-color': 'rgba(0, 120, 190, 0.3)'  
  }
};
