/// <reference path='../consumerinterfaces/MassConfiguratorController.ts' />
/**
 * @ref WoonConnectApiController/Configurator/MassConfiguratorController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/MassConfiguratorController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as DefineGridModel from "@datacontracts/DefineGridModel";
import * as ChangeBuildingmodel from "@datacontracts/ChangeBuildingmodel";
import * as CreateShortWuidModel from "@datacontracts/CreateShortWuidModel";
import * as CreateApplicationURLModel from "@datacontracts/CreateApplicationURLModel";
import * as CutThroughModel from "@datacontracts/CutThroughModel";
import * as UpdateWuidModel from "@datacontracts/UpdateWuidModel";
import * as AbstractGeometryModel from "@datacontracts/AbstractGeometryModel";
import * as ReplaceWindowByWuid from "@datacontracts/ReplaceWindowByWuid";
import * as PhysicalGeometryModel from "@datacontracts/PhysicalGeometryModel";
import * as MeasurementsModel from "@datacontracts/MeasurementsModel";
import * as SaveModelModel from "@datacontracts/SaveModelModel";
import * as SearchAddressModel from "@datacontracts/SearchAddressModel";
import * as AddDwellingModel from "@datacontracts/AddDwellingModel";
import * as AddMultipleDwellingsModel from "@datacontracts/AddMultipleDwellingsModel";
import * as BlueprintModel from "@datacontracts/BlueprintModel";
import * as GetDemolitionObjectsModel from "@datacontracts/GetDemolitionObjectsModel";
import * as UpdateDemolishedObjectModel from "@datacontracts/UpdateDemolishedObjectModel";
import * as BgtMapModel from "@datacontracts/BgtMapModel";
import * as ExteriorWallsFragmentsModel from "@datacontracts/ExteriorWallsFragmentsModel";
import * as AhnModel from "@datacontracts/AhnModel";
import * as RoomUpdaterModel from "@datacontracts/RoomUpdaterModel";
import * as UploadSearchTreeModel from "@datacontracts/UploadSearchTreeModel";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class MassConfiguratorConsumer implements Interface.IMassConfiguratorConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public DefineGrid_Observable(inputModel: DefineGridModel.IDefineGridRequestModel & {ToJsonContract(noContent?: boolean): DefineGridModel.IDefineGridRequestModel}): Observable<DefineGridModel.IDefineGridResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/DefineGrid`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<DefineGridModel.IDefineGridResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public DefineGrid(inputModel: DefineGridModel.IDefineGridRequestModel & {ToJsonContract(noContent?: boolean): DefineGridModel.IDefineGridRequestModel}): ng.IPromise<DefineGridModel.IDefineGridResponseModel> {
				let obs = this.DefineGrid_Observable(inputModel);
				let promise = new Promise<DefineGridModel.IDefineGridResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ChangeBuildingModel_Observable(inputModel: ChangeBuildingmodel.IChangeBuildingModelRequestModel & {ToJsonContract(noContent?: boolean): ChangeBuildingmodel.IChangeBuildingModelRequestModel}): Observable<ChangeBuildingmodel.IChangeBuildingModelResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/ChangeBuildingModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ChangeBuildingmodel.IChangeBuildingModelResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ChangeBuildingModel(inputModel: ChangeBuildingmodel.IChangeBuildingModelRequestModel & {ToJsonContract(noContent?: boolean): ChangeBuildingmodel.IChangeBuildingModelRequestModel}): ng.IPromise<ChangeBuildingmodel.IChangeBuildingModelResponseModel> {
				let obs = this.ChangeBuildingModel_Observable(inputModel);
				let promise = new Promise<ChangeBuildingmodel.IChangeBuildingModelResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public CreateShortWuid_Observable(inputModel: CreateShortWuidModel.ICreateShortWuidModelRequestModel & {ToJsonContract(noContent?: boolean): CreateShortWuidModel.ICreateShortWuidModelRequestModel}): Observable<CreateShortWuidModel.ICreateShortWuidModelResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/CreateShortWuid`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<CreateShortWuidModel.ICreateShortWuidModelResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public CreateShortWuid(inputModel: CreateShortWuidModel.ICreateShortWuidModelRequestModel & {ToJsonContract(noContent?: boolean): CreateShortWuidModel.ICreateShortWuidModelRequestModel}): ng.IPromise<CreateShortWuidModel.ICreateShortWuidModelResponseModel> {
				let obs = this.CreateShortWuid_Observable(inputModel);
				let promise = new Promise<CreateShortWuidModel.ICreateShortWuidModelResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public CreateApplicationURL_Observable(inputModel: CreateApplicationURLModel.ICreateApplicationURLModelRequestModel & {ToJsonContract(noContent?: boolean): CreateApplicationURLModel.ICreateApplicationURLModelRequestModel}): Observable<CreateApplicationURLModel.ICreateApplicationURLModelResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/CreateApplicationURL`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<CreateApplicationURLModel.ICreateApplicationURLModelResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public CreateApplicationURL(inputModel: CreateApplicationURLModel.ICreateApplicationURLModelRequestModel & {ToJsonContract(noContent?: boolean): CreateApplicationURLModel.ICreateApplicationURLModelRequestModel}): ng.IPromise<CreateApplicationURLModel.ICreateApplicationURLModelResponseModel> {
				let obs = this.CreateApplicationURL_Observable(inputModel);
				let promise = new Promise<CreateApplicationURLModel.ICreateApplicationURLModelResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public CutThrough_Observable(inputModel: CutThroughModel.ICutThroughRequestModel & {ToJsonContract(noContent?: boolean): CutThroughModel.ICutThroughRequestModel}): Observable<CutThroughModel.ICutThroughResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/CutThrough`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<CutThroughModel.ICutThroughResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public CutThrough(inputModel: CutThroughModel.ICutThroughRequestModel & {ToJsonContract(noContent?: boolean): CutThroughModel.ICutThroughRequestModel}): ng.IPromise<CutThroughModel.ICutThroughResponseModel> {
				let obs = this.CutThrough_Observable(inputModel);
				let promise = new Promise<CutThroughModel.ICutThroughResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UpdateWuid_Observable(inputModel: UpdateWuidModel.IUpdateWuidRequestModel & {ToJsonContract(noContent?: boolean): UpdateWuidModel.IUpdateWuidRequestModel}): Observable<UpdateWuidModel.IUpdateWuidResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/UpdateWuid`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UpdateWuidModel.IUpdateWuidResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UpdateWuid(inputModel: UpdateWuidModel.IUpdateWuidRequestModel & {ToJsonContract(noContent?: boolean): UpdateWuidModel.IUpdateWuidRequestModel}): ng.IPromise<UpdateWuidModel.IUpdateWuidResponseModel> {
				let obs = this.UpdateWuid_Observable(inputModel);
				let promise = new Promise<UpdateWuidModel.IUpdateWuidResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetAbstractGeometry_Observable(inputModel: AbstractGeometryModel.IGetAbstractGeometryRequestModel & {ToJsonContract(noContent?: boolean): AbstractGeometryModel.IGetAbstractGeometryRequestModel}): Observable<AbstractGeometryModel.IGetAbstractGeometryResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/GetAbstractGeometry`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AbstractGeometryModel.IGetAbstractGeometryResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetAbstractGeometry(inputModel: AbstractGeometryModel.IGetAbstractGeometryRequestModel & {ToJsonContract(noContent?: boolean): AbstractGeometryModel.IGetAbstractGeometryRequestModel}): ng.IPromise<AbstractGeometryModel.IGetAbstractGeometryResponseModel> {
				let obs = this.GetAbstractGeometry_Observable(inputModel);
				let promise = new Promise<AbstractGeometryModel.IGetAbstractGeometryResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SetAbstractGeometry_Observable(inputModel: AbstractGeometryModel.ISetAbstractGeometryRequestModel & {ToJsonContract(noContent?: boolean): AbstractGeometryModel.ISetAbstractGeometryRequestModel}): Observable<AbstractGeometryModel.ISetAbstractGeometryResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/SetAbstractGeometry`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AbstractGeometryModel.ISetAbstractGeometryResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SetAbstractGeometry(inputModel: AbstractGeometryModel.ISetAbstractGeometryRequestModel & {ToJsonContract(noContent?: boolean): AbstractGeometryModel.ISetAbstractGeometryRequestModel}): ng.IPromise<AbstractGeometryModel.ISetAbstractGeometryResponseModel> {
				let obs = this.SetAbstractGeometry_Observable(inputModel);
				let promise = new Promise<AbstractGeometryModel.ISetAbstractGeometryResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ReplaceWindowByWuid_Observable(inputModel: ReplaceWindowByWuid.IReplaceWindowByWuidRequestModel & {ToJsonContract(noContent?: boolean): ReplaceWindowByWuid.IReplaceWindowByWuidRequestModel}): Observable<ReplaceWindowByWuid.IReplaceWindowByWuidResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/ReplaceWindowByWuid`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ReplaceWindowByWuid.IReplaceWindowByWuidResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ReplaceWindowByWuid(inputModel: ReplaceWindowByWuid.IReplaceWindowByWuidRequestModel & {ToJsonContract(noContent?: boolean): ReplaceWindowByWuid.IReplaceWindowByWuidRequestModel}): ng.IPromise<ReplaceWindowByWuid.IReplaceWindowByWuidResponseModel> {
				let obs = this.ReplaceWindowByWuid_Observable(inputModel);
				let promise = new Promise<ReplaceWindowByWuid.IReplaceWindowByWuidResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetPhysicalGeometry_Observable(inputModel: PhysicalGeometryModel.IGetPhysicalGeometryRequestModel & {ToJsonContract(noContent?: boolean): PhysicalGeometryModel.IGetPhysicalGeometryRequestModel}): Observable<PhysicalGeometryModel.IGetPhysicalGeometryResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/GetPhysicalGeometry`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<PhysicalGeometryModel.IGetPhysicalGeometryResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetPhysicalGeometry(inputModel: PhysicalGeometryModel.IGetPhysicalGeometryRequestModel & {ToJsonContract(noContent?: boolean): PhysicalGeometryModel.IGetPhysicalGeometryRequestModel}): ng.IPromise<PhysicalGeometryModel.IGetPhysicalGeometryResponseModel> {
				let obs = this.GetPhysicalGeometry_Observable(inputModel);
				let promise = new Promise<PhysicalGeometryModel.IGetPhysicalGeometryResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetMeasurements_Observable(inputModel: MeasurementsModel.IGetMeasurementsRequestModel & {ToJsonContract(noContent?: boolean): MeasurementsModel.IGetMeasurementsRequestModel}): Observable<MeasurementsModel.IGetMeasurementsResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/GetMeasurements`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<MeasurementsModel.IGetMeasurementsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetMeasurements(inputModel: MeasurementsModel.IGetMeasurementsRequestModel & {ToJsonContract(noContent?: boolean): MeasurementsModel.IGetMeasurementsRequestModel}): ng.IPromise<MeasurementsModel.IGetMeasurementsResponseModel> {
				let obs = this.GetMeasurements_Observable(inputModel);
				let promise = new Promise<MeasurementsModel.IGetMeasurementsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SaveModel_Observable(inputModel: SaveModelModel.ISaveModelRequestModel & {ToJsonContract(noContent?: boolean): SaveModelModel.ISaveModelRequestModel}): Observable<SaveModelModel.ISaveModelResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/SaveModel`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<SaveModelModel.ISaveModelResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SaveModel(inputModel: SaveModelModel.ISaveModelRequestModel & {ToJsonContract(noContent?: boolean): SaveModelModel.ISaveModelRequestModel}): ng.IPromise<SaveModelModel.ISaveModelResponseModel> {
				let obs = this.SaveModel_Observable(inputModel);
				let promise = new Promise<SaveModelModel.ISaveModelResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SearchAddress_Observable(inputModel: SearchAddressModel.ISearchAddressRequestModel & {ToJsonContract(noContent?: boolean): SearchAddressModel.ISearchAddressRequestModel}): Observable<SearchAddressModel.ISearchAddressResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/SearchAddress`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<SearchAddressModel.ISearchAddressResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SearchAddress(inputModel: SearchAddressModel.ISearchAddressRequestModel & {ToJsonContract(noContent?: boolean): SearchAddressModel.ISearchAddressRequestModel}): ng.IPromise<SearchAddressModel.ISearchAddressResponseModel> {
				let obs = this.SearchAddress_Observable(inputModel);
				let promise = new Promise<SearchAddressModel.ISearchAddressResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AddDwelling_Observable(inputModel: AddDwellingModel.IAddDwellingRequestModel & {ToJsonContract(noContent?: boolean): AddDwellingModel.IAddDwellingRequestModel}): Observable<AddDwellingModel.IAddDwellingResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/AddDwelling`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AddDwellingModel.IAddDwellingResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AddDwelling(inputModel: AddDwellingModel.IAddDwellingRequestModel & {ToJsonContract(noContent?: boolean): AddDwellingModel.IAddDwellingRequestModel}): ng.IPromise<AddDwellingModel.IAddDwellingResponseModel> {
				let obs = this.AddDwelling_Observable(inputModel);
				let promise = new Promise<AddDwellingModel.IAddDwellingResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AddMultipleDwellings_Observable(inputModel: AddMultipleDwellingsModel.IAddMultipleDwellingsRequestModel & {ToJsonContract(noContent?: boolean): AddMultipleDwellingsModel.IAddMultipleDwellingsRequestModel}): Observable<AddMultipleDwellingsModel.IAddMultipleDwellingsResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/AddMultipleDwellings`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AddMultipleDwellingsModel.IAddMultipleDwellingsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AddMultipleDwellings(inputModel: AddMultipleDwellingsModel.IAddMultipleDwellingsRequestModel & {ToJsonContract(noContent?: boolean): AddMultipleDwellingsModel.IAddMultipleDwellingsRequestModel}): ng.IPromise<AddMultipleDwellingsModel.IAddMultipleDwellingsResponseModel> {
				let obs = this.AddMultipleDwellings_Observable(inputModel);
				let promise = new Promise<AddMultipleDwellingsModel.IAddMultipleDwellingsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetBlueprint_Observable(inputModel: BlueprintModel.IGetBlueprintRequestModel & {ToJsonContract(noContent?: boolean): BlueprintModel.IGetBlueprintRequestModel}): Observable<BlueprintModel.IGetBlueprintResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/GetBlueprint`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BlueprintModel.IGetBlueprintResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetBlueprint(inputModel: BlueprintModel.IGetBlueprintRequestModel & {ToJsonContract(noContent?: boolean): BlueprintModel.IGetBlueprintRequestModel}): ng.IPromise<BlueprintModel.IGetBlueprintResponseModel> {
				let obs = this.GetBlueprint_Observable(inputModel);
				let promise = new Promise<BlueprintModel.IGetBlueprintResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GetDemolitionObjects_Observable(inputModel: GetDemolitionObjectsModel.IGetDemolitionObjectsRequestModel & {ToJsonContract(noContent?: boolean): GetDemolitionObjectsModel.IGetDemolitionObjectsRequestModel}): Observable<GetDemolitionObjectsModel.IGetDemolitionObjectsResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/GetDemolitionObjects`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GetDemolitionObjectsModel.IGetDemolitionObjectsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GetDemolitionObjects(inputModel: GetDemolitionObjectsModel.IGetDemolitionObjectsRequestModel & {ToJsonContract(noContent?: boolean): GetDemolitionObjectsModel.IGetDemolitionObjectsRequestModel}): ng.IPromise<GetDemolitionObjectsModel.IGetDemolitionObjectsResponseModel> {
				let obs = this.GetDemolitionObjects_Observable(inputModel);
				let promise = new Promise<GetDemolitionObjectsModel.IGetDemolitionObjectsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UpdateDemolishedObject_Observable(inputModel: UpdateDemolishedObjectModel.IUpdateDemolishedObjectRequestModel & {ToJsonContract(noContent?: boolean): UpdateDemolishedObjectModel.IUpdateDemolishedObjectRequestModel}): Observable<UpdateDemolishedObjectModel.IUpdateDemolishedObjectResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/UpdateDemolishedObject`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UpdateDemolishedObjectModel.IUpdateDemolishedObjectResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UpdateDemolishedObject(inputModel: UpdateDemolishedObjectModel.IUpdateDemolishedObjectRequestModel & {ToJsonContract(noContent?: boolean): UpdateDemolishedObjectModel.IUpdateDemolishedObjectRequestModel}): ng.IPromise<UpdateDemolishedObjectModel.IUpdateDemolishedObjectResponseModel> {
				let obs = this.UpdateDemolishedObject_Observable(inputModel);
				let promise = new Promise<UpdateDemolishedObjectModel.IUpdateDemolishedObjectResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public SetBlueprint_Observable(inputModel: BlueprintModel.ISetBlueprintRequestModel & {ToJsonContract(noContent?: boolean): BlueprintModel.ISetBlueprintRequestModel}): Observable<BlueprintModel.ISetBlueprintResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/SetBlueprint`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BlueprintModel.ISetBlueprintResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public SetBlueprint(inputModel: BlueprintModel.ISetBlueprintRequestModel & {ToJsonContract(noContent?: boolean): BlueprintModel.ISetBlueprintRequestModel}): ng.IPromise<BlueprintModel.ISetBlueprintResponseModel> {
				let obs = this.SetBlueprint_Observable(inputModel);
				let promise = new Promise<BlueprintModel.ISetBlueprintResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public BgtMap_Observable(inputModel: BgtMapModel.IBgtMapRequestModel & {ToJsonContract(noContent?: boolean): BgtMapModel.IBgtMapRequestModel}): Observable<BgtMapModel.IBgtMapResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/BgtMap`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<BgtMapModel.IBgtMapResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public BgtMap(inputModel: BgtMapModel.IBgtMapRequestModel & {ToJsonContract(noContent?: boolean): BgtMapModel.IBgtMapRequestModel}): ng.IPromise<BgtMapModel.IBgtMapResponseModel> {
				let obs = this.BgtMap_Observable(inputModel);
				let promise = new Promise<BgtMapModel.IBgtMapResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ExteriorWallsFragments_Observable(inputModel: ExteriorWallsFragmentsModel.IExteriorWallsFragmentsRequestModel & {ToJsonContract(noContent?: boolean): ExteriorWallsFragmentsModel.IExteriorWallsFragmentsRequestModel}): Observable<ExteriorWallsFragmentsModel.IExteriorWallsFragmentsResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/ExteriorWallsFragments`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ExteriorWallsFragmentsModel.IExteriorWallsFragmentsResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ExteriorWallsFragments(inputModel: ExteriorWallsFragmentsModel.IExteriorWallsFragmentsRequestModel & {ToJsonContract(noContent?: boolean): ExteriorWallsFragmentsModel.IExteriorWallsFragmentsRequestModel}): ng.IPromise<ExteriorWallsFragmentsModel.IExteriorWallsFragmentsResponseModel> {
				let obs = this.ExteriorWallsFragments_Observable(inputModel);
				let promise = new Promise<ExteriorWallsFragmentsModel.IExteriorWallsFragmentsResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AhnHeight_Observable(inputModel: AhnModel.IAhnRequestModel & {ToJsonContract(noContent?: boolean): AhnModel.IAhnRequestModel}): Observable<AhnModel.IAhnResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/AhnHeight`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AhnModel.IAhnResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AhnHeight(inputModel: AhnModel.IAhnRequestModel & {ToJsonContract(noContent?: boolean): AhnModel.IAhnRequestModel}): ng.IPromise<AhnModel.IAhnResponseModel> {
				let obs = this.AhnHeight_Observable(inputModel);
				let promise = new Promise<AhnModel.IAhnResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public AutomaticAhn_Observable(inputModel: AhnModel.IAutomaticAhnRequestModel & {ToJsonContract(noContent?: boolean): AhnModel.IAutomaticAhnRequestModel}): Observable<AhnModel.IAutomaticAhnResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/AutomaticAhn`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<AhnModel.IAutomaticAhnResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public AutomaticAhn(inputModel: AhnModel.IAutomaticAhnRequestModel & {ToJsonContract(noContent?: boolean): AhnModel.IAutomaticAhnRequestModel}): ng.IPromise<AhnModel.IAutomaticAhnResponseModel> {
				let obs = this.AutomaticAhn_Observable(inputModel);
				let promise = new Promise<AhnModel.IAutomaticAhnResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public RoomUpdater_Observable(inputModel: RoomUpdaterModel.IRoomUpdaterRequestModel & {ToJsonContract(noContent?: boolean): RoomUpdaterModel.IRoomUpdaterRequestModel}): Observable<RoomUpdaterModel.IRoomUpdaterResponseModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/MassConfigurator/RoomUpdater`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<RoomUpdaterModel.IRoomUpdaterResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public RoomUpdater(inputModel: RoomUpdaterModel.IRoomUpdaterRequestModel & {ToJsonContract(noContent?: boolean): RoomUpdaterModel.IRoomUpdaterRequestModel}): ng.IPromise<RoomUpdaterModel.IRoomUpdaterResponseModel> {
				let obs = this.RoomUpdater_Observable(inputModel);
				let promise = new Promise<RoomUpdaterModel.IRoomUpdaterResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UploadSearchTree_Observable(): Observable<UploadSearchTreeModel.IUploadSearchTreeResponseModel> {
				let operation = this.m_Operation++;
				let consumerdata = null;
				let apiURL = `api/MassConfigurator/UploadSearchTree`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<UploadSearchTreeModel.IUploadSearchTreeResponseModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UploadSearchTree(): ng.IPromise<UploadSearchTreeModel.IUploadSearchTreeResponseModel> {
				let obs = this.UploadSearchTree_Observable();
				let promise = new Promise<UploadSearchTreeModel.IUploadSearchTreeResponseModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


