import * as ng from 'angular';
import * as HelpersBackwards from '../Helpers/Backwards';
import * as ServicesProjectService from '../services/ProjectService';
import * as ServicesWebBerichtService from '../services/WebBerichtService';
import * as ComponentsfooterWctFooter from '../components/footer/WctFooter';
import * as ViewModelsWebGLSessie from '../Models/WebGLSessie';
import { WctLogService}  from '../services/WctLogService';

import { Injectable } from '@angular/core';



export interface ISceneService {
	Reken: ComponentsfooterWctFooter.BezigCollectie;
	Download: ComponentsfooterWctFooter.BezigCollectie;
	Viewer: ComponentsfooterWctFooter.BezigCollectie;
	Opslaan: ComponentsfooterWctFooter.BezigCollectie;

	WebGLError: boolean;

	ZetActieveSessie(sess: ViewModelsWebGLSessie.WebGLSessie);
	GeefActieveSessie(): ViewModelsWebGLSessie.WebGLSessie;

	GebruikWebGL(): boolean;

	Error(s: string);

	DumpConfigSleutels();
	WebGLOndersteuningDialoogGetoond: boolean;
}

export enum WebGLSupport {
	BrowserNiet,
	BrowserWelGrafischeKaartNiet,
	Wel
}


@Injectable({
	providedIn: 'root',
})
export class SceneService implements ISceneService {
	Reken: ComponentsfooterWctFooter.BezigCollectie = new ComponentsfooterWctFooter.BezigCollectie();
	Download: ComponentsfooterWctFooter.BezigCollectie = new ComponentsfooterWctFooter.BezigCollectie();
	Viewer: ComponentsfooterWctFooter.BezigCollectie = new ComponentsfooterWctFooter.BezigCollectie();
	Opslaan: ComponentsfooterWctFooter.BezigCollectie = new ComponentsfooterWctFooter.BezigCollectie();
	private m_ActieveSessie: ViewModelsWebGLSessie.WebGLSessie;
	public WebGLError: boolean = false;
	public WebGLOndersteuningDialoogGetoond: boolean = false;
	private m_WebGLOndersteuning: WebGLSupport = null;
	private m_ToonFPS: boolean = false;

	ZetActieveSessie(sess: ViewModelsWebGLSessie.WebGLSessie) {
		this.m_ActieveSessie = sess;
	}

	GeefActieveSessie(): ViewModelsWebGLSessie.WebGLSessie {
		return this.m_ActieveSessie;
	}

	Error(s: string) {
		this.$log.warn(s);
	}

	constructor(private $log: WctLogService) {
		'ngInject';
		this.m_WebGLOndersteuning = this.HeeftWebGLIntern();
	}


	GebruikWebGL(): boolean {
		if (this.WebGLError) {
			return false;
		}

		return this.HeeftWebGL() == WebGLSupport.Wel;
	}

	DumpConfigSleutels() {
		if (this.m_ActieveSessie != null) {
			this.m_ActieveSessie.SceneSession.DumpViewer();
		}
	}

	HeeftWebGL(): WebGLSupport {
		return this.m_WebGLOndersteuning;
	}

	private HeeftWebGLIntern(): WebGLSupport {
		var queryWebGL = HelpersBackwards.queryWebGLSupport();
		if (queryWebGL != null) {
			var online3DSupport = queryWebGL.isSupported;
			if (online3DSupport) {
				var supportOnMachine = queryWebGL.isSupportedByMachine;
				if (supportOnMachine) {
					return WebGLSupport.Wel;
				}
			}
			else {
				if (queryWebGL.isMobile) {

					if (queryWebGL.browserOS == 'iPad') {
						if (queryWebGL.browserName == 'Safari' && queryWebGL.browserVersion >= 8) {
							return WebGLSupport.Wel;
						}
						if (queryWebGL.browserName == 'Chrome' && queryWebGL.browserVersion >= 48) {
							return WebGLSupport.Wel;
						}
					}
					else if (queryWebGL.browserOS == 'Android') {
						if (queryWebGL.browserName == 'Chrome' && queryWebGL.browserVersion >= 48) {
							return WebGLSupport.Wel;
						}
					}
				}
			}
		}
		return WebGLSupport.BrowserNiet;
	}
}
