<div class="flex-cont cont-title">
	<p class="txt-title">Foutmelding</p>
	<img (click)="clickAnnuleren()" src="/Content/Images/menu/menu-close-white.svg" class="img-title" />
</div>

<div class="cont-main">
    <h3>3D viewer is niet beschikbaar</h3>
    <p [hidden[="WebGLError || (oorzaak == 'isImacOfMacbookEnFirefox')">Foutmelding: er is een fout opgetreden bij het aansturen van de 3D viewer.</p>
    <p *ngIf="WebGLError && (oorzaak !== 'isImacOfMacbookEnFirefox')">
        Er is geen 3D viewer beschikbaar. Gebruik Chrome of Firefox en/of update de drivers van de videokaart:
    </p>
    <div *ngIf="WebGLError && (oorzaak !== 'isImacOfMacbookEnFirefox')" class="flex-cont cont-logos-browsers">
        <a href="" href="{{downloadChrome()}}" target="_blank">
            <img class="img-logo" src="../../../Content/Images/chrome.svg" />
            <p class="txt-logo">Download Chrome</p>
        </a>
        <a href="" href="{{downloadFirefox()}}" target="_blank">
            <img class="img-logo" src="../../../Content/Images/firefox.svg" />
            <p class="txt-logo">Download Firefox</p>
        </a>
    </div>

	<p *ngIf="oorzaak == 'isImacOfMacbookEnFirefox'">
		Er is geen 3D viewer beschikbaar. Gebruik Safari of Chrome en/of update de drivers van de videokaart:
	</p>
	<div *ngIf="oorzaak == 'isImacOfMacbookEnFirefox'" class="flex-cont cont-logos-browsers">
		<a href="" target="_blank">
			<img class="img-logo" src="../../../Content/Images/safari.svg" />
			<p class="txt-logo">Safari</p>
		</a>
		<a href="" href="{{downloadChrome()}}" target="_blank">
			<img class="img-logo" src="../../../Content/Images/Chrome.svg" />
			<p class="txt-logo">Download Chrome</p>
		</a>
	</div>

    <p>Blijft u problemen houden met de 3D viewer? Stuur dan een e-mail naar <a href="{{geefSupportEmailadresLinkToEmailProgram('problemen met 3D viewer')}}">{{geefSupportEmailadres()}}</a><br />We nemen binnen één werkdag contact met u op.</p>
</div>