<div style="display: flex;">
  <div>
    <header></header>
  </div>
  <div style="flex: auto; margin-left: 240px;">
    <div style="min-height: 835px;">
      <router-outlet></router-outlet>
    </div>
    <footer></footer>
  </div>
</div>

