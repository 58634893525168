import { createReducer, on, ActionReducerMap } from '@ngrx/store';
import * as Actions from './survey.actions';
import { FORM_ID, SurveyCategories, SurveyState } from './survey.state';
import { initialSurveyState } from './survey.state';
import * as _ from 'lodash';
import { WijzigScopeEnum } from '@enums/WijzigScopeEnum';
import { DwellingSurvey } from '@models/DwellingSurvey';
import { ISurveyProperty } from '@datacontracts/SurveyProperty';
import { addArrayControl, createFormGroupState, FormGroupControls, FormGroupState, onNgrxForms, updateGroup } from 'ngrx-forms';
import { IDwellingSurvey } from '@datacontracts/DwellingSurvey';
import { state } from '@angular/animations';
import { treenode } from '../../smarttwin-ui/components/wct-stui-tree-menu/wct-stui-tree-menu.component';
import { v4 as uuidv4 } from 'uuid';
import * as SurveyClimateSystemEnum from "@enums/SurveyClimateSystem";
import { ISurveyClimateSystem } from '@datacontracts/SurveyClimateSystem';
import { SurveyClimateSystem } from '@models/SurveyClimateSystem';
import { changeScenarioAndDwellingSuccess } from '@services/dwellingstore/dwelling.actions';

function copystate(state: SurveyState): SurveyState {
  return ({ ...state });
}

function setNodeActive(id: string, nodesArray: treenode[]): treenode[] {
  nodesArray.forEach((node) => {
    if (node.id == id) {
      nodesArray.map((node) => {
        if (node.id !== id) {
          node.active = false;
        }
      })
      node.active = node.active ? false : true;
    }
    if (node.children.length > 0) {
      setNodeActive(id, node.children);
    }
  })
  return nodesArray;
}

function getClimateSystemName(HeatingDevice: SurveyClimateSystemEnum.HeatingDevice): string {
  switch (HeatingDevice) {
    case SurveyClimateSystemEnum.HeatingDevice.BiomassBoiler:
      return "Biomassa ketel"
    case SurveyClimateSystemEnum.HeatingDevice.BiomassCombiboiler:
      return "Biomassa combiketel"
    case SurveyClimateSystemEnum.HeatingDevice.Boiler:
      return "Ketel"
    case SurveyClimateSystemEnum.HeatingDevice.BoilerWithHeatPowerCoupling:
      return "Ketel met WKK"
    case SurveyClimateSystemEnum.HeatingDevice.CombiHeatpump:
      return "Combiwarmtepomp"
    case SurveyClimateSystemEnum.HeatingDevice.Combiboiler:
      return "Combiketel"
    case SurveyClimateSystemEnum.HeatingDevice.CombiboilerHeatPowerCoupling:
      return "Combiketel met WKK"
    case SurveyClimateSystemEnum.HeatingDevice.Heatpump:
      return "Wamtepomp"
    case SurveyClimateSystemEnum.HeatingDevice.Local:
      return "Lokale verwarming"
    case SurveyClimateSystemEnum.HeatingDevice.SupplyByThirdParty:
      return "Warmtelevering derden"
    default:
      return "Onbekend systeem"
  }
}

function updateSurveyTree(controls: FormGroupControls<IDwellingSurvey>, tree: treenode[]): treenode[] {
  controls.ClimateSystem.controls.forEach((control) => {
    let isNewSystem: boolean = !(tree.some((node) =>
      node.category == SurveyCategories.Installations && node.children.some((system) => system.tag == control.id)
    ));
    if (isNewSystem) {
      tree.map((node) => {
        if (node.category == SurveyCategories.Installations) {
          let newNode: treenode = {
            id: control.controls.ObjectId.value,
            image: '/Content/Images/configurator/surveytree/Opbouw_gevel.svg',
            title: getClimateSystemName(control.value.HeatingDevice),
            category: SurveyCategories.ClimateSystem,
            active: false,
            children: [],
            tag: control.id
          }
          node.children.push(newNode);
        }
      });
    }
  })
  return tree;
}

function getActiveClimateSystemId(state: SurveyState, tag: string): string {
  let systemId: string = '';
  state.Survey.controls.ClimateSystem.controls.forEach((system) => {
    if (system.id == tag) {
      systemId = system.id;
    }
  });
  return systemId;
}

export const surveyReducer = createReducer(initialSurveyState,
  onNgrxForms(),
  on(changeScenarioAndDwellingSuccess, (oldstate) => {
    let state = copystate(oldstate);
    state.Survey = initialSurveyState.Survey;
    let surveyTree: treenode[] = _.cloneDeep(state.SurveyTree);
    state.SurveyTree = updateSurveyTree(state.Survey.controls, surveyTree);
    return state;
  }),
  on(Actions.surveyLoaded, (oldstate, { survey }) => {
    let state = copystate(oldstate);
    const loadedState = createFormGroupState<IDwellingSurvey>(FORM_ID, survey);
    state.Survey = loadedState;
    let surveyTree: treenode[] = _.cloneDeep(state.SurveyTree);
    state.SurveyTree = updateSurveyTree(state.Survey.controls, surveyTree);
    return state;
  }),
  on(Actions.setActiveTreeNode, (oldstate, { node }) => {
    let newState = copystate(oldstate);
    let surveyTree: treenode[] = _.cloneDeep(newState.SurveyTree);
    let activeNode: treenode = _.cloneDeep(node);
    newState.SurveyTree = setNodeActive(node.id, surveyTree);
    if (node.tag) {
      newState.ActiveClimateSystemId = getActiveClimateSystemId(newState, node.tag);
    }
    if (!node.active) {
      activeNode.active = true;
      newState.ActiveNode = activeNode;
    }
    return newState;
  }),
  on(Actions.addClimateSystem, (oldstate, { system }) => {
    var newSystem: ISurveyClimateSystem = new SurveyClimateSystem().ToJsonContract();
    newSystem.ObjectId = '00000000-0000-0000-0000-000000000000'
    let state = copystate(oldstate);
    state = {
      Survey: updateGroup(state.Survey, {
        ClimateSystem: addArrayControl(newSystem)
      }),
      ActiveNode: state.ActiveNode,
      SurveyTree: state.SurveyTree,
      ActiveClimateSystemId: state.ActiveClimateSystemId
    }
    let surveyTree: treenode[] = _.cloneDeep(state.SurveyTree);
    state.SurveyTree = updateSurveyTree(state.Survey.controls, surveyTree);
    return state;
  }),
);

export const surveyFeatureKey = 'survey';
