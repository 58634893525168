<div class="dialog__header">
  <p class="dialog__title">Disclaimer</p>
  <i (click)="Close()" class="fal fa-times"></i>
</div>
<div class="dialog__main">
  <wct-disclaimer></wct-disclaimer>
</div>
<div class="dialog__btns">
  <button (click)="Close()" class="dialog__btn__primary">Sluit disclaimer</button>
</div>


