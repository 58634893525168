/**
 * @ref WoonConnectViewModel/Tender/DwellingCostViewModel.cs
 */

 import * as DataContracts from "@datacontracts/DwellingCostViewModel";

 
import * as BuildingComponentInstanceViewModel from "./BuildingComponentInstanceViewModel";
import * as MeasureViewModel from "./MeasureViewModel";
import * as BuildingComponentQuantityViewModel from "./BuildingComponentQuantityViewModel";
import * as EfficacyViewModel from "./EfficacyViewModel";



	/**
	 * Originele namespace: WoonConnectViewModel.Tender.DwellingCostRequestModel
	 */
	 

	export interface IDwellingCostRequestModel {
		DwellingId: number;
		ToJsonContract(): DataContracts.IDwellingCostRequestModel;
	}

	export class DwellingCostRequestModel {
		// property-Properties
		public get DwellingId(): number {
			return this._DwellingId;
		}
		public set DwellingId(newDwellingId: number) {
			this._DwellingId = newDwellingId;
		}

		// field-Properties

		// fields
		protected _DwellingId: number = 0;

		constructor(data?: DataContracts.IDwellingCostRequestModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDwellingCostRequestModel): void {
			if(data) {
				// vul properties
				this._DwellingId = data.DwellingId;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDwellingCostRequestModel {
			return {
				DwellingId: this.DwellingId
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Tender.DwellingCostViewModel
	 */
	 

	export interface IDwellingCostViewModel {
		BuildingComponents: BuildingComponentCostViewModel[];
		Cost: number;
		ToJsonContract(): DataContracts.IDwellingCostViewModel;
	}

	export class DwellingCostViewModel {
		// property-Properties
		public get BuildingComponents(): BuildingComponentCostViewModel[] {
			return this._BuildingComponents;
		}
		public set BuildingComponents(newBuildingComponents: BuildingComponentCostViewModel[]) {
			this._BuildingComponents = newBuildingComponents;
		}

		public get Cost(): number {
			return this._Cost;
		}
		public set Cost(newCost: number) {
			this._Cost = newCost;
		}

		// field-Properties

		// fields
		protected _BuildingComponents: BuildingComponentCostViewModel[] = [];
		protected _Cost: number = 0;

		constructor(data?: DataContracts.IDwellingCostViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IDwellingCostViewModel): void {
			if(data) {
				// vul properties
				this._BuildingComponents = data.BuildingComponents ? data.BuildingComponents.map(buildingComponentCostViewModelItem => new BuildingComponentCostViewModel(buildingComponentCostViewModelItem)): [];
				this._Cost = data.Cost;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IDwellingCostViewModel {
			return {
				BuildingComponents: (this.BuildingComponents ? this.BuildingComponents.map(buildingComponentCostViewModelItem => new BuildingComponentCostViewModel(buildingComponentCostViewModelItem).ToJsonContract()): []),
				Cost: this.Cost
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Tender.BuildingComponentCostViewModel
	 */
	 

	export interface IBuildingComponentCostViewModel {
		BuildingComponentInstance: BuildingComponentInstanceViewModel.IBuildingComponentInstanceViewModel;
		MeasureViewModel: MeasureViewModel.IMeasureViewModel;
		QuantityViewModels: BuildingComponentInstanceQuantityViewModel[];
		MeasureViewModels: BuildingComponentInstanceMeasureViewModel[];
		EfficacyViewModels: BuildingComponentInstanceEfficacyViewModel[];
		Cost: number;
		ToJsonContract(): DataContracts.IBuildingComponentCostViewModel;
	}

	export class BuildingComponentCostViewModel {
		// property-Properties
		public get BuildingComponentInstance(): BuildingComponentInstanceViewModel.IBuildingComponentInstanceViewModel {
			return this._BuildingComponentInstance;
		}
		public set BuildingComponentInstance(newBuildingComponentInstance: BuildingComponentInstanceViewModel.IBuildingComponentInstanceViewModel) {
			this._BuildingComponentInstance = newBuildingComponentInstance;
		}

		public get MeasureViewModel(): MeasureViewModel.IMeasureViewModel {
			return this._MeasureViewModel;
		}
		public set MeasureViewModel(newMeasureViewModel: MeasureViewModel.IMeasureViewModel) {
			this._MeasureViewModel = newMeasureViewModel;
		}

		public get QuantityViewModels(): BuildingComponentInstanceQuantityViewModel[] {
			return this._QuantityViewModels;
		}
		public set QuantityViewModels(newQuantityViewModels: BuildingComponentInstanceQuantityViewModel[]) {
			this._QuantityViewModels = newQuantityViewModels;
		}

		public get MeasureViewModels(): BuildingComponentInstanceMeasureViewModel[] {
			return this._MeasureViewModels;
		}
		public set MeasureViewModels(newMeasureViewModels: BuildingComponentInstanceMeasureViewModel[]) {
			this._MeasureViewModels = newMeasureViewModels;
		}

		public get EfficacyViewModels(): BuildingComponentInstanceEfficacyViewModel[] {
			return this._EfficacyViewModels;
		}
		public set EfficacyViewModels(newEfficacyViewModels: BuildingComponentInstanceEfficacyViewModel[]) {
			this._EfficacyViewModels = newEfficacyViewModels;
		}

		public get Cost(): number {
			return this._Cost;
		}
		public set Cost(newCost: number) {
			this._Cost = newCost;
		}

		// field-Properties

		// fields
		protected _BuildingComponentInstance: BuildingComponentInstanceViewModel.IBuildingComponentInstanceViewModel = new BuildingComponentInstanceViewModel.BuildingComponentInstanceViewModel(undefined);
		protected _MeasureViewModel: MeasureViewModel.IMeasureViewModel = new MeasureViewModel.MeasureViewModel(undefined);
		protected _QuantityViewModels: BuildingComponentInstanceQuantityViewModel[] = [];
		protected _MeasureViewModels: BuildingComponentInstanceMeasureViewModel[] = [];
		protected _EfficacyViewModels: BuildingComponentInstanceEfficacyViewModel[] = [];
		protected _Cost: number = 0;

		constructor(data?: DataContracts.IBuildingComponentCostViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentCostViewModel): void {
			if(data) {
				// vul properties
				this._BuildingComponentInstance = data.BuildingComponentInstance ? new BuildingComponentInstanceViewModel.BuildingComponentInstanceViewModel(data.BuildingComponentInstance): undefined;
				this._MeasureViewModel = data.MeasureViewModel ? new MeasureViewModel.MeasureViewModel(data.MeasureViewModel): undefined;
				this._QuantityViewModels = data.QuantityViewModels ? data.QuantityViewModels.map(buildingComponentInstanceQuantityViewModelItem => new BuildingComponentInstanceQuantityViewModel(buildingComponentInstanceQuantityViewModelItem)): [];
				this._MeasureViewModels = data.MeasureViewModels ? data.MeasureViewModels.map(buildingComponentInstanceMeasureViewModelItem => new BuildingComponentInstanceMeasureViewModel(buildingComponentInstanceMeasureViewModelItem)): [];
				this._EfficacyViewModels = data.EfficacyViewModels ? data.EfficacyViewModels.map(buildingComponentInstanceEfficacyViewModelItem => new BuildingComponentInstanceEfficacyViewModel(buildingComponentInstanceEfficacyViewModelItem)): [];
				this._Cost = data.Cost;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentCostViewModel {
			return {
				BuildingComponentInstance: (this.BuildingComponentInstance ? new BuildingComponentInstanceViewModel.BuildingComponentInstanceViewModel(this.BuildingComponentInstance).ToJsonContract(): this.BuildingComponentInstance),
				MeasureViewModel: (this.MeasureViewModel ? new MeasureViewModel.MeasureViewModel(this.MeasureViewModel).ToJsonContract(): this.MeasureViewModel),
				QuantityViewModels: (this.QuantityViewModels ? this.QuantityViewModels.map(buildingComponentInstanceQuantityViewModelItem => new BuildingComponentInstanceQuantityViewModel(buildingComponentInstanceQuantityViewModelItem).ToJsonContract()): []),
				MeasureViewModels: (this.MeasureViewModels ? this.MeasureViewModels.map(buildingComponentInstanceMeasureViewModelItem => new BuildingComponentInstanceMeasureViewModel(buildingComponentInstanceMeasureViewModelItem).ToJsonContract()): []),
				EfficacyViewModels: (this.EfficacyViewModels ? this.EfficacyViewModels.map(buildingComponentInstanceEfficacyViewModelItem => new BuildingComponentInstanceEfficacyViewModel(buildingComponentInstanceEfficacyViewModelItem).ToJsonContract()): []),
				Cost: this.Cost
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Tender.BuildingComponentInstanceQuantityViewModel
	 */
	 

	export interface IBuildingComponentInstanceQuantityViewModel {
		Id: number;
		QuantityViewModel: BuildingComponentQuantityViewModel.IBuildingComponentQuantityViewModel;
		Quantity: number;
		ToJsonContract(): DataContracts.IBuildingComponentInstanceQuantityViewModel;
	}

	export class BuildingComponentInstanceQuantityViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get QuantityViewModel(): BuildingComponentQuantityViewModel.IBuildingComponentQuantityViewModel {
			return this._QuantityViewModel;
		}
		public set QuantityViewModel(newQuantityViewModel: BuildingComponentQuantityViewModel.IBuildingComponentQuantityViewModel) {
			this._QuantityViewModel = newQuantityViewModel;
		}

		public get Quantity(): number {
			return this._Quantity;
		}
		public set Quantity(newQuantity: number) {
			this._Quantity = newQuantity;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _QuantityViewModel: BuildingComponentQuantityViewModel.IBuildingComponentQuantityViewModel = new BuildingComponentQuantityViewModel.BuildingComponentQuantityViewModel(undefined);
		protected _Quantity: number = 0;

		constructor(data?: DataContracts.IBuildingComponentInstanceQuantityViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentInstanceQuantityViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._QuantityViewModel = data.QuantityViewModel ? new BuildingComponentQuantityViewModel.BuildingComponentQuantityViewModel(data.QuantityViewModel): undefined;
				this._Quantity = data.Quantity;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentInstanceQuantityViewModel {
			return {
				Id: this.Id,
				QuantityViewModel: (this.QuantityViewModel ? new BuildingComponentQuantityViewModel.BuildingComponentQuantityViewModel(this.QuantityViewModel).ToJsonContract(): this.QuantityViewModel),
				Quantity: this.Quantity
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Tender.BuildingComponentInstanceMeasureViewModel
	 */
	 

	export interface IBuildingComponentInstanceMeasureViewModel {
		Id: number;
		MeasureViewModel: MeasureViewModel.IMeasureViewModel;
		ToJsonContract(): DataContracts.IBuildingComponentInstanceMeasureViewModel;
	}

	export class BuildingComponentInstanceMeasureViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get MeasureViewModel(): MeasureViewModel.IMeasureViewModel {
			return this._MeasureViewModel;
		}
		public set MeasureViewModel(newMeasureViewModel: MeasureViewModel.IMeasureViewModel) {
			this._MeasureViewModel = newMeasureViewModel;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _MeasureViewModel: MeasureViewModel.IMeasureViewModel = new MeasureViewModel.MeasureViewModel(undefined);

		constructor(data?: DataContracts.IBuildingComponentInstanceMeasureViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentInstanceMeasureViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._MeasureViewModel = data.MeasureViewModel ? new MeasureViewModel.MeasureViewModel(data.MeasureViewModel): undefined;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentInstanceMeasureViewModel {
			return {
				Id: this.Id,
				MeasureViewModel: (this.MeasureViewModel ? new MeasureViewModel.MeasureViewModel(this.MeasureViewModel).ToJsonContract(): this.MeasureViewModel)
			}
		}
	}	

	/**
	 * Originele namespace: WoonConnectViewModel.Tender.BuildingComponentInstanceEfficacyViewModel
	 */
	 

	export interface IBuildingComponentInstanceEfficacyViewModel {
		Id: number;
		EfficacyViewModel: EfficacyViewModel.IEfficacyViewModel;
		Quantity: number;
		TotalCost: number;
		ToJsonContract(): DataContracts.IBuildingComponentInstanceEfficacyViewModel;
	}

	export class BuildingComponentInstanceEfficacyViewModel {
		// property-Properties
		public get Id(): number {
			return this._Id;
		}
		public set Id(newId: number) {
			this._Id = newId;
		}

		public get EfficacyViewModel(): EfficacyViewModel.IEfficacyViewModel {
			return this._EfficacyViewModel;
		}
		public set EfficacyViewModel(newEfficacyViewModel: EfficacyViewModel.IEfficacyViewModel) {
			this._EfficacyViewModel = newEfficacyViewModel;
		}

		public get Quantity(): number {
			return this._Quantity;
		}
		public set Quantity(newQuantity: number) {
			this._Quantity = newQuantity;
		}

		public get TotalCost(): number {
			return this._TotalCost;
		}
		public set TotalCost(newTotalCost: number) {
			this._TotalCost = newTotalCost;
		}

		// field-Properties

		// fields
		protected _Id: number = 0;
		protected _EfficacyViewModel: EfficacyViewModel.IEfficacyViewModel = new EfficacyViewModel.EfficacyViewModel(undefined);
		protected _Quantity: number = 0;
		protected _TotalCost: number = 0;

		constructor(data?: DataContracts.IBuildingComponentInstanceEfficacyViewModel) {
			if(data){
				this.fromDataContract(data);
			}
		}

		protected fromDataContract(data: DataContracts.IBuildingComponentInstanceEfficacyViewModel): void {
			if(data) {
				// vul properties
				this._Id = data.Id;
				this._EfficacyViewModel = data.EfficacyViewModel ? new EfficacyViewModel.EfficacyViewModel(data.EfficacyViewModel): undefined;
				this._Quantity = data.Quantity;
				this._TotalCost = data.TotalCost;
				// vul fields
			}
		}

		public ToJsonContract(): DataContracts.IBuildingComponentInstanceEfficacyViewModel {
			return {
				Id: this.Id,
				EfficacyViewModel: (this.EfficacyViewModel ? new EfficacyViewModel.EfficacyViewModel(this.EfficacyViewModel).ToJsonContract(): this.EfficacyViewModel),
				Quantity: this.Quantity,
				TotalCost: this.TotalCost
			}
		}
	}
