import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, Observable,of,empty } from 'rxjs';
import { map, mergeMap, switchMap, catchError, withLatestFrom, filter } from 'rxjs/operators';
import * as GridActions from './dwelling.actions';
import { DwellingState } from './dwelling.state';
import * as _ from 'lodash';
import { WebBerichtConsumer } from '@consumers/WebBerichtController';
import * as ModelWisselWoning from '@models/WisselWoning';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class DwellingEffects {
   updateDwellingServerSide = createEffect(() => this.actions$.pipe(
        ofType(GridActions.changeScenarioAndDwelling),
        withLatestFrom(this.store, (action, state) => ({ action: action, dwelling: state.dwelling })),
        switchMap((state) => {
            var wwmodel = new ModelWisselWoning.WisselWoningDataIn({ WoningId: state.dwelling.DwellingId, Scenario: state.dwelling.ScenarioId });
            return this.webberichtconsumer.WisselWoning_Observable(wwmodel);
        }), map((x) => GridActions.changeScenarioAndDwellingSuccessServerSide())
   ));

   changeScenarioAndDwelling =  createEffect(() => this.actions$.pipe(
    ofType(GridActions.changeScenarioAndDwelling, GridActions.loadConfig),
    withLatestFrom(this.store, (action, state) => ({ action: action, dwelling: state.dwelling })),
    map((x) => GridActions.changeScenarioAndDwellingSuccess())
    ));

   uloadDwellingList = createEffect(() => this.actions$.pipe(
    ofType(GridActions.ReloadDwellingList, GridActions.loadConfig),
    withLatestFrom(this.store, (action, state) => ({ action: action, dwelling: state.dwelling })),
    filter(x => x.action.type == GridActions.ReloadDwellingList.type || !x.dwelling.WoningenGeladen),
    switchMap((state) => {
        return this.http.post('/api/woning/WoningGroepen/' + state.dwelling.ProjectId, {})
    }), map((x) => GridActions.ReloadDwellingListSuccess({data: x}))
    ));

    constructor(
        private store: Store<{ dwelling: DwellingState }>,
        private http: HttpClient,
        private actions$: Actions,
        private webberichtconsumer: WebBerichtConsumer,
    ) { }
}