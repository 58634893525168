/// <reference path='../consumerinterfaces/WebBerichtController.ts' />
/**
 * @ref WoonConnectApiController/WebBericht/WebBerichtController.cs
 */
 import { Injectable } from '@angular/core';
 import * as Interface from "../consumerinterfaces/WebBerichtController";
 import { HttpClient } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { WctLogService }  from '../../../services/WctLogService';
 import { tap, catchError  } from 'rxjs/operators';

import * as KiesOptieDataIn from "@datacontracts/KiesOptieDataIn";
import * as KiesOptieDataUit from "@datacontracts/KiesOptieDataUit";
import * as BerekenScenarioHelper from "@datacontracts/BerekenScenarioHelper";
import * as ScenarioViewModel from "@datacontracts/ScenarioViewModel";
import * as UpdateScenarioSettings from "@datacontracts/UpdateScenarioSettings";
import * as ResetOptiesHelper from "@datacontracts/ResetOptiesHelper";
import * as GeefWoningInstantiesHelper from "@datacontracts/GeefWoningInstantiesHelper";
import * as RegressieTest from "@datacontracts/RegressieTest";
import * as WisselWoning from "@datacontracts/WisselWoning";
import * as request from "../../../helpers/IRequestHandler";



	/**
	 * Automatisch gegenereerde controller consumer.
	 */
	@Injectable({
		providedIn: 'root',
	})
	export class WebBerichtConsumer implements Interface.IWebBerichtConsumer {
		private m_Operation : number = 0;
		constructor(
			private readonly http: HttpClient, private log: WctLogService) {
		}

		public KiesOptie_Observable(inputModel: KiesOptieDataIn.IKiesOptieDataIn & {ToJsonContract(noContent?: boolean): KiesOptieDataIn.IKiesOptieDataIn}): Observable<KiesOptieDataUit.IKiesOptieDataUit> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/WebBerichtController/KiesOptie`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<KiesOptieDataUit.IKiesOptieDataUit>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public KiesOptie(inputModel: KiesOptieDataIn.IKiesOptieDataIn & {ToJsonContract(noContent?: boolean): KiesOptieDataIn.IKiesOptieDataIn}): ng.IPromise<KiesOptieDataUit.IKiesOptieDataUit> {
				let obs = this.KiesOptie_Observable(inputModel);
				let promise = new Promise<KiesOptieDataUit.IKiesOptieDataUit>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public BerekenScenariosAngular_Observable(inputModel: BerekenScenarioHelper.IBerekenScenarioDataIn & {ToJsonContract(noContent?: boolean): BerekenScenarioHelper.IBerekenScenarioDataIn}): Observable<ScenarioViewModel.IScenarioViewModel> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/WebBerichtController/BerekenScenariosAngular`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ScenarioViewModel.IScenarioViewModel>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public BerekenScenariosAngular(inputModel: BerekenScenarioHelper.IBerekenScenarioDataIn & {ToJsonContract(noContent?: boolean): BerekenScenarioHelper.IBerekenScenarioDataIn}): ng.IPromise<ScenarioViewModel.IScenarioViewModel> {
				let obs = this.BerekenScenariosAngular_Observable(inputModel);
				let promise = new Promise<ScenarioViewModel.IScenarioViewModel>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public UpdateBerekeningVariabelen_Observable(inputModel: UpdateScenarioSettings.IUpdateScenarioSettings & {ToJsonContract(noContent?: boolean): UpdateScenarioSettings.IUpdateScenarioSettings}): Observable<boolean> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/WebBerichtController/UpdateBerekeningVariabelen`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<boolean>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public UpdateBerekeningVariabelen(inputModel: UpdateScenarioSettings.IUpdateScenarioSettings & {ToJsonContract(noContent?: boolean): UpdateScenarioSettings.IUpdateScenarioSettings}): ng.IPromise<boolean> {
				let obs = this.UpdateBerekeningVariabelen_Observable(inputModel);
				let promise = new Promise<boolean>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public ResetOpties_Observable(inputModel: ResetOptiesHelper.IResetOptiesDataIn & {ToJsonContract(noContent?: boolean): ResetOptiesHelper.IResetOptiesDataIn}): Observable<ResetOptiesHelper.IResetOptiesDataUit> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/WebBerichtController/ResetOpties`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<ResetOptiesHelper.IResetOptiesDataUit>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public ResetOpties(inputModel: ResetOptiesHelper.IResetOptiesDataIn & {ToJsonContract(noContent?: boolean): ResetOptiesHelper.IResetOptiesDataIn}): ng.IPromise<ResetOptiesHelper.IResetOptiesDataUit> {
				let obs = this.ResetOpties_Observable(inputModel);
				let promise = new Promise<ResetOptiesHelper.IResetOptiesDataUit>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public GeefWoningInstanties_Observable(inputModel: GeefWoningInstantiesHelper.IGeefWoningInstantiesDataIn & {ToJsonContract(noContent?: boolean): GeefWoningInstantiesHelper.IGeefWoningInstantiesDataIn}): Observable<GeefWoningInstantiesHelper.IGeefWoningInstantiesDataUit> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/WebBerichtController/GeefWoningInstanties`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<GeefWoningInstantiesHelper.IGeefWoningInstantiesDataUit>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public GeefWoningInstanties(inputModel: GeefWoningInstantiesHelper.IGeefWoningInstantiesDataIn & {ToJsonContract(noContent?: boolean): GeefWoningInstantiesHelper.IGeefWoningInstantiesDataIn}): ng.IPromise<GeefWoningInstantiesHelper.IGeefWoningInstantiesDataUit> {
				let obs = this.GeefWoningInstanties_Observable(inputModel);
				let promise = new Promise<GeefWoningInstantiesHelper.IGeefWoningInstantiesDataUit>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public RunRegressieTest_Observable(inputModel: RegressieTest.IRegressieTestDataIn & {ToJsonContract(noContent?: boolean): RegressieTest.IRegressieTestDataIn}): Observable<RegressieTest.IRegressieTestDataUit> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/WebBerichtController/RunRegressieTest`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<RegressieTest.IRegressieTestDataUit>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public RunRegressieTest(inputModel: RegressieTest.IRegressieTestDataIn & {ToJsonContract(noContent?: boolean): RegressieTest.IRegressieTestDataIn}): ng.IPromise<RegressieTest.IRegressieTestDataUit> {
				let obs = this.RunRegressieTest_Observable(inputModel);
				let promise = new Promise<RegressieTest.IRegressieTestDataUit>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		

		public WisselWoning_Observable(inputModel: WisselWoning.IWisselWoningDataIn & {ToJsonContract(noContent?: boolean): WisselWoning.IWisselWoningDataIn}): Observable<WisselWoning.IWisselWoningDataUit> {
				const input = inputModel.ToJsonContract();
				let operation = this.m_Operation++;
				let consumerdata = input;
				let apiURL = `api/WebBerichtController/WisselWoning`;
				this.log.ConsumerCall(apiURL, operation, consumerdata);
				let obs = this.http
					.post<WisselWoning.IWisselWoningDataUit>(apiURL, consumerdata)
					.pipe(tap(x => this.log.ConsumerReturn(apiURL, operation, x)), 
						   catchError(err => {
							this.log.ConsumerError(apiURL, operation, err);
							return throwError(err);
						}));
				return obs;
		};
		

		public WisselWoning(inputModel: WisselWoning.IWisselWoningDataIn & {ToJsonContract(noContent?: boolean): WisselWoning.IWisselWoningDataIn}): ng.IPromise<WisselWoning.IWisselWoningDataUit> {
				let obs = this.WisselWoning_Observable(inputModel);
				let promise = new Promise<WisselWoning.IWisselWoningDataUit>((resolve, reject) => {
					obs	
					.pipe(tap(x => this.log.debug(x)))
					.toPromise()
					.then(
						res => {
							// Success
							resolve(res);
						},
						msg => {
							// Error
							reject(msg);
						}
					);
			});
			return promise;
		};


		
		
	}


