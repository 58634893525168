import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'wct-survey-main',
  templateUrl: './wct-survey-main.component.html',
  styleUrls: ['./wct-survey-main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WctSurveyMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
