<div class="content" *ngIf="!Loading && !LoadingUser">
  <h1>Gebruikers</h1>

  <a href="#" onclick="return false;" (click)="Nieuw()">nieuw</a>

  <div *ngIf="EditUser" style="padding: 10px; border: 1px solid #000;">
    <table>
      <tr>
        <td>Username</td>
        <td><input type="text" autocomplete="off" [(ngModel)]="UserName" /></td>
      </tr>
      <tr>
        <td>Password</td>
        <td><input type="password" autocomplete="off" [(ngModel)]="Password" /></td>
      </tr>
      <tr>
        <td>Rol</td>
        <td>
          <select [(ngModel)]="Rol">
            <option ng-value="Gebruiker">Gebruiker</option>
            <option ng-value="Beheerder">Beheerder</option>
          </select>
        </td>
      </tr>
    </table>
    <br />
    <div *ngIf="Error != ''" style="color: darkred;">{{ Error }}</div>
    <br />
    <a href="#" onclick="return false;" (click)="this.EditUser = false;">annuleren</a> &nbsp;
    <a href="#" onclick="return false;" (click)="Opslaan()">opslaan</a>
  </div>

  <h2>Normaal</h2>

  <div class="table-div">
    <table class="overview-table">
      <thead>
        <tr>
          <td>Naam</td>
          <td class="small"></td>
          <td class="small"></td>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let user of UsersViewModel.Users">
          <tr *ngIf="user.Rol == 'Gebruiker'">
            <td>{{ user.UserName }}</td>
            <td><a href="#" onclick="return false;" (click)="Wijzigen(user)">wijzigen</a></td>
            <td><a href="#" onclick="return false;"  (click)="Verwijder(user)">verwijderen</a></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <h2>Beheerder</h2>

  <div class="table-div">
    <table class="overview-table">
      <thead>
        <tr>
          <td>Naam</td>
          <td class="small"></td>
          <td class="small"></td>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let user of UsersViewModel.Users">
          <tr *ngIf="user.Rol == 'Beheerder' && (user.UserName != 'adminTweeSnoeken' || UserViewModel.UserName == 'adminTweeSnoeken')">
            <td>{{ user.UserName }}</td>
            <td><a href="#" onclick="return false;" (click)="Wijzigen(user)">wijzigen</a></td>
            <td><a href="#" onclick="return false;" *ngIf="UserViewModel.UserName != user.UserName" (click)="Verwijder(user)">verwijderen</a></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <br />
  <br />
  <br />
</div>
