<button	class="{{buttonAlign}} {{buttonSize}} {{buttonRole}}" [ngClass]="{'faIcon': buttonIconFaBoolean, 'wctIcon': buttonIconWctBoolean, 'onlyIcon': buttonOnlyIconBoolean, 'passive': buttonBusyBoolean, 'active': buttonActiveBoolean}" [disabled]="buttonDisabledBoolean">
	<span *ngIf="buttonIconFaBoolean" class="cont-icon-fa">
		<mat-progress-spinner *ngIf="buttonDisabledBoolean" mode="indeterminate" color="white" strokeWidth="1" diameter="15"></mat-progress-spinner>
		<i *ngIf="!buttonDisabledBoolean" class="{{buttonIconFa}}" aria-hidden="true"></i>
	</span>
	<span *ngIf="buttonIconWctBoolean" class="cont-icon-wct">
		<mat-progress-spinner *ngIf="buttonDisabledBoolean" mode="indeterminate" color="white" strokeWidth="1" diameter="15"></mat-progress-spinner>
		<i *ngIf="!buttonDisabledBoolean" class="{{buttonIconWct}}" aria-hidden="true"></i>
	</span>
	<span style="font-size: 14px;" *ngIf="buttonOnlyIconBoolean == false && buttonText.length > 0" class="cont-txt" [ngClass]="{'contTxtAfterIcon': buttonIconFaBoolean}">
		{{buttonBusyBoolean ? '' : buttonText }}
	</span>
	<span *ngIf="buttonOnlyIconBoolean == false && buttonText.length == 0" class="cont-txt" [ngClass]="{'contTxtAfterIcon': buttonIconFaBoolean}">
		<ng-content>			
		</ng-content>
	</span>

   <span *ngIf="buttonBusyBoolean" style="width: 50%;">
   </span>
</button> 
