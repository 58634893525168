<div style="display: flex; padding-top: 40px;">
  <div style="flex: auto;"></div>
  <div>
    <div *ngIf="Loading">
      Laden...
    </div>

    <div *ngIf="!LoggedIn && !Loading" style="min-height: 700px;">
      <table>
        <tr>
          <td>Username</td>
          <td><input type="text" [(ngModel)]="UserName" /></td>
        </tr>
        <tr>
          <td>Password</td>
          <td><input type="password" [(ngModel)]="Password" /></td>
        </tr>
      </table>
      <button (click)="Login()">
        Login
      </button>
    </div>

    
  </div>
  <div style="flex: auto;"></div>
</div>
<div *ngIf="LoggedIn && !Loading" style="padding: 40px;">
  <table style="width: 100%" cellpadding="5" cellspacing="5">
    <tr>
      <td>Titel 1</td>
      <td>
        <input type="text" [(ngModel)]="FabrikantenCMSModel.Title1" style="width: 100%;" />
      </td>
    </tr>
    <tr>
      <td>Link 1</td>
      <td>
        <input type="text" [(ngModel)]="FabrikantenCMSModel.Link1" style="width: 100%;" />
      </td>
    </tr>
    <tr>
      <td>
        Image 1 (500x281 px)</td>
      <td>
        <input type="file" class="file-upload" (change)="onFileSelected1($event)" accept="image/*">
        <ng-container *ngIf="Foto1">
          <img [src]="Foto1" style="max-width: 260px;"><br />
        </ng-container>
      </td>
    </tr>
    <tr>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td>Titel 2</td>
      <td>
        <input type="text" [(ngModel)]="FabrikantenCMSModel.Title2" style="width: 100%;" />
      </td>
    </tr>
    <tr>
      <td>Link 2</td>
      <td>
        <input type="text" [(ngModel)]="FabrikantenCMSModel.Link2" style="width: 100%;" />
      </td>
    </tr>
    <tr>
      <td>Image 2 (500x281 px)</td>
      <td>
        <input type="file" class="file-upload" (change)="onFileSelected2($event)" accept="image/*">
        <ng-container *ngIf="Foto2">
          <img [src]="Foto2" style="max-width: 260px;"><br />
        </ng-container>
      </td>
    </tr>
  </table>

  <button (click)="SaveCMS()">
    Save
  </button>

  <app-meer></app-meer>
</div>

